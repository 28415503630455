import React from 'react'

import { Typography } from '@material-ui/core'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { liquidityActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { LiquidityDatepicker } from '@oldComponents/LiquidityDatepicker'

type UpdateResponse = { planned_payment_date: string }

interface PaymentDatePickerProps {
  callUpdateInvoice: AsyncFunction<{ id: ItemIdType; planned_payment_date: string }, UpdateResponse>
  invoiceId: ItemIdType
  plannedPaymentDate: string
}

function PurePaymentDatePicker({ callUpdateInvoice, invoiceId, plannedPaymentDate }: PaymentDatePickerProps) {
  const [{ value, error }, setState] = React.useState<{ error: BackendError; value: string }>({
    error: null,
    value: plannedPaymentDate,
  })
  const cPromiseRef = useCancellablePromiseRef<UpdateResponse>()

  const handleOpen = React.useCallback(() => {
    setState(state => ({ ...state, error: null }))
  }, [])

  const handleChange = React.useCallback(
    async (planned_payment_date: string) => {
      try {
        cPromiseRef.current = cancellablePromise(callUpdateInvoice({ id: invoiceId, planned_payment_date }))

        const response = await cPromiseRef.current.promise
        setState(state => ({ ...state, value: response.planned_payment_date }))
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setState(state => ({ ...state, error: errorMessage }))
        }
      }
    },
    [cPromiseRef, callUpdateInvoice, invoiceId]
  )

  return (
    <>
      <LiquidityDatepicker
        value={value}
        onChange={handleChange}
        ButtonProps={{
          children: (
            <FormattedMessage
              id="invoiceDetailsForm.paymentDate.extraText"
              defaultMessage="Tervezett fizetés: {plannedDate}"
              values={{ plannedDate: <FormattedDate value={value} /> }}
            />
          ),
          style: { fontSize: 'inherit' },
        }}
        onOpen={handleOpen}
      />
      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </>
  )
}

export const PaymentDatePicker = connect(null, dispatch => ({
  callUpdateInvoice: bindActionToPromise(dispatch, liquidityActions.updateInvoicePlannedPaymentDate.request),
}))(PurePaymentDatePicker)

PaymentDatePicker.displayName = 'PaymentDatePicker'
