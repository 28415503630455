import React from 'react'
import PropTypes from 'prop-types'

export default function AttachCaseIcon({ color = 'currentColor', size = 28 }: IconComponentProps) {
  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.666 8V5.333h-5.333V8h5.333ZM5.333 10.667v4H2.676l.004-4A2.646 2.646 0 0 1 5.333 8h5.333V5.333a2.657 2.657 0 0 1 2.667-2.666h5.333a2.657 2.657 0 0 1 2.667 2.666V8h5.334a2.657 2.657 0 0 1 2.666 2.667v14.666A2.658 2.658 0 0 1 26.667 28H5.332a2.657 2.657 0 0 1-2.667-2.667l.003-2.666h2.664v2.666h21.334V10.667H5.332Zm6.667 14-1.88-1.88L12.893 20H2.666v-2.667h10.227l-2.773-2.786 1.88-1.88 6 6-6 6Z"
        fill={color}
      />
    </svg>
  )
}

AttachCaseIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}
