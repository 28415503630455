import { SelectInstance } from 'react-select'

import { SetAlertProps } from '@contexts'

export function serializeAssignment(
  {
    company_vat_category,
    expense_type,
    gross_amount,
    id,
    job_number,
    ledger_number,
    net_amount,
    note,
    tags,
    vat_amount,
    vat_ledger_number,
    vat,
  }: ExpenseDetailsAssignment,
  isAdvancedAccounting: boolean
) {
  return {
    expense_type,
    gross_amount: gross_amount == null ? '' : gross_amount,
    id,
    net_amount: net_amount == null ? '' : net_amount,
    tags,
    vat_amount: vat_amount == null ? '' : vat_amount,
    vat,
    ...(isAdvancedAccounting
      ? {
          company_vat_category,
          job_number: job_number == null ? '' : job_number,
          ledger_number,
          note: note == null ? '' : note,
          vat_ledger_number,
        }
      : {}),
  }
}

//* QUICK HELPERS
// update select
function selectOption(ref: SelectInstance, option: object) {
  ref.selectOption(option)
}

type VatLabel = CompanyVatType & {
  label: string
}

export function createLabelForCompanyVats(vats: CompanyVatType[]): VatLabel[] {
  return vats.map(vat => ({ ...vat, label: vat.name || `${vat.percent}%` }))
}

interface CreateTagHandlerProps {
  company: number
  createNewTag: CreateCommonIdAndNameTypeCallback
  setNewTag: (payload: boolean) => void
  newOption: { name: string }
  selectRef: any
  setErrorAlert: SetAlertProps
}

// create a new tag
export function onCreateTagHandler({
  company,
  createNewTag,
  setNewTag,
  newOption,
  selectRef,
  setErrorAlert,
}: CreateTagHandlerProps) {
  setNewTag(true)
  // call action
  createNewTag({ ...newOption, company })
    .then(response => {
      const newTag = {
        id: response.id,
        name: response.name,
      }
      // select it
      selectOption(selectRef, newTag)
    })
    .catch(error => {
      const errorMessage =
        typeof error === 'string' ? error : error instanceof Error ? error.message : 'Unexpected error'
      // display error in snackalert
      setErrorAlert(errorMessage)
    })
    .finally(() => {
      setNewTag(false)
    })
}
