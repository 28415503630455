import { getCursorFromUrl } from '@helpers'
import { getDefaultPageSize } from '@helpers/cookie'

import { EXPENSE_V2_DEFAULT_SORTING } from '@constants'
import { PageChangeDirection } from '@constants/common'

import attachmentActions from '../attachment/actions'
import authActions from '../auth/actions'
import filtersActions from '../filters/actions'
import quarantineActions from '../quarantine/actions'
import actions from './actions'

const defaultPageSize = getDefaultPageSize()

//* local helper
function getFilingNumberFromResults(
  { id, filingNumber }: Pick<ExpenseListData, 'id' | 'filingNumber'>,
  results: Record<ItemIdType, string>
) {
  return results[id] || filingNumber
}

export const initialState: ExpenseStore = {
  details: {
    data: {} as ExpenseDetailsFrontendValues,
    meta: {
      attachment_count: 0,
      is_duplicate: false,
    },
    fetched: false,
    error: null,
    loading: false,
    uploading: false,
  },
  expenseTypes: {
    data: [],
    dates: [],
    total_netto: [],
    total_vat: [],
    loading: false,
    error: null,
    fetched: false,
  },
  //* V2 list
  list: {
    count: 0,
    countLoading: false,
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    page: 1,
    pageSize: defaultPageSize,
    prevPageUrl: null,
    ...EXPENSE_V2_DEFAULT_SORTING,
  },
  kpiCharts: {
    data: undefined,
    error: null,
    fetched: false,
    loading: false,
  },
}

// TODO later type should depend on generated actions' types [xxx]_[REQUEST|SUCCESS|FAILURE]
type ReducerAction = { type: string; payload: any }

function reducer(state: ExpenseStore = initialState, action: ReducerAction) {
  switch (action.type) {
    case authActions.selectCompany.REQUEST:
      return { ...initialState }

    case filtersActions.initExpenseListParamsFromUrl.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      }
    }

    //* v2
    // update filingNumber in v2 list and reset details
    case actions.bulkFilingV2.SUCCESS:
      return {
        ...state,
        details: { ...initialState.details },
        list: {
          ...state.list,
          data: state.list.data.map((expense: ExpenseListData) => ({
            ...expense,
            filingNumber: getFilingNumberFromResults(expense, action.payload),
          })),
        },
      }

    case actions.expenseFiling.SUCCESS: {
      const { id, filing_number } = state.details.data
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            filing_number: getFilingNumberFromResults({ id, filingNumber: filing_number }, action.payload),
          },
        },
      }
    }

    case actions.updateExpense.SUCCESS:
    case quarantineActions.updateQuarantineInvoice.SUCCESS: {
      const { meta, ...data } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data,
          meta,
        },
      }
    }

    // Expense Details
    case actions.uploadExpense.REQUEST:
    case quarantineActions.uploadQuarantineInvoice.REQUEST:
    case quarantineActions.uploadAcceptedQuarantineInvoice.REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          uploading: true,
        },
      }
    case actions.uploadExpense.SUCCESS: {
      const { meta, ...data } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            ...data,
          },
          meta: {
            ...state.details.meta,
            ...meta,
          },
          uploading: false,
        },
      }
    }
    case quarantineActions.uploadQuarantineInvoice.SUCCESS:
    case quarantineActions.uploadAcceptedQuarantineInvoice.SUCCESS: {
      const { meta, ...data } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data,
          meta,
          uploading: false,
        },
      }
    }
    case actions.uploadExpense.FAILURE:
    case actions.cancelUploadExpenseProcess.REQUEST:
    case actions.abortUploadExpense.REQUEST:
    case quarantineActions.uploadQuarantineInvoice.FAILURE:
    case quarantineActions.uploadAcceptedQuarantineInvoice.FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          uploading: false,
        },
      }
    // details
    //* QUARANTINE INVOICE DETAILS
    case actions.fetchExpenseDetails.REQUEST:
    case quarantineActions.fetchQuarantineInvoiceDetails.REQUEST:
      return {
        ...state,
        details: {
          ...initialState.details,
          loading: true,
        },
      }
    case actions.fetchExpenseDetails.SUCCESS:
    case quarantineActions.fetchQuarantineInvoiceDetails.SUCCESS: {
      const { meta, ...data } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data,
          meta,
          loading: false,
          fetched: true,
          error: null,
        },
      }
    }
    case actions.fetchExpenseDetails.FAILURE:
    case quarantineActions.fetchQuarantineInvoiceDetails.FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          error: action.payload,
        },
      }

    case actions.removeExpenseArtifact.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            artifact: null,
          },
        },
      }

    case actions.bulkTaggingV2.SUCCESS:
    case actions.bulkCategorizationV2.SUCCESS:
    case actions.bulkApproveV2.SUCCESS:
    case actions.bulkAccountingV2.SUCCESS:
    case actions.bulkFilingV2.FAILURE: //! used as secondary success action in this case
      return {
        ...state,
        details: { ...initialState.details },
      }

    case actions.clearExpenseDetails.REQUEST:
    case actions.startBulkAccountingExportV2.REQUEST:
      return {
        ...state,
        details: { ...initialState.details },
      }

    //* reset data when change company
    case authActions.selectCompany.SUCCESS:
      return {
        ...state,
        details: { ...initialState.details },
        expenseTypes: { ...initialState.expenseTypes },
      }

    // status change by accountant
    case actions.expenseStatusChange.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            status: action.payload,
          },
        },
      }
    // advanced accounting: status change by accountant
    case actions.callExcludeFromAccountingToggle.SUCCESS:
    case actions.callAdvancedAccountingToggle.SUCCESS:
    case actions.callUnlockAccounting.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            links: {
              accounting_complete_toggle: action.payload.links.accountingCompleteToggle,
              accounting_unlock: action.payload.links.accountingUnlock,
              add_exclude_from_accounting: action.payload.links.addExcludeFromAccounting,
              remove_exclude_from_accounting: action.payload.links.removeExcludeFromAccounting,
            },
            status: action.payload.status,
          },
        },
      }

    // approve on details
    case actions.expenseApprove.SUCCESS: {
      const { approved } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            is_approved: approved,
          },
        },
      }
    }
    // expense types
    case actions.fetchExpenseTypes.REQUEST:
      return {
        ...state,
        expenseTypes: {
          ...state.expenseTypes,
          loading: true,
          fetched: false,
        },
      }
    case actions.fetchExpenseTypes.SUCCESS:
      return {
        ...state,
        expenseTypes: {
          ...state.expenseTypes,
          data: action.payload,
          loading: false,
          fetched: true,
          error: null,
        },
      }
    case actions.fetchExpenseTypes.FAILURE:
      return {
        ...state,
        expenseTypes: {
          ...state.expenseTypes,
          loading: false,
          error: action.payload,
        },
      }

    case actions.createExpenseType.SUCCESS:
      return {
        ...state,
        expenseTypes: {
          ...state.expenseTypes,
          data: [
            {
              ...action.payload,
              values: state.expenseTypes.dates.map(() => ({
                total: 0,
                all_paid: false,
                has_outstanding: false,
              })), // filled with empty data
            },
            ...state.expenseTypes.data,
          ],
        },
      }

    // meta
    case attachmentActions.increaseAttachment.REQUEST: {
      if (action.payload.documentType === 'expense') {
        return {
          ...state,
          details: {
            ...state.details,
            meta: {
              ...state.details.meta,
              attachment_count: state.details.meta.attachment_count + 1,
            },
          },
        }
      } else {
        return state
      }
    }
    case attachmentActions.decreaseAttachment.REQUEST: {
      if (action.payload.documentType === 'expense') {
        return {
          ...state,
          details: {
            ...state.details,
            meta: {
              ...state.details.meta,
              attachment_count: state.details.meta?.attachment_count - 1,
            },
          },
        }
      } else {
        return state
      }
    }

    //* V2 API
    case actions.fetchExpenseListV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchExpenseListByPagingV2.REQUEST:
    case actions.fetchExpenseDetailsByPagingV2.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        list: {
          ...state.list,
          cursor,
          loading: true,
          fetched: false,
        },
      }
    }

    case actions.fetchExpenseListV2.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          prevPageUrl: action.payload.previous,
          nextPageUrl: action.payload.next,
          loading: false,
          fetched: true,
          error: null,
          ...(action.payload.isCursorDropped && {
            cursor: action.payload.previousCursor,
            page: action.payload.previousCursor ? state.list.page - 1 : initialState.list.page,
          }),
        },
      }

    // only for paging ExpenseListTable w/ cursor pagination
    case actions.fetchExpenseListByPagingV2.SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          prevPageUrl: action.payload.previous,
          nextPageUrl: action.payload.next,
          loading: false,
          fetched: true,
          error: null,
          page: action.payload.direction === PageChangeDirection.NEXT ? state.list.page + 1 : state.list.page - 1,
        },
      }
    }

    case actions.fetchExpenseListV2.FAILURE:
    case actions.fetchExpenseListByPagingV2.FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      }

    case actions.fetchExpenseCountV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          countLoading: true,
        },
      }

    case actions.fetchExpenseCountV2.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          count: action.payload.count,
          countLoading: false,
        },
      }

    case actions.fetchExpenseCountV2.FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          countLoading: false,
          error: action.payload,
        },
      }

    case actions.updateOrderV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          order: action.payload.order,
          orderBy: action.payload.orderBy,
          page: initialState.list.page,
        },
      }

    case actions.updateRowsPerPageV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          pageSize: action.payload.pageSize,
          page: initialState.list.page,
        },
      }

    case filtersActions.toggleExpenseListDateFilter.REQUEST:
    case filtersActions.updateExpenseListFilters.REQUEST:
    case filtersActions.resetExpenseListFilters.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          page: initialState.list.page,
        },
        kpiCharts: {
          ...state.kpiCharts,
          error: null,
          loading: true,
        },
      }

    case actions.fetchExpenseCharts.REQUEST:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          error: null,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchExpenseCharts.SUCCESS:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          data: action.payload,
          loading: false,
          fetched: true,
        },
      }
    case actions.fetchExpenseCharts.FAILURE:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          loading: false,
          error: action.payload,
        },
      }

    case actions.deleteExpenseDuplication.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.map((expenseInvoice: ExpenseListData) =>
            expenseInvoice.id === action.payload.invoice
              ? { ...expenseInvoice, badges: expenseInvoice.badges.filter(badge => badge !== 'duplicate') }
              : expenseInvoice
          ),
        },
      }

    case quarantineActions.triggerExpenseUpdateV2.REQUEST:
    case actions.triggerExpenseListUpdate.REQUEST:
    case actions.resetPagination.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
        },
      }
    }

    default:
      return state
  }
}

export default reducer
