import React from 'react'
import PropTypes from 'prop-types'

import { AppBar, ClickAwayListener, Toolbar, useMediaQuery } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import cx from 'classnames'
import { matchPath, useLocation } from 'react-router'
import styled from 'styled-components'

import { useCompactNavbarVisible } from '@hooks'

import { CompanyBasedIntercomInitializer, PrivateNavbarIntercomLauncherButton } from '@components/Intercom'
import { LogoutButton, MobileProfileMenuToggleButton, NavLink, ProfileMenu } from '@components/navigations'
import { BackToSzamlazzButton } from '@components/navigations/elements/buttons/BackToSzamlazzButton'
import { FlexRow, IconButton } from '@components/ui'
import { QuickLogo } from '@oldComponents/ui'

import { generateMaxWidthBreakPoint, RouteKeys, ROUTES } from '@constants'

import { MainMenu } from './elements'

import { useStyles } from './styles'

const ProfileMenuContainer = styled(FlexRow)`
  ${generateMaxWidthBreakPoint('small')} {
    flex-direction: column;
  }
`

const MobileMenuContainerDiv = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const StyledMobileQuickLogo = styled(QuickLogo)`
  display: block;
`

enum MenuVariant {
  COMPANY = 'company',
  NAVIGATION = 'navigation',
}

interface PrivateNavbarProps {
  hasSubscription: boolean
  withoutLinks?: boolean
}

export function PrivateNavbar({ hasSubscription, withoutLinks = false }: PrivateNavbarProps) {
  const [menu, setMenu] = React.useState<Nullable<MenuVariant>>(null)
  const isDesktop = useMediaQuery('(min-width: 1200px)')
  const location = useLocation()
  const dense = useCompactNavbarVisible()
  const classes = useStyles()

  function toggleNavigationMenu() {
    setMenu(state => (state === MenuVariant.NAVIGATION ? null : MenuVariant.NAVIGATION))
  }

  function toggleCompanyMenu() {
    setMenu(state => (state === MenuVariant.COMPANY ? null : MenuVariant.COMPANY))
  }

  function handleClose() {
    if (menu) {
      setMenu(null)
    }
  }

  const matchProfile = matchPath(ROUTES[RouteKeys.PROFILE], location.pathname)
  const matchCompany = matchPath(ROUTES[RouteKeys.COMPANY], location.pathname)
  const isActive = Boolean(matchProfile || matchCompany)

  return (
    <>
      <CompanyBasedIntercomInitializer />
      <AppBar className={cx(classes.appbar, { dense })} elevation={0}>
        <ClickAwayListener onClickAway={handleClose}>
          <Toolbar className={cx(classes.toolbar, { open: Boolean(menu) })}>
            {!isDesktop && (
              <div className={classes.mobileRoot}>
                <MobileMenuContainerDiv>
                  <NavLink to={ROUTES.root} className={classes.navLink} activeWhenNoCompany linkWhenNoCompany>
                    <StyledMobileQuickLogo withDecoration />
                  </NavLink>
                  <IconButton onClick={toggleNavigationMenu}>
                    <MenuIcon />
                  </IconButton>
                </MobileMenuContainerDiv>
                <MobileProfileMenuToggleButton open={menu === MenuVariant.COMPANY} onClick={toggleCompanyMenu} />
              </div>
            )}
            <div className={cx(classes.toolbarInner, { navigation: menu === MenuVariant.NAVIGATION })}>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
              <div onClick={handleClose}>
                {isDesktop && (
                  <NavLink to={ROUTES.root} className={classes.navLink} activeWhenNoCompany linkWhenNoCompany>
                    <StyledMobileQuickLogo className={classes.logo} withDecoration />
                  </NavLink>
                )}
                {((isDesktop && !withoutLinks) || (!isDesktop && menu === MenuVariant.NAVIGATION)) && (
                  <MainMenu hasSubscription={hasSubscription} />
                )}
              </div>

              {!isDesktop && menu === MenuVariant.COMPANY && (
                <ProfileMenu isActive={isActive} onCloseCallback={handleClose} />
              )}
              {(isDesktop || menu === MenuVariant.NAVIGATION) && (
                <ProfileMenuContainer onClick={handleClose}>
                  {isDesktop && <ProfileMenu isActive={isActive} isDesktop />}
                  <PrivateNavbarIntercomLauncherButton showIconButton={isDesktop} />
                  <BackToSzamlazzButton isDesktop={isDesktop} />
                  <LogoutButton isDesktop={isDesktop} />
                </ProfileMenuContainer>
              )}
            </div>
          </Toolbar>
        </ClickAwayListener>
      </AppBar>
    </>
  )
}

PrivateNavbar.propTypes = {
  hasSubscription: PropTypes.bool.isRequired,
  withoutLinks: PropTypes.bool,
}
