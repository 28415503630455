import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

function BaseBadgePlusTaskIcon({ className, size = 18 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.4 7.2228a4.9585 4.9585 0 0 0-.45-.0228c-1.0555 0-2.0329.335-2.8373.9H0V5.85C0 5.13.63 4.5 1.35 4.5H3.6V3.15c0-.72.63-1.35 1.35-1.35h4.5c.72 0 1.35.63 1.35 1.35V4.5h2.25c.72 0 1.35.63 1.35 1.35v1.3728ZM9.9 12.15c0-2.2385 1.8115-4.05 4.05-4.05 2.2385 0 4.05 1.8115 4.05 4.05 0 2.2385-1.8115 4.05-4.05 4.05-2.2385 0-4.05-1.8115-4.05-4.05Zm-.7082-1.35H5.85a.4499.4499 0 0 1-.45-.45V9H0v4.05c0 .72.63 1.35 1.35 1.35h8.1965C9.1997 13.7239 9 12.9606 9 12.15c0-.4686.0698-.9203.1918-1.35Zm4.3756-.4173a.2.2 0 0 1 .2-.2h.4968a.2.2 0 0 1 .2.2v1.2971h1.2971a.2.2 0 0 1 .2.2v.4968a.2.2 0 0 1-.2.2h-1.2971v1.2971a.2.2 0 0 1-.2.2h-.4968a.2.2 0 0 1-.2-.2v-1.2971h-1.2971a.2.2 0 0 1-.2-.2v-.4968a.2.2 0 0 1 .2-.2h1.2971v-1.2971Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

BaseBadgePlusTaskIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}

export const BadgePlusTaskIcon = styled(BaseBadgePlusTaskIcon)``
