import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export default withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.neutral.xxxLight,
    color: theme.palette.grayscale.xxDark,
    boxShadow: theme.shadows[1],
    textAlign: 'left',
    whiteSpace: 'normal',
  },
  popper: {}, // need to avoid MUI warning when pass a classes: {{ popper }} override in InvoicePaidStatus
}))(Tooltip)
