import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

interface ProviderInfoProps {
  className: string
  companyName: string
  companyCountry: string
  companyTaxNo: string
}

export function ProviderInfo({ className, companyName, companyCountry, companyTaxNo }: ProviderInfoProps) {
  return (
    <>
      <Typography variant="body1" className={className}>
        <strong>{companyName}</strong>
      </Typography>
      <Typography variant="body1" className={className}>
        {companyCountry}
      </Typography>
      <Typography variant="body1" className={className}>
        {companyTaxNo}
      </Typography>
    </>
  )
}

ProviderInfo.propTypes = {
  className: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyCountry: PropTypes.string.isRequired,
  companyTaxNo: PropTypes.string.isRequired,
}
