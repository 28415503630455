import React from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import __groupBy from 'lodash/groupBy'
import __mapValues from 'lodash/mapValues'
import { FormattedMessage } from 'react-intl'

import { Currency } from '../FormattedTexts'
import { Typography } from '../Typography'
import { FlexColumn } from '../Wrappers'

import { CalculatorTotalMessage } from '@messages'
import { CalculatorMainDiv } from './styles'

function isGroupItem(data: SelectedPayableItemV2 | SelectedPayableGroupedItemV2): data is SelectedPayableGroupedItemV2 {
  return (data as SelectedPayableGroupedItemV2).amount !== undefined
}

interface SelectedPaymentCalculatorProps {
  data: Array<SelectedPayableItemV2 | SelectedPayableGroupedItemV2>
  variant?: 'expense' | 'income' | 'salary' | 'tax'
}

export function SelectedPaymentCalculator({ data, variant = 'expense' }: SelectedPaymentCalculatorProps) {
  const collection = __groupBy(data, 'currencyId')
  const amounts = __mapValues(
    collection,
    function mapper(items: Array<SelectedPayableItemV2 | SelectedPayableGroupedItemV2>) {
      return items.reduce(
        (acc, curr) => ({
          amount: acc.amount + Number(curr.remainingAmount),
          total: acc.total + Number(isGroupItem(curr) ? curr.amount : curr.grossAmount),
          used: acc.used + 1,
        }),
        { amount: 0, total: 0, used: 0 }
      )
    }
  )

  return (
    <Fade in>
      <CalculatorMainDiv>
        <FlexColumn>
          <Typography color="gray-50" size="700-xs">
            {variant === 'income' ? (
              <FormattedMessage id="incomes.calculator.labels.total" defaultMessage="Bevételek összesen:" />
            ) : (
              CalculatorTotalMessage
            )}
          </Typography>
          <Typography color="gray-50" size="700-xs">
            <FormattedMessage id="payment.calculator.remainingAmount" defaultMessage="Rendezetlen:" />
          </Typography>
        </FlexColumn>
        {Object.keys(amounts).map((key, index, keys) => (
          <FlexColumn key={key}>
            <Typography color="gray-50" size="400-xs" align="right">
              <Currency value={Number(amounts[key].total)} currencyId={Number(key)} />
              <>{index < keys.length - 1 && ','}</>
            </Typography>
            <Typography color="gray-50" size="400-xs" align="right">
              <Currency value={Number(amounts[key].amount)} currencyId={Number(key)} />
              <>{index < keys.length - 1 && ','}</>
            </Typography>
          </FlexColumn>
        ))}
      </CalculatorMainDiv>
    </Fade>
  )
}

SelectedPaymentCalculator.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        grossAmount: PropTypes.string.isRequired,
        remainingAmount: PropTypes.string.isRequired,
        currencyId: PropTypes.number.isRequired,
      }).isRequired,
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        amount: PropTypes.string.isRequired,
        remainingAmount: PropTypes.string.isRequired,
        currencyId: PropTypes.number.isRequired,
      }).isRequired,
    ]).isRequired
  ).isRequired,
  variant: PropTypes.oneOf(['expense', 'income', 'salary', 'tax']),
}
