import React from 'react'

import styled from 'styled-components'

function PureCircleCheckRegularIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M9.61875 12.6187C9.27813 12.9594 8.72187 12.9594 8.38125 12.6187L6.38125 10.6187C6.04063 10.2781 6.04063 9.72187 6.38125 9.38125C6.72187 9.04063 7.27813 9.04063 7.61875 9.38125L9 10.7625L12.3813 7.38125C12.7219 7.04063 13.2781 7.04063 13.6187 7.38125C13.9594 7.72187 13.9594 8.27813 13.6187 8.61875L9.61875 12.6187ZM18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10ZM10 3.5C6.40938 3.5 3.5 6.40938 3.5 10C3.5 13.5906 6.40938 16.5 10 16.5C13.5906 16.5 16.5 13.5906 16.5 10C16.5 6.40938 13.5906 3.5 10 3.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CircleCheckRegularIcon = styled(PureCircleCheckRegularIcon)``
