import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { getFontStyles } from '@helpers/styled-helpers'

import { CopyAndPasteButton, Typography } from '@components/ui'

import triangleSVGSrc from '@assets/img/triangle_small.svg'

const VALUE_COLOR_CLASSES = {
  inherit: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
} as const

const StyledCopyAndPasteButton = styled(CopyAndPasteButton)`
  ${({ theme }) => getFontStyles(theme, '700-md')}

  &.${VALUE_COLOR_CLASSES.primary} button {
    color: ${({ theme }) => theme.colors.primary[50]};
  }
  &.${VALUE_COLOR_CLASSES.secondary} button {
    color: ${({ theme }) => theme.colors.blue[40]};
  }
`

const IndicatorLabel = styled(Typography)`
  padding-left: 16px;
  position: relative;

  &:before {
    position: absolute;
    left: 0px;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(${triangleSVGSrc});
    background-position: top 5px left 4px;
    background-repeat: no-repeat;
  }
`

const IndicatorWrapperDiv = styled.div`
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.colors.secondary[10]};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface TaxationIndicatorProps {
  className?: string
  formattedValue: JSX.Element | string
  label: StringOrMessage
  value: number | string
  valueColor?: ConstObjectValues<typeof VALUE_COLOR_CLASSES>
}

export function TaxationIndicator({
  className,
  formattedValue,
  value,
  label,
  valueColor = VALUE_COLOR_CLASSES.secondary,
}: TaxationIndicatorProps) {
  return (
    <IndicatorWrapperDiv className={className}>
      <IndicatorLabel size="400-xs" color="gray-80">
        {label}
      </IndicatorLabel>
      <StyledCopyAndPasteButton text={String(value)} noWrap className={valueColor}>
        {formattedValue}
      </StyledCopyAndPasteButton>
    </IndicatorWrapperDiv>
  )
}

TaxationIndicator.propTypes = {
  className: PropTypes.string,
  formattedValue: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
  valueColor: PropTypes.oneOf(Object.values(VALUE_COLOR_CLASSES)),
}
