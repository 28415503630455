import React from 'react'

import { ClickAwayListener, Grow, Popper } from '@material-ui/core'
import styled from 'styled-components'

import { IconButton, Paper } from '@components/ui'

import { MENU_SIZES, MenuSizingOptions } from '../constants'

const MenuButton = styled(IconButton)`
  width: ${MENU_SIZES[MenuSizingOptions.SMALL]}px;
  height: ${MENU_SIZES[MenuSizingOptions.SMALL]}px;
  --css-focus-border-color: transparent;
`

const DropdownMenuContainer = styled.div<{ $minWidth: number; size?: 'small' | 'normal' }>`
  min-width: ${({ $minWidth }) => $minWidth}px;
  display: flex;
  flex-direction: column;

  & > a,
  & > a > * {
    width: 100%;
  }
`

const StyledPopper = styled(Popper)`
  z-index: ${({ theme }) => theme.dialogZIndex};
`

interface DropdownMenuButtonProps {
  children: (renderProps: { onCloseCallback: VoidFunction }) => JSX.Element
  className?: string
  icon: JSX.Element
  isContainerVisible: boolean
  placement: 'bottom-end' | 'bottom-start'
  transformOrigin: 'right top' | 'left top'
  width: number
}

export function DropdownMenuButton({
  children,
  className,
  icon,
  isContainerVisible,
  placement,
  transformOrigin,
  width,
}: DropdownMenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null)
  const open = Boolean(anchorEl)

  React.useEffect(() => {
    // close menu when container is not visible
    if (!isContainerVisible && anchorEl) {
      setAnchorEl(null)
    }
  }, [anchorEl, isContainerVisible])

  function handleToggle(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function handleClose(event: React.MouseEvent<HTMLElement | Document>) {
    if (anchorEl && anchorEl.contains(event.target as Nullable<Node>)) {
      return
    }
    setAnchorEl(null)
  }

  function onCloseCallback() {
    setAnchorEl(null)
  }

  return (
    <>
      <MenuButton className={className} onClick={handleToggle}>
        {icon}
      </MenuButton>
      <StyledPopper open={open} anchorEl={anchorEl} transition placement={placement} disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin }}>
            <Paper borderRadius={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <DropdownMenuContainer $minWidth={width}>{children({ onCloseCallback })}</DropdownMenuContainer>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}
