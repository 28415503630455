import React from 'react'
import PropTypes from 'prop-types'

import __uniqueId from 'lodash/uniqueId'

import { PreviewProvider, useEditPayment } from '@contexts'

import { CustomDialog, CustomDialogHeader } from '@components/ui'

import { PAYMENT_FLOW } from '@constants'

import { EditManualPaymentForm, EditTransferPaymentForm, PaymentDialogTitleMessage } from './elements'
import { EditPaymentDialogProps } from './types'

export function EditPaymentDialog({ onClose, onSubmit, onSubmitSuccess, skipStoreUpdate }: EditPaymentDialogProps) {
  const {
    state: { data, open },
  } = useEditPayment()

  const ariaIdPrefix = __uniqueId('create-payment-dialog')

  return (
    <CustomDialog
      ariaIdPrefix={ariaIdPrefix}
      onClose={onClose}
      open={open}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <CustomDialogHeader title={PaymentDialogTitleMessage} borderless />
      <PreviewProvider>
        {data && data.flow === PAYMENT_FLOW.transfer && (
          <EditTransferPaymentForm
            data={data}
            onClose={onClose}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            skipStoreUpdate={skipStoreUpdate}
          />
        )}
        {data && data.flow === PAYMENT_FLOW.manual && (
          <EditManualPaymentForm
            data={data}
            onClose={onClose}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            skipStoreUpdate={skipStoreUpdate}
          />
        )}
      </PreviewProvider>
    </CustomDialog>
  )
}

EditPaymentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  skipStoreUpdate: PropTypes.bool,
}
