import styled, { keyframes } from 'styled-components'

import { PageWrapper } from '@components/layouts'
import { ReportAccordionContent } from '@components/ui/Accordions'
import { Typography } from '@components/ui/Typography'
import { FlexRow } from '@components/ui/Wrappers'

export const PulsePageWrapper = styled(PageWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`

export const PageTitleTypography = styled(Typography).attrs({
  tag: 'h1',
  size: 'heading-4',
  color: 'gray-80',
})`
  margin: 0 12px;
`

export const AccordionContent = styled(ReportAccordionContent)`
  padding: 20px;
`

const shimmerAnimation = keyframes`
to { transform: translateX(100%) }
`

export const SkeletonBox = styled.div<{ $delay?: string; $height?: number }>`
  height: ${({ $height = 57 }) => $height}px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray[110]};
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(200, 200, 200, 0) 0,
      rgba(200, 200, 200, 0.2) 20%,
      rgba(200, 200, 200, 0.5) 60%,
      rgba(200, 200, 200, 0)
    );
    animation: ${shimmerAnimation} 2s linear infinite;
  }
`

export const KpiSkeletonBox = styled(FlexRow)`
  gap: 20px;
  ${SkeletonBox} {
    flex: 1;
    height: 195px;
  }
`
