import React from 'react'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

// assets
import { useStyles } from './PartnerError'

export default function PartnerLoader() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <CircularProgress color="primary" size={24} />
      <FormattedMessage id="partner.details.loading" defaultMessage="Partner adatainek betöltése folyamatban..." />
    </div>
  )
}
