import styled, { css } from 'styled-components'

import { IconButton } from '@components/ui/Buttons'
import { FlexColumn, FlexRow } from '@components/ui/Wrappers'

export const StyledIconButton = styled(IconButton)`
  padding: 4px;
`

export const ListItem = styled.li<{ $divider: boolean }>`
  display: flex;
  gap: 10px;
  position: relative;
  ${({ $divider, theme }) =>
    $divider &&
    css`
      padding-bottom: 10px;
      margin-bottom: 7px;
      border-bottom: 1px solid ${theme.colors.common.paperStroke};
    `}
`

export const ListItemActionsContainer = styled(FlexRow)`
  padding: 3px;
  margin-top: -3px;
  min-width: 40px;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
`

export const ListItemContent = styled(FlexColumn)`
  gap: 10px;
  flex: 1;
  overflow: hidden;
`

export const ListItemBody = styled.div`
  padding-top: 3px;
  display: flex;
  gap: 40px;
`

export const ListItemText = styled(FlexColumn)`
  flex: 1;
  overflow: hidden;
`

export const IconWrapper = styled.div<{ $rejected?: boolean }>`
  padding-top: 3px;
  flex-shrink: 0;
  width: 20px;
  font-size: 20px;
  color: ${({ $rejected, theme }) => ($rejected ? theme.colors.gray[40] : theme.colors.primary[50])};
`
