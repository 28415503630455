import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

function BaseCreditCardDownloadRegularIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.833 4.111a.39.39 0 0 0-.389-.389H2.556a.39.39 0 0 0-.39.39v.777h11.667v-.778Zm0 3.834v-.723H2.167v4.667a.39.39 0 0 0 .389.389h6.968c.192.448.48.846.838 1.167H2.556A1.557 1.557 0 0 1 1 11.889V4.111c0-.858.698-1.555 1.556-1.555h10.888c.858 0 1.556.697 1.556 1.555v4.66a3.304 3.304 0 0 0-1.167-.826Zm-4.403 2H7.028a.582.582 0 0 0-.584.583c0 .323.26.583.584.583h2.236a3.293 3.293 0 0 1 .166-1.167Zm-6.097.583c0-.323.26-.583.584-.583h1.166c.324 0 .584.26.584.583 0 .323-.26.583-.584.583H3.917a.582.582 0 0 1-.584-.583Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M12.058 8.973a.565.565 0 1 1 1.13 0v2.3l.464-.463a.565.565 0 1 1 .798.8l-1.396 1.395a.565.565 0 0 1-.83.034l-1.434-1.434a.565.565 0 0 1 .798-.799l.47.47V8.973Z"
      />
    </svg>
  )
}

BaseCreditCardDownloadRegularIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}

export const CreditCardDownloadRegularIcon = styled(BaseCreditCardDownloadRegularIcon)``
