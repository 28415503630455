import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormControlLabel, FormHelperText, InputLabel, Radio, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import PublicIcon from '@material-ui/icons/Public'
import { ClassNameMap } from '@material-ui/styles'
import cx from 'classnames'
import { FieldValidator } from 'final-form'
import { Field, useField } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  controlRoot: {
    display: 'inline-flex',
    marginLeft: 0,
    width: 'auto',
  },
  controlLabel: {
    paddingLeft: 5,
    fontSize: 14,
    '&.selected': {
      color: theme.palette.primary.main,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 18,
  },
}))

export enum AttachmentType {
  FILE = 'file',
  LINK = 'link',
}

interface AttachementTypeSelectorProps {
  classes: ClassNameMap<string>
  className?: string
  disabled?: boolean
  label: string
  name: string
  validate?: FieldValidator<string>
}

export function AttachementTypeSelector({
  classes,
  className,
  disabled,
  label,
  name,
  validate,
}: AttachementTypeSelectorProps) {
  const asClasses = useStyles()

  const {
    input: { value: attachmentType },
    meta: { submitError, dirtySinceLastSubmit, error, touched },
  } = useField(name, {
    subscription: {
      dirtySinceLastSubmit: true,
      error: true,
      modified: true,
      submitError: true,
      touched: true,
      value: true,
    },
  })
  const {
    input: { onChange: onFileChange },
  } = useField('file')
  const {
    input: { onChange: onLinkChange },
  } = useField('link')

  // clear file / link input when type changes
  React.useEffect(() => {
    if (attachmentType !== AttachmentType.FILE) {
      onFileChange(null)
    }
    if (attachmentType !== AttachmentType.LINK) {
      onLinkChange('')
    }
  }, [attachmentType, onFileChange, onLinkChange])

  const hasError = !!(((submitError && !dirtySinceLastSubmit) || error) && touched)

  return (
    <FormControl
      fullWidth
      margin="normal"
      error={hasError}
      className={cx(className, 'form-control', { 'form-control-error': hasError })}
      disabled={disabled}
      required
    >
      <InputLabel shrink className={classes.bootstrapFormLabel}>
        {label}
      </InputLabel>
      <RadioGroup row className={classes.bootstrapRoot} aria-label={name}>
        <Field
          name={name}
          value={AttachmentType.FILE}
          type="radio"
          render={({ input }) => (
            <FormControlLabel
              classes={{
                root: asClasses.controlRoot,
                label: cx(asClasses.controlLabel, { selected: input.checked }),
              }}
              control={
                <Radio
                  {...input}
                  disabled={disabled}
                  classes={{ root: asClasses.iconButton }}
                  icon={<InsertDriveFileOutlinedIcon color="inherit" classes={{ root: asClasses.icon }} />}
                  checkedIcon={<InsertDriveFileOutlinedIcon color="primary" classes={{ root: asClasses.icon }} />}
                />
              }
              label={<FormattedMessage id="attachement.type.file" defaultMessage="Fájl" />}
            />
          )}
          validate={validate}
        />
        <Field
          name={name}
          value={AttachmentType.LINK}
          type="radio"
          render={({ input }) => (
            <FormControlLabel
              classes={{
                root: asClasses.controlRoot,
                label: cx(asClasses.controlLabel, { selected: input.checked }),
              }}
              control={
                <Radio
                  {...input}
                  disabled={disabled}
                  classes={{ root: asClasses.iconButton }}
                  icon={<PublicIcon color="inherit" classes={{ root: asClasses.icon }} />}
                  checkedIcon={<PublicIcon color="primary" classes={{ root: asClasses.icon }} />}
                />
              }
              label={<FormattedMessage id="attachement.type.link" defaultMessage="Web link" />}
            />
          )}
          validate={validate}
        />
      </RadioGroup>

      {hasError && <FormHelperText>{error || submitError}</FormHelperText>}
    </FormControl>
  )
}

AttachementTypeSelector.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
}
