import React from 'react'

import { CircularProgress, Fade, InputAdornment } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons'

import { AsyncStatus } from '@hooks'

const DoneIcon = withStyles(theme => ({
  root: {
    color: theme.palette.success.main,
  },
}))(CheckCircleOutline)

const StyledInputAdornment = withStyles(() => ({
  root: {
    paddingTop: 3,
    paddingBottom: 3,
    marginRight: 10,
    height: '100%',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(InputAdornment)

function getStatusBody(status: AsyncStatus) {
  switch (status) {
    case AsyncStatus.DONE:
      return <DoneIcon fontSize="inherit" />
    case AsyncStatus.WIP:
      return <CircularProgress size={18} color="secondary" />
    case AsyncStatus.ERROR:
      return <CancelOutlined color="error" fontSize="inherit" />
    case AsyncStatus.IDLE:
    default:
      return <div />
  }
}

interface AsyncInputAdornmentProps {
  status: AsyncStatus
  className?: string
}

export default function AsyncInputAdornment({ status, className }: AsyncInputAdornmentProps) {
  const StatusBody = getStatusBody(status)

  return (
    <StyledInputAdornment
      className={className}
      // @ts-expect-error MUI bug
      component={Fade}
      in={status !== AsyncStatus.IDLE}
      position="end"
      mountOnEnter
      unmountOnExit
    >
      {StatusBody}
    </StyledInputAdornment>
  )
}
