import React from 'react'

import { CommonAxiosPayload } from '@services'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

/**
 * Custom hook to fetch details via callUrl get method
 * @param {AsyncFunction<unknown, unknown>} callUrl - function to call commonActions' callUrl.request action
 * @param {BackendCallableLink} url - the URL what should be fetched
 * @param {boolean} shouldFetch - flag to trigger fetch
 * @returns - custom state with { loading, error, data }
 */
export function useCallUrl<BackendResponse>(
  callUrl: (args: CommonAxiosPayload<unknown>) => Promise<BackendResponse>,
  url: BackendCallableLink,
  shouldFetch: boolean
) {
  const [data, setData] = React.useState<Nullable<BackendResponse>>(null)
  const [error, setError] = React.useState<BackendError>(null)
  const [isLoading, setLoading] = React.useState(false)

  const cPromiseRef = useCancellablePromiseRef<BackendResponse>()

  React.useEffect(() => {
    async function fetchData() {
      if (!url) {
        throw Error('Url argument must be a valid string in useCallUrl hook when "shouldFetch" property is True!')
      }
      try {
        setLoading(true)
        setError(null)
        cPromiseRef.current = cancellablePromise(callUrl({ url, method: 'get' }))
        const details = await cPromiseRef.current.promise
        setData(details)
        setLoading(false)
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          setError(errorMsg)
        }
      }
    }

    if (shouldFetch) {
      fetchData()
    }
  }, [cPromiseRef, callUrl, url, shouldFetch])

  return {
    data,
    error,
    isLoading,
  }
}
