import React from 'react'

import styled from 'styled-components'

function BaseCheckRegularIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M20.191 6.023c.399.368.399.961 0 1.293L9.88 17.63c-.332.398-.926.398-1.293 0l-5.311-5.313c-.367-.332-.367-.925 0-1.293a.943.943 0 0 1 1.325 0l4.65 4.653 9.648-9.653c.368-.364.961-.364 1.293 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CheckRegularIcon = styled(BaseCheckRegularIcon)``
