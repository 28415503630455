import React from 'react'
import PropTypes from 'prop-types'

import { Field, useField } from 'react-final-form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Typography } from '@components/ui'
import { RenderRffCheckbox } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION } from '@constants/detailsForm'

import messages from '../messages'
import { CheckboxLabelSpan } from '../styles'

const StyledCheckboxContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary[10]};
  height: 28px;
  width: 214px;
  border-radius: 6px;
  padding: 0 8px;
`

const IsAutokasszaCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

interface IsAutokasszaCheckboxProps {
  setBalanceAccordionOpen: (value: boolean) => void
  submitting: boolean
}

export function IsAutokasszaCheckbox({ setBalanceAccordionOpen, submitting }: IsAutokasszaCheckboxProps) {
  const { formatMessage } = useIntl()

  const {
    input: { value },
    meta: { error, dirtySinceLastSubmit, initial: initialValue, submitError, touched },
  } = useField<boolean>('is_autokassza', {
    subscription: {
      error: true,
      dirtySinceLastSubmit: true,
      initial: true,
      submitError: true,
      touched: true,
      value: true,
    },
  })

  const displayedError = touched && (error || (!dirtySinceLastSubmit && submitError))
  const hasError = Boolean(displayedError)

  // useEffect instead of ChangeListener because of Safari bug
  React.useEffect(() => {
    if (!initialValue && value) {
      setBalanceAccordionOpen(true)
    }
  }, [initialValue, setBalanceAccordionOpen, value])

  return (
    <IsAutokasszaCheckboxWrapper>
      <StyledCheckboxContainer>
        <Field
          color="primary"
          fullWidth={false}
          render={props => RenderRffCheckbox({ ...props, displayError: false })}
          type="checkbox"
          disabled={submitting}
          label={<CheckboxLabelSpan>{formatMessage(messages.autoCashRegisterLabel)}</CheckboxLabelSpan>}
          name="is_autokassza"
          subscription={FIELD_SUBSCRIPTION}
        />
      </StyledCheckboxContainer>
      {hasError && (
        <Typography color="error" size="400-sm">
          {displayedError}
        </Typography>
      )}
    </IsAutokasszaCheckboxWrapper>
  )
}

IsAutokasszaCheckbox.propTypes = {
  setBalanceAccordionOpen: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}
