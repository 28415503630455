import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { dokumentsActions } from '@services'

import { usePrevious } from '@hooks/usePrevious'

import { TableCell } from '@components/ui/TableElements'
import { Typography } from '@components/ui/Typography'

import { NotificationTableRow, StyledFlexRow } from './styles'

interface TransactionsNotificationRowProps {
  checkProcessStatus: VoidFunction
  colSpan: number
  count: number
  refreshPage: VoidFunction
}

function BaseDokumentsNotificationRow({
  checkProcessStatus,
  colSpan,
  count,
  refreshPage,
}: TransactionsNotificationRowProps) {
  const intervalRef = React.useRef<number>()
  const prevCount = usePrevious(count)

  const isHidden = count <= 0

  // checkProcessStatus control
  React.useEffect(() => {
    checkProcessStatus()
  }, [checkProcessStatus])

  React.useEffect(() => {
    if (intervalRef.current && isHidden) {
      window.clearInterval(intervalRef.current)
    }

    if (!intervalRef.current && !isHidden) {
      intervalRef.current = window.setInterval(() => checkProcessStatus(), 60000) // 1min
    }

    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current)
      }
    }
  }, [checkProcessStatus, isHidden])

  // refreshPage control
  React.useEffect(() => {
    if (prevCount && prevCount !== count) {
      refreshPage()
    }
  }, [count, prevCount, refreshPage])

  if (isHidden) {
    return null
  }

  return (
    <NotificationTableRow data-testid="dokuments-notification-message">
      <TableCell colSpan={colSpan}>
        <StyledFlexRow>
          <CircularProgress color="inherit" size={16} />
          <Typography size="400-sm" color="gray-70" align="center">
            <FormattedMessage
              id="dokuments.notifications.processing"
              defaultMessage="{count} db dokumentum feldolgozása folyamatban"
              values={{ count }}
            />
          </Typography>
        </StyledFlexRow>
      </TableCell>
    </NotificationTableRow>
  )
}

BaseDokumentsNotificationRow.propTypes = {
  checkProcessStatus: PropTypes.func.isRequired,
  colSpan: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  refreshPage: PropTypes.func.isRequired,
}

export const DokumentsNotificationRow = connect(
  ({ dokuments: { aiRecognitionCount } }: Store) => ({
    count: aiRecognitionCount,
  }),
  {
    checkProcessStatus: dokumentsActions.statusCheck.request,
    refreshPage: dokumentsActions.refreshPage.request,
  }
)(BaseDokumentsNotificationRow)

DokumentsNotificationRow.displayName = 'DokumentsNotificationRow'
