import styled from 'styled-components'

import { CustomDialogActions } from '@components/ui/Dialogs'
import { Typography } from '@components/ui/Typography'
import { Grid2Column } from '@components/ui/Wrappers'

// TODO: review this if all are needed
// TODO: 2 column layout components should be standardized

export const FormSection = styled.section`
  width: 620px;
  padding: 20px;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.gray[110]};
  }
`

interface CollapseProps {
  open?: boolean
}

export const CollapseGrid2Column = styled(Grid2Column)<CollapseProps>`
  opacity: ${props => (props.open ? '1' : '0')};
  max-height: ${props => (props.open ? '150px' : '0')};
  overflow: hidden;
  transition: all 0.3s;
  grid-column-start: 1;
  grid-column-end: 3;
  padding-top: ${props => (props.open ? '20px' : '0')};
`

export const CollapseGrid2ColumnWithNoPaddingTop = styled(CollapseGrid2Column)<CollapseProps>`
  padding-top: 0;
`

export const FormDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
  justify-content: unset;

  & > div:last-child {
    display: inherit;
    gap: inherit;
  }
`

export const CheckboxLabelSpan = styled(Typography).attrs({
  size: '400-xs',
  tag: 'span',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`
