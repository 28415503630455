import React from 'react'

import { FormattedMessage } from 'react-intl'
import { components as reactSelectComponents, GroupBase, OptionProps } from 'react-select'
import styled from 'styled-components'

import { Typography } from '@components/ui'

// need to customize the recommended option
const ReactSelectOption = reactSelectComponents.Option

export type PaidThroughSelectOption = PaidThroughOption & { isDefault?: boolean }

type CustomPaidThroughSelectOptionProps = OptionProps<
  PaidThroughSelectOption,
  boolean,
  GroupBase<PaidThroughSelectOption>
>

const StyledSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
`

const OptionWrapper = styled.div`
  line-height: 1.1;
  min-height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
`

function OverflowTextWithTitle({ children }: { children: React.ReactNode }) {
  const textNodeRef = React.useRef<HTMLSpanElement>(null)
  const [overflowActive, setOverflowActive] = React.useState(false)

  React.useEffect(() => {
    if (textNodeRef.current && typeof children === 'string') {
      const { offsetWidth, scrollWidth } = textNodeRef.current
      setOverflowActive(offsetWidth < scrollWidth)
    }
  }, [children])

  const textNode = React.useMemo(() => {
    return <StyledSpan ref={textNodeRef}>{children}</StyledSpan>
  }, [children])

  if (overflowActive && typeof children === 'string') {
    return <span title={children}>{textNode}</span>
  }

  return textNode
}

export function CustomPaidThroughSelectOption({ children, ...rest }: CustomPaidThroughSelectOptionProps) {
  if (rest.data.isDefault) {
    return (
      <ReactSelectOption {...rest}>
        <OptionWrapper>
          <FormattedMessage
            tagName={StyledSpan}
            id="select.customOption.default"
            defaultMessage="{option} <default>(Alapértelmezett)</default>"
            values={{
              option: <OverflowTextWithTitle>{children}</OverflowTextWithTitle>,
              default: (text: string) => (
                <Typography tag="span" color="gray-40" size="700-xs" italic>
                  {text}
                </Typography>
              ),
            }}
          />
        </OptionWrapper>
      </ReactSelectOption>
    )
  }
  return (
    <ReactSelectOption {...rest}>
      <OverflowTextWithTitle>{children}</OverflowTextWithTitle>
    </ReactSelectOption>
  )
}
