import React from 'react'

import { FormattedMessage } from 'react-intl'

import { useConfetti } from '@hooks'

import { CustomDialogHeader, CustomDialogHeaderProps, Typography } from '@components/ui'

import { DialogOpenState, TaxDeclarationMultiClosureDialogState } from '../constants'

import { DialogCompleteTitle } from '@messages'
import { TaxationTaskDoneTitleMessage } from '../messages'

const BORDERLESS_STATES = [
  TaxDeclarationMultiClosureDialogState.ACCEPT,
  TaxDeclarationMultiClosureDialogState.CONTINUE,
  TaxDeclarationMultiClosureDialogState.RESPONSE,
  TaxDeclarationMultiClosureDialogState.TAX_OVERWRITE,
]

function getTitle({ dialogState, openState }: Pick<ClosureDialogHeaderProps, 'dialogState' | 'openState'>) {
  if (dialogState === TaxDeclarationMultiClosureDialogState.RESPONSE) {
    if (openState === DialogOpenState.SUPPORT) {
      return <FormattedMessage id="taxation.dialog.response.supportFinish" defaultMessage="Nincs több teendőd! 🎉" />
    }
    if (openState === DialogOpenState.TAX_OVERWRITE_FORM) {
      return TaxationTaskDoneTitleMessage
    }
    return DialogCompleteTitle
  }

  if (dialogState === TaxDeclarationMultiClosureDialogState.SUMMARY) {
    return <FormattedMessage id="taxation.dialog.summary.title" defaultMessage="Adataidat sikeresen megadtad" />
  }

  if (dialogState === TaxDeclarationMultiClosureDialogState.FORM) {
    return <FormattedMessage id="taxation.dialog.form.title" defaultMessage="Adatok megadása" />
  }

  if (dialogState === TaxDeclarationMultiClosureDialogState.CONTINUE) {
    return <FormattedMessage id="taxation.dialog.continue.title" defaultMessage="Hogyan folytassuk?" />
  }

  if (dialogState === TaxDeclarationMultiClosureDialogState.TAX_OVERWRITE) {
    if (openState === DialogOpenState.TAX_OVERWRITE_FORM) {
      return <FormattedMessage id="taxation.dialog.modifyTax.title.form" defaultMessage="Fizetendő adó felülírása" />
    }
    if (openState === DialogOpenState.CONFIRM) {
      return (
        <FormattedMessage id="taxation.dialog.modifyTax.title.confirm" defaultMessage="Biztosan felülírod az adókat?" />
      )
    }
  }

  return TaxationTaskDoneTitleMessage
}

function getChildren({
  aria,
  dialogState,
  openState,
}: Pick<ClosureDialogHeaderProps, 'aria' | 'dialogState' | 'openState'>) {
  if (dialogState === TaxDeclarationMultiClosureDialogState.FORM) {
    if (openState === DialogOpenState.SUPPORT) {
      return (
        <Typography id={aria?.describedby} size="400-sm" color="gray-50" align="center">
          <FormattedMessage
            id="taxation.dialog.form.description.support"
            defaultMessage="A bevallás elkészítéséhez néhány általános adatra még szükségünk van. Az alábbi adatokat csak egyszer kell megadnod."
          />
        </Typography>
      )
    }
    return (
      <Typography id={aria?.describedby} size="400-sm" color="gray-50" align="center">
        <FormattedMessage
          id="taxation.dialog.form.description"
          defaultMessage="A bevallás elkészítéséhez néhány általános adatra még szükségünk van, ezt követően tudod letölteni az XML-t. Az alábbi adatokat csak egyszer kell megadnod."
        />
      </Typography>
    )
  }

  if (dialogState === TaxDeclarationMultiClosureDialogState.SUMMARY) {
    if (openState === DialogOpenState.SUPPORT) {
      return (
        <Typography id={aria?.describedby} size="400-sm" color="gray-50" align="center">
          <FormattedMessage
            id="taxation.dialog.summary.support"
            defaultMessage="Sikeresen megadtál minden a bevalláshoz szükséges adatot. A Küldés gombra kattintva ezeket elküldjük a QUiCK szakmai ügyfélszolgálatának, akik személyre szabott segítséget nyújtanak a bevallásodhoz."
          />
        </Typography>
      )
    }
    return (
      <>
        <Typography id={aria?.describedby} size="400-sm" color="gray-50" align="center">
          <FormattedMessage
            id="taxation.dialog.summary.description"
            defaultMessage="Minden készen áll a bevallásod letöltésére."
          />
        </Typography>
        <Typography size="400-sm" color="gray-50" align="center">
          <FormattedMessage id="taxation.dialog.summary.details" defaultMessage="Az alábbi adatokat adtad meg:" />
        </Typography>
      </>
    )
  }

  return undefined
}

export interface ClosureDialogHeaderProps extends Pick<CustomDialogHeaderProps, 'aria' | 'className'> {
  dialogState: Nullable<TaxDeclarationMultiClosureDialogState>
  openState: Nullable<DialogOpenState>
}

function ClosureDialogHeader({ aria, className, dialogState, openState }: ClosureDialogHeaderProps) {
  const { triggerConfetti } = useConfetti({ spread: 90, particleCount: 100, origin: { y: 0.4 } })

  React.useEffect(() => {
    if (
      dialogState === TaxDeclarationMultiClosureDialogState.RESPONSE ||
      dialogState === TaxDeclarationMultiClosureDialogState.ACCEPT
    ) {
      triggerConfetti()
    }
  }, [triggerConfetti, dialogState])

  if (!dialogState) {
    return null
  }

  const customProps: Exclude<CustomDialogHeaderProps, 'aria'> = {
    className,
    borderless:
      BORDERLESS_STATES.includes(dialogState) ||
      (dialogState === TaxDeclarationMultiClosureDialogState.SUMMARY && openState !== DialogOpenState.SUPPORT), // only hide border in summary state when support flow is active
    children: getChildren({ aria, dialogState, openState }),
    title: getTitle({ openState, dialogState }),
  }

  return <CustomDialogHeader aria={aria} {...customProps} />
}

export const MemoizedClosureDialogHeader = React.memo(ClosureDialogHeader, (prevProps, nextProps) => {
  return prevProps.dialogState === nextProps.dialogState
})
