import { Theme } from '@material-ui/core/styles'
import { StyleRulesCallback } from '@material-ui/styles'

import { ADVANCED_ACCOUNTING_CLASS_NAME } from '@constants'

// transform MUI TextField to Bootstrap style
export const formStyles: StyleRulesCallback<Theme, Record<never, never>> = theme => ({
  bootstrapRoot: {
    padding: 0,
    height: 36,
    'label + &': { marginTop: '1.2rem' },
    '&.MuiInput-multiline': {
      height: 'auto',
      minHeight: 36,
    },
  },
  bootstrapTextareaRoot: {
    padding: 0,
    'label + &': { marginTop: '1.2rem' },
  },
  bootstrapError: { '&>input': { borderColor: theme.palette.error.main } },
  bootstrapInput: {
    boxSizing: 'border-box',
    height: '100%',
    borderRadius: 4,
    backgroundColor: theme.palette.grayscale.inputBackground,
    border: `1px solid ${theme.palette.grayscale.light}`,
    fontSize: 16,
    padding: '6px 10px',
    // width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    '&.highlighted:not(:disabled)': {
      borderColor: theme.palette.primary.main,
      [`.${ADVANCED_ACCOUNTING_CLASS_NAME} &`]: {
        borderColor: theme.palette.tertiary.purple,
      },
    },
    '&:focus': { borderColor: theme.palette.tertiary.outlineBlue },
    '&:disabled': { backgroundColor: theme.palette.grayscale.disabledInputBackground },
  },
  bootstrapFormLabel: {
    fontSize: 18,
    whiteSpace: 'nowrap',
  },
  formFeedback: { fontSize: 14 },
  selectInput: {
    padding: 0,
    marginTop: '1.2rem',
    minHeight: 36,
  },
  selectRoot: { textAlign: 'left' },
  eulaLink: {
    color: 'inherit',
    fontWeight: 500,
  },
  amountFormControl: {
    margin: 0,
    fontSize: 14,
    // maxWidth: 170,
  },
  amountRoot: {
    backgroundColor: theme.palette.grayscale.inputBackground,
    border: `1px solid ${theme.palette.grayscale.light}`,
    borderRadius: 4,
    height: 32,
    padding: 0,
    fontSize: 14,
    fontFamily: ['Roboto', 'sans-serif'].join(','),

    '&.Mui-disabled': {
      color: 'initial',
      backgroundColor: '#f1f1f1', // same as react-select disabled
    },
  },
  amountError: { borderColor: theme.palette.error.main },
  amountInput: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    padding: '6px 10px',
    textAlign: 'inherit',
  },
  amountAdornment: {
    margin: 0,
    maxHeight: 30,
    justifyContent: 'center',

    '&.end': {
      borderLeft: `1px solid ${theme.palette.grayscale.light}`,
      padding: '0 5px',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.start': {
      borderRight: `1px solid ${theme.palette.grayscale.light}`,
      padding: '0 5px',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.error': { borderLeftColor: theme.palette.error.main },
  },
  endAdornmentInput: { paddingRight: 0 },
  startAdornmentInput: { paddingLeft: 0 },
  autoTagSelect: {
    '& .react-select__multi-value': {
      backgroundColor: theme.palette.tertiary.lightPurple,
    },
    '& .react-select__multi-value__label, & .react-select__multi-value__remove': {
      color: theme.palette.tertiary.darkPurple,
    },
  },
  highlightedLabel: {
    color: theme.palette.grayscale.xxxDark,
    fontWeight: 700,
  },
})

export const taxAutocompleteStyles = () => ({
  taxAutocompleteFormControl: {
    margin: 0,
    fontSize: 14,
    maxWidth: 330,
  },
  taxAutocompleteRoot: {
    '& .tnatcp__control': { height: 30 },
    '& .tnatcp__control, & .tnatcp__value-container, & .tnatcp__indicators': { minHeight: 0 },
    '& .tnatcp__value-container': {
      padding: '0px 6px',
      height: '100%',
    },
    '& .tnatcp__input > input': { maxWidth: 275 },
  },
})
