import React from 'react'

/**
 * Helper hook to scroll an element into a view with a trigger and an optional delay in MS which also returns a function to do so
 *
 * @param {Nullable<HTMLElement>} element Any HTML Element
 * @param {boolean} trigger Boolean when to trigger the scroll
 * @param {number} [delay=0] Delay in MS
 * @returns scrollIntoView function to call with an optional delay in MS
 */
export function useScrollIntoView(element: Nullable<HTMLElement | undefined>, trigger: boolean, delay = 0) {
  const timeoutRef = React.useRef<number>()

  const scrollIntoView = React.useCallback(
    (delay = 0) => {
      timeoutRef.current = window.setTimeout(
        () => element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' }),
        delay
      )
    },
    [element]
  )

  React.useEffect(() => {
    if (element && trigger) {
      scrollIntoView(delay)
    }
  }, [delay, element, scrollIntoView, trigger])

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current)
    }
  }, [])

  return scrollIntoView
}
