export const API_PREFIX = '/api/1/'
export const COMPANY_ROUTE = `${API_PREFIX}company/{company_id}/`

// TODO: Analytics TBD analytics
// export const API_EXPENSE_TYPE_ANALYTICS_URL = `${companyRoute}expense-type-analytics/`
// export const API_REVENUE_TYPE_ANALYTICS_URL = `${companyRoute}revenue-type-analytics/`

//* V2 url endpoints
export const V2_API_PREFIX = '/api/v2/'
export const V2_COMPANY_ROUTE = `${V2_API_PREFIX}company/{company_id}/`

export const URL_MAX_LENGTH = 2000

export enum UrlValidationLevel {
  INVALID_PARAMS = 1,
  TOO_LONG = 2,
}
