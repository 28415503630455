import React from 'react'
import PropTypes from 'prop-types'

import { FilterSwitchButton } from './FilterSwitchButton'

interface FilterSwitchProps {
  currentValue: boolean | undefined
  name: string
  onChange: (name: string, value?: boolean) => void
  options: readonly StatusFilterOption[]
}

export function FilterSwitch({ currentValue, name, onChange, options }: FilterSwitchProps) {
  function handleOptionClick(option: StatusFilterOption) {
    return function handler() {
      // set undefined as value on clear and pass option.value when selected
      onChange(name, currentValue === option.value ? undefined : option.value)
    }
  }

  return (
    <>
      {options.map(option => (
        <FilterSwitchButton
          key={`name-${option.value}-option`}
          onClick={handleOptionClick(option)}
          isActive={currentValue === option.value}
        >
          {option.label}
        </FilterSwitchButton>
      ))}
    </>
  )
}

FilterSwitch.propTypes = {
  currentValue: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.bool,
      label: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
}
