import React from 'react'

import styled from 'styled-components'

function BaseWarningIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M21.7769 18.6943L13.4512 4.48636C13.1311 3.9399 12.569 3.66667 12.007 3.66667C11.4449 3.66667 10.8828 3.9399 10.5276 4.48636L2.2058 18.6943C1.60119 19.7833 2.39906 21.1534 3.68281 21.1534H20.3342C21.6129 21.1534 22.4131 19.7872 21.7769 18.6943ZM4.06689 19.2798L11.9718 5.7276L19.9462 19.2798H4.06689ZM12.007 15.5756C11.3293 15.5756 10.7798 16.1252 10.7798 16.8028C10.7798 17.4804 11.3305 18.03 12.0085 18.03C12.6865 18.03 13.2341 17.4804 13.2341 16.8028C13.2326 16.126 12.6861 15.5756 12.007 15.5756ZM11.0702 9.59966V13.3468C11.0702 13.866 11.4917 14.2836 12.007 14.2836C12.5222 14.2836 12.9437 13.864 12.9437 13.3468V9.59966C12.9437 9.08443 12.5261 8.66287 12.007 8.66287C11.4878 8.66287 11.0702 9.08443 11.0702 9.59966Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const WarningIcon = styled(BaseWarningIcon)``
