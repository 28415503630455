import React from 'react'

import { connect } from 'react-redux'

import { commonActions, CommonAxiosPayload } from '@services'

import { bindActionToPromise } from '@helpers'

import { RendererRect } from '@components/DocViewer/types'

import { BackendInvoicePreviewData, InvoicePreviewLoaderState, InvoicePreviewVariant } from './types'
import { useLoadInvoicePreviewData } from './useLoadInvoicePreviewData'

export const GeneratedPreviewViewerContext = React.createContext<
  InvoicePreviewLoaderState & {
    previewVariant?: InvoicePreviewVariant
    rendererRect?: RendererRect
    renderPostItLayer?: (scale: number, page?: number) => Nullable<JSX.Element>
  }
>({
  data: null,
  loading: false,
  error: null,
})

interface GeneratedPreviewViewerProviderProps {
  callUrl: AsyncFunction<CommonAxiosPayload, BackendInvoicePreviewData>
  children: React.ReactNode
  previewUrl: string
  previewVariant: InvoicePreviewVariant
  rendererRect: RendererRect
  renderPostItLayer?: (scale: number, page?: number) => Nullable<JSX.Element>
}

function PureGeneratedPreviewViewerProvider({
  callUrl,
  children,
  previewUrl,
  previewVariant,
  rendererRect,
  renderPostItLayer,
}: GeneratedPreviewViewerProviderProps) {
  const { data, error, loading } = useLoadInvoicePreviewData({ callUrl, previewUrl })

  return (
    <GeneratedPreviewViewerContext.Provider
      value={{
        data,
        error,
        loading,
        previewVariant,
        rendererRect,
        renderPostItLayer,
      }}
    >
      {children}
    </GeneratedPreviewViewerContext.Provider>
  )
}

export const GeneratedPreviewViewerProvider = connect(null, dispatch => ({
  callUrl: bindActionToPromise(dispatch, commonActions.callUrl.request),
}))(PureGeneratedPreviewViewerProvider)

GeneratedPreviewViewerProvider.displayName = 'GeneratedPreviewViewerProvider'
