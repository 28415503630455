import React from 'react'

import styled, { css, DefaultTheme } from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

//* PAPER
function getPaperShadow(
  theme: DefaultTheme,
  elevation: Required<PaperStyleOnlyProps>[PaperStyleOnlyPropKeys.ELEVATION]
) {
  switch (elevation) {
    case 1:
      return theme.shadows[50]
    case 2:
      return `0px 5px 15px ${applyOpacity(theme.colors.gray[100], 25)}`
    case 3:
      return `0px 2px 2px 0px ${applyOpacity(theme.colors.gray[100], 25)}`
    case 0:
    default:
      return 'none'
  }
}

enum PaperStyleOnlyPropKeys {
  ELEVATION = 'elevation',
  BORDER_RADIUS = 'borderRadius',
  INFO_BOX = 'infoBox',
}

interface PaperStyleOnlyProps {
  // currently we were only using MUI's elevation 1 (1), 4 (0) and 9 (2), extend if needed
  [PaperStyleOnlyPropKeys.ELEVATION]?: 0 | 1 | 2 | 3
  [PaperStyleOnlyPropKeys.BORDER_RADIUS]?: 0 | 4 | 6
  [PaperStyleOnlyPropKeys.INFO_BOX]?: boolean
}

interface PaperProps extends React.HTMLAttributes<HTMLDivElement>, PaperStyleOnlyProps {
  children: React.ReactNode
  infoBox?: boolean
}

export const Paper = styled.div.withConfig<PaperProps>({
  shouldForwardProp: (prop, defaultValidator) => {
    return !(Object.values(PaperStyleOnlyPropKeys) as unknown[]).includes(prop) && defaultValidator(prop)
  },
})`
  ${({ borderRadius = 4, elevation = 1, theme, infoBox = false }) => css`
    box-shadow: ${getPaperShadow(theme, infoBox ? 0 : elevation)};
    border: 1px solid ${infoBox ? theme.colors.gray[30] : theme.colors.common.paperStroke};
    background-color: ${infoBox ? theme.colors.gray[10] : theme.colors.gray[0]};
    border-radius: ${borderRadius}px;
    padding: ${infoBox ? '20px' : '0'};
  `}
`

//* APPBAR
enum AppBarStyleOnlyPropKeys {
  POSITION = 'position',
}

interface AppBarStyleOnlyProps {
  [AppBarStyleOnlyPropKeys.POSITION]?: React.CSSProperties['position']
}

interface AppBarProps extends React.HTMLAttributes<HTMLDivElement>, AppBarStyleOnlyProps {
  children: React.ReactNode
}

export const AppBar = styled.header.withConfig<AppBarProps>({
  shouldForwardProp: (prop, defaultValidator) => {
    return !(Object.values(AppBarStyleOnlyPropKeys) as unknown[]).includes(prop) && defaultValidator(prop)
  },
})`
  ${({ position = 'fixed' }) => css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: ${position};
    width: 100%;
    z-index: 1100;
  `}
`
//* FLEXCOLUMN
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

//* FLEXDIV
export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`

//* FLEXROW
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
`

//* GRID ITEMS
export const Grid2Column = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`

export const FormGrid2Column = styled(Grid2Column)`
  & .MuiFormControl-marginNormal {
    margin-top: 0;
  }
`

export const FirstColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
`

export const SecondColumn = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
`

export const DoubleColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`
