import { ZoomOrientation } from '@components/DocViewer/constant'

export enum DocViewerImageActions {
  SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL',
  INIT = 'INIT',
  CHANGE_ZOOM_ORIENTATION = 'CHANGE_ZOOM_ORIENTATION',
}

interface SetZoomLevel {
  type: DocViewerImageActions.SET_ZOOM_LEVEL
  value: number
}

interface InitImage {
  type: DocViewerImageActions.INIT
  zoomLevel: number
  zoomOrientation: ZoomOrientation
  imageWidth: number
  imageHeight: number
}

interface ChangeZoomOrientation {
  type: DocViewerImageActions.CHANGE_ZOOM_ORIENTATION
  defaultZoomLevel: number
  zoomOrientation: ZoomOrientation
}

export type ImageStateActions = SetZoomLevel | InitImage | ChangeZoomOrientation

export function setZoomLevel(value: number): SetZoomLevel {
  return {
    type: DocViewerImageActions.SET_ZOOM_LEVEL,
    value,
  }
}

export function initImageZoomLevelAndOrientation({
  zoomLevel,
  zoomOrientation,
  imageWidth,
  imageHeight,
}: Omit<InitImage, 'type'>): InitImage {
  return {
    type: DocViewerImageActions.INIT,
    zoomLevel,
    zoomOrientation,
    imageWidth,
    imageHeight,
  }
}

export function changeZoomOrientation({
  defaultZoomLevel,
  zoomOrientation,
}: {
  defaultZoomLevel: number
  zoomOrientation: ZoomOrientation
}): ChangeZoomOrientation {
  return {
    type: DocViewerImageActions.CHANGE_ZOOM_ORIENTATION,
    defaultZoomLevel,
    zoomOrientation,
  }
}
