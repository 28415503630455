import React from 'react'
import PropTypes from 'prop-types'

import { useFieldArray, UseFieldArrayProps, UseFieldArrayReturn, useFormContext } from 'react-hook-form'

interface ReactHookFieldArrayProps extends Omit<UseFieldArrayProps, 'control'> {
  render: (fieldArrayRenderProps: UseFieldArrayReturn) => JSX.Element | JSX.Element[]
}

export function ReactHookFieldArray({ render, ...fieldArrayProps }: ReactHookFieldArrayProps) {
  const { control } = useFormContext()
  const fieldArrayRenderProps = useFieldArray({ control, ...fieldArrayProps })
  return <>{render(fieldArrayRenderProps)}</>
}

ReactHookFieldArray.propTypes = {
  keyName: PropTypes.string,
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  rules: PropTypes.object,
  shouldUnregister: PropTypes.bool,
}
