import React from 'react'

export default function LightbulbOnIcon({ className, size = 23 }: IconComponentProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={(size / 23) * 24}
      viewBox="0 0 23 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m3.765 13.1-1.95 1.125a.851.851 0 0 0-.435.734c0 .303.162.585.426.739.268.149.593.145.853-.014l1.95-1.125a.846.846 0 0 0 .295-1.142.84.84 0 0 0-1.139-.317Zm.844-8.158L2.658 3.817a.845.845 0 0 0-.853-.013.857.857 0 0 0-.426.738c.005.308.172.59.436.734l1.95 1.125c.26.16.587.164.855.014a.856.856 0 0 0 .425-.739.85.85 0 0 0-.436-.734ZM3.938 9.75a.844.844 0 0 0-.844-.844H.844a.844.844 0 0 0 0 1.688h2.25a.842.842 0 0 0 .844-.844Zm14.378-3.234c.145 0 .29-.04.422-.115l1.947-1.125a.841.841 0 0 0 .295-1.142.84.84 0 0 0-1.139-.317l-1.947 1.125a.843.843 0 0 0 .422 1.574Zm3.34 2.39h-2.25a.844.844 0 0 0 0 1.688h2.25a.844.844 0 0 0 0-1.688Zm-.97 5.32L18.735 13.1a.845.845 0 0 0-.853-.013.856.856 0 0 0-.426.738.85.85 0 0 0 .435.734l1.952 1.125a.84.84 0 0 0 1.138-.316.845.845 0 0 0-.295-1.143ZM11.25 3a6.173 6.173 0 0 0-6.188 6.156c0 1.56.577 2.982 1.53 4.07.582.663 1.49 2.049 1.837 3.217l.004.027h5.636c0-.01.001-.018.003-.027.347-1.168 1.255-2.554 1.836-3.216.953-1.058 1.53-2.479 1.53-4.071C17.438 5.77 14.668 3 11.25 3Zm3.39 9.144c-.552.628-1.233 1.628-1.725 2.668H9.589c-.493-1.04-1.174-2.04-1.725-2.668A4.55 4.55 0 0 1 6.75 9.156c0-2.173 1.691-4.46 4.468-4.469 2.514 0 4.532 2.018 4.532 4.469 0 1.09-.394 2.169-1.11 2.988Zm-6.199 6.83c0 .222.066.438.188.622l.601.904c.185.276.604.502.937.502h2.17c.332 0 .752-.225.936-.502l.601-.903c.104-.158.188-.436.188-.622v-1.349H8.441v1.349Zm2.246-13.162a2.816 2.816 0 0 0-2.812 2.813.562.562 0 1 0 1.125 0c0-.93.757-1.688 1.688-1.688a.562.562 0 1 0 0-1.125Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
