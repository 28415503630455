import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { isAdvancedAccountingAvailable } from '@helpers'

import { ReactHookFormSelectField } from '@components/ui'
import { ReactHookFormSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSelectField'
import { MAIN_SUGGESTIONS } from '@oldComponents/forms/DetailsForm/elements/LedgerNumberSelects/constants'

import { InvoiceType } from '@constants'

import messages from '../messages'

interface VatAreaFieldProps
  extends Pick<ReactHookFormSelectFieldProps<VatArea>, 'disabled' | 'highlighted' | 'isLabelHighlighted' | 'options'> {
  invoiceType: InvoiceType
  isAdvancedAccountingAvailableForUser: boolean
}

function PureVatAreaField({ invoiceType, isAdvancedAccountingAvailableForUser, ...rest }: VatAreaFieldProps) {
  const { formatMessage } = useIntl()
  const { setValue, getValues } = useFormContext<ExpenseDetailsFormInitialValues>()

  const onChangeHandler = React.useCallback(
    (_fieldName, value) => {
      if (invoiceType === InvoiceType.EXPENSE && isAdvancedAccountingAvailableForUser) {
        if (value === '3RD') {
          setValue('reverse_tax_subject', true)
        }

        // clear company_vat_category for all assignments
        getValues('assignments').forEach((assignment, index) => {
          setValue(`assignments.${index}.company_vat_category`, null)
        })

        // add ledger_number suggestion based on vat_area
        const suggestion = MAIN_SUGGESTIONS[invoiceType][value] ?? null
        if (suggestion) {
          setValue('ledger_number', suggestion)
        }
      }
    },
    [getValues, invoiceType, isAdvancedAccountingAvailableForUser, setValue]
  )

  return (
    <ReactHookFormSelectField
      {...rest}
      label={formatMessage(messages.vatAreaLabel)}
      labelKey="label"
      name="vat_area"
      onChange={onChangeHandler}
      valueKey="value"
    />
  )
}

export const VatAreaField = connect((state: Store) => ({
  isAdvancedAccountingAvailableForUser: isAdvancedAccountingAvailable(state),
  options: state.dashboard.common.vat_areas,
}))(PureVatAreaField)

VatAreaField.displayName = 'VatAreaField'
