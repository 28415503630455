import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { attachmentActions } from '@services'

import { bindActionToPromise } from '@helpers'

import {
  AttachmentDocumentType,
  AttachmentUploadFormValues,
  BackendAttachmentFileData,
  CreateAttachmentSuccessFunction,
} from '../types'
import { AttachmentForm } from './AttachmentForm'

const useStyles = makeStyles({
  root: {
    '& ._ddwetr-pdf-viewer': {
      position: 'relative',
    },
  },
})

const BackButton = withStyles(theme => ({
  root: {
    padding: 0,
    color: theme.palette.grayscale.xxxDark,
    '&,&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(Button)

interface UploadViewProps {
  createAttachment: AsyncFunction<AttachmentUploadFormValues, BackendAttachmentFileData>
  documentId: number
  documentType: AttachmentDocumentType
  onClose: VoidFunction
  onSuccess: CreateAttachmentSuccessFunction
}

function PureUploadView({ createAttachment, documentId, documentType, onClose, onSuccess }: UploadViewProps) {
  const classes = useStyles()

  // form
  function handleSubmit(values: AttachmentUploadFormValues) {
    return createAttachment(values)
      .then(response => {
        onSuccess(response)
        return undefined
      })
      .catch(err => err) // err is parsed via getRFFFormErrors in saga
  }

  return (
    <div className={classes.root}>
      <BackButton variant="text" onClick={onClose}>
        <ChevronLeftIcon color="inherit" />
        <FormattedMessage id="uploadOverlay.buttons.back" defaultMessage="Vissza" />
      </BackButton>
      <AttachmentForm onSubmit={handleSubmit} initialValues={{ documentId, documentType, type: undefined, link: '' }} />
    </div>
  )
}

PureUploadView.propTypes = {
  createAttachment: PropTypes.func.isRequired,
  documentId: PropTypes.number.isRequired,
  documentType: PropTypes.oneOf(['expense', 'income', 'partner']).isRequired as React.Validator<AttachmentDocumentType>,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export const UploadView = connect(null, dispatch => ({
  createAttachment: bindActionToPromise(dispatch, attachmentActions.createAttachment.request),
}))(PureUploadView)

UploadView.displayName = 'UploadView'
