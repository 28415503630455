import React from 'react'

import __isObject from 'lodash/isObject'
import { GroupBase } from 'react-select'

import { orderSelectOptions } from '@components/ui/FormElements/helpers'

import { generateOption } from './helpers'
import { PartnerOptionData, PartnerRecommendationConfig } from './types'

function getRecommendedOptions(recommendation: PartnerRecommendationConfig): Nullable<GroupBase<PartnerOptionData>> {
  if (
    __isObject(recommendation) &&
    Object.prototype.hasOwnProperty.call(recommendation, 'options') &&
    Array.isArray(recommendation.options)
  ) {
    const { options, texts } = recommendation
    const mappedOptions = options.map(({ id, name, ...rest }) =>
      generateOption({
        ...rest,
        id: id ?? name,
        name,
        prefix: id ? null : texts.newOptionPrefix,
      })
    )

    return {
      label: texts.recommendationsLabel,
      options: orderSelectOptions(mappedOptions, 'name'),
    }
  }

  return null
}

export function useRecommendation(recommendation: PartnerRecommendationConfig) {
  const [recommendations, setRecommendations] = React.useState<Nullable<GroupBase<PartnerOptionData>>>(null)

  React.useEffect(() => {
    setRecommendations(getRecommendedOptions(recommendation))
  }, [recommendation])

  return React.useMemo(() => {
    function clear(usedLabelText: string) {
      if (__isObject(recommendations) && Object.prototype.hasOwnProperty.call(recommendations, 'options')) {
        // filter out - it has only 1 and match then clear
        const availableRecommendations = recommendations.options.filter(({ name }) => name !== usedLabelText)

        if (availableRecommendations.length === 0) {
          setRecommendations(null)
        } else {
          setRecommendations({
            ...recommendations,
            options: availableRecommendations,
          })
        }
      }
    }

    return {
      recommendations,
      clear,
    }
  }, [recommendations])
}
