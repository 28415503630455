import React from 'react'
import PropTypes from 'prop-types'

import { useController, useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { TextFieldBehavior, TextFieldBehaviorProps } from './TextFieldBehavior'

//* local helper to format date string
function formatDate(value: string) {
  if (!value) return value
  const numericStringValue = value.replace(/[^\d]/g, '')
  if (numericStringValue.length < 5) {
    return numericStringValue
  }
  if (numericStringValue.length < 7) {
    return `${numericStringValue.substring(0, 4)}-${numericStringValue.substring(4)}`
  }
  return `${numericStringValue.substring(0, 4)}-${numericStringValue.substring(4, 6)}-${numericStringValue.substring(
    6,
    8
  )}`
}

export function ReactHookFormDateField({
  name,
  disabled,
  ...rest
}: Omit<TextFieldBehaviorProps, 'error' | 'helperText'>) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control })

  const isFieldDisabled = disabled || isSubmitting
  return (
    <NumberFormat
      {...rest}
      allowEmptyFormatting
      autoComplete="off"
      customInput={TextFieldBehavior}
      disabled={isFieldDisabled}
      error={!!error}
      format={formatDate}
      helperText={error?.message ?? ''}
      isNumericString
      name={name}
      onChange={onChange}
      type="tel"
      value={value ?? ''}
    />
  )
}

ReactHookFormDateField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}
