// simple use state => true when need to hide warningBar and enable FileUploadForm
export function hasActiveSubscription(state: Store) {
  return state.auth.company.data?.subscription.is_active ?? false
}

export function calculatePlanPrices({ discounted_price, price: original_price }: SubscriptionPlan) {
  return discounted_price != null ? discounted_price : original_price
}

export function hasBlockedSubscription(state: Store) {
  return Boolean(state.auth.company.data?.subscription.blocked_reason)
}
