import React from 'react'

import styled from 'styled-components'

function BaseDownloadArrowIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M19.7381 19.817H4.73812C4.1466 19.817 3.66669 20.2973 3.66669 20.8893C3.66669 21.5179 4.1466 22 4.73812 22H19.7381C20.3296 22 20.8095 21.5196 20.8095 20.9277C20.8095 20.3348 20.3319 19.817 19.7381 19.817ZM17.1712 9.84375L13.3095 13.9509V3.07589C13.3095 2.48036 12.8319 2 12.2381 2C11.6444 2 11.1667 2.48036 11.1667 3.07232V13.9473L7.30374 9.84375C7.09303 9.61607 6.80865 9.50446 6.52383 9.50446C6.26017 9.50446 5.9966 9.6008 5.7899 9.79625C5.35883 10.2011 5.33812 10.8797 5.74387 11.311L11.4582 17.3869C11.8627 17.8196 12.6135 17.8196 13.018 17.3869L18.7323 11.311C19.1382 10.8796 19.1173 10.2012 18.6862 9.79625C18.256 9.38839 17.5774 9.37054 17.1712 9.84375Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const DownloadArrowIcon = styled(BaseDownloadArrowIcon)``
