import { getDefaultPageSize } from '@helpers/cookie'
import { getCursorFromUrl } from '@helpers/url'

import authActions from '../auth/actions'
import filtersActions from '../filters/actions'
import actions from './actions'

const defaultPageSize = getDefaultPageSize()

function updatePaymentInStore(state: PaymentStore, updatedPayment: PaymentOrdersData): PaymentStore {
  // action.payload == payment
  return {
    ...state,
    paymentOrders: {
      ...state.paymentOrders,
      data: state.paymentOrders.data.map(payment => {
        if (payment.id === updatedPayment.id) {
          return updatedPayment
        }
        return payment
      }),
    },
  }
}

export const initialState: PaymentStore = {
  bankTransactions: {
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    pageSize: defaultPageSize,
    prevPageUrl: null,
  },
  paidThroughs: {
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    pageSize: defaultPageSize,
    prevPageUrl: null,
  },
  paidThroughOptions: [],
  paymentOrders: {
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    pageSize: defaultPageSize,
    prevPageUrl: null,
  },
  paymentTransactions: {
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    pageSize: defaultPageSize,
    prevPageUrl: null,
  },
}

// TODO: correct types
function reducer(state: PaymentStore = initialState, action: any): PaymentStore {
  switch (action.type) {
    //* reset data when change company
    case authActions.selectCompany.REQUEST:
      return { ...initialState }

    //* PAID THROUGH
    case filtersActions.initPaidThroughListParamsFromUrl.REQUEST: {
      return {
        ...state,
        paidThroughs: {
          ...state.paidThroughs,
          ...action.payload,
        },
      }
    }
    case actions.fetchPaidThroughs.REQUEST:
      return {
        ...state,
        paidThroughs: {
          ...state.paidThroughs,
          loading: true,
          fetched: false,
        },
      }

    case filtersActions.updatePaidThroughFilters.REQUEST:
    case filtersActions.resetPaidThroughFilters.REQUEST:
      return {
        ...state,
        paidThroughs: {
          ...state.paidThroughs,
          loading: true,
        },
      }

    case actions.fetchPaidThroughs.SUCCESS:
      return {
        ...state,
        paidThroughs: {
          ...state.paidThroughs,
          data: action.payload.results,
          error: null,
          fetched: true,
          loading: false,
          nextPageUrl: action.payload.next,
          prevPageUrl: action.payload.previous,
        },
      }

    case actions.fetchPaidThroughs.FAILURE:
      return {
        ...state,
        paidThroughs: {
          ...state.paidThroughs,
          error: action.payload,
          loading: false,
        },
      }

    case actions.fetchPaidThroughOptions.SUCCESS:
      return {
        ...state,
        paidThroughOptions: action.payload,
      }

    // pagination
    case actions.fetchPaidThroughsByPaging.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        paidThroughs: {
          ...state.paidThroughs,
          cursor,
          fetched: false,
          loading: true,
        },
      }
    }

    case actions.updatePaidThroughsRowsPerPage.REQUEST:
      return {
        ...state,
        paidThroughs: {
          ...state.paidThroughs,
          cursor: null,
          loading: true,
          pageSize: action.payload.pageSize,
        },
      }

    case actions.resetPaidThroughsPagination.REQUEST: {
      return {
        ...state,
        paidThroughs: {
          ...state.paidThroughs,
          cursor: null,
        },
      }
    }

    //* PAYMENT ORDERS
    case filtersActions.initPaymentOrderListParamsFromUrl.REQUEST: {
      return {
        ...state,
        paymentOrders: {
          ...state.paymentOrders,
          ...action.payload,
        },
      }
    }

    case actions.fetchPayments.REQUEST:
      return {
        ...state,
        paymentOrders: {
          ...state.paymentOrders,
          fetched: false,
          loading: true,
        },
      }

    case filtersActions.updatePaymentOrderFilters.REQUEST:
    case filtersActions.resetPaymentOrderFilters.REQUEST:
    case filtersActions.togglePaymentOrderDateFilter.REQUEST:
      return {
        ...state,
        paymentOrders: {
          ...state.paymentOrders,
          cursor: null,
          loading: true,
        },
      }

    case actions.fetchPayments.SUCCESS:
      return {
        ...state,
        paymentOrders: {
          ...state.paymentOrders,
          data: action.payload.results,
          error: null,
          fetched: true,
          loading: false,
          nextPageUrl: action.payload.next,
          prevPageUrl: action.payload.previous,
        },
      }

    case actions.fetchPayments.FAILURE:
      return {
        ...state,
        paymentOrders: {
          ...state.paymentOrders,
          error: action.payload,
          loading: false,
        },
      }

    case actions.updatePayment.SUCCESS:
      return updatePaymentInStore(state, action.payload)

    // pagination
    case actions.fetchPaymentsByPaging.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        paymentOrders: {
          ...state.paymentOrders,
          cursor,
          fetched: false,
          loading: true,
        },
      }
    }

    case actions.updatePaymentsRowsPerPage.REQUEST:
      return {
        ...state,
        paymentOrders: {
          ...state.paymentOrders,
          cursor: null,
          loading: true,
          pageSize: action.payload.pageSize,
        },
      }

    case actions.resetPaymentOrdersPagination.REQUEST: {
      return {
        ...state,
        paymentOrders: {
          ...state.paymentOrders,
          cursor: null,
        },
      }
    }

    //* BANK TRANSACTIONS
    case filtersActions.initBankTransactionListParamsFromUrl.REQUEST: {
      return {
        ...state,
        bankTransactions: {
          ...state.bankTransactions,
          ...action.payload,
        },
      }
    }

    case actions.fetchBankTransactions.REQUEST:
      return {
        ...state,
        bankTransactions: {
          ...state.bankTransactions,
          fetched: false,
          loading: true,
        },
      }

    case filtersActions.updateBankTransactionsFilters.REQUEST:
    case filtersActions.resetBankTransactionsFilters.REQUEST:
    case filtersActions.toggleBankTransactionsDateFilter.REQUEST:
      return {
        ...state,
        bankTransactions: {
          ...state.bankTransactions,
          cursor: null,
          loading: true,
        },
      }

    case actions.fetchBankTransactions.SUCCESS:
      return {
        ...state,
        bankTransactions: {
          ...state.bankTransactions,
          data: action.payload.results,
          error: null,
          fetched: true,
          loading: false,
          nextPageUrl: action.payload.next,
          prevPageUrl: action.payload.previous,
        },
      }

    case actions.fetchBankTransactions.FAILURE:
      return {
        ...state,
        bankTransactions: {
          ...state.bankTransactions,
          error: action.payload,
          loading: false,
        },
      }

    // pagination
    case actions.fetchBankTransactionsByPaging.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        bankTransactions: {
          ...state.bankTransactions,
          cursor,
          fetched: false,
          loading: true,
        },
      }
    }

    case actions.updateBankTransactionsRowsPerPage.REQUEST:
      return {
        ...state,
        bankTransactions: {
          ...state.bankTransactions,
          cursor: null,
          loading: true,
          pageSize: action.payload.pageSize,
        },
      }

    case actions.resetBankTransactionsPagination.REQUEST:
      return {
        ...state,
        bankTransactions: {
          ...state.bankTransactions,
          cursor: null,
        },
      }

    //* PAYMENT TRANSACTIONS
    case filtersActions.initPaymentTransactionListParamsFromUrl.REQUEST: {
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          ...action.payload,
        },
      }
    }

    case actions.fetchPaymentTransactions.REQUEST:
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          fetched: false,
          loading: true,
        },
      }

    case filtersActions.updatePaymentTransactionsFilters.REQUEST:
    case filtersActions.resetPaymentTransactionsFilters.REQUEST:
    case filtersActions.togglePaymentTransactionsDateFilter.REQUEST:
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          cursor: null,
          loading: true,
        },
      }

    case actions.fetchPaymentTransactions.SUCCESS:
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          data: action.payload.results,
          error: null,
          fetched: true,
          loading: false,
          nextPageUrl: action.payload.next,
          prevPageUrl: action.payload.previous,
        },
      }

    case actions.fetchPaymentTransactions.FAILURE:
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          error: action.payload,
          loading: false,
        },
      }

    // pagination
    case actions.fetchPaymentTransactionsByPaging.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          cursor,
          fetched: false,
          loading: true,
        },
      }
    }

    case actions.updatePaymentTransactionsRowsPerPage.REQUEST:
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          cursor: null,
          loading: true,
          pageSize: action.payload.pageSize,
        },
      }

    case actions.resetPaymentTransactionsPagination.REQUEST:
      return {
        ...state,
        paymentTransactions: {
          ...state.paymentTransactions,
          cursor: null,
        },
      }

    default:
      return state
  }
}

export default reducer
