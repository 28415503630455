import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import translations from '../../i18n'

const ConnectedIntlProvider = connect(state => {
  const lang = state.locale.language
  return {
    locale: lang,
    messages: translations[lang],
  }
})(IntlProvider)

export default ConnectedIntlProvider
