import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { FeatureFlags } from '@constants/featureFlags'

interface FeatureFlagProps {
  children: (enabled: boolean) => JSX.Element | false | null
  flags: string[]
  flag: FeatureFlags
}

function PureFeatureFlag({ children, flags, flag }: FeatureFlagProps) {
  const enabled = flags.includes(flag)
  return <>{children(enabled)}</>
}

PureFeatureFlag.propTypes = {
  children: PropTypes.func.isRequired,
  flag: PropTypes.oneOf(Object.values(FeatureFlags)).isRequired,
  flags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export const FeatureFlag = connect((state: Store) => ({
  flags: state.auth.company.data?.flags || [],
}))(PureFeatureFlag)

FeatureFlag.displayName = 'FeatureFlag'
