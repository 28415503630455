export function validateProps(keys, props) {
  // checking props
  const propsErrors = []

  keys.forEach(key => {
    switch (key) {
      // functions
      case 'api':
      case 'cancelApi':
      case 'responseHandler':
      case 'calculateInterval': {
        if (typeof props[key] !== 'function') {
          propsErrors.push({ key, msg: 'required and must be a function' })
        }
        break
      }
      // optional function
      case 'emailApi': {
        if (props[key] && typeof props[key] !== 'function') {
          propsErrors.push({ key, msg: 'must be a function' })
        }
        break
      }
      // numbers
      case 'delay':
      case 'interval':
      case 'maxTries': {
        if (typeof props[key] !== 'number' || props[key] < 0) {
          propsErrors.push({ key, msg: 'must be a positive number' })
        }
        break
      }

      default:
        propsErrors.push({ key, msg: 'unknown property' })
        break
    }
  })

  if (propsErrors.length) {
    throw new Error(
      `ProcessListener has invalid properties: ${propsErrors.map(err => `\n - "${err.key}": ${err.msg}`).join(',')}`
    )
  }
}

export function ProcessListener(props) {
  let tries = 0
  let processTimeout = null
  let payload // { id: "backgroundJobId", company_id }
  let resolve
  let reject

  const {
    api,
    emailApi,
    responseHandler,
    interval = 5,
    delay = 10,
    maxTries = 7,
    calculateInterval = (_tries, _interval, _delay) => {
      if (_tries < 1) {
        return _delay * 1000
      }
      return Math.pow(2, _tries - 1) * _interval * 1000
    },
  } = props

  validateProps(Object.keys(props), { api, emailApi, responseHandler, interval, delay, maxTries, calculateInterval })

  function stop() {
    if (processTimeout) {
      clearTimeout(processTimeout)
    }
  }

  function run() {
    if (tries >= maxTries) {
      stop()

      // call emailApi to cancel FE process and notify BE
      if (emailApi) {
        emailApi(payload)
          .then(response => {
            responseHandler(response, resolve, reject, setProcess)
          })
          .catch(err => {
            reject(err)
          })
      } else {
        reject({ response: { data: { _error: '__process_error_maxTries__' } } })
      }
      return
    }

    tries++

    api(payload)
      .then(response => {
        responseHandler(response, resolve, reject, setProcess)
      })
      .catch(err => {
        stop()
        reject(err)
      })
  }

  function setProcess() {
    processTimeout = setTimeout(run, calculateInterval(tries, interval, delay))
  }

  function start(_payload) {
    tries = 0
    payload = _payload

    return new Promise((_resolve, _reject) => {
      resolve = _resolve
      reject = _reject
      setProcess()
    })
  }

  return {
    start,
    stop,
  }
}

export function CancelableProcessListener(props) {
  let tries = 0
  let processTimeout = null
  let payload // { id: "backgroundJobId", invoice_id, company_id }
  let resolve
  let reject

  const {
    api,
    cancelApi,
    responseHandler,
    interval = 5,
    delay = 10,
    calculateInterval = (_tries, _interval, _delay) => {
      if (_tries < 1) {
        return _delay * 1000
      }
      return Math.pow(2, _tries - 1) * _interval * 1000
    },
  } = props

  validateProps(Object.keys(props), { api, cancelApi, responseHandler, interval, delay, calculateInterval })

  function stop() {
    if (processTimeout) {
      clearTimeout(processTimeout)
    }
  }

  function run() {
    tries++

    api(payload)
      .then(response => {
        responseHandler(response, resolve, reject, setProcess)
      })
      .catch(err => {
        stop()
        reject(err)
      })
  }

  function setProcess() {
    processTimeout = setTimeout(run, calculateInterval(tries, interval, delay))
  }

  function start(_payload) {
    tries = 0
    payload = _payload

    return new Promise((_resolve, _reject) => {
      resolve = _resolve
      reject = _reject
      setProcess()
    })
  }

  function cancel() {
    stop()

    cancelApi(payload)
      .then(() => {
        // enter the uploadExpenseSaga resolve path with correct values for (response2)
        // make a similar object as resolved BackgroundJob response ("data.results.id")
        resolve({ data: { results: { id: payload.invoice_id } } })
      })
      .catch(err => {
        reject(err)
      })
  }

  return {
    start,
    cancel,
    stop,
  }
}
