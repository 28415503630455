import React from 'react'

import { Link } from 'react-router-dom'

import { ButtonBaseProps } from './ButtonBase'
import { IconButton } from './IconButton'
import { LinkButtonProps } from './LinkButton'

type LinkIconButtonProps = Omit<LinkButtonProps, 'withoutIcon' | 'children'> & Pick<ButtonBaseProps, 'children'>

export const LinkIconButton = React.forwardRef<HTMLAnchorElement, LinkIconButtonProps>(({ children, ...rest }, ref) => {
  const tag = rest.href ? 'a' : Link

  return (
    <IconButton as={tag} {...(rest.href && { target: '_blank', rel: 'noopener noreferrer' })} {...rest} ref={ref}>
      {children}
    </IconButton>
  )
})

LinkIconButton.displayName = 'LinkIconButton'
