import { css } from 'styled-components'

// Styles to make a custom outline effect (eg.: button, checkbox, input, select)
export const OutlineEffectStyles = css`
  position: relative;

  &::before {
    border-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    bottom: -2px;
    content: '';
    left: -2px;
    position: absolute;
    right: -2px;
    top: -2px;
    transition: border-color 250ms ease;
  }
`
