import Qs from 'qs'
import { matchPath, NavigateFunction } from 'react-router-dom'

import { RouteKeys, ROUTES } from '@constants'

import {
  BackToExpenseButtonMessage,
  BackToLiquidityButtonMessage,
  BackToMonthlyStatementButtonMessage,
  BackToPartnersListButtonMessage,
  BackToPaymentOrdersButtonMessage,
} from '@messages'

export function getRouteUrl(key: keyof typeof ROUTES, company_id?: Nullable<number | string>) {
  const url = ROUTES[key]
  if (url == null || company_id == null) {
    console.warn('Invalid route or company in url')
    return '/dashboard/404'
  } else {
    return replaceCompanyIdInPath(url, company_id)
  }
}

export function replaceCompanyIdInPath(path: string, companyId: number | string) {
  return path.replace(':company_id', String(companyId))
}

export function injectCompanyToURL(url: string, companyId: number | string) {
  return url.replace('{company_id}', String(companyId))
}

/**
 * Helper function to return url for "back-to-list" button on details pages
 * @param {PreviousPathHistoryState} locationState - locationState object
 * @param {number} company - current company id
 * @param {RouteKeysn} listRouteKey - v2 list route key
 * @returns {string} url
 */
export function getBackToListUrl(locationState: PreviousPathHistoryState, company: number, listRouteKey: RouteKeys) {
  const defaultLink = getRouteUrl(listRouteKey, company)
  return locationState?.previousPath ?? defaultLink
}

/**
 * Helper function to return message for "back-to-list" button on details pages
 * @param {string} backToListUrl - url to go back to
 * @param {JSX.Element} defaultMessage - default message for back button
 * @returns - message for back button
 */
export function getBackButtonMessage(backToListUrl: string, defaultMessage: JSX.Element) {
  if (matchPath(ROUTES[RouteKeys.MONTHLY_STATEMENT], backToListUrl)) {
    return BackToMonthlyStatementButtonMessage
  }
  if (matchPath(ROUTES[RouteKeys.PAYMENT_ORDERS], backToListUrl)) {
    return BackToPaymentOrdersButtonMessage
  }
  // dokuments details page
  // TODO expense details page
  if (matchPath(`${ROUTES[RouteKeys.COST]}/:expense_id`, backToListUrl)) {
    return BackToExpenseButtonMessage
  }
  // TODO partners list modal
  if (matchPath(ROUTES[RouteKeys.PARTNERS], backToListUrl)) {
    return BackToPartnersListButtonMessage
  }
  if (matchPath(ROUTES[RouteKeys.LIQUIDITY], backToListUrl)) {
    return BackToLiquidityButtonMessage
  }
  return defaultMessage
}

/**
 * Helper function to sync URL with stored filters (in redux store)
 * @param {NavigateFunction} params.navigate - react-router navigate function
 * @param {ApiPageParams} params.filters - filters from store to sync URL search params
 * @param {boolean} params.skipDateFilters - do not check date filters existance when True (optional, Default: False)
 */
export function syncFiltersWithUrl({
  navigate,
  filters,
  skipDateFilters = false,
}: {
  navigate: NavigateFunction
  filters: ApiPageParams
  skipDateFilters?: boolean
}) {
  // make all filters camelCase
  const urlParams = { ...filters }

  if (!skipDateFilters && filters.fromDate === undefined && filters.toDate === undefined) {
    urlParams.isDateFilterDisabled = true
  }

  // Replace the current URL without triggering a new navigation
  navigate(`${window.location.pathname}?${Qs.stringify(urlParams, { arrayFormat: 'comma', encodeValuesOnly: true })}`, {
    replace: true,
    preventScrollReset: true,
  })
}

export function getCursorFromUrl(url: string) {
  const urlSearchParams = new URLSearchParams(url.split('?')[1])
  return urlSearchParams.get('cursor')
}

/**
 * Helper function to build URL object from relative or absolute url
 * @param {string} uri - relative or absolute url
 * @returns URL object
 */
function buildURL(uri: string) {
  return new URL(uri, window.location.origin)
}

/**
 * Helper function to get last segment of URL (file name from artifact url for income invoice)
 * @param {string} url - relativeor absolute path of income invoice artifact
 * @returns file name from url
 */
export function getLastUrlSegment(url: string) {
  return buildURL(url).pathname.split('/').filter(Boolean).pop()
}

/**
 * Helper function to check if pathnames of signed URLs are equal
 * @param {string} prevUri - pevious signed URL
 * @param {string} nextUri - next signed URL
 * @returns are pathnames of signed URLs equal (boolean)
 */
export function areSignedURLPathnamesEqual(prevUri: string, nextUri: string) {
  return buildURL(prevUri).pathname === buildURL(nextUri).pathname
}

export function trimLocalhostFromUrl(url: string) {
  return url.replace('http://localhost:18031', '') // need for localhost only
}
