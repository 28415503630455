import React from 'react'

import { Grid } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { ReactHookFormConditionalField, ReactHookFormDateField } from '@components/ui/FormElements'

import { SPACING } from '@oldComponents/forms/DetailsForm/styles'
import messages from '../messages'
import { StyledReactHookFormCheckboxField } from './styles'

interface ReschedulePaymentFieldsProps {
  disabled: boolean
  isLabelHighlighted?: boolean
  isVisible?: boolean
}

export function ReschedulePaymentFields({
  disabled,
  isLabelHighlighted,
  isVisible = false,
}: ReschedulePaymentFieldsProps) {
  const { formatMessage } = useIntl()
  const { setValue } = useFormContext()
  const reschedulePayment = useWatch({ name: 'reschedule_payment' })

  //* clear field's value when it is not visible
  React.useEffect(() => {
    if (!reschedulePayment) {
      setValue('planned_payment_date', null)
    }
  }, [reschedulePayment, setValue])

  return (
    <Grid container spacing={SPACING} justifyContent="space-between">
      <Grid item xs={4}>
        <StyledReactHookFormCheckboxField
          name="reschedule_payment"
          isLabelHighlighted={isLabelHighlighted}
          label={formatMessage(messages.reschedulingPaymentLabel)}
          disabled={disabled}
        />
      </Grid>
      <ReactHookFormConditionalField isVisible={isVisible} name="reschedule_payment">
        <Grid item xs={4}>
          <ReactHookFormDateField
            disabled={disabled}
            isLabelHighlighted={isLabelHighlighted}
            label={formatMessage(messages.plannedPaymentDateLabel)}
            name="planned_payment_date"
            placeholder={formatMessage(messages.dateFieldPlaceholder)}
            required
          />
        </Grid>
      </ReactHookFormConditionalField>
    </Grid>
  )
}
