import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { CloseButtonMessage } from '@messages'

export default function InfoDialog({ id = 'mobile-site-info-dialog-title', open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={id}>
      <DialogTitle id={id}>
        <FormattedMessage id="dialogs.mobileBrowser.title" defaultMessage="Figyelem" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="dialogs.mobileBrowser.text"
            defaultMessage="Egyelőre a QUiCK-nek nincs mobilra optimalizált verziója. Ugyan elérhetővé tesszük a teljes weboldalt, de a jobb felhasználói élmény érdekében javasoljuk, hogy használd inkább laptopról vagy számítógépről."
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="small" color="primary" variant="contained">
          {CloseButtonMessage}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InfoDialog.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
