import { FieldRenderProps } from 'react-final-form'

import { PaidThroughType } from '@constants'

export function paidthroughTypeOnChangeHandler({
  form: { change, batch },
  values: { currency, name, is_autokassza, provider, account_number },
  input: { value: paidthrough_type },
  currencies,
  pettyCashMessage,
}: FieldRenderProps<PaidThroughType, HTMLInputElement>) {
  const currencyText = currencies.find((c: Currency) => c.id === currency)?.name
  const pettyCashNameWithCurrency = `${pettyCashMessage} ${currencyText}`

  if (paidthrough_type === PaidThroughType.CASH) {
    batch(() => {
      if (currency != null && !name) {
        change('name', pettyCashNameWithCurrency)
      }

      if (is_autokassza) {
        change('is_autokassza', false)
      }
      if (provider) {
        change('provider', null)
      }
      if (account_number) {
        change('account_number', '')
      }
    })
  }

  if (paidthrough_type === PaidThroughType.BANK_ACCOUNT && name === pettyCashNameWithCurrency) {
    change('name', '')
  }
}

export function currencyOnChangeHandler({
  form: { change },
  values: { name, paidthrough_type },
  input: { value: currency },
  prevValue,
  currencies,
  pettyCashMessage,
}: FieldRenderProps<number, HTMLInputElement>) {
  const currencyText = currencies.find((c: Currency) => c.id === currency)?.name
  const pettyCashNameWithCurrency = `${pettyCashMessage} ${currencyText}`

  const previousNameToMatch = prevValue
    ? `${pettyCashMessage} ${currencies.find((c: Currency) => c.id === prevValue)?.name}`
    : ''

  if (paidthrough_type === PaidThroughType.CASH && currency != null) {
    if (!name || name === previousNameToMatch || name === pettyCashMessage) {
      change('name', pettyCashNameWithCurrency)
    }
  }
}

//* These 2 functions set the add_new_balance field to true if both value_date and balance are filled (checkbox was removed from UI)
export function onChangeValueDateHandler({
  form: { change },
  input: { value: value_date },
  values: { balance },
}: FieldRenderProps<string, HTMLInputElement>) {
  change('add_new_balance', balance != null && value_date != null)
}

export function onChangeBalanceHandler({
  form: { change },
  input: { value: balance },
  values: { value_date },
}: FieldRenderProps<string, HTMLInputElement>) {
  change('add_new_balance', balance != null && value_date != null)
}
