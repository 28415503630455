import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

import { generateMaxWidthBreakPoint } from '@constants'

const styles: Styles<Theme, Record<never, never>> = (theme: any) => ({
  root: {
    position: 'relative',
    height: '100%',
    flexWrap: 'nowrap',
  },
  text1: {
    fontSize: 12,
    lineHeight: '16px',
    position: 'relative',
    zIndex: 1,
    '&.right': {
      textAlign: 'right',
    },
  },
  headerContainer: {
    padding: '30px 20px',
    '&>div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
    '&>div:last-child': {
      marginLeft: 30,
    },
  },
  infoContainer: {
    backgroundColor: '#F3F3F3',
    padding: '35px 20px',
    '& strong': {
      fontWeight: 700,
    },
  },
  dueAtContainer: {
    backgroundColor: theme.palette.tertiary.oceanBlue,
    padding: '5px 10px',
    marginTop: 10,
    '& strong': {
      color: theme.palette.neutral.xxxLight,
    },
  },
  detailsContainer: {
    padding: '30px 20px',
    width: '100%',
  },
  table: {
    '& tr': {
      height: 'auto',
    },
    '& th': {
      whiteSpace: 'nowrap',
    },
    '& th, & td': {
      fontSize: 10,
      fontWeight: 'bold',
      position: 'relative',
      zIndex: 1,
      lineHeight: 1.5,
      padding: '10px 5px',
    },
  },
  tableRow: {
    borderLeft: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
    '&:nth-of-type(even)': {
      backgroundColor: '#E8ECF2',
    },
  },
  tableSum: {
    marginTop: 10,
    '& td': {
      border: 0,
      paddingTop: 30,
    },
    '& td:last-child': {
      fontSize: 14,
    },
  },
  watermark: {
    fontSize: 50,
    lineHeight: 1,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.05)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-45deg)',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    [generateMaxWidthBreakPoint('small')]: {
      fontSize: 45,
    },
  },
})

export default styles
