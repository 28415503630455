import React from 'react'

import styled from 'styled-components'

function BaseScrewdriverWrenchIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
    >
      <path
        d="M12.4809 6.51368L12.4812 8.80293L13.9182 10.2007C13.5951 8.56292 14.0822 6.89553 15.2563 5.72132C16.2074 4.77021 17.4695 4.25919 18.7785 4.25919L18.8313 4.25918L16.0975 6.94109L16.8082 11.1969L21.0645 11.9066L23.7529 9.21766C23.7648 10.5445 23.2541 11.8239 22.2856 12.7868C21.8512 13.2271 21.3406 13.5618 20.7944 13.8027C20.8766 13.8791 20.9763 13.9389 21.0586 14.021L22.4972 15.4639C22.9904 15.1645 23.4601 14.8064 23.8828 14.3836C25.6671 12.6411 26.4008 9.95732 25.7902 7.50274C25.6492 6.92738 25.2087 6.47554 24.6451 6.31726C24.0697 6.15883 23.4591 6.32324 23.0904 6.74007L20.3315 9.49336L18.8111 9.2411L18.5585 7.71462L21.3118 4.96696C21.7286 4.58651 21.8872 3.97592 21.7285 3.4123C21.5758 2.84304 21.1179 2.40247 20.5425 2.26204C18.0476 1.64017 15.4746 2.35644 13.6616 4.16473C13.1802 4.64616 12.7927 5.1863 12.4701 5.71939L12.5229 5.75461L12.4809 6.51368ZM6.98093 23.319C6.38208 23.9179 5.32529 23.9179 4.7208 23.3192C4.42138 23.0199 4.25112 22.657 4.25112 22.2343C4.25107 21.8057 4.41541 21.4067 4.72066 21.1493L11.0343 14.7567L9.44208 13.1644L3.12951 19.4676C2.40149 20.1956 2.04453 21.1641 2.00273 22.1495C1.96093 23.1349 2.4021 24.1452 3.12998 24.8732C3.85799 25.6012 4.82648 26 5.81188 26C6.83908 25.9999 7.80804 25.6006 8.53559 24.8728L13.2747 20.1336C12.8226 19.3882 12.5641 18.5428 12.5406 17.6856L6.98093 23.319ZM25.5382 20.5808L20.0382 15.0854C18.9518 13.9995 17.3305 13.7868 16.0275 14.4326L10.978 9.42103V6.50851L5.00825 2L2.00226 4.96372L6.51124 10.9334L9.42517 10.9338L14.4367 15.9406C13.797 17.244 14.0038 18.8654 15.0901 19.9513L20.5854 25.4513C21.2664 26.138 22.38 26.138 23.0611 25.4512L25.5387 22.9736C26.2193 22.3703 26.2193 21.2618 25.5382 20.5808Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const ScrewdriverWrenchIcon = styled(BaseScrewdriverWrenchIcon)``
