import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ActionMeta, GetOptionLabel, GetOptionValue, GroupBase, SelectInstance } from 'react-select'
import SelectComponent from 'react-select/async-creatable'

import { cancellablePromise, customFilterOption, isFieldHighlighted, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef, usePromptTextCreator } from '@hooks'

import { Typography } from '@components/ui/Typography'

import { CustomReactSelectOption, CustomReactSelectOptionProps } from './elements'
import { ReactHookFormTextFieldProps } from './ReactHookFormTextField'
import { useIsValidNewOption } from './useIsValidNewOption'

import { formStyles, selectFormStyles as customReactSelectStyles } from '@styles'
import { formSelectMessages } from '@messages'

const useStyles = makeStyles(formStyles)

export interface ReactHookFormSearchSelectFieldProps<Option extends Record<string, unknown>>
  extends ReactHookFormTextFieldProps {
  defaultOptions?: boolean
  highlighted?: boolean
  isClearable?: boolean
  isLoading?: boolean
  labelKey?: keyof Option
  menuShouldBlockScroll?: boolean
  onCreateCallback?: (option: Option) => void
  onCreateOption: (payload: any) => Option // need any cause of multiple payload types
  onEmptyCreateText?: string
  onSelectCallback?: (option: Option) => void
  searchOptions: AsyncFunction<string, Option[]>
  SelectOptionComponent?: (props: CustomReactSelectOptionProps<Option>) => JSX.Element
  valueKey?: string
}

export function ReactHookFormSearchSelectField<Option extends Record<string, unknown>>({
  className,
  defaultOptions = false,
  disabled = false,
  highlighted = false,
  isClearable = false,
  isLoading = false,
  label,
  labelKey = 'name',
  menuShouldBlockScroll = false,
  name,
  onCreateCallback, // optional callback after onCreateOption
  onCreateOption,
  onEmptyCreateText, // optional overwrite for no results text when onCreate property is passed
  onSelectCallback,
  required,
  searchOptions,
  SelectOptionComponent = CustomReactSelectOption,
  valueKey = 'name',
}: ReactHookFormSearchSelectFieldProps<Option>) {
  const { formatMessage } = useIntl()
  const { control } = useFormContext()
  const classes = useStyles()
  const selectRef = React.useRef<SelectInstance<Option, false, GroupBase<Option>>>()
  const cPromiseRef = useCancellablePromiseRef()

  const promptTextCreator = usePromptTextCreator()

  const isValidNewOption = useIsValidNewOption(labelKey)

  //* customize react-select components
  const componentOverridesProp: any = {
    components: {
      Option: SelectOptionComponent,
    },
  }

  const loadOptions = React.useCallback(
    fieldValue => (inputValue: string, callback: (options: Option[]) => void) => {
      // previously it was inputValue === undefined, but defualt by inputValue sems to be empty string
      const searchOnMount = defaultOptions && !inputValue
      const searchValue = searchOnMount ? fieldValue : inputValue

      if (String(searchValue).length >= 3) {
        cPromiseRef.current = cancellablePromise(searchOptions(searchValue))
        cPromiseRef.current.promise
          .then(newOptions => {
            callback(newOptions)
          })
          .catch(error => {
            const errorMessage = parseApiErrorMessage(error)
            console.error(errorMessage) // TODO has to visible for user?
          })
      } else {
        callback([])
      }
    },
    [cPromiseRef, defaultOptions, searchOptions]
  )

  //* react-select getters
  const noOptionsMessage = React.useCallback(() => {
    if (onEmptyCreateText) {
      return (
        <Typography size="400-xs" color="inherit" tag="span">
          {onEmptyCreateText}
        </Typography>
      )
    }
    return formatMessage(formSelectMessages.asyncNoResultsText)
  }, [formatMessage, onEmptyCreateText])

  function loadingMessage() {
    return formatMessage(formSelectMessages.asyncLoadingText)
  }

  const getOptionLabel = React.useCallback<GetOptionLabel<Option>>(option => String(option[labelKey]), [labelKey])

  const getOptionValue = React.useCallback<GetOptionValue<Option>>(option => String(option[valueKey]), [valueKey])

  const getNewOptionData = React.useCallback(
    (inputValue, optionLabel) => ({
      [valueKey]: inputValue,
      [labelKey]: optionLabel,
    }),
    [labelKey, valueKey]
  )

  const getValue = React.useCallback(
    value => {
      return value ? { [valueKey]: value, [labelKey]: value } : null
    },
    [labelKey, valueKey]
  )

  //* custom functions
  const handleCreateOption = React.useCallback(
    newValue => {
      if (selectRef.current) {
        const option = onCreateOption(newValue)
        selectRef.current?.selectOption(option)

        onCreateCallback?.(option)
      }
    },
    [onCreateCallback, onCreateOption]
  )

  const handleChange = React.useCallback(
    onChange =>
      (option: Option, { action }: ActionMeta<Option>) => {
        switch (action) {
          case 'select-option':
            onChange((option as Option)[valueKey] ?? '')
            onSelectCallback?.(option as Option)
            break
          case 'remove-value':
            onChange((option as Option)[valueKey] ?? '')
            break
          case 'clear':
            onChange('')
            break
          default:
            break
        }
      },
    [onSelectCallback, valueKey]
  )

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value }, formState: { errors } }) => {
        const hasError = !!errors[name]

        return (
          <FormControl
            fullWidth
            margin="normal"
            className={cx(className, classes.selectRoot, 'form-control', { 'form-control-error': hasError })}
            error={hasError}
            disabled={disabled}
            required={required}
          >
            {label && (
              <InputLabel htmlFor={name} shrink className={classes.bootstrapFormLabel}>
                {label}
              </InputLabel>
            )}
            <div className={classes.selectInput}>
              <SelectComponent
                ref={selectRef}
                className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
                classNamePrefix="react-select"
                defaultOptions={defaultOptions}
                instanceId={`rs-${name}`}
                inputId={name}
                hasError={hasError}
                isClearable={isClearable}
                isDisabled={disabled}
                value={getValue(value)}
                onBlur={onBlur}
                onChange={handleChange(onChange)}
                noOptionsMessage={noOptionsMessage}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                loadingMessage={loadingMessage}
                loadOptions={loadOptions(value)}
                openMenuOnFocus
                menuPortalTarget={document.body}
                menuShouldBlockScroll={menuShouldBlockScroll}
                placeholder={formatMessage(formSelectMessages.creatableSelectPlaceholder)}
                // only for CreatableSelect
                getNewOptionData={getNewOptionData}
                onCreateOption={handleCreateOption}
                formatCreateLabel={promptTextCreator}
                filterOption={customFilterOption}
                isValidNewOption={isValidNewOption}
                isLoading={isLoading}
                {...componentOverridesProp}
                // custom style
                styles={customReactSelectStyles}
              />
            </div>
            {errors[name]?.message && <FormHelperText>{errors[name]?.message}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}

ReactHookFormSearchSelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.node.isRequired,
  labelKey: PropTypes.string,
  menuShouldBlockScroll: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onCreateCallback: PropTypes.func,
  onCreateOption: PropTypes.func.isRequired,
  onEmptyCreateText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  valueKey: PropTypes.string,
  SelectOptionComponent: PropTypes.elementType,
}
