import React from 'react'

import { components as reactSelectComponents, GroupBase, OptionProps } from 'react-select'

import { Typography } from '@components/ui/Typography'

import { LedgerNumberSelectOptionValue } from './types'

// need to customize the recommended option
const CustomSelectOption = reactSelectComponents.Option

interface CustomLedgerNumberOptionProps
  extends OptionProps<LedgerNumberSelectOptionValue, false, GroupBase<LedgerNumberSelectOptionValue>> {
  data: LedgerNumberSelectOptionValue
}

export function CustomLedgerNumberOption({ children, ...rest }: CustomLedgerNumberOptionProps) {
  // "isNew" only set when it is a new creatable option
  const { isNew, name } = rest.data.data
  return (
    <CustomSelectOption {...rest}>
      <div className="custom-option">
        <Typography size="400-sm">{children}</Typography>
        {!isNew && <Typography size="400-xs">{name}</Typography>}
      </div>
    </CustomSelectOption>
  )
}
