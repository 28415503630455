import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { parseFiltersFromUrlWorkerRunner } from '@webWorkers'

import {
  downloadFileWithURL,
  getActiveCompanyId,
  getErrorMessage,
  getPartnerParamsFromStore,
  getRFFFormErrors,
  getUrlFilterOptionsFromStore,
} from '@helpers'

import { TYPING_INTERVAL } from '@constants'

import { BackgroundPartnerEmailProcess, BackgroundPartnerMergeProcess } from '../background/process'
import filtersActions from '../filters/actions'
import actions from './actions'
import * as api from './api'

function* initProviderPartnersPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    //* >> sync from url to store
    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* call worker
    const { filters, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield put(filtersActions.initPartnerListFiltersFromUrl.request({ filters: { ...filters, type: 'provider' } }))
    //* << sync from store to url
    const storedFilters = yield select(getPartnerParamsFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
    yield call(resolve, validationLevel)
    yield put(actions.fetchPartners.request())
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    yield put(actions.fetchPartners.failure(errorMsg))
  }
}

function* initCustomerPartnersPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    //* >> sync from url to store
    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* call worker
    const { filters, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield put(filtersActions.initPartnerListFiltersFromUrl.request({ filters: { ...filters, type: 'customer' } }))
    //* << sync from store to url
    const storedFilters = yield select(getPartnerParamsFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
    yield call(resolve, validationLevel)
    yield put(actions.fetchPartners.request())
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    yield put(actions.fetchPartners.failure(errorMsg))
  }
}

function* fetchPartnersSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getPartnerParamsFromStore, { withType: true, withPage: true })
    const response = yield call(api.fetchPartners, companyId, params)
    yield put(actions.fetchPartners.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPartners.failure(errorMsg))
  }
}

function* fetchPartnerDetailsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchPartnerDetails, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updatePartnerSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.updatePartner, payload) // payload contains company_id
    yield put(actions.updatePartner.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield put(actions.updatePartner.failure())
    yield call(reject, formErrors)
  }
}

function* updatePartnerCalculationBaseSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(api.updatePartnerCalculationBase, payload)
    resolve()
  } catch (error) {
    // reject errors
    reject(error.message)
  }
}

function* createPartnerSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.createPartner, payload) // payload contains company_id
    yield put(actions.createPartner.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield put(actions.createPartner.failure())
    yield call(reject, formErrors)
  }
}

function* removePartnerSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(api.removePartner, payload) // payload contains companyId
    yield put(actions.removePartner.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* mergeAndRemovePartnerSaga({ payload, meta: { resolve, reject } }) {
  try {
    //! NOTE backgroundJob
    const response = yield call(api.mergeAndRemovePartner, payload) // payload contains companyId
    yield call(BackgroundPartnerMergeProcess.start, {
      id: response.data.id,
      company_id: payload.companyId,
    })
    yield put(actions.mergeAndRemovePartner.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* exportPartnersSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const { ordering, pageSize, ...params } = yield select(getPartnerParamsFromStore) // cleanup params
    const response = yield call(api.exportPartners, companyId, params)
    const response2 = yield call(BackgroundPartnerEmailProcess.start, {
      id: response.data.id,
      company_id: companyId,
    })
    // download file when it not resolved by send-in-email cancel
    if (!response2.data.send_email) {
      yield call(downloadFileWithURL, response2) // download file
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* abortExportPartnersSaga() {
  yield call(BackgroundPartnerEmailProcess.stop)
}

// PARTNER field search
function* searchPartnersSaga({ payload, meta: { resolve } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.searchPartners, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    console.error('Search partners failed', error)
    yield call(resolve, []) // resolve with empty results on error
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.initProviderPartnersPageLoad.REQUEST, initProviderPartnersPageLoadSaga)
  yield takeLatest(actions.initCustomerPartnersPageLoad.REQUEST, initCustomerPartnersPageLoadSaga)
  yield takeLatest(actions.fetchPartnerDetails.REQUEST, fetchPartnerDetailsSaga)
  yield takeLatest(actions.updatePartner.REQUEST, updatePartnerSaga)
  yield takeLatest(actions.updatePartnerCalculationBase.REQUEST, updatePartnerCalculationBaseSaga)
  yield takeLatest(actions.createPartner.REQUEST, createPartnerSaga)
  yield takeLatest(actions.removePartner.REQUEST, removePartnerSaga)
  yield takeLatest(actions.mergeAndRemovePartner.REQUEST, mergeAndRemovePartnerSaga)
  yield takeLatest(
    [
      actions.fetchPartners.REQUEST,
      filtersActions.updatePartnerListFilters.REQUEST,
      filtersActions.resetPartnerListFilters.REQUEST,
      actions.removePartner.SUCCESS,
      actions.mergeAndRemovePartner.SUCCESS,
      actions.updatePage.REQUEST,
      actions.updateRowsPerPage.REQUEST,
      actions.updateOrder.REQUEST,
    ],
    fetchPartnersSaga
  )
  yield takeLatest(actions.exportPartners.REQUEST, exportPartnersSaga)
  yield takeLatest(actions.abortExportPartners.REQUEST, abortExportPartnersSaga)
  yield takeLatest(actions.searchPartners.REQUEST, searchPartnersSaga)
}
