import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

const LoaderContainer = styled.div`
  font-size: 14px;
  padding: 20px;
  display: flex;
  align-items: center;

  & .MuiCircularProgress-root {
    margin-right: 10px;
  }
`
interface LoaderProps {
  infoText: React.ReactChild
}

export function Loader({ infoText }: LoaderProps) {
  return (
    <LoaderContainer>
      <CircularProgress color="primary" size={20} />
      {infoText}
    </LoaderContainer>
  )
}

Loader.propTypes = {
  infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}
