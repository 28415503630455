import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  Button,
  CustomDialog,
  CustomDialogActions,
  CustomDialogBody,
  CustomDialogHeader,
  Typography,
} from '@components/ui'

import { SimpleNextMessage } from '@messages'

const StyledDialog = styled(CustomDialog)`
  width: 596px;

  ${Button} {
    width: 180px;
  }
`

interface WelcomeToTaskListDialogProps {
  companyType: string
  isOpen: boolean
  onClose: VoidFunction
}

/**
 * Dialog component visible to users who will see the task list by triggering a redirect flow in the app leading them to Pulse page
 *
 * @param {WelcomeToTaskListDialogProps} { isOpen, onClose }
 * @returns The dialog component
 */
function PureWelcomeToTaskListDialog({ onClose, isOpen, companyType }: WelcomeToTaskListDialogProps) {
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <CustomDialogHeader
        borderless
        title={<FormattedMessage id="setupTaskList.welcomeDialog.title" defaultMessage="Már csak néhány lépés 🤞" />}
      />

      <CustomDialogBody>
        <Typography size="400-sm" align="center">
          <FormattedMessage
            id="setupTaskList.welcomeDialog.description"
            defaultMessage="A profilod alapján a te vállalkozási formád: {companyType}. Az ilyen típusú vállalkozások otthona a Pulzus. Mielőtt elkezded használni a Pulzust, arra kérünk, hogy menj végig az alábbi lépéseken és add meg a vállalkozásoddal kapcsolatos információkat!"
            values={{
              companyType: <strong>{companyType}</strong>,
            }}
          />
        </Typography>
      </CustomDialogBody>
      <CustomDialogActions borderless>
        <Button variant="primaryContained" onClick={onClose}>
          {SimpleNextMessage}
        </Button>
      </CustomDialogActions>
    </StyledDialog>
  )
}

const WelcomeToTaskListDialog = connect(
  ({
    dashboard: {
      common: { company_types },
    },
    auth: {
      company: { data: company },
    },
  }: Store) => ({
    companyType: company_types.find(({ id }) => id === company.company_type)?.name ?? '',
  })
)(PureWelcomeToTaskListDialog)

export default WelcomeToTaskListDialog
