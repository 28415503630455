import React from 'react'

import styled from 'styled-components'

function BaseCreditCardLinkedRegularIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7292 10.625V9.09725H4.27083V13.6806C4.27083 13.8907 4.44271 14.0625 4.65278 14.0625H10.0697C10.1666 14.4895 10.3606 14.8796 10.6274 15.2084H4.65278C3.81011 15.2084 3.125 14.5232 3.125 13.6806V6.04169C3.125 5.19903 3.81011 4.51392 4.65278 4.51392H15.3472C16.1899 4.51392 16.875 5.19903 16.875 6.04169V10.8074C16.6125 10.6902 16.3217 10.625 16.0156 10.625H15.7292ZM15.7292 6.04169C15.7292 5.83162 15.5573 5.65975 15.3472 5.65975H4.65278C4.44271 5.65975 4.27083 5.83162 4.27083 6.04169V6.80558H15.7292V6.04169Z"
        fill="currentColor"
      />
      <path
        d="M5.41667 12.3438C5.41667 12.0263 5.67209 11.7709 5.98958 11.7709H7.13542C7.45291 11.7709 7.70833 12.0263 7.70833 12.3438C7.70833 12.6613 7.45291 12.9167 7.13542 12.9167H5.98958C5.67209 12.9167 5.41667 12.6613 5.41667 12.3438Z"
        fill="currentColor"
      />
      <path
        d="M13.474 11.25H12.7734C11.5869 11.25 10.625 12.2119 10.625 13.3985C10.625 14.585 11.5869 15.5469 12.7734 15.5469H13.474C13.7893 15.5469 14.0449 15.2913 14.0449 14.9759C14.0449 14.6606 13.7893 14.405 13.474 14.405H12.7736C12.2177 14.405 11.7671 13.9543 11.7671 13.3985C11.7671 12.8426 12.2177 12.392 12.7736 12.392H13.474C13.7893 12.392 14.0449 12.1363 14.0449 11.821C14.0449 11.5057 13.7893 11.25 13.474 11.25Z"
        fill="currentColor"
      />
      <path
        d="M14.939 14.9759C14.939 15.2913 15.1946 15.5469 15.5099 15.5469H16.2109C17.3975 15.5469 18.3594 14.585 18.3594 13.3985C18.3594 12.2119 17.3975 11.25 16.2109 11.25H15.5099C15.1946 11.25 14.939 11.5057 14.939 11.821C14.939 12.1363 15.1946 12.392 15.5099 12.392H16.2108C16.7667 12.392 17.2173 12.8426 17.2173 13.3985C17.2173 13.9543 16.7667 14.405 16.2108 14.405H15.5099C15.1946 14.405 14.939 14.6606 14.939 14.9759Z"
        fill="currentColor"
      />
      <path
        d="M12.7681 13.3985C12.7681 13.082 13.0246 12.8255 13.341 12.8255H15.6312C15.9476 12.8255 16.2041 13.082 16.2041 13.3985C16.2041 13.7149 15.9476 13.9714 15.6312 13.9714H13.341C13.0246 13.9714 12.7681 13.7149 12.7681 13.3985Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CreditCardLinkedRegularIcon = styled(BaseCreditCardLinkedRegularIcon)``
