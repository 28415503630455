import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'

import { KPIIndex } from './KPIIndex'

const KPIWrapperDiv = styled.div<{ $alignItems: 'center' | 'flex-start' }>`
  display: flex;
  padding: 10px;
  align-items: ${({ $alignItems }) => $alignItems};
  gap: 10px;
  min-height: 52px;

  & + & {
    margin-top: 5px;
    border-top: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  }
`
const KPIHeadingTypography = styled(Typography)`
  flex: 2;
  display: flex;
  align-items: center;
`

const KPIAmountDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
`

const ColoredTypography = styled(Typography)<{ $color: string }>`
  color: ${({ $color }) => $color};
  line-height: ${({ theme }) => theme.typography['700-sm'].lineHeight};
`

interface KPICardProps {
  amount: React.ReactChild
  amountColor: string
  hideIndex?: boolean
  indexValue?: Nullable<number>
  title: React.ReactChild
}

export function KPICard({ amount, amountColor, hideIndex = false, indexValue, title }: KPICardProps) {
  return (
    <KPIWrapperDiv $alignItems={hideIndex ? 'center' : 'flex-start'}>
      <KPIHeadingTypography size="700-sm" color="gray-80">
        {title}
      </KPIHeadingTypography>

      <KPIAmountDiv>
        <ColoredTypography $color={amountColor} size="700-md">
          {amount}
        </ColoredTypography>

        {!hideIndex && <KPIIndex value={indexValue} />}
      </KPIAmountDiv>
    </KPIWrapperDiv>
  )
}

KPICard.propTypes = {
  amount: PropTypes.node.isRequired,
  amountColor: PropTypes.string.isRequired,
  hideIndex: PropTypes.bool,
  indexValue: PropTypes.number,
  title: PropTypes.node.isRequired,
}
