import React from 'react'

import styled from 'styled-components'

import { CircleCheckRegularIcon, CircleXmarkRegularIcon } from '@components/ui/svgIcons'
import { Typography } from '@components/ui/Typography'

const GreenCheckIcon = styled(CircleCheckRegularIcon)`
  color: ${({ theme }) => theme.colors.success[40]};
`

const ListItem = styled(Typography).attrs({ as: 'li', size: '400-sm' })`
  display: flex;
  gap: 16px;

  svg {
    flex-shrink: 0;
  }

  & + & {
    margin-top: 16px;
  }
`

interface FeatureListItemProps {
  children: StringOrMessage
  isExcluded?: boolean
}

export function FeatureListItem({ children, isExcluded = false }: FeatureListItemProps) {
  return (
    <ListItem>
      {isExcluded ? <CircleXmarkRegularIcon /> : <GreenCheckIcon />}
      {children}
    </ListItem>
  )
}
