import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Button, Typography } from '@components/ui'

import { KpiSkeletonBox, SkeletonBox } from './styles'

const RetryButton = styled(Button)`
  padding: 0;
  font-weight: 600;

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    text-decoration: underline;
  }
`

const LoadingWrapperSection = styled.section`
  height: 57px;
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[0]};
  border-radius: 4px;

  & + & {
    margin-top: 20px;
  }
`

enum ReportLayouts {
  ACCORDION = 'accordion',
  ASYNC_ACCORDION_OPEN = 'asyncAccordionOpen',
  THREE_COLUMN_WIDGET = 'threeColumnWidget',
}
interface LoadingReportProps {
  error?: Error
  layout?: ReportLayouts
  name: React.ReactNode
  pastDelay: boolean
  retry: (...args: unknown[]) => void
}

function LoadingReport({ error, layout, name, pastDelay, retry }: LoadingReportProps) {
  if (error) {
    console.log(name, error)
    return (
      <LoadingWrapperSection>
        <Typography color="error" size="400-md">
          <FormattedMessage
            id="pulse.report.error"
            defaultMessage="Sajnáljuk, a(z) {name} betöltése nem sikerült. <button>Próbáld meg újra!</button>"
            values={{
              name,
              button: (chunks: React.ReactChild) => <RetryButton onClick={retry}>{chunks}</RetryButton>,
            }}
          />
        </Typography>
      </LoadingWrapperSection>
    )
  }
  if (pastDelay) {
    switch (layout) {
      case ReportLayouts.THREE_COLUMN_WIDGET:
        return (
          <KpiSkeletonBox as="section">
            <SkeletonBox as="article" />
            <SkeletonBox as="article" />
            <SkeletonBox as="article" />
          </KpiSkeletonBox>
        )
      case ReportLayouts.ASYNC_ACCORDION_OPEN:
        return <SkeletonBox $height={160} as="section" />
      case ReportLayouts.ACCORDION:
      default:
        return <SkeletonBox as="section" />
    }
  }
  return null
}

LoadingReport.propTypes = {
  error: PropTypes.instanceOf(Error),
  layout: PropTypes.oneOf(Object.values(ReportLayouts)),
  name: PropTypes.node.isRequired,
  pastDelay: PropTypes.bool.isRequired,
}

type LoadingReportCommonProps = Omit<LoadingReportProps, 'children' | 'name'>

export function LoadingVatPositionReport(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.report.name.vatPosition" defaultMessage="ÁFA pozíció" />}
    />
  )
}

export function LoadingWorkoutReport(props: LoadingReportCommonProps) {
  return (
    <LoadingReport {...props} name={<FormattedMessage id="pulse.report.name.workout" defaultMessage="Behajtás" />} />
  )
}

export function LoadingBalanceReport(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.report.name.balance" defaultMessage="Banki egyenlegek" />}
    />
  )
}

export function LoadingSetupTaskList(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.report.name.setupTaskList" defaultMessage="Személyre szabás" />}
    />
  )
}

export function LoadingTaxationAamReport(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.report.name.taxation.aam" defaultMessage="AAM keret" />}
    />
  )
}

export function LoadingTaxationAtalanyReport(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.report.name.taxation.atalany" defaultMessage="Átalányadó keret" />}
    />
  )
}

export function LoadingTaxationKataReport(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.report.name.taxation.kata" defaultMessage="KATA keret" />}
    />
  )
}

export function LoadingTaxationTaxCalculationReport(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.report.name.taxation.taxCalculation" defaultMessage="Adókalkuláció" />}
    />
  )
}

export function LoadingTaxationTaskReport(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.report.name.taxation.task" defaultMessage="Határidős feladat" />}
    />
  )
}

export function LoadingWidget(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.kpiWidget.name" defaultMessage="KPI adatok" />}
      layout={ReportLayouts.THREE_COLUMN_WIDGET}
    />
  )
}

export function LoadingMonthlyChart(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.monthlyChart.name" defaultMessage="Kiadások és bevételek" />}
      layout={ReportLayouts.ASYNC_ACCORDION_OPEN}
    />
  )
}

export function LoadingChat(props: LoadingReportCommonProps) {
  return (
    <LoadingReport
      {...props}
      name={<FormattedMessage id="pulse.chatWidget.name" defaultMessage="Chat" />}
      layout={ReportLayouts.THREE_COLUMN_WIDGET}
    />
  )
}
