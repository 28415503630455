import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import { paymentActions } from '@services'

import { bindActionToPromise, cancellablePromise, getIdsFromList, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from '@hooks'

import { CreatePaymentDialog } from '@components/PaymentEditor'

import { ALL_PAYMENT_TRANSACTION_TYPES, PAYMENT_FLOW, PaymentDialogState } from '@constants'

import { CreatePaymentContext } from './contexts'
import { CreatePaymentProviderProps, CreatePaymentProviderState, CreatePaymentResult } from './types'

function PureCreatePaymentProvider({
  children,
  items,
  loadData,
  saveData,
  transactionType,
}: CreatePaymentProviderProps) {
  const [state, setState] = React.useState<CreatePaymentProviderState>({
    data: null,
    dialogState: PaymentDialogState.FORM,
    flow: transactionType === ALL_PAYMENT_TRANSACTION_TYPES.income ? PAYMENT_FLOW.express : PAYMENT_FLOW.transfer,
    loading: false,
    open: false,
    responseData: null,
  })
  const cPromiseRef = useCancellablePromiseRef<PaymentSetupResponseData>()
  const { setErrorAlert } = useAlertDispatch()

  function startTransferPayment() {
    startPayment(PAYMENT_FLOW.transfer)
  }

  function startExpressPayment() {
    startPayment(PAYMENT_FLOW.express)
  }

  function setFlow(flow: PaymentFlowType) {
    setState(prevState => ({ ...prevState, flow }))
  }

  async function startPayment(flow: PaymentFlowType) {
    setState(prevState => ({ ...prevState, loading: true, flow, dialogState: PaymentDialogState.FORM }))

    try {
      cPromiseRef.current = cancellablePromise(
        loadData({
          ids: getIdsFromList(items),
          transactionType,
        })
      )
      const data = await cPromiseRef.current.promise
      setState(prevState => ({ ...prevState, data, loading: false, open: true }))
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setErrorAlert(errorMessage)
      }
      setState(prevState => ({ ...prevState, loading: false }))
    }
  }

  function handleDialogClose() {
    setState(prevState => ({ ...prevState, open: false }))
  }

  function handleCallOfAttention() {
    setState(prevState => ({ ...prevState, dialogState: PaymentDialogState.CALL_OF_ATTENTION }))
  }

  function onSubmitSuccess(response: CreatePaymentResult) {
    setState(prevState => ({
      ...prevState,
      dialogState: prevState.flow === PAYMENT_FLOW.transfer ? PaymentDialogState.SUMMARY : PaymentDialogState.RESPONSE,
      responseData: response,
    }))
  }

  // do not need to use memo on the contextValue because "state" will trigger update anyway
  return (
    <CreatePaymentContext.Provider
      value={{
        startExpressPayment,
        startTransferPayment,
        state,
      }}
    >
      {children}
      <CreatePaymentDialog
        onCallOfAttention={handleCallOfAttention}
        onClose={handleDialogClose}
        onSubmit={saveData}
        onSubmitSuccess={onSubmitSuccess}
        setFlow={setFlow}
        transactionType={transactionType}
      />
    </CreatePaymentContext.Provider>
  )
}

PureCreatePaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired).isRequired,
  loadData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  transactionType: PropTypes.oneOf(Object.values(ALL_PAYMENT_TRANSACTION_TYPES)).isRequired,
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return {
    loadData: bindActionToPromise(dispatch, paymentActions.initializePayment.request),
    saveData: bindActionToPromise(dispatch, paymentActions.createPaymentV2.request),
  }
}

export const CreatePaymentProvider = connect<unknown, Pick<CreatePaymentProviderProps, 'loadData' | 'saveData'>>(
  null,
  mapDispatchToProps
)(PureCreatePaymentProvider)

CreatePaymentProvider.displayName = 'CreatePaymentProvider'

export const CreatePaymentConsumer = CreatePaymentContext.Consumer
