import { useLocation, useParams } from 'react-router'

import { parseIfExists } from '@helpers/invoice'

import { InvoiceType } from '@constants/common'

interface PagingCounterHookProps {
  currentPage: number
  invoices: ExpenseListData[]
  pageSize: number
  totalCount: number
}

/**
 * A custom hook that calculates the current position in the invoice list and determines whether
 * the paging counter should be shown.
 *
 * The counter should be shown if the user navigates from the expense list to the expense details page
 */
export function usePagingCounter({ currentPage, invoices, pageSize, totalCount }: PagingCounterHookProps) {
  // Extract invoice ID from URL params
  const { invoice_id: invoiceIdParam } = useParams<{ invoice_id?: string }>()

  // Get location object to determine if current page is an expense page
  const location = useLocation()
  const isExpensePage = location.pathname.includes(InvoiceType.EXPENSE)

  // Parse the invoice ID, if exists
  const invoiceId = parseIfExists(invoiceIdParam)

  // Calculate current position in the list
  const currentPosition = (currentPage - 1) * pageSize + (invoices.findIndex(invoice => invoice.id === invoiceId) + 1)

  // Determine whether to show the paging counter
  const showPagingCounterCondition = isExpensePage && currentPosition != null && invoices.length !== 0

  return {
    currentPosition,
    showPagingCounterCondition,
    totalCount,
  }
}
