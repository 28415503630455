import { createGlobalStyle } from 'styled-components'

import { DIALOG_CLASS_NAMES, THEME } from '@constants'

// TODO: replace MUIs CssBaseline below
export const GlobalStyle = createGlobalStyle`
  .${DIALOG_CLASS_NAMES.body},
  .${DIALOG_CLASS_NAMES.html} {
    overflow: hidden;
  }

  html {
    scroll-padding-top: ${THEME.appBarHeight}px;
  }

  body {
    font-size: 1rem;
  }

  body, input, textarea, keygen, select, button {
    font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
  }

  button {
    margin: 0;
  }
`
