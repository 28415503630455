import React from 'react'

import { FormattedMessage } from 'react-intl'

export const FileUploadTextMessage = (
  <FormattedMessage id="fileUpload.texts.upload" defaultMessage="Húzd ide a fájlt, vagy válaszd ki manuálisan." />
)

export const FileUploadSuggestionMessage = (
  <FormattedMessage
    id="fileUpload.texts.suggestion"
    defaultMessage="Egy számla feldolgozása 10-15 másodperc is lehet. Ha sok számlád van, kényelmesebb, ha csatolmányként elküldöd őket e-mailben."
  />
)

export const MultipleFileUploadErrorMessage = (
  <FormattedMessage
    id="fileUpload.errors.multipleFile"
    defaultMessage="Egyszerre csak egy fájlt tudsz itt feltölteni. Ha a QUiCK-es e-mail címedre küldesz üzenetet, abba viszont több file-t is becsatolhatsz!"
  />
)
