import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button, IconButton } from '@components/ui/Buttons'
import { AssistantIcon } from '@components/ui/svgIcons'

import { generateMaxHeightBreakPoint } from '@constants'

import { INTERCOM_LAUNCHER_CLASS_NAME } from './constant'
import { IntercomUnreadCountBadge } from './IntercomUnreadCountBadge'
import { useIntercomUnreadMessagesCount } from './QuickIntercomProvider'
import { useIntercomProps } from './useIntercomProps'

const messages = defineMessages({
  text: { id: 'navLink.assistant', defaultMessage: 'Súgó' },
})

const StyledIconButton = styled(IconButton)`
  height: ${({ theme }) => theme.appBarHeight}px;
  padding: 0;
  width: 50px;
  border-radius: 0;

  &::before {
    border: none;
  }

  ${generateMaxHeightBreakPoint('medium')} {
    height: ${({ theme }) => theme.appBarHeightMedium}px;
  }
`

// styled as old mui button to match styles with logout button in mobile view
const StyledButton = styled(Button)`
  justify-content: flex-start;
  font-weight: 500;
  padding: 0 8px;
  font-size: 1rem;
  height: 50px;
`

interface PrivateNavbarIntercomLauncherButtonProps {
  className?: string
  showIconButton?: boolean
}

function QuickCustomIntercomLauncherIconButton(props: { className?: string; onClick?: VoidFunction }) {
  const { formatMessage } = useIntl()
  const count = useIntercomUnreadMessagesCount()
  return (
    <StyledIconButton title={formatMessage(messages.text)} {...props}>
      <IntercomUnreadCountBadge count={count} />
      <AssistantIcon color="currentColor" />
    </StyledIconButton>
  )
}

export function PrivateNavbarIntercomLauncherButton({
  className,
  showIconButton = false,
}: PrivateNavbarIntercomLauncherButtonProps) {
  const { formatMessage } = useIntl()
  const props = useIntercomProps(cx(className, { [INTERCOM_LAUNCHER_CLASS_NAME]: window.intercomAppId }))

  if (showIconButton) {
    return <QuickCustomIntercomLauncherIconButton {...props} />
  }

  return (
    <StyledButton fullWidth {...props}>
      {formatMessage(messages.text)}
    </StyledButton>
  )
}

PrivateNavbarIntercomLauncherButton.propTypes = {
  className: PropTypes.string,
  showIconButton: PropTypes.bool,
}
