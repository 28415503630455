import PropTypes from 'prop-types'

import { companyPlanTiers, FeaturePermissons, PagePermissions } from './config'
import { checkPermission, withCompanyPlan } from './helpers'

type RuleSet = {
  static?: Array<PagePermissions | FeaturePermissons>
  dynamic?: Record<string, (payload: unknown) => boolean>
}

type PermissionRules = {
  pro: RuleSet
  finance: RuleSet
  base: RuleSet
}

interface PermissionProps {
  yes?: (payload?: any) => JSX.Element | null
  no?: (payload?: any) => JSX.Element | null
  level: 'base' | 'pro' | 'finance'
  rules?: PermissionRules
  perform: PagePermissions | FeaturePermissons
  data?: unknown
}

export function Permission({
  level,
  rules = companyPlanTiers,
  perform,
  data,
  yes = () => null,
  no = () => null,
  ...rest
}: PermissionProps) {
  return checkPermission(rules, level, perform, data) ? yes(rest) : no(rest)
}

Permission.propTypes = {
  yes: PropTypes.func,
  no: PropTypes.func,
  level: PropTypes.oneOf(['base', 'pro', 'finance']),
  perform: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.bool.isRequired,
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]),
  rules: PropTypes.shape({
    base: PropTypes.shape({
      static: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
    finance: PropTypes.shape({
      static: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
    pro: PropTypes.shape({
      static: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
  }),
}

// usage example
//   <Can
//     level={user.level}
//     perform="settings-page:routes"
//     yes={withLayout(
//       () => (
//         <AdminSettingsRoutes {...props} />
//       ),
//       AdminSettingsLayout
//     )}
//     no={() => <UserSettingsRoutes {...props} />}
//   />

export const PlanPermission = withCompanyPlan(Permission) as any // TODO fix type later
