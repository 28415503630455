import React from 'react'

import styled from 'styled-components'

function PureCircleXmarkRegularIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M10 3.5C11.7239 3.5 13.3772 4.18482 14.5962 5.40381C15.8152 6.62279 16.5 8.27609 16.5 10C16.5 11.7239 15.8152 13.3772 14.5962 14.5962C13.3772 15.8152 11.7239 16.5 10 16.5C8.27609 16.5 6.62279 15.8152 5.40381 14.5962C4.18482 13.3772 3.5 11.7239 3.5 10C3.5 8.27609 4.18482 6.62279 5.40381 5.40381C6.62279 4.18482 8.27609 3.5 10 3.5ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM7.46875 7.46875C7.175 7.7625 7.175 8.2375 7.46875 8.52812L8.9375 9.99687L7.46875 11.4656C7.175 11.7594 7.175 12.2344 7.46875 12.525C7.7625 12.8156 8.2375 12.8187 8.52812 12.525L9.99687 11.0562L11.4656 12.525C11.7594 12.8187 12.2344 12.8187 12.525 12.525C12.8156 12.2312 12.8187 11.7562 12.525 11.4656L11.0562 9.99687L12.525 8.52812C12.8187 8.23438 12.8187 7.75938 12.525 7.46875C12.2312 7.17812 11.7562 7.175 11.4656 7.46875L9.99687 8.9375L8.52812 7.46875C8.23438 7.175 7.75938 7.175 7.46875 7.46875Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CircleXmarkRegularIcon = styled(PureCircleXmarkRegularIcon)``
