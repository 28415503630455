import React from 'react'
import PropTypes from 'prop-types'

import { ErrorBoundary } from '@sentry/react'
import Helmet from 'react-helmet'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'

import { hasActiveSubscription } from '@helpers'

import { CreateCompanyDialogProvider } from '@contexts'

import CompanyDialogsController from '@components/CompanyDialogsController'
import { InitializeUser } from '@oldComponents/InitializeUser'
import { PrivateNavbar } from '@oldComponents/PrivateNavbar'
import { AsyncPageLoadingComponent } from '@oldComponents/ui'

import { FeatureFlags, generateMaxHeightBreakPoint } from '@constants'

import { featureFlagEnabledForUser } from '@permissions'

import DashboardCompanyRoutes from './DashboardCompanyRoutes'
import { DashboardErrorBoundary } from './DashboardErrorBoundary'
import { NavigateToDefaultRoute } from './NavigateToDefaultRoute'

// code splitting - async component import
const AsyncDashboardProfilePage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardProfilePage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncNotFoundPrivatePage = Loadable({
  loader: () => import('@components/pages/NotFoundPage/NotFoundPrivatePage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDashboardNoCompanyPage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardNoCompanyPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDashboardSelectCompanyPage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardSelectCompanyPage'),
  loading: AsyncPageLoadingComponent,
})

const WrapperDiv = styled.div`
  min-height: 500px;
  color: ${({ theme }) => theme.colors.common.offBlack};
  padding-top: ${({ theme }) => theme.appBarHeight}px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${generateMaxHeightBreakPoint('medium')} {
    padding-top: ${({ theme }) => theme.appBarHeightMedium}px;
  }
`

interface DashboardRoutesProps {
  company: Nullable<Company>
  hasSubscription: boolean
  isForceChatPageOnlyEnabled: boolean
  isCompanySelectRouteAvailable: boolean
  isUserDetailsFetched: boolean
  lang: string
}

function PureDashboardRoutes({
  company,
  hasSubscription,
  isForceChatPageOnlyEnabled,
  isCompanySelectRouteAvailable,
  isUserDetailsFetched,
  lang,
}: DashboardRoutesProps) {
  return isUserDetailsFetched ? (
    <CreateCompanyDialogProvider>
      <WrapperDiv>
        <Helmet htmlAttributes={{ lang }} />
        <PrivateNavbar hasSubscription={hasSubscription} withoutLinks={isForceChatPageOnlyEnabled} />
        <ErrorBoundary fallback={DashboardErrorBoundary}>
          <Routes>
            <Route index element={<NavigateToDefaultRoute />} />
            <Route path="profile" element={<AsyncDashboardProfilePage />} />
            <Route path="no-company" element={<AsyncDashboardNoCompanyPage />} />
            <Route
              path="select-company"
              element={
                isCompanySelectRouteAvailable ? (
                  <AsyncDashboardSelectCompanyPage />
                ) : (
                  <Navigate to="no-company" replace />
                )
              }
            />
            <Route
              path=":company_id/*"
              element={<DashboardCompanyRoutes company={company} hasSubscription={hasSubscription} />}
            />
            <Route path="*" element={<AsyncNotFoundPrivatePage />} />
          </Routes>
        </ErrorBoundary>
        <CompanyDialogsController />
      </WrapperDiv>
    </CreateCompanyDialogProvider>
  ) : (
    <InitializeUser />
  )
}

PureDashboardRoutes.propTypes = {
  company: PropTypes.object as PropTypes.Requireable<Company>,
  hasSubscription: PropTypes.bool.isRequired,
  isCompanySelectRouteAvailable: PropTypes.bool.isRequired,
  isUserDetailsFetched: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
}

export const DashboardRoutes = connect((state: Store) => ({
  company: state.auth.company.data,
  hasSubscription: hasActiveSubscription(state),
  isCompanySelectRouteAvailable: Boolean(state.auth.companies.length),
  isForceChatPageOnlyEnabled: featureFlagEnabledForUser(state, FeatureFlags.FORCE_CHAT_PAGE_ONLY),
  isUserDetailsFetched: Boolean(state.auth.user),
  lang: state.locale.language,
}))(PureDashboardRoutes)

DashboardRoutes.displayName = 'DashboardRoutes'
