import { ZoomOrientation } from '@components/DocViewer/constant'

export enum DocViewerPDFActions {
  SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL',
  SET_PDF_PAGINATED = 'SET_PDF_PAGINATED',
  SET_NUM_PAGES = 'SET_NUM_PAGES',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  CHANGE_ZOOM_ORIENTATION = 'CHANGE_ZOOM_ORIENTATION',
}

interface SetZoomLevel {
  type: DocViewerPDFActions.SET_ZOOM_LEVEL
  value: number
}

interface SetPDFPaginated {
  type: DocViewerPDFActions.SET_PDF_PAGINATED
  value: boolean
}

interface SetNumPages {
  type: DocViewerPDFActions.SET_NUM_PAGES
  value: number
}

interface SetCurrentPage {
  type: DocViewerPDFActions.SET_CURRENT_PAGE
  value: number
}

interface ChangeZoomOrientation {
  type: DocViewerPDFActions.CHANGE_ZOOM_ORIENTATION
  defaultZoomLevel: number
  zoomOrientation: ZoomOrientation
}

export type PDFStateActions = SetZoomLevel | SetPDFPaginated | SetNumPages | SetCurrentPage | ChangeZoomOrientation

export function setZoomLevel(value: number): SetZoomLevel {
  return {
    type: DocViewerPDFActions.SET_ZOOM_LEVEL,
    value,
  }
}

export function setPDFPaginated(value: boolean): SetPDFPaginated {
  return {
    type: DocViewerPDFActions.SET_PDF_PAGINATED,
    value,
  }
}

export function setNumPages(value: number): SetNumPages {
  return {
    type: DocViewerPDFActions.SET_NUM_PAGES,
    value,
  }
}

export function setCurrentPage(value: number): SetCurrentPage {
  return {
    type: DocViewerPDFActions.SET_CURRENT_PAGE,
    value,
  }
}

export function changeZoomOrientation({
  defaultZoomLevel,
  zoomOrientation,
}: {
  defaultZoomLevel: number
  zoomOrientation: ZoomOrientation
}): ChangeZoomOrientation {
  return {
    type: DocViewerPDFActions.CHANGE_ZOOM_ORIENTATION,
    defaultZoomLevel,
    zoomOrientation,
  }
}
