import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getDefaultCurrencyName } from '@helpers/currencies'

import { Currency } from '@components/ui/FormattedTexts'
import { Typography } from '@components/ui/Typography'

import { useCalculateTransactionsFieldTotal } from './paymentHooks'

const StyledTypography = styled(Typography)`
  padding-right: 20px;
`

interface TransactionsTotalAmountProps {
  className?: string
  companyDefaultCurrencyName: string
  transactions: PaymentTransaction[]
}

function PureTransactionsTotalAmount({
  className,
  companyDefaultCurrencyName,
  transactions,
}: TransactionsTotalAmountProps) {
  const [totalValue, totalCurrency] = useCalculateTransactionsFieldTotal(transactions, companyDefaultCurrencyName)

  return (
    <StyledTypography className={className} size="heading-5" align="right">
      <FormattedMessage
        id="paymentEditor.total"
        defaultMessage="Összesen: {value}"
        values={{
          value: <Currency value={totalValue} currency={totalCurrency} />,
        }}
      />
    </StyledTypography>
  )
}

export const TransactionsTotalAmount = connect((state: Store) => ({
  companyDefaultCurrencyName: getDefaultCurrencyName(state),
}))(PureTransactionsTotalAmount)

TransactionsTotalAmount.displayName = 'TransactionsTotalAmount'
