import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 20,
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.grayscale.light,
    flex: 1,
  },
  inner: {
    display: 'inline-block',
    paddingLeft: '1em',
    paddingRight: '1em',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: theme.palette.grayscale.inputLabel,
  },
}))

export default function FormDivider() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.divider} />
      <div className={classes.inner}>
        <FormattedMessage id="ui.divider" defaultMessage="vagy" />
      </div>
      <div className={classes.divider} />
    </div>
  )
}
