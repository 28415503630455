import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

import { generateMaxHeightBreakPoint } from '@constants'

import { FileDropzoneFontSizes, FileDropzoneStyleProps, FileDropzoneVariants } from './types'

// TODO: theme type
export const styles: Styles<Theme, FileDropzoneStyleProps> = (theme: any) => ({
  dropzone: {
    border: `1px dashed ${theme.palette.primary.main}`,
    padding: 10,
    minHeight: ({ variant }) => (variant === FileDropzoneVariants.SMALL ? 110 : 190),
    backgroundColor: ({ variant }) =>
      variant === FileDropzoneVariants.SMALL ? theme.palette.grayscale.inputBackground : 'rgba(243, 243, 243, 0.4)',
    borderRadius: 4,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    '&.disabled': {
      backgroundColor: 'rgba(220, 52, 22, 0.1)',
    },
  },
  uploadBox: {
    display: 'flex',
    flexDirection: ({ variant }) => (variant === FileDropzoneVariants.SMALL ? 'row' : 'column'),
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    gap: 10,
    '& > p': {
      fontSize: ({ fontSize }) => (fontSize === FileDropzoneFontSizes.DEFAULT ? 12 : 16),
      fontWeight: 400,
      lineHeight: ({ fontSize }) => (fontSize === FileDropzoneFontSizes.DEFAULT ? '16.34px' : '26px'),
      textAlign: ({ variant }) => (variant === FileDropzoneVariants.SMALL ? 'left' : 'center'),
    },

    '&.small': {
      display: 'grid',
      gridTemplateAreas: '"text text text" "left middle right"',

      '& :first-child': {
        gridArea: 'text',
      },
      '& :nth-child(2)': {
        gridArea: 'middle',
        alignSelf: 'center',
      },
      '& :nth-child(3):last-child': {
        gridArea: 'left',
        alignSelf: 'center',
        display: 'flex',
      },
    },
  },
  initialView: {
    '& > img': {
      [generateMaxHeightBreakPoint('medium')]: {
        height: 70,
      },
    },
    '& > p:first-of-type': {
      fontWeight: 700,
    },
    '& > p:last-of-type:not(p:first-of-type)': {
      fontSize: ({ fontSize }) => (fontSize === FileDropzoneFontSizes.DEFAULT ? 12 : 14),
      lineHeight: ({ fontSize }) => (fontSize === FileDropzoneFontSizes.DEFAULT ? '16.34px' : '22.76px'),
    },
    '& > label > span': {
      padding: '8px 10px',
    },
    '& > label span': {
      fontSize: ({ fontSize }) => (fontSize === FileDropzoneFontSizes.DEFAULT ? 14 : 15),
      lineHeight: '20px',
    },
  },
})
