import React from 'react'

import styled from 'styled-components'

function BaseCircleCheckIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M7.61875 10.6187C7.27813 10.9594 6.72187 10.9594 6.38125 10.6187L4.38125 8.61875C4.04062 8.27812 4.04062 7.72187 4.38125 7.38125C4.72187 7.04062 5.27813 7.04062 5.61875 7.38125L7 8.7625L10.3813 5.38125C10.7219 5.04062 11.2781 5.04062 11.6187 5.38125C11.9594 5.72187 11.9594 6.27813 11.6187 6.61875L7.61875 10.6187ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1.5C4.40937 1.5 1.5 4.40937 1.5 8C1.5 11.5906 4.40937 14.5 8 14.5C11.5906 14.5 14.5 11.5906 14.5 8C14.5 4.40937 11.5906 1.5 8 1.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CircleCheckIcon = styled(BaseCircleCheckIcon)``
