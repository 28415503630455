import React from 'react'

import { defineMessage, defineMessages, FormattedMessage } from 'react-intl'

import { StrongTextValues } from '@helpers'

import { IntercomInheritLink } from '@components/Intercom'
import LightTooltip from '@oldComponents/ui/LightTooltip'

import { StyledLabelInfoIcon } from './elements/styles'

export const TaxationTaskDoneTitleMessage = (
  <FormattedMessage id="taxation.dialogs.acceptSuccess.title" defaultMessage="Sikeres adóelőírás 🎉" />
)

//* Common messages
export const OverviewMonthlyIncomeMessage = (
  <FormattedMessage
    id="taxation.overview.monthlyIncome"
    defaultMessage="Havi bevétel {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.overview.tooltip.monthlyIncome"
              defaultMessage="A havi árbevételed onnan tudtuk, hogy megnéztük a számlázódból, hogy ügyfeleid mikor fizettek (pénzforgalmi szemlélet)."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const OverviewIncomeBeforeTaxMessage = (
  <FormattedMessage
    id="taxation.overview.incomeBeforeTax"
    defaultMessage="Adózás előtti jövedelem {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.overview.tooltip.incomeBeforeTax"
              defaultMessage="A tevékenységedből származó bevételek költséghányaddal csökkentett része a jövedelem."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const IndicatorExemptedIncomeMessage = (
  <FormattedMessage
    id="taxation.indicators.exemptedIncome"
    defaultMessage="Mentesített jövedelemrész {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.indicators.tooltip.exemptedIncome"
              defaultMessage="Az SZJA törvényben meghatározott mentesített jövedelemnek, vagyis az éves minimálbér felének az adott hónapra jutó része. Fontos, hogy ez a keret folyamatosan csökken."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const IndicatorExemptedIncomeQuarterYearLabelMessage = (
  <FormattedMessage
    id="taxation.tbjSzocho2358.indicators.exemptedIncomeQuarterYear"
    defaultMessage="Mentesített jövedelemrész {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.indicators.tooltip.exemptedIncomeQuarterYear"
              defaultMessage="Az SZJA törvényben meghatározott mentesített jövedelemnek, vagyis az éves minimálbér felének az adott negyedévre jutó része. Fontos, hogy ez a keret folyamatosan csökken."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const IndicatorsTitleMessage = (
  <FormattedMessage id="taxation.text.title.indicators" defaultMessage="Részletes mutatók" />
)

export const FollowingAcceptanceMessage = (
  <FormattedMessage
    id="taxation.text.followingAcceptance"
    defaultMessage="Az elfogadással a kalkulált adó bekerül a fizetendő adók közé."
  />
)

export const ChecklistDeletableTaskErrorMessage = (
  <FormattedMessage
    id="taxation.text.questions.errorMessage"
    defaultMessage="Válaszaid alapján <b>változtak korábban beállított adózási adataid.</b> Ezt az esetet jelenleg <b>nem támogatjuk.</b> <b>A Törlés gombra kattintva</b> tudod bezárni a feladatot."
    values={StrongTextValues}
  />
)

export const BevallasChecklistErrorMessage = (
  <FormattedMessage
    id="taxation.bevallas.questions.errorMessage"
    defaultMessage="Válaszod alapján nem tudunk automatizáltan bevallás XML fájlt generálni számodra. Ne aggódj, szakmai ügyfélszolálatunk segít Neked a továbbiakban! Kattints a lenti Segítséget kérek gombra és az adataid megadása után munkatársaink felveszik veled a kapcsolatot!"
  />
)

export const RejectedChecklistDeletableTaskErrorMessage = (
  <FormattedMessage
    id="taxation.text.questions.rejectedErrorMessage"
    defaultMessage="Válaszaid alapján <b>változtak korábban beállított adózási adataid.</b> Ezt az esetet jelenleg <b>nem támogatjuk.</b>"
    values={StrongTextValues}
  />
)

export const CalculatedTaxByQuickMessage = (
  <FormattedMessage
    id="taxation.payables.tooltip.calculatedByQuick"
    defaultMessage="Ez a QUiCK által számított összeg. Amennyiben nem helyesek az alábbi adatok, úgy felülírhatod ezt az értéket."
  />
)

export const CalculatedTaxByUserMessage = (
  <FormattedMessage
    id="taxation.payables.tooltip.calculatedByUser"
    defaultMessage="Ez egy általad előírt érték. Amennyiben mégsem helyes, úgy törölheted ezt a feladatot."
  />
)

export const CalculatedTaxBySupportMessage = (
  <FormattedMessage
    id="taxation.payables.tooltip.calculatedBySupport"
    defaultMessage="Ez egy ügyfélszolgálatunk által előírt érték. Amennyiben mégsem helyes, úgy törölheted ezt a feladatot."
  />
)

export const TaxationTaskDoneSubtitleMessage = (
  <FormattedMessage id="taxation.task.subtitle.done" defaultMessage="az adóelőírást sikeresen elvégezted" />
)

export const TaxationXmlDoneSubtitleMessage = (
  <FormattedMessage id="taxation.xml.subtitle.done" defaultMessage="a feladatot sikeresen elvégezted" />
)

export const TaxationTaskDoneMessage = (
  <FormattedMessage
    id="taxation.task.done"
    defaultMessage="Ezt a teendőt már elvégezted. Amennyiben szeretnéd törölni, <link>vedd fel a kapcsolatot az ügyfélszolgálattal.</link>"
    values={{
      link: function RenderSupportLink(chunks: string) {
        return <IntercomInheritLink>{chunks}</IntercomInheritLink>
      },
    }}
  />
)

export const TaxationBevallasTaskRejectedMessage = (
  <FormattedMessage
    id="taxation.task.rejected"
    defaultMessage="Ezt a teendőt már elvégezted. Mivel felülírtad a QUiCK által kiszámolt adót, ezért nem tudsz bevallást letölteni. Amennyiben szeretnéd törölni a feladatot, <link>vedd fel a kapcsolatot az ügyfélszolgálattal.</link>"
    values={{
      link: function RenderSupportLink(chunks: string) {
        return <IntercomInheritLink>{chunks}</IntercomInheritLink>
      },
    }}
  />
)

export const TaxationDownloadButtonMessage = (
  <FormattedMessage id="taxation.task.buttons.download" defaultMessage="Letöltés" />
)

export const PayablesOverrideTextMessage = (
  <FormattedMessage id="taxation.payables.summaryValue.overrideText" defaultMessage="(felülírt)" />
)

export const TbjSzochoIndicatorTaxKeyLabelMessage = (
  <FormattedMessage id="taxation.tbjSzocho.indicators.taxKey" defaultMessage="Adókulcs" />
)

//* Dialog common messages for KATA nad TBJ-SZOCHO
export const TaxationClosureDialogSupportSuccessResponseMessage = (
  <FormattedMessage
    id="taxation.dialog.support.successResponse"
    defaultMessage="Az ügyfélszolgálat megkapta a kérelmet a bevallásod elkészítése kapcsán. A fiókodhoz tartozó e-mail címre küldtünk Neked egy automatikus levelet tájékoztatásképp. A válaszadásig szíves türelmedet kérjük!"
  />
)

export const TaxationClosureDialogXmlDownloadSuccessResponseMessage = (
  <FormattedMessage
    id="taxation.dialog.xmlDownload.successResponse"
    defaultMessage="Sikeresen letöltötted a bevallásod adatait tartalmazó XML fájlt. A következő lépésben ezt be kell töltened az ÁNYK-ba vagy ONYÁ-ba. Ehhez segítséget a Tudáscentrumban találsz."
  />
)

export const TaxationTaskCompleteSupportMessage = (
  <FormattedMessage
    id="taxation.taskCompleteBySupport"
    defaultMessage="Ezt a teendőt már elvégezted. A bevallásod a <link>QUiCK szakmai ügyfélszolgálata</link> segítségével készül el. A kapcsolatfelvételig szíves türelmedet kérjük!"
    values={{
      link: function RenderSupportLink(chunks: string) {
        return <IntercomInheritLink>{chunks}</IntercomInheritLink>
      },
    }}
  />
)

//* TAXATION TAX COMPANY_OWNER FORM LABELS

export const companyOwnerFormMessages = defineMessages({
  titleLabel: {
    id: 'taxation.form.companyOwner.titleLabel',
    defaultMessage: 'Titulus',
  },
  cityLabel: {
    id: 'taxation.form.companyOwner.cityLabel',
    defaultMessage: 'Település',
  },
  birthDateLabel: {
    id: 'taxation.form.companyOwner.birthDateLabel',
    defaultMessage: 'Születési idő',
  },
  zipCodeLabel: {
    id: 'taxation.form.companyOwner.zipCodeLabel',
    defaultMessage: 'Irányítószám',
  },
  addressLabel: {
    id: 'taxation.form.companyOwner.addressLabel',
    defaultMessage: 'Közterület neve',
  },
  addressTypeLabel: {
    id: 'taxation.form.companyOwner.addressTypeLabel',
    defaultMessage: 'Közterület jellege',
  },
  houseNumberLabel: {
    id: 'taxation.form.companyOwner.houseNumberLabel',
    defaultMessage: 'Házszám',
  },
  buildingLabel: {
    id: 'taxation.form.companyOwner.buildingLabel',
    defaultMessage: 'Épület',
  },
  stairCaseNumberLabel: {
    id: 'taxation.form.companyOwner.stairCaseNumberLabel',
    defaultMessage: 'Lépcsőház',
  },
  floorNumberLabel: {
    id: 'taxation.form.companyOwner.floorNumberLabel',
    defaultMessage: 'Emelet',
  },
  doorNumberLabel: {
    id: 'taxation.form.companyOwner.doorNumberLabel',
    defaultMessage: 'Ajtó',
  },
  socialSecurityNumberLabel: {
    id: 'taxation.form.companyOwner.socialSecurityNumberLabel',
    defaultMessage: 'TAJ szám',
  },
  genderLabel: {
    id: 'taxation.form.companyOwner.genderLabel',
    defaultMessage: 'Neme',
  },
  taxIdentificationNumberLabel: {
    id: 'taxation.form.companyOwner.taxIdentificationNumberLabel',
    defaultMessage: 'Adóazonosító jel',
  },
  nationalityLabel: {
    id: 'taxation.form.companyOwner.nationalityLabel',
    defaultMessage: 'Állampolgárság',
  },
})

//* Support View/Dialog messages
export const supportViewCommentPlaceholderMessage = defineMessage({
  id: 'supportView.comment.placeholderText',
  defaultMessage:
    'Írj ide bármilyen általad fontosnak tartott információt, ami segíthet nekünk a bevallásod elkészítése során.',
})

export const supportViewCommentGenericPlaceholderMessage = defineMessage({
  id: 'supportView.comment.genericPlaceHolderText',
  defaultMessage:
    'Kérjük, írj ide bármilyen általad fontosnak tartott információt, ami segíthet nekünk megérteni a problémát!',
})

export const SupportViewCommentLabelMessage = (
  <FormattedMessage id="supportView.comment.label" defaultMessage="Üzenet az ügyfélszolgálatnak (opcionális)" />
)
