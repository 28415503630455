import React from 'react'

import { useIntl } from 'react-intl'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef } from '@hooks'

import { DownloadIcon } from '@oldComponents/ui'

import { AsyncButtonWithIcon } from './Buttons'

import { ExportButtonMessage, listPageMessages } from '@messages'

interface BaseBulkExportActionButtonProps {
  callStartBulkExport: AsyncFunction<void, BackgroundActionResponse>
  callStopBulkExport: VoidFunction
}

export function BaseBulkExportActionButton({
  callStartBulkExport,
  callStopBulkExport,
}: BaseBulkExportActionButtonProps) {
  const [loading, setLoading] = React.useState(false)
  const { formatMessage } = useIntl()
  const { setErrorAlert, setInfoAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef<BackgroundActionResponse>()
  const stopRef = React.useRef(callStopBulkExport)

  React.useEffect(() => {
    const callStopAction = stopRef.current
    return () => {
      if (cPromiseRef.current) {
        callStopAction()
      }
    }
  }, [cPromiseRef])

  async function handleClick() {
    setLoading(true)
    setInfoAlert(formatMessage(listPageMessages.backgroundProcessStarted), { autoHideDuration: 8000 })
    try {
      cPromiseRef.current = cancellablePromise(callStartBulkExport())
      const response = await cPromiseRef.current.promise
      if (response.sendEmail) {
        setInfoAlert(formatMessage(listPageMessages.processMaxTriesEmail))
      }
      setLoading(false)
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setErrorAlert(errorMessage)
        setLoading(false)
      }
    }
  }

  return (
    <AsyncButtonWithIcon icon={<DownloadIcon />} loading={loading} onClick={handleClick} variant="primaryContained">
      {ExportButtonMessage}
    </AsyncButtonWithIcon>
  )
}
