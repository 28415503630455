import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { useAssertContext, useConfetti } from '@hooks'

import { Button } from '@components/ui'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@oldComponents/dialogs'

import { SuccessDialogContextValue, SuccessDialogProviderProps, SuccessDialogStateProps } from './types'

export const SuccessDialogContext = React.createContext<SuccessDialogContextValue | undefined>(undefined)

export function SuccessDialogProvider({ children }: SuccessDialogProviderProps) {
  const [{ open, title, message, withConfetti, ...dialogProps }, setState] = React.useState<SuccessDialogStateProps>({
    open: false,
    title: '',
    message: '',
    withConfetti: false,
  })

  const { triggerConfetti } = useConfetti({ spread: 90, particleCount: 100, origin: { y: 0.5 } })

  React.useEffect(() => {
    if (open && withConfetti) {
      triggerConfetti()
    }
  }, [open, triggerConfetti, withConfetti])

  const openDialog = React.useCallback(payload => setState({ ...payload, open: true }), [])

  const closeDialog = () => setState(prevState => ({ ...prevState, open: false }))

  return (
    <SuccessDialogContext.Provider value={openDialog}>
      {children}

      <Dialog
        {...dialogProps}
        open={open}
        onClose={closeDialog}
        aria-labelledby="success-response-dialog-title"
        disableRestoreFocus
      >
        <DialogTitle id="success-response-dialog-title">{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant="primaryContained">
            <FormattedMessage id="dialogs.successResponse.buttons.close" defaultMessage="Ok" />
          </Button>
        </DialogActions>
      </Dialog>
    </SuccessDialogContext.Provider>
  )
}

SuccessDialogProvider.propTypes = { children: PropTypes.node.isRequired }

export function useSuccessDialog(): SuccessDialogContextValue {
  return useAssertContext(SuccessDialogContext)
}
