import React from 'react'
import PropTypes from 'prop-types'

import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import { localeActions } from '@services'

import styles from './styles'

const useStyles = makeStyles(styles)

interface LocaleSelectorProps {
  changeLocale: (lang: Locale) => void
  lang: Locale
}

function PureLocaleSelector({ changeLocale, lang }: LocaleSelectorProps) {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLButtonElement>>(null)
  const classes = useStyles()

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleChange(newLang: Locale) {
    return function handler() {
      handleClose()
      if (lang !== newLang) {
        changeLocale(newLang)
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.desktopRoot}>
        <Button
          aria-owns={anchorEl ? 'locale-select-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.button}
        >
          {lang.toUpperCase()} <span className="Select-arrow" />
        </Button>
        <Popper open={!!anchorEl} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper id="locale-select-menu" className={classes.dropdownContainer}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList className={classes.dropdownMenu}>
                    <MenuItem onClick={handleChange('hu')}>
                      <Typography variant="button" color={lang === 'hu' ? 'primary' : 'initial'}>
                        HU
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleChange('en')}>
                      <Typography variant="button" color={lang === 'en' ? 'primary' : 'initial'}>
                        EN
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className={classes.mobileRoot}>
        <MenuList className={classes.dropdownMenu}>
          <MenuItem onClick={handleChange('hu')}>
            <Typography variant="button" color={lang === 'hu' ? 'primary' : 'inherit'}>
              HU
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleChange('en')}>
            <Typography variant="button" color={lang === 'en' ? 'primary' : 'inherit'}>
              EN
            </Typography>
          </MenuItem>
        </MenuList>
      </div>
    </div>
  )
}

PureLocaleSelector.propTypes = {
  lang: PropTypes.oneOf(['hu', 'en']).isRequired as React.Validator<Locale>,
  changeLocale: PropTypes.func.isRequired,
}

export const LocaleSelector = connect(
  (state: Store) => ({
    lang: state.locale.language,
  }),
  {
    changeLocale: localeActions.changeLocale.request,
  }
)(PureLocaleSelector)

LocaleSelector.displayName = 'LocaleSelector'
