import { FieldState, FieldValidator } from 'final-form'

type ConditionFunction<FieldValue> = (value: FieldValue, allValues: object, meta?: FieldState<FieldValue>) => boolean

// generic higher order rule to apply validation conditionally
export function applyIf<FieldValue>(condition: ConditionFunction<FieldValue>) {
  return function validatorCreator(rule: FieldValidator<FieldValue>): FieldValidator<FieldValue> {
    return function validator(value, allValues, meta) {
      return condition(value, allValues, meta) && rule(value, allValues, meta)
    }
  }
}

export function composeFieldValidators<FieldValue>(
  ...validators: FieldValidator<FieldValue>[]
): FieldValidator<FieldValue> {
  return function composedFieldValidator(value, allValues, meta) {
    return validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)
  }
}
