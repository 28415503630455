import React from 'react'
import PropTypes from 'prop-types'

import { AppBar as MuiAppBar, ButtonBase, Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { LightTooltip as Tooltip, UpsellMessage } from '@oldComponents/ui'

import { PagePermissions, PlanPermission } from '@permissions'

import { DetailsTabValues } from './constant'

import { appBarStyles, tabsStyles, tabStyles } from './styles'

export const AppBar = withStyles(appBarStyles)(MuiAppBar)
export const Tab = withStyles(tabStyles)(MuiTab)
export const Tabs = withStyles(tabsStyles)(MuiTabs)
export const TabPanel = ({ children, visible, ...other }) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    <div role="tabpanel" {...other}>
      {children}
    </div>
  </div>
)

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
}

// NOTE Tabs must be duplicated with PlanPermission because
// this component pass props to its children and TAB list must be in next level of react tree

function PureDetailsTabs({ tabs, children, WrapperComponent, getWrapperProps }) {
  const [activeTab, setActiveTab] = React.useState('details-form')

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <WrapperComponent {...getWrapperProps(activeTab)}>
      {children}
      <AppBar position="static" color="default">
        <PlanPermission
          perform={PagePermissions.DETAILS_ATTACHMENT}
          yes={() => (
            <Tabs value={activeTab} onChange={handleChange} aria-label="dashboard tabs" variant="fullWidth">
              {tabs.map(({ label, labelRenderer, value, disabled }) => (
                <Tab
                  key={`tab-${value}`}
                  label={labelRenderer?.({ isActive: activeTab === value }) ?? label}
                  value={value}
                  disabled={disabled}
                  id={`details-tab-${value}`}
                  data-testid={`details-tab-${value}`}
                  aria-controls={`details-tabpanel-${value}`}
                />
              ))}
            </Tabs>
          )}
          no={() => (
            <Tabs value={activeTab} onChange={handleChange} aria-label="dashboard tabs" variant="fullWidth">
              {tabs.map(({ label, labelRenderer, value, disabled }) => (
                <Tab
                  key={`tab-${value}`}
                  label={labelRenderer?.({ isActive: activeTab === value }) ?? label}
                  value={value}
                  disabled={value === DetailsTabValues.DOCUMENTS || disabled}
                  id={`details-tab-${value}`}
                  aria-controls={`details-tabpanel-${value}`}
                  component={
                    // need to override MuiTab ButtonBase component with tooltip
                    value === DetailsTabValues.DOCUMENTS
                      ? // eslint-disable-next-line react/display-name
                        React.forwardRef((buttonProps, ref) => (
                          <Tooltip title={<UpsellMessage />}>
                            <div>
                              <ButtonBase {...buttonProps} ref={ref} disabled />
                            </div>
                          </Tooltip>
                        ))
                      : undefined
                  }
                />
              ))}
            </Tabs>
          )}
        />
      </AppBar>
      {tabs.map(({ Component, value, props: compProps, addActiveFlag }) => (
        <TabPanel
          key={`tabpanel-${value}`}
          visible={activeTab === value}
          id={`details-tabpanel-${value}`}
          aria-labelledby={`details-tab-${value}`}
        >
          <Component {...compProps} {...(addActiveFlag ? { active: activeTab === value } : {})} />
        </TabPanel>
      ))}
    </WrapperComponent>
  )
}

PureDetailsTabs.defaultProps = { children: null }
PureDetailsTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.node.isRequired,
        Component: PropTypes.elementType.isRequired,
        props: PropTypes.object,
        addActiveFlag: PropTypes.bool,
        disabled: PropTypes.bool,
      }).isRequired,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        labelRenderer: PropTypes.func.isRequired,
        Component: PropTypes.elementType.isRequired,
        props: PropTypes.object,
        addActiveFlag: PropTypes.bool,
        disabled: PropTypes.bool,
      }).isRequired,
    ]).isRequired
  ),
  WrapperComponent: PropTypes.elementType.isRequired,
  getWrapperProps: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export const DetailsTabs = React.memo(PureDetailsTabs)
