import React from 'react'

import __merge from 'lodash/merge'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { getDecimal } from '@helpers/common'

import { ButtonWithIcon } from '@components/ui/Buttons'
import { AddCircleIcon } from '@components/ui/svgIcons'

// align button with select fields
const StyledButtonWithIcon = styled(ButtonWithIcon)`
  margin-top: 32px;
`

interface BalanceAllTransactionsButtonProps {
  transactions: PaymentTransaction[]
}

export function BalanceAllTransactionsButton({ transactions }: BalanceAllTransactionsButtonProps) {
  const { setValue, getValues } = useFormContext()

  function onClickHandler() {
    setValue(
      'transactions',
      __merge(
        getValues().transactions,
        transactions.map(({ amount, remaining }) => ({ amount: getDecimal((Number(amount) || 0) + Number(remaining)) }))
      )
    )
  }
  return (
    <StyledButtonWithIcon variant="primaryText" size="small" icon={<AddCircleIcon />} onClick={onClickHandler}>
      <FormattedMessage id="paymentEditor.buttons.copyAllFullAmount" defaultMessage="Összes kiegyenlítése" />
    </StyledButtonWithIcon>
  )
}
