import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Action, bindActionCreators, Dispatch } from 'redux'

import { expenseActions, incomeActions, quarantineActions } from '@services'

import { bindActionToPromise, isInvoiceClassifyConfirmNeeded } from '@helpers'

import { INVOICE_ACCEPTED_MIME_TYPES } from '@constants'

import uploadSVGSrc from '@assets/img/scan_upload_folder.svg'

import { SmallUploadForm } from './SmallUploadForm'
import { SmallInvoiceFileUploadFormProps } from './types'

import { FileUploadTextMessage } from './messages'

function PureSmallInvoiceFileUploadForm({
  abortUploadExpenseProcess,
  company,
  invoice,
  variant,
  ...props
}: SmallInvoiceFileUploadFormProps) {
  React.useEffect(() => {
    // abort upload process when form unmount
    return () => {
      abortUploadExpenseProcess?.()
    }
  }, [abortUploadExpenseProcess])

  return (
    <SmallUploadForm
      {...props}
      onUploadSuccess={() => {
        // history.replace(`${getRouteUrl('cost', company)}/${response.id}`)
        // do not do anything here, redux-saga will handle it
      }}
      getFileUploadPayload={acceptedFiles => ({
        invoice,
        file: acceptedFiles[0],
      })}
      acceptedFileMimeTypes={INVOICE_ACCEPTED_MIME_TYPES}
      fileUploadText={FileUploadTextMessage}
      uploadDescriptionText={
        variant === 'expense' ? (
          <FormattedMessage
            id="fileUpload.texts.navExpenseDescription"
            defaultMessage="Itt feltöltheted a számlát PDF, scan, vagy fotó formában."
          />
        ) : variant === 'income' ? (
          <FormattedMessage
            id="fileUpload.texts.navIncomeDescription"
            defaultMessage="Itt feltöltheted a számlát PDF, scan, vagy fotó formában."
          />
        ) : (
          <FormattedMessage
            id="fileUpload.texts.navDescription"
            defaultMessage="Itt feltöltheted a számlát PDF, scan, vagy fotó formában. A felismert adatok alapján a számlád átkerül a karanténból a bejövő számlákhoz."
          />
        )
      }
      uploadSuggestionText={
        <FormattedMessage
          id="fileUpload.nav.texts.suggestion"
          defaultMessage="Egy számla feldolgozása 10-15 másodperc is lehet. A felismert adatok alapján a számlád átkerül a karanténból a bejövő számlákhoz."
        />
      }
      uploadImgSrc={uploadSVGSrc}
      {...(variant === 'expense' ? { withExtendedTimer: 60000 } : {})} // after 1 min upload (pdf recognition) is cancelable
    />
  )
}

PureSmallInvoiceFileUploadForm.propTypes = {
  abortUploadExpenseProcess: PropTypes.func,
  company: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  invoice: PropTypes.number,
  maxSize: PropTypes.number.isRequired,
  showServerError: PropTypes.bool,
  uploadFile: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['expense', 'quarantine', 'quarantine-accepted', 'income']).isRequired as React.Validator<
    SmallInvoiceFileUploadFormProps['variant']
  >,
}

function getUploadFileAction(variant: SmallInvoiceFileUploadFormProps['variant']) {
  switch (variant) {
    case 'income':
      return incomeActions.uploadIncomeArtifact.request
    case 'quarantine-accepted':
      return quarantineActions.uploadAcceptedQuarantineInvoice.request
    case 'expense':
      return expenseActions.uploadExpense.request
    case 'quarantine':
    default:
      return quarantineActions.uploadQuarantineInvoice.request
  }
}

type SmallInvoiceFileUploadFormOwnProps = Omit<
  SmallInvoiceFileUploadFormProps,
  'company' | 'uploading' | 'invoice' | 'maxSize' | 'uploadFile'
>

function mapStateToProps(
  {
    auth: {
      company: { data: company },
    },
    expense,
    income,
    dashboard: { common },
  }: Store,
  { variant }: SmallInvoiceFileUploadFormOwnProps
) {
  const details = variant === 'income' ? income.details : expense.details
  const showInvoiceClassifyConfirm = variant === 'income' ? false : isInvoiceClassifyConfirmNeeded(expense.details.data)

  return {
    company: company.id,
    invoice: details.data?.id || null,
    maxSize: common.artifact_max_size,
    showInvoiceClassifyConfirm,
    uploading: details.uploading,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>, { variant }: SmallInvoiceFileUploadFormOwnProps) {
  const props = {
    uploadFile: bindActionToPromise(dispatch, getUploadFileAction(variant)),
  }

  if (variant === 'expense') {
    return {
      ...props,
      abortUploadExpenseProcess: bindActionCreators(expenseActions.abortUploadExpense.request, dispatch),
    }
  }

  return props
}

export const SmallInvoiceFileUploadForm = connect(mapStateToProps, mapDispatchToProps)(PureSmallInvoiceFileUploadForm)

SmallInvoiceFileUploadForm.displayName = 'SmallInvoiceFileUploadForm'
