import React, { MutableRefObject } from 'react'

import { useMediaQuery } from '@material-ui/core'

export function useSticky(top: number): [boolean, MutableRefObject<Nullable<HTMLDivElement>>] {
  const appBarRef = React.useRef<Nullable<HTMLDivElement>>(null)
  const [sticky, setSticky] = React.useState(false)
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  React.useLayoutEffect(() => {
    function scrollDetector() {
      const el = appBarRef.current
      if (el) {
        const rect = el.getBoundingClientRect()
        setSticky(isDesktop && rect.top < top)
      }
    }
    window.addEventListener('scroll', scrollDetector)
    return () => {
      window.removeEventListener('scroll', scrollDetector)
    }
  }, [isDesktop])

  return [sticky, appBarRef]
}
