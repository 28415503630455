import React, { Component } from 'react'
import { func, number, object, shape } from 'prop-types'

import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { authActions } from '@services'

import { formatDate } from '@helpers'

import { BADGE_MASTER } from '@constants'

import { getCompanyUserBadge } from './helpers'
import { UserBadge } from './UserBadge'

import styles from './styles'

class UserBadgeView extends Component {
  componentDidMount() {
    // call auth/me patch with "badge_displayed_at"
    const payload = formatDate(new Date().toISOString())
    this.props.callUpdateBadgeDisplayedAt(payload)
  }

  render() {
    const {
      badgeInfo: { badge },
      classes,
      onClose,
    } = this.props

    return (
      <Grid container alignItems="center" justifyContent="center" className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.userBadge}>
            <UserBadge badge={badge} size="big" />
          </div>
          <Typography variant="h3" align="center" style={{ marginBottom: 30 }}>
            <FormattedMessage id="badgeView.accost" defaultMessage="Kedves Felhasználónk!" />
          </Typography>

          <Typography variant="body2" component="div">
            {badge === BADGE_MASTER ? (
              <FormattedMessage
                id="badgeView.content.master"
                // eslint-disable-next-line max-len
                defaultMessage="Rengetegszer segítettél nekünk jobbá tenni a QUiCK-et és köszönjük, hogy ajánlottad programunkat. Hálánk jeléül fiókod kiemelt {rank} rangot kapott, és örökre díjmentesen használhatod a QUiCK-et."
                values={{
                  rank: (
                    <span className={classes.badgeType}>
                      <FormattedMessage id="badgeView.quickBadgeTitle.master" defaultMessage=",,Kvikkmester”" />
                    </span>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id="badgeView.content.assistant"
                // eslint-disable-next-line max-len
                defaultMessage="Rengetegszer segítettél nekünk jobbá tenni a QUiCK-et és köszönjük, hogy ajánlottad programunkat. Hálánk jeléül fiókod kiemelt {rank} rangot kapott, és a következő 6 hónapban díjmentesen használhatod a QUiCK-et."
                values={{
                  rank: (
                    <span className={classes.badgeType}>
                      <FormattedMessage id="badgeView.quickBadgeTitle.assistant" defaultMessage=",,Kvikk segéd”" />
                    </span>
                  ),
                }}
              />
            )}
          </Typography>

          <Button size="small" className={classes.goToDashboardButton} onClick={onClose}>
            <FormattedMessage id="badgeView.button.goToDashboard" defaultMessage="Tovább a kezdőlapra" />
          </Button>
        </Paper>
      </Grid>
    )
  }
}

UserBadgeView.propTypes = {
  classes: object.isRequired,
  badgeInfo: shape({
    badge: number.isRequired,
  }).isRequired,
  onClose: func.isRequired,
  callUpdateBadgeDisplayedAt: func.isRequired,
}

export default connect(
  state => ({
    badgeInfo: getCompanyUserBadge(state),
  }),
  {
    callUpdateBadgeDisplayedAt: authActions.updateBadgeDisplayedAt.request,
  }
)(withStyles(styles)(UserBadgeView))
