import React from 'react'

import styled from 'styled-components'

function BaseChartRegularIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M5.10413 17.7188C5.10413 18.1863 5.48206 18.5625 5.94788 18.5625H20.5729C21.0405 18.5625 21.4166 18.9387 21.4166 19.4062C21.4166 19.8738 21.0405 20.25 20.5729 20.25H5.94788C4.55006 20.25 3.41663 19.118 3.41663 17.7188V5.34375C3.41663 4.87793 3.79456 4.5 4.26038 4.5C4.7262 4.5 5.10413 4.87793 5.10413 5.34375V17.7188ZM16.0588 8.73281C16.5194 8.27227 17.2822 8.30039 17.7076 8.79609L19.8838 11.3379C20.1475 11.6438 20.2916 12.034 20.2916 12.4383V15.75C20.2916 16.3723 19.7889 16.875 19.1666 16.875H7.91663C7.29436 16.875 6.79163 16.3723 6.79163 15.75V11.9496C6.79163 11.5031 6.97092 11.0707 7.28733 10.7543L10.4971 7.54453C10.9365 7.10508 11.6467 7.10508 12.0862 7.54453L14.6666 10.125L16.0588 8.73281ZM11.2916 9.13711L8.47913 11.9496V15.1875H18.6041V12.4383L16.8217 10.357L15.8584 11.3168C15.542 11.6332 15.1131 11.8125 14.6666 11.8125C14.2201 11.8125 13.7912 11.6332 13.4748 11.3168L11.2916 9.13711Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const ChartRegularIcon = styled(BaseChartRegularIcon)``
