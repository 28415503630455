import React from 'react'
import PropTypes from 'prop-types'

import { AppBar, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { PublicIntercomInitializer } from '@components/Intercom'
import { LocaleSelector } from '@oldComponents/LocaleSelector'
import { QuickLogo } from '@oldComponents/ui/SvgIcons/QuickLogo'

import { LINKS } from '@constants'

import styles from './styles'

const useStyles = makeStyles(styles)

interface PublicNavbarProps {
  withExtraDesign: boolean
}

export function PublicNavbar({ withExtraDesign }: PublicNavbarProps) {
  const classes = useStyles()

  return (
    <>
      <PublicIntercomInitializer />
      <AppBar color="default">
        <Toolbar className={classes.toolbar}>
          <div className={cx(classes.logoContainer, { [classes.centeredLogo]: withExtraDesign })}>
            <a href={LINKS.landing}>
              <QuickLogo withDecoration />
            </a>
          </div>
          <LocaleSelector />
        </Toolbar>
      </AppBar>
    </>
  )
}

PublicNavbar.propTypes = {
  withExtraDesign: PropTypes.bool.isRequired,
}
