import React from 'react'

import styled from 'styled-components'

function BaseVerticalDotMenuIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12.188 15.75a1.687 1.687 0 1 0 0 3.374 1.687 1.687 0 0 0 0-3.374Zm0-5.625a1.687 1.687 0 1 0 0 3.374 1.687 1.687 0 0 0 0-3.374Zm1.687-3.938a1.686 1.686 0 0 0-2.333-1.559 1.688 1.688 0 1 0 2.333 1.56Z"
      />
    </svg>
  )
}

export const VerticalDotMenuIcon = styled(BaseVerticalDotMenuIcon)``
