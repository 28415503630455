import {
  SettingsDialogProviderProps,
  SettingsDialogStateProps,
  SettingsDialogStates,
  SettingsProviderCompany,
  TranslationMessages,
} from './types'

import {
  accountMessages,
  apiKeyMessages,
  companyMessages,
  companyVatMessages,
  inviteMessages,
  memberMessages,
  membershipMessages,
  notDeletableAccountMessages,
  notDeletableCompanyMessages,
} from './messages'

export function getMessages(state: SettingsDialogStates): Nullable<TranslationMessages> {
  switch (state) {
    case SettingsDialogStates.MEMBER:
      return memberMessages
    case SettingsDialogStates.MEMBERSHIP:
      return membershipMessages
    case SettingsDialogStates.COMPANY:
      return companyMessages
    case SettingsDialogStates.ACCOUNT:
      return accountMessages
    case SettingsDialogStates.COMPANY_VAT:
      return companyVatMessages
    case SettingsDialogStates.API_KEY:
      return apiKeyMessages
    case SettingsDialogStates.INVITE:
      return inviteMessages

    case SettingsDialogStates.ACCOUNT_NOT_DELETABLE:
      return notDeletableAccountMessages
    case SettingsDialogStates.COMPANY_NOT_DELETABLE:
      return notDeletableCompanyMessages

    default:
      return null
  }
}

export function callSubmitFunction(
  state: Nullable<SettingsDialogStates>,
  props: SettingsDialogProviderProps,
  { company, data }: { company: Nullable<SettingsProviderCompany>; data: SettingsDialogStateProps['data'] }
) {
  switch (state) {
    case SettingsDialogStates.COMPANY: {
      if (!company) {
        throw Error('Missing company in removeCompany request')
      }
      return props.callRemoveCompany({ company })
    }

    case SettingsDialogStates.ACCOUNT:
      return props.callRemoveAccount({ data })

    case SettingsDialogStates.MEMBERSHIP: {
      if (!company) {
        throw Error('Missing company in removeCompanyMembership request')
      }
      return props.callRemoveCompanyMembership({ company, data })
    }

    case SettingsDialogStates.COMPANY_VAT: {
      if (!company) {
        throw Error('Missing company in removeCompanyVat request')
      }
      return props.callRemoveCompanyVat({ company, data })
    }

    case SettingsDialogStates.API_KEY: {
      if (!company) {
        throw Error('Missing company in removeApiKey request')
      }
      return props.callRemoveApiKey({ company, data })
    }

    case SettingsDialogStates.INVITE: {
      if (!company) {
        throw Error('Missing company in declineCompanyInvite request')
      }
      return props.callDeclineCompanyInvite({ company, data })
    }

    case SettingsDialogStates.MEMBER:
    default: {
      if (!company) {
        throw Error('Missing company in removeCompanyMember request')
      }
      return props.callRemoveCompanyMember({ company, data })
    }
  }
}
