import React from 'react'

import { AppBar, Toolbar } from '@material-ui/core'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonWithIcon } from '../Buttons'
import { LeftArrowIcon } from '../svgIcons'
import { Paging, PagingProps } from './Paging'

export const FOOTER_CONTAINER_HEIGHT = 60
const FOOTER_SIDE_PADDING = 20

const AppBarWrapperDiv = styled.div`
  margin-top: ${FOOTER_CONTAINER_HEIGHT}px;
`

const StyledAppBar = styled(AppBar)`
  top: auto;
  bottom: 0;
  background-color: white;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.15);
`

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.gray[0]};
  color: ${({ theme }) => theme.colors.primary[50]};
  min-height: ${FOOTER_CONTAINER_HEIGHT}px;
  flex: none;
  padding-left: ${FOOTER_SIDE_PADDING}px;
  padding-right: ${FOOTER_SIDE_PADDING}px;
  gap: 24px;
`

const ColumnDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${FOOTER_SIDE_PADDING}px;

  &.right {
    justify-content: flex-end;
  }
`

const ButtonsContainerDiv = styled.div`
  display: inherit;
  align-items: inherit;
  gap: ${FOOTER_SIDE_PADDING}px;
`

interface PageFooterBarProps {
  backButtonMessage: JSX.Element
  backToListUrl: string
  leftColumnContent?: React.ReactNode
  pagingProps?: PagingProps
  rightColumnContent?: React.ReactNode
}

export function PageFooterBar({
  backButtonMessage,
  backToListUrl,
  leftColumnContent,
  pagingProps,
  rightColumnContent,
}: PageFooterBarProps) {
  return (
    <AppBarWrapperDiv>
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <ColumnDiv>
            <ButtonWithIcon
              variant="primaryText"
              size="small"
              icon={<LeftArrowIcon />}
              forwardedAs={Link}
              to={backToListUrl}
            >
              {backButtonMessage}
            </ButtonWithIcon>
            {leftColumnContent && <ButtonsContainerDiv>{leftColumnContent}</ButtonsContainerDiv>}
          </ColumnDiv>
          <ColumnDiv className={cx({ right: !rightColumnContent })}>
            {rightColumnContent && <ButtonsContainerDiv>{rightColumnContent}</ButtonsContainerDiv>}
            {pagingProps && <Paging {...pagingProps} />}
          </ColumnDiv>
        </StyledToolbar>
      </StyledAppBar>
    </AppBarWrapperDiv>
  )
}
