import React from 'react'
import PropTypes from 'prop-types'

import { useField, useForm, useFormState } from 'react-final-form'
import { useIntl } from 'react-intl'

import { DEFAULT_BLUR_FIELD_LISTENER_SUBSCRIPTION } from '@constants'

import { FieldListenerProps } from './types'

//* COMPONENT TO USE FOR BLUR EVENTS
export function BlurFieldListener<Props extends Record<string, any>>({
  handler,
  name,
  subscription = DEFAULT_BLUR_FIELD_LISTENER_SUBSCRIPTION,
  ...rest
}: FieldListenerProps<Props>) {
  const {
    meta: { active },
    input,
    meta,
  } = useField(name, { subscription })
  const form = useForm()
  const { values } = useFormState({ subscription: { values: true } })
  const intl = useIntl()
  const [previous, setPrevious] = React.useState(active)

  // set previous to active once field is active
  React.useEffect(() => {
    if (active !== previous) setPrevious(active)
  }, [active, previous])

  // kick off handler if we leave an active field with "blur" type
  React.useEffect(() => {
    if (!active && previous) {
      setPrevious(active)
      // call handler
      handler({ ...rest, form, intl, values, input, meta })
    }
  }, [active, form, handler, input, intl, meta, previous, rest, values])

  return null
}

BlurFieldListener.propTypes = {
  handler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  subscription: PropTypes.object,
}
