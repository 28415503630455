import React from 'react'

import { FormattedMessage } from 'react-intl'

import { POPPER_PROPS } from '@components/DocViewer/constant'
import { IconButton, ZoomInIcon, ZoomOutIcon, ZoomResetIcon } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

interface ZoomControlsProps {
  handleZoomIn: VoidFunction
  handleZoomOut: VoidFunction
  handleZoomReset: VoidFunction
  zoomResetTitle: StringOrMessage
}

export function ZoomControls({ handleZoomIn, handleZoomOut, handleZoomReset, zoomResetTitle }: ZoomControlsProps) {
  return (
    <>
      <LightTooltip
        title={<FormattedMessage id="docViewer.fab.zoomInMessage" defaultMessage="Nagyítás" />}
        PopperProps={POPPER_PROPS}
      >
        <IconButton onClick={handleZoomIn} size="small">
          <ZoomInIcon />
        </IconButton>
      </LightTooltip>
      <LightTooltip
        title={<FormattedMessage id="docViewer.fab.zoomOutMessage" defaultMessage="Kicsinyítés" />}
        PopperProps={POPPER_PROPS}
      >
        <IconButton onClick={handleZoomOut} size="small">
          <ZoomOutIcon />
        </IconButton>
      </LightTooltip>
      <LightTooltip title={zoomResetTitle} PopperProps={POPPER_PROPS}>
        <IconButton onClick={handleZoomReset} size="small">
          <ZoomResetIcon size={18} />
        </IconButton>
      </LightTooltip>
    </>
  )
}
