export enum CompanyUserRoles {
  ADMIN = 'admin',
  ACCOUNTANT = 'accountant',
  EDITOR = 'editor',
  VIEWER = 'viewer',
  UNKNOWN = 'unknown', // for example in onboarding scenario where we do not know yet what role the user will have
}

export const ROLES: Record<'admin' | 'editor' | 'viewer' | 'adminOrAccountant' | 'accountant', CompanyUserRole[]> = {
  admin: [CompanyUserRoles.ADMIN],
  editor: [CompanyUserRoles.ACCOUNTANT, CompanyUserRoles.EDITOR],
  viewer: [CompanyUserRoles.VIEWER],
  adminOrAccountant: [CompanyUserRoles.ADMIN, CompanyUserRoles.ACCOUNTANT],
  accountant: [CompanyUserRoles.ACCOUNTANT],
}

export const DEFAULT_ROLE = CompanyUserRoles.ADMIN

export const ACCESS: Record<'admin' | 'editor' | 'viewer', CompanyUserRole[]> = {
  admin: [CompanyUserRoles.ADMIN],
  editor: [CompanyUserRoles.ADMIN, CompanyUserRoles.ACCOUNTANT, CompanyUserRoles.EDITOR],
  viewer: [CompanyUserRoles.ADMIN, CompanyUserRoles.ACCOUNTANT, CompanyUserRoles.EDITOR, CompanyUserRoles.VIEWER],
}
