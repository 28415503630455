import React from 'react'

import styled from 'styled-components'

function BaseZoomResetIcon({ className, size = 22 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(23 / 22) * size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 23"
    >
      <path
        d="M6.679.563a1.172 1.172 0 1 1 0 2.343H2.357v4.297c0 .65-.525 1.172-1.178 1.172A1.172 1.172 0 0 1 0 7.203V1.734C0 1.085.525.563 1.179.563h5.5ZM0 15.796c0-.65.525-1.172 1.179-1.172.653 0 1.178.523 1.178 1.172v4.297H6.68a1.172 1.172 0 1 1 0 2.343h-5.5A1.172 1.172 0 0 1 0 21.267v-5.47ZM20.821.562c.654 0 1.179.523 1.179 1.172v5.47c0 .649-.525 1.171-1.179 1.171a1.172 1.172 0 0 1-1.178-1.172V2.906H15.32a1.172 1.172 0 1 1 0-2.344h5.5Zm-1.178 15.235c0-.65.525-1.172 1.178-1.172.654 0 1.179.523 1.179 1.172v5.469c0 .649-.525 1.172-1.179 1.172h-5.5a1.172 1.172 0 1 1 0-2.344h4.322v-4.297Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const ZoomResetIcon = styled(BaseZoomResetIcon)``
