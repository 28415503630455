import React from 'react'

import styled from 'styled-components'

function PureOneLetterLogo({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size / 2}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 20"
    >
      <path
        d="M6.3959 18.3446c-.5815.1931-1.2598.2897-1.9382.2897C1.6474 18.6343 0 16.9929 0 13.9998V4.6344C.097 1.6414 1.6474 0 4.5546 0 7.462 0 9.0124 1.6414 9.0124 4.6344v9.3654c0 1.2552-.2907 2.2207-.7753 2.9931.1938.3862.5815.3862 1.066.3862h.4845v2.6069h-.8721c-1.163.0965-2.132-.2897-2.5196-1.6414Zm-.3876-4.1517V4.4413c0-1.3517-.5815-1.8344-1.5506-1.8344-.969 0-1.4536.4827-1.4536 1.8344v9.7516c0 1.3517.5815 1.8345 1.5505 1.8345.9691 0 1.4537-.4828 1.4537-1.8345Z"
        fill="#4F4F4F"
      />
    </svg>
  )
}

export const OneLetterLogo = styled(PureOneLetterLogo)``
