import React from 'react'
import { bool, string } from 'prop-types'

import { TableRow as MuiTableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grayscale.tablerowBg,
    '&$selected': {
      backgroundColor: theme.palette.neutral.xxxLight,
    },
    '&$hover:hover': {
      backgroundColor: theme.palette.neutral.xxxLight,
      boxShadow:
        'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)',
      position: 'relative',
      zIndex: 2,
    },
  },
  hover: {},
  selected: {},
  highlighted: {
    backgroundColor: theme.palette.neutral.xxxLight,

    '& > .MuiTableCell-root': {
      fontWeight: 700,
    },
  },
  head: {
    backgroundColor: theme.palette.neutral.xxxLight,
  },
}))

export default function TableRow({ className = '', highlighted = false, ...rest }) {
  const { highlighted: highlightedClass, ...classes } = useStyles()
  return <MuiTableRow classes={classes} className={cx(className, { [highlightedClass]: highlighted })} {...rest} />
}

TableRow.propTypes = {
  className: string,
  highlighted: bool,
}
