import React from 'react'

import { defineMessage, useIntl } from 'react-intl'

import { useCalculationBaseControl } from '@contexts'

import { CalculatorIcon, IconButton } from '@components/ui'

const TooltipMessage = defineMessage({
  id: 'buttons.calculationBaseControl',
  defaultMessage: 'Számítások alapja beállítás',
})

export function CalculationBaseControlIconButton() {
  const { formatMessage } = useIntl()
  const { openDialog } = useCalculationBaseControl()

  return (
    <IconButton
      data-testid="calculation-base"
      onClick={openDialog}
      size="small"
      title={formatMessage(TooltipMessage)}
      variant="primaryText"
    >
      <CalculatorIcon />
    </IconButton>
  )
}
