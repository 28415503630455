import React from 'react'

export default function EyeIcon({ className, size = 15 }: IconComponentProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={(size / 15) * 12}
      viewBox="0 0 15 12"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 6a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3 1.875a1.874 1.874 0 1 0 0-3.75h-.068c.052.12.068.246.068.375 0 .827-.673 1.5-1.5 1.5a.934.934 0 0 1-.375-.068v.047A1.88 1.88 0 0 0 7.75 7.875ZM3.236 2.639C4.34 1.613 5.856.75 7.75.75c1.894 0 3.41.863 4.514 1.89 1.097 1.016 1.83 2.214 2.177 3.072a.745.745 0 0 1 0 .576c-.347.837-1.08 2.035-2.177 3.073-1.104 1.026-2.62 1.889-4.514 1.889-1.894 0-3.41-.863-4.514-1.89-1.096-1.037-1.83-2.235-2.178-3.072a.75.75 0 0 1 0-.576c.348-.858 1.082-2.056 2.178-3.073Zm4.514-.764c-1.528 0-2.784.694-3.748 1.587C3.1 4.3 2.477 5.276 2.16 6c.318.703.941 1.7 1.843 2.538.964.893 2.22 1.587 3.748 1.587s2.784-.694 3.748-1.587C12.4 7.7 13.002 6.703 13.342 6a8.457 8.457 0 0 0-1.844-2.538c-.964-.893-2.22-1.587-3.748-1.587Z"
        fill="currentColor"
      />
    </svg>
  )
}
