import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { getDecimal } from '@helpers'

import { IncomeCashRegisterFormValues } from '@components/forms/IncomeCashRegisterForm/types'
import { ReactHookFormAmountField } from '@components/ui'

import { EXCHANGE_RATE_DECIMAL_PLACES } from '@constants'

import { ReactHookFormExchangeDate } from './ReactHookFormExchangeDate'
import { useReactHookFormExchangeRateDisplay } from './useReactHookFormExchangeRateDisplay'

import messages from '@components/forms/messages'

interface ReactHookFormExchangeRateFieldsProps {
  defaultCurrencyId: number
  disabled: boolean
  invoiceDetailsId?: ItemIdType
}

export function ReactHookFormExchangeRateFields({
  defaultCurrencyId,
  disabled,
  invoiceDetailsId,
}: ReactHookFormExchangeRateFieldsProps) {
  const { formatMessage } = useIntl()
  const {
    setValue,
    formState: { defaultValues: { currency: initialCurrency } = {} },
  } = useFormContext<IncomeCashRegisterFormValues>()
  const currency = useWatch({ name: 'currency' })
  const formInitializedRef = React.useRef(false)

  // reset conditional fields' values when they are not visible
  React.useEffect(() => {
    if (formInitializedRef.current && currency === defaultCurrencyId) {
      setValue('exchange_date', null)
      setValue(
        'exchange_rate',
        getDecimal(1, {
          maximumFractionDigits: EXCHANGE_RATE_DECIMAL_PLACES,
          minimumFractionDigits: 0,
        })
      )
    }
    formInitializedRef.current = true
  }, [currency, defaultCurrencyId, initialCurrency, setValue])

  const fieldVisible = useReactHookFormExchangeRateDisplay(defaultCurrencyId, currency, invoiceDetailsId)

  if (!fieldVisible) {
    return null
  }

  return (
    <>
      <Grid item xs={4}>
        <ReactHookFormExchangeDate disabled={disabled} maximumFractionDigits={EXCHANGE_RATE_DECIMAL_PLACES} />
      </Grid>
      <Grid item xs={4}>
        <ReactHookFormAmountField
          disabled={disabled}
          label={formatMessage(messages.exchangeRateLabel)}
          maximumFractionDigits={EXCHANGE_RATE_DECIMAL_PLACES}
          name="exchange_rate"
          required
        />
      </Grid>
    </>
  )
}

ReactHookFormExchangeRateFields.propTypes = {
  defaultCurrencyId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  invoiceDetailsId: PropTypes.number,
}
