import moment, { MomentInput } from 'moment'

export function formatDate(dateStringOrMoment?: MomentInput, formatString = 'YYYY-MM-DD') {
  return moment(dateStringOrMoment).format(formatString)
}

export function getFirstDayOfTheYear(dateStringOrMoment?: MomentInput) {
  return formatDate(moment(dateStringOrMoment).startOf('year'))
}

export function getLastDayOfTheYear(dateStringOrMoment?: MomentInput) {
  return formatDate(moment(dateStringOrMoment).endOf('year'))
}

export function dateIsInFiltersPeriod(from: MomentInput, to: MomentInput, current: MomentInput) {
  const currentMoment = moment(current)

  return currentMoment.isSameOrAfter(from, 'day') && currentMoment.isSameOrBefore(to, 'day')
}

export function getCurrentMonth() {
  return formatDate(moment().startOf('month'))
}

export function getSalaryPaymentDueAt(defaultDay: number) {
  if (defaultDay > 28) {
    const lastDayOfMonth = moment().endOf('month').get('date')
    const paymentDay = Math.min(defaultDay, lastDayOfMonth)

    return formatDate(moment().startOf('month').date(paymentDay).add(1, 'months'))
  }

  return formatDate(moment().startOf('month').date(defaultDay).add(1, 'months'))
}

export function isXmasPeriod(startDay: number, endDay: number) {
  const startMoment = moment().month(11).date(startDay)
  const endMoment = moment().month(11).date(endDay)
  const currentMoment = moment()

  return currentMoment.isSameOrAfter(startMoment, 'day') && currentMoment.isSameOrBefore(endMoment, 'day')
}

export function getFirstDayOfMonth(dateStringOrMoment: MomentInput) {
  return formatDate(moment(dateStringOrMoment).startOf('month'))
}
