import React from 'react'

export default function TaskListChekcedIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="30" height="30" rx="5" stroke="currentColor" strokeWidth="2" />
      <path
        d="M26.7 8.4 12.55 22.6 5.3 15.25"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
