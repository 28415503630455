import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

const styles: Styles<Theme, Record<never, never>> = theme => ({
  datepickerRoot: {
    width: 270,
  },
  datepickerButton: {
    height: 36,
    padding: '4px 8px',
    border: `1px solid ${theme.palette.grayscale.light}`,
    backgroundColor: theme.palette.grayscale.inputBackground,
    color: theme.palette.grayscale.xxxDark,
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 400,
  },
  datepicker: {
    // overwrite react-widgets styles
    fontSize: 16,
    marginTop: 0,
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    color: 'inherit',
    '&.rw-state-focus': {
      '&>.rw-widget-container': {
        borderColor: '#80bdff',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },

    '&.rw-calendar': {
      border: 0,
      padding: 10,
      borderRadius: 4,
    },
    '& .rw-cell': {
      color: theme.palette.grayscale.xxDark,
      '&.rw-state-focus': {
        borderColor: theme.palette.tertiary.oceanBlue,
      },
      '&.rw-state-selected': {
        backgroundColor: theme.palette.tertiary.oceanBlue,
        borderColor: theme.palette.tertiary.oceanBlue,
        color: theme.palette.neutral.xxxLight,
      },
    },
  },
})

export default styles
