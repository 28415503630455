import React from 'react'

import styled from 'styled-components'

function BaseInfoIcon({ className, size = 24, ...rest }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        d="M10.5 7.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 4.8a1.5 1.5 0 0 1 3 0v4.2a1.5 1.5 0 0 1-3 0v-4.2ZM12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0Zm0 21.6c-5.292 0-9.6-4.308-9.6-9.6S6.708 2.4 12 2.4s9.6 4.308 9.6 9.6-4.308 9.6-9.6 9.6Z"
        fill="currentColor"
      />
    </svg>
  )
}

const InfoIcon = styled(BaseInfoIcon)``

export default InfoIcon
