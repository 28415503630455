export type ValidationErrors<Values extends object> = {
  [K in keyof Values]: string
}

type Validator<Values extends object> = {
  (values: Values): Promise<ValidationErrors<Values>>
}

export async function validationEngine<Values extends object>(
  validators: Validator<Values>[],
  submitHandler: AsyncFunction<Values, unknown>,
  values: Values
) {
  const validationErrors = await Promise.all(validators.map(validator => validator(values))).then(results =>
    results.reduce((errors, result) => ({ ...errors, ...result }), {} as ValidationErrors<Values>)
  )

  if (Object.keys(validationErrors).length) {
    return Promise.reject(validationErrors)
  }
  return submitHandler(values)
}
