import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles({
  root: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export function LoadingView() {
  const classes = useStyles()
  return (
    <div className={classes.root} style={{ gap: 30 }}>
      <CircularProgress color="primary" size={50} />
      <Typography variant="body1" component="div">
        <FormattedMessage
          id="subscription.init.loading"
          defaultMessage="Az előfizetői csomagok betöltése folyamatban..."
        />
      </Typography>
    </div>
  )
}

interface ErrorViewProps {
  details?: string
}

export function ErrorView({ details }: ErrorViewProps) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="body1" component="div" color="error">
        <FormattedMessage
          id="subscription.init.error"
          defaultMessage="Az előfizetői csomagok lekérése sikertelen, kérjük próbálja meg újratölteni az oldalt."
        />
      </Typography>
      {details && (
        <Typography variant="caption" color="error">
          {details}
        </Typography>
      )}
    </div>
  )
}

ErrorView.propTypes = { details: PropTypes.string }
