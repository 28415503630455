import React from 'react'
import PropTypes from 'prop-types'

export default function BadgeDeadlineTaskIcon({ className, size = 18 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.95 8.10005C11.7115 8.10005 9.9 9.91158 9.9 12.15C9.9 14.3885 11.7115 16.2 13.95 16.2C16.1885 16.2 18 14.3885 18 12.15C18 9.91158 16.1885 8.10005 13.95 8.10005ZM15.75 12.327C15.75 12.4771 15.6271 12.6 15.4769 12.6H13.7734C13.6232 12.6 13.5003 12.4771 13.5003 12.327V10.1734C13.5003 10.0232 13.6232 9.90033 13.7734 9.90033H14.1269C14.2771 9.90033 14.4 10.0232 14.4 10.1734V11.7H15.4769C15.6271 11.7 15.75 11.823 15.75 11.9731V12.327ZM13.95 7.20005C14.1019 7.20005 14.2515 7.20933 14.4 7.22283V5.85005C14.4 5.13005 13.77 4.50005 13.05 4.50005H10.8V3.15005C10.8 2.43005 10.17 1.80005 9.45 1.80005H4.95C4.23 1.80005 3.6 2.43005 3.6 3.15005V4.50005H1.35C0.63 4.50005 0 5.13005 0 5.85005V8.10005H11.1127C11.9171 7.53502 12.8945 7.20005 13.95 7.20005ZM9 4.50005H5.4V3.60005H9V4.50005ZM9.19181 10.8H5.85C5.60137 10.8 5.4 10.5987 5.4 10.35V9.00005H0V13.05C0 13.77 0.63 14.4 1.35 14.4H9.54647C9.19969 13.7239 9 12.9606 9 12.15C9 11.6815 9.06975 11.2298 9.19181 10.8Z"
        fill="currentColor"
      />
    </svg>
  )
}

BadgeDeadlineTaskIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}
