import React from 'react'
import PropTypes from 'prop-types'

import { components as reactSelectComponents, GroupBase, OptionProps } from 'react-select'

export interface CustomReactSelectOptionProps<Option extends { isExist?: boolean }>
  extends OptionProps<Option, boolean, GroupBase<Option>> {
  texts?: { newOptionPrefix: string; [x: string]: string }
}

// need to customize the recommended option
const ReactSelectOption = reactSelectComponents.Option

// react-select custom options
export function CustomReactSelectOption<Option extends { isExist?: boolean }>({
  children,
  texts,
  ...rest
}: CustomReactSelectOptionProps<Option>) {
  const {
    data: { isExist },
  } = rest
  return (
    <ReactSelectOption {...rest}>
      <div className="custom-option">
        {texts && isExist === false && <span className="badge">{texts.newOptionPrefix + ' '}</span>}
        <span {...(typeof children === 'string' ? { title: children } : {})}>{children}</span>
      </div>
    </ReactSelectOption>
  )
}

CustomReactSelectOption.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({ isExist: PropTypes.bool }).isRequired,
  texts: PropTypes.object,
}
