import Qs from 'qs'

import { callUrl } from '@services/common/api'

import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import { API_SALARY_BACKGROUND_ACTION_URL, API_SALARY_LIST_URL, API_SALARY_PREDICT_URL } from './urls'

export function fetchSalaryListV2(companyId: number, params: Partial<SalaryListStoreFilters>) {
  return Api.get<BackendPaginatedListResponse<BackendSalaryListResult>>(
    injectCompanyToURL(API_SALARY_LIST_URL, companyId),
    {
      params,
      paramsSerializer: {
        serialize(params) {
          return Qs.stringify(params, { indices: false })
        },
      },
    }
  )
}

export function salaryBackgroundAction(companyId: number, payload: unknown) {
  return Api.post<BackgroundActionResponse>(injectCompanyToURL(API_SALARY_BACKGROUND_ACTION_URL, companyId), payload)
}

export function fetchSalaryListByPagingV2(url: string) {
  return callUrl({ method: 'get', url })
}

export function fetchLastSalary(companyId: number) {
  return Api.get(injectCompanyToURL(API_SALARY_PREDICT_URL, companyId))
}
