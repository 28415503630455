import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { userHasAdminPermission, userHasEditorPermission } from '@helpers'

import { ROUTES } from '@constants'

interface AuthorizedRouteProps {
  accessLevel: 'admin' | 'editor' | 'viewer'
  children: JSX.Element
  redirectTo?: string
  role?: CompanyUserRole
}

// TODO add unit test
function PureAuthorizedContent({ accessLevel, children, redirectTo = ROUTES.root, role }: AuthorizedRouteProps) {
  if (accessLevel === 'admin') {
    if (userHasAdminPermission(role)) {
      return children
    }
  } else if (accessLevel === 'editor') {
    if (userHasEditorPermission(role)) {
      return children
    }
  } else {
    return children
  }

  return <Navigate to={redirectTo} replace />
}

PureAuthorizedContent.propTypes = {
  accessLevel: PropTypes.oneOf(['admin', 'editor', 'viewer']).isRequired as React.Validator<
    AuthorizedRouteProps['accessLevel']
  >,
  children: PropTypes.element.isRequired,
  redirectTo: PropTypes.string,
  role: PropTypes.string as React.Validator<AuthorizedRouteProps['role']>,
}

function mapStateToProps(state: Store) {
  return {
    role: state.auth.company.data?.role, // TODO: fix this, it depends on active company
  }
}

export const AuthorizedContent = connect(mapStateToProps)(PureAuthorizedContent)

AuthorizedContent.displayName = 'AuthorizedContent'
