import React from 'react'

import { InvoiceType } from '@constants'

import { VAT_LEDGER_NUMBER_SUGGESTION } from './constants'
import { LedgerNumberField, LedgerNumberFieldProps } from './LedgerNumberField'

interface VatLedgerNumberFieldProps extends LedgerNumberFieldProps {
  invoiceType?: InvoiceType
}

export function VatLedgerNumberField({ invoiceType = InvoiceType.EXPENSE, ...rest }: VatLedgerNumberFieldProps) {
  return <LedgerNumberField {...rest} suggestion={VAT_LEDGER_NUMBER_SUGGESTION[invoiceType]} />
}
