import React from 'react'

import styled from 'styled-components'

function BaseVatPositionIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M14.5714 2.67857C14.5714 3.21295 15.0013 3.64286 15.5357 3.64286H17.7094L13.2335 8.11473L9.41251 4.84018C9.0509 4.5308 8.51251 4.5308 8.15492 4.8442L3.33349 9.02277C2.93171 9.37232 2.88751 9.97902 3.23706 10.3848C3.58662 10.7906 4.19331 10.8308 4.59912 10.4812L8.79376 6.84509L12.663 10.1598C13.0447 10.4893 13.6152 10.4652 13.9728 10.1076L19.0755 5.00491V7.17857C19.0755 7.71295 19.5054 8.14286 20.0397 8.14286C20.5741 8.14286 21.004 7.71295 21.004 7.17857V2.67857C21.004 2.1442 20.5741 1.71429 20.0397 1.71429H15.5357C15.0013 1.71429 14.5714 2.1442 14.5714 2.67857ZM5.87278 13.9286H18.1272C18.2719 15.0937 19.196 16.0138 20.3572 16.1585V18.1272C19.192 18.2719 18.2719 19.196 18.1272 20.3571H5.87278C5.72813 19.192 4.80403 18.2719 3.64287 18.1272V16.1585C4.80403 16.0138 5.72813 15.0897 5.87278 13.9286ZM3.64287 12C2.57813 12 1.71429 12.8638 1.71429 13.9286V20.3571C1.71429 21.4219 2.57813 22.2857 3.64287 22.2857H20.3572C21.4219 22.2857 22.2857 21.4219 22.2857 20.3571V13.9286C22.2857 12.8638 21.4219 12 20.3572 12H3.64287ZM12 19.7143C12.682 19.7143 13.336 19.4434 13.8183 18.9611C14.3005 18.4789 14.5714 17.8248 14.5714 17.1429C14.5714 16.4609 14.3005 15.8068 13.8183 15.3246C13.336 14.8423 12.682 14.5714 12 14.5714C11.318 14.5714 10.664 14.8423 10.1817 15.3246C9.6995 15.8068 9.42858 16.4609 9.42858 17.1429C9.42858 17.8248 9.6995 18.4789 10.1817 18.9611C10.664 19.4434 11.318 19.7143 12 19.7143Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const VatPositionIcon = styled(BaseVatPositionIcon)``
