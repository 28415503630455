import React from 'react'

import { ArrowDownward } from '@material-ui/icons'
import styled, { css, DefaultTheme } from 'styled-components'

import { applyOpacity, getFontStyles } from '@helpers/styled-helpers'

import { TableClassNames } from '@constants'

export const TableSortIcon = styled(ArrowDownward)`
  width: 17px;
  height: 17px;
  vertical-align: bottom;
  margin: 0 4px;
`

export const TableCell = styled.td<{
  align?: 'left' | 'right' | 'center' | 'inherit'
  $hoverCursor?: 'default' // used in SimpleTable selection cell
}>`
  padding: 9px 10px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  color: ${({ theme }) => theme.colors.gray[100]};
  text-align: ${({ align = 'start' }) => align};
  ${({ theme }) => getFontStyles(theme, '400-sm')}

  &:first-of-type {
    padding-left: 25px;
  }

  &:last-of-type {
    padding-right: 21px;
  }

  &:hover {
    cursor: ${({ $hoverCursor = 'auto' }) => $hoverCursor};
  }

  //* smaller paddings for table cells on smaller screens:
  @media (max-width: 1440px) {
    padding: 9px 5px 8px;

    &:first-of-type {
      padding-left: 10px;
    }

    &:last-of-type {
      padding-right: 10px;
    }
  }
`

export const NoDataCell = styled(TableCell).attrs({ align: 'center' })`
  height: 43px; // height of a row in default state
`

interface TableHeadingProps {
  $rightAligned?: boolean
  $sortable?: boolean
  $sorted?: boolean
  $sortedDescending?: boolean // default view is descending
}

export const TableHeading = styled(TableCell).attrs({ as: 'th' })<TableHeadingProps>`
  --default-color: ${({ theme }) => theme.colors.gray[50]};
  padding-top: 20px;
  color: var(--default-color);
  ${({ theme }) => getFontStyles(theme, '700-xs')}

  ${({ $sortable, $rightAligned, $sortedDescending = true, $sorted, theme }) =>
    $sortable &&
    css`
      white-space: nowrap;
      cursor: pointer;
      direction: ${$rightAligned ? 'rtl' : 'ltr'};
      transition: color 0.2s ease-in-out;
      color: ${$sorted ? applyOpacity(theme.colors.gray[100], 87) : 'var(--default-color)'};

      ${TableSortIcon} {
        opacity: ${$sorted ? 1 : 0};
        transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
        transform: rotateX(${$sortedDescending ? '0deg' : '180deg'});
      }

      &:hover {
        color: ${({ theme }) => applyOpacity(theme.colors.gray[100], 47)};
        ${TableSortIcon} {
          opacity: 1;
        }
      }
    `}
`

export const TableRow = styled.tr<{ $isSubRow?: boolean }>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  &.${TableClassNames.HIGHLIGHTED_ROW} ${TableCell} {
    font-weight: 700;
  }

  background-color: ${({ $isSubRow, theme }) => ($isSubRow ? theme.colors.common.tableRowBg : 'inherit')};

  &:focus-within,
  &:hover {
    position: relative;
    box-shadow: ${({ theme }) => `inset 1px 0 0 ${theme.colors.gray[110]}, inset -1px 0 0 ${
      theme.colors.gray[110]
    }, 0 -1px 2px 0 ${applyOpacity(theme.colors.gray[180], 30)},
    0 1px 3px 1px ${applyOpacity(theme.colors.gray[180], 15)}`};
  }
`
export const TableHead = styled.thead`
  & > ${TableRow}:focus-within, & > ${TableRow}:hover {
    box-shadow: none;
  }
`
export const TableBody = styled.tbody``

export const TableFooter = styled.tfoot`
  & > ${TableRow}:focus-within, & > ${TableRow}:hover {
    box-shadow: none;
  }
  ${TableCell} {
    border-bottom: none;
  }
`

export type TableStyleVariant =
  | 'chartDetails'
  | 'default'
  | 'inner'
  | 'invoiceDetailsItems'
  | 'minimal'
  | 'minimalDetails'

enum TableStyleOnlyPropKeys {
  LAYOUT = 'layout',
  VARIANT = 'variant',
  SELECTABLE = 'selectable',
}

interface TableStyleOnlyProps {
  [TableStyleOnlyPropKeys.LAYOUT]?: React.CSSProperties['tableLayout']
  [TableStyleOnlyPropKeys.VARIANT]?: TableStyleVariant
  [TableStyleOnlyPropKeys.SELECTABLE]?: boolean
}

function getTableVariantColors(variant: TableStyleVariant, theme: DefaultTheme) {
  switch (variant) {
    case 'inner':
      return css`
        background-color: ${theme.colors.common.tableRowBg};
      `
    case 'default':
    default:
      return css`
        background-color: inherit;
      `
  }
}

function getVariantSpecificTableStyles(variant: Required<TableStyleOnlyProps>[TableStyleOnlyPropKeys.VARIANT]) {
  switch (variant) {
    case 'minimal':
      return css`
        ${TableCell}, ${TableHeading} {
          padding: 0 0 5px;
          border-bottom: none;
        }

        ${TableRow}:focus-within, ${TableRow}:hover {
          box-shadow: none;
        }
      `

    case 'minimalDetails':
      return css`
        ${TableHeading} {
          color: ${({ theme }) => theme.colors.gray[100]};
          font-size: 14px;
        }
        ${TableCell} {
          font-size: 12px;
          line-height: 16px;
        }

        ${TableCell}, ${TableHeading} {
          padding: 0 0 5px;
          border-bottom: none;
        }

        ${TableRow}:focus-within, ${TableRow}:hover {
          box-shadow: none;
        }
      `

    case 'chartDetails':
      return css`
        border-collapse: separate;

        ${TableRow}:focus-within, ${TableRow}:hover {
          box-shadow: none;
        }

        ${TableHeading} {
          padding: 20px 0 20px 10px;
          background-color: ${({ theme }) => theme.colors.gray[0]};
          border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
          position: sticky;
          top: 0;
          z-index: 1;
        }

        ${TableBody} {
          ${TableCell} {
            padding: 5px 0 0 10px;
            border: none;
            ${({ theme }) => getFontStyles(theme, '400-xs')}
          }
        }

        ${TableCell} {
          &:first-of-type {
            padding-left: 20px;
          }
          &:last-of-type {
            padding-right: 20px;
          }
        }
      `

    case 'invoiceDetailsItems':
      return css`
        ${TableRow}:focus-within, ${TableRow}:hover {
          box-shadow: none;
        }
        ${TableBody} {
          ${TableRow}:nth-of-type(even) {
            background-color: #e8ecf2;
          }
        }
        ${TableHeading} {
          color: ${({ theme }) => theme.colors.gray[100]};
        }
        ${TableCell} {
          font-size: 12px;
          line-height: 1.5;

          &:first-of-type {
            padding-left: 10px;
          }
          &:last-of-type {
            padding-right: 10px;
          }
        }
      `

    case 'default':
    default:
      return css``
  }
}

type TableProps = React.HTMLAttributes<HTMLTableElement> & TableStyleOnlyProps

export const Table = styled.table.withConfig<TableProps>({
  shouldForwardProp: (prop, defaultValidator) => {
    return !(Object.values(TableStyleOnlyPropKeys) as unknown[]).includes(prop) && defaultValidator(prop)
  },
})`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  table-layout: ${({ layout = 'auto' }) => layout};
  white-space: initial;

  ${({ theme, variant = 'default' }) => getTableVariantColors(variant, theme)}
  ${({ variant = 'default' }) => getVariantSpecificTableStyles(variant)}

  tbody,
  thead,
  tr,
  th,
  td {
    position: relative;
    border-spacing: 0;
    border-collapse: collapse;
  }

  ${({ selectable }) =>
    selectable &&
    css`
      ${TableHeading} {
        padding-top: 9px; // due to selector sizing difference, we need the text inline with the checkbox
      }

      th:first-of-type,
      td:first-of-type {
        padding-left: 15px;
        padding-right: 0;
      }
    `}
`
