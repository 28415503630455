import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!FETCH_LAST_SALARY',
  '!FETCH_SALARY_GROUP_PAYMENT_HISTORY_V2',
  '!FETCH_SALARY_LIST_BY_PAGING_V2',
  '!FETCH_SALARY_PAYMENT_HISTORY_V2',
  '!INIT_SALARY_LIST_PAGE_LOAD',
  '!RESET_PAGINATION',
  '!START_BULK_EXPORT_V2',
  '!STOP_BULK_EXPORT_V2',
  '!TRIGGER_SALARY_LIST_UPDATE',
  'BULK_DELETE_V2',
  'BULK_TAGGING_V2',
  'FETCH_SALARY_LIST_V2',
  'UPDATE_ORDER_V2',
  'UPDATE_ROWS_PER_PAGE_V2',
] as const

export default serviceActionsGenerator<typeof actionTypes>('salary', actionTypes)
