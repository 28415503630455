import { COMPANY_ROUTE, V2_COMPANY_ROUTE } from '@constants'

export const API_EXPENSE_ARTIFACT_DELETE_URL = `${COMPANY_ROUTE}expense/delete-file/`
export const API_EXPENSE_LIST_URL = `${COMPANY_ROUTE}expense/` // create / upload //! removed v1
export const API_EXPENSE_STATUS_CHANGE_URL = `${COMPANY_ROUTE}expense/{expense_id}/status-change/`
export const API_EXPENSE_DETAILS_URL = `${COMPANY_ROUTE}expense/{expense_id}/`
export const API_EXPENSE_DELETE_URL = `${V2_COMPANY_ROUTE}expenses/{expense_id}/delete`
export const API_EXPENSE_USER_VIEW_URL = `${COMPANY_ROUTE}expense/{expense_id}/user-saw/`
export const API_EXPENSE_DUPLICATE_REMOVE_URL = `${COMPANY_ROUTE}duplicate-of/{id}/`
// invoice payment history
export const API_EXPENSE_PAYMENT_TRANSACTIONS_URL = `${COMPANY_ROUTE}expense/{expense_id}/transactions/`
// multi upload
export const API_EXPENSE_MULTI_UPLOAD_URL = `${COMPANY_ROUTE}upload/invoice/`
// events log
export const API_EXPENSE_EVENTS_LOG_URL = `${COMPANY_ROUTE}expense/{expense_id}/events/`
// invoice recommendation by partner
export const API_INVOICE_RECOMMENDATION_URL = `${COMPANY_ROUTE}recommendation/`

export const API_EXPENSE_STOP_RECOGNITION_URL = `${COMPANY_ROUTE}expense/{expense_id}/stop-recognition/`

// expense types
export const API_EXPENSE_TYPE_URL = `${COMPANY_ROUTE}expense-type/`

// bulk update
export const API_MISSING_INVOICES_URL = `${COMPANY_ROUTE}missing-invoices/{invoice_id}/{partner_id}/{payment_method}/`

// approve
export const API_EXPENSE_APPROVE_URL = `${COMPANY_ROUTE}approve/{invoice_id}/`
export const API_EXPENSE_REMOVE_APPROVE_URL = `${COMPANY_ROUTE}approve/{invoice_id}/remove/`

// categorization
export const API_BULK_CATEGORIZATION_URL = `${COMPANY_ROUTE}expense/categorization/`

// DOWNLOAD
export const API_EXPENSE_ARTIFACT_DOWNLOAD_URL = `${COMPANY_ROUTE}expense/{invoice_id}/download/`

// CUSTOM EXPENSE DETAILS FORM VALIDATOR
export const API_INVOICE_NUMBER_CHECK_URL = `${COMPANY_ROUTE}expense/is-unique-invoice/`

//* V2 endpoints
export const V2_API_EXPENSE_LIST_URL = `${V2_COMPANY_ROUTE}expenses`

export const V2_API_EXPENSE_COUNT_URL = `${V2_COMPANY_ROUTE}expenses/count`

export const V2_API_EXPENSE_CHARTS_URL = `${V2_COMPANY_ROUTE}expenses/charts`

export const V2_API_EXPENSE_DUPLICATE_URL = `${V2_COMPANY_ROUTE}expenses/{expense_id}/duplicate-of`

export const V2_API_EXPENSES_BACKGROUND_ACTION_URL = `${V2_COMPANY_ROUTE}expenses/actions`

export const V2_API_INIT_EXPENSE_DOWNLOAD_URL = `${V2_COMPANY_ROUTE}expenses/has-mergeable-attachments`

//* last filing number
export const API_FILING_NUMBER_URL = `${V2_COMPANY_ROUTE}filing-number/`
