import { getCursorFromUrl } from '@helpers'
import { getDefaultPageSize } from '@helpers/cookie'

import { QUARANTINE_V2_DEFAULT_SORTING } from '@constants'

import expenseActions from '../expense/actions'
import filtersActions from '../filters/actions'
import actions from './actions'

const defaultPageSize = getDefaultPageSize()

export const initialState: QuarantineStore = {
  isQuarantineExpenseExists: false,
  //* V2 list
  list: {
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    pageSize: defaultPageSize,
    prevPageUrl: null,
    ...QUARANTINE_V2_DEFAULT_SORTING,
  },
  kpiCharts: {
    data: undefined,
    error: null,
    fetched: false,
    loading: false,
  },
}

// TODO later type should depend on generated actions' types [xxx]_[REQUEST|SUCCESS|FAILURE]
type ReducerAction = { type: string; payload: any }

function reducer(state = initialState, action: ReducerAction) {
  switch (action.type) {
    case filtersActions.initQuarantineListParamsFromUrl.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      }
    }

    // v2 duplication clear
    case expenseActions.deleteExpenseDuplication.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.map((quarantineInvoice: QuarantineListData) =>
            quarantineInvoice.id === action.payload.invoice
              ? { ...quarantineInvoice, badges: quarantineInvoice.badges.filter(badge => badge !== 'duplicate') }
              : quarantineInvoice
          ),
        },
      }

    //* V2 API
    case actions.fetchQuarantineListV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchQuarantineListByPagingV2.REQUEST:
    case actions.fetchQuarantineDetailsByPagingV2.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        list: {
          ...state.list,
          cursor,
          loading: true,
          fetched: false,
        },
      }
    }

    case actions.fetchQuarantineListV2.SUCCESS:
    case actions.fetchQuarantineListByPagingV2.SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          prevPageUrl: action.payload.previous,
          nextPageUrl: action.payload.next,
          loading: false,
          fetched: true,
          error: null,
          ...(action.payload.isCursorDropped && {
            cursor: action.payload.previousCursor,
          }),
        },
      }
    }

    case actions.fetchQuarantineListV2.FAILURE:
    case actions.fetchQuarantineListByPagingV2.FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      }

    case actions.updateOrderV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          order: action.payload.order,
          orderBy: action.payload.orderBy,
        },
      }

    case actions.updateRowsPerPageV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          pageSize: action.payload.pageSize,
        },
      }

    case filtersActions.toggleQuarantineListDateFilter.REQUEST:
    case filtersActions.updateQuarantineListFilters.REQUEST:
    case filtersActions.resetQuarantineListFilters.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
        },
        kpiCharts: {
          ...state.kpiCharts,
          error: null,
          loading: true,
        },
      }

    case actions.fetchQuarantineCharts.REQUEST:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          error: null,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchQuarantineCharts.SUCCESS:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          data: action.payload,
          loading: false,
          fetched: true,
        },
      }

    case actions.fetchQuarantineCharts.FAILURE:
      return {
        ...state,
        kpiCharts: {
          ...state.kpiCharts,
          loading: false,
          error: action.payload,
        },
      }

    case actions.checkQuarantineExpenseExists.SUCCESS:
      return {
        ...state,
        isQuarantineExpenseExists: action.payload.exists,
      }

    case actions.triggerQuarantineUpdateV2.REQUEST:
    case actions.triggerQuarantineListUpdate.REQUEST:
    case actions.resetPagination.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
        },
      }

    default:
      return state
  }
}

export default reducer
