import React from 'react'
import PropTypes from 'prop-types'

import { TooltipProps } from '@material-ui/core'
import styled from 'styled-components'

import Tooltip from '@oldComponents/ui/LightTooltip'

export const IconContainerSpan = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.common.darkPurple};

  & > svg {
    transform: translate(0, 2px);
  }
`

interface IconTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  icon: React.ReactChild
  title: React.ReactChild
}

export function IconTooltip({ icon, title, ...rest }: IconTooltipProps) {
  return (
    <Tooltip title={title} {...rest}>
      <IconContainerSpan>{icon}</IconContainerSpan>
    </Tooltip>
  )
}

IconTooltip.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
}
