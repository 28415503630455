import React from 'react'

import { LightTooltip } from '@oldComponents/ui'

import { PlanPermission } from '@permissions'

import { PageLink } from './types'

import { PageTabLink, PageTabUpsell } from './styles'

interface PageMenuProps {
  className?: string
  links: PageLink[]
  saveLastVisitedSubPage?: (to: string) => void
}

export function PageMenu({ className, links, saveLastVisitedSubPage }: PageMenuProps) {
  return (
    <nav className={className}>
      {links.map(({ to, label, companyPlanPermission, LabelWrapperComponent }) => {
        if (companyPlanPermission) {
          return (
            <PlanPermission
              key={to}
              perform={companyPlanPermission.perform}
              yes={() => (
                <PageTabLink to={to} onClick={() => saveLastVisitedSubPage?.(to)}>
                  {LabelWrapperComponent ? <LabelWrapperComponent>{label}</LabelWrapperComponent> : label}
                </PageTabLink>
              )}
              no={() =>
                companyPlanPermission.upsellMessage ? (
                  <LightTooltip title={companyPlanPermission.upsellMessage}>
                    <PageTabUpsell>{label}</PageTabUpsell>
                  </LightTooltip>
                ) : (
                  <PageTabUpsell>{label}</PageTabUpsell>
                )
              }
            />
          )
        }

        return (
          <PageTabLink key={to} to={to} onClick={() => saveLastVisitedSubPage?.(to)}>
            {LabelWrapperComponent ? <LabelWrapperComponent>{label}</LabelWrapperComponent> : label}
          </PageTabLink>
        )
      })}
    </nav>
  )
}
