import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'
import * as yup from 'yup'

import { ReactHookForm, ReactHookFormError, ReactHookFormSubmitButton, ReactHookFormTextField } from '@components/ui'
import { Typography } from '@components/ui/Typography'
import { PrimaryLink } from '@oldComponents/ui/links'

import { PASSWORD_REGEX } from '@constants'
import { ROUTES } from '@constants/routes'

import { formErrorMessages, globalMessages } from '@messages'
import formMessages from '@components/forms/messages'

export const resetPasswordConfirmFormMessages = defineMessages({
  resetPasswordConfirmButtonText: {
    id: 'resetPasswordConfirm.buttonText',
    defaultMessage: 'Új jelszó alkalmazása',
  },
  resetPasswordSuccessMessage: {
    id: 'resetPasswordConfirm.successResponseText',
    defaultMessage: 'Az új jelszavad beállítása megtörtént. Az alábbi linkre kattintva bejelentkezhetsz:',
  },
  goToLoginLinkText: {
    id: 'resetPasswordConfirm.goToLoginLinkText',
    defaultMessage: 'Tovább a belépéshez',
  },
})

const StyledReactHookForm = styled(ReactHookForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledSubmitButton = styled(ReactHookFormSubmitButton)`
  margin-top: 15px;
`

const StyledReactHookFormError = styled(ReactHookFormError)`
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.error[150]};
`

const StyledTypography = styled(Typography)`
  margin-top: 15px;
`

const StyledPrimaryLink = styled(PrimaryLink)`
  margin-top: 15px;
`

interface BaseResetPasswordConfirmFormProps {
  uid: string
  token: string
}

export interface ResetPasswordConfirmFormValues extends BaseResetPasswordConfirmFormProps {
  new_password: string
  confirm_password?: string
}

function useValidationSchema(): yup.SchemaOf<ResetPasswordConfirmFormValues> {
  const { formatMessage } = useIntl()

  return yup.object({
    new_password: yup
      .string()
      .test(
        'validPassword',
        formatMessage(formErrorMessages.invalidPassword),
        value => !value || PASSWORD_REGEX.test(value)
      )
      .required(formatMessage(formErrorMessages.required)),
    confirm_password: yup.string().oneOf(
      [yup.ref('new_password'), null],
      formatMessage(formErrorMessages.confirmation, {
        fieldLabel: formatMessage(globalMessages.formLabelPassword),
      })
    ),
    uid: yup.string().required(formatMessage(formErrorMessages.required)),
    token: yup.string().required(formatMessage(formErrorMessages.required)),
  })
}

interface ResetPasswordConfirmFormProps extends BaseResetPasswordConfirmFormProps {
  onSubmit: AsyncFunction<ResetPasswordConfirmFormValues, unknown>
}

export function ResetPasswordConfirmForm({ uid, token, onSubmit }: ResetPasswordConfirmFormProps) {
  const { formatMessage } = useIntl()
  const validationSchema = useValidationSchema()
  const [submitSucceeded, setSubmitSucceeded] = React.useState(false)

  const initialValues = {
    uid,
    token,
    new_password: '',
    confirm_password: '',
  }

  function onSubmitSuccess() {
    setSubmitSucceeded(true)
  }

  //TODO: display messages coming from the backend (uid, token)

  //TODO: replace primary link with new component

  return (
    <StyledReactHookForm
      autoComplete="off"
      data-testid="reset-password-confirm-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      skipUnsavedChanges
      validationSchema={validationSchema}
    >
      <ReactHookFormTextField
        name="new_password"
        label={formatMessage(globalMessages.formLabelPassword)}
        type="password"
        disabled={submitSucceeded}
        required
      />
      <ReactHookFormTextField
        name="confirm_password"
        label={formatMessage(formMessages.formLabelConfirmPassword)}
        type="password"
        disabled={submitSucceeded}
      />
      <StyledReactHookFormError hideIcon withFade />
      {submitSucceeded ? (
        <>
          <StyledTypography size="400-sm">
            {formatMessage(resetPasswordConfirmFormMessages.resetPasswordSuccessMessage)}
          </StyledTypography>
          <StyledPrimaryLink to={ROUTES.login}>
            {formatMessage(resetPasswordConfirmFormMessages.goToLoginLinkText)}
          </StyledPrimaryLink>
        </>
      ) : (
        <StyledSubmitButton
          buttonText={formatMessage(resetPasswordConfirmFormMessages.resetPasswordConfirmButtonText)}
        />
      )}
    </StyledReactHookForm>
  )
}

ResetPasswordConfirmForm.propTypes = {
  uid: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
