import React from 'react'

export default function EyeSlashedIcon({ className, size = 15 }: IconComponentProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={(size / 15) * 12}
      viewBox="0 0 15 12"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.532 2.174C4.57 1.366 5.902.75 7.5.75c1.894 0 3.41.863 4.514 1.89 1.097 1.016 1.83 2.214 2.177 3.072a.746.746 0 0 1 0 .576c-.314.757-.944 1.824-1.872 2.775l2.465 1.931a.563.563 0 0 1-.693.886L.216 1.005A.562.562 0 1 1 .91.12l2.622 2.054Zm.916.72 1.079.844a3 3 0 0 1 4.64 3.637l1.261.99A9.113 9.113 0 0 0 13.092 6a8.457 8.457 0 0 0-1.844-2.538c-.964-.893-2.22-1.608-3.748-1.608-1.184 0-2.224.437-3.052 1.04Zm4.807 3.767a1.91 1.91 0 0 0 .12-.682c0-1.015-.84-1.875-1.875-1.875-.016 0-.03.021-.068.021.052.12.068.246.068.354 0 .26-.056.485-.155.684l1.91 1.498Zm.22 3.054.983.773a6.16 6.16 0 0 1-2.958.762c-1.894 0-3.41-.863-4.514-1.89C1.89 8.324 1.156 7.126.808 6.289a.75.75 0 0 1 0-.576 8.912 8.912 0 0 1 1.14-1.927l.883.696A7.522 7.522 0 0 0 1.91 5.98c.318.724.941 1.72 1.843 2.56.964.892 2.22 1.586 3.748 1.586.72 0 1.378-.155 1.976-.41ZM4.5 5.979c0-.047.002-.113.007-.178l1.315 1.036c.246.494.705.865 1.256.97l1.317 1.057C8.112 8.932 7.812 9 7.48 9c-1.636 0-3-1.343-3-3.021h.02Z"
        fill="currentColor"
      />
    </svg>
  )
}
