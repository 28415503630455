import React from 'react'

import { useAssertContext } from '@hooks'

import { SyncFiltersConfig } from '@components/filters/types'

const PageControllerContext = React.createContext<Nullable<SyncFiltersConfig>>(null)

interface PageControllerProviderProps {
  children: JSX.Element | JSX.Element[]
  value: SyncFiltersConfig
}

export function PageControllerProvider(props: PageControllerProviderProps) {
  return <PageControllerContext.Provider {...props} />
}

export function usePageConfig() {
  return useAssertContext(PageControllerContext)
}
