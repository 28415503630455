import React from 'react'

import styled from 'styled-components'

function BaseSzamlaverzumIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.2496 9.147a1.352 1.352 0 0 0-1.3534 1.3534v2.0302h1.3534v2.0301H5.8962c-1.1208 0-2.0301-.9093-2.0301-2.0301v-2.0302c0-1.8694 1.5141-3.3835 3.3835-3.3835h13.5342c1.8694 0 3.3836 1.5141 3.3836 3.3835v2.4421l.0019.0001v1.4504l-.0019-.0003v2.8748h-2.0301v-6.7671a1.352 1.352 0 0 0-1.3535-1.3534h-10.434c.1819.4145.2834.8713.2834 1.3534v10.6174c.9562-.024 1.8727-.1031 2.7068-.2075v-.0053c.064-.0075 5.2605-.616 8.3621-1.3167 2.8874-.6523 3.6495-.9295 3.7473-.9673h.0044c3.5844-1.6368 3.9672-3.3686-.6726-4.1364V12.975c.8041.0423 1.7974.0965 2.2717.1632 6.2699.8811 6.628 3.601.7998 6.0749a23.638 23.638 0 0 1-.1585.0666 2.023 2.023 0 0 1 .5335 1.3714v2.0301c0 1.8694-1.5142 3.3836-3.3836 3.3836H11.9866c-1.8694 0-3.3836-1.5142-3.3836-3.3836v-.3462c-.9356-.0429-1.8267-.1214-2.656-.2379-6.2698-.8812-6.628-3.6011-.7997-6.075 1.0395-.4413 2.2057-.8449 3.4557-1.2053v-4.3164A1.352 1.352 0 0 0 7.2496 9.147Zm16.0559 9.474h-7.9354c-1.0295 0-1.8805.7672-2.0124 1.7608.5717-.0741 3.3829-.4429 5.4746-.7935 1.3034-.2185 3.0977-.6325 4.4732-.9673ZM8.603 16.1722c-.2117.081-.4177.164-.6175.2488-4.3712 1.8554-4.1026 3.8953.5998 4.5562l.0177.0025v-4.8075Zm2.0302 6.1735v.3355a1.352 1.352 0 0 0 1.3534 1.3534 1.352 1.352 0 0 0 1.3534-1.3534v-.531a70.593 70.593 0 0 1-2.7068.1955Zm4.7369-.4047v.7402c0 .4822-.1015.939-.2833 1.3534H25.0217a1.352 1.352 0 0 0 1.3535-1.3534v-2.0301h-2.8195a38.604 38.604 0 0 1-.1788.047s-3.565.7334-8.0068 1.2429Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const SzamlaverzumIcon = styled(BaseSzamlaverzumIcon)``
