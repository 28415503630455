import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import { API_POST_IT_DETAIL_URL, API_POST_IT_LIST_URL, API_POST_IT_TOOLTIP_URL } from './urls'

export const fetchPostIt = (companyId, { documentId, documentType }) =>
  Api.get(
    injectCompanyToURL(API_POST_IT_LIST_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', documentId)
  )

export const createPostIt = (companyId, { documentId, data, documentType }) =>
  Api.post(
    injectCompanyToURL(API_POST_IT_LIST_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', documentId),
    data
  )

export const updatePostIt = (companyId, { documentId, data, documentType }) =>
  Api.patch(
    injectCompanyToURL(API_POST_IT_DETAIL_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', documentId)
      .replace('{post_it_id}', data.id),
    data
  )

export const removePostIt = (companyId, { documentId, noteId, documentType }) =>
  Api.delete(
    injectCompanyToURL(API_POST_IT_DETAIL_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', documentId)
      .replace('{post_it_id}', noteId)
  )

export const fetchInvoicePostIts = (companyId, { documentType, documentId }) =>
  Api.get(
    injectCompanyToURL(API_POST_IT_TOOLTIP_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', documentId)
  )
