import filtersActions from '../filters/actions'
import actions from './actions'

export const initialState: MonthlyStatementStore = {
  data: {
    columns: [],
    expenses: [],
    incomes: [],
    footer: [],
  },
  kpiCharts: undefined,
  error: null,
  loading: false,
}

function reducer(state: MonthlyStatementStore = initialState, action: any): MonthlyStatementStore {
  switch (action.type) {
    case actions.fetchMonthlyStatementAnalytics.REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }

    case actions.fetchMonthlyStatementAnalytics.SUCCESS: {
      const { charts, ...data } = action.payload
      return {
        ...state,
        data,
        kpiCharts: charts,
        error: null,
        loading: false,
      }
    }

    case actions.fetchMonthlyStatementAnalytics.FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case filtersActions.resetMonthlyStatementFilters.REQUEST:
    case filtersActions.updateMonthlyStatementFilters.REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }

    default:
      return state
  }
}

export default reducer
