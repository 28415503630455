import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'

import { Currency } from '@components/ui'
import { DeleteConfirmDialog } from '@oldComponents/dialogs'

interface PaymentOrderDeleteConfirmDialogProps {
  data: Nullable<PaymentTransactionsData>
  loading: boolean
  onClose: VoidFunction
  onDelete: VoidFunction
  open: boolean
}

export function PaymentTransactionDeleteConfirmDialog({
  data,
  loading,
  onClose,
  onDelete,
  open,
}: PaymentOrderDeleteConfirmDialogProps) {
  const { formatDate } = useIntl()

  return (
    <DeleteConfirmDialog
      id="remove-payment-transaction-dialog-title"
      loading={loading}
      onClose={onClose}
      onDelete={onDelete}
      open={open}
    >
      <FormattedMessage
        id="paymentTransactions.dialogText.removePaymentTransaction"
        defaultMessage="Biztosan eltávolítja {subject} a rendszerből?"
        values={{
          subject: data ? (
            <b>
              <FormattedMessage
                id="paymentTransactions.removePaymentTransaction.name"
                defaultMessage="{date} - {partnerName} ({amount}) kifizetést"
                values={{
                  date: formatDate(data.paymentDate),
                  partnerName: data.partnerName,
                  amount: <Currency value={Number(data.amount)} currency={data.currency.name} />,
                }}
              />
            </b>
          ) : (
            '-'
          ),
        }}
      />
    </DeleteConfirmDialog>
  )
}
