import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'
import * as yup from 'yup'

import { AuthError, AuthPayload } from '@components/LoginView'
import {
  ReactHookForm,
  ReactHookFormError,
  ReactHookFormSubmitButton,
  ReactHookFormTextField,
  Typography,
} from '@components/ui'

import { formErrorMessages } from '@messages'

export const loginRestoreFormMessages = defineMessages({
  formLabel: {
    id: 'loginRestore.formLabel',
    defaultMessage: 'Biztonsági kód',
  },
  restoreButtonText: {
    id: 'loginRestore.restoreButtonText',
    defaultMessage: 'Kód ellenörzése',
  },
  restoreDescriptionText: {
    id: 'loginRestore.description',
    defaultMessage: 'Írd be a fiókodhoz tartozó biztonsági kódok valamelyikét.',
  },
})

const StyledReactHookForm = styled(ReactHookForm)`
  min-height: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`

const StyledReactHookFormError = styled(ReactHookFormError)`
  color: ${({ theme }) => theme.colors.error[150]};
`

interface LoginRestoreFormValues {
  token: string
}

function useValidationSchema(): yup.SchemaOf<LoginRestoreFormValues> {
  const { formatMessage } = useIntl()

  return yup.object({
    token: yup.string().required(formatMessage(formErrorMessages.required)),
  })
}

const INITIAL_VALUES = {
  token: '',
}

interface LoginRestoreFormSubmitValues extends LoginRestoreFormValues {
  remember_me?: boolean
  secret?: string
  step: 'backup_token'
}

export interface LoginRestoreFormProps {
  authPayload: AuthPayload
  expired: boolean
  onSubmit: AsyncFunction<LoginRestoreFormSubmitValues, unknown>
  onSubmitFail: (errors: AuthError) => void
}

export function LoginRestoreForm({ authPayload, expired, onSubmit, onSubmitFail }: LoginRestoreFormProps) {
  const validationSchema = useValidationSchema()
  const { formatMessage } = useIntl()

  function handleFormSubmit(data: LoginRestoreFormValues) {
    return onSubmit({
      ...data,
      remember_me: authPayload.remember_me,
      secret: authPayload.secret,
      step: 'backup_token',
    })
  }

  return (
    <StyledReactHookForm
      initialValues={INITIAL_VALUES}
      onSubmit={handleFormSubmit}
      onSubmitFail={onSubmitFail}
      skipUnsavedChanges
      validationSchema={validationSchema}
    >
      <ReactHookFormTextField name="token" label={formatMessage(loginRestoreFormMessages.formLabel)} />

      <StyledReactHookFormError hideIcon withFade />

      {expired ? (
        <></> //here because of how ReactHookForm's childrens work (can't be falsy)
      ) : (
        <>
          <Typography size="400-sm" align="left">
            {formatMessage(loginRestoreFormMessages.restoreDescriptionText)}
          </Typography>
          <ReactHookFormSubmitButton
            buttonText={formatMessage(loginRestoreFormMessages.restoreButtonText)}
            size="large"
          />
        </>
      )}
    </StyledReactHookForm>
  )
}

LoginRestoreForm.propTypes = {
  authPayload: PropTypes.shape({
    remember_me: PropTypes.bool,
    secret: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
}
