import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { StrongTextValues } from '@helpers/translation'

import { OverflowText, Typography } from '@components/ui'

import { PagingCounter, PagingCounterProps } from './PagingCounter'
import { usePagingCounter } from './usePagingCounter'

const StyledTypography = styled(Typography)`
  overflow: hidden;
`

const InvoiceHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.appBarHeight}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  gap: 20px;
`

interface ArtifactViewerProps extends Omit<PagingCounterProps, 'currentPosition'> {
  children?: React.ReactNode
  currentPage: number
  documentId?: ItemIdType
  fileName?: string
  invoices: ExpenseListData[]
  pageSize: number
}

/**
 * Displays the document ID and filename of the current artifact being viewed.
 * It also renders the `PagingCounter` component.
 */
function PureArtifactViewerHeader({
  children,
  countLoading,
  currentPage,
  documentId,
  fileName,
  invoices,
  pageSize,
  totalCount,
}: ArtifactViewerProps) {
  const { currentPosition, showPagingCounterCondition } = usePagingCounter({
    currentPage,
    invoices,
    pageSize,
    totalCount,
  })

  return (
    <>
      {documentId != null && (
        <InvoiceHeaderContainer>
          <StyledTypography color="gray-80" size="400-sm">
            <OverflowText>
              {fileName != null ? (
                <FormattedMessage
                  id="artifactViewer.header.invoiceIdWithFileName"
                  defaultMessage={'<b>Számla ID:</b> {invoiceId} - {fileName}'}
                  values={{
                    ...StrongTextValues,
                    invoiceId: documentId,
                    fileName,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="artifactViewer.header.invoiceId"
                  defaultMessage={'<b>Számla ID:</b> {invoiceId}'}
                  values={{ ...StrongTextValues, invoiceId: documentId }}
                />
              )}
            </OverflowText>
          </StyledTypography>
          {showPagingCounterCondition && (
            <PagingCounter currentPosition={currentPosition} countLoading={countLoading} totalCount={totalCount} />
          )}
        </InvoiceHeaderContainer>
      )}
      {children}
    </>
  )
}

export const ArtifactViewerHeader = connect((state: Store) => ({
  countLoading: state.expense.list.countLoading,
  currentPage: state.expense.list.page,
  invoices: state.expense.list.data,
  pageSize: state.expense.list.pageSize,
  totalCount: state.expense.list.count,
}))(PureArtifactViewerHeader)
