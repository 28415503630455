export const BUTTON_SIZE = {
  SMALL: 'small',
  NORMAL: 'normal',
  LARGE: 'large',
} as const

export const BUTTON_VARIANT = {
  PRIMARY_CONTAINED: 'primaryContained',
  PRIMARY_TEXT: 'primaryText',
  SECONDARY_CONTAINED: 'secondaryContained',
  SECONDARY_TEXT: 'secondaryText',
  ALERT: 'alert',
  ALERT_TEXT: 'alertText',
  PRIMARY_CONTAINED_TRANSPARENT: 'primaryContainedTransparent',
} as const

export const ICON_SIZE = {
  [BUTTON_SIZE.SMALL]: 24,
  [BUTTON_SIZE.NORMAL]: 24,
  [BUTTON_SIZE.LARGE]: 28,
} as const
