import React from 'react'

import cx from 'classnames'
import { connect } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

import { getTransactionsSubRoute, TransactionsSubRouteProps } from '@helpers'

import { NavLink, NoLinkTooltip } from '@components/navigations'
import { NavLinkTooltipVariant } from '@components/navigations/constants'

import { COMPANY_USER_PERMISSIONS as TAB_PERMISSIONS, RouteKeys, ROUTES } from '@constants'

import {
  denyTransactionsPermission,
  isPlanPermissionEnabled,
  PagePermissions,
  permissionDeniedForUser,
} from '@permissions'

import { DokumentsPageMessage, TransactionsPageMessage } from '@messages'
import { useStyles } from '../styles'

function getNavLinkClassName(cssClassName?: string, displayAsActive?: boolean) {
  return function getter({
    isActive,
    isPending,
    isTransitioning,
  }: Record<'isActive' | 'isPending' | 'isTransitioning', boolean>) {
    return cx(cssClassName, {
      active: isActive || displayAsActive,
      pending: isPending,
      transitioning: isTransitioning,
    })
  }
}
function isNavLinkActive(pathname: string) {
  const matchDokuments = matchPath(ROUTES[RouteKeys.DOKUMENT_LIST], pathname)
  const matchDokumentDetails = matchPath(`${ROUTES[RouteKeys.DOKUMENT]}/:document_id`, pathname)
  const matchDokumentsMulti = matchPath(ROUTES[RouteKeys.DOCUMENTS_MULTI_UPLOAD], pathname)
  const matchExpenseCreate = matchPath(ROUTES[RouteKeys.COST], pathname)
  const matchExpenseDetails = matchPath(`${ROUTES[RouteKeys.COST]}/:invoice_id`, pathname)
  const matchExpenseList = matchPath(ROUTES[RouteKeys.EXPENSE_LIST], pathname)
  const matchExpenseMulti = matchPath(ROUTES[RouteKeys.COST_MULTI_UPLOAD], pathname)
  const matchIncomeDetails = matchPath(`${ROUTES[RouteKeys.INCOME]}/:invoice_id?`, pathname) // it handles create as well, do not cross match because there is no "income-multi-upload"
  const matchIncomeList = matchPath(ROUTES[RouteKeys.INCOME_LIST], pathname)
  const matchQuarantineDetails = matchPath(`${ROUTES[RouteKeys.QUARANTINE]}/:invoice_id?`, pathname)
  const matchQuarantineList = matchPath(ROUTES[RouteKeys.QUARANTINE_LIST], pathname)
  const matchSalaryList = matchPath(ROUTES[RouteKeys.SALARY_LIST], pathname)
  const matchTaxList = matchPath(ROUTES[RouteKeys.TAX_LIST], pathname)

  return Boolean(
    matchDokuments ||
      matchDokumentDetails ||
      matchDokumentsMulti ||
      matchExpenseCreate ||
      matchExpenseDetails ||
      matchExpenseList ||
      matchExpenseMulti ||
      matchIncomeDetails ||
      matchIncomeList ||
      matchQuarantineDetails ||
      matchQuarantineList ||
      matchSalaryList ||
      matchTaxList
  )
}

interface TransactionsNavLinkProps extends TransactionsSubRouteProps {
  hasSubscription: boolean
  isDokumentsPageEnabled: boolean
  lastVisitedSubPage?: string
}

function PureTransactionsNavLink({
  hasSubscription,
  isDokumentsPageEnabled,
  isDokumentsVisibleForUser,
  isExpenseVisibleForUser,
  isIncomeVisibleForUser,
  isQuarantineVisibleForUser,
  isSalaryVisibleForUser,
  isTaxVisibleForUser,
  lastVisitedSubPage,
  tabOrder,
}: TransactionsNavLinkProps) {
  const classes = useStyles()
  const location = useLocation()

  const isActive = React.useMemo(() => {
    return isNavLinkActive(location.pathname)
  }, [location.pathname])

  const subPageUrl = getTransactionsSubRoute({
    isDokumentsVisibleForUser: isDokumentsPageEnabled && isDokumentsVisibleForUser,
    isExpenseVisibleForUser,
    isIncomeVisibleForUser,
    isQuarantineVisibleForUser,
    isSalaryVisibleForUser,
    isTaxVisibleForUser,
    tabOrder,
  })

  // when no subpage is available
  if (subPageUrl == null) {
    if (!isDokumentsPageEnabled) {
      return (
        <NoLinkTooltip variant={NavLinkTooltipVariant.UPSELL_MULTIBOX}>
          <span className={classes.link}>{DokumentsPageMessage}</span>
        </NoLinkTooltip>
      )
    }
    return null
  }

  return (
    <NavLink
      to={lastVisitedSubPage ?? subPageUrl}
      className={getNavLinkClassName(classes.navLink, isActive)}
      noSubscription={!hasSubscription}
      permission={denyTransactionsPermission}
    >
      <span className={classes.link}>{TransactionsPageMessage}</span>
    </NavLink>
  )
}

export const TransactionsNavLink = connect((state: Store) => ({
  isDokumentsPageEnabled: isPlanPermissionEnabled(state, PagePermissions.DOKUMENTS),
  isDokumentsVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.dokuments),
  isExpenseVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.expense),
  isIncomeVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.income),
  isQuarantineVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.quarantine),
  isSalaryVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.salary),
  isTaxVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.tax),
  lastVisitedSubPage: state.controls.lastVisitedSubPage[RouteKeys.TRANSACTIONS],
  tabOrder: state.auth.user.preferences.tab_order,
}))(PureTransactionsNavLink)

TransactionsNavLink.displayName = 'TransactionsNavLink'
