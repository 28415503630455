import React from 'react'
import PropTypes from 'prop-types'

import { TooltipProps } from '@material-ui/core'
import styled, { css } from 'styled-components'

import { PAID_INSTALLMENT, PAID_YES } from '@constants'

import { IndicatorWrapper, StyledLightTooltip } from './styles'

export interface PaidStatusIndicatorProps extends Pick<TooltipProps, 'title' | 'onClose' | 'onOpen'> {
  className?: string
  value: InvoicePaidStatusValue
  placedInModal?: boolean
}

const StyledIndicatorSpan = styled('span').withConfig<{ value: InvoicePaidStatusValue }>({
  shouldForwardProp: (prop, defaultValidator) => {
    return !['value'].includes(prop) && defaultValidator(prop)
  },
})`
  display: inherit;
  width: 16px;
  height: 16px;
  background-color: ${({ theme, value }) =>
    value === PAID_YES ? theme.colors.success[150] : theme.colors.common.inactive};
  border-radius: 50%;
  margin: auto;

  ${({ theme, value }) =>
    value === PAID_INSTALLMENT &&
    css`
      position: relative;
      &:before {
        display: block;
        content: ' ';
        position: absolute;
        bottom: 0;
        height: 8px;
        width: 16px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        background-color: ${theme.colors.success[150]};
      }
    `}
`

export function PaidStatusIndicator({ className, value, ...rest }: PaidStatusIndicatorProps) {
  return (
    <StyledLightTooltip {...rest} interactive placement="left">
      <IndicatorWrapper className={className}>
        <StyledIndicatorSpan value={value} />
      </IndicatorWrapper>
    </StyledLightTooltip>
  )
}

PaidStatusIndicator.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  value: PropTypes.oneOf([1, 2, 3]).isRequired as React.Validator<InvoicePaidStatusValue>,
  title: PropTypes.node.isRequired,
}
