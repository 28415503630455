import React from 'react'

import __uniqueId from 'lodash/uniqueId'

const BOLD_REGEX = /\*\*(.*?)\*\*/g
const ITALIC_REGEX = /\*(.*?)\*/g

//TODO: fix this function to handle nested bold and italic, or use a library to handle markdown

/**
 * This function takes a string and replaces substrings wrapped in '**' with a <strong> element,
 * and substrings wrapped in '*' with an <em> element.
 * Essentially, it converts markdown-style bold and italic syntax into HTML <strong> and <em> tags.
 *
 * @param {string} text - The text to be processed.
 * @return {JSX.Element} - A JSX fragment where substrings wrapped in '**' are replaced with <strong> elements,
 *                          and substrings wrapped in '*' are replaced with <em> elements.
 */
export function convertMarkdownToHtml(text: string, idPrefix: string): JSX.Element {
  if (!text) {
    return <></>
  }

  const boldParts = text.split(BOLD_REGEX)

  return (
    <>
      {boldParts.map((part, index) => {
        if (index % 2 === 1) {
          return <strong key={`${idPrefix}bold_${index}`}>{convertMarkdownItalicToEm(part, idPrefix)}</strong>
        }
        return convertMarkdownItalicToEm(part, idPrefix)
      })}
    </>
  )
}

function convertMarkdownItalicToEm(text: string, idPrefix: string): JSX.Element {
  const parts = text.split(ITALIC_REGEX)
  return (
    <>
      {parts.map((part, index) => {
        if (index % 2 === 1) {
          return <em key={`${idPrefix}em_${index}`}>{part}</em>
        }
        return part
      })}
    </>
  )
}

export function MarkdownText({ text }: { text: string }) {
  const markdownIdPrefix = __uniqueId('markdown_')

  return <>{convertMarkdownToHtml(text, markdownIdPrefix)}</>
}
