import React from 'react'
import PropTypes from 'prop-types'

import AttachFileIcon from '@material-ui/icons/AttachFile'
import { defineMessages, useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import { DOC_VIEWER_CONFIG, PortalProvider, SUPPORTED_PLUGIN_RENDERERS, usePortal } from '@contexts'

import { ArtifactPreviewControlsDiv } from '@components/ArtifactViewer/elements'
import { ParentSize } from '@components/ui'
import { IconButton } from '@components/ui/Buttons'
import { TrashRegularIcon } from '@components/ui/svgIcons'
import { Typography } from '@components/ui/Typography'
import { FlexColumn, FlexRow, Paper } from '@components/ui/Wrappers'
import { LightTooltip } from '@oldComponents/ui'

import { DOC_PREVIEW_CONFIG } from '@constants'

import DocViewer from './DocViewer'
import { isSupportedFileForPreview } from './helpers'

const messages = defineMessages({
  title: {
    id: 'uploadPreview.remove.title',
    defaultMessage: 'Feltöltött fájl eltávolítása',
  },
})

const UploadPreviewWrapper = styled(FlexColumn)`
  width: 100%;
  height: 100%;
`

const StyledPaper = styled(Paper)`
  margin-top: 25px;
`

const Attachment = styled(FlexRow)<{ $hasPreview: boolean }>`
  gap: 10px;
  align-items: center;
  padding: 5px 10px;
  height: 48px;

  ${({ $hasPreview }) =>
    $hasPreview
      ? css`
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
        `
      : css`
          border-radius: 4px;
          margin-top: 25px;
          border: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
        `}
`

const PreviewContainer = styled.div`
  position: relative;
`

const AttachedFileTypography = styled(Typography)`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  flex: 1;
`

const PreviewControlsDiv = styled(ArtifactPreviewControlsDiv)`
  justify-content: flex-end;
`

function truncateFileName(name: string) {
  const size = 40 // config variable
  if (name.length > size) {
    return `${name.substring(0, size - 18)}...${name.substring(name.length - 15)}`
  }
  return name
}

interface AttachedFileProps {
  name: string
}

function AttachedFile({ name }: AttachedFileProps) {
  return (
    <AttachedFileTypography size="700-md" color="gray-80">
      <AttachFileIcon color="inherit" fontSize="small" />
      {truncateFileName(name)}
    </AttachedFileTypography>
  )
}

AttachedFile.propTypes = { name: PropTypes.string.isRequired }

function PortalAnchorElement() {
  const { setPortalAnchorEl } = usePortal()
  return <PreviewControlsDiv ref={setPortalAnchorEl} />
}

interface UploadPreviewProps {
  file: File
  fileUrl: string
  maxHeight?: number
  noPreview?: boolean
  onClear: VoidFunction
  withPaper?: boolean
}

export function UploadPreview({
  fileUrl,
  file,
  onClear,
  withPaper = false,
  maxHeight,
  noPreview = false,
}: UploadPreviewProps) {
  const { formatMessage } = useIntl()

  const isSupported = !noPreview && isSupportedFileForPreview(file.name)

  // important to keep fileData during window resize
  const memoizedDocuments = React.useMemo(
    () => [
      {
        uri: fileUrl,
      },
    ],
    [fileUrl]
  )

  function renderWithPaper(content: JSX.Element, skip: boolean) {
    if (!withPaper || skip) {
      return content
    }
    return <StyledPaper elevation={0}>{content}</StyledPaper>
  }

  return (
    <UploadPreviewWrapper>
      {renderWithPaper(
        <>
          <Attachment $hasPreview={isSupported}>
            <AttachedFile name={file.name} />
            <LightTooltip
              title={formatMessage(messages.title)}
              PopperProps={{ disablePortal: true, placement: 'left' }}
            >
              <IconButton size="small" onClick={onClear}>
                <TrashRegularIcon />
              </IconButton>
            </LightTooltip>
          </Attachment>
          {isSupported && (
            <ParentSize>
              {({ height, width }) => (
                <PreviewContainer>
                  <PortalProvider>
                    <DocViewer
                      documents={memoizedDocuments}
                      pluginRenderers={SUPPORTED_PLUGIN_RENDERERS}
                      config={{
                        ...DOC_VIEWER_CONFIG,
                        viewerSize: {
                          maxHeight: maxHeight ?? height - DOC_PREVIEW_CONFIG.controlsBarHeight,
                          maxWidth: width,
                        },
                        hideFileControls: true,
                      }}
                    />
                    <PortalAnchorElement />
                  </PortalProvider>
                </PreviewContainer>
              )}
            </ParentSize>
          )}
        </>,
        !isSupported
      )}
    </UploadPreviewWrapper>
  )
}

UploadPreview.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  file: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  maxHeight: PropTypes.number,
  noPreview: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  withPaper: PropTypes.bool,
}
