import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import ContentWithLoadingOverlay from '@components/ui/ContentWithLoadingOverlay'
import { Typography } from '@components/ui/Typography'
import { Paper } from '@components/ui/Wrappers'

const CardHeaderTypography = styled(Typography)`
  padding: var(--card-padding);
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledContentWithLoadingOverlay = styled(ContentWithLoadingOverlay)``

export const StyledCard = styled(Paper)<{ $withoutPadding: boolean }>`
  --card-padding: 15px 20px;

  & > ${StyledContentWithLoadingOverlay} {
    padding: ${({ $withoutPadding }) => ($withoutPadding ? '0' : 'var(--card-padding)')};
  }
`

interface CardProps {
  children: React.ReactNode
  headingTag?: 'h2' | 'h3'
  icon: React.ReactNode
  loading?: boolean
  title: React.ReactChild | React.ReactChild[]
  withoutPadding?: boolean
}

export function Card({ children, headingTag = 'h2', icon, loading = false, title, withoutPadding = false }: CardProps) {
  return (
    <StyledCard as="article" $withoutPadding={withoutPadding}>
      <CardHeaderTypography tag={headingTag} size="heading-5" color="gray-40">
        {icon} {title}
      </CardHeaderTypography>
      <StyledContentWithLoadingOverlay loading={loading}>{children}</StyledContentWithLoadingOverlay>
    </StyledCard>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  headingTag: PropTypes.oneOf(['h2', 'h3']),
  icon: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.node.isRequired,
  withoutPadding: PropTypes.bool,
}
