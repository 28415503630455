import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { expenseActions } from '@services'

import { Button } from '@components/ui'

import { StopRecognitionButtonProps } from './types'

function PureStopRecognitionButton({ cancelUploadProcess }: StopRecognitionButtonProps) {
  return (
    <Button variant="primaryContained" onClick={cancelUploadProcess}>
      <FormattedMessage id="buttons.stopRecognition" defaultMessage="Megszakítás" />
    </Button>
  )
}

PureStopRecognitionButton.propTypes = {
  cancelUploadProcess: PropTypes.func.isRequired,
}

export const StopRecognitionButton = connect(null, {
  cancelUploadProcess: expenseActions.cancelUploadExpenseProcess.request,
})(PureStopRecognitionButton)

StopRecognitionButton.displayName = 'StopRecognitionButton'
