import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { DATE_WIDGET_VARIANTS } from '../constants'
import { DateTypeOption } from '../types'

const useStyles = makeStyles((theme: any) => ({
  formControlRoot: {
    margin: 0,
    whiteSpace: 'nowrap',
  },
  formcontrolLabel: ({ variant }: Pick<DateTypeSelectorProps, 'variant'>) =>
    variant === DATE_WIDGET_VARIANTS.list
      ? {
          fonstSize: 12,
          fontWeight: 700,
          color: theme.palette.neutral.mDark,
        }
      : {
          fontSize: 14,
          color: theme.palette.grayscale.black,
        },
  group: ({ variant }: Pick<DateTypeSelectorProps, 'variant'>) =>
    variant === DATE_WIDGET_VARIANTS.list
      ? {
          padding: '10px 0',

          '& label + label': {
            marginTop: 10,
          },
        }
      : {
          padding: 20,
          borderBottom: `1px solid ${theme.palette.tertiary.paperStroke}`,

          '& label + label': {
            marginTop: 10,
          },
        },
  input: {
    padding: 0,
    marginRight: 10,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grayscale.black,
    fontSize: 12,
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },
}))

interface DateTypeSelectorProps {
  onChange: (values: Record<'dateField', string>) => void
  options: DateTypeOption[]
  value: string
  variant: ConstObjectValues<typeof DATE_WIDGET_VARIANTS>
}

export default function DateTypeSelector({ options, value, onChange, variant }: DateTypeSelectorProps) {
  const classes = useStyles({ variant })
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ dateField: event.target.value })
  }

  return (
    <>
      {variant === DATE_WIDGET_VARIANTS.list && (
        <Typography variant="h4" className={classes.title}>
          <FormattedMessage id="dateTypeSelector.title" defaultMessage="Dátum típusa" />
        </Typography>
      )}
      <RadioGroup className={classes.group} onChange={handleChange} value={value}>
        {options.map(option => (
          <FormControlLabel
            classes={{ label: classes.formcontrolLabel, root: classes.formControlRoot }}
            control={<Radio color="primary" className={classes.input} size="small" />}
            key={option.key}
            label={option.label}
            value={option.key}
          />
        ))}
      </RadioGroup>
    </>
  )
}

DateTypeSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(DATE_WIDGET_VARIANTS)).isRequired,
}
