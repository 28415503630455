import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'
import * as yup from 'yup'

import { ReactHookForm, ReactHookFormError, ReactHookFormSubmitButton, ReactHookFormTextField } from '@components/ui'
import { Typography } from '@components/ui/Typography'

import { formErrorMessages, globalMessages } from '@messages'

export const resetPasswordFormMessages = defineMessages({
  resetPasswordButtonText: {
    id: 'resetPassword.buttonText',
    defaultMessage: 'Új jelszó',
  },
  resetPasswordSuccessMessage: {
    id: 'resetPassword.successResponseText',
    defaultMessage: 'További teendőidet elküldtük a megadott e-mail címre',
  },
})

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`

interface ResetPasswordFormValues {
  email: string
}

const INITIAL_VALUES = {
  email: '',
}

function useValidationSchema(): yup.SchemaOf<ResetPasswordFormValues> {
  const { formatMessage } = useIntl()

  return yup.object({
    email: yup
      .string()
      .email(formatMessage(formErrorMessages.email))
      .required(formatMessage(formErrorMessages.required)),
  })
}

export interface ResetPasswordFormProps {
  onSubmit: AsyncFunction<ResetPasswordFormValues, unknown>
}

export function ResetPasswordForm({ onSubmit }: ResetPasswordFormProps) {
  const [submitted, setSubmitted] = React.useState(false)
  const { formatMessage } = useIntl()
  const validationSchema = useValidationSchema()

  function onSubmitSuccess() {
    setSubmitted(true)
  }

  return (
    <ReactHookForm
      data-testid="reset-password-form"
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      skipUnsavedChanges
      validationSchema={validationSchema}
    >
      <ContainerDiv>
        <ReactHookFormTextField
          name="email"
          disabled={submitted}
          label={formatMessage(globalMessages.formLabelEmail)}
          required
        />

        <ReactHookFormError hideIcon withFade />

        {submitted ? (
          <Typography size="400-sm">{formatMessage(resetPasswordFormMessages.resetPasswordSuccessMessage)}</Typography>
        ) : (
          <ReactHookFormSubmitButton buttonText={formatMessage(resetPasswordFormMessages.resetPasswordButtonText)} />
        )}
      </ContainerDiv>
    </ReactHookForm>
  )
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
