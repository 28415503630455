import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { Field, Form, FormRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { required } from 'redux-form-validators'

import {
  accountNumberFormatter,
  recommendationMessages,
  renderRecommendationTextField,
  taxNumberFormatter,
} from '@helpers'

import { SubmitButton, WarningText } from '@oldComponents/ui'
import { RenderRffCheckbox, RenderTextField } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION, ZIP_CITY_ADDRESS_REGEX } from '@constants'

import { ReadOnlyField } from './elements'
import { generateOptions } from './helpers'
import { RenderSearchField } from './RenderSearchField'
import { InitialValues } from './types'

import { formStyles } from '@styles'
import { formErrorMessages } from '@messages'
import fieldMessages from '@oldComponents/forms/fields/ReduxFormFields/messages'
import messages from './formMessages'
import { partnerEditorStyles } from './styles'

const useStyles = makeStyles(partnerEditorStyles)
// TODO refact it later - Field must have its own classes, do not pass it as prop
const useFieldStyles = makeStyles(formStyles)

function onCreateNewOption(val: string) {
  return {
    label: val,
    name: val,
    tax_number: '',
  }
}

function updateFields({ change, batch }: FormRenderProps<InitialValues>['form']) {
  function serializeCegjelzoAddress(addressString: string): Partial<InitialValues> {
    // return groups [[full address], [zipcode], [city], [address line]]
    const matched = addressString.match(ZIP_CITY_ADDRESS_REGEX)
    if (!matched) {
      return {}
    }
    const [, zip_code, city, address] = matched
    return {
      zip_code,
      city,
      address,
    }
  }

  return function updater(val: { address: string; tax_number: string }) {
    batch(() => {
      change('tax_number', taxNumberFormatter.parse(val.tax_number))
      if (val.address && typeof val.address === 'string') {
        const addressParts = serializeCegjelzoAddress(val.address)
        Object.keys(addressParts).forEach(
          fieldName => addressParts[fieldName] && change(fieldName, addressParts[fieldName])
        )
      }
    })
  }
}

interface PartnerEditorFormProps {
  alternateNames?: React.ReactNode
  callCegjelzoOnInit?: boolean
  initialValues: InitialValues
  isEditDisabled?: boolean
  isPrefilled?: boolean
  locked?: boolean
  method?: 'create' | 'edit'
  onSubmit: AsyncFunction<InitialValues, unknown>
  recommendations?: {
    taxNumber: string[]
    bankAccount: string[]
  }
  variant?: 'normal' | 'modal'
}

export default function PartnerEditorForm({
  alternateNames,
  callCegjelzoOnInit,
  initialValues,
  isEditDisabled = false,
  isPrefilled = false,
  locked = false,
  method = 'create',
  onSubmit,
  recommendations = {
    taxNumber: [],
    bankAccount: [],
  },
  variant = 'normal',
}: PartnerEditorFormProps) {
  const classes = useStyles()
  const fieldClasses = useFieldStyles()
  const { formatMessage } = useIntl()
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={values => {
        const errors: Partial<{ [Key in keyof InitialValues]: string }> = {}
        if (!values.is_provider && !values.is_customer) {
          errors.is_customer_or_provider = formatMessage(formErrorMessages.partnerIsCustomerOrProvider)
        }
        return errors
      }}
    >
      {({ handleSubmit, pristine, submitting, dirtySinceLastSubmit, error, form, submitError }) => (
        <form onSubmit={handleSubmit} autoComplete="off" className={cx(classes.root, variant)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                callback={updateFields(form)}
                classes={fieldClasses}
                component={RenderSearchField as any}
                createLabelText={formatMessage(fieldMessages.asyncCegjelzoCreateLabelText)}
                defaultOptions={callCegjelzoOnInit}
                disabled={isEditDisabled || locked || submitting}
                label={formatMessage(messages.partnerNameLabel)}
                labelKey="name"
                loadingText={formatMessage(fieldMessages.asyncLoadingText)}
                name="name"
                noResultsText={formatMessage(fieldMessages.asyncNoResultsText)}
                onCreate={onCreateNewOption}
                placeholder={formatMessage(fieldMessages.asyncSelectPlaceholder)}
                validate={required({ message: formErrorMessages.required })}
                valueKey="name"
                subscription={FIELD_SUBSCRIPTION}
              />

              {alternateNames}
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Field
                classes={fieldClasses}
                component={renderRecommendationTextField as any}
                disabled={isEditDisabled || locked || submitting}
                format={taxNumberFormatter.format}
                label={formatMessage(messages.taxNumberLabel)}
                name="tax_number"
                parse={taxNumberFormatter.parse}
                options={generateOptions(
                  recommendations.taxNumber,
                  formatMessage(recommendationMessages.recommendationsLabel)
                )}
                subscription={FIELD_SUBSCRIPTION}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={RenderTextField}
                disabled={isEditDisabled || locked || submitting}
                label={formatMessage(messages.partnerZipCodeLabel)}
                name="zip_code"
                subscription={FIELD_SUBSCRIPTION}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Field
                component={RenderTextField}
                disabled={isEditDisabled || locked || submitting}
                label={formatMessage(messages.partnerCountryLabel)}
                name="country"
                subscription={FIELD_SUBSCRIPTION}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={RenderTextField}
                disabled={isEditDisabled || locked || submitting}
                label={formatMessage(messages.partnerCityLabel)}
                name="city"
                subscription={FIELD_SUBSCRIPTION}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={RenderTextField}
                disabled={isEditDisabled || locked || submitting}
                label={formatMessage(messages.partnerAddressLabel)}
                name="address"
                subscription={FIELD_SUBSCRIPTION}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Field
                color="primary"
                render={RenderRffCheckbox}
                disabled={locked || submitting}
                label={formatMessage(messages.partnerProviderLabel)}
                name="is_provider"
                type="checkbox"
                subscription={FIELD_SUBSCRIPTION}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                color="primary"
                render={RenderRffCheckbox}
                disabled={locked || submitting}
                label={formatMessage(messages.partnerCustomerLabel)}
                name="is_customer"
                type="checkbox"
                subscription={FIELD_SUBSCRIPTION}
              />
            </Grid>

            <Field
              name="is_customer_or_provider"
              component={({ meta: { touched, error } }) =>
                touched ? (
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography color="error" variant="caption" component="p" align="left">
                      {error}
                    </Typography>
                  </Grid>
                ) : null
              }
            />
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <WarningText className={classes.warning}>
                <FormattedMessage
                  id="partner.editor.defaultAccountNumberWarning"
                  defaultMessage="Ha kitöltöd az alapértelmezett bankszámlaszámot, új utalási csomag létrehozásakor mindig ezt fogjuk használni ehhez a partnerhez."
                />
              </WarningText>
              <Field
                classes={fieldClasses}
                component={renderRecommendationTextField as any}
                disabled={locked || submitting}
                format={accountNumberFormatter.format}
                label={formatMessage(messages.defaultAccountNumberLabel)}
                name="default_account_number"
                parse={accountNumberFormatter.parse}
                options={generateOptions(
                  recommendations.bankAccount,
                  formatMessage(recommendationMessages.recommendationsLabel)
                )}
                positioner={accountNumberFormatter.positioner}
                subscription={FIELD_SUBSCRIPTION}
              />
            </Grid>
          </Grid>

          {method === 'edit' && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  component={ReadOnlyField}
                  format={accountNumberFormatter.format}
                  label={formatMessage(messages.partnerAccountNumberLabel)}
                  name="account_number"
                  subscription={FIELD_SUBSCRIPTION}
                />
              </Grid>
            </Grid>
          )}

          {(error || (!dirtySinceLastSubmit && submitError)) && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography color="error" variant="caption" component="p" align="left">
                  {error || submitError}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={4}>
            <Grid item>
              <SubmitButton
                loading={submitting}
                loadingText={
                  <FormattedMessage id="forms.button.partnerSubmitting" defaultMessage="Mentés folyamatban..." />
                }
                buttonText={<FormattedMessage id="forms.button.partnerSubmit" defaultMessage="Mentés" />}
                disabled={locked || (pristine && !isPrefilled)}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
}

PartnerEditorForm.propTypes = {
  alternateNames: PropTypes.node,
  callCegjelzoOnInit: PropTypes.bool,
  initialValues: PropTypes.shape({
    account_number: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    default_account_number: PropTypes.string,
    id: PropTypes.number,
    is_customer_or_provider: PropTypes.bool,
    is_customer: PropTypes.bool.isRequired,
    is_provider: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    tax_number: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
  }),
  isEditDisabled: PropTypes.bool,
  isPrefilled: PropTypes.bool,
  locked: PropTypes.bool,
  method: PropTypes.oneOf(['create', 'edit']),
  onSubmit: PropTypes.func.isRequired,
  recommendations: PropTypes.shape({
    bankAccount: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    taxNumber: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  variant: PropTypes.oneOf(['modal', 'normal']),
}
