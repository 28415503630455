import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import Dropzone from 'react-dropzone'
import { FormattedMessage } from 'react-intl'

import formatBytes from '@helpers/bytes'

import defaultUploadImgSrc from '@assets/img/scan_upload_folder.svg'

import { FileDropzoneFontSizes, FileDropzoneProps, FileDropzoneVariants } from './types'

import { styles } from './styles'

const useStyles = makeStyles(styles)

function getDropzoneButtonText(variant: FileDropzoneVariants) {
  switch (variant) {
    case FileDropzoneVariants.SMALL:
      return <FormattedMessage id="fileUpload.buttons.browseAlt" defaultMessage="Kiválaszt" />

    case FileDropzoneVariants.DEFAULT:
    default:
      return <FormattedMessage id="fileUpload.buttons.browse" defaultMessage="Fájl kiválasztása" />
  }
}

function FileDropzone({
  acceptedFileMimeTypes,
  className,
  customUploadingExtraContent,
  customUploadingText,
  error,
  fileUploadText = (
    <FormattedMessage
      id="multiFileUpload.texts.upload"
      defaultMessage="Húzd ide a fájlokat, vagy válaszd ki manuálisan."
    />
  ),
  maxSize = 0,
  multiple = false,
  uploadImgSrc = defaultUploadImgSrc,
  uploading,
  uploadSuggestionText,
  onDrop,
  variant = FileDropzoneVariants.DEFAULT,
  fontSize = FileDropzoneFontSizes.DEFAULT,
  ...restDropzoneProps
}: FileDropzoneProps) {
  const classes = useStyles({ variant, fontSize })

  function manualOnClick(event: React.MouseEvent<HTMLButtonElement>) {
    // to avoid opening the file selector twice and still be accessible
    event.preventDefault()
  }

  const isMaxSizeVisible = maxSize > 0 && variant === FileDropzoneVariants.DEFAULT

  return (
    <Dropzone
      multiple={multiple}
      accept={acceptedFileMimeTypes}
      onDrop={onDrop}
      disabled={uploading}
      {...(maxSize ? { maxSize } : {})}
      {...restDropzoneProps}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div
          data-testid="dropzone"
          {...getRootProps({
            className: cx(className, classes.dropzone, {
              active: isDragActive,
              rejected: isDragReject,
            }),
          })}
        >
          {!uploading && !error && (
            <div className={cx(classes.uploadBox, classes.initialView)}>
              {uploadImgSrc && variant === FileDropzoneVariants.DEFAULT && (
                <img role="presentation" alt="" src={uploadImgSrc} />
              )}
              <Typography variant="body2">{fileUploadText}</Typography>
              {isMaxSizeVisible && (
                <Typography variant="caption" component="p">
                  <FormattedMessage
                    id="fileUpload.limit"
                    defaultMessage="A feltölthető fájl maximális mérete {value}"
                    values={{ value: formatBytes(maxSize) }}
                  />
                </Typography>
              )}
              <label htmlFor="dropzone-input">
                <input {...getInputProps()} id="dropzone-input" />
                <Button color="primary" variant="contained" size="small" component="span" onClick={manualOnClick}>
                  {getDropzoneButtonText(variant)}
                </Button>
              </label>
            </div>
          )}

          {uploading && (
            <div className={cx(classes.uploadBox, { small: variant === FileDropzoneVariants.SMALL })}>
              {uploadSuggestionText && <Typography variant="body2">{uploadSuggestionText}</Typography>}
              <Typography variant="body2">
                {customUploadingText || (
                  <FormattedMessage
                    id="fileUpload.texts.uploadingInProgress"
                    defaultMessage="Fájl(ok) feltöltése folyamatban"
                  />
                )}
              </Typography>
              {customUploadingExtraContent}
              <div>
                <CircularProgress color="primary" size={variant === FileDropzoneVariants.SMALL ? 20 : 40} />
              </div>
            </div>
          )}

          {error && (
            <div className={classes.uploadBox} data-testid="dropzone-error">
              <Typography color="error" variant="body2" component="div">
                {error}
              </Typography>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  )
}

FileDropzone.propTypes = {
  acceptedFileMimeTypes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired),
  className: PropTypes.string,
  customUploadingExtraContent: PropTypes.node,
  customUploadingText: PropTypes.node,
  error: PropTypes.node,
  fileUploadText: PropTypes.node,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  uploadImgSrc: PropTypes.string,
  uploading: PropTypes.bool,
  uploadSuggestionText: PropTypes.node,
  onDrop: PropTypes.func.isRequired,
}

export default FileDropzone
