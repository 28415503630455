import React from 'react'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

interface AssignmentNotesHookProps {
  isEnabled: boolean
  loadAssignmentNoteRecommendation: AsyncFunction<void, string[]>
}

export function useAssignmentNotes({ isEnabled, loadAssignmentNoteRecommendation }: AssignmentNotesHookProps) {
  const [assignmentNotes, setAssignmentNotes] = React.useState<string[]>([])
  const cPromiseRef = useCancellablePromiseRef<string[]>()

  React.useEffect(() => {
    async function load() {
      try {
        cPromiseRef.current = cancellablePromise(loadAssignmentNoteRecommendation())
        const results = await cPromiseRef.current.promise
        setAssignmentNotes(results)
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          console.error("Fetch assignment's note recommendations failed", errorMessage)
        }
      }
    }

    if (isEnabled) {
      load()
    }
  }, [cPromiseRef, isEnabled, loadAssignmentNoteRecommendation])

  return assignmentNotes
}
