import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { ButtonBase } from './ButtonBase'

const ToggleWrapperDiv = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray[30]};
  border-radius: 100px;
  box-shadow: inset 0px 0px 2px ${({ theme }) => applyOpacity(theme.colors.gray[100], 10)};
  display: grid;
  overflow: hidden;
  padding: 2px;
  height: 30px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  ${ButtonBase} {
    min-width: 60px;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    padding: 0 10px;
    color: ${({ theme }) => theme.colors.gray[175]};

    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
      color: ${({ theme }) => theme.colors.primary[50]};
    }

    &:first-of-type {
      color: ${({ theme }) => theme.colors.primary[50]};
      font-weight: 600;
      padding: 0 12px 0 20px;
      grid-column: 1/2;
      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: 0;
        bottom: 0;
        left: 6px;
        margin: auto;
        background-color: ${({ theme }) => theme.colors.primary[50]};
        border-radius: 50%;
        transition: background-color 250ms ease-in-out;
      }

      &:hover:not(:disabled),
      &:active:not(:disabled),
      &:focus:not(:disabled) {
        color: ${({ theme }) => theme.colors.primary[170]};

        &:after {
          background-color: ${({ theme }) => theme.colors.primary[170]};
        }
      }
    }
    &:last-of-type {
      grid-column: 2/3;
    }
  }

  /* ACTIVE BUTTON BACKGROUND */
  &:before {
    background-color: ${({ theme }) => theme.colors.gray[0]};
    box-shadow: 0px 0px 2px ${({ theme }) => applyOpacity(theme.colors.gray[100], 35)};
    border-radius: 30px;
    content: '';
    width: calc(50% - 2px);
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 300ms ease-in-out;
    transform: translateX(calc(var(--background-translate) * 1%));
  }
`

interface ToggleButtonProps {
  children: React.ReactChild[]
  currentValue: string
  onToggle: VoidFunction
  primaryValue: string
  secondaryValue: string
}

/**
 * Toggle button component with primary and secondary value.
 *
 * NOTE: this will only use the first two children
 *
 * @param {ToggleButtonProps} {
 *   children,
 *   currentValue,
 *   onToggle,
 *   primaryValue,
 *   secondaryValue,
 * }
 */
export default function ToggleButton({
  children,
  currentValue,
  onToggle,
  primaryValue,
  secondaryValue,
}: ToggleButtonProps) {
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    onToggle()
  }

  // wrap the first two children component into buttons to be DRY
  const wrappedChildren = React.Children.map(children, (child, index) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      const buttonValue = index === 0 ? primaryValue : secondaryValue
      const isSelected = buttonValue === currentValue

      return (
        <ButtonBase
          type="button"
          value={buttonValue}
          aria-pressed={isSelected}
          {...(!isSelected && { onClick: handleClick })}
        >
          {React.cloneElement(child)}
        </ButtonBase>
      )
    }
    return null
  })

  return (
    <ToggleWrapperDiv
      role="group"
      style={{
        '--background-translate': currentValue === primaryValue ? 0 : 100,
      }}
    >
      {wrappedChildren}
    </ToggleWrapperDiv>
  )
}

ToggleButton.propTypes = {
  children: PropTypes.node.isRequired,
  currentValue: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  primaryValue: PropTypes.string.isRequired,
  secondaryValue: PropTypes.string.isRequired,
}
