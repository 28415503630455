import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getActiveCompanyId, getErrorMessage } from '@helpers'

import actions from './actions'
import * as api from './api'

export function* fetchOnboardingInviteApprovalsSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.getOnboardingInvitationMembersList, companyId)
    yield put(actions.fetchOnboardingInviteApprovals.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchOnboardingInviteApprovals.failure(errorMsg))
  }
}

export function* declineOnboardingInviteSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = payload.company.id
    const uuid = payload.data.uuid
    yield call(api.declineOnboardingInvitation, companyId, uuid)
    yield put(actions.declineOnboardingInvite.success(uuid))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.declineOnboardingInvite.failure())
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchOnboardingInviteApprovals.REQUEST, fetchOnboardingInviteApprovalsSaga)
  yield takeLatest(actions.declineOnboardingInvite.REQUEST, declineOnboardingInviteSaga)
}
