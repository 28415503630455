import React from 'react'
import { number, string } from 'prop-types'

export default function ManualSourceIcon({ color, size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M3.5 3C3.5 2.17157 4.17157 1.5 5 1.5H11.7929L16.5 6.20711V17C16.5 17.8284 15.8284 18.5 15 18.5H5C4.17157 18.5 3.5 17.8284 3.5 17V3Z"
        stroke={color}
      />
      <path d="M17 7L11 1V7H17Z" fill={color} />
      <rect x="6" y="12" width="8" height="1" fill={color} />
      <rect x="6" y="9" width="8" height="1" fill={color} />
      <rect x="6" y="6" width="3" height="1" fill={color} />
      <rect x="6" y="15" width="5" height="1" fill={color} />
    </svg>
  )
}

ManualSourceIcon.propTypes = {
  size: number,
  color: string,
}
ManualSourceIcon.defaultProps = {
  color: '#2168D1',
  size: 18,
}
