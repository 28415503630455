import { defineMessages } from 'react-intl'

// TODO need to clean up when v1 pages are removed
export const listPageMessages = defineMessages({
  downloadInvoicesArtifact: {
    id: 'costs.buttons.downloadInvoicesArtifact',
    defaultMessage: 'Kijelölt számlaképek letöltése',
  },
  downloadRegister: {
    id: 'costs.buttons.downloadRegister',
    defaultMessage: 'Adatok exportja',
  },
  filingInvoices: {
    id: 'costs.buttons.filingInvoices',
    defaultMessage: 'Kijelölt számlák iktatása',
  },
  statusChangeInvoices: {
    id: 'costs.buttons.statusChangeInvoices',
    defaultMessage: 'Kijelölt számlák könyvelési státuszának állítása',
  },
  approveInvoices: {
    id: 'costs.buttons.approveInvoices',
    defaultMessage: 'Kijelölt számlák jóváhagyási státuszának állítása',
  },
  taggingUpsell: {
    id: 'costs.dashboard.taggingButton.upsell',
    defaultMessage: 'Tömeges címkézés csak az <b>OkosBox</b> előfizetői csomagtól elérhető',
  },
  categorizationUpsell: {
    id: 'costs.dashboard.categorizationButton.upsell',
    defaultMessage: 'Tömeges kategorizálás csak az <b>OkosBox</b> előfizetői csomagtól elérhető',
  },
  createExpressPayment: {
    id: 'costs.dashboard.expressPaymentCreateButton',
    defaultMessage: 'Kifizetés rögzítése',
  },
  createExpressPaymentUpsell: {
    id: 'costs.dashboard.expressPaymentCreateButton.upsell',
    defaultMessage: 'Kifizetés rögzítése csak az <b>OkosBox</b> előfizetői csomagtól elérhető',
  },
  noSelectedItemError: {
    id: 'controls.title.noSelectedItemError',
    defaultMessage: 'Nincs kijelölt elem',
  },
  deleteSelectedItems: {
    id: 'actions.list.bulkDelete.tooltip',
    defaultMessage: 'Kijelöltek végleges törlése',
  },
  // common list table messages
  checkboxTitleSelected: {
    id: 'controls.title.checkboxSelected',
    defaultMessage: 'Összes kijelölés megszüntetése',
  },
  checkboxTitleEmpty: {
    id: 'controls.title.checkboxEmpty',
    defaultMessage: 'Összes kijelölése',
  },
  labelRowsPerPage: {
    id: 'table.pagination.labelRowsPerPage',
    defaultMessage: 'Sorok oldalanként:',
  },
  labelDisplayedRow: {
    id: 'table.pagination.labelDisplayedRow',
    defaultMessage: '{from}-{to} / {count}',
  },
  backgroundProcessStarted: {
    id: 'backgroundJobProcess.info.started',
    defaultMessage:
      'A letöltést megkezdtük, amennyiben sokáig tartana, e-mail címedre elküldjük a file letöltéséhez alkalmas linket',
  },

  // payment messages
  createPayment: {
    id: 'costs.dashboard.paymentCreateButton',
    defaultMessage: 'Utaláskönnyítő',
  },
  createPaymentUpsell: {
    id: 'costs.dashboard.paymentCreateButton.upsell',
    defaultMessage: 'Utaláskönnyítő csak az <b>OkosBox</b> előfizetői csomagtól elérhető',
  },
  processMaxTriesEmail: {
    id: 'bacgroundJobProcess.email.maxTries',
    defaultMessage: 'Az exportált fájlokhoz tartozó letöltési linket hamarosan elküldjük neked e-mailben.',
  },
  removeSuccessDialogTitle: {
    id: 'dashboard.bulkDelete.removeSuccessDialogTitle',
    defaultMessage: 'Sikeres törlés',
  },
  // bulk delete response
  removeInvoicesSuccess: {
    id: 'dashboard.bulkDelete.removeInvoicesSuccess',
    defaultMessage: 'Kijelölt számlák törölve.',
  },
  removeIncomesSuccess: {
    id: 'dashboard.bulkDelete.removeIncomesSuccess',
    defaultMessage: 'Kijelölt bevételek törölve.',
  },
  removeSalarySuccess: {
    id: 'dashboard.bulkDelete.removeSalarySuccess',
    defaultMessage: 'Kijelölt bérköltségek törölve.',
  },
  removeTaxSuccess: {
    id: 'dashboard.bulkDelete.removeTaxSuccess',
    defaultMessage: 'Kijelölt adók törölve.',
  },
  // v2
  removeSelectedItemsSuccess: {
    id: 'dashboard.bulkDelete.removeSelectedItemsSuccess',
    defaultMessage: 'Kijelölt {subject} törölve.',
  },
})
