import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, Input, InputLabel, InputProps, TextFieldProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { isFieldHighlighted } from '@helpers'

import { ReactHookFormTextFieldProps } from './ReactHookFormTextField'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles)

const useInputStyles = makeStyles(theme => ({
  refreshRoot: {
    border: `1px solid ${theme.palette.grayscale.light}`,
    backgroundColor: theme.palette.grayscale.inputBackground,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 4,
  },
  refreshDisabled: {
    backgroundColor: theme.palette.grayscale.disabledInputBackground,
  },
  refreshInput: {
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
  refreshFocused: {
    borderColor: theme.palette.tertiary.outlineBlue,
  },
  extraText: {
    marginTop: 3,
    fontSize: 12,

    '&>button': {
      height: 'auto',
      fontSize: 12,
      lineHeight: '16px',
      color: theme.palette.grayscale.inputLabel,
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      letterSpacing: -0.4,
      textAlign: 'left',
      fontStyle: 'italic',
    },
  },
  extraTextError: {
    marginTop: 3,
  },
}))

export interface TextFieldBehaviorProps extends ReactHookFormTextFieldProps {
  endAdornment?: InputProps['endAdornment']
  error: TextFieldProps['error']
  ExtraTextComponent?: React.ComponentType<{ className?: string }>
  helperText: TextFieldProps['helperText']
}

//* integration component to display NumberFormat as TextField
export function TextFieldBehavior({
  className,
  endAdornment,
  error,
  ExtraTextComponent,
  helperText,
  highlighted,
  isLabelHighlighted = false,
  label,
  required,
  ...rest
}: TextFieldBehaviorProps) {
  const classes = useStyles()
  const inputClasses = useInputStyles()

  return (
    <FormControl
      error={error}
      margin="normal"
      className={cx(className, 'form-control', { 'form-control-error': error })}
      required={required}
      fullWidth
    >
      {rest.type !== 'hidden' && (
        <InputLabel
          className={cx(classes.bootstrapFormLabel, { [classes.highlightedLabel]: isLabelHighlighted })}
          htmlFor={rest.name}
          shrink
        >
          {label}
        </InputLabel>
      )}
      <Input
        classes={{
          root: cx(classes.bootstrapRoot, {
            [inputClasses.refreshRoot]: !!endAdornment,
            [inputClasses.refreshDisabled]: !!endAdornment && rest.disabled,
          }),
          input: cx(classes.bootstrapInput, {
            highlighted: isFieldHighlighted(highlighted, rest.value),
            [inputClasses.refreshInput]: !!endAdornment,
          }),
          focused: cx({ [inputClasses.refreshFocused]: !!endAdornment }),
          error: classes.bootstrapError,
        }}
        disableUnderline
        endAdornment={endAdornment}
        id={rest.name}
        required={required}
        {...rest}
      />
      {ExtraTextComponent && <ExtraTextComponent className={inputClasses.extraText} />}
      {error && (
        <FormHelperText className={cx({ [inputClasses.extraTextError]: Boolean(ExtraTextComponent) })}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

TextFieldBehavior.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  highlighted: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}
