import { defineMessages } from 'react-intl'

const messages = defineMessages({
  moveBack: {
    id: 'calendar.moveBack',
    defaultMessage: 'Előző',
  },
  moveForward: {
    id: 'calendar.moveForward',
    defaultMessage: 'Következő',
  },
  dateButton: {
    id: 'calendar.dateButton',
    defaultMessage: 'Dátum kiválasztása',
  },
})

export default messages
