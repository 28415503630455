import React from 'react'

import styled from 'styled-components'

function BaseFlaskRoundPotionRegularIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.125 4.6875H13.5v3.8566c0 .8543.4746 1.5786 1.1355 1.9653 1.6805.9773 2.802 2.7914 2.802 4.8621-2.2641-.0317-3.3328-.4324-4.3066-.7981-.8368-.3164-1.6067-.6046-3.0059-.6046-1.9617 0-3.245.566-3.92.9808.1407-1.8949 1.2235-3.5297 2.7844-4.4402.661-.3867 1.1356-1.111 1.1356-1.9653V4.6875Zm5.0625 3.8566V4.6875h.2813a.8417.8417 0 0 0 .8437-.8438A.8417.8417 0 0 0 15.4688 3H8.1562a.8417.8417 0 0 0-.8437.8438c0 .4675.3762.8437.8438.8437h.2812v3.8566c0 .211-.116.4008-.2988.5063C5.9625 10.3195 4.5 12.675 4.5 15.375c0 1.9688.777 3.7582 2.046 5.073.3622.3762.8825.552 1.4063.552h7.7204c.5273 0 1.0441-.1758 1.4097-.552 1.2656-1.3148 2.0426-3.1042 2.0426-5.073 0-2.7-1.4625-5.0555-3.6387-6.3246-.1828-.1055-.2988-.2953-.2988-.5063Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const FlaskRoundPotionRegularIcon = styled(BaseFlaskRoundPotionRegularIcon)``
