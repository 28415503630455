import React from 'react'
import PropTypes from 'prop-types'

import { useTableRowExpander } from '@contexts'

import { getAriaRowId } from '../../helpers'

import { ExpandableTableBodyRow, ExpandedTableRow } from './styles'

interface ExpandableTableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  children: React.ReactChild | React.ReactChild[]
  className?: string
  colSpan: number
  rowId: string
  subComponent: React.ReactChild
}

/**
 * Component to be used when expandable rows are required. Pass any `React.ReactChild` as `subComponent` prop to render it once the actual row is expanded.
 * While the row is not expanded, the sibling `<tr />` has aria props to handle visibility for assistive technology users
 *
 * @param {ExpandableTableRowProps} {
 *   children,
 *   colSpan,
 *   rowId,
 *   subComponent,
 *   ...props
 * }
 */
export default function ExpandableTableRow({
  children,
  colSpan,
  rowId,
  subComponent,
  ...props
}: ExpandableTableRowProps) {
  const { expandedRows } = useTableRowExpander()
  const isExpanded = Boolean(expandedRows[rowId])
  const ariaRowId = getAriaRowId(rowId)

  return (
    <>
      <ExpandableTableBodyRow {...props}>{children}</ExpandableTableBodyRow>
      <ExpandedTableRow aria-hidden={!isExpanded} id={ariaRowId}>
        <td colSpan={colSpan}>{isExpanded && subComponent}</td>
      </ExpandedTableRow>
    </>
  )
}

ExpandableTableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  colSpan: PropTypes.number.isRequired,
  rowId: PropTypes.string.isRequired,
  subComponent: PropTypes.node.isRequired,
}
