import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const StyledText = styled.p`
  margin: 0.5em 0 0;
`

interface BulkActionDisabledByAllSelectedTooltipProps {
  isAllSelected: boolean
  tooltip: React.ReactNode
}

export function BulkActionDisabledByAllSelectedTooltip({
  isAllSelected,
  tooltip,
}: BulkActionDisabledByAllSelectedTooltipProps) {
  return (
    <>
      {tooltip}
      {isAllSelected && (
        <FormattedMessage
          tagName={StyledText}
          id="dashboard.bulkActionDisabledByAllSelected"
          defaultMessage="Oldalakon átívelő kijelölés esetén a művelet nem érhető el."
        />
      )}
    </>
  )
}

BulkActionDisabledByAllSelectedTooltip.propTypes = {
  isAllSelected: PropTypes.bool.isRequired,
  tooltip: PropTypes.node.isRequired,
}
