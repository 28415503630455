import React from 'react'

import styled from 'styled-components'

import { QUICK_COLORS } from '@constants/theme'

function BaseSzamlazzHuIcon({ className, color = QUICK_COLORS.primary[50], size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M15.287 5.073v10.655a.4856.4856 0 0 1-.4855.4856H5.8381l-.0527.6027a.4856.4856 0 0 0 .435.5255l9.6604.9757a.4856.4856 0 0 0 .5324-.4394l1.0953-12.122a.4856.4856 0 0 0-.4413-.5274L15.287 5.073Z"
        fill={color}
      />
      <path
        d="M3 2.4856v12.1983c0 .2681.2174.4855.4856.4855h10.2716a.4856.4856 0 0 0 .4856-.4855V2.4856A.4856.4856 0 0 0 13.7572 2H3.4856A.4856.4856 0 0 0 3 2.4856Zm6.8006 4.735a.3894.3894 0 0 1-.3894.3893H5.0416a.3894.3894 0 0 1-.3893-.3894v-.2783c0-.215.1743-.3894.3893-.3894h4.3696c.215 0 .3894.1743.3894.3894v.2783Zm2.8028-2.518a.3894.3894 0 0 1-.3894.3895H4.9993a.3894.3894 0 0 1-.3894-.3894v-.2784c0-.215.1744-.3893.3894-.3893h7.2147c.215 0 .3894.1743.3894.3893v.2784Z"
        fill={color}
      />
    </svg>
  )
}

export const SzamlazzHuIcon = styled(BaseSzamlazzHuIcon)``
