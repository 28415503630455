import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { ListActionsDialogActions, ListActionsDialogBody } from '@components/dialogs'
import { Button, CustomDialog, CustomDialogHeader, InfoText, Typography } from '@components/ui'

import { ResetButtonMessage } from '@messages'
import { GrayPaperBox } from './styles'

const StyledCustomDialogHeader = styled(CustomDialogHeader)`
  color: ${({ theme }) => theme.colors.gray[80]};

  ${Typography} {
    font-size: 28px;
  }
`

interface DokumentAiRecognitionConfirmDialogProps {
  onClose: VoidFunction
  onConfirm: VoidFunction
  open: boolean
}

export function DokumentAiRecognitionConfirmDialog({
  onClose,
  onConfirm,
  open,
}: DokumentAiRecognitionConfirmDialogProps) {
  return (
    <CustomDialog open={open} onClose={onClose}>
      <StyledCustomDialogHeader
        borderless
        title={
          <FormattedMessage
            id="dokumentAiRecognition.confirmationDialog.title"
            defaultMessage="Felülírod az adatokat?"
          />
        }
      />
      <ListActionsDialogBody>
        <GrayPaperBox elevation={0}>
          <InfoText iconColor="gray-40" size="400-sm" color="gray-80">
            <FormattedMessage
              id="dokumentAiRecognition.confirmationDialog.text.overwrite"
              defaultMessage="Ehhez a dokumentumhoz korábban már adtál adatokat. A generálást követően a mezőket felülírjuk, de továbbra is lesz lehetőséged azok szerkesztésére."
            />
          </InfoText>
        </GrayPaperBox>
      </ListActionsDialogBody>
      <ListActionsDialogActions borderless>
        <Button onClick={onConfirm} variant="primaryContained">
          <FormattedMessage
            id="dokumentAiRecognition.confirmationDialog.buttons.overwrite"
            defaultMessage="Felülírás"
          />
        </Button>
        <Button onClick={onClose} variant="primaryText">
          {ResetButtonMessage}
        </Button>
      </ListActionsDialogActions>
    </CustomDialog>
  )
}
