import React from 'react'
import PropTypes from 'prop-types'

import styled, { css, DefaultTheme } from 'styled-components'

import { QUICK_TYPOGRAPHY } from '@constants/theme'

// TODO cleanup colors
export const TYPOGRAPHY_COLOR = {
  INHERIT: 'inherit',
  ORANGE_50: 'orange-50',
  GRAY_30: 'gray-30',
  GRAY_40: 'gray-40',
  GRAY_50: 'gray-50',
  GRAY_70: 'gray-70',
  GRAY_80: 'gray-80',
  ERROR: 'error', // 'red-50'
  SUCCESS: 'success', // 'green-50'
  WHITE: 'white',
  // TODO old color - missing form Design System
  GRAY_130: 'gray-130',
  BLUE: 'blue',
} as const

export const TYPOGRAPHY_ALIGN = {
  INHERIT: 'inherit',
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const

function getTextColor(theme: DefaultTheme, color?: (typeof TYPOGRAPHY_COLOR)[keyof typeof TYPOGRAPHY_COLOR]) {
  switch (color) {
    case TYPOGRAPHY_COLOR.ORANGE_50: // TYPOGRAPHY_COLOR.PRIMARY_COLOR - primary[150]
      return theme.colors.primary[50]
    case TYPOGRAPHY_COLOR.WHITE:
      return theme.colors.gray[0]
    case TYPOGRAPHY_COLOR.BLUE:
      return theme.colors.blue[40]
    case TYPOGRAPHY_COLOR.ERROR:
      return theme.colors.error[50]
    case TYPOGRAPHY_COLOR.SUCCESS:
      return theme.colors.success[50]
    //* new gray colors
    case TYPOGRAPHY_COLOR.GRAY_80: // TYPOGRAPHY_COLOR.DEFAULT - gray[180]
      return theme.colors.gray[80]
    case TYPOGRAPHY_COLOR.GRAY_70: // TYPOGRAPHY_COLOR.ACCENT - blue[40]
      return theme.colors.gray[70]
    case TYPOGRAPHY_COLOR.GRAY_50: //TYPOGRAPHY_COLOR.SECONDARY - gray[50]
      return theme.colors.gray[50]
    case TYPOGRAPHY_COLOR.GRAY_40: //TYPOGRAPHY_COLOR.PRIMARY - gray[40]
      return theme.colors.gray[40]
    case TYPOGRAPHY_COLOR.GRAY_30:
      return theme.colors.gray[30]
    case TYPOGRAPHY_COLOR.GRAY_130:
      return theme.colors.gray[130]
    case TYPOGRAPHY_COLOR.INHERIT:
    default:
      return 'inherit'
  }
}

type StyleOnlyProps = {
  align?: ConstObjectValues<typeof TYPOGRAPHY_ALIGN>
  color?: ConstObjectValues<typeof TYPOGRAPHY_COLOR>
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'caption' | 'strong' | 'label'
  size?: keyof DefaultTheme['typography']
  italic?: boolean
}

export type TypographyProps = Omit<React.HTMLAttributes<HTMLElement>, 'color'> &
  StyleOnlyProps & {
    children: React.ReactNode
    htmlFor?: string
  }

// TODO use css variables to reduce the number of the  classNames generated by props permutations
/**
 * Typography component
 * @param {string} align - inherit, left, center, right, default: inherit
 * @param {string} color - inherit, orange-50, gray-30, gray-40, gray-50, gray-70, gray-80, error, success, white, blue, default: inherit
 * @param {string} size -  inherit, heading-1, heading-2, heading-3, heading-4, heading-5, heading-6, 400-xxs, 400-xs, 400-sm, 400-md, 400-lg, 700-xxs, 700-xs, 700-sm, 700-md, 700-lg, default: inherit
 * @param {string} tag - h1, h2, h3, h4, h5, h6, p, span, caption, strong, default: p
 * @param {boolean} italic - set font-style to italic, default: false
 */
export const Typography = styled('p')
  .withConfig<TypographyProps>({
    shouldForwardProp: (prop, defaultValidator) => {
      return !['align', 'color', 'tag', 'size', 'italic'].includes(prop) && defaultValidator(prop)
    },
  })
  .attrs<TypographyProps>(({ tag = 'p', htmlFor }) => ({
    as: tag,
    htmlFor,
  }))`
  ${({ theme, color = 'inherit', size = 'inherit', align = 'inherit', italic = false }) => css`
    color: ${getTextColor(theme, color)};
    font-weight: ${theme.typography[size].fontWeight};
    font-size: ${theme.typography[size].fontSize};
    line-height: ${theme.typography[size].lineHeight};
    font-style: ${italic ? 'italic' : 'inherit'};
    text-align: ${align};
    margin: 0;
    padding: 0;

    b,
    strong {
      font-weight: 700;
    }
  `}
`

Typography.propTypes = {
  align: PropTypes.oneOf(Object.values(TYPOGRAPHY_ALIGN)),
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(TYPOGRAPHY_COLOR)),
  italic: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(QUICK_TYPOGRAPHY)) as React.Validator<keyof DefaultTheme['typography']>,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'caption', 'strong', 'label']),
}
