import React from 'react'

import styled from 'styled-components'

function BasePieChartRegularIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M17.0421 15.9413a.5.5 0 0 1-.5-.5v-8.57c0-.3663.2476-.673.6495-.673 5.0217 0 9.0934 4.0718 9.0934 9.0935 0 .3653-.3085.6495-.6738.6495h-8.5691Zm6.8729-1.9486c.1574 0 .2758-.1438.2392-.2968-.6364-2.6639-2.7074-4.7635-5.3695-5.3707-.1523-.0348-.294.0834-.294.2396v5.1779a.25.25 0 0 0 .25.25h5.1743Zm-9.3215-5.7804v9.6776l6.3532 6.3532c.272.272.2517.7186-.0609.9419-1.5913 1.1326-3.5399 1.7984-5.6428 1.7984-5.379 0-9.743-4.36-9.743-9.743 0-4.9243 3.6577-8.9985 8.3668-9.6517.41-.0511.7267.248.7267.6236Zm-1.8021 10.6319a.5002.5002 0 0 1-.1465-.3537v-8.248c0-.1726-.1708-.293-.3307-.2281-2.8543 1.1588-4.8656 3.957-4.8656 7.226 0 4.3031 3.4547 7.7944 7.7944 7.7944.9754 0 1.878-.1775 2.7553-.5043.1665-.062.2085-.2748.0828-.4004l-5.2897-5.2859Zm14.0782-.9543c.3735 0 .6739.3166.6252.6901-.3126 2.2693-1.4087 4.2869-3 5.7768-.2436.1949-.6252.2111-.8607-.0244l-6.0157-6.0157c-.1575-.1575-.046-.4268.1767-.4268h9.0745Z"
      />
    </svg>
  )
}

export const PieChartRegularIcon = styled(BasePieChartRegularIcon)``
