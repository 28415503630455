import React from 'react'

function getSelectionListsDiff(list1: SelectedItemProps[], list2: SelectedItemProps[]): SelectedItemProps[] {
  return list1.filter(a => !list2.some(b => b.id === a.id))
}

function getNotVisibleSelectionCount(selectedItems: SelectedItemProps[], list: SelectedItemProps[]): number {
  return getSelectionListsDiff(selectedItems, list).length
}

/**
 * Helper to count not visible selected items for v2 list tables' notification row and actions
 * @param selected - selected items list
 * @param data - original data list
 * @returns number of not visible selection
 */
export function useNotVisibleSelectionCount<Data extends SelectedItemProps>(
  selected: SelectedItemProps[],
  data: Data[]
) {
  return React.useMemo(() => getNotVisibleSelectionCount(selected, data), [data, selected])
}
