import React from 'react'
import { bool, node } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const ExpiredBadge = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 275,
  },
  badge: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.neutral.xxxLight,
    borderRadius: 4,
    border: '1px solid rgba(0,0,0,.05)',
    padding: '2px 6px',
    marginLeft: 4,
    lineHeight: 1,
    backgroundColor: theme.palette.primary.dark,
  },
  partner: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 700,
  },
}))(({ classes, children, expired }) => (
  <div className={classes.root}>
    <div className={classes.partner}>{children}</div>
    {expired && (
      <div className={classes.badge}>
        <FormattedMessage id="badges.expired" defaultMessage="Lejárt" />
      </div>
    )}
  </div>
))

ExpiredBadge.propTypes = {
  children: node.isRequired,
  expired: bool.isRequired,
}
export default ExpiredBadge
