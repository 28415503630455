import __mapValues from 'lodash/mapValues'

import { taxNumberFormatter } from '@helpers'

import { InitialValues, PartnerData } from './types'

// reduxForm field value can not be null - empty string used in this case
function replaceValueIfNull<TValue>(value: TValue | null, defaultValue: NonNullable<TValue>): NonNullable<TValue> {
  return value == null ? defaultValue : (value as NonNullable<TValue>)
}

export function createInitialValues(
  partner: Nullable<Partial<PartnerData>> | undefined,
  variant: 'provider' | 'customer' | 'income' | 'expense'
): InitialValues {
  return {
    account_number: replaceValueIfNull(partner?.account_number, ''),
    address: replaceValueIfNull(partner?.address, ''),
    city: replaceValueIfNull(partner?.city, ''),
    country: replaceValueIfNull(partner?.country, ''),
    default_account_number: replaceValueIfNull(partner?.default_account_number, ''),
    id: partner?.id,
    is_customer_or_provider: undefined,
    is_customer: replaceValueIfNull(partner?.is_customer, ['income', 'customer'].includes(variant)),
    is_provider: replaceValueIfNull(partner?.is_provider, ['expense', 'provider'].includes(variant)),
    name: replaceValueIfNull(partner?.name, ''),
    tax_number: replaceValueIfNull(partner?.tax_number, ''),
    zip_code: replaceValueIfNull(partner?.zip_code, ''),
  }
}

// used before API call
export function replaceEmptyValueWithNull(values: any) {
  return __mapValues(values, v => (v === '' ? null : v))
}

// form helpers
export function generateOptions(
  options: string[],
  label: string,
  valueKey = 'value'
): { label: string; options: { label: string; [key: string]: string }[] }[] {
  return options.length > 0
    ? [
        {
          label,
          options: options.map(value => ({
            label: value,
            [valueKey]: value,
          })),
        },
      ]
    : (options as any)
}

export function getPartnerInfo(data: Nullable<PartnerData>): string {
  if (!data) {
    return ''
  }

  const { name, tax_number } = data
  if (!tax_number) {
    return name || ''
  }

  return `${name} (${taxNumberFormatter.format(tax_number)})`
}
