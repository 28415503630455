import { FileError } from 'react-dropzone'

import { CustomValidatorCodes } from '@constants'

export function zeroFileSizeValidator<UploadedFile extends File>(file: UploadedFile): Nullable<FileError> {
  if (file.size === 0) {
    return {
      code: CustomValidatorCodes.ZERO_FILE_SIZE,
      message: 'Invalid file size.', // will be translated when display error in UploadedFile component
    }
  }
  return null
}
