import React from 'react'

export default function FiltersIcon() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25 12.75v1.5h4.5v-1.5h-4.5Zm0-9v1.5h7.5v-1.5h-7.5Zm7.5 12v-1.5h6v-1.5h-6v-1.5h-1.5v4.5h1.5Zm-4.5-9v1.5h-3v1.5h3v1.5h1.5v-4.5h-1.5Zm10.5 3v-1.5h-7.5v1.5h7.5Zm-4.5-3h1.5v-1.5h3v-1.5h-3v-1.5h-1.5v4.5Z"
        fill="#2168D1"
      />
    </svg>
  )
}
