import React from 'react'
import PropTypes from 'prop-types'

import { useAssertContext } from '@hooks/useAssertContext'

interface PortalProviderContextProps {
  portalAnchorEl: Nullable<HTMLDivElement>
  setPortalAnchorEl: (payload: HTMLDivElement) => void
}

const PortalContext = React.createContext<PortalProviderContextProps | undefined>(undefined)

PortalContext.displayName = 'Portal'

interface PortalProviderProps {
  children: React.ReactNode
}

export function PortalProvider({ children }: PortalProviderProps) {
  const [portalAnchorEl, setAnchorEl] = React.useState<Nullable<HTMLDivElement>>(null)

  const value = React.useMemo(
    () => ({
      portalAnchorEl,
      setPortalAnchorEl: (node: Nullable<HTMLDivElement>) => {
        if (node) {
          setAnchorEl(node)
        }
      },
    }),
    [portalAnchorEl]
  )

  return <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
}

PortalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function usePortal() {
  return useAssertContext(PortalContext)
}
