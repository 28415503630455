import React from 'react'

import styled from 'styled-components'

function BasePrintIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(25 / 24) * size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M5.258 7.884v-4.5c0-.413.338-.75.75-.75h10.758c.197 0 .389.08.53.22l1.242 1.242c.14.141.22.333.22.53v3.258h2.25V4.626c0-.797-.314-1.56-.877-2.123L18.886 1.26a2.99 2.99 0 0 0-2.12-.876H6.009c-1.655 0-3 1.345-3 3v4.5h2.25Zm.75 9.75h12v4.5h-12v-4.5Zm-.75-2.25c-.83 0-1.5.67-1.5 1.5h-1.5v-4.5c0-.413.338-.75.75-.75h18c.413 0 .75.337.75.75v4.5h-1.5c0-.83-.67-1.5-1.5-1.5h-13.5Zm15 3.75h2.25c.83 0 1.5-.67 1.5-1.5v-5.25c0-1.655-1.345-3-3-3h-18c-1.655 0-3 1.345-3 3v5.25c0 .83.67 1.5 1.5 1.5h2.25v3.75c0 .83.67 1.5 1.5 1.5h13.5c.83 0 1.5-.67 1.5-1.5v-3.75Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.008.384h24v24h-24z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const PrintIcon = styled(BasePrintIcon)``
