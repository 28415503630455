import React from 'react'

import styled from 'styled-components'

function BaseDownloadFileIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={(18 / 24) * size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8471 16.4141C15.8471 19.6173 13.2503 22.2141 10.0471 22.2141C6.84382 22.2141 4.24707 19.6173 4.24707 16.4141C4.24707 13.2108 6.84382 10.6141 10.0471 10.6141C13.2503 10.6141 15.8471 13.2108 15.8471 16.4141ZM10.0474 13.0742C9.63322 13.0742 9.29743 13.41 9.29743 13.8242V16.8825L8.68193 16.267C8.38903 15.9741 7.91416 15.9741 7.62127 16.267C7.32837 16.5598 7.32837 17.0347 7.62127 17.3276L9.51705 19.2234C9.80995 19.5163 10.2848 19.5163 10.5777 19.2234L12.4683 17.3328C12.7612 17.0399 12.7612 16.565 12.4683 16.2721C12.1755 15.9792 11.7006 15.9792 11.4077 16.2721L10.7974 16.8824V13.8242C10.7974 13.41 10.4616 13.0742 10.0474 13.0742Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1234 4.37719L13.6252 0.878438C13.0594 0.316031 12.2953 0 11.5031 0H3C1.34297 0 0 1.34297 0 3L0.000304688 21C0.000304688 22.6566 1.34327 24 3.0003 24H15C16.65 24 18 22.65 18 21V6.49688C18 5.70469 17.6859 4.94062 17.1234 4.37719ZM3.58858 22.0354H5.88817C7.06327 22.9273 8.5287 23.4566 10.1177 23.4566C13.985 23.4566 17.12 20.3216 17.12 16.4544C17.12 14.8707 16.5943 13.4098 15.7078 12.2367V7.5H12C11.1717 7.5 10.5 6.82828 10.5 6V2.25609H3C2.58581 2.25609 2.25 2.59191 2.25 3.00609L2.2503 21C2.2503 21.4142 2.43738 22.0354 3.58858 22.0354Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const DownloadFileIcon = styled(BaseDownloadFileIcon)``
