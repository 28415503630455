import React from 'react'

import __uniqueId from 'lodash/uniqueId'
import { Form, FormRenderProps } from 'react-final-form'
import { Props as ModalProps } from 'react-modal'

import { createAriaAttributes } from '@helpers'

import { EditorMode } from '@hooks/useEditor'

import { CustomDialog, CustomDialogHeader } from '@components/ui/Dialogs'

import { DIALOG_CLOSING_TIMEOUT } from '@constants/dialog'

import DialogResponse from './DialogResponse'
import { LoadingDialogOverlay } from './LoadingDialogOverlay'

import { FormDialogBody } from './styles'

enum DialogState {
  CLOSED = 'closed', // initial state when the dialog is not open
  LOADING = 'loading', // during initial onLoad (only in edit mode)
  RESPONSE = 'response', // enter when initial onLoad failed OR form submitSucceed
  FORM = 'form', // user can work with the form
}

//! initialValues must be a non null object otherwise FormSpy onChange listener will throw error when try to access any value of it (eg.: values.name)
interface FormDialogState<FormValues extends Record<string, unknown>, ApiData> {
  initialError: null | string
  initialValues: Partial<FormValues>
  dialogState: DialogState
  storedValues: null | ApiData
}

const INITIAL_STATE = {
  initialError: null,
  dialogState: DialogState.CLOSED,
  storedValues: null,
}

interface FormDialogChildrenData<ApiData> {
  mode: EditorMode
  storedValues: ApiData | null
  aria: Required<ModalProps>['aria']
}

interface FormDialogProps<FormValues extends Record<string, unknown>, ApiData> {
  children: (
    formRenderProps: FormRenderProps<FormValues, Partial<FormValues> | null>,
    formDialogData: FormDialogChildrenData<ApiData>
  ) => JSX.Element
  createInitialValues: (data?: Partial<ApiData>) => Partial<FormValues>
  dialogTitle: React.ReactChild
  initialData?: Partial<ApiData>
  mode: EditorMode
  onClose: VoidFunction
  onLoad: AsyncFunction<void, ApiData>
  onLoadFailureText: React.ReactChild
  onSubmit: AsyncFunction<FormValues, ApiData>
  onSubmitFailure?: (payload: unknown) => void
  onSubmitSuccess?: (results: ApiData) => void
  onSubmitSuccessText: ((storedValues: ApiData | null, mode: EditorMode) => React.ReactChild) | React.ReactChild
  open: boolean
  skipSuccessResponse?: boolean
  validate?: <FormValues>(values: FormValues) => Promise<ValidationError>
}

/**
 * When a form needs to be displayed in a dialog, use this component which handles loading, success and error states as well.
 *
 * @template FormValues - generic type for the actual form's values - needs to by a `type`
 * @template ApiData - generic type for the data returned by the API
 * @param {FormDialogProps<FormValues, ApiData>} {
 *   children, - the actual form used with render props
 *   createInitialValues, - function that creates the initial values for the form
 *   dialogTitle, - title of the dialog
 *   mode, - can be `EDIT` or `CREATE`
 *   onClose, - function that closes the dialog
 *   onLoad, - needs to return a Promise and passes data to `createInitialValues`
 *   onLoadFailureText, - text to display when the initial load fails
 *   onSubmit, - needs to return a Promise and passes data to `renderOnSubmitSuccess`
 *   onSubmitFailure, - (optional) function that gets called if submit fails with the error response
 *   onSubmitSuccess, - (optional) function that gets called if submit succeeds with the data returned by the API
 *   onSubmitSuccessText, - either a function that gets called with the data returned by the API and the mode, or a string to display on success
 *   open, - if the dialog should be open or not
 *   skipSuccessResponse, - (optional) if the success response should be skipped
 * }
 * @returns
 */
export function FormDialog<FormValues extends Record<string, unknown>, ApiData>({
  children,
  createInitialValues,
  dialogTitle,
  initialData,
  mode,
  onClose,
  onLoad,
  onSubmit,
  onSubmitFailure,
  onSubmitSuccess,
  open,
  onLoadFailureText,
  onSubmitSuccessText: onSubmitSuccessTextProp,
  skipSuccessResponse = false,
  validate,
}: FormDialogProps<FormValues, ApiData>) {
  const [{ storedValues, initialError, initialValues, dialogState }, setState] = React.useState<
    FormDialogState<FormValues, ApiData>
  >({ ...INITIAL_STATE, initialValues: createInitialValues(initialData) })

  // when open dialog call onLoad
  React.useEffect(() => {
    // need to only run when DialogState is CLOSED to ensure that prop changes does not trigger onLoad again
    if (open && dialogState === DialogState.CLOSED) {
      if (mode === EditorMode.EDIT) {
        setState(state => ({
          ...state,
          dialogState: DialogState.LOADING,
        }))
        onLoad()
          .then(results => {
            setState(state => ({
              ...state,
              dialogState: DialogState.FORM,
              initialValues: createInitialValues(results),
              storedValues: results,
            }))
          })
          .catch(errorMsg => {
            setState(state => ({
              ...state,
              initialError: errorMsg,
              dialogState: DialogState.RESPONSE,
            }))
          })
      } else {
        // EditorMode.CREATE
        setState(state => ({
          ...state,
          dialogState: DialogState.FORM,
          initialValues: createInitialValues(initialData),
        }))
      }
    }
  }, [onLoad, mode, open, createInitialValues, dialogState, initialData])

  // reset state when dialog is closed after a delay to allow transition animation
  React.useEffect(() => {
    let timeout: number
    if (dialogState !== DialogState.CLOSED && !open) {
      timeout = window.setTimeout(() => {
        // check once more if we're still in response state to avoid clearing a valid new form
        setState(state =>
          state.dialogState !== DialogState.CLOSED
            ? {
                ...INITIAL_STATE, // we need to create initial values here as well otherwise validator functions could break which listens to all of the form's values
                initialValues: createInitialValues(),
              }
            : state
        )
      }, DIALOG_CLOSING_TIMEOUT)
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout)
      }
    }
  }, [createInitialValues, dialogState, open])

  async function handleFormSubmit(values: FormValues) {
    return onSubmit(values)
      .then(results => {
        onSubmitSuccess?.(results)
        if (skipSuccessResponse) {
          onClose()
        } else {
          setState(state => ({
            ...state,
            storedValues: results,
            dialogState: DialogState.RESPONSE,
          }))
        }
        return undefined
      })
      .catch(err => {
        onSubmitFailure?.(err)
        return err
      })
  }

  function handleOnSubmitSuccessText() {
    if (typeof onSubmitSuccessTextProp === 'function') {
      return onSubmitSuccessTextProp(storedValues, mode)
    } else {
      return onSubmitSuccessTextProp
    }
  }

  // store the text into a variable to have `onErrorText` and `onSubmitSuccessText` the same type for `DialogResponse`
  const onSubmitSuccessText = handleOnSubmitSuccessText()

  // ensure to have the same aria attributes and pass down to dialog body
  const ariaIdPrefix = __uniqueId()
  const aria = createAriaAttributes(ariaIdPrefix)

  const canCloseDialogWithoutButton = dialogState === DialogState.RESPONSE

  return (
    <CustomDialog
      ariaIdPrefix={ariaIdPrefix}
      open={open}
      onClose={onClose}
      shouldCloseOnEsc={canCloseDialogWithoutButton}
      shouldCloseOnOverlayClick={canCloseDialogWithoutButton}
    >
      <CustomDialogHeader title={dialogTitle} borderless={dialogState === DialogState.RESPONSE} />
      <FormDialogBody aria-busy={dialogState === DialogState.LOADING}>
        <LoadingDialogOverlay loading={dialogState === DialogState.LOADING} />
        <Form
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          subscription={{ initialValues: true, submitSucceeded: true }}
          validate={validate}
          render={formRenderProps => (
            <DialogResponse
              aria={aria}
              error={initialError}
              onClose={onClose}
              onErrorText={onLoadFailureText}
              onSubmitSuccessText={onSubmitSuccessText}
              success={formRenderProps.submitSucceeded}
            >
              {children(formRenderProps, { storedValues, mode, aria })}
            </DialogResponse>
          )}
        />
      </FormDialogBody>
    </CustomDialog>
  )
}
