import React from 'react'

import {
  MainStateActions,
  nextDocument,
  previousDocument,
  setAllDocuments,
  setMainConfig,
  updateCurrentDocument,
} from './actions'
import { DocViewerProps } from './DocViewer'
import { initialState, MainStateReducer, mainStateReducer } from './reducer'
import { DocViewerMainState, DocViewerRef } from './types'

const DocViewerContext = React.createContext<{
  state: DocViewerMainState
  dispatch: React.Dispatch<MainStateActions>
  pageRef?: React.MutableRefObject<number>
  renderPostItLayer?: (scale: number, page?: number) => Nullable<JSX.Element>
}>({ state: initialState, dispatch: () => null })

interface DocViewerProviderProps extends DocViewerProps {
  prefetchMethod?: 'GET'
}

const DocViewerProvider = React.forwardRef<DocViewerRef, React.PropsWithChildren<DocViewerProviderProps>>(
  (
    {
      activeDocument,
      children,
      config,
      documents,
      initialActiveDocument,
      onDocumentChange,
      pageRef,
      pluginRenderers,
      prefetchMethod,
      renderPostItLayer,
      requestHeaders,
    },
    ref
  ) => {
    const [state, dispatch] = React.useReducer<MainStateReducer>(mainStateReducer, {
      ...initialState,
      activeDocument,
      currentDocument:
        documents && documents.length ? (initialActiveDocument ? initialActiveDocument : documents[0]) : undefined,
      config,
      currentFileNo: initialActiveDocument ? documents.findIndex(doc => doc === initialActiveDocument) ?? 0 : 0,
      documents: documents || [],
      onDocumentChange,
      pluginRenderers,
      prefetchMethod,
      requestHeaders,
    })

    React.useEffect(() => {
      dispatch(setAllDocuments(documents, initialActiveDocument))
    }, [documents, initialActiveDocument])

    React.useEffect(() => {
      config && dispatch(setMainConfig(config))
    }, [config])

    React.useEffect(() => {
      if (activeDocument) {
        dispatch(updateCurrentDocument(activeDocument))
      }
    }, [activeDocument])

    React.useImperativeHandle(
      ref,
      () => ({
        prev() {
          dispatch(previousDocument())
        },
        next() {
          dispatch(nextDocument())
        },
      }),
      [dispatch]
    )

    return (
      <DocViewerContext.Provider value={{ state, dispatch, pageRef, renderPostItLayer }}>
        {children}
      </DocViewerContext.Provider>
    )
  }
)

DocViewerProvider.displayName = 'DocViewerProvider'

export { DocViewerContext, DocViewerProvider }
