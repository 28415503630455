import React from 'react'

export default function LightningBoltIcon({ className, size = 18 }: IconComponentProps) {
  return (
    <svg
      className={className}
      height={(size / 18) * 24}
      viewBox="0 0 18 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.58 0c.895 0 1.542.856 1.298 1.716L12.366 10.5h3.998c.905 0 1.636.731 1.636 1.636 0 .483-.21.937-.577 1.252L5.293 23.68a1.347 1.347 0 0 1-2.168-1.397l2.51-8.784H1.581a1.58 1.58 0 0 1-1.028-2.784L12.708.323C12.952.115 13.26 0 13.58 0Zm-1.693 3.984L3.393 11.25h3.733c.352 0 .684.164.9.445.21.286.277.652.183.99l-2.1 7.354 8.587-7.289h-3.82c-.352 0-.684-.164-.9-.445a1.15 1.15 0 0 1-.183-.99l2.095-7.331Z"
        fill="currentColor"
      />
    </svg>
  )
}
