import styled from 'styled-components'

export const PageWrapper = styled.div`
  padding: 20px;
  max-width: ${({ theme }) => theme.contentWidth}px;
  margin: 0 auto;
  width: 100%;
  position: relative;
`
export const SubPage = styled.div`
  padding: 20px;
`
