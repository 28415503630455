const BMP_RENDERER_ALLOWED_FILE_EXTENSIONS = ['bmp']
const JPEG_RENDERER_ALLOWED_FILE_EXTENSIONS = ['jpeg', 'jpg']
const PDF_RENDERER_ALLOWED_FILE_EXTENSIONS = ['pdf']
const PNG_RENDERER_ALLOWED_FILE_EXTENSIONS = ['png']
// const TIFF_RENDERER_ALLOWED_FILE_EXTENSIONS = ['tiff', 'tif']
const TXT_RENDERER_ALLOWED_FILE_EXTENSIONS = ['txt']

const ALLOWED_EXTENSIONS = [
  ...BMP_RENDERER_ALLOWED_FILE_EXTENSIONS,
  ...JPEG_RENDERER_ALLOWED_FILE_EXTENSIONS,
  ...PDF_RENDERER_ALLOWED_FILE_EXTENSIONS,
  ...PNG_RENDERER_ALLOWED_FILE_EXTENSIONS,
  // ...TIFF_RENDERER_ALLOWED_FILE_EXTENSIONS,
  ...TXT_RENDERER_ALLOWED_FILE_EXTENSIONS,
]

function getFileExtension(fileName: string) {
  return fileName.toLowerCase().slice(fileName.lastIndexOf('.') + 1)
}

export function isSupportedFileForPreview(fileName: string) {
  return ALLOWED_EXTENSIONS.includes(getFileExtension(fileName))
}
