import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { usePostIt } from '@components/DocViewer/hooks'
import { CustomRendererOverrideProps } from '@components/DocViewer/types'
import { Button } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'
import { FlexColumn } from '@components/ui/Wrappers'

import previewNotFoundIcon from '@assets/img/preview_not_found_icon.svg'

import { ReloadPageButtonMessage } from '@messages'

const NoRendererWrapper = styled(FlexColumn)`
  align-items: center;
  gap: 20px;
  justify-content: center;
  min-height: 380px;
  padding: 20px;
  position: relative;
  width: 100%;

  ${Button} {
    width: max-content;
  }
`

const NoRendererInformationBlock = styled(FlexColumn)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[10]};
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  border-radius: 6px;
  margin-top: 12px;
  max-width: 488px;
  padding: 20px;
  text-align: center;
`

const PreviewNotFoundImage = styled.img.attrs({ src: previewNotFoundIcon })`
  padding: 20px;
`

function handleReloadWindow() {
  window.location.reload()
}

export function NoRenderer({ currentDocument }: CustomRendererOverrideProps) {
  const { renderPostItLayer } = usePostIt()

  if (currentDocument == null) {
    return null
  }

  const { status } = currentDocument

  return (
    <NoRendererWrapper>
      <Typography color="gray-80" size="heading-3">
        {status === 403 ? (
          <FormattedMessage id="docViewer.noRenderer.403.title" defaultMessage="Lejárt token" />
        ) : (
          <FormattedMessage id="docViewer.noRenderer.404.title" defaultMessage="Hiányzó fájl" />
        )}
      </Typography>
      <NoRendererInformationBlock>
        <Typography size="400-md">
          {status === 403 ? (
            <FormattedMessage
              id="docViewer.noRenderer.403.text"
              defaultMessage="Úgy tűnik sokáig voltál inaktív, ezért elveszítettük a fonalat. Kérjük frissítsd az oldalt és próbáld újra!"
            />
          ) : (
            <FormattedMessage
              id="docViewer.noRenderer.404.text"
              defaultMessage="A megnyitott fájl nem elérhető. Előfordulhat, hogy a fájl időközben törlésre került. Kérjük frissítsd az oldalt és próbáld újra!"
            />
          )}
        </Typography>
      </NoRendererInformationBlock>
      <PreviewNotFoundImage />
      <Button onClick={handleReloadWindow} variant="secondaryContained">
        {ReloadPageButtonMessage}
      </Button>
      {renderPostItLayer?.(1, 1)}
    </NoRendererWrapper>
  )
}
