import React from 'react'

import { useIntl } from 'react-intl'

import { ArtifactPreviewOpener, ArtifactPreviewOpenerProps } from './ArtifactPreviewOpener'

import { messages } from './messages'

export function InvoiceArtifactPreviewOpener(
  props: Omit<ArtifactPreviewOpenerProps, 'supportedMessage' | 'unsupportedMessage'>
) {
  const { formatMessage } = useIntl()
  return (
    <ArtifactPreviewOpener
      {...props}
      supportedMessage={formatMessage(messages.invoiceSupportedMessage)}
      unsupportedMessage={formatMessage(messages.invoiceUnsupportedMessage)}
    />
  )
}
