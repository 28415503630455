import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authActions } from '@services'

import { getRouteUrl } from '@helpers/url'

import { useAssertContext } from '@hooks/useAssertContext'

import { CreateCompanyDialog } from '@components/dialogs'

import { RouteKeys } from '@constants'

const CreateCompanyDialogContext = React.createContext<{ open: VoidFunction } | undefined>(undefined)
CreateCompanyDialogContext.displayName = 'CreateCompanyDialogContext'

interface CreateCompanyDialogProviderProps {
  callSelectCompany: (companyId: number) => void
  children: React.ReactNode
}

function PureCreateCompanyDialogProvider({ callSelectCompany, children }: CreateCompanyDialogProviderProps) {
  const [isOpen, setOpen] = React.useState(false)
  const [createdCompanyName, setCreatedCompanyName] = React.useState<Nullable<string>>(null)
  const navigate = useNavigate()
  const timeoutRef = React.useRef<number>()

  function onOpen() {
    if (createdCompanyName != null) {
      setCreatedCompanyName(null)
    }

    setOpen(true)
  }

  function onClose() {
    setOpen(false)
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
    }
  }

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  function onSubmitSuccessHandler(company: Company) {
    setCreatedCompanyName(company.name)

    // redirect user to transaction route with new company id - DashboardCompanyRoutes will take care of the rest
    const url = getRouteUrl(RouteKeys.ROOT, company.id)
    // redirect to new company url
    callSelectCompany(company.id)
    navigate(url)

    timeoutRef.current = window.setTimeout(onClose, 3000)
  }

  return (
    <CreateCompanyDialogContext.Provider
      value={{
        open: onOpen,
      }}
    >
      <>
        {children}

        <CreateCompanyDialog
          createdCompanyName={createdCompanyName}
          isOpen={isOpen}
          onClose={onClose}
          onSubmitSuccessHandler={onSubmitSuccessHandler}
        />
      </>
    </CreateCompanyDialogContext.Provider>
  )
}

PureCreateCompanyDialogProvider.propTypes = {
  callSelectCompany: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export const CreateCompanyDialogProvider = connect(null, {
  callSelectCompany: authActions.selectCompany.request,
})(PureCreateCompanyDialogProvider)

export function useCreateCompanyDialog() {
  return useAssertContext(CreateCompanyDialogContext)
}
