import React from 'react'

import { ZoomOrientation } from '@components/DocViewer/constant'
import { DocViewerMainState } from '@components/DocViewer/types'

import { PDFStateActions } from './actions'
import { DocViewerPDFState, initialPDFState, PDFStateReducer, reducer } from './reducer'
import { getDefaultZoomLevel } from './zoom'

export const PDFControlsContext = React.createContext<{
  state: DocViewerPDFState
  dispatch: React.Dispatch<PDFStateActions>
}>({ state: initialPDFState, dispatch: () => null })

PDFControlsContext.displayName = 'PDFControlsContext'

export const PDFControlsProvider: React.FC<React.PropsWithChildren<{ mainState: DocViewerMainState }>> = ({
  children,
  mainState,
}) => {
  const initialZoomLevel = getDefaultZoomLevel(ZoomOrientation.LANDSCAPE, mainState)
  const [state, dispatch] = React.useReducer<PDFStateReducer>(reducer, {
    ...initialPDFState,
    defaultZoomLevel: mainState.config?.zoom?.defaultZoom ?? initialZoomLevel,
    mainState,
    paginated: mainState.config?.pdfVerticalScrollByDefault ? false : initialPDFState.paginated,
    zoomJump: mainState.config?.zoom?.zoomJump ?? initialPDFState.zoomJump,
    zoomLevel: mainState.config?.zoom?.defaultZoom ?? initialZoomLevel,
    zoomOrientation: mainState.config?.pdfVerticalScrollByDefault
      ? ZoomOrientation.LANDSCAPE
      : ZoomOrientation.PORTRAIT,
  })

  return <PDFControlsContext.Provider value={{ state, dispatch }}>{children}</PDFControlsContext.Provider>
}
