import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { paymentActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage, userHasEditorPermission } from '@helpers'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import {
  Button,
  CustomDialog,
  CustomDialogActions,
  CustomDialogBody,
  CustomDialogHeader,
  Typography,
} from '@components/ui'

import { InvoicePayment, InvoicePaymentDetailsTable } from './InvoicePaymentDetailsTable'

import { CloseButtonMessage, DataLoadingMessage } from '@messages'

const StyledCustomDialogBody = styled(CustomDialogBody)`
  width: 680px;
`
const TableContainer = styled.div`
  & table {
    border: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
    border-radius: 4px;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
`

type InitialState = {
  data: Nullable<InvoicePayment[]>
  error: Nullable<string>
  loading: boolean
}

export enum OpenControls {
  INIT = 'init',
  OPEN = 'open',
  HIDE = 'hide',
  CLOSE = 'close',
}

enum ReducerActionType {
  INIT = 'init',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

type ReducerAction =
  | { type: ReducerActionType.INIT }
  | { type: ReducerActionType.FAILURE; payload: string }
  | { type: ReducerActionType.SUCCESS; payload: InvoicePayment[] }

const initialState: InitialState = {
  data: null,
  error: null,
  loading: true,
}

function reducer(state: InitialState, action: ReducerAction) {
  switch (action.type) {
    case 'init':
      return initialState
    case 'success':
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case 'failure':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

interface InvoicePaymentDetailsDialogProps {
  invoice: ItemIdType
  onClose: VoidFunction
  loadData: AsyncFunction<ItemIdType, InvoicePayment[]>
  isEditorUser: boolean
  openControl: OpenControls
}

function PureInvoicePaymentDetailsDialog({
  invoice,
  onClose,
  loadData,
  isEditorUser,
  openControl,
}: InvoicePaymentDetailsDialogProps) {
  const [{ loading, data, error }, dispatch] = React.useReducer(reducer, initialState)
  const cPromiseRef = useCancellablePromiseRef<InvoicePayment[]>()

  React.useEffect(() => {
    async function initialize() {
      dispatch({ type: ReducerActionType.INIT })
      try {
        cPromiseRef.current = cancellablePromise(loadData(invoice))
        const payload = await cPromiseRef.current.promise
        dispatch({ type: ReducerActionType.SUCCESS, payload })
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          dispatch({ type: ReducerActionType.FAILURE, payload: errorMsg })
        }
      }
    }

    if (openControl === OpenControls.INIT) {
      initialize()
    }

    return () => {
      if (cPromiseRef.current) {
        cPromiseRef.current.cancel()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openControl])

  function renderBodyContent() {
    if (loading) {
      return (
        <LoaderContainer>
          <CircularProgress color="primary" size={30} />
          <Typography size="400-sm">{DataLoadingMessage}</Typography>
        </LoaderContainer>
      )
    }
    if (data) {
      return (
        <TableContainer>
          <InvoicePaymentDetailsTable data={data} isEditorUser={isEditorUser} />
        </TableContainer>
      )
    }
    return (
      <Typography color="error" size="400-md" align="center">
        {error}
      </Typography>
    )
  }

  return (
    <CustomDialog open={openControl === OpenControls.INIT || openControl === OpenControls.OPEN} onClose={onClose}>
      <CustomDialogHeader
        title={<FormattedMessage id="invoice.payments.dialog.title" defaultMessage="Fizetési előzmények" />}
        borderless
      />
      <StyledCustomDialogBody>{renderBodyContent()}</StyledCustomDialogBody>
      <CustomDialogActions borderless>
        <Button variant="primaryContained" onClick={onClose}>
          {CloseButtonMessage}
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

PureInvoicePaymentDetailsDialog.propTypes = {
  invoice: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
  isEditorUser: PropTypes.bool.isRequired,
  loadData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  openControl: PropTypes.oneOf(Object.values(OpenControls)).isRequired,
}

export const InvoicePaymentDetailsDialog = connect(
  (state: Store) => ({
    isEditorUser: userHasEditorPermission(state.auth.company.data.role),
  }),
  dispatch => ({
    loadData: bindActionToPromise(dispatch, paymentActions.loadInvoiceTransactions.request),
  })
)(PureInvoicePaymentDetailsDialog)

InvoicePaymentDetailsDialog.displayName = 'InvoicePaymentDetailsDialog'
