import React from 'react'

import styled from 'styled-components'

function BaseAcountoSyncIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.788 23.287a7.03 7.03 0 0 0 3.912 1.15c1.47 0 2.805-.394 4.006-1.181a8.261 8.261 0 0 0 2.447-2.427l.006 3.018h4.565V7.59h-4.596l.005 2.673a7.496 7.496 0 0 0-2.116-2.114C14.857 7.383 13.543 7 12.073 7a7.544 7.544 0 0 0-4.036 1.15c-1.222.767-2.205 1.813-2.95 3.14C4.362 12.615 4 14.075 4 15.671c0 1.637.331 3.129.994 4.476.683 1.326 1.614 2.372 2.794 3.139Zm7.763-2.953a4.772 4.772 0 0 1-2.484.684c-.89 0-1.698-.228-2.422-.684a4.645 4.645 0 0 1-1.646-1.865c-.393-.808-.59-1.72-.59-2.735s.218-1.927.652-2.735c.456-.829 1.066-1.471 1.832-1.927a4.828 4.828 0 0 1 2.546-.715c.89 0 1.687.238 2.391.715a4.525 4.525 0 0 1 1.677 1.896c.414.808.621 1.72.621 2.735 0 .995-.238 1.896-.714 2.704a5.197 5.197 0 0 1-1.863 1.927ZM28 22.222a2.358 2.358 0 1 1-4.717 0 2.358 2.358 0 0 1 4.717 0Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const AcountoSyncIcon = styled(BaseAcountoSyncIcon)``
