import React from 'react'

import { useIntl } from 'react-intl'

import { useCreatePayment } from '@contexts/PaymentProvider'

import { BulkActionDisabledByAllSelectedTooltip, CreditCardRegularIcon } from '@components/ui'
import { QuickIconButton } from '@oldComponents/ui/buttons'

import { PAYMENT_FLOW } from '@constants'

import { listPageMessages } from '@messages'

interface CreateTransferPaymentIconButtonProps {
  disabled: boolean
  isAllSelected: boolean
}

export function CreateTransferPaymentIconButton({ disabled, isAllSelected }: CreateTransferPaymentIconButtonProps) {
  const [open, setOpen] = React.useState(false)
  const { formatMessage } = useIntl()
  const {
    startTransferPayment,
    state: { loading, flow },
  } = useCreatePayment()

  function handleClick() {
    setOpen(false)
    startTransferPayment()
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <QuickIconButton
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onClick={handleClick}
      disabled={disabled || loading}
      loading={loading && flow === PAYMENT_FLOW.transfer}
      icon={<CreditCardRegularIcon size={24} />}
      size="medium"
      tooltipText={
        <BulkActionDisabledByAllSelectedTooltip
          isAllSelected={isAllSelected}
          tooltip={formatMessage(listPageMessages.createPayment)}
        />
      }
    />
  )
}
