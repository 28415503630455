import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { PortalProvider } from '@contexts'

import { PortalAnchorElement } from '@components/ArtifactViewer'
import { GeneratedPreviewViewer } from '@components/GeneratedPreviewViewer'
import { ParentSize } from '@components/ui'

import { DOC_PREVIEW_CONFIG } from '@constants'

interface ExpensePreviewProps {
  company: number
  expenseId: number
}

type InvoicePreviewData = { company: number; id: ItemIdType }
/**
 * Helper function to build preview API url for expense invoice
 * @param {InvoicePreviewData} invoiceDetails - details data of expense invoice
 * @returns preview API url for expense invoice
 */
function generatePreviewApiUrl({ company, id }: InvoicePreviewData) {
  return `/api/v2/company/${company}/expenses/${id}/preview`
}

function PureExpensePreview({ company, expenseId }: ExpensePreviewProps) {
  const previewUrl = React.useMemo(() => {
    const url = generatePreviewApiUrl({ company, id: expenseId })
    return url + `?t=${Date.now()}` // prevent caching
  }, [company, expenseId])

  return (
    <PortalProvider>
      <ParentSize>
        {({ height, width }) => (
          <GeneratedPreviewViewer
            config={{
              ...DOC_PREVIEW_CONFIG,
              viewerSize: {
                maxHeight: height - DOC_PREVIEW_CONFIG.controlsBarHeight,
                maxWidth: width,
              },
            }}
            previewUrl={previewUrl}
            previewVariant="expense"
          />
        )}
      </ParentSize>
      <PortalAnchorElement />
    </PortalProvider>
  )
}

PureExpensePreview.propTypes = {
  company: PropTypes.number.isRequired,
  expenseId: PropTypes.number.isRequired,
}

export const ExpensePreview = connect((state: Store) => ({
  company: state.auth.company.data.id,
}))(PureExpensePreview)

ExpensePreview.displayName = 'ExpensePreview'
