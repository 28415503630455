import styled from 'styled-components'

import { CustomDialog, CustomDialogBody } from '@components/ui/Dialogs'
import { Typography } from '@components/ui/Typography'

export const StyledDialog = styled(CustomDialog)`
  width: 924px;
`

export const DateFilterWrapperDiv = styled.div`
  display: grid;
  grid-template-columns: auto 196px;
  gap: 20px;

  & > *:nth-child(3) {
    grid-column: span 2;
  }
`

export const FilterWrapper = styled.div`
  ${Typography} {
    text-transform: uppercase;
  }
`

export const StatusFilterWrapper = styled(FilterWrapper)`
  & > *:last-child {
    padding: 0;
  }
`

export const FilterDialogBody = styled(CustomDialogBody)`
  display: flex;
  flex-direction: column;
  padding: 0;

  & > * {
    padding: 20px;
  }

  & > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[110]};
  }
`

export const IncludeExcludeFilterWrapper = styled(FilterWrapper)`
  display: flex;
  gap: 20px;

  & > div {
    flex: 1;
  }
`

export const ChoicesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`
