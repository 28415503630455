import React from 'react'
import { bool, func } from 'prop-types'

import { Button, DialogContentText } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@oldComponents/dialogs'

const InvoiceClassifyConfirmDialog = ({ onConfirm, onSkip, onClose, open }) => (
  <Dialog open={open} onClose={onClose} aria-labelledby="invoice-classify-confirm-dialog-title">
    <DialogTitle id="invoice-classify-confirm-dialog-title">
      <FormattedMessage id="dialogs.invoiceClassifyDialog.title" defaultMessage="Számla felismerés" />
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <FormattedMessage
          id="dialogs.invoiceClassifyDialog.description"
          // eslint-disable-next-line max-len
          defaultMessage="Szeretnéd, ha lefuttatnánk a felismerést a most feltöltött számlaképen és felülírjuk a már korábban megadott számla adatait, vagy az adatokhoz ne nyúljunk?"
        />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} color="default" variant="contained">
        <FormattedMessage id="dialogs.invoiceClassifyDialog.buttons.confirm" defaultMessage="Futtassunk felismerést" />
      </Button>
      <Button onClick={onSkip} color="primary" variant="contained">
        <FormattedMessage id="dialogs.invoiceClassifyDialog.buttons.cancel" defaultMessage="Ne nyúljunk az adatokhoz" />
      </Button>
    </DialogActions>
  </Dialog>
)

InvoiceClassifyConfirmDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  onSkip: func.isRequired,
}
export default InvoiceClassifyConfirmDialog
