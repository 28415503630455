/**
 * Helper to get Autokassza availability by providerId
 *
 * @param {(number | string | null)} providerId - bank provider id
 * @param {BankProvider[]} providers - list of bank provider options
 * @returns {bool} Autokassza can be enabled
 */
export function getIsAutokasszaEnabled(providerId: Nullable<number | string>, providers: BankProvider[]) {
  if (!providerId) {
    return false
  }

  return providers.find(provider => provider.id === providerId)?.autokassza_available ?? false
}

/**
 * Helper to get the bank provider name from the bank provider id
 *
 * @param {(number | null)} provider - bank provider id
 * @param {PaidThroughOption[]} paidThroughOptions - list of paid through options
 * @returns {string} bank provider name
 */
export function getProviderNameFromPaidThroughOptions(
  provider: number | null,
  paidThroughOptions: PaidThroughOption[]
) {
  if (!provider) {
    return ''
  }

  return paidThroughOptions.find(({ id }) => id === provider)?.provider_name ?? ''
}
