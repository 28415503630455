import React from 'react'

import styled from 'styled-components'

import { LightTooltip, UpsellMessage } from '@oldComponents/ui'

const FakeFilterButton = styled.div`
  height: 100%;
  display: flex;
  padding: 15px 20px;
  flex-wrap: wrap;
  min-width: 240px;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.gray[70]};
  font-size: 12px;
  font-weight: 700;
  line-height: inherit;
  flex: 1;

  &:hover {
    cursor: help;
    background-color: ${({ theme }) => theme.colors.gray[10]};
  }
`

const ButtonLabelSpan = styled.span`
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: -5px;
  text-transform: uppercase;
`
const ButtonValueSpan = styled.span`
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  height: 30px;
  line-height: 30px;
  width: 100%;
`

interface UpsellFilterSelectProps {
  emptyPlaceholder?: React.ReactChild
  labelText: React.ReactChild
  message?: React.ReactChild
}

export function UpsellFilterSelect({
  emptyPlaceholder,
  labelText,
  message = <UpsellMessage />,
}: UpsellFilterSelectProps) {
  return (
    <LightTooltip title={message} placement="top">
      <FakeFilterButton>
        <ButtonLabelSpan>{labelText}</ButtonLabelSpan>
        <ButtonValueSpan>{emptyPlaceholder}</ButtonValueSpan>
      </FakeFilterButton>
    </LightTooltip>
  )
}
