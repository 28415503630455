import React from 'react'
import PropTypes from 'prop-types'

import Braintree from 'braintree-web'
import cx from 'classnames'
import __uniqueId from 'lodash/uniqueId'

import BraintreeContext from './context'

function cap(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function BraintreeProvider({
  children,
  className: providedClassName,
  createPaymentMethodNonce,
  token,
  styles,
  onError,
  onReady,
}) {
  const authorizationRef = React.useRef(null)
  const clientRef = React.useRef(null)
  const dataCollectorRef = React.useRef(null)
  const hostedFieldsRef = React.useRef(null)
  const threeDSecureRef = React.useRef(null)
  const pendingTimerRef = React.useRef(null)

  // hold hostedFields data
  const fieldsRef = React.useRef({})
  const fieldHandlersRef = React.useRef({})

  const createDataCollector = React.useCallback(
    client => {
      Braintree.dataCollector.create(
        {
          client: client,
          kount: true,
        },
        function (err, dataCollectorInstance) {
          if (!err) {
            dataCollectorRef.current = dataCollectorInstance
          }
        }
      )
    },
    [dataCollectorRef]
  )

  function onFieldEvent(eventName, event) {
    const fieldHandlers = fieldHandlersRef.current[event.emittedBy]
    if (fieldHandlers && fieldHandlers[eventName]) {
      fieldHandlers[eventName](event.fields[event.emittedBy], event)
    }
  }

  const createHostedFields = React.useCallback(
    client => {
      Braintree.hostedFields.create(
        {
          client,
          styles,
          fields: fieldsRef.current,
        },
        (err, hostedFields) => {
          if (err) {
            onError(err)
          } else {
            hostedFieldsRef.current = hostedFields
            // TODO: what these do?
            const events = ['blur', 'focus', 'empty', 'notEmpty', 'cardTypeChange', 'validityChange']
            events.forEach(eventName => {
              hostedFields.on(eventName, ev => onFieldEvent(`on${cap(eventName)}`, ev))
            })

            onReady()
          }
        }
      )
    },
    [onError, onReady, styles]
  )

  // 3DSecure
  // NOTE test cards: https://developers.braintreepayments.com/guides/3d-secure/migration/javascript/v3
  const create3DSecure = React.useCallback(
    client => {
      Braintree.threeDSecure.create(
        {
          version: 2, // Will use 3DS 2 whenever possible
          client,
        },
        function (err, threeDSecureInstance) {
          if (err) {
            // Handle error in 3D Secure component creation
            console.log('create3DSecure error', err)
            onError(err)
          } else {
            // set up lookup-complete listener
            threeDSecureInstance.on('lookup-complete', function (data, next) {
              // check lookup data
              if (process.env.NODE_ENV === 'development') console.log('[DEV log] 3DSecure lookup data', data)
              next()
            })
            threeDSecureInstance.on('customer-canceled', function () {
              if (process.env.NODE_ENV === 'development') console.log('[DEV log] 3DSecure lookup - customer canceled')
            })
            threeDSecureRef.current = threeDSecureInstance
          }
        }
      )
    },
    [onError]
  )

  const clearError = React.useCallback(() => onError(null), [onError])

  const tokenize = React.useCallback(
    ({ amount, onVerifySuccess, onVerifyFailure }) => {
      // (validate)
      // tokenize
      // https://braintree.github.io/braintree-web/current/HostedFields.html#tokenize
      hostedFieldsRef.current.tokenize(async function (err, tokenizePayload) {
        if (err) {
          // set global errors
          onError(err)
          // set form fields errors
          onVerifyFailure(err)
        } else {
          try {
            const response = await createPaymentMethodNonce({ nonce: tokenizePayload.nonce })

            const options = {
              nonce: response.nonce,
              amount,
              bin: tokenizePayload.details.bin,
              challengeRequested: true,
              // email: 'test@example.com'
              // billingAddress: {
              //   givenName: 'Jill',
              //   surname: 'Doe',
              //   phoneNumber: '8101234567',
              //   streetAddress: '555 Smith St.',
              //   extendedAddress: '#5',
              //   locality: 'Oakland',
              //   region: 'CA',
              //   postalCode: '12345',
              //   countryCodeAlpha2: 'US'
              // },
              // onLookupComplete: function (data, next) {
              //   // use `data` here, then call `next()`
              //   next()
              // },
            }

            // 3DSecure
            // https://braintree.github.io/braintree-web/current/ThreeDSecure.html#verifyCard-examples
            threeDSecureRef.current.verifyCard(options, function (err, payload) {
              if (err) {
                onError(err)
                // NOTE documentation
                // if (err.code.indexOf('THREEDS_LOOKUP') === 0) {
                //   // an error occurred during the initial lookup request
                //   if (err.code === 'THREEDS_LOOKUP_TOKENIZED_CARD_NOT_FOUND_ERROR') {
                //     // either the passed payment method nonce does not exist
                //     // or it was already consumed before the lookup call was made
                //   } else if (err.code.indexOf('THREEDS_LOOKUP_VALIDATION') === 0) {
                //     // a validation error occurred
                //     // likely some non-ascii characters were included in the billing
                //     // address given name or surname fields, or the cardholdername field
                //     // Instruct your user to check their data and try again
                //   } else {
                //     // an unknown lookup error occurred
                //   }
                // } else {
                //   // some other kind of error
                // }
              } else {
                // https://braintree.github.io/braintree-web/current/ThreeDSecure.html#verifyCard
                if (process.env.NODE_ENV === 'development') {
                  console.group('[DEV log] 3DSecure verifyCard', payload)
                  if (payload.liabilityShifted) {
                    // Liability has shifted
                    console.log('Liability has shifted - submit nonce to server')
                  } else if (payload.liabilityShiftPossible) {
                    // Liability may still be shifted
                    // Decide if you want to submit the nonce
                    console.log('Liability may still be shifted - do not submit nonce to server')
                  } else {
                    // Liability has not shifted and will not shift
                    // Decide if you want to submit the nonce
                    console.log('Liability has not shifted and will not shift - do not submit nonce to server')
                  }
                  console.groupEnd()
                }
                if (payload.liabilityShifted) {
                  onVerifySuccess(payload)
                } else {
                  // generate a custom error
                  // TODO should use only one generic error here?
                  const threeDSecureError = {
                    method: 'verifyCard',
                    code: '3DSecure',
                    status: payload.threeDSecureInfo.status,
                    details: payload.verificationDetails,
                  }
                  onError(threeDSecureError)
                  onVerifyFailure(threeDSecureError)
                  if (process.env.NODE_ENV === 'development')
                    console.log('[DEV log] Generate custom error state with 3DSecure', threeDSecureError)
                }
              }
            })
          } catch (err) {
            if (process.env.NODE_ENV === 'development') console.log('[DEV log] Create payment method nonce failed', err)
            onError({ code: 'CREATE_PAYMENT_METHOD_TOKEN_FAILURE' })
            onVerifyFailure(err)
          }
        }
      })
    },
    [createPaymentMethodNonce, onError]
  )

  const registerField = React.useCallback(
    ({
      formatInput,
      maxlength,
      minlength,
      placeholder,
      select,
      name,
      prefill,
      id = __uniqueId('braintree-field-wrapper-'),
      rejectUnsupportedCards,
      ...handlers
    }) => {
      const onRenderComplete = () => {
        fieldHandlersRef.current[name] = handlers
        fieldsRef.current[name] = {
          formatInput,
          maxlength,
          minlength,
          placeholder,
          select,
          prefill,
          selector: `#${id}`,
        }
        if ('number' === name && rejectUnsupportedCards) {
          fieldsRef.current.number.rejectUnsupportedCards = true
        }
      }
      return [id, onRenderComplete]
    },
    []
  )

  const teardown = () => {
    if (dataCollectorRef.current) {
      dataCollectorRef.current.teardown()
    }
    if (threeDSecureRef.current) {
      threeDSecureRef.current.teardown()
    }
    if (hostedFieldsRef.current) {
      hostedFieldsRef.current.teardown()
    }
    if (pendingTimerRef.current) {
      clearTimeout(pendingTimerRef.current)
      pendingTimerRef.current = null
    }
  }

  const init = React.useCallback(
    authorization => {
      if (authorizationRef.current !== authorization) {
        // fields have not yet registered, delay init so they can register
        if (0 === Object.keys(fieldsRef.current).length && !pendingTimerRef.current) {
          pendingTimerRef.current = setTimeout(() => {
            pendingTimerRef.current = null
            init(authorization)
          }, 5)
        } else {
          if (authorizationRef.current) {
            teardown()
          }
          authorizationRef.current = authorization

          Braintree.client.create(
            {
              authorization: authorization,
            },
            function (err, clientInstance) {
              if (err) {
                onError(err)
              } else {
                // clientInstance exists - braintree initialized
                clientRef.current = clientInstance
                // dataCollector when needed
                createDataCollector(clientInstance)
                // 3Dsecure
                create3DSecure(clientInstance)
                // hostedFields
                createHostedFields(clientInstance)
              }
            }
          )
        }
      }
    },
    [create3DSecure, createDataCollector, createHostedFields, onError]
  )

  React.useEffect(() => {
    if (token) {
      init(token)
    }
  }, [init, token])

  // TEARDOWN when unmount
  React.useEffect(() => {
    return () => {
      teardown()
    }
  }, [])

  const contextValue = React.useMemo(
    () => ({
      clearError,
      client: clientRef.current,
      dataCollector: dataCollectorRef.current,
      tokenize,
      hostedFields: hostedFieldsRef.current,
      threeDSecure: threeDSecureRef.current,
      registerField,
      fields: fieldsRef.current,
    }),
    [clearError, hostedFieldsRef, registerField, tokenize]
  )

  return (
    <BraintreeContext.Provider value={contextValue}>
      <div className={cx('braintree-hosted-fields-wrapper', providedClassName)}>{children}</div>
    </BraintreeContext.Provider>
  )
}

BraintreeProvider.defaultProps = { className: '' }
BraintreeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onError: PropTypes.func.isRequired,
  onReady: PropTypes.func.isRequired,
  styles: PropTypes.objectOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))).isRequired,
  token: PropTypes.string.isRequired,
  createPaymentMethodNonce: PropTypes.func.isRequired,
}
