import { isWorkerError } from './helpers'

//* call worker
export function* salaryListWorkerRunner(payload: { salaries: BackendSalaryListResult[]; tags: Tag[] }) {
  // Initialize the web worker
  const worker = new Worker(new URL('./salaryList.worker.ts', import.meta.url))
  // Send data to the worker
  worker.postMessage(payload)
  // Listen for messages from the worker
  const workerResults: SalaryListData[] | WorkerError = yield new Promise(resolve => {
    worker.onmessage = event => {
      resolve(event.data)
    }
  })
  // To remove the event listener later
  worker.onmessage = null
  // Close the worker after processing
  worker.terminate()

  if (isWorkerError(workerResults)) {
    throw new Error(`WebWorker error: ${workerResults.error}`)
  }

  return workerResults
}
