import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getActiveCompanyId, getErrorMessage, getFormErrors } from '@helpers'

import expenseActions from '../expense/actions'
import incomeActions from '../income/actions'
import quarantineActions from '../quarantine/actions'
import salaryActions from '../salary/actions'
import taxActions from '../tax/actions'
import actions from './actions'
import * as api from './api'

function* initializeSaga({ meta: { resolve, reject } }) {
  try {
    const response = yield call(api.initialize)
    yield put(actions.initialize.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.initialize.failure())
    yield call(reject, errorMsg)
  }
}

// status check
function* dashboardStatusCheckSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.dashboardStatusCheck, companyId)
    yield put(actions.dashboardStatusCheck.success(response.data))
  } catch (error) {
    // const errorMsg = getErrorMessage(error)
    yield put(actions.dashboardStatusCheck.failure())
  }
}

// TAGS
function* fetchTagsSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchTags, companyId)
    yield put(actions.fetchTags.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchTags.failure(errorMsg))
  }
}

function* createTagSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createTag, companyId, payload)
    yield put(actions.createTag.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// called by salary editor - do not update store
function* fetchSalaryGroupDetailsToStateSaga({ meta: { resolve, reject }, payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchSalaryGroupDetails, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    console.error(error)
  }
}

// upload salary
function* uploadSalaryAndTaxV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.uploadSalaryAndTax, companyId, payload)
    yield put(actions.uploadSalaryAndTaxV2.success())
    if (payload.dataType === 'salary') {
      yield put(salaryActions.triggerSalaryListUpdate.request())
    }
    if (payload.dataType === 'tax') {
      yield put(taxActions.triggerTaxListUpdate.request())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.uploadSalaryAndTaxV2.failure())
    yield call(reject, errorMsg)
  }
}

// manual create salary
function* createMonthlySalaryV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createMonthlySalary, companyId, payload)
    yield put(actions.createMonthlySalaryV2.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

// user viewed new salary
function* userViewSalaryV2Saga({ payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.userViewSalary, companyId, payload)
    yield put(actions.userViewSalaryV2.success(payload))
  } catch (error) {
    // do nothing
  }
}

function* updateMonthlySalaryV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateMonthlySalary, companyId, payload)
    yield put(actions.updateMonthlySalaryV2.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* removeMonthlySalaryV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removeMonthlySalary, companyId, payload.id)
    yield put(actions.removeMonthlySalaryV2.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// TAX
// called by salary editor - do not update store
function* fetchTaxGroupDetailsToStateSaga({ meta: { resolve, reject }, payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchTaxGroupDetails, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    console.error(error)
  }
}

// user viewed new tax
function* userViewTaxV2Saga({ payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.userViewTax, companyId, payload)
    yield put(actions.userViewTaxV2.success(payload))
  } catch (error) {
    // do nothing
  }
}

// manual create monthly tax
function* createMonthlyTaxV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createMonthlyTax, companyId, payload)
    yield put(actions.createMonthlyTaxV2.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* updateMonthlyTaxV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateMonthlyTax, companyId, payload)
    yield put(actions.updateMonthlyTaxV2.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* removeMonthlyTaxV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removeMonthlyTax, companyId, payload.id)
    yield put(actions.removeMonthlyTaxV2.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// TAX CODES
function* fetchTaxCodesSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchTaxCodes, companyId)
    yield put(actions.fetchTaxCodes.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchTaxCodes.failure(errorMsg))
  }
}

// BULK TAGGING
function* initTaggingSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.initTagging, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* ACCOUNTING (Yeri)
function* fetchJobNumberHistorySaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchJobNumberHistory, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchAssignmentNoteRecommendationsSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchAssignmentNoteRecommendations, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchLedgerNumbersSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchLedgerNumbers, companyId)
    yield put(actions.fetchLedgerNumbers.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    // do nothing
    console.error('Fetch ledger numbers failed:', errorMsg)
  }
}

function* createLedgerNumberSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createLedgerNumber, companyId, payload)
    yield put(actions.createLedgerNumber.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// v2 transactions warning notification
function* refreshTransactionsPageSaga({ payload }) {
  if (payload === 'expense') {
    yield put(expenseActions.triggerExpenseListUpdate.request())
  }
  if (payload === 'quarantine') {
    yield put(quarantineActions.triggerQuarantineListUpdate.request())
  }
  if (payload === 'income') {
    yield put(incomeActions.triggerIncomeListUpdate.request())
  }
}

//* v2 event log (generic)
function* fetchEventsLogDataSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchEventsLogData, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* getCompanyConsentsSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.getCompanyConsents, companyId)
    yield call(resolve, response.data.consents)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* setCompanyConsentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.setCompanyConsent, companyId, payload)
    yield call(resolve, response.data.consents)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.initialize.REQUEST, initializeSaga)
  // expense status check
  yield takeLatest(actions.dashboardStatusCheck.REQUEST, dashboardStatusCheckSaga)
  // tags
  yield takeLatest(actions.fetchTags.REQUEST, fetchTagsSaga)
  yield takeLatest(actions.createTag.REQUEST, createTagSaga)

  // salary
  yield takeLatest(actions.fetchSalaryGroupDetailsToState.REQUEST, fetchSalaryGroupDetailsToStateSaga)
  // tax
  yield takeLatest(actions.fetchTaxGroupDetailsToState.REQUEST, fetchTaxGroupDetailsToStateSaga)
  // tax code
  yield takeLatest(actions.fetchTaxCodes.REQUEST, fetchTaxCodesSaga)
  // tagging
  yield takeLatest(actions.initTagging.REQUEST, initTaggingSaga)
  //*v2
  yield takeLatest(actions.uploadSalaryAndTaxV2.REQUEST, uploadSalaryAndTaxV2Saga)
  yield takeLatest(actions.createMonthlySalaryV2.REQUEST, createMonthlySalaryV2Saga)
  yield takeLatest(actions.updateMonthlySalaryV2.REQUEST, updateMonthlySalaryV2Saga)
  yield takeLatest(actions.removeMonthlySalaryV2.REQUEST, removeMonthlySalaryV2Saga)
  yield takeLatest(actions.userViewSalaryV2.REQUEST, userViewSalaryV2Saga)
  yield takeLatest(actions.createMonthlyTaxV2.REQUEST, createMonthlyTaxV2Saga)
  yield takeLatest(actions.userViewTaxV2.REQUEST, userViewTaxV2Saga)
  yield takeLatest(actions.updateMonthlyTaxV2.REQUEST, updateMonthlyTaxV2Saga)
  yield takeLatest(actions.removeMonthlyTaxV2.REQUEST, removeMonthlyTaxV2Saga)
  //* accounting
  yield takeLatest(actions.fetchJobNumberHistory.REQUEST, fetchJobNumberHistorySaga)
  yield takeLatest(actions.fetchAssignmentNoteRecommendations.REQUEST, fetchAssignmentNoteRecommendationsSaga)
  yield takeLatest(actions.fetchLedgerNumbers.REQUEST, fetchLedgerNumbersSaga)
  yield takeLatest(actions.createLedgerNumber.REQUEST, createLedgerNumberSaga)
  yield takeLatest(actions.refreshTransactionsPage.REQUEST, refreshTransactionsPageSaga)
  // events log v2
  yield takeLatest(actions.fetchEventsLogData.REQUEST, fetchEventsLogDataSaga)
  //* company consents
  yield takeLatest(actions.getCompanyConsents.REQUEST, getCompanyConsentsSaga)
  yield takeLatest(actions.setCompanyConsent.REQUEST, setCompanyConsentSaga)
}
