import React from 'react'

import styled from 'styled-components'

function BaseSettingsIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 6.3438a6.5267 6.5267 0 0 0-.5-1.25L14.25 3A8.8623 8.8623 0 0 0 13 1.75l-2.0938 1a6.522 6.522 0 0 0-1.25-.5L8.875.0625C8.5625.0208 8.2708 0 8 0c-.2708 0-.5625.0208-.875.0625L6.3437 2.25a6.5248 6.5248 0 0 0-1.25.5L3 1.75A8.8602 8.8602 0 0 0 1.75 3l1 2.0938a6.5248 6.5248 0 0 0-.5 1.25L.0625 7.125C.0208 7.4375 0 7.7292 0 8c0 .2708.0208.5625.0625.875l2.1875.7813a6.522 6.522 0 0 0 .5 1.2499L1.75 13c.375.4583.7917.875 1.25 1.25l2.0938-1c.3958.2083.8125.375 1.25.5l.7812 2.1875c.3125.0417.6042.0625.875.0625.2708 0 .5625-.0208.875-.0625l.7813-2.1875a6.5239 6.5239 0 0 0 1.2499-.5l2.0938 1A8.8645 8.8645 0 0 0 14.25 13l-1-2.0938a6.5239 6.5239 0 0 0 .5-1.25l2.1875-.7812C15.9792 8.5625 16 8.2708 16 8c0-.2708-.0208-.5625-.0625-.875L13.75 6.3437Zm-2.9375 4.5C10.0417 11.6146 9.1042 12 8 12s-2.0469-.3906-2.8281-1.1719C4.3906 10.0469 4 9.1041 4 8c0-1.1042.3906-2.0469 1.1719-2.8281C5.953 4.3906 6.8959 4 8 4c1.1042 0 2.0469.3906 2.8281 1.1719C11.6094 5.953 12 6.8959 12 8c0 1.1042-.3958 2.0521-1.1875 2.8438Zm-4.2188-4.25C6.198 6.9896 6 7.4582 6 8c0 .5417.198 1.0104.5938 1.4063C6.9896 9.802 7.4582 10 8 10c.5417 0 1.0104-.198 1.4063-.5938C9.802 9.0105 10 8.5418 10 8c0-.5417-.198-1.0104-.5938-1.4063C9.0105 6.198 8.5418 6 8 6c-.5417 0-1.0104.198-1.4063.5938Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const SettingsIcon = styled(BaseSettingsIcon)``
