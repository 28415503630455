import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { TableCell } from '@components/ui/TableElements'
import { Typography } from '@components/ui/Typography'
import LightTooltip from '@oldComponents/ui/LightTooltip'

export const StyledLightTooltip = withStyles(theme => ({
  tooltip: {
    padding: 0,
  },
  popper: {
    zIndex: ({ placedInModal }: { placedInModal?: boolean }) =>
      placedInModal ? theme.zIndex.modal + 1 : theme.zIndex.modal - 1,
  },
}))(LightTooltip)

export const IndicatorWrapper = styled.span`
  display: inline-flex;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;

  &:hover {
    cursor: help;
  }
`

export const StyledProgress = styled(CircularProgress).attrs({ size: 16, color: 'primary' })`
  font-size: 14px;
  margin: 8px;
`

export const HistoryTitleTypography = styled(Typography)<{ $hasDetails: boolean }>`
  border-bottom: ${({ theme, $hasDetails }) => ($hasDetails ? `1px solid ${theme.colors.common.paperStroke}` : 'none')};
  padding: 10px 10px;
`
export const HistoryTableCell = styled(TableCell)`
  border: 0;

  &:first-of-type {
    padding: 0 10px 5px;
  }
  &:last-of-type {
    padding: 0 10px 5px;
  }
`

export const SummaryContainerTypography = styled(Typography)`
  padding: 5px 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  display: flex;
  justify-content: space-between;
`

export const HistoryTableContainer = styled.div`
  padding: 5px 0 0;
  text-align: center;
`

export const NoDataTypography = styled(Typography)`
  padding: 0 10px 5px;
`
