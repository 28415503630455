//! DEPRECATED - use @components/layouts/PrivatePage
import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import Helmet from 'react-helmet'

import { DashboardAnnouncement } from '@components/DashboardAnnouncement'

import { APP_TITLE } from '@constants'

import styles from './styles'

const useStyles = makeStyles(styles)

interface PrivatePageProps {
  children?: React.ReactNode
  className?: string
  noMinWidth?: boolean
  subtitle?: string
  warningBarIsActive?: boolean
}

function PrivatePage({
  children,
  className,
  noMinWidth = false,
  subtitle,
  warningBarIsActive = false,
}: PrivatePageProps) {
  const classes = useStyles()

  return (
    <Grid
      container
      className={cx(classes.root, className, {
        [classes.minWidth]: !noMinWidth,
        [classes.warningBarIsActive]: warningBarIsActive,
      })}
      alignItems="stretch"
      direction="column"
    >
      {subtitle && <Helmet title={`${subtitle} | ${APP_TITLE}`} />}
      {children}
      <DashboardAnnouncement />
    </Grid>
  )
}

PrivatePage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noMinWidth: PropTypes.bool,
  subtitle: PropTypes.string,
  warningBarIsActive: PropTypes.bool,
}

export default PrivatePage
