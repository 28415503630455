import React from 'react'
import { bool } from 'prop-types'

import { CircularProgress, Fade, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  overlay: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999,

    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: `linear-gradient(to bottom, transparent 140px, ${theme.palette.common.white} 300px)`,
    opacity: 0.5,
  },
  content: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    '& p': {
      fontSize: 20,
      fontWeight: 600,
    },
  },
}))

export function LoadingOverlay({ loading }) {
  const classes = useStyles()

  return (
    <Fade in={loading} mountOnEnter unmountOnExit>
      <div className={classes.overlay}>
        <div className={classes.content}>
          <CircularProgress size={60} />
          <Typography>
            <FormattedMessage id="subscription.form.submitButton.loadingText" defaultMessage="Fizetés folyamatban..." />
          </Typography>
        </div>
        <div className={classes.background} />
      </div>
    </Fade>
  )
}

LoadingOverlay.propTypes = {
  loading: bool.isRequired,
}
