import React from 'react'

import { DocViewerContext } from '../DocViewerProvider'
import { DocRenderer } from '../types'

export const useRendererSelector = (): {
  CurrentRenderer: DocRenderer | null | undefined
} => {
  const {
    state: { currentDocument, pluginRenderers },
  } = React.useContext(DocViewerContext)

  const [CurrentRenderer, setCurrentRenderer] = React.useState<DocRenderer | null | undefined>()

  React.useEffect(() => {
    if (!currentDocument) return

    const fileType = currentDocument.fileType

    if (!fileType) {
      setCurrentRenderer(undefined)
      return
    }

    const SelectedRenderer = pluginRenderers?.find(r => r.fileTypes.indexOf(fileType) >= 0)

    if (SelectedRenderer && SelectedRenderer !== undefined) {
      setCurrentRenderer(() => SelectedRenderer)
    } else {
      setCurrentRenderer(null)
    }
  }, [currentDocument, pluginRenderers])

  return { CurrentRenderer }
}
