import { defineMessages } from 'react-intl'

const messages = defineMessages({
  taxNoLabel: {
    id: 'nav.preview.taxNumber',
    defaultMessage: 'Adószám',
  },
  paymentMethodLabel: {
    id: 'nav.preview.paymentMethod',
    defaultMessage: 'Fizetési mód',
  },
})

export default messages
