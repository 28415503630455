import React from 'react'

import styled from 'styled-components'

import { applyOpacity } from '@helpers'

import triangleSVGSrc from '@assets/img/triangle.svg'

import { Typography } from '../Typography'

const MessageWrapper = styled.div`
  padding: 24px 20px;
  border-radius: 12px;
  background-color: ${applyOpacity('#E0DDDD', 25)};
  backdrop-filter: blur(12px);

  ${Typography} {
    white-space: nowrap;
    position: relative;
    z-index: 1;
  }
`

const ContentWrapper = styled.div`
  padding: 20px;
  filter: blur(6px);
`

const DemoContentWrapper = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.common.demoBlue};
  background-color: ${({ theme }) => applyOpacity(theme.colors.gray[0], 25)};
  border-radius: 8px;
  position: relative;

  ${MessageWrapper} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding-left: 32px;
      background-image: url(${triangleSVGSrc});
      background-position: top 0.2875em left;
      background-repeat: no-repeat;
    }
  }
`

interface DemoContentProps {
  children: React.ReactChild | React.ReactChild[]
  message: StringOrMessage
}

export default function DemoContent({ children, message }: DemoContentProps) {
  return (
    <DemoContentWrapper>
      <ContentWrapper>{children}</ContentWrapper>
      <MessageWrapper>
        <Typography color="gray-80" size="700-md">
          {message}
        </Typography>
      </MessageWrapper>
    </DemoContentWrapper>
  )
}
