import React from 'react'

export default function PaperPlanIcon({ className, size = 18 }: IconComponentProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 18 18"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m17.986 1.296-2.25 14.593a1.125 1.125 0 0 1-1.545.867l-4.31-1.796-1.788 2.682A.698.698 0 0 1 7.48 18a.73.73 0 0 1-.731-.732v-3.38c0-.251.083-.494.237-.691l7.638-9.822L4.3 12.667.692 11.162a1.13 1.13 0 0 1-.69-.999c-.026-.46.192-.833.565-1.046L16.317.148a1.124 1.124 0 0 1 1.669 1.148Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
