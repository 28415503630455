// NOTE cloned from MonthlyStatementListTable's styles
const _gray = 'rgb(224,224,224)'

export const tableStyles = (theme: any) => ({
  dataTable: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto', // Safari needs to have it set on parent div when using position sticky
  },
  table: {
    position: 'relative',
    color: theme.palette.grayscale.xxDark,
    fontSize: '14px',
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    overflow: 'visible',
    // row
    '&>div:hover': {
      backgroundColor: '#f3f3f3',
    },
    // cell
    '&>div>div': {
      borderBottom: `1px solid ${_gray}`,
    },
    '&>div': {
      flex: 'none',
    },
  },
})

export const elementsStyles = () => ({
  row: {
    flex: '1 auto',
    display: 'inline-flex',
    minWidth: '100%',
    backgroundColor: '#fff',
    minHeight: 46,

    '&>*:first-child': {
      paddingLeft: 20,
    },
  },
  headerRow: {
    fontWeight: 600,
    // cell
    '&>div': {
      borderBottom: `2px solid ${_gray}`,
    },
  },
  cell: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 15px',
    backgroundColor: 'inherit',
  },
  alignRightCell: {
    justifyContent: 'flex-end',
  },
  highlightedRow: {
    // backgroundColor: theme.palette.secondary.xxLight,
    backgroundColor: 'rgba(194,221,226)',
  },
  warningRow: {
    backgroundColor: 'rgba(120,170,184)',
  },
})
