import { makeStyles } from '@material-ui/core/styles'

import { generateMaxHeightBreakPoint, generateMaxWidthBreakPoint, generateMinWidthBreakPoint } from '@constants'

export const useStyles = makeStyles(theme => ({
  logo: {
    marginRight: 8,
    [generateMinWidthBreakPoint('large')]: {
      marginRight: '1rem',
    },
  },
  appbar: {
    backgroundColor: theme.palette.neutral.xxxLight,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.2)',
    border: 'none',
    borderBottom: `1px solid ${theme.palette.tertiary.paperStroke}`,
    zIndex: 1296, // higher than details page quick paging bar (1295)
    transition: 'top ease-in-out 400ms',

    '&.dense': {
      boxShadow: 'none',
      top: theme.appBarHeightMedium - theme.appBarHeight,
    },
  },
  toolbar: {
    minHeight: theme.appBarHeight,
    maxHeight: theme.appBarHeight,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    transition: 'max-height 200ms',

    [generateMaxWidthBreakPoint('small')]: {
      paddingLeft: 0,
      paddingRight: 0,
      overflow: 'hidden',
      backgroundColor: theme.palette.grayscale.inputBackground,

      '&.open': {
        maxHeight: 550,
        paddingBottom: 10,
        borderBottom: `1px solid ${theme.palette.grayscale.xLight}`,
      },
    },

    [generateMaxHeightBreakPoint('medium')]: {
      minHeight: theme.appBarHeightMedium,
      maxHeight: theme.appBarHeightMedium,
    },

    [generateMinWidthBreakPoint('small')]: { paddingRight: 0 },
  },
  mobileRoot: {
    paddingLeft: 24,
    paddingRight: 24,
    minHeight: theme.appBarHeight,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.neutral.xxxLight,

    [generateMaxHeightBreakPoint('medium')]: { minHeight: theme.appBarHeightMedium },
  },
  toolbarInner: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '&>div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },

    '&.navigation': {
      paddingLeft: 10,
      paddingRight: 10,
    },

    [generateMaxWidthBreakPoint('small')]: {
      paddingLeft: 0,
      paddingRight: 0,
      borderTop: `1px solid ${theme.palette.grayscale.xLight}`,
      flexDirection: 'column',

      '&>div:first-child': {
        flexDirection: 'column',
      },

      '&>div:first-child > a, &>div:first-child > span': {
        '&, & > span': { width: '100%' },
      },
    },
  },
  navLink: {
    color: theme.palette.neutral.dark,
    /* active class applied by react router */
    display: 'inline-block',
    '&.active > span': {
      backgroundColor: theme.palette.tertiary.oceanBlue,
      color: theme.palette.neutral.xxxLight,
      fontWeight: 600,
    },
  },
  link: {
    display: 'inline-block',
    height: theme.appBarHeight,
    lineHeight: '20px',
    padding: `${(theme.appBarHeight - 20) / 2}px 8px`,
    fontSize: 16,
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
    [generateMaxHeightBreakPoint('medium')]: {
      height: theme.appBarHeightMedium,
      padding: `${(theme.appBarHeightMedium - 20) / 2}px 8px`,
    },
    [generateMinWidthBreakPoint('large')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  betaIcon: {
    // TODO: temporary until navbar is refactored to styled-components
    '&&': {
      transform: 'scale(0.8) translate(-4px, -1px)',
    },
  },
}))
