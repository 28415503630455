import React from 'react'

import styled from 'styled-components'

function BaseAssistantIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <rect x="0.9" y="0.9" width="14.2" height="14.2" rx="0.9" stroke="currentColor" strokeWidth="1.8" />
      <path
        d="M6.75705 9.60927V9.11921C6.75705 8.69536 6.84599 8.32671 7.02386 8.01325C7.20174
      7.69978 7.52712 7.36645 8 7.01325C8.45553 6.68212 8.75488 6.4128 8.89805 6.2053C9.04555
      5.99779 9.11931 5.766 9.11931 5.50993C9.11931 5.22296 9.01518 5.00442 8.80694 4.8543C8.5987
      4.70419 8.30803 4.62914 7.93492 4.62914C7.28416 4.62914 6.5423 4.84547 5.70933 5.27815L5
      3.82781C5.96746 3.27594 6.99349 3 8.07809 3C8.9718 3 9.68113 3.21854 10.2061 3.65563C10.7354
      4.09272 11 4.6755 11 5.40397C11 5.88962 10.8915 6.30905 10.6746 6.66225C10.4577 7.01545
      10.0456 7.4128 9.43818 7.8543C9.02169 8.16777 8.75705 8.40618 8.64425 8.56954C8.53579
      8.73289 8.48156 8.94702 8.48156 9.21192V9.60927H6.75705ZM6.54881 11.8742C6.54881 11.5033
      6.64642 11.223 6.84165 11.0331C7.03688 10.8433 7.32104 10.7483 7.69414 10.7483C8.05423
      10.7483 8.33189 10.8455 8.52712 11.0397C8.72668 11.234 8.82646 11.5121 8.82646
      11.8742C8.82646 12.223 8.72668 12.4989 8.52712 12.702C8.32755 12.9007 8.04989 13
      7.69414 13C7.32972 13 7.04772 12.9029 6.84816 12.7086C6.64859 12.5099 6.54881
      12.2318 6.54881 11.8742Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const AssistantIcon = styled(BaseAssistantIcon)``
