// assets
import './styles.css'

import React, { Component, Fragment } from 'react'
import { node } from 'prop-types'

import Bowser from 'bowser'
import { connect } from 'react-redux'

// components
import InfoDialog from './InfoDialog'
// utils
import { getWarningCookie, setWarningCookie } from './util'

const mobileAndTabletCheck = function () {
  const parser = Bowser.getParser(window.navigator.userAgent)
  const platformType = parser.getPlatformType()
  switch (platformType) {
    case 'desktop':
      return false
    case 'tablet':
    case 'mobile':
    default:
      return true
  }
}

class CheckingMobileBrowser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
    }
    // add cookie control to show it only once per device
    const warningShowed = getWarningCookie()
    if (warningShowed) {
      this.showDialog = false
    } else {
      this.showDialog = mobileAndTabletCheck()
      // set cookie
      if (this.showDialog) {
        setWarningCookie()
      }
    }
  }

  componentDidMount() {
    if (this.showDialog) {
      document.body.className = 'mobile'
    }
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    return (
      <>
        {this.props.children}
        {this.showDialog && <InfoDialog open={this.state.open} onClose={this.handleClose} />}
      </>
    )
  }
}

CheckingMobileBrowser.propTypes = {
  children: node.isRequired,
}

export default connect(state => ({ language: state.locale.language }))(CheckingMobileBrowser)
