import { getInitialDateFilters, setDateFilter } from '@helpers'

import {
  DATE_FILTER_KEYS,
  DEFAULT_EXPENSE_SEARCH_FILTERS,
  DEFAULT_INVOICE_SEARCH_FILTERS,
  FiltersStateKey,
  INITIAL_ORIGIN_FILTER,
  INITIAL_PAID_STATUS_FILTERS,
  INITIAL_PAYMENT_METHOD_FILTER,
  INITIAL_VAT_AREA_FILTER,
} from '@constants'

import authActions from '../auth/actions'
import dokumentsActions from '../dokuments/actions'
import actions from './actions'

function getDateFilterDisabled(storeValue: boolean, needClear: boolean, payload?: boolean): boolean {
  if (typeof payload === 'undefined') {
    return needClear ? false : storeValue
  }
  return payload !== storeValue ? payload : storeValue
}

const commonSearchFilters = {
  search: '',
  searchFields: [], // it sends to BE snake_case is fine here
}

const dokumentsInitialFilters: DokumentsStoreFilters = {
  containsPayable: undefined,
  dateField: DATE_FILTER_KEYS.CREATED_AT,
  isAiRecognitionAllowed: undefined,
  isNew: undefined,
  isUpdated: undefined,
  withTagIds: [],
  withDocumentTypeIds: [],
  ...commonSearchFilters,
}

const paidThroughInitialFilters: PaidThroughsStoreFilters = {
  ...commonSearchFilters,
}

const paymentOrdersInitialFilters: PaymentOrdersStoreFilters = {
  dateField: DATE_FILTER_KEYS.VALUE_DATE,
  paidThroughs: [],
  ...commonSearchFilters,
}

export const BANK_TRANSACTIONS_INITIAL_FILTERS: BankTransactionsStoreFilters = {
  currencyId: undefined,
  dateField: DATE_FILTER_KEYS.VALUE_DATE,
  grossAmountMax: undefined,
  grossAmountMin: undefined,
  paidThroughs: [],
  ...commonSearchFilters,
}

const PAYMENT_TRANSACTIONS_INITIAL_FILTERS: PaymentTransactionsStoreFilters = {
  dateField: DATE_FILTER_KEYS.PAYMENT_DATE,
  paidThroughs: [],
  transactionTypes: [],
  ...commonSearchFilters,
}

export const EXPENSE_LIST_INITIAL_FILTERS: ExpenseListStoreFilters = {
  currencyId: undefined,
  dateField: DATE_FILTER_KEYS.FULFILLED_AT,
  grossAmountMax: undefined,
  grossAmountMin: undefined,
  hasAccountingTodo: undefined,
  hasArtifact: undefined,
  hasAttachment: undefined,
  hasExpenseType: undefined,
  hasPostIt: undefined,
  hasUnfinishedAccountingTodo: undefined,
  isAccountingExported: undefined,
  isAccountingTodoDone: undefined,
  isApproved: undefined,
  isDuplicate: undefined,
  isExcludedFromAccounting: undefined,
  isFiled: undefined,
  isNew: undefined,
  isProcessed: undefined,
  isReadyToSync: undefined,
  isReadyToAcountoSync: undefined,
  isUpdated: undefined,
  origin: [...INITIAL_ORIGIN_FILTER],
  paymentMethod: [...INITIAL_PAYMENT_METHOD_FILTER],
  vatArea: [...INITIAL_VAT_AREA_FILTER],
  withExpenseTypeIds: [],
  withoutExpenseTypeIds: [],
  withoutTagIds: [],
  withTagIds: [],
  ...INITIAL_PAID_STATUS_FILTERS,
  ...commonSearchFilters,
  searchFields: DEFAULT_EXPENSE_SEARCH_FILTERS,
}

export const QUARANTINE_LIST_INITIAL_FILTERS: QuarantineListStoreFilters = {
  currencyId: undefined,
  dateField: DATE_FILTER_KEYS.FULFILLED_AT,
  grossAmountMax: undefined,
  grossAmountMin: undefined,
  hasArtifact: undefined,
  isNew: undefined,
  isMissing: undefined,
  isUpdated: undefined,
  origin: [...INITIAL_ORIGIN_FILTER],
  withExpenseTypeIds: [],
  withoutExpenseTypeIds: [],
  withoutTagIds: [],
  withTagIds: [],
  ...commonSearchFilters,
  searchFields: DEFAULT_INVOICE_SEARCH_FILTERS,
  isDateFilterDisabled: true,
}

export const INCOME_LIST_INITIAL_FILTERS: IncomeListStoreFilters = {
  dateField: DATE_FILTER_KEYS.FULFILLED_AT,
  hasArtifact: undefined,
  hasAttachment: undefined,
  hasRevenueType: undefined,
  hasPostIt: undefined,
  isNew: undefined,
  isReadyToSync: undefined,
  isUpdated: undefined,
  withTagIds: [],
  withoutTagIds: [],
  withRevenueTypeIds: [],
  withoutRevenueTypeIds: [],
  currencyId: undefined,
  grossAmountMax: undefined,
  grossAmountMin: undefined,
  paymentMethod: [...INITIAL_PAYMENT_METHOD_FILTER],
  origin: [...INITIAL_ORIGIN_FILTER],
  ...INITIAL_PAID_STATUS_FILTERS,
  ...commonSearchFilters,
  searchFields: DEFAULT_INVOICE_SEARCH_FILTERS,
}

export const TAX_LIST_INITIAL_FILTERS: TaxListStoreFilters = {
  dateField: DATE_FILTER_KEYS.CURRENT_MONTH,
  grossAmountMax: undefined,
  grossAmountMin: undefined,
  isNew: undefined,
  withTagIds: [],
  withoutTagIds: [],
  ...INITIAL_PAID_STATUS_FILTERS,
  ...commonSearchFilters,
}

export const SALARY_LIST_INITIAL_FILTERS: SalaryListStoreFilters = {
  dateField: DATE_FILTER_KEYS.CURRENT_MONTH,
  grossAmountMax: undefined,
  grossAmountMin: undefined,
  isNew: undefined,
  withoutTagIds: [],
  withTagIds: [],
  ...INITIAL_PAID_STATUS_FILTERS,
  ...commonSearchFilters,
}

export const MONTHLY_STATEMENT_INITIAL_FILTERS: MonthlyStatementStoreFilters = {
  dateField: DATE_FILTER_KEYS.FULFILLED_AT,
  withTagIds: [],
  withoutTagIds: [],
  ...commonSearchFilters,
}

export const PARTNER_LIST_INITIAL_FILTERS: PartnerListStoreFilters = {
  dateField: DATE_FILTER_KEYS.FULFILLED_AT,
  type: 'provider',
  ...commonSearchFilters,
}

export const initialState: FiltersStore = {
  fromDate: null,
  toDate: null,

  //* toggle date filter
  isGlobalDateFilterDisabled: false,

  [FiltersStateKey.BANK_TRANSACTIONS]: BANK_TRANSACTIONS_INITIAL_FILTERS,
  [FiltersStateKey.DOKUMENT_LIST]: dokumentsInitialFilters,
  [FiltersStateKey.EXPENSE_LIST]: EXPENSE_LIST_INITIAL_FILTERS,
  [FiltersStateKey.INCOME_LIST]: INCOME_LIST_INITIAL_FILTERS,
  [FiltersStateKey.MONTHLY_STATEMENT]: MONTHLY_STATEMENT_INITIAL_FILTERS,
  [FiltersStateKey.PAID_THROUGHS]: paidThroughInitialFilters,
  [FiltersStateKey.PARTNER_LIST]: PARTNER_LIST_INITIAL_FILTERS,
  [FiltersStateKey.PAYMENT_ORDERS]: paymentOrdersInitialFilters,
  [FiltersStateKey.PAYMENT_TRANSACTIONS]: PAYMENT_TRANSACTIONS_INITIAL_FILTERS,
  [FiltersStateKey.QUARANTINE_LIST]: QUARANTINE_LIST_INITIAL_FILTERS,
  [FiltersStateKey.SALARY_LIST]: SALARY_LIST_INITIAL_FILTERS,
  [FiltersStateKey.TAX_LIST]: TAX_LIST_INITIAL_FILTERS,
}

// TODO: action type
function reducer(state: FiltersStore = initialState, action: any) {
  switch (action.type) {
    case authActions.selectCompany.SUCCESS: {
      const dateFilters = getInitialDateFilters()

      return {
        ...initialState,
        ...dateFilters,
      }
    }

    //* ##########
    //* DOKUMENTS
    //* ##########

    case actions.initDokumentListFiltersFromUrl.REQUEST:
    case actions.updateDokumentFilters.REQUEST: {
      // there is no filters dialog on DokumentsListPage so payload can not contain "isDateFilterDisabled" flag
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.DOKUMENT_LIST]: {
          ...state[FiltersStateKey.DOKUMENT_LIST],
          ...rest,
        },
      }
    }

    case actions.resetDokumentFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.DOKUMENT_LIST]: {
          ...dokumentsInitialFilters,
        },
      }
    }

    // after successful AI recognition turn off AI recognition filter
    case dokumentsActions.bulkAiRecognition.SUCCESS:
      return {
        ...state,
        [FiltersStateKey.DOKUMENT_LIST]: {
          ...state[FiltersStateKey.DOKUMENT_LIST],
          isAiRecognitionAllowed: false,
        },
      }

    //* #############
    //* PAID_THROUGH
    //* #############

    case actions.initPaidThroughListFiltersFromUrl.REQUEST:
    case actions.updatePaidThroughFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.PAID_THROUGHS]: {
          ...state.paidThroughs,
          ...action.payload.filters,
        },
      }
    }

    case actions.resetPaidThroughFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.PAID_THROUGHS]: {
          ...paidThroughInitialFilters,
        },
      }
    }

    //* ###############
    //* PAYMENT_ORDERS
    //* ###############

    case actions.initPaymentOrderListFiltersFromUrl.REQUEST:
    case actions.updatePaymentOrderFilters.REQUEST: {
      // there is no filters dialog on DokumentsListPage so payload can not contain "isDateFilterDisabled" flag
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.PAYMENT_ORDERS]: {
          ...state.paymentOrders,
          ...rest,
        },
      }
    }

    case actions.resetPaymentOrderFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.PAYMENT_ORDERS]: {
          ...paymentOrdersInitialFilters,
        },
      }
    }

    //* #####################
    //* PAYMENT TRANSACTIONS
    //* #####################

    case actions.initPaymentTransactionListFiltersFromUrl.REQUEST:
    case actions.updatePaymentTransactionsFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.PAYMENT_TRANSACTIONS]: {
          ...state.paymentTransactions,
          ...rest,
        },
      }
    }

    case actions.resetPaymentTransactionsFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.PAYMENT_TRANSACTIONS]: {
          ...PAYMENT_TRANSACTIONS_INITIAL_FILTERS,
        },
      }
    }

    //* ##################
    //* BANK TRANSACTIONS
    //* ##################

    case actions.initBankTransactionListFiltersFromUrl.REQUEST:
    case actions.updateBankTransactionsFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.BANK_TRANSACTIONS]: {
          ...state.bankTransactions,
          ...rest,
        },
      }
    }

    case actions.resetBankTransactionsFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.BANK_TRANSACTIONS]: {
          ...BANK_TRANSACTIONS_INITIAL_FILTERS,
        },
      }
    }

    //* ################
    //* EXPENSE LIST V2
    //* ################

    case actions.initExpenseListFiltersFromUrl.REQUEST:
    case actions.updateExpenseListFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.EXPENSE_LIST]: {
          ...state[FiltersStateKey.EXPENSE_LIST],
          ...rest,
        },
      }
    }

    case actions.syncExpenseSearchFilterInput.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.EXPENSE_LIST]: {
          ...state[FiltersStateKey.EXPENSE_LIST],
          search: action.payload,
        },
      }
    }

    case actions.resetExpenseListFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.EXPENSE_LIST]: {
          ...EXPENSE_LIST_INITIAL_FILTERS,
        },
      }
    }

    //* ###################
    //* QUARANTINE LIST V2
    //* ###################

    case actions.initQuarantineListFiltersFromUrl.REQUEST:
    case actions.updateQuarantineListFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        [FiltersStateKey.QUARANTINE_LIST]: {
          ...state[FiltersStateKey.QUARANTINE_LIST],
          ...rest,
          isDateFilterDisabled: getDateFilterDisabled(
            state[FiltersStateKey.QUARANTINE_LIST].isDateFilterDisabled,
            fromDate || toDate,
            isDateFilterDisabled
          ),
        },
      }
    }

    case actions.syncQuarantineSearchFilterInput.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.QUARANTINE_LIST]: {
          ...state[FiltersStateKey.QUARANTINE_LIST],
          search: action.payload,
        },
      }
    }

    case actions.resetQuarantineListFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.QUARANTINE_LIST]: {
          ...QUARANTINE_LIST_INITIAL_FILTERS,
        },
      }
    }

    case actions.toggleQuarantineListDateFilter.REQUEST:
      return {
        ...state,
        [FiltersStateKey.QUARANTINE_LIST]: {
          ...QUARANTINE_LIST_INITIAL_FILTERS,
          isDateFilterDisabled: !state[FiltersStateKey.QUARANTINE_LIST].isDateFilterDisabled,
        },
      }

    //* ###############
    //* INCOME LIST V2
    //* ###############

    case actions.initIncomeListFiltersFromUrl.REQUEST:
    case actions.updateIncomeListFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.INCOME_LIST]: {
          ...state.incomeList,
          ...rest,
        },
      }
    }

    case actions.resetIncomeListFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.INCOME_LIST]: {
          ...INCOME_LIST_INITIAL_FILTERS,
        },
      }
    }

    //* ###########
    //* TAX LIST V2
    //* ###########

    case actions.initTaxListFiltersFromUrl.REQUEST:
    case actions.updateTaxListFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.TAX_LIST]: {
          ...state.taxList,
          ...rest,
        },
      }
    }

    case actions.resetTaxListFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.TAX_LIST]: {
          ...TAX_LIST_INITIAL_FILTERS,
        },
      }
    }

    //* ###############
    //* SALARY LIST V2
    //* ###############

    case actions.initSalaryListFiltersFromUrl.REQUEST:
    case actions.updateSalaryListFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.SALARY_LIST]: {
          ...state[FiltersStateKey.SALARY_LIST],
          ...rest,
        },
      }
    }

    case actions.resetSalaryListFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.SALARY_LIST]: {
          ...SALARY_LIST_INITIAL_FILTERS,
        },
      }
    }

    //* ##################
    //* MONTHLY STATEMENT
    //* ##################

    case actions.initMonthlyStatementFiltersFromUrl.REQUEST:
    case actions.updateMonthlyStatementFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.MONTHLY_STATEMENT]: {
          ...state.monthlyStatement,
          ...rest,
        },
      }
    }

    case actions.resetMonthlyStatementFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.MONTHLY_STATEMENT]: {
          ...MONTHLY_STATEMENT_INITIAL_FILTERS,
        },
      }
    }

    //* #############
    //* PARTNER LIST
    //* #############
    case actions.initPartnerListFiltersFromUrl.REQUEST:
    case actions.updatePartnerListFilters.REQUEST: {
      const { fromDate, toDate, isDateFilterDisabled, ...rest } = action.payload.filters

      // update date filters fromDate / toDate when they changed
      if (fromDate && fromDate !== state.fromDate) {
        setDateFilter('fromDate', fromDate)
      }
      if (toDate && toDate !== state.toDate) {
        setDateFilter('toDate', toDate)
      }

      return {
        ...state,
        fromDate: fromDate || state.fromDate,
        toDate: toDate || state.toDate,
        isGlobalDateFilterDisabled: getDateFilterDisabled(
          state.isGlobalDateFilterDisabled,
          fromDate || toDate,
          isDateFilterDisabled
        ),
        [FiltersStateKey.PARTNER_LIST]: {
          ...state[FiltersStateKey.PARTNER_LIST],
          ...rest,
        },
      }
    }

    case actions.resetPartnerListFilters.REQUEST: {
      return {
        ...state,
        [FiltersStateKey.PARTNER_LIST]: {
          ...PARTNER_LIST_INITIAL_FILTERS,
        },
      }
    }

    //* TOGGLE DATE FILTER
    case actions.toggleBankTransactionsDateFilter.REQUEST:
    case actions.toggleDokumentDateFilter.REQUEST:
    case actions.toggleExpenseListDateFilter.REQUEST:
    case actions.toggleIncomeListDateFilter.REQUEST:
    case actions.togglePaymentOrderDateFilter.REQUEST:
    case actions.togglePaymentTransactionsDateFilter.REQUEST:
    case actions.toggleSalaryListDateFilter.REQUEST:
    case actions.toggleTaxListDateFilter.REQUEST:
      return {
        ...state,
        isGlobalDateFilterDisabled: !state.isGlobalDateFilterDisabled,
      }

    default:
      return state
  }
}

export default reducer
