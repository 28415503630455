import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, List, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { PreviewProvider } from '@contexts/index'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { DeleteConfirmDialog } from '@oldComponents/dialogs'

import { AttachmentDocumentType, BackendAttachmentFileData, RemoveSuccessHandlerFunction } from '../types'
import { Attachment } from './Attachment'

import { DataLoadingMessage } from '@messages'

const style = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 15,
}

interface AttachmentsListProps {
  data: BackendAttachmentFileData[]
  documentType: AttachmentDocumentType
  error: BackendError
  loading: boolean
  onDelete: AsyncFunction<BackendAttachmentFileData>
  onDeleteSuccess: RemoveSuccessHandlerFunction
  onDownload: AsyncFunction<BackendAttachmentFileData>
}

export function AttachmentsList({
  data,
  documentType,
  error,
  loading,
  onDelete,
  onDeleteSuccess,
  onDownload,
}: AttachmentsListProps) {
  const [removableFile, setRemovableFile] = React.useState<Nullable<BackendAttachmentFileData>>(null)
  const [removeFileLoading, setRemoveFileLoading] = React.useState(false)
  const [removeFileError, setRemoveFileError] = React.useState<BackendError>(null)
  const cPromiseRef = useCancellablePromiseRef()

  const handleDelete = React.useCallback<AsyncFunction>(async () => {
    if (removableFile) {
      setRemoveFileLoading(true)
      try {
        cPromiseRef.current = cancellablePromise(onDelete(removableFile))
        await cPromiseRef.current.promise
        onDeleteSuccess(removableFile)
        setRemovableFile(null)
        setRemoveFileLoading(false)
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setRemoveFileError(errorMessage)
          setRemoveFileLoading(false)
        }
      }
    }
  }, [cPromiseRef, onDelete, onDeleteSuccess, removableFile])

  React.useEffect(() => {
    let timer: number | undefined = undefined
    if (removeFileError) {
      timer = window.setTimeout(setRemoveFileError, 2000, null)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [removeFileError])

  function handleClose() {
    setRemovableFile(null)
  }

  function onDownloadHandler(fileData: BackendAttachmentFileData) {
    return function handler() {
      return onDownload(fileData)
    }
  }

  function onDeleteHandler(fileData: BackendAttachmentFileData) {
    return function handler() {
      setRemovableFile(fileData)
    }
  }

  if (loading) {
    return (
      <div style={style}>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'inherit',
            marginRight: 10,
          }}
        >
          <CircularProgress color="primary" size={20} />
        </div>
        <Typography variant="body1" style={{ fontSize: 14 }}>
          {DataLoadingMessage}
        </Typography>
      </div>
    )
  }
  if (error) {
    return (
      <div style={style}>
        <Typography color="error">{error}</Typography>
      </div>
    )
  }
  if (!data.length) {
    return (
      <div style={style}>
        {documentType === 'partner' ? (
          <FormattedMessage
            id="attachments.partner.noResult"
            defaultMessage="Még egyetlen csatolt fájl sem tartozik a partnerhez"
          />
        ) : (
          <FormattedMessage
            id="attachments.noResult"
            defaultMessage="Még egyetlen csatolt fájl sem tartozik a számlához"
          />
        )}
      </div>
    )
  }
  return (
    <React.Fragment>
      <PreviewProvider>
        <List data-testid="attachment-list">
          {data.map((fileData, index) => (
            <Attachment
              data={fileData}
              divider={index < data.length - 1}
              key={fileData.id}
              onDelete={onDeleteHandler(fileData)}
              onDownload={onDownloadHandler(fileData)}
            />
          ))}
        </List>
      </PreviewProvider>

      <DeleteConfirmDialog
        data-testid="remove-file-dialog-title"
        disableRestoreFocus
        error={removeFileError}
        id="remove-file-dialog-title"
        loading={removeFileLoading}
        onClose={handleClose}
        onDelete={handleDelete}
        open={Boolean(removableFile)}
      >
        <FormattedMessage
          id="attachments.dialog.removeText"
          defaultMessage='Biztosan eltávolítja a "{fileName}" csatolt dokumentumot?'
          values={{
            fileName: removableFile && removableFile.title,
          }}
        />
      </DeleteConfirmDialog>
    </React.Fragment>
  )
}

AttachmentsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
  documentType: PropTypes.oneOf(['expense', 'income', 'partner']).isRequired,
  error: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
}
