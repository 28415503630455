import React from 'react'

import styled from 'styled-components'

import { useParentSize } from '@hooks'

interface ParentSizeProps {
  children: (rect: { width: number; height: number }) => JSX.Element
}

const ParentSizeWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export function ParentSize({ children }: ParentSizeProps) {
  const [ref, rect] = useParentSize()

  return <ParentSizeWrapper ref={ref}>{children(rect)}</ParentSizeWrapper>
}
