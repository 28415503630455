import styled from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { ButtonBase } from './ButtonBase'

export const OldIconButton = styled(ButtonBase)`
  border: 0;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 250ms ease;
  width: 28px;
  height: 28px;
  padding: 5px;

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${({ theme }) => applyOpacity(theme.colors.blue[40], 6)};
    box-shadow: unset;
  }
`
