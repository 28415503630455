import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { useEditor } from '@hooks'

import { PaidThroughDialogVariant, PaidThroughEditorDialog } from '@components/dialogs'
import { ReactHookFormSelectField } from '@components/ui'
import { ReactHookFormSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSelectField'

import { PaidThroughType } from '@constants'

import messages from '../messages'

function PurePaidThroughField({
  disabled,
  isLabelHighlighted,
  options,
}: Pick<ReactHookFormSelectFieldProps<CommonIdAndNameType>, 'disabled' | 'options' | 'isLabelHighlighted'>) {
  const { formatMessage } = useIntl()
  const { editorState, openEditorForCreateWithData, closeEditor } = useEditor<number, { name: string }>()
  const { setValue } = useFormContext()

  React.useEffect(() => {
    // when field is not visible clear value
    return () => {
      setValue('paid_through', null)
    }
  }, [setValue])

  const handleCreate = React.useCallback(
    (newOption: CommonIdAndNameType) => {
      openEditorForCreateWithData({ name: newOption.name })
    },
    [openEditorForCreateWithData]
  )

  const handleSuccess = React.useCallback(
    (response: CommonIdAndNameType) => {
      setValue('paid_through', response.id, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    },
    [setValue]
  )

  return (
    <>
      <ReactHookFormSelectField
        disabled={disabled}
        isClearable={false}
        isLabelHighlighted={isLabelHighlighted}
        label={formatMessage(messages.paidThroughLabel)}
        name="paid_through"
        onCreate={handleCreate}
        options={options}
        required
      />
      <PaidThroughEditorDialog
        editor={editorState}
        onClose={closeEditor}
        onSubmitSuccess={handleSuccess}
        variant={PaidThroughDialogVariant.SELECT_OPTION}
      />
    </>
  )
}

export const PaidThroughField = connect((state: Store) => ({
  options: state.payment.paidThroughOptions.filter(
    ({ paidthrough_type }) => paidthrough_type === PaidThroughType.BANK_ACCOUNT
  ),
}))(PurePaidThroughField)

PaidThroughField.displayName = 'PaidThroughField'
