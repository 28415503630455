import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { CommonAxiosPayload } from '@services'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch, useSuccessDialog } from '@contexts'

import { useCancellablePromiseRef } from '@hooks'

import { AsyncButton } from '@components/ui'

import { AcceptButtonMessage } from '@messages'
import { TaxationTaskDoneTitleMessage } from '../messages'

interface CompleteTaskButtonProps<Payload> {
  callUrl: AsyncFunction<CommonAxiosPayload, unknown>
  refetchReports?: VoidFunction
  successCallback?: (payload: Partial<Payload>) => void
  url: BackendCallableLink
  buttonText?: StringOrMessage
}

export function CompleteTaskButton<Payload>({
  callUrl,
  refetchReports,
  successCallback,
  url,
  buttonText,
}: CompleteTaskButtonProps<Payload>) {
  const [loading, setLoading] = React.useState(false)
  const { setErrorAlert } = useAlertDispatch()
  const setSuccessMessage = useSuccessDialog()
  const cPromiseRef = useCancellablePromiseRef()

  async function handleClick() {
    if (url) {
      setLoading(true)
      try {
        cPromiseRef.current = cancellablePromise(callUrl({ url, method: 'post' }))
        const response = await cPromiseRef.current.promise
        if (successCallback) {
          setLoading(false)
          successCallback(response)
        } else {
          // success message
          setSuccessMessage({
            title: TaxationTaskDoneTitleMessage,
            message: (
              <FormattedMessage
                id="taxation.dialogs.acceptSuccess.message"
                defaultMessage="Sikeresen előírtad a fizetendő adót, amik bekerültek a Tranzakciók menübe."
              />
            ),
            withConfetti: true,
          })
          refetchReports?.()
        }
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          setErrorAlert(errorMsg)
          setLoading(false)
        }
      }
    }
  }

  return (
    <AsyncButton disabled={!url} loading={loading} onClick={handleClick} variant="primaryContained">
      {buttonText ?? AcceptButtonMessage}
    </AsyncButton>
  )
}

CompleteTaskButton.propTypes = {
  callUrl: PropTypes.func.isRequired,
  refetchReports: PropTypes.func,
  successCallback: PropTypes.func,
  url: PropTypes.string,
}
