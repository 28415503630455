import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { getDefaultRedirectUrl } from '@helpers'

function PureNavigateToDefaultRoute({ url }: { url: string }) {
  return <Navigate to={url} replace />
}

PureNavigateToDefaultRoute.propTypes = {
  url: PropTypes.string.isRequired,
}

export const NavigateToDefaultRoute = connect((state: Store) => ({
  url: getDefaultRedirectUrl(state.auth.companies),
}))(PureNavigateToDefaultRoute)

NavigateToDefaultRoute.displayName = 'NavigateToDefaultRoute'
