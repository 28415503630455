import React from 'react'
import { arrayOf, bool, func, number, object, oneOf, oneOfType, shape, string } from 'prop-types'

import { Button, CircularProgress, DialogContent, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import __find from 'lodash/find'
import __get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { bulkIncomeUpdate as bulkIncomeUpdateAPI, bulkInvoiceUpdate as bulkInvoiceUpdateAPI } from '@services'

import { cancellablePromise } from '@helpers'

import { Dialog, DialogActions, DialogTitle } from '@oldComponents/dialogs'

class InvoiceBulkUpdateConfirmDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showErrorMsg: false,
    }
  }

  componentWillUnmount() {
    if (this.cPromise) {
      this.cPromise.cancel()
    }
  }

  handleConfirm = event => {
    event.preventDefault()
    const { callBulkInvoiceUpdate, data, onClose } = this.props
    this.setState({ loading: true, showErrorMsg: false })
    this.cPromise = cancellablePromise(callBulkInvoiceUpdate(data))

    this.cPromise.promise.then(onClose).catch(err => {
      if (err && !Object.prototype.hasOwnProperty.call(err, 'isCanceled')) {
        this.setState({ loading: false, showErrorMsg: true })
      }
    })
  }

  resetState = () => {
    this.setState({
      loading: false,
      showErrorMsg: false,
    })
  }

  handleDialogClose = (event, reason) => {
    const { onClose } = this.props
    const { loading } = this.state

    if (reason === 'backdropClick') {
      if (!loading) {
        onClose()
      }
    }
  }

  render() {
    const { classes, data, onClose, open, paymentMethodName, variant } = this.props
    const { showErrorMsg, loading } = this.state
    const count = __get(data, 'count', 0)
    const partnerName = __get(data, 'partner_name', '')

    return (
      <Dialog
        open={open}
        onClose={this.handleDialogClose}
        aria-labelledby="invoice-submit-confirm-dialog-title"
        disableEscapeKeyDown={loading}
        TransitionProps={{
          onExited: this.resetState,
        }}
      >
        <DialogTitle id="invoice-submit-confirm-dialog-title">
          <FormattedMessage id="dialogs.invoiceBulkUpdate.title" defaultMessage="Tömeges számlamódosítás" />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormattedMessage
            id="dialogs.invoiceBulkUpdate.description"
            defaultMessage="{partner} partneredtől van még {count} hiányos számla {payment_method} fizetési móddal. Az alábbi adatokat át tudjuk vezetni ezekre a számlákra is, hogy ne legyenek hiányosak:" // eslint-disable-line max-len
            values={{
              partner: <b>{partnerName}</b>,
              count: (
                <b>
                  {count} <FormattedMessage id="dialogs.invoiceBulkUpdate.pieces" defaultMessage="db" />
                </b>
              ),
              payment_method: <u>{paymentMethodName}</u>,
            }}
          />

          <div className={classes.listBox}>
            <ul>
              <li>
                <FormattedMessage id="dialogs.invoiceBulkUpdate.fields.vatArea" defaultMessage="területi hatály" />
              </li>
              {variant === 'expense' && (
                <li>
                  <FormattedMessage id="dialogs.invoiceBulkUpdate.fields.paidThrough" defaultMessage="pénztárca" />
                </li>
              )}
              <li>
                <FormattedMessage id="dialogs.invoiceBulkUpdate.fields.tags" defaultMessage="címkék" />
              </li>
              <li>
                {variant === 'expense' && (
                  <FormattedMessage
                    id="dialogs.invoiceBulkUpdate.fields.expenseType"
                    defaultMessage="költségtípus neve"
                  />
                )}
                {variant === 'revenue' && (
                  <FormattedMessage
                    id="dialogs.invoiceBulkUpdate.fields.revenueType"
                    defaultMessage="bevételtípus neve"
                  />
                )}
              </li>
            </ul>
          </div>
          {variant === 'expense' && (
            <div className={classes.warningBox}>
              <div className={classes.warningBoxTitle}>
                <FormattedMessage id="heading.attention" defaultMessage="Figyelem!" />
              </div>
              <div className={classes.warningBoxText}>
                <FormattedMessage
                  id="dialogs.invoiceBulkUpdate.warning"
                  defaultMessage="Ha egy számla már nem hiányos, eltűnhet a táblázatból, amennyiben a teljesítési időszak szűrésbe nem esik bele." // eslint-disable-line max-len
                />
              </div>
            </div>
          )}

          {showErrorMsg && (
            <Typography color="error" variant="body2">
              <FormattedMessage
                id="dialogs.invoiceBulkUpdate.errorMessage"
                defaultMessage="Hiba történt. Nem tudtuk átvezetni az adatokat. Próbálkozz később!"
              />
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {loading ? (
            <div className={classes.progressBar}>
              <CircularProgress color="primary" size={18} style={{ marginRight: 5 }} />
              <FormattedMessage
                id="dialogs.invoiceBulkUpdate.progressText"
                defaultMessage="Feldolgozás folyamatban..."
              />
            </div>
          ) : (
            <React.Fragment>
              <Button onClick={onClose} color="default" variant="contained">
                <FormattedMessage id="dialogs.invoiceBulkUpdate.buttons.cancel" defaultMessage="Ne vezessük át" />
              </Button>
              <Button onClick={this.handleConfirm} color="primary" variant="contained">
                <FormattedMessage id="dialogs.invoiceBulkUpdate.buttons.confirm" defaultMessage="Igen, vezessük át" />
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

InvoiceBulkUpdateConfirmDialog.propTypes = {
  classes: object.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  paymentMethodName: string,
  data: oneOfType([
    shape({
      count: number,
      company_id: number.isRequired,
      id: number.isRequired,
      partner_id: number.isRequired,
      partner_name: string.isRequired,
      payment_method: string.isRequired,
      vat_area: string.isRequired,
      paid_through: number,
      tags: arrayOf(number).isRequired,
      assignment_tags: arrayOf(number).isRequired,
      expense_type: number,
    }),
    shape({
      company_id: number.isRequired,
      count: number,
      id: number.isRequired,
      income_assignment_tags: arrayOf(number).isRequired,
      // paid_through: number, // not required as of yet on incomes
      partner_id: number.isRequired,
      partner_name: string.isRequired,
      payment_method: string.isRequired,
      revenue_type: number,
      tags: arrayOf(number).isRequired,
      vat_area: string.isRequired,
    }),
  ]),
  callBulkInvoiceUpdate: func.isRequired,
  variant: oneOf(['expense', 'revenue']).isRequired,
}

function getPaymentMethodName(state, data) {
  if (!data) {
    return ''
  }
  return __get(
    __find(state.dashboard.common.payment_methods, {
      value: data.payment_method,
    }),
    'label',
    ''
  )
}

export default withStyles(theme => ({
  dialogContent: {
    paddingLeft: 65,
    paddingRight: 65,
    textAlign: 'center',
  },
  listBox: {
    margin: '20px 0 40px',
    padding: 20,
    textAlign: 'center',
    backgroundColor: theme.palette.grayscale.xxxLight,
    borderRadius: 4,
    '& > ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
      display: 'inline-block',
      textAlign: 'left',
    },
  },
  warningBox: {
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 14,
  },
  warningBoxTitle: {
    fontWeight: 700,
    marginBottom: '0.5em',
  },
  warningBoxText: { fontWeight: 300 },
  progressBar: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
  },
}))(
  connect(
    (state, ownProps) => ({
      paymentMethodName: getPaymentMethodName(state, ownProps.data),
    }),
    (dispatch, ownProps) => ({
      callBulkInvoiceUpdate: ownProps.variant === 'revenue' ? bulkIncomeUpdateAPI : bulkInvoiceUpdateAPI,
    })
  )(InvoiceBulkUpdateConfirmDialog)
)
