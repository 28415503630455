export const SPACE_FINDER_REGEX = /\s/g

export const FILENAME_PREFIX_REGEX = /filename="?([^"]+)"?/

export const FOUR_DIGIT_REGEX = /^\d{4}$/

export const HEX_COLOR_REGEX = /^#([a-fA-F0-9]{3}|[a-fA-F0-9]{6})/

/**
 * Regular expression to validate strings that start with 2 to 7 digits (the ledger number's code),
 * optionally followed by a space and non-numeric characters (an optional name for the ledger number).
 * Used in the handleInputChange function within the PureLedgerNumberSelect component to validate and modify user's input.
 */
export const LEDGER_NUMBER_WITH_OPTIONAL_NAME_REGEX = /^[0-9]{2,7}(( [^ 0-9].*)| ?)$/

export const NINE_DIGIT_REGEX = /^\d{9}$/

export const OPTIONAL_DIGITS_REGEX = /^\d*$/

export const REQUIRED_DIGITS_REGEX = /^\d+$/

export const CITIBANK_ACCOUNT_NUMBER_REGEX = /^\d{1,10}$/

export const TAJ_NUMBER_REGEX = /^(.{3})(.{3})(.{3})$/

export const TAX_NUMBER_REGEX = /^8\d{9}$/

export const FORMATTER_CLEAN_TEXT_REGEX = /[^\da-zA-Z\n|]/g

export const FORMATTER_PATTERN_SPLIT_REGEX = /[\da-zA-Z\n|]/g

export const FORMATTER_HU_TAX_NUMBER_REGEX = /^[0-9]{9,11}$/ // Hungary: [8 digit]-[1 digit]-[2 digit]

export const FORMATTER_GIRO_BANK_ACCOUNT_NUMBER_REGEX = /^(\d{16}|\d{24})$/ // GIRO - 12345678-12345678(-12345678) (Hungary: 2x8 or 3x8 digit)

export const FORMATTER_HU_IBAN_BANK_ACCOUNT_NUMBER_REGEX = /^[A-Z]{2}\d{26}$/ // IBAN - HU36 1231 1232 1233 1232 0000 0000 (Hungary)

export const FORMATTER_IBAN_BANK_ACCOUNT_NUMBER_REGEX = /^[A-Z]{2}\d{2}[\dA-Z]{11,}$/ // IBAN shortest 15, longest 33

export const BANK_ACCOUNT_NUMBER_REGEX = /^(\d{16}|\d{24}|[A-Z]{2}\d{26}|[A-Z]{2}\d{2}[\dA-Z]{11,})$/ // for validation

/**
 * Regular expression to validate strings that start with a 4-digit number (representing a zip code),
 * followed by optional sequences of characters (representing a city and an address) that are separated by a comma or a space.
 * Used in the serializeCegjelzoAddress function within the PartnerEditorForm component to parse and update the address fields.
 */
export const ZIP_CITY_ADDRESS_REGEX = /^(\d{4})(?:(?:,\s|\s)(.+?))?(?:(?:,\s|\s)(.+))?$/

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/
