import React from 'react'

import { usePortal } from '@contexts'

import { Portal } from '@components/ui/Portal'

interface PreviewControlsProps {
  children: React.ReactNode
}

export function PreviewControls({ children }: PreviewControlsProps) {
  const { portalAnchorEl } = usePortal()
  return (
    <Portal anchorEl={portalAnchorEl}>
      <>{children}</>
    </Portal>
  )
}
