import React from 'react'

import __partition from 'lodash/partition'
import __uniqueId from 'lodash/uniqueId'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { components as reactSelectComponents, GroupBase, OptionProps, SelectComponentsConfig } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { dashboardActions } from '@services'

import { bindActionToPromise, recommendationMessages } from '@helpers'
import { getValueComponents } from '@oldComponents/forms/DetailsForm/elements/LedgerNumberSelects/helpers'

import { useAlertDispatch } from '@contexts'

import { usePromptTextCreator } from '@hooks'

import { Typography } from '@components/ui'

import { LEDGER_NUMBER_WITH_OPTIONAL_NAME_REGEX, OPTIONAL_DIGITS_REGEX } from '@constants'

import { createOptions, createSingleOption, getNewOptionData, isValidNewOption } from './helpers'
import { SelectOption } from './types'

import { selectFormStyles as customReactSelectStyles } from '@styles/select'
import { formSelectMessages } from '@messages'

// need to customize the recommended option
const CustomSelectOption = reactSelectComponents.Option

function CustomLedgerNumberOption({ children, ...rest }: OptionProps<SelectOption, false, GroupBase<SelectOption>>) {
  const { name, isNew } = rest.data.data
  return (
    <CustomSelectOption {...rest}>
      <div className="custom-option">
        <Typography size="400-sm">{children}</Typography>
        {!isNew && name && <Typography size="400-xs">{name}</Typography>}
      </div>
    </CustomSelectOption>
  )
}

const componentOverridesProp: {
  components: SelectComponentsConfig<SelectOption, false, GroupBase<SelectOption>>
} = {
  components: {
    Option: CustomLedgerNumberOption,
  },
}

interface LedgerNumberCreatableSelectProps {
  className?: string
  createNewOption: AsyncFunction<Omit<LedgerNumber, 'id'>, LedgerNumber>
  disabled?: boolean
  name: string
  onChange: (value: Nullable<LedgerNumberFormValue>) => void
  options: LedgerNumber[]
  recommendations?: number[]
  suggestion?: LedgerNumberFormValue
  value: Nullable<string>
}

function PureLedgerNumberCreatableSelect({
  className,
  createNewOption,
  disabled = false,
  name,
  onChange,
  options,
  recommendations = [],
  suggestion,
  value,
}: LedgerNumberCreatableSelectProps) {
  const { formatMessage } = useIntl()
  const [isLoading, setLoading] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const { setErrorAlert } = useAlertDispatch()
  const promptTextCreator = usePromptTextCreator()

  const selectValue = value ? createSingleOption(value) : null

  const selectOptions = React.useMemo(() => {
    let filteredOptions: LedgerNumberFormValue[] = options
    if (suggestion) {
      filteredOptions = filteredOptions.filter(
        option => option.code !== suggestion.code && option.name !== suggestion.name
      )
    }
    // separate options and recommendations
    const [recommendedOptions, existingOptions] = __partition(filteredOptions, function (option) {
      return option.id && recommendations.includes(option.id)
    })

    if (suggestion) {
      recommendedOptions.push(suggestion)
    }

    if (recommendedOptions.length > 0) {
      return [
        {
          label: formatMessage(recommendationMessages.recommendationsLabel),
          options: createOptions(recommendedOptions),
        },
        {
          label: formatMessage(recommendationMessages.optionsLabel),
          options: createOptions(existingOptions),
        },
      ]
    }
    return createOptions(existingOptions)
  }, [formatMessage, options, recommendations, suggestion])

  const handleChange = React.useCallback(
    (option, { action }) => {
      if (action === 'select-option' && option) {
        onChange(option.data) // set to form field
      }
      if (action === 'clear') {
        onChange(null) // set to form fields
      }
    },
    [onChange]
  )

  const handleInputChange = React.useCallback((rawValue: string) => {
    const inputValue =
      (rawValue.length >= 2 && rawValue.match(LEDGER_NUMBER_WITH_OPTIONAL_NAME_REGEX)) || // already entered minimum 2 digits
      (rawValue.length < 2 && rawValue.match(OPTIONAL_DIGITS_REGEX)) // enter at least 2 digits first
        ? rawValue
        : rawValue.substring(0, rawValue.length - 1)
    setInputValue(inputValue)
  }, [])

  const handleCreateOption = React.useCallback(
    async (inputValue: string) => {
      setLoading(true)
      const payload = getValueComponents(inputValue)
      try {
        const result = await createNewOption(payload)
        onChange(result)
      } catch (error) {
        // display error in snackalert
        setErrorAlert(error)
      }
      setLoading(false)
    },
    [createNewOption, onChange, setErrorAlert]
  )

  const noOptionsMessage = React.useCallback(() => {
    if (!selectOptions.length) {
      return formatMessage(formSelectMessages.ledgerNumberEmptyCreateText)
    }
    return formatMessage(formSelectMessages.selectNoResultsText)
  }, [formatMessage, selectOptions])

  return (
    <CreatableSelect
      {...componentOverridesProp}
      getNewOptionData={getNewOptionData}
      isValidNewOption={isValidNewOption}
      className={className}
      classNamePrefix="react-select"
      formatCreateLabel={promptTextCreator}
      inputId={name}
      inputValue={inputValue}
      instanceId={__uniqueId('rs')}
      isClearable
      isDisabled={disabled}
      isLoading={isLoading}
      noOptionsMessage={noOptionsMessage}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      onInputChange={handleInputChange}
      options={selectOptions}
      placeholder={formatMessage(formSelectMessages.selectPlaceholder)}
      styles={customReactSelectStyles}
      value={selectValue}
    />
  )
}

export const LedgerNumberCreatableSelect = connect(
  (state: Store) => ({
    options: state.dashboard.ledgerNumbers,
  }),
  dispatch => ({
    createNewOption: bindActionToPromise(dispatch, dashboardActions.createLedgerNumber.request),
  })
)(PureLedgerNumberCreatableSelect)

LedgerNumberCreatableSelect.displayName = 'LedgerNumberCreatableSelect'
