import { makeStyles } from '@material-ui/core/styles'

import { generateMaxHeightBreakPoint, generateMinHeightBreakPoint } from '@constants'

// TODO: once Theme is typed
export const useStyles = makeStyles((theme: any) => ({
  paper: {
    width: '100%',
    padding: theme.sizes.medium,
    minHeight: '100%',
  },
  root: {
    height: '100%',
    textAlign: 'center',
  },
  rootPaper: {
    [generateMinHeightBreakPoint('desktop')]: {
      paddingTop: theme.sizes.medium,
    },
  },
  item: {
    // maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  dropzone: {
    marginTop: theme.sizes.medium,
    [generateMinHeightBreakPoint('desktop')]: {
      marginTop: theme.sizes.large,
    },
    '&.small': {
      marginTop: 0,
    },
  },
  dropzoneDialog: { minHeight: 290 },
  uploadBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    '& > img': {
      marginTop: -20,
      marginBottom: 10,
      [generateMaxHeightBreakPoint('medium')]: {
        height: 70,
      },
    },
    '& > .button': {
      marginTop: 15,
      [generateMinHeightBreakPoint('medium')]: {
        marginTop: 30,
      },
    },
  },
  marginTopRow: {
    marginTop: theme.sizes.small,
    [generateMinHeightBreakPoint('medium')]: {
      marginTop: theme.sizes.medium,
    },
  },
  xMarginTopRow: {
    marginTop: theme.sizes.medium,
    [generateMinHeightBreakPoint('desktop')]: {
      marginTop: theme.sizes.large,
    },
  },
  xxMarginTopRow: {
    marginTop: theme.sizes.xLarge,
    [generateMinHeightBreakPoint('medium')]: {
      marginTop: theme.sizes.xxLarge,
    },
  },
  xxxMarginTopRow: {
    marginTop: theme.sizes.xxLarge,
    [generateMinHeightBreakPoint('medium')]: {
      marginTop: theme.sizes.xxxLarge,
    },
  },
  uploadDescription: {
    [generateMaxHeightBreakPoint('medium')]: {
      lineHeight: '22px',
    },
  },
}))
