import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { FileDownloadIcon, FlexColumn, FlexRow, IconButton, Typography } from '@components/ui'
import { LinkIconButton } from '@components/ui/Buttons'

export const StyledFileDownloadIcon = styled(FileDownloadIcon)`
  margin: -2px;
`

export const StyledIconButton = styled(IconButton)`
  padding: 4px;
`

export const StyledLinkButton = styled(LinkIconButton)`
  padding: 4px;
`

export const ListItem = styled.li<{ $divider: boolean }>`
  display: flex;
  gap: 10px;
  position: relative;
  ${({ $divider, theme }) =>
    $divider &&
    css`
      padding-bottom: 10px;
      margin-bottom: 7px;
      border-bottom: 1px solid ${theme.colors.common.paperStroke};
    `}
`

export const ListItemContent = styled(FlexColumn)`
  gap: 10px;
  flex: 1;
  overflow: hidden;
`

export const ListItemBody = styled.div`
  padding-top: 3px;
  display: flex;
  gap: 40px;
`

export const ListItemText = styled(FlexColumn)`
  flex: 1;
  overflow: hidden;
`

export const IconWrapper = styled.div<{ $isLink: boolean }>`
  padding-top: 3px;
  flex-shrink: 0;
  width: 20px;
  font-size: 20px;
  color: ${({ $isLink, theme }) => ($isLink ? theme.colors.blue[40] : theme.colors.primary[50])};
`

export const ListItemActionsContainer = styled(FlexRow)`
  padding: 3px;
  margin-top: -3px;
  min-width: 100px;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
`

export const ErrorTypography = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  box-shadow: ${({ theme }) => theme.shadows[50]};
  max-width: 250px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 38px;
  z-index: 10;
`

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
