import React from 'react'

import { ButtonBase, Fade, Input, InputAdornment, MenuItem, MenuList, Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import { FormattedMessage, useIntl } from 'react-intl'

import localeCompareSort from '@helpers/stringOrder'

import { FilterChip } from './FilterChip'
import { CommonFilterSelectProps } from './types'

import messages from '@components/filters/PageFilterBars/messages'
import { filterSelectStyles } from '@components/filters/PageFilterBars/styles'

const useStyles = makeStyles(filterSelectStyles)

interface WidgetFilterSelectProps<Payload extends Record<string, SelectableFilterOption[]>>
  extends CommonFilterSelectProps<Payload> {
  disabled?: boolean
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  value: SelectableFilterOption[]
}

export function WidgetFilterSelect<Payload extends Record<string, SelectableFilterOption[]>>({
  disabled = false,
  keyValue,
  labelText,
  onChange,
  searchTerm,
  selectableOptions,
  selectedLabelText,
  selectedOptions,
  setSearchTerm,
  value,
  emptyPlaceholder = '-',
}: WidgetFilterSelectProps<Payload>) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const classes = useStyles()
  const { formatMessage } = useIntl()

  React.useEffect(() => {
    // clear search input value when close the menu
    if (!anchorEl) {
      setSearchTerm('')
    }
  }, [anchorEl, setSearchTerm])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleEscClear = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchTerm && event.key === 'Escape') {
      event.stopPropagation()
      setSearchTerm('')
    }
  }

  const handleSelect = (option: SelectableFilterOption) => () => {
    onChange({ [keyValue]: [...value, option] } as Payload) // need to cast due to generic type, otherwise TS will complain
  }

  const handleDeselect = (option: SelectableFilterOption) => () => {
    onChange({
      [keyValue]: value.filter(({ id }) => id !== option.id),
    } as Payload) // need to cast due to generic type, otherwise TS will complain
  }

  const open = Boolean(anchorEl)
  const id = open ? `${String(keyValue)}-filter-popover` : undefined

  return (
    <>
      <ButtonBase
        aria-describedby={id}
        className={classes.button}
        disabled={disabled}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
        data-testid="widget-filter-select"
      >
        <span className={classes.buttonLabel}>{labelText}</span>
        <span className={classes.buttonValue}>
          {selectedOptions.map(option => option.name).join(', ') || emptyPlaceholder}
        </span>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        <div className={classes.menuContainer}>
          <Typography variant="h4" className={classes.heading}>
            <FormattedMessage
              id="filterSelect.popover.headings.selected"
              defaultMessage="Kijelölt {values}"
              values={{
                values: selectedLabelText || labelText,
              }}
            />
          </Typography>

          <div className={classes.selectedFiltersContainer}>
            {selectedOptions.length === 0 && <span className={classes.buttonValue}>{emptyPlaceholder}</span>}
            {selectedOptions.map(option => (
              <FilterChip
                key={option.id}
                label={option.name}
                deleteIcon={<ClearIcon color="inherit" fontSize="inherit" />}
                onDelete={handleDeselect(option)}
                variant="outlined"
              />
            ))}
          </div>
        </div>
        <div className={classes.inputContainer}>
          <Input
            placeholder={formatMessage(messages.searchInputPlaceholder)}
            className={classes.searchInput}
            disableUnderline
            fullWidth
            value={searchTerm}
            onKeyDown={handleEscClear}
            onChange={handleInputChange}
            endAdornment={
              <>
                <InputAdornment component={Fade} in={Boolean(searchTerm)} position="end">
                  <ButtonBase tabIndex={-1} className={classes.inputClearButton} onClick={() => setSearchTerm('')}>
                    <ClearIcon color="inherit" fontSize="inherit" />
                  </ButtonBase>
                </InputAdornment>
                <InputAdornment className={classes.searchIcon} position="end">
                  <SearchIcon fontSize="inherit" color="inherit" />
                </InputAdornment>
              </>
            }
          />

          <Typography variant="h4" className={classes.heading}>
            {labelText}
          </Typography>
        </div>
        <div>
          <MenuList className={classes.list}>
            {selectableOptions
              .sort((a, b) => localeCompareSort(a.name, b.name))
              .map(option => (
                <MenuItem key={option.id} className={classes.listItem} onClick={handleSelect(option)}>
                  <Typography variant="inherit">{option.name}</Typography>
                </MenuItem>
              ))}
          </MenuList>
        </div>
      </Popover>
    </>
  )
}

// propTypes of WidgetFilterSelect skipped due to conflict with generic type
