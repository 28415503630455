import React from 'react'

import __isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'

import { filtersActions, MONTHLY_STATEMENT_INITIAL_FILTERS } from '@services'

import { usePageConfig, usePortal } from '@contexts'

import { MOTHLY_STATEMENT_DATE_FIELD_OPTIONS } from '@components/pages/constants'

import { FilterBar, FilterBarBaseProps } from './FilterBar'
import { getSelectableFilterProps } from './helpers'

import { MonthlyStatementPageMessage, TagFilterEmptyPlaceholderMessage, TagFilterLabelMessage } from '@messages'

interface PureMonthlyStatementPageFilterBarProps extends FilterBarBaseProps {
  filters: MonthlyStatementStoreFilters
  tagOptions: Tag[]
}

function PureMonthlyStatementPageFilterBar({
  filters,
  filters: { dateField, search, searchFields, withoutTagIds, withTagIds },
  isDateFilterDisabled,
  resetFilters,
  tagOptions,
  updateFilters,
}: PureMonthlyStatementPageFilterBarProps) {
  const { setPortalAnchorEl } = usePortal()
  const pageConfig = usePageConfig()

  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }

  const selectableFilterProps = getSelectableFilterProps({
    values: {
      withTagIds,
    },
    config: [
      {
        keyValue: 'withTagIds' as const,
        options: tagOptions,
        labelText: TagFilterLabelMessage,
        emptyPlaceholder: TagFilterEmptyPlaceholderMessage,
      },
    ],
  })

  const filterDialogProps: FilterDialogProps<StatusFilterConfig> = {
    dateFilterProps: {
      dateTypeOptions: MOTHLY_STATEMENT_DATE_FIELD_OPTIONS,
      dateTypeValue: dateField,
    },
    isChanged: !__isEqual(filters, MONTHLY_STATEMENT_INITIAL_FILTERS),
    page: MonthlyStatementPageMessage,
    searchFilterProps,
  }
  if (pageConfig.tagFilterProps) {
    filterDialogProps['tagFilterProps'] = {
      config: {
        ...pageConfig.tagFilterProps.config,
        options: tagOptions,
      },
      values: {
        include: withTagIds,
        exclude: withoutTagIds,
      },
    }
  }

  return (
    <FilterBar
      dateField={filters.dateField}
      filterDialogProps={filterDialogProps}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterToggleDisabled
      isDateFilterUsed
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      selectableFilterProps={selectableFilterProps}
      setPortalRef={setPortalAnchorEl}
      updateFilters={updateFilters}
    />
  )
}

export const MonthlyStatementPageFilterBar = connect(
  ({
    dashboard: {
      tags: { data: tagOptions },
    },
    filters: { isGlobalDateFilterDisabled, monthlyStatement },
  }: Store) => {
    return {
      filters: monthlyStatement,
      isDateFilterDisabled: isGlobalDateFilterDisabled,
      tagOptions,
    }
  },
  {
    resetFilters: filtersActions.resetMonthlyStatementFilters.request,
    updateFilters: filtersActions.updateMonthlyStatementFilters.request,
  }
)(PureMonthlyStatementPageFilterBar)

MonthlyStatementPageFilterBar.displayName = 'MonthlyStatementPageFilterBar'
