import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import * as yup from 'yup'

import { paymentActions } from '@services'

import { applyEmptyFormFieldsAndNullEmptyStrings, bindActionToPromise, makeValidate } from '@helpers'

import { EditorMode } from '@hooks'

import { FormDialog } from '@components/FormDialog'

import { BANK_ACCOUNT_NUMBER_REGEX, CITIBANK_ACCOUNT_NUMBER_REGEX } from '@constants'

import PaidThroughEditorForm from './PaidThroughEditorForm'
import { PaidThroughDialogVariant, PaidThroughFormValues, PurePaidThroughEditorDialogProps } from './types'

import { formErrorMessages } from '@messages'

/**
 * This is what gets called once the form is successfully submitted to display a success message
 *
 * @param {PaidThroughDetailData} data - the data returned by the API
 * @param {EditorMode} mode - can be `EDIT` or `CREATE`
 */
function onSubmitSuccessText(data: PaidThroughDetailData | null, mode: EditorMode) {
  return (
    <FormattedMessage
      id="editor.paidThrough.success.message"
      defaultMessage="Sikeresen {mode} a(z) {name} pénztárcát. Amennyiben autokassza integrációt is beállítottál a szinkron több napon keresztül is eltarthat, de utána folyamatos lesz."
      values={{
        mode:
          mode === EditorMode.CREATE ? (
            <FormattedMessage id="editor.paidThrough.success.create" defaultMessage="létrehoztad" />
          ) : (
            <FormattedMessage id="editor.paidThrough.success.edit" defaultMessage="módosítottad" />
          ),
        name: <strong>{data?.name}</strong>,
      }}
    />
  )
}

const INITIAL_VALUES: PaidThroughFormValues = {
  account_number: '',
  add_new_balance: false,
  balance: null,
  currency: null,
  is_autokassza: false,
  ledger_number: null,
  name: '',
  provider: null,
  paidthrough_type: null,
  value_date: null,
}

// local helper
export function createInitialValues(data?: Partial<PaidThroughDetailData>) {
  return {
    ...INITIAL_VALUES,
    account_number: data?.account_number ?? INITIAL_VALUES.account_number,
    add_new_balance: INITIAL_VALUES.add_new_balance,
    currency: data?.currency?.id ?? INITIAL_VALUES.currency,
    id: data?.id,
    is_autokassza: data?.is_autokassza ?? INITIAL_VALUES.is_autokassza,
    ledger_number: data?.ledger_number ?? INITIAL_VALUES.ledger_number,
    name: data?.name ?? INITIAL_VALUES.name,
    paidthrough_type: data?.paidthrough_type ?? INITIAL_VALUES.paidthrough_type,
    provider: data?.provider ?? INITIAL_VALUES.provider,
  }
}

/**
 * Component to handle editing or creation of a paid through using FormDialog
 *
 * @param {PurePaidThroughEditorDialogProps} {
 *   callCreateListItem,
 *   callCreateSelectOption,
 *   callUpdate,
 *   editor: { open, mode, payload: paidThroughId, data: initialData },
 *   loadDetails,
 *   onClose,
 *   onSubmitSuccess
 * }
 * @returns
 */
export function PurePaidThroughEditorDialog({
  callCreateListItem,
  callCreateSelectOption,
  callUpdate,
  editor: { open, mode, payload: paidThroughId, data: initialData },
  loadDetails,
  onClose,
  onSubmitSuccess,
  variant = PaidThroughDialogVariant.LIST_ITEM,
}: PurePaidThroughEditorDialogProps) {
  const { formatMessage } = useIntl()

  const isEdit = mode === EditorMode.EDIT

  const yupValidatorRef = React.useRef(
    makeValidate(
      yup.object().shape({
        paidthrough_type: yup.number().nullable().required(formatMessage(formErrorMessages.required)),
        currency: yup.number().nullable().required(formatMessage(formErrorMessages.required)),
        name: yup.string().required(formatMessage(formErrorMessages.required)),
        provider: yup.number().nullable(),
        account_number: yup
          .string()
          .nullable()
          .when(['provider'], {
            is: (provider: number | string) => provider != null && provider !== '',
            then: yup.string().required(formatMessage(formErrorMessages.required)),
          })
          .when(['provider'], {
            is: (value: number | string) => value === 1, // citibank
            then: yup.string().matches(CITIBANK_ACCOUNT_NUMBER_REGEX, {
              message: formatMessage(formErrorMessages.invalidBankIdentifier),
              excludeEmptyString: true,
            }),
            otherwise: yup.string().matches(BANK_ACCOUNT_NUMBER_REGEX, {
              message: formatMessage(formErrorMessages.invalidBankAccountNumber),
              excludeEmptyString: true,
            }),
          }),
        is_autokassza: yup.boolean(),
        balance: yup
          .string()
          .nullable()
          // if value_date is set, balance is required
          .test('balance', formatMessage(formErrorMessages.required), function (value) {
            const { value_date } = this.parent
            return value_date && value_date !== '' ? Boolean(value) : true
          }),
        value_date: yup
          .string()
          .nullable()
          .test(
            'value_date',
            formatMessage(formErrorMessages.invalidDate),
            value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
          )
          .test(
            'value_date',
            formatMessage(formErrorMessages.invalidFutureDate),
            value => !moment(value, 'YYYY-MM-DD', true).isAfter()
          )
          // if balance is set, value_date is required
          .test('value_date', formatMessage(formErrorMessages.required), function (value) {
            const { balance } = this.parent
            return balance && balance !== '' ? Boolean(value) : true
          }),
      })
    )
  )

  const dialogTitle = isEdit ? (
    <FormattedMessage id="editor.paidThrough.editDialogTitle" defaultMessage="Pénztárca szerkesztése" />
  ) : (
    <FormattedMessage id="editor.paidThrough.newDialogTitle" defaultMessage="Új pénztárca hozzáadása" />
  )

  async function handleFormSubmit(values: PaidThroughFormValues) {
    // frontend should always send every field, even if it's empty
    const submitValues = applyEmptyFormFieldsAndNullEmptyStrings(values, INITIAL_VALUES, ['balance', 'value_date'])

    if (isEdit) {
      return callUpdate(submitValues)
    }

    if (variant === PaidThroughDialogVariant.SELECT_OPTION) {
      return callCreateSelectOption(submitValues)
    }

    return callCreateListItem(submitValues)
  }

  function onLoadFormData() {
    // it will only run when we do have paidThroughId already
    return loadDetails(paidThroughId as number)
  }

  return (
    <FormDialog
      createInitialValues={createInitialValues}
      dialogTitle={dialogTitle}
      initialData={initialData}
      mode={mode}
      onClose={onClose}
      onLoad={onLoadFormData}
      open={open}
      onSubmit={handleFormSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onLoadFailureText={
        <FormattedMessage
          id="editor.paidThrough.loadFailure"
          defaultMessage="Hiba történt a pénztárca adatainak lekérése során:"
        />
      }
      onSubmitSuccessText={onSubmitSuccessText}
      skipSuccessResponse={variant === PaidThroughDialogVariant.SELECT_OPTION}
      validate={yupValidatorRef.current}
    >
      {(formRenderProps, { storedValues, aria }) => (
        <PaidThroughEditorForm
          {...formRenderProps}
          aria={aria}
          detailsData={storedValues}
          isEdit={isEdit}
          onClose={onClose}
        />
      )}
    </FormDialog>
  )
}

PurePaidThroughEditorDialog.propTypes = {
  callCreateListItem: PropTypes.func.isRequired,
  callCreateSelectOption: PropTypes.func.isRequired,
  callUpdate: PropTypes.func.isRequired,
  editor: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    mode: PropTypes.oneOf(['create', 'edit']).isRequired as React.Validator<EditorMode>,
    payload: PropTypes.number as React.Validator<number>,
    data: PropTypes.shape({ name: PropTypes.string.isRequired }) as React.Validator<{ name: string }>,
  }).isRequired,
  loadDetails: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
}

export const PaidThroughEditorDialog = connect(null, dispatch => ({
  callCreateListItem: bindActionToPromise(dispatch, paymentActions.createPaidThrough.request),
  callCreateSelectOption: bindActionToPromise(dispatch, paymentActions.createPaidThroughOption.request),
  callUpdate: bindActionToPromise(dispatch, paymentActions.updatePaidThrough.request),
  loadDetails: bindActionToPromise(dispatch, paymentActions.fetchPaidThroughDetails.request),
}))(PurePaidThroughEditorDialog)

PaidThroughEditorDialog.displayName = 'PaidThroughEditorDialog'
