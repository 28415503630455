export const iconButtonStyles = (theme: any) => ({
  root: {
    fontSize: 16,
    lineHeight: '20px',
    height: 34,
    width: 34,
    color: theme.palette.neutral.mDark,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.grayscale.xxxDark,
    },
    '&$disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.grayscale.xLight,
    },
  },
  disabled: {},
  minimal: {
    width: 'auto',
    padding: 0,
  },
})
