import { Theme } from '@material-ui/core/styles'
import { StyleRulesCallback } from '@material-ui/styles'

const styles: StyleRulesCallback<Theme, Record<never, never>> = theme => ({
  root: {
    padding: 50,
    paddingBottom: 0,
    '& > p': {
      marginTop: 10,
    },
    '& button': {
      marginTop: 20,
    },
    '& a': {
      color: theme.palette.tertiary.oceanBlue,
      textDecoration: 'underline',
    },
  },
  error: {
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 6,
  },
  status: {
    backgroundColor: theme.palette.success.main,
    fontSize: 12,
    color: theme.palette.neutral.xxxLight,
    padding: '0 7px',
    borderRadius: 4,
    '&.error': {
      backgroundColor: theme.palette.error.main,
    },
  },
})

export default styles
