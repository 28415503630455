import React from 'react'

import { FormattedMessage } from 'react-intl'

import { StrongTextValues } from '@helpers'

import { OverflowText } from '@components/ui'
import { Typography } from '@components/ui/Typography'

import { DuplicateResults } from '../types'

import { PreviewHeaderContainer } from './styles'

interface PreviewInvoiceHeaderProps {
  data: DuplicateResults
  showDuplicateInfo?: boolean
}

export function PreviewInvoiceHeader({ data, showDuplicateInfo = false }: PreviewInvoiceHeaderProps) {
  const invoiceId = showDuplicateInfo ? data.duplicateId : data.originalId
  const invoiceFilename = showDuplicateInfo ? data.duplicateFilename : data.originalFilename

  const text = invoiceFilename ? (
    <FormattedMessage
      id="duplicateInvoice.header.uploadedInvoiceWithFileName"
      defaultMessage="<b>Számla ID:</b> {invoiceId} - {filename}"
      values={{
        ...StrongTextValues,
        invoiceId,
        filename: invoiceFilename,
      }}
    />
  ) : (
    <FormattedMessage
      id="duplicateInvoice.header.uploadedInvoice"
      defaultMessage="<b>Számla ID:</b> {invoiceId}"
      values={{ ...StrongTextValues, invoiceId }}
    />
  )

  return (
    <PreviewHeaderContainer>
      <Typography size="400-xs">
        <OverflowText>{text}</OverflowText>
      </Typography>
    </PreviewHeaderContainer>
  )
}
