import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import GoogleAnalytics from 'react-ga4'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { dashboardActions } from '@services'

import { bindActionToPromise, cancellablePromise, initAmplitude, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { PublicPage } from '@oldComponents/pages'

import { SessionExpiredDialog } from './SessionExpiredDialog'

import { ReloadPageButtonMessage } from '@messages'

interface InitializeApplicationProps {
  children: React.ReactNode
  fetch: AsyncFunction<void, BackendInitResponse>
  locale: Locale
}

function PureInitializeApplication({ children, fetch }: InitializeApplicationProps) {
  const [{ error, fetched }, setState] = React.useState<{ error: BackendError; fetched: boolean }>({
    fetched: false,
    error: null,
  })
  const cPromiseRef = useCancellablePromiseRef<BackendInitResponse>()

  React.useEffect(() => {
    async function init() {
      try {
        cPromiseRef.current = cancellablePromise(fetch())
        const response = await cPromiseRef.current.promise
        setState(state => ({ ...state, fetched: true }))
        // initialize amplitude
        if (response.amplitude_api_key) {
          initAmplitude(response.amplitude_api_key)
        }
        // initialize GoogleAnalytics
        if (response.ga_api_key) {
          GoogleAnalytics.initialize(response.ga_api_key)
        }
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setState(state => ({ ...state, error: errorMessage }))
        }
      }
    }
    init()
  }, [])

  function handleReloadWindow() {
    window.location.reload()
  }

  if (fetched) {
    return (
      <>
        {children}
        <SessionExpiredDialog />
      </>
    )
  }

  // show error or loading screen
  return (
    <PublicPage>
      <Grid container justifyContent="center" alignItems="center">
        {error ? (
          <Grid item style={{ textAlign: 'center' }}>
            <Typography variant="h1" gutterBottom>
              <FormattedMessage id="pages.initError.title" defaultMessage="Hiba" />
            </Typography>

            <Typography variant="body1">
              <FormattedMessage id="pages.initError.text" defaultMessage="Az applikáció betöltése sikertelen. " />
            </Typography>

            <Button color="primary" onClick={handleReloadWindow}>
              {ReloadPageButtonMessage}
            </Button>
          </Grid>
        ) : (
          <Grid item style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: 30 }}>
              <CircularProgress color="primary" size={50} />
            </div>
            <Typography variant="body1">
              <FormattedMessage id="pages.initialize.app.title" defaultMessage="Applikáció betöltése folyamatban..." />
            </Typography>
          </Grid>
        )}
      </Grid>
    </PublicPage>
  )
}

PureInitializeApplication.propTypes = {
  children: PropTypes.node.isRequired,
  fetch: PropTypes.func.isRequired,
  locale: PropTypes.oneOf(['hu', 'en']).isRequired as React.Validator<Locale>,
}

export const InitializeApplication = connect(
  (state: Store) => ({
    locale: state.locale.language, // trigger language update in app
  }),
  dispatch => ({
    fetch: bindActionToPromise(dispatch, dashboardActions.initialize.request),
  })
)(PureInitializeApplication)

InitializeApplication.displayName = 'InitializeApplication'
