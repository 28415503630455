import { defineMessages } from 'react-intl'

const messages = defineMessages({
  partnerNameLabel: {
    id: 'form.labels.name',
    defaultMessage: 'Név',
  },
  accountNumberLabel: {
    id: 'form.labels.accountNumber',
    defaultMessage: 'Bankszámlaszám',
  },
  partnerAccountNumberLabel: {
    id: 'form.labels.alastAcountNumber',
    defaultMessage: 'Legutolsó számlán szereplő bankszámlaszám:',
  },
  defaultAccountNumberLabel: {
    id: 'form.labels.defaultAccountNumber',
    defaultMessage: 'Alapértelmezett bankszámlaszám',
  },
  taxNumberLabel: {
    id: 'form.labels.taxNumber',
    defaultMessage: 'Adószám',
  },
  partnerZipCodeLabel: {
    id: 'form.labels.zip_code',
    defaultMessage: 'Irányítószám',
  },
  partnerCityLabel: {
    id: 'form.labels.city',
    defaultMessage: 'Város',
  },
  partnerCountryLabel: {
    id: 'form.labels.country',
    defaultMessage: 'Ország',
  },
  partnerAddressLabel: {
    id: 'form.labels.address',
    defaultMessage: 'Cím',
  },
  partnerProviderLabel: {
    id: 'form.label.provider',
    defaultMessage: 'Szállító',
  },
  partnerCustomerLabel: {
    id: 'form.label.customer',
    defaultMessage: 'Vevő',
  },
  partnerProviderNameLabel: {
    id: 'form.label.providerName',
    defaultMessage: 'Szállító neve',
  },
  partnerCustomerNameLabel: {
    id: 'form.label.customerName',
    defaultMessage: 'Vevő neve',
  },
  partnerKataSubjectLabel: {
    id: 'form.label.kataSubject',
    defaultMessage: 'Kisadózó',
  },
  partnerReverseTaxSubjectLabel: {
    id: 'form.label.reverseTaxSubject',
    defaultMessage: 'Fordított adózó',
  },
  partnerCashAccountLabel: {
    id: 'form.label.cashAccount',
    defaultMessage: 'Pénzforgalmi adózó',
  },
})

export default messages
