import React from 'react'
import PropTypes from 'prop-types'

import { Fade, Typography } from '@material-ui/core'

export class FormFeedback extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      const { callback, delay } = this.props
      if (callback) {
        this.timeout = setTimeout(callback, delay)
      }
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    const { callback, color, delay, text, show, ...rest } = this.props
    return (
      <Fade in={show}>
        <Typography color={color} paragraph style={{ margin: 0 }} align="left" {...rest}>
          {text}
        </Typography>
      </Fade>
    )
  }
}
FormFeedback.propTypes = {
  callback: PropTypes.func,
  color: PropTypes.string,
  delay: PropTypes.number,
  text: PropTypes.node,
  show: PropTypes.bool.isRequired,
}
FormFeedback.defaultProps = { delay: 3000 }
