import React from 'react'
import PropTypes from 'prop-types'

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { FormattedMessage, useIntl } from 'react-intl'

import { Currency, FlexColumn } from '@components/ui'

import { PotentialExpense } from '../types'

import messages from '@oldComponents/forms/DetailsForm/messages'
import { BoldValue, Card, CardTitle, ContainerDiv, IconContainer, Label, Value } from './styles'

interface SelectableNavInvoiceCardProps {
  data: PotentialExpense
  selected: boolean
  onSelect: (id: ItemIdType) => void
}

export default function SelectableNavInvoiceCard({ data, selected, onSelect }: SelectableNavInvoiceCardProps) {
  const { formatMessage, formatDate } = useIntl()

  function handleClick() {
    onSelect(data.id)
  }

  return (
    <Card onClick={handleClick} $selected={selected}>
      <IconContainer>
        {selected ? <RadioButtonCheckedIcon color="primary" /> : <RadioButtonUncheckedIcon color="inherit" />}
      </IconContainer>
      <CardTitle>{`${data.invoiceNumber} ${data.partnerName}`}</CardTitle>
      <ContainerDiv>
        <FlexColumn>
          <Label>{formatMessage(messages.fulfilledDateLabel)}</Label>
          <Value>{formatDate(data.fulfilledAt)}</Value>
        </FlexColumn>
        <FlexColumn>
          <Label>{formatMessage(messages.createdDateLabel)}</Label>
          <Value>{formatDate(data.createdAt)}</Value>
        </FlexColumn>
        <FlexColumn>
          <Label>{formatMessage(messages.deadlineDateLabel)}</Label>
          <Value>{formatDate(data.dueAt)}</Value>
        </FlexColumn>
        <FlexColumn>
          <Label>{formatMessage(messages.totalCostLabel)}</Label>
          <BoldValue>
            <Currency value={Number(data.grossAmount)} currencyId={data.currencyId} />
          </BoldValue>
        </FlexColumn>
      </ContainerDiv>
      <ContainerDiv>
        <FlexColumn>
          <Label>
            <FormattedMessage id="navInvoice.card.assignments" defaultMessage="Tételek:" />
          </Label>
          {data.expenseTypes.map((assignment, index, arr) => (
            <Value key={assignment}>
              {assignment}
              <>
                {index === arr.length - 1 && !!data.expenseTypesCount && (
                  <BoldValue tag="span">
                    &nbsp;
                    <FormattedMessage
                      id="navInvoice.card.assignmentsCount"
                      defaultMessage="+{value} egyéb tétel"
                      values={{ value: data.expenseTypesCount }}
                    />
                  </BoldValue>
                )}
              </>
            </Value>
          ))}
        </FlexColumn>
      </ContainerDiv>
    </Card>
  )
}

SelectableNavInvoiceCard.propTypes = {
  data: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    currencyId: PropTypes.number.isRequired,
    dueAt: PropTypes.string.isRequired,
    expenseTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    expenseTypesCount: PropTypes.number.isRequired,
    fulfilledAt: PropTypes.string.isRequired,
    grossAmount: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    invoiceNumber: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}
