import Cookie from 'js-cookie'

const COOKIE_NAME = 'quick_mobile_warning'
const COOKIE_EXPIRATION_DAYS = 100

export function setWarningCookie() {
  Cookie.set(COOKIE_NAME, 1, { expires: COOKIE_EXPIRATION_DAYS })
}

export function getWarningCookie() {
  return Cookie.get(COOKIE_NAME) || 0
}
