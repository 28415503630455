import React from 'react'

import { FormControlLabel, Switch } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { ExternalLink, InfoText } from '@components/ui'

import { LINKS } from '@constants'

import { ManualOrExpensePaymentFormValues } from '../types'

import { MoreInformationMessage } from '@messages'

const StyledInfoText = styled(InfoText)`
  max-width: 1062px;
`

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; // prevent streatch
  gap: 12px;
  margin-bottom: 10px;

  & > a {
    margin-left: 34px;
  }
`

interface ExpressPaymentSwitchProps {
  checked: boolean
  initialValues: ManualOrExpensePaymentFormValues
  onChange: VoidFunction
}

export function ExpressPaymentSwitch({ checked, initialValues, onChange }: ExpressPaymentSwitchProps) {
  const { reset } = useFormContext()

  React.useEffect(() => {
    // Do not delete the seemingly unnecessary check.
    // This enforces prettier updates on the dependency array to be always correct.
    if (typeof checked == 'boolean') {
      reset(initialValues)
    }
  }, [checked, initialValues, reset])

  return (
    <WrapperDiv>
      <FormControlLabel
        control={<Switch color="primary" checked={checked} onChange={onChange} />}
        label={<FormattedMessage id="payment.express.switchLabel" defaultMessage="Expressz fizetés" />}
      />
      {checked && (
        <>
          <StyledInfoText iconColor="gray-40" size="400-md">
            <FormattedMessage
              id="payment.express.infoText"
              defaultMessage="Az Expressz fizetést akkor válaszd, ha gyorsan szeretnél fizetettnek jelölni múltbeli számlákat. A fizetést egy külön Expressz nevű pénztárcában adminisztráljuk. Az Expressz fizetés során a teljes számlaösszeget tudod kiegyenlítetté tenni, részkifizetés rögzítésére nincs lehetőség."
            />
          </StyledInfoText>
          <ExternalLink href={LINKS.expressPayment} variant="primary" text={MoreInformationMessage} />
        </>
      )}
    </WrapperDiv>
  )
}
