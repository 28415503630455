import React from 'react'
import PropTypes from 'prop-types'

export default function BadgeCheckListIcon({ className, size = 18 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.34727 1.34168C5.6918 1.65351 5.71992 2.18684 5.40703 2.53348L2.87719 5.34738C2.72215 5.51613 2.50348 5.61809 2.27215 5.59348C2.04082 5.63215 1.81723 5.54074 1.6534 5.34738L0.247113 3.94113C-0.0823711 3.6423 -0.0823711 3.10793 0.247113 2.77851C0.576563 2.4491 1.11094 2.4491 1.44035 2.77851L2.21766 3.55441L4.15547 1.40426C4.46484 1.05797 4.99922 1.02984 5.34727 1.34168ZM5.34727 6.96809C5.6918 7.27746 5.71992 7.81184 5.40703 8.15988L2.87719 10.9724C2.72215 11.1411 2.50348 11.2431 2.27215 11.2185C2.04082 11.2571 1.81723 11.1657 1.6534 10.9724L0.247113 9.56613C-0.0823711 9.2673 -0.0823711 8.73293 0.247113 8.40246C0.576563 8.07551 1.11094 8.07551 1.44035 8.40246L2.21766 9.17941L4.15547 7.02785C4.46484 6.68332 4.99922 6.65519 5.34727 6.96809ZM7.875 3.37512C7.875 2.75391 8.37773 2.25012 9 2.25012H16.875C17.4973 2.25012 18 2.75391 18 3.37512C18 3.99738 17.4973 4.50012 16.875 4.50012H9C8.37773 4.50012 7.875 3.99738 7.875 3.37512ZM7.875 9.00012C7.875 8.37785 8.37773 7.87512 9 7.87512H16.875C17.4973 7.87512 18 8.37785 18 9.00012C18 9.62238 17.4973 10.1251 16.875 10.1251H9C8.37773 10.1251 7.875 9.62238 7.875 9.00012ZM5.625 14.6251C5.625 14.0029 6.12773 13.5001 6.75 13.5001H16.875C17.4973 13.5001 18 14.0029 18 14.6251C18 15.2474 17.4973 15.7501 16.875 15.7501H6.75C6.12773 15.7501 5.625 15.2474 5.625 14.6251ZM0 14.6251C0 13.6935 0.755508 12.9376 1.6875 12.9376C2.61949 12.9376 3.375 13.6935 3.375 14.6251C3.375 15.5568 2.61949 16.3126 1.6875 16.3126C0.755508 16.3126 0 15.5568 0 14.6251Z"
        fill="currentColor"
      />
    </svg>
  )
}

BadgeCheckListIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}
