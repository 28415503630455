export const DATE_WIDGET_VARIANTS = {
  menu: 'menu',
  list: 'list',
} as const

export const PAGE_FILTER_VARIANTS = {
  compact: 'compact',
  form: 'form-field',
  widget: 'widget',
} as const
