import React from 'react'
import PropTypes from 'prop-types'

import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'

import { WarningText } from '@components/ui'
import { Typography, TypographyProps } from '@components/ui/Typography'
import { RejectedFileResponse } from '@oldComponents/FileDropzone/types'

import { IconWrapper, ListItem, ListItemBody, ListItemContent, ListItemText } from './styles'

interface RejectedUploadedFileProps extends Pick<TypographyProps, 'color' | 'size'> {
  data: RejectedFileResponse
  divider: boolean
}

export function RejectedUploadedFile({ color = 'gray-40', data, divider, size = '400-md' }: RejectedUploadedFileProps) {
  return (
    <ListItem $divider={divider}>
      <IconWrapper $rejected>
        <InsertDriveFileOutlinedIcon fontSize="inherit" color="inherit" />
      </IconWrapper>
      <ListItemContent>
        <ListItemBody>
          <ListItemText>
            <Typography color={color} size={size} tag="span">
              {data.filename}
            </Typography>
          </ListItemText>
        </ListItemBody>
        <WarningText>{data.message}</WarningText>
      </ListItemContent>
    </ListItem>
  )
}

RejectedUploadedFile.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object.isRequired,
  divider: PropTypes.bool.isRequired,
  size: PropTypes.string,
}
