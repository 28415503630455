import React from 'react'

import { FormattedDate, FormattedMessage } from 'react-intl'

import { ContentWithLoadingOverlay, Currency, Table, TableBody, Typography } from '@components/ui'

import { PAID_INSTALLMENT, PAID_NO, PAID_YES } from '@constants'

import {
  HistoryTableCell,
  HistoryTableContainer,
  HistoryTitleTypography,
  NoDataTypography,
  StyledProgress,
  SummaryContainerTypography,
} from './styles'

interface PaidStatusHistoryProps {
  data: BackendInvoicePaymentHistoryResults
  fetched: boolean
  invoiceCurrencyId: number
  loading: boolean
  value: InvoicePaidStatusValue
}

export function PaidStatusHistory({ data, fetched, invoiceCurrencyId, loading, value }: PaidStatusHistoryProps) {
  const headingText = {
    [PAID_YES]: <FormattedMessage id="statuses.paid.fully" defaultMessage="Fizetve" />,
    [PAID_INSTALLMENT]: <FormattedMessage id="statuses.paid.partially" defaultMessage="Részben fizetve" />,
    [PAID_NO]: <FormattedMessage id="statuses.paid.no" defaultMessage="Fizetendő" />,
  }[value]

  const calculatedTotal = data.map(payment => Number(payment.amount)).reduce((acc, curr) => (acc += curr), 0)

  return (
    <>
      <HistoryTitleTypography color="blue" size="700-xs" $hasDetails={fetched}>
        {headingText}
      </HistoryTitleTypography>
      <HistoryTableContainer>
        {fetched && (
          <ContentWithLoadingOverlay loading={loading} size={16}>
            <Table>
              <TableBody>
                {data.map((payment, index) => (
                  <tr key={index}>
                    <HistoryTableCell>
                      <Typography color="gray-80" tag="span" size="700-xs">
                        <FormattedDate value={payment.date} />
                      </Typography>
                    </HistoryTableCell>
                    <HistoryTableCell align="right">
                      <Typography color="blue" size="700-xs" tag="span">
                        <Currency value={Number(payment.amount)} currencyId={invoiceCurrencyId} />
                      </Typography>
                    </HistoryTableCell>
                  </tr>
                ))}
              </TableBody>
            </Table>
            {data.length > 1 && (
              <SummaryContainerTypography size="700-xs">
                <Typography color="gray-80" tag="span">
                  <FormattedMessage id="statuses.paid.summary" defaultMessage="Összesen" />
                </Typography>
                <Typography color="blue" tag="span" align="right">
                  <Currency value={calculatedTotal} currencyId={invoiceCurrencyId} />
                </Typography>
              </SummaryContainerTypography>
            )}
            {data.length === 0 && (
              <NoDataTypography size="400-xs" color="gray-80" align="left">
                <FormattedMessage id="statuses.paid.noData" defaultMessage="Nincs fizetési előzmény." />
              </NoDataTypography>
            )}
          </ContentWithLoadingOverlay>
        )}
        {!fetched && <StyledProgress />}
      </HistoryTableContainer>
    </>
  )
}
