import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Typography, TypographyProps } from '@components/ui/Typography'

import { InnerWrapperDiv, WrapperDiv } from './styles'

interface CenteredPageErrorProps {
  title: TypographyProps['children']
  errorMessage: TypographyProps['children']
}

export function CenteredPageError({ errorMessage, title }: CenteredPageErrorProps) {
  return (
    <WrapperDiv $gap={50}>
      <InnerWrapperDiv $gap={50}>
        <Typography size="heading-3">{title}</Typography>
        <Typography size="400-sm" italic color="error">
          {errorMessage}
        </Typography>
      </InnerWrapperDiv>
      <InnerWrapperDiv $gap={5}>
        <Typography size="700-sm">
          <FormattedMessage id="screen.failure.text" defaultMessage="Kérjük próbáld meg újratölteni az oldalt." />
        </Typography>
        <Typography size="400-sm">
          <FormattedMessage
            id="screen.failure.footer"
            defaultMessage="Amennyiben a probléma továbbra is fennáll kérjük vedd fel velünk a kapcsolatot a hello@riport.app e-mail címen!"
          />
        </Typography>
      </InnerWrapperDiv>
    </WrapperDiv>
  )
}
