import { injectCompanyToURL } from '@helpers/url'

import Api from '../api'
import {
  API_ONBOARDING_CONFIRM,
  API_ONBOARDING_INVITATION_MEMBERS_LIST,
  API_ONBOARDING_INVITATION_REJECT,
  API_ONBOARDING_INVITATION_STATUS,
  API_ONBOARDING_KSZI_CONNECT,
  API_ONBOARDING_SETUP,
} from './urls'

export const getOnboardingInstructions = () => Api.post(API_ONBOARDING_SETUP)

export const confirmOnboarding = () => Api.post(API_ONBOARDING_CONFIRM)

export const connectKszi = data => Api.post(API_ONBOARDING_KSZI_CONNECT, data)

export const getOnboardingInvitationStatus = companyId =>
  Api.get(injectCompanyToURL(API_ONBOARDING_INVITATION_STATUS, companyId))

export const getOnboardingInvitationMembersList = companyId =>
  Api.get(injectCompanyToURL(API_ONBOARDING_INVITATION_MEMBERS_LIST, companyId))

export const declineOnboardingInvitation = (companyId, uuid) =>
  Api.delete(injectCompanyToURL(API_ONBOARDING_INVITATION_REJECT, companyId).replace('{uuid}', uuid))
