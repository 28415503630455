import { defineMessages, IntlShape } from 'react-intl'

import { CheckboxListProps, PaidStatusFilterInnerKeys, PaidStatusFilterKeys } from './types'

export const messages = defineMessages({
  paidLabel: {
    id: 'filters.paidStatus.paidLabel',
    defaultMessage: 'Fizetett',
  },
  expiringLabel: {
    id: 'filters.paidStatus.expiringLabel',
    defaultMessage: 'Esedékes',
  },
  expiringInLabel: {
    id: 'filters.paidStatus.expiringInLabel',
    defaultMessage: 'Esedékes ({fromDay}-{toDay} napon belül)',
  },
  expiringOverLabel: {
    id: 'filters.paidStatus.expiringOverLabel',
    defaultMessage: 'Esedékes ({day} napon túl)',
  },
  expiredLabel: {
    id: 'filters.paidStatus.expiredLabel',
    defaultMessage: 'Lejárt',
  },
  expiredInLabel: {
    id: 'filters.paidStatus.expiredInLabel',
    defaultMessage: 'Lejárt ({fromDay}-{toDay} napja)',
  },
  expiredOverLabel: {
    id: 'filters.paidStatus.expiredOverLabel',
    defaultMessage: 'Lejárt ({day} napon túl)',
  },
})

export function getCheckboxListSelectedOptions(
  fields: CheckboxListProps['fields'],
  values: CheckboxListProps['value']
): string[] {
  const selected: string[] = []
  fields.forEach(({ name, label, inner }) => {
    if (values[name]) {
      if (inner) {
        if (inner && values[inner.name].length === inner.options.length) {
          selected.push(label)
        } else {
          inner.options.forEach(option => {
            if (values[inner.name].includes(option.name)) {
              selected.push(option.label)
            }
          })
        }
      } else {
        selected.push(label)
      }
    }
  })
  return selected
}

export function getPaidStatusFieldConfig(formatMessage: IntlShape['formatMessage']) {
  //! sync with getValidFiltersFromUrl helper's response
  return [
    {
      name: PaidStatusFilterKeys.PAID,
      label: formatMessage(messages.paidLabel),
    },
    {
      name: PaidStatusFilterKeys.EXPIRING,
      label: formatMessage(messages.expiringLabel),
      inner: {
        name: PaidStatusFilterInnerKeys.EXPIRING,
        options: [
          { name: '1_7', label: formatMessage(messages.expiringInLabel, { fromDay: 1, toDay: 7 }) },
          { name: '8_14', label: formatMessage(messages.expiringInLabel, { fromDay: 8, toDay: 14 }) },
          { name: '15_', label: formatMessage(messages.expiringOverLabel, { day: 15 }) },
        ],
      },
    },
    {
      name: PaidStatusFilterKeys.EXPIRED,
      label: formatMessage(messages.expiredLabel),
      inner: {
        name: PaidStatusFilterInnerKeys.EXPIRED,
        options: [
          { name: '0_14', label: formatMessage(messages.expiredInLabel, { fromDay: 0, toDay: 14 }) },
          { name: '15_30', label: formatMessage(messages.expiredInLabel, { fromDay: 15, toDay: 30 }) },
          { name: '31_', label: formatMessage(messages.expiredOverLabel, { day: 31 }) },
        ],
      },
    },
  ]
}
