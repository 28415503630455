import styled from 'styled-components'

import { Typography } from '@components/ui'
import { CustomDialogActions, CustomDialogBody, CustomDialogHeader } from '@components/ui/Dialogs'
import { Table, TableCell, TableHeading, TableRow } from '@components/ui/TableElements'
import { Tab, TabList } from '@components/ui/Tabs'

import { TransactionsTotalAmount } from './TransactionsTotalAmount'

export const PaymentDialogTab = styled(Tab)`
  min-width: 0;
  padding-left: 20px;
  padding-right: 20px;

  &:first-child {
    padding-left: 0;
  }
`

export const PaymentDialogHeader = styled(CustomDialogHeader)`
  padding: 20px 12px 0;

  &:has(${Typography}:last-child) {
    padding-bottom: 20px;
  }

  & ${TabList} {
    margin-top: 20px;
  }
`

export const PaymentDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
  justify-content: unset;
`

export const PaymentDialogBodyWrapper = styled.div`
  padding: 20px 12px;

  ${Table} {
    border: none;
    border-collapse: separate;
    border-radius: 4px;
    tbody tr:last-of-type td {
      border-bottom: none;
    }
  }

  ${TableHeading} {
    padding-top: 9px;
  }

  ${TableHeading}, ${TableCell} {
    padding: 9px 3px 8px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  ${TableRow} {
    vertical-align: top;
  }

  ${TableRow}:focus-within, ${TableRow}:hover {
    box-shadow: none;
  }

  // need to align content in cells with inputs
  ${TableCell} {
    line-height: 28px;
  }
`

export const ExpressPaymentFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;

  &[disabled] {
    & .react-select__control,
    & .react-select__placeholder,
    & .rw-state-disabled .rw-widget-picker,
    & .rw-state-disabled .rw-widget-picker .rw-widget-input,
    & .rw-state-disabled .rw-widget-picker .rw-i-calendar,
    & .MuiInput-root {
      background-color: ${({ theme }) => theme.colors.gray[20]};
      color: ${({ theme }) => theme.colors.gray[130]};
      -webkit-text-fill-color: ${({ theme }) => theme.colors.gray[130]}; /* required on Safari */
      opacity: 1; /* required on iOS */
    }
  }
`

export const PaymentResponseDialogBody = styled(CustomDialogBody)`
  width: 596px;
  min-height: 128px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const SameSizeButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  & button {
    min-width: 110px;
  }

  &:first-child {
    grid-column: 1/2;
  }
  &:last-child {
    grid-column: 2/3;
  }
`

export const StyledTransactionsTotalAmount = styled(TransactionsTotalAmount)`
  margin-top: 24px;
`
