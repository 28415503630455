import { defineMessages } from 'react-intl'

const messages = defineMessages({
  // InvoiceAssignments
  itemRemoveButtonTitle: {
    id: 'form.button.itemRemoveTitle',
    defaultMessage: '{type} törlése',
  },
  itemCopyButtonTitle: {
    id: 'form.button.itemCopyTitle',
    defaultMessage: '{type} másolása',
  },
  itemCostTypeLabel: {
    id: 'form.label.itemCostType',
    defaultMessage: 'Név',
  },
  grossAmountLabel: {
    id: 'form.label.grossAmount',
    defaultMessage: 'Bruttó összeg',
  },
  calculationTooltipGrossAmount: {
    id: 'form.tooltip.calculationTooltipGrossAmount',
    defaultMessage: 'A számítások alapja a bruttó összeg',
  },
  vatRateLabel: {
    id: 'form.label.vatRate',
    defaultMessage: 'ÁFA-kulcs',
  },
  netAmountLabel: {
    id: 'form.label.netAmount',
    defaultMessage: 'Nettó összeg',
  },
  calculationTooltipNetAmount: {
    id: 'form.tooltip.calculationTooltipNetAmount',
    defaultMessage: 'A számítások alapja a nettó összeg',
  },
  newItemButtonText: {
    id: 'form.button.newItem',
    defaultMessage: 'Újabb {type} hozzáadása',
  },
  // DetailsForm
  itemSimpleTagsLabel: {
    id: 'form.label.itemSimpleTagsLabel',
    defaultMessage: 'Címke',
  },
  jobNumberLabel: {
    id: 'form.label.jobNumber',
    defaultMessage: 'Munkaszám',
  },
  partnerNameLabel: {
    id: 'form.label.partnerName',
    defaultMessage: 'Partnertörzs',
  },
  vatAreaLabel: {
    id: 'form.label.vatArea',
    defaultMessage: 'Terület',
  },
  invoiceNumberLabel: {
    id: 'form.label.invoiceNumber',
    defaultMessage: 'Számla sorszáma',
  },
  secondaryIdLabel: {
    id: 'form.label.secondaryId',
    defaultMessage: 'Másodlagos azonosító',
  },
  voucherNumberLabel: {
    id: 'form.label.voucherNumber',
    defaultMessage: 'Bizonylat sorszáma',
  },
  paymentMethodLabel: {
    id: 'form.label.paymentMethod',
    defaultMessage: 'Fizetési forma',
  },
  fulfilledDateLabel: {
    id: 'form.label.fulfilledDate',
    defaultMessage: 'Teljesítés ideje',
  },
  createdDateLabel: {
    id: 'form.label.createdDate',
    defaultMessage: 'Számla kelte',
  },
  deadlineDateLabel: {
    id: 'form.label.deadlineDate',
    defaultMessage: 'Fizetési határidő',
  },
  deliveredAtDateLabel: {
    id: 'form.label.deliveredAtDate',
    defaultMessage: 'Kézhezvétel dátuma',
  },
  reschedulingPaymentLabel: {
    id: 'form.label.reschedulePayment',
    defaultMessage: 'Fizetési határidő átütemezése',
  },
  plannedPaymentDateLabel: {
    id: 'form.label.plannedPaymentDate',
    defaultMessage: 'Tervezett fizetés dátuma',
  },
  currencyLabel: {
    id: 'form.label.currency',
    defaultMessage: 'Devizanem',
  },
  exchangeRateLabel: {
    id: 'form.label.exchangeRate',
    defaultMessage: 'Árfolyam',
  },
  totalCostLabel: {
    id: 'form.label.totalCost',
    defaultMessage: 'Végösszeg',
  },
  saveButtonText: {
    id: 'form.button.save',
    defaultMessage: 'Mentés',
  },
  needsReviewLabel: {
    id: 'form.label.needsReview',
    defaultMessage: 'További könyvelési teendők szükségesek',
  },
  reviewDoneLabel: {
    id: 'form.label.reviewDone',
    defaultMessage: 'Teendő elvégezve?',
  },
  commentLabel: {
    id: 'form.label.comment',
    defaultMessage: 'Megjegyzés',
  },
  ledgerNumberLabel: {
    id: 'form.label.ledgerNumber',
    defaultMessage: 'Kontír',
  },
  savedButtonText: {
    id: 'form.button.saved',
    defaultMessage: 'Elmentve',
  },
  savingText: {
    id: 'form.button.savingText',
    defaultMessage: 'Mentés folyamatban...',
  },
  mixedExpenseType: {
    id: 'form.placeholder.mixedExpenseType',
    defaultMessage: 'Többféle',
  },
  accountingPeriodCheckboxLabel: {
    id: 'form.label.accountingPeriodCheckbox',
    defaultMessage: 'Elszámolási időszak hozzáadása',
  },
  paidThroughLabel: {
    id: 'form.label.paidThrough',
    defaultMessage: 'Paid through',
  },
  smartTagTooltip: {
    id: 'form.smartTagTooltip',
    defaultMessage: 'Az Autocímkéket automatikusan átmásoljuk a partner előző számlájáról',
  },
  accountingEndDateLabel: {
    id: 'form.label.accountingEndDate',
    defaultMessage: 'Elszámolási isz. vége',
  },
  accountingStartDateLabel: {
    id: 'form.label.accountingStartDate',
    defaultMessage: 'Elszámolási isz. kezdete',
  },
  dateFieldPlaceholder: {
    id: 'form.placeholder.dateField',
    defaultMessage: 'éééé-hh-nn',
  },
  itemTagsLabel: {
    id: 'form.label.itemTagsLabel',
    defaultMessage: 'Autocímke',
  },
  exchangeDateLabel: {
    id: 'exchangeRate.label',
    defaultMessage: 'Árfolyam dátum',
  },
  statusChangeDoneInvoice: {
    id: 'form.button.statusChangeDoneInvoice',
    defaultMessage: 'Számla "könyvelt" státuszra állítása',
  },
  statusChangeUndoInvoice: {
    id: 'form.button.statusChangeUndoInvoice',
    defaultMessage: 'Számla "nem könyvelt" státuszra állítása',
  },
  approveUndoInvoice: {
    id: 'form.button.approveUndoInvoice',
    defaultMessage: 'Számla jóváhagyás visszavonása',
  },
  approveDoneInvoice: {
    id: 'form.button.approveDoneInvoice',
    defaultMessage: 'Számla jóváhagyása',
  },
  approveQuarantineInvoice: {
    id: 'form.button.approveQuarantineInvoice',
    defaultMessage: 'A "Jóváhagyás" hiányos számlán nem engedélyezett',
  },
  statusChangeQuarantineInvoice: {
    id: 'form.button.statusChangeQuarantineInvoice',
    defaultMessage: 'A "Könyvelés" hiányos számlán nem engedélyezett',
  },
  revenueAssignmentType: {
    id: 'assignmentType.revenue',
    defaultMessage: 'Bevételtípus',
  },
  expenseAssignmentType: {
    id: 'assignmentType.expense',
    defaultMessage: 'Költségtípus',
  },
  emptyAssignmentLabel: {
    id: 'assignmentType.uncategorized.placeholer',
    defaultMessage: 'Kategorizálatlan',
  },
  formLabelConfirmPassword: {
    id: 'form.label.confirmPassword',
    defaultMessage: 'Jelszó újra',
  },
})

export default messages
