function getName(result: BackendCegjelzoSearchResult): string {
  const shortName = result.short_name
  if (typeof shortName === 'string') {
    return shortName
  }
  if (Array.isArray(shortName) && shortName.length) {
    return shortName[0].name
  }

  const longName = result.long_name
  if (typeof longName === 'string') {
    return longName
  }
  if (Array.isArray(longName) && longName.length) {
    return longName[0].name
  }

  return result.name
}

function getAddress(result: BackendCegjelzoSearchResult): string {
  const address = result.address

  if (typeof address === 'string') {
    return address
  }
  if (Array.isArray(address) && address.length) {
    // NOTE: "székhely" is always the first item
    return address[0].address
  }

  return ''
}

function getTaxNumber(result: BackendCegjelzoSearchResult): string {
  let taxNumber = result.tax_number
  if (Array.isArray(taxNumber) && taxNumber.length) {
    taxNumber = taxNumber[0].tax_number
  }

  if (typeof taxNumber !== 'string') {
    return ''
  }

  return String(taxNumber).replace(/\D/gi, '')
}

// Cegjelzo return mixed active and inactive results
// 0 - inactive, 1 - active, 2 - on hold, temporarily inactive ("szüneteltetett")
export function serializer(results: BackendCegjelzoSearchResult[]): CegjelzoBusinessOption[] {
  return results
    .filter(({ status_code }) => status_code === 1) // status_code: 1 - only use active business list
    .map(result => ({
      name: getName(result),
      address: getAddress(result),
      tax_number: getTaxNumber(result),
    }))
}
