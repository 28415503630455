import React from 'react'

import { FormControl, FormHelperText, Input, InputAdornment, InputProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { formStyles } from '@styles'

export enum AdornmentType {
  end = 'endAdornment',
  start = 'startAdornment',
}

interface CustomFieldBehaviorProps extends InputProps {
  adornmentType?: AdornmentType
  disabled: boolean
  error: boolean
  formattedCurrency?: string
  helperText: string
}

const useStyles = makeStyles(formStyles)

export function CustomFieldBehavior({
  adornmentType,
  disabled,
  error,
  formattedCurrency,
  helperText,
  ...rest
}: CustomFieldBehaviorProps) {
  const classes = useStyles()

  return (
    <FormControl fullWidth error={error} disabled={disabled} className={classes.amountFormControl}>
      <Input
        {...rest}
        disableUnderline
        classes={{
          root: cx(classes.bootstrapRoot, classes.amountRoot),
          input: cx(classes.amountInput, classes[`${adornmentType}Input`]),
          error: classes.amountError,
        }}
        {...(adornmentType &&
          formattedCurrency && {
            [adornmentType]: (
              <InputAdornment
                disableTypography
                position={adornmentType === AdornmentType.start ? 'end' : 'start'}
                className={cx(classes.amountAdornment, {
                  start: adornmentType === AdornmentType.start,
                  end: adornmentType === AdornmentType.end,
                  error,
                })}
              >
                {formattedCurrency}
              </InputAdornment>
            ),
          })}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
