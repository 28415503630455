import React from 'react'

import { FormattedMessage } from 'react-intl'

import { StrongTextValues } from '@helpers'

import { Typography } from '@components/ui'

export const DialogTitleMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.title"
    defaultMessage="Számla duplikáció <highlight>feloldása</highlight>"
    values={{
      highlight: (chunks: string) => (
        <Typography color="orange-50" tag="span">
          {chunks}
        </Typography>
      ),
    }}
  />
)

//* descriptions
export const NavDuplicationDescriptionMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.description.navDuplication"
    defaultMessage="Úgy tűnik, hogy a feltöltött <b>számla adatai megegyeznek egy, a NAV rendszerén keresztül importált számla adataival</b>. Lehetőséged van <b>összekapcsolni a számlát a kijelölt NAV-os párjával</b> és dönthetsz arról, hogy a <b>NAV-tól érkező adatokkal frissítsük-e</b> a számlát."
    values={StrongTextValues}
  />
)

export const DuplicationDescriptionOriginalDeletableMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.description.originalDeletable"
    defaultMessage="Úgy tűnik, hogy ennek az <b>új számlának a sorszáma megegyezik egy korábbival</b>. A duplikáció feloldásához <b>szerkeszd az új számla sorszámát</b>, vagy <b>töröld a korábbi számlát</b>."
    values={StrongTextValues}
  />
)

export const DuplicationDescriptionDefaultMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.description.default"
    defaultMessage="Úgy tűnik, hogy ennek az <b>új számlának a sorszáma megegyezik egy korábbival</b>. A duplikáció feloldásához <b>szerkeszd az új számla sorszámát</b>, vagy <b>töröld valamelyik számlát</b>."
    values={StrongTextValues}
  />
)

export const DuplicationDescriptionDuplicateDeletableMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.description.duplicateDeletable"
    defaultMessage="Úgy tűnik, hogy ennek az <b>új számlának a sorszáma megegyezik egy korábbi, már feldolgozott vagy kifizetett számlával</b>. A duplikáció feloldásához <b>szerkeszd az új számla sorszámát</b>, vagy <b>töröld az új számlát</b>."
    values={StrongTextValues}
  />
)

export const DuplicationDescriptionNotDeletableMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.description.notDeletable"
    defaultMessage="Úgy tűnik, hogy ennek az <b>új számlának a sorszáma megegyezik egy korábbival</b>. A duplikáció nem oldható fel a felületen, mert mindkét számla tartalmaz már olyan adatokat, amelyeket nem enged a rendszer törölni vagy szerkeszteni. A megoldáshoz <b>keresd fel ügyfélszolgálatunkat</b>."
    values={StrongTextValues}
  />
)

//* responses
export const MergeWithUpdateResponseMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.response.mergeWithUpdate"
    defaultMessage="A két számla összekapcsolásra került és a feltöltött számla adatait frissítjük a NAV-tól kapott számla adataival."
  />
)

export const MergeWithoutUpdateResponseMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.response.mergeWithoutUpdate"
    defaultMessage="A két számla összekapcsolásra került, de a feltöltött számla adatait nem frissítjük a NAV-tól kapott számla adataival."
  />
)

export const DeleteOriginalExpenseResponseMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.response.deleteOriginalExpense"
    defaultMessage="A korábbi számlát töröltük a rendszerből."
  />
)

export const DeleteDuplicateExpenseResponseMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.response.deleteNewExpense"
    defaultMessage="Az új számlát töröltük a rendszerből."
  />
)

export const DeleteNavDuplicationResponseMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.response.deleteNavDuplication"
    defaultMessage="A feltöltött számlát nem kapcsoltuk össze egyik NAV-ból importált számlával sem. A NAV számlát megtalálod a hiányos számlák között, ahol a számla részleteinek megtekintésekor dönthetsz arról, hogy szeretnéd-e befogadni és a hiányos adatok szerkesztése után menteni."
  />
)

export const DeleteDuplicationResponseMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.response.deleteDuplication"
    defaultMessage="Az új számláról töröltük a számlaszámot, így már tudod azt szerkeszteni és menteni. A számlát megtalálod a hiányos számlák között."
  />
)

export const InitFailedResponseMessage = (
  <FormattedMessage
    id="dialogs.expenseDuplication.messages.failedInit"
    defaultMessage="A számlák betöltése során hiba lépett fel, kérjük, próbáld újra megnyitni az ablakot."
  />
)
