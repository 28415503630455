import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { usePostIt } from '@components/DocViewer/hooks'
import { IconButton } from '@components/ui/Buttons'
import { ArrowLastLeftIcon, ArrowLastRightIcon, LeftArrowIcon, RightArrowIcon } from '@components/ui/svgIcons'
import { FlexRow } from '@components/ui/Wrappers'
import { LightTooltip } from '@oldComponents/ui'

import { setCurrentPage } from './actions'
import { PaginationInput } from './PaginationInput'
import { PDFControlsContext } from './PDFControlsProvider'

const ButtonWrapperSpan = styled.span`
  display: inline-flex;
`

const PaginationWrapper = styled(FlexRow)`
  align-items: center;
  gap: 8px;
`

const ButtonsWrapper = styled(FlexRow)`
  align-items: inherit;
  gap: 4px;
`

export function PaginationControls() {
  const {
    state: { numPages, currentPage },
    dispatch,
  } = React.useContext(PDFControlsContext)
  const { pageRef } = usePostIt()

  function handlePreviousPage() {
    if (pageRef) {
      pageRef.current = currentPage - 1
    }
    dispatch(setCurrentPage(currentPage - 1))
  }

  function handleNextPage() {
    if (pageRef) {
      pageRef.current = currentPage + 1
    }
    dispatch(setCurrentPage(currentPage + 1))
  }

  function handleFirstPage() {
    if (pageRef) {
      pageRef.current = 1
    }
    dispatch(setCurrentPage(1))
  }

  function handleLastPage() {
    if (pageRef) {
      pageRef.current = numPages
    }
    dispatch(setCurrentPage(numPages))
  }

  function handlePageChange(value: number) {
    if (pageRef) {
      pageRef.current = value
    }
    dispatch(setCurrentPage(value))
  }

  if (numPages <= 1) return null

  return (
    <PaginationWrapper>
      <PaginationInput defaultValue={currentPage} maxValue={numPages} onChange={handlePageChange} />
      <ButtonsWrapper>
        <LightTooltip
          title={<FormattedMessage id="docViewer.fab.firstPage" defaultMessage="Első oldal" />}
          PopperProps={{ placement: 'top' }}
        >
          <ButtonWrapperSpan>
            <IconButton variant="primaryText" size="small" onClick={handleFirstPage} disabled={currentPage === 1}>
              <ArrowLastLeftIcon />
            </IconButton>
          </ButtonWrapperSpan>
        </LightTooltip>
        <LightTooltip
          title={<FormattedMessage id="docViewer.fab.previousPage" defaultMessage="Előző oldal" />}
          PopperProps={{ placement: 'top' }}
        >
          <ButtonWrapperSpan>
            <IconButton variant="primaryText" size="small" onClick={handlePreviousPage} disabled={currentPage === 1}>
              <LeftArrowIcon />
            </IconButton>
          </ButtonWrapperSpan>
        </LightTooltip>
        <LightTooltip
          title={<FormattedMessage id="docViewer.fab.nextPage" defaultMessage="Következő oldal" />}
          PopperProps={{ placement: 'top' }}
        >
          <ButtonWrapperSpan>
            <IconButton variant="primaryText" size="small" onClick={handleNextPage} disabled={currentPage === numPages}>
              <RightArrowIcon />
            </IconButton>
          </ButtonWrapperSpan>
        </LightTooltip>
        <LightTooltip
          title={<FormattedMessage id="docViewer.fab.lastPage" defaultMessage="Utolsó oldal" />}
          PopperProps={{ placement: 'top' }}
        >
          <ButtonWrapperSpan>
            <IconButton variant="primaryText" size="small" onClick={handleLastPage} disabled={currentPage === numPages}>
              <ArrowLastRightIcon />
            </IconButton>
          </ButtonWrapperSpan>
        </LightTooltip>
      </ButtonsWrapper>
    </PaginationWrapper>
  )
}
