import React from 'react'
import PropTypes from 'prop-types'

import { ButtonBase, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'

import { PAGE_FILTER_VARIANTS } from '@components/filters/constants'

import { getCheckboxListSelectedOptions, getPaidStatusFieldConfig } from '../helpers'
import {
  FormPaidStatusFilterProps,
  PaidStatusFilterInnerKeys,
  PaidStatusFilterKeys,
  PaidStatusFilterProps,
  WidgetPaidStatusFilterProps,
} from '../types'
import { CheckboxList } from './CheckboxList'

import { PaidStatusLabelMessage } from '@messages'
import { filterSelectStyles } from '../../styles'
import { formStyles, listStyles } from './styles'

const useStyles = makeStyles(filterSelectStyles)
const useListStyles = makeStyles(listStyles)
const useFormStyles = makeStyles(formStyles)

/**
 * Component to display paid status filter in PageFilterBar
 *
 * @param {WidgetPaidStatusFilterProps} {
 *  disabled (optional),
 *  fields,
 *  onChange,
 *  value
 * }
 */
function WidgetPaidStatusFilter({ disabled = false, fields, onChange, value }: WidgetPaidStatusFilterProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const classes = useStyles()
  const listClasses = useListStyles()

  // display labels when they checked - only display first level label when all second level labels are checked
  const selectedOptions = getCheckboxListSelectedOptions(fields, value)

  const open = Boolean(anchorEl)
  const id = open ? 'paid_status-filter-popover' : undefined

  return (
    <>
      <ButtonBase
        aria-describedby={id}
        className={classes.button}
        disabled={disabled}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
        data-testid="widget-paid-status-filter"
      >
        <span className={classes.buttonLabel}>{PaidStatusLabelMessage}</span>
        <span className={classes.buttonValue}>
          {selectedOptions.join(', ') || (
            <FormattedMessage id="filterBar.searchFilter.searchAllPlaceholder" defaultMessage="Összes" />
          )}
        </span>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        <div className={classes.menuContainer}>
          <CheckboxList classes={listClasses} fields={fields} onChange={onChange} value={value} />
        </div>
      </Popover>
    </>
  )
}

/**
 * Component to display paid status filter in FiltersDialog
 *
 * @param {FormPaidStatusFilterProps} {
 *  fields,
 *  onChange,
 *  value
 * }
 */
function FormPaidStatusFilter({ fields, onChange, value }: FormPaidStatusFilterProps) {
  const formClasses = useFormStyles()
  return <CheckboxList classes={formClasses} fields={fields} onChange={onChange} value={value} />
}

const propTypes = {
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf([PaidStatusFilterKeys.EXPIRED, PaidStatusFilterKeys.EXPIRING, PaidStatusFilterKeys.PAID])
        .isRequired,
      label: PropTypes.string.isRequired,
      inner: PropTypes.shape({
        name: PropTypes.oneOf([PaidStatusFilterInnerKeys.EXPIRED, PaidStatusFilterInnerKeys.EXPIRING]).isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
      }),
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    isPaid: PropTypes.bool.isRequired,
    isExpiring: PropTypes.bool.isRequired,
    isExpired: PropTypes.bool.isRequired,
    expiringDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    expiredDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
}

FormPaidStatusFilter.propTypes = propTypes
WidgetPaidStatusFilter.propTypes = propTypes

export default function PaidStatusFilter({
  disabled = false,
  onChange,
  value,
  variant = PAGE_FILTER_VARIANTS.widget,
}: PaidStatusFilterProps) {
  const { formatMessage } = useIntl()

  // create a render config with translated labels
  const fieldsConfigRef = React.useRef(getPaidStatusFieldConfig(formatMessage))

  if (variant === PAGE_FILTER_VARIANTS.widget) {
    return (
      <WidgetPaidStatusFilter disabled={disabled} fields={fieldsConfigRef.current} onChange={onChange} value={value} />
    )
  }

  return <FormPaidStatusFilter fields={fieldsConfigRef.current} onChange={onChange} value={value} />
}

PaidStatusFilter.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    isPaid: PropTypes.bool.isRequired,
    isExpiring: PropTypes.bool.isRequired,
    isExpired: PropTypes.bool.isRequired,
    expiringDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    expiredDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  variant: PropTypes.oneOf(Object.values(PAGE_FILTER_VARIANTS)),
}
