import moment from 'moment'

import { ALL_PAYMENT_TRANSACTION_TYPES } from '@constants'

export function getToday() {
  return moment.utc().format('YYYY-MM-DD')
}

export function isInvoiceTransactionType(transactionType: AllPaymentTransactionType) {
  return (
    transactionType === ALL_PAYMENT_TRANSACTION_TYPES.expense ||
    transactionType === ALL_PAYMENT_TRANSACTION_TYPES.income
  )
}
