import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'

export const HeadingTypography = styled(Typography).attrs({ size: 'heading-3', align: 'center' })`
  margin-bottom: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`

export const ReasonBlockWrapperDiv = styled.div`
  position: relative;
  padding: 2rem 1rem;
  margin: -1rem -1rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  background-color: ${({ theme }) => theme.colors.gray[0]};
`
