import React from 'react'
import PropTypes from 'prop-types'

import { DialogProps } from '@material-ui/core'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import { paymentActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from '@hooks'

import { EditPaymentDialog } from '@components/PaymentEditor'

import { PaymentDialogState } from '@constants'

import { EditPaymentContext } from './contexts'
import { EditPaymentContextProps, EditPaymentProviderProps, EditPaymentProviderState } from './types'

export function PureEditPaymentProvider({
  loadData,
  children,
  saveData,
  dialogControls: { onClose, onOpen, onSuccess, skipStoreUpdate } = { skipStoreUpdate: false },
}: EditPaymentProviderProps) {
  const [state, setState] = React.useState<EditPaymentProviderState>({
    data: null,
    dialogState: PaymentDialogState.FORM,
    loading: false,
    open: false,
  })
  const cPromiseRef = useCancellablePromiseRef<PaymentDetailsResponseData>()
  const { setErrorAlert } = useAlertDispatch()

  const loadAndEditPayment = React.useCallback<EditPaymentContextProps['loadAndEditPayment']>(
    async paymentId => {
      setState(prevState => ({
        ...prevState,
        dialogState: PaymentDialogState.FORM,
        loading: true,
      }))

      try {
        cPromiseRef.current = cancellablePromise(loadData(paymentId))
        const data = await cPromiseRef.current.promise
        onOpen?.()
        setState(prevState => ({ ...prevState, data, loading: false, open: true }))
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
        setState(prevState => ({ ...prevState, loading: false }))
      }
    },
    [cPromiseRef, loadData, onOpen, setErrorAlert]
  )

  function handleDialogClose() {
    setState(prevState => ({ ...prevState, open: false }))
    // re-open invoice payment history dialog
    onClose?.()
  }

  function onSubmitSuccess() {
    setState(prevState => ({ ...prevState, open: false }))
    // re-init invoice payment history dialog
    onSuccess?.()
  }

  const contextValue = React.useMemo<EditPaymentContextProps>(
    () => ({
      loadAndEditPayment,
      state,
    }),
    [loadAndEditPayment, state]
  )

  return (
    <EditPaymentContext.Provider value={contextValue}>
      {children}
      <EditPaymentDialog
        onClose={handleDialogClose}
        onSubmit={saveData}
        onSubmitSuccess={onSubmitSuccess}
        skipStoreUpdate={skipStoreUpdate}
      />
    </EditPaymentContext.Provider>
  )
}

PureEditPaymentProvider.propTypes = {
  loadData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  dialogControls: PropTypes.shape({
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onSuccess: PropTypes.func,
    skipStoreUpdate: PropTypes.bool,
  }) as React.Validator<EditPaymentProviderProps['dialogControls']>,
  DialogProps: PropTypes.object as React.Validator<Partial<DialogProps> | undefined>,
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return {
    loadData: bindActionToPromise(dispatch, paymentActions.fetchPayment.request),
    saveData: bindActionToPromise(dispatch, paymentActions.updatePayment.request),
  }
}

export const EditPaymentProvider = connect<
  unknown,
  Pick<EditPaymentProviderProps, 'loadData' | 'saveData'>,
  Pick<EditPaymentProviderProps, 'children' | 'dialogControls'>
>(
  null,
  mapDispatchToProps
)(PureEditPaymentProvider)

EditPaymentProvider.displayName = 'EditPaymentProvider'

export const EditPaymentConsumer = EditPaymentContext.Consumer
