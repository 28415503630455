import React from 'react'
import PropTypes from 'prop-types'

import { ButtonBase } from '@components/ui/Buttons'

export interface CollapseControlButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  contentId: string
  isExpanded?: boolean
}

export function CollapseControlButton({ contentId, isExpanded = false, ...rest }: CollapseControlButtonProps) {
  return <ButtonBase aria-expanded={isExpanded} aria-controls={contentId} {...rest} />
}

CollapseControlButton.propTypes = {
  contentId: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
}
