import React from 'react'
import PropTypes from 'prop-types'

import { components as reactSelectComponents, OptionProps } from 'react-select'
import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'

const WrapperDiv = styled.div`
  ${Typography} {
    display: block;
  }
`

// need to customize the recommended option
const CustomSelectOption = reactSelectComponents.Option

interface CegjelzoOptionProps {
  children: React.ReactNode
  data: CegjelzoBusinessOption
}

export function CegjelzoOption({ children, ...rest }: CegjelzoOptionProps) {
  return (
    <CustomSelectOption {...(rest as OptionProps<CegjelzoBusinessOption, false>)}>
      <WrapperDiv>
        <Typography size="700-md" tag="span">
          {rest.data.name}
        </Typography>
        {rest.data.address && (
          <Typography size="400-sm" tag="span">
            {rest.data.address}
          </Typography>
        )}
        {rest.data.tax_number && (
          <Typography size="400-sm" tag="span">
            {rest.data.tax_number}
          </Typography>
        )}
      </WrapperDiv>
    </CustomSelectOption>
  )
}

CegjelzoOption.propTypes = {
  children: PropTypes.node,
}
