import React from 'react'

import { useLocation } from 'react-router'
import { useTheme } from 'styled-components'

import { COMPACT_NAVBAR_ID } from '@constants'

const DEFAULT_VISIBLE = false

export function useCompactNavbarVisible() {
  const [visible, setVisible] = React.useState(DEFAULT_VISIBLE)
  const theme = useTheme()
  const location = useLocation()

  React.useLayoutEffect(() => {
    function scrollDetector() {
      if (document.getElementById(COMPACT_NAVBAR_ID)) {
        const doc = document.documentElement
        const top = (window.scrollY || doc.scrollTop) - (doc.clientTop || 0)
        setVisible(top > 2 * theme.appBarHeight - theme.appBarHeightMedium)
      }
    }
    window.addEventListener('scroll', scrollDetector)
    return () => {
      window.removeEventListener('scroll', scrollDetector)
    }
  }, [theme.appBarHeight, theme.appBarHeightMedium])

  React.useEffect(() => {
    // reset value when change location
    setVisible(DEFAULT_VISIBLE)
  }, [location.pathname])

  return visible
}
