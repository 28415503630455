// TODO: move to components

import React, { Component } from 'react'
import { any, arrayOf, bool, func, object, shape, string } from 'prop-types'

import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core'
import cx from 'classnames'
import { defineMessages } from 'react-intl'
// import CreatableSelect from 'react-select/lib/Creatable'
import Select, { components as reactSelectComponents } from 'react-select'

import { selectFormStyles } from '@styles'

export const recommendationMessages = defineMessages({
  recommendationsLabel: {
    id: 'recommendations.recommendationsLabel',
    defaultMessage: 'QUiCK ajánlás',
  },
  optionsLabel: {
    id: 'recommendations.optionsLabel',
    defaultMessage: 'Létező keresési találatok',
  },
  newOptionPrefix: {
    id: 'recommendations.newOptionPrefix',
    defaultMessage: '(Új)',
  },
})

// customize react-select Input component to avoid hidden state (will set opacity 0 on input)
// eslint-disable-next-line react/prop-types
const SelectInput = ({ isHidden, ...props }) => {
  return <reactSelectComponents.Input {...props} />
}

export class RecommendationSelect extends Component {
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  handleInputChange = (value, { action }) => {
    switch (action) {
      case 'input-change':
        this.props.input.onChange(value === '' ? null : value)
        break
      default:
    }
  }

  handleChange = (option, { action }) => {
    switch (action) {
      case 'clear':
      case 'remove-value':
        this.props.input.onChange(null)
        break
      case 'select-option':
        this.props.input.onChange(option.value)
        break
      default:
    }
  }

  handleBlur = () => {
    const { onBlur, value } = this.props.input
    // directly can't call onBlur cause it will trigger a component update
    // what will break the react-select inputBlur event and won't clear
    // the focusedValue from its state
    this.timeout = setTimeout(onBlur, 100, value)
  }

  getValue = () => {
    const { value } = this.props.input
    return value ? { label: value, value } : null
  }

  render() {
    const {
      input: { value, ...inputProps },
      options,
      className,
      disabled,
    } = this.props

    return (
      <Select
        classNamePrefix="react-select"
        {...inputProps}
        inputValue={value}
        onInputChange={this.handleInputChange}
        value={this.getValue()}
        options={options}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        noOptionsMessage={() => null}
        styles={selectFormStyles}
        className={className}
        placeholder={null}
        components={{
          Input: SelectInput,
        }}
        isClearable
        isDisabled={disabled}
      />
    )
  }
}
RecommendationSelect.propTypes = {
  options: arrayOf(object).isRequired,
  className: string,
  input: shape({
    value: any.isRequired,
  }).isRequired,
  disabled: bool,
}

export const renderRecommendationTextField = ({
  classes,
  label,
  labelClassName,
  meta: { touched, error },
  options,
  input,
  autoFocus,
  disabled,
  positioner,
}) => {
  const hasError = touched && Boolean(error)
  const hasRecommendations = options.length > 0

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(classes.selectRoot, 'form-control')}
      error={hasError}
      disabled={disabled}
    >
      {label && (
        <InputLabel shrink className={cx(classes.bootstrapFormLabel, labelClassName)}>
          {label}
        </InputLabel>
      )}
      <div className={classes.selectInput}>
        {hasRecommendations ? (
          <RecommendationSelect
            options={options}
            input={input}
            className={cx({ error: hasError })}
            disabled={disabled}
          />
        ) : (
          <Input
            id={input.name}
            fullWidth
            disableUnderline
            classes={{
              root: classes.bootstrapRoot,
              input: classes.bootstrapInput,
              error: classes.bootstrapError,
            }}
            autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
            {...input}
            onChange={event => {
              input.onChange(event)
              if (typeof positioner === 'function') {
                positioner(event, event.target.value, input.value)
              }
            }}
            disabled={disabled}
          />
        )}
      </div>
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

renderRecommendationTextField.defaultProps = {
  options: [],
}

renderRecommendationTextField.propTypes = {
  classes: object.isRequired,
  labelClassName: string,
  input: object.isRequired,
  label: string.isRequired,
  meta: shape({
    touched: bool,
    error: string,
  }),
  options: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ),
  positioner: func,
}
