import React from 'react'

import { FormattedMessage } from 'react-intl'

export const getItemName = (name: string | undefined, idx: number) => {
  if (name) {
    return name
  } else {
    return (
      <FormattedMessage
        id="nav.preview.expenseTypePlaceholder"
        defaultMessage="Kiadás {id}"
        values={{
          id: idx + 1,
        }}
      />
    )
  }
}

export const formatVatPercent = (percent: string | null | undefined | number) => {
  if (typeof percent === 'number') {
    return `${percent}%`
  }
  return '-'
}
