import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { BadgeInfo, getCompanyUserBadge } from '@oldComponents/ui/badges/helpers'

import { Typography } from '@components/ui'
import OverflowTextWithTooltip from '@oldComponents/OverflowTextWithTooltip'
import { UserBadge } from '@oldComponents/ui'

import { generateMinWidthBreakPoint } from '@constants'

const StyledOverFlowTextWithTooltip = styled(OverflowTextWithTooltip)<{ $withBadge?: boolean }>`
  max-width: ${({ $withBadge }) => ($withBadge ? '139px' : '175px')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;

  ${generateMinWidthBreakPoint('large')} {
    max-width: ${({ $withBadge }) => ($withBadge ? '229px' : '265px')};
  }
`

interface CompanyNameWithBadgeProps {
  companyName: string | undefined
  badgeInfo: BadgeInfo
}

function PureCompanyNameWithBadge({ companyName, badgeInfo: { badge } }: CompanyNameWithBadgeProps) {
  const companyNameComponent = companyName ? (
    <Typography size="700-sm" tag="span">
      <StyledOverFlowTextWithTooltip $withBadge={Boolean(badge)} disablePortal={false}>
        {companyName}
      </StyledOverFlowTextWithTooltip>
    </Typography>
  ) : (
    <Typography size="700-sm">
      <FormattedMessage id="navLink.profile" defaultMessage="Profil" />
    </Typography>
  )

  return (
    <>
      <UserBadge badge={badge} />
      {companyNameComponent}
    </>
  )
}

PureCompanyNameWithBadge.propTypes = {
  companyName: PropTypes.string,
  badgeInfo: PropTypes.shape({
    badge: PropTypes.number,
  }).isRequired as React.Validator<CompanyNameWithBadgeProps['badgeInfo']>,
}

export const CompanyNameWithBadge = connect((state: Store) => ({
  companyName: state.auth.company.data?.name,
  badgeInfo: getCompanyUserBadge(state),
}))(PureCompanyNameWithBadge)

CompanyNameWithBadge.displayName = 'CompanyNameWithBadge'
