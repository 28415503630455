import React from 'react'

import { FormattedMessage } from 'react-intl'

export const GoToQuickButtonMessage = (
  <FormattedMessage id="dialogs.subscribePayment.buttons.finish" defaultMessage="Irány a QUiCK" />
)

export const CloseButtonMessage = (
  <FormattedMessage id="dialogs.subscribePayment.buttons.close" defaultMessage="Másik csomagot választok" />
)

export const FinishButtonMessage = (
  <FormattedMessage id="dialogs.subscribePayment.buttons.finishOnboarding" defaultMessage="Tovább" />
)

export const SubscriptionChangeMoreInfoMessage = (
  <FormattedMessage id="dialogs.subscription.change.moreInfo" defaultMessage="A csomagváltásról bővebben" />
)
