export function cancellablePromise<Results>(promise: Promise<Results>) {
  let isCanceled = false
  const wrappedPromise = new Promise<Results>((resolve, reject) => {
    promise
      .then(val => (isCanceled ? reject({ isCanceled }) : resolve(val)))
      .catch(error => (isCanceled ? reject({ isCanceled }) : reject(error)))
  })
  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true
    },
  }
}
