import React from 'react'
import { arrayOf, shape, string } from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: 10,
    fontSize: 12,
    lineHeight: 1.4,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,

    '& > strong': {
      flexBasis: '100%',
    },
  },
  badge: {
    padding: '2px 5px',
    borderRadius: 4,
    backgroundColor: theme.palette.grayscale.xxxLight,
  },
}))

export default function PartnerAlternateNames({ data }) {
  const { alternate_names: names = [] } = data
  const classes = useStyles()

  if (!names.length) {
    return null
  }

  return (
    <Typography className={classes.text} variant="caption" component="p" align="left">
      <strong>
        <FormattedMessage
          id="partner.alternateNames"
          defaultMessage="Egyéb nevek, amelyeken ez a partner állított ki számlákat:"
        />
      </strong>
      {names.map((name, idx) => (
        <span key={idx} className={classes.badge}>
          {name}
        </span>
      ))}
    </Typography>
  )
}

PartnerAlternateNames.propTypes = {
  data: shape({
    alternate_names: arrayOf(string).isRequired,
  }).isRequired,
}
