import { defineMessages } from 'react-intl'

export const memberMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.member.titleText',
    defaultMessage: 'Biztos vagy benne?',
  },
  submitButtonText: {
    id: 'dialogs.settingsBlock.member.submitButtonText',
    defaultMessage: 'Igen, töröljük a tagot',
  },
  contentText: {
    id: 'dialogs.settingsBlock.member.contentText',
    defaultMessage:
      '{email} tag eltávolításával az adatok többé nem lesznek elérhetők számára. A törlésről nem értesítjük. Biztosan ezt szeretnéd?',
  },
  successText: {
    id: 'dialogs.settingsBlock.member.successText',
    defaultMessage: 'Sikeresen eltávolítottad {email} tagot a(z) {company} cégből.',
  },
})

export const membershipMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.membership.titleText',
    defaultMessage: 'Biztos vagy benne?',
  },
  submitButtonText: {
    id: 'dialogs.settingsBlock.membership.submitButtonText',
    defaultMessage: 'Igen, töröljük a tagságom',
  },
  contentText: {
    id: 'dialogs.settingsBlock.membership.contentText',
    defaultMessage:
      'Tagságod törlésével többé nem fogsz tudni hozzáférni a(z) {company} cég adataihoz. Biztos, hogy ezt szeretnéd?',
  },
  successText: {
    id: 'dialogs.settingsBlock.membership.successText',
    defaultMessage: 'Sikeresen eltávolítottad a(z) {company} céges tagságodat.',
  },
})

export const companyMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.company.titleText',
    defaultMessage: 'Biztosan törlöd a céget?',
  },
  submitButtonText: {
    id: 'dialogs.settingsBlock.company.submitButtonText',
    defaultMessage: 'Igen, töröljük a céget',
  },
  contentText: {
    id: 'dialogs.settingsBlock.company.contentText',
    defaultMessage: 'A cég törlésével az adatok minden tag számára elvesznek. Biztosan ezt szeretnéd?',
  },
  successText: {
    id: 'dialogs.settingsBlock.company.successText',
    defaultMessage: 'Sikeresen eltávolítottad a(z) {company} céget.',
  },
})

export const accountMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.account.titleText',
    defaultMessage: 'Biztosan törlöd a fiókodat?',
  },
  submitButtonText: {
    id: 'dialogs.settingsBlock.account.submitButtonText',
    defaultMessage: 'Igen, töröljük a fiókot',
  },
  contentText: {
    id: 'dialogs.settingsBlock.account.contentText',
    defaultMessage:
      'A törlési kérelem indítását követő napon minden adatodat töröljük a rendszerünkből. Amennyiben a törlés megtörténik, a folyamat nem visszafordítható! Biztosan ezt szeretnéd?',
  },
})

export const companyVatMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.companyVat.titleText',
    defaultMessage: 'Biztos vagy benne?',
  },
  submitButtonText: {
    id: 'dialogs.settingsBlock.companyVat.submitButtonText',
    defaultMessage: 'Igen, töröljük',
  },
  contentText: {
    id: 'dialogs.settingsBlock.companyVat.contentText',
    defaultMessage: 'Biztosan eltávolítod a(z) "{company_vat}" ÁFA-típust?',
  },
  successText: {
    id: 'dialogs.settingsBlock.companyVat.successText',
    defaultMessage: 'Sikeresen eltávolítottad a(z) "{company_vat}" ÁFA-típust.',
  },
})

export const apiKeyMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.apiKey.titleText',
    defaultMessage: 'Biztos vagy benne?',
  },
  submitButtonText: {
    id: 'dialogs.settingsBlock.apiKey.submitButtonText',
    defaultMessage: 'Igen, töröljük',
  },
  contentText: {
    id: 'dialogs.settingsBlock.apiKey.contentText',
    defaultMessage: 'Biztosan eltávolítod a(z) "{company_vat}" API-kulcsot?',
  },
  successText: {
    id: 'dialogs.settingsBlock.apiKey.successText',
    defaultMessage: 'Sikeresen eltávolítottad a(z) "{company_vat}" API-kulcsot.',
  },
})

export const inviteMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.invite.titleText',
    defaultMessage: 'Biztos vagy benne?',
  },
  submitButtonText: {
    id: 'dialogs.settingsBlock.invite.submitButtonText',
    defaultMessage: 'Igen, elutasítom',
  },
  contentText: {
    id: 'dialogs.settingsBlock.invite.contentText',
    defaultMessage:
      'Amennyiben elutasítod a(z) {email} tag hozzáférését a céges fiókodhoz, semmilyen adat nem lesz elérhető a számára. Az elutasításról nem értesítjük. Biztosan ezt szeretnéd?',
  },
  successText: {
    id: 'dialogs.settingsBlock.invite.successText',
    defaultMessage: 'Sikeresen eltávolítottad {email} tagot a(z) {company} cégből.',
  },
})

export const notDeletableAccountMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.notDeletableAccount.titleText',
    defaultMessage: 'Fiókod nem törölhető',
  },
  contentText: {
    id: 'dialogs.settingsBlock.notDeletableAccount.contentText',
    defaultMessage:
      'Egyéni vállalkozóként a QUiCK fiókodat a Számlázz.hu-n keresztül hoztad létre, fiókodat ott tudod törölni.',
  },
})

export const notDeletableCompanyMessages = defineMessages({
  titleText: {
    id: 'dialogs.settingsBlock.notDeletableCompany.titleText',
    defaultMessage: 'Ügyfélszolgálatunk szívesen segít!',
  },
  contentText: {
    id: 'dialogs.settingsBlock.notDeletableCompany.contentText',
    defaultMessage:
      'Mivel egyéni vállalkozóként a QUiCK keret- és adófigyelő szolgáltatására a Számlázz.hu felületén fizettél elő, ezért a fiókod törlésében ügyfélszolgálatunk tud segíteni.',
  },
})
