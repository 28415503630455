import React from 'react'
import { bool, func } from 'prop-types'

import { Button, ButtonProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import cx from 'classnames'

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 0,
    minHeight: 0,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grayscale.xLight,
    borderRadius: '50%',
    padding: 4,
    '&:hover': {
      backgroundColor: theme.palette.grayscale.light,
      color: theme.palette.common.white,
    },
  },
  absolutePositioned: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}))

interface DialogCloseButtonProps extends ButtonProps {
  absolutePositioned?: boolean
}

export default function DialogCloseButton({ absolutePositioned = false, className, ...rest }: DialogCloseButtonProps) {
  const { absolutePositioned: absolutePositionedClassName, ...classes } = useStyles()
  return (
    <Button
      classes={classes}
      {...rest}
      className={cx(className, { [absolutePositionedClassName]: absolutePositioned })}
    >
      <ClearIcon style={{ fontSize: 18 }} />
    </Button>
  )
}

DialogCloseButton.propTypes = {
  absolutePositioned: bool,
  onClick: func.isRequired,
}
