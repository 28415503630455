import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { isVisibleForAccountantOrAdmin } from '@helpers'
import { permissionDeniedForUser } from '@permissions/helpers'

import { COMPANY_USER_PERMISSIONS, SUBSCRIPTION_PLANS } from '@constants'

interface BaseDeleteFunctionVisibilityControlProps {
  children: React.ReactNode
  isDeleteAllowed: boolean
}

function BaseDeleteFunctionVisibilityControl({ children, isDeleteAllowed }: BaseDeleteFunctionVisibilityControlProps) {
  return isDeleteAllowed ? <>{children}</> : null
}

BaseDeleteFunctionVisibilityControl.propTypes = {
  children: PropTypes.node.isRequired,
  isDeleteAllowed: PropTypes.bool.isRequired,
}

export const DeleteFunctionVisibilityControl = connect((state: Store) => ({
  isDeleteAllowed: !permissionDeniedForUser(state, COMPANY_USER_PERMISSIONS.delete),
}))(BaseDeleteFunctionVisibilityControl)

DeleteFunctionVisibilityControl.displayName = 'DeleteFunctionVisibilityControl'

interface BaseAccountingButtonVisibilityControlProps {
  children: React.ReactNode
  company: Company
}

/**
 * A könyvelő role-nak mindig ott a könyvelés gomb - csomagtól függetlenül
 * Az admin alapból nem látja a könyvelés gombot, de ha akarja, bekapcsolhatja magának - csomagtól függetlenül
 */
function BaseAccountingButtonVisibilityControl({ children, company }: BaseAccountingButtonVisibilityControlProps) {
  if (isVisibleForAccountantOrAdmin(company)) {
    return <>{children}</>
  }
  return null
}

BaseAccountingButtonVisibilityControl.propTypes = {
  children: PropTypes.node.isRequired,
  company: PropTypes.shape({
    role: PropTypes.string.isRequired,
    subscription: PropTypes.shape({
      plan: PropTypes.shape({
        tier: PropTypes.oneOf(SUBSCRIPTION_PLANS).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired as React.Validator<BaseAccountingButtonVisibilityControlProps['company']>,
}

export const AccountingButtonVisibilityControl = connect((state: Store) => ({
  company: state.auth.company.data,
}))(BaseAccountingButtonVisibilityControl)

AccountingButtonVisibilityControl.displayName = 'AccountingButtonVisibilityControl'
