import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { reportsActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { CenteredPageError, CenteredPageLoader } from '@components/ui'

import { BackendReportResponse } from '../types'
import { useFetchReports } from '../useFetchReports'
import { ReportsContainer } from './ReportsContainer'

import { PageTitleTypography } from './styles'

interface PurePulsePageContentProps {
  fetchReports: AsyncFunction<void, BackendReportResponse>
}

function PurePulsePageContent({ fetchReports }: PurePulsePageContentProps) {
  const { reports, loading, error, refetch, updatedAt } = useFetchReports(fetchReports)

  if (loading) {
    return (
      <CenteredPageLoader>
        <FormattedMessage id="screen.pulse.loading" defaultMessage="Adatok betöltése folyamatban..." />
      </CenteredPageLoader>
    )
  }

  if (error) {
    return (
      <CenteredPageError
        title={
          <FormattedMessage id="screen.pulse.failure" defaultMessage="Sajnáljuk! Az adatok betöltése sikertelen." />
        }
        errorMessage={error}
      />
    )
  }

  return (
    <>
      <PageTitleTypography>
        <FormattedMessage id="pages.pulse.title" defaultMessage="Helló, nézzük a legfontosabbakat!" />
      </PageTitleTypography>
      <ReportsContainer reports={reports} refetch={refetch} updatedAt={updatedAt} />
    </>
  )
}

PurePulsePageContent.propTypes = {
  fetchReports: PropTypes.func.isRequired,
}

export const PulsePageContent = connect(null, dispatch => ({
  fetchReports: bindActionToPromise(dispatch, reportsActions.fetchReports.request),
}))(PurePulsePageContent)

PulsePageContent.displayName = 'PulsePageContent'
