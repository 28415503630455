import { Checkbox, FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const CustomCheckbox = withStyles((theme: any) => ({
  root: {
    height: 34,
    width: 34,
    transform: 'translate(0, -4px)',
    margin: '-4px 8px 0 4px',
  },
  checked: {
    // blue theme
    color: theme.palette.tertiary.blue,
  },
  colorPrimary: {
    '&$disabled': { opacity: 0.3 },
  },
  colorSecondary: {
    // purple theme
    '&$checked': { color: theme.palette.tertiary.purple },
  },
  disabled: {},
}))(Checkbox)

export const CustomFormControlLabel = withStyles(() => ({
  root: {
    alignItems: 'flex-start',
    paddingTop: 6,
    marginBottom: 5,
  },
  label: {},
}))(FormControlLabel)
