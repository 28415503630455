import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { commonActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from '@hooks'

// circ.dep. issue: keep imports separated
import { Button, ButtonWithIcon } from '@components/ui/Buttons'
import { AddCircleIcon } from '@components/ui/svgIcons'
import { Typography } from '@components/ui/Typography'

import { CompanyInactivePeriodDeleteConfirmDialog, CompanyInactivePeriodEditorDialog } from './elements'
import { InactivePeriodBadge } from './InactivePeriodBadge'
import { CompanyInactivePeriodsProps, EditorMode, EditorState } from './types'

import { AddNewButtonMessage, DeleteButtonMessage, EditButtonMessage } from '@messages'

const StyledList = styled.ul`
  margin: 12px 0 0;
  padding: 0;
  list-style: none;
`

const CreateButtonWrapperDiv = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[30]}};
  margin-top: 16px;
  padding-top: 12px;
  max-width: 742px;
`

type StateProps = {
  open: boolean
  initialValues: { startDate: string; endDate: string }
  mode: EditorMode
  methodUrl?: BackendCallableLink
  deleteLoading: boolean
}
type ReducerActions =
  | { type: 'CREATE' }
  | { type: 'EDIT'; payload: Pick<StateProps, 'initialValues' | 'methodUrl'> }
  | { type: 'DELETE'; payload: Pick<StateProps, 'methodUrl'> }
  | { type: 'CLOSE' }
  | { type: 'DELETE_REQUESTED' }

const INITIAL_VALUES = { startDate: '', endDate: '' }

function PureCompanyInactivePeriods({ callUrl, data: { items, links }, refetch }: CompanyInactivePeriodsProps) {
  const { setErrorAlert } = useAlertDispatch()
  const [{ open, initialValues, mode, methodUrl, deleteLoading }, dispatch] = React.useReducer(
    function reducer(state: StateProps, action: ReducerActions) {
      switch (action.type) {
        case 'CREATE': {
          return {
            ...state,
            open: true,
            mode: EditorMode.CREATE,
            initialValues: INITIAL_VALUES,
            methodUrl: links.create,
          }
        }
        case 'EDIT': {
          return {
            ...state,
            open: true,
            mode: EditorMode.EDIT,
            ...action.payload,
          }
        }
        case 'DELETE': {
          return {
            ...state,
            open: true,
            mode: EditorMode.DELETE,
            ...action.payload,
          }
        }
        case 'DELETE_REQUESTED': {
          return {
            ...state,
            deleteLoading: true,
          }
        }
        case 'CLOSE': {
          return {
            ...state,
            deleteLoading: false,
            open: false,
          }
        }
        default:
          return state
      }
    },
    {
      open: false,
      initialValues: INITIAL_VALUES,
      mode: EditorMode.CREATE,
      methodUrl: undefined,
      deleteLoading: false,
    }
  )
  const cancellablePromiseRef = useCancellablePromiseRef()

  function handleCreatePeriod() {
    dispatch({ type: 'CREATE' })
  }

  function handleEdit(editorData: BackendCompanyInactivePeriod) {
    return function handler() {
      dispatch({
        type: 'EDIT',
        payload: {
          initialValues: { startDate: editorData.startDate, endDate: editorData.endDate ?? '' },
          methodUrl: editorData.links.update,
        },
      })
    }
  }

  function handleDeleteConfirm(editorData: BackendCompanyInactivePeriod) {
    return function handler() {
      dispatch({
        type: 'DELETE',
        payload: { methodUrl: editorData.links.delete },
      })
    }
  }

  async function handleDelete() {
    dispatch({ type: 'DELETE_REQUESTED' })
    if (methodUrl) {
      try {
        cancellablePromiseRef.current = cancellablePromise(
          callUrl({
            method: 'delete',
            url: methodUrl,
          })
        )
        await cancellablePromiseRef.current.promise
        // close dialog
        dispatch({ type: 'CLOSE' })
        refetch()
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          setErrorAlert(errorMsg)
        }
      }
    }
    dispatch({ type: 'CLOSE' })
  }

  function handleDialogClose() {
    dispatch({ type: 'CLOSE' })
  }

  async function handleFormSubmit({ startDate, endDate }: EditorState['initialValues']) {
    if (methodUrl) {
      try {
        cancellablePromiseRef.current = cancellablePromise(
          callUrl({
            method: 'post',
            isFormError: true,
            data: {
              startDate,
              endDate: endDate || null, // do not send empty string to server
            },
            url: methodUrl,
          })
        )
        await cancellablePromiseRef.current.promise
        // close dialog
        dispatch({ type: 'CLOSE' })
        refetch()
      } catch (error) {
        // API error handling already taken care of in saga
        return error
      }
    }
  }

  return (
    <div>
      <StyledList>
        {items.length === 0 && (
          <li>
            <Typography color="gray-70" size="400-xs" italic>
              <FormattedMessage
                id="company.inactivePeriods.emptyListText"
                defaultMessage="Nem adtál még hozzá szünetet."
              />
            </Typography>
          </li>
        )}
        {items.map(item => (
          <li key={`company-inactive-period-${item.id}`}>
            <InactivePeriodBadge startDate={item.startDate} endDate={item.endDate} />
            {item.links.update && (
              <Button variant="primaryText" onClick={handleEdit(item)}>
                {EditButtonMessage}
              </Button>
            )}
            {item.links.delete && <Button onClick={handleDeleteConfirm(item)}>{DeleteButtonMessage}</Button>}
          </li>
        ))}
      </StyledList>

      {links.create && (
        <CreateButtonWrapperDiv>
          <ButtonWithIcon onClick={handleCreatePeriod} icon={<AddCircleIcon />} variant="primaryText">
            {AddNewButtonMessage}
          </ButtonWithIcon>
        </CreateButtonWrapperDiv>
      )}

      <CompanyInactivePeriodEditorDialog
        open={open && mode !== EditorMode.DELETE}
        onClose={handleDialogClose}
        initialValues={initialValues}
        isEdit={mode === EditorMode.EDIT}
        onSubmit={handleFormSubmit}
      />
      <CompanyInactivePeriodDeleteConfirmDialog
        open={open && mode === EditorMode.DELETE}
        onClose={handleDialogClose}
        onDelete={handleDelete}
        loading={deleteLoading}
      />
    </div>
  )
}

export const CompanyInactivePeriods = connect(null, dispatch => ({
  callUrl: bindActionToPromise(dispatch, commonActions.callUrl.request),
}))(PureCompanyInactivePeriods)

CompanyInactivePeriods.displayName = 'CompanyInactivePeriods'
