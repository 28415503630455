export enum MenuSizingOptions {
  SMALL = 'small',
  NORMAL = 'normal',
}

export const MENU_SIZES: Record<MenuSizingOptions, number> = {
  [MenuSizingOptions.SMALL]: 40,
  [MenuSizingOptions.NORMAL]: 50,
}

export enum NavLinkTooltipVariant {
  COMPANY = 'company',
  INVOICE = 'invoice',
  SELECT = 'select',
  SUBSCRIPTION = 'subscription',
  UPSELL = 'upsell',
  UPSELL_MULTIBOX = 'upsell-multibox',
}
