import { MessageDescriptor } from 'react-intl'

export enum SettingsDialogStates {
  COMPANY = 'company',
  ACCOUNT = 'account',
  MEMBERSHIP = 'membership',
  COMPANY_VAT = 'companyVat',
  API_KEY = 'apiKey',
  INVITE = 'invite',
  MEMBER = 'member',
  DONE = 'done',
  // new states to control EV company delete restriction
  ACCOUNT_NOT_DELETABLE = 'accountNotDeletable',
  COMPANY_NOT_DELETABLE = 'companyNotDeletable',
}

export type TranslationMessages = {
  titleText: MessageDescriptor
  submitButtonText?: MessageDescriptor
  contentText: MessageDescriptor
  successText?: MessageDescriptor
}

export type SettingsProviderCompany = Company | UserCompany

export type SettingsDialogStateProps = {
  open: boolean
  company: Nullable<SettingsProviderCompany>
  loading: boolean
  data: any // TODO
  error: Nullable<string>
  success: boolean
  dialogState: Nullable<SettingsDialogStates> // hold dialog type (one of [member, membership, company])
  translations: Nullable<TranslationMessages> // hold dialog type specific messages
}

// TODO finish
type SubmitHandler = AsyncFunction<
  { company: SettingsProviderCompany; data: SettingsDialogStateProps['data'] },
  unknown
>

// TODO fix - not every SubmitHandler request the same payload
export interface SettingsDialogProviderProps {
  callDeclineCompanyInvite: SubmitHandler
  callLogout: VoidFunction
  callRemoveAccount: AsyncFunction<{ data: User }>
  callRemoveApiKey: SubmitHandler
  callRemoveCompany: AsyncFunction<{ company: SettingsProviderCompany }, unknown>
  callRemoveCompanySuccess: (companyId: number) => void
  callRemoveCompanyMember: SubmitHandler
  callRemoveCompanyMembership: SubmitHandler
  callRemoveCompanyVat: SubmitHandler
  children: React.ReactNode
  companies: CompaniesItem[]
  currentCompany: Company
  currentUser: User
}

export interface SettingsDialogContextProps {
  openWithData: (company: SettingsProviderCompany, data: any, dialogState: SettingsDialogStates) => VoidFunction
  openCompanyDelete: VoidFunction
  openAccountDelete: VoidFunction
}
