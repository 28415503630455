import React from 'react'

import styled from 'styled-components'

function BaseLiabilityWriteOffRegularIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18 3.25c1.379 0 2.5 1.121 2.5 2.5v12.5c0 1.379-1.121 2.5-2.5 2.5H5.5a2.502 2.502 0 0 1-2.5-2.5V5.75c0-1.379 1.121-2.5 2.5-2.5H18Zm.625 2.5A.627.627 0 0 0 18 5.125h-8V9h.75a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25H10v7.875h8a.627.627 0 0 0 .625-.625V11h-.375a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h.375V5.75ZM5.5 5.125H8v13.75H5.5a.627.627 0 0 1-.625-.625V5.75c0-.344.281-.625.625-.625ZM14 10.75a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v1.5ZM16.75 9a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const LiabilityWriteOffRegularIcon = styled(BaseLiabilityWriteOffRegularIcon)``
