import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { InformationListAccordion } from '@components/ui'

const StyledInformationListAccordion = styled(InformationListAccordion)`
  /* it must have the margin here to avoid everything having a wrapper div */
  margin-top: 24px;
`

interface TaxationLimitInformationProps {
  children: React.ReactChild | Nullable<React.ReactChild>[]
}

/**
 * Simple listing component for taxation detail information
 *
 * @param {TaxationLimitInformationProps} { children } elements to be wrapped in a <li> tag
 */
export function TaxationLimitInformation({ children }: TaxationLimitInformationProps) {
  return (
    <StyledInformationListAccordion
      title={<FormattedMessage id="taxation.information.title" defaultMessage="Hogyan számoltuk ki?" />}
      titleTag="h2"
      visibleOnRender
    >
      {children}
    </StyledInformationListAccordion>
  )
}

TaxationLimitInformation.propTypes = {
  children: PropTypes.node.isRequired,
}
