import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { SimpleDialog } from '@components/ui'

interface InvoiceSubmitConfirmDialogProps {
  dateValue?: Nullable<string>
  onClose: VoidFunction
  open: boolean
}

export function InvoiceSubmitConfirmDialog({ dateValue, onClose, open }: InvoiceSubmitConfirmDialogProps) {
  return (
    <SimpleDialog
      className="gap-3x"
      open={open}
      onClose={onClose}
      description={
        <FormattedMessage
          id="dialogs.invoiceSubmitConfirm.description"
          defaultMessage="A számla teljesítési dátuma {fulfilled_at}. Nem biztos, hogy hibáztál, de a rögzítés után ez a számla nem fog látszani. Persze nem tűnt el. Akkor fogod újra látni, ha majd módosítod a dátumszűrő jelenlegi beállításait a dashboardon. " // eslint-disable-line max-len
          values={{
            fulfilled_at: dateValue,
          }}
        />
      }
      title={<FormattedMessage id="dialogs.invoiceSubmitConfirm.title" defaultMessage="Figyelem! Biztos jó a dátum?" />}
      onPrimaryAction={onClose}
      primaryActionText={
        <FormattedMessage id="dialogs.invoiceSubmitConfirm.buttons.confirm" defaultMessage="Ok, értem" />
      }
    />
  )
}

InvoiceSubmitConfirmDialog.propTypes = {
  dateValue: PropTypes.string as React.Validator<InvoiceSubmitConfirmDialogProps['dateValue']>,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
