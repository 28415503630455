import React from 'react'

import { connect } from 'react-redux'

import { dashboardActions } from '@services'

import { bindActionToPromise, cancellablePromise, isAdmin, parseApiErrorMessage } from '@helpers'

import { useAssertContext, useCancellablePromiseRef } from '@hooks'

import { CompanyConsentDialog } from './CompanyConsentDialog'

interface CompanyConsentContextProps {
  requestConsent: VoidFunction
  requestWithConsentCheck: (callback: VoidFunction) => VoidFunction
}

const CompanyConsentContext = React.createContext<Nullable<CompanyConsentContextProps>>(null)

CompanyConsentContext.displayName = 'CompanyConsentContext'

type ConsentProviderState = {
  error: BackendError
  loading: boolean
  open: boolean
  originalRequestFunction: Nullable<VoidFunction>
  warning: boolean
}

interface CompanyConsentProviderProps {
  children: React.ReactNode
  consentType: keyof CompanyConsents
  getConsents: AsyncFunction<void, CompanyConsents>
  isAdminUser: boolean
  setConsent: AsyncFunction<{ consentType: keyof CompanyConsents }, CompanyConsents>
}

function PureCompanyConsentProvider({
  children,
  consentType,
  getConsents,
  isAdminUser,
  setConsent,
}: CompanyConsentProviderProps) {
  const [consents, setConsents] = React.useState<Nullable<CompanyConsents>>(null)
  const [{ error, loading, open, originalRequestFunction, warning }, setState] = React.useState<ConsentProviderState>({
    error: null,
    loading: false,
    open: false,
    originalRequestFunction: null,
    warning: false,
  })
  const cPromiseRef = useCancellablePromiseRef<CompanyConsents>()

  React.useEffect(() => {
    async function fetchConsents() {
      try {
        cPromiseRef.current = cancellablePromise(getConsents())
        const consents = await cPromiseRef.current.promise
        setConsents(consents)
      } catch (error) {
        // silently ignore error
      }
    }
    fetchConsents()
  }, [cPromiseRef, getConsents])

  const handleConfirm = React.useCallback(async () => {
    try {
      setState(prevState => ({ ...prevState, error: null, loading: true }))
      const results = await setConsent({ consentType })
      setConsents(results)
      // call callback
      originalRequestFunction?.()
      // close dialog
      setState(prevState => ({ ...prevState, open: false, loading: false }))
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setState(prevState => ({ ...prevState, error: errorMessage, loading: false }))
      }
    }
  }, [consentType, originalRequestFunction, setConsent])

  const contextValue = React.useMemo<CompanyConsentContextProps>(
    () => ({
      requestWithConsentCheck: (callback: VoidFunction) => () => {
        if (consents?.[consentType]) {
          setState(prevState => ({ ...prevState, originalRequestFunction: callback }))
          callback()
        } else {
          setState(prevState => ({ ...prevState, open: true, warning: false, originalRequestFunction: callback }))
        }
      },
      requestConsent: () => {
        setState(prevState => ({ ...prevState, open: true, warning: true }))
      },
    }),
    [consentType, consents]
  )

  return (
    <CompanyConsentContext.Provider value={contextValue}>
      {children}
      <CompanyConsentDialog
        isAdminUser={isAdminUser}
        open={open}
        onClose={() => setState(prevState => ({ ...prevState, open: false }))}
        onConfirm={handleConfirm}
        warning={warning}
        loading={loading}
        error={error}
      />
    </CompanyConsentContext.Provider>
  )
}

export const CompanyConsentProvider = connect(
  (state: Store) => ({
    isAdminUser: isAdmin(state.auth.company.data.role),
  }),
  dispatch => ({
    getConsents: bindActionToPromise(dispatch, dashboardActions.getCompanyConsents.request),
    setConsent: bindActionToPromise(dispatch, dashboardActions.setCompanyConsent.request),
  })
)(PureCompanyConsentProvider)

CompanyConsentProvider.displayName = 'CompanyConsentProvider'

export function useCompanyConsent() {
  return useAssertContext(CompanyConsentContext)
}
