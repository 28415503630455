import React from 'react'

import __uniq from 'lodash/uniq'
import { useWatch } from 'react-hook-form'

type Transaction = Pick<PaymentTransaction, 'amount' | 'currencyName' | 'exchangeRate'>

export function calculate(transactions: Transaction[], companyDefaultCurrencyName: string): [number, string] {
  // when more than one currency exists in transactions
  const usedCurrencies = __uniq(transactions.map(({ currencyName }) => currencyName))
  if (usedCurrencies.length === 1) {
    return [transactions.reduce((total, { amount }) => (total += Number(amount)), 0), usedCurrencies[0]]
  }

  // otherwise  all amount converted to company default currency
  return [
    transactions.reduce((total, { amount, exchangeRate }) => (total += Number(amount) * Number(exchangeRate)), 0),
    companyDefaultCurrencyName,
  ]
}

export function useCalculateTotal(transactions: Transaction[], companyDefaultCurrencyName: string): [number, string] {
  return React.useMemo(
    () => calculate(transactions, companyDefaultCurrencyName),
    [companyDefaultCurrencyName, transactions]
  )
}

export function useCalculateTransactionsFieldTotal(
  transactions: Transaction[],
  companyDefaultCurrencyName: string
): [number, string] {
  const transactionsValue = useWatch<
    { transactions: Array<{ transaction_id: number; amount: string }> },
    'transactions'
  >({ name: 'transactions' })

  return React.useMemo(
    // merge the two transactions array together by index (form field array has the same order)
    () =>
      calculate(
        transactions.map((transaction, index) => ({ ...transaction, amount: transactionsValue[index].amount })),
        companyDefaultCurrencyName
      ),
    [companyDefaultCurrencyName, transactions, transactionsValue]
  )
}
