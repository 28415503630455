import React from 'react'

import styled from 'styled-components'

function BaseCopyRegularIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M8.75 0C7.37109 0 6.25 1.12109 6.25 2.5V11.25C6.25 12.6289 7.37109 13.75 8.75 13.75H17.5C18.8789 13.75 20 12.6289 20 11.25V2.5C20 1.12109 18.8789 0 17.5 0H8.75ZM2.5 6.25C1.12109 6.25 0 7.37109 0 8.75V17.5C0 18.8789 1.12109 20 2.5 20H11.25C12.6289 20 13.75 18.8789 13.75 17.5V15H11.875V17.5C11.875 17.8438 11.5938 18.125 11.25 18.125H2.5C2.15625 18.125 1.875 17.8438 1.875 17.5V8.75C1.875 8.40625 2.15625 8.125 2.5 8.125H5V6.25H2.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CopyRegularIcon = styled(BaseCopyRegularIcon)``
