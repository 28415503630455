const styles = theme => ({
  root: {
    flexDirection: 'column',
    flex: 1,
  },
  paper: {
    paddingTop: 0,
    paddingLeft: 45,
    paddingRight: 45,
    paddingBottom: 85,
    textAlign: 'center',
    maxWidth: 540,
    marginTop: 37,
    position: 'relative',
  },
  userBadge: {
    position: 'relative',
    display: 'inline-block',
    marginTop: -37,
    marginBottom: 15,
  },
  badgeType: {
    display: 'block',
    borderWidth: 1,
    borderColor: theme.palette.grayscale.light,
    borderStyle: 'solid',
    borderRadius: 4,
    backgroundColor: theme.palette.grayscale.inputBackground,
    color: theme.palette.secondary.mDark,
    fontSize: 22,
    fontWeight: 700,
    padding: 15,
    minWidth: 180,
    maxWidth: 260,
    textAlign: 'center',
    margin: '35px auto',
  },
  goToDashboardButton: {
    ...theme.links.secondary,
    position: 'absolute',
    top: 20,
    right: 20,
    padding: 0,
    border: 0,
    minHeight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
    '&:focus': {
      textDecoration: 'underline',
    },
  },
})

export default styles
