import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { CollapseControlButton } from '@components/ui/CollapseElements'
import { ChevronUpIcon } from '@components/ui/svgIcons'

const StyledChevronIcon = styled(ChevronUpIcon)<{ $isExpanded: boolean }>`
  transition: all linear 200ms;
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? '0deg' : '180deg')});
`

const StyledToggleButton = styled(CollapseControlButton)`
  border-color: ${({ theme }) => theme.colors.common.paperStroke};
  background-color: ${({ theme }) => theme.colors.gray[0]};
  padding: 3px 5px;
  gap: 5px;
  color: ${({ theme }) => theme.colors.gray[100]};

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    color: ${({ theme }) => theme.colors.blue[40]};
  }
`

interface ToggleButtonProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'children' | 'type'> {
  contentId: string
  isExpanded: boolean
}

export function ToggleButton({ isExpanded, ...rest }: ToggleButtonProps) {
  return (
    <StyledToggleButton {...rest}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
      >
        <path d="M5 9.2H8V19H5V9.2ZM10.6 5H13.4V19H10.6V5ZM16.2 13H19V19H16.2V13Z" fill="#2168D1" />
      </svg>
      <StyledChevronIcon $isExpanded={isExpanded} color="currentColor" />
    </StyledToggleButton>
  )
}

ToggleButton.propTypes = {
  contentId: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
