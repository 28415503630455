import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { applyOpacity, getFontStyles } from '@helpers/styled-helpers'

const Button = styled.button.attrs({
  type: 'button',
})<{ $isActive: boolean }>`
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.blue[40] : theme.colors.gray[50])};
  background-color: unset;
  border: 1px solid;
  height: 20px;
  display: inline-flex;
  align-items: center;
  padding: 2px 4px;
  outline: none;
  border-radius: 4px;

  ${({ theme }) => getFontStyles(theme, '700-xs')}

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    background-color: ${({ theme }) => applyOpacity(theme.colors.blue[40], 10)};
  }
`

interface FilterSwitchButtonProps {
  children: React.ReactChild
  onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void
  isActive: boolean
}

export function FilterSwitchButton({ children, onClick, isActive }: FilterSwitchButtonProps) {
  return (
    <Button $isActive={isActive} onClick={onClick}>
      {children}
    </Button>
  )
}

FilterSwitchButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
}
