export const DIALOG_CLASS_NAMES = {
  portal: 'DialogPortal',
  overlay: {
    base: 'Dialog__Overlay',
    afterOpen: 'Dialog__Overlay--after-open',
    beforeClose: 'Dialog__Overlay--before-close',
  },
  content: {
    base: 'Dialog__Content',
    afterOpen: 'Dialog__Content--after-open',
    beforeClose: 'Dialog__Content--before-close',
  },
  body: 'Dialog__Body--open',
  html: 'Dialog__Html--open',
  prompt: 'prompt',
}

export const DIALOG_CLOSING_TIMEOUT = 225
export const SIMPLE_DIALOG_MAX_WIDTH = 600
export const DIALOG_PADDING = 20

export const DIALOG_SIZE = {
  small: 420,
  medium: 640,
  large: 800,
}
