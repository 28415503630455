import React from 'react'

import { AppBar } from '@material-ui/core'
import { connect } from 'react-redux'
import styled, { useTheme } from 'styled-components'

import { hasActiveSubscription } from '@helpers'

import { PortalProvider } from '@contexts'

import { useCompactNavbarVisible } from '@hooks'

import { AttentionIcon, FlexRow, HamburgerMenuIcon } from '@components/ui'
import { MainMenu } from '@oldComponents/PrivateNavbar/elements'

import { COMPACT_NAVBAR_ID, ROUTES } from '@constants'

import {
  DateFilterPageConfig,
  DropdownMenuButton,
  NavLink,
  OneLetterLogo,
  PageDateFilter,
  PageLink,
  PageMenu,
  PageTabLink,
} from './elements'
import { CompactPersonalDropdownMenu } from './elements/ProfileMenu'

// need to use mui appbar for preventing jumping on popover's scroll lock behavior
// this component listens on it and makes correction with padding
const CompactNavbarWrapper = styled(AppBar).attrs({ color: 'default' })`
  height: ${({ theme }) => `${theme.appBarHeightMedium}px`};
  background: ${({ theme }) => theme.colors.gray[0]};
  transition: top ease-in-out 400ms;
  position: fixed;
  top: var(--top, -200px);
  width: 100%;
  z-index: 1297;
  border-left: 0;
  border-right: 0;
  border-top: 0;
`

const CompactNavbarHeader = styled.div`
  height: ${({ theme }) => `${theme.appBarHeightMedium}px`};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MainDropdownMenuButton = styled(DropdownMenuButton)`
  border-right: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  border-radius: unset;
`

const StyledPageMenu = styled(PageMenu)`
  padding: 0 10px;

  ${PageTabLink} {
    height: ${({ theme }) => `${theme.appBarHeightMedium}px`};
    padding: ${({ theme }) =>
      `calc((${theme.appBarHeightMedium}px - ${theme.typography['400-md'].lineHeight}) / 2) 10px`};
  }
  ${AttentionIcon} {
    top: 3px;
    right: 0;
  }
`

const StyledNavLink = styled(NavLink)`
  width: ${({ theme }) => `${theme.appBarHeightMedium}px`};
  height: ${({ theme }) => `${theme.appBarHeightMedium}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
`

interface CompactNavbarProps {
  dateFilterConfig?: DateFilterPageConfig
  hasSubscription: boolean
  links: PageLink[]
}

function PureCompactNavbar({ dateFilterConfig, hasSubscription, links }: CompactNavbarProps) {
  const visible = useCompactNavbarVisible()
  const theme = useTheme()

  return (
    <CompactNavbarWrapper
      id={COMPACT_NAVBAR_ID}
      style={{
        '--top': visible ? 0 : `-${theme.appBarHeightMedium}px`,
      }}
      aria-hidden={!visible}
    >
      <PortalProvider>
        <CompactNavbarHeader>
          <FlexRow>
            <StyledNavLink to={ROUTES.root}>
              <OneLetterLogo />
            </StyledNavLink>
            <MainDropdownMenuButton
              icon={<HamburgerMenuIcon />}
              isContainerVisible={visible}
              placement="bottom-start"
              transformOrigin="left top"
              width={240}
            >
              {() => <MainMenu hasSubscription={hasSubscription} />}
            </MainDropdownMenuButton>
            <StyledPageMenu links={links} />
          </FlexRow>
          <FlexRow>
            {dateFilterConfig && (
              <PageDateFilter
                dateTypeOptions={dateFilterConfig.dateTypeOptions}
                defaultView="year"
                disabled={dateFilterConfig.disabled}
                getDateTypeValue={dateFilterConfig.getDateTypeValue}
                isDateFilterToggleDisabled={dateFilterConfig.isDateFilterToggleDisabled}
                onChange={dateFilterConfig.onChange}
                onToggle={dateFilterConfig.onToggle}
                variant="compact"
              />
            )}
            <CompactPersonalDropdownMenu visible={visible} />
          </FlexRow>
        </CompactNavbarHeader>
      </PortalProvider>
    </CompactNavbarWrapper>
  )
}

export const CompactNavbar = connect((state: Store) => ({
  hasSubscription: hasActiveSubscription(state),
}))(PureCompactNavbar)

CompactNavbar.displayName = 'CompactNavbar'
