import React from 'react'
import PropTypes from 'prop-types'

import { IntlShape, MessageDescriptor, useIntl } from 'react-intl'
import styled from 'styled-components'

import { useUniqueId } from '@hooks'

import { Collapse } from '@components/ui/CollapseElements'
import { Portal } from '@components/ui/Portal'
import { LightTooltip } from '@oldComponents/ui'

import { StyledCard } from './Card'
import { ToggleButton } from './elements'

import messages from './messages'

//* 3 column grid layout with 3 layout variants
// 1) - one item only with fullwidth
// 2) - two items with 1:2 ratio
// 3) - three items of the same size
const WrapperDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  & ${StyledCard} {
    width: 100%;
    min-width: 100%; // important to prevent content from expanding grid item
  }

  // only one item, 2nd has x2 width
  & ${StyledCard}:first-of-type:last-of-type {
    grid-column: 1/-1;
  }

  // only two items, 2nd has x2 width
  & ${StyledCard}:nth-of-type(2):last-of-type {
    grid-column: 2/-1;
  }
`

function getLabel(
  externalMessage: StringOrMessage | undefined,
  fallbackMessage: MessageDescriptor,
  formatMessage: IntlShape['formatMessage']
) {
  if (typeof externalMessage === 'string') {
    return externalMessage
  } else if (externalMessage) {
    return formatMessage({
      id: externalMessage.props.id,
      defaultMessage: externalMessage.props.defaultMessage,
    })
  } else {
    return formatMessage(fallbackMessage)
  }
}

const LS_CHARTS_COLLAPSED_NAME = 'quick_charts_collapsed'

interface CollapsableCardsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactChild | React.ReactChild[]
  className?: string
  collapsedLabel?: StringOrMessage
  expandedLabel?: StringOrMessage
  portalAnchorEl?: React.RefObject<HTMLElement>['current']
}

export function CollapsableCards({
  children,
  collapsedLabel,
  expandedLabel,
  id,
  portalAnchorEl,
  ...rest
}: CollapsableCardsProps) {
  const { formatMessage } = useIntl()
  const [isExpanded, setExpanded] = React.useState(!localStorage.getItem(LS_CHARTS_COLLAPSED_NAME))

  const contentId = useUniqueId('collapsable-cards-', id)
  const label = isExpanded
    ? getLabel(expandedLabel, messages.expanded, formatMessage)
    : getLabel(collapsedLabel, messages.collapsed, formatMessage)

  function handleToggleClick() {
    setExpanded(expanded => {
      const value = !expanded
      if (value) {
        localStorage.removeItem(LS_CHARTS_COLLAPSED_NAME)
      } else {
        localStorage.setItem(LS_CHARTS_COLLAPSED_NAME, '1')
      }
      return value
    })
  }

  return (
    <>
      <Portal anchorEl={portalAnchorEl}>
        <LightTooltip title={label} placement="bottom">
          <span>
            <ToggleButton
              contentId={contentId}
              isExpanded={isExpanded}
              onClick={handleToggleClick}
              aria-label={label}
            />
          </span>
        </LightTooltip>
      </Portal>
      <Collapse id={contentId} isExpanded={isExpanded}>
        <WrapperDiv {...rest}>{children}</WrapperDiv>
      </Collapse>
    </>
  )
}

CollapsableCards.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  collapsedLabel: PropTypes.node,
  expandedLabel: PropTypes.node,
  id: PropTypes.string,
  portalAnchorEl: PropTypes.object,
}

export const PageCollapsableCards = styled(CollapsableCards)`
  ${StyledCard} {
    height: 268px;

    & > *:last-child {
      height: 206px;
    }
  }
`
