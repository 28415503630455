import { defineMessages } from 'react-intl'

const messages = defineMessages({
  searchInputPlaceholder: {
    id: 'filterBar.filter.input.placeholder',
    defaultMessage: 'Szűrés...',
  },
  searchFieldAllPlaceholder: {
    id: 'filterBar.searchFilter.button.allSearchFields',
    defaultMessage: 'Mindenben keres',
  },
  searchFieldDefaultPlaceholder: {
    id: 'filterBar.searchFilter.button.defaultSearchFields',
    defaultMessage: 'Alapértelmezett',
  },
  searchFieldUnique: {
    id: 'filterBar.searchFilter.button.unique',
    defaultMessage: 'Egyéni',
  },
})

export default messages
