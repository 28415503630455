import { combineReducers } from 'redux'

import apiKeysReducer from './apiKeys/reducer'
import authActions from './auth/actions'
import authReducer from './auth/reducer'
import controlsReducer from './controls/reducer'
import dashboardReducer, { initialState as dashboardInitialState } from './dashboard/reducer'
import dokumentsReducer from './dokuments/reducer'
import expenseReducer from './expense/reducer'
import filtersReducer, { initialState as filtersInitialState } from './filters/reducer'
import incomeReducer from './income/reducer'
import liquidityReducer from './liquidity/reducer'
import localeReducer from './locale/reducer'
import monthlyStatementReducer from './monthlyStatement/reducer'
import partnersReducer from './partners/reducer'
import paymentReducer from './payment/reducer'
import quarantineReducer from './quarantine/reducer'
import salaryReducer from './salary/reducer'
import subscriptionReducer from './subscription/reducer'
import taxReducer from './tax/reducer'

const appReducer = combineReducers({
  apiKeys: apiKeysReducer,
  auth: authReducer,
  controls: controlsReducer,
  dashboard: dashboardReducer,
  dokuments: dokumentsReducer,
  expense: expenseReducer,
  filters: filtersReducer,
  income: incomeReducer,
  liquidity: liquidityReducer,
  locale: localeReducer,
  monthlyStatement: monthlyStatementReducer,
  partners: partnersReducer,
  payment: paymentReducer,
  quarantine: quarantineReducer,
  salary: salaryReducer,
  subscription: subscriptionReducer,
  tax: taxReducer,
})

const rootReducer = (state, action) => {
  if (action.type === authActions.logout.SUCCESS) {
    // reset all reducer state to initial after logout except dashboard.common part
    state = {
      filters: {
        ...filtersInitialState,
      },
      dashboard: {
        ...dashboardInitialState,
        common: state.dashboard.common,
      },
    }
  }

  return appReducer(state, action)
}

export default rootReducer
