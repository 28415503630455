import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import PublicIcon from '@material-ui/icons/Public'

const useStyles = makeStyles((theme: any) => ({
  root: ({ fontSize, isLink }: { fontSize: 'inherit' | number; isLink: boolean }) => ({
    fontSize,
    color: isLink ? theme.palette.tertiary.oceanBlue : theme.palette.primary.main,
    '&>svg': { transform: 'translate(0, 2px)' },
  }),
}))

interface DocumentTypeProps {
  fontSize?: 'inherit' | number
  isLink: boolean
}

export default function DocumentType({ fontSize = 18, isLink }: DocumentTypeProps) {
  const classes = useStyles({ fontSize, isLink })
  const Icon = isLink ? PublicIcon : InsertDriveFileOutlinedIcon

  return (
    <div className={classes.root}>
      <Icon color="inherit" fontSize="inherit" />
    </div>
  )
}

DocumentType.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.oneOf(['inherit']).isRequired, PropTypes.number.isRequired]),
  isLink: PropTypes.bool.isRequired,
}
