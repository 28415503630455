import React from 'react'
import PropTypes from 'prop-types'

export default function ChevronUpIcon({ className, color = '#000000', size = 14 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.19909 10.3672L1.16659 9.33469L6.99992 3.50135L12.8333 9.33469L11.8008 10.3672L6.99992 5.56636L2.19909 10.3672Z"
        fill={color}
      />
    </svg>
  )
}

ChevronUpIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}
