import { Accept } from 'react-dropzone'

export const INVOICE_ACCEPTED_MIME_TYPES: Accept = {
  'application/pdf': [],
  'image/jpeg': [],
  'image/png': [],
}

export const XML_ACCEPTED_MIME_TYPES: Accept = {
  'application/xml': [],
  'text/xml': [],
}

export enum CustomValidatorCodes {
  ZERO_FILE_SIZE = 'ZERO_FILE_SIZE',
}
