import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import { connect } from 'react-redux'

import { getCookie, setCookie } from '@helpers'

import AnnouncementIcon from '@oldComponents/ui/SvgIcons/AnnouncementIcon'

import { ANNOUNCEMENT_COOKIE_PREFIX } from '@constants'

import styles from './styles'

const useStyles = makeStyles(styles)

function isAnnouncementVisible(cookieName: string, showOnce: boolean) {
  const cookie = getCookie(cookieName)
  if (cookie && showOnce) {
    return false
  }
  return true
}

function buildLink(text: string) {
  const re = /<link:url=([^<]+)>([^<]+)<\/link>/gi
  return text.replace(re, '<a href="$1" target="_blank">$2</a>').replace(/<\/?link(:url=[^<>]+)?>/gi, '')
}

export interface AnnouncementProps {
  announcement: Announcement
}

export function Announcement({
  announcement: { id, title, description, show_once: showOnce, to_time: expDate },
}: AnnouncementProps) {
  const classes = useStyles()
  const cookieName = `${ANNOUNCEMENT_COOKIE_PREFIX}_${id}`
  const [visible, setVisible] = React.useState(isAnnouncementVisible(cookieName, showOnce))

  const handleHide = React.useCallback(() => {
    if (showOnce) {
      const expiration = expDate ? new Date(expDate) : 365 // default 365 days
      setCookie(cookieName, 1, expiration)
    }
    setVisible(false)
  }, [showOnce, expDate, cookieName])

  if (!visible) {
    return null
  }

  const descriptionWithLink = buildLink(description)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={9}>
        <div className={classes.iconContainer}>
          <AnnouncementIcon />
        </div>
        <div className={classes.content}>
          <IconButton onClick={handleHide} className={classes.closeButton}>
            <ClearIcon style={{ fontSize: 18 }} />
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography
            align="left"
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: descriptionWithLink }}
          />
        </div>
      </Paper>
    </div>
  )
}

const propTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  show_once: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  to_time: PropTypes.string,
})

Announcement.propTypes = {
  announcement: propTypes.isRequired,
}

interface DashboardAnnouncementProps {
  announcement: Nullable<Announcement>
}

function PureDashboardAnnouncement({ announcement }: DashboardAnnouncementProps) {
  if (!announcement) {
    return null
  }
  return <Announcement announcement={announcement} />
}

PureDashboardAnnouncement.propTypes = {
  announcement: propTypes as React.Validator<Nullable<Announcement>>,
}

export const DashboardAnnouncement = connect((state: Store) => ({
  announcement: state.auth.company.data?.announcement,
}))(PureDashboardAnnouncement)

DashboardAnnouncement.displayName = 'DashboardAnnouncement'
