import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, useIntl } from 'react-intl'
import { CellProps, HeaderProps } from 'react-table'

import { Checkbox } from '@components/ui'

import { SelectAllPopover } from './SelectAllPopover'

const messages = defineMessages({
  selectAll: {
    id: 'table.selectAll',
    defaultMessage: 'Minden sor kijelölése',
  },
  selectOne: {
    id: 'table.selectOne',
    defaultMessage: 'Sor kijelölése',
  },
})

export function SelectAllHeaderCell<RowData extends object>({
  data,
  getToggleAllRowsSelectedProps,
  hasMorePage,
  isAllRowsSelected,
  isAllSelected,
  onSelectChange,
}: HeaderProps<RowData>) {
  const { formatMessage } = useIntl()
  const [isPopoverVisible, setPopoverVisible] = React.useState(hasMorePage && isAllRowsSelected)
  const anchorRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (hasMorePage) {
      if (isAllRowsSelected) {
        setPopoverVisible(true)
      } else {
        setPopoverVisible(false)
      }
    }
  }, [hasMorePage, isAllRowsSelected])

  const handleClose = () => setPopoverVisible(false)

  return (
    <>
      <Checkbox {...getToggleAllRowsSelectedProps()} ref={anchorRef} title={formatMessage(messages.selectAll)} />
      {isPopoverVisible && (
        <SelectAllPopover
          selectedCount={data.length}
          anchorEl={anchorRef.current}
          isAllSelected={isAllSelected}
          onClose={handleClose}
          onSelectChange={onSelectChange}
        />
      )}
    </>
  )
}

SelectAllHeaderCell.propTypes = {
  data: PropTypes.array.isRequired,
  getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
  hasMorePage: PropTypes.bool.isRequired,
  isAllRowsSelected: PropTypes.bool.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  onSelectChange: PropTypes.func.isRequired,
}

export function SelectRowCell<RowData extends object>({ row }: CellProps<RowData>) {
  const { formatMessage } = useIntl()
  return <Checkbox {...row.getToggleRowSelectedProps()} title={formatMessage(messages.selectOne)} />
}
