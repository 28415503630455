import axios from 'axios'

import { getDefaultLanguage } from '@helpers'

import { clearToken, getAuthToken } from '../helpers'

const axiosInstance = axios.create()
// Override timeout default for the library
// Now all requests will wait 2.5 seconds before timing out
// axiosInstance.defaults.timeout = 2500
axiosInstance.defaults.xsrfCookieName = 'csrftoken'
axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Do nothing with response data
    return response
  },
  function (error) {
    // console.log('axios interceptor', error.message, {
    //   url: error.config.url,
    //   method: error.config.method,
    //   statusText: error.response.statusText,
    //   status: error.response.status,
    //   message: error.message,
    //   date: error.response.headers.date,
    // })

    const { status } = error.response
    // check auth_token validity
    if (status === 401) {
      // has invalid token is localeStorage
      if (getAuthToken()) {
        clearToken()
      }
    }
    // Do something with response error
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.request.use(config => {
  // console.log('axios request interceptor', config)
  const authToken = getAuthToken()
  const lang = getDefaultLanguage()
  const { appVersion } = window

  config.headers['app-name'] = 'Quick'

  if (authToken) {
    config.headers.Authorization = `Token ${authToken}`
  }

  if (lang) {
    config.headers['Accept-Language'] = lang
  }

  if (appVersion) {
    config.headers.APP_VERSION = appVersion
  }

  return config
})

export default axiosInstance
