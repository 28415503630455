import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core'
import cx from 'classnames'

import { isFieldHighlighted } from '@helpers'

export function RenderCustomTextField({
  classes,
  input: { onChange, ...inputRest },
  label,
  labelClassName,
  meta: { touched, error },
  callback,
  className,
  autoFocus,
  disabled,
  readOnly,
  ExtraTextComponent,
  required,
  highlighted,
  ...rest
}) {
  return (
    <FormControl
      error={Boolean(touched && error)}
      margin="normal"
      className={cx(className, 'form-control')}
      required={required}
      fullWidth
    >
      {rest.type !== 'hidden' && (
        <InputLabel htmlFor={inputRest.name} shrink className={cx(classes.bootstrapFormLabel, labelClassName)}>
          {label}
        </InputLabel>
      )}
      <Input
        id={inputRest.name}
        disableUnderline
        classes={{
          root: classes.bootstrapRoot,
          input: cx(classes.bootstrapInput, { highlighted: isFieldHighlighted(highlighted, inputRest.value) }),
          error: classes.bootstrapError,
        }}
        autoFocus={autoFocus}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        onChange={event => {
          onChange(event)
          if (callback) {
            callback(event.target.value)
          }
        }}
        {...inputRest}
        {...rest}
      />
      {ExtraTextComponent && <ExtraTextComponent className={classes.extraText} />}
      {touched && error && (
        <FormHelperText className={cx({ [classes.extraTextError]: Boolean(ExtraTextComponent) })}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

RenderCustomTextField.defaultProps = {
  autoFocus: false,
  callback: undefined,
  className: '',
  disabled: false,
  highlighted: false,
  readOnly: false,
  required: false,
}

RenderCustomTextField.propTypes = {
  autoFocus: PropTypes.bool,
  callback: PropTypes.func,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  ExtraTextComponent: PropTypes.elementType,
  highlighted: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool }),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
}
