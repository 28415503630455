import React from 'react'

import styled from 'styled-components'

function BaseMapLocationDotRegularIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M16.167 7.167c0 .298-.063.635-.174.993-.198.639-.542 1.347-.951 2.055l-.004.007c-.107.188-.219.372-.333.556-.153.243-.31.486-.469.722l-.014.02a34.5 34.5 0 0 1-1.708 2.313.653.653 0 0 1-1.028 0 35.779 35.779 0 0 1-1.708-2.312c-.64-.948-1.26-1.997-1.622-2.93-.028-.074-.055-.147-.08-.22-.152-.437-.243-.847-.243-1.2A4.165 4.165 0 0 1 12 3a4.166 4.166 0 0 1 4.167 4.166Zm-1.945 6.27c.469-.631 1-1.385 1.483-2.166.062-.1.125-.202.184-.306v7.788l4.444-1.614V8.358L16.56 9.729c.076-.163.153-.323.219-.486.205-.486.382-1.014.458-1.535l3.646-1.326A.831.831 0 0 1 22 7.167v10.555c0 .35-.219.663-.549.785l-6.11 2.222a.845.845 0 0 1-.57 0l-5.827-2.121-5.826 2.118A.832.832 0 0 1 2 19.944V9.39c0-.35.219-.663.549-.785l4.173-1.517v.08c0 .524.129 1.066.31 1.583L3.666 9.972v8.781L8.11 17.14v-6.17c.063.104.122.205.184.305.483.782 1.01 1.535 1.483 2.167v3.698l4.444 1.614v-5.316ZM12 8.277A1.389 1.389 0 1 0 12 5.5a1.389 1.389 0 0 0 0 2.778Z"
      />
    </svg>
  )
}

export const MapLocationDotRegularIcon = styled(BaseMapLocationDotRegularIcon)``
