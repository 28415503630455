import { AppBar as MuiAppBar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const appBarStyles = () => ({
  root: {
    flexDirection: 'row',
    border: 'none',
    margin: 0,
    width: 'auto',
    zIndex: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'hidden',
  },
  colorDefault: {
    backgroundColor: '#F1F2F4',
  },
})

export const AppBar = withStyles(appBarStyles)(MuiAppBar)
