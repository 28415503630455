import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import * as yup from 'yup'

import { AuthResponse } from '@components/LoginView'
import {
  ReactHookForm,
  ReactHookFormCheckboxField,
  ReactHookFormError,
  ReactHookFormSubmitButton,
  ReactHookFormTextField,
} from '@components/ui'
import { Typography } from '@components/ui/Typography'
import { BoldDefaultLink } from '@oldComponents/ui/links'

import { ROUTES } from '@constants/routes'

import { formErrorMessages, globalMessages } from '@messages'

export const loginFormMessages = defineMessages({
  loginButtonText: {
    id: 'login.buttonText',
    defaultMessage: 'Belépek',
  },
})

const StyledReactHookForm = styled(ReactHookForm)`
  min-height: 310px;
`

const LoginButton = styled(ReactHookFormSubmitButton)`
  margin-top: 15px;
  height: 50px;
`

const StyledTypography = styled(Typography)`
  margin-bottom: 5px;
`

const CheckboxWrapperDiv = styled.div`
  margin-bottom: 15px;
`

const StyledReactHookFormError = styled(ReactHookFormError)`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.error[150]};
`

interface LoginFormValues {
  email: string
  password: string
  remember_me?: boolean
}

interface LoginFormSubmitValues extends LoginFormValues {
  step: 'login'
}

function useValidationSchema(): yup.SchemaOf<LoginFormValues> {
  const { formatMessage } = useIntl()

  return yup.object({
    email: yup
      .string()
      .email(formatMessage(formErrorMessages.email))
      .required(formatMessage(formErrorMessages.required)),
    password: yup.string().required(formatMessage(formErrorMessages.required)),
    remember_me: yup.boolean(),
  })
}

export interface LoginFormProps {
  loginEmail?: string
  onSubmit: AsyncFunction<LoginFormSubmitValues, AuthResponse>
  onSubmitSuccess?: (response: AuthResponse) => void
}

export function LoginForm({ loginEmail, onSubmit, onSubmitSuccess }: LoginFormProps) {
  const { formatMessage } = useIntl()
  const validationSchema = useValidationSchema()

  const initialValues = {
    email: loginEmail ?? '',
    password: '',
  }

  function handleFormSubmit(data: LoginFormValues) {
    return onSubmit({ ...data, step: 'login' })
  }

  return (
    <StyledReactHookForm
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      onSubmitSuccess={onSubmitSuccess}
      validationSchema={validationSchema}
      data-testid="login-form"
      skipUnsavedChanges
    >
      <ReactHookFormTextField
        disabled={Boolean(loginEmail)}
        name="email"
        label={formatMessage(globalMessages.formLabelEmail)}
      />
      <ReactHookFormTextField name="password" type="password" label={formatMessage(globalMessages.formLabelPassword)} />
      <StyledTypography size="400-sm" align="left">
        <BoldDefaultLink to={`${ROUTES.resetPassword}${location.search}`}>
          <FormattedMessage id="resetPassword.linkText" defaultMessage="Elfelejtettem a jelszavam" />
        </BoldDefaultLink>
      </StyledTypography>
      <CheckboxWrapperDiv>
        <ReactHookFormCheckboxField name="remember_me" label={formatMessage(globalMessages.formLabelRememberMe)} />
      </CheckboxWrapperDiv>
      <StyledReactHookFormError withFade hideIcon />
      <LoginButton buttonText={formatMessage(loginFormMessages.loginButtonText)} fullWidth size="large" />
    </StyledReactHookForm>
  )
}

LoginForm.propTypes = {
  loginEmail: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
}
