import React from 'react'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { getFileName } from '@components/DocViewer/helpers'

import { setRendererRect } from '@components/DocViewer/actions'
import { useDocumentLoader, useRendererSizes } from '@components/DocViewer/hooks'
import {
  DocRenderer,
  DocViewerConfig,
  DocViewerDocument,
  DocViewerMainState,
  RendererRect,
} from '@components/DocViewer/types'

import { LoadingTimeout } from './LoadingTimeout'

export const Container = styled.div<{ $config: RendererRect }>`
  display: flex;
  overflow: auto;
  height: ${({ $config }) => $config.height ?? 0}px;
  width: 100%;
  max-width: ${({ $config }) => $config.width ?? 0}px;
  position: relative;
`

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

interface ContentsProps {
  documents: DocViewerDocument[]
  documentLoading: boolean | undefined
  config: DocViewerConfig | undefined
  currentDocument: DocViewerDocument | undefined
  fileName: string
  CurrentRenderer: DocRenderer | null | undefined
  state: DocViewerMainState
}

function Contents({
  documents,
  documentLoading,
  config,
  currentDocument,
  fileName,
  CurrentRenderer,
  state,
}: ContentsProps) {
  if (!documents.length) {
    return <div id="no-documents"></div>
  } else if (documentLoading) {
    if (config && config?.loadingRenderer?.overrideComponent) {
      const OverrideComponent = config.loadingRenderer.overrideComponent
      return (
        <LoadingTimeout>
          <OverrideComponent currentDocument={currentDocument} fileName={fileName} />
        </LoadingTimeout>
      )
    }

    return (
      <LoadingTimeout>
        <LoadingContainer id="loading-renderer" data-testid="loading-renderer">
          <CircularProgress size={30} />
        </LoadingContainer>
      </LoadingTimeout>
    )
  } else {
    if (CurrentRenderer) {
      return <CurrentRenderer mainState={state} hideFileControls={config?.hideFileControls} />
    } else if (CurrentRenderer === undefined) {
      return null
    } else {
      if (config && config?.noRenderer?.overrideComponent) {
        const OverrideComponent = config.noRenderer.overrideComponent
        return <OverrideComponent currentDocument={currentDocument} fileName={fileName} />
      }

      return (
        <FormattedMessage
          id="docViewer.noRendererMessage"
          defaultMessage="Sajnáljuk a {fileName} fájltípus megjelenítése nem lehetséges"
          values={{
            fileType: currentDocument?.fileType ?? '',
          }}
        />
      )
    }
  }
}

export function ProxyRenderer() {
  const { state, dispatch, CurrentRenderer } = useDocumentLoader()
  const { documents, documentLoading, currentDocument, config } = state
  const rendererSizes = useRendererSizes(config)

  React.useEffect(() => {
    dispatch(setRendererRect(rendererSizes))
  }, [dispatch, rendererSizes])

  const fileName = getFileName(currentDocument, false)

  return (
    <Container $config={rendererSizes}>
      <Contents
        {...{
          state,
          documents,
          documentLoading,
          config,
          currentDocument,
          fileName,
          CurrentRenderer,
        }}
      />
    </Container>
  )
}
