import React from 'react'

import { useFormContext } from 'react-hook-form'

import { Button } from '@components/ui/Buttons'

import { CopyFullAmountButtonMessage } from '@messages'

interface BalanceTransactionButtonProps {
  amount: number
  fieldName: string
}

export function BalanceTransactionButton({ amount, fieldName }: BalanceTransactionButtonProps) {
  const { setValue } = useFormContext()
  function onClickHandler() {
    setValue(fieldName, amount)
  }
  return (
    <Button variant="primaryText" size="small" onClick={onClickHandler}>
      {CopyFullAmountButtonMessage}
    </Button>
  )
}
