import React from 'react'

import styled from 'styled-components'

function PureCommentCodeRegularIcon({ className, size = 12 }: IconComponentProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M3.94219 9.02109C3.59062 8.89453 3.19922 8.94844 2.89687 9.17109C2.70469 9.31172 2.37422 9.51797 1.97344 9.70312C2.10469 9.35859 2.20547 8.96953 2.23828 8.54531C2.26172 8.24297 2.16094 7.94297 1.96172 7.71328C1.41562 7.09688 1.125 6.375 1.125 5.625C1.125 3.76172 3.07734 1.875 6 1.875C8.92266 1.875 10.875 3.76172 10.875 5.625C10.875 7.48828 8.92266 9.375 6 9.375C5.25938 9.375 4.56328 9.24609 3.94219 9.02109ZM0.616406 9.93281C0.578906 9.99609 0.539062 10.0594 0.496875 10.1227L0.489844 10.1344C0.452344 10.1883 0.414844 10.2422 0.377344 10.2961C0.295313 10.4062 0.20625 10.5141 0.1125 10.6125C0.00468751 10.7203 -0.0257813 10.8797 0.0328125 11.0203C0.0914062 11.1609 0.227344 11.2523 0.379688 11.2523C0.499219 11.2523 0.61875 11.2453 0.738281 11.2336L0.754688 11.2313C0.857813 11.2195 0.960938 11.2055 1.06406 11.1867C1.08281 11.1844 1.10156 11.1797 1.12031 11.175C1.5375 11.093 1.93828 10.9523 2.29453 10.7977C2.83125 10.5633 3.28828 10.2844 3.56719 10.0805C4.3125 10.35 5.1375 10.5 6.00703 10.5C9.32109 10.5 12.007 8.31797 12.007 5.625C12.007 2.93203 9.31406 0.75 6 0.75C2.68594 0.75 0 2.93203 0 5.625C0 6.68203 0.414844 7.65937 1.11797 8.45859C1.07344 9.03281 0.850781 9.54375 0.616406 9.93281ZM5.08594 4.52344C5.30625 4.30312 5.30625 3.94688 5.08594 3.72891C4.86562 3.51094 4.50938 3.50859 4.29141 3.72891L2.79141 5.22891C2.57109 5.44922 2.57109 5.80547 2.79141 6.02344L4.29141 7.52344C4.51172 7.74375 4.86797 7.74375 5.08594 7.52344C5.30391 7.30312 5.30625 6.94688 5.08594 6.72891L3.98438 5.62734L5.08594 4.52578V4.52344ZM7.71094 3.72656C7.49062 3.50625 7.13438 3.50625 6.91641 3.72656C6.69844 3.94688 6.69609 4.30312 6.91641 4.52109L8.01797 5.62266L6.91641 6.72422C6.69609 6.94453 6.69609 7.30078 6.91641 7.51875C7.13672 7.73672 7.49297 7.73906 7.71094 7.51875L9.21094 6.01875C9.43125 5.79844 9.43125 5.44219 9.21094 5.22422L7.71094 3.72422V3.72656Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CommentCodeRegularIcon = styled(PureCommentCodeRegularIcon)``
