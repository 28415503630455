import React from 'react'

import confetti from 'canvas-confetti'
import { useTheme } from 'styled-components'

export function useConfetti(options: confetti.Options = {}) {
  const theme = useTheme()

  const triggerConfetti = React.useCallback(() => {
    confetti({
      zIndex: theme.dialogZIndex + 100,
      ...options,
    })
  }, [options, theme.dialogZIndex])

  return { triggerConfetti }
}
