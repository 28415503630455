import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { defineMessages, useIntl } from 'react-intl'

import { BADGE_ASSISTANT, BADGE_MASTER } from '@constants'

import assistant_big_badge from '@assets/img/badges/assistant_big.svg'
import assistant_small_badge from '@assets/img/badges/assistant_small.svg'
import master_big_badge from '@assets/img/badges/master_big.svg'
import master_small_badge from '@assets/img/badges/master_small.svg'

const messages = defineMessages({
  masterBadge: {
    id: 'badges.master',
    defaultMessage: 'Master',
  },
  assistantBadge: {
    id: 'badges.assistant',
    defaultMessage: 'Assistant',
  },
})

const useStyles = makeStyles(() => ({
  root: {
    '&.small': {
      position: 'relative',
      paddingRight: 5,
      display: 'inline-block',
      verticalAlign: 'middle',
      lineHeight: 1,
    },
    '&.big': {
      display: 'block',
    },
  },
}))

const badges = {
  source: {
    small: {
      [BADGE_ASSISTANT]: assistant_small_badge,
      [BADGE_MASTER]: master_small_badge,
    },
    big: {
      [BADGE_ASSISTANT]: assistant_big_badge,
      [BADGE_MASTER]: master_big_badge,
    },
  },
  alt: {
    [BADGE_ASSISTANT]: messages.assistantBadge,
    [BADGE_MASTER]: messages.masterBadge,
  },
}

export const BADGES_LIST = [BADGE_MASTER, BADGE_ASSISTANT] as const

interface UserBadgeProps {
  badge?: (typeof BADGES_LIST)[number] | null
  size?: 'small' | 'big'
}

export function UserBadge({ badge, size = 'small' }: UserBadgeProps) {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  if (badge == null) {
    return null
  }

  return (
    <span className={cx(classes.root, { [size]: size })}>
      <img src={badges.source[size][badge]} alt={formatMessage(badges.alt[badge])} />
    </span>
  )
}

UserBadge.propTypes = {
  badge: PropTypes.oneOf(BADGES_LIST),
  size: PropTypes.oneOf(['small', 'big']),
}
