import React from 'react'

import { InputBase, InputBaseProps } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledInputBase = withStyles((theme: any) => ({
  root: {
    height: 24,
    backgroundColor: theme.palette.grayscale.grey02,
    fontSize: 12,
    lineHeight: '16.34px',
    letterSpacing: 'initial',
    fontWeight: 400,
    border: `1px solid ${theme.palette.grayscale.grey20}`,
    borderRadius: 4,
    transition: 'border 125ms ease, backgroundColor 125ms ease',
    display: 'flex',

    '&.Mui-error, &.Mui-focused.Mui-error': {
      border: `1px solid ${theme.palette.error.main}`,
    },

    '& input, & textarea': {
      padding: '4px 10px',
      height: 'auto',
    },

    '&::-webkit-input-placeholder': {
      /* Edge */
      opacity: 1,
    },

    '& ::placeholder': {
      opacity: 1,
    },
  },
  multiline: {
    padding: 0,
  },
  focused: {
    border: `1px solid ${theme.palette.tertiary.outlineBlue}`,
  },
  disabled: {
    '-webkit-text-fill-color': theme.palette.grayscale.inputLabel, // Safari fix (https://stackoverflow.com/questions/262158/disabled-input-text-color)

    '&:hover': {
      border: `1px solid ${theme.palette.grayscale.grey20}`,
    },
  },
}))(InputBase)

interface InlineTextInputProps extends InputBaseProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  value: string
}

/*
  this component handles the text input functionality with Material UI's InputBase component
*/
export function InlineTextInput({ disabled = false, id, ...restInputProps }: InlineTextInputProps): JSX.Element {
  return <StyledInputBase {...restInputProps} disabled={disabled} id={id} name={restInputProps.autoComplete || id} />
}
