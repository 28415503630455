import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { IconButton } from '../Buttons'
import { LeftArrowIcon, RightArrowIcon } from '../svgIcons'

const messages = defineMessages({
  previous: {
    id: 'buttons.paging.previous',
    defaultMessage: 'Előző',
  },
  next: {
    id: 'buttons.paging.next',
    defaultMessage: 'Következő',
  },
})

const ButtonWrapperDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export interface PagingProps {
  disabledNext: boolean
  disabledPrev: boolean
  displayPaging?: boolean
  onNext: VoidFunction
  onPrev: VoidFunction
}

export function Paging({ disabledNext, disabledPrev, displayPaging = false, onNext, onPrev }: PagingProps) {
  const { formatMessage } = useIntl()

  if (!displayPaging) {
    return null
  }

  return (
    <ButtonWrapperDiv>
      <IconButton
        variant="primaryText"
        size="small"
        onClick={onPrev}
        disabled={disabledPrev}
        title={formatMessage(messages.previous)}
      >
        <LeftArrowIcon />
      </IconButton>
      <IconButton
        variant="primaryText"
        size="small"
        onClick={onNext}
        disabled={disabledNext}
        title={formatMessage(messages.next)}
      >
        <RightArrowIcon />
      </IconButton>
    </ButtonWrapperDiv>
  )
}

Paging.propTypes = {
  disabledNext: PropTypes.bool.isRequired,
  disabledPrev: PropTypes.bool.isRequired,
  displayPaging: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
}
