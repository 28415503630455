import { changeLanguage, getDefaultLanguage, setDefaultLanguage } from '@helpers'

import actions from './actions'

export const initialState = { language: getDefaultLanguage() }

function getSafeLanguage(language) {
  return language ?? getDefaultLanguage()
}

export default function localeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.setLocale.REQUEST: {
      const language = getSafeLanguage(action.payload)
      setDefaultLanguage(language)
      return { language: getDefaultLanguage(language) }
    }
    case actions.changeLocale.REQUEST: {
      const language = getSafeLanguage(action.payload)
      changeLanguage(language)
      return { language }
    }
    case actions.updateLocale.REQUEST: {
      const language = getSafeLanguage(action.payload)
      return { language }
    }
    default:
      return state
  }
}
