import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { ArtifactViewer } from '@components/ArtifactViewer'
import { Typography } from '@components/ui'

import { DialogActions, ReducerState } from '../reducer'
import { PotentialExpense } from '../types'
import { ExpensePreview } from './ExpensePreview'
import { InvoicePreviewNavBadge } from './InvoicePreviewNavBadge'
import { PreviewInvoiceHeader } from './PreviewInvoiceHeader'
import SelectableNavInvoiceCard from './SelectableNavInvoiceCard'

import {
  ColumnDiv,
  ContentContainerDiv,
  ContentWrapperDiv,
  PreviewContainer,
  PreviewContainerArtifactWrapper,
  PreviewHeaderContainerDiv,
} from './styles'

interface DialogContentProps {
  activeAction: ReducerState['activeAction']
  data: ReducerState['data']
  responseState: ReducerState['responseState']
  selectedExpenseId: ReducerState['selectedExpenseId']
  onSelect: (id: ItemIdType) => void
}

export default function DialogContent({
  activeAction,
  data,
  responseState,
  selectedExpenseId,
  onSelect,
}: DialogContentProps) {
  if (activeAction === DialogActions.INIT) {
    return (
      <ContentWrapperDiv>
        <CircularProgress color="primary" size={30} />
      </ContentWrapperDiv>
    )
  }
  if (responseState?.isError && responseState?.action === DialogActions.INIT) {
    return (
      <ContentWrapperDiv>
        <Typography align="center" color="error" size="400-sm">
          {responseState?.message}
        </Typography>
      </ContentWrapperDiv>
    )
  }
  if (responseState && !responseState?.isError && responseState?.action !== DialogActions.INIT) {
    return (
      <Typography align="center" size="400-sm">
        {responseState?.message}
      </Typography>
    )
  }
  if (!data) {
    // this only need for typescript
    throw Error('Invalid duplicate expense dialog state: missing data')
  }
  // show the duplicate invoice artifact or details when navMergeDirection === 'to_nav' otherwise there is the original one
  const showDuplicateOnLeft = data.navMergeDirection === 'to_nav'
  const leftColumnPreviewPath = showDuplicateOnLeft ? data.duplicatePath : data.originalPath
  const leftColumnPreviewId = showDuplicateOnLeft ? data.duplicateId : data.originalId
  return (
    <ContentContainerDiv>
      <ColumnDiv>
        <PreviewHeaderContainerDiv>
          <Typography tag="h4" size="700-xs">
            {data?.navMergeDirection ? (
              <FormattedMessage
                id="dialogs.title.duplicateInvoice.uploadedInvoice"
                defaultMessage="Feltöltött számla"
              />
            ) : (
              <FormattedMessage
                id="dialogs.title.duplicateInvoice.oldInvoice"
                defaultMessage="Korábban felismert számla"
              />
            )}
          </Typography>
          {data?.navMergeDirection !== 'to_nav' && data?.originalIsNav && <InvoicePreviewNavBadge />}
        </PreviewHeaderContainerDiv>
        <PreviewContainer>
          <PreviewInvoiceHeader showDuplicateInfo={showDuplicateOnLeft} data={data} />
          <PreviewContainerArtifactWrapper>
            {leftColumnPreviewPath ? (
              <ArtifactViewer fileUri={leftColumnPreviewPath} hideFileControls pdfVerticalScrollByDefault />
            ) : (
              <ExpensePreview expenseId={leftColumnPreviewId} />
            )}
          </PreviewContainerArtifactWrapper>
        </PreviewContainer>
      </ColumnDiv>

      {data.navMergeDirection ? (
        <ColumnDiv>
          <PreviewHeaderContainerDiv>
            <Typography tag="h4" size="700-xs">
              <FormattedMessage
                id="dialogs.title.navDuplicateInvoice.navInvoiceList"
                defaultMessage="NAV-ból importált, hasonló számlák"
              />
            </Typography>
          </PreviewHeaderContainerDiv>

          {data?.potentialExpenses.map((expense: PotentialExpense) => (
            <SelectableNavInvoiceCard
              data={expense}
              selected={expense.id === selectedExpenseId}
              onSelect={onSelect}
              key={`selectable-nav-invice-card-${expense.id}`}
            />
          ))}
        </ColumnDiv>
      ) : (
        <ColumnDiv>
          <PreviewHeaderContainerDiv>
            <Typography tag="h4" size="700-xs">
              <FormattedMessage id="dialogs.title.duplicateInvoice.newInvoice" defaultMessage="Új számla" />
            </Typography>
            {data?.duplicateIsNav && <InvoicePreviewNavBadge />}
          </PreviewHeaderContainerDiv>
          <PreviewContainer>
            <PreviewInvoiceHeader showDuplicateInfo data={data} />
            <PreviewContainerArtifactWrapper>
              {data?.duplicatePath ? (
                <ArtifactViewer fileUri={data?.duplicatePath} hideFileControls pdfVerticalScrollByDefault />
              ) : (
                <ExpensePreview expenseId={data?.duplicateId} />
              )}
            </PreviewContainerArtifactWrapper>
          </PreviewContainer>
        </ColumnDiv>
      )}
    </ContentContainerDiv>
  )
}

DialogContent.propTypes = {
  activeAction: PropTypes.string,
  data: PropTypes.object,
  responseState: PropTypes.shape({
    action: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]).isRequired,
    isError: PropTypes.bool.isRequired,
    state: PropTypes.shape({
      willRedirect: PropTypes.bool.isRequired,
      redirectUrl: PropTypes.string.isRequired,
    }),
  }),
  selectedExpenseId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
}
