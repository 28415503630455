// profile-menu uses
// custom in-case-sensitive filter only on label
export function inCaseSensitiveSearch(label, searchText) {
  return label.toLowerCase().includes(searchText.toLowerCase())
}

// only admin-company-selector component uses
export const filterOption = ({ label }, searchText) => {
  if (!searchText) {
    return true // no filtering
  }
  return inCaseSensitiveSearch(label, searchText)
}
