import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Checkbox } from '@components/ui/Checkbox'

import { filterFormStyles } from '@styles/filters'
import { searchFilterStyles } from '@components/filters/PageFilterBars/styles'
import { ChoicesWrapper } from '../styles'

const useStyles = makeStyles(searchFilterStyles)
const useFieldStyles = makeStyles(filterFormStyles)

interface MultiCheckboxFilterProps {
  values: CommonIdAndNameType[]
  onChange: (values: CommonIdAndNameType[]) => void
  config: { name: string; options: CommonIdAndNameType[] }
}

// TODO this component should match with FilterSelect:FormFilterSelect - checkbox variant
export default function MultiCheckboxFilter({ config: { options }, onChange, values }: MultiCheckboxFilterProps) {
  const classes = useStyles()
  const fieldClasses = useFieldStyles()

  function handleChange(option: CommonIdAndNameType) {
    return function handler(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.checked) {
        onChange([...values, option])
      } else {
        onChange(values.filter(({ id }) => id !== option.id))
      }
    }
  }

  return (
    <ChoicesWrapper>
      {options.map(option => (
        <FormControlLabel
          key={option.id}
          classes={{ label: fieldClasses.formControlLabel, root: classes.formControlRoot }}
          control={
            <Checkbox
              checked={Boolean(values.find(value => value.id === option.id))}
              className={classes.checkbox}
              onChange={handleChange(option)}
            />
          }
          label={option.name}
        />
      ))}
    </ChoicesWrapper>
  )
}

MultiCheckboxFilter.propTypes = {
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
