import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Typography } from '@components/ui'

const ErrorWrapper = styled.div`
  padding: 20px;
`

interface TableDataErrorProps {
  error: React.ReactChild
}

export default function TableDataError({ error }: TableDataErrorProps) {
  return (
    <ErrorWrapper>
      <Typography color="error" tag="span" size="400-xs">
        {error}
      </Typography>
    </ErrorWrapper>
  )
}

TableDataError.propTypes = {
  error: PropTypes.node.isRequired,
}
