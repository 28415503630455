import React from 'react'
import PropTypes from 'prop-types'

import { taxNumberFormatter } from '@helpers'

interface TaxNumberProps {
  value?: Nullable<string>
}

export default function TaxNumber({ value }: TaxNumberProps) {
  if (!value) {
    return null
  }
  return <span>{taxNumberFormatter.format(value)}</span>
}

TaxNumber.propTypes = { value: PropTypes.string }
