import React from 'react'
import PropTypes from 'prop-types'

// TODO maybe need replace these icons
import HelpIcon from '@material-ui/icons/Help'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'
import { LightTooltip } from '@oldComponents/ui'

const IndexTypography = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 2px;

  & > span {
    display: inline-flex;
    align-items: inherit;

    &:last-child {
      font-size: 14px;
    }
  }
`

interface KPIIndexProps {
  value?: number | null
}

export function KPIIndex({ value }: KPIIndexProps) {
  if (value == null) {
    return (
      <LightTooltip
        title={<FormattedMessage id="tooltips.kpi" defaultMessage="Nincs kiválaszott időszak" />}
        placement="bottom"
        PopperProps={{ disablePortal: true }}
      >
        <IndexTypography color="gray-80" size="700-xs">
          <FormattedMessage tagName="span" id="kpi.notAvailable" defaultMessage="N/A" />
          <span>
            <HelpIcon color="inherit" fontSize="inherit" />
          </span>
        </IndexTypography>
      </LightTooltip>
    )
  }

  return (
    <IndexTypography size="700-xs" color={value < 0 ? 'error' : 'success'}>
      <span>{value}%</span>
      <span>
        {value < 0 ? (
          <KeyboardArrowDownOutlinedIcon color="inherit" fontSize="inherit" />
        ) : (
          <KeyboardArrowUpOutlinedIcon color="inherit" fontSize="inherit" />
        )}
      </span>
    </IndexTypography>
  )
}

KPIIndex.propTypes = { value: PropTypes.number }
