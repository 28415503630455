import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import __union from 'lodash/union'

import { getTagsLabelList } from '@helpers'

import OverflowTextWithTooltip from '../OverflowTextWithTooltip'

const TagBadge = withStyles(theme => ({
  root: {
    display: 'inline-block',
    color: theme.palette.secondary.main,
    fontSize: 14,
    letterSpacing: 0.26,
    maxWidth: 180,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '1px 4px',
    margin: '0 3px 2px 0',
  },
  badge: {
    backgroundColor: theme.palette.secondary.xxxLight,
    color: theme.palette.tertiary.oceanBlue,
    borderRadius: 4,
  },
  purpleBadge: {
    borderRadius: 4,
    backgroundColor: theme.palette.tertiary.lightPurple,
    color: theme.palette.tertiary.darkPurple,
  },
}))(({ classes, children, withBackground, withPurpleBackground, ...rest }) => (
  <OverflowTextWithTooltip
    className={cx(classes.root, { [classes.badge]: withBackground, [classes.purpleBadge]: withPurpleBackground })}
    {...rest}
  >
    {children}
  </OverflowTextWithTooltip>
))

export function TagBadgeList(tags, { tags: autoTags, simpleTags }) {
  const selected = __union(autoTags, simpleTags)
  const tmp = getTagsLabelList(tags, selected)

  const length = tmp.length
  if (length > 2) {
    const badges = tmp.slice(0, 2).map((tag, index) => (
      <TagBadge
        key={index}
        withBackground={simpleTags.includes(tag.id)}
        withPurpleBackground={!simpleTags.includes(tag.id)}
      >
        {tag.name}
      </TagBadge>
    ))
    badges.push(<TagBadge key="more">+{length - 2}</TagBadge>)
    return badges
  } else {
    return tmp.map((tag, index) => (
      <TagBadge
        key={index}
        withBackground={simpleTags.includes(tag.id)}
        withPurpleBackground={!simpleTags.includes(tag.id)}
      >
        {tag.name}
      </TagBadge>
    ))
  }
}
