import actions from './actions'

export const initialState: LiquidityStore = {
  billingo_incomes: false,
  fetched: false,
  szamlazz_incomes: false,
}

type ReducerAction =
  | { type: '@liquidity/LOAD_DATA_SUCCESS'; payload: Omit<LiquidityStore, 'fetched'> }
  | { type: '@liquidity/SAVE_DATA_SUCCESS'; payload: Omit<LiquidityStore, 'fetched'> }

export default function reducer(state: LiquidityStore = initialState, action: ReducerAction) {
  switch (action.type) {
    case actions.loadData.SUCCESS:
      return {
        fetched: true,
        ...action.payload,
      }
    case actions.saveData.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
