import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { StrongTextValues } from '@helpers'

import { LightTooltip, UpsellMessage } from '@oldComponents/ui'

import { NavLinkTooltipVariant } from '../constants'

function getTitle(variant: NavLinkTooltipVariant) {
  switch (variant) {
    case NavLinkTooltipVariant.UPSELL:
    case NavLinkTooltipVariant.UPSELL_MULTIBOX:
      return <UpsellMessage type={variant} />
    case NavLinkTooltipVariant.COMPANY:
      return (
        <FormattedMessage
          id="navLink.tooltip.noCompany"
          defaultMessage="<b>Még nem elérhető</b>{br}Hozz létre egy céget,{br}hogy láthasd ezt az oldalt."
          values={{
            ...StrongTextValues,
            br: <br />,
          }}
        />
      )

    case NavLinkTooltipVariant.SUBSCRIPTION:
      return (
        <FormattedMessage
          id="navLink.tooltip.noSubscription"
          defaultMessage="<b>Nem elérhető</b>{br}Érvényes előfizetéssel kell rendelkezned,{br}hogy láthasd ezt az oldalt."
          values={{
            ...StrongTextValues,
            br: <br />,
          }}
        />
      )

    case NavLinkTooltipVariant.SELECT:
    default:
      return (
        <FormattedMessage
          id="navLink.tooltip.selectCompany"
          defaultMessage="<b>Még nem elérhető</b>{br}Válassz ki egy céget,{br}hogy láthasd ezt az oldalt."
          values={{
            ...StrongTextValues,
            br: <br />,
          }}
        />
      )
  }
}

const TooltipWrapperSpan = styled.span`
  display: inline-block;
  cursor: help;
  color: ${({ theme }) => theme.colors.gray[130]};
`

interface NoLinkTooltipProps {
  children: React.ReactNode
  variant: NavLinkTooltipVariant
}

export function NoLinkTooltip({ children, variant }: NoLinkTooltipProps) {
  return (
    <LightTooltip title={getTitle(variant)} placement="bottom">
      <TooltipWrapperSpan>{children}</TooltipWrapperSpan>
    </LightTooltip>
  )
}

NoLinkTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.values(NavLinkTooltipVariant)).isRequired,
}
