import React from 'react'

import { Props as ModalProps } from 'react-modal'
import styled, { css } from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { DIALOG_PADDING } from '@constants'

import { BaseDialog } from './BaseDialog'

export const DialogHeader = styled.div<{ $borderless?: boolean }>`
  top: 0;
  position: sticky;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[0]};
  z-index: 10;
  padding: ${DIALOG_PADDING}px;
  border-bottom: ${({ $borderless = false, theme }) => ($borderless ? 'none' : `1px solid ${theme.colors.gray[110]}`)};
`

/**
 * Building block for `CustomDialog`. Primary scrollable area.
 *
 * Simple styled component to be used as the body for a dialog.
 */
export const CustomDialogBody = styled.div.attrs<{ aria?: ModalProps['aria'] }>(({ aria }) => ({
  id: aria?.describedby,
}))`
  padding: ${DIALOG_PADDING}px;
  overflow: auto;
  position: relative;
`

interface CustomDialogActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  borderless?: boolean
}

/**
 * Building block for `CustomDialog`. Sticks to the bottom and has a separator at the top.
 *
 * Simple styled component to be used as actions area for a dialog.
 */
export const CustomDialogActions = styled.div.withConfig<CustomDialogActionsProps>({
  shouldForwardProp: (prop, defaultValidator) => {
    return !['borderless'].includes(prop) && defaultValidator(prop)
  },
})`
  border-top: ${({ theme, borderless = false }) => (borderless ? 'none' : `1px solid ${theme.colors.gray[110]}`)};
  padding: ${DIALOG_PADDING}px;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.gray[0]};
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 10;
`

export const ScrollableBaseDialog = styled(BaseDialog)<{
  $isFullScreen?: boolean
  $isScrollable: boolean
  $isFullWidth?: boolean
}>`
  ${({ $isScrollable, theme }) =>
    $isScrollable &&
    css`
      scroll-padding-top: calc(var(--scroll-padding-top, 0) * 1px);
      scroll-padding-bottom: calc(var(--scroll-padding-bottom, 0) * 1px);

      ${DialogHeader} {
        border-bottom: none;

        &:after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          height: 10px;
          background: linear-gradient(
            180deg,
            ${theme.colors.blue[40]} 0%,
            ${applyOpacity(theme.colors.blue[40], 0)} 100%
          );
          mix-blend-mode: multiply;
          opacity: 0.1;
        }
      }

      ${CustomDialogActions} {
        border-top: none;

        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          height: 10px;
          background: linear-gradient(
            0deg,
            ${theme.colors.blue[40]} 0%,
            ${applyOpacity(theme.colors.blue[40], 0)} 100%
          );
          mix-blend-mode: multiply;
          opacity: 0.1;
        }
      }
    `}

  ${({ $isFullScreen }) =>
    $isFullScreen &&
    css`
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      ${CustomDialogBody} {
        width: inherit;
        height: inherit;
        overflow: inherit;
      }
    `}

    ${({ $isFullScreen, $isFullWidth }) =>
    $isFullWidth &&
    !$isFullScreen &&
    css`
      width: 100%;
    `}
`
