export enum PaidStatusFilterKeys {
  PAID = 'isPaid',
  EXPIRING = 'isExpiring',
  EXPIRED = 'isExpired',
}

export enum PaidStatusFilterInnerKeys {
  EXPIRING = 'expiringDays',
  EXPIRED = 'expiredDays',
}

type Fields = Array<{
  name: PaidStatusFilterKeys
  label: string
  inner?: {
    name: PaidStatusFilterInnerKeys
    options: Array<{ name: string; label: string }>
  }
}>

export type PaidStatusFilterValues = Record<PaidStatusFilterKeys, boolean> & Record<PaidStatusFilterInnerKeys, string[]>

export interface CheckboxListProps {
  classes: Record<string, string>
  fields: Fields
  onChange: (payload: PaidStatusFilterValues) => void
  value: PaidStatusFilterValues
}

export interface WidgetPaidStatusFilterProps extends Omit<CheckboxListProps, 'classes'> {
  disabled?: boolean
}

export type FormPaidStatusFilterProps = Omit<CheckboxListProps, 'classes'>

export interface PaidStatusFilterProps {
  disabled?: boolean
  variant?: string
  value: PaidStatusFilterValues
  onChange: (payload: PaidStatusFilterValues) => void
}
