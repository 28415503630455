import React from 'react'

import { FormControl, FormControlLabel, Switch } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import cx from 'classnames'
import { useController, useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { LightTooltip } from '@oldComponents/ui'

const LabelWithInfoIconWrapperSpan = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
`

const IconWrapperSpan = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue[40]};
`

const StyledFormControl = styled(FormControl)`
  &.form-control {
    margin-top: 20px;
  }
`

const SwitchOffLabelMessage = (
  <FormattedMessage
    id="form.label.vatValidationField.off"
    defaultMessage="A számla adatait ellenőriztem. Az áfaértékre vonatkozó automatikus ellenőrzést kikapcsolom erre a számlára."
  />
)
const SwitchOnLabelMessage = (
  <FormattedMessage
    id="form.label.vatValidationField.on"
    defaultMessage="Az áfaértékre vonatkozó automatikus ellenőrzés kikapcsolva ezen a számlán."
  />
)
const SwitchOnTooltipMessage = (
  <FormattedMessage
    id="form.label.vatValidationField.tooltip"
    defaultMessage="A számla korábbi mentésekor eltérést találtunk az ÁFA értékben, amelyet a számla szerkesztője helyesnek ítélt, ezért kikapcsolta az erre vonatkozó ellenőrzést. A részleteket a számlatörténetben találod. Az automatikus ellenőrzéshez állítsd át a kapcsolót."
  />
)

const FIELD_NAME = 'vat_validation_disabled'

interface DisableVatValidationFieldProps {
  className?: string
  disabled: boolean
}

export function DisableVatValidationField({ className, disabled }: DisableVatValidationFieldProps) {
  const {
    control,
    formState: { defaultValues, isSubmitting, isSubmitSuccessful },
  } = useFormContext()

  const {
    field,
    fieldState: { error },
  } = useController({ control, name: FIELD_NAME })

  const initial = defaultValues?.[FIELD_NAME] || false
  // keep visible when initial true or when submitError is present
  const [visible, setVisible] = React.useState(initial === true)

  const hasError = Boolean(error)
  const isFieldDisabled = disabled || isSubmitting

  const inputId = `${FIELD_NAME}-switch`

  React.useEffect(() => {
    // set visible when initial is false and hasError is true
    if (initial === false && hasError && !visible) {
      setVisible(true)
    }
  }, [hasError, initial, visible])

  React.useEffect(() => {
    // hide after successful submit if no error
    if (initial === false && !hasError && !field.value && visible && isSubmitSuccessful) {
      setVisible(false)
    }
  }, [field.value, hasError, initial, isSubmitSuccessful, visible])

  if (!visible) {
    return null
  }

  return (
    <StyledFormControl
      className={cx(className, 'form-control', { 'form-control-error': hasError })}
      error={hasError}
      fullWidth
      margin="normal"
    >
      <FormControlLabel
        control={<Switch color="primary" disabled={isFieldDisabled} id={inputId} {...field} checked={field.value} />}
        htmlFor={inputId}
        label={
          initial ? (
            <LabelWithInfoIconWrapperSpan>
              {SwitchOnLabelMessage}
              <LightTooltip title={SwitchOnTooltipMessage} placement="top">
                <IconWrapperSpan>
                  <InfoIcon style={{ fontSize: 20 }} color="inherit" />
                </IconWrapperSpan>
              </LightTooltip>
            </LabelWithInfoIconWrapperSpan>
          ) : (
            SwitchOffLabelMessage
          )
        }
      />
    </StyledFormControl>
  )
}
