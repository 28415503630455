import { DATE_FILTER_KEYS } from '@constants'

import {
  AccountingPeriodOptionMessage,
  CashFlowDateOptionMessage,
  CreatedAtOptionMessage,
  CurrentMonthOptionMessage,
  DueAtOptionMessage,
  ExportedAtOptionMessage,
  FulfilledAtOptionMessage,
  IssuedAtOptionMessage,
  PaymentDateOptionMessage,
  ValueDateOptionMessage,
} from '@messages'

export const DOCUMENTS_DATE_FIELD_OPTIONS = [
  {
    key: DATE_FILTER_KEYS.CREATED_AT,
    label: CreatedAtOptionMessage,
  },
]

export const PAYMENT_ORDERS_DATE_FIELD_OPTIONS = [
  {
    key: DATE_FILTER_KEYS.VALUE_DATE,
    label: ValueDateOptionMessage,
  },
]

export const BANK_TRANSACTIONS_DATE_FIELD_OPTIONS = [
  {
    key: DATE_FILTER_KEYS.VALUE_DATE,
    label: ValueDateOptionMessage,
  },
]

export const PAYMENT_TRANSACTIONS_DATE_FIELD_OPTIONS = [
  {
    key: DATE_FILTER_KEYS.PAYMENT_DATE,
    label: ValueDateOptionMessage,
  },
]

export const EXPENSE_LIST_DATE_FIELD_OPTIONS = [
  {
    key: DATE_FILTER_KEYS.FULFILLED_AT,
    label: FulfilledAtOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.ACCOUNTING_PERIOD,
    label: AccountingPeriodOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.FINANCIAL_FULFILLMENT,
    label: PaymentDateOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.CREATED_AT,
    label: CreatedAtOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.ISSUED_AT,
    label: IssuedAtOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.DUE_AT,
    label: DueAtOptionMessage,
  },
]

export const ADVANCED_ACCOUNTING_EXPENSE_LIST_DATE_FIELD_OPTIONS = [
  ...EXPENSE_LIST_DATE_FIELD_OPTIONS,
  {
    key: DATE_FILTER_KEYS.EXPORTED_AT,
    label: ExportedAtOptionMessage,
  },
]

export const QUARANTINE_LIST_DATE_FIELD_OPTIONS = [
  {
    key: DATE_FILTER_KEYS.FULFILLED_AT,
    label: FulfilledAtOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.CREATED_AT,
    label: CreatedAtOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.ISSUED_AT,
    label: IssuedAtOptionMessage,
  },
]

// same as expense options
export const INCOME_LIST_DATE_FIELD_OPTIONS = EXPENSE_LIST_DATE_FIELD_OPTIONS

export const SALARY_LIST_DATE_FIELD_OPTIONS = [
  { key: DATE_FILTER_KEYS.DUE_AT, label: DueAtOptionMessage },
  { key: DATE_FILTER_KEYS.CURRENT_MONTH, label: CurrentMonthOptionMessage },
  { key: DATE_FILTER_KEYS.CREATED_AT, label: CreatedAtOptionMessage },
  { key: DATE_FILTER_KEYS.FINANCIAL_FULFILLMENT, label: PaymentDateOptionMessage },
]

// same as salary options
export const TAX_LIST_DATE_FIELD_OPTIONS = SALARY_LIST_DATE_FIELD_OPTIONS

export const MOTHLY_STATEMENT_DATE_FIELD_OPTIONS = [
  {
    key: DATE_FILTER_KEYS.FULFILLED_AT,
    label: FulfilledAtOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.ACCOUNTING_PERIOD,
    label: AccountingPeriodOptionMessage,
  },
  {
    key: DATE_FILTER_KEYS.FINANCIAL_FULFILLMENT,
    label: CashFlowDateOptionMessage,
  },
]

export const PARTNER_LIST_DATE_FIELD_OPTIONS = [
  {
    key: DATE_FILTER_KEYS.FULFILLED_AT,
    label: FulfilledAtOptionMessage,
  },
]
