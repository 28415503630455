import React from 'react'
import PropTypes from 'prop-types'

import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'

import { WarningText } from '@components/ui'
import { TrashRegularIcon } from '@components/ui/svgIcons'
import { Typography } from '@components/ui/Typography'

import { UploadedFileProps } from './types'
import { UploadedFileError } from './UploadedFileError'

import {
  IconWrapper,
  ListItem,
  ListItemActionsContainer,
  ListItemBody,
  ListItemContent,
  ListItemText,
  StyledIconButton,
} from './styles'

export function UploadedFile({ actionDisabled, onDelete, rejected, data, divider }: UploadedFileProps) {
  return (
    <ListItem $divider={divider}>
      <IconWrapper $rejected={rejected}>
        <InsertDriveFileOutlinedIcon fontSize="inherit" color="inherit" />
      </IconWrapper>
      <ListItemContent>
        <ListItemBody>
          <ListItemText>
            <Typography color={rejected ? 'gray-40' : 'gray-80'} size="400-md" tag="span">
              {rejected ? data.file.name : data.name}
            </Typography>
          </ListItemText>
          <ListItemActionsContainer>
            <StyledIconButton
              data-testid="attachment-delete-button"
              onClick={onDelete}
              size="small"
              disabled={actionDisabled}
            >
              <TrashRegularIcon />
            </StyledIconButton>
          </ListItemActionsContainer>
        </ListItemBody>
        {rejected && (
          <WarningText>
            <UploadedFileError errors={data.errors} />
          </WarningText>
        )}
      </ListItemContent>
    </ListItem>
  )
}

UploadedFile.propTypes = {
  actionDisabled: PropTypes.bool,
  data: PropTypes.object.isRequired,
  divider: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  rejected: PropTypes.bool,
}
