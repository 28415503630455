import React from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import styled from 'styled-components'

import { ButtonBase, LabelInfoIcon, Typography } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { DeselectAllMessage, SelectAllMessage } from '@messages'

const WrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SelectionButton = styled(ButtonBase)`
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blue[40]};
  font-weight: 700;
  font-size: 12px;

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    box-shadow: none;
    text-decoration: underline;
  }
`

const TOGGLE_VARIANTS = {
  selectOnly: 'selectOnly',
  select: 'select',
  deselect: 'deselect',
  deselectOnly: 'deselectOnly',
} as const

const TOGGLE_SELECTION_STATES = {
  all: 'all',
  none: 'none',
  some: 'some',
} as const

/**
 * Helper function to determine if the button is visible or not. We wish to hide the button sometimes if it is in `selectOnly` or `deselectOnly` variants.
 *
 * @param {FilterSelectionState} toggleSelectionState - The selection state of the filter field.
 * @param {typeof TOGGLE_VARIANTS} toggleVariant - The toggle variant of the filter field.
 * @returns {boolean} - Whether the button is visible or not.
 */
function getButtonVisibility(toggleSelectionState: FilterSelectionState, toggleVariant: keyof typeof TOGGLE_VARIANTS) {
  if (toggleVariant === TOGGLE_VARIANTS.selectOnly && toggleSelectionState === TOGGLE_SELECTION_STATES.all) {
    return false
  } else if (toggleVariant === TOGGLE_VARIANTS.deselectOnly && toggleSelectionState === TOGGLE_SELECTION_STATES.none) {
    return false
  } else {
    return true
  }
}

/**
 * Helper function to decide on the text to display in the button.
 *
 * @param {TOGGLE_SELECTION_STATES} toggleSelectionState - The selection state of the filter field.
 * @param {TOGGLE_VARIANTS} toggleVariant - The toggle variant of the filter field.
 * @returns - The text to display in the button.
 */
function getButtonLabel(toggleSelectionState: FilterSelectionState, toggleVariant: keyof typeof TOGGLE_VARIANTS) {
  if (toggleVariant === TOGGLE_VARIANTS.selectOnly) {
    return SelectAllMessage
  } else if (toggleVariant === TOGGLE_VARIANTS.select) {
    if (toggleSelectionState !== TOGGLE_SELECTION_STATES.all) {
      return SelectAllMessage
    } else {
      return DeselectAllMessage
    }
  } else if (toggleVariant === TOGGLE_VARIANTS.deselectOnly) {
    return DeselectAllMessage
  } else if (toggleVariant === TOGGLE_VARIANTS.deselect) {
    if (toggleSelectionState !== TOGGLE_SELECTION_STATES.none) {
      return DeselectAllMessage
    } else {
      return SelectAllMessage
    }
  }
}

interface FilterFieldLabelBase {
  isAllSelected?: never
  label: StringOrMessage
  onToggleClick?: never
  toggleSelectionState?: never
  toggleVariant?: never
  tooltipText: StringOrMessage
}

interface FilterFieldLabelWithToggle extends Pick<FilterFieldLabelBase, 'label' | 'tooltipText'> {
  onToggleClick: VoidFunction
  toggleSelectionState: FilterSelectionState
  toggleVariant: keyof typeof TOGGLE_VARIANTS
}

type FilterFieldLabelProps = FilterFieldLabelBase | FilterFieldLabelWithToggle

/**
 * Component for rendering a label for a filter field with an optional toggle button.
 *
 * @param {FilterFieldLabelProps} { label, tooltipText, onClick, isAllSelected }
 */
export function FilterFieldLabel({
  label,
  onToggleClick,
  toggleSelectionState,
  toggleVariant,
  tooltipText,
}: FilterFieldLabelProps) {
  const isToggleable = toggleVariant !== undefined && onToggleClick !== undefined && toggleSelectionState !== undefined
  return (
    <WrapperDiv>
      <div>
        <Typography size="700-xs" color="gray-80">
          {label}&nbsp;
          <LightTooltip
            PopperProps={{ style: { zIndex: 1400 } }} // dialog has z-index of 1300
            title={tooltipText}
          >
            <LabelInfoIcon />
          </LightTooltip>
        </Typography>
      </div>
      {isToggleable && (
        <Fade
          in={getButtonVisibility(toggleSelectionState, toggleVariant)}
          timeout={{
            exit: 0,
            enter: 225,
          }}
        >
          <SelectionButton onClick={onToggleClick}>
            {getButtonLabel(toggleSelectionState, toggleVariant)}
          </SelectionButton>
        </Fade>
      )}
    </WrapperDiv>
  )
}

FilterFieldLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onToggleClick: PropTypes.func,
  toggleSelectionState: PropTypes.oneOf(Object.keys(TOGGLE_SELECTION_STATES)),
  toggleVariant: PropTypes.oneOf(Object.keys(TOGGLE_VARIANTS)),
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}
