import React from 'react'
import PropTypes from 'prop-types'

import BraintreeContext from './context'

// set fieldName prop in obj to true
function reduceFields(obj, fieldName) {
  obj[fieldName] = true
  return obj
}

function usePrevious(value) {
  const ref = React.useRef()

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

// custom hook to handle when all errors cleared - clear global form error
function useErrors(obj, cb) {
  const hasError = React.useMemo(() => Object.values(obj).some(cond => cond), [obj])
  const prevHasError = usePrevious(hasError)

  React.useEffect(() => {
    if (prevHasError && !hasError) {
      cb()
    }
  }, [cb, hasError, prevHasError])

  return hasError
}

// HOC to add form states to Braintree hosted fields: touched, focused, error
export default function BraintreeForm({ amount, onSubmitFailure, onSubmitSuccess, render, setSubmitting }) {
  const braintree = React.useContext(BraintreeContext)
  const [errors, setErrors] = React.useState({})
  const [focused, setFocused] = React.useState({})
  const [touched, setTouched] = React.useState({})

  // automatically clear global error when fields are valid
  const hasError = useErrors(errors, braintree.clearError)

  // hosted field callback to set field validity
  const onValidityChange = React.useCallback(
    field => response => {
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: !response.isValid,
      }))
    },
    [setErrors]
  )

  const onFieldFocus = React.useCallback(
    field => () => {
      setFocused(prevFocused => ({
        ...prevFocused,
        [field]: true,
      }))
    },
    [setFocused]
  )

  const onFieldBlur = React.useCallback(
    field => () => {
      setFocused(prevFocused => ({
        ...prevFocused,
        [field]: false,
      }))
      setTouched(prevTouched => ({
        ...prevTouched,
        [field]: true,
      }))
    },
    [setFocused, setTouched]
  )

  const hasFieldError = React.useCallback(
    field => {
      return touched[field] && errors[field]
    },
    [errors, touched]
  )

  const hasFieldFocus = React.useCallback(
    field => {
      return focused[field]
    },
    [focused]
  )

  // helper to handle Braintree 3DSecure verifyCard error: BraintreeError
  const onVerifyFailure = React.useCallback(
    err => {
      // Braintree return invalidFieldKeys as details
      const { details: { invalidFieldKeys = [] } = {} } = err
      // process errors and set fields' errors
      if (invalidFieldKeys.length) {
        setErrors(prevErrors => invalidFieldKeys.reduce(reduceFields, { ...prevErrors }))
      }

      if (onSubmitFailure) {
        onSubmitFailure(err, () => setSubmitting(false))
      } else {
        setSubmitting(false)
      }
    },
    [onSubmitFailure, setErrors, setSubmitting]
  )

  const onVerifySuccess = React.useCallback(
    response => {
      if (onSubmitSuccess) {
        onSubmitSuccess(response, () => setSubmitting(false))
      } else {
        setSubmitting(false)
      }
    },
    [onSubmitSuccess, setSubmitting]
  )

  const handleSubmit = React.useCallback(() => {
    braintree.clearError()
    setSubmitting(true)
    setErrors({})
    // set all field touched
    setTouched(Object.keys(braintree.fields).reduce(reduceFields, {}))

    braintree.tokenize({
      amount,
      onVerifySuccess,
      onVerifyFailure,
      // NOTE can be added: billingAddress, email, etc...
    })
  }, [setSubmitting, braintree, amount, onVerifySuccess, onVerifyFailure])

  return render({
    hasError,
    hasFieldError,
    hasFieldFocus,
    onFieldBlur,
    onFieldFocus,
    onValidityChange,
    handleSubmit,
  })
}

BraintreeForm.defaultProps = {
  onSubmitFailure: undefined,
  onSubmitSuccess: undefined,
}
BraintreeForm.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSubmitFailure: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  render: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
}
