import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  'APPLY_PROMOCODE',
  'CANCEL_SUBSCRIPTION',
  '!CREATE_SUBSCRIPTION',
  'UPDATE_SUBSCRIPTION',
  'FETCH_SUBSCRIPTION_HISTORY',
  'FETCH_SUBSCRIPTION_PLANS',
  '!CREATE_PAYMENT_METHOD_NONCE',
] as const

export default serviceActionsGenerator<typeof actionTypes>('subscription', actionTypes)
