const SEPARATORS: Record<Locale, string[]> = {
  en: [',', '.'],
  hu: [' ', ','],
}

export function useNumberFormatSeparators(locale: Locale) {
  const [thousandSeparator, decimalSeparator] = SEPARATORS[locale]

  return {
    thousandSeparator,
    decimalSeparator,
  }
}
