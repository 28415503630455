import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { Collapse, InformationalMessage } from '@components/ui'

import { useBinXProvider } from './useBinXProvider'

interface BinxInfoMessageProps {
  paidThroughOptions: PaidThroughOption[]
}

function PureBinxInfoMessage({ paidThroughOptions }: BinxInfoMessageProps) {
  const isExpanded = useBinXProvider(paidThroughOptions)

  return (
    <Collapse isExpanded={isExpanded}>
      <InformationalMessage iconSize={16} typographySize="700-xs" variant="info">
        <FormattedMessage
          id="messages.payment.info.binx"
          defaultMessage="BinX esetében az utalások aznapi dátummal egyelőre nem tudnak teljesülni. Ha a csomagban mai fizetési időpontot állítasz be, akkor azt a BinX el fogja utasítani."
        />
      </InformationalMessage>
    </Collapse>
  )
}

export const BinxInfoMessage = connect((state: Store) => ({
  paidThroughOptions: state.payment.paidThroughOptions,
}))(PureBinxInfoMessage)

BinxInfoMessage.displayName = 'BinxInfoMessage'
