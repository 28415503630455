import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { FlaskRoundPotionRegularIcon } from '@components/ui'
import { ExternalLink } from '@components/ui/Links'
import { Typography } from '@components/ui/Typography'

const CardWrapperSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 24px;
  gap: 20px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  min-width: 340px;
`

const HeaderDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const LinkWrapperDiv = styled.div`
  display: flex;
  margin-top: 20px;
`

const IconWrapperDiv = styled.div`
  flex-shrink: 0;
  flex-basis: 24px;
  height: 24px;
`

export interface ExternalHelperCardProps {
  iconVariant: string //TODO: implement this
  title: string
  headingTag?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  description: JSX.Element
  buttonText?: string
  buttonLink: string
}

export function ExternalHelperCard({
  title,
  headingTag = 'h2',
  description,
  buttonText,
  buttonLink,
}: ExternalHelperCardProps) {
  return (
    <CardWrapperSection>
      <HeaderDiv>
        <IconWrapperDiv>
          <FlaskRoundPotionRegularIcon />
        </IconWrapperDiv>
        <Typography tag={headingTag} size="heading-4">
          {title}
        </Typography>
      </HeaderDiv>
      <Typography size="400-sm">{description}</Typography>
      <LinkWrapperDiv>
        <ExternalLink
          href={buttonLink}
          text={buttonText ?? <FormattedMessage id="general.buttons.externalLink" defaultMessage="Ugrás" />}
          iconSize={24}
          typographySize="navigation-md"
        />
      </LinkWrapperDiv>
    </CardWrapperSection>
  )
}
