export const PAYMENT_TRANSACTION_TYPE = {
  expense: 'expense',
  salary: 'salary',
  tax: 'tax',
} as const

export const ALL_PAYMENT_TRANSACTION_TYPES = {
  ...PAYMENT_TRANSACTION_TYPE,
  income: 'income',
} as const

export const PAYMENT_FLOW = {
  express: 'express',
  manual: 'manual',
  transfer: 'transfer',
} as const

export enum PaymentDialogState {
  FORM = 'form',
  SUMMARY = 'summary',
  RESPONSE = 'response',
  CALL_OF_ATTENTION = 'coa',
}

export const EXPRESS_PAID_THROUGH_NAME = 'Expressz' // keep synced with backend
