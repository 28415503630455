import { serialize } from 'object-to-formdata'
import Qs from 'qs'

import { callUrl } from '@services/common/api'

import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_QUARANTINE_DETAILS_URL,
  API_QUARANTINE_EXPENSE_PREVIEW_URL,
  API_QUARANTINE_MERGE_INVOICES_URL,
  V2_API_QUARANTINE_ACTIONS_URL,
  V2_API_QUARANTINE_CHARTS_URL,
  V2_API_QUARANTINE_EXPENSE_EXISTS_URL,
  V2_API_QUARANTINE_LIST_URL,
} from './urls'

export function fetchQuarantineInvoiceDetails(companyId: number, payload: unknown) {
  return Api.get(injectCompanyToURL(API_QUARANTINE_DETAILS_URL, companyId).replace('{invoice_id}', String(payload)))
}

//* ARTIFACTS
export function uploadQuarantineInvoice(
  companyId: number,
  { invoice, ...data }: Record<string, unknown> & { invoice: number }
) {
  return Api.patch(
    injectCompanyToURL(API_QUARANTINE_DETAILS_URL, companyId).replace('{invoice_id}', String(invoice)),
    data,
    {
      timeout: 5 * 60000, // 5 * 60sec
      transformRequest: [
        function (data) {
          return serialize(data)
        },
      ],
    }
  )
}

//* INVOICE ACTIONS
export function updateQuarantineInvoice(companyId: number, { id, ...data }: Record<string, unknown>) {
  return Api.patch(injectCompanyToURL(API_QUARANTINE_DETAILS_URL, companyId).replace('{invoice_id}', String(id)), data)
}

export function mergeInvoices(companyId: number, data: Record<string, unknown>) {
  return Api.post(injectCompanyToURL(API_QUARANTINE_MERGE_INVOICES_URL, companyId), data)
}

//* EXPENSE PREVIEW
export function fetchExpenseDetails(companyId: number, invoiceId: number) {
  return Api.get(
    injectCompanyToURL(API_QUARANTINE_EXPENSE_PREVIEW_URL, companyId).replace('{invoice_id}', String(invoiceId))
  )
}

//* V2 API
export function fetchQuarantineListV2(companyId: number, params: Partial<QuarantineListStoreFilters>) {
  return Api.get(injectCompanyToURL(V2_API_QUARANTINE_LIST_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params)
      },
    },
  })
}

export function fetchQuarantineCharts(companyId: number, params: Partial<QuarantineListStoreFilters>) {
  return Api.get(injectCompanyToURL(V2_API_QUARANTINE_CHARTS_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params)
      },
    },
  })
}

// need to remove the protocol, domain and port information from the url in DEV only for proxy request properly
export function fetchQuarantineListByPagingV2(url: string) {
  return callUrl({ method: 'get', url })
}

//* SPECIAL BACKGROUND-ACTION API
export function quarantineBackgroundAction(companyId: number, payload: unknown) {
  return Api.post<BackgroundActionResponse>(injectCompanyToURL(V2_API_QUARANTINE_ACTIONS_URL, companyId), payload)
}

export function checkQuarantineExpenseExists(companyId: number) {
  return Api.get(injectCompanyToURL(V2_API_QUARANTINE_EXPENSE_EXISTS_URL, companyId))
}
