export enum StatusFilters {
  // EXPENSE V2 NEW STATUSES
  HAS_ATTACHMENT = 'hasAttachment',
  HAS_ARTIFACT = 'hasArtifact',
  HAS_EXPENSE_TYPE = 'hasExpenseType',
  HAS_POST_IT = 'hasPostIt',
  IS_APPROVED = 'isApproved',
  IS_DUPLICATE = 'isDuplicate',
  IS_FILED = 'isFiled',
  IS_NEW = 'isNew',
  IS_MISSING = 'isMissing',
  IS_READY_TO_SYNC_SZAMLAZZ = 'isReadyToSync',
  IS_READY_TO_SYNC_ACOUNTO = 'isReadyToAcountoSync',
  IS_UPDATED = 'isUpdated',
  HAS_REVENUE_TYPE = 'hasRevenueType',
  //* advanced accounting
  HAS_ACCOUNTING_TODO = 'hasAccountingTodo', // van könyvelési teendő / nincs
  HAS_UNFINISHED_ACCOUNTING_TODO = 'hasUnfinishedAccountingTodo', // nyitott teendő
  IS_ACCOUNTING_EXPORTED = 'isAccountingExported', // exportált
  IS_ACCOUNTING_TODO_DONE = 'isAccountingTodoDone', // könyvelési teendő kész
  IS_EXCLUDED_FROM_ACCOUNTING = 'isExcludedFromAccounting', // könyvelésből kizárva
  IS_PROCESSED = 'isProcessed', // False: express könyvelés - könyveletlen / advanced könyvelés - feldolgozatlan
  //* document list
  CONTAINS_PAYABLE = 'containsPayable',
  IS_AI_RECOGNITION_ALLOWED = 'isAiRecognitionAllowed',
}

export const TASK_STATUS = {
  current: 'current',
  skipped: 'skipped',
  completed: 'completed',
  disabled: 'disabled',
} as const
