import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { attachmentActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage, userHasEditorPermission } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { AddCircleIcon, ButtonWithIcon } from '@components/ui'

import { AttachmentsList, UploadView } from './elements'
import { Actions, initialState, reducer } from './reducer'
import {
  AttachmentDocumentType,
  BackendAttachmentFileData,
  CreateAttachmentSuccessFunction,
  RemoveSuccessHandlerFunction,
} from './types'

const StyledButtonWithIcon = styled(ButtonWithIcon)`
  margin-top: 40px;
`

export enum ChangeEventTypes {
  CREATE_SUCCESS = 'CREATE_SUCCESS',
  REMOVE_SUCCESS = 'REMOVE_SUCCESS',
}

type AsyncPayload = {
  attachmentId: number
  documentId: number
  documentType: AttachmentDocumentType
}

export interface AttachmentsContainerProps {
  active: boolean
  callFetchAttachments: AsyncFunction<
    { documentId: number; documentType: AttachmentDocumentType },
    BackendAttachmentFileData[]
  >
  documentId: number
  documentType: AttachmentDocumentType
  downloadAttachment: AsyncFunction<AsyncPayload>
  isEditorUser: boolean
  onChange?: (eventType: ChangeEventTypes) => void
  removeAttachment: AsyncFunction<AsyncPayload>
}

function PureAttachmentsContainer({
  active,
  callFetchAttachments,
  documentId,
  documentType,
  downloadAttachment,
  isEditorUser,
  onChange,
  removeAttachment,
}: AttachmentsContainerProps) {
  const [{ fetched, ...filesListProps }, dispatch] = React.useReducer(reducer, initialState)
  const [showOverlay, setShowOverlay] = React.useState(false)
  const cPromiseRef = useCancellablePromiseRef<BackendAttachmentFileData[]>()

  React.useEffect(() => {
    async function fetchAttachments() {
      cPromiseRef.current = cancellablePromise(callFetchAttachments({ documentId, documentType }))
      try {
        const payload = await cPromiseRef.current.promise
        dispatch({ type: Actions.FETCH_SUCCESS, payload })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          dispatch({ type: Actions.FETCH_FAILED, payload: errorMessage })
        }
      }
    }
    if (active && !fetched) {
      fetchAttachments()
    }
  }, [active, cPromiseRef, callFetchAttachments, documentId, documentType, fetched])

  const removeHandler = React.useCallback<AsyncFunction<BackendAttachmentFileData>>(
    attachment =>
      removeAttachment({
        attachmentId: attachment.id,
        documentId,
        documentType,
      }),
    [documentId, documentType, removeAttachment]
  )

  const downloadHandler = React.useCallback<AsyncFunction<BackendAttachmentFileData>>(
    attachment =>
      downloadAttachment({
        attachmentId: attachment.id,
        documentId,
        documentType,
      }),
    [documentId, documentType, downloadAttachment]
  )

  const removeSuccessHandler = React.useCallback<RemoveSuccessHandlerFunction>(
    payload => {
      dispatch({ type: Actions.REMOVE_SUCCESS, payload })
      onChange?.(ChangeEventTypes.REMOVE_SUCCESS)
    },
    [onChange]
  )

  // OVERLAY control
  function handleOpen() {
    setShowOverlay(true)
  }

  function handleClose() {
    setShowOverlay(false)
  }

  const createAttachmentSuccess = React.useCallback<CreateAttachmentSuccessFunction>(
    payload => {
      dispatch({ type: Actions.CREATE_SUCCESS, payload })
      setShowOverlay(false)
      onChange?.(ChangeEventTypes.CREATE_SUCCESS)
    },
    [onChange]
  )

  return (
    <>
      {showOverlay ? (
        <UploadView
          onClose={handleClose}
          documentId={documentId}
          documentType={documentType}
          onSuccess={createAttachmentSuccess}
        />
      ) : (
        <>
          <AttachmentsList
            {...filesListProps}
            documentType={documentType}
            onDelete={removeHandler}
            onDeleteSuccess={removeSuccessHandler}
            onDownload={downloadHandler}
          />
          {isEditorUser && (
            <StyledButtonWithIcon variant="primaryContained" icon={<AddCircleIcon />} onClick={handleOpen}>
              <FormattedMessage id="attachments.buttons.addNewAttachement" defaultMessage="Új csatolmány hozzáadása" />
            </StyledButtonWithIcon>
          )}
        </>
      )}
    </>
  )
}

PureAttachmentsContainer.propTypes = {
  active: PropTypes.bool.isRequired,
  callFetchAttachments: PropTypes.func.isRequired,
  documentId: PropTypes.number.isRequired,
  documentType: PropTypes.oneOf(['expense', 'income', 'partner']).isRequired as React.Validator<AttachmentDocumentType>,
  downloadAttachment: PropTypes.func.isRequired,
  isEditorUser: PropTypes.bool.isRequired,
  onChange: PropTypes.func, // optional callback to listen on create or remove success, for PartnerEditor
  removeAttachment: PropTypes.func.isRequired,
}

export const AttachmentsContainer = connect(
  (state: Store) => ({
    isEditorUser: userHasEditorPermission(state.auth.company.data.role),
  }),
  dispatch => ({
    callFetchAttachments: bindActionToPromise(dispatch, attachmentActions.fetchAttachments.request),
    downloadAttachment: bindActionToPromise(dispatch, attachmentActions.downloadAttachment.request),
    removeAttachment: bindActionToPromise(dispatch, attachmentActions.removeAttachment.request),
  })
)(PureAttachmentsContainer)

AttachmentsContainer.displayName = 'AttachmentsContainer'
