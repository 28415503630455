import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage, FormattedNumber } from 'react-intl'
import styled from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { Typography } from '@components/ui/Typography'
import { Paper } from '@components/ui/Wrappers'

import { CURRENCY_OPTIONS, SUBSCRIPTION_PERIODS, SUBSCRIPTION_PERIODS_OBJ } from '@constants'

const StyledPaper = styled(Paper)`
  width: 300px;
  border-radius: 12px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 8px 20px;
  position: relative;
`

const SecondaryTypography = styled(Typography).attrs({ size: '400-xs' })`
  line-height: 16px;
`

const LineThroughTypography = styled(SecondaryTypography)`
  text-decoration: line-through;
`

const CurrentTypography = styled(SecondaryTypography).attrs({ color: 'gray-80' })`
  background-color: ${({ theme }) => theme.colors.gray[30]};
  border-radius: 8px;
  padding: 6px 12px;
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
`

const NewTypography = styled(CurrentTypography)`
  background-color: ${({ theme }) => theme.colors.mango['20']};
  color: ${({ theme }) => theme.colors.primary[40]};
  border: 1px solid ${({ theme }) => applyOpacity(theme.colors.primary[60], 40)};
`

interface PriceBoxProps {
  className?: string
  discountedPrice?: Nullable<string | number>
  isCurrent?: boolean
  isNew?: boolean
  isSubscriptionActive?: boolean
  price: string | number
  subscriptionPeriod: string
  tertiaryHelperText?: StringOrMessage
  tertiaryText?: StringOrMessage
}

export function PriceBox({
  className,
  discountedPrice,
  isCurrent,
  isNew,
  isSubscriptionActive,
  price,
  subscriptionPeriod,
}: PriceBoxProps) {
  const grossPrice = Number(discountedPrice != null ? discountedPrice : price)
  const netPrice = Math.round(grossPrice / 1.27)
  const originalNetPrice = Math.round(Number(price) / 1.27)

  const subscriptionPeriodNode = (
    <FormattedMessage
      id="subscription-card.texts.price.period"
      defaultMessage="/{subscriptionPeriod}"
      values={{
        subscriptionPeriod:
          subscriptionPeriod === SUBSCRIPTION_PERIODS_OBJ.monthly ? (
            <FormattedMessage id="subscription-card.texts.price.additional.monhtly" defaultMessage="hó" />
          ) : (
            <FormattedMessage id="subscription-card.texts.price.additional.yearly" defaultMessage="év" />
          ),
      }}
    />
  )

  return (
    <StyledPaper className={className}>
      {isCurrent && (
        <CurrentTypography>
          {isSubscriptionActive ? (
            <FormattedMessage id="subscription-card.texts.current" defaultMessage="Jelenlegi csomagod" />
          ) : (
            <FormattedMessage id="subscription-card.texts.previous" defaultMessage="Korábbi csomagod" />
          )}
        </CurrentTypography>
      )}
      {!isCurrent && isNew && (
        <NewTypography>
          <FormattedMessage id="subscription-card.texts.new" defaultMessage="Új előfizetésed" />
        </NewTypography>
      )}
      {discountedPrice && (
        <LineThroughTypography align="center" size="400-xs" color="gray-50">
          <FormattedMessage
            id="subscription-card.texts.price.additional"
            defaultMessage="{value} +ÁFA{subscriptionPeriod}"
            values={{
              value: <FormattedNumber value={originalNetPrice} {...CURRENCY_OPTIONS} />,
              subscriptionPeriod: subscriptionPeriodNode,
            }}
          />
        </LineThroughTypography>
      )}
      <Typography align="center" size="700-sm" color="gray-80">
        <FormattedMessage
          id="subscription-card.texts.price.additional"
          defaultMessage="{value} +ÁFA{subscriptionPeriod}"
          values={{
            value: (
              <Typography tag="span" size="heading-3">
                <FormattedNumber value={netPrice} {...CURRENCY_OPTIONS} />
              </Typography>
            ),
            subscriptionPeriod: subscriptionPeriodNode,
          }}
        />
      </Typography>
      <SecondaryTypography align="center" color="gray-50">
        <FormattedMessage
          id="subscription-card.texts.price.gross"
          defaultMessage="bruttó {value} {subscriptionPeriod}"
          values={{
            value: <FormattedNumber value={grossPrice} {...CURRENCY_OPTIONS} />,
            subscriptionPeriod: subscriptionPeriodNode,
          }}
        />
      </SecondaryTypography>
    </StyledPaper>
  )
}

PriceBox.propTypes = {
  className: PropTypes.string,
  discountedPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCurrent: PropTypes.bool,
  isNew: PropTypes.bool,
  isSubscriptionActive: PropTypes.bool,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subscriptionPeriod: PropTypes.oneOf(SUBSCRIPTION_PERIODS).isRequired,
  tertiaryHelperText: PropTypes.node,
  tertiaryText: PropTypes.node,
}
