import React from 'react'

export function useParentSize(): [React.RefObject<HTMLDivElement>, { width: number; height: number }] {
  const ref = React.useRef<HTMLDivElement>(null)
  const [size, setSize] = React.useState({ width: 0, height: 0 })

  React.useEffect(() => {
    const element = ref.current
    if (!element) return

    const updateSize = () => {
      if (size.width !== element.offsetWidth || size.height !== element.offsetHeight) {
        // use timeout to avoid ResizeObserver loop completed with undelivered notifications error
        window.setTimeout(() => {
          setSize({
            width: element.offsetWidth,
            height: element.offsetHeight,
          })
        })
      }
    }

    const resizeObserver = new ResizeObserver(updateSize)

    resizeObserver.observe(element)

    // Initial size update
    updateSize()

    return () => {
      resizeObserver.unobserve(element)
    }
  }, [size.height, size.width])

  return [ref, size]
}
