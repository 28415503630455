import React from 'react'

import { FormattedMessage } from 'react-intl'

import { IntercomInheritLink } from '@components/Intercom'
import { DialogOpenState, TaskTypes } from '@components/pages/PulsePage/TaxationTasks/constants'
import { KnowledgeCenterButton, TaskCustomDialogActions } from '@components/pages/PulsePage/TaxationTasks/elements'
import { MultiClosureViewProps } from '@components/pages/PulsePage/TaxationTasks/types'
import { Button, CustomDialogBody, Typography } from '@components/ui'

import { CloseButtonMessage, OkButtonMessage } from '@messages'
import { SZJA_HELPER_LINKS } from '@components/pages/PulsePage/TaxationTasks/SzjaTasks/messages'

interface ClosureResponseViewProps extends MultiClosureViewProps {
  openState: DialogOpenState
  taskType: TaskTypes
}

export function ClosureResponseView({ aria, onClose, openState, taskType }: ClosureResponseViewProps) {
  return (
    <CustomDialogBody>
      <Typography id={aria.describedby} size="400-md" align="center">
        {openState === DialogOpenState.SUPPORT ? (
          <FormattedMessage
            id="supportView.response.responseText"
            defaultMessage="Az ügyfélszolgálat megkapta a kérelmedet. A fiókodhoz tartozó e-mail címre küldtünk Neked egy automatikus levelet tájékoztatásképp. A válaszadásig szíves türelmedet kérjük!"
          />
        ) : (
          <FormattedMessage
            id="taxation.task.szja.responseText"
            defaultMessage="Sikeresen végigértél a teendőn. A következő lépésben a Tudáscentrumban lévő leírásunk alapján ki kell egészítened az SZJA bevallásodat. Ha elakadnál, kérdés esetén <supportLink>fordulj bizalommal szakmai ügyfélszolgálatunkhoz</supportLink>!"
            values={{
              supportLink: function RenderSupportLink(chunks: string) {
                return <IntercomInheritLink inherit>{chunks}</IntercomInheritLink>
              },
            }}
          />
        )}
      </Typography>

      <TaskCustomDialogActions>
        {openState === DialogOpenState.SUPPORT ? (
          <Button variant="primaryContained" onClick={onClose}>
            {OkButtonMessage}
          </Button>
        ) : (
          <>
            <KnowledgeCenterButton
              href={SZJA_HELPER_LINKS[taskType as keyof typeof SZJA_HELPER_LINKS]}
              onClick={onClose}
            />
            <Button variant="primaryText" onClick={onClose}>
              {CloseButtonMessage}
            </Button>
          </>
        )}
      </TaskCustomDialogActions>
    </CustomDialogBody>
  )
}
