import React from 'react'

import styled from 'styled-components'

import { DOC_VIEWER_CONFIG } from '@contexts'

import { ZoomOrientation } from '@components/DocViewer/constant'
import { changeZoomOrientation, setZoomLevel } from '@components/DocViewer/elements/renderers/ImageRenderer/actions'
import { ZOOM_STATE_MESSAGES } from '@components/DocViewer/elements/renderers/ImageRenderer/ImageControls'
import {
  ImageStateReducer,
  initialImageState,
  reducer,
} from '@components/DocViewer/elements/renderers/ImageRenderer/reducer'
import { getZoomResetState } from '@components/DocViewer/elements/renderers/ImageRenderer/zoom'
import { PreviewControls } from '@components/DocViewer/elements/renderers/PreviewControls'
import { ZoomControls } from '@components/DocViewer/elements/renderers/ZoomControls'
import { RendererRect } from '@components/DocViewer/types'

import { A4_PAPER_SIZE } from '@constants'

import { BackendInvoicePreviewData, InvoicePreviewVariant } from '../types'
import { GeneratedInvoicePreview } from './GeneratedInvoicePreview'

const ROW_HEIGHT = 42
const PREVIEW_STATIC_ELEMENTS_HEIGHT = 435

const StyledSvg = styled.svg`
  display: block;
  margin: 0 auto;
`

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
`

function getDefaultZoomLevel(orientation: ZoomOrientation, width: number, height: number, rendererRect?: RendererRect) {
  if (!rendererRect) {
    return initialImageState.defaultZoomLevel
  }

  return orientation === ZoomOrientation.LANDSCAPE
    ? (rendererRect.width - 20) / width
    : (rendererRect.height - 20) / height
}

interface ScalableSvgPreviewProps {
  data: BackendInvoicePreviewData
  rendererRect?: RendererRect
  renderPostItLayer?: (scale: number, page?: number) => Nullable<JSX.Element>
  variant?: InvoicePreviewVariant
}

export function ScalableSvgPreview({ data, rendererRect, renderPostItLayer, variant }: ScalableSvgPreviewProps) {
  const [previewHeight, setHeight] = React.useState(A4_PAPER_SIZE.height)
  const previewRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setHeight(previewRef.current?.scrollHeight ?? A4_PAPER_SIZE.height)
  }, [setHeight])

  const itemsNum = data.items.length
  const svgWidth = A4_PAPER_SIZE.width
  const svgHeight = Math.max(previewHeight, PREVIEW_STATIC_ELEMENTS_HEIGHT + itemsNum * ROW_HEIGHT)

  const [{ zoomLevel, zoomJump, zoomOrientation, defaultZoomLevel }, dispatch] = React.useReducer<ImageStateReducer>(
    reducer,
    {
      ...initialImageState,
      defaultZoomLevel: getDefaultZoomLevel(initialImageState.zoomOrientation, svgWidth, svgHeight, rendererRect),
      zoomLevel: getDefaultZoomLevel(initialImageState.zoomOrientation, svgWidth, svgHeight, rendererRect),
      zoomJump: DOC_VIEWER_CONFIG.zoom.zoomJump,
    }
  )

  function handleZoomIn() {
    dispatch(setZoomLevel(zoomLevel + zoomJump))
  }

  function handleZoomOut() {
    dispatch(setZoomLevel(zoomLevel - zoomJump))
  }

  function handleZoomReset() {
    if (zoomLevel !== defaultZoomLevel) {
      // when zoomLevel is modified in any orientation reset it
      dispatch(setZoomLevel(defaultZoomLevel))
    } else {
      const newZoomOrientation =
        zoomOrientation === ZoomOrientation.LANDSCAPE ? ZoomOrientation.PORTRAIT : ZoomOrientation.LANDSCAPE
      dispatch(
        changeZoomOrientation({
          defaultZoomLevel: getDefaultZoomLevel(newZoomOrientation, svgWidth, svgHeight, rendererRect),
          zoomOrientation: newZoomOrientation,
        })
      )
    }
  }

  const zoomResetState = getZoomResetState({
    zoomOrientation,
    zoomLevel,
    defaultZoomLevel,
    imageOrientation: ZoomOrientation.PORTRAIT,
  })

  return (
    <PageWrapper>
      <StyledSvg
        width={svgWidth * zoomLevel}
        height={svgHeight * zoomLevel}
        preserveAspectRatio="xMinYMax slice"
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <foreignObject width="100%" height="100%">
          <GeneratedInvoicePreview details={data} variant={variant} ref={previewRef} />
        </foreignObject>
      </StyledSvg>
      {renderPostItLayer?.(zoomLevel, 1)}
      <PreviewControls>
        <ZoomControls
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          handleZoomReset={handleZoomReset}
          zoomResetTitle={ZOOM_STATE_MESSAGES[zoomResetState]}
        />
      </PreviewControls>
    </PageWrapper>
  )
}
