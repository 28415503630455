import styled, { css } from 'styled-components'

import { CustomDialogActions, CustomDialogBody, CustomDialogHeader } from '@components/ui/Dialogs'

// 1271 = 20(padding-left) + 595(paper width) + 20(padding-right) + 1(separator border) + 20(padding-left) + 595(paper width) + 20(padding-right)
const DIALOG_MAX_WIDTH = 1271

export const DuplicateDialogHeader = styled(CustomDialogHeader).attrs(({ borderless }) => ({
  align: borderless ? 'center' : 'left',
}))`
  max-width: ${DIALOG_MAX_WIDTH}px;
  & > * + * {
    margin-top: 10px;
  }
`

export const DuplicateDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
`

export const DuplicateDialogActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const DialogActionsInnerContainer = styled.div`
  display: inherit;
  align-items: inherit;
  gap: inherit;
`

export const CloseActionContainer = styled.div`
  display: inherit;
  align-items: inherit;
  justify-content: center;
`

export const DuplicateDialogBody = styled(CustomDialogBody)<{ $isResponseState: boolean }>`
  min-width: 500px;

  ${({ $isResponseState, theme }) =>
    $isResponseState
      ? css`
          padding: 20px 40px;
          max-width: 600px;
          & + ${DuplicateDialogActions} {
            border-top: none;
          }
        `
      : css`
          padding: 0;
          min-height: 200px;
          max-width: ${DIALOG_MAX_WIDTH}px;
          background-color: ${theme.colors.gray[102]};
        `}
`
