import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  dokumentSupportedMessage: {
    id: 'buttons.preview.supported.title',
    defaultMessage: 'Dokumentum előnézetének megtekintése',
  },
  dokumentUnsupportedMessage: {
    id: 'buttons.preview.unsupported.title',
    defaultMessage: 'A dokumentum nem rendelkezik előnézettel',
  },
  invoiceSupportedMessage: {
    id: 'buttons.preview.invoice.supported',
    defaultMessage: 'Számlakép előnézetének megtekintése',
  },
  invoiceUnsupportedMessage: {
    id: 'buttons.preview.invoice.unsupported',
    defaultMessage: 'A számla nem rendelkezik előnézettel',
  },
})
