import React from 'react'

import { CustomErrorBoundaryView } from '@components/ui'

interface DashboardErrorBoundaryProps {
  error: Error
}

export function DashboardErrorBoundary({ error }: DashboardErrorBoundaryProps) {
  console.error('DashboardErrorBoundary', error.message)

  return <CustomErrorBoundaryView withIntercom />
}
