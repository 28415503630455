import React from 'react'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController, useFormContext } from 'react-hook-form'

import { isFieldHighlighted } from '@helpers'

import { ExpenseDetailsFormValues } from '@components/forms/ExpenseDetailsForm/types'
import { Typography } from '@components/ui'

import { LedgerNumberCreatableSelect } from './LedgerNumberCreatableSelect'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles)

type LedgerNumberFieldKey =
  | 'ledger_number'
  | `assignments.${number}.ledger_number`
  | `assignments.${number}.vat_ledger_number`

export interface LedgerNumberFieldProps {
  className?: string
  disabled?: boolean
  highlighted?: boolean
  isLabelHighlighted?: boolean
  label?: React.ReactNode
  name: LedgerNumberFieldKey
  recommendations?: number[]
  suggestion?: LedgerNumberFormValue
}

export function LedgerNumberField({
  className,
  disabled = false,
  name,
  highlighted = false,
  isLabelHighlighted = false,
  label,
  recommendations,
  suggestion,
}: LedgerNumberFieldProps) {
  const classes = useStyles()
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<ExpenseDetailsFormValues>() // this field is only used in expense details form so it's safe to use ExpenseDetailsFormValues
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<ExpenseDetailsFormValues, LedgerNumberFieldKey>({ control, name })
  // TODO later should use 2 fields for ledger_number_code and ledger_number_name
  //   const {
  //     field: nameField,
  //     fieldState: { error: nameError },
  //   } = useController({ control, name: `${name}_name` })
  //   const {
  //     field: codeField,
  //     fieldState: { error: codeError },
  //   } = useController({ control, name: `${name}_code` })

  const hasError = !!error
  const isFieldDisabled = disabled || isSubmitting

  const ledgerNumberCode = value?.code || null
  const ledgerNumberName = value?.name || ''

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(className, classes.selectRoot, 'form-control', {
        'form-control-error': hasError,
      })}
      error={hasError}
      disabled={isFieldDisabled}
    >
      {label && (
        <InputLabel
          htmlFor={name}
          shrink
          className={cx(classes.bootstrapFormLabel, { [classes.highlightedLabel]: isLabelHighlighted })}
        >
          {label}
        </InputLabel>
      )}
      <div className={classes.selectInput}>
        <LedgerNumberCreatableSelect
          className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
          disabled={disabled}
          name={name} // later this should be ledger_number_code
          onChange={onChange}
          recommendations={recommendations}
          suggestion={suggestion}
          value={ledgerNumberCode}
        />
        {ledgerNumberName && (
          <Typography size="400-xs" italic>
            {ledgerNumberName}
          </Typography>
        )}
      </div>
      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  )
}
