import React from 'react'

// import __isEmpty from 'lodash/isEmpty'
import { IncomeDetailsFormValues } from './types'

export function useFormInitialValues(
  details: IncomeDetailsFrontendValues,
  defaultCurrencyId: number,
  incomeType: number
): IncomeDetailsFormValues {
  return React.useMemo(() => {
    //! there is no CREATE path yet
    // if (__isEmpty(details)) {
    //   return { ... }
    // }
    return {
      accounting_period_end: details.accounting_period_end || '',
      accounting_period_start: details.accounting_period_start || '',
      assignments: details.assignments,
      currency: details.currency || defaultCurrencyId,
      due_at: details.due_at || '',
      exchange_date: details.exchange_date || null,
      exchange_rate: details.exchange_rate || '', // do not set it to default 1, let the user decide
      fulfilled_at: details.fulfilled_at || '',
      gross_amount: details.gross_amount || '',
      id: details.id,
      income_type: details.income_type || incomeType,
      invoice_number: details.invoice_number || '',
      issued_at: details.issued_at || '',
      net_amount: details.net_amount || '',
      paid_through: details.paid_through || null,
      partner_account_number: details.partner_account_number || '',
      partner_address: details.partner_address || '',
      partner_city: details.partner_city || '',
      partner_country: details.partner_country || '',
      partner_id: details.partner?.id || '',
      partner_name: details.partner_name || '',
      partner_tax_number: details.partner_tax_number || '',
      partner_zip_code: details.partner_zip_code || '',
      payment_method: details.payment_method || null,
      planned_payment_date: details.planned_payment_date || '',
      secondary_id: details.secondary_id || '',
      simple_tags: details.simple_tags,
      tags: details.tags,
      vat_area: details.vat_area || null,
      // form control fields - do not send them to backend
      need_accounting_period: details.accounting_period_start != null,
      reschedule_payment: details.planned_payment_date != null,
    }
  }, [defaultCurrencyId, details, incomeType])
}
