import React from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { DashboardAnnouncement } from '@components/DashboardAnnouncement'
import PageTitle from '@components/PageTitle'

import { generateMaxHeightBreakPoint } from '@constants'

const CLASS_KEYS = {
  minWidth: 'minWidth',
  warningBarIsActive: 'warningBarIsActive',
}

const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  width: 100%;

  &.${CLASS_KEYS.minWidth} {
    min-width: 1200px;
  }

  &.${CLASS_KEYS.warningBarIsActive} {
    padding-top: ${({ theme }) => theme.appBarHeight}px;

    ${generateMaxHeightBreakPoint('medium')}: {
      top: ${({ theme }) => theme.appBarHeightMedium}px;
    }
  }
`

interface PrivatePageProps {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  noMinWidth?: boolean
  subtitle?: string
  warningBarIsActive?: boolean
}

export function PrivatePage({
  children,
  className,
  noMinWidth = false,
  subtitle,
  warningBarIsActive = false,
}: PrivatePageProps) {
  return (
    <PageWrapper
      className={cx(className, {
        [CLASS_KEYS.minWidth]: !noMinWidth,
        [CLASS_KEYS.warningBarIsActive]: warningBarIsActive,
      })}
    >
      {subtitle && <PageTitle subtitle={subtitle} />}
      {children}
      <DashboardAnnouncement />
    </PageWrapper>
  )
}
