import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

const styles: Styles<Theme, Record<never, never>> = theme => ({
  paper: {
    '@media (min-width: 551px)': {
      display: 'flex',
    },
  },
  root: {
    position: 'fixed',
    zIndex: 6000,
    right: 25,
    bottom: 25,
    left: 20,
    height: 'auto',
    '@media (min-width: 551px)': {
      left: 'auto',
      width: 500,
      minHeight: 140,
    },
  },
  iconContainer: {
    backgroundColor: theme.palette.grayscale.xxLight,
    padding: '5px 10px',
    '& > svg': {
      display: 'block',
    },
    '@media (max-width: 550px)': {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#D5E0EB',
      '& > *': {
        width: 25,
        height: 25,
      },
    },
    '@media (min-width: 551px)': {
      minWidth: 140,
      minHeight: 140,
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: '#D5E0EB',
      padding: 30,
    },
  },
  content: {
    padding: 10,
    paddingBottom: 20,
    '@media (min-width: 551px)': {
      paddingBottom: 10,
    },
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  title: {
    fontSize: 16,
    lineHeight: 1.2,
    marginBottom: 10,
    '@media (min-width: 551px)': {
      marginRight: 25,
    },
  },
  description: {
    fontSize: 12,
    lineHeight: 1.2,

    '& > a': {
      color: 'inherit',
      fontWeight: 600,
    },
  },
})

export default styles
