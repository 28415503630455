import { Button, IconButton, Input, MenuList } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'
import { NavLink as RouterLink } from 'react-router-dom'
import styled, { css, DefaultTheme } from 'styled-components'

import { applyOpacity } from '@helpers/index'

import { SettingsIcon, Typography } from '@components/ui'

import { generateMaxHeightBreakPoint, generateMaxWidthBreakPoint } from '@constants/theme'

import { NavLink } from '../NavLink'

const MENU_ITEM_MARGIN_TOP = 4

//* ProfileMenu styles

export const RootContainer = styled.div`
  display: inline-block;
  position: relative;

  ${generateMaxWidthBreakPoint('small')} {
    display: block;
  }
`

export const MenuRootContainer = styled.div`
  width: 385px;
  color: ${({ theme }) => theme.colors.common.menu};
`

export const MenuRootMobileContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.common.menu};
`

export const MenuHeader = styled.div`
  height: ${({ theme }) => theme.appBarHeight}px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
`

export const MenuFooter = styled.div`
  height: ${({ theme }) => theme.appBarHeight}px;
  padding: 15px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
`

export const ProfileDesktopNavLink = styled(NavLink)`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  padding-left: 21px;
  justify-content: center;
  color: ${({ theme }) => theme.colors.common.menu};
  text-decoration: none;

  & > ${SettingsIcon} {
    position: absolute;
    left: 0;
    top: 4px;
  }

  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.blue[40]};
  }
`

function getActiveNavStyles({ $isActive, theme }: { $isActive?: boolean; theme: DefaultTheme }) {
  const vPadding = (theme.appBarHeight - 20) / 2
  const vPaddingMedium = (theme.appBarHeightMedium - 20) / 2
  return css`
    display: inline-flex;
    height: ${theme.appBarHeight}px;
    text-decoration: none;
    line-height: 20px;
    padding: ${vPadding}px 8px;
    color: ${theme.colors.common.midnightEscape};
    border-radius: 0;

    &:hover {
      background-color: ${theme.colors.gray[20]};
    }

    ${generateMaxHeightBreakPoint('medium')} {
      height: ${theme.appBarHeightMedium}px;
      padding: ${vPaddingMedium}px 0 ${vPaddingMedium}px 8px;
    }

    ${$isActive &&
    css`
      &,
      &:hover {
        background-color: ${theme.colors.blue[40]};
        color: ${theme.colors.gray[0]};
      }
    `}
  `
}

export const StyledNavLink = styled(NavLink)<{ $isActive?: boolean }>`
  ${getActiveNavStyles}
`

export const StyledNavButton = styled(Button)<{ $isActive?: boolean }>`
  ${getActiveNavStyles}
`

//* MenuItemWithNavigation styles

export const CompanySelectLink = styled(RouterLink)`
  min-width: 0;
  margin-right: 15px;
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  line-height: 1.2;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
`

export const CompanySettingsLink = styled(RouterLink)`
  padding: 0 10px;
  color: inherit;
  display: inline-flex;
  height: 100%;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
`

//* SearchableCompanyMenu styles:

export const SearchWrapper = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
`

export const SearchGrid = styled.div`
  display: flex;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.gray[80]};
  border-radius: 4px;
  padding: 2px;
  color: ${({ theme }) => theme.colors.gray[80]};
  opacity: 0.5;
  transition: opacity 300ms ease;
  &:focus-within {
    opacity: 1;
  }
`

export const StyledSearchIcon = styled(SearchIcon)`
  display: inline-flex;
  align-items: center;
  height: 100%;
  vertical-align: middle;
`

export const ClearButton = styled(IconButton)`
  width: 20px;
  color: ${({ theme }) => theme.colors.common.offBlack};

  &:hover,
  &:focus {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.blue[40]};
  }
`

export const SearchInput = styled(Input)`
  width: 100%;
  font-size: 14px;
  padding: 4px;
  color: ${({ theme }) => theme.colors.gray[100]};
  font-weight: 700;
  height: auto;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[20]};
    opacity: 1;
  }
`

export const NoResultTypography = styled(Typography).attrs({
  color: 'gray-40',
  size: '400-sm',
})`
  padding: 10px 15px;
`

export const ScrollableMenuWrapper = styled.div<{ $shadowVisible: boolean }>`
  position: relative;

  ${({ theme, $shadowVisible }) =>
    $shadowVisible &&
    css`
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 5px;
        mix-blend-mode: multiply;
        opacity: 0.1;
      }
      &:before {
        top: 0;
        background: ${`linear-gradient(180deg, ${theme.colors.blue[40]} 0%, ${applyOpacity(
          theme.colors.blue[40],
          0
        )} 100%)`};
      }
      &:after {
        bottom: 0;
        background: ${`linear-gradient(0deg, ${theme.colors.blue[40]} 0%, ${applyOpacity(
          theme.colors.blue[40],
          0
        )} 100%)`};
      }
    `}
`

export const StyledMenuList = styled(MenuList)`
  margin: 0;
  padding: 0;
  max-height: ${({ theme }) => 5 * theme.appBarHeight - MENU_ITEM_MARGIN_TOP}px;
  overflow-y: auto;

  & > li {
    font-weight: 600;
    font-size: 14px;
    height: ${({ theme }) => theme.appBarHeight - MENU_ITEM_MARGIN_TOP}px;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.common.menu};
    justify-content: space-between;
    padding: 10px 0 10px 15px;

    &:hover {
      cursor: auto;
    }

    &.Mui-selected {
      &,
      &:hover {
        background-color: ${({ theme }) => theme.colors.blue[40]};
        color: ${({ theme }) => theme.colors.gray[0]};
      }
    }

    &:not(.Mui-selected) ${CompanySelectLink}, &:not(.Mui-selected) ${CompanySettingsLink} {
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.blue[40]};
      }
    }
  }
`

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)<{ $isOpen: boolean }>`
  font-size: 20px;
  transition: transform 300ms ease-out;
  transform: rotate(0deg);
  ${({ $isOpen }) => $isOpen && `transform: rotate(180deg);`}
`
