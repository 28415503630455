import React from 'react'

import styled from 'styled-components'

function BaseXmarkSquareIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M5.10714 5.10714C5.44286 4.775 5.98571 4.775 6.28929 5.10714L7.96786 6.78929L9.67857 5.10714C10.0143 4.775 10.5571 4.775 10.8607 5.10714C11.225 5.44286 11.225 5.98571 10.8607 6.28929L9.21071 7.96786L10.8607 9.67857C11.225 10.0143 11.225 10.5571 10.8607 10.8607C10.5571 11.225 10.0143 11.225 9.67857 10.8607L7.96786 9.21071L6.28929 10.8607C5.98571 11.225 5.44286 11.225 5.10714 10.8607C4.775 10.5571 4.775 10.0143 5.10714 9.67857L6.78929 7.96786L5.10714 6.28929C4.775 5.98571 4.775 5.44286 5.10714 5.10714ZM0 2.28571C0 1.02321 1.02321 0 2.28571 0H13.7143C14.975 0 16 1.02321 16 2.28571V13.7143C16 14.975 14.975 16 13.7143 16H2.28571C1.02321 16 0 14.975 0 13.7143V2.28571ZM1.71429 2.28571V13.7143C1.71429 14.0286 1.97 14.2857 2.28571 14.2857H13.7143C14.0286 14.2857 14.2857 14.0286 14.2857 13.7143V2.28571C14.2857 1.97 14.0286 1.71429 13.7143 1.71429H2.28571C1.97 1.71429 1.71429 1.97 1.71429 2.28571Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const XmarkSquareIcon = styled(BaseXmarkSquareIcon)``
