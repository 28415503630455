import React from 'react'

import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import { PaymentHeader, PaymentHeaderProps } from './PaymentFormElements'
import { useBinXProvider } from './useBinXProvider'

const StyledPaymentHeader = styled(PaymentHeader)<{ $highlight: boolean }>`
  ${({ $highlight, theme }) =>
    $highlight &&
    css`
      .rw-widget-picker.rw-widget-container,
      .react-select__control {
        background-color: ${theme.colors.common.demoBlue};
      }
    `}
`

interface TransferPaymentHeaderProps extends PaymentHeaderProps {
  paidThroughOptions: PaidThroughOption[]
}

function PureTransferPaymentHeader({ paidThroughOptions, ...rest }: TransferPaymentHeaderProps) {
  const isBinxProvider = useBinXProvider(paidThroughOptions)
  return <StyledPaymentHeader $highlight={isBinxProvider} {...rest} />
}

export const TransferPaymentHeader = connect((state: Store) => ({
  paidThroughOptions: state.payment.paidThroughOptions,
}))(PureTransferPaymentHeader)

TransferPaymentHeader.displayName = 'TransferPaymentHeader'
