import __isEqual from 'lodash/isEqual'

interface GetStatusFilterDialogProps<
  CustomStatusFilterConfig extends StatusFilterConfig,
  ProvidedValues extends object
> {
  config: readonly CustomStatusFilterConfig[]
  values: ProvidedValues
}

/**
 * Helper function to get the status filter dialog props for the given config. It will return the valid props or an error if something is missing or invalid.
 *
 * @template CustomStatusFilterConfig - The custom status filter config
 * @template ProvidedValues - The values provided by the user
 * @param {GetStatusFilterDialogProps<CustomStatusFilterConfig, ProvidedValues>} {
 *   config,
 *   values: providedValues,
 * }
 * @returns {StatusFilterBarProps<CustomStatusFilterConfig>} - The status filter props
 */
export function getStatusFilterDialogProps<
  CustomStatusFilterConfig extends StatusFilterConfig,
  ProvidedValues extends object // we need object extension as filter types are interfaces and not types therefore can't be paired with Record<string, unknown> due to index type errors
>({
  config,
  values: providedValues,
}: GetStatusFilterDialogProps<CustomStatusFilterConfig, ProvidedValues>): StatusFilterProps<CustomStatusFilterConfig> {
  const configKeys = config.map(({ name }) => name)
  const providedTypedValues = providedValues as Record<string, unknown> // due to object extension this is required otherwise providedvalues needs to be casted everywhere it is used
  const providedKeys = Object.keys(providedTypedValues)

  if (configKeys.every(key => providedKeys.includes(key))) {
    return {
      config,
      values: configKeys.reduce((valuesNeeded, currentKey) => {
        const value = providedTypedValues[currentKey]
        if (typeof value === 'undefined' || typeof value === 'boolean') {
          valuesNeeded[currentKey as keyof typeof valuesNeeded] = value
        } else {
          throw new Error(
            `Invalid value type for ${currentKey}, which is of type ${typeof value} instead of boolean or undefined`
          )
        }
        return valuesNeeded
      }, {} as StatusFilterBarProps<CustomStatusFilterConfig>['values']),
    }
  } else {
    throw new Error(`Missing status filter values: ${configKeys.filter(key => !providedKeys.includes(key)).join(', ')}`)
  }
}
interface GetStatusFilterProps<CustomStatusFilterConfig extends StatusFilterConfig, ProvidedValues extends object> {
  config: readonly CustomStatusFilterConfig[]
  values: ProvidedValues
  width: number
}

/**
 * Helper function to get the status filter props for the given config. It will return the valid props or an error if something is missing or invalid.
 *
 * @template CustomStatusFilterConfig - The custom status filter config
 * @template ProvidedValues - The values provided by the user
 * @param {GetStatusFilterProps<CustomStatusFilterConfig, ProvidedValues>} {
 *   config,
 *   values,
 *   width,
 * }
 * @returns {StatusFilterBarProps<CustomStatusFilterConfig>} - The status filter props
 */
export function getStatusFilterProps<
  CustomStatusFilterConfig extends StatusFilterConfig,
  ProvidedValues extends object // we need object extension as filter types are interfaces and not types therefore can't be paired with Record<string, unknown> due to index type errors
>({
  config,
  values,
  width,
}: GetStatusFilterProps<CustomStatusFilterConfig, ProvidedValues>): StatusFilterBarProps<CustomStatusFilterConfig> {
  return {
    ...getStatusFilterDialogProps({ config, values }),
    width,
  }
}

/**
 * Helper function to get the selectable filter props for the given config. It will return the valid props or an error if something is missing or invalid.
 *
 * @param {SelectableFilterProps} { values, config } - The selectable filter props
 * @returns {SelectableFilterProps} - The selectable filter props
 */
export function getSelectableFilterProps({ values, config }: SelectableFilterProps): SelectableFilterProps {
  const configKeys = config.map(({ keyValue }) => keyValue)
  const valueKeys = Object.keys(values)

  if (configKeys.every(key => valueKeys.includes(key))) {
    return {
      config,
      values: configKeys.reduce((valuesNeeded, currentKey) => {
        const value = values[currentKey]
        if (typeof value !== 'undefined') {
          valuesNeeded[currentKey] = value
        } else {
          throw new Error(`Invalid value type for ${currentKey}, which is undefined`)
        }
        return valuesNeeded
      }, {} as SelectableFilterValues),
    }
  } else {
    throw new Error(
      `Missing selectable filter values: ${configKeys.filter(key => !valueKeys.includes(key)).join(', ')}`
    )
  }
}

export function checkFiltersChanged<ListFilters extends CommonSearchFilters>(
  filters: ListFilters,
  defaultFilters: ListFilters
) {
  return !__isEqual(
    { ...filters, searchFields: filters.searchFields.sort() },
    { ...defaultFilters, searchFields: defaultFilters.searchFields.sort() }
  )
}
