import { FORMATTER_CLEAN_TEXT_REGEX, FORMATTER_PATTERN_SPLIT_REGEX } from '@constants'

function shouldAppendLastSeparator(current: string, pattern: string, lastSeparator: string) {
  return current.length + 1 === pattern.length && pattern.endsWith(lastSeparator)
}

function getSeparators(pattern: string) {
  return pattern.split(FORMATTER_PATTERN_SPLIT_REGEX).filter(Boolean)
}

function getFormattedString(pattern: string, value?: string | number) {
  if (!value) {
    return value
  }

  const cleanValue = String(value).replace(FORMATTER_CLEAN_TEXT_REGEX, '')
  const blockSizes = pattern
    .split(FORMATTER_CLEAN_TEXT_REGEX)
    .filter(Boolean)
    .map(b => b.length)
  const separators = getSeparators(pattern)
  const [firstSeparator] = separators
  const lastSeparator = separators[separators.length - 1]

  if (pattern.startsWith(firstSeparator)) {
    const afterReduce = separators.reduce(
      (acc, cur, index) => {
        const curBlockSize = blockSizes[index]
        const beforeSlice = acc.value.slice(0, curBlockSize)
        const afterSlice = acc.value.slice(curBlockSize)
        const nextResult = beforeSlice ? acc.result.concat(cur, beforeSlice) : acc.result

        return {
          result: nextResult,
          value: afterSlice,
        }
      },
      {
        result: '',
        value: cleanValue,
      }
    )

    const res = afterReduce.result.slice(0, pattern.length)

    if (shouldAppendLastSeparator(res, pattern, lastSeparator)) {
      return res.concat(lastSeparator)
    }

    return res
  }

  const afterReduce = blockSizes.reduce(
    (acc, cur, index) => {
      const curSeparator = separators[index] || ''
      const replace = `$1${curSeparator}$2`
      const curSlice = cur + acc.prevSlice + acc.prevSeparator.length
      const curRegex = new RegExp(`(.{${curSlice}})(.)`)
      const curValue = acc.value.replace(curRegex, replace)

      return {
        prevSeparator: curSeparator,
        prevSlice: curSlice,
        value: curValue,
      }
    },
    {
      prevSeparator: '',
      prevSlice: 0,
      value: cleanValue,
    }
  )

  const res = afterReduce.value.slice(0, pattern.length)

  if (shouldAppendLastSeparator(res, pattern, lastSeparator)) {
    return res.concat(lastSeparator)
  }

  return res
}

function formatStringByPattern(pattern: string): (value?: string | number) => string
function formatStringByPattern(pattern: string, value: string | number): string
function formatStringByPattern(pattern: string, value?: string | number) {
  return value === undefined
    ? (curriedValue: string | number) => getFormattedString(pattern, curriedValue)
    : getFormattedString(pattern, value)
}

type FormatterRule = {
  re: Nullable<RegExp>
  pattern: string
  length?: number
}

type FormatterOptions = {
  parseBeforeFormat?: boolean
}

function getRule(value: string, rules: FormatterRule | FormatterRule[]): FormatterRule | undefined {
  if (Array.isArray(rules)) {
    return rules.find(rule => rule.re?.test(value))
  } else {
    if (rules.re) {
      if (rules.re.test(value)) {
        return rules
      }
      return undefined
    }
    return rules
  }
}

export default function Formatter(rules: FormatterRule | FormatterRule[], options?: FormatterOptions) {
  const parseBeforeFormat = options?.parseBeforeFormat ?? false

  function format(inputValue?: string | number | null) {
    if (inputValue == undefined) {
      return ''
    }
    const value = parseBeforeFormat ? parse(String(inputValue)) : String(inputValue)
    const rule = getRule(value, rules)
    if (rule) {
      return formatStringByPattern(rule.pattern, value)
    }
    return value
  }

  function parse(formattedValue: string) {
    if (!formattedValue) {
      return formattedValue
    }
    const cleanedValue = formattedValue.replace(FORMATTER_CLEAN_TEXT_REGEX, '')
    const rule = getRule(cleanedValue, rules)
    return cleanedValue.substring(0, rule?.length)
  }

  function positioner(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string,
    previousValue: string
  ) {
    if (typeof window.requestAnimationFrame === 'function') {
      // positioning
      const element = event.target
      let caret = element.selectionStart || 0
      if (previousValue) {
        const prevParsedValue = parse(previousValue)
        const parsedValue = parse(value)
        const formattedValue = format(parsedValue)
        const vLength = formattedValue.length
        if (parsedValue.length > prevParsedValue.length) {
          // enter char
          const caretValue = format(parsedValue.substring(0, caret))
          const formattedCaret = format(parse(formattedValue.substring(0, caret)))
          const diff = Math.abs(formattedCaret.length - caretValue.length)
          if (diff !== 0) {
            caret = Math.min(caret + diff, vLength)
          }
        } else {
          const formattedPrevValue = format(prevParsedValue)
          const caretValue = formattedPrevValue.substring(0, caret)
          const parsedCaret = format(parse(caretValue))
          const diff = Math.abs(parsedCaret.length - caretValue.length)
          // remove char
          if (diff !== 0) {
            caret = Math.max(caret - diff, 0)
          }
        }
      }
      window.requestAnimationFrame(function () {
        element.selectionStart = caret
        element.selectionEnd = caret
      })
    }
  }

  return {
    format,
    parse,
    positioner,
  }
}
