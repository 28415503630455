import React from 'react'

import { FormattedMessage } from 'react-intl'

import { PureOriginIcon } from '@components/ui'

import { HeadingTypography } from './styles'

export function NavHeader({ origin }: { origin: InvoiceOrigin }) {
  return (
    <HeadingTypography>
      <PureOriginIcon origin={origin} />
      <FormattedMessage id="nav.preview.handler.heading" defaultMessage="NAV-ból importált számla" />
    </HeadingTypography>
  )
}
