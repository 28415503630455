import React from 'react'

import { CellProps, Row } from 'react-table'

import { ExpandRowButton } from './ExpandRowButton'

interface ExpandRowCellProps<RowData extends object> extends CellProps<RowData> {
  onRowExpanded?: (payload: Row<RowData>['values']['rowData']) => void
}

export function ExpandRowCell<RowData extends object>({
  row: {
    canExpand,
    depth,
    getToggleRowExpandedProps,
    isExpanded,
    values: { rowData },
  },
  onRowExpanded,
}: ExpandRowCellProps<RowData>) {
  React.useEffect(() => {
    if (isExpanded && depth === 0) {
      onRowExpanded?.(rowData)
    }
  }, [isExpanded])

  if (!canExpand) {
    return null
  }

  return <ExpandRowButton {...getToggleRowExpandedProps()} isExpanded={isExpanded} />
}
