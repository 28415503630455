import React from 'react'
import PropTypes from 'prop-types'

import { useController } from 'react-hook-form'

import { accountNumberFormatter } from '@helpers'

import { CustomFieldBehavior } from '@components/ui/FormElements/CustomFieldBehavior'

interface TableAccountNumberFieldProps {
  disabled?: boolean
  name: string
}

export function TableAccountNumberField({ disabled, name }: TableAccountNumberFieldProps) {
  const {
    field: { onChange, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({ name })
  const isFieldDisabled = disabled || isSubmitting

  return (
    <CustomFieldBehavior
      disabled={isFieldDisabled}
      error={!!error}
      helperText={error?.message ?? ''}
      name={name}
      value={accountNumberFormatter.format(value)}
      onChange={event => {
        onChange(accountNumberFormatter.parse(event.target.value))
        accountNumberFormatter.positioner?.(event, event.target.value, value)
      }}
    />
  )
}

TableAccountNumberField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
}
