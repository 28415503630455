import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import styled, { keyframes } from 'styled-components'

import { useMeasure } from '@hooks'

const openAnimation = keyframes`
  from {
    max-height: var(--start-animation-max-height, 0);
    min-height: var(--start-animation-min-height, 0);
  }
  to {
    max-height: var(--max-height);
    min-height: var(--min-height);
  }
`
const closeAnimation = keyframes`
  from {
    max-height: var(--close-animation-closed, var(--max-height));
    min-height: var(--close-animation-closed, var(--min-height));
  }
  to {
    max-height: 0px;
    min-height: 0px;
  }
`

export const TIMING = 300 // animation attribute does not handle custom css variables
const BLOCK_ANIMATION_CLASS = 'block-animation'

const CollapseDiv = styled.div`
  --transition: all ${TIMING}ms ease-in-out;
  --min-height: calc(var(--height, 0) * 1px);
  --max-height: calc(var(--height, 0) * 1px);
  transition: var(--transition);
  overflow: hidden;
  will-change: max-height, min-height;

  &:has([data-type='collapse']).${BLOCK_ANIMATION_CLASS} {
    /* On initial render we want everything to render as is, without animation or transition */
    --start-animation-min-height: var(--min-height);
    --start-animation-max-height: var(--max-height);
    --close-animation-closed: 0px;
    transition: none;

    & [data-type='collapse'] {
      transition: none;
    }
  }

  &:not(.${BLOCK_ANIMATION_CLASS})[aria-hidden='false'] {
    &:has([data-type='collapse']) {
      /* When the collapse has a nested collapse we anticipate that the body size changes are handled inside the body */
      /* and not by the collapse itself, so we animate the size change once on open and does not keep track of size */
      animation: ${openAnimation} ${TIMING}ms;
    }

    &:not(:has([data-type='collapse'])) {
      max-height: var(--max-height);
      min-height: var(--min-height);
    }
  }

  &:not(.${BLOCK_ANIMATION_CLASS})[aria-hidden='true'] {
    &:has([data-type='collapse']) {
      /* When the collapse has a nested collapse we anticipate that the body size changes are handled inside the body */
      /* and not by the collapse itself but we need to close the collapse properly */
      animation: ${closeAnimation} ${TIMING}ms;
      max-height: 0px;
      min-height: 0px;
    }

    &:not(:has([data-type='collapse'])) {
      max-height: 0px;
      min-height: 0px;
    }
  }

  /* Fallback for browsers not yet supporting :has() */
  @supports not (selector(:has(*))) {
    &[aria-hidden='false'] {
      max-height: var(--max-height);
      min-height: var(--min-height);
    }

    &[aria-hidden='true'] {
      max-height: 0px;
      min-height: 0px;
    }
  }
`
interface CollapseProps extends React.HTMLAttributes<HTMLDivElement> {
  isExpanded: boolean
  children: React.ReactNode
}

export function Collapse({ isExpanded, children, className, ...rest }: CollapseProps) {
  const [measureRef, { height }] = useMeasure()
  const [blockFirstAnimationForNestedCollapses, setBlockFirstAnimationForNestedCollapses] = React.useState(isExpanded)

  React.useEffect(() => {
    let timeout: number
    if (blockFirstAnimationForNestedCollapses) {
      timeout = window.setTimeout(() => setBlockFirstAnimationForNestedCollapses(false), TIMING)
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout)
      }
    }
  }, [blockFirstAnimationForNestedCollapses])

  return (
    <CollapseDiv
      {...rest}
      className={cx(className, { [BLOCK_ANIMATION_CLASS]: blockFirstAnimationForNestedCollapses })}
      data-type="collapse"
      aria-hidden={!isExpanded}
      style={{
        '--height': height,
      }}
    >
      <div ref={measureRef}>{children}</div>
    </CollapseDiv>
  )
}

Collapse.propTypes = { isExpanded: PropTypes.bool.isRequired }
