import { COMPANY_ROUTE, V2_COMPANY_ROUTE } from '@constants'

export { API_INVOICE_RECOMMENDATION_URL } from '../expense/urls'

export const API_INCOME_LIST_URL = `${COMPANY_ROUTE}incomes/`
export const API_INCOME_DETAILS_URL = `${COMPANY_ROUTE}incomes/{income_id}`
export const API_INCOME_USER_VIEW_URL = `${COMPANY_ROUTE}incomes/{income_id}/user-saw/`
export const API_INCOME_ARTIFACT_DELETE_URL = `${COMPANY_ROUTE}incomes/delete-file/`

// bulk update
export const API_MISSING_INCOMES_URL = `${COMPANY_ROUTE}missing-incomes/{income_id}/{partner_id}/{payment_method}/`

// revenue types
export const API_REVENUE_TYPE_URL = `${COMPANY_ROUTE}revenue-type/`

// categorization
export const API_BULK_CATEGORIZATION_URL = `${COMPANY_ROUTE}incomes/categorization/`

// download
export const API_INCOME_ARTIFACT_DOWNLOAD_URL = `${COMPANY_ROUTE}incomes/{income_id}/download/`

// CUSTOM INCOME DETAILS FORM VALIDATOR
export const API_INCOME_INVOICE_NUMBER_CHECK_URL = `${COMPANY_ROUTE}incomes/is-unique-invoice/`

//* V2
export const API_INCOME_REGISTER_DOWNLOAD_URL = `${V2_COMPANY_ROUTE}income-register-export/`

export const V2_API_INCOME_LIST_URL = `${V2_COMPANY_ROUTE}incomes`

export const V2_API_INCOME_CHARTS_URL = `${V2_COMPANY_ROUTE}incomes/charts`

export const V2_API_INCOME_BACKGROUND_ACTION_URL = `${V2_COMPANY_ROUTE}incomes/actions`

export const V2_API_INIT_INCOME_DOWNLOAD_URL = `${V2_COMPANY_ROUTE}incomes/has-mergeable-attachments`
