import React from 'react'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import cx from 'classnames'
import __isArray from 'lodash/isArray'
import __isObject from 'lodash/isObject'
import { FieldRenderProps } from 'react-final-form'

import FormFieldSelect from './select'

import useStyles from './styles'

// TODO must extends with MySelectProps - need rewrite
type RenderSelectProps = FieldRenderProps<string | number | string[] | number[] | object, HTMLSelectElement>

export function RenderFormFieldSelect({
  inputClassName,
  label,
  labelClassName,
  meta: { dirtySinceLastSubmit, error, submitError, touched, modifiedSinceLastSubmit },
  required,
  selectComponent: SelectComponent = FormFieldSelect,
  ...rest
}: RenderSelectProps) {
  const classes = useStyles()

  const displayedError = touched && (error || (!dirtySinceLastSubmit && !modifiedSinceLastSubmit && submitError))
  const hasError = Boolean(displayedError)

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(classes.selectRoot, 'form-control', { 'form-control-error': hasError })}
      error={hasError}
      disabled={rest.disabled}
      required={required}
    >
      {label && (
        <InputLabel htmlFor={rest.input.name} shrink className={cx(classes.bootstrapFormLabel, labelClassName)}>
          {label}
        </InputLabel>
      )}
      <div className={cx(classes.selectInput, inputClassName)}>
        <SelectComponent hasError={hasError} {...rest} />
      </div>
      {hasError && (
        <>
          {__isObject(displayedError) && !__isArray(displayedError) ? (
            Object.keys(displayedError).map(key => (
              <FormHelperText key={`${rest.input.name}-${key}`}>
                {key}: {displayedError[key as keyof typeof displayedError]}
              </FormHelperText>
            ))
          ) : (
            <FormHelperText>{displayedError}</FormHelperText>
          )}
        </>
      )}
    </FormControl>
  )
}
