import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import { API_PUBLIC_KEYS_URL, API_REMOVE_PUBLIC_KEY_URL } from './urls'

export function loadApiKeys(companyId: number) {
  return Api.get<ApiKeysData[]>(injectCompanyToURL(API_PUBLIC_KEYS_URL, companyId))
}

export function createApiKey(
  companyId: number,
  data: {
    company: number
    name: string
  }
) {
  return Api.post<{
    id: number
    name: string
    token: string
  }>(injectCompanyToURL(API_PUBLIC_KEYS_URL, companyId), data)
}

export function removeApiKey(companyId: number, { id }: ApiKeysData) {
  return Api.delete(injectCompanyToURL(API_REMOVE_PUBLIC_KEY_URL, companyId).replace('{api_token_id}', String(id)))
}
