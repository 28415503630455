import React from 'react'
import PropTypes from 'prop-types'

import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { authActions } from '@services'

import { Button, LogoutIcon } from '@components/ui'

import { messages } from './messages'
import { StyledIconButton } from './styles'

interface LogoutButtonProps {
  isCompact?: boolean
  isDesktop?: boolean
  logout: VoidFunction
}

function PureLogoutButton({ isCompact = false, isDesktop = false, logout }: LogoutButtonProps) {
  const { formatMessage } = useIntl()

  return isDesktop ? (
    <StyledIconButton
      onClick={logout}
      title={formatMessage(messages.logout)}
      data-testid="logout-btn"
      $isCompact={isCompact}
    >
      <LogoutIcon />
    </StyledIconButton>
  ) : (
    <StyledIconButton as={Button} onClick={logout} data-testid="logout-btn" $isCompact={isCompact}>
      {formatMessage(messages.logout)}
    </StyledIconButton>
  )
}

PureLogoutButton.propTypes = {
  isCompact: PropTypes.bool,
  isDesktop: PropTypes.bool,
  logout: PropTypes.func.isRequired,
}

export const LogoutButton = connect(
  (state: Store) => ({ lang: state.locale.language }),
  dispatch => ({
    logout: () => dispatch(authActions.logout.request()),
  })
)(PureLogoutButton)
