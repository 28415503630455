import __flatten from 'lodash/flatten'
import __unionBy from 'lodash/unionBy'

type ReactTableRowData = {
  rowData: Record<string, unknown> & { id: number | string }
  subRows?: unknown[]
}

/**
 *  Takes the ID of the current bank transaction and returns that to be used as the table row's ID
 *
 * @param {ReactTableRowData} originalRow - row data
 * @param {number} relativeIndex - index
 * @param {Row<ReactTableRowData>} parent - optional
 * @returns string ID of row
 */
export function getRowId<TableRow extends ReactTableRowData>(originalRow: TableRow) {
  // add group prefix to expandable rows to avoid same ID issue
  return originalRow.subRows != null ? `group-${originalRow.rowData.id}` : String(originalRow.rowData.id)
}

/**
 * Map the selectable items' ID into an object for react-table
 *
 * @param {boolean} param.isAllSelected - selection control isAllSelected
 * @param {SelectedItemProps[]} param.data - list table data
 * @param {SelectedItemProps[]} param.selected - selection control selected items array
 * @returns create an object for react-table to detect selected items' ID - { [ID]: true }
 */

export function getSelectedRowIds<ListData extends SelectedItemProps>({
  isAllSelected,
  data,
  selected,
}: {
  isAllSelected: boolean
  data: ListData[]
  selected: SelectedItemProps[]
}) {
  return (isAllSelected ? __unionBy(data, selected, 'id') : selected).reduce((selectedIds, item) => {
    selectedIds[String(item.id)] = true
    return selectedIds
  }, {} as Record<string, boolean>)
}

/**
 * Helper function to build selection control action's payload data
 *
 * @param {boolean} param.isAllSelected - selection control isAllSelected
 * @param {SelectedItemProps[]} param.data - list table data
 * @param {SelectedItemProps[]} param.selected - selection control selected items array
 * @param {Record<string, boolean>} param.selectedRowIds - react-table's selection control object { [ID]: true }
 * @returns selection control action payload data
 */
export function getTableListSelectionData<ListData extends SelectedItemProps>({
  isAllSelected,
  data,
  selected,
  selectedRowIds,
}: {
  isAllSelected: boolean
  data: ListData[]
  selected: SelectedItemProps[]
  selectedRowIds: Record<string, boolean>
}): Partial<ControlTabStateProps<SelectedItemProps>> {
  return {
    selected: isAllSelected ? [] : __unionBy(data, selected, 'id').filter(({ id }) => selectedRowIds[id]),
    isAllSelected,
  }
}

/**
 * Helper function to build selection control action's payload data
 *
 * @param {boolean} param.isAllSelected - selection control isAllSelected
 * @param {SelectedItemProps[]} param.data - list table data
 * @param {SelectedItemProps[]} param.selected - selection control selected items array
 * @param {Record<string, boolean>} param.selectedRowIds - react-table's selection control object { [ID]: true }
 * @returns selection control action payload data
 */
export function getTableExpandableListSelectionData<ListData extends { subRows: SelectedItemProps[] }>({
  isAllSelected,
  data,
  selected,
  selectedRowIds,
}: {
  isAllSelected: boolean
  data: ListData[]
  selected: SelectedItemProps[]
  selectedRowIds: Record<string, boolean>
}): Partial<ControlTabStateProps<SelectedItemProps>> {
  return {
    selected: isAllSelected
      ? []
      : __unionBy(__flatten(data.map(({ subRows }) => subRows)), selected, 'id').filter(({ id }) => selectedRowIds[id]),
    isAllSelected,
  }
}
