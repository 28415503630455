import { AxiosError, AxiosResponse } from 'axios'
import { call, takeLatest } from 'redux-saga/effects'

import { CommonAxiosPayload, SagaWithPromise } from '@services/types'

import { getErrorMessage, getFormErrors, getRFFFormErrors } from '@helpers'

import actions from './actions'
import * as api from './api'

function* callUrlSaga<Payload>({ payload, meta: { resolve, reject } }: SagaWithPromise<CommonAxiosPayload<Payload>>) {
  try {
    const response: AxiosResponse<unknown> = yield call(api.callUrl, payload)
    yield call(resolve, response.data)
  } catch (error) {
    if (payload.isFormError || payload.isHookFormError) {
      const formErrors = payload.isHookFormError ? getFormErrors(error) : getRFFFormErrors(error)
      yield call(reject, formErrors)
    } else {
      // @ts-expect-error not yet TS function
      const errorMsg = getErrorMessage(error as AxiosError)
      yield call(reject, errorMsg)
    }
  }
}

export function* fetchCitiesSaga({ payload, meta: { resolve, reject } }: SagaWithPromise<number | string>) {
  try {
    const response: AxiosResponse<BackendCitiesResponse> = yield call(api.fetchCities, payload)
    yield call(resolve, response.data)
  } catch (error) {
    // @ts-expect-error not yet TS function
    const errorMsg = getErrorMessage(error as AxiosError)
    yield call(reject, errorMsg)
  }
}

export function* fetchExchangeRateSaga({
  payload,
  meta: { resolve, reject },
}: SagaWithPromise<FetchExchangeRatePayload>) {
  try {
    const response: AxiosResponse<FetchExchangeRateResponse> = yield call(api.fetchExchangeRate, payload)
    yield call(resolve, response.data)
  } catch (error) {
    // @ts-expect-error not yet TS function
    const errorMsg = getErrorMessage(error as AxiosError)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  // @ts-expect-error takeLatest's type is not valid for our use case due to all the generalisation it has
  yield takeLatest(actions.callUrl.REQUEST, callUrlSaga)
  // @ts-expect-error takeLatest's type is not valid for our use case due to all the generalisation it has
  yield takeLatest(actions.fetchCities.REQUEST, fetchCitiesSaga)
  // @ts-expect-error takeLatest's type is not valid for our use case due to all the generalisation it has
  yield takeLatest(actions.fetchExchangeRate.REQUEST, fetchExchangeRateSaga)
}
