import __camelCase from 'lodash/camelCase'

const SINGLE_STATUS_LIST = ['REQUEST'] as const
const MULTI_STATUS_LIST = ['REQUEST', 'SUCCESS', 'FAILURE'] as const

type SingleStatusList = typeof SINGLE_STATUS_LIST
type MultiStatusList = typeof MULTI_STATUS_LIST

type PromiseMeta = {
  resolve: (payload: any) => void
  reject: (payload: any) => void
}

type Action = {
  type: string
  payload?: any
  meta?: PromiseMeta
}

type ActionMethod = (payload?: any, meta?: PromiseMeta) => Action

type CreatedAction<TStatusList extends ReadonlyArray<string>> = {
  [UpperK in TStatusList extends ReadonlyArray<infer U> ? U : never]: string
} & {
  [K in TStatusList extends ReadonlyArray<infer U> ? Lowercase<string & U> : never]: ActionMethod
}

type SingleAction = CreatedAction<SingleStatusList>
type MultiAction = CreatedAction<MultiStatusList>
type Return<StatusList> = StatusList extends SingleStatusList ? SingleAction : MultiAction

// based on the package: https://www.npmjs.com/package/redux-saga-actions
function actionCreator(actionType: string, statuses: SingleStatusList | MultiStatusList): Return<typeof statuses> {
  const action = {} as Return<typeof statuses>

  for (const status of statuses) {
    const type = `${actionType}_${status}`
    const methodKey = status.toLowerCase() as `${Lowercase<string & keyof Return<typeof statuses>>}`
    const typeKey = status as `${Uppercase<string & keyof Return<typeof statuses>>}`

    // create action creators:
    // action.request(payload) == { type: 'FETCH_DATA_REQUEST', payload, meta }
    // action.success(payload) == { type: 'FETCH_DATA_SUCCESS', payload, meta }
    // action.failure(payload) == { type: 'FETCH_DATA_FAILURE', payload, meta }
    action[methodKey] = <ActionMethod>function actionMethod(payload, meta) {
      return {
        type,
        payload,
        meta,
      }
    }
    // create action types constants:
    // action.REQUEST == 'FETCH_DATA_REQUEST'
    // action.SUCCESS == 'FETCH_DATA_SUCCESS'
    // action.FAILURE == 'FETCH_DATA_FAILURE'
    action[typeKey] = type
  }

  return action
}

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>

type KeysToCamelCase<T> = {
  [K in keyof T as KeyWithoutPrefix<CamelCase<K & string>, '!'>]: T[K]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TailLetters<TString extends string> = TString extends `${infer FirstLetter}${infer Rest}` ? Rest : never

type KeyWithPrefix<TPrefix extends string> = `${TPrefix}${string}`
type KeyWithoutPrefix<TKey extends string, TPrefix extends string> = TKey extends KeyWithPrefix<TPrefix>
  ? TailLetters<TKey>
  : TKey

type ServiceActions<TActionTypes extends ReadonlyArray<string>> = KeysToCamelCase<{
  [K in TActionTypes extends ReadonlyArray<infer U> ? U : never]: K extends KeyWithPrefix<'!'>
    ? SingleAction
    : MultiAction
}>

export function serviceActionsGenerator<TActionTypes extends ReadonlyArray<string>>(
  service: string,
  actionTypes: TActionTypes
) {
  const actions = {} as Record<string, MultiAction | SingleAction>
  for (const actionType of actionTypes) {
    const isSimple = actionType.charAt(0) === '!'
    const type = isSimple ? actionType.slice(1) : actionType
    // transform type into action name
    const actionName = __camelCase(type.replace('_', ' '))
    const statuses = isSimple ? SINGLE_STATUS_LIST : MULTI_STATUS_LIST

    const action = actionCreator(`@${service}/${type}`, statuses)

    actions[actionName] = action
  }

  return actions as ServiceActions<TActionTypes>
}
