import React from 'react'

export const StrongTextValues = {
  b: function Strong(chunks: React.ReactNode) {
    return <strong>{chunks}</strong>
  },
}

// NOTE not used yet
export function startsWithVowel(word: string) {
  return /[aáeéiíoóöőuúüű]/i.test(word[0])
}
