import React from 'react'

import { CommonAxiosPayload } from '@services/types'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { BackendInvoicePreviewData, InvoicePreviewLoaderState } from './types'

export function useLoadInvoicePreviewData({
  callUrl,
  previewUrl,
}: {
  callUrl: AsyncFunction<CommonAxiosPayload, BackendInvoicePreviewData>
  previewUrl: string
}) {
  const [state, setState] = React.useState<InvoicePreviewLoaderState>({
    data: null,
    loading: false,
    error: null,
  })
  const cPromiseRef = useCancellablePromiseRef<BackendInvoicePreviewData>()

  React.useEffect(() => {
    async function fetchData() {
      try {
        // keep previous data during update - show loading spinner on top of the old data
        setState(prevState => ({ ...prevState, loading: true }))
        cPromiseRef.current = cancellablePromise(callUrl({ url: previewUrl, method: 'get' }))
        const results = await cPromiseRef.current.promise
        setState(prevState => ({ ...prevState, data: results, loading: false }))
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setState({ data: null, loading: false, error: errorMessage })
        }
      }
    }

    fetchData()
  }, [cPromiseRef, callUrl, previewUrl])

  return state
}
