import React from 'react'
import PropTypes from 'prop-types'

import { Badge } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

export const TabBadge = withStyles(theme => ({
  badge: {
    backgroundColor: theme.palette.grayscale.inputLabel,
    border: '2px solid #F1F2F4',
    color: theme.palette.common.white,
    fontSize: 10,
    fontWeight: 600,
    padding: '0 3px',
    borderRadius: 4,
    height: 16,
    minWidth: 16,
    '&$dot': {
      minWidth: 12,
      height: 12,
      borderRadius: '50%',
    },
  },
  dot: {},
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
    border: '2px solid #fff',
  },
  colorSecondary: {
    backgroundColor: theme.palette.tertiary.oceanBlue,
    border: '2px solid #fff',
  },
}))(Badge)

function PureAttachmentTab({ count, active }) {
  return (
    <TabBadge badgeContent={count} color={active ? 'secondary' : 'default'} variant="standard" invisible={!count}>
      <FormattedMessage id="detailsTabs.label.documents" defaultMessage="Csatolmányok" />
    </TabBadge>
  )
}

PureAttachmentTab.propTypes = {
  type: PropTypes.oneOf(['expense', 'income']).isRequired,
  active: PropTypes.bool.isRequired,
  count: PropTypes.number,
}

export const AttachmentTab = connect((state, ownProps) => {
  const details = ownProps.type === 'expense' ? state.expense.details : state.income.details
  return {
    count: details.meta.attachment_count,
  }
})(PureAttachmentTab)

AttachmentTab.displayName = 'AttachmentTab'
