import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { expenseActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage, userHasEditorPermission } from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { ListActionsDialogActions, ListActionsDialogBody } from '@components/dialogs'
import {
  AsyncButtonWithIcon,
  Button,
  CustomDialog,
  CustomDialogHeader,
  FlexRow,
  InfoText,
  Paper,
  Typography,
  WalletPlusIcon,
} from '@components/ui'

import { CancelButtonMessage, FilingButtonMessage, FilingConfirmDialogTitleMessage } from '@messages'

const Wrapper = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
`

type FilingResponse = BackgroundActionResponse<
  unknown,
  {
    denied: Array<{ partner: string; invoiceNumber: string }>
  }
>

interface ExpenseDetailsFilingProps {
  actionEnabled: boolean
  callFiling: AsyncFunction<
    {
      ids: ItemIdType[]
      isAllSelected: boolean
    },
    FilingResponse
  >
  callLastFilingNumber: AsyncFunction<void, string>
  filingNumber?: ExpenseDetailsFrontendValues['filing_number']
  invoiceId?: ItemIdType
  isEditorUser: boolean
}

function PureExpenseDetailsFiling({
  actionEnabled,
  callFiling,
  callLastFilingNumber,
  filingNumber,
  invoiceId,
  isEditorUser,
}: ExpenseDetailsFilingProps) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [lastKnownFilingNumber, setLastKnownFilingNumber] = React.useState('')
  const cPromise = useCancellablePromiseRef<string>()
  const cPromiseFiling = useCancellablePromiseRef<FilingResponse>()
  const { setErrorAlert } = useAlertDispatch()

  async function handleOpen() {
    setLoading(true)
    try {
      cPromise.current = cancellablePromise(callLastFilingNumber())
      const result = await cPromise.current.promise
      setLastKnownFilingNumber(result)
      setLoading(false)
      setOpen(true)
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setLoading(false)
        setErrorAlert(errorMessage)
      }
    }
  }

  function handleClose() {
    setOpen(false)
  }

  async function handleFiling() {
    setLoading(true)
    setOpen(false)
    if (invoiceId) {
      try {
        cPromiseFiling.current = cancellablePromise(callFiling({ ids: [invoiceId], isAllSelected: false }))
        const {
          errors: { denied },
        } = await cPromiseFiling.current.promise
        if (denied.length > 0) {
          setErrorAlert(
            <FormattedMessage
              id="expense.details.filingError"
              defaultMessage="Sajnáljuk! A számlán az iktatás nem végrehajtható, amíg összerendelésre vár egy NAV-os számlával vagy duplikáció. Kérünk végezd el az összerendelést vagy szüntesd meg a duplikációt."
            />,
            { autoHideDuration: 8000 }
          )
        }
        setLoading(false)
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setLoading(false)
          setErrorAlert(errorMessage)
        }
      }
    }
  }

  return (
    <Wrapper>
      <Typography size="400-sm" color="inherit">
        {filingNumber ? (
          <FormattedMessage
            id="form.text.filingNumber"
            defaultMessage="Ez a számla már iktatva van a(z) {value} iktatószámon."
            values={{ value: <strong>{filingNumber}</strong> }}
          />
        ) : (
          <FormattedMessage id="form.text.filingNumber.notSet" defaultMessage="Ez a számla még nincs iktatva." />
        )}
      </Typography>
      {actionEnabled && !filingNumber && isEditorUser && (
        <>
          <AsyncButtonWithIcon
            icon={<WalletPlusIcon size={24} />}
            size="small"
            variant="primaryText"
            onClick={handleOpen}
            loading={loading}
          >
            {FilingButtonMessage}
          </AsyncButtonWithIcon>
          <CustomDialog open={open} onClose={handleClose}>
            <CustomDialogHeader title={FilingConfirmDialogTitleMessage} borderless />
            <ListActionsDialogBody>
              <Paper infoBox>
                <InfoText iconColor="gray-40" color="gray-80" size="400-md">
                  <FormattedMessage
                    id="dialogs.filing.confirmText"
                    defaultMessage="A legutolsó kiosztott iktatószám a(z) {lastKnownFilingNumber} volt. Ha ezt a számlát iktatod, akkor később már nem fogod tudni visszavonni ezt a műveletet."
                    values={{ lastKnownFilingNumber }}
                  />
                </InfoText>
              </Paper>
            </ListActionsDialogBody>
            <ListActionsDialogActions borderless>
              <Button variant="primaryContained" onClick={handleFiling}>
                {FilingButtonMessage}
              </Button>
              <Button variant="primaryText" onClick={handleClose}>
                {CancelButtonMessage}
              </Button>
            </ListActionsDialogActions>
          </CustomDialog>
        </>
      )}
    </Wrapper>
  )
}

PureExpenseDetailsFiling.propTypes = {
  actionEnabled: PropTypes.bool.isRequired,
  callFiling: PropTypes.func.isRequired,
  callLastFilingNumber: PropTypes.func.isRequired,
  filingNumber: PropTypes.string,
  invoiceId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  isEditorUser: PropTypes.bool.isRequired,
}

export const ExpenseDetailsFiling = connect(
  (state: Store) => ({
    actionEnabled: !state.expense.details.data?.missing,
    filingNumber: state.expense.details.data?.filing_number,
    invoiceId: state.expense.details.data?.id,
    isEditorUser: userHasEditorPermission(state.auth.company.data.role),
  }),
  dispatch => ({
    callFiling: bindActionToPromise(dispatch, expenseActions.expenseFiling.request),
    callLastFilingNumber: bindActionToPromise(dispatch, expenseActions.fetchLastFilingNumber.request),
  })
)(PureExpenseDetailsFiling)

ExpenseDetailsFiling.displayName = 'ExpenseDetailsFiling'
