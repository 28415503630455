import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { elementsStyles as styles } from '@styles/scrollableTable'

function ScrollableDataTableRow({
  classes,
  children,
  footer = false,
  header = false,
  highlighted = false,
  warning = false,
  width,
}) {
  return (
    <div
      className={cx(classes.row, {
        [classes.headerRow]: header,
        [classes.footerRow]: footer,
        [classes.highlightedRow]: highlighted,
        [classes.warningRow]: warning,
      })}
      role="row"
      style={{ width }}
    >
      {children}
    </div>
  )
}

ScrollableDataTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  footer: PropTypes.bool,
  header: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
  highlighted: PropTypes.bool,
  warning: PropTypes.bool,
}

export const ScrollableTableRow = withStyles(styles)(ScrollableDataTableRow)

function ScrollableDataTableCell({ alignRight = false, classes, className, children, style }) {
  return (
    <div className={cx(classes.cell, className, { [classes.alignRightCell]: alignRight })} role="cell" style={style}>
      {children}
    </div>
  )
}

ScrollableDataTableCell.propTypes = {
  alignRight: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}

export const ScrollableTableCell = withStyles(styles)(ScrollableDataTableCell)
