import { defineMessages } from 'react-intl'

const messages = defineMessages({
  //* PaidThroughEditorForm
  autokassza: {
    id: 'forms.paidThrough.autokassza',
    defaultMessage: 'Autokassza',
  },
  autoCashRegisterLabel: {
    id: 'forms.paidThrough.autoCashRegisterLabel',
    defaultMessage: 'Autokassza szinkronizálás',
  },
  balanceLabel: {
    id: 'forms.paidThrough.balanceLabel',
    defaultMessage: 'Nyitó egyenleg',
  },
  balanceDateLabel: {
    id: 'forms.paidThrough.balanceDateLabel',
    defaultMessage: 'Nyitó egyenleg dátuma',
  },
  bankAccount: {
    id: 'forms.paidThrough.bankAccount',
    defaultMessage: 'Bankszámla',
  },
  bankAccountNumberLabel: {
    id: 'forms.paidThrough.bankAccountNumberLabel',
    defaultMessage: 'Bankszámlaszám',
  },
  bankAccountIdentifierLabel: {
    id: 'forms.paidThrough.bankAccountIdentifierLabel',
    defaultMessage: 'Forrásszámla azonosító',
  },
  cash: {
    id: 'forms.paidThrough.cash',
    defaultMessage: 'Készpénz',
  },
  currencyLabel: {
    id: 'forms.paidThrough.currency',
    defaultMessage: 'Pénznem',
  },
  dateFieldPlaceholder: {
    id: 'forms.paidThrough.dateFieldPlaceholder',
    defaultMessage: 'éééé-hh-nn',
  },
  paidThroughLedgerNumberLabel: {
    id: 'forms.paidThrough.ledgerNumber',
    defaultMessage: 'Főkönyvi szám',
  },
  paidThroughNameLabel: {
    id: 'forms.paidThrough.name',
    defaultMessage: 'Pénztárca megnevezése',
  },
  paidThroughTypeLabel: {
    id: 'forms.paidThrough.type',
    defaultMessage: 'Típus',
  },
  providerLabel: {
    id: 'forms.paidThrough.providerLabel',
    defaultMessage: 'Bankszámla szolgáltató',
  },
  providerPlaceholder: {
    id: 'forms.paidThrough.providerPlaceholder',
    defaultMessage: 'Nincs',
  },
  //* EditorFormBalanceSection
  autokasszaInfo: {
    id: 'paidThrough.editor.info.autokassza',
    defaultMessage:
      'Beállításaid alapján úgy látjuk, hogy banki tranzakcióid szinkronizálhatóak az autokasszán keresztül. Szinkronizálás bekapcsolása esetén adj meg egy tetszőleges dátumot, és annak napi nyitóegyenlegét. Ettől a naptól szinkronizáljuk majd a tranzakciókat.',
  },
  balanceAccordionHeader: {
    id: 'paidThrough.editor.balance.accordionHeader',
    defaultMessage: 'Egyenlegek',
  },
  balanceInfoCash: {
    id: 'paidThrough.editor.info.balance.cash',
    defaultMessage:
      'Pénzeszközeid egyenlegeinek QUiCK-en belüli követéséhez adj meg a pénztárcához egy adott naphoz tartozó nyitó egyenleget! Figyelem! Jelenleg csak házipénztár típusú és autokasszához társított pénzeszközök esetén működik az egyenleg követés.',
  },
  balanceInfoBankAccount: {
    id: 'paidThrough.editor.info.balance.bankAccount',
    defaultMessage:
      'A bankszámla egyenleg QUiCK-en belüli követéséhez adj meg a pénztárcához egy adott naphoz tartozó záró egyenleget, melyet bármikor frissíthetsz itt, a pénztárca szerkesztésénél.',
  },
})

export default messages
