import React from 'react'
import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'

// local helper
function formatDate(val) {
  if (!val) return val
  const str = val.replace(/[^\d]/g, '')
  if (str.length < 5) {
    return str
  }
  if (str.length < 7) {
    return `${str.substr(0, 4)}-${str.substr(4)}`
  }
  return `${str.substr(0, 4)}-${str.substr(4, 2)}-${str.substr(6, 2)}`
}

export function DateInput({ onChange, inputRef, value, ...rest }) {
  return (
    <NumberFormat
      {...rest}
      autoComplete="off"
      format={formatDate}
      getInputRef={inputRef}
      type="tel"
      isNumericString
      onChange={onChange}
      allowEmptyFormatting
      value={value ?? ''}
    />
  )
}

DateInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
