import React from 'react'

import styled from 'styled-components'

function PureAddPostItIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill="currentColor"
        d="M7.884 18.042a2.24 2.24 0 0 0-2.09.3c-.385.281-1.046.694-1.847 1.064a8.3 8.3 0 0 0 .53-2.315 2.276 2.276 0 0 0-.554-1.664C2.831 14.194 2.25 12.75 2.25 11.25c0-3.727 3.905-7.5 9.75-7.5 5.845 0 9.75 3.773 9.75 7.5s-3.905 7.5-9.75 7.5c-1.481 0-2.873-.258-4.116-.708Zm-6.651 1.824a8.464 8.464 0 0 1-.24.38l-.013.023-.225.323c-.164.22-.343.436-.53.633a.742.742 0 0 0-.16.816.75.75 0 0 0 .694.464c.24 0 .479-.014.718-.038l.032-.005c.207-.023.413-.051.62-.089a11.576 11.576 0 0 0 2.46-.778 14.038 14.038 0 0 0 2.545-1.434c1.491.539 3.141.839 4.88.839 6.628 0 12-4.364 12-9.75S18.628 1.5 12 1.5c-6.628 0-12 4.364-12 9.75 0 2.114.83 4.069 2.236 5.667-.09 1.149-.534 2.17-1.003 2.949Zm9.642-4.491c0 .623.502 1.125 1.125 1.125s1.125-.502 1.125-1.125v-3h3c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125h-3v-3C13.125 6.502 12.623 6 12 6s-1.125.502-1.125 1.125v3h-3c-.623 0-1.125.502-1.125 1.125s.502 1.125 1.125 1.125h3v3Z"
      />
    </svg>
  )
}

export const AddPostItIcon = styled(PureAddPostItIcon)``
