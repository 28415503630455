import React from 'react'
import { bool, string } from 'prop-types'

import { FormattedDate } from 'react-intl'

import ValueWithBadges from './ValueWithBadges'

export default function DateTitleWithBadge({ expiring, expired, title, user_saw }) {
  return (
    <ValueWithBadges
      badges={{
        expireSoon: expiring,
        expired: expired,
        processed: false,
        notSeenYet: user_saw === null,
      }}
    >
      <FormattedDate value={new Date(title)} year="numeric" month="long" />
    </ValueWithBadges>
  )
}

DateTitleWithBadge.propTypes = {
  expiring: bool.isRequired,
  expired: bool.isRequired,
  title: string.isRequired,
  user_saw: string,
}
