import React from 'react'

import { defineMessages, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { PrivatePage } from '@components/layouts'
import { AmplitudeTrackPageView } from '@oldComponents/AmplitudeTracking'

import { PulsePageContent, PulsePageWrapper } from './elements'
import { PulseDialogs } from './types'
import WelcomeToTaskListDialog from './WelcomeToTaskListDialog'

const messages = defineMessages({
  subtitle: {
    id: 'pages.subtitle.reports',
    defaultMessage: 'Pulzus',
  },
})

export default function PulsePage() {
  const [openedDialog, setOpenedDialog] = React.useState<Nullable<PulseDialogs>>(null)

  const { formatMessage } = useIntl()
  const location = useLocation<Nullable<{ showDialog?: PulseDialogs }>>()

  React.useEffect(() => {
    // dialogs can be triggered when redirecting the user to Pulse page using react-router state
    if (location.state?.showDialog) {
      setOpenedDialog(location.state.showDialog)
      // need to clear window history state so a refresh does not trigger the dialog again
      window.history.replaceState({}, document.title)
    }
  }, [location.state?.showDialog])

  function closeDialog() {
    setOpenedDialog(null)
  }

  return (
    <PrivatePage subtitle={formatMessage(messages.subtitle)}>
      <AmplitudeTrackPageView eventProperties={{ screen: 'reports' }} />
      <PulsePageWrapper>
        <PulsePageContent />
      </PulsePageWrapper>
      <WelcomeToTaskListDialog isOpen={openedDialog === PulseDialogs.WELCOME_TO_TASK_LIST} onClose={closeDialog} />
    </PrivatePage>
  )
}
