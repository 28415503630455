import React from 'react'

import { useIntl } from 'react-intl'

import { useCreatePayment } from '@contexts/PaymentProvider'

import { BulkActionDisabledByAllSelectedTooltip, CoinsRegularIcon } from '@components/ui'
import { QuickIconButton } from '@oldComponents/ui/buttons'

import { PAYMENT_FLOW } from '@constants'

import { listPageMessages } from '@messages'

interface CreateExpressPaymentIconButtonProps {
  disabled: boolean
  isAllSelected: boolean
}

export function CreateExpressPaymentIconButton({ disabled, isAllSelected }: CreateExpressPaymentIconButtonProps) {
  const [open, setOpen] = React.useState(false)
  const { formatMessage } = useIntl()
  const {
    startExpressPayment,
    state: { loading, flow },
  } = useCreatePayment()

  function handleClick() {
    setOpen(false)
    startExpressPayment()
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <QuickIconButton
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onClick={handleClick}
      disabled={disabled || loading}
      loading={loading && flow === PAYMENT_FLOW.express}
      icon={<CoinsRegularIcon />}
      size="medium"
      tooltipText={
        <BulkActionDisabledByAllSelectedTooltip
          isAllSelected={isAllSelected}
          tooltip={formatMessage(listPageMessages.createExpressPayment)}
        />
      }
    />
  )
}
