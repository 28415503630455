import { COMPANY_ROUTE, V2_COMPANY_ROUTE } from '@constants'

export const API_COMPANY_REPORT_URL = `${COMPANY_ROUTE}report/`
export const API_COMPANY_WORKOUT_REPORT_URL = `${COMPANY_ROUTE}report/workout/`
export const API_COMPANY_WORKOUT_REPORT_DETAILS_URL = `${COMPANY_ROUTE}report/workout/{partner_id}`
export const API_COMPANY_PARTNER_REPORT_DETAILS_URL = `${COMPANY_ROUTE}payee/partner/{partner_id}`
export const API_COMPANY_CREATE_PAYEE_WORKFLOW_URL = `${COMPANY_ROUTE}payee/partner/{partner_id}/cases`
export const API_COMPANY_VAT_POSITION_REPORT_URL = `${COMPANY_ROUTE}report/vat-position`
export const API_COMPANY_BANK_TRANSACTIONS_REPORT_URL = `${COMPANY_ROUTE}report/bank-transactions/`
export const API_AI_CHAT_DETAILS_URL = `${V2_COMPANY_ROUTE}ai/chat`
