import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { authActions, subscriptionActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useSubscription } from '@contexts'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { AsyncButton, Button } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'
import { FlexColumn } from '@components/ui/Wrappers'

import { reducer } from './helpers'

import { CloseButtonMessage } from './messages'

const WrapperDiv = styled.div`
  ${Typography} + ${Typography} {
    margin-top: 16px;
  }
`

const ButtonsContainer = styled(FlexColumn)`
  width: 300px;
  margin: 32px auto 0;
  gap: 8px;
`

interface SubscriptionTrialProps {
  createSubscription: AsyncFunction<{ plan_id: number; trial: boolean }, CompanySubscription>
  setActiveSubscription: (payload: CompanySubscription) => void
}

function PureSubscriptionTrial({ createSubscription, setActiveSubscription }: SubscriptionTrialProps) {
  const { clearTier, selectedPlan, setSuccessView } = useSubscription()
  const cPromiseRef = useCancellablePromiseRef<CompanySubscription>()
  const [{ loading, error }, dispatch] = React.useReducer(reducer, { loading: false, error: null })

  const handleCreateTrial = React.useCallback(() => {
    if (!selectedPlan) {
      throw Error('Missing selected plan')
    }
    dispatch({ type: 'request' })

    const payload = {
      plan_id: selectedPlan.id,
      trial: true,
    }

    cPromiseRef.current = cancellablePromise(createSubscription(payload))
    cPromiseRef.current.promise
      .then(newSubscription => {
        setSuccessView('trial')
        setActiveSubscription(newSubscription)
      })
      .catch(error => {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          dispatch({ type: 'failure', payload: errorMsg })
        }
      })
  }, [selectedPlan, cPromiseRef, createSubscription, setActiveSubscription, setSuccessView])

  return (
    <WrapperDiv>
      <Typography color="gray-80" align="center">
        <FormattedMessage
          id="subscription.trial.description"
          defaultMessage="Próbaidőszakod alatt bármikor csomagot válthatsz. Csomagváltásod a 30 napos próbaidőszakodat nem befolyásolja."
        />
      </Typography>

      {error && (
        <Typography align="center" color="error">
          {error}
        </Typography>
      )}

      <ButtonsContainer>
        <AsyncButton
          data-testid="submit-btn"
          fullWidth
          variant="primaryContained"
          onClick={handleCreateTrial}
          loading={loading}
        >
          <FormattedMessage id="dialogs.subscribePayment.buttons.trial" defaultMessage="Elindítom" />
        </AsyncButton>

        <Button fullWidth variant="primaryText" color="primary" onClick={clearTier}>
          {CloseButtonMessage}
        </Button>
      </ButtonsContainer>
    </WrapperDiv>
  )
}

PureSubscriptionTrial.propTypes = {
  companyId: PropTypes.number.isRequired,
  createSubscription: PropTypes.func.isRequired,
  setActiveSubscription: PropTypes.func.isRequired,
}

export const SubscriptionTrial = connect(
  (state: Store) => ({
    companyId: state.auth.company.data.id,
  }),
  dispatch => ({
    createSubscription: bindActionToPromise(dispatch, subscriptionActions.createSubscription.request),
    setActiveSubscription: bindActionCreators(authActions.setActiveSubscription.request, dispatch),
  })
)(PureSubscriptionTrial)

SubscriptionTrial.displayName = 'SubscriptionTrial'
