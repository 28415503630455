import React from 'react'

import { Button as MuiButton, ButtonProps } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Button = withStyles((theme: any) => ({
  root: {
    padding: 0,
    fontSize: 'inherit',
    fontWeight: 'bold',
    minHeight: 0,
    color: theme.palette.neutral.dark,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.neutral.xDark,
      textDecoration: 'underline',
    },
  },
}))(MuiButton)

export default function DefaultLinkButton(props: Omit<ButtonProps, 'color' | 'variant'>) {
  return <Button {...props} variant="text" color="secondary" />
}
