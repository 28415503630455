import styled, { css } from 'styled-components'

import {
  CustomDialog,
  CustomDialogActions,
  FlexRow,
  LabelInfoIcon,
  SimpleContentAccordion,
  TaxationOverview,
  Typography,
} from '@components/ui'

import { DIALOG_CLASS_NAMES, SIMPLE_DIALOG_MAX_WIDTH } from '@constants'

import { TaxationCheckList } from './TaxationCheckList'
import TaxationPayables from './TaxationPayables'

export const StyledTaxationCheckList = styled(TaxationCheckList)`
  margin-top: 20px;
`

export const StyledTaxationOverview = styled(TaxationOverview)`
  margin-top: 20px;
`

export const StyledSimpleContentAccordion = styled(SimpleContentAccordion)`
  margin-top: 24px;
`

export const IndicatorContainerDiv = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidator) => {
    return !['columnsNum', 'fullWidth'].includes(prop) && defaultValidator(prop)
  },
})<{ columnsNum?: 1 | 2; fullWidth?: boolean }>`
  padding-top: 12px;
  max-width: ${({ columnsNum = 2 }) => columnsNum * 361 + (columnsNum - 1) * 20}px;

  ${({ columnsNum = 2, fullWidth = false }) =>
    columnsNum === 2 && !fullWidth
      ? css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20px;
          row-gap: 6px;
        `
      : css`
          display: flex;
          flex-direction: column;
          gap: 6px;
        `}
`

// This is used iside `IndicatorContainerDiv` and is placed in a full size manner
export const OverrideInformationTypography = styled(Typography)`
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  gap: 6px;
`

export const StyledLabelInfoIcon = styled(LabelInfoIcon)`
  color: ${({ theme }) => theme.colors.gray[160]};
`

export const StyledTaxationPayables = styled(TaxationPayables)`
  margin-top: 20px;
`

export const StyledItalicTypography = styled(Typography).attrs({ italic: true })`
  margin-top: 10px;
`

export const RejectedItalicTypography = styled(StyledItalicTypography)`
  margin-top: 48px;
  max-width: 565px;
`

export const ButtonsContainer = styled(FlexRow)`
  margin-top: 48px;
  gap: 10px;
`

export const TaskCustomDialogActions = styled(CustomDialogActions)`
  padding: 0;
  margin-top: 20px;
  border-top: none;
  flex-direction: column;
  align-items: center;
`

export const TaskFormDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
  justify-content: unset;

  & > button {
    width: auto;
  }
`

// set custom size for this dialog (keep the max size)
export const TaskCustomDialog = styled(CustomDialog)`
  width: 100%;
  &.${DIALOG_CLASS_NAMES.content.base} {
    max-width: ${SIMPLE_DIALOG_MAX_WIDTH}px;
    max-height: min(calc(100vh - 52px * 2), 716px);
  }
`
//* tax overwrite elements
export const ModifyTaxForm = styled.form`
  transition: min-height 200ms ease-out;
  min-height: calc(var(--form-height, 0) * 1px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const TaxOverwriteDialogSubtitleTypography = styled(Typography)`
  margin-bottom: 10px;

  strong {
    color: ${({ theme }) => theme.colors.primary[50]};
  }
`

export const SummaryCustomDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
`
