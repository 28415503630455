import React from 'react'

import { connect } from 'react-redux'

import { filtersActions } from '@services'

import { usePageConfig } from '@contexts'

import { FilterBar, FilterBarBaseProps } from './FilterBar'
import { getSelectableFilterProps, getStatusFilterProps } from './helpers'

import {
  DocumentTypeFilterEmptyPlaceholderMessage,
  DocumentTypeLabelMessage,
  TagFilterEmptyPlaceholderMessage,
  TagFilterLabelMessage,
} from '@messages'

interface PureDokumentsPageFilterBarProps extends FilterBarBaseProps {
  documentTypeOptions: CommonIdAndNameType[]
  filters: DokumentsStoreFilters
  tagOptions: Tag[]
}

function PureDokumentsPageFilterBar({
  documentTypeOptions,
  filters,
  filters: { withTagIds, withDocumentTypeIds, search, searchFields, dateField },
  isDateFilterDisabled,
  tagOptions,
  updateFilters,
  resetFilters,
}: PureDokumentsPageFilterBarProps) {
  const pageConfig = usePageConfig()

  const isDateFilterUsed = true
  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }
  const selectableFilterProps = getSelectableFilterProps({
    values: {
      withTagIds,
      withDocumentTypeIds,
    },
    config: [
      {
        keyValue: 'withDocumentTypeIds' as const,
        options: documentTypeOptions,
        labelText: DocumentTypeLabelMessage,
        emptyPlaceholder: DocumentTypeFilterEmptyPlaceholderMessage,
      },
      {
        keyValue: 'withTagIds' as const,
        options: tagOptions,
        labelText: TagFilterLabelMessage,
        emptyPlaceholder: TagFilterEmptyPlaceholderMessage,
      },
    ],
  })

  let statusFilterProps
  if (pageConfig.statusFilterProps) {
    statusFilterProps = getStatusFilterProps({
      config: pageConfig.statusFilterProps.config,
      values: filters,
      width: 385,
    })
  }

  return (
    <FilterBar
      dateField={dateField}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      selectableFilterProps={selectableFilterProps}
      statusFilterProps={statusFilterProps}
      updateFilters={updateFilters}
    />
  )
}

export const DokumentsPageFilterBar = connect(
  ({
    dashboard: {
      tags: { data: tagOptions },
    },
    dokuments: {
      dokumentTypes: { data: documentTypeOptions },
    },
    filters: { dokumentList, isGlobalDateFilterDisabled },
  }: Store) => {
    return {
      documentTypeOptions,
      filters: dokumentList,
      isDateFilterDisabled: isGlobalDateFilterDisabled,
      tagOptions,
    }
  },
  {
    resetFilters: filtersActions.resetDokumentFilters.request,
    updateFilters: filtersActions.updateDokumentFilters.request,
  }
)(PureDokumentsPageFilterBar)
DokumentsPageFilterBar.displayName = 'DokumentsPageFilterBar'
