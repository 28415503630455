import React from 'react'
import PropTypes from 'prop-types'

import { Box, Fade } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { useWatch } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { LightTooltip } from '@oldComponents/ui'

interface AccountNumberWarningProps {
  children: React.ReactChild
  fieldIndex: number
  partnerAccountNumber?: Nullable<string>
}

export function AccountNumberWarning({ children, fieldIndex, partnerAccountNumber }: AccountNumberWarningProps) {
  const currentTransactionsValue = useWatch<
    { transactions: Array<{ accountNumber: Nullable<string> }> },
    'transactions'
  >({
    name: 'transactions',
    defaultValue: [],
  })
  const currentValue = currentTransactionsValue[fieldIndex]?.accountNumber ?? null
  const showWarning = Boolean(currentValue && partnerAccountNumber && partnerAccountNumber !== currentValue)
  return (
    <Box component="div" display="flex" style={{ gap: 5 }}>
      {children}

      <Fade in={showWarning}>
        <LightTooltip
          title={
            <FormattedMessage
              id="payment.warnings.differenAccountNumber"
              defaultMessage="A jelenleg beírt bankszámlaszám nem egyezik a számlán szereplővel."
            />
          }
          placement="top"
          PopperProps={{ disablePortal: true }}
        >
          <Box component="span" display="inline-flex" width={20} height={30} alignItems="center" fontSize={20}>
            <ErrorIcon color="action" fontSize="inherit" data-testid="account-number-warning-icon" />
          </Box>
        </LightTooltip>
      </Fade>
    </Box>
  )
}

AccountNumberWarning.propTypes = {
  children: PropTypes.node.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  partnerAccountNumber: PropTypes.string,
}
