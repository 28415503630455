import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { SzamlazzHuIcon, Typography } from '@components/ui'

import { QUICK_COLORS } from '@constants'

const InvoicePreviewNavBadgeWrapper = styled.span`
  background-color: ${({ theme }) => theme.colors.common.navBadge};
  color: ${({ theme }) => theme.colors.gray[0]};
  padding: 2px 6px 2px 4px;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  align-items: center;
`

export function InvoicePreviewNavBadge() {
  return (
    <InvoicePreviewNavBadgeWrapper>
      <SzamlazzHuIcon color={QUICK_COLORS.gray[0]} size={16} />
      <Typography size="400-xs" color="inherit" tag="span">
        <FormattedMessage id="badges.invoicePreview.nav" defaultMessage="NAV adattal rendelkező számla" />
      </Typography>
    </InvoicePreviewNavBadgeWrapper>
  )
}
