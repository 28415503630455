import React from 'react'
import PropTypes from 'prop-types'

import { Collapse } from '@components/ui/CollapseElements'

import { SimpleContentAccordionControl, SimpleContentAccordionControlBaseProps } from './elements'

interface SimpleContentAccordionProps extends SimpleContentAccordionControlBaseProps {
  children: React.ReactChild | Nullable<React.ReactChild>[]
  visibleOnRender?: boolean
}

/**
 * Simple component with collapse functionality
 *
 * @param {SimpleContentAccordionProps} {
 *   children,
 *   className,
 *   title,
 *   titleTag = 'h2',
 *   visibleOnRender = true,
 * }
 */
export default function SimpleContentAccordion({
  children,
  className,
  title,
  titleTag = 'h2',
  visibleOnRender = true,
}: SimpleContentAccordionProps) {
  const [isDetailVisible, setDetailVisible] = React.useState(visibleOnRender)

  function toggleVisibility() {
    setDetailVisible(state => !state)
  }

  return (
    <>
      <SimpleContentAccordionControl
        className={className}
        title={title}
        titleTag={titleTag}
        isDetailVisible={isDetailVisible}
        toggleVisibility={toggleVisibility}
      />
      <Collapse isExpanded={isDetailVisible}>{children}</Collapse>
    </>
  )
}

SimpleContentAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  titleTag: PropTypes.string,
  visibleOnRender: PropTypes.bool,
}
