import React from 'react'

import styled from 'styled-components'

import { DocViewerProvider } from './DocViewerProvider'
import { ProxyRenderer } from './elements'
import { DocRenderer, DocViewerConfig, DocViewerDocument, DocViewerRef } from './types'

const DocViewerContainer = styled.div`
  width: 100%;
  height: 100%;
`

export interface DocViewerProps {
  activeDocument?: DocViewerDocument
  className?: string
  config?: DocViewerConfig
  documents: DocViewerDocument[]
  initialActiveDocument?: DocViewerDocument
  onDocumentChange?: (document: DocViewerDocument) => void
  pageRef?: React.MutableRefObject<number>
  pluginRenderers: DocRenderer[]
  renderPostItLayer?: (scale: number, page?: number) => Nullable<JSX.Element>
  requestHeaders?: Record<string, string>
}

const DocViewer = React.forwardRef<DocViewerRef, DocViewerProps>(({ className, ...rest }, ref) => {
  if (!rest.documents) {
    throw new Error('Please provide an array of documents to DocViewer!')
  }

  return (
    <DocViewerProvider
      ref={ref}
      {...rest}
      {...(process.env.NODE_ENV === 'production' && { prefetchMethod: 'GET' })} // S3 need this
    >
      <DocViewerContainer className={className}>
        <ProxyRenderer />
      </DocViewerContainer>
    </DocViewerProvider>
  )
})

DocViewer.displayName = 'DocViewer'

export default React.memo(DocViewer)
