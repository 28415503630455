export function mapListToObject(items: CommonIdAndNameType[]) {
  const obj = {} as Record<number, string>
  items.forEach(({ id, name }) => {
    obj[id] = name
  })
  return obj
}

export function mapIntoList(list: number[], map: Record<number, string>) {
  return list.map(id => ({
    id,
    name: map[id] ?? '',
  }))
}

export function isWorkerError(result: object[] | object | WorkerError): result is WorkerError {
  return (result as WorkerError).error !== undefined
}
