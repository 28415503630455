import __findIndex from 'lodash/findIndex'
import moment from 'moment'

export const getWeekIndexByPlannedDate = (dates, date) => {
  const plannedMoment = moment(date)
  const plannedWeek = plannedMoment.isoWeek()
  const plannedYear = plannedMoment.year()
  const weekIndex = getWeekIndexByDate(dates, plannedYear, plannedWeek)
  const startWeekLastDayMoment = moment().year(dates[0].year).isoWeek(dates[0].week).endOf('week')

  if (weekIndex === -1 && plannedMoment.isSameOrBefore(startWeekLastDayMoment, 'day')) {
    return 0
  }

  return weekIndex
}

export const getWeekIndexByDate = (dates, year, week) => {
  return __findIndex(dates, function (o) {
    return o.week === week && o.year === year
  })
}

// Generate dates for WeekTag
export const generateDates = function (todayString, data) {
  return data.map((row, index) => {
    const currentMoment = moment().year(row.year).isoWeek(row.week)
    return {
      year: row.year,
      week: row.week,
      from:
        index === 0
          ? currentMoment.format('MMM.DD') + ` (${todayString})`
          : currentMoment.startOf('week').format('MMM.DD'),
      to: currentMoment.endOf('week').format('MMM.DD.'),
    }
  })
}

// empty data
export const generateEmptyLiquidityData = function (todayString) {
  let data = []
  let currentMoment

  for (var i = 0; i < 4; i++) {
    currentMoment = moment().add({ week: i })
    data.push({
      year: currentMoment.year(),
      week: currentMoment.isoWeek(),
      opening_balance: 0,
      incomes: 0,
      wages: 0,
      taxes: 0,
      vats: 0,
      others: 0,
      invoices: 0,
      predicted_invoices: 0,
      closing_balance: 0,
    })
  }

  return {
    data,
    dates: generateDates(todayString, data),
  }
}

export function isResiduaryWages(dates, planned) {
  const { year, week } = dates[0]
  const firstWeekFirstDay = moment().year(year).week(week).startOf('week')
  return planned.isBefore(firstWeekFirstDay)
}
