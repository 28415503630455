import React from 'react'

import styled from 'styled-components'

import { WarningIcon } from '@components/ui/svgIcons'
import { Typography, TypographyProps } from '@components/ui/Typography'

const WrapperTypography = styled(Typography)`
  display: flex;
  gap: 10px;

  ${WarningIcon} {
    flex-shrink: 0;
    margin-top: 2px;
  }
`

export type WarningTextProps = Omit<TypographyProps, 'color' | 'size' | 'tag' | 'align'> & { hideIcon?: boolean }

export function WarningText({ children, hideIcon = false, ...rest }: WarningTextProps) {
  return (
    <WrapperTypography color="error" size="400-sm" {...rest}>
      {!hideIcon && <WarningIcon size={16} />}
      <span>{children}</span>
    </WrapperTypography>
  )
}
