import { serialize } from 'object-to-formdata'
import Qs from 'qs'

import { injectCompanyToURL } from '@helpers'
import { transformV2ParamsToV1 } from '@services/helpers'

import Api from '../api'
import {
  API_ASSIGNMENT_NOTE_RECOMMENDATIONS_URL,
  API_BULK_TAGGING_INIT_URL,
  API_BULK_TAGGING_UPDATE_URL,
  API_COMPANY_CONSENT_CREATE_URL,
  API_COMPANY_CONSENTS_URL,
  API_CONFIG_URL,
  API_DASHBOARD_CHECK_URL,
  API_EVENTS_LOG_URL,
  API_LEDGER_NUMBERS_URL,
  API_PARTNER_JOB_NUMBER_HISTORY_URL,
  API_SALARY_AND_TAX_FILE_UPLOAD_URL,
  API_SALARY_GROUP_DETAIL_URL,
  API_SALARY_GROUP_LIST_URL,
  API_SALARY_LIST_URL,
  API_SALARY_USER_VIEW_URL,
  API_TAGS_URL,
  API_TAX_CODE_LIST_URL,
  API_TAX_GROUP_DETAIL_URL,
  API_TAX_GROUP_LIST_URL,
  API_TAX_LIST_URL,
  API_TAX_USER_VIEW_URL,
} from './urls'

export const initialize = () => Api.get(API_CONFIG_URL)

export const dashboardStatusCheck = companyId => Api.get(injectCompanyToURL(API_DASHBOARD_CHECK_URL, companyId))

// INVOICE TAGS
export const fetchTags = companyId => Api.get(injectCompanyToURL(API_TAGS_URL, companyId))

export const createTag = (companyId, data) => Api.post(injectCompanyToURL(API_TAGS_URL, companyId), data)

// SALARY
export const fetchSalaryGroupDetails = (companyId, monthlySalaryId, params) =>
  Api.get(
    injectCompanyToURL(API_SALARY_LIST_URL, companyId).replace('{monthly_salary_id}', monthlySalaryId),
    params
      ? {
          params,
          paramsSerializer: {
            serialize(v2Params) {
              return Qs.stringify(transformV2ParamsToV1(v2Params), { indices: false })
            },
          },
        }
      : {}
  )

// CREATE SALARY
// by file upload (tax or salary)
export const uploadSalaryAndTax = (companyId, data) =>
  Api.post(injectCompanyToURL(API_SALARY_AND_TAX_FILE_UPLOAD_URL, companyId), data, {
    timeout: 5 * 60000, // 5 * 60sec
    transformRequest: [
      function (data) {
        return serialize(data)
      },
    ],
  })
// by manual create
export const createMonthlySalary = (companyId, data) =>
  Api.post(injectCompanyToURL(API_SALARY_GROUP_LIST_URL, companyId), data)

export const userViewSalary = (companyId, payload) =>
  Api.post(injectCompanyToURL(API_SALARY_USER_VIEW_URL, companyId).replace('{monthly_salary_id}', payload))

export const updateMonthlySalary = (companyId, data) =>
  Api.patch(injectCompanyToURL(API_SALARY_GROUP_DETAIL_URL, companyId).replace('{monthly_salary_id}', data.id), data)

export const removeMonthlySalary = (companyId, monthlySalaryId) =>
  Api.delete(injectCompanyToURL(API_SALARY_GROUP_DETAIL_URL, companyId).replace('{monthly_salary_id}', monthlySalaryId))

// VAT
export const fetchTaxGroupDetails = (companyId, monthly_tax_id, params) =>
  Api.get(
    injectCompanyToURL(API_TAX_LIST_URL, companyId).replace('{monthly_tax_id}', monthly_tax_id),
    params
      ? {
          params,
          paramsSerializer: {
            serialize(v2Params) {
              return Qs.stringify(transformV2ParamsToV1(v2Params), { indices: false })
            },
          },
        }
      : {}
  )

export const userViewTax = (companyId, payload) =>
  Api.post(injectCompanyToURL(API_TAX_USER_VIEW_URL, companyId).replace('{monthly_tax_id}', payload))

export const createMonthlyTax = (companyId, data) =>
  Api.post(injectCompanyToURL(API_TAX_GROUP_LIST_URL, companyId), data)

export const updateMonthlyTax = (companyId, data) =>
  Api.patch(injectCompanyToURL(API_TAX_GROUP_DETAIL_URL, companyId).replace('{monthly_tax_id}', data.id), data)

export const removeMonthlyTax = (companyId, monthlyTaxId) =>
  Api.delete(injectCompanyToURL(API_TAX_GROUP_DETAIL_URL, companyId).replace('{monthly_tax_id}', monthlyTaxId))

// TAX CODES
export const fetchTaxCodes = companyId => Api.get(injectCompanyToURL(API_TAX_CODE_LIST_URL, companyId))

// BULK TAGGING
export const initTagging = (companyId, payload) =>
  Api.post(injectCompanyToURL(API_BULK_TAGGING_INIT_URL, companyId), payload)

export const bulkTagging = (companyId, payload) =>
  Api.post(injectCompanyToURL(API_BULK_TAGGING_UPDATE_URL, companyId), payload)

//* ACCOUNTING (Yeri)
export function fetchJobNumberHistory(companyId, partnerId) {
  return Api.get(injectCompanyToURL(API_PARTNER_JOB_NUMBER_HISTORY_URL, companyId).replace('{partner_id}', partnerId))
}

export function fetchAssignmentNoteRecommendations(companyId) {
  return Api.get(injectCompanyToURL(API_ASSIGNMENT_NOTE_RECOMMENDATIONS_URL, companyId))
}

export function fetchLedgerNumbers(companyId) {
  return Api.get(injectCompanyToURL(API_LEDGER_NUMBERS_URL, companyId))
}

export function createLedgerNumber(companyId, data) {
  return Api.post(injectCompanyToURL(API_LEDGER_NUMBERS_URL, companyId), data)
}

export function fetchEventsLogData(companyId, { documentId, documentType }) {
  return Api.get(
    injectCompanyToURL(API_EVENTS_LOG_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', documentId)
  )
}

//* Company consents
export function getCompanyConsents(companyId) {
  return Api.get(injectCompanyToURL(API_COMPANY_CONSENTS_URL, companyId))
}

export function setCompanyConsent(companyId, payload) {
  return Api.post(injectCompanyToURL(API_COMPANY_CONSENT_CREATE_URL, companyId), payload)
}
