import styled from 'styled-components'

import { getFontStyles } from '@helpers/styled-helpers'

import { CopyAndPasteButton } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'

export const TaxationOverviewWrapperDiv = styled.div`
  --overview-error-color: ${({ theme }) => theme.colors.error[30]};
  --overview-default-value-color: ${({ theme }) => theme.colors.gray[100]};
  --overview-default-value-size: 16px;
  --overview-default-percent-display: inline;
  --overview-default-percent-color: ${({ theme }) => theme.colors.gray[175]};
  --overview-default-percent-border-color: ${({ theme }) => theme.colors.gray[175]};
  --overview-default-percent-background-color: transparent;
  --overview-primary-value-color: ${({ theme }) => theme.colors.blue[40]};
  --overview-primary-percent-border-color: ${({ theme }) => theme.colors.common.lightBadgeBlue};
  --overview-primary-percent-background-color: transparent;
  --overview-primary-percent-color: ${({ theme }) => theme.colors.blue[40]};
  --overview-primary-percent-display: inline;
  --overview-border-color: ${({ theme }) => theme.colors.common.lightBadgeBlue};
`

export const TaxationOverviewItemsWrapperDiv = styled.div`
  display: inline-flex;
  border: 2px solid var(--overview-border-color);
  border-radius: 8px;
  margin: 12px auto auto;
  width: fit-content;
  min-height: 74px;

  & > p {
    padding: 0px 20px;
    margin: 4px 0px;

    &:first-child {
      --overview-default-value-size: 20px;
      --overview-default-value-color: var(--overview-primary-value-color);
      --overview-default-percent-border-color: var(--overview-primary-percent-border-color);
      --overview-default-percent-background-color: var(--overview-primary-percent-background-color);
      --overview-default-percent-color: var(--overview-primary-percent-color);
      --overview-default-percent-display: var(--overview-primary-percent-display);

      min-width: 249px;

      &:last-child {
        min-width: 361px;
      }
    }

    &:nth-child(2) {
      min-width: 278px;
      border-left: 1px solid ${({ theme }) => theme.colors.common.pageBackground};
      border-right: 1px solid ${({ theme }) => theme.colors.common.pageBackground};
    }

    &:last-child {
      min-width: 211px;
    }
  }
`

export const OverviewItemParagraph = styled.p`
  display: flex;
  gap: 2px;
  flex-direction: column;
  justify-content: center;
`

export const LabelTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[160]};
`

export const ValueTypography = styled(Typography)`
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 27px;
  color: var(--overview-default-value-color);
  font-size: var(--overview-default-value-size);

  & > span {
    display: var(--overview-default-percent-display);
    padding: 2px 4px;
    color: var(--overview-default-percent-color);
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    border: 1px solid var(--overview-default-percent-border-color);
    border-radius: 3px;
    background-color: var(--overview-default-percent-background-color);
  }
`

export const StyledCopyAndPasteButton = styled(CopyAndPasteButton)`
  ${({ theme }) => getFontStyles(theme, '700-md')}

  & button {
    color: inherit;
  }
`
