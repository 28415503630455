import React from 'react'
import PropTypes from 'prop-types'

import { PAYMENT_FLOW, PAYMENT_TRANSACTION_TYPE } from '@constants/payments'

//* ##########################
//* BACKEND DATA PROP-TYPES
//* ##########################

//* PAYMENTS
export const PAYMENT_TRANSACTION_EDIT_PROP_TYPE = PropTypes.shape({
  amount: PropTypes.string.isRequired,
  beneficiaryAccountNumber: PropTypes.string.isRequired,
  currencyName: PropTypes.string.isRequired,
  dueAt: PropTypes.string.isRequired,
  exchangeRate: PropTypes.string.isRequired,
  grossAmount: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  partnerAccountNumber: PropTypes.string,
  partnerName: PropTypes.string,
  remaining: PropTypes.string.isRequired,
  secondaryId: PropTypes.string,
  title: PropTypes.string,
  transactionId: PropTypes.number.isRequired,
})

export const PAYMENT_DATA_PROP_TYPE = PropTypes.shape({
  date: PropTypes.string.isRequired,
  download: PropTypes.shape({
    requireProviderAccountNumber: PropTypes.bool.isRequired,
    requireTransferTypeSelection: PropTypes.bool.isRequired,
    transferTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        transferType: PropTypes.string.isRequired,
      }).isRequired
    ),
  }).isRequired,
  flow: PropTypes.oneOf(Object.values(PAYMENT_FLOW)).isRequired,
  hasRestrictedTransactions: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  links: PropTypes.shape({
    delete: PropTypes.string,
    export: PropTypes.string,
  }).isRequired,
  paidThroughId: PropTypes.number.isRequired,
  transactions: PropTypes.arrayOf(PAYMENT_TRANSACTION_EDIT_PROP_TYPE.isRequired).isRequired,
  transactionType: PropTypes.oneOf(Object.values(PAYMENT_TRANSACTION_TYPE)).isRequired,
})

//* ##################
//* FILTERS PROP-TYPES
//* ##################

export const FILTER_DIALOG_FILTER_PROPS_PROP_TYPE = PropTypes.shape({
  dateFilterProps: PropTypes.shape({
    dateTypeOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.node.isRequired,
      }).isRequired
    ).isRequired,
    dateTypeValue: PropTypes.string.isRequired,
  }) as React.Validator<DateFilterProps>,
  isChanged: PropTypes.bool.isRequired,
  page: PropTypes.node.isRequired,
  searchFilterProps: PropTypes.shape({
    search: PropTypes.string.isRequired,
    searchFields: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]).isRequired,
      })
    ).isRequired,
  }) as React.Validator<SearchFilterProps>,
  statusFilterProps: PropTypes.shape({
    config: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.bool,
            label: PropTypes.node.isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ),
    values: PropTypes.object.isRequired,
  }) as React.Validator<StatusFilterProps<StatusFilterConfig>>,
  categoryFilterProps: PropTypes.shape({
    config: PropTypes.shape({
      excludeKey: PropTypes.string.isRequired,
      includeKey: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
    values: PropTypes.object.isRequired,
  }) as React.Validator<IncludeExcludeFilterProps>,
  tagFilterProps: PropTypes.shape({
    config: PropTypes.shape({
      excludeKey: PropTypes.string.isRequired,
      includeKey: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
    values: PropTypes.object.isRequired,
  }) as React.Validator<IncludeExcludeFilterProps>,
  currencyFilterProps: PropTypes.shape({
    currencyId: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }) as React.Validator<CurrencyFilterProps>,
  amountFilterProps: PropTypes.shape({
    grossAmountMax: PropTypes.string,
    grossAmountMin: PropTypes.string,
  }) as React.Validator<AmountFilterProps>,
  originFilterProps: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    config: PropTypes.shape({
      name: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.node.isRequired,
          icon: PropTypes.node,
        })
      ).isRequired,
    }).isRequired,
  }) as React.Validator<FilterDialogMultiChoiceFilterProps>,
  paymentMethodFilterProps: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    config: PropTypes.shape({
      name: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.node.isRequired,
          icon: PropTypes.node,
        })
      ).isRequired,
    }).isRequired,
  }) as React.Validator<FilterDialogMultiChoiceFilterProps>,
  paidStatusFilterProps: PropTypes.shape({
    values: PropTypes.shape({
      isPaid: PropTypes.bool.isRequired,
      isExpiring: PropTypes.bool.isRequired,
      isExpired: PropTypes.bool.isRequired,
      expiringDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      expiredDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
  }) as React.Validator<FilterDialogPaidStatusFilterProps>,
  paidThroughFilterProps: PropTypes.shape({
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    config: PropTypes.shape({
      name: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }) as React.Validator<FilterDialogPaidThroughFilterProps>,
}) as PropTypes.Requireable<FilterDialogProps<StatusFilterConfig>>
