import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { PortalProvider } from '@contexts'

import { ParentSize } from '@components/ui'

import { DOC_PREVIEW_CONFIG } from '@constants'

import { PartnerPreviewData } from '../types'
import { PartnerLastInvoicePreview } from './PartnerLastInvoicePreview'
import { PartnerPreviewAnchorElement } from './PartnerPreviewAnchorElement'

import { layoutStyles } from '../styles'

const useStyles = makeStyles(layoutStyles)

const ArtifactViewerWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: ${DOC_PREVIEW_CONFIG.controlsBarHeight}px;
`

interface PartnerEditorLayoutProps {
  previewData: Nullable<PartnerPreviewData>
  children: React.ReactNode
}

export function PartnerEditorLayout({ previewData, children }: PartnerEditorLayoutProps) {
  const classes = useStyles()

  return (
    <Grid container className={classes.layoutRoot}>
      <Grid item className={classes.layoutContentContainer}>
        <Grid container spacing={4} className={classes.editorRoot}>
          <Grid item xs={6} className={classes.layoutColumn}>
            <Paper className={classes.paper}>
              <Typography className={classes.columnTitle} component="div">
                <FormattedMessage
                  id="partner.editor.lastInvoiceTitle"
                  defaultMessage="Partner utoljára befogadott számlája"
                />
              </Typography>
              <div
                className={cx(classes.viewport, {
                  [classes.previewContainer]: Boolean(previewData),
                })}
              >
                {previewData ? (
                  <PortalProvider>
                    <ArtifactViewerWrapper>
                      <ParentSize>
                        {({ height, width }) => (
                          <PartnerLastInvoicePreview data={previewData} height={height} width={width} />
                        )}
                      </ParentSize>
                      <PartnerPreviewAnchorElement invoiceId={previewData.invoiceId} variant={previewData.variant} />
                    </ArtifactViewerWrapper>
                  </PortalProvider>
                ) : (
                  <Grid container className={classes.emptyDemo} justifyContent="center" alignItems="center">
                    <FormattedMessage
                      id="partnerEditor.emptyDemoText"
                      defaultMessage="Nincs megjeleníthető számlakép"
                    />
                  </Grid>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} className={cx(classes.layoutColumn, classes.alignLeft)}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

PartnerEditorLayout.propTypes = {
  previewData: PropTypes.shape({
    invoiceId: PropTypes.number.isRequired,
    previewUrl: PropTypes.string,
    variant: PropTypes.oneOf(['expense', 'income']).isRequired,
  }) as React.Validator<PartnerEditorLayoutProps['previewData']>,
  children: PropTypes.node.isRequired,
}
