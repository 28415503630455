import { call, put, select, takeLatest } from 'redux-saga/effects'

import { downloadFileWithURL, getActiveCompanyId, getErrorMessage, getRFFFormErrors } from '@helpers'

import actions from './actions'
import * as api from './api'

export function* fetchAttachmentsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchAttachments, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* createAttachmentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createAttachment, companyId, payload)
    if (payload.documentType !== 'partner') {
      yield put(actions.increaseAttachment.request(payload))
    }
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* removeAttachmentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removeAttachment, companyId, payload)
    if (payload.documentType !== 'partner') {
      yield put(actions.decreaseAttachment.request(payload))
    }
    yield call(resolve, payload.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* downloadAttachmentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.downloadAttachment, companyId, payload)
    yield call(downloadFileWithURL, response)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//! export download
export function* downloadExportSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.downloadExport, payload)
    if (payload.download) {
      yield call(downloadFileWithURL, response)
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchAttachments.REQUEST, fetchAttachmentsSaga)
  yield takeLatest(actions.createAttachment.REQUEST, createAttachmentSaga)
  yield takeLatest(actions.removeAttachment.REQUEST, removeAttachmentSaga)
  yield takeLatest(actions.downloadAttachment.REQUEST, downloadAttachmentSaga)
  yield takeLatest(actions.downloadExport.REQUEST, downloadExportSaga)
}
