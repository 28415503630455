import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { INVOICE_ACCEPTED_MIME_TYPES } from '@constants'

import uploadSVGSrc from '@assets/img/scan_upload_folder.svg'

import { FileUploadForm } from './FileUploadForm'
import { FileUploadFormProps } from './types'

import { FileUploadSuggestionMessage, FileUploadTextMessage } from './messages'

interface IncomeVoucherUploadFormProps
  extends Omit<
    FileUploadFormProps,
    | 'getFileUploadPayload'
    | 'acceptedFileMimeTypes'
    | 'title'
    | 'fileUploadText'
    | 'uploadDescriptionText'
    | 'uploadSuggestionText'
    | 'uploadImgSrc'
  > {
  company: number
  invoice?: ItemIdType
  onUploadSuccess: (payload: any) => void
  uploading: boolean
}

function PureIncomeVoucherUploadForm({ company, invoice, ...rest }: IncomeVoucherUploadFormProps) {
  return (
    <FileUploadForm
      {...rest}
      getFileUploadPayload={acceptedFiles => ({
        invoice,
        file: acceptedFiles[0],
      })}
      acceptedFileMimeTypes={INVOICE_ACCEPTED_MIME_TYPES}
      title={<FormattedMessage id="fileUpload.income.title" defaultMessage="Bizonylat feltöltése" />}
      fileUploadText={FileUploadTextMessage}
      uploadSuggestionText={FileUploadSuggestionMessage}
      uploadImgSrc={uploadSVGSrc}
    />
  )
}

PureIncomeVoucherUploadForm.propTypes = {
  company: PropTypes.number.isRequired,
  invoice: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  maxSize: PropTypes.number.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
}

export const IncomeVoucherUploadForm = connect((state: Store) => ({
  company: state.auth.company.data.id,
  invoice: state.income.details?.data.id,
  maxSize: state.dashboard.common.artifact_max_size,
  uploading: state.income.details.uploading,
}))(PureIncomeVoucherUploadForm)

IncomeVoucherUploadForm.displayName = 'IncomeVoucherUploadForm'
