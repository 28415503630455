import { ZoomOrientation, ZoomState } from '@components/DocViewer/constant'
import { DocViewerMainState } from '@components/DocViewer/types'

import { initialImageState } from './reducer'

export function getDefaultZoomLevel(
  orientation: ZoomOrientation,
  width: number,
  height: number,
  mainState?: DocViewerMainState
) {
  if (!mainState?.rendererRect) {
    return mainState?.config?.zoom?.defaultZoom ?? initialImageState.defaultZoomLevel
  }

  return orientation === ZoomOrientation.LANDSCAPE
    ? (mainState.rendererRect.width - 20) / width
    : (mainState.rendererRect.height - 20) / height
}

export function getZoomResetState({
  defaultZoomLevel,
  imageOrientation,
  zoomLevel,
  zoomOrientation,
}: {
  defaultZoomLevel: number
  imageOrientation: ZoomOrientation
  zoomLevel: number
  zoomOrientation: ZoomOrientation
}) {
  if (zoomLevel !== defaultZoomLevel) {
    return ZoomState.RESET
  }
  if (imageOrientation === ZoomOrientation.LANDSCAPE) {
    return ZoomState.ORIGINAL_SIZE
  }
  if (zoomOrientation === ZoomOrientation.LANDSCAPE) {
    return ZoomState.CHANGE_TO_PORTRAIT
  }
  return ZoomState.CHANGE_TO_LANDSCAPE
}
