import { CommonAxiosPayload } from '@services'

export enum EditorMode {
  EDIT = 'edit',
  CREATE = 'create',
  DELETE = 'delete',
}

export type EditorState = {
  open: boolean
  initialValues: {
    endDate: string
    startDate: string
  }
  mode: EditorMode
  submitUrl: BackendCallableLink | undefined
}

export type BackendCompanyInactivePeriodsDetails = {
  items: BackendCompanyInactivePeriod[]
  links: {
    create?: BackendCallableLink
  }
}

export interface CompanyInactivePeriodsProps {
  callUrl: AsyncFunction<CommonAxiosPayload<unknown>, unknown>
  data: BackendCompanyInactivePeriodsDetails
  refetch: AsyncFunction
}
