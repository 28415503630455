import React from 'react'

import { useWatch } from 'react-hook-form'

import { InvoiceType } from '@constants'

import { CategoryTypeOption } from '../InvoiceAssignments/types'
import { LedgerNumberField, LedgerNumberFieldProps } from './LedgerNumberField'

interface AssignmentLedgerNumberFieldProps extends Omit<LedgerNumberFieldProps, 'recommendations' | 'name'> {
  assignmentPrefix: `assignments.${number}.`
  invoiceType: InvoiceType
  options: CategoryTypeOption[] // TODO later it can be RevenueType too
}

export function AssignmentLedgerNumberField({
  assignmentPrefix,
  invoiceType,
  options,
  ...rest
}: AssignmentLedgerNumberFieldProps) {
  const categoryTypeId = useWatch({ name: `${assignmentPrefix}${invoiceType}_type` })
  const recommendations = options.find(({ id }) => id === categoryTypeId)?.ledger_numbers ?? []

  return <LedgerNumberField {...rest} name={`${assignmentPrefix}ledger_number`} recommendations={recommendations} />
}
