import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { Typography } from '@components/ui/Typography'

import {
  OverviewItem,
  OverviewItemProps,
  OverviewItemWithCopyProps,
  TaxationOverviewItemsWrapperDiv,
  TaxationOverviewWrapperDiv,
} from './elements'

interface TaxationOverviewProps {
  className?: string
  firstParam: OverviewItemWithCopyProps
  secondParam?: OverviewItemProps
  thirdParam?: OverviewItemProps
}

export function TaxationOverview({ className, firstParam, secondParam, thirdParam }: TaxationOverviewProps) {
  return (
    <TaxationOverviewWrapperDiv className={className}>
      <Typography tag="h2" color="gray-80" size="heading-6">
        <FormattedMessage id="taxation.overview.title" defaultMessage="Áttekintés" />
      </Typography>
      <TaxationOverviewItemsWrapperDiv>
        <OverviewItem {...firstParam} />
        {secondParam && <OverviewItem {...secondParam} />}
        {thirdParam && <OverviewItem {...thirdParam} />}
      </TaxationOverviewItemsWrapperDiv>
    </TaxationOverviewWrapperDiv>
  )
}

TaxationOverview.propTypes = {
  className: PropTypes.string,
  firstParam: PropTypes.shape({
    label: PropTypes.node.isRequired,
    percent: PropTypes.number,
    value: PropTypes.number.isRequired,
  }).isRequired,
  secondParam: PropTypes.shape({
    label: PropTypes.node.isRequired,
    percent: PropTypes.number,
    value: PropTypes.number.isRequired,
  }).isRequired,
  thirdParam: PropTypes.shape({
    label: PropTypes.node.isRequired,
    percent: PropTypes.number,
    value: PropTypes.number.isRequired,
  }).isRequired,
}
