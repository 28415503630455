import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { parseFiltersFromUrlWorkerRunner } from '@webWorkers'

import {
  generateBackgroundProcessActionPayload,
  getActiveCompanyId,
  getCursorFromUrl,
  getErrorMessage,
  getQuarantineListFiltersFromStore,
  getUrlFilterOptionsFromStore,
} from '@helpers'

import { BackgroundProcessActions } from '@constants'

import dashboardActions from '../dashboard/actions'
import { fetchTags as fetchTagsApi } from '../dashboard/api'
import expenseActions from '../expense/actions'
import { fetchExpenseTypes as fetchExpenseTypesApi, uploadExpense as uploadExpenseApi } from '../expense/api'
import filtersActions from '../filters/actions'
import actions from './actions'
import * as api from './api'
import { ExportBackgroundAction } from './backgroundActions'

//* NAV INVOICES
// handle paging on details with v2 data
export function* fetchQuarantineDetailsByPagingV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.fetchQuarantineListByPagingV2, payload.url)
    yield put(
      actions.fetchQuarantineListByPagingV2.success({
        data: response.data.results,
        next: response.data.next,
        previous: response.data.previous,
      })
    )
    // return the id of the next invoice to fetch
    const nextId = payload.isNext
      ? response.data.results[0].id
      : response.data.results[response.data.results.length - 1].id
    yield call(resolve, nextId)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    const errorResponseData = error?.response?.data || {}
    yield call(reject, { data: errorResponseData, msg: errorMsg })
  }
}

//* INVOICE DETAILS
export function* fetchQuarantineInvoiceDetailsSaga({ payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchQuarantineInvoiceDetails, companyId, payload)
    yield put(actions.fetchQuarantineInvoiceDetails.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchQuarantineInvoiceDetails.failure(errorMsg))
  }
}

export function* updateQuarantineInvoiceSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateQuarantineInvoice, companyId, payload)

    yield put(actions.updateQuarantineInvoice.success(response.data))
    yield call(resolve, {
      response: response.data,
    })
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* ARTIFACTS
// upload invoice - pending status
export function* uploadQuarantineInvoiceSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    const response = yield call(api.uploadQuarantineInvoice, company_id, payload)
    yield put(actions.uploadQuarantineInvoice.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.uploadQuarantineInvoice.failure())
    yield call(reject, errorMsg)
  }
}
// upload invoice - accepted status
export function* uploadAcceptedQuarantineInvoiceSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    // no BackgroundProcess because there is no recognition requested
    const response = yield call(uploadExpenseApi, company_id, payload)
    yield put(actions.uploadAcceptedQuarantineInvoice.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.uploadAcceptedQuarantineInvoice.failure())
    yield call(reject, errorMsg)
  }
}

//* missing preview fetch details
export function* fetchExpenseDetailsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchExpenseDetails, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* V2 - used in DuplicateExpenseProvider
export function* mergeInvoicesV2Saga({ payload: { needToUpdateList, ...payload }, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.mergeInvoices, companyId, payload)
    // only need this when merge called from list view
    if (needToUpdateList === 'expense') {
      yield put(actions.triggerExpenseUpdateV2.request())
    }
    if (needToUpdateList === 'quarantine') {
      yield put(actions.triggerQuarantineUpdateV2.request())
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* initQuarantineListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    //* >> sync from url to store
    const companyId = yield select(getActiveCompanyId)
    // gather expense types and tags as we're going to need them for mapping purposes for the list
    const [expenseTypesResponse, tagsResponse] = yield all([
      call(fetchExpenseTypesApi, companyId),
      call(fetchTagsApi, companyId),
    ])

    yield put(expenseActions.fetchExpenseTypes.success(expenseTypesResponse.data))
    yield put(dashboardActions.fetchTags.success(tagsResponse.data))

    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* call worker
    const { filters, params, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initQuarantineListFiltersFromUrl.request({ filters })),
      put(filtersActions.initQuarantineListParamsFromUrl.request(params)),
    ])

    yield call(resolve, validationLevel)
    yield all([put(actions.fetchQuarantineListV2.request({ navigate })), put(actions.fetchQuarantineCharts.request())])
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    yield put(actions.fetchQuarantineListV2.failure(errorMsg))
  }
}

//* V2 API
export function* fetchQuarantineListV2Saga({ payload: { navigate } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getQuarantineListFiltersFromStore, { withCursor: true })
    let listResponse = yield call(api.fetchQuarantineListV2, companyId, filters)

    // drop cursor and refetch list when actual cursor return zero results
    const isCursorDropped = filters.cursor && listResponse.data.results.length === 0
    let previousCursor = null
    if (isCursorDropped) {
      if (listResponse.data.previous) {
        // when previous list is exists
        previousCursor = getCursorFromUrl(listResponse.data.previous)
        listResponse = yield call(api.fetchQuarantineListByPagingV2, listResponse.data.previous)
      } else {
        // clear cursor when no previous list
        const { cursor, ...newFilters } = filters
        listResponse = yield call(api.fetchQuarantineListV2, companyId, newFilters)
      }
    }

    yield put(
      actions.fetchQuarantineListV2.success({
        data: listResponse.data.results,
        isCursorDropped,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
        previousCursor,
      })
    )

    //* << sync from store to url
    const storedFilters = yield select(getQuarantineListFiltersFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchQuarantineListV2.failure(errorMsg))
  }
}

//* When we do not need to sync filters with url, only need to refresh list data
function* fetchQuarantineListV2SimpleSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getQuarantineListFiltersFromStore)
    // start fetching expenses
    const listResponse = yield call(api.fetchQuarantineListV2, companyId, filters)

    yield put(
      actions.fetchQuarantineListV2.success({
        data: listResponse.data.results,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchQuarantineListV2.failure(errorMsg))
  }
}

export function* fetchQuarantineListByPagingV2Saga({ payload: { url } }) {
  try {
    const response = yield call(api.fetchQuarantineListByPagingV2, url)
    yield put(
      actions.fetchQuarantineListByPagingV2.success({
        data: response.data.results,
        next: response.data.next,
        previous: response.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchQuarantineListByPagingV2.failure(errorMsg))
  }
}

export function* fetchQuarantineChartsSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getQuarantineListFiltersFromStore)

    // start fetching expenses
    const listResponse = yield call(api.fetchQuarantineCharts, companyId, filters)
    yield put(actions.fetchQuarantineCharts.success(listResponse.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchQuarantineCharts.failure(errorMsg))
  }
}

export function* bulkApproveQuarantineInvoicesV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getQuarantineListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.QUARANTINE_APPROVE,
      payload,
      filters
    )
    const response = yield call(api.quarantineBackgroundAction, companyId, apiPayload)
    // need to refresh list only when at least 1 approved
    if (response.data?.results.successCount > 0) {
      yield put(
        actions.bulkApproveQuarantineInvoicesV2.success({
          isAllSelected: payload.isAllSelected,
          selected: response.data.results.successIds,
        })
      )
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* bulkRejectQuarantineInvoicesV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getQuarantineListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.QUARANTINE_REJECT,
      payload,
      filters
    )
    yield call(api.quarantineBackgroundAction, companyId, apiPayload)
    yield put(actions.bulkRejectQuarantineInvoicesV2.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* checkQuarantineExpenseExistsSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.checkQuarantineExpenseExists, companyId)
    yield put(actions.checkQuarantineExpenseExists.success(response.data))
  } catch (error) {
    // do nothing with error
  }
}

//* v2
function* startBulkExportV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getQuarantineListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.QUARANTINE_EXPORT,
      payload,
      filters
    )
    const response = yield call(ExportBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkExportV2Saga() {
  yield call(ExportBackgroundAction.stop)
}

// watcher Saga
export default function* commonSaga() {
  // list page controller init
  yield takeLatest(actions.initQuarantineListPageLoad.REQUEST, initQuarantineListPageLoadSaga)
  //* missing preview - detials
  yield takeLatest(actions.fetchExpenseDetails.REQUEST, fetchExpenseDetailsSaga)
  //* invoice details
  yield takeLatest(actions.fetchQuarantineInvoiceDetails.REQUEST, fetchQuarantineInvoiceDetailsSaga)
  yield takeLatest(actions.updateQuarantineInvoice.REQUEST, updateQuarantineInvoiceSaga)
  //* processes
  yield takeLatest(actions.uploadQuarantineInvoice.REQUEST, uploadQuarantineInvoiceSaga)
  yield takeLatest(actions.uploadAcceptedQuarantineInvoice.REQUEST, uploadAcceptedQuarantineInvoiceSaga)
  //* V2 api
  yield takeLatest(actions.fetchQuarantineListV2.REQUEST, fetchQuarantineListV2Saga)
  yield takeLatest(
    [
      actions.updateOrderV2.REQUEST,
      actions.updateRowsPerPageV2.REQUEST,
      filtersActions.toggleQuarantineListDateFilter.REQUEST,
      filtersActions.updateQuarantineListFilters.REQUEST,
      filtersActions.resetQuarantineListFilters.REQUEST,
      actions.triggerQuarantineUpdateV2.REQUEST,
      actions.bulkApproveQuarantineInvoicesV2.SUCCESS,
      actions.bulkRejectQuarantineInvoicesV2.SUCCESS,
      actions.triggerQuarantineListUpdate.REQUEST,
      actions.resetPagination.REQUEST,
    ],
    fetchQuarantineListV2SimpleSaga
  )
  yield takeLatest(actions.fetchQuarantineListByPagingV2.REQUEST, fetchQuarantineListByPagingV2Saga)
  yield takeLatest(actions.fetchQuarantineDetailsByPagingV2.REQUEST, fetchQuarantineDetailsByPagingV2Saga)
  yield takeLatest(
    [
      filtersActions.updateQuarantineListFilters.REQUEST,
      filtersActions.resetQuarantineListFilters.REQUEST,
      filtersActions.toggleQuarantineListDateFilter.REQUEST,
      actions.fetchQuarantineCharts.REQUEST,
      actions.triggerQuarantineUpdateV2.REQUEST,
      actions.bulkApproveQuarantineInvoicesV2.SUCCESS,
      actions.bulkRejectQuarantineInvoicesV2.SUCCESS,
      actions.triggerQuarantineListUpdate.REQUEST,
    ],
    fetchQuarantineChartsSaga
  )
  yield takeLatest(actions.mergeInvoicesV2.REQUEST, mergeInvoicesV2Saga)
  yield takeLatest(actions.bulkApproveQuarantineInvoicesV2.REQUEST, bulkApproveQuarantineInvoicesV2Saga)
  yield takeLatest(actions.bulkRejectQuarantineInvoicesV2.REQUEST, bulkRejectQuarantineInvoicesV2Saga)
  yield takeLatest(
    [
      actions.checkQuarantineExpenseExists.REQUEST,
      actions.triggerQuarantineUpdateV2.REQUEST,
      actions.bulkApproveQuarantineInvoicesV2.SUCCESS,
      actions.bulkRejectQuarantineInvoicesV2.SUCCESS,
    ],
    checkQuarantineExpenseExistsSaga
  )
  // export v2
  yield takeLatest(actions.startBulkExportV2.REQUEST, startBulkExportV2Saga)
  yield takeLatest(actions.stopBulkExportV2.REQUEST, stopBulkExportV2Saga)
}
