import React from 'react'
import PropTypes from 'prop-types'

import { Button, DialogContentText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'

import { makeValidate } from '@helpers'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@oldComponents/dialogs'
import { RenderTextField } from '@oldComponents/ui/form'

import { formStyles } from '@styles'
import { CancelButtonMessage, ConfirmDialogTitleMessage, formErrorMessages, quarantineFormMessages } from '@messages'

const useStyles = makeStyles(formStyles)

interface QuarantineInvoiceRemoveConfirmDialogProps {
  onRemove: VoidFunction
  onClose: VoidFunction
  open: boolean
}

function QuarantineInvoiceRemoveConfirmDialog({ onRemove, onClose, open }: QuarantineInvoiceRemoveConfirmDialogProps) {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const validateRef = React.useRef(
    makeValidate(
      yup.object({
        confirm: yup
          .string()
          .required(formatMessage(formErrorMessages.required))
          .test('isItOk', formatMessage(quarantineFormMessages.notOK), value => !value || value.toLowerCase() === 'ok'),
      })
    )
  )

  function submitHandler() {
    onRemove()
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="quarantine-remove-confirm-dialog-title">
      <DialogTitle id="quarantine-remove-confirm-dialog-title">{ConfirmDialogTitleMessage}</DialogTitle>
      <Form onSubmit={submitHandler} validate={validateRef.current}>
        {({ handleSubmit, pristine, submitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off" className={classes.root}>
            <DialogContent>
              <DialogContentText>
                <FormattedMessage
                  id="dialogs.quarantineRemove.confirmText.removeFromDetails"
                  defaultMessage="Biztosan elutasítod a számlát? Ebben az esetben törölni fogjuk a QUiCK rendszeréből, a döntés visszavonására nincs mód."
                />
              </DialogContentText>
              <Field
                component={RenderTextField}
                name="confirm"
                label={formatMessage(quarantineFormMessages.confirmLabel)}
                disabled={submitting}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" disabled={pristine}>
                <FormattedMessage
                  id="dialogs.quarantineRemove.buttons.singleConfirm"
                  defaultMessage="Számla elutasítása"
                />
              </Button>
              <Button onClick={onClose} color="primary" variant="contained">
                {CancelButtonMessage}
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

QuarantineInvoiceRemoveConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default QuarantineInvoiceRemoveConfirmDialog
