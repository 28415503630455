import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { attachmentActions, partnersActions } from '@services'

import { bindActionToPromise, checkEmptyValues } from '@helpers'

import { COMPANY_USER_DASHBOARD_PERMISSION_MAPPER as TAB_PERMISSIONS } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { createInitialValues, replaceEmptyValueWithNull } from './helpers'
import { PartnerCreateDialog, PartnerEditDialog } from './PartnerDialogs'
import { InitialValues, PartnerDialogData } from './types'

export enum EditorMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export type PartnerEditorState =
  | {
      method: EditorMode.CREATE
      open: boolean
      partnerId: null
    }
  | {
      method: EditorMode.EDIT
      open: boolean
      partnerId: number
    }

type PartnerEditorData = { company_id: number } & NullableEmptyStringObject<InitialValues>

interface PartnerEditorDialogProps {
  callCreatePartner: AsyncFunction<PartnerEditorData, any>
  callUpdatePartner: AsyncFunction<PartnerEditorData, any>
  companyId: number
  editor: PartnerEditorState
  isExpenseVisibleForUser: boolean
  isIncomeVisibleForUser: boolean
  loadPartnerDetails: AsyncFunction<number, PartnerDialogData>
  onClose: VoidFunction
  updatePartnerAttachment: (data: any) => void
  variant: 'provider' | 'customer'
}

function PurePartnerEditorDialog({
  callCreatePartner,
  callUpdatePartner,
  companyId,
  editor: { open, partnerId, method },
  isExpenseVisibleForUser,
  isIncomeVisibleForUser,
  loadPartnerDetails,
  onClose,
  updatePartnerAttachment,
  variant,
}: PartnerEditorDialogProps) {
  function handleCreateSubmit(values: InitialValues) {
    const promise = callCreatePartner({
      ...replaceEmptyValueWithNull(values),
      company_id: companyId,
    } as PartnerEditorData)
    // return empty result to Form as success and error object as failure
    return promise
      .then(() => {
        onClose() // call submission success handler
        return undefined
      })
      .catch(err => err) // err is parsed via getRFFFormErrors in saga
  }

  function handleEditSubmit(values: InitialValues) {
    // need to check for removed values
    const initialValues = createInitialValues(null, variant)
    const submitData = checkEmptyValues(values, initialValues)
    const promise = callUpdatePartner({
      ...replaceEmptyValueWithNull(submitData),
      company_id: companyId,
    } as PartnerEditorData)
    // return empty result to Form as success and error object as failure
    return promise
      .then(() => {
        onClose() // call submission success handler
        return undefined
      })
      .catch(err => err) // err is parsed via getRFFFormErrors in saga
  }

  if (method === 'edit' && partnerId) {
    return (
      <PartnerEditDialog
        open={open}
        onClose={onClose}
        partnerId={partnerId}
        variant={variant}
        onSubmit={handleEditSubmit}
        loadData={loadPartnerDetails}
        updatePartnerAttachment={updatePartnerAttachment}
        isExpenseVisibleForUser={isExpenseVisibleForUser}
        isIncomeVisibleForUser={isIncomeVisibleForUser}
      />
    )
  }

  return (
    <PartnerCreateDialog
      open={open}
      onClose={onClose}
      onSubmit={handleCreateSubmit}
      initialValues={createInitialValues(null, variant)}
    />
  )
}

PurePartnerEditorDialog.propTypes = {
  callCreatePartner: PropTypes.func.isRequired,
  callUpdatePartner: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  editor: PropTypes.shape({
    method: PropTypes.oneOf(Object.values(EditorMode)).isRequired,
    open: PropTypes.bool.isRequired,
    partnerId: PropTypes.number,
  }).isRequired as React.Validator<PartnerEditorState>,
  isExpenseVisibleForUser: PropTypes.bool.isRequired,
  isIncomeVisibleForUser: PropTypes.bool.isRequired,
  loadPartnerDetails: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updatePartnerAttachment: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['provider', 'customer']).isRequired as React.Validator<'provider' | 'customer'>,
}

export const PartnerEditorDialog = connect(
  (state: Store) => ({
    companyId: state.auth.company.data.id,
    isExpenseVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.expense),
    isIncomeVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.income),
  }),
  dispatch => ({
    callCreatePartner: bindActionToPromise(dispatch, partnersActions.createPartner.request),
    callUpdatePartner: bindActionToPromise(dispatch, partnersActions.updatePartner.request),
    loadPartnerDetails: bindActionToPromise(dispatch, partnersActions.fetchPartnerDetails.request),
    updatePartnerAttachment: bindActionCreators(attachmentActions.updatePartnerAttachment.request, dispatch),
  })
)(PurePartnerEditorDialog)

PartnerEditorDialog.displayName = 'PartnerEditorDialog'
