import styled from 'styled-components'

import { CustomDialogActions, CustomDialogBody, CustomDialogHeader, Typography } from '@components/ui'

export const ListActionsDialogHeader = styled(CustomDialogHeader)`
  padding-top: 40px;
  ${Typography} {
    font-size: 28px;
  }
`

export const ListActionsDialogBody = styled(CustomDialogBody)`
  padding-left: 40px;
  padding-right: 40px;
  max-width: 600px;
  margin: 0 auto;
`

export const ListActionsDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;

  & > button {
    width: 244px;
  }
`
