import React from 'react'
import PropTypes from 'prop-types'

import { InputProps, TextField, TextFieldProps } from '@material-ui/core'
import cx from 'classnames'
import { FieldRenderProps } from 'react-final-form'

import { isFieldHighlighted } from '@helpers'

import useStyles from './styles'

type RenderTextFieldProps = FieldRenderProps<Nullable<string>, HTMLTextAreaElement | HTMLInputElement> &
  TextFieldProps & {
    highlighted?: boolean
    forcedError?: boolean // this is useful for triggering an error state for the field without the actual form field having an error (visibility purposes)
  }

export function RenderTextField({
  className,
  disabled = false,
  forcedError = false,
  highlighted = false,
  input: { name, value, type, onChange, onBlur, onFocus, ...restInput },
  InputProps,
  label,
  labelClassName,
  meta: { dirtySinceLastSubmit, error, submitError, touched, modifiedSinceLastSubmit },
  required = false,
  ...rest
}: RenderTextFieldProps) {
  const classes = useStyles()
  const displayedError = touched && (error || (!dirtySinceLastSubmit && !modifiedSinceLastSubmit && submitError))
  const hasError = Boolean(displayedError || forcedError)

  return (
    <TextField
      id={name}
      className={cx(className, 'form-control', { 'form-control-error': hasError })}
      error={hasError}
      label={type === 'hidden' ? null : label}
      helperText={displayedError}
      margin="normal"
      fullWidth
      InputProps={
        {
          disableUnderline: true,
          classes: {
            root: InputProps?.multiline ? classes.bootstrapTextareaRoot : classes.bootstrapRoot,
            input: cx(classes.bootstrapInput, { highlighted: isFieldHighlighted(highlighted, value) }),
            error: classes.bootstrapError,
          },
          disabled,
          ...InputProps,
        } as InputProps
      }
      InputLabelProps={{
        shrink: true,
        className: cx(classes.bootstrapFormLabel, labelClassName),
      }}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      value={value}
      type={type}
      required={required}
      inputProps={{ required, ...restInput }}
      {...rest}
    />
  )
}

RenderTextField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  InputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  meta: PropTypes.shape({
    dirtySinceLastSubmit: PropTypes.bool as React.Validator<boolean | undefined>,
    error: PropTypes.node,
    modifiedSinceLastSubmit: PropTypes.bool as React.Validator<boolean | undefined>,
    submitError: PropTypes.node,
    touched: PropTypes.bool.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  highlighted: PropTypes.bool,
}
