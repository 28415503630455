import React from 'react'

import cx from 'classnames'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { PublicNavbar } from '@components/PublicNavbar'

import { APP_TITLE, generateMaxHeightBreakPoint, generateMaxWidthBreakPoint } from '@constants'

import blue_theme_auth_bg_mobile from '@assets/img/blue_theme_auth_bg_mobile.svg'
import purple_theme_auth_bg_mobile from '@assets/img/purple_theme_auth_bg_mobile.svg'

const CLASS_KEYS = {
  designPage: 'design-page',
}

const PageWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[102]};
  color: ${({ theme }) => theme.colors.common.offBlack};
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  padding-bottom: 20px;
  padding-top: ${({ theme }) => theme.appBarHeight + 20}px;
  width: 100%;

  /* TODO: Check this style */
  && > header {
    border: none;
  }

  ${generateMaxHeightBreakPoint('medium')} {
    padding-top: ${({ theme }) => theme.appBarHeightMedium + 20}px;
  }

  &.${CLASS_KEYS.designPage} {
    min-width: 340px;
    background-color: ${({ theme }) => theme.colors.common.darkBlue};
    position: relative;

    ${generateMaxWidthBreakPoint('mobile')} {
      background-position: center ${({ theme }) => theme.appBarHeight}px;
      background-repeat: no-repeat;
      background-attachment: fixed;

      &.blueTheme {
        background-image: url(${blue_theme_auth_bg_mobile});
      }

      &.purpleTheme {
        background-image: url(${purple_theme_auth_bg_mobile});
      }
    }
  }
`

interface BasePublicPageProps {
  children: React.ReactNode | React.ReactNode[]
  lang: 'hu' | 'en'
  subtitle?: string
  theme?: 'default' | 'blue' | 'purple'
  withExtraDesign?: boolean
}

function BasePublicPage({ children, subtitle, lang, withExtraDesign = false, theme = 'default' }: BasePublicPageProps) {
  return (
    <PageWrapper
      className={cx(
        {
          [CLASS_KEYS.designPage]: withExtraDesign,
        },
        `${theme}Theme`
      )}
    >
      {subtitle && <Helmet title={`${subtitle} | ${APP_TITLE}`} />}
      <Helmet htmlAttributes={{ lang }} />
      <PublicNavbar withExtraDesign={withExtraDesign} />
      {children}
    </PageWrapper>
  )
}

export const PublicPage = connect((state: Store) => ({
  lang: state.locale.language,
}))(BasePublicPage)

PublicPage.displayName = 'PublicPage'
