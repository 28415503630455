export enum ZoomOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum ZoomState {
  RESET = 'reset',
  CHANGE_TO_PORTRAIT = 'to_portrait',
  CHANGE_TO_LANDSCAPE = 'to_landscape',
  ORIGINAL_SIZE = 'original_size',
}

export const POPPER_PROPS = { disablePortal: true, placement: 'top' } as const
