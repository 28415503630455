import React from 'react'

import styled from 'styled-components'

function BaseAstromechSolidIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(18 / 16) * size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 18"
    >
      <path
        fill="currentColor"
        d="M13.032 3.379A5.618 5.618 0 0 0 7.875 0a5.618 5.618 0 0 0-5.157 3.379c-.577.049-1.03.53-1.03 1.121v2.25c0 .59.453 1.072 1.03 1.121a5.618 5.618 0 0 0 5.157 3.379 5.618 5.618 0 0 0 5.157-3.379c.577-.049 1.03-.53 1.03-1.121V4.5c0-.59-.453-1.072-1.03-1.121Zm-1.22 1.683v.563A3.376 3.376 0 0 1 8.438 9H7.312a3.376 3.376 0 0 1-3.375-3.375v-.563c0-.931.756-1.687 1.688-1.687h4.5c.932 0 1.688.756 1.688 1.688Zm-5.157.658-.21-.745a.264.264 0 0 0-.258-.194.264.264 0 0 0-.256.194l-.211.745-.745.21a.264.264 0 0 0-.194.258c0 .119.078.224.194.256l.745.211.21.745a.264.264 0 0 0 .258.194c.119 0 .224-.078.256-.194l.211-.745.745-.21a.264.264 0 0 0 .194-.258.264.264 0 0 0-.194-.256l-.745-.211Zm-2.693 5.407A6.268 6.268 0 0 0 0 16.956C0 17.532.468 18 1.044 18H4.5v-2.25c0-.622.503-1.125 1.125-1.125h4.5c.622 0 1.125.503 1.125 1.125V18h3.456c.576 0 1.044-.468 1.044-1.044a6.268 6.268 0 0 0-3.962-5.829 6.737 6.737 0 0 1-3.913 1.248 6.737 6.737 0 0 1-3.913-1.248Zm2.225 4.623a.564.564 0 0 0-.562.563V18H6.75v-1.688a.564.564 0 0 0-.563-.562Zm3.375 1.125a.563.563 0 1 0 0-1.125.563.563 0 0 0 0 1.125Z"
      />
    </svg>
  )
}

export const AstromechSolidIcon = styled(BaseAstromechSolidIcon)``
