import { useIntl } from 'react-intl'

import messages from '../../messages'

export function usePlaceholderMessage(state: FilterSelectionState, disabled: boolean) {
  const { formatMessage } = useIntl()

  if (disabled) {
    return formatMessage(messages.searchFieldAllPlaceholder)
  }

  switch (state) {
    case 'all':
      return formatMessage(messages.searchFieldAllPlaceholder)
    case 'defaults':
    case 'none':
      return formatMessage(messages.searchFieldDefaultPlaceholder)
    case 'some':
    default:
      return formatMessage(messages.searchFieldUnique)
  }
}
