import styled from 'styled-components'

export const WrapperDiv = styled.div<{ $gap: number }>`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap}px;
`

export const InnerWrapperDiv = styled(WrapperDiv)`
  flex: 0;
`
