import React from 'react'

import { useFormState } from 'react-final-form'

import { Typography } from '@components/ui'

export default function FormError({ className }: { className?: string }) {
  const { error, modifiedSinceLastSubmit, submitError, submitFailed } = useFormState({
    subscription: {
      error: true,
      modifiedSinceLastSubmit: true,
      submitError: true,
      submitFailed: true,
    },
  })

  if (error || (!modifiedSinceLastSubmit && submitFailed && submitError)) {
    return (
      <Typography className={className} color="error" size="400-sm">
        {submitError || error}
      </Typography>
    )
  }

  return null
}
