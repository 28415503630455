import React from 'react'

import styled from 'styled-components'

function BaseCircleExportRegularIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M19.0431 25.7441c.0739.2661.35.4231.612.336.482-.1603.951-.357 1.4031-.5885.2458-.1259.3273-.4328.1893-.672l-.4498-.7796c-.138-.2392-.4431-.3197-.6904-.1968a8.1034 8.1034 0 0 1-.9616.4034c-.261.0902-.4174.3643-.3435.6304l.2409.8671ZM23.3327 23.1912c.1986.1919.5162.1872.6979-.0207.3211-.3675.6149-.7581.879-1.1685.1494-.2322.0658-.5386-.1736-.6762l-.7802-.4486c-.2394-.1376-.5438-.0542-.6959.1763a8.0994 8.0994 0 0 1-.598.7949c-.1792.2101-.1751.5257.0235.7175l.6473.6253ZM25.7026 18.9502c.2675.0686.5412-.0924.5964-.363.0992-.487.162-.9807.1878-1.477.0143-.2758-.2104-.5002-.4865-.5007l-.9-.0018c-.2762-.0005-.4989.2231-.5165.4987a8.086 8.086 0 0 1-.1282 1.0083c-.0519.2712.1077.5434.3752.612l.8718.2235ZM25.7191 14.297c.268-.0667.4324-.3384.3524-.6027a9.9885 9.9885 0 0 0-.5361-1.389c-.1184-.2495-.4227-.3403-.666-.2096l-.7929.4258c-.2432.1307-.333.4333-.2176.6841a8.096 8.096 0 0 1 .366.9483c.0831.2633.3529.4271.6209.3604l.8733-.2173ZM23.4647 10.1297c.2024-.1878.2152-.5051.0175-.698-.3558-.3469-.737-.6669-1.1403-.9572-.2242-.1613-.5345-.0938-.6844.1381l-.4886.7558c-.1499.232-.0824.5402.1398.7042.2729.2013.5329.4196.7785.6535.1999.1905.5153.2036.7177.0158l.6598-.6122ZM9.429 23.6619a10.0001 10.0001 0 0 0 6.5712 2.9164c.2758.0138.4998-.2113.4998-.4875v-.9c0-.2761-.2241-.4984-.4997-.5154a8.1001 8.1001 0 0 1-5.2279-13.8122 8.1003 8.1003 0 0 1 7.561-2.1622c.269.0625.5473-.0864.6262-.351l.2574-.8624c.079-.2646-.0713-.5444-.3395-.61A10.0002 10.0002 0 0 0 6.5 16.5907a10 10 0 0 0 2.929 7.0711Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m21.7021 17.4947-4.601 4.399a.8501.8501 0 0 1-1.2021 0l-4.399-4.399a.8501.8501 0 0 1 1.2021-1.2021l2.9479 2.948v-7.948a.85.85 0 0 1 1.7 0v7.948l3.15-2.948c.3319-.3319.8598-.3215 1.1917.0105.332.3319.3423.8597.0104 1.1916Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const CircleExportRegularIcon = styled(BaseCircleExportRegularIcon)``
