import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { apiKeysActions, authActions, onboardingActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { useAssertContext } from '@hooks/useAssertContext'

import { IntercomLauncherButton } from '@components/Intercom'
import { AsyncButton, Button } from '@components/ui'
import { LinkButton } from '@components/ui/Buttons'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@oldComponents/dialogs'

import { LINKS, ROUTES } from '@constants'

import goodbyeImageSrc from '@assets/img/goodbye.png'

import { callSubmitFunction, getMessages } from './helpers'
import {
  SettingsDialogContextProps,
  SettingsDialogProviderProps,
  SettingsDialogStateProps,
  SettingsDialogStates,
} from './types'

import { CloseButtonMessage, CustomerServiceButtonOrLinkMessage, GoToSzamlazzHuButtonMessage } from '@messages'

const DialogContext = React.createContext<Nullable<SettingsDialogContextProps>>(null)

DialogContext.displayName = 'DialogContext'

function PureSettingsProvider(props: SettingsDialogProviderProps) {
  const { children, callLogout, callRemoveCompanySuccess, companies, currentCompany, currentUser } = props
  const [{ open, company, data, loading, error, success, dialogState, translations }, setState] =
    React.useState<SettingsDialogStateProps>({
      open: false,
      company: null,
      loading: false,
      data: null,
      error: null,
      success: false,
      dialogState: null, // hold dialog type (one of [member, membership, company])
      translations: null, // hold dialog type specific messages
    })
  const navigate = useNavigate()
  const location = useLocation()
  const { formatMessage } = useIntl()

  // log the user out with a delay if they don't close the modal
  React.useEffect(() => {
    let timeout: number | undefined
    if (dialogState === SettingsDialogStates.DONE) {
      timeout = window.setTimeout(callLogout, 3000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [callLogout, dialogState])

  // reset error state after a delay
  React.useEffect(() => {
    let timeout: number | undefined
    if (error) {
      timeout = window.setTimeout(() => setState(state => ({ ...state, error: null })), 3000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [error])

  const openWithData = React.useCallback<SettingsDialogContextProps['openWithData']>(
    (company, data, dialogState) => () => {
      const translations = getMessages(dialogState)
      setState({
        open: true,
        loading: false,
        error: null,
        success: false,
        company,
        data,
        dialogState,
        translations,
      })
    },
    []
  )

  const openCompanyDelete = React.useCallback(() => {
    const deleteCompanyDialogState = currentCompany.links.delete
      ? SettingsDialogStates.COMPANY
      : SettingsDialogStates.COMPANY_NOT_DELETABLE
    setState({
      open: true,
      loading: false,
      error: null,
      success: false,
      company: currentCompany,
      data: currentUser,
      dialogState: deleteCompanyDialogState,
      translations: getMessages(deleteCompanyDialogState),
    })
  }, [currentCompany, currentUser])

  const openAccountDelete = React.useCallback(() => {
    const deleteAccountDialogState = currentUser.links.delete
      ? SettingsDialogStates.ACCOUNT
      : SettingsDialogStates.ACCOUNT_NOT_DELETABLE
    setState({
      open: true,
      loading: false,
      error: null,
      success: false,
      company: currentCompany,
      data: currentUser,
      dialogState: deleteAccountDialogState,
      translations: getMessages(deleteAccountDialogState),
    })
  }, [currentCompany, currentUser])

  const onRemoveCompanySuccessHandler = React.useCallback(() => {
    if (!company) {
      throw Error('Missing company')
    }
    // check if user has more company
    const availableCompanies = companies.filter(c => c.id !== company.id)
    // has other company
    const url = availableCompanies.length ? ROUTES.selectCompany : ROUTES.noCompany

    // success action call to sync store
    callRemoveCompanySuccess(company.id)
    // redirect
    window.setTimeout(() => navigate(url))
  }, [callRemoveCompanySuccess, companies, company, navigate])

  const onRemoveCompanyMembershipSuccessHandler = React.useCallback(() => {
    if (!company) {
      throw Error('Missing company')
    }
    // check if user is company settings page
    const match = matchPath(ROUTES.company, location.pathname)
    // USER IS ON COMPANY SETTINGS PAGE
    if (match) {
      // same flow when delete current company
      // check if user has more company
      const availableCompanies = companies.filter(c => c.id !== company?.id)
      // has other company
      const url = availableCompanies.length ? ROUTES.selectCompany : ROUTES.noCompany

      // success action call to sync store
      callRemoveCompanySuccess(company.id)
      // redirect
      window.setTimeout(() => navigate(url))
    } else {
      // USER IS ON PROFILE PAGE
      // close dialog
      setState(state => ({ ...state, open: false }))
      // success action call to sync store
      callRemoveCompanySuccess(company.id)
    }
  }, [callRemoveCompanySuccess, companies, company, navigate, location.pathname])

  const handleClose = React.useCallback(() => {
    if (dialogState === SettingsDialogStates.DONE) {
      callLogout()
    } else if (dialogState === SettingsDialogStates.COMPANY && success) {
      onRemoveCompanySuccessHandler()
    } else if (dialogState === SettingsDialogStates.MEMBERSHIP && success) {
      onRemoveCompanyMembershipSuccessHandler()
    } else {
      setState(state => ({ ...state, open: false }))
    }
  }, [callLogout, onRemoveCompanyMembershipSuccessHandler, onRemoveCompanySuccessHandler, success, dialogState])

  const handleDialogClose = React.useCallback(
    (event, reason) => {
      if (loading) {
        if (reason === 'backdropClick') {
          return
        }
      }
      handleClose()
    },
    [handleClose, loading]
  )

  async function onSubmitHandler() {
    setState(state => ({ ...state, loading: true }))
    try {
      await callSubmitFunction(dialogState, props, { company, data })
      if (dialogState === SettingsDialogStates.ACCOUNT) {
        setState(state => ({
          ...state,
          loading: false,
          success: true,
          dialogState: SettingsDialogStates.DONE,
        }))
      } else {
        setState(state => ({
          ...state,
          loading: false,
          success: true,
        }))
      }
    } catch (error) {
      console.error('SettingsProvider submit error:', { error })
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: error as string,
      }))
    }
  }

  function renderContentText() {
    if (!data || !translations) {
      return null
    }

    if (dialogState === SettingsDialogStates.DONE) {
      return (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <img role="presentation" src={goodbyeImageSrc} alt="" />
          </Grid>
          <Typography variant="h3" align="center" style={{ margin: '1rem 0' }}>
            <FormattedMessage id="dialogs.goodbye.title" defaultMessage="Viszontlátásra!" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="dialogs.goodbye.successText"
              defaultMessage="A felhasználói fiók törlése hamarosan megtörténik."
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="dialogs.goodbye.descriptionText"
              defaultMessage="Minden adat véglegesen törlésre kerül."
            />
          </Typography>
        </>
      )
    }

    if (success && translations.successText) {
      return (
        <div style={{ marginBottom: 30 }}>
          {formatMessage(translations.successText, {
            email: <b>{data.email}</b>,
            company: <b>{company?.name}</b>,
            company_vat: <b>{data.name || `${data.percent}%`}</b>,
          })}
        </div>
      )
    }

    return formatMessage(translations.contentText, {
      email: <b>{data.email}</b>,
      company: <b>{company?.name}</b>,
      company_vat: <b>{data.name || `${data.percent}%`}</b>,
    })
  }

  function renderText(text: 'titleText' | 'submitButtonText') {
    const messageDescriptor = translations?.[text]
    if (!success && messageDescriptor) {
      return formatMessage(messageDescriptor)
    }
    return null
  }

  const contextValue = React.useMemo(
    () => ({
      openWithData,
      openCompanyDelete,
      openAccountDelete,
    }),
    [openAccountDelete, openCompanyDelete, openWithData]
  )

  const submitButtonText = renderText('submitButtonText')

  return (
    <DialogContext.Provider value={contextValue}>
      <>
        {children}

        <Dialog open={open} onClose={handleDialogClose} aria-labelledby="confirm-dialog-title">
          <DialogTitle id="confirm-dialog-title">{renderText('titleText')}</DialogTitle>
          <DialogContent>
            {renderContentText()}
            {error && <Typography color="error">{error}</Typography>}
          </DialogContent>
          <DialogActions>
            {success ? (
              <Button data-testid="close" variant="primaryContained" onClick={handleClose}>
                {dialogState === SettingsDialogStates.DONE ? (
                  <FormattedMessage id="dialogs.buttons.goodbye.close" defaultMessage="Ok" />
                ) : (
                  CloseButtonMessage
                )}
              </Button>
            ) : dialogState === SettingsDialogStates.ACCOUNT_NOT_DELETABLE ? (
              <LinkButton variant="primaryText" href={LINKS.szamlazzMainPage} target="_self">
                {GoToSzamlazzHuButtonMessage}
              </LinkButton>
            ) : dialogState === SettingsDialogStates.COMPANY_NOT_DELETABLE ? (
              <IntercomLauncherButton>{CustomerServiceButtonOrLinkMessage}</IntercomLauncherButton>
            ) : (
              <>
                {submitButtonText && (
                  <AsyncButton
                    data-testid="submit"
                    variant="secondaryContained"
                    loading={loading}
                    onClick={onSubmitHandler}
                  >
                    {submitButtonText}
                  </AsyncButton>
                )}
                <Button variant="primaryContained" onClick={handleClose} disabled={loading}>
                  <FormattedMessage id="dialogs.buttons.settings.cancel" defaultMessage="Mégsem" />
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    </DialogContext.Provider>
  )
}

PureSettingsProvider.propTypes = {
  callDeclineCompanyInvite: PropTypes.func.isRequired,
  callLogout: PropTypes.func.isRequired,
  callRemoveAccount: PropTypes.func.isRequired,
  callRemoveApiKey: PropTypes.func.isRequired,
  callRemoveCompany: PropTypes.func.isRequired,
  callRemoveCompanySuccess: PropTypes.func.isRequired,
  callRemoveCompanyMember: PropTypes.func.isRequired,
  callRemoveCompanyMembership: PropTypes.func.isRequired,
  callRemoveCompanyVat: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      role: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired as React.Validator<SettingsDialogProviderProps['companies']>,
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
  }) as React.Validator<SettingsDialogProviderProps['currentCompany']>,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    links: PropTypes.shape({
      delete: PropTypes.string,
    }).isRequired,
  }).isRequired as React.Validator<SettingsDialogProviderProps['currentUser']>,
}

export const SettingsDialogProvider = connect(
  ({
    auth: {
      companies,
      user,
      company: { data: currentCompany },
    },
  }: Store) => ({
    companies,
    currentUser: user,
    currentCompany,
  }),
  dispatch => ({
    callDeclineCompanyInvite: bindActionToPromise(dispatch, onboardingActions.declineOnboardingInvite.request),
    callLogout: bindActionCreators(authActions.logout.success, dispatch),
    callRemoveAccount: bindActionToPromise(dispatch, authActions.removeAccount.request), // use link
    callRemoveCompany: bindActionToPromise(dispatch, authActions.removeCompany.request), // use link
    callRemoveCompanySuccess: bindActionCreators(authActions.removeCompany.success, dispatch),
    callRemoveCompanyMember: bindActionToPromise(dispatch, authActions.removeCompanyMember.request),
    callRemoveCompanyMembership: bindActionToPromise(dispatch, authActions.removeCompanyMembership.request),
    callRemoveCompanyVat: bindActionToPromise(dispatch, authActions.removeCompanyVat.request),
    callRemoveApiKey: bindActionToPromise(dispatch, apiKeysActions.removeApiKey.request),
  })
)(PureSettingsProvider)

SettingsDialogProvider.displayName = 'SettingsDialogProvider'

export function useSettingsDialog() {
  return useAssertContext(DialogContext)
}
