import React from 'react'

import { connect } from 'react-redux'

import { CommonAxiosPayload, paymentActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useAssertContext, useCancellablePromiseRef } from '@hooks'

import { PaymentTransactionDeleteConfirmDialog } from './PaymentTransactionDeleteConfirmDialog'

const PaymentTransactionsContext = React.createContext<
  | {
      requestPaymentTransactionDelete: (data: PaymentTransactionsData) => void
    }
  | undefined
>(undefined)

type PaymentTransactionDeleteDialogState = {
  data: Nullable<PaymentTransactionsData>
  loading: boolean
  open: boolean
}

interface PaymentTransactionsProviderProps {
  callDeletePaymentTransactionUrl: AsyncFunction<CommonAxiosPayload<never>>
  children: React.ReactNode
}

function PurePaymentTransactionsProvider({
  callDeletePaymentTransactionUrl,
  children,
}: PaymentTransactionsProviderProps) {
  const [dialogState, setDialogState] = React.useState<PaymentTransactionDeleteDialogState>({
    data: null,
    loading: false,
    open: false,
  })
  const { setErrorAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef()

  function handleClose() {
    setDialogState(prevState => ({ ...prevState, open: false }))
  }

  const contextValue = React.useMemo(() => {
    function requestPaymentTransactionDelete(data: PaymentTransactionsData) {
      setDialogState({ data, loading: false, open: true })
    }

    return {
      requestPaymentTransactionDelete,
    }
  }, [])

  const handleDelete = React.useCallback(async () => {
    const methodUrl = dialogState.data?.links.delete
    if (methodUrl) {
      setDialogState(prevState => ({ ...prevState, loading: true }))
      try {
        cPromiseRef.current = cancellablePromise(
          callDeletePaymentTransactionUrl({
            method: 'delete',
            url: methodUrl,
          })
        )
        await cPromiseRef.current.promise
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
      }
      setDialogState(prevState => ({ ...prevState, loading: false, open: false }))
    }
  }, [cPromiseRef, callDeletePaymentTransactionUrl, dialogState.data?.links.delete, setErrorAlert])

  return (
    <PaymentTransactionsContext.Provider value={contextValue}>
      {children}
      <PaymentTransactionDeleteConfirmDialog {...dialogState} onDelete={handleDelete} onClose={handleClose} />
    </PaymentTransactionsContext.Provider>
  )
}

export const PaymentTransactionsProvider = connect(null, dispatch => ({
  callDeletePaymentTransactionUrl: bindActionToPromise(dispatch, paymentActions.removePaymentTransaction.request),
}))(PurePaymentTransactionsProvider)

PaymentTransactionsProvider.displayName = 'PaymentTransactionsProvider'

export function usePaymentTransactionsDispatch() {
  return useAssertContext(PaymentTransactionsContext)
}
