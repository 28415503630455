import React from 'react'

import styled from 'styled-components'

const StyledSpan = styled.span`
  background-color: ${({ theme }) => theme.colors.error[40]};
  color: ${({ theme }) => theme.colors.gray[0]};
  position: absolute;
  min-width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  padding: 3px;
  border-radius: 11px;
  top: 4px;
  right: 3px;
  border: 2px solid ${({ theme }) => theme.colors.gray[0]};
`

export function IntercomUnreadCountBadge({ count }: { count: number }) {
  if (count > 0) {
    const displayedCount = count > 9 ? '9+' : count
    return <StyledSpan>{displayedCount}</StyledSpan>
  }
  return null
}
