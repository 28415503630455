import styled from 'styled-components'

import { getFontStyles } from '@helpers/styled-helpers'

export const PrimaryLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary[50]};
  ${({ theme }) => getFontStyles(theme, '400-sm')}
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const InheritLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: 700;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const PrimaryInheritLink = styled(InheritLink)`
  color: ${({ theme }) => theme.colors.primary[50]};
`
// TODO later it can be more link
