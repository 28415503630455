import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { textFileLoader } from '@components/DocViewer/helpers'

import { DocRenderer, DocViewerMainState } from '@components/DocViewer/types'

import { DIALOG_PADDING } from '@constants'

const TXTRendererContainer = styled.pre`
  padding: ${DIALOG_PADDING}px;
  padding-left: 0;
  margin: 0;
  white-space: pre-wrap;
  font-style: inherit;
  font-family: inherit;
  color: ${({ theme }) => theme.colors.gray[80]};
  font-size: ${({ theme }) => theme.typography['400-md'].fontSize};
  line-height: ${({ theme }) => theme.typography['400-md'].lineHeight};
  font-weight: ${({ theme }) => theme.typography['400-md'].fontWeight};
  letter-spacing: ${({ theme }) => theme.typography['400-md'].letterSpacing};
`

export const TXTRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  return <TXTRendererContainer id="txt-renderer">{currentDocument?.fileData as React.ReactNode}</TXTRendererContainer>
}

TXTRenderer.fileTypes = ['txt', 'text/plain']
TXTRenderer.fileLoader = textFileLoader

TXTRenderer.propTypes = {
  mainState: PropTypes.object.isRequired as React.Validator<DocViewerMainState>,
}
