import React from 'react'

import { useIntercom } from 'react-use-intercom'

import { Button, ButtonProps } from '@components/ui/Buttons'

import { useIntercomProps } from './useIntercomProps'

function IntercomButton(props: ButtonProps) {
  const { show } = useIntercom()

  function handleClick() {
    show()
  }

  return <Button {...props} onClick={handleClick} />
}

export function IntercomLauncherButton({ children, className, variant = 'primaryContained', ...rest }: ButtonProps) {
  const Component = window.intercomAppId ? IntercomButton : Button
  const props = useIntercomProps(className)
  return (
    <Component variant={variant} {...rest} {...props}>
      {children}
    </Component>
  )
}
