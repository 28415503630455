import React from 'react'
import PropTypes from 'prop-types'

import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { FormattedMessage } from 'react-intl'

import { BillingoIcon, ManualSourceIcon } from '@oldComponents/ui/SvgIcons'

import { INVOICE_ORIGINS, QUICK_COLORS } from '@constants'

import { IconContainerSpan, IconTooltip } from '../IconTooltip'
import { NoszIntegrationIcon, SzamlazzHuIcon } from '../svgIcons'

interface InvoiceOriginIconProps {
  origin: InvoiceOrigin | undefined
}

function getOriginIcon(origin?: InvoiceOrigin) {
  switch (origin) {
    case INVOICE_ORIGINS.BILLINGO:
      return <BillingoIcon />

    case INVOICE_ORIGINS.EMAIL:
      return <MailOutlineIcon color="inherit" fontSize="inherit" />

    case INVOICE_ORIGINS.NAV:
      return <SzamlazzHuIcon color={QUICK_COLORS.common.navBadge} />

    case INVOICE_ORIGINS.SZAMLAZZ:
      return <SzamlazzHuIcon />

    case INVOICE_ORIGINS.NOSZ:
      return <NoszIntegrationIcon />

    default:
      return <ManualSourceIcon />
  }
}

function getOriginTooltipMessage(origin?: InvoiceOrigin) {
  switch (origin) {
    case INVOICE_ORIGINS.BILLINGO:
      return (
        <FormattedMessage
          id="badges.tooltips.billingo"
          defaultMessage="Ezt a számlát a Billingo rendszeréből szinkronizáltuk"
        />
      )

    case INVOICE_ORIGINS.EMAIL:
      return <FormattedMessage id="badges.tooltips.email" defaultMessage="Ez egy e-mailben beküldött számla" />

    case INVOICE_ORIGINS.NAV:
      return (
        <FormattedMessage
          id="badges.tooltips.szlahuNav"
          defaultMessage="Ezt a NAV-tól származó számlát a Számlázz.hu rendszeréből szinkronizáltuk."
        />
      )

    case INVOICE_ORIGINS.SZAMLAZZ:
      return (
        <FormattedMessage
          id="badges.tooltips.szlahu"
          defaultMessage="Ezt a számlát a Számlázz.hu rendszeréből szinkronizáltuk"
        />
      )

    case INVOICE_ORIGINS.NOSZ:
      return (
        <FormattedMessage
          id="origins.badges.tooltips.nosz"
          defaultMessage="Ezt a számlát a NAV Online Számla rendszeréből szinkronizáltuk"
        />
      )

    default:
      return <FormattedMessage id="badges.tooltips.manual" defaultMessage="Kézzel feltöltött számla" />
  }
}

export function PureOriginIcon({ origin }: InvoiceOriginIconProps) {
  return <IconContainerSpan>{getOriginIcon(origin)}</IconContainerSpan>
}

export function InvoiceOriginIcon({ origin }: InvoiceOriginIconProps) {
  const icon = getOriginIcon(origin)
  const tooltipMessage = getOriginTooltipMessage(origin)
  return <IconTooltip icon={icon} title={tooltipMessage} />
}

InvoiceOriginIcon.propTypes = {
  origin: PropTypes.oneOf(Object.values(INVOICE_ORIGINS)),
}
