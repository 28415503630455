export const SUBSCRIPTION_PERIODS = ['monthly', 'yearly'] as const
export const SUBSCRIPTION_PERIODS_OBJ = {
  monthly: SUBSCRIPTION_PERIODS[0],
  yearly: SUBSCRIPTION_PERIODS[1],
} as const

export const SUBSCRIPTION_PLANS = ['base', 'finance', 'pro']
export const SUBSCRIPTION_PLANS_OBJ = {
  szamla: SUBSCRIPTION_PLANS[0],
  okos: SUBSCRIPTION_PLANS[1],
  multi: SUBSCRIPTION_PLANS[2],
}

export enum SubscriptionBlockedReasons {
  SZAMLAZZ = 'szamlazz',
  DEBT = 'debt',
}
