import React from 'react'

import { Input } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import { Typography } from '@components/ui'
import { LightTooltip, UpsellMessage } from '@oldComponents/ui'

import { formStyles } from '@styles'
import { formSelectMessages } from '@messages'
import { IncludeExcludeFilterWrapper } from '../styles'

const useStyles = makeStyles(formStyles)

function FakeInput({ label, title }: { label: React.ReactChild; title: React.ReactChild }) {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const placeholder = formatMessage(formSelectMessages.creatableSelectPlaceholder)
  return (
    <div>
      <Typography size="700-xs" color="gray-80">
        {label}
      </Typography>
      <LightTooltip title={title} PopperProps={{ disablePortal: true }}>
        <div>
          <Input
            classes={{ root: classes.bootstrapRoot, input: classes.bootstrapInput }}
            disableUnderline
            fullWidth
            disabled
            placeholder={placeholder}
          />
        </div>
      </LightTooltip>
    </div>
  )
}

interface UpsellIncludeExcludeFiltersProps {
  excludeLabel: StringOrMessage
  includeLabel: StringOrMessage
  upsellMessage?: React.ReactChild
}

export function UpsellIncludeExcludeFilters({
  excludeLabel,
  includeLabel,
  upsellMessage = <UpsellMessage />,
}: UpsellIncludeExcludeFiltersProps) {
  return (
    <IncludeExcludeFilterWrapper>
      <FakeInput label={includeLabel} title={upsellMessage} />
      <FakeInput label={excludeLabel} title={upsellMessage} />
    </IncludeExcludeFilterWrapper>
  )
}
