import React from 'react'

import { CircularProgress } from '@material-ui/core'

import { Typography, TypographyProps } from '@components/ui/Typography'

import { WrapperDiv } from './styles'

type CenteredPageLoaderProps = Pick<TypographyProps, 'children'>

export function CenteredPageLoader({ children }: CenteredPageLoaderProps) {
  return (
    <WrapperDiv $gap={30}>
      <div>
        <CircularProgress color="primary" size={50} />
      </div>
      <Typography size="400-lg">{children}</Typography>
    </WrapperDiv>
  )
}
