import React from 'react'

import { FormattedMessage } from 'react-intl'

import { StrongTextValues } from '@helpers'

import { IntercomInheritLink } from '@components/Intercom'
import { InheritLink, PrimaryInheritLink } from '@components/ui'
import LightTooltip from '@oldComponents/ui/LightTooltip'

import LINKS from '@constants/links'

import { TaskTypes } from '../constants'

import { StyledLabelInfoIcon } from '../elements/styles'

export const SzjaIndicatorTaxBaseLabelMessage = (
  <FormattedMessage
    id="taxation.indicators.taxBase.szja"
    defaultMessage="SZJA adóalap {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.indicators.tooltip.taxBase.szja"
              defaultMessage="Az SZJA adóalapot a következőképpen számítjuk: adózás előtti jövedelem csökkentve a mentesített jövedelemrésszel."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const SzjaIndicatorTaxKeyLabelMessage = (
  <FormattedMessage id="taxation.indicators.taxKey.szja" defaultMessage="SZJA adókulcs" />
)

export const SzjaPayablesSummaryValueTooltipMessage = (
  <FormattedMessage
    id="taxation.payables.tooltip.summaryValue.szja"
    defaultMessage="Az SZJA adóalap és a 15%-os SZJA adókulcs szorzatából adódik."
  />
)

export const SzjaTaskFirstParamLabelMessage = (
  <FormattedMessage
    id="taxation.overview.quarterlyIncome"
    defaultMessage="Negyedéves bevétel {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.overview.tooltip.quarterlyIncome"
              defaultMessage="A negyedéves árbevételed onnan tudtuk, hogy megnéztük a számlázódból, hogy ügyfeleid mikor fizettek (pénzforgalmi szemlélet)."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const SzjaTaskSecondParamLabelMessage = (
  <FormattedMessage
    id="taxation.overview.quarterlyCostRatio"
    defaultMessage="Levonható költséghányad {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.overview.tooltip.quarterlyCostRatio"
              defaultMessage="A negyedéves árbevételed költségként elszámolható része, a regisztráció során általad megadott költséghányaddal számolva. Egyszerűen az árbevételed SZJA törvény szerint megállapított százaléka."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const SzjaTaskWarningMessage = (
  <FormattedMessage
    id="taxation.taskWarning.szja"
    defaultMessage="Az alábbi információk segítenek az <b>SZJA előleg áttekintésében</b>. Az adatok visszaigazolása után <b>a fizetendő adót a Tranzakciók menüpont, Adók aloldalon találod</b>. Az SZJA előleg kapcsán nincs negyedéves bevallási kötelezettség, majd a tárgyévet követő május 20-ig szükséges az SZJA bevallásban szerepeltetned az előlegeket. Kérdés esetén fordulj bizalommal <link>szakmai ügyfélszolgálatunkhoz</link>!"
    values={{
      ...StrongTextValues,
      link: function RenderSupportLink(chunks: string) {
        return <IntercomInheritLink inherit>{chunks}</IntercomInheritLink>
      },
    }}
  />
)

export const SzjaChecklistErrorMessage = (
  <FormattedMessage
    id="taxation.szja.checklistErrorMessage"
    defaultMessage="Válaszaid alapján <b>kedvezményt szeretnél érvényesíteni az SZJA előleg utalásakor</b>. Ezt az esetet jelenleg <b>nem támogatjuk</b>. A felülírás gombra kattintva manuálisan beállíthatod a helyes adóösszeget."
    values={StrongTextValues}
  />
)

export const SZJA_HELPER_LINKS = {
  [TaskTypes.BEVALLAS_22_SZJA]: LINKS.szja22BevallasTudascentrum,
  [TaskTypes.BEVALLAS_23_SZJA]: LINKS.szja23BevallasTudascentrum,
}

export function getYearlySzjaTaskWarningMessage(taskType: TaskTypes): JSX.Element {
  return (
    <FormattedMessage
      id="taxation.taskWarning.yearlySzja"
      defaultMessage="Az alábbi információk segítséget nyújtanak <b>az SZJA bevallás - egyéni vállalkozásodat érintő - mezőinek kitöltésében</b>. Az SZJA bevalláshoz XML-t ugyan nem tudunk generálni, <link1>de kitöltési segédletünkkel</link1> könnyedén elvégezheted ezt a teendőt. Kérjük, vedd figyelembe, hogy a teendő kizárólag az átalányadózó jövedelmedet mutatja, tehát <link2>nem tartalmazza az esetleges további SZJA köteles jövedelmeidet</link2>, valamint <link3>az SZJA adóalap-kedvezményeket sem</link3>! Ha bármilyen kérdésed lenne, kérjük, fordulj bizalommal <supportLink>szakmai ügyfélszolgálatunkhoz</supportLink>!"
      values={{
        ...StrongTextValues,
        link1: function RenderInheritLink(chunks: string) {
          return (
            <InheritLink
              rel="noopener noreferrer"
              target="_blank"
              href={SZJA_HELPER_LINKS[taskType as keyof typeof SZJA_HELPER_LINKS]}
            >
              {chunks}
            </InheritLink>
          )
        },
        link2: function RenderInheritLink(chunks: string) {
          return (
            <InheritLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://quickipedia.riport.app/hc/hu/articles/8525082663452"
            >
              {chunks}
            </InheritLink>
          )
        },
        link3: function RenderInheritLink(chunks: string) {
          return (
            <InheritLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://quickipedia.riport.app/hc/hu/articles/8512738703516"
            >
              {chunks}
            </InheritLink>
          )
        },
        supportLink: function RenderSupportLink(chunks: string) {
          return <IntercomInheritLink inherit>{chunks}</IntercomInheritLink>
        },
      }}
    />
  )
}

export function getYearlySzjaTaskFirstParamLabelMessage(year: number | string) {
  return (
    <FormattedMessage
      id="taxation.szja.overview.yearlyIncome"
      defaultMessage="Éves bevétel ({year}) {info}"
      values={{
        year,
        info: (
          <LightTooltip
            title={
              <FormattedMessage
                id="taxation.szja.overview.tooltip.yearlyIncome"
                defaultMessage="Az éves árbevételed onnan tudtuk, hogy megnéztük a számlázódból, hogy ügyfeleid mikor fizettek (pénzforgalmi szemlélet)."
              />
            }
            placement="top"
          >
            <StyledLabelInfoIcon />
          </LightTooltip>
        ),
      }}
    />
  )
}

export const YearlySzjaTaskSecondParamLabelMessage = (
  <FormattedMessage
    id="taxation.szja.overview.yearlyCostRatio"
    defaultMessage="Levonható költséghányad {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.szja.overview.tooltip.yearlyCostRatio"
              defaultMessage="Az éves árbevételed költségként elszámolható része, a regisztráció során általad megadott költséghányaddal számolva. Egyszerűen az árbevételed SZJA törvény szerint megállapított százaléka."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const YearlySzjaTaskThirdParamLabelMessage = (
  <FormattedMessage
    id="taxation.szja.overview.yearlyRevenue"
    defaultMessage="Átalányban megállapított jövedelem {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.szja.overview.tooltip.yearlyRevenue"
              defaultMessage="A tevékenységedből származó bevételek költséghányaddal csökkentett része a jövedelem."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const YearlySzjaIndicatorFirstQuarterPrePaymentLabelMessage = (
  <FormattedMessage
    id="taxation.szja.indicators.firstQuarterPrePayment"
    defaultMessage="I. negyedévi SZJA előleg {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.szja.overview.tooltip.firstQuarterPrePayment"
              defaultMessage="A kifizetett számláid és az adómentes sáv alapján ekkora összegű az I. negyedévre vonatkozó előleg összege. Fontos, hogy a 10 000 Ft alatti előleget a következő negyedévi előleghez számoltuk a bevallás helyessége érdekében."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const YearlySzjaIndicatorSecondQuarterPrePaymentLabelMessage = (
  <FormattedMessage
    id="taxation.szja.indicators.secondQuarterPrePayment"
    defaultMessage="II. negyedévi SZJA előleg {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.szja.overview.tooltip.secondQuarterPrePayment"
              defaultMessage="A kifizetett számláid és az adómentes sáv alapján ekkora összegű az II. negyedévre vonatkozó előleg összege. Fontos, hogy a 10 000 Ft alatti előleget a következő negyedévi előleghez számoltuk a bevallás helyessége érdekében."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const YearlySzjaIndicatorThirdQuarterPrePaymentLabelMessage = (
  <FormattedMessage
    id="taxation.szja.indicators.thirdQuarterPrePayment"
    defaultMessage="III. negyedévi SZJA előleg {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.szja.overview.tooltip.thirdQuarterPrePayment"
              defaultMessage="A kifizetett számláid és az adómentes sáv alapján ekkora összegű az III. negyedévre vonatkozó előleg összege. Fontos, hogy a 10 000 Ft alatti előleget a következő negyedévi előleghez számoltuk a bevallás helyessége érdekében."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const YearlySzjaIndicatorFourthQuarterPrePaymentLabelMessage = (
  <FormattedMessage
    id="taxation.szja.indicators.fourthQuarterPrePayment"
    defaultMessage="IV. negyedévi SZJA előleg {info}"
    values={{
      info: (
        <LightTooltip
          title={
            <FormattedMessage
              id="taxation.szja.overview.tooltip.fourthQuarterPrePayment"
              defaultMessage="A kifizetett számláid és az adómentes sáv alapján ekkora összegű az IV. negyedévre vonatkozó előleg összege. Fontos, hogy ha a IV. negyedévben és azelőtt sem éri el a 10.000 Ft alatti összeget az előleg, akkor ezt nem szükséges feltüntetni a bevallásban az előlegek között."
            />
          }
          placement="top"
        >
          <StyledLabelInfoIcon />
        </LightTooltip>
      ),
    }}
  />
)

export const NoPayableMessage = (
  <FormattedMessage id="taxation.szja.indicators.noPayable" defaultMessage="Nincs fizetendő" />
)

export const YearlySzjaIndicatorFromDateLabelMessage = (
  <FormattedMessage id="taxation.szja.indicators.fromDate" defaultMessage="Bevallási időszak kezdete" />
)

export const YearlySzjaIndicatorToDateLabelMessage = (
  <FormattedMessage id="taxation.szja.indicators.toDate" defaultMessage="Bevallási időszak vége" />
)

export function getYearlySzjaIndicatorExemptedIncomeMessage(year: number | string) {
  return (
    <FormattedMessage
      id="taxation.szja.indicators.yearlyExemptedIncome"
      defaultMessage="Mentesített jövedelemrész {info}"
      values={{
        info: (
          <LightTooltip
            title={
              <FormattedMessage
                id="taxation.szja.indicators.tooltip.yearlyExemptedIncome"
                defaultMessage="Az SZJA törvényben meghatározott mentesített jövedelemnek, vagyis az éves minimálbér felének a {year}-es évben felhasznált része."
                values={{ year }}
              />
            }
            placement="top"
          >
            <StyledLabelInfoIcon />
          </LightTooltip>
        ),
      }}
    />
  )
}

export function getTaxationSzjaBevallasTaskDoneMessage(taskType: TaskTypes): JSX.Element {
  return (
    <FormattedMessage
      id="taxation.szja.task.done"
      defaultMessage="Ezt a teendőt a QUiCK-ben elvégezted, az SZJA bevallás elkészítéséhez a <link>Tudáscentrumban találsz további segítséget</link>."
      values={{
        link: function RenderPrimaryInheritLink(chunks: string) {
          return (
            <PrimaryInheritLink
              href={SZJA_HELPER_LINKS[taskType as keyof typeof SZJA_HELPER_LINKS]}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </PrimaryInheritLink>
          )
        },
      }}
    />
  )
}

export const TaxationSzjaBevallasTaskDoneBySupportMessage = (
  <FormattedMessage
    id="taxation.szja.task.doneBySupport"
    defaultMessage="Ezt a teendőt már elvégezted a QUiCK-ben. A szakmai ügyfélszolgálat hamarosan felveszi veled a kapcsolatot, a válaszadásig szíves türelmedet kérjük!"
  />
)

export const YearlySzjaChecklistErrorMessage = (
  <FormattedMessage
    id="taxation.yearlySzja.checklist.errorMessage"
    defaultMessage="Válaszaid alapján kitöltési segédletünk nem lesz számodra megfelelő. Kattints a lenti Segítséget kérek gombra és a probléma leírása után munkatársaink felveszik veled a kapcsolatot!"
  />
)
