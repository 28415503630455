import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, List, ListItem } from '@material-ui/core'

import { Checkbox } from '@components/ui/Checkbox'

import { CheckboxListProps, PaidStatusFilterInnerKeys, PaidStatusFilterKeys } from '../types'

/**
 * Component to display checkboxes based on fields config
 *
 * @param {CheckboxListProps} {
 *  classes,
 *  fields,
 *  onChange,
 *  value
 * }
 */
export function CheckboxList({ classes, fields, onChange, value }: CheckboxListProps) {
  function handleChange(innerName?: PaidStatusFilterInnerKeys, newValues?: string[]) {
    return function handler(event: React.ChangeEvent<HTMLInputElement>) {
      if (innerName) {
        onChange({
          ...value,
          [event.target.name]: event.target.checked || event.target.indeterminate,
          [innerName]: event.target.checked ? newValues : [],
        })
      } else {
        onChange({ ...value, [event.target.name]: event.target.checked })
      }
    }
  }

  function handleInnerChange(name: PaidStatusFilterKeys, innerName: PaidStatusFilterInnerKeys) {
    const values = value[innerName]
    return function handler(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.checked) {
        // add to selection
        onChange({ ...value, [name]: true, [innerName]: [...values, event.target.name] })
      } else {
        // remove from selection
        const newValues = values.filter(innerValue => innerValue !== event.target.name)
        onChange({ ...value, [name]: newValues.length > 0, [innerName]: newValues })
      }
    }
  }
  return (
    <List className={classes.list} dense>
      {fields.map(listItem => (
        <ListItem key={listItem.name} className={classes.listItem}>
          <FormControlLabel
            className={classes.controlLabel}
            control={
              <Checkbox
                checked={
                  value[listItem.name] &&
                  (listItem.inner ? value[listItem.inner.name].length === listItem.inner.options.length : true)
                }
                onChange={handleChange(
                  listItem.inner?.name,
                  listItem.inner?.options.map(o => o.name)
                )}
                name={listItem.name}
                className={classes.checkbox}
                indeterminate={Boolean(
                  listItem.inner &&
                    value[listItem.inner.name].length > 0 &&
                    value[listItem.inner.name].length < listItem.inner.options.length
                )}
              />
            }
            label={listItem.label}
          />
          {listItem.inner && (
            <List dense className={classes.innerList}>
              {listItem.inner.options.map(
                innerListItem =>
                  listItem.inner && (
                    <ListItem key={`${listItem.inner.name}-${innerListItem.name}`} className={classes.listItem}>
                      <FormControlLabel
                        className={classes.controlLabel}
                        control={
                          <Checkbox
                            checked={value[listItem.inner.name].includes(innerListItem.name)}
                            onChange={handleInnerChange(listItem.name, listItem.inner.name)}
                            name={innerListItem.name}
                            className={classes.checkbox}
                          />
                        }
                        label={innerListItem.label}
                      />
                    </ListItem>
                  )
              )}
            </List>
          )}
        </ListItem>
      ))}
    </List>
  )
}

CheckboxList.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf([PaidStatusFilterKeys.EXPIRED, PaidStatusFilterKeys.EXPIRING, PaidStatusFilterKeys.PAID])
        .isRequired,
      label: PropTypes.string.isRequired,
      inner: PropTypes.shape({
        name: PropTypes.oneOf([PaidStatusFilterInnerKeys.EXPIRED, PaidStatusFilterInnerKeys.EXPIRING]).isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
      }),
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    isPaid: PropTypes.bool.isRequired,
    isExpiring: PropTypes.bool.isRequired,
    isExpired: PropTypes.bool.isRequired,
    expiringDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    expiredDays: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
}
