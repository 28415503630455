import React from 'react'

import { FormControlLabel } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { ListActionsDialogActions, ListActionsDialogBody, ListActionsDialogHeader } from '@components/dialogs'
import {
  AsyncButton,
  Button,
  Checkbox,
  CustomDialog,
  ExternalLink,
  FlexColumn,
  FlexRow,
  InfoText,
  Typography,
  WarningTypography,
} from '@components/ui'

import { LINKS } from '@constants'

import { AttentionDialogCloseButtonMessage, CancelButtonMessage } from '@messages'

const DetailsWrapperDiv = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  padding: 20px;
  border-radius: 6px;
  gap: 12px;

  ${WarningTypography} + & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;

  & > *:first-child {
    flex-shrink: 0;
    margin-right: 8px;
  }
`

const Wrapper = styled.div`
  padding-left: 34px;
  margin-top: 12px;

  > * + * {
    margin-top: 24px;
  }
`

const ButtonsContainer = styled(FlexRow)`
  justify-content: center;
  gap: 8px;
`

interface CompanyConsentDialogProps {
  error: BackendError
  isAdminUser: boolean
  loading: boolean
  onClose: VoidFunction
  onConfirm: VoidFunction
  open: boolean
  warning: boolean
}

export function CompanyConsentDialog({
  error,
  isAdminUser,
  loading,
  onClose,
  onConfirm,
  open,
  warning,
}: CompanyConsentDialogProps) {
  const [isAccepted, setAccepted] = React.useState(false)

  // reset checkbox state when dialog is closed
  React.useEffect(() => {
    if (!open) {
      setAccepted(false)
    }
  }, [open])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAccepted(event.target.checked)
  }

  return (
    <CustomDialog open={open} onClose={onClose}>
      <ListActionsDialogHeader
        borderless
        title={<FormattedMessage id="dialogs.companyConsent.title" defaultMessage="Adatkezelési hozzájárulás" />}
      />
      <ListActionsDialogBody>
        {warning && (
          <WarningTypography color="gray-80" size="400-md">
            {isAdminUser ? (
              <FormattedMessage
                id="dialogs.companyConsent.warningText.admin"
                defaultMessage="A feldolgozás közben hiba történt vagy a funkció engedélyét visszavonták. Kérlek próbáld újra!"
              />
            ) : (
              <FormattedMessage
                id="dialogs.companyConsent.warningText"
                defaultMessage="A feldolgozás közben hiba történt vagy a funkció engedélyét visszavonták."
              />
            )}
          </WarningTypography>
        )}
        <DetailsWrapperDiv>
          <InfoText iconColor="gray-40" size="400-md">
            {isAdminUser ? (
              <FormattedMessage
                id="dialogs.companyConsent.detailsText.admin"
                defaultMessage="A dokumentum feldolgozás előtt engedélyezned kell, hogy a dokumentum tartalmát továbbítsuk a ChatGPT felé, a lenti adatkezelési tájékoztató alapján. A továbbiakban is csak azoknak a dokumentumoknak az adatait továbbítjuk, amelyen feldolgozást futtatsz."
              />
            ) : (
              <FormattedMessage
                id="dialogs.companyConsent.detailsText"
                defaultMessage="Ennek a funkciónak a használatához egy adminisztrátor engedélyére van szükségünk. Az engedélyt bármely adminisztrátor szintű felhasználó megadhatja ugyanerre a felületre visszatérve."
              />
            )}
          </InfoText>
          <Wrapper>
            <ExternalLink
              href={LINKS.documentAiEULA}
              text={
                <FormattedMessage
                  id="dialogs.companyConsent.eulaLinkText"
                  defaultMessage="Adat- és dokumentumelemzésre vonatkozó tájékoztató"
                />
              }
              typographySize="400-md"
            />
            {isAdminUser && (
              <StyledFormControlLabel
                control={<Checkbox checked={isAccepted} onChange={handleChange} />}
                label={
                  <FormattedMessage
                    id="dialogs.companyConsent.checkbox"
                    defaultMessage="Elolvastam és tudomásul vettem az adat- és dokumentumelemzésre vonatkozó tájékoztatót."
                  />
                }
              />
            )}
          </Wrapper>
        </DetailsWrapperDiv>
      </ListActionsDialogBody>
      <ListActionsDialogActions borderless>
        {error && (
          <Typography color="error" size="400-sm">
            {error}
          </Typography>
        )}
        <ButtonsContainer>
          {isAdminUser ? (
            <>
              <Button variant="secondaryContained" onClick={onClose}>
                {CancelButtonMessage}
              </Button>
              <AsyncButton variant="primaryContained" loading={loading} onClick={onConfirm} disabled={!isAccepted}>
                <FormattedMessage id="dialogs.companyConsent.buttons.confirm" defaultMessage="Engedélyezem" />
              </AsyncButton>
            </>
          ) : (
            <Button variant="secondaryContained" onClick={onClose}>
              {AttentionDialogCloseButtonMessage}
            </Button>
          )}
        </ButtonsContainer>
      </ListActionsDialogActions>
    </CustomDialog>
  )
}
