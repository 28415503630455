import React from 'react'

import styled from 'styled-components'

function BaseFilterResetIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 21 21"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.127 5.499 11.152 9.29l1.191 1.192c.859-1.092 4.15-5.308 4.15-5.308a.832.832 0 0 0-.658-1.342H5.693L7.36 5.499h6.767ZM2.343 2.842 1.16 4.017l7.175 7.317v5c0 .458.375.833.833.833h1.667a.836.836 0 0 0 .833-.834v-1.808l4.817 4.817 1.175-1.175L2.343 2.842Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const FilterResetIcon = styled(BaseFilterResetIcon)``
