import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Button, CustomDialog, CustomDialogActions, CustomDialogBody, CustomDialogHeader } from '@components/ui'

import CreateCompanyForm from './CreateCompanyForm'

import { CloseButtonMessage } from '@messages'

const StyledDialog = styled(CustomDialog)`
  width: 444px;
`

interface CreatedSuccessViewProps {
  createdCompanyName: string
  onClose: VoidFunction
}

function CreatedSuccessView({ createdCompanyName, onClose }: CreatedSuccessViewProps) {
  return (
    <>
      <CustomDialogBody>
        <Typography align="center">
          <FormattedMessage
            id="dialogs.company.successText"
            defaultMessage="Sikeresen létrehoztad a(z) {company} céget."
            values={{ company: <b>{createdCompanyName}</b> }}
          />
        </Typography>
      </CustomDialogBody>
      <CustomDialogActions>
        <Button variant="primaryContained" onClick={onClose}>
          {CloseButtonMessage}
        </Button>
      </CustomDialogActions>
    </>
  )
}

interface CreateCompanyDialogProps {
  createdCompanyName: Nullable<string>
  isOpen: boolean
  onClose: VoidFunction
  onSubmitSuccessHandler: (payload: Company) => void
}

export default function CreateCompanyDialog({
  createdCompanyName,
  isOpen,
  onClose,
  onSubmitSuccessHandler,
}: CreateCompanyDialogProps) {
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <CustomDialogHeader
        title={<FormattedMessage id="dialogis.company.title" defaultMessage="Új cég létrehozása" />}
      />
      {createdCompanyName ? (
        <CreatedSuccessView createdCompanyName={createdCompanyName} onClose={onClose} />
      ) : (
        <CreateCompanyForm onSubmitSuccess={onSubmitSuccessHandler} />
      )}
    </StyledDialog>
  )
}

CreateCompanyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitSuccessHandler: PropTypes.func.isRequired,
}
