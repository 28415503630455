import React from 'react'

import styled from 'styled-components'

function BaseCoinsRegularIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.974 9.6763c-.1161-.1044-.1762-.186-.202-.238-.0055-.0102-.0098-.0192-.0139-.0276a.2911.2911 0 0 0-.0384-.0629l.0065-.0056c.0096-.0082.024-.0207.0458-.0363.0258-.052.0859-.134.202-.238.2363-.214.6359-.4542 1.2203-.6768 1.1644-.4426 2.8402-.7764 4.7394-.7764s3.575.3338 4.7352.7764c.5886.2226.9883.4628 1.2246.6768.116.104.1762.186.2019.238a.4629.4629 0 0 1 .0185.0366c-.0142.02-.0129.0353-.0115.0509.0012.0136.0024.0275-.007.0448-.0257.052-.0859.1337-.2019.238-.2363.2158-.636.4521-1.2246.6756-.8852.3394-2.0754.5929-3.4375.6918.5242.2234 1.0312.58 1.4652.971.2449.2235.4984.4942.7176.8208.6918-.1418 1.4008-.3309 1.9894-.5543.2493-.0946.4899-.1977.7176-.3137v1.8347c-.0219.0244-.0204.0418-.0188.0594.0012.0135.0023.027-.007.0438-.0257.0515-.0859.1375-.2019.2406-.2363.2148-.636.4512-1.2246.6746a7.7716 7.7716 0 0 1-.6102.2063v.152a6.417 6.417 0 0 1 2.0452 1.2661l.0173-.0088v.0247c.8344.7712 1.4635 1.7611 1.7964 2.8787.1634-.3099.2661-.6654.2661-1.0686V9.3334c0-.7356-.4168-1.3544-.9066-1.7978-.4942-.4443-1.1516-.7966-1.8735-1.0716-1.448-.5529-3.3859-.8732-5.4699-.8732s-4.0219.3203-5.4699.8731c-.7219.275-1.3793.6274-1.8734 1.0717-.4899.4434-.9067 1.0622-.9067 1.7978v.5629c.752-.1014 1.5383-.1611 2.3375-.1766a.5145.5145 0 0 1-.0472-.0434Z"
        fill="currentColor"
      />
      <path
        d="M21.6837 14.8225c-.0039-.768-.4191-1.3447-.9066-1.7852-.4942-.4469-1.1516-.7992-1.8735-1.0742-1.448-.55-3.3859-.8723-5.4699-.8723s-4.0206.3223-5.4716.8723c-.7219.275-1.3772.6273-1.87 1.0742-.4927.4426-.9084 1.0227-.9084 1.7961v8.9762c0 .7734.4157 1.3922.9084 1.8347.4928.4469 1.1481.7993 1.87 1.0743 1.451.55 3.3876.8722 5.4716.8722 1.7042 0 3.3107-.2155 4.6316-.5948a6.4368 6.4368 0 0 1-2.1679-1.6432c-.7541.1121-1.585.1755-2.4637.1755-1.8992 0-3.575-.2965-4.7373-.739-.5844-.2235-.9866-.4598-1.2242-.6747-.1155-.1031-.1744-.189-.202-.2406a.2665.2665 0 0 0-.0264-.0365c-.0154-.0186-.023-.0279.0024-.0279v-1.8735c.2282.116.4684.2192.7159.3137 1.451.55 3.3876.8723 5.4329.8723.423 0 .8387-.0128 1.2445-.0377a6.4147 6.4147 0 0 1-.2877-2.0482 17.9814 17.9814 0 0 1-.9181.0234c-1.8992 0-3.575-.2965-4.7373-.7391-.5844-.2234-.9866-.4598-1.2242-.6746-.1155-.1031-.1744-.1891-.202-.2406-.0218-.0387-.024-.0602-.024-.0645v-1.8734c.2282.116.4684.2191.7159.3137 1.451.55 3.3876.8335 5.4716.8335.4996 0 .9908-.0163 1.4689-.0486a6.4377 6.4377 0 0 1 1.7198-2.2906c-.9324.1788-2.0181.2767-3.1887.2767-1.8992 0-3.575-.2578-4.7373-.7003-.5844-.2235-.9866-.4598-1.2242-.6747-.1155-.1031-.1744-.189-.202-.2406-.0218-.0386-.024-.0601-.024-.1031h-.0019a.0618.0618 0 0 0 .026-.0258c.0275-.0515.0864-.1375.202-.2406.2376-.2149.6397-.4512 1.2241-.6746 1.1623-.4426 2.8381-.7391 4.7373-.7391 1.8992 0 3.575.2965 4.7352.7391.5886.2234.9883.4597 1.2246.6746.116.1031.1762.1891.2019.2406.0134.0223.0198.0284.0229.0291a.0712.0712 0 0 0-.0145.026 6.4514 6.4514 0 0 1 1.1632-.1052c.3113 0 .6173.0221.9167.065Z"
        fill="currentColor"
      />
      <path
        d="M26.0867 21.1742c0 2.9363-2.3804 5.3167-5.3167 5.3167s-5.3167-2.3804-5.3167-5.3167 2.3804-5.3167 5.3167-5.3167 5.3167 2.3804 5.3167 5.3167Zm-7.5835.4909 1.2647 1.2648a.5547.5547 0 0 0 .7826 0l2.5294-2.5295a.5547.5547 0 0 0 0-.7826.5545.5545 0 0 0-.7825 0l-2.1382 2.1382-.8735-.8734a.5545.5545 0 0 0-.7825 0 .5545.5545 0 0 0 0 .7825Z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const CoinsRegularIcon = styled(BaseCoinsRegularIcon)``
