import { TaxationTaskTypes } from '../../constants'

export enum ModifyDialogState {
  confirm = 'confirm',
  form = 'form',
}

export interface TbjSzochoModifyDialogProps {
  variant: TaxationTaskTypes.TBJ_SZOCHO
  data: {
    tbj: number
    szocho: number
  }
}

export interface SzjaModifyDialogProps {
  variant: TaxationTaskTypes.SZJA
  data: {
    szja: number
  }
}
