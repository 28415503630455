import Cookie from 'js-cookie'
import __snakeCase from 'lodash/snakeCase'
import moment from 'moment'

import { ROWS_PER_PAGE_OPTIONS } from '../constants'
import { formatDate } from './date'

export const LANGUAGE_COOKIE_NAME = 'quick_display_language'
export const DEFAULT_LANGUAGE = 'hu'
export const DATE_FILTER_COOKIE_NAME_PREFIX = 'quick_filter_'
export const PAGE_SIZE_COOKIE_NAME = 'quick_default_page_size'

const COOKIE_EXPIRATION_DAYS = 365

// COOKIE based
export function getDefaultLanguage(defaultLang = DEFAULT_LANGUAGE) {
  const lang = Cookie.get(LANGUAGE_COOKIE_NAME)
  return lang || defaultLang
}
// only set language cookie when not exists
export function setDefaultLanguage(defaultLang: string) {
  if (!Cookie.get(LANGUAGE_COOKIE_NAME)) {
    Cookie.set(LANGUAGE_COOKIE_NAME, defaultLang, { expires: COOKIE_EXPIRATION_DAYS })
  }
}
// always change language cookie
export function changeLanguage(lang: string) {
  moment.locale(lang)
  Cookie.set(LANGUAGE_COOKIE_NAME, lang, { expires: COOKIE_EXPIRATION_DAYS })
}

function getFilterCookieName(name: string) {
  return `${DATE_FILTER_COOKIE_NAME_PREFIX}${__snakeCase(name)}`
}

export function getDateFilter(name: string) {
  const cookieName = getFilterCookieName(name)
  const date = Cookie.get(cookieName)
  return date ? formatDate(date) : null
}

export function setDateFilter(name: string, value: string) {
  const cookieName = getFilterCookieName(name)
  Cookie.set(cookieName, value, {
    expires: COOKIE_EXPIRATION_DAYS,
  })
}

export function getCookie(name: string) {
  return Cookie.get(__snakeCase(name))
}

export function setCookie(name: string, value: number | string, expDateOrDays: Date | number) {
  Cookie.set(__snakeCase(name), String(value), { expires: expDateOrDays })
}

// dashboard table list rows-per-page-options
export function getDefaultPageSize() {
  const num = Cookie.get(PAGE_SIZE_COOKIE_NAME)

  if (process.env.ROWS_PER_PAGE) {
    return Number(process.env.ROWS_PER_PAGE)
  }

  if (num) {
    return Number(num)
  }

  return ROWS_PER_PAGE_OPTIONS[0]
}

export function setDefaultPageSize(value: number | string) {
  Cookie.set(PAGE_SIZE_COOKIE_NAME, String(value), {
    expires: COOKIE_EXPIRATION_DAYS,
  })
}
