// TODO: later we might change order to react-table logic, which is a descending boolean
// TODO: maybe DefaultSorting could also have a generic prop so `orderBy` can only be valid property of that object
const DEFAULT_ORDER = 'desc'

export const EXPENSE_V2_DEFAULT_SORTING: DefaultSorting = {
  order: DEFAULT_ORDER,
  orderBy: 'planned_payment_date',
}

export const DOKUMENTS_DEFAULT_SORTING: DefaultSorting = {
  order: DEFAULT_ORDER,
  orderBy: 'created_at',
}

export const QUARANTINE_V2_DEFAULT_SORTING: DefaultSorting = {
  order: DEFAULT_ORDER,
  orderBy: 'fulfilled_at',
}

export const INCOME_V2_DEFAULT_SORTING: DefaultSorting = {
  order: DEFAULT_ORDER,
  orderBy: 'due_at',
}

export const TAX_V2_DEFAULT_SORTING: DefaultSorting = {
  order: DEFAULT_ORDER,
  orderBy: 'title',
}

export const SALARY_V2_DEFAULT_SORTING: DefaultSorting = {
  order: DEFAULT_ORDER,
  orderBy: 'title', // tárgyhó
}
