import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { TextButtonWithIcon } from '@components/ui/Buttons'
import { EyeIcon, EyeSlashedIcon } from '@components/ui/svgIcons'
import { Typography, TypographyProps } from '@components/ui/Typography'

const TitleWrapperDiv = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

const ToggleButton = styled(TextButtonWithIcon)`
  color: ${({ theme }) => theme.colors.common.szamlazz};
  font-size: 12px;
`

export interface SimpleContentAccordionControlBaseProps {
  className?: string
  title: StringOrMessage
  titleTag?: TypographyProps['tag']
}

interface SimpleContentAccordionControlProps extends SimpleContentAccordionControlBaseProps {
  isDetailVisible: boolean
  toggleVisibility: VoidFunction
}

export default function SimpleContentAccordionControl({
  className,
  isDetailVisible,
  title,
  titleTag = 'h2',
  toggleVisibility,
}: SimpleContentAccordionControlProps) {
  return (
    <TitleWrapperDiv className={className}>
      <Typography color="gray-80" tag={titleTag} size="700-md">
        {title}
      </Typography>
      <ToggleButton onClick={toggleVisibility} startIcon={isDetailVisible ? <EyeSlashedIcon /> : <EyeIcon />}>
        {isDetailVisible ? (
          <FormattedMessage id="buttons.generic.hide" defaultMessage="elrejtés" />
        ) : (
          <FormattedMessage id="buttons.generic.show" defaultMessage="megjelenítés" />
        )}
      </ToggleButton>
    </TitleWrapperDiv>
  )
}

SimpleContentAccordionControl.propTypes = {
  className: PropTypes.string,
  isDetailVisible: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  titleTag: PropTypes.string,
  toggleVisibility: PropTypes.func.isRequired,
}
