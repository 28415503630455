import React from 'react'
import { oneOf } from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { SzamlazzHuIcon } from '@components/ui/svgIcons'

import { PARTNER_SOURCES } from '@constants'

import Tooltip from './LightTooltip'
import { BillingoIcon, ManualSourceIcon, QuickSourceIcon } from './SvgIcons'

const useStyles = makeStyles(() => ({
  root: {
    '&>svg': { transform: 'translate(0, 2px)' },
  },
}))

export default function PartnerSource({ variant }) {
  const classes = useStyles()
  switch (variant) {
    case PARTNER_SOURCES.SOURCE_SZAMLAZZ:
      return (
        <Tooltip
          title={
            <FormattedMessage
              id="partnerSource.tooltips.szlahu"
              defaultMessage="Ezt a partnert a Számlázz.hu rendszeréből szinkronizáltuk"
            />
          }
        >
          <div className={classes.root}>
            <SzamlazzHuIcon />
          </div>
        </Tooltip>
      )

    case PARTNER_SOURCES.SOURCE_BILLINGO:
      return (
        <Tooltip
          title={
            <FormattedMessage
              id="partnerSource.tooltips.billingo"
              defaultMessage="Ezt a partnert a Billingo rendszeréből szinkronizáltuk"
            />
          }
        >
          <div className={classes.root}>
            <BillingoIcon />
          </div>
        </Tooltip>
      )

    case PARTNER_SOURCES.SOURCE_QUICK:
      return (
        <Tooltip
          title={
            <FormattedMessage
              id="partnerSource.tooltips.quickAutoGenerated"
              defaultMessage="Ezt a partnert a QUiCK automatikusan hozta létre"
            />
          }
        >
          <div className={classes.root}>
            <QuickSourceIcon />
          </div>
        </Tooltip>
      )

    default:
      return (
        <Tooltip
          title={<FormattedMessage id="partnerSource.tooltips.manual" defaultMessage="Kézzel rögzített partner" />}
        >
          <div className={classes.root}>
            <ManualSourceIcon />
          </div>
        </Tooltip>
      )
  }
}

PartnerSource.propTypes = {
  variant: oneOf(Object.values(PARTNER_SOURCES)),
}
