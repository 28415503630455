import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

const styles: Styles<Theme, Record<never, never>> = () => ({
  root: {
    textAlign: 'center',
  },
  loader: {
    marginBottom: 30,
  },
})

export default styles
