import React from 'react'

import { ExpandMore } from '@material-ui/icons'
import { defineMessages, useIntl } from 'react-intl'

import { ButtonProps } from '@components/ui'

import { ExpandIconButton } from '@components/tables/styles'

const messages = defineMessages({
  expandRowShow: {
    id: 'table.expandRow.show',
    defaultMessage: 'Sor részleteinek mutatása',
  },
  expandRowHide: {
    id: 'table.expandRow.hide',
    defaultMessage: 'Sor részleteinek elrejtése',
  },
})

interface ExpandRowButtonProps extends Omit<ButtonProps, 'children'> {
  isExpanded: boolean
}

export function ExpandRowButton({ isExpanded, ...rest }: ExpandRowButtonProps) {
  const { formatMessage } = useIntl()

  return (
    <ExpandIconButton
      {...rest}
      $expanded={isExpanded && !rest.disabled}
      title={formatMessage(isExpanded ? messages.expandRowHide : messages.expandRowShow)}
    >
      <ExpandMore />
    </ExpandIconButton>
  )
}
