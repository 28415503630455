import __orderBy from 'lodash/orderBy'
import { GroupBase, Options, OptionsOrGroups } from 'react-select'

import { getValueComponents } from '@oldComponents/forms/DetailsForm/elements/LedgerNumberSelects/helpers'

import { SelectOption } from './types'

export function createSingleOption(
  code: LedgerNumberFormValue['code'],
  name: LedgerNumberFormValue['name'] = null
): SelectOption {
  return {
    value: code,
    label: code,
    data: { code, name },
  }
}

export function createOptions(options: LedgerNumberFormValue[]): SelectOption[] {
  return __orderBy(
    options.map(option => createSingleOption(option.code, option.name)),
    item => item.value,
    'asc'
  )
}

export function getNewOptionData(inputValue: string, optionLabel: React.ReactNode): SelectOption {
  const { code, name } = getValueComponents(inputValue)
  return {
    value: code,
    label: String(optionLabel),
    data: { code, name, isNew: true },
  }
}

function compareOption(inputValue: string, option: SelectOption) {
  return inputValue.toLowerCase() === [option.data.code, option.data.name].join(' ').toLowerCase()
}

function groupedCompareOption(inputValue: string, option: SelectOption | GroupBase<SelectOption>) {
  if ('options' in option) {
    return option.options.some(opt => compareOption(inputValue, opt))
  }
  return compareOption(inputValue, option)
}

export function isValidNewOption(
  inputValue: string,
  selectValue: Options<SelectOption> | null,
  selectOptions: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>
) {
  // returns true when
  // inputValue is not empty or
  // selectValue is not null or
  // inputValue length is too short (minimum 2)
  // selectValue does not match with entered inputValue or
  // select has no options what matches the inputValue
  return !(
    !inputValue ||
    !selectValue ||
    inputValue.length < 2 ||
    selectValue.some(function (option) {
      return compareOption(inputValue, option)
    }) ||
    selectOptions.some(function (option) {
      return groupedCompareOption(inputValue, option)
    })
  )
}
