import { defineMessages } from 'react-intl'

const messages = defineMessages({
  formLabelEmail: {
    id: 'form.label.email',
    defaultMessage: 'Email cím',
  },
  formLabelBusinessName: {
    id: 'form.label.businessName',
    defaultMessage: 'Vállalkozás elnevezése',
  },
  formLabelBusinessType: {
    id: 'form.label.businessType',
    defaultMessage: 'Vállalkozási forma',
  },
  selectPlaceholder: {
    id: 'form.placeholders.select',
    defaultMessage: 'Válassz a listából', //! duplicated
  },
  asyncSelectPlaceholder: {
    id: 'form.placeholders.asyncSelect',
    defaultMessage: 'Név vagy adószám',
  },
  asyncLoadingText: {
    id: 'form.select.asyncLoadingText',
    defaultMessage: 'Adatok lekérdezése folyamatban...',
  },
  asyncCegjelzoCreateLabelText: {
    id: 'form.select.asyncCegjelzoCreateLabelText',
    defaultMessage: 'Kézzel létrehoz (nincs adat)',
  },
  asyncNoResultsText: {
    id: 'form.select.asyncNoResultsText',
    defaultMessage: 'Kezdj el gépelni a kereséshez',
  },
})

export default messages
