import React from 'react'

import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { commonActions, CommonAxiosPayload } from '@services'

import { bindActionToPromise, cancellablePromise } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { Portal, RffSubmitButton } from '@components/ui'
import { FormError } from '@components/ui/FormElements'
import { RenderTextField } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION } from '@constants'

import { supportViewCommentPlaceholderMessage } from '../messages'

const TEXTAREA_INPUT_PROPS = { multiline: true, maxRows: 5, minRows: 5 }

const ButtonWrapperDiv = styled.div`
  margin-top: 24px;
`

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-control + p {
    margin-top: 20px;
  }
  .form-control + ${ButtonWrapperDiv} {
    margin-top: 44px;
  }

  .form-control {
    label {
      font-size: ${({ theme }) => theme.typography['heading-6'].fontSize};
      font-weight: ${({ theme }) => theme.typography['heading-6'].fontWeight};
      line-height: ${({ theme }) => theme.typography['heading-6'].lineHeight};
      transform: translate(0, -0.5rem);
      color: ${({ theme }) => theme.colors.common.offBlack};
    }
    label + * {
      margin-top: 1.5rem;
    }
    textarea::placeholder {
      font-size: ${({ theme }) => theme.typography['400-sm'].fontSize};
      font-weight: ${({ theme }) => theme.typography['400-sm'].fontWeight};
      font-style: italic;
      color: ${({ theme }) => theme.colors.gray[40]};
    }
  }
`

const INITIAL_VALUES = { comment: '' }

interface SupportViewProps<Response> {
  callUrl: AsyncFunction<CommonAxiosPayload<unknown>, Response>
  url: string
  onEnterResponseState: (payload?: Response) => void
  placeholder?: string
  portalAnchorEl: React.RefObject<HTMLElement>['current']
  label?: StringOrMessage
}

function PureSupportView<Response>({
  callUrl,
  url,
  onEnterResponseState,
  placeholder,
  label,
  portalAnchorEl,
}: SupportViewProps<Response>) {
  const { formatMessage } = useIntl()
  const cancellablePromiseRef = useCancellablePromiseRef<Response>()
  let externalSubmitFn: (event: React.MouseEvent<HTMLButtonElement>) => void

  async function onSubmit(values: Record<string, number>) {
    if (url) {
      try {
        cancellablePromiseRef.current = cancellablePromise(
          callUrl({ url, data: values, method: 'post', isFormError: true })
        )
        const results = await cancellablePromiseRef.current.promise
        onEnterResponseState(results)
      } catch (error) {
        // API error handling already taken care of in saga
        return error
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      render={({ handleSubmit, submitting }) => {
        externalSubmitFn = handleSubmit
        return (
          <StyledForm onSubmit={handleSubmit} noValidate autoComplete="off" data-testid="bevallas-support-form">
            <Field
              component={RenderTextField}
              disabled={submitting}
              label={label}
              placeholder={placeholder ?? formatMessage(supportViewCommentPlaceholderMessage)}
              name="comment"
              subscription={FIELD_SUBSCRIPTION}
              InputProps={TEXTAREA_INPUT_PROPS}
            />

            <FormError />

            <Portal anchorEl={portalAnchorEl}>
              <RffSubmitButton variant="primaryContained" onClick={event => externalSubmitFn(event)}>
                <FormattedMessage id="taxation.buttons.send" defaultMessage="Küldés" />
              </RffSubmitButton>
            </Portal>
          </StyledForm>
        )
      }}
    />
  )
}

// these any cast need to avoid genery type issue with redux-connect
export const SupportView = connect<null, Pick<SupportViewProps<any>, 'callUrl'>, any, Store>(null, dispatch => ({
  callUrl: bindActionToPromise(dispatch, commonActions.callUrl.request),
}))(PureSupportView)

SupportView.displayName = 'SupportView'
