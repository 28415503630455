import React from 'react'

import { connect } from 'react-redux'

import { getRouteUrl } from '@helpers'

import { usePortal } from '@contexts'

import { AbsoluteArtifactPreviewControlsDiv, ArtifactPreviewControlsDiv } from '@components/ArtifactViewer'
import { InvoicePreviewVariant } from '@components/GeneratedPreviewViewer'
import { JumpToInvoiceDetailsLinkButton } from '@components/ui'

import { RouteKeys } from '@constants'

interface PartnerPreviewAnchorElementProps {
  companyId: number
  invoiceId: number
  variant: InvoicePreviewVariant
}

function PurePartnerPreviewAnchorElement({ companyId, invoiceId, variant }: PartnerPreviewAnchorElementProps) {
  const { setPortalAnchorEl } = usePortal()

  const routeKey = variant === 'expense' ? RouteKeys.COST : RouteKeys.INCOME
  const detailsUrl = `${getRouteUrl(routeKey, companyId)}/${invoiceId}`

  return (
    <AbsoluteArtifactPreviewControlsDiv>
      <JumpToInvoiceDetailsLinkButton detailsUrl={detailsUrl} />
      <ArtifactPreviewControlsDiv ref={setPortalAnchorEl} />
    </AbsoluteArtifactPreviewControlsDiv>
  )
}

export const PartnerPreviewAnchorElement = connect((state: Store) => ({
  companyId: state.auth.company.data.id,
}))(PurePartnerPreviewAnchorElement)

PartnerPreviewAnchorElement.displayName = 'PartnerPreviewAnchorElement'
