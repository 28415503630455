import React from 'react'
import { number, string } from 'prop-types'

/* eslint-disable max-len */
const LinkIcon = ({ color, size }) => (
  <svg width={(11 / 12) * size} height={size} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.07422 2.76831C2.77809 4.11244 3.06093 6.34307 4.54563 7.33121C4.59455 7.36379 4.65963 7.35732 4.70165 7.31617C5.01422 7.01006 5.27864 6.71355 5.51017 6.33645C5.54559 6.27876 5.52356 6.2039 5.46413 6.17162C5.23767 6.04865 5.01233 5.81807 4.88547 5.5745L4.88532 5.57458C4.73336 5.27129 4.68162 4.93131 4.76207 4.58019C4.76216 4.58021 4.76224 4.58024 4.76233 4.58024C4.8549 4.13071 5.33639 3.71254 5.70413 3.32581C5.70336 3.32555 5.70261 3.32527 5.70183 3.32502L7.07967 1.9155C7.62875 1.35379 8.53193 1.34916 9.08671 1.90522C9.64711 2.45556 9.65637 3.36539 9.10731 3.92708L8.27274 4.78727C8.23411 4.82708 8.22158 4.88517 8.23966 4.93767C8.43181 5.49619 8.47908 6.28372 8.35031 6.87871C8.3467 6.89536 8.3672 6.90626 8.37909 6.89409L10.1553 5.07701C11.29 3.91624 11.2804 2.02634 10.1339 0.877247C8.96394 -0.29544 7.05925 -0.28568 5.90128 0.898898L4.08133 2.76068C4.07893 2.76322 4.07665 2.76581 4.07422 2.76831Z"
      fill={color}
    />
    <path
      d="M7.39476 7.58717C7.39474 7.58724 7.3947 7.5873 7.39468 7.58737C7.39582 7.58689 7.39687 7.58644 7.39801 7.58594C7.76051 6.92156 7.83187 6.15958 7.66198 5.41678L7.6612 5.41758L7.66037 5.41721C7.49905 4.75563 7.05643 4.09867 6.45528 3.69419C6.40357 3.6594 6.32096 3.66343 6.27254 3.70268C5.96803 3.94946 5.66997 4.26592 5.4733 4.66975C5.44242 4.73316 5.46554 4.80931 5.52641 4.84473C5.75466 4.97755 5.96079 5.172 6.0988 5.43016L6.09902 5.43001C6.20657 5.61237 6.31256 5.95841 6.24391 6.33021C6.24386 6.33021 6.2438 6.33021 6.24376 6.33021C6.17971 6.82315 5.68306 7.27531 5.28826 7.68274L5.28845 7.68293C4.98792 7.99096 4.22396 8.77158 3.91806 9.08482C3.36899 9.64651 2.46125 9.65579 1.90086 9.10546C1.34047 8.55513 1.3312 7.6453 1.88027 7.08361L2.71731 6.22083C2.75525 6.18173 2.76815 6.1249 2.75127 6.07304C2.56541 5.50147 2.5145 4.73169 2.63181 4.13737C2.63508 4.1208 2.61474 4.11022 2.60294 4.12229L0.853435 5.91202C-0.292886 7.08471 -0.283171 8.99399 0.875058 10.1549C2.04498 11.3038 3.94005 11.2844 5.08632 10.1118C5.48454 9.66529 7.18919 8.08336 7.39476 7.58717Z"
      fill={color}
    />
  </svg>
)

LinkIcon.propTypes = {
  size: number,
  color: string,
}
LinkIcon.defaultProps = {
  color: '#fff',
  size: 12,
}

export default LinkIcon
