import React from 'react'
import PropTypes from 'prop-types'

import { Field, useField, useFormState } from 'react-final-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { required } from 'redux-form-validators'
import styled from 'styled-components'

import { applyIf, getIsAutokasszaEnabled } from '@helpers'

import { BlurFieldListener } from '@components/forms'
import { Accordion, AmountInput, AutoKasszaIcon, ExternalLink, InfoText, Typography } from '@components/ui'
import { DoubleColumn, FirstColumn, FormGrid2Column, SecondColumn } from '@components/ui/Wrappers' // separate imports to avoid circular dependency issues
import { DateInput, RenderTextField } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION, LINKS, PaidThroughType, USE_FIELD_VALUE_CONFIG } from '@constants'

import { onChangeBalanceHandler, onChangeValueDateHandler } from '../helpers'
import { PaidThroughFormValues } from '../types'
import { IsAutokasszaCheckbox } from './IsAutokasszaCheckbox'
import { LastSavedBalancesList } from './LastSavedBalancesList'

import { formErrorMessages, MoreInformationMessage } from '@messages'
import messages from '../messages'
import { CollapseGrid2Column, FormSection } from '../styles'

// need min-height, otherwise the dialog would jump around in sizes
const DoubleColumnWithMinHeight = styled(DoubleColumn)`
  min-height: 64px;
`

const BlockTitleSpan = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`

const AutokasszaInfoContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`

// this exists because the Autokassza component on the dialog is bigger then the normal collapse component - the collapse cuts of what doesn't fit
const CollapseGrid2ColumnWithMaxHeight = styled(CollapseGrid2Column)`
  max-height: ${props => (props.open ? '250px' : '0')};
`

const StyledExternalLink = styled(ExternalLink)`
  padding-left: 34px;
  font-size: 12px;
`

const FormGrid2ColumnWithTopPadding = styled(FormGrid2Column)`
  padding-top: 12px;
`

interface EditorFormBalanceSectionProps {
  bankProviders: BankProvider[]
  detailsData?: Nullable<BackendPaidThroughDetailResponse>
  isEdit: boolean
  provider: Nullable<number>
}

function PureEditorFormBalanceSection({ bankProviders, detailsData, isEdit, provider }: EditorFormBalanceSectionProps) {
  const { formatMessage } = useIntl()
  const { submitting, errors, submitFailed, submitErrors } = useFormState({
    subscription: { submitting: true, errors: true, submitFailed: true, submitErrors: true },
  })
  const [balanceAccordionOpen, setBalanceAccordionOpen] = React.useState(false)

  const {
    input: { value: paidThroughTypeValue },
  } = useField<PaidThroughType>('paidthrough_type', USE_FIELD_VALUE_CONFIG)

  const {
    input: { value: isAutoKasszaValue },
  } = useField<boolean>('is_autokassza', USE_FIELD_VALUE_CONFIG)

  const isBalanceRequired = isAutoKasszaValue && (!isEdit || detailsData?.balances?.length === 0)

  if (
    !balanceAccordionOpen &&
    submitFailed &&
    (errors?.balance || errors?.value_date || submitErrors?.balance || submitErrors?.value_date)
  ) {
    // when user try to submit form with errors in balance section prevent to close accordion
    setBalanceAccordionOpen(true)
  }

  const balanceValidation = applyIf(
    (value: string, values) =>
      (values as PaidThroughFormValues).is_autokassza && (!isEdit || detailsData?.balances?.length === 0)
  )(
    required({
      message: formatMessage(formErrorMessages.required),
    })
  )

  return (
    <FormSection as={FormGrid2Column}>
      <DoubleColumn>
        <Accordion
          variant="plain"
          iconSize="small"
          headerContent={
            <Typography size="700-md" tag="h4" data-testid="balance-accordion-label">
              {formatMessage(messages.balanceAccordionHeader)}
            </Typography>
          }
          open={balanceAccordionOpen}
          openHandler={() => setBalanceAccordionOpen(!balanceAccordionOpen)}
        >
          <FormGrid2ColumnWithTopPadding>
            <FirstColumn>
              <Field
                required={isBalanceRequired}
                component={RenderTextField}
                disabled={submitting}
                InputProps={{
                  inputComponent: AmountInput,
                  inputProps: { maximumFractionDigits: 2 },
                }}
                label={formatMessage(messages.balanceLabel)}
                name="balance"
                subscription={FIELD_SUBSCRIPTION}
                validate={balanceValidation}
              />
              <BlurFieldListener name="balance" handler={onChangeBalanceHandler} />
            </FirstColumn>
            <SecondColumn>
              <Field
                required={isBalanceRequired}
                subscription={FIELD_SUBSCRIPTION}
                component={RenderTextField}
                label={formatMessage(messages.balanceDateLabel)}
                name="value_date"
                InputProps={{
                  placeholder: formatMessage(messages.dateFieldPlaceholder),
                  inputComponent: DateInput,
                }}
                disabled={submitting}
                validate={balanceValidation}
              />
              <BlurFieldListener name="value_date" handler={onChangeValueDateHandler} />
            </SecondColumn>
          </FormGrid2ColumnWithTopPadding>
          {isEdit && (
            <FormGrid2Column>
              <DoubleColumn>
                <LastSavedBalancesList data={detailsData} data-testid="last-saved-balances-list" />
              </DoubleColumn>
            </FormGrid2Column>
          )}
        </Accordion>
      </DoubleColumn>
      <CollapseGrid2Column open={Boolean(paidThroughTypeValue)}>
        <DoubleColumnWithMinHeight>
          <InfoText iconColor="gray-40">
            {formatMessage(
              paidThroughTypeValue === PaidThroughType.BANK_ACCOUNT
                ? messages.balanceInfoBankAccount
                : messages.balanceInfoCash
            )}
          </InfoText>
        </DoubleColumnWithMinHeight>
      </CollapseGrid2Column>
      <CollapseGrid2ColumnWithMaxHeight
        open={getIsAutokasszaEnabled(provider, bankProviders) && paidThroughTypeValue === PaidThroughType.BANK_ACCOUNT}
        as={DoubleColumn}
      >
        <DoubleColumn>
          <BlockTitleSpan>
            <Typography size="700-md" tag="h4">
              {formatMessage(messages.autokassza)}
            </Typography>
            <AutoKasszaIcon size={20} />
          </BlockTitleSpan>
        </DoubleColumn>
        <DoubleColumn>
          <AutokasszaInfoContainer>
            <InfoText iconColor="gray-40">{formatMessage(messages.autokasszaInfo)}</InfoText>
            <StyledExternalLink text={MoreInformationMessage} href={LINKS.paidThroughMoreInformation} />
          </AutokasszaInfoContainer>
        </DoubleColumn>
        <DoubleColumn>
          <IsAutokasszaCheckbox submitting={submitting} setBalanceAccordionOpen={setBalanceAccordionOpen} />
        </DoubleColumn>
      </CollapseGrid2ColumnWithMaxHeight>
    </FormSection>
  )
}

PureEditorFormBalanceSection.propTypes = {
  bankProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      autokassza_available: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  detailsData: PropTypes.shape({
    balances: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.string.isRequired,
        value_date: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }) as React.Validator<EditorFormBalanceSectionProps['detailsData']>,
  isEdit: PropTypes.bool.isRequired,
  provider: PropTypes.number,
}

export const EditorFormBalanceSection = connect((state: Store) => ({
  bankProviders: state.dashboard.common.bank_providers,
}))(PureEditorFormBalanceSection)

EditorFormBalanceSection.displayName = 'EditorFormBalanceSection'
