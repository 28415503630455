import React from 'react'

import styled from 'styled-components'

function BaseLeftArrowIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49995 12.8L14.2 18.4C14.6 18.8 15.2 18.8 15.6 18.4C16 18 16 17.4 15.6 17L10.7 12L15.6 7.00005C16 6.60005 16 6.00005 15.6 5.60005C15.4 5.40005 15.2 5.30005 14.9 5.30005C14.6 5.30005 14.4 5.40005 14.2 5.60005L8.49995 11.2C8.09995 11.7 8.09995 12.3 8.49995 12.8C8.49995 12.7 8.49995 12.7 8.49995 12.8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const LeftArrowIcon = styled(BaseLeftArrowIcon)``
