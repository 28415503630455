import { call, put, select, takeLatest } from 'redux-saga/effects'

import { AMPLITUDE_EVENTS, getActiveCompanyId, getErrorMessage, getFormErrors, sendAmplitudeData } from '@helpers'

import * as dashboardApi from '../dashboard/api'
import actions from './actions'
import * as api from './api'

function* loadLiquidityDataSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const {
      data: { weeks, ...results },
    } = yield call(api.loadLiquidityData, companyId)
    yield put(actions.loadData.success(results))
    yield call(resolve, weeks)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    // yield put(actions.loadData.failure(errorMsg))
    yield call(reject, errorMsg)
  }
}

function* saveLiquidityDataSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const {
      data: { weeks, ...results },
    } = yield call(api.saveLiquidityData, companyId, payload)
    yield put(actions.saveData.success(results))
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.LIQUIDITY_WEEKLY_DATA_SAVE)
    yield call(resolve, weeks)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* loadLiquidityInvoicesSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.loadLiquidityInvoices, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updateInvoicePlannedPaymentDateSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateInvoice, companyId, payload)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.LIQUIDITY_PLANNED_PAYMENT_CHANGE)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updateIncomePlannedPaymentDateSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateIncome, companyId, payload)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.LIQUIDITY_PLANNED_INCOME_CHANGE, payload.amplitudeEventParams)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// Predicted invoices
function* loadLiquidityPredictedInvoicesSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.loadLiquidityPredictedInvoices, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// SALARY
function* createMonthlySalarySaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(dashboardApi.createMonthlySalary, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* updateMonthlySalarySaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(dashboardApi.updateMonthlySalary, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* removeMonthlySalarySaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(dashboardApi.removeMonthlySalary, companyId, payload)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* removePredictedInvoiceSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removePredictedInvoice, companyId, payload)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.LIQUIDITY_REMOVE_PREDICTION)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// Predicted incomes
function* loadLiquidityPredictedIncomesSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.loadLiquidityPredictedIncomes, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}
// liquidity incomes process
function* startLiquidityIncomesProcessSaga({ meta: { resolve, reject }, payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const { data: response } = yield call(payload.process.start, companyId)
    const { weeks, ...results } = response
    yield put(actions.loadData.success(results))
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}
function* abortLiquidityIncomesProcessSaga({ payload }) {
  if (payload.process) {
    yield call(payload.process.stop)
  }
}

function* loadLiquidityPredictedWagesSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.loadLiquidityPredictedWages, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// salary
function* removePredictedSalarySaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removePredictedSalary, companyId, payload)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.LIQUIDITY_REMOVE_SALARY_PREDICTION)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// TAXES (wages)
function* loadLiquidityTaxesSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.loadLiquidityTaxes, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updateTaxSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.updateTax, companyId, payload)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* createMonthlyTaxSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(dashboardApi.createMonthlyTax, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* updateMonthlyTaxSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(dashboardApi.updateMonthlyTax, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* removeMonthlyTaxSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(dashboardApi.removeMonthlyTax, companyId, payload)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* removePredictedTaxSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removePredictedTax, companyId, payload)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.LIQUIDITY_REMOVE_TAX_PREDICTION)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.loadData.REQUEST, loadLiquidityDataSaga)
  yield takeLatest(actions.saveData.REQUEST, saveLiquidityDataSaga)
  yield takeLatest(actions.loadInvoices.REQUEST, loadLiquidityInvoicesSaga)
  yield takeLatest(actions.updateInvoicePlannedPaymentDate.REQUEST, updateInvoicePlannedPaymentDateSaga)
  yield takeLatest(actions.updateIncomePlannedPaymentDate.REQUEST, updateIncomePlannedPaymentDateSaga)
  yield takeLatest(actions.loadPredictedIncomes.REQUEST, loadLiquidityPredictedIncomesSaga)
  yield takeLatest(actions.loadPredictedInvoices.REQUEST, loadLiquidityPredictedInvoicesSaga)
  yield takeLatest(actions.removePredictedInvoice.REQUEST, removePredictedInvoiceSaga)
  // liquidity incomes process
  yield takeLatest(actions.startLiquidityIncomesProcess.REQUEST, startLiquidityIncomesProcessSaga)
  yield takeLatest(actions.abortLiquidityIncomesProcess.REQUEST, abortLiquidityIncomesProcessSaga)
  // liquidity salary (wages)
  yield takeLatest(actions.loadPredictedWages.REQUEST, loadLiquidityPredictedWagesSaga)
  yield takeLatest(actions.removePredictedSalary.REQUEST, removePredictedSalarySaga)
  yield takeLatest(actions.createMonthlySalary.REQUEST, createMonthlySalarySaga)
  yield takeLatest(actions.updateMonthlySalary.REQUEST, updateMonthlySalarySaga)
  yield takeLatest(actions.removeMonthlySalary.REQUEST, removeMonthlySalarySaga)
  // liquidity taxes
  yield takeLatest(actions.loadTaxes.REQUEST, loadLiquidityTaxesSaga)
  yield takeLatest(actions.removePredictedTax.REQUEST, removePredictedTaxSaga)
  yield takeLatest(actions.createMonthlyTax.REQUEST, createMonthlyTaxSaga)
  yield takeLatest(actions.updateMonthlyTax.REQUEST, updateMonthlyTaxSaga)
  yield takeLatest(actions.removeMonthlyTax.REQUEST, removeMonthlyTaxSaga)
  yield takeLatest(actions.updateTax.REQUEST, updateTaxSaga)
}
