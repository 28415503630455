import React from 'react'

import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { bankAccountNumberTransform, taxNumberTransform } from '@helpers'

import { Button, Collapse, ReactHookFormRecommendationTextField, ReactHookFormTextField } from '@components/ui'

import { CopyPartnerDetailsButton } from './CopyPartnerDetailsButton'

import { SPACING } from '@oldComponents/forms/DetailsForm/styles'
import partnerMessages from '@oldComponents/PartnerEditor/formMessages'

const StyledButton = styled(Button)`
  margin-top: 5px;
`

const ButtonContainer = styled.div`
  margin-top: calc(1.2rem + 6px);
  height: 36px;
  text-align: right;
`

const StyledGrid = styled(Grid)`
  align-self: flex-start;
`

function isInvoicePartnerDetailsFilled(data: InvoiceDetailsFormInitialValues): boolean {
  if (!data.partner_id) {
    return false
  }

  const fields: Array<keyof InvoiceDetailsFormInitialValues> = [
    'partner_account_number',
    'partner_address',
    'partner_city',
    'partner_country',
    'partner_name',
    'partner_tax_number',
    'partner_zip_code',
  ]

  return fields.some(field => Boolean(data[field]))
}

interface ControlledPartnerDetailsBlockProps {
  disabled: boolean
  isAdvancedAccountingAvailableForUser: boolean
  isEdit: boolean
  recommendations: Recommendations | undefined
}

export function ControlledPartnerDetailsFields({
  disabled,
  isAdvancedAccountingAvailableForUser,
  isEdit,
  recommendations,
}: ControlledPartnerDetailsBlockProps) {
  const { formatMessage } = useIntl()

  const {
    formState: { errors, isSubmitSuccessful, isSubmitted },
    getValues,
  } = useFormContext<InvoiceDetailsFormInitialValues>()
  const values = getValues()
  const [isCopyVisible] = React.useState(!isEdit || isInvoicePartnerDetailsFilled(values))
  const [isExpanded, setExpanded] = React.useState(
    isAdvancedAccountingAvailableForUser || !isInvoicePartnerDetailsFilled(values)
  )
  const isExpandedRef = React.useRef(isExpanded)

  React.useEffect(() => {
    const partnerNameError = errors && errors.partner_name
    if (!isExpandedRef.current && isSubmitted && !isSubmitSuccessful && partnerNameError) {
      setExpanded(true)
      isExpandedRef.current = true
    }
  }, [errors, isSubmitted, isSubmitSuccessful])

  return (
    <>
      <StyledButton
        onClick={() => {
          setExpanded(!isExpanded)
          isExpandedRef.current = !isExpanded
        }}
        size="small"
        type="button"
        variant="primaryText"
      >
        {isExpanded ? (
          <FormattedMessage id="form.partnerBlock.toggle.close" defaultMessage="Részletek elrejtése" />
        ) : (
          <FormattedMessage id="form.partnerBlock.toggle.open" defaultMessage="Részletek mutatása" />
        )}
      </StyledButton>
      <Collapse isExpanded={isExpanded}>
        <Grid container spacing={SPACING}>
          <Grid item xs={isCopyVisible ? 12 : 8}>
            <ReactHookFormTextField
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerProviderNameLabel)}
              name="partner_name"
              required
            />
          </Grid>
          {!isCopyVisible && (
            <StyledGrid item xs={4}>
              <ButtonContainer>
                <CopyPartnerDetailsButton />
              </ButtonContainer>
            </StyledGrid>
          )}
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={8}>
            <ReactHookFormRecommendationTextField
              disabled={disabled}
              label={formatMessage(partnerMessages.accountNumberLabel)}
              name="partner_account_number"
              recommendations={recommendations?.bank_account}
              transform={bankAccountNumberTransform} // TODO formatted value, should we keep or parse it?
            />
          </Grid>
          <Grid item xs={4}>
            <ReactHookFormRecommendationTextField
              disabled={disabled}
              name="partner_tax_number"
              label={formatMessage(partnerMessages.taxNumberLabel)}
              recommendations={recommendations?.tax_number}
              transform={taxNumberTransform} // TODO formatted value, should we keep or parse it?
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <ReactHookFormTextField
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerCountryLabel)}
              name="partner_country"
            />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormTextField
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerZipCodeLabel)}
              name="partner_zip_code"
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <ReactHookFormTextField
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerCityLabel)}
              name="partner_city"
            />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormTextField
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerAddressLabel)}
              name="partner_address"
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  )
}
