import React from 'react'

export default function CommentExclamationIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M10 17.5c5.524 0 10-3.637 10-8.125 0-4.488-4.476-8.125-10-8.125-5.523 0-10 3.637-10 8.125 0 1.762.691 3.39 1.863 4.723-.074.957-.445 1.808-.836 2.457a7.042 7.042 0 0 1-.785 1.066l-.05.055-.012.012a.625.625 0 0 0 .445 1.066c1.121 0 2.25-.348 3.188-.754.894-.39 1.656-.855 2.12-1.195 1.243.449 2.618.699 4.067.699V17.5Zm0-13.125c.52 0 .938.418.938.938v4.375c0 .519-.418.937-.938.937a.935.935 0 0 1-.937-.938V5.314c0-.52.418-.938.937-.938Zm1.25 8.75c0 .691-.559 1.25-1.25 1.25s-1.25-.559-1.25-1.25.559-1.25 1.25-1.25 1.25.559 1.25 1.25Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
