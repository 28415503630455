import React from 'react'
import PropTypes from 'prop-types'

import styled, { css, DefaultTheme } from 'styled-components'

import { unreachable } from '@helpers'

import { QUICK_TYPOGRAPHY } from '@constants'

import { CommentExclamationIcon, InfoIcon, LightbulbOnIcon } from '../svgIcons'
import { Typography, TypographyProps } from '../Typography'
import { INFORMATIONAL_MESSAGE_VARIANTS } from './constant'

export type InformationalMessageVariant = ConstObjectValues<typeof INFORMATIONAL_MESSAGE_VARIANTS>

function getVariantStyles(theme: DefaultTheme, variant: InformationalMessageVariant) {
  switch (variant) {
    case INFORMATIONAL_MESSAGE_VARIANTS.error:
      return css`
        background-color: ${theme.colors.error[30]};
        color: ${theme.colors.gray[0]};
      `
    case INFORMATIONAL_MESSAGE_VARIANTS.info:
      return css`
        background-color: ${theme.colors.common.demoBlue};
        color: ${theme.colors.gray[100]};
        & > svg {
          color: ${theme.colors.blue[40]};
        }
      `
    case INFORMATIONAL_MESSAGE_VARIANTS.warning:
      return css`
        background-color: ${theme.colors.common.warningMessageBackground};
        color: ${theme.colors.gray[175]};
      `

    case INFORMATIONAL_MESSAGE_VARIANTS.default:
      return css`
        background-color: ${theme.colors.gray[20]};
        color: ${theme.colors.gray[70]};
      `
    default:
      return unreachable(variant)
  }
}

function getIconComponent(variant: InformationalMessageVariant) {
  switch (variant) {
    case INFORMATIONAL_MESSAGE_VARIANTS.error:
      return CommentExclamationIcon
    case INFORMATIONAL_MESSAGE_VARIANTS.default:
    case INFORMATIONAL_MESSAGE_VARIANTS.info:
      return InfoIcon
    case INFORMATIONAL_MESSAGE_VARIANTS.warning:
      return LightbulbOnIcon
    default:
      return unreachable(variant)
  }
}

const VARIANT_SIZES: {
  [key in InformationalMessageVariant]: TypographyProps['size']
} = {
  [INFORMATIONAL_MESSAGE_VARIANTS.error]: '400-xs',
  [INFORMATIONAL_MESSAGE_VARIANTS.info]: '400-sm',
  [INFORMATIONAL_MESSAGE_VARIANTS.warning]: '400-sm',
  [INFORMATIONAL_MESSAGE_VARIANTS.default]: '400-sm',
}

const InformationalMessageDiv = styled.div.withConfig<{
  variant: InformationalMessageVariant
}>({
  shouldForwardProp: (prop, defaultValidator) => {
    return prop !== 'variant' && defaultValidator(prop)
  },
})`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  border-radius: 8px;
  padding: 12px 18px 12px 10px;

  > *:first-child {
    flex-shrink: 0;
  }

  ${({ theme, variant }) => getVariantStyles(theme, variant)}
`
interface InformationalMessageProps {
  children: StringOrMessage
  className?: string
  icon?: InformationalMessageVariant
  iconSize?: number
  typographySize?: TypographyProps['size']
  variant?: InformationalMessageVariant
}

export function InformationalMessage({
  children,
  className,
  icon,
  iconSize = 20,
  typographySize,
  variant = INFORMATIONAL_MESSAGE_VARIANTS.default,
}: InformationalMessageProps) {
  const IconComponent = getIconComponent(icon ?? variant)
  return (
    <InformationalMessageDiv variant={variant} className={className}>
      <IconComponent size={iconSize} />
      <Typography size={typographySize ?? VARIANT_SIZES[variant]}>{children}</Typography>
    </InformationalMessageDiv>
  )
}

InformationalMessage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(INFORMATIONAL_MESSAGE_VARIANTS)),
  iconSize: PropTypes.number,
  typographySize: PropTypes.oneOf(Object.keys(QUICK_TYPOGRAPHY)) as React.Validator<keyof DefaultTheme['typography']>,
  variant: PropTypes.oneOf(Object.values(INFORMATIONAL_MESSAGE_VARIANTS)),
}
