import React from 'react'

import styled from 'styled-components'

function BaseMoneyCheckPenRegularIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M4 7.5H18C18.275 7.5 18.5 7.725 18.5 8V10.8625L18.7062 10.6562C19.0687 10.2937 19.5281 10.0781 20 10.0156V8C20 6.89688 19.1031 6 18 6H4C2.89688 6 2 6.89688 2 8V16C2 17.1031 2.89688 18 4 18H12.3281L12.5156 17.2531C12.5844 16.9781 12.7094 16.7219 12.8844 16.5H4C3.725 16.5 3.5 16.275 3.5 16V8C3.5 7.725 3.725 7.5 4 7.5ZM5.75 12.5C5.33437 12.5 5 12.8344 5 13.25C5 13.6656 5.33437 14 5.75 14H12.25C12.6656 14 13 13.6656 13 13.25C13 12.8344 12.6656 12.5 12.25 12.5H5.75ZM5 10.25C5 10.6656 5.33437 11 5.75 11H15.25C15.6656 11 16 10.6656 16 10.25C16 9.83437 15.6656 9.5 15.25 9.5H5.75C5.33437 9.5 5 9.83437 5 10.25ZM21.1812 11.3656C20.6938 10.8781 19.9031 10.8781 19.4125 11.3656L18.4937 12.2844L20.7125 14.5031L21.6313 13.5844C22.1187 13.0969 22.1187 12.3063 21.6313 11.8156L21.1812 11.3656ZM13.7469 17.0312C13.6187 17.1594 13.5281 17.3187 13.4844 17.4969L13.0156 19.375C12.9719 19.5469 13.0219 19.725 13.1469 19.85C13.2719 19.975 13.45 20.025 13.6219 19.9812L15.5 19.5125C15.675 19.4688 15.8375 19.3781 15.9656 19.25L20.0031 15.2094L17.7844 12.9906L13.7469 17.0312Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const MoneyCheckPenRegularIcon = styled(BaseMoneyCheckPenRegularIcon)``
