import React from 'react'
import PropTypes from 'prop-types'

import { FormattedDate, FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'

import { EndPeriodMessage } from '@messages'

const BadgeWrapperDiv = styled.div`
  display: inline-flex;
  min-height: 42px;
  min-width: 228px;
  align-items: center;

  & + * {
    margin-left: 20px;
  }
`

const PeriodBadgeTypography = styled(Typography)`
  display: inline-flex;
  align-items: center;
  padding: 3px 9px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.secondary[10]};
  border: 1px solid ${({ theme }) => theme.colors.common.lightBadgeBlue};
`

interface InactivePeriodBadgeProps {
  startDate: string
  endDate?: string
}

export function InactivePeriodBadge({ endDate, startDate }: InactivePeriodBadgeProps) {
  return (
    <BadgeWrapperDiv>
      <PeriodBadgeTypography size="700-sm" color="blue">
        <FormattedMessage
          id="company.inactivePeriod"
          defaultMessage="{startDate} - {endDate}"
          values={{
            startDate: <FormattedDate value={new Date(startDate)} />,
            endDate: endDate ? <FormattedDate value={new Date(endDate)} /> : EndPeriodMessage,
          }}
        />
      </PeriodBadgeTypography>
    </BadgeWrapperDiv>
  )
}

InactivePeriodBadge.propTypes = {
  endDate: PropTypes.string,
  startDate: PropTypes.string.isRequired,
}
