import React from 'react'

import styled from 'styled-components'

import { FlexRow } from '@components/ui/Wrappers'

const PaymentHeaderContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
`
const InnerWrapper = styled(FlexRow)<{ $gap: number }>`
  gap: ${({ $gap }) => $gap}px;
`

const DatePickerSizer = styled.div`
  width: 160px;
`
const PaidThroughSelectSizer = styled.div`
  width: 268px;
`

const PaymentFooterContainer = styled(FlexRow)`
  margin-top: 24px;
  justify-content: space-between;
`

export interface PaymentHeaderProps {
  button: JSX.Element
  className?: string
  datepicker: JSX.Element
  paidThroughBalance?: JSX.Element
  paidThroughSelect: JSX.Element
}

export function PaymentHeader({
  button,
  className,
  datepicker,
  paidThroughBalance,
  paidThroughSelect,
}: PaymentHeaderProps) {
  return (
    <PaymentHeaderContainer className={className}>
      <InnerWrapper $gap={40}>
        <InnerWrapper $gap={20}>
          <DatePickerSizer>{datepicker}</DatePickerSizer>
          <PaidThroughSelectSizer>{paidThroughSelect}</PaidThroughSelectSizer>
        </InnerWrapper>
        {paidThroughBalance}
      </InnerWrapper>

      {button}
    </PaymentHeaderContainer>
  )
}

interface PaymentFooterProps {
  checkbox: JSX.Element
  totalAmount: JSX.Element
}

export function PaymentFooter({ checkbox, totalAmount }: PaymentFooterProps) {
  return (
    <PaymentFooterContainer>
      <div>{checkbox}</div>
      {totalAmount}
    </PaymentFooterContainer>
  )
}
