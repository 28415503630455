import React from 'react'

import styled from 'styled-components'

function BaseCircleArrowRegularIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M18.125 10C18.125 12.1549 17.269 14.2215 15.7452 15.7452C14.2215 17.269 12.1549 18.125 10 18.125C7.84512 18.125 5.77849 17.269 4.25476 15.7452C2.73102 14.2215 1.875 12.1549 1.875 10C1.875 7.84512 2.73102 5.77849 4.25476 4.25476C5.77849 2.73102 7.84512 1.875 10 1.875C12.1549 1.875 14.2215 2.73102 15.7452 4.25476C17.269 5.77849 18.125 7.84512 18.125 10ZM0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10ZM11.2891 14.1016L14.7266 10.6641C15.0938 10.2969 15.0938 9.70312 14.7266 9.33984L11.2891 5.90234C10.9219 5.53516 10.3281 5.53516 9.96484 5.90234C9.60156 6.26953 9.59766 6.86328 9.96484 7.22656L11.8008 9.0625H5.9375C5.41797 9.0625 5 9.48047 5 10C5 10.5195 5.41797 10.9375 5.9375 10.9375H11.8008L9.96484 12.7734C9.59766 13.1406 9.59766 13.7344 9.96484 14.0977C10.332 14.4609 10.9258 14.4648 11.2891 14.0977V14.1016Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CircleArrowRegularIcon = styled(BaseCircleArrowRegularIcon)``
