import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController, useFormContext } from 'react-hook-form'

import { isFieldHighlighted } from '@helpers'

import { RecommendationSelect } from './elements'
import { ReactHookFormTextFieldProps } from './ReactHookFormTextField'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles)

interface ReactHookFormRecommendationTextFieldProps extends ReactHookFormTextFieldProps {
  recommendations: string[] | undefined
}

export function ReactHookFormRecommendationTextField({
  className,
  disabled = false,
  endAdornment,
  highlighted = false,
  isLabelHighlighted = false,
  label,
  name,
  placeholder,
  recommendations = [],
  required = false,
  transform,
  type,
  ...rest
}: ReactHookFormRecommendationTextFieldProps) {
  const classes = useStyles()
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({ control, name })

  const hasError = !!error
  const isFieldDisabled = disabled || isSubmitting

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(classes.selectRoot, 'form-control', { 'form-control-error': hasError })}
      error={hasError}
      disabled={isFieldDisabled}
      required={required}
    >
      {label && (
        <InputLabel
          shrink
          className={cx(classes.bootstrapFormLabel, { [classes.highlightedLabel]: isLabelHighlighted })}
          htmlFor={name}
        >
          {label}
        </InputLabel>
      )}
      <div className={classes.selectInput}>
        {recommendations.length > 0 ? (
          <RecommendationSelect
            id={field.name}
            recommendations={recommendations}
            field={field}
            className={cx({ error: hasError })}
            disabled={isFieldDisabled}
            transform={transform}
          />
        ) : (
          <Input
            id={field.name}
            fullWidth
            disableUnderline
            classes={{
              root: classes.bootstrapRoot,
              input: cx(classes.bootstrapInput, { highlighted: isFieldHighlighted(highlighted, field.value) }),
              error: classes.bootstrapError,
            }}
            {...field}
            disabled={isFieldDisabled}
            {...rest}
            {...(transform && {
              value: transform.input(field.value),
              onChange: event => {
                field.onChange(transform.output(event.target.value))
                transform.positioner?.(event, transform.output(event.target.value), transform.input(field.value))
              },
            })}
          />
        )}
      </div>
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

ReactHookFormRecommendationTextField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  highlighted: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  recommendations: PropTypes.arrayOf(PropTypes.string.isRequired),
  required: PropTypes.bool,
  transform: PropTypes.shape({
    input: PropTypes.func.isRequired,
    output: PropTypes.func.isRequired,
    positioner: PropTypes.func,
  }),
}
