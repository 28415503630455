import React from 'react'

import { usePortal } from '@contexts'

import { AbsoluteArtifactPreviewControlsDiv, ArtifactControlsDiv } from './elements'

interface PortalAnchorElementProps {
  postItControls?: React.ReactNode
}

export function PortalAnchorElement({ postItControls }: PortalAnchorElementProps) {
  const { setPortalAnchorEl } = usePortal()
  return (
    <AbsoluteArtifactPreviewControlsDiv>
      {postItControls ? <div>{postItControls}</div> : <div />}
      <ArtifactControlsDiv ref={setPortalAnchorEl} />
    </AbsoluteArtifactPreviewControlsDiv>
  )
}
