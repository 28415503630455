import React from 'react'
import PropTypes from 'prop-types'

import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import { getDefaultCurrencyName } from '@helpers'

import { BackendReportResponse, ReportNames } from '../types'
import {
  LoadingBalanceReport,
  LoadingChat,
  LoadingMonthlyChart,
  LoadingSetupTaskList,
  LoadingTaxationAamReport,
  LoadingTaxationAtalanyReport,
  LoadingTaxationKataReport,
  LoadingTaxationTaskReport,
  LoadingTaxationTaxCalculationReport,
  LoadingVatPositionReport,
  LoadingWidget,
  LoadingWorkoutReport,
} from './LoadingReports'

// map reports name with components (dynamic imports with specified loading component)
const REPORT_COMPONENT_SOURCES = {
  [ReportNames.BANK_TRANSACTIONS]: Loadable({
    loader: () => import('../BankTransactionsReportAccordion'),
    loading: LoadingBalanceReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.SETUP_TASK_LIST]: Loadable({
    loader: () => import('../SetupTaskList'),
    loading: LoadingSetupTaskList,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.VAT_POSITION]: Loadable({
    loader: () => import('../VatPositionReportAccordion'),
    loading: LoadingVatPositionReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.WORKOUT]: Loadable({
    loader: () => import('../WorkoutReportAccordion'),
    loading: LoadingWorkoutReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.TAXATION_AAM]: Loadable({
    loader: () => import('../TaxationAamAccordion'),
    loading: LoadingTaxationAamReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.TAXATION_KATA]: Loadable({
    loader: () => import('../TaxationKataAccordion'),
    loading: LoadingTaxationKataReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.TAXATION_ATALANY]: Loadable({
    loader: () => import('../TaxationAtalanyAccordion'),
    loading: LoadingTaxationAtalanyReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.TAXATION_TAX_CALCULATION]: Loadable({
    loader: () => import('../TaxationTaxCalculationAccordion'),
    loading: LoadingTaxationTaxCalculationReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.TAXATION_TASK]: Loadable({
    loader: () => import('../TaxationTasks/TaxationTask'),
    loading: LoadingTaxationTaskReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  //* utility cards
  [ReportNames.SPLITTER]: Loadable({
    loader: () => import('./Splitter'),
    loading: LoadingTaxationTaskReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.DISCLAIMER]: Loadable({
    loader: () => import('./Disclaimer'),
    loading: LoadingTaxationTaskReport,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.KPI_WIDGET]: Loadable({
    loader: () => import('../KpiWidget'),
    loading: LoadingWidget,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.PULSE_MONTHLY_CHART]: Loadable({
    loader: () => import('../PulseMonthlyChartAccordion'),
    loading: LoadingMonthlyChart,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
  [ReportNames.QUIC_CHAT]: Loadable({
    loader: () => import('../QuickChatAccordion'),
    loading: LoadingChat,
    render: (loaded, props) => {
      const Component = loaded.default
      return <Component {...props} />
    },
  }),
} as const

interface ReportsContainerProps {
  companyCurrency: string
  reports: BackendReportResponse
  refetch: AsyncFunction
  updatedAt: string
}

function PureReportsContainer({ companyCurrency, reports, refetch, updatedAt }: ReportsContainerProps) {
  return (
    <>
      {reports.map(report => {
        const ReportComponent = REPORT_COMPONENT_SOURCES[report.name]

        if (!ReportComponent) {
          return null
        }

        return (
          <ReportComponent
            companyCurrency={companyCurrency}
            // we need to remount task list if its position is changing
            key={`${report.name}-${report.id}`}
            // @ts-expect-error due to generalisation the type of ReportComponent is not valid, and this is flagged as error here
            report={report}
            refetchReports={refetch}
            updatedAt={updatedAt}
          />
        )
      })}
    </>
  )
}

PureReportsContainer.propTypes = {
  companyCurrency: PropTypes.string.isRequired,
  reports: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired as React.Validator<
    ReportsContainerProps['reports']
  >,
  refetch: PropTypes.func.isRequired,
  updatedAt: PropTypes.string.isRequired,
}

export const ReportsContainer = connect((state: Store) => ({
  companyCurrency: getDefaultCurrencyName(state),
}))(PureReportsContainer)

ReportsContainer.displayName = 'ReportsContainer'
