import React from 'react'

import styled from 'styled-components'

function BaseSquarePollHorizontalIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M18.625 5.75A.627.627 0 0 0 18 5.125H5.5a.627.627 0 0 0-.625.625v12.5c0 .344.281.625.625.625H18a.627.627 0 0 0 .625-.625V5.75ZM18 3.25c1.379 0 2.5 1.121 2.5 2.5v12.5c0 1.379-1.121 2.5-2.5 2.5H5.5a2.502 2.502 0 0 1-2.5-2.5V5.75c0-1.379 1.121-2.5 2.5-2.5H18Zm-5 5c0 .52-.418.938-.938.938H7.689a.935.935 0 0 1-.938-.938c0-.52.418-.938.938-.938h4.375c.519 0 .937.418.937.938Zm2.813 2.813c.519 0 .937.417.937.937s-.418.938-.938.938H7.688A.935.935 0 0 1 6.75 12c0-.52.418-.938.938-.938h8.125ZM10.5 15.75c0 .52-.418.938-.938.938H7.688a.935.935 0 0 1-.938-.938c0-.52.418-.938.938-.938h1.875c.519 0 .937.418.937.938Z"
      />
    </svg>
  )
}

export const SquarePollHorizontalIcon = styled(BaseSquarePollHorizontalIcon)``
