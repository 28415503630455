import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { InformationListAccordion, Typography } from '@components/ui'

import { IncomeRegister } from '../types'
import { IncomeRegisterDownloadButton } from './IncomeRegisterDownloadButton'

const StyledInformationListAccordion = styled(InformationListAccordion)`
  /* it must have the margin here to avoid everything having a wrapper div */
  margin-top: 24px;
`

const WrapperBoxDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border-radius: 8px;
  min-height: 74px;
  display: inline-flex;
  gap: 12px;
  padding: 12px 20px;
  align-items: center;
  margin-top: 10px;
`

const ListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const IncomeRegisterDownloadMessage = (
  <FormattedMessage id="taxation.incomeRegister.label.download" defaultMessage="Bevételi nyilvántartás letöltése:" />
)

interface TaxationIncomeRegisterDownloadProps {
  data: IncomeRegister[]
}

export function TaxationIncomeRegisterDownload({ data }: TaxationIncomeRegisterDownloadProps) {
  const hasRegisterPeriods = data.length > 0
  return (
    <StyledInformationListAccordion
      title={<FormattedMessage id="taxation.incomeRegister.title" defaultMessage="Bevételi nyilvántartás" />}
      titleTag="h2"
      visibleOnRender={hasRegisterPeriods}
      withoutList
    >
      {hasRegisterPeriods ? (
        <ListWrapper>
          {data.map(({ params }, index) => (
            <li key={`register-download-item-${index}`}>
              <WrapperBoxDiv>
                <Typography color="gray-70" size="400-sm">
                  {IncomeRegisterDownloadMessage}
                </Typography>
                <IncomeRegisterDownloadButton startDate={params.start_date} endDate={params.end_date} />
              </WrapperBoxDiv>
            </li>
          ))}
        </ListWrapper>
      ) : (
        <WrapperBoxDiv>
          <Typography color="gray-70" size="400-sm">
            <FormattedMessage
              id="taxation.incomeRegister.noIncomesText"
              defaultMessage="Nincs bevételi nyilvántartásod ebben az adónemben, mert erre az időszakra nincs bevételre vonatkozó elérhető adatunk."
            />
          </Typography>
        </WrapperBoxDiv>
      )}
    </StyledInformationListAccordion>
  )
}

TaxationIncomeRegisterDownload.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      params: PropTypes.shape({
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}
