import styled from 'styled-components'

import { OutlineEffectStyles } from '@styles'

// TODO consider to remove children overwrite
export interface ButtonBaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactChild | React.ReactChild[]
  disabled?: boolean
}

export const ButtonBase = styled.button.attrs<React.ButtonHTMLAttributes<HTMLButtonElement>>(({ type = 'button' }) => ({
  type, // to avoid unexpected form submit when any instance of it is used in react-final-form
}))`
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: inline-flex;
  transition: color 250ms ease, border-color 250ms ease, background-color 250ms ease;
  white-space: nowrap;
  text-decoration: none;
  font: inherit;

  ${OutlineEffectStyles}

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    pointer-events: none;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    outline: 0;
    text-decoration: none;
  }
`
