import React from 'react'

import { ErrorCode, FileError } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'

import { CustomValidatorCodes } from '@constants'

export function UploadedFileError({ errors }: { errors: FileError[] }) {
  const codes = errors.map(({ code }) => code)
  if (codes.includes(ErrorCode['FileTooLarge'])) {
    return <FormattedMessage id="fileUpload.errors.fileTooLarge" defaultMessage="Túl nagy méretű fájl" />
  }

  if (codes.includes(CustomValidatorCodes.ZERO_FILE_SIZE)) {
    return <FormattedMessage id="fileUpload.errors.zeroFileSize" defaultMessage="A fájl mérete nem lehet 0 byte" />
  }

  return <FormattedMessage id="fileUpload.errors.invalidFileType" defaultMessage="Nem támogatott formátum" />
}
