import React from 'react'
import PropTypes from 'prop-types'

import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { CloseButtonMessage } from '@messages'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    minHeight: 240,
    // width: 450,
    padding: '50px 90px 20px',
    flex: 1,
  },
  title: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 10,
  },
})

export default function PartnerError({ children, onClose }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title}>
          <FormattedMessage id="partnerDetails.error.title" defaultMessage="Sajnáljuk" />
        </Typography>
        <Typography>
          <FormattedMessage
            id="partnerDetails.error.description"
            defaultMessage="A partner adatok betöltése sikertelen."
          />
        </Typography>
      </div>
      <Typography color="error" variant="caption">
        {children}
      </Typography>

      <Button color="primary" variant="contained" onClick={onClose}>
        {CloseButtonMessage}
      </Button>
    </div>
  )
}

PartnerError.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
}
