import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { formatDate } from '@helpers'

import { CALENDAR_LOCALE_FORMATS } from '@constants'

import { datepickerStyles } from '@styles'
import calendarMessages from '@oldComponents/DashboardDatePicker/messages'

const useStyles = makeStyles(datepickerStyles)

// TODO ask design/business about this: need to overwrite CALENDAR_LOCALE_FORMATS
const INPUT_FORMAT = {
  hu: 'yyyy. MM. DD.',
  en: 'DD/MM/yyyy',
}

interface ReactHookFormDatePickerFieldProps {
  defaultView?: 'month' | 'year' | 'decade'
  disabled?: boolean
  formats?: Record<Locale, string>
  label: React.ReactNode
  locale: Locale
  name: string
  required?: boolean
}

function PureReactHookFormDatePickerField({
  defaultView = 'year',
  disabled,
  formats = INPUT_FORMAT,
  label,
  locale,
  name,
  required = false,
}: ReactHookFormDatePickerFieldProps) {
  const [open, setOpen] = React.useState<DateTimePicker.Open | undefined>(undefined)
  const {
    field: { onChange, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({ name })
  const classes = useStyles()
  const { formatMessage } = useIntl()

  function onToggleHandler() {
    setOpen(open => (open ? undefined : 'date'))
  }

  function onChangeHandler(date?: Date) {
    onChange(date ? formatDate(date) : null)
  }

  return (
    <FormControl fullWidth margin="normal" className={classes.datepickerRoot} error={!!error} required={required}>
      <InputLabel shrink className={classes.datepickerLabel}>
        {label}
      </InputLabel>
      <DateTimePicker
        value={value ? new Date(value) : undefined}
        onChange={onChangeHandler}
        // @ts-expect-error - this property is not exist in TS props
        headerFormat={CALENDAR_LOCALE_FORMATS.header[locale]}
        format={formats[locale]}
        time={false}
        footer={false}
        views={['month', 'year', 'decade']}
        defaultView={defaultView}
        inputProps={{
          readOnly: true,
          onClick: onToggleHandler,
        }}
        className={cx(classes.datepicker, { [classes.datepickerError]: error })}
        open={open}
        onToggle={onToggleHandler}
        messages={
          {
            moveBack: formatMessage(calendarMessages.moveBack),
            moveForward: formatMessage(calendarMessages.moveForward),
            dateButton: formatMessage(calendarMessages.dateButton),
          } as DateTimePicker.DateTimePickerMessages
        }
        disabled={disabled || isSubmitting}
      />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

PureReactHookFormDatePickerField.propTypes = {
  disabled: PropTypes.bool,
  defaultView: PropTypes.oneOf(['month', 'year', 'decade']) as React.Validator<
    ReactHookFormDatePickerFieldProps['defaultView']
  >,
  formats: PropTypes.shape({
    hu: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
  }),
  label: PropTypes.node.isRequired,
  locale: PropTypes.oneOf(['hu', 'en']).isRequired as React.Validator<Locale>,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export const ReactHookFormDatePickerField = connect((state: Store) => ({
  locale: state.locale.language,
}))(PureReactHookFormDatePickerField)

ReactHookFormDatePickerField.displayName = 'ReactHookFormDatePickerField'
