import React from 'react'
import { node, string } from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import cx from 'classnames'

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 14,
    lineHeight: 1.4,
    display: 'flex',
    gap: 10,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}))

export default function WarningText({ children, className }) {
  const classes = useStyles()
  return (
    <Typography className={cx(classes.root, className)} component="div" align="left">
      <InfoOutlinedIcon color="secondary" fontSize="small" />
      {children}
    </Typography>
  )
}

WarningText.propTypes = {
  children: node.isRequired,
  className: string,
}
