import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!FETCH_POST_IT',
  '!CREATE_POST_IT',
  '!UPDATE_POST_IT',
  '!REMOVE_POST_IT',
  '!FETCH_INVOICE_POST_ITS',
] as const

export default serviceActionsGenerator<typeof actionTypes>('post-it', actionTypes)
