import 'react-widgets/dist/css/react-widgets.css' // TODO talán ez itt nem a legjobb helyen van

import React from 'react'
import PropTypes from 'prop-types'

import { ButtonProps, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import Calendar, { CalendarMessages, CalendarView } from 'react-widgets/lib/Calendar'

import { formatDate } from '@helpers'

import SecondaryLinkButton from '@oldComponents/ui/buttons/SecondaryLinkButton'

import calendarMessages from '../DashboardDatePicker/messages'
import styles from './styles'

const useStyles = makeStyles(styles)

function findParentByTagName(tagName: string, target: Element) {
  let currentTarget = target
  let iteration = 0
  while (currentTarget.tagName !== tagName && iteration < 5) {
    currentTarget = (currentTarget.parentNode as Element) || currentTarget
    iteration++
  }
  return currentTarget
}

function getAvailableCalendarValue(value: string, minDate: Date) {
  // to avoid navigateNext button disabled bug: https://github.com/jquense/react-widgets/issues/513
  const valueDate = new Date(value)
  return valueDate < minDate ? minDate : valueDate
}

export interface LiquidityDatepickerProps {
  locale: Locale
  onChange: (value: string) => void
  disabled?: boolean
  defaultView?: CalendarView
  views?: CalendarView[]
  value: string
  ButtonProps: Pick<ButtonProps, 'children'> & Partial<Omit<ButtonProps, 'children'>>
  onOpen?: VoidFunction
  useMinDefault?: boolean
}

function PureLiquidityDatepicker({
  locale,
  onChange,
  disabled = false,
  defaultView = 'month',
  views = ['month', 'year', 'decade'],
  value,
  ButtonProps,
  onOpen,
  useMinDefault = false,
}: LiquidityDatepickerProps) {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<Element>>(null)
  const { formatMessage } = useIntl()
  const classes = useStyles()

  function handleOpen(event: React.MouseEvent<HTMLButtonElement>) {
    if (Object.prototype.hasOwnProperty.call(event, 'currentTarget')) {
      setAnchorEl(event.currentTarget) // almost every modern browser support it
    } else {
      setAnchorEl(findParentByTagName('BUTTON', event.target as Element))
    }
    onOpen?.()
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function onDateChangeHandler(value: Date | undefined) {
    onChange(formatDate(value))
    handleClose()
  }

  const calendarMinValue = new Date()
  const calendarValue = getAvailableCalendarValue(value, calendarMinValue)

  return (
    <>
      <SecondaryLinkButton
        className={classes.datepickerButton}
        onClick={handleOpen}
        disabled={disabled}
        {...ButtonProps}
      />

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <div className={classes.datepickerRoot}>
          <Calendar
            value={calendarValue}
            {...(useMinDefault ? {} : { min: calendarMinValue })}
            onChange={onDateChangeHandler}
            headerFormat={locale === 'hu' ? 'YYYY. MMMM' : 'MMMM, YYYY'}
            // format={locale === 'hu' ? 'YYYY. MM. DD.' : 'MM. DD. YYYY'}
            // time={false}
            footer={false}
            views={views}
            defaultView={defaultView}
            // className={classes.datepicker} // TODO invalid prop?
            messages={
              {
                moveBack: formatMessage(calendarMessages.moveBack),
                moveForward: formatMessage(calendarMessages.moveForward),
                dateButton: formatMessage(calendarMessages.dateButton),
              } as CalendarMessages
            }
          />
        </div>
      </Popover>
    </>
  )
}

PureLiquidityDatepicker.propTypes = {
  locale: PropTypes.oneOf(['hu', 'en']).isRequired as React.Validator<Locale>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultView: PropTypes.oneOf(['month', 'year', 'decade']) as React.Validator<CalendarView | undefined>,
  views: PropTypes.arrayOf(PropTypes.string.isRequired) as React.Validator<CalendarView[] | undefined>,
  value: PropTypes.string.isRequired,
  ButtonProps: PropTypes.shape({
    children: PropTypes.node.isRequired,
  }).isRequired,
  onOpen: PropTypes.func,
  useMinDefault: PropTypes.bool,
}

export const LiquidityDatepicker = connect((state: Store) => ({
  locale: state.locale.language,
}))(PureLiquidityDatepicker)

LiquidityDatepicker.displayName = 'LiquidityDatepicker'
