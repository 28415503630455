import React from 'react'

import { connect } from 'react-redux'

import { isAdmin } from '@helpers'

import { SetCompanyTypeDialog } from '@components/dialogs'

type CompanyDialogType = Nullable<'setCompanyType'>

interface CompanyDialogsControllerProps {
  company: Nullable<Company>
}

/**
 * Controller component for any dialog display dependent on the selected company without any page context
 *
 * @param {CompanyDialogsControllerProps} { company }
 */
function PureCompanyDialogsController({ company }: CompanyDialogsControllerProps) {
  const [openedDialog, setOpenedDialog] = React.useState<CompanyDialogType>(null)
  React.useEffect(() => {
    // check if company has set company_type and user is admin
    const hasCompanyType = Boolean(company?.company_type !== null)
    const isAdminUser = isAdmin(company?.role)
    // if company does not have a company_type open the unclosable SetCompanyTypeDialog
    if (!hasCompanyType && isAdminUser) {
      setOpenedDialog('setCompanyType')
    }
  }, [company?.company_type, company?.role])

  React.useEffect(() => {
    //! Note: due to current known company change issues we might end up with the dialog open for the wrong company
    // if company has changed in the background and the dialog was active already, ensure we're still good
    const hasCompanyType = Boolean(company?.company_type !== null)
    const isAdminUser = isAdmin(company?.role)

    if (openedDialog && (!isAdminUser || hasCompanyType)) {
      setOpenedDialog(null)
    }
  }, [company?.company_type, company?.role, openedDialog])

  function onClose() {
    setOpenedDialog(null)
  }

  return (
    <>
      <SetCompanyTypeDialog isOpen={openedDialog === 'setCompanyType'} onSuccess={onClose} />
    </>
  )
}

const CompanyDialogsController = connect((state: Store) => ({
  company: state.auth.company.data,
}))(PureCompanyDialogsController)
CompanyDialogsController.displayName = 'CompanyDialogsController'

export default CompanyDialogsController
