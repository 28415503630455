import React from 'react'

export default function ArrowRotateRightIcon({ className, size = 18 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.438 1.406v5.625a.842.842 0 0 1-.844.844h-5.625a.844.844 0 0 1 0-1.688h3.533C13.458 4.16 11.338 2.813 9 2.813A6.156 6.156 0 0 0 2.812 9 6.176 6.176 0 0 0 9 15.188c1.445 0 2.85-.51 3.959-1.433a.844.844 0 1 1 1.081 1.296 7.899 7.899 0 0 1-5.045 1.82c-4.34 0-7.87-3.53-7.87-7.871 0-4.342 3.53-7.871 7.871-7.871 2.808 0 5.358 1.527 6.754 3.835V1.406a.844.844 0 0 1 1.688 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
