import React from 'react'
import PropTypes from 'prop-types'

import { Cell, Column, MetaBase, PluginHook, Row, TableCellProps, TableOptions, useTable } from 'react-table'

import { TableRowExpanderProvider } from '@contexts'

import { NoDataCell, Table, TableBody, TableCell, TableRow } from '@components/ui'

import TableHeadArea from '../TableHeadArea'
import { ExpandableTableRow } from './elements'

import { TableNoDataMessage } from '@messages'

interface ExpandableTableProps<RowData extends object, SubComponentData> {
  columns: Column<RowData>[]
  data: RowData[]
  layout?: React.CSSProperties['tableLayout']
  SubComponent: React.ComponentType<{ data: SubComponentData }>
  subComponentDataGetter: (rowValues: Row<RowData>['values']) => SubComponentData
  useTablePlugins?: PluginHook<RowData>[]
  useTableProps?: Omit<TableOptions<RowData>, 'data' | 'columns'>
  variant?: 'inner' | 'default'
}

//! SimpleTable with isExpandable prop can not be used here because it cant handle subComponent and sub headers
/**
 * A generic table with expandable rows. Subrows have their own component and headers.
 *
 * @template RowData - Type of the row data.
 * @template SubComponentData - Type of the sub component data.
 * @param {ExpandableTableProps<RowData, SubComponentData>} {
 *   columns, - The columns of the table.
 *   data, - The data of the table.
 *   layout, - The layout style of the table.
 *   SubComponent, - The sub component to render for each row. It will receive a `data` prop using the `subComponentDataGetter` function.
 *   subComponentDataGetter, - The function to get the sub component's data prop.
 *   useTablePlugins = [], - The plugins to use for react-table.
 *   useTableProps, - The props to use for react-table.
 *   variant, - The variant style of the table.
 * }
 */
export default function ExpandableTable<RowData extends object, SubComponentData>({
  columns,
  data,
  layout,
  SubComponent,
  subComponentDataGetter,
  useTablePlugins = [],
  useTableProps,
  variant,
}: ExpandableTableProps<RowData, SubComponentData>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
    {
      columns,
      data,
      ...useTableProps,
    },
    ...useTablePlugins
  )

  function enhancedCellGetter(props: Partial<TableCellProps>, meta: MetaBase<RowData> & { cell: Cell<RowData> }) {
    return {
      ...props,
      align: meta.cell.column.align,
      style: { width: meta.cell.column.width },
    }
  }

  return (
    <TableRowExpanderProvider>
      <Table {...getTableProps()} layout={layout} variant={variant}>
        <TableHeadArea headerGroups={headerGroups} />
        <TableBody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map(row => {
              prepareRow(row)
              const { key: rowKey, ...rowProps } = row.getRowProps()
              return (
                <ExpandableTableRow
                  {...rowProps}
                  key={rowKey}
                  rowId={row.id}
                  colSpan={row.cells.length}
                  subComponent={<SubComponent data={subComponentDataGetter(row.values)} />}
                >
                  {row.cells.map(cell => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps(enhancedCellGetter)
                    return (
                      <TableCell key={cellKey} {...cellProps}>
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </ExpandableTableRow>
              )
            })
          ) : (
            <TableRow>
              <NoDataCell colSpan={visibleColumns.length}>{TableNoDataMessage}</NoDataCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableRowExpanderProvider>
  )
}

ExpandableTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  layout: PropTypes.string,
  SubComponent: PropTypes.elementType.isRequired,
  subComponentDataGetter: PropTypes.func.isRequired,
  useTablePlugins: PropTypes.array,
  useTableProps: PropTypes.object,
  variant: PropTypes.oneOf(['inner', 'default']),
}
