import { serialize } from 'object-to-formdata'

import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_ATTACHMENT_DOWNLOAD_URL,
  API_ATTACHMENT_URL,
  API_ATTACHMENTS_URL,
  API_BACKGROUND_JOB_EXPORT_EMAIL_URL,
} from './urls'

const createAttachmentUrl = (url, { companyId, documentId, documentType }) => {
  return injectCompanyToURL(url, companyId)
    .replace('{document_type}', documentType)
    .replace('{document_id}', documentId)
}

export const fetchAttachments = (companyId, { documentId, documentType }) =>
  Api.get(createAttachmentUrl(API_ATTACHMENTS_URL, { companyId, documentType, documentId }))

export const createAttachment = (companyId, { documentId, documentType, ...data }) =>
  Api.post(createAttachmentUrl(API_ATTACHMENTS_URL, { companyId, documentType, documentId }), data, {
    timeout: 5 * 60000, // 5 * 60sec
    transformRequest: [
      function (data) {
        return serialize(data)
      },
    ],
  })

export const removeAttachment = (companyId, { documentId, attachmentId, documentType }) =>
  Api.delete(
    createAttachmentUrl(API_ATTACHMENT_URL, { companyId, documentType, documentId }).replace(
      '{attachment_id}',
      attachmentId
    )
  )

export const downloadAttachment = (companyId, { documentId, attachmentId, documentType }) =>
  Api.get(
    createAttachmentUrl(API_ATTACHMENT_DOWNLOAD_URL, { companyId, documentType, documentId }).replace(
      '{attachment_id}',
      attachmentId
    )
  )

// TODO finish this API call
export const downloadExport = ({ hash }) => Api.get(API_BACKGROUND_JOB_EXPORT_EMAIL_URL.replace('{hash}', hash))
