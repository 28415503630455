import React from 'react'

import __debounce from 'lodash/debounce'
import { useIntl } from 'react-intl'

import { TYPING_INTERVAL } from '@constants'

function addOrRemoveItem(arr: string[], allOptions: SearchFilterOption[], item: string): string[] {
  if (arr.length === 0) {
    return allOptions.filter(option => option.key !== item).map(({ key }) => key)
  }
  if (arr.includes(item)) {
    return arr.filter(val => val !== item)
  }
  return [...arr, item]
}

/**
 * Helper function to determine the state of the status filters
 */
function getSearchSelectionState(
  searchFields: CommonSearchFilters['searchFields'],
  searchFieldAllOptions: SearchFilterOption[],
  searchFieldDefaultOptions: SearchFilterOption[]
): FilterSelectionState {
  if (searchFields.length > 0) {
    if (searchFields.length === searchFieldAllOptions.length) {
      return 'all'
    }
    if (
      searchFields.length === searchFieldDefaultOptions.length &&
      searchFieldDefaultOptions.every(({ key }) => searchFields.includes(key))
    ) {
      return 'defaults'
    }
    return 'some'
  }
  return 'none'
}

interface UseSearchFilterProps {
  debounce?: boolean
  onChange: (payload: Pick<CommonSearchFilters, 'search'> | Pick<CommonSearchFilters, 'searchFields'>) => void
  onInputValueChange?: (payload: CommonSearchFilters['search']) => void
  search: CommonSearchFilters['search']
  searchFields: CommonSearchFilters['searchFields']
  searchFieldOptions: SearchFilterOption[]
  searchFieldExtraOptions: SearchFilterOption[]
}

/**
 * Common functionality helper for both SearchFilters
 */
export function useSearchFilter({
  debounce = false,
  onChange,
  onInputValueChange,
  search,
  searchFields,
  searchFieldOptions,
  searchFieldExtraOptions,
}: UseSearchFilterProps) {
  const { formatMessage } = useIntl()
  const [value, setValue] = React.useState(search)

  const searchFieldAllOptions = React.useMemo(
    () => [...searchFieldOptions, ...searchFieldExtraOptions],
    [searchFieldOptions, searchFieldExtraOptions]
  )

  const [searchSelectionState, setSearchSelectionState] = React.useState(
    getSearchSelectionState(searchFields, searchFieldAllOptions, searchFieldOptions)
  )

  React.useEffect(() => {
    if (!search) {
      // reset value when search property cleared by resetDokumentFilters action
      setValue(search)
    }
  }, [search])

  React.useEffect(() => {
    // reset selection state when searchFields property modified by resetDokumentFilters action
    setSearchSelectionState(getSearchSelectionState(searchFields, searchFieldAllOptions, searchFieldOptions))
  }, [searchFieldAllOptions, searchFieldOptions, searchFields])

  // update the value if it is out of sync  when in uncrontrolled mode (so when debounce is not used) - ie if it is changed in another component with its own state handling
  React.useEffect(() => {
    if (debounce) {
      setValue(search)
    }
  }, [search, debounce])

  // Internal handlers
  const debouncedSearchChange = React.useMemo(() => {
    return __debounce((value: string) => {
      onChange({ search: value })
      onInputValueChange?.(value)
    }, TYPING_INTERVAL)
  }, [onChange, onInputValueChange])

  function handleSearchChange(value: string, isDebounced = false) {
    if (isDebounced) {
      setValue(value)
      debouncedSearchChange(value)
    } else {
      onChange({ search: value })
      onInputValueChange?.(value)
    }
  }

  // Exposed handlers
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = event
    handleSearchChange(value, debounce)
  }

  function handleInputClear() {
    handleSearchChange('')
  }

  function handleEscClear(event: React.KeyboardEvent<HTMLInputElement>) {
    if (search && event.key === 'Escape') {
      event.stopPropagation()
      handleSearchChange('')
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newSearchFields = addOrRemoveItem(searchFields, searchFieldAllOptions, event.target.name)
    onChange({ searchFields: newSearchFields })
    setSearchSelectionState(getSearchSelectionState(newSearchFields, searchFieldAllOptions, searchFieldOptions))
  }

  function handlePresetClick(newSearchFields: string[]) {
    return function clickHandler() {
      onChange({ searchFields: newSearchFields })
      setSearchSelectionState(getSearchSelectionState(newSearchFields, searchFieldAllOptions, searchFieldOptions))
    }
  }

  function handleDeselectAll() {
    onChange({ searchFields: [] })
    setSearchSelectionState('none')
  }

  // memoized elements
  const inputPlaceholder = React.useMemo(() => {
    return searchFieldAllOptions
      .filter(option => searchFields.includes(option.key))
      .map(({ label }) => {
        // if this is a JSX element, it must be a <FormattedMessage /> component, so we need it's id and defaultMessage props, otherwise just return string
        if (typeof label === 'object') {
          return formatMessage({
            id: label.props.id,
            defaultMessage: label.props.defaultMessage,
          })
        }
        return label
      })
      .join(', ')
  }, [formatMessage, searchFieldAllOptions, searchFields])

  return {
    handleChange,
    handleDeselectAll,
    handleEscClear,
    handleInputChange,
    handleInputClear,
    handlePresetClick,
    inputPlaceholder,
    searchSelectionState,
    value: debounce ? value : search,
  }
}
