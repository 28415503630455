import React from 'react'

import styled from 'styled-components'

function BaseArrowLastLeftIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2" height="14" x="8" y="19" fill="currentColor" rx="1" transform="rotate(-180 8 19)" />
      <path
        fill="currentColor"
        d="m10.3 12.5 5.7 5.6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-4.9-5 4.9-5c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-5.7 5.6c-.4.5-.4 1.1 0 1.6 0-.1 0-.1 0 0Z"
      />
    </svg>
  )
}

export const ArrowLastLeftIcon = styled(BaseArrowLastLeftIcon)``
