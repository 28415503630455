import React from 'react'
import { func, string } from 'prop-types'

import {
  CircularProgress,
  FormControl as MuiFormControl,
  FormHelperText,
  IconButton,
  Input as MuiInput,
  InputAdornment,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import cx from 'classnames'
import __isObject from 'lodash/isObject'

// helpers
import { cancellablePromise } from '@helpers'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 40px',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
  },
  iconButton: {
    width: 28,
    height: 28,
    fontSize: 28,
    color: theme.palette.success.main,

    '&:hover': {
      backgroundColor: 'initial',
      color: theme.palette.success.dark,
    },
  },
  hasError: {
    borderColor: `${theme.palette.error.main} !important`,
  },
  errorText: {
    fontSize: 12,
    lineHeight: 1.2,
  },
}))

// overrides
const FormControl = withStyles(theme => ({
  root: {
    padding: 4,
    height: 36,
    borderRadius: 4,
    backgroundColor: theme.palette.grayscale.inputBackground,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grayscale.light,
  },
}))(MuiFormControl)
const Input = withStyles({
  input: {
    padding: 0,
    fontSize: 12,
    height: 26,
    '&::placeholder': {
      fontStyle: 'italic',
    },
  },
})(MuiInput)

const initialState = {
  loading: false,
  value: '',
  error: null,
}

function reducer(state, action) {
  switch (action.type) {
    case 'change-value':
      return {
        ...state,
        value: action.payload,
        error: null,
      }
    case 'start-create':
      return { ...state, loading: true }
    case 'failure-create':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case 'success-create':
      return initialState
    default:
      return state
  }
}

export default function BulkCreateInput({ placeholder, onCreate, generatePayload }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const cPromiseRef = React.useRef()
  const classes = useStyles()

  const { loading, value, error } = state

  const handleChange = event => {
    dispatch({ type: 'change-value', payload: event.target.value })
  }

  const handleCreate = () => {
    dispatch({ type: 'start-create' })

    const payload = generatePayload(value)

    cPromiseRef.current = cancellablePromise(onCreate(payload))
    cPromiseRef.current.promise
      .then(() => {
        dispatch({ type: 'success-create' })
      })
      .catch(errorMsg => {
        if (!__isObject(errorMsg) || !Object.prototype.hasOwnProperty.call(errorMsg, 'isCanceled')) {
          dispatch({ type: 'failure-create', payload: errorMsg }) // error parsed via getErrorMessage in saga
        }
      })
  }

  const handleCreateWithEnter = event => {
    if (event.keyCode === 13) {
      handleCreate()
    }
  }

  return (
    <div className={classes.root}>
      <FormControl fullWidth error={Boolean(error)} className={cx({ [classes.hasError]: Boolean(error) })}>
        <Input
          value={value}
          onChange={handleChange}
          disableUnderline
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton disabled={!value} onClick={handleCreate} className={classes.iconButton}>
                {loading ? <CircularProgress size={20} /> : <AddBoxIcon color="inherit" fontSize="inherit" />}
              </IconButton>
            </InputAdornment>
          }
          onKeyDown={handleCreateWithEnter}
        />
      </FormControl>
      {error && (
        <FormHelperText className={classes.errorText} error>
          {error}
        </FormHelperText>
      )}
    </div>
  )
}

BulkCreateInput.propTypes = {
  placeholder: string.isRequired,
  onCreate: func.isRequired,
  generatePayload: func.isRequired,
}
