import React from 'react'

import { FormattedMessage } from 'react-intl'

export const ExcludeCategoryLabelMessage = (
  <FormattedMessage id="dialogs.filters.labels.excludeCategory" defaultMessage="Kategóriába nem tartozó" />
)

export const IncludeCategoryLabelMessage = (
  <FormattedMessage id="dialogs.filters.labels.includeCategory" defaultMessage="Kategóriába tartozó" />
)

export const ExcludeTagLabelMessage = (
  <FormattedMessage id="dialogs.filters.labels.excludeTag" defaultMessage="Címkét nem tartalmaz" />
)

export const IncludeTagLabelMessage = (
  <FormattedMessage id="dialogs.filters.labels.includeTag" defaultMessage="Címkét tartalmaz" />
)

export const MinAmountLabelMessage = (
  <FormattedMessage id="dialogs.filters.amount.minAdornment" defaultMessage="Minimum összeg" />
)

export const MaxAmountLabelMessage = (
  <FormattedMessage id="dialogs.filters.amount.maxAdornment" defaultMessage="Maximum összeg" />
)

export const CurrencyLabelMessage = <FormattedMessage id="dialogs.filters.labels.currency" defaultMessage="Devizanem" />
