const styles = theme => ({
  boldDefaultLink: {
    ...theme.links.default,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  primaryLink: theme.links.primary,
  secondaryLink: theme.links.secondary,
  blueTheme: {
    color: theme.palette.tertiary.blue,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  purpleTheme: {
    color: theme.palette.tertiary.purple,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

export default styles
