import React from 'react'

import { MainStateActions, setDocumentLoading, updateCurrentDocument } from '../actions'
import { DocViewerContext } from '../DocViewerProvider'
import { defaultFileLoader, FileLoaderComplete, FileLoaderFuncProps } from '../helpers'
import { DocRenderer, DocViewerMainState } from '../types'
import { useRendererSelector } from './useRendererSelector'

/**
 * Custom Hook for loading the current document into context
 */
export function useDocumentLoader(): {
  state: DocViewerMainState
  dispatch: React.Dispatch<MainStateActions>
  CurrentRenderer: DocRenderer | null | undefined
} {
  const { state, dispatch } = React.useContext(DocViewerContext)
  const { currentFileNo, currentDocument, prefetchMethod } = state

  const { CurrentRenderer } = useRendererSelector()

  const documentURI = currentDocument?.uri || ''

  React.useEffect(
    () => {
      if (!currentDocument) return
      if (currentDocument.fileType !== undefined) return

      const controller = new AbortController()
      const { signal } = controller

      fetch(documentURI, {
        method: prefetchMethod || documentURI.startsWith('blob:') ? 'GET' : 'HEAD',
        signal,
        headers: state?.requestHeaders,
      })
        .then(response => {
          const contentTypeRaw = response.headers.get('content-type')
          const contentTypes = contentTypeRaw?.split(';') || []
          const contentType = contentTypes.length ? contentTypes[0] : undefined
          dispatch(
            updateCurrentDocument({
              ...currentDocument,
              fileType: contentType || undefined,
              status: response.status,
            })
          )
        })
        .catch(error => {
          if (error?.name !== 'AbortError') {
            throw error
          }
        })

      return () => {
        controller.abort()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFileNo, documentURI, currentDocument]
  )

  React.useEffect(() => {
    if (!currentDocument || CurrentRenderer === undefined) return

    const controller = new AbortController()
    const { signal } = controller

    const fileLoaderComplete: FileLoaderComplete = fileReader => {
      if (!currentDocument || !fileReader) {
        dispatch(setDocumentLoading(false))
        return
      }

      const updatedDocument = { ...currentDocument }
      if (fileReader.result !== null) {
        updatedDocument.fileData = fileReader.result
      }

      dispatch(updateCurrentDocument(updatedDocument))
      dispatch(setDocumentLoading(false))
    }

    const loaderFunctionProps: FileLoaderFuncProps = {
      documentURI,
      signal,
      fileLoaderComplete,
      headers: state?.requestHeaders,
    }

    if (CurrentRenderer === null) {
      dispatch(setDocumentLoading(false))
    } else if (CurrentRenderer.fileLoader !== undefined) {
      CurrentRenderer.fileLoader?.(loaderFunctionProps)
    } else {
      defaultFileLoader(loaderFunctionProps)
    }

    return () => {
      controller.abort()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [CurrentRenderer, currentFileNo])

  return { state, dispatch, CurrentRenderer }
}
