import React from 'react'

import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export default withStyles(theme => ({
  root: {
    padding: 0,
    fontSize: theme.typography.sizes.small,
    minHeight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.mDark,
    },
  },
}))(props => <Button {...props} variant="text" color="secondary" />)
