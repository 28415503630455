import styled from 'styled-components'

import { FlexColumn, FlexRow } from '@components/ui/Wrappers'

import { DOC_PREVIEW_CONFIG } from '@constants'

export const ArtifactFileUploadWrapper = styled(FlexColumn)`
  min-height: 100%;
  gap: 1rem;
  padding: 1rem;
`

export const PostItViewport = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ $visible }) => ($visible ? 10 : -1)};
  overflow: visible;
  padding: 5px;

  & > div {
    height: 100%;
    width: 100%;
  }
`

export const ControlsContainer = styled(FlexRow)`
  align-items: center;
  gap: 12px;
`

export const ArtifactControlsDiv = styled(FlexRow)`
  gap: 10px;
  align-items: center;
`

export const SwitchWrapperDiv = styled.div`
  display: inline-flex;
`

export const ArtifactPreviewControlsDiv = styled(FlexRow)`
  border-top: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  gap: 10px;
  padding: 5px 10px;
  height: ${DOC_PREVIEW_CONFIG.controlsBarHeight}px;
  align-items: center;
`

export const AbsoluteArtifactPreviewControlsDiv = styled(ArtifactPreviewControlsDiv)`
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
`
