import React from 'react'

import { FormattedMessage } from 'react-intl'

export const SecondaryIdTableHeaderMessage = (
  <FormattedMessage id="payment.table.th.secondaryId" defaultMessage="Másodlagos azonosító" />
)

export const PartnerTableHeaderMessage = <FormattedMessage id="payment.table.th.partnerName" defaultMessage="Partner" />

export const BankAccountNumberTableHeaderMessage = (
  <FormattedMessage id="common.table.th.accountNumber" defaultMessage="Bankszámlaszám" />
)

export const InvoiceNumberTableHeaderMessage = (
  <FormattedMessage id="common.table.th.invoiceNumber" defaultMessage="Számla sorszáma" />
)

export const AmountTableHeaderMessage = <FormattedMessage id="common.table.th.amount" defaultMessage="Összeg" />
