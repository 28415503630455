import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { IconTooltip } from '@components/ui'
import { SzamlazzHuIcon } from '@components/ui/svgIcons'
import { ManualSourceIcon } from '@oldComponents/ui/SvgIcons'

export enum PaymentSources {
  SZAMLAZZ = 'szamlazz',
}

interface InvoicePaymentSourceProps {
  data: Nullable<PaymentSources>
}

export function InvoicePaymentSource({ data }: InvoicePaymentSourceProps) {
  switch (data) {
    case PaymentSources.SZAMLAZZ:
      return (
        <IconTooltip
          PopperProps={{ disablePortal: true }}
          icon={<SzamlazzHuIcon />}
          title={
            <FormattedMessage
              id="paymentSource.tooltips.szlahu"
              defaultMessage="Ezt a fizetést a Számlázz.hu rendszeréből szinkronizáltuk"
            />
          }
        />
      )

    default:
      return (
        <IconTooltip
          PopperProps={{ disablePortal: true }}
          icon={<ManualSourceIcon />}
          title={
            <FormattedMessage
              id="paymentSource.tooltips.manual"
              defaultMessage="A QUiCK rendszerében létrehozott fizetés"
            />
          }
        />
      )
  }
}

InvoicePaymentSource.propTypes = {
  data: PropTypes.oneOf(Object.values(PaymentSources)),
}
