import { PrintableChartVariant } from '@constants'

export function getPrintedChartFileName({
  companyName,
  variant,
}: {
  companyName?: string
  variant: PrintableChartVariant
}) {
  let fileName = 'plot'
  if (companyName) {
    // clean company name
    fileName = companyName.toLowerCase().replace(/[^a-záéíóöőúüű0-9]/gu, '')
  }

  return `${fileName}_${variant}`
}
