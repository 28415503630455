import React from 'react'
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types'

import { FormattedMessage } from 'react-intl'

import ConfirmDialog from './ConfirmDialog'

import { ConfirmDialogTitleMessage } from '@messages'

const DeleteConfirmDialog = ({ onDelete, ...props }) => (
  <ConfirmDialog
    {...props}
    onSubmit={onDelete}
    submitButtonText={<FormattedMessage id="dialogs.confirm.buttons.deleteConfirm" defaultMessage="Igen, töröljük" />}
    submitButtonLoadingText={
      <FormattedMessage id="dialogs.confirm.buttons.deleteLoading" defaultMessage="Törlés folyamatban..." />
    }
    title={ConfirmDialogTitleMessage}
  />
)

DeleteConfirmDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onDelete: func.isRequired,
  loading: bool.isRequired,
  id: string.isRequired,
  children: oneOfType([node, arrayOf(node)]).isRequired,
}
export default DeleteConfirmDialog
