import styled from 'styled-components'

import { Paper } from '@components/ui'

export const GrayCardPaper = styled(Paper)`
  background-color: ${({ theme }) => theme.colors.gray[10]};
`

export const GrayPaperBox = styled(GrayCardPaper)`
  padding: 20px;
`
