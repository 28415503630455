import React from 'react'
import PropTypes from 'prop-types'

export default function AttachPaperIcon({ color = 'currentColor', size = 28 }: IconComponentProps) {
  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m12.12 23.453 1.88 1.88 6.667-6.666L14 12l-1.88 1.88 3.44 3.453H5.333V20H15.56l-3.44 3.453Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.667h10.667l8 8v16c0 1.466-1.2 2.666-2.667 2.666H7.987a2.663 2.663 0 0 1-2.654-2.666l.003-4H8v4h16V12h-6.666V5.333H8v9.334H5.341l.006-9.334A2.663 2.663 0 0 1 8 2.667Z"
        fill={color}
      />
    </svg>
  )
}

AttachPaperIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}
