import { GenerateOptionPayload, PartnerOptionData } from './types'

export function generateOption({
  account_number,
  address,
  calculation_base,
  city,
  country,
  id,
  name,
  prefix,
  tax_number,
  zip_code,
}: GenerateOptionPayload): PartnerOptionData {
  return {
    account_number,
    address: [country, zip_code, city, address].filter(f => f).join(' '),
    calculation_base,
    id,
    name,
    tax_number,
    original_address: {
      address,
      city,
      country,
      zip_code,
    },
    prefix,
  }
}
