// TODO move into own file

import React from 'react'
import { any, bool, object } from 'prop-types'

import { CircularProgress, Grid, Portal, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const loaderStyles = () => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(230,230,230, .3)',
    zIndex: 2000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
const PureAsyncLoader = ({ classes }) => (
  <Portal container={document.querySelector('body')}>
    <div className={classes.root}>
      <CircularProgress color="primary" size={50} />
    </div>
  </Portal>
)

PureAsyncLoader.propTypes = {
  classes: object.isRequired,
}

export const AsyncLoader = withStyles(loaderStyles)(PureAsyncLoader)

export const AsyncPageLoadingComponent = ({ isLoading, error }) => {
  if (error) {
    console.log('[DEBUG] page load error:', error.message)
    console.error(error)
  }
  return (
    <Grid className="page-loading-container" container justifyContent="center" alignItems="center">
      <Grid item style={{ textAlign: 'center' }}>
        {isLoading ? (
          <div>
            <div style={{ marginBottom: 30 }}>
              <CircularProgress color="primary" size={50} />
            </div>
            <Typography variant="body1">
              <FormattedMessage id="pages.loadingPage.title" defaultMessage="Oldal betöltése folyamatban..." />
            </Typography>
          </div>
        ) : error ? (
          <Typography variant="body1" component="div" color="error">
            <FormattedMessage
              id="pages.asyncLoad.error"
              defaultMessage="Az oldal betöltése sikertelen, kérjük próbálja meg újratölteni."
            />
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  )
}

AsyncPageLoadingComponent.propTypes = {
  isLoading: bool.isRequired,
  error: any,
}
