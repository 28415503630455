import React from 'react'

import { useTheme } from 'styled-components'

interface ToggleIconProps extends IconComponentProps {
  isChanged: boolean
}

export function ToggleIcon({ className, isChanged, size = 20 }: ToggleIconProps) {
  const theme = useTheme()
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 21 21"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.835 5.5h8.334l-4.175 5.25L5.835 5.5Zm-2.291-.324c1.683 2.158 4.791 6.158 4.791 6.158v5c0 .458.375.833.834.833h1.666a.836.836 0 0 0 .833-.833v-5s3.1-4 4.784-6.158a.832.832 0 0 0-.659-1.342H4.203a.831.831 0 0 0-.658 1.342Z"
        fill={theme.colors.common.menu}
      />
      {isChanged && <circle cx="15.002" cy="5.5" r="3" fill={theme.colors.primary[50]} stroke={theme.colors.gray[0]} />}
    </svg>
  )
}
