import React from 'react'

import { useWatch } from 'react-hook-form'
import { connect } from 'react-redux'

import { partnersActions } from '@services'

import { bindActionToPromise, cancellablePromise } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'
import { useAssertContext } from '@hooks/useAssertContext'

import { InvoiceType } from '@constants'

import { CalculationBaseControlDialog } from './CalculationBaseControlDialog'

export type UpdatePartnerCalculationBaseCallback = AsyncFunction<
  {
    calculation_base: CalculationBase
    company: number
    id?: number
    invoiceType: InvoiceType
  },
  void
>

interface CalculationBaseControlContextProps {
  calculationBase: CalculationBase
  openDialog: VoidFunction
  updatePartnerCalculationBase: AsyncFunction<CalculationBase, void>
  setCalculationBase: React.Dispatch<React.SetStateAction<CalculationBase>>
}

const CalculationBaseControlContext = React.createContext<Nullable<CalculationBaseControlContextProps>>(null)

CalculationBaseControlContext.displayName = 'CalculationBaseControlContext'

interface CalculationBaseControlProviderProps {
  children: React.ReactNode
  company: number
  invoiceType: InvoiceType
  updateCalculationBase: UpdatePartnerCalculationBaseCallback
}

function PureCalculationBaseControlProvider({
  children,
  company,
  invoiceType,
  updateCalculationBase,
}: CalculationBaseControlProviderProps) {
  const [open, setOpen] = React.useState(false)
  const [calculationBase, setCalculationBase] = React.useState<CalculationBase>('gross_amount')
  const cPromiseRef = useCancellablePromiseRef()

  const partnerId = useWatch({ name: 'partner_id' })

  const updatePartnerCalculationBase = React.useCallback(
    async newCalculationBase => {
      try {
        const payload = {
          calculation_base: newCalculationBase,
          company,
          id: partnerId,
          invoiceType,
        }
        cPromiseRef.current = cancellablePromise(updateCalculationBase(payload))
        await cPromiseRef.current.promise
        setCalculationBase(payload.calculation_base)
      } catch (error) {
        console.error('Update partner calculation base failed', error)
      }
    },
    [cPromiseRef, company, invoiceType, partnerId, updateCalculationBase]
  )

  const contextValues = React.useMemo(
    () => ({
      calculationBase,
      openDialog: () => setOpen(true),
      updatePartnerCalculationBase,
      setCalculationBase,
    }),
    [calculationBase, updatePartnerCalculationBase]
  )

  return (
    <CalculationBaseControlContext.Provider value={contextValues}>
      {children}
      <CalculationBaseControlDialog
        calculationBase={calculationBase}
        open={open}
        onClose={() => setOpen(false)}
        updatePartnerCalculationBase={updatePartnerCalculationBase}
      />
    </CalculationBaseControlContext.Provider>
  )
}

export const CalculationBaseControlProvider = connect(
  (state: Store) => ({
    company: state.auth.company.data.id,
  }),
  dispatch => ({
    updateCalculationBase: bindActionToPromise(dispatch, partnersActions.updatePartnerCalculationBase.request),
  })
)(PureCalculationBaseControlProvider)

CalculationBaseControlProvider.displayName = 'CalculationBaseControlProvider'

export function useCalculationBaseControl() {
  return useAssertContext(CalculationBaseControlContext)
}
