import { COMPANY_ROUTE, V2_COMPANY_ROUTE } from '@constants'

export { API_EXPENSE_PAYMENT_TRANSACTIONS_URL } from '../expense/urls'

export const API_PAID_THROUGH_LIST_URL = `${COMPANY_ROUTE}paid-through/`
export const API_PAID_THROUGH_OPTIONS_URL = `${COMPANY_ROUTE}paid-through/dropdown/`
export const API_PAID_THROUGH_CREATE_URL = `${COMPANY_ROUTE}paid-through/create/`
export const API_PAID_THROUGH_DETAILS_URL = `${COMPANY_ROUTE}paid-through/{paid_through_id}/`
export const API_PAID_THROUGH_UPDATE_URL = `${COMPANY_ROUTE}paid-through/{paid_through_id}/update/`
export const API_PAID_THROUGH_DELETE_URL = `${COMPANY_ROUTE}paid-through/{paid_through_id}/delete`
export const API_PAID_THROUGH_BALANCE_URL = `${COMPANY_ROUTE}paid-through/{paid_through_id}/current-balance`

export const API_SETUP_PAYMENT_URL = `${V2_COMPANY_ROUTE}payments/setup`
export const API_PAYMENT_LIST_URL = `${V2_COMPANY_ROUTE}payments`
export const API_PAYMENT_CREATE_URL = `${V2_COMPANY_ROUTE}payments/create`
export const API_PAYMENT_DETAILS_URL = `${V2_COMPANY_ROUTE}payments/{payment_id}/details`

export const API_PAYMENT_UPDATE_URL = `${V2_COMPANY_ROUTE}payments/{payment_id}/update`

//* BANK TRANSACTIONS
export const API_BANK_TRANSACTIONS_LIST_URL = `${COMPANY_ROUTE}transactions/`
export const API_BANK_TRANSACTIONS_ACTIONS_URL = `${V2_COMPANY_ROUTE}transactions/actions`

//* PAYMENT TRANSACTIONS
export const API_PAYMENT_TRANSACTIONS_LIST_URL = `${V2_COMPANY_ROUTE}payments/transactions`
export const API_PAYMENT_TRANSACTIONS_ACTIONS_URL = `${V2_COMPANY_ROUTE}payments/transactions/actions`
