import React from 'react'

import { CircularProgress, Fade } from '@material-ui/core'
import styled from 'styled-components'

import { Button, ButtonProps } from './Button'
import { BUTTON_SIZE, ICON_SIZE } from './constant'

import { AsyncButtonLoadingStyles, LoadingIndicatorDiv, setLoadingColorsStyles } from './styles'

const StyledButton = styled(Button)<{ $loading: boolean }>`
  ${setLoadingColorsStyles}
  ${AsyncButtonLoadingStyles}
`

export interface AsyncButtonProps extends ButtonProps {
  loading: boolean
}

export function AsyncButton({ loading, children, disabled, ...props }: AsyncButtonProps) {
  return (
    <StyledButton {...props} $loading={loading} disabled={disabled || loading}>
      <>
        <Fade in={loading} mountOnEnter unmountOnExit>
          <LoadingIndicatorDiv>
            <CircularProgress size={`${ICON_SIZE[props.size ?? BUTTON_SIZE.NORMAL]}px`} color="inherit" />
          </LoadingIndicatorDiv>
        </Fade>
        <div>{children}</div>
      </>
    </StyledButton>
  )
}
