import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { serializePlans } from '@oldComponents/subscription/helpers'

import { useAssertContext } from '@hooks'

import AsyncFetch from '@oldComponents/AsyncActionTriggers'
import SubscribePaymentDialog from '@oldComponents/subscription/SubscribePaymentDialog'

import { SUBSCRIPTION_PERIODS, SUBSCRIPTION_PERIODS_OBJ, SUBSCRIPTION_PLANS } from '@constants'

import { ErrorView, LoadingView } from './elements'

export interface SubscriptionContextProps {
  buttonEnabled: boolean
  clearTier: VoidFunction
  handleSubscriptionPeriodTrigger: VoidFunction
  isCurrentCard: (payload: SubscriptionPlan) => boolean
  isSubscriptionActive: boolean
  isTrialAvailable: boolean
  plans: Record<SubscriptionTier, SubscriptionPlan>
  selectedPlan: Nullable<SubscriptionPlan>
  selectedTier: Nullable<SubscriptionTier>
  selectTier: (payload: SubscriptionTier) => void
  setSubmitting: (payload: boolean) => void
  setSuccessView: (payload: Nullable<'trial' | 'change' | 'payment'>) => void
  showSuccessView: Nullable<'trial' | 'change' | 'payment'>
  submitting: boolean
  subscription: CompanySubscription
  subscriptionPeriod: 'monthly' | 'yearly'
  variant: 'normal' | 'onboarding'
}

export const SubscriptionContext = React.createContext<Nullable<SubscriptionContextProps>>(null)

interface SubscriptionProviderProps {
  children: React.ReactNode
  error: BackendError
  plans: SubscriptionPlan[]
  plansFetched?: boolean
  subscription: CompanySubscription
  variant?: 'normal' | 'onboarding'
}

function PureSubscriptionProvider({
  children,
  error,
  plans,
  plansFetched,
  subscription,
  variant = 'normal',
}: SubscriptionProviderProps) {
  const { is_trial_available: isTrialAvailable, plan, cancelable, is_active: isSubscriptionActive } = subscription

  const [subscriptionPeriod, setSubscriptionPeriod] = React.useState<SubscriptionPeriod>(
    plan?.period || SUBSCRIPTION_PERIODS_OBJ.monthly
  )
  const [selectedTier, selectTier] = React.useState<Nullable<SubscriptionTier>>(null)
  const [showSuccessView, setSuccessView] = React.useState<Nullable<'trial' | 'change' | 'payment'>>(null)
  const [submitting, setSubmitting] = React.useState(false) // BraintreeeContainer - form submitting

  const availablePlans = React.useMemo(() => {
    return serializePlans(plans)
  }, [plans])

  const selectedPlan = React.useMemo(() => {
    if (selectedTier && availablePlans[subscriptionPeriod]) {
      return availablePlans[subscriptionPeriod][selectedTier]
    }
    //! NOTE: IMPORTANT plans must contain plan with monthly period
    return null
  }, [availablePlans, selectedTier, subscriptionPeriod])

  const choosablePlans = React.useMemo(
    () => availablePlans?.[subscriptionPeriod] ?? [],
    [availablePlans, subscriptionPeriod]
  )

  const handleClearTier = React.useCallback(() => {
    selectTier(null)
  }, [selectTier])

  function handleSubscriptionPeriodTrigger() {
    setSubscriptionPeriod(
      subscriptionPeriod === SUBSCRIPTION_PERIODS_OBJ.monthly
        ? SUBSCRIPTION_PERIODS_OBJ.yearly
        : SUBSCRIPTION_PERIODS_OBJ.monthly
    )
  }

  function isCurrentCard({ tier, period }: SubscriptionPlan) {
    // called with a plan object: { period, tier }
    return period === plan?.period && tier === plan?.tier
  }

  function render() {
    if (error) {
      return <ErrorView details={error} />
    }
    if (plansFetched) {
      return children
    }

    return <LoadingView />
  }

  return (
    <SubscriptionContext.Provider
      value={{
        buttonEnabled: cancelable,
        clearTier: handleClearTier,
        handleSubscriptionPeriodTrigger,
        isCurrentCard,
        isSubscriptionActive,
        isTrialAvailable,
        plans: choosablePlans,
        selectedPlan,
        selectedTier,
        selectTier,
        setSubmitting,
        setSuccessView,
        showSuccessView,
        submitting,
        subscription,
        subscriptionPeriod,
        variant,
      }}
    >
      <AsyncFetch mode={['subscriptionPlans']} />
      {render()}

      <SubscribePaymentDialog />
    </SubscriptionContext.Provider>
  )
}

PureSubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string as React.Validator<BackendError>,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      braintree_plan_id: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      discounted_price: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      period: PropTypes.oneOf(SUBSCRIPTION_PERIODS).isRequired,
      price: PropTypes.string.isRequired,
      tier: PropTypes.oneOf(SUBSCRIPTION_PLANS).isRequired,
    }).isRequired
  ).isRequired as React.Validator<SubscriptionProviderProps['plans']>,
  plansFetched: PropTypes.bool,
  subscription: PropTypes.shape({
    cancelable: PropTypes.bool.isRequired,
    id: PropTypes.number,
    is_active: PropTypes.bool.isRequired,
    is_trial_available: PropTypes.bool.isRequired,
    plan: PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_grandfather: PropTypes.bool.isRequired,
      tier: PropTypes.oneOf(SUBSCRIPTION_PLANS).isRequired,
      period: PropTypes.oneOf(SUBSCRIPTION_PERIODS).isRequired,
    }),
    trial: PropTypes.bool.isRequired,
  }).isRequired as React.Validator<SubscriptionProviderProps['subscription']>,
  variant: PropTypes.oneOf(['onboarding', 'normal']) as React.Validator<SubscriptionProviderProps['variant']>,
}

export const SubscriptionProvider = connect((state: Store) => ({
  error: state.subscription.plans.error,
  plans: state.subscription.plans.data,
  plansFetched: state.subscription.plans.fetched,
  subscription: state.auth.company.data.subscription,
}))(PureSubscriptionProvider)

SubscriptionProvider.displayName = 'SubscriptionProvider'

export function useSubscription() {
  return useAssertContext(SubscriptionContext)
}
