import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, useIntl } from 'react-intl'
import { HeaderGroup } from 'react-table'

import { TableHead, TableHeading, TableRow } from '@components/ui'
import { TableSortIcon } from '@components/ui/TableElements'

const messages = defineMessages({
  toggleSortBy: {
    id: 'tables.simpleTable.toggleSortBy',
    defaultMessage: 'Rendezés',
  },
})

interface TableHeadAreaProps<RowData extends object> {
  headerGroups: HeaderGroup<RowData>[]
}

/**
 * A table header with a custom style.
 *
 * @template RowData - Type of the row data.
 * @param {TableHeadAreaProps<RowData>} { headerGroups } - The header groups from react-table
 */
export default function TableHeadArea<RowData extends object>({ headerGroups }: TableHeadAreaProps<RowData>) {
  const { formatMessage } = useIntl()
  return (
    <TableHead>
      {headerGroups.map(headerGroup => {
        const { key: rowKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps()
        return (
          <TableRow key={rowKey} {...headerGroupProps}>
            {headerGroup.headers.map(column => {
              const { key: cellKey, ...columnHeaderProps } = column.getHeaderProps(
                column.getSortByToggleProps?.({
                  ...column.getSortByToggleProps(),
                  title: column.canSort ? formatMessage(messages.toggleSortBy) : undefined,
                })
              )
              const isSortableTable = Boolean(column.getSortByToggleProps)
              const isSortEnabled = isSortableTable && column.canSort
              const isRightAligned = column.align === 'right'
              return (
                <TableHeading
                  {...columnHeaderProps}
                  key={cellKey}
                  align={column.align}
                  className={column.className}
                  style={{
                    width: column.width,
                  }}
                  $sortable={isSortEnabled}
                  $sorted={column.isSorted}
                  $sortedDescending={column.isSortedDesc}
                  $rightAligned={isRightAligned}
                >
                  {column.render('Header')}
                  {isSortEnabled && <TableSortIcon />}
                </TableHeading>
              )
            })}
          </TableRow>
        )
      })}
    </TableHead>
  )
}

TableHeadArea.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
}
