import React from 'react'

export function useIntercomProps(className?: string) {
  return React.useMemo(() => {
    if (!window.intercomAppId && process.env.NODE_ENV === 'development') {
      return {
        className,
        onClick: () => alert('[Development Mode] Launch Intercom'),
      }
    } else {
      return {
        className,
      }
    }
  }, [className])
}
