import { ZoomOrientation } from '@components/DocViewer/constant'
import { DocViewerMainState } from '@components/DocViewer/types'

import { DocViewerPDFActions, PDFStateActions } from './actions'

export type DocViewerPDFState = {
  defaultZoomLevel: number
  zoomLevel: number
  zoomOrientation: ZoomOrientation
  zoomJump: number
  paginated: boolean
  numPages: number
  currentPage: number
  mainState?: DocViewerMainState
}

export const initialPDFState: DocViewerPDFState = {
  defaultZoomLevel: 1,
  zoomLevel: 1,
  zoomOrientation: ZoomOrientation.LANDSCAPE,
  zoomJump: 0.1,
  paginated: true,
  numPages: 0,
  currentPage: 1,
}

export type PDFStateReducer = (state: DocViewerPDFState, action: PDFStateActions) => DocViewerPDFState

export const reducer: PDFStateReducer = (state = initialPDFState, action) => {
  switch (action.type) {
    case DocViewerPDFActions.SET_ZOOM_LEVEL: {
      const { value } = action

      return { ...state, zoomLevel: value }
    }

    case DocViewerPDFActions.SET_PDF_PAGINATED: {
      const { value } = action
      return { ...state, paginated: value }
    }

    case DocViewerPDFActions.SET_NUM_PAGES: {
      const { value } = action
      return { ...state, numPages: value }
    }

    case DocViewerPDFActions.SET_CURRENT_PAGE: {
      const { value } = action
      return { ...state, currentPage: value }
    }

    case DocViewerPDFActions.CHANGE_ZOOM_ORIENTATION: {
      const { defaultZoomLevel, zoomOrientation } = action
      return { ...state, defaultZoomLevel, zoomLevel: defaultZoomLevel, zoomOrientation }
    }

    default:
      return state
  }
}
