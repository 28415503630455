import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components'

import { IconButton, LinkButton } from '@components/ui/Buttons'

import { generateMaxHeightBreakPoint, generateMaxWidthBreakPoint } from '@constants/theme'

function getButtonStyles({ theme, $isCompact = false }: ThemedStyledProps<{ $isCompact?: boolean }, DefaultTheme>) {
  return css`
    padding: 0;
    min-width: 0;
    line-height: 1;
    min-height: 0;
    height: ${theme.appBarHeight}px;
    width: ${$isCompact ? '100%' : `${theme.appBarHeight}px`};
    border-radius: 0;
    font: inherit;

    & > span {
      margin: 0 auto;
    }

    ${$isCompact &&
    css`
      padding: 0 15px;
      justify-content: flex-start;
    `}

    ${generateMaxHeightBreakPoint('medium')} {
      height: ${({ theme }) => theme.appBarHeightMedium}px;
    }
    ${generateMaxWidthBreakPoint('small')} {
      width: 100%;
      justify-content: flex-start;
      padding: 0 8px;
    }
  `
}

export const StyledIconButton = styled(IconButton)<{ $isCompact?: boolean }>`
  ${getButtonStyles}
`

export const StyledLinkButton = styled(LinkButton)`
  ${getButtonStyles}
`
