import { JOB_STATUS_ERROR, JOB_STATUS_FINISHED } from '@services/BackgroundAction'
import { callUrl } from '@services/common/api'
import { CommonAxiosPayload } from '@services/types'

const INTERVAL = 5000

class BackgroundAction {
  private resolve: ((value?: unknown) => void) | undefined
  private reject: ((reason?: unknown) => void) | undefined
  private timerRef: number | undefined

  private run = async (payload: CommonAxiosPayload) => {
    try {
      const response = await callUrl<BackgroundActionResponse>(payload)
      this.responseHandler(response)
    } catch (error) {
      this.reject?.(error)
    }
  }

  private responseHandler = ({ data: { status, errors, links } }: { data: BackgroundActionResponse }) => {
    // bg job finished
    if (JOB_STATUS_FINISHED === status) {
      this.resolve?.()
    } else if (JOB_STATUS_ERROR === status) {
      this.reject?.(errors)
    } else {
      // created and pending statuses
      this.timerRef = window.setTimeout(() => {
        this.run({
          url: links.status as string,
          method: 'get',
        })
      }, INTERVAL)
    }
  }

  start = (url: string) => {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.run({
        url,
        method: 'post',
      })
    })
  }

  continueProcess = (url: string) => {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.run({
        url,
        method: 'get',
      })
    })
  }

  stop = async () => {
    if (this.timerRef) {
      window.clearTimeout(this.timerRef)
      return
    }
    this.resolve?.()
  }
}

export const DokumentAiRecognitionBackgroundAction = new BackgroundAction()
