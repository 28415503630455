import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { areSignedURLPathnamesEqual, trimLocalhostFromUrl } from '@helpers'

import { DOC_VIEWER_CONFIG, PortalProvider, usePortal } from '@contexts'

import {
  DocRenderer,
  DocViewer,
  DocViewerDocument,
  DocViewerFileControls,
  DocViewerProps,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
} from '@components/DocViewer'
import { ParentSize } from '@components/ui'

import { DOC_PREVIEW_CONFIG } from '@constants'

import {
  AbsoluteArtifactPreviewControlsDiv,
  ArtifactControlsDiv,
  ArtifactDocumentType,
  PostItController,
} from './elements'

export const SUPPORTED_PLUGIN_RENDERERS: DocRenderer[] = [JPGRenderer, PDFRenderer, PNGRenderer]

interface PortalAnchorElementProps {
  postItControls: React.ReactNode
}

function PortalAnchorElement({ postItControls }: PortalAnchorElementProps) {
  const { setPortalAnchorEl } = usePortal()
  return (
    <AbsoluteArtifactPreviewControlsDiv>
      {postItControls ? <div>{postItControls}</div> : <div />}
      <ArtifactControlsDiv ref={setPortalAnchorEl} />
    </AbsoluteArtifactPreviewControlsDiv>
  )
}

interface ArtifactDocViewerProps
  extends Pick<DocViewerProps, 'pageRef' | 'documents' | 'renderPostItLayer'>,
    Pick<ArtifactViewerProps, 'hideFileControls' | 'pdfVerticalScrollByDefault'> {
  fileControls?: DocViewerFileControls
  height: number
  width: number
}

export function ArtifactDocViewer({
  documents,
  fileControls,
  height,
  hideFileControls,
  pageRef,
  pdfVerticalScrollByDefault,
  renderPostItLayer,
  width,
}: ArtifactDocViewerProps) {
  const memoizedConfig = React.useMemo(() => {
    return {
      ...DOC_VIEWER_CONFIG,
      fileControls,
      hideFileControls,
      pdfVerticalScrollByDefault,
      viewerSize: {
        maxHeight: height - DOC_PREVIEW_CONFIG.controlsBarHeight,
        maxWidth: width,
      },
    }
  }, [fileControls, height, hideFileControls, pdfVerticalScrollByDefault, width])

  return (
    <DocViewer
      documents={documents}
      pluginRenderers={SUPPORTED_PLUGIN_RENDERERS}
      config={memoizedConfig}
      renderPostItLayer={renderPostItLayer}
      pageRef={pageRef}
    />
  )
}

interface ArtifactViewerProps extends Omit<DocViewerFileControls, 'downloadLabel' | 'printLabel' | 'deleteLabel'> {
  documentId?: ItemIdType
  documentType?: ArtifactDocumentType
  enabledPostIt?: boolean
  fileName?: string
  fileUri: string
  hideDelete?: boolean
  hideFileControls?: boolean
  hidePrint?: boolean
  matched?: boolean
  pdfVerticalScrollByDefault?: boolean
}

export function ArtifactViewer({
  deleteArtifact,
  documentId,
  documentType = 'expense',
  downloadArtifact,
  enabledPostIt = false,
  fileName,
  fileUri,
  hideDelete = false,
  hideFileControls = false,
  hidePrint = false,
  matched = true,
  pdfVerticalScrollByDefault = false,
}: ArtifactViewerProps) {
  const [documents, setDocuments] = React.useState<DocViewerDocument[]>([])

  React.useEffect(() => {
    if (matched && fileUri) {
      if (areSignedURLPathnamesEqual(fileUri, documents[0]?.uri)) return

      setDocuments([
        {
          uri: trimLocalhostFromUrl(fileUri),
          fileName,
        },
      ])
    }
  }, [documents, fileName, fileUri, matched])

  const fileControls = React.useMemo<DocViewerFileControls>(
    () => ({
      deleteArtifact,
      deleteLabel: <FormattedMessage id="editor.title.deleteArtifact" defaultMessage="Számlakép törlése" />,
      downloadArtifact,
      downloadLabel: <FormattedMessage id="editor.title.downloadArtifact" defaultMessage="Számlakép letöltése" />,
      hideDelete,
      hidePrint,
      printLabel: <FormattedMessage id="editor.title.printArtifact" defaultMessage="Számlakép nyomtatása" />,
    }),
    [deleteArtifact, downloadArtifact, hideDelete, hidePrint]
  )

  // do not show pdf viewer when no matched artifact
  if (!matched) {
    return null
  }

  return (
    <PortalProvider>
      <PostItController documentId={documentId} documentType={documentType} enabled={enabledPostIt}>
        {({ renderControlButtons, renderNotes, pageRef }) => (
          <>
            <ParentSize>
              {({ height, width }) => (
                <ArtifactDocViewer
                  documents={documents}
                  fileControls={fileControls}
                  height={height}
                  hideFileControls={hideFileControls}
                  pageRef={pageRef}
                  pdfVerticalScrollByDefault={pdfVerticalScrollByDefault}
                  renderPostItLayer={renderNotes}
                  width={width}
                />
              )}
            </ParentSize>

            <PortalAnchorElement postItControls={renderControlButtons()} />
          </>
        )}
      </PostItController>
    </PortalProvider>
  )
}

ArtifactViewer.propTypes = {
  deleteArtifact: PropTypes.func,
  documentId: PropTypes.number,
  documentType: PropTypes.oneOf(['expense', 'income']),
  downloadArtifact: PropTypes.func,
  enabledPostIt: PropTypes.bool,
  fileName: PropTypes.string,
  fileUri: PropTypes.string.isRequired,
  hideDelete: PropTypes.bool,
  hideFileControls: PropTypes.bool,
  hidePrint: PropTypes.bool,
  matched: PropTypes.bool,
  pdfVerticalScrollByDefault: PropTypes.bool,
}
