import React, { Component, Fragment } from 'react'
import { bool, node } from 'prop-types'

import { connect } from 'react-redux'

import { shouldShowBadgeView } from './helpers'
import UserBadgeView from './UserBadgeView'

export class UserBadgeWelcomeContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { show: props.showBadgeView }
  }

  closeBadgeView = () => {
    this.setState({ show: false })
  }

  render() {
    if (this.state.show) {
      return <UserBadgeView onClose={this.closeBadgeView} />
    } else {
      return <Fragment>{this.props.children}</Fragment>
    }
  }
}

UserBadgeWelcomeContainer.propTypes = {
  showBadgeView: bool.isRequired,
  children: node.isRequired,
}

export default connect(state => ({
  showBadgeView: shouldShowBadgeView(state),
}))(UserBadgeWelcomeContainer)
