import React from 'react'

export function useReactHookFormExternalSubmit(externalRef?: React.RefObject<HTMLFormElement>) {
  const formRef = React.useRef<HTMLFormElement>(externalRef?.current ?? null)

  function triggerSubmit() {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return {
    formRef,
    triggerSubmit,
  }
}
