import styled from 'styled-components'

import { FlexRow } from '../Wrappers'

export const CalculatorMainDiv = styled(FlexRow)`
  gap: 5px;
  height: 42px;
  background-color: ${({ theme }) => theme.colors.gray[30]};
  border-radius: 4px;
  padding: 1px 10px;
  color: ${({ theme }) => theme.colors.gray[50]};
`
