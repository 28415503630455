import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { incomeActions } from '@services'

import {
  bindActionToPromise,
  cancellablePromise,
  checkEmptyValues,
  isAdvancedAccountingAvailable,
  recommendationMessages,
} from '@helpers'
import { INCOME_TYPE_INVOICE } from '@oldComponents/pages/DashboardIncomePage/helpers'

import { CalculationBaseControlProvider } from '@contexts'

import { useCancellablePromiseRef } from '@hooks'

import {
  FormBlock,
  FormBlockTitle,
  InfoIcon,
  ReactHookForm,
  ReactHookFormAmountField,
  ReactHookFormConditionalField,
  ReactHookFormDateField,
  ReactHookFormError,
  ReactHookFormSelectField,
  ReactHookFormSubmitButton,
  ReactHookFormTextField,
} from '@components/ui'
import AsyncFetch from '@oldComponents/AsyncActionTriggers'
import { ContentWithHighlightBlock, NotEditableReason } from '@oldComponents/forms/DetailsForm/elements'
import { LightTooltip } from '@oldComponents/ui'

import { COMPANY_USER_FEATURE_PERMISSION_MAPPER as FEATURE_PERMISSIONS, InvoiceType } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import {
  AccountingPeriodFields,
  ControlledPartnerDetailsFields,
  InvoiceAssignments,
  PaidThroughField,
  PartnerSearchFieldWithCalculationBase,
  ReactHookFormExchangeRateFields,
  ReactHookFormTagField,
  ReschedulePaymentFields,
  TagIconContainerDiv,
} from '../elements'
import { validationEngine, ValidationErrors } from '../validationEngine'
import { IncomeDetailsFormProps, IncomeDetailsFormValues } from './types'
import { useFormInitialValues } from './useFormInitialValues'
import useValidationSchema from './useValidationSchema'

import {
  formErrorMessages,
  InvoiceCostsBlockTitleMessage,
  InvoiceDateBlockTitleMessage,
  InvoiceGeneralInfoBlockTitleMessage,
  InvoicePartnerBlockTitleMessage,
} from '@messages'
import { HIGHLIGHT_STYLES, SPACING } from '@oldComponents/forms/DetailsForm/styles'
import messages from '../messages'

const StyledReactHookFormError = styled(ReactHookFormError)`
  margin-bottom: 1rem;
`

export function PureIncomeDetailsForm({
  callInvoiceNumberCheck,
  createNewType,
  currencyOptions,
  defaultCurrencyId,
  hasArtifact,
  hasIntegration,
  invoiceDetails,
  invoiceDetails: {
    id: invoiceId,
    items: invoiceIntegrationItems,
    origin,
    partner: partnerDefaultValue,
    recommendations,
    user_updated: lastUpdated,
  },
  isAdvancedAccountingAvailableForUser,
  isEditDisabled,
  isNavInvoice,
  isTagsDisabledForUser,
  onCreatePartner,
  onSubmit,
  onSubmitSuccess,
  paymentMethodOptions,
  preventSubmitSuccess,
  readOnly,
  revenueTypeOptions,
  updateFormWithRecommendation,
  uploading,
  vatAreaOptions,
}: IncomeDetailsFormProps) {
  const { formatMessage } = useIntl()
  const validationSchema = useValidationSchema(defaultCurrencyId)
  const initialValues = useFormInitialValues(invoiceDetails, defaultCurrencyId, INCOME_TYPE_INVOICE)
  const cPromiseRef = useCancellablePromiseRef()

  const isProcessedInvoice = !readOnly && isEditDisabled
  const isAllFieldDisabled = readOnly || isEditDisabled || uploading || hasIntegration

  const recommendationConfig = React.useMemo(
    () => ({
      options: recommendations?.partner ?? [],
      texts: {
        recommendationsLabel: formatMessage(recommendationMessages.recommendationsLabel),
        optionsLabel: formatMessage(recommendationMessages.optionsLabel),
        newOptionPrefix: formatMessage(recommendationMessages.newOptionPrefix),
      },
    }),
    [recommendations?.partner, formatMessage]
  )

  //* Validator:invoiceNumber
  const invoiceNumberValidator = React.useCallback(
    async ({ invoice_number, partner_id }: IncomeDetailsFormInitialValues) => {
      const errors = {} as ValidationErrors<IncomeDetailsFormInitialValues>
      if (invoice_number && partner_id) {
        try {
          cPromiseRef.current = cancellablePromise(
            callInvoiceNumberCheck({
              id: invoiceId,
              income_type: INCOME_TYPE_INVOICE,
              invoice_number,
              partner: partner_id,
            })
          )
          const { result } = await cPromiseRef.current.promise
          if (!result) {
            errors['invoice_number'] = formatMessage(formErrorMessages.isUniqueInvoiceNumber)
          }
        } catch (err) {
          // do nothing
        }
      }
      return errors
    },
    [cPromiseRef, callInvoiceNumberCheck, formatMessage, invoiceId]
  )

  const handleSubmit = React.useCallback(
    ({ need_accounting_period, reschedule_payment, ...values }: IncomeDetailsFormValues) => {
      // keep empty values compared to initialValues
      const data = checkEmptyValues(values, initialValues) as IncomeDetailsFormInitialValues

      return validationEngine([invoiceNumberValidator], onSubmit, data)
    },
    [initialValues, invoiceNumberValidator, onSubmit]
  )

  return (
    <>
      <AsyncFetch mode={['revenueType']} />
      <ReactHookForm
        data-testid="income-details-form"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitSuccess={onSubmitSuccess}
        preventSubmitSuccess={preventSubmitSuccess}
        validationSchema={validationSchema}
      >
        <NotEditableReason
          hasArtifact={hasArtifact}
          isProcessedInvoice={isProcessedInvoice}
          origin={origin}
          readOnly={readOnly}
          variant={InvoiceType.INCOME}
        />
        <CalculationBaseControlProvider invoiceType={InvoiceType.INCOME}>
          <FormBlock>
            <FormBlockTitle>{InvoicePartnerBlockTitleMessage}</FormBlockTitle>
            <Grid container spacing={SPACING}>
              <Grid item xs={8}>
                <PartnerSearchFieldWithCalculationBase
                  defaultValue={partnerDefaultValue}
                  disabled={isAllFieldDisabled}
                  invoiceId={invoiceId}
                  invoiceType={InvoiceType.INCOME}
                  label={formatMessage(messages.partnerNameLabel)}
                  onCreate={onCreatePartner}
                  recommendationConfig={recommendationConfig}
                  updateFormWithRecommendation={updateFormWithRecommendation}
                />
              </Grid>
              <Grid item xs={4}>
                <ContentWithHighlightBlock
                  needHighlight={hasIntegration && !(readOnly || isEditDisabled || uploading)}
                  additionalStyle={HIGHLIGHT_STYLES.rightCell}
                >
                  <ReactHookFormSelectField
                    disabled={readOnly || isEditDisabled || uploading}
                    isLabelHighlighted={hasIntegration}
                    label={formatMessage(messages.vatAreaLabel)}
                    labelKey="label"
                    name="vat_area"
                    options={vatAreaOptions}
                    valueKey="value"
                  />
                </ContentWithHighlightBlock>
              </Grid>
            </Grid>
            <ControlledPartnerDetailsFields
              disabled={isAllFieldDisabled}
              isAdvancedAccountingAvailableForUser={isAdvancedAccountingAvailableForUser}
              isEdit={Boolean(invoiceId)}
              recommendations={recommendations}
            />
          </FormBlock>
          <FormBlock>
            <FormBlockTitle>{InvoiceGeneralInfoBlockTitleMessage}</FormBlockTitle>
            <Grid container spacing={SPACING}>
              <Grid item xs={4}>
                <ReactHookFormTextField
                  disabled={isAllFieldDisabled}
                  label={formatMessage(messages.invoiceNumberLabel)}
                  name="invoice_number"
                  required
                />
              </Grid>
              <Grid item xs={8}>
                <ContentWithHighlightBlock
                  needHighlight={isProcessedInvoice || hasIntegration}
                  additionalStyle={HIGHLIGHT_STYLES.rightCell}
                >
                  <Grid container spacing={SPACING}>
                    <Grid item xs={6}>
                      <ReactHookFormSelectField
                        disabled={readOnly || isEditDisabled || uploading}
                        isClearable
                        isLabelHighlighted={isProcessedInvoice || hasIntegration}
                        label={formatMessage(messages.paymentMethodLabel)}
                        labelKey="label"
                        name="payment_method"
                        options={paymentMethodOptions}
                        valueKey="value"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReactHookFormConditionalField name="payment_method" condition={val => val === 'card'}>
                        <PaidThroughField
                          disabled={readOnly || isEditDisabled || uploading}
                          isLabelHighlighted={isProcessedInvoice || hasIntegration}
                        />
                      </ReactHookFormConditionalField>
                    </Grid>
                  </Grid>
                </ContentWithHighlightBlock>
              </Grid>
            </Grid>
            <ContentWithHighlightBlock needHighlight={isProcessedInvoice || hasIntegration}>
              <Grid container spacing={SPACING}>
                <Grid item xs={4}>
                  <ReactHookFormTextField
                    disabled={readOnly || uploading}
                    isLabelHighlighted={isProcessedInvoice || hasIntegration}
                    label={formatMessage(messages.secondaryIdLabel)}
                    name="secondary_id"
                  />
                </Grid>
              </Grid>
            </ContentWithHighlightBlock>
            <ContentWithHighlightBlock needHighlight={!isTagsDisabledForUser && (isProcessedInvoice || hasIntegration)}>
              <Grid container spacing={SPACING}>
                <Grid item xs={6}>
                  <ReactHookFormTagField
                    disabled={isTagsDisabledForUser || readOnly || uploading}
                    isLabelHighlighted={!isTagsDisabledForUser && (isProcessedInvoice || hasIntegration)}
                    label={formatMessage(messages.itemSimpleTagsLabel)}
                    name="simple_tags"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ReactHookFormTagField
                    disabled={isTagsDisabledForUser || readOnly || uploading}
                    isLabelHighlighted={!isTagsDisabledForUser && (isProcessedInvoice || hasIntegration)}
                    name="tags"
                    label={
                      <LightTooltip title={formatMessage(messages.smartTagTooltip)} placement="top">
                        <TagIconContainerDiv>
                          {formatMessage(messages.itemTagsLabel)}
                          <InfoIcon size={16} />
                        </TagIconContainerDiv>
                      </LightTooltip>
                    }
                    selectVariant="purple"
                  />
                </Grid>
              </Grid>
            </ContentWithHighlightBlock>
          </FormBlock>
          <FormBlock>
            <FormBlockTitle>{InvoiceDateBlockTitleMessage}</FormBlockTitle>
            <Grid container spacing={SPACING}>
              <Grid item xs={4}>
                <ReactHookFormDateField
                  disabled={isAllFieldDisabled}
                  label={formatMessage(messages.fulfilledDateLabel)}
                  name="fulfilled_at"
                  placeholder={formatMessage(messages.dateFieldPlaceholder)}
                  required
                  // TODO onBlur hander: onChangeFulfilledAtHandler - not editable yet
                />
              </Grid>
              <Grid item xs={4}>
                <ReactHookFormDateField
                  disabled={isAllFieldDisabled}
                  label={formatMessage(messages.createdDateLabel)}
                  name="issued_at"
                  placeholder={formatMessage(messages.dateFieldPlaceholder)}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <ContentWithHighlightBlock
                  needHighlight={isProcessedInvoice || hasIntegration}
                  additionalStyle={HIGHLIGHT_STYLES.rightCell}
                >
                  <ReactHookFormDateField
                    disabled={readOnly || uploading}
                    isLabelHighlighted={isProcessedInvoice || hasIntegration}
                    label={formatMessage(messages.deadlineDateLabel)}
                    name="due_at"
                    placeholder={formatMessage(messages.dateFieldPlaceholder)}
                    required
                  />
                </ContentWithHighlightBlock>
              </Grid>
            </Grid>
            <ContentWithHighlightBlock needHighlight={isProcessedInvoice || hasIntegration}>
              <ReschedulePaymentFields
                disabled={readOnly || uploading}
                isLabelHighlighted={isProcessedInvoice || hasIntegration}
              />
            </ContentWithHighlightBlock>
            <ContentWithHighlightBlock needHighlight={isProcessedInvoice || hasIntegration}>
              <AccountingPeriodFields
                disabled={readOnly || uploading}
                isLabelHighlighted={isProcessedInvoice || hasIntegration}
              />
            </ContentWithHighlightBlock>
          </FormBlock>
          <FormBlock>
            <FormBlockTitle>{InvoiceCostsBlockTitleMessage}</FormBlockTitle>
            <Grid container spacing={SPACING}>
              <Grid item xs={4}>
                <ReactHookFormSelectField
                  disabled={isAllFieldDisabled}
                  label={formatMessage(messages.currencyLabel)}
                  labelKey="name"
                  name="currency"
                  options={currencyOptions}
                  valueKey="id"
                  required
                />
              </Grid>
              <ReactHookFormExchangeRateFields
                defaultCurrencyId={defaultCurrencyId}
                disabled={isAllFieldDisabled}
                invoiceDetailsId={invoiceId}
              />
            </Grid>
            <Grid container spacing={SPACING}>
              <Grid item xs={4}>
                <ReactHookFormAmountField
                  disabled={isAllFieldDisabled}
                  label={formatMessage(messages.totalCostLabel)}
                  name="gross_amount"
                  required
                  // TODO onBlur handler: onChangeGrossAmountHandler - not editable yet
                />
              </Grid>
            </Grid>
          </FormBlock>
          <ContentWithHighlightBlock
            needHighlight={hasIntegration && !isProcessedInvoice}
            additionalStyle={HIGHLIGHT_STYLES.row}
          >
            <>
              <InvoiceAssignments
                categoryTypeOptions={revenueTypeOptions}
                hasIntegration={hasIntegration}
                initialValues={initialValues}
                invoiceIntegrationItems={invoiceIntegrationItems}
                invoiceType={InvoiceType.INCOME}
                isEditDisabled={isEditDisabled}
                isNavInvoice={isNavInvoice}
                onCreateCategoryType={createNewType}
                readOnly={readOnly}
              />
              <StyledReactHookFormError hideIcon />
              <ReactHookFormSubmitButton
                hasDisabledState
                initialValues={initialValues}
                isCreateOnly={!invoiceId}
                lastUpdated={lastUpdated}
                needConfirmOnReset
              />
            </>
          </ContentWithHighlightBlock>
        </CalculationBaseControlProvider>
      </ReactHookForm>
    </>
  )
}

PureIncomeDetailsForm.propTypes = {
  callInvoiceNumberCheck: PropTypes.func.isRequired,
  createNewType: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  defaultCurrencyId: PropTypes.number.isRequired,
  hasArtifact: PropTypes.bool.isRequired,
  hasIntegration: PropTypes.bool.isRequired,
  invoiceDetails: PropTypes.object.isRequired as React.Validator<IncomeDetailsFormProps['invoiceDetails']>,
  isAdvancedAccountingAvailableForUser: PropTypes.bool.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
  isNavInvoice: PropTypes.bool.isRequired,
  isTagsDisabledForUser: PropTypes.bool.isRequired,
  onCreatePartner: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  paymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  preventSubmitSuccess: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  revenueTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  updateFormWithRecommendation: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  vatAreaOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export const IncomeDetailsForm = connect(
  (state: Store) => ({
    currencyOptions: state.dashboard.common.currencies,
    defaultCurrencyId: state.auth.company.data.default_currency,
    invoiceDetails: state.income.details.data,
    isAdvancedAccountingAvailableForUser: isAdvancedAccountingAvailable(state),
    isTagsDisabledForUser: permissionDeniedForUser(state, FEATURE_PERMISSIONS.tag),
    paymentMethodOptions: state.dashboard.common.payment_methods,
    revenueTypeOptions: state.income.revenueTypes.data,
    uploading: state.income.details.uploading,
    vatAreaOptions: state.dashboard.common.vat_areas,
  }),
  dispatch => ({
    callInvoiceNumberCheck: bindActionToPromise(dispatch, incomeActions.invoiceNumberCheck.request),
    createNewType: bindActionToPromise(dispatch, incomeActions.createRevenueType.request),
    updateFormWithRecommendation: bindActionToPromise(dispatch, incomeActions.updateIncomeWithRecommendation.request),
  })
)(PureIncomeDetailsForm)

IncomeDetailsForm.displayName = 'IncomeDetailsForm'
