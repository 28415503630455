import { Tab as BaseTab, TabList as BaseTabList, TabPanel as BaseTabPanel, Tabs as BaseTabs } from 'react-tabs'
import styled from 'styled-components'

import { getFontStyles } from '@helpers/styled-helpers'

import { TABS_CLASS_NAMES } from '@constants/tabs'
import { generateMinWidthBreakPoint } from '@constants/theme'

/**
 * Base TabList from react-tabs.
 *
 *  For advanced usage please check the following documentation: https://github.com/reactjs/react-tabs
 */
export const TabList = styled(BaseTabList)`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  list-style: none;
`

/**
 * Base Tab from react-tabs. You can use `TABS_CLASS_NAMES.selectedTabClassName` if you need to style only the selected panel, and `TABS_CLASS_NAMES.disabledTabClassName` if you need to style only the disabled panel.
 *
 *  For advanced usage please check the following documentation: https://github.com/reactjs/react-tabs
 */
export const Tab = styled(BaseTab)`
  cursor: pointer;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[50]};
  ${({ theme }) => getFontStyles(theme, '700-md')}

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.gray[100]};
    outline: none;
  }

  &.${TABS_CLASS_NAMES.selectedTabClassName} {
    color: ${({ theme }) => theme.colors.gray[100]};
  }

  &.${TABS_CLASS_NAMES.disabledTabClassName} {
    color: ${({ theme }) => theme.colors.gray[40]};
  }

  ${generateMinWidthBreakPoint('mobile')} {
    min-width: 160px;
  }
`

/**
 * Base TabPanel from react-tabs. You can use `TABS_CLASS_NAMES.selectedTabPanelClassName` if you need to style only the selected panel.
 *
 *  For advanced usage please check the following documentation: https://github.com/reactjs/react-tabs
 */
export const TabPanel = styled(BaseTabPanel)``

/**
 * Base Tabs from react-tabs with predefined classNames to be used from `TABS_CLASS_NAMES`
 *
 * Basic example:
 * ```
 * <Tabs>
 *   <TabList>
 *     <Tab>Title 1</Tab>
 *     <Tab>Title 2</Tab>
 *   </TabList>
 *
 *   <TabPanel>
 *     <h2>Any content 1</h2>
 *   </TabPanel>
 *   <TabPanel>
 *     <h2>Any content 2</h2>
 *   </TabPanel>
 * </Tabs>
 * ```
 *
 * For advanced usage please check the following documentation: https://github.com/reactjs/react-tabs
 */
export const Tabs = styled(BaseTabs).attrs({
  disabledTabClassName: TABS_CLASS_NAMES.disabledTabClassName,
  selectedTabClassName: TABS_CLASS_NAMES.selectedTabClassName,
  selectedTabPanelClassName: TABS_CLASS_NAMES.selectedTabPanelClassName,
})``
