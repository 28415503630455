import React from 'react'

import { DocViewerContext } from '@components/DocViewer/DocViewerProvider'

export function LoadingTimeout({ children }: { children: React.ReactNode }) {
  const {
    state: { config },
  } = React.useContext(DocViewerContext)
  const [shouldLoadingRender, setShouldLoadingRender] = React.useState(
    config?.loadingRenderer?.showLoadingTimeout === false
  )

  React.useEffect(() => {
    const timer = window.setTimeout(
      () => {
        setShouldLoadingRender(true)
      },
      typeof config?.loadingRenderer?.showLoadingTimeout === 'number' ? config.loadingRenderer.showLoadingTimeout : 500
    )

    return () => {
      window.clearTimeout(timer)
    }
  }, [config?.loadingRenderer?.showLoadingTimeout])

  if (!shouldLoadingRender) {
    return null
  }

  return <>{children}</>
}
