import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, useIntl } from 'react-intl'

import { CompanyUserRoles } from '@constants'

export const messages = defineMessages({
  admin: {
    id: 'companyRoles.admin',
    defaultMessage: 'Adminisztrátor',
  },
  accountant: {
    id: 'companyRoles.accountant',
    defaultMessage: 'Könyvelő',
  },
  editor: {
    id: 'companyRoles.editor',
    defaultMessage: 'Szerkesztő',
  },
  viewer: {
    id: 'companyRoles.viewer',
    defaultMessage: 'Megtekintő',
  },
  // for example in onboarding scenario where we do not know yet what role the user will have
  unknown: {
    id: 'companyRoles.unknown',
    defaultMessage: 'Felhasználó',
  },
})

interface CompanyRoleProps<Data> {
  data: Data
}

export function CompanyRole<Data extends { role: CompanyUserRole }>({ data: { role } }: CompanyRoleProps<Data>) {
  const { formatMessage } = useIntl()
  return <>{formatMessage(messages[role])}</>
}

CompanyRole.propTypes = {
  data: PropTypes.shape({
    role: PropTypes.oneOf(Object.values(CompanyUserRoles)).isRequired,
  }).isRequired,
}

interface UserRoleProps {
  role: CompanyUserRole
}

export function UserRole({ role }: UserRoleProps) {
  const { formatMessage } = useIntl()
  return <>{formatMessage(messages[role])}</>
}

UserRole.propTypes = {
  role: PropTypes.oneOf(Object.values(CompanyUserRoles)).isRequired,
}
