import React from 'react'

import { FormattedMessage } from 'react-intl'

//* SEARCH FILTERS

export const SearchLabelMessage = <FormattedMessage id="filterBar.searchFilter.input.label" defaultMessage="Keresés" />

export const PartnerNameSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.partner" defaultMessage="Partner neve" />
)

export const AccountNumberSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.accountNumber" defaultMessage="Bankszámlaszám" />
)

export const InvoiceNumberSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.invoiceId" defaultMessage="Számla sorszáma" />
)

export const BeneficiarySearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.beneficiary" defaultMessage="Munkavállaló" />
)

export const TaxNameSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.taxName" defaultMessage="Adó, járulék neve" />
)

export const CommentSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.comment" defaultMessage="Megjegyzés" />
)

export const PaidThroughSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.paidThrough" defaultMessage="Pénztárca" />
)

export const FilenameSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.filename" defaultMessage="Eredeti fájlnév" />
)

export const TitleSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.title" defaultMessage="Név" />
)

export const FilingNumberSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.filingNumber" defaultMessage="Iktatószám" />
)

export const SecondaryIdSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.secondaryId" defaultMessage="Másodlagos azonosító" />
)

//* SELECTABLE FILTERS

export const PaidThroughsFilterLabelMessage = (
  <FormattedMessage id="paidThroughFilter.popover.headings.label" defaultMessage="Pénztárca" />
)

export const PaidThroughsFilterSelectedLabelMessage = (
  <FormattedMessage id="paidThroughFilter.popover.headings.selected" defaultMessage="pénztárcák" />
)

export const PaidThroughsFilterEmptyPlaceholderMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchAllPlaceholder" defaultMessage="Összes" />
)
export const TagFilterLabelMessage = <FormattedMessage id="tagsFilter.popover.headings.tag" defaultMessage="Címke" />

export const TagFilterEmptyPlaceholderMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchAllTagPlaceholder" defaultMessage="Minden címke" />
)

export const RevenueTypeLabelMessage = (
  <FormattedMessage id="tagsFilter.popover.headings.revenueType" defaultMessage="Kategória" />
)

export const RevenueTypeFilterEmptyPlaceholderMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchAllRevenueTypePlaceholder" defaultMessage="Minden kategória" />
)

//* DIALOG FILTER MESSAGES

export const StatusLabelMessage = <FormattedMessage id="filters.label.status" defaultMessage="Státusz" />

export const OriginLabelMessage = <FormattedMessage id="filters.label.origin" defaultMessage="Forrás" />

export const PaidThroughLabelMessage = <FormattedMessage id="filters.label.paidThrough" defaultMessage="Pénztárca" />

export const PaymentMethodLabelMessage = (
  <FormattedMessage id="filters.label.paymentMethod" defaultMessage="Fizetés módja" />
)

export const VatAreaLabelMessage = <FormattedMessage id="filters.label.vatArea" defaultMessage="Területi hatály" />

export const DeselectAllMessage = (
  <FormattedMessage id="filterBar.text.deselectAll" defaultMessage="Kijelölések elvetése" />
)

export const SelectAllMessage = <FormattedMessage id="filterBar.text.selectAll" defaultMessage="Minden kijelölése" />

//* STATUS FILTER MESSAGES

export const NewStatusMessage = <FormattedMessage id="filters.label.nootSeenYet" defaultMessage="Új" />

export const UpdatedStatusMessage = <FormattedMessage id="filters.label.updated" defaultMessage="Frissült" />

export const NoArtifactStatusMessage = (
  <FormattedMessage id="filters.label.noArtifact" defaultMessage="Nincs számlakép" />
)

export const HasArtifactStatusMessage = (
  <FormattedMessage id="filters.label.hasArtifact" defaultMessage="Van számlakép" />
)

export const ReaydToSyncStatusMessage = (
  <FormattedMessage id="filters.label.readyToSync" defaultMessage="Számlakép átadható" />
)

export const SzamlazzReaydToSyncStatusMessage = (
  <FormattedMessage id="filters.label.readyToSync.szamlazz" defaultMessage="Számlakép átadható Számlaverzum felé" />
)

export const AcountoReaydToSyncStatusMessage = (
  <FormattedMessage id="filters.label.readyToSync.acounto" defaultMessage="Számlakép átadható Acounto felé" />
)

export const ApprovedStatusMessage = <FormattedMessage id="filters.label.approved" defaultMessage="Jóváhagyva" />

export const NotApprovedStatusMessage = (
  <FormattedMessage id="filters.label.notApproved" defaultMessage="Nincs jóváhagyva" />
)

export const DuplicatedStatusMessage = <FormattedMessage id="filters.label.duplicated" defaultMessage="Duplikált" />

export const UncategorizedStatusMessage = (
  <FormattedMessage id="filters.label.uncategorized" defaultMessage="Kategorizálatlan" />
)

export const HasPostItMessage = <FormattedMessage id="filters.label.hasPostIt" defaultMessage="Van megjegyzés" />

export const NoPostItMessage = <FormattedMessage id="filters.label.noPostIt" defaultMessage="Nincs megjegyzés" />

export const HasAttachmentMessage = (
  <FormattedMessage id="filters.label.hasAttachment" defaultMessage="Van csatolmány" />
)

export const NoAttachmentMessage = (
  <FormattedMessage id="filters.label.noAttachment" defaultMessage="Nincs csatolmány" />
)

export const SzamlazzOriginMessage = <FormattedMessage id="filters.label.szamlazz" defaultMessage="Számlázz.hu" />

export const SzamlazzNAVOriginMessage = (
  <FormattedMessage id="filters.label.nav.szamlazz" defaultMessage="Számlázz.hu - NAV" />
)

export const BillingoOriginMessage = <FormattedMessage id="filters.label.billingo" defaultMessage="Billingo" />

export const VasfalaNAVOriginMessage = (
  <FormattedMessage id="filters.label.nav.nosz" defaultMessage="NAV Online Számla" />
)

export const EmailOriginMessage = <FormattedMessage id="filters.label.email" defaultMessage="E-mail" />

export const UploadOriginMessage = <FormattedMessage id="filters.label.upload" defaultMessage="Feltöltött" />

export const PaidStatusLabelMessage = <FormattedMessage id="filters.label.paidStatus" defaultMessage="Fizetettség" />

export const MissingStatusMessage = <FormattedMessage id="filters.label.missing" defaultMessage="Adathiányos" />

export const FiledStatusMessage = <FormattedMessage id="filters.label.filed" defaultMessage="Iktatva" />

export const NotFiledStatusMessage = <FormattedMessage id="filters.label.notFiled" defaultMessage="Nincs iktatva" />

//* ACCOUNTING FILTERS
// express: (=exported) - Könyvelt vs. advanced (=exported) - Exportált
export const AccountingExpressExportedStatusMessage = (
  <FormattedMessage id="filters.label.accounting.exported.express" defaultMessage="Könyvelt" />
)

export const AccountingAdvancedExportedStatusMessage = (
  <FormattedMessage id="filters.label.accounting.exported.advanced" defaultMessage="Exportált" />
)

export const AccountingUnprocessedStatusMessage = (
  <FormattedMessage id="filters.label.accounting.unprocessed" defaultMessage="Feldolgozatlan" />
)

export const AccountingProcessedStatusMessage = (
  <FormattedMessage id="filters.label.accounting.processed" defaultMessage="Feldolgozott" />
)

export const HasAccountingTodoStatusMessage = (
  <FormattedMessage id="filters.label.hasAccountingTodo" defaultMessage="Van könyvelési teendő" />
)

export const NoAccountingTodoStatusMessage = (
  <FormattedMessage id="filters.label.noAccountingTodo" defaultMessage="Nincs könyvelési teendő" />
)

export const HasUnfinishedAccountingTodoStatusMessage = (
  <FormattedMessage id="filters.label.hasUnfinishedAccountingTodo" defaultMessage="Nyitott teendő" />
)

export const AccountingTodoDoneStatusMessage = (
  <FormattedMessage id="filters.label.accountingTodoDone" defaultMessage="Elvégzett könyvelési teendő" />
)

export const ExcludedFromAccountingStatusMessage = (
  <FormattedMessage id="filters.label.excludedFromAccounting" defaultMessage="Könyvelésből kizárva" />
)

export const DisabledDateFilterValueMessage = (
  <FormattedMessage id="dateFilter.value.disabled" defaultMessage="Minden dátum" />
)

export const LedgerNumberMessage = (
  <FormattedMessage id="filters.label.ledgerNumber" defaultMessage="Kontír tétel száma" />
)

export const PostItMessage = <FormattedMessage id="filters.label.postIt" defaultMessage="Megjegyzés" />

export const InvoiceItemNameMessage = (
  <FormattedMessage id="filters.label.invoiceItemName" defaultMessage="Tétel neve" />
)

export const DefaultFilterOptionsMessage = (
  <FormattedMessage id="filters.label.presets.defaults" defaultMessage="Alapértelmezett" />
)

export const AllFilterOptionsMessage = (
  <FormattedMessage id="filters.label.presets.all" defaultMessage="Mindenben keres" />
)

export const KeywordsSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.keywords" defaultMessage="Kulcsszavak" />
)

export const SenderSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.sender" defaultMessage="Feladó" />
)

export const RecipientSearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.recipient" defaultMessage="Címzett" />
)

export const SummarySearchMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchField.summary" defaultMessage="Összefoglaló" />
)

export const ContainsPayableFilterMessage = (
  <FormattedMessage id="filters.label.containsPayable" defaultMessage="Fizetendőt tartalmaz" />
)

export const DocumentTypeLabelMessage = (
  <FormattedMessage id="tagsFilter.popover.headings.documentType" defaultMessage="Típus" />
)

export const DocumentTypeFilterEmptyPlaceholderMessage = (
  <FormattedMessage id="filterBar.searchFilter.searchAllDocumentTypePlaceholder" defaultMessage="Minden típus" />
)

export const AiRecognitionFilterMessage = (
  <FormattedMessage id="filters.label.aiRecognitionAllowed" defaultMessage="Feldolgozás indítható" />
)
