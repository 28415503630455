import React from 'react'
import PropTypes from 'prop-types'

import { Currency } from '@components/ui/FormattedTexts'

import { LabelTypography, OverviewItemParagraph, StyledCopyAndPasteButton, ValueTypography } from './styles'

export interface OverviewItemProps {
  label: StringOrMessage
  percent?: number
  value: number
}

export interface OverviewItemWithCopyProps extends OverviewItemProps {
  canCopy?: boolean
}

export function OverviewItem({ canCopy = false, label, value, percent }: OverviewItemWithCopyProps) {
  const absValue = Math.abs(value)
  const formattedValue = <Currency value={absValue} />
  return (
    <OverviewItemParagraph>
      <LabelTypography color="gray-80" size="400-sm" tag="span">
        {label}
      </LabelTypography>
      <ValueTypography color="gray-80" size="700-xs" tag="span">
        <>
          {canCopy ? (
            <StyledCopyAndPasteButton text={String(absValue)} noWrap>
              {formattedValue}
            </StyledCopyAndPasteButton>
          ) : (
            formattedValue
          )}
          {percent != null && <span>{percent}%</span>}
        </>
      </ValueTypography>
    </OverviewItemParagraph>
  )
}

OverviewItem.propTypes = {
  canCopy: PropTypes.bool,
  label: PropTypes.node.isRequired,
  percent: PropTypes.number,
  value: PropTypes.number.isRequired,
}
