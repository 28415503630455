import styled from 'styled-components'

import { CustomDialogActions, CustomDialogBody } from '@components/ui/Dialogs'
import { Typography } from '@components/ui/Typography'

export const FormDialogBody = styled(CustomDialogBody)`
  padding: 0;
`

export const DialogResponseWrapper = styled.div`
  @keyframes resize {
    from {
      min-height: var(--min-height-dialog-response, auto);
    }

    to {
      min-height: 0px;
    }
  }

  animation: resize 0.3s;
  width: var(--width-dialog-response, auto);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;

  ${Typography} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DialogResponseActions = styled(CustomDialogActions)`
  padding-top: 0;
  border-top: none;
`
