import React from 'react'
import { func, shape, string } from 'prop-types'

import { injectIntl } from 'react-intl'

import { getBraintreeError } from './validator'

// eslint-disable-next-line react/display-name
export const BraintreeErrorMessage = React.memo(({ className, error, intl: { formatMessage } }) => {
  const translatedError = getBraintreeError(error)

  const errorMessage = translatedError ? formatMessage(translatedError) : error.message

  return <div className={className}>{errorMessage}</div>
})

BraintreeErrorMessage.propTypes = {
  error: shape({
    code: string.isRequired,
    message: string,
  }).isRequired,
  className: string,
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
}

export default injectIntl(BraintreeErrorMessage)
