import React from 'react'

import __isEqual from 'lodash/isEqual'

export function useMemoizedState<StateValue>(initialValue: StateValue): [StateValue, (val: StateValue) => void] {
  const [state, _setState] = React.useState<StateValue>(initialValue)

  const setState = React.useCallback((newState: StateValue) => {
    _setState(prevState => {
      if (!__isEqual(newState, prevState)) {
        return newState
      } else {
        return prevState
      }
    })
  }, [])

  return [state, setState]
}
