import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import React from 'react'
import PropTypes from 'prop-types'

import { pdfjs } from 'react-pdf'
import styled from 'styled-components'

import { FileControls } from '@components/DocViewer/elements/renderers/FileControls'
import { DocRenderer, DocViewerMainState } from '@components/DocViewer/types'

import { PreviewControls } from '../PreviewControls'
import { PDFControls } from './PDFControls'
import { PDFControlsProvider } from './PDFControlsProvider'
import { PDFPages } from './PDFPages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
`

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`

export const PDFRenderer: DocRenderer = ({ hideFileControls, mainState }) => {
  return (
    <PDFControlsProvider mainState={mainState}>
      <Container>
        <PDFPages />
      </Container>
      <PreviewControls>
        <PDFControls />
        {!hideFileControls && <FileControls mainState={mainState} />}
      </PreviewControls>
    </PDFControlsProvider>
  )
}

PDFRenderer.fileTypes = ['pdf', 'application/pdf']

PDFRenderer.propTypes = {
  hideFileControls: PropTypes.bool,
  mainState: PropTypes.object.isRequired as React.Validator<DocViewerMainState>,
}
