import { ZoomOrientation, ZoomState } from '@components/DocViewer/constant'
import { DocViewerMainState } from '@components/DocViewer/types'

import { A4_PAPER_SIZE } from '@constants'

import { initialPDFState } from './reducer'

export function getDefaultZoomLevel(orientation: ZoomOrientation, mainState?: DocViewerMainState) {
  if (!mainState?.rendererRect) {
    return mainState?.config?.zoom?.defaultZoom ?? initialPDFState.defaultZoomLevel
  }

  return orientation === ZoomOrientation.LANDSCAPE
    ? (mainState.rendererRect.width - 20) / A4_PAPER_SIZE.width
    : (mainState.rendererRect.height - 20) / A4_PAPER_SIZE.height
}

export function getZoomResetState({
  zoomOrientation,
  zoomLevel,
  defaultZoomLevel,
}: {
  zoomOrientation: ZoomOrientation
  zoomLevel: number
  defaultZoomLevel: number
}) {
  if (zoomLevel !== defaultZoomLevel) {
    return ZoomState.RESET
  }
  if (zoomOrientation === ZoomOrientation.LANDSCAPE) {
    return ZoomState.CHANGE_TO_PORTRAIT
  }
  return ZoomState.CHANGE_TO_LANDSCAPE
}
