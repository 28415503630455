import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

import { generateMaxHeightBreakPoint } from '@constants'

const styles: Styles<Theme, Record<never, never>> = theme => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    justifyContent: 'space-between',
    minHeight: theme.appBarHeight,
    [generateMaxHeightBreakPoint('medium')]: {
      minHeight: theme.appBarHeightMedium,
    },
  },
  centeredLogo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',

    '& a': {
      display: 'inherit',

      '& > svg': {
        height: 46,
        width: 'auto',
      },
    },
  },
})

export default styles
