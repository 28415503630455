import React from 'react'
import PropTypes from 'prop-types'

import { FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import Tooltip from './LightTooltip'
import UpsellMessage from './UpsellMessage'

import { formStyles } from '@styles'

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  root: {
    marginTop: '1.2rem',
  },
  input: {
    backgroundColor: '#F1F1F1',
    color: '#AAA',
  },
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    position: 'absolute',
    top: 0,
    left: 0,
    transformOrigin: 'top left',
    color: theme.palette.grayscale.inputLabel,
    lineHeight: 1,
  },
}))

export function FakeInput({ label, margin = 'none', placeholder, title, noUpsellMessage = false }) {
  const classes = useStyles()

  const tooltipTitle = noUpsellMessage ? title : title || <UpsellMessage />

  const content = tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <div className={cx(classes.root, classes.bootstrapRoot)}>
        <div className={cx(classes.bootstrapInput, classes.input)}>{placeholder}</div>
      </div>
    </Tooltip>
  ) : (
    <div className={cx(classes.root, classes.bootstrapRoot)}>
      <div className={cx(classes.bootstrapInput, classes.input)}>{placeholder}</div>
    </div>
  )

  return (
    <FormControl fullWidth className="form-control" margin={margin}>
      <div className={cx(classes.bootstrapFormLabel, classes.label)}>{label}</div>
      {content}
    </FormControl>
  )
}

FakeInput.propTypes = {
  label: PropTypes.node.isRequired,
  margin: PropTypes.oneOf(['dense', 'normal', 'none']),
  noUpsellMessage: PropTypes.bool,
  placeholder: PropTypes.node.isRequired,
  title: PropTypes.node,
}

const useTableStyles = makeStyles(theme => ({
  ...formStyles(theme),
  root: {
    height: 30,
    fontSize: 14,
    lineHeight: '16px',
    marginTop: 0,
  },
  input: {
    backgroundColor: '#F1F1F1',
    color: '#AAA',
    fontSize: 'inherit',
  },
}))

export function FakeTableInput({ placeholder, title, noUpsell = false }) {
  const classes = useTableStyles()

  const tooltipTitle = noUpsell ? title : title || <UpsellMessage />

  const content = tooltipTitle ? (
    <Tooltip title={title} PopperProps={{ disablePortal: true }}>
      <div className={cx(classes.root, classes.bootstrapRoot)}>
        <div className={cx(classes.bootstrapInput, classes.input)}>{placeholder}</div>
      </div>
    </Tooltip>
  ) : (
    <div className={cx(classes.root, classes.bootstrapRoot)}>
      <div className={cx(classes.bootstrapInput, classes.input)}>{placeholder}</div>
    </div>
  )

  return (
    <FormControl fullWidth className="form-control">
      {content}
    </FormControl>
  )
}

FakeTableInput.propTypes = {
  placeholder: PropTypes.node.isRequired,
  title: PropTypes.node,
  noUpsell: PropTypes.bool,
}
