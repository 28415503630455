import React from 'react'

export function useMeasure(): readonly [
  ref: React.RefObject<HTMLDivElement>,
  bounds: {
    x: number
    y: number
    width: number
    height: number
    top: number
    right: number
    bottom: number
    left: number
  }
] {
  const [bounds, set] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  })
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (ref.current === null) return

    let timeoutId: number | undefined

    const ro = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
      window.clearTimeout(timeoutId)
      timeoutId = window.setTimeout(() => {
        set(entry.contentRect)
      }, 100) // Adjust debounce delay as needed
    })
    ro.observe(ref.current)
    return () => {
      ro.disconnect()
      window.clearTimeout(timeoutId)
    }
  }, [])

  return [ref, bounds] as const
}
