//! TODO Deprecated - remove this component from app
import React from 'react'

import { CircularProgress } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import DoneIcon from '@material-ui/icons/Done'

import { ButtonProps } from './Button'
import { ButtonWithIcon } from './ButtonWithIcon'

const CLEAR_TIME = 1800 // in ms

interface ProgressButtonProps extends ButtonProps {
  loading: boolean
  succeed: boolean
  failed: boolean
}

// TODO make smoother state change (animation)
// TODO design fix? - do not change size when state changes
export default function ProgressButton({ disabled, loading, succeed, failed, ...rest }: ProgressButtonProps) {
  const [icon, setIcon] = React.useState<React.ReactChild | undefined>(undefined)
  const timerRef = React.useRef<number | undefined>(undefined)

  React.useEffect(() => {
    if (loading) {
      setIcon(<CircularProgress size={16} color="inherit" />)
    } else if (succeed) {
      setIcon(<DoneIcon color="inherit" fontSize="inherit" />)
      timerRef.current = window.setTimeout(() => setIcon(undefined), CLEAR_TIME)
    } else if (failed) {
      setIcon(<ClearIcon color="inherit" fontSize="inherit" />)
      timerRef.current = window.setTimeout(() => setIcon(undefined), CLEAR_TIME)
    } else {
      setIcon(undefined)
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [failed, loading, succeed])

  return <ButtonWithIcon icon={icon} disabled={disabled || loading} {...rest} />
}
