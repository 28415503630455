import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { dashboardActions } from '@services'

import { usePrevious } from '@hooks/usePrevious'

import { TableCell } from '@components/ui/TableElements'
import { Typography } from '@components/ui/Typography'

import { DATA_TABS } from '@constants'

import { AnimatedPlugCircleBoltRegularIcon, NotificationTableRow, StyledFlexRow } from './styles'

enum View {
  SYNCING = 'syncing',
  PROCESSING = 'processing',
  HIDDEN = 'hidden',
}

function getView({ processing, syncing }: { processing: number; syncing: boolean }) {
  if (syncing) {
    return View.SYNCING
  }
  if (processing > 0) {
    return View.PROCESSING
  }
  return View.HIDDEN
}

function getViewIcon(view: View) {
  switch (view) {
    case View.SYNCING:
      return <AnimatedPlugCircleBoltRegularIcon />
    case View.PROCESSING:
    default:
      return <CircularProgress color="inherit" size={16} />
  }
}

function getViewText(view: View, values: { count: number }) {
  switch (view) {
    case View.SYNCING:
      return (
        <FormattedMessage
          id="transactions.notifications.syncing"
          defaultMessage="Éppen kapcsolódunk a Számlázz.hu fiókodhoz, ez eltarthat egy kis ideig"
        />
      )
    case View.PROCESSING:
      return (
        <FormattedMessage
          id="transactions.notifications.processing"
          defaultMessage="{count} db számla feldolgozása folyamatban"
          values={values}
        />
      )
  }
}

type NotificationDataType = Exclude<ConstObjectValues<typeof DATA_TABS>, 'salary' | 'tax' | 'dokuments'>

interface TransactionsNotificationRowProps {
  checkProcessStatus: VoidFunction
  colSpan: number
  count: number
  dataType: NotificationDataType
  onlySyncViewAllowed?: boolean
  refreshPage: (dataType: NotificationDataType) => void
  view: View
}

function BaseTransactionsNotificationRow({
  checkProcessStatus,
  colSpan,
  count,
  dataType,
  onlySyncViewAllowed = false,
  refreshPage,
  view,
}: TransactionsNotificationRowProps) {
  const intervalRef = React.useRef<number>()
  const prevView = usePrevious(view)
  const prevCount = usePrevious(count)

  const isHidden = view === View.HIDDEN || (onlySyncViewAllowed && view !== View.SYNCING)

  // checkProcessStatus control
  React.useEffect(() => {
    checkProcessStatus()
  }, [checkProcessStatus])

  React.useEffect(() => {
    if (intervalRef.current && isHidden) {
      window.clearInterval(intervalRef.current)
    }

    if (!intervalRef.current && !isHidden) {
      intervalRef.current = window.setInterval(() => checkProcessStatus(), 60000) // 1min
    }

    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current)
      }
    }
  }, [checkProcessStatus, isHidden, onlySyncViewAllowed])

  // refreshPage control
  React.useEffect(() => {
    if ((prevView && prevView !== view) || (prevCount && prevCount !== count)) {
      refreshPage(dataType)
    }
  }, [count, dataType, prevCount, prevView, refreshPage, view])

  if (isHidden) {
    return null
  }

  return (
    <NotificationTableRow data-testid="transactions-notification-message">
      <TableCell colSpan={colSpan}>
        <StyledFlexRow>
          {getViewIcon(view)}
          <Typography size="400-sm" color="gray-70" align="center">
            {getViewText(view, { count })}
          </Typography>
        </StyledFlexRow>
      </TableCell>
    </NotificationTableRow>
  )
}

BaseTransactionsNotificationRow.propTypes = {
  checkProcessStatus: PropTypes.func.isRequired,
  colSpan: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  dataType: PropTypes.oneOf([DATA_TABS.expense, DATA_TABS.quarantine, DATA_TABS.income])
    .isRequired as PropTypes.Validator<NotificationDataType>,
  onlySyncViewAllowed: PropTypes.bool,
  refreshPage: PropTypes.func.isRequired,
  view: PropTypes.oneOf(Object.values(View)).isRequired,
}

export const TransactionsNotificationRow = connect(
  ({
    dashboard: {
      counts: { processing, show_szamlazz_sync_ribbon },
    },
  }: Store) => ({
    count: processing,
    view: getView({ processing, syncing: show_szamlazz_sync_ribbon }),
  }),
  {
    checkProcessStatus: dashboardActions.dashboardStatusCheck.request,
    refreshPage: dashboardActions.refreshTransactionsPage.request,
  }
)(BaseTransactionsNotificationRow)

TransactionsNotificationRow.displayName = 'TransactionsNotificationRow'
