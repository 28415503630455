import __get from 'lodash/get'
import FacebookPixel from 'react-facebook-pixel'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { localeActions, onboardingActions } from '@services'

import {
  AMPLITUDE_EVENTS,
  changeLanguage,
  getActiveCompanyId,
  getCompanyType,
  getDefaultUserCompanyId,
  getErrorMessage,
  getFormErrors,
  getIndustry,
  getRFFFormErrors,
  sendAmplitudeData,
  setAmplitudeUser,
  setUserProperties,
} from '@helpers'

import { CompanyIntegrationProviders } from '@constants'

import { callUrl } from '../common/api'
import { clearToken, setToken } from '../helpers'
import actions from './actions'
import * as api from './api'

export function* renewTokenSaga() {
  try {
    const response = yield call(api.renewToken)
    yield call(setToken, response.data)
    yield put(actions.renewToken.success())
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.renewToken.failure(errorMsg))
    // console.error('Renew token failed:' + errorMsg)
  }
}

// worker Saga: will be fired on types.vatRatesFetchRequested actions
export function* changePasswordSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(api.changePassword, payload)
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* login({ payload, meta: { resolve, reject } }, amplitudeEvent) {
  try {
    const response = yield call(api.login, payload)
    const { need_verification = false } = response.data
    const { remember_me } = payload

    if (need_verification) {
      // pass remember_me back to 2FactorAuth
      yield call(resolve, { ...response.data, remember_me })
    } else {
      yield call(setToken, response.data)
      const user = yield call(api.fetchUser)
      yield put(actions.fetchUser.success(user.data))
      yield put(localeActions.updateLocale.request(user.data.user.default_language))
      const companyId = yield select(getDefaultUserCompanyId)
      if (companyId) {
        yield put(actions.selectCompany.request(companyId))
      }
      // --amplitude tracking start
      yield call(setAmplitudeUser, __get(user, ['data', 'id'], null))
      yield call(sendAmplitudeData, amplitudeEvent, { success: true })
      // --amplitude tracking end
      yield put(actions.login.success())
      yield call(resolve)
    }
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.login.failure())
    // --amplitude tracking start
    yield call(sendAmplitudeData, amplitudeEvent, { success: false })
    // --amplitude tracking end
    yield call(reject, formErrors)
  }
}

export function* regularLoginSaga(data) {
  yield call(login, data, AMPLITUDE_EVENTS.LOGIN)
}

export function* onboardingManualLoginSaga(data) {
  yield call(login, data, AMPLITUDE_EVENTS.ONBOARDING_MANUAL_LOGIN)
}

export function* onboardingAutoLoginSaga({ payload: { auth_token, expires, companyId }, meta: { resolve, reject } }) {
  try {
    yield call(setToken, { auth_token, expires })
    const user = yield call(api.fetchUser)
    yield put(actions.fetchUser.success(user.data))
    const selectedCompanyId = companyId ? companyId : yield select(getDefaultUserCompanyId)
    if (selectedCompanyId) {
      // need to call here so the funcation can be awaited
      const response = yield call(api.fetchCompanyDetails, selectedCompanyId)
      yield put(actions.selectCompany.success(response.data))
    }
    // --amplitude tracking start
    yield call(setAmplitudeUser, __get(user, ['data', 'id'], null))
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.ONBOARDING_AUTO_LOGIN, { success: true })
    // --amplitude tracking end
    yield put(actions.login.success())
    yield call(resolve)
  } catch (error) {
    yield put(actions.login.failure())
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.ONBOARDING_AUTO_LOGIN, { success: false })
    // --amplitude tracking end
    yield call(reject)
  }
}

export function* logoutSaga() {
  try {
    yield call(api.logout)
    yield call(clearToken)
    // --amplitude tracking start
    yield call(setAmplitudeUser, null)
    // --amplitude tracking end
    yield put(actions.logout.success())
  } catch (error) {
    // silent error
  }
}

// same as logout but do not call api.logout (change password success callback)
export function* forceToLoginSaga() {
  try {
    yield call(clearToken)
    // --amplitude tracking start
    yield call(setAmplitudeUser, null)
    // --amplitude tracking end
    yield put(actions.logout.success())
  } catch (error) {
    // silent error
  }
}

export function* resetPasswordSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(api.resetPassword, payload)
    yield put(actions.resetPassword.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.resetPassword.failure())
    yield call(reject, formErrors)
  }
}

export function* resetPasswordConfirmSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(api.resetPasswordConfirm, payload)
    yield put(actions.resetPasswordConfirm.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.resetPasswordConfirm.failure())
    yield call(reject, formErrors)
  }
}

export function* signupSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.signup, payload)
    yield call(setToken, response.data)
    const user = yield call(api.fetchUser)
    yield put(actions.fetchUser.success(user.data))
    yield put(localeActions.updateLocale.request(user.data.user.default_language))
    const companyId = yield select(getDefaultUserCompanyId)
    if (companyId) {
      yield put(actions.selectCompany.request(companyId))
    }
    // --amplitude tracking start
    yield call(setAmplitudeUser, __get(user, ['data', 'id'], null))
    const company_type = yield select(getCompanyType, __get(payload, ['companies', 0, 'company_type'], null))
    const industry = yield select(getIndustry, __get(payload, ['companies', 0, 'industry'], null))
    yield call(setUserProperties, { company_type, industry })
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.REGISTRATION_SUCCESS)
    // --amplitude tracking end
    // -- Facebook Pixel track CompleteRegistration
    yield call(FacebookPixel.track, 'CompleteRegistration')
    yield put(actions.signup.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.signup.failure())
    yield call(reject, formErrors)
  }
}

// INVITE
export function* loadInvitationDetailsSaga({ payload }) {
  try {
    const response = yield call(api.loadInvitationDetails, payload)
    yield put(actions.loadInvitationDetails.success(response.data))
  } catch (error) {
    yield put(actions.loadInvitationDetails.failure())
  }
}
// similar as sign up
export function* acceptInvitationSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.acceptInvitation, payload)
    yield call(setToken, response.data)
    const user = yield call(api.fetchUser)
    yield put(actions.fetchUser.success(user.data))
    const companyId = yield select(getDefaultUserCompanyId)
    if (companyId) {
      yield put(actions.selectCompany.request(companyId))
    }
    // --amplitude tracking start
    yield call(setAmplitudeUser, __get(user, ['data', 'id'], null))
    const company_type = yield select(getCompanyType, __get(payload, ['companies', 0, 'company_type'], null))
    const industry = yield select(getIndustry, __get(payload, ['companies', 0, 'industry'], null))
    yield call(setUserProperties, { company_type, industry })
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.INVITATION_SUCCESS)
    // --amplitude tracking end
    // -- Facebook Pixel track CompleteRegistration
    yield call(FacebookPixel.track, 'CompleteRegistration')
    yield put(actions.acceptInvitation.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.acceptInvitation.failure())
    yield call(reject, formErrors)
  }
}

// USER
export function* userSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.fetchUser)
    yield put(localeActions.setLocale.request(response.data.user.default_language))
    // --amplitude tracking start
    yield call(setAmplitudeUser, __get(response, ['data', 'id'], null))
    // --amplitude tracking end
    yield delay(1000) // wait 1 sec before call api (avoid flashing screen)
    yield put(actions.fetchUser.success(response.data))
    if (payload?.companyId) {
      yield put(actions.selectCompany.request(payload.companyId))
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchUser.failure())
    yield call(reject, errorMsg)
  }
}

// USER BADGE
export function* updateBadgeDisplayedAtSaga({ payload }) {
  try {
    const response = yield call(api.updateBadgeDisplayedAt, payload)
    yield put(actions.updateBadgeDisplayedAt.success(response.data))
  } catch (error) {
    yield put(actions.updateBadgeDisplayedAt.failure())
  }
}

// USER NOTIFICATIONS
export function* updateNotificationDisplayedAtSaga({ payload }) {
  try {
    yield call(api.updateNotificationDisplayedAt, payload)
    yield put(actions.updateNotificationDisplayedAt.success(payload))
  } catch (error) {
    yield put(actions.updateNotificationDisplayedAt.failure())
  }
}

// USER COMPANIES
export function* userCompaniesSaga() {
  try {
    const response = yield call(api.fetchUserCompanies)
    yield put(actions.fetchUserCompanies.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchUserCompanies.failure(errorMsg))
  }
}

// data is a User object
export function* removeAccountSaga({ payload: { data }, meta: { resolve, reject } }) {
  try {
    yield call(callUrl, { url: data.links.delete, method: 'delete' })
    yield call(clearToken)
    // --amplitude tracking start
    yield call(setAmplitudeUser, null)
    // --amplitude tracking end
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// change and set language when change user profile
export function* updatePersonalInfoSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.updatePersonalInfo, payload)
    yield put(actions.updatePersonalInfo.success(response.data))
    yield call(changeLanguage, response.data.default_language)
    yield call(resolve)
    yield delay(1000)
    yield put(localeActions.updateLocale.request(response.data.default_language))
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

// change user preferences
export function* changePreferencesSaga({ payload }) {
  try {
    const response = yield call(api.changeUserPreferences, payload)
    yield put(actions.changePreferences.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.changePreferences.failure(errorMsg))
  }
}
export function* restorePreferencesSaga({ meta: { resolve } }) {
  try {
    const response = yield call(api.restoreUserPreferences)
    yield put(actions.changePreferences.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.changePreferences.failure(errorMsg))
  }
}

// COMPANY
export function* refreshCompanySaga({ payload }) {
  let companyId = payload
  if (!companyId) {
    companyId = yield select(getActiveCompanyId)
  }
  const response = yield call(api.fetchCompanyDetails, companyId)
  yield put(actions.fetchCompany.success(response.data))
}

export function* refreshCompanyAsyncSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(refreshCompanySaga, payload)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchCompany.failure())
    yield call(reject, errorMsg)
  }
}

export function* selectCompanySaga({ payload }) {
  try {
    const response = yield call(api.fetchCompanyDetails, payload)
    yield put(actions.selectCompany.success(response.data))
  } catch (error) {
    yield put(actions.selectCompany.failure(error.message))
  }
}

// MULTI COMPANY
export function* createCompanySaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.createCompany, payload)
    yield put(actions.createCompany.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* updateCompanySaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateCompany, { id: companyId, ...payload })
    yield put(actions.updateCompany.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}
// RFF version of updateCompany
export function* updateCompanyRffSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateCompany, { id: companyId, ...payload })
    yield put(actions.updateCompany.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* updateCompanyFieldSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateCompany, { id: companyId, ...payload })
    yield put(actions.updateCompanyField.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* removeCompanySaga({ payload: { company }, meta: { resolve, reject } }) {
  try {
    yield call(callUrl, { url: company.links.delete, method: 'delete' })
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// COMPANY MEMBER
export function* fetchCompanyMembersSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchCompanyMembers, companyId)
    yield put(actions.fetchCompanyMembers.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchCompanyMembers.failure(errorMsg))
  }
}
export function* createCompanyMemberSaga({ payload: { isInvited, ...restPayload }, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createCompanyMember, companyId, restPayload)
    yield put(actions.createCompanyMember.success(response.data))
    // if it is an invite approve, refetch invites as well
    // isInvited is created as initialValue in `<InvitesTable />`
    if (isInvited) {
      yield put(onboardingActions.fetchOnboardingInviteApprovals.request())
    }
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* updateCompanyMemberSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateCompanyMember, companyId, payload)
    yield put(actions.updateCompanyMember.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* removeCompanyMemberSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = payload.company.id
    const companyUserId = payload.data.id
    yield call(api.removeCompanyMember, companyId, companyUserId)
    yield put(actions.removeCompanyMember.success(companyUserId))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.removeCompanyMember.failure())
    yield call(reject, errorMsg)
  }
}

export function* removeCompanyMembershipSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = payload.company.id
    const companyUserId = payload.data.id
    yield call(api.removeCompanyMember, companyId, companyUserId)
    yield put(actions.removeCompanyMembership.success(companyUserId))
    yield call(resolve, companyId)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// COMPANY VAT
export function* fetchCompanyVatsSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchCompanyVats, companyId)
    yield put(actions.fetchCompanyVats.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchCompanyVats.failure(errorMsg))
  }
}
export function* createCompanyVatSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createCompanyVat, companyId, payload)
    yield put(actions.createCompanyVat.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.createCompanyVat.failure())
    yield call(reject, formErrors)
  }
}

export function* updateCompanyVatSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateCompanyVat, companyId, payload)
    yield put(actions.updateCompanyVat.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.updateCompanyVat.failure())
    yield call(reject, formErrors)
  }
}

export function* removeCompanyVatSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = payload.company.id
    const companyVatId = payload.data.id
    yield call(api.removeCompanyVat, companyId, companyVatId)
    yield put(actions.removeCompanyVat.success(companyVatId))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.removeCompanyVat.failure())
    yield call(reject, errorMsg)
  }
}
export function* createIntegrationProviderSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createIntegrationProvider, companyId, payload)
    yield put(actions.createIntegrationProvider.success(response.data))
    // refresh company details
    yield call(refreshCompanySaga, companyId)
    yield call(resolve, response.data) // need for local state update
  } catch (error) {
    if (payload.integration === CompanyIntegrationProviders.szamlazz) {
      // no form in SzamlazzHu flow
      const errorMsg = getErrorMessage(error)
      yield call(reject, errorMsg)
    } else {
      const formErrors = getRFFFormErrors(error)
      yield call(reject, formErrors)
    }
  }
}

export function* updateIntegrationProviderSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateIntegrationProvider, companyId, payload)
    yield put(actions.updateIntegrationProvider.success(response.data))
    yield call(resolve, response.data) // need for local state update
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* loadIntegrationProvidersSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.loadIntegrationProviders, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// 2 FACTOR AUTH
export function* getAuthStatusSaga({ meta: { resolve, reject } }) {
  try {
    const response = yield call(api.getAuthStatus)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* getAuthDataSaga({ meta: { resolve, reject } }) {
  try {
    const response = yield call(api.getAuthData)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* validateDeviceSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.validateDevice, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* updateBackupTokensSaga({ meta: { resolve, reject } }) {
  try {
    const response = yield call(api.updateBackupTokens)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* clearTwoFactorAuthSaga({ meta: { resolve, reject } }) {
  try {
    yield call(api.clearTwoFactorAuth)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* TAXATION
export function* setTaxationTaxFormSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.taxationSetTaxForm, companyId, payload)
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

// watcher Saga
export default function* commonSaga() {
  // RENEW TOKEN
  yield takeLatest(actions.renewToken.REQUEST, renewTokenSaga)
  // AUTH
  yield takeLatest(actions.changePassword.REQUEST, changePasswordSaga)
  yield takeLatest(actions.login.REQUEST, regularLoginSaga)
  yield takeLatest(actions.onboardingManualLogin.REQUEST, onboardingManualLoginSaga)
  yield takeLatest(actions.onboardingAutoLogin.REQUEST, onboardingAutoLoginSaga)
  yield takeLatest(actions.logout.REQUEST, logoutSaga)
  yield takeLatest(actions.forceToLogin.REQUEST, forceToLoginSaga)
  yield takeLatest(actions.resetPassword.REQUEST, resetPasswordSaga)
  yield takeLatest(actions.resetPasswordConfirm.REQUEST, resetPasswordConfirmSaga)
  yield takeLatest(actions.signup.REQUEST, signupSaga)
  yield takeLatest(actions.updatePersonalInfo.REQUEST, updatePersonalInfoSaga)
  // 2 FACTOR AUTH
  yield takeLatest(actions.getAuthStatus.REQUEST, getAuthStatusSaga)
  yield takeLatest(actions.getAuthData.REQUEST, getAuthDataSaga)
  yield takeLatest(actions.validateDevice.REQUEST, validateDeviceSaga)
  yield takeLatest(actions.updateBackupTokens.REQUEST, updateBackupTokensSaga)
  yield takeLatest(actions.clearTwoFactorAuth.REQUEST, clearTwoFactorAuthSaga)

  // USER
  yield takeLatest(actions.fetchUser.REQUEST, userSaga)
  yield takeLatest(actions.fetchUserCompanies.REQUEST, userCompaniesSaga)
  // USER BADGE
  yield takeLatest(actions.updateBadgeDisplayedAt.REQUEST, updateBadgeDisplayedAtSaga)
  yield takeLatest(actions.removeAccount.REQUEST, removeAccountSaga)
  // COMPANY
  yield takeLatest(actions.fetchCompany.REQUEST, refreshCompanyAsyncSaga)
  yield takeLatest(actions.refreshCompany.REQUEST, refreshCompanySaga)
  yield takeLatest(actions.selectCompany.REQUEST, selectCompanySaga)
  yield takeLatest(actions.createCompany.REQUEST, createCompanySaga)
  yield takeLatest(actions.updateCompany.REQUEST, updateCompanySaga)
  yield takeLatest(actions.updateCompanyRff.REQUEST, updateCompanyRffSaga)
  yield takeLatest(actions.updateCompanyField.REQUEST, updateCompanyFieldSaga)
  yield takeLatest(actions.removeCompany.REQUEST, removeCompanySaga)
  // COMPANY MEMBERS
  yield takeLatest(actions.fetchCompanyMembers.REQUEST, fetchCompanyMembersSaga)
  yield takeLatest(actions.createCompanyMember.REQUEST, createCompanyMemberSaga)
  yield takeLatest(actions.updateCompanyMember.REQUEST, updateCompanyMemberSaga)
  yield takeLatest(actions.removeCompanyMember.REQUEST, removeCompanyMemberSaga)
  yield takeLatest(actions.removeCompanyMembership.REQUEST, removeCompanyMembershipSaga)
  // INVITE
  yield takeLatest(actions.loadInvitationDetails.REQUEST, loadInvitationDetailsSaga)
  yield takeLatest(actions.acceptInvitation.REQUEST, acceptInvitationSaga)
  // COMPANY VAT
  yield takeLatest(actions.fetchCompanyVats.REQUEST, fetchCompanyVatsSaga)
  yield takeLatest(actions.createCompanyVat.REQUEST, createCompanyVatSaga)
  yield takeLatest(actions.updateCompanyVat.REQUEST, updateCompanyVatSaga)
  yield takeLatest(actions.removeCompanyVat.REQUEST, removeCompanyVatSaga)
  // INTEGRATION
  yield takeLatest(actions.createIntegrationProvider.REQUEST, createIntegrationProviderSaga)
  yield takeLatest(actions.updateIntegrationProvider.REQUEST, updateIntegrationProviderSaga)
  yield takeLatest(actions.loadIntegrationProviders.REQUEST, loadIntegrationProvidersSaga)
  // USER NOTIFICATIONS
  yield takeLatest(actions.updateNotificationDisplayedAt.REQUEST, updateNotificationDisplayedAtSaga)
  // PREFERENCES
  yield takeLatest(actions.changePreferences.REQUEST, changePreferencesSaga)
  yield takeLatest(actions.restorePreferences.REQUEST, restorePreferencesSaga)
  // TAXATION
  yield takeLatest(actions.taxationSetTaxForm.REQUEST, setTaxationTaxFormSaga)
}
