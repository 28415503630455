import React from 'react'

import styled from 'styled-components'

function BaseWalletRegularIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M5.94643 5C4.3192 5 3 6.3192 3 7.94643V17.0536C3 18.6808 4.3192 20 5.94643 20H17.1964C18.8237 20 20.1429 18.6808 20.1429 17.0536V11.1607C20.1429 9.53348 18.8237 8.21429 17.1964 8.21429H7.01786C6.57254 8.21429 6.21429 8.57254 6.21429 9.01786C6.21429 9.46317 6.57254 9.82143 7.01786 9.82143H17.1964C17.9364 9.82143 18.5357 10.4208 18.5357 11.1607V17.0536C18.5357 17.7935 17.9364 18.3929 17.1964 18.3929H5.94643C5.20647 18.3929 4.60714 17.7935 4.60714 17.0536V7.94643C4.60714 7.20647 5.20647 6.60714 5.94643 6.60714H18.2679C18.7132 6.60714 19.0714 6.24888 19.0714 5.80357C19.0714 5.35826 18.7132 5 18.2679 5H5.94643ZM15.8571 15.1786C16.1413 15.1786 16.4138 15.0657 16.6148 14.8648C16.8157 14.6638 16.9286 14.3913 16.9286 14.1071C16.9286 13.823 16.8157 13.5505 16.6148 13.3495C16.4138 13.1486 16.1413 13.0357 15.8571 13.0357C15.573 13.0357 15.3005 13.1486 15.0995 13.3495C14.8986 13.5505 14.7857 13.823 14.7857 14.1071C14.7857 14.3913 14.8986 14.6638 15.0995 14.8648C15.3005 15.0657 15.573 15.1786 15.8571 15.1786Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const WalletRegularIcon = styled(BaseWalletRegularIcon)``
