import React from 'react'

import { AppBar } from '@material-ui/core'
import styled, { css, useTheme } from 'styled-components'

import { useSticky } from '@hooks'

const PlaceholderWrapper = styled.div`
  height: 132px;
`

// need to use mui appbar for preventing jumping on popover's scroll lock behavior
// this component listens on it and makes correction with padding
const StyledAppBar = styled(AppBar).attrs({ color: 'inherit', elevation: 0 })`
  border: none;
  padding: 0;
  background-color: transparent;

  ${({ position, theme }) =>
    position === 'fixed' &&
    css`
      top: ${theme.appBarHeightMedium}px;
      background-color: ${theme.colors.gray[0]};
      border-bottom: 1px solid ${theme.colors.common.paperStroke};
      box-shadow: var(--filterBarBoxShadow, none);
      z-index: 1296; // must be below compactNavbar's zIndex
      height: ${theme.stickyFilterBarHeight}px;
    `}
`

export function StickyFilterBar({ children }: { children: (sticky: boolean) => React.ReactNode }) {
  const theme = useTheme()
  const [sticky, appBarRef] = useSticky(theme.appBarHeightMedium)
  return (
    <PlaceholderWrapper ref={appBarRef}>
      <StyledAppBar position={sticky ? 'fixed' : 'static'} component="div">
        {children(sticky)}
      </StyledAppBar>
    </PlaceholderWrapper>
  )
}
