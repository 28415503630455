import React from 'react'

import {
  DialogOpenState,
  TaskTypes,
  TaxDeclarationMultiClosureDialogState,
} from '@components/pages/PulsePage/TaxationTasks/constants'

import { ClosureDialog } from './ClosureDialog'

type DialogControlRenderProps = {
  openDialogResponseState: VoidFunction
  openDialogSupportState: VoidFunction
}

interface ClosureDialogControlProps<BackendTaskDetailResponseType> {
  children: (props: DialogControlRenderProps) => JSX.Element
  links: { support?: BackendCallableLink; [key: string]: unknown }
  onCompleteCallback: (payload: BackendTaskDetailResponseType) => void
  taskType: TaskTypes
}

export function ClosureDialogControl<BackendTaskDetailResponseType>({
  children,
  links,
  onCompleteCallback,
  taskType,
}: ClosureDialogControlProps<BackendTaskDetailResponseType>) {
  const [openState, setOpenState] = React.useState<Nullable<DialogOpenState>>(null)
  const [dialogState, setDialogState] = React.useState<Nullable<TaxDeclarationMultiClosureDialogState>>(null)

  function openDialogResponseState() {
    setDialogState(TaxDeclarationMultiClosureDialogState.RESPONSE)
    // value does not matter just fill it with something else than support state
    setOpenState(DialogOpenState.CONTINUE)
  }

  function openDialogSupportState() {
    setDialogState(TaxDeclarationMultiClosureDialogState.SUMMARY)
    setOpenState(DialogOpenState.SUPPORT)
  }

  function handleClose() {
    setOpenState(null)
  }

  return (
    <>
      {children({ openDialogResponseState, openDialogSupportState })}
      <ClosureDialog
        onClose={handleClose}
        dialogState={dialogState}
        openState={openState}
        onCompleteCallback={onCompleteCallback}
        setDialogState={setDialogState}
        supportLink={links.support}
        taskType={taskType}
      />
    </>
  )
}
