import { DuplicateResults } from './types'

export enum DialogActions {
  INIT = 'initializing',
  DELETE_DUPLICATION = 'delete_duplication',
  DELETE_DUPLICATE_EXPENSE = 'delete_expense_with_duplicate_id',
  DELETE_ORIGINAL_EXPENSE = 'delete_expense_with_original_id',
  MERGE_EXPENSES = 'merge_expenses',
  MERGE_EXPENSES_UPDATE = 'merge_expenses_with_update',
}

type ResponseState = {
  action: DialogActions
  message: StringOrMessage
  isError: boolean
  state?: {
    willRedirect: boolean
    redirectUrl: string
  }
}

export type ReducerState = {
  activeAction?: DialogActions
  canRedirect: boolean
  clearFormInvoiceNumberField: VoidFunction | undefined
  data: Nullable<DuplicateResults>
  expenseId: Nullable<ItemIdType> | undefined
  isOpen: boolean
  redirectUrl: Nullable<string>
  responseState?: ResponseState
  selectedExpenseId: Nullable<ItemIdType>
  willRedirect: boolean
}

export const initialState: ReducerState = {
  activeAction: undefined,
  canRedirect: false,
  clearFormInvoiceNumberField: undefined,
  data: null,
  expenseId: null,
  isOpen: false, // control modal
  redirectUrl: null,
  responseState: undefined,
  selectedExpenseId: null,
  willRedirect: false, // on details page prevent unsaved changes dialog open too
}

export enum ActionTypes {
  OPEN_REQUEST = 'open-request',
  OPEN_SUCCESS = 'open-success',
  OPEN_FAILURE = 'open-failure',
  CLOSE = 'close_without_redirect',
  CLOSE_AND_REDIRECT = 'close_and_redirect',
  NAV_MERGE_SELECT = 'nav_merge_select',
  ACTION_REQUEST = 'action_request',
  ACTION_RESPONSE = 'action_response',
}

type ReducerActions =
  | { type: ActionTypes.OPEN_REQUEST }
  | {
      type: ActionTypes.OPEN_SUCCESS
      payload: { clearFormInvoiceNumberField?: VoidFunction; expenseId?: ItemIdType; results: DuplicateResults }
    }
  | { type: ActionTypes.OPEN_FAILURE; payload: StringOrMessage }
  | { type: ActionTypes.CLOSE }
  | { type: ActionTypes.CLOSE_AND_REDIRECT }
  | { type: ActionTypes.NAV_MERGE_SELECT; payload: ItemIdType }
  | { type: ActionTypes.ACTION_REQUEST; payload: DialogActions }
  | { type: ActionTypes.ACTION_RESPONSE; payload: ResponseState }

function getNavMergeDefaultSelectedExpenseId(data: DuplicateResults) {
  switch (data.navMergeDirection) {
    case 'from_nav':
      return data.duplicateId // to_id: navInvoicePreviewId, original_id | from_id - selected

    case 'to_nav':
      return data.originalId // is nav original: merge data from nav to selected

    default:
      return null // this is not a nav merge flow
  }
}

export function reducer(state: ReducerState, action: ReducerActions): ReducerState {
  switch (action.type) {
    case ActionTypes.OPEN_REQUEST: {
      return {
        ...state,
        data: null,
        activeAction: DialogActions.INIT,
        responseState: undefined,
        isOpen: true,
      }
    }

    case ActionTypes.OPEN_SUCCESS: {
      const defaultSelectedExpenseId = getNavMergeDefaultSelectedExpenseId(action.payload.results)
      return {
        ...state,
        clearFormInvoiceNumberField: action.payload.clearFormInvoiceNumberField,
        data: {
          ...action.payload.results,
          potentialExpenses: action.payload.results.potentialExpenses.sort(function (expense) {
            // default selected is the best match so show it as first item
            return expense.id === defaultSelectedExpenseId ? -1 : 1
          }),
        },
        expenseId: action.payload.expenseId,
        activeAction: undefined,
        isOpen: true, // when enter here with openWithData
        selectedExpenseId: defaultSelectedExpenseId,
      }
    }

    case ActionTypes.OPEN_FAILURE: {
      return {
        ...state,
        responseState: { isError: true, message: action.payload, action: DialogActions.INIT },
        activeAction: undefined,
      }
    }

    case ActionTypes.CLOSE: {
      return {
        ...state,
        isOpen: false,
        clearFormInvoiceNumberField: undefined,
      }
    }

    //! very important to do the close case updates too, otherwise user can not close the dialog and stuck in that state
    case ActionTypes.CLOSE_AND_REDIRECT: {
      return {
        ...state,
        canRedirect: true,
        isOpen: false,
        clearFormInvoiceNumberField: undefined,
      }
    }

    case ActionTypes.NAV_MERGE_SELECT: {
      return {
        ...state,
        selectedExpenseId: action.payload,
      }
    }

    // Dialog actions
    case ActionTypes.ACTION_REQUEST: {
      return {
        ...state,
        activeAction: action.payload,
        responseState: undefined,
      }
    }

    case ActionTypes.ACTION_RESPONSE: {
      const { state: stateControls, ...responseState } = action.payload
      return {
        ...state,
        activeAction: undefined,
        responseState,
        ...stateControls,
      }
    }

    default:
      return state
  }
}
