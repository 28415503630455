import React from 'react'

import { connect } from 'react-redux'

import { filtersActions } from '@services'

import { usePageConfig } from '@contexts'

import { FilterBar, FilterBarBaseProps } from './FilterBar'
import { getSelectableFilterProps } from './helpers'

import {
  DocumentTypeFilterEmptyPlaceholderMessage,
  DocumentTypeLabelMessage,
  PaidThroughsFilterEmptyPlaceholderMessage,
  PaidThroughsFilterLabelMessage,
  PaidThroughsFilterSelectedLabelMessage,
} from '@messages'

interface PaymentTransactionsPageFilterBarBaseProps extends FilterBarBaseProps {
  filters: PaymentTransactionsStoreFilters
  paidThroughOptions: PaidThroughOption[]
}

function PurePaymentTransactionsPageFilterBar({
  filters: { paidThroughs, search, searchFields, dateField, transactionTypes },
  isDateFilterDisabled,
  paidThroughOptions,
  resetFilters,
  updateFilters,
}: PaymentTransactionsPageFilterBarBaseProps) {
  const pageConfig = usePageConfig()

  const isDateFilterUsed = true
  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }
  const selectableFilterProps = getSelectableFilterProps({
    values: {
      paidThroughs,
      transactionTypes,
    },
    config: [
      {
        keyValue: 'paidThroughs' as const,
        options: paidThroughOptions,
        labelText: PaidThroughsFilterLabelMessage,
        selectedLabelText: PaidThroughsFilterSelectedLabelMessage,
        emptyPlaceholder: PaidThroughsFilterEmptyPlaceholderMessage,
      },
      {
        keyValue: 'transactionTypes' as const,
        options: pageConfig.transactionTypeFilterProps?.config.options ?? [],
        labelText: DocumentTypeLabelMessage,
        emptyPlaceholder: DocumentTypeFilterEmptyPlaceholderMessage,
      },
    ],
  })

  return (
    <FilterBar
      dateField={dateField}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      selectableFilterProps={selectableFilterProps}
      updateFilters={updateFilters}
    />
  )
}

export const PaymentTransactionsPageFilterBar = connect(
  ({ payment: { paidThroughOptions }, filters: { isGlobalDateFilterDisabled, paymentTransactions } }: Store) => {
    return {
      filters: paymentTransactions,
      isDateFilterDisabled: isGlobalDateFilterDisabled,
      paidThroughOptions,
    }
  },
  {
    resetFilters: filtersActions.resetPaymentTransactionsFilters.request,
    updateFilters: filtersActions.updatePaymentTransactionsFilters.request,
  }
)(PurePaymentTransactionsPageFilterBar)
PaymentTransactionsPageFilterBar.displayName = 'PaymentTransactionsPageFilterBar'
