import { ACCESS, ROLES } from '@constants'

function checkUserRole(role: string | undefined, acceptedRoles: string[]) {
  if (!role) {
    return false
  }
  return acceptedRoles.includes(role)
}

// ROLE based check
export function isAdmin(role?: CompanyUserRole) {
  return checkUserRole(role, ROLES.admin)
}

export function isEditor(role?: CompanyUserRole) {
  return checkUserRole(role, ROLES.editor)
}

export function isViewer(role?: CompanyUserRole) {
  return checkUserRole(role, ROLES.viewer)
}

export function isAdminOrAccountant(role?: CompanyUserRole) {
  return checkUserRole(role, ROLES.adminOrAccountant)
}

export function isAccountant(role?: CompanyUserRole) {
  return checkUserRole(role, ROLES.accountant)
}

// ACCESS based check
export function userHasAdminPermission(role?: CompanyUserRole) {
  return checkUserRole(role, ACCESS.admin)
}

export function userHasEditorPermission(role?: CompanyUserRole) {
  return checkUserRole(role, ACCESS.editor)
}
