import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import { cegjelzoActions } from '@services'

import { bindActionToPromise, taxNumberFormatter } from '@helpers'

import AsyncSelect from './select'

export const CustomOption = withStyles(theme => ({
  mainData: {
    color: theme.palette.grayscale.xxDark,
    fontSize: 16,
    lineHeight: '22px',
    display: 'block',
    fontWeight: 700,
  },
  seconderData: {
    fontSize: 14,
    lineHeight: '19px',
    display: 'block',
    color: theme.palette.grayscale.menu,
  },
}))(({ classes, data, prefix }) => (
  <div>
    <span className={classes.mainData}>
      {prefix}
      {data.label ? data.label : data.name}
    </span>
    {data.address && <span className={classes.seconderData}>{data.address}</span>}
    {data.tax_number && <span className={classes.seconderData}>{taxNumberFormatter.format(data.tax_number)}</span>}
  </div>
))

export function optionRenderer(data) {
  return <CustomOption data={data} />
}

export const ConnectedSelect = connect(null, dispatch => ({
  searchOptions: bindActionToPromise(dispatch, cegjelzoActions.searchCompanies.request),
}))(AsyncSelect)
