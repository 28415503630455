import React from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import { PrivateNavbarIntercomLauncherButton } from '@components/Intercom'
import { HorizontalDotMenuIcon, Typography } from '@components/ui'

import { ROUTES } from '@constants'

import { BackToSzamlazzButton, LogoutButton } from './buttons'
import { DropdownMenuButton } from './DropdownMenuButton'
import { NavLink } from './NavLink'
import { ProfileMenu } from './ProfileMenu'

import { ProfileSettingsMessage } from './ProfileMenu/messages'
import { TertiaryInfoTypography } from './styles'

const PersonalDropdownMenuButton = styled(DropdownMenuButton)`
  border-left: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  border-radius: unset;
`

const ProfileLinkContainer = styled.div`
  display: flex;
  align-items: stretch;
  border-top: 1px solid ${({ theme }) => theme.colors.common.paperStroke};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }
`

const StyledNavLink = styled(NavLink)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-left: 15px;
  color: ${({ theme }) => theme.colors.common.offBlack};
  text-decoration: none;
  height: ${({ theme }) => theme.appBarHeight}px;
`

const StyledPrivateNavbarIntercomLauncherButton = styled(PrivateNavbarIntercomLauncherButton)`
  padding-left: 15px;
`

interface CompactPersonalDropdownMenuProps {
  userEmail: string
  visible: boolean
}

export function PureCompactPersonalDropdownMenu({ userEmail, visible }: CompactPersonalDropdownMenuProps) {
  return (
    <PersonalDropdownMenuButton
      icon={<HorizontalDotMenuIcon />}
      isContainerVisible={visible}
      placement="bottom-end"
      transformOrigin="right top"
      width={350}
    >
      {({ onCloseCallback }) => (
        <>
          <ProfileMenu isCompact onCloseCallback={onCloseCallback} />
          <ProfileLinkContainer>
            <StyledNavLink to={ROUTES.profile}>
              <Typography color="gray-80">{ProfileSettingsMessage}</Typography>
              <TertiaryInfoTypography>{userEmail}</TertiaryInfoTypography>
            </StyledNavLink>
          </ProfileLinkContainer>
          <StyledPrivateNavbarIntercomLauncherButton />
          <BackToSzamlazzButton isCompact />
          <LogoutButton isCompact />
        </>
      )}
    </PersonalDropdownMenuButton>
  )
}

export const CompactPersonalDropdownMenu = connect((state: Store) => ({
  userEmail: state.auth.user.email,
}))(PureCompactPersonalDropdownMenu)

CompactPersonalDropdownMenu.displayName = 'CompactPersonalDropdownMenu'
