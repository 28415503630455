import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import { INTERCOM_LAUNCHER_CLASS_NAME } from './constant'
import { getFullName, getIntercomCompanyType } from './helpers'

interface IntercomLoaderProps {
  company: Company
  user: User
}

function IntercomLoader({ company, user }: IntercomLoaderProps) {
  const { boot, shutdown } = useIntercom()

  React.useLayoutEffect(() => {
    boot({
      customLauncherSelector: `.${INTERCOM_LAUNCHER_CLASS_NAME}`,
      hideDefaultLauncher: true,
      email: user.email,
      name: getFullName(user.first_name, user.last_name),
      userId: String(user.id),
      userHash: user.intercom_user_hash,
      company: {
        companyId: String(company.id),
        name: company.name,
        customAttributes: {
          company_type: getIntercomCompanyType(company.company_type),
        },
      },
      sessionDuration: 10 * 60 * 60 * 1000, // milliseconds - same as auth token expirtion: 10 hrs
    })
    return () => {
      shutdown()
    }
  }, [
    boot,
    company.company_type,
    company.id,
    company.name,
    shutdown,
    user.email,
    user.first_name,
    user.id,
    user.intercom_user_hash,
    user.last_name,
  ])

  return null
}

function PureCompanyBasedIntercomInitializer({ company, user }: IntercomLoaderProps) {
  if (window.intercomAppId && company) {
    // use a fake react component because custom hook can't be used with conditional render
    return <IntercomLoader company={company} user={user} />
  }
  return null
}

PureCompanyBasedIntercomInitializer.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    company_type: PropTypes.string.isRequired,
  }) as React.Validator<IntercomLoaderProps['company']>,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    id: PropTypes.number.isRequired,
    intercom_user_hash: PropTypes.string.isRequired,
    last_name: PropTypes.string,
  }).isRequired as React.Validator<IntercomLoaderProps['user']>,
}

export const CompanyBasedIntercomInitializer = connect((state: Store) => ({
  company: state.auth.company.data,
  user: state.auth.user,
}))(PureCompanyBasedIntercomInitializer)

CompanyBasedIntercomInitializer.displayName = 'CompanyBasedIntercomInitializer'
