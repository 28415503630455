// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1199px) {
  body.mobile #root {
    overflow: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/oldComponents/CheckingMobileBrowser/styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":["@media screen and (max-width: 1199px) {\n  body.mobile #root {\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
