import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'

import {
  AstromechSolidIcon,
  BadgeCheckListIcon,
  BadgeDeadlineTaskIcon,
  BadgeFinancialTaskIcon,
  BadgePercentIcon,
  BadgePlusTaskIcon,
} from '@components/ui/svgIcons'
import { Typography } from '@components/ui/Typography'

const REPORT_BADGE_VARIANTS = {
  chat: 'chat',
  financialSupport: 'financial-support',
  setup: 'setup',
  task: 'task',
  taxSupport: 'tax-support',
  unsupported: 'unsupported',
} as const

type ReportBadgeVariants = ConstObjectValues<typeof REPORT_BADGE_VARIANTS>

interface ReportAccordionBadgeProps {
  variant: ReportBadgeVariants
}

//* Report Badges
const ReportBadgeSpan = styled.span`
  display: inline-flex;
  width: 139px;
  gap: 6px;
  align-items: center;
  padding: 5px;
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
  color: var(--text-color);
  border-color: var(--border-color);
  background-color: var(--background-color);

  & > svg {
    color: var(--icon-color);
  }
`

const BADGE_ICONS = {
  [REPORT_BADGE_VARIANTS.chat]: AstromechSolidIcon,
  [REPORT_BADGE_VARIANTS.financialSupport]: BadgeFinancialTaskIcon,
  [REPORT_BADGE_VARIANTS.setup]: BadgeCheckListIcon,
  [REPORT_BADGE_VARIANTS.task]: BadgeDeadlineTaskIcon,
  [REPORT_BADGE_VARIANTS.taxSupport]: BadgePercentIcon,
  [REPORT_BADGE_VARIANTS.unsupported]: BadgePlusTaskIcon,
}
const BADGE_SIZES: Record<string, number> = {
  [REPORT_BADGE_VARIANTS.chat]: 14,
}

const TaskLabel = <FormattedMessage id="report.badges.task" defaultMessage="határidős feladat" />

const BADGE_LABELS = {
  [REPORT_BADGE_VARIANTS.financialSupport]: (
    <FormattedMessage id="report.badges.financialSupport" defaultMessage="pénzügyi támogatás" />
  ),
  [REPORT_BADGE_VARIANTS.setup]: <FormattedMessage id="report.badges.setup" defaultMessage="személyre szabás" />,
  [REPORT_BADGE_VARIANTS.task]: TaskLabel,
  [REPORT_BADGE_VARIANTS.taxSupport]: (
    <FormattedMessage id="report.badges.taxSupport" defaultMessage="adózási támogatás" />
  ),
  [REPORT_BADGE_VARIANTS.unsupported]: TaskLabel,
  [REPORT_BADGE_VARIANTS.chat]: <FormattedMessage id="report.badges.chat" defaultMessage="Aimee asszisztens" />,
}

function ReportAccordionBadge({ variant }: ReportAccordionBadgeProps) {
  const BadgeIcon = BADGE_ICONS[variant]
  const size = BADGE_SIZES[variant]

  return (
    <ReportBadgeSpan>
      <BadgeIcon size={size} />
      <Typography size="700-xxs" tag="span">
        {BADGE_LABELS[variant]}
      </Typography>
    </ReportBadgeSpan>
  )
}

const HEADER_CLASSES = {
  taxSupport: REPORT_BADGE_VARIANTS.taxSupport,
  setup: REPORT_BADGE_VARIANTS.setup,
  financialSupport: REPORT_BADGE_VARIANTS.financialSupport,
  task: REPORT_BADGE_VARIANTS.task,
  unsupported: REPORT_BADGE_VARIANTS.unsupported,
  completed: 'completed',
  chat: REPORT_BADGE_VARIANTS.chat,
}

const ReportAccordionHeaderWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 12px 16px 11px; // due to border bottom we need 1px less padding on the bottom

  ${({ theme }) => {
    return css`
      &.${[HEADER_CLASSES.taxSupport]} {
        --border-color: ${theme.colors.common.lightBadgeBlue};
        --icon-color: ${theme.colors.common.lightBadgeBlue};
        --text-color: ${theme.colors.gray[0]};
        --background-color: ${theme.colors.common.badgeBlue};
        --strong-color: ${theme.colors.common.badgeBlue};
      }

      &.${[HEADER_CLASSES.financialSupport]} {
        --border-color: ${theme.colors.common.lightBadgePurple};
        --icon-color: ${theme.colors.common.lightBadgePurple};
        --text-color: ${theme.colors.gray[0]};
        --background-color: ${theme.colors.common.badgePurple};
        --strong-color: ${theme.colors.common.badgePurple};
      }

      &.${[HEADER_CLASSES.task]}, &.${[HEADER_CLASSES.setup]} {
        --border-color: ${theme.colors.primary[155]};
        --icon-color: ${theme.colors.primary[155]};
        --text-color: ${theme.colors.gray[0]};
        --background-color: ${theme.colors.primary[50]};
        --strong-color: ${theme.colors.primary[50]};
      }

      &.${[HEADER_CLASSES.unsupported]} {
        --border-color: ${theme.colors.primary[50]};
        --icon-color: ${theme.colors.primary[155]};
        --text-color: ${theme.colors.primary[50]};
        --background-color: ${theme.colors.gray[0]};
        --strong-color: ${theme.colors.primary[50]};
      }

      &.${[HEADER_CLASSES.completed]} {
        --border-color: ${theme.colors.success[10]};
        --icon-color: ${theme.colors.success[10]};
        --text-color: ${theme.colors.gray[0]};
        --background-color: ${theme.colors.success[40]};
        --strong-color: ${theme.colors.success[40]};
      }

      &.${[HEADER_CLASSES.chat]} {
        --border-color: #d2ceff;
        --icon-color: #d2ceff;
        --text-color: ${theme.colors.gray[0]};
        ${ReportBadgeSpan} {
          background: linear-gradient(90deg, #2837c6, ${({ theme }) => theme.colors.common.badgePurple});
        }
      }
    `
  }}
`

const ReportsAccordionHeaderTypography = styled(Typography)`
  strong {
    color: var(--strong-color);
  }
`

interface ReportAccordionHeaderProps {
  className?: string
  isCompleted?: boolean
  title: React.ReactChild
  variant: ReportBadgeVariants
}

export function ReportAccordionHeader({ className, isCompleted, title, variant }: ReportAccordionHeaderProps) {
  return (
    <ReportAccordionHeaderWrapper
      className={cx(className, variant, {
        [HEADER_CLASSES.completed]: isCompleted,
      })}
    >
      <ReportAccordionBadge variant={variant} />
      <ReportsAccordionHeaderTypography color="gray-80" size="700-md" tag="span">
        {title}
      </ReportsAccordionHeaderTypography>
    </ReportAccordionHeaderWrapper>
  )
}

ReportAccordionHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.values(REPORT_BADGE_VARIANTS)).isRequired,
}

interface ReportAccordionContentProps {
  children: React.ReactChild | React.ReactChild[]
}

export const ReportAccordionContent = styled.div<ReportAccordionContentProps>`
  background-color: ${({ theme }) => theme.colors.gray[0]};
`

// control the CSS variable in the parent ReportAccordionHeaderWrapper
export const ReportHeaderHighlightedStrong = styled.strong<{ $completed?: boolean }>`
  --strong-color: ${({ theme, $completed }) => ($completed ? theme.colors.success[40] : theme.colors.primary[50])};
`
