import subscribe_patch from '@assets/img/subscribe_patch.png'

const styles = theme => ({
  braintreeField: {
    width: '100%',
    padding: 0,
    height: 36,
    borderRadius: 4,
    backgroundColor: theme.palette.grayscale.inputBackground,
    border: `1px solid ${theme.palette.grayscale.light}`,
    'label + &': {
      marginTop: '1.2rem',
    },
    '&.focus': {
      borderColor: '#80BDFF',
    },
    '&.error': {
      borderColor: theme.palette.error.main,
    },
  },
  braintreeButtonsContainer: {
    textAlign: 'center',
    display: 'flex',
    gap: 10,
    width: '100%',
    flexDirection: 'column',
  },
  braintreeErrorMessage: {
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  paymentProviderContainer: {
    color: theme.palette.grayscale.dark,
    borderTop: `1px solid ${theme.palette.grayscale.grey05}`,
    fontSize: 10,
    lineHeight: '16px',
    textAlign: 'center',
    paddingTop: 12,
    marginTop: 46,
    '& > *': {
      display: 'block',
    },
  },
  braintreeContainer: {
    minHeight: 780,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 110,
  },
  formContainer: {
    width: 420,
    padding: '24px 42px 42px',
  },
  subscribePatch: {
    width: 500,
    height: 500,
    backgroundImage: `url(${subscribe_patch})`,
    backgroundSize: 'cover',
    position: 'relative',

    '& > div': {
      position: 'absolute',
      top: 135,
      left: 205,
      width: 250,
      color: theme.palette.neutral.xxxLight,
    },
    '& > div > span': {
      display: 'block',

      '&:last-child': {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1,
        marginTop: '.5em',
      },
    },
  },
})
export default styles
