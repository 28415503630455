import React from 'react'
import PropTypes from 'prop-types'

import { VariableSizeList as List } from 'react-window'

//* locale helpers
function flattenGroupedChildren(children) {
  return children.reduce((result, child) => {
    const {
      props: { children: nestedChildren = [], data: { options: nestedOptions = [] } = {} },
      props: childProps,
    } = child

    const isGrouped = nestedOptions.length > 0

    if (!isGrouped) {
      // when create new option
      return [...result, React.cloneElement(child, childProps)]
    }

    return [...result, React.cloneElement(child, { type: 'group' }, []), ...nestedChildren]
  }, [])
}

function getCurrentIndex(children) {
  return Math.max(
    children.findIndex(({ props: { isFocused } = {} } = {}) => !!isFocused),
    0
  )
}

//* custom UI integration component
export function WindowedMenuList({ children: childrenProp, maxHeight = 0 }) {
  const listRef = React.useRef(null)

  const children = flattenGroupedChildren(React.Children.toArray(childrenProp))

  const currentIndex = getCurrentIndex(children)

  React.useEffect(() => {
    const ref = listRef && listRef.current
    if (ref) {
      if (children.length === 1) {
        ref.resetAfterIndex(0)
      }
      // enables scrolling on key down arrow
      if (currentIndex >= 0) {
        ref.scrollToItem(currentIndex)
      }
    }
  })

  const [heights, totalHeight] = React.useMemo(() => {
    const childrenHeights = children.map(({ props: { type, data } = {} }) => {
      if (type === 'group') {
        return 36
      }
      if (data?.data?.name || data?.isDefault) {
        // kontír, payment form - paidthrough select
        return 48
      }
      return 32
    })
    return [childrenHeights, childrenHeights.reduce((acc, curr) => (acc += curr), 0)]
  }, [children])

  return (
    <List
      ref={listRef}
      height={Math.min(maxHeight, totalHeight)}
      itemCount={children.length}
      itemSize={index => heights[index]}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}

WindowedMenuList.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.number,
}
