import React from 'react'

export default function CollapseToggleIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 16"
      width={size}
      height={(size / 18) * 16}
    >
      <path
        d="M13.7496 5.46889C13.7496 5.68469 13.832 5.90059 13.9968 6.06544L15.1207 7.15634L12.0952 7.15634C11.6293 7.15634 11.2514 7.53427 11.2514 8.00009C11.2514 8.46591 11.6293 8.84384 12.0952 8.84384H15.1207L14.0298 9.93474C13.8621 10.0989 13.7496 10.3169 13.7496 10.5313C13.7496 10.982 14.1128 11.3751 14.5934 11.3751C14.8093 11.3751 15.0251 11.2927 15.19 11.1279L17.7216 8.59666C17.8418 8.50634 18 8.29892 18 8.00009C18 7.70126 17.8432 7.49384 17.7528 7.40349L15.2212 4.87219C15.0574 4.70599 14.8395 4.62509 14.5934 4.62509C14.1434 4.62509 13.7496 5.01889 13.7496 5.46889ZM4.18708 10.5313C4.18708 10.3155 4.10467 10.0996 3.9399 9.93474L2.87962 8.84384H5.90552C6.37134 8.84384 6.74927 8.46591 6.74927 8.00009C6.74927 7.53427 6.37134 7.15634 5.90552 7.15634H2.87962L3.97052 6.06544C4.13435 5.90129 4.18708 5.68679 4.18708 5.46889C4.18708 4.80829 3.58239 4.62509 3.37497 4.62509C3.15908 4.62509 2.94325 4.70749 2.77837 4.87229L0.246766 7.40352C0.0343031 7.61689 -3.05176e-05 7.87001 -3.05176e-05 8.03173C-3.05176e-05 8.13017 0.0340254 8.38329 0.246063 8.59775L2.77767 11.129C2.94255 11.2942 3.15841 11.3751 3.37427 11.3751C3.85661 11.3751 4.18708 10.9813 4.18708 10.5313ZM8.15622 0.968892L8.15622 15.0313C8.15622 15.4972 8.53239 15.8751 8.96833 15.8751C9.40427 15.8751 9.84372 15.4972 9.84372 15.0313L9.84372 0.968892C9.84372 0.502992 9.46544 0.125092 8.99962 0.125092C8.5338 0.125092 8.15622 0.501292 8.15622 0.968892Z"
        fill="currentColor"
      />
    </svg>
  )
}
