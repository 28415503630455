// TODO: move to components

import { Component } from 'react'
import { string } from 'prop-types'

import FacebookPixel from 'react-facebook-pixel'

export class FBPixelTrackEvent extends Component {
  componentDidMount() {
    const { event } = this.props
    FacebookPixel.track(event)
  }
  render() {
    return null
  }
}

FBPixelTrackEvent.propTypes = {
  event: string.isRequired,
}
