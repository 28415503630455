import React from 'react'

import { useWatch } from 'react-hook-form'
import { defineMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { ReactHookFormSelectField } from '@components/ui'
import { ReactHookFormSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSelectField'

import { InvoiceType } from '@constants'

const NoResultsTextMessage = defineMessage({
  id: 'form.companyVatCategory.noOptions',
  defaultMessage: 'A kiválaszott területhez és ÁFA-kulcshoz nincs találat',
})

const INVOICE_CLASS_FILTER_MAP = {
  [InvoiceType.EXPENSE]: 0,
  [InvoiceType.INCOME]: 1,
}

interface CompanyVatCategoryFieldProps
  extends Omit<ReactHookFormSelectFieldProps<CompanyVatCategory>, 'labelKey' | 'name' | 'options' | 'valueKey'> {
  assignmentPrefix: `assignments.${number}.`
  companyVatCategoryOptions?: CompanyVatCategory[]
  companyVatOptions: CompanyVatType[]
  invoiceType: InvoiceType
}

function PureCompanyVatCategoryField({
  assignmentPrefix,
  companyVatCategoryOptions = [],
  companyVatOptions,
  invoiceType,
  ...rest
}: CompanyVatCategoryFieldProps) {
  const fieldName = `${assignmentPrefix}company_vat_category`
  const vatFieldName = `${assignmentPrefix}vat`
  const { formatMessage } = useIntl()
  const vat = useWatch({ name: vatFieldName })
  const vatArea = useWatch({ name: 'vat_area' })

  const filteredOptions = React.useMemo<CompanyVatCategory[]>(() => {
    const percentFilter = companyVatOptions.find(({ id }) => id === vat)?.percent
    const invoiceClassFilter = INVOICE_CLASS_FILTER_MAP[invoiceType]

    return companyVatCategoryOptions.filter(
      ({ percent, invoice_class, vat_area }) =>
        percent === percentFilter && invoice_class === invoiceClassFilter && vat_area === vatArea
    )
  }, [companyVatCategoryOptions, companyVatOptions, invoiceType, vat, vatArea])

  return (
    <ReactHookFormSelectField
      {...rest}
      labelKey="name"
      name={fieldName}
      noResultsText={formatMessage(NoResultsTextMessage)}
      options={filteredOptions}
      valueKey="id"
    />
  )
}

export const CompanyVatCategoryField = connect((state: Store) => ({
  companyVatOptions: state.auth.companyVats.data,
  companyVatCategoryOptions: state.auth.company.data.vat_categories,
}))(PureCompanyVatCategoryField)

CompanyVatCategoryField.displayName = 'CompanyVatCategoryField'
