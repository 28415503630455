// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Open Sans, sans-serif;
}

#root {
  min-height: 100vh;
  display: flex;
  overflow: auto;
}

/* public loading page */
#root>div.page-loading-container {
  height: 100vh;
}


/* NOTE: temporary fix for the issue of react-pdf text-layer canvas bug */
canvas.hiddenCanvasElement {
  width: 0;
  height: 0;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/style.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,cAAc;AAChB;;AAEA,wBAAwB;AACxB;EACE,aAAa;AACf;;;AAGA,yEAAyE;AACzE;EACE,QAAQ;EACR,SAAS;AACX","sourcesContent":["body {\n  font-family: Open Sans, sans-serif;\n}\n\n#root {\n  min-height: 100vh;\n  display: flex;\n  overflow: auto;\n}\n\n/* public loading page */\n#root>div.page-loading-container {\n  height: 100vh;\n}\n\n\n/* NOTE: temporary fix for the issue of react-pdf text-layer canvas bug */\ncanvas.hiddenCanvasElement {\n  width: 0;\n  height: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
