import { Theme } from '@material-ui/core'
import { StyleRulesCallback, Styles } from '@material-ui/styles'

export const styles: Styles<Theme, Record<never, never>> = theme => ({
  editorHeader: {
    // '&>*': {
    //   display: 'flex',
    //   justifyContent: 'space-between',
    // },
    position: 'relative',

    '& > button': {
      position: 'absolute',
      top: 15,
      right: 15,
    },
  },
  label: {
    fontSize: 12,
    lineHeight: 1.4,
    color: theme.palette.grayscale.xxDark,
  },
  warning: {
    fontSize: 12,
    marginTop: 10,

    '& svg': {
      fontSize: 22,
      transform: 'translateX(-1px)',
    },
  },
})

// styles for PartnerEditorForm
export const partnerEditorStyles: StyleRulesCallback<Theme, Record<never, never>> = theme => ({
  root: {
    minWidth: 420,
    '&>div:last-child': { marginTop: theme.sizes.large },
    '&.modal': {
      '& button': { minWidth: 150 },
      '&>div:last-child': { justifyContent: 'center' },
    },
    '& .form-control': {
      marginTop: 6,
      marginBottom: 0,
    },
  },
  warning: {
    fontSize: 12,
    marginTop: 15,
    marginBottom: 10,
    flexWrap: 'nowrap',
    gap: 5,

    '& svg': {
      fontSize: 22,
      transform: 'translateX(-1px)',
    },
  },
})

export const layoutStyles: StyleRulesCallback<Theme, Record<never, never>> = theme => ({
  layoutRoot: {
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 805, // A4 size height with scaled width (same ratio) to fix height when no preview and view attachment tab
  },
  layoutContentContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  layoutColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  columnTitle: {
    fontWeight: 700,
    fontSize: 'inherit',
    textAlign: 'left',
    backgroundColor: '#F1F2F4',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    height: 48, // .MuiTab-root
  },
  paper: {
    padding: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  viewport: {
    padding: '1rem',
    flexGrow: 1,
  },
  editorRoot: {
    padding: '0 8px',
    maxWidth: 1440,
    margin: '0 auto',
    width: '100%',
  },
  previewContainer: {
    // width: 'calc(50% - 89px - 24px)',
    // width: 597, // (1250 - 56) / 2
    width: '100%',
    padding: 0,
    position: 'relative',
  },
  emptyDemo: { height: '100%' },
  containerWithDivider: {
    paddingBottom: '1rem',
    marginBottom: '1rem',
    textAlign: 'left',
    position: 'relative',

    '&::after': {
      display: 'block',
      content: '""',
      borderBottom: `1px solid ${theme.palette.tertiary.paperStroke}`,
      position: 'absolute',
      left: '-1rem',
      right: '-1rem',
      bottom: 0,
    },
  },
  editorDialogHeader: {
    padding: '14px 20px 0',
    fontWeight: 700,
    color: theme.palette.grayscale.xxxDark,
    backgroundColor: theme.palette.grayscale.xxxLight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',

    '&> div:first-child': {
      flexGrow: 1,
    },

    '& + .MuiDialogContent-root': {
      padding: 0,
    },
  },
  alignLeft: { textAlign: 'left' },
})

export const overlayStyles: Styles<Theme, Record<never, never>> = () => ({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,.4)',
    borderRadius: 4,
    zIndex: 20,
  },
  editorTitle: {
    margin: 0,
  },
  editorBox: {
    padding: '1rem',
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    '& .form-control': {
      marginTop: 6,
      marginBottom: 0,
    },
  },
  editorHeader: {
    marginBottom: -10,
  },
})

export const editorStyles: StyleRulesCallback<Theme, Record<never, never>> = theme => ({
  paymentPaperRoot: {
    maxWidth: 600,
    border: 'none',
    outline: 'none',
  },
  paymentPaperRootWithData: {
    maxWidth: 1250,
    minHeight: 500,
    overflow: 'auto',
    '& > *': { minWidth: 1200 },
  },
  partnerPaperRoot: {
    maxWidth: 1250,
    border: 'none',
    margin: '40px auto',
    outline: 'none',
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
    '&>*': { minWidth: 1200 },
  },
  paymentDialogRoot: { overflow: 'auto' },
  partnerDialogRoot: {
    display: 'flex',
    overflow: 'auto',
  },
  bold: { fontWeight: 700 },
  table: {
    border: `1px solid ${theme.palette.grayscale.light}`,
    borderRadius: 4,
  },
  copyContainer: { justifyContent: 'flex-end' },
  copyValueButton: {
    padding: 0,
    color: theme.palette.tertiary.oceanBlue,
    fontSize: 12,
    lineHeight: '16px',
    minHeight: 0,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.dark,
    },
  },
  totalRow: {
    fontWeight: 700,
    textAlign: 'right',
    margin: '10px 0',
    paddingRight: 25,
  },
  errorRow: {
    color: theme.palette.error.main,
    margin: 10,
    textAlign: 'center',
  },
  infoRow: {
    fontSize: 16,
    padding: '10px 24px',
    backgroundColor: theme.palette.grayscale.xxDark,
    color: theme.palette.neutral.xxxLight,
    letterSpacing: '0.26px',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    textAlign: 'center',
  },
  accountNumberCell: { whiteSpace: 'nowrap' },
  taxNameCell: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editorCell: { lineHeight: '1 !important' },
  partnerName: {
    display: 'block',
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  editorHeader: {
    position: 'relative',
    textAlign: 'center',
    '& h2': {
      fontSize: 24,
      fontWeight: 700,
    },
    '& > button': {
      position: 'absolute',
      top: 15,
      right: 15,
    },
  },
  editorBody: { overflowY: 'unset' },
  editorFooter: {
    marginTop: 30,
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'center',
  },
  gridRow: {
    marginBottom: theme.sizes?.medium,
    '&.summary': {
      margin: '10px 0 30px',
    },
  },
  gridItem: {
    maxWidth: 200,
    width: '100%',
    '&.summary': {
      width: 'unset',
      maxWidth: 'unset',
      marginRight: 25,
      paddingLeft: 0,
    },
  },
  checkboxLabel: {
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: '0.02em',
  },
  lightTooltip: {
    ...theme.lightTooltip,
    fontSize: 14,
  },
  sectionHeader: {
    marginTop: 30,
    marginBottom: 40,
    fontSize: 28,
  },
  allTotalRow: {
    textAlign: 'right',
    padding: 25,
    color: '#3CA2C5',
    fontWeight: 'bold',
    fontSize: 24,
  },
  summaryTableHeader: {
    '&>span': {
      paddingRight: 24,
    },
  },
  sectionTitle: {
    marginBottom: 10,
    '&.summary': {
      marginBottom: 30,
      fontSize: 16,
    },
  },
})
