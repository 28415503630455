import React from 'react'
import PropTypes from 'prop-types'

import { useAssertContext } from '@hooks/useAssertContext'

interface DokumentDetailsControlsStateProps {
  isActionInProgress: boolean
  isDeleteInProgress: boolean
  isFormDirty: boolean
  isUnsavedChangesBlocked: boolean
}

interface DokumentDetailsControlsDispatchProps {
  setAttachInProgress: (payload: boolean) => void
  setDeleteInProgress: (payload: boolean) => void
  setFormDirty: (payload: boolean) => void
  setFormSubmittingInProgress: (payload: boolean) => void
  setUnsavedChangesBlocked: (payload: boolean) => void
}

enum DokumentDetailsActions {
  ATTACH = 'attach',
  DELETE = 'delete',
  FORM_SUBMIT = 'submit',
}

interface ExpenseDetailsControlsProviderProps {
  children: React.ReactNode
}

const DokumentDetailsControlsStateContext = React.createContext<DokumentDetailsControlsStateProps | undefined>(
  undefined
)

DokumentDetailsControlsStateContext.displayName = 'DokumentDetailsControlsStateContext'

const DokumentDetailsControlsDispatchContext = React.createContext<DokumentDetailsControlsDispatchProps | undefined>(
  undefined
)

DokumentDetailsControlsDispatchContext.displayName = 'DokumentDetailsControlsDispatchContext'

export function DokumentDetailsControlsProvider({ children }: ExpenseDetailsControlsProviderProps) {
  const [actionInProgress, setActionInProgress] = React.useState<Nullable<DokumentDetailsActions>>(null)
  const [isUnsavedChangesBlocked, setUnsavedChangesBlocked] = React.useState(false)
  const [isFormDirty, setFormDirty] = React.useState(false)

  const stateValue = React.useMemo(
    () => ({
      isActionInProgress: Boolean(actionInProgress),
      isDeleteInProgress: actionInProgress === DokumentDetailsActions.DELETE,
      isFormDirty,
      isUnsavedChangesBlocked,
    }),
    [actionInProgress, isFormDirty, isUnsavedChangesBlocked]
  )

  const dispatchValue = React.useMemo(
    () => ({
      setAttachInProgress: (payload: boolean) => setActionInProgress(payload ? DokumentDetailsActions.ATTACH : null),
      setDeleteInProgress: (payload: boolean) => setActionInProgress(payload ? DokumentDetailsActions.DELETE : null),
      setFormDirty,
      setFormSubmittingInProgress: (payload: boolean) =>
        setActionInProgress(payload ? DokumentDetailsActions.FORM_SUBMIT : null),
      setUnsavedChangesBlocked,
    }),
    []
  )

  return (
    <DokumentDetailsControlsDispatchContext.Provider value={dispatchValue}>
      <DokumentDetailsControlsStateContext.Provider value={stateValue}>
        {children}
      </DokumentDetailsControlsStateContext.Provider>
    </DokumentDetailsControlsDispatchContext.Provider>
  )
}

DokumentDetailsControlsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useDokumentDetailsControlsState() {
  return useAssertContext(DokumentDetailsControlsStateContext)
}

export function useDokumentDetailsControlsDispatch() {
  return useAssertContext(DokumentDetailsControlsDispatchContext)
}
