import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!FETCH_ATTACHMENTS',
  '!CREATE_ATTACHMENT',
  '!REMOVE_ATTACHMENT',
  '!DOWNLOAD_ATTACHMENT',
  '!INCREASE_ATTACHMENT',
  '!DECREASE_ATTACHMENT',
  '!UPDATE_PARTNER_ATTACHMENT',
  '!DOWNLOAD_EXPORT',
] as const

export default serviceActionsGenerator<typeof actionTypes>('attachment', actionTypes)
