import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_LIQUIDITY_INCOMES_URL,
  API_LIQUIDITY_INVOICE_DETAIL_URL,
  API_LIQUIDITY_INVOICES_URL,
  API_LIQUIDITY_PREDICTED_INCOME_DETAIL_URL,
  API_LIQUIDITY_PREDICTED_INCOMES_URL,
  API_LIQUIDITY_PREDICTED_INVOICE_DETAIL_URL,
  API_LIQUIDITY_PREDICTED_INVOICES_URL,
  API_LIQUIDITY_PREDICTED_SALARIES_URL,
  API_LIQUIDITY_TAXES_URL,
  API_LIQUIDITY_URL,
  API_TAX_DETAILS_URL,
} from './urls'

export const loadLiquidityData = (company_id: number) => Api.get(injectCompanyToURL(API_LIQUIDITY_URL, company_id))

export const saveLiquidityData = (company_id: number, data: unknown) =>
  Api.post(injectCompanyToURL(API_LIQUIDITY_URL, company_id), data)

// load weekly data
export const loadLiquidityInvoices = (company_id: number, { year, week }: { year: number; week: number }) =>
  Api.get(
    injectCompanyToURL(API_LIQUIDITY_INVOICES_URL, company_id)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )

export const loadLiquidityPredictedInvoices = (company_id: number, { year, week }: { year: number; week: number }) =>
  Api.get(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_INVOICES_URL, company_id)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )

export const loadLiquidityPredictedIncomes = (
  company_id: number,
  { year, week, params }: { year: number; week: number; params: unknown }
) =>
  Api.get(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_INCOMES_URL, company_id)
      .replace('{year}', String(year))
      .replace('{week}', String(week)),
    { params }
  )

// update
export const updateInvoice = (
  company_id: number,
  { id, planned_payment_date }: { id: number; planned_payment_date: string }
) =>
  Api.patch(injectCompanyToURL(API_LIQUIDITY_INVOICE_DETAIL_URL, company_id).replace('{id}', String(id)), {
    planned_payment_date,
  })

export const updateIncome = (
  company_id: number,
  { id, planned_payment_date }: { id: number; planned_payment_date: string }
) =>
  Api.patch(injectCompanyToURL(API_LIQUIDITY_PREDICTED_INCOME_DETAIL_URL, company_id).replace('{id}', String(id)), {
    planned_payment_date,
  })

// remove
export const removePredictedInvoice = (
  company_id: number,
  { id, year, week }: { id: number; year: number; week: number }
) =>
  Api.delete(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_INVOICE_DETAIL_URL, company_id)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
      .replace('{id}', String(id))
  )

// check: is SzamlazzHU / Billingo connected?
export const fetchLiquidityIncomes = (company_id: number) =>
  Api.get(injectCompanyToURL(API_LIQUIDITY_INCOMES_URL, company_id))

// salary
export const loadLiquidityPredictedWages = (company_id: number, { year, week }: { year: number; week: number }) =>
  Api.get(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_SALARIES_URL, company_id)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )

export const removePredictedSalary = (company_id: number, { year, week }: { year: number; week: number }) =>
  Api.delete(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_SALARIES_URL, company_id)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )

// tax
export const loadLiquidityTaxes = (company_id: number, { year, week }: { year: number; week: number }) =>
  Api.get(
    injectCompanyToURL(API_LIQUIDITY_TAXES_URL, company_id)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )

export const updateTax = (company_id: number, { id, ...data }: { id: number; [key: string]: number | unknown }) =>
  Api.patch(injectCompanyToURL(API_TAX_DETAILS_URL, company_id).replace('{tax_id}', String(id)), data)

// NOTE not used yet
export const removePredictedTax = (company_id: number, { year, week }: { year: number; week: number }) =>
  Api.delete(
    injectCompanyToURL(API_LIQUIDITY_TAXES_URL, company_id)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )
