import React from 'react'
import PropTypes from 'prop-types'

import { Input } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { AmountInput, Typography } from '@components/ui'

import { MaxAmountLabelMessage, MinAmountLabelMessage } from '../messages'
import { FilterWrapper } from '../styles'
import { AdornmentTypography, Divider, ErrorTypography, InputWrapper } from './styles'

// based on @styles:formStyles
const useStyles = makeStyles(theme => ({
  bootstrapRoot: {
    height: 36,
    borderRadius: 4,
    backgroundColor: theme.palette.grayscale.inputBackground,
    border: `1px solid ${theme.palette.grayscale.light}`,
    padding: '6px 10px',
    transition: theme.transitions.create(['border-color']),

    '&:focus-within': {
      borderColor: theme.palette.tertiary.outlineBlue,
    },

    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },
  bootstrapInput: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '0 10px 0 0',
    fontSize: 14,
  },
}))

interface AmountFiltersProps {
  onChange: (values: AmountFilterProps) => void
  values: AmountFilterProps
}

const ERROR_ID = 'amount-validation-error'

export default function AmountFilters({ values, onChange }: AmountFiltersProps) {
  const [hasError, setError] = React.useState(false)
  const classes = useStyles()

  function validate() {
    const { grossAmountMax, grossAmountMin } = values
    setError(grossAmountMax != null && grossAmountMin != null && Number(grossAmountMin) > Number(grossAmountMax))
  }

  function handleMinChange(inputValue: Decimal) {
    // use "undefined" when inputValue is empty string - input cleared
    onChange({ ...values, grossAmountMin: inputValue || undefined })
  }
  function handleMaxChange(inputValue: Decimal) {
    // use "undefined" when inputValue is empty string - input cleared size={30} color="secondary" tag="span"
    onChange({ ...values, grossAmountMax: inputValue || undefined })
  }
  return (
    <FilterWrapper>
      <Typography size="700-xs" color="gray-80">
        <FormattedMessage id="dialogs.filters.labels.amount" defaultMessage="Összeg" />
      </Typography>
      <InputWrapper>
        <Input
          error={hasError}
          classes={{ root: classes.bootstrapRoot, input: classes.bootstrapInput }}
          disableUnderline
          fullWidth
          inputComponent={AmountInput as any}
          inputProps={{ ...(hasError && { 'aria-errormessage': ERROR_ID }) }}
          value={values.grossAmountMin ?? ''}
          onBlur={validate}
          onChange={handleMinChange as any} // there is an issue with NumberFormat's onChange is not match with Input's onChange
          endAdornment={
            <AdornmentTypography size="400-xs" color="gray-50" tag="span">
              {MinAmountLabelMessage}
            </AdornmentTypography>
          }
        />
        <Divider />
        <Input
          error={hasError}
          classes={{ root: classes.bootstrapRoot, input: classes.bootstrapInput }}
          disableUnderline
          fullWidth
          inputComponent={AmountInput as any}
          inputProps={{ ...(hasError && { 'aria-errormessage': ERROR_ID }) }}
          value={values.grossAmountMax ?? ''}
          onBlur={validate}
          onChange={handleMaxChange as any} // there is an issue with NumberFormat's onChange is not match with Input's onChange
          endAdornment={
            <AdornmentTypography size="400-xs" color="gray-50" tag="span">
              {MaxAmountLabelMessage}
            </AdornmentTypography>
          }
        />
      </InputWrapper>
      {hasError && (
        <ErrorTypography id={ERROR_ID} size="400-xs" color="error" data-error>
          <FormattedMessage
            id="dialogs.filters.amount.validationError"
            defaultMessage="A minimum összeg nem lehet nagyobb, mint a maximum összeg"
          />
        </ErrorTypography>
      )}
    </FilterWrapper>
  )
}

AmountFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    grossAmountMax: PropTypes.string,
    grossAmountMin: PropTypes.string,
  }) as React.Validator<AmountFilterProps>,
}
