import React from 'react'
import PropTypes from 'prop-types'

import { useTableRowExpander } from '@contexts'

import { getAriaRowId } from '../helpers'
import { ExpandRowButton } from '../SimpleTable/elements'

interface TableRowExpanderProps {
  row: {
    id: string
  }
  disabled?: boolean
}

/**
 * Expander component responsible for toggling a row's expanded state using `TableRowExpanderProvider`.
 *
 * @param {TableRowExpanderProps} { row }
 */
export default function TableRowExpander({ row: { id: rowId }, disabled = false }: TableRowExpanderProps) {
  const { expandedRows, toggleRow } = useTableRowExpander()

  function toggleExpander() {
    toggleRow(rowId)
  }

  const ariaRowId = getAriaRowId(rowId)
  const isExpanded = Boolean(expandedRows[rowId])

  return (
    <ExpandRowButton isExpanded={isExpanded} aria-controls={ariaRowId} disabled={disabled} onClick={toggleExpander} />
  )
}

TableRowExpander.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
}
