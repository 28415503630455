import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const RefreshIconButton = styled(IconButton)`
  width: 20px;
  height: auto;
  opacity: 0.25;
  color: ${({ theme }) => theme.colors.gray[100]};

  &:hover,
  &:focus {
    background-color: transparent;
    opacity: 1;
  }
`
