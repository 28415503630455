import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import {
  authActions,
  dashboardActions,
  dokumentsActions,
  expenseActions,
  incomeActions,
  paymentActions,
  subscriptionActions,
} from '@services'

type Mode =
  | 'companyDetails'
  | 'companyVats'
  | 'expenseCountV2'
  | 'expenseType'
  | 'paidThroughs'
  | 'payments'
  | 'revenueType'
  | 'subscriptionHistory'
  | 'subscriptionPlans'
  | 'tags'
  | 'ledgerNumbers'
  | 'dokumentType'

interface PureAsyncFetchProps {
  callFetchCompanyVats: (argument: Required<PureAsyncFetchProps>['companyId']) => void
  callFetchDokumentTypes: VoidFunction
  callFetchExpenseCountV2: VoidFunction
  callFetchExpenseType: (argument: Required<PureAsyncFetchProps>['companyId']) => void
  callFetchLedgerNumbers: VoidFunction
  callFetchPaidThroughOptions: VoidFunction
  callFetchPayments: VoidFunction
  callFetchRevenueType: (argument: Required<PureAsyncFetchProps>['companyId']) => void
  callFetchSubscriptionHistory: VoidFunction
  callFetchSubscriptionPlans: VoidFunction
  callFetchTags: VoidFunction
  callRefreshCompanyDetails: (argument: PureAsyncFetchProps['companyId']) => void
  companyId?: number
  isDropdownOptions?: boolean
  mode: Mode[]
}

function PureAsyncFetch({
  callFetchCompanyVats,
  callFetchDokumentTypes,
  callFetchExpenseCountV2,
  callFetchExpenseType,
  callFetchLedgerNumbers,
  callFetchPaidThroughOptions,
  callFetchPayments,
  callFetchRevenueType,
  callFetchSubscriptionHistory,
  callFetchSubscriptionPlans,
  callFetchTags,
  callRefreshCompanyDetails,
  companyId,
  isDropdownOptions = false,
  mode,
}: PureAsyncFetchProps) {
  const calledRef = React.useRef(false)

  React.useEffect(() => {
    if (!calledRef.current) {
      if (mode.includes('expenseCountV2')) {
        callFetchExpenseCountV2()
      }
      if (mode.includes('expenseType')) {
        if (companyId) {
          callFetchExpenseType(companyId)
        }
      }
      if (mode.includes('revenueType')) {
        if (companyId) {
          callFetchRevenueType(companyId)
        }
      }
      if (mode.includes('tags')) {
        callFetchTags()
      }
      if (mode.includes('paidThroughs') && isDropdownOptions) {
        callFetchPaidThroughOptions()
      }
      if (mode.includes('payments')) {
        callFetchPayments()
      }
      if (mode.includes('subscriptionPlans')) {
        callFetchSubscriptionPlans()
      }
      if (mode.includes('subscriptionHistory')) {
        callFetchSubscriptionHistory()
      }
      if (mode.includes('companyVats') && companyId) {
        callFetchCompanyVats(companyId)
      }
      if (mode.includes('companyDetails')) {
        callRefreshCompanyDetails(companyId)
      }
      if (mode.includes('ledgerNumbers')) {
        callFetchLedgerNumbers()
      }
      if (mode.includes('dokumentType')) {
        callFetchDokumentTypes()
      }

      calledRef.current = true
    }
  }, [
    callFetchCompanyVats,
    callFetchDokumentTypes,
    callFetchExpenseCountV2,
    callFetchExpenseType,
    callFetchPaidThroughOptions,
    callFetchPayments,
    callFetchRevenueType,
    callFetchSubscriptionHistory,
    callFetchSubscriptionPlans,
    callFetchTags,
    callRefreshCompanyDetails,
    companyId,
    isDropdownOptions,
    mode,
    callFetchLedgerNumbers,
  ])

  return null
}

PureAsyncFetch.propTypes = {
  callFetchCompanyVats: PropTypes.func.isRequired,
  callFetchDokumentTypes: PropTypes.func.isRequired,
  callFetchExpenseCountV2: PropTypes.func.isRequired,
  callFetchExpenseType: PropTypes.func.isRequired,
  callFetchLedgerNumbers: PropTypes.func.isRequired,
  callFetchPaidThroughOptions: PropTypes.func.isRequired,
  callFetchPayments: PropTypes.func.isRequired,
  callFetchRevenueType: PropTypes.func.isRequired,
  callFetchSubscriptionHistory: PropTypes.func.isRequired,
  callFetchSubscriptionPlans: PropTypes.func.isRequired,
  callFetchTags: PropTypes.func.isRequired,
  callRefreshCompanyDetails: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  isDropdownOptions: PropTypes.bool,
  mode: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired as React.Validator<Mode[]>,
}

function mapStateToProps(state: Store) {
  return {
    companyId: state.auth.company.data?.id, // company.data can be nullable
  }
}

const mapDispatchToProps = {
  callFetchCompanyVats: authActions.fetchCompanyVats.request,
  callFetchDokumentTypes: dokumentsActions.fetchDokumentTypes.request,
  callFetchExpenseCountV2: expenseActions.fetchExpenseCountV2.request,
  callFetchExpenseType: expenseActions.fetchExpenseTypes.request,
  callFetchLedgerNumbers: dashboardActions.fetchLedgerNumbers.request,
  callFetchPaidThroughOptions: paymentActions.fetchPaidThroughOptions.request,
  callFetchPayments: paymentActions.fetchPayments.request,
  callFetchRevenueType: incomeActions.fetchRevenueTypes.request,
  callFetchSubscriptionHistory: subscriptionActions.fetchSubscriptionHistory.request,
  callFetchSubscriptionPlans: subscriptionActions.fetchSubscriptionPlans.request,
  callFetchTags: dashboardActions.fetchTags.request,
  callRefreshCompanyDetails: authActions.refreshCompany.request,
}

const AsyncFetch = connect(mapStateToProps, mapDispatchToProps)(PureAsyncFetch)

AsyncFetch.displayName = 'AsyncFetch'

export default AsyncFetch
