import React from 'react'
import PropTypes from 'prop-types'

import { FormHelperText } from '@material-ui/core'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { EditPaymentProvider } from '@contexts'

import SecondaryLinkButton from '@oldComponents/ui/buttons/SecondaryLinkButton'

import { PAID_INSTALLMENT, PAID_YES } from '@constants'

import { InvoicePaymentDetailsDialog, OpenControls } from './InvoicePaymentDetailsDialog'

interface PaymentInfoProps {
  className?: string
  invoice: Pick<Partial<ExpenseDetailsFrontendValues>, 'id' | 'last_payment_date' | 'paid_status'>
}

function PurePaymentInfo({
  className,
  invoice: { id, paid_status: paidStatus, last_payment_date: lastPaymentDate },
}: PaymentInfoProps) {
  const [openControl, setOpenControl] = React.useState(OpenControls.CLOSE)

  // helpers to control dialog
  const initDialog = React.useCallback(() => setOpenControl(OpenControls.INIT), [setOpenControl])

  const openDialog = React.useCallback(
    () => setOpenControl(openControl === OpenControls.HIDE ? OpenControls.OPEN : OpenControls.INIT),
    [openControl, setOpenControl]
  )

  const hideDialog = React.useCallback(() => setOpenControl(OpenControls.HIDE), [setOpenControl])

  const closeDialog = React.useCallback(() => setOpenControl(OpenControls.CLOSE), [setOpenControl])

  const dialogControls = React.useMemo(
    () => ({ onOpen: hideDialog, onClose: openDialog, onSuccess: initDialog, skipStoreUpdate: true }),
    [hideDialog, initDialog, openDialog]
  )

  if (!id || !paidStatus || !lastPaymentDate) {
    return null
  }

  if ([PAID_YES, PAID_INSTALLMENT].includes(paidStatus)) {
    return (
      <FormHelperText component="div" className={className}>
        <SecondaryLinkButton onClick={openDialog}>
          {paidStatus === PAID_YES ? (
            <FormattedMessage
              id="invoice.payment.info.paid"
              defaultMessage="Fizetve: {date}"
              values={{ date: <FormattedDate value={lastPaymentDate} /> }}
            />
          ) : (
            <FormattedMessage
              id="invoice.payment.info.installment"
              defaultMessage="Részben fizetve: {date}"
              values={{ date: <FormattedDate value={lastPaymentDate} /> }}
            />
          )}
        </SecondaryLinkButton>
        <EditPaymentProvider dialogControls={dialogControls}>
          <InvoicePaymentDetailsDialog openControl={openControl} invoice={id} onClose={closeDialog} />
        </EditPaymentProvider>
      </FormHelperText>
    )
  }

  return null
}

PurePaymentInfo.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.shape({
    id: PropTypes.number,
    last_payment_date: PropTypes.string,
    paid_status: PropTypes.number,
  }).isRequired as React.Validator<PaymentInfoProps['invoice']>,
}

export const PaymentInfo = connect((state: Store) => ({
  invoice: state.expense.details.data,
}))(PurePaymentInfo)

PaymentInfo.displayName = 'PaymentInfo'
