import React from 'react'

import styled from 'styled-components'

function PureAttentionIcon({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" className={className}>
      <g filter="url(#a)">
        <path fill="#fff" d="M9 3.333h4V12H9V3.333Z" />
        <path
          fill="url(#b)"
          d="M7.992 1.883c.352-.352.828-.55 1.326-.55h3.36c.496 0 .973.198 1.325.55l3.109 3.11c.352.35.55.827.55 1.325v3.36c0 .496-.198.973-.55 1.325l-3.11 3.109c-.35.352-.828.55-1.325.55h-3.36c-.497 0-.973-.198-1.325-.55l-3.11-3.11a1.874 1.874 0 0 1-.549-1.325v-3.36c0-.497.198-.973.55-1.325l3.11-3.11Zm3.005 2.781a.624.624 0 0 0-.625.625v2.917c0 .346.28.625.625.625a.623.623 0 0 0 .625-.625V5.289a.623.623 0 0 0-.625-.625Zm.834 5.833a.833.833 0 1 0-1.667 0 .833.833 0 0 0 1.667 0Z"
        />
        <path
          fill="#fff"
          d="M5.167 10.154V5.846l3.68-3.68h4.307l3.68 3.68v4.308l-3.68 3.68H8.846l-3.68-3.68ZM8.5 1.334 4.333 5.5v5L8.5 14.667h5l4.167-4.167v-5L13.5 1.333h-5Z"
        />
      </g>
      <defs>
        <linearGradient id="b" x1="11.003" x2="11.003" y1="14.672" y2="1.333" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF2323" />
          <stop offset="1" stopColor="#FD6767" />
        </linearGradient>
        <filter
          id="a"
          width="21.333"
          height="21.333"
          x=".333"
          y=".333"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1514_27" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1514_27" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export const AttentionIcon = styled(PureAttentionIcon)<{ $visible: boolean }>`
  position: absolute;
  top: 8px;
  right: 2px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity ease-in-out 300ms;
`
