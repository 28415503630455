import React from 'react'
import PropTypes from 'prop-types'

import { AMPLITUDE_EVENTS, sendAmplitudeData } from '@helpers/amplitude'

import { AmplitudeTrackEventProps, AmplitudeTrackPageViewProps } from './types'

export function AmplitudeTrackEvent({ eventType }: AmplitudeTrackEventProps) {
  React.useEffect(() => {
    sendAmplitudeData(eventType)
  }, [eventType])

  return null
}

AmplitudeTrackEvent.propTypes = {
  eventType: PropTypes.oneOf(Object.values(AMPLITUDE_EVENTS)).isRequired,
}

export function AmplitudeTrackPageView({ eventProperties }: AmplitudeTrackPageViewProps) {
  const calledRef = React.useRef(false)

  React.useEffect(() => {
    if (!calledRef.current) {
      sendAmplitudeData(AMPLITUDE_EVENTS.PAGE_VIEW, eventProperties)
      calledRef.current = true
    }
  }, [eventProperties])

  return null
}

AmplitudeTrackPageView.propTypes = {
  eventProperties: PropTypes.object,
}
