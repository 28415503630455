import { API_PREFIX, COMPANY_ROUTE, V2_COMPANY_ROUTE } from '@constants'

export const API_RENEW_TOKEN_URL = `${API_PREFIX}auth/refresh-token/`
export const API_CHANGE_PASSWORD_URL = `${API_PREFIX}auth/password/`
export const API_LOGIN_URL = `${API_PREFIX}auth/login/`
export const API_LOGOUT_URL = `${API_PREFIX}auth/logout/`
export const API_USER_URL = `${API_PREFIX}auth/me/`
export const API_USER_COMPANIES_URL = `${API_PREFIX}company/`
export const API_RESET_PASSWORD_URL = `${API_PREFIX}auth/password/reset/`
export const API_RESET_PASSWORD_CONFIRM_URL = `${API_PREFIX}auth/password/reset/confirm/`
export const API_SIGNUP_URL = `${API_PREFIX}users/create/`
export const API_PREFERENCES_URL = `${API_PREFIX}users/preferences/`
export const API_INVITATION_URL = `${API_PREFIX}invite/{hash}/`
// (beta) user email check for registration
export const API_EMAIL_CHECK_URL = `${API_PREFIX}check-email/`
// 2factor auth
export const API_2FACTOR_AUTH_URL = `${API_PREFIX}auth/two-factor/`
export const API_2FACTOR_QR_CODE_URL = `${API_PREFIX}auth/two-factor/qr-code/`
export const API_2FACTOR_VALIDATE_DEVICE_URL = `${API_PREFIX}auth/two-factor/validate-device/`
export const API_2FACTOR_BACKUP_TOKENS_URL = `${API_PREFIX}auth/two-factor/backup-tokens/`
export const API_2FACTOR_DELETE_URL = `${API_PREFIX}auth/two-factor/delete/`
/* MULTI COMPANY */
// create and update
export const API_COMPANY_URL = `${API_PREFIX}company/`
export const API_COMPANY_DETAIL_URL = COMPANY_ROUTE
// members
export const API_COMPANY_MEMBERS_URL = `${COMPANY_ROUTE}user/`
export const API_COMPANY_MEMBER_DETAILS_URL = `${COMPANY_ROUTE}user/{company_user_id}/`
// company vat
export const API_COMPANY_VATS_URL = `${COMPANY_ROUTE}vat/`
export const API_COMPANY_VAT_DETAILS_URL = `${COMPANY_ROUTE}vat/{company_vat_id}/`
// integrations
export const API_INTEGRATION_PROVIDER_URL = `${COMPANY_ROUTE}integration/`
// user notifications
export const API_USER_NOTIFICATION_URL = `${API_PREFIX}notification/{notification_id}/`
/* TAXATION */
export const API_TAXATION_TAX_FORM_URL = `${V2_COMPANY_ROUTE}tax-form/`
