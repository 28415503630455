import __escapeRegExp from 'lodash/escapeRegExp'

type FilterOptionOption = {
  label: string
  value: string
  data: unknown
}

//* Custom filter

// based on: https://react-select.com/props
/**
 * Helper function to only show options that includes the text in the input
 *
 * @export
 * @param {FilterOptionOption} { label }
 * @param {string} inputValue
 * @returns {boolean}
 */
export function customFilterOption({ label }: FilterOptionOption, inputValue: string): boolean {
  // input empty return all options
  if (!inputValue.trim()) {
    return true
  }
  const searchRegexp = new RegExp(__escapeRegExp(inputValue), 'i')
  return searchRegexp.test(label)
}
