import React from 'react'
import PropTypes from 'prop-types'

import { ClickAwayListener, Grow, Popper } from '@material-ui/core'
import { connect } from 'react-redux'
import { matchPath, useLocation } from 'react-router'

import { useCreateCompanyDialog } from '@contexts/CreateCompanyDialogProvider'

import { useCompactNavbarVisible } from '@hooks'

import { SearchableCompanyMenu } from '@components/navigations/elements/ProfileMenu/SearchableCompanyMenu'
import { Paper, SettingsIcon, Typography } from '@components/ui'
import { SecondaryLinkButton } from '@oldComponents/ui'

import { ROUTES } from '@constants'

import { CompanyNameWithBadge } from '../CompanyNameWithBadge'

import { TertiaryInfoTypography } from '../styles'
import { CreateNewCompanyMessage, MyCompaniesMessage, ProfileSettingsMessage } from './messages'
import {
  MenuFooter,
  MenuHeader,
  MenuRootContainer,
  MenuRootMobileContainer,
  ProfileDesktopNavLink,
  RootContainer,
  StyledExpandMoreIcon,
  StyledNavButton,
  StyledNavLink,
} from './styles'

interface ProfileMenuProps {
  companies: CompaniesItem[]
  currentCompanyId: number | undefined
  disabled: boolean
  isActive?: boolean
  isCompact?: boolean
  isDesktop?: boolean
  onCloseCallback?: VoidFunction
  userEmail: string
}

function PureProfileMenu({
  companies,
  currentCompanyId,
  disabled,
  isActive = false,
  isCompact = false,
  isDesktop = false,
  onCloseCallback,
  userEmail,
}: ProfileMenuProps) {
  const location = useLocation()
  const { open: openDialog } = useCreateCompanyDialog()
  const compactVisible = useCompactNavbarVisible()

  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null)
  const open = Boolean(anchorEl)

  React.useEffect(() => {
    if (compactVisible && open) {
      setAnchorEl(null)
    }
  }, [compactVisible, open])

  function handleToggle(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function handleClose(event: React.MouseEvent<HTMLElement | Document>) {
    if (anchorEl && anchorEl.contains(event.target as Nullable<Node>)) {
      return
    }
    setAnchorEl(null)
    onCloseCallback?.()
  }

  function handleSelectCompany(company: CompaniesItem, isSettings?: boolean) {
    return function handler(event: React.MouseEvent<HTMLElement>) {
      const matchSettings = matchPath(ROUTES.company, location.pathname)
      if (company.id === currentCompanyId) {
        if (!isSettings || (isSettings && matchSettings && matchSettings.params.company_id === String(company.id))) {
          event.preventDefault()
        }
      }
      setAnchorEl(null)
      onCloseCallback?.()
    }
  }

  function handleDialogOpen() {
    openDialog()
    setAnchorEl(null)
    onCloseCallback?.()
  }

  function renderMenu() {
    return (
      <>
        <MenuHeader>
          <Typography color="gray-80" size="700-md">
            {MyCompaniesMessage}
          </Typography>
          <SecondaryLinkButton onClick={handleDialogOpen} disabled={disabled}>
            {CreateNewCompanyMessage}
          </SecondaryLinkButton>
        </MenuHeader>
        <SearchableCompanyMenu
          currentCompanyId={currentCompanyId}
          data={companies}
          disabled={disabled}
          onSelect={handleSelectCompany}
        />
        {!isCompact && (
          <MenuFooter>
            <ProfileDesktopNavLink linkWhenNoCompany to={ROUTES.profile} onClick={handleClose}>
              <SettingsIcon />
              <Typography size="700-sm">{ProfileSettingsMessage}</Typography>
              <TertiaryInfoTypography>{userEmail}</TertiaryInfoTypography>
            </ProfileDesktopNavLink>
          </MenuFooter>
        )}
      </>
    )
  }

  // DESKTOP
  if (isDesktop) {
    if (companies.length) {
      return (
        <RootContainer>
          <StyledNavButton
            $isActive={isActive}
            aria-haspopup="true"
            aria-owns={open ? 'menu-list-grow' : undefined}
            data-testid="profile-menu-btn"
            disabled={disabled}
            onClick={handleToggle}
          >
            <CompanyNameWithBadge />
            <StyledExpandMoreIcon $isOpen={open} />
          </StyledNavButton>
          <Popper open={open} anchorEl={anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                // @ts-expect-error faulty MUI type
                id="menu-list-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuRootContainer data-testid="profile-menu">{renderMenu()}</MenuRootContainer>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </RootContainer>
      )
    } else {
      // user has no company
      return (
        <RootContainer>
          <StyledNavLink linkWhenNoCompany to={ROUTES.profile} $isActive={isActive}>
            {ProfileSettingsMessage}
          </StyledNavLink>
        </RootContainer>
      )
    }
  }

  // MOBILE
  if (companies.length) {
    return (
      <RootContainer>
        <MenuRootMobileContainer data-testid="profile-menu">{renderMenu()}</MenuRootMobileContainer>
      </RootContainer>
    )
  } else {
    // user has no company
    return (
      <RootContainer>
        <StyledNavLink linkWhenNoCompany to={ROUTES.profile} $isActive={isActive}>
          {ProfileSettingsMessage}
        </StyledNavLink>
      </RootContainer>
    )
  }
}

PureProfileMenu.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ).isRequired as React.Validator<CompaniesItem[]>,
  currentCompanyId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  isActive: PropTypes.bool,
  isCompact: PropTypes.bool,
  isDesktop: PropTypes.bool,
  onCloseCallback: PropTypes.func,
  userEmail: PropTypes.string.isRequired,
}

export const ProfileMenu = connect((state: Store) => ({
  companies: state.auth.companies,
  currentCompanyId: state.auth.company.data?.id,
  disabled: state.auth.company.loading,
  userEmail: state.auth.user.email,
}))(PureProfileMenu)

ProfileMenu.displayName = 'ProfileMenu'
