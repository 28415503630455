import React from 'react'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { BackendReportResponse } from './types'

interface UseFetchReportsState {
  error: BackendError
  loading: boolean
  reports: BackendReportResponse
  updatedAt: string
}

export function useFetchReports(fetchReports: AsyncFunction<void, BackendReportResponse>) {
  const mountedRef = React.useRef(true)
  const cPromiseRef = useCancellablePromiseRef<BackendReportResponse>()

  const [{ reports, loading, error, updatedAt }, setState] = React.useState<UseFetchReportsState>({
    error: null,
    loading: true,
    reports: [],
    updatedAt: '',
  })

  const fetchReportsHandler = React.useCallback(async () => {
    if (!mountedRef.current) {
      return
    }
    try {
      cPromiseRef.current = cancellablePromise(fetchReports())
      const results = await cPromiseRef.current.promise
      setState(prevState => ({
        ...prevState,
        loading: false,
        reports: results,
        updatedAt: new Date().toISOString(),
      }))
    } catch (error) {
      const errorMsg = parseApiErrorMessage(error)
      if (errorMsg) {
        setState(prevState => ({ ...prevState, loading: false, error: errorMsg }))
      }
    }
  }, [cPromiseRef, fetchReports])

  React.useEffect(() => {
    fetchReportsHandler()

    return () => {
      mountedRef.current = false
    }
  }, [fetchReportsHandler])

  return { reports, loading, error, refetch: fetchReportsHandler, updatedAt }
}
