import PropTypes from 'prop-types'

// custom prop-type validator
type CustomValidatorArguments = [
  props: {
    [key: string]: any
  },
  propName: string,
  componentName: string,
  location: string,
  propFullName: string
]
type CustomValidatorFunction = (...args: CustomValidatorArguments) => Error | null

export function conditionalRequiredPropType(
  validator: PropTypes.Requireable<any>,
  condition: (props: Record<string, unknown>) => boolean
): CustomValidatorFunction {
  return function customValidator(...args) {
    const test = condition(args[0]) ? validator.isRequired : validator
    // @ts-expect-error this cant be typed here
    return test.apply(this, args)
  }
}

export function HTMLElementPropType(
  props: Record<string, unknown>,
  propName: string,
  componentName: string,
  location: string,
  propFullName: string
): Error | null {
  const propValue = props[propName]
  const safePropName = propFullName || propName

  if (propValue == null) {
    return null
  }

  if (propValue && (propValue as any).nodeType !== 1) {
    return new Error(
      `Invalid ${location} \`${safePropName}\` supplied to \`${componentName}\`. ` + `Expected an HTMLElement.`
    )
  }

  return null
}
