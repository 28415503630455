import React from 'react'

import styled from 'styled-components'

function BaseHorizontalDotMenuIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="12" r="2" fill="currentColor" />
      <circle cx="12" cy="12" r="2" fill="currentColor" />
      <circle cx="20" cy="12" r="2" fill="currentColor" />
    </svg>
  )
}

export const HorizontalDotMenuIcon = styled(BaseHorizontalDotMenuIcon)``
