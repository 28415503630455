import { API_PREFIX, COMPANY_ROUTE, V2_COMPANY_ROUTE } from '@constants'

export const API_CONFIG_URL = `${API_PREFIX}init/`

export const API_DASHBOARD_CHECK_URL = `${COMPANY_ROUTE}status-check/`
// invoice tags
export const API_TAGS_URL = `${COMPANY_ROUTE}tag/`
// salary
export const API_SALARY_AND_TAX_FILE_UPLOAD_URL = `${COMPANY_ROUTE}payroll/`
export const API_SALARY_GROUP_LIST_URL = `${COMPANY_ROUTE}monthly-salary/`
export const API_SALARY_GROUP_DETAIL_URL = `${COMPANY_ROUTE}monthly-salary/{monthly_salary_id}/` //eslint-disable-line max-len
export const API_SALARY_LIST_URL = `${COMPANY_ROUTE}monthly-salary/{monthly_salary_id}/salaries/` //eslint-disable-line max-len
export const API_SALARY_USER_VIEW_URL = `${COMPANY_ROUTE}monthly-salary/{monthly_salary_id}/user-saw/` //eslint-disable-line max-len
// tax
export const API_TAX_GROUP_LIST_URL = `${COMPANY_ROUTE}monthly-tax/`
export const API_TAX_GROUP_DETAIL_URL = `${COMPANY_ROUTE}monthly-tax/{monthly_tax_id}/` //eslint-disable-line max-len
export const API_TAX_LIST_URL = `${COMPANY_ROUTE}monthly-tax/{monthly_tax_id}/taxes/` //eslint-disable-line max-len
export const API_TAX_USER_VIEW_URL = `${COMPANY_ROUTE}monthly-tax/{monthly_tax_id}/user-saw/` //eslint-disable-line max-len

// tax codes
export const API_TAX_CODE_LIST_URL = `${COMPANY_ROUTE}taxcode/`

// tagging
export const API_BULK_TAGGING_INIT_URL = `${COMPANY_ROUTE}tag/bulk-query-tags/`
export const API_BULK_TAGGING_UPDATE_URL = `${COMPANY_ROUTE}tag/bulk-update-tags/`

//* accounting
export const API_PARTNER_JOB_NUMBER_HISTORY_URL = `${COMPANY_ROUTE}partner/{partner_id}/jobnumber-history/`
export const API_ASSIGNMENT_NOTE_RECOMMENDATIONS_URL = `${COMPANY_ROUTE}notes`
export const API_LEDGER_NUMBERS_URL = `${COMPANY_ROUTE}ledger-numbers/`

//* new generic event log
export const API_EVENTS_LOG_URL = `${V2_COMPANY_ROUTE}eventlog/{document_type}/{document_id}/`

//* Company consent
export const API_COMPANY_CONSENTS_URL = `${V2_COMPANY_ROUTE}company-consent/`
export const API_COMPANY_CONSENT_CREATE_URL = `${V2_COMPANY_ROUTE}company-consent/create/`
