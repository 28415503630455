import React from 'react'
import PropTypes from 'prop-types'

export default function BadgePercentIcon({ className, size = 18 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(size / 18) * 19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C10.2937 0 11.4187 0.732283 11.9848 1.80771C13.1414 1.41858 14.4176 1.73201 15.3633 2.65249C16.2773 3.57297 16.527 4.88896 16.2035 6.05283C17.2723 6.62238 18 7.75441 18 9.05625C18 10.3581 17.2723 11.4901 16.2035 12.0597C16.5586 13.2235 16.2773 14.5077 15.3633 15.4593C14.4176 16.3791 13.1414 16.6302 11.9848 16.3048C11.4187 17.3802 10.2937 18.1125 9 18.1125C7.70625 18.1125 6.58125 17.3802 6.01523 16.3048C4.85859 16.6621 3.55078 16.3791 2.63602 15.4593C1.72125 14.5077 1.44141 13.2235 1.79648 12.0597C0.727734 11.4901 0 10.3581 0 9.05625C0 7.75441 0.727734 6.62238 1.79648 6.05283C1.40977 4.88896 1.72125 3.57297 2.63602 2.65249C3.55078 1.73201 4.85859 1.45041 6.01523 1.80771C6.58125 0.732283 7.70625 0 9 0ZM6.75 7.92422C7.37227 7.92422 7.875 7.41834 7.875 6.79219C7.875 6.16603 7.37227 5.66016 6.75 5.66016C6.12773 5.66016 5.625 6.16603 5.625 6.79219C5.625 7.41834 6.12773 7.92422 6.75 7.92422ZM11.25 10.1883C10.6277 10.1883 10.125 10.6942 10.125 11.3203C10.125 11.9465 10.6277 12.4523 11.25 12.4523C11.8723 12.4523 12.375 11.9465 12.375 11.3203C12.375 10.6942 11.8723 10.1883 11.25 10.1883ZM11.816 7.36174C12.1746 7.06104 12.1746 6.52333 11.816 6.1908C11.5172 5.8618 10.9828 5.8618 10.6523 6.1908L6.15234 10.7189C5.82539 11.0515 5.82539 11.5892 6.15234 11.8899C6.48281 12.2507 7.01719 12.2507 7.31602 11.8899L11.816 7.36174Z"
        fill="currentColor"
      />
    </svg>
  )
}

BadgePercentIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}
