import actions from './actions'

export const initialState: SubscriptionStore = {
  plans: {
    fetched: false,
    loading: false,
    data: [],
    error: null,
  },
  promo: null,
  braintree_token: null,
  history: {
    fetched: false,
    loading: false,
    data: [],
    error: null,
  },
}

// TODO later type should depend on generated actions' types [xxx]_[REQUEST|SUCCESS|FAILURE]
type ReducerAction = { type: string; payload: any }
// reducer
export default function reducer(state: SubscriptionStore = initialState, action: ReducerAction) {
  switch (action.type) {
    case actions.fetchSubscriptionPlans.REQUEST:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchSubscriptionPlans.FAILURE:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          error: action.payload,
        },
      }

    case actions.fetchSubscriptionPlans.SUCCESS:
      return {
        ...state,
        plans: {
          data: action.payload.plans,
          loading: false,
          fetched: true,
          error: null,
        },
        promo: action.payload.promo,
        braintree_token: action.payload.braintree_token,
      }

    case actions.applyPromocode.SUCCESS: // TODO: maybe need to separate them
      return {
        ...state,
        plans: {
          ...state.plans,
          data: action.payload.plans,
        },
        promo: action.payload.promo,
      }

    case actions.fetchSubscriptionHistory.REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchSubscriptionHistory.FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          loading: false,
          error: action.payload,
        },
      }

    case actions.fetchSubscriptionHistory.SUCCESS:
      return {
        ...state,
        history: {
          data: action.payload,
          loading: false,
          fetched: true,
          error: null,
        },
      }

    default:
      return state
  }
}
