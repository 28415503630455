import React from 'react'

import { Grid } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { GroupBase, SelectInstance } from 'react-select'
import styled from 'styled-components'

import { dashboardActions, dokumentsActions } from '@services'

import { bindActionToPromise, parseApiErrorMessage } from '@helpers'
import { onCreateTagHandler } from '@oldComponents/forms/DetailsForm/helpers'

import { useAlertDispatch, useDokumentDetailsControlsState } from '@contexts'

import { DokumentAiRecognition } from '@components/DokumentAiRecognition'
import {
  FormBlock,
  FormBlockTitle,
  ReactHookForm,
  ReactHookFormCheckboxField,
  ReactHookFormError,
  ReactHookFormSelectField,
  ReactHookFormSubmitButton,
  ReactHookFormTextField,
} from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { COMPANY_USER_FEATURE_PERMISSION_MAPPER as FEATURE_PERMISSIONS } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { SyncFormWithDokumentDetailsControls } from '../SyncFormWithDokumentDetailsControls'
import { isDokumentDetailsFilled } from './helper'
import { SyncFormStateWithControls } from './SyncFormStateWithControls'

import { formSelectMessages } from '@messages'
import { SPACING } from '@oldComponents/forms/DetailsForm/styles'
import { messages } from './messages'

export const ButtonsContainerDiv = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`

const TooltipWrapperDiv = styled.div`
  display: flex;
  &:hover {
    cursor: help;
  }
  & > svg {
    transform: translate(3px, -3px);
  }
`

type DokumentDetailsFormValues = {
  containsPayable: boolean
  documentTypeId: number
  keywords: string
  recipient: string
  sender: string
  simpleTagIds: number[]
  summary: string
  title: string
}

interface DokumentDetailsFormProps {
  company: number
  createNewDokumentType: CreateCommonIdAndNameTypeCallback
  createNewTag: CreateCommonIdAndNameTypeCallback
  detailsMatchedWithParam: boolean
  documentDetails: BackendDokumentDetailResult
  documentTypeOptions: CommonIdAndNameType[]
  handleSubmit: AsyncFunction<DokumentDetailsUpdatePayload, unknown>
  isTagsDisabledForUser: boolean
  readOnly: boolean
  tagOptions: Tag[]
}

function PureDokumentDetailsForm({
  company,
  createNewDokumentType,
  createNewTag,
  documentDetails,
  documentTypeOptions,
  handleSubmit,
  isTagsDisabledForUser,
  readOnly,
  tagOptions,
}: DokumentDetailsFormProps) {
  const { formatMessage } = useIntl()
  const { setErrorAlert } = useAlertDispatch()
  const [creatingNewTag, setNewTag] = React.useState(false)
  const [creatingNewDokumentType, setNewDokumentType] = React.useState(false)
  const { isActionInProgress } = useDokumentDetailsControlsState()

  const { isUnsavedChangesBlocked } = useDokumentDetailsControlsState()

  const initialValues: DokumentDetailsFormValues = React.useMemo(
    () => ({
      containsPayable: documentDetails.containsPayable ?? false,
      documentTypeId: documentDetails.documentTypeId,
      keywords: (documentDetails.keywords ?? []).join(', '),
      recipient: documentDetails.recipient || '',
      sender: documentDetails.sender || '',
      simpleTagIds: documentDetails.simpleTagIds ?? [],
      summary: documentDetails.summary || '',
      title: documentDetails.title || '',
    }),
    [documentDetails]
  )

  function handleCreateTag(newOption: Tag, selectRef: SelectInstance<Tag, boolean, GroupBase<Tag>>) {
    onCreateTagHandler({
      company,
      createNewTag,
      newOption,
      selectRef,
      setErrorAlert,
      setNewTag,
    })
  }

  async function handleCreateDokumentType(
    newOption: CommonIdAndNameType,
    selectRef: SelectInstance<CommonIdAndNameType, boolean, GroupBase<CommonIdAndNameType>>
  ) {
    setNewDokumentType(true)
    try {
      const result = await createNewDokumentType({ name: newOption.name, company })
      selectRef.selectOption(result)
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setErrorAlert(errorMessage)
      }
    }
    setNewDokumentType(false)
  }

  function onSubmit({ keywords, ...values }: DokumentDetailsFormValues) {
    const payload = {
      ...values,
      id: documentDetails.id,
      keywords: keywords
        .trim()
        .split(/[;,]|,\s+/)
        .filter(o => o),
    }

    return handleSubmit(payload)
  }

  const disableField = isActionInProgress || readOnly
  const needConfirm = isDokumentDetailsFilled(documentDetails)

  return (
    <ReactHookForm initialValues={initialValues} onSubmit={onSubmit} skipUnsavedChanges={isUnsavedChangesBlocked}>
      <SyncFormWithDokumentDetailsControls />
      {!readOnly && (
        <DokumentAiRecognition
          data={documentDetails.documentAiRecognition}
          documentId={documentDetails.id}
          needConfirm={needConfirm}
          requestUrl={documentDetails.links?.aiRecognition}
          statusUrl={documentDetails.links?.aiRecognitionStatus}
        />
      )}
      <SyncFormStateWithControls />
      <FormBlock>
        <FormBlockTitle>
          <FormattedMessage id="dokumentDetails.heading.details" defaultMessage="Részletek" />
        </FormBlockTitle>
        <Grid container spacing={SPACING}>
          <Grid item xs={12}>
            <ReactHookFormTextField
              disabled={disableField}
              label={
                <LightTooltip title={formatMessage(messages.titleTooltip)} placement="top">
                  <TooltipWrapperDiv>
                    {formatMessage(messages.titleLabel)}
                    <InfoIcon style={{ fontSize: 20 }} color="inherit" />
                  </TooltipWrapperDiv>
                </LightTooltip>
              }
              name="title"
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={12}>
            <ReactHookFormTextField
              disabled={disableField}
              label={
                <LightTooltip title={formatMessage(messages.summaryTooltip)} placement="top">
                  <TooltipWrapperDiv>
                    {formatMessage(messages.summaryLabel)}
                    <InfoIcon style={{ fontSize: 20 }} color="inherit" />
                  </TooltipWrapperDiv>
                </LightTooltip>
              }
              maxRows={6}
              minRows={3}
              multiline
              name="summary"
              type="textarea"
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={12}>
            <ReactHookFormTextField
              disabled={disableField}
              label={
                <LightTooltip title={formatMessage(messages.keywordsTooltip)} placement="top">
                  <TooltipWrapperDiv>
                    {formatMessage(messages.keywordsLabel)}
                    <InfoIcon style={{ fontSize: 20 }} color="inherit" />
                  </TooltipWrapperDiv>
                </LightTooltip>
              }
              maxRows={3}
              minRows={1}
              multiline
              name="keywords"
              type="textarea"
            />
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <FormBlockTitle>
          <FormattedMessage id="dokumentDetails.heading.otherInfo" defaultMessage="Egyéb adatok" />
        </FormBlockTitle>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <ReactHookFormTextField disabled={disableField} label={formatMessage(messages.senderLabel)} name="sender" />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormTextField
              disabled={disableField}
              label={formatMessage(messages.recipientLabel)}
              name="recipient"
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <ReactHookFormCheckboxField
              disabled={disableField}
              label={formatMessage(messages.containsPayableLabel)}
              name="containsPayable"
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <ReactHookFormSelectField
              disabled={disableField}
              isLoading={creatingNewDokumentType}
              label={formatMessage(messages.documentTypeLabel)}
              labelKey="name"
              name="documentTypeId"
              onCreate={handleCreateDokumentType}
              options={documentTypeOptions}
              valueKey="id"
            />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormSelectField
              disabled={isTagsDisabledForUser || readOnly}
              isClearable
              isLoading={creatingNewTag}
              isMulti
              label={formatMessage(messages.simpleTagsLabel)}
              name="simpleTagIds"
              onCreate={handleCreateTag}
              onEmptyCreateText={formatMessage(formSelectMessages.tagsEmptyCreateText)}
              options={tagOptions}
            />
          </Grid>
        </Grid>
      </FormBlock>
      <ReactHookFormError withFade hideIcon />
      {!readOnly && (
        <ButtonsContainerDiv>
          <ReactHookFormSubmitButton hasDisabledState initialValues={initialValues} disabled={isActionInProgress} />
        </ButtonsContainerDiv>
      )}
    </ReactHookForm>
  )
}

export const DokumentDetailsForm = connect(
  (state: Store) => ({
    company: state.auth.company.data.id,
    documentDetails: state.dokuments.details.data,
    documentTypeOptions: state.dokuments.dokumentTypes.data,
    isTagsDisabledForUser: permissionDeniedForUser(state, FEATURE_PERMISSIONS.tag),
    tagOptions: state.dashboard.tags.data,
  }),
  dispatch => ({
    createNewDokumentType: bindActionToPromise(dispatch, dokumentsActions.createDokumentType.request),
    createNewTag: bindActionToPromise(dispatch, dashboardActions.createTag.request),
    handleSubmit: bindActionToPromise(dispatch, dokumentsActions.updateDokumentDetails.request),
  })
)(PureDokumentDetailsForm)

DokumentDetailsForm.displayName = 'DokumentDetailsForm'
