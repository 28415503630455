import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  backToSzamlazz: {
    id: 'buttons.backToSzamlazz.title',
    defaultMessage: 'Vissza a Számlázz.hu-ra',
  },
  logout: {
    id: 'buttons.logout',
    defaultMessage: 'Kijelentkezés',
  },
})
