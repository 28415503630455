import React from 'react'

import { DocViewerMainState } from '@components/DocViewer/types'

import { ImageStateActions } from './actions'
import { DocViewerImageState, ImageStateReducer, initialImageState, reducer } from './reducer'

export const ImageControlsContext = React.createContext<{
  state: DocViewerImageState
  dispatch: React.Dispatch<ImageStateActions>
}>({ state: initialImageState, dispatch: () => null })

ImageControlsContext.displayName = 'ImageControlsContext'

export const ImageControlsProvider: React.FC<React.PropsWithChildren<{ mainState: DocViewerMainState }>> = ({
  children,
  mainState,
}) => {
  const [state, dispatch] = React.useReducer<ImageStateReducer>(reducer, {
    ...initialImageState,
    defaultZoomLevel: mainState.config?.zoom?.defaultZoom ?? initialImageState.defaultZoomLevel,
    zoomLevel: mainState.config?.zoom?.defaultZoom ?? initialImageState.zoomLevel,
    zoomJump: mainState.config?.zoom?.zoomJump ?? initialImageState.zoomJump,
    mainState,
  })

  return <ImageControlsContext.Provider value={{ state, dispatch }}>{children}</ImageControlsContext.Provider>
}
