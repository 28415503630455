// TODO: move to components

import React, { Component } from 'react'
import { arrayOf, bool, func, node, number, object, oneOfType, shape, string } from 'prop-types'

import cx from 'classnames'
import __get from 'lodash/get'
import __isObject from 'lodash/isObject'
import __orderBy from 'lodash/orderBy'
import __uniqueId from 'lodash/uniqueId'
import Select, { components as reactSelectComponents } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { customFilterOption, isFieldHighlighted } from '@helpers'

import { WindowedMenuList } from '@oldComponents/ui/WindowedMenuList'

import { selectFormStyles as customReactSelectStyles } from '@styles/select'

const getSelectableOptions = options => {
  const isGroupedOption = option => {
    // { label: String, options: Array}
    if (
      __isObject(option) &&
      Object.prototype.hasOwnProperty.call(option, 'label') &&
      Object.prototype.hasOwnProperty.call(option, 'options') &&
      Array.isArray(option.options)
    ) {
      return option.options.map(function (opt) {
        return isGroupedOption(opt)
      })
    } else {
      return option
    }
  }
  const flatten = arr =>
    arr.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
    }, [])

  const optionsArrays = options.map(option => isGroupedOption(option))
  return flatten(optionsArrays)
}

const orderSelectOptions = (options, field, skipSorting = false) => {
  if (skipSorting) {
    return options
  }

  return __orderBy(
    options,
    item => {
      const label = __get(item, field, '')
      if (typeof label === 'string') {
        return label.toLowerCase()
      } else {
        return label
      }
    },
    'asc'
  )
}

const getNoOptionMessage = (isCreatable, selectOptions, noResultsText, onEmptyCreateText) => {
  if (isCreatable && onEmptyCreateText && !selectOptions.length) {
    return <span style={{ fontSize: 12 }}>{onEmptyCreateText}</span>
  } else {
    return noResultsText
  }
}

// need to customize the recommended option
const CustomSelectOption = reactSelectComponents.Option
// react-select custom options
const MyOption = ({ children, texts, ...props }) => {
  const {
    data: { isExist },
  } = props
  return (
    <CustomSelectOption {...props}>
      <div className="custom-option">
        {texts && isExist === false && <span className="badge">{texts.newOptionPrefix + ' '}</span>}
        <span {...(typeof children === 'string' ? { title: children } : {})}>{children}</span>
      </div>
    </CustomSelectOption>
  )
}
MyOption.propTypes = {
  children: node.isRequired,
  texts: object,
  data: shape({ isExist: bool }).isRequired,
}

function withTexts(texts, Comp) {
  return function HOC(props) {
    return <Comp {...props} texts={texts} />
  }
}

// TODO move into component
class MySelect extends Component {
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.input.name === 'partner') {
  //     console.group('Select updated')
  //     Object.entries(this.props).forEach(
  //       ([key, val]) =>
  //         prevProps[key] !== val && console.log(`Prop '${key}' changed`)
  //     )
  //     if (this.state) {
  //       Object.entries(this.state).forEach(
  //         ([key, val]) =>
  //           prevState[key] !== val && console.log(`State '${key}' changed`)
  //       )
  //     }
  //     console.groupEnd()
  //   }
  // }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  handleBlur = () => {
    const { onBlur, value } = this.props.input
    // directly can't call onBlur cause it will trigger a component update
    // what will break the react-select inputBlur event and won't clear
    // the focusedValue from its state
    this.timeout = setTimeout(onBlur, 100, value)
  }

  handleChange = (option, { action }) => {
    const {
      input: { onChange },
      valueKey,
      labelKey,
      callback,
      multi,
      onCreate,
    } = this.props

    switch (action) {
      case 'select-option':
        // check isExist property of selected option:
        // true: new recommendation call onCreate
        // false - existing recommendation call onChange
        // simple options has no isExist property so __get will return null
        if (__get(option, 'isExist', null) === false) {
          // [true, false, null] === false
          onCreate({ [labelKey]: option[valueKey] }, this.select)
        } else {
          // (creatable) select without recommendations
          if (multi) {
            // option is an array
            onChange(option.map(el => __get(el, valueKey, null)).filter(a => a))
          } else {
            onChange(__get(option, valueKey, null))
          }
          if (callback) {
            callback(option)
          }
        }
        break
      case 'remove-value':
        if (multi) {
          // option is an array
          onChange(option.map(el => __get(el, valueKey, null)).filter(a => a))
        } else {
          onChange(__get(option, valueKey, null))
        }
        if (callback) {
          callback(option)
        }
        break
      case 'clear':
        onChange(multi ? [] : null)
        break
      default:
    }
  }

  handleCreateOption = newValue => {
    const { onCreate, labelKey } = this.props

    onCreate({ [labelKey]: newValue }, this.select)
  }

  getValue = () => {
    const {
      options,
      multi,
      input: { value },
      valueKey,
      labelKey,
      skipSorting,
    } = this.props
    const selectableOptions = getSelectableOptions(options)
    if (multi) {
      return orderSelectOptions(
        selectableOptions.filter(o => value.includes(o[valueKey])),
        labelKey,
        skipSorting
      )
    } else {
      return selectableOptions.filter(o => o[valueKey] === value)
    }
  }

  isValidNewOption = (valueKey, labelKey) => (inputValue, selectValue, selectOptions) => {
    const compareOption = (value, option) => {
      const candidate = String(value).toLowerCase()
      // const valueString = String(__get(option, valueKey, '')).toLowerCase()
      const labelString = String(__get(option, labelKey, '')).toLowerCase()
      // return valueString === candidate || labelString === candidate
      return labelString === candidate
    }
    const isGroupedOption = (inputValue, option) => {
      // { label: String, options: Array}
      if (
        __isObject(option) &&
        Object.prototype.hasOwnProperty.call(option, 'label') &&
        Object.prototype.hasOwnProperty.call(option, 'options') &&
        Array.isArray(option.options)
      ) {
        return option.options.some(function (opt) {
          return isGroupedOption(inputValue, opt)
        })
      } else {
        return compareOption(inputValue, option)
      }
    }

    return !(
      !inputValue ||
      selectValue.some(function (option) {
        return compareOption(inputValue, option)
      }) ||
      selectOptions.some(function (option) {
        return isGroupedOption(inputValue, option)
      })
    )
  }

  render() {
    // console.log({ props: this.props })
    const {
      options,
      labelKey,
      valueKey,
      hasError,
      input: { onBlur, onChange, value, ...inputProps },
      onCreate,
      disabled,
      placeholder,
      promptTextCreator,
      multi,
      noResultsText,
      onEmptyCreateText,
      recommendation,
      isLoading,
      isClearable,
      skipSorting,
      highlighted,
      menuShouldBlockScroll = false,
    } = this.props

    const isCreatable = Boolean(onCreate)
    const SelectComponent = isCreatable ? CreatableSelect : Select
    // incasesensitive order
    const orderedOptions = orderSelectOptions(options, labelKey, skipSorting)
    const componentOverridesProp = {
      components: {
        Option: MyOption,
      },
    }

    // use react-window when has more than 9 options
    if (options.length > 9) {
      componentOverridesProp.components = {
        ...componentOverridesProp.components,
        MenuList: WindowedMenuList,
      }
    }

    let selectOptions = orderedOptions
    if (recommendation) {
      const { options: recommendations, texts } = recommendation

      if (recommendations.length > 0) {
        selectOptions = [
          {
            label: texts.recommendationsLabel,
            options: orderSelectOptions(
              recommendations.map(value => ({
                [valueKey]: value.isExist ? value[valueKey] : value[labelKey],
                [labelKey]: value[labelKey],
                isExist: value.isExist,
              })),
              labelKey
            ),
          },
          {
            label: texts.optionsLabel,
            options: orderedOptions,
          },
        ]

        componentOverridesProp.components = {
          ...componentOverridesProp.components,
          Option: withTexts(texts, MyOption),
        }
      }
    }
    const noOptionsMessage = getNoOptionMessage(isCreatable, selectOptions, noResultsText, onEmptyCreateText)

    return (
      <SelectComponent
        ref={ref => (this.select = ref)}
        classNamePrefix="react-select"
        instanceId={__uniqueId('rs')}
        inputId={inputProps.name}
        {...inputProps}
        value={this.getValue()}
        options={selectOptions}
        isDisabled={disabled}
        placeholder={placeholder}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
        noOptionsMessage={() => noOptionsMessage}
        isMulti={multi}
        // closeMenuOnSelect={!multi}
        // menuIsOpen={inputProps.name === 'partner'}
        getOptionLabel={option => option[labelKey]}
        getOptionValue={option => option[valueKey]}
        // only for CreatableSelect
        getNewOptionData={(inputValue, optionLabel) => ({
          [valueKey]: inputValue,
          [labelKey]: optionLabel,
          // isCreatable: true,
        })}
        onCreateOption={this.handleCreateOption}
        formatCreateLabel={promptTextCreator}
        isValidNewOption={this.isValidNewOption(valueKey, labelKey)}
        filterOption={customFilterOption}
        styles={customReactSelectStyles}
        isClearable={isClearable}
        isLoading={isLoading}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={menuShouldBlockScroll}
        {...componentOverridesProp}
      />
    )
  }
}

MySelect.defaultProps = {
  hasError: false,
  recommendation: null,
  isClearable: true,
  isLoading: false,
  disabled: false,
  skipSorting: false,
  highlighted: false,
}

MySelect.propTypes = {
  options: arrayOf(object).isRequired,
  labelKey: string.isRequired,
  valueKey: string.isRequired,
  hasError: bool,
  noResultsText: string,
  onEmptyCreateText: string,
  recommendation: shape({
    options: arrayOf(
      shape({
        id: number,
        name: string.isRequired,
      })
    ).isRequired,
    texts: shape({
      recommendationsLabel: string.isRequired,
      optionsLabel: string.isRequired,
      newOptionPrefix: string.isRequired,
    }).isRequired,
  }),
  isClearable: bool,
  input: shape({
    onBlur: func.isRequired,
    onChange: func.isRequired,
    value: oneOfType([number, string, arrayOf(number), arrayOf(string), bool]),
  }).isRequired,
  callback: func,
  multi: bool,
  onCreate: func,
  isLoading: bool,
  disabled: bool,
  placeholder: node,
  promptTextCreator: func,
  skipSorting: bool,
  highlighted: bool,
  menuShouldBlockScroll: bool,
}

export default MySelect
