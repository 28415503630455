import moment from 'moment'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

import { IncomeCashRegisterFormValues } from './types'

import { formErrorMessages } from '@messages'

export default function useValidationSchema(defaultCurrencyId: number): yup.SchemaOf<IncomeCashRegisterFormValues> {
  const { formatMessage } = useIntl()

  return yup.object({
    invoice_number: yup.string().nullable().required(formatMessage(formErrorMessages.required)),
    fulfilled_at: yup
      .string()
      .nullable()
      .required(formatMessage(formErrorMessages.required))
      .test(
        'valid_date',
        formatMessage(formErrorMessages.invalidDate),
        value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
      ),
    currency: yup.number().required(formatMessage(formErrorMessages.required)),
    exchange_rate: yup
      .string()
      .test(
        'valid_exchange_rate',
        formatMessage(formErrorMessages.greaterThan, {
          number: 0,
        }),
        value => !value || Number(value) > 0
      )
      .when(['currency'], {
        is: (currency: number | string) => currency != defaultCurrencyId,
        then: yup
          .string()
          .test(
            'valid_exchange_rate_non_one',
            formatMessage(formErrorMessages.invalidExchangeRateError),
            value => !value || Number(value) !== 1
          )
          .required(formatMessage(formErrorMessages.required)),
      })
      .required(formatMessage(formErrorMessages.required)),
    exchange_date: yup
      .string()
      .nullable()
      .test(
        'valid_date',
        formatMessage(formErrorMessages.invalidDate),
        value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
      )
      .when(['currency'], {
        is: (currency: number | string) => currency != defaultCurrencyId,
        then: yup.string().nullable().required(formatMessage(formErrorMessages.required)),
      }),
    simple_tags: yup.array(),
    tags: yup.array(),
    assignments: yup
      .array()
      .of(
        yup.object({
          gross_amount: yup.string().required(formatMessage(formErrorMessages.required)),
          vat: yup.number().required(),
        })
      )
      .min(1, formatMessage(formErrorMessages.noAssignmentsError)),
    //* non visible fields
    gross_amount: yup.string().required(),
    net_amount: yup.string().required(),
    vat_amount: yup.string().required(),
    id: yup.number(),
    income_type: yup.number().required(),
  })
}
