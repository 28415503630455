import React from 'react'
import { object, oneOf, string } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import styles from './styles'

const BoldDefault = ({ classes, className, ...rest }) => (
  <Link className={cx(className, classes.boldDefaultLink)} {...rest} />
)
BoldDefault.propTypes = {
  classes: object.isRequired,
  className: string,
}

const Primary = ({ classes, className, theme, ...rest }) => (
  <Link
    className={cx(className, classes.primaryLink, {
      [classes.blueTheme]: theme === 'blue',
      [classes.purpleTheme]: theme === 'purple',
    })}
    {...rest}
  />
)
Primary.propTypes = {
  classes: object.isRequired,
  className: string,
  theme: oneOf(['default', 'blue', 'purple']),
}
Primary.defaultProps = {
  theme: 'default',
}

const Secondary = ({ classes, className, ...rest }) => (
  <Link className={cx(className, classes.secondaryLink)} {...rest} />
)
Secondary.propTypes = {
  classes: object.isRequired,
  className: string,
}

export const BoldDefaultLink = withStyles(styles)(BoldDefault)
export const PrimaryLink = withStyles(styles)(Primary)
export const SecondaryLink = withStyles(styles)(Secondary)
