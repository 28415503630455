import React from 'react'

import styled from 'styled-components'

function BaseRightArrowLineIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 11.25a.75.75 0 0 0 0 1.5v-1.5Zm16.5303 1.2803a.75.75 0 0 0 0-1.0606l-4.7729-4.773a.75.75 0 0 0-1.0607 1.0607L18.9393 12l-4.2426 4.2426a.75.75 0 0 0 1.0607 1.0607l4.7729-4.773ZM4 12.75h16v-1.5H4v1.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const RightArrowLineIcon = styled(BaseRightArrowLineIcon)``
