import React from 'react'

import styled from 'styled-components'

function BaseZoomInIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(25 / 24) * size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
    >
      <path
        d="M9.75806 2.86609C11.7472 2.86609 13.6548 3.65627 15.0614 5.06279C16.4679 6.46931 17.2581 8.37697 17.2581 10.3661C17.2581 12.3552 16.4679 14.2629 15.0614 15.6694C13.6548 17.0759 11.7472 17.8661 9.75806 17.8661C7.76893 17.8661 5.86128 17.0759 4.45476 15.6694C3.04823 14.2629 2.25806 12.3552 2.25806 10.3661C2.25806 8.37697 3.04823 6.46931 4.45476 5.06279C5.86128 3.65627 7.76893 2.86609 9.75806 2.86609ZM9.75806 20.1161C12.0456 20.1161 14.1502 19.3286 15.8096 18.0114L22.0862 24.288C22.5268 24.7286 23.2393 24.7286 23.6752 24.288C24.1112 23.8473 24.1159 23.1348 23.6752 22.6989L17.4034 16.4177C18.7206 14.7583 19.5081 12.6536 19.5081 10.3661C19.5081 4.98015 15.144 0.616089 9.75806 0.616089C4.37212 0.616089 0.00805664 4.98015 0.00805664 10.3661C0.00805664 15.752 4.37212 20.1161 9.75806 20.1161ZM8.63306 14.4911C8.63306 15.1145 9.13462 15.6161 9.75806 15.6161C10.3815 15.6161 10.8831 15.1145 10.8831 14.4911V11.4911H13.8831C14.5065 11.4911 15.0081 10.9895 15.0081 10.3661C15.0081 9.74265 14.5065 9.24109 13.8831 9.24109H10.8831V6.24109C10.8831 5.61765 10.3815 5.11609 9.75806 5.11609C9.13462 5.11609 8.63306 5.61765 8.63306 6.24109V9.24109H5.63306C5.00962 9.24109 4.50806 9.74265 4.50806 10.3661C4.50806 10.9895 5.00962 11.4911 5.63306 11.4911H8.63306V14.4911Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const ZoomInIcon = styled(BaseZoomInIcon)``
