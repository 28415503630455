import { DefaultTheme } from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

export const QUICK_COLORS = {
  primary: {
    10: '#FFE9E1',
    20: '#FBD9CE',
    30: '#F7B59F',
    40: '#F18769',
    50: '#EF572C',
    60: '#D83723',
    140: '#FF9944',
    155: '#E9B2A8', // report badge border
    170: '#DC3416',
  },
  secondary: {
    5: '#001826',
    10: '#EBF5FF',
    20: '#DBECF8',
    30: '#76DDFB',
    40: '#41B2D4',
    50: '#508EA1',
    60: '#427A8B',
    70: '#3A6B7A',
    80: '#325C68',
  },
  tertiary: {
    40: '#8ED932',
    50: '#7EC02D',
    60: '#639723',
    70: '#507A1D',
    80: '#3A5915',
  },
  success: {
    10: '#AEF4B9', // report badge border
    40: '#1FBB38',
    50: '#1FBB38',
    60: '#154E1E',
    80: '#008115',
    150: '#16A82D',
  },
  error: {
    10: '#A81616', // monthly-statement-details table
    20: '#BE1B1B', // monthly-statement-details table
    30: '#EB5757',
    40: '#F5554A',
    50: '#E33232',
    70: '#BE181F',
    150: '#F44336',
  },
  gray: {
    0: '#FFF',
    10: '#F9F9F9',
    20: '#F1F2F4',
    30: '#ECECEC',
    35: '#EBF0FF',
    40: '#8C8C8C',
    50: '#656565',
    70: '#464646',
    80: '#101010',
    100: '#000', // xxxDark
    102: '#F6F6F6', // xxxLight
    105: '#E3E3E3', // settings block divider border color
    110: '#DFDFDF',
    120: '#CCCCCC',
    130: '#B1B1B1', // inputLabel
    160: '#7B7B7B',
    175: '#353333', // warning alert
    180: '#2C2C2C', // xxDark
  },
  mango: {
    20: '#FFE0CC',
    30: '#FFC092',
  },
  blue: {
    20: '#8EA7FF',
    30: '#3667CA',
    40: '#2168D1',
  },
  common: {
    accounto: '#B2EC5D',
    aragoniteGrey: '#958F95', // neutral.mLight
    authBlue: '#4D87C6', // neutral.xxxLight
    badgeBlue: '#3F5FBA',
    badgePurple: '#6428C6',
    billingo: '#10355a',
    black: '#000',
    blue: '#5379F2',
    blueDark: '#001826',
    cardStroke2: '#B1D2F3',
    darkBlue: '#0C1534',
    darkPurple: '#623EAE',
    demoBlue: '#DEE7FF',
    eliteTeal: '#0F2F3B', // neutral.xDark
    gloomyPurple: '#8256DF',
    inactive: '#CDCDCD',
    inputBackground: '#FAFAFA',
    inputLabel: '#B1B1B1',
    lightBadgeBlue: '#AFC4FF',
    lightBadgePurple: '#E9DCFF',
    lightPurple: '#EFE9F3',
    lightTurquoise: '#D1F2FC', //! deprecated do not use
    lushMeadow: '#067250',
    menu: '#4F4F4F',
    midnightEscape: '#403E40', // neutral.mDark
    mintCocktailGreen: '#B3E5B0',
    navBadge: '#01988F',
    navGreen: '#4CA79E',
    oasisStream: '#9FB8FF',
    oceanBlue: '#2168D1', //! deprecated: use blue[40]
    offBlack: '#312F31', // neutral.dark
    outlineBlue: '#80BDFF',
    pageBackground: '#E3E6F0',
    paperStroke: '#D5E0EB',
    pink: '#AE1961',
    placeboPurple: '#EEE5FF',
    purple: '#A448B3',
    shipCove: '#7C86AB',
    smokeBlue: '#DADEED',
    stoicWhite: '#E6DAFF',
    szamlazz: '#FF6630',
    tableRowBg: '#F3F3F3',
    torquoise: '#3AB2D6', //! deprecated do not use
    warningMessageBackground: '#FFCE50',
    workoutDefaultGray: '#DFDFDF',
    workoutPrimaryBlue: '#455BED',
    workoutSecondaryBlue: '#1161AF',
  },
} as const

//* new DS 1rem = 16px
export const QUICK_TYPOGRAPHY = {
  inherit: { fontSize: 'inherit', lineHeight: 'inherit', letterSpacing: 'inherit', fontWeight: 'inherit' },
  'heading-1': { fontSize: '2.25rem', lineHeight: '48px', letterSpacing: '-0.01em', fontWeight: 700 },
  'heading-2': { fontSize: '2rem', lineHeight: '40px', letterSpacing: 'inherit', fontWeight: 700 },
  'heading-3': { fontSize: '1.5rem', lineHeight: '32px', letterSpacing: 'inherit', fontWeight: 700 },
  'heading-4': { fontSize: '1.25rem', lineHeight: '28px', letterSpacing: 'inherit', fontWeight: 700 },
  'heading-5': { fontSize: '1.125rem', lineHeight: '24px', letterSpacing: 'inherit', fontWeight: 700 },
  'heading-6': { fontSize: '1rem', lineHeight: '24px', letterSpacing: 'inherit', fontWeight: 700 },
  'navigation-sm': { fontSize: '12px', lineHeight: '20px', letterSpacing: 'inherit', fontWeight: 700 },
  'navigation-md': { fontSize: '0.875rem', lineHeight: '24px', letterSpacing: 'inherit', fontWeight: 700 },
  'navigation-lg': { fontSize: '1rem', lineHeight: '28px', letterSpacing: 'inherit', fontWeight: 700 },
  'navigation-xl': { fontSize: '1.25rem', lineHeight: '32px', letterSpacing: 'inherit', fontWeight: 700 },
  '400-xxs': { fontSize: '0.625rem', lineHeight: '16px', letterSpacing: 'inherit', fontWeight: 400 },
  '400-xs': { fontSize: '0.75rem', lineHeight: '20px', letterSpacing: 'inherit', fontWeight: 400 },
  '400-sm': { fontSize: '0.875rem', lineHeight: '20px', letterSpacing: 'inherit', fontWeight: 400 },
  '400-md': { fontSize: '1rem', lineHeight: '24px', letterSpacing: 'inherit', fontWeight: 400 },
  '400-lg': { fontSize: '1.125rem', lineHeight: '28px', letterSpacing: 'inherit', fontWeight: 400 },
  '700-xxs': { fontSize: '0.625rem', lineHeight: '16px', letterSpacing: 'inherit', fontWeight: 700 },
  '700-xs': { fontSize: '0.75rem', lineHeight: '20px', letterSpacing: 'inherit', fontWeight: 700 },
  '700-sm': { fontSize: '0.875rem', lineHeight: '20px', letterSpacing: 'inherit', fontWeight: 700 },
  '700-md': { fontSize: '1rem', lineHeight: '24px', letterSpacing: 'inherit', fontWeight: 700 },
  '700-lg': { fontSize: '1.125rem', lineHeight: '28px', letterSpacing: 'inherit', fontWeight: 700 },
} as const

export const QUICK_BREAKPOINTS = {
  height: {
    medium: 650,
    desktop: 800,
  },
  width: {
    large: 1440,
    desktop: 1280,
    small: 1200,
    filterBarOpenButton: 1400,
    mobile: 600,
  },
} as const

export const PROVIDER_BOX_MIN_WIDTH = 232

export const PROVIDER_BOX_HEIGHT = 170

export const QUICK_SHADOWS = {
  20: `0px 4px 4px -4px ${applyOpacity(QUICK_COLORS.gray[100], 20)}`,
  50: `0px 5px 15px ${applyOpacity(QUICK_COLORS.secondary[50], 10)}`,
  80: `0px 2px 4px -1px ${applyOpacity(QUICK_COLORS.gray[100], 20)}, 0px 4px 5px 0px ${applyOpacity(
    QUICK_COLORS.gray[100],
    14
  )}, 0px 1px 10px 0px ${applyOpacity(QUICK_COLORS.gray[100], 12)}`, // MUI button box shadow
}

export const THEME: DefaultTheme = {
  appBarHeight: 50,
  appBarHeightMedium: 40,
  breakpoints: QUICK_BREAKPOINTS,
  colors: QUICK_COLORS,
  contentWidth: 1420,
  dialogZIndex: 1300,
  pagingContainerZIndex: 1295,
  shadows: QUICK_SHADOWS,
  statBarHeight: 82,
  statBarHeightMedium: 67,
  stickyFilterBarHeight: 120,
  typography: QUICK_TYPOGRAPHY,
}

export const generateMinWidthBreakPoint = (size: keyof (typeof QUICK_BREAKPOINTS)['width']) =>
  `@media (min-width: ${QUICK_BREAKPOINTS.width[size]}px)`
export const generateMaxWidthBreakPoint = (size: keyof (typeof QUICK_BREAKPOINTS)['width']) =>
  `@media (max-width: ${QUICK_BREAKPOINTS.width[size] - 1}px)`
export const generateMaxHeightBreakPoint = (size: keyof (typeof QUICK_BREAKPOINTS)['height']) =>
  `@media (max-height: ${QUICK_BREAKPOINTS.height[size]}px)`
export const generateMinHeightBreakPoint = (size: keyof (typeof QUICK_BREAKPOINTS)['height']) =>
  `@media (min-height: ${QUICK_BREAKPOINTS.height[size] + 1}px)`
