import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

function BasePlugCircleBoltRegularIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M3.556 1.667A.665.665 0 0 0 2.889 1a.665.665 0 0 0-.667.667V4.11h1.334V1.667Zm4.888 0A.665.665 0 0 0 7.778 1a.665.665 0 0 0-.667.667V4.11h1.333V1.667ZM.667 5A.665.665 0 0 0 0 5.667c0 .37.297.666.667.666h.222v1.778a4.445 4.445 0 0 0 3.778 4.395v2.05c0 .369.297.666.666.666.37 0 .667-.297.667-.666v-2.05a4.43 4.43 0 0 0 1.197-.359 4.977 4.977 0 0 1-.053-1.505 3.11 3.11 0 0 1-4.922-2.53V6.332h6.222v1.534a4.909 4.909 0 0 1 1.334-1v-.534H10A.665.665 0 1 0 10 5H.667ZM12 15.222a4 4 0 1 0 0-7.999 4 4 0 0 0 0 8Zm1.33-6.25c.12.103.15.275.073.414l-.836 1.503h.989c.144 0 .272.092.316.228a.337.337 0 0 1-.116.372l-2.667 2a.331.331 0 0 1-.42-.017.332.332 0 0 1-.072-.414l.836-1.502h-.989a.332.332 0 0 1-.316-.228.337.337 0 0 1 .116-.372l2.667-2a.331.331 0 0 1 .42.016Z"
      />
    </svg>
  )
}

BasePlugCircleBoltRegularIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}

export const PlugCircleBoltRegularIcon = styled(BasePlugCircleBoltRegularIcon)``
