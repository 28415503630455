import styled, { css, DefaultTheme } from 'styled-components'

import { getFontStyles } from '@helpers'

import { Typography } from '@components/ui/Typography'

import { NavLink } from './NavLink'

export const PageNavbarContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.gray[0]};
  overflow: hidden;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
`

function getPageTabStyles(theme: DefaultTheme) {
  return css`
    color: ${theme.colors.gray[50]};
    height: ${theme.appBarHeight}px;
    padding: calc((${theme.appBarHeight}px - ${theme.typography['400-md'].lineHeight}) / 2) 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    position: relative;

    ${getFontStyles(theme, '400-md')}

    ::after {
      content: '';
      height: 3px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  `
}

export const PageTabLink = styled(NavLink)`
  ${({ theme }) => getPageTabStyles(theme)}

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.gray[100]};
    outline: none;
  }

  &.active {
    color: ${({ theme }) => theme.colors.gray[80]};
    font-weight: 600;

    ::after {
      background-color: ${({ theme }) => theme.colors.blue[40]};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray[40]};
  }
`

export const PageTabUpsell = styled.span`
  ${({ theme }) => getPageTabStyles(theme)}
  cursor: help;
  color: ${({ theme }) => theme.colors.gray[130]};
`

export const DateFilterWrapper = styled.div<{ $disabled: boolean }>`
  transition: all 225ms ease;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  opacity: ${({ $disabled }) => ($disabled ? 0 : 1)};
  visibility: ${({ $disabled }) => ($disabled ? 'hidden' : 'visible')};
`

export const CompactMenuNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.common.offBlack};
  display: inline-block;

  /* active class applied by react router */
  &.active > span {
    background-color: ${({ theme }) => theme.colors.blue[40]};
    color: ${({ theme }) => theme.colors.gray[0]};
    font-weight: 600;
  }
`

export const TertiaryInfoTypography = styled(Typography).attrs({
  size: '400-xs',
  tag: 'span',
})`
  line-height: 1.2;
  font-weight: 300;
`
