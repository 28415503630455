import React from 'react'

import { useFormContext } from 'react-hook-form'

import { useEditor } from '@hooks'

import { PaidThroughDialogVariant, PaidThroughEditorDialog } from '@components/dialogs'
import { ReactHookFormSelectField } from '@components/ui'

import { CustomPaidThroughSelectOption, PaidThroughSelectOption } from './CustomPaidThroughSelectOption'

import { PaidThroughLabelMessage } from '@messages'

interface PaidThroughFieldProps {
  options: PaidThroughSelectOption[]
  disabled?: boolean
  placeholder?: string
}

export function PaidThroughField({ options, disabled, placeholder }: PaidThroughFieldProps) {
  const { editorState, openEditorForCreateWithData, closeEditor } = useEditor<number, { name: string }>()
  const { setValue } = useFormContext()

  const handleCreate = React.useCallback(
    (newOption: PaidThroughSelectOption) => {
      openEditorForCreateWithData({ name: newOption.name })
    },
    [openEditorForCreateWithData]
  )

  const handleSuccess = React.useCallback(
    (response: PaidThroughDetailData) => {
      // only set the value if the newly created paidThrough type is "bank"
      if (response.paidthrough_type === 2) {
        setValue('paidThroughId', response.id, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
      }
    },
    [setValue]
  )

  return (
    <>
      <ReactHookFormSelectField
        disabled={disabled}
        isClearable={false}
        label={PaidThroughLabelMessage}
        labelKey="name"
        name="paidThroughId"
        onCreate={handleCreate}
        OptionComponent={CustomPaidThroughSelectOption}
        options={options}
        placeholder={placeholder}
        required
        valueKey="id"
      />
      <PaidThroughEditorDialog
        editor={editorState}
        onClose={closeEditor}
        onSubmitSuccess={handleSuccess}
        variant={PaidThroughDialogVariant.SELECT_OPTION}
      />
    </>
  )
}
