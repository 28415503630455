export const appBarStyles = () => ({
  root: {
    flexDirection: 'row',
    border: 'none',
    marginLeft: '-1rem',
    marginRight: '-1rem',
    marginTop: '-1rem',
    marginBottom: '1rem',
    width: 'auto',
    zIndex: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'hidden',
  },
  colorDefault: {
    backgroundColor: '#F1F2F4',
  },
})

export const tabStyles = theme => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
    color: theme.palette.grayscale.inputLabel,
    minWidth: 0,
    padding: 0,
  },
  selected: {
    backgroundColor: '#fff',
    color: theme.palette.grayscale.xxDark,
  },
  wrapper: {
    padding: '6px 1rem',
  },
  fullWidth: {
    flexBasis: 'auto',
  },
})

export const tabsStyles = () => ({
  indicator: { display: 'none' },
})
