import { Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const FilterChip = withStyles((theme: any) => ({
  root: {
    color: theme.palette.grayscale.grey70,
    borderColor: 'currentColor',
    borderRadius: 4,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    height: 20,
    minWidth: 0,
  },
  label: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  deleteIcon: {
    width: 14,
    height: 14,
    color: 'currentColor',
    margin: '0 5px 0 0',

    '&:hover': {
      color: theme.palette.grayscale.black,
    },
  },
}))(Chip)

FilterChip.displayName = 'FilterChip'
