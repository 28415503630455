import __orderBy from 'lodash/orderBy'

import { orderCompanies } from '@helpers'

import { COMPANY_USER_PERMISSIONS_LIST, CompanyUserRoles } from '@constants'

import { isUserPermissionsEnabled } from '@permissions'

import { isLoggedIn } from '../helpers'
import onboardingActions from '../onboarding/actions'
import subscriptionActions from '../subscription/actions'
import actions from './actions'

// TODO: once `AuthStore` is final this simply needs to be `AuthStore`
export const initialState: Omit<AuthStore, 'user' | 'company'> & {
  user: null
  company: CommonFetchProps & {
    data: Nullable<Company>
  }
} = {
  user: null,
  companies: [], // company-select
  userCompanies: [], // user profile companies list
  company: {
    loading: false,
    data: null,
    error: null,
    fetched: false,
  },
  companyMembers: {
    companyUserTemplate: {} as CompanyUser,
    data: [],
  },
  companyPendingInviteApprovals: [],
  is_logged_in: isLoggedIn(),
  token_expired: false,
  renew_token_inprogress: false, // lock infinite refresh request in middleware
  integrations: [],
  invitation: {
    loading: true,
    data: null,
    error: null,
  },
  companyVats: {
    loading: false,
    fetched: false,
    data: [],
    error: null,
  },
}

const ROLES_ORDER: CompanyUserRole[] = [
  CompanyUserRoles.ADMIN,
  CompanyUserRoles.ACCOUNTANT,
  CompanyUserRoles.EDITOR,
  CompanyUserRoles.VIEWER,
  CompanyUserRoles.UNKNOWN,
]

// order company members by role and restrictions' number
function orderCompanyMembers(members: CompanyUser[], userId: number, extendedOrder = false) {
  if (extendedOrder) {
    return __orderBy(
      members,
      [
        function (o) {
          return ROLES_ORDER.indexOf(o.role)
        },
        function (o) {
          return (o.not_allowed_expense_types || []).length
        },
        function (o) {
          return (o.not_allowed_revenue_types || []).length
        },
        function (o) {
          return o.user === userId ? 0 : 1
        },
        function (o) {
          return (o.tags_restrictions || []).length
        },
      ],
      ['asc', 'asc', 'asc', 'asc', 'desc']
    )
  }
  return __orderBy(
    members,
    [
      function (o) {
        return ROLES_ORDER.indexOf(o.role)
      },
      function (o) {
        return o.user === userId ? 0 : 1
      },
    ],
    ['asc', 'asc']
  )
}

function serializeCompanyUser(companyUser: BackendCompaniesItem['company_user']) {
  return [...COMPANY_USER_PERMISSIONS_LIST, 'role'].reduce((obj, key) => {
    const value = companyUser[key as keyof typeof companyUser]
    if (value !== undefined) {
      obj[key as keyof typeof obj] = value
    }
    return obj
  }, {} as Record<string, string | boolean>)
}

function serializeCompanyWithUserPermissions({ company_user, ...company }: BackendCompaniesItem) {
  return {
    ...company,
    ...serializeCompanyUser(company_user),
  }
}

function serializeAuthMeData({ user, companies }: BackendAuthMeResponse) {
  return {
    user,
    companies: companies.map(serializeCompanyWithUserPermissions),
  }
}

const companyWithTokenAndIntegrations = (state: AuthStore, payload: Record<string, unknown>) => ({
  ...state.company.data,
  token: payload.key,
  integrations: [...(state.company.data?.integrations ?? null), payload],
})

function updateCompaniesListIfNecessary(state: AuthStore, { company_type }: Partial<Company>) {
  // currently we only need to update the list if `company_type` is changing, but we might need more in the future
  if (company_type) {
    const companies = [...state.companies]
    return companies.map(company => {
      if (company.id === state.company.data?.id) {
        company.company_type = company_type
      }
      return company
    })
  } else {
    return state.companies
  }
}

// TODO later type should depend on generated actions' types [xxx]_[REQUEST|SUCCESS|FAILURE]
type ReducerAction = { type: string; payload: any }

// @ts-expect-error initialState is not yet a valid type due to Nullable<Company> and Nullable<User>
export default function reducer(state = initialState as AuthStore, action: ReducerAction) {
  switch (action.type) {
    case actions.login.SUCCESS:
    case actions.signup.SUCCESS:
    case actions.acceptInvitation.SUCCESS:
      return {
        ...state,
        is_logged_in: true,
        token_expired: false,
      }

    case actions.loadInvitationDetails.SUCCESS:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          loading: false,
          data: action.payload,
        },
      }
    case actions.loadInvitationDetails.FAILURE:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          loading: false,
          error: true,
        },
      }

    case actions.logout.SUCCESS:
      return {
        ...state,
        is_logged_in: false,
      }

    case actions.renewToken.REQUEST:
      return {
        ...state,
        renew_token_inprogress: true,
      }
    case actions.renewToken.SUCCESS:
      return {
        ...state,
        renew_token_inprogress: false,
      }

    case actions.updateBadgeDisplayedAt.SUCCESS:
    case actions.updatePersonalInfo.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }

    case actions.changePreferences.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          preferences: {
            ...(state.user?.preferences ?? null),
            ...action.payload,
          },
        },
      }

    case actions.fetchUser.SUCCESS: {
      const { user, companies } = serializeAuthMeData(action.payload)

      return {
        ...state,
        user,
        companies,
      }
    }

    case actions.fetchUserCompanies.SUCCESS: {
      return {
        ...state,
        userCompanies: action.payload,
      }
    }

    case actions.createCompany.SUCCESS: {
      const createdCompany = serializeCompanyWithUserPermissions(action.payload)

      return {
        ...state,
        companies: orderCompanies([createdCompany, ...state.companies]),
      }
    }

    case actions.updateCompany.SUCCESS:
    case actions.updateCompanyField.SUCCESS:
      return {
        ...state,
        companies: updateCompaniesListIfNecessary(state, action.payload),
        company: {
          ...state.company,
          data: serializeCompanyWithUserPermissions(action.payload),
        },
      }

    case actions.removeCompany.SUCCESS:
      return {
        ...state,
        companies: state.companies.filter(o => o.id !== action.payload),
        company: {
          ...state.company,
          data: state.company.data?.id === action.payload ? null : state.company.data,
        },
      }

    //! SUBSCRIPTION changes
    case subscriptionActions.cancelSubscription.SUCCESS: {
      const { companyId, subscription } = action.payload
      return {
        ...state,
        company: {
          ...state.company,
          data:
            state.company.data?.id === companyId
              ? {
                  ...state.company.data,
                  subscription,
                }
              : state.company.data,
        },
      }
    }
    case actions.setActiveSubscription.REQUEST:
      return {
        ...state,
        company: {
          ...state.company,
          data: {
            ...state.company.data,
            subscription: action.payload,
          },
        },
      }

    //* reset data when change company
    case actions.selectCompany.REQUEST:
      return {
        ...state,
        company: {
          ...initialState.company,
          fetched: false,
          loading: true,
        },
      }

    case actions.selectCompany.FAILURE:
      return {
        ...state,
        company: {
          ...state.company,
          loading: false,
          fetched: true,
          error: action.payload,
        },
      }

    case actions.selectCompany.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...initialState.companyVats,
        },
        company: {
          ...state.company,
          loading: false,
          fetched: true,
          data: serializeCompanyWithUserPermissions(action.payload),
        },
        companyMembers: {
          ...initialState.companyMembers,
        },
      }

    case actions.fetchCompany.SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          data: serializeCompanyWithUserPermissions(action.payload),
        },
      }

    case actions.removeCompanyMembership.SUCCESS:
      return {
        ...state,
        userCompanies: state.userCompanies.filter(userCompany => userCompany.company_user.id !== action.payload),
      }

    case actions.updateNotificationDisplayedAt.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: state.user?.notifications.filter(notif => notif.id !== action.payload.id),
        },
      }

    case actions.fetchCompanyMembers.SUCCESS: {
      const { company_user_template, users } = action.payload
      const hasUserPermissions = isUserPermissionsEnabled(state.company.data, true)
      return {
        ...state,
        companyMembers: {
          data: orderCompanyMembers(users, state.user.id, hasUserPermissions),
          companyUserTemplate: company_user_template,
        },
      }
    }

    case onboardingActions.fetchOnboardingInviteApprovals.SUCCESS:
      return {
        ...state,
        companyPendingInviteApprovals: action.payload,
      }

    case actions.createCompanyMember.SUCCESS:
      return {
        ...state,
        companyMembers: {
          ...state.companyMembers,
          data: [...state.companyMembers.data, action.payload],
        },
      }

    case actions.updateCompanyMember.SUCCESS: {
      // check if updated member is current logged in user
      // update permissions
      const isCurrentUser = state.user?.id === action.payload.user
      if (isCurrentUser) {
        return {
          ...state,
          companyMembers: {
            ...state.companyMembers,
            data: state.companyMembers.data.map((member: CompanyUser) =>
              member.id === action.payload.id ? action.payload : member
            ),
          },
          company: {
            ...state.company,
            data: {
              ...state.company.data,
              ...serializeCompanyUser(action.payload),
            },
          },
        }
      }
      return {
        ...state,
        companyMembers: {
          ...state.companyMembers,
          data: state.companyMembers.data.map((member: CompanyUser) =>
            member.id === action.payload.id ? action.payload : member
          ),
        },
      }
    }

    case actions.removeCompanyMember.SUCCESS:
      return {
        ...state,
        companyMembers: {
          ...state.companyMembers,
          data: state.companyMembers.data.filter((f: CompanyUser) => f.id !== action.payload),
        },
      }

    case onboardingActions.declineOnboardingInvite.SUCCESS:
      return {
        ...state,
        companyPendingInviteApprovals: state.companyPendingInviteApprovals.filter(
          (f: CompanyUserInvitation) => f.id !== action.payload
        ),
      }

    case actions.fetchCompanyVats.REQUEST:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          loading: true,
        },
      }

    case actions.fetchCompanyVats.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          loading: false,
          fetched: true,
          data: __orderBy(action.payload, 'percent', 'asc'),
        },
      }

    case actions.fetchCompanyVats.FAILURE:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          loading: false,
          fetched: false,
          error: action.payload,
        },
      }

    case actions.createCompanyVat.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          data: [...state.companyVats.data, action.payload],
        },
      }

    case actions.updateCompanyVat.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          data: state.companyVats.data.map(companyVat =>
            companyVat.id === action.payload.id ? action.payload : companyVat
          ),
        },
      }

    case actions.removeCompanyVat.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          data: state.companyVats.data.filter(f => f.id !== action.payload),
        },
      }
    // it is used in company integrations and in liquidity page
    case actions.createIntegrationProvider.SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          data: companyWithTokenAndIntegrations(state, action.payload),
        },
      }

    case actions.expiredToken.REQUEST:
      return {
        ...state,
        token_expired: true,
      }

    default:
      return state
  }
}
