import React from 'react'

import { defineMessage, FormattedMessage } from 'react-intl'

export const KPIChartTitleMessage = (
  <FormattedMessage id="charts.title.kpiChart" defaultMessage="Amire érdemes figyelned" />
)

export const KPIChartVatLabelMessage = (
  <FormattedMessage id="charts.title.kpiChart.vatTitle" defaultMessage="Időszaki számlák ÁFA tartalma" />
)

export const KPIChartOutstandingLabelMessage = (
  <FormattedMessage
    id="charts.title.kpiChart.outstandingTitle"
    defaultMessage="Rendezetlen szállítói számlák értéke az időszakban"
  />
)

export const PrintableChartSlogenMessage = defineMessage({
  id: 'charts.printable.slogen',
  defaultMessage: 'Céges pénzügyeid új helye.',
})
