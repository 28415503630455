import React from 'react'

export enum EditorMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export interface EditorStateProps<EditorDetailsPayload, EditorDetailsData = unknown> {
  data?: EditorDetailsData
  mode: EditorMode
  open: boolean
  payload: Nullable<EditorDetailsPayload>
}

export function useEditor<EditorDetailsPayload, EditorDetailsData = unknown>() {
  const [state, setState] = React.useState<EditorStateProps<EditorDetailsPayload, EditorDetailsData>>({
    data: undefined,
    mode: EditorMode.CREATE,
    open: false,
    payload: null,
  })

  function openEditorForCreate() {
    setState({ open: true, mode: EditorMode.CREATE, payload: null, data: undefined })
  }

  function openEditorForCreateWithData(data: EditorDetailsData) {
    setState({ open: true, mode: EditorMode.CREATE, payload: null, data })
  }

  function openEditorForEdit(payload: EditorDetailsPayload) {
    setState({ open: true, mode: EditorMode.EDIT, payload, data: undefined })
  }

  function closeEditor() {
    setState(state => ({ ...state, open: false }))
  }

  return {
    closeEditor,
    editorState: state,
    openEditorForCreate,
    openEditorForCreateWithData,
    openEditorForEdit,
  }
}
