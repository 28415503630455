import __find from 'lodash/find'
import __isObject from 'lodash/isObject'

import { getMaximumFractionDigitsByCurrency, roundToDecimal } from './common'

export function isFieldHighlighted(needHighlight, inputValue) {
  return needHighlight && !inputValue && inputValue !== 0
}

export function calculateInvoiceNetAndVatAmounts(values) {
  const assignments = values?.assignments ?? []
  const maximumFractionDigits = getMaximumFractionDigitsByCurrency(values.currency)
  let sumNetAmount = 0
  let sumVatAmount = 0

  // summarize assignments net_amount and vat_amount
  assignments.forEach(assignment => {
    sumNetAmount += Number(assignment.net_amount) || 0
    sumVatAmount += Number(assignment.vat_amount) || 0
  })

  return {
    ...values,
    net_amount: roundToDecimal(sumNetAmount, { maximumFractionDigits, numeric: false }),
    vat_amount: roundToDecimal(sumVatAmount, { maximumFractionDigits, numeric: false }),
  }
}

// https://github.com/final-form/react-final-form/issues/130#issuecomment-559739326
/**
 * Helper function to add empty values as null before form submit
 * @param {*} values current form values
 * @param {*} initialValues initial values of form
 * @param {boolean} isAssignments
 * @returns modified form values
 */
export function checkEmptyValues(values, initialValues, isAssignments = false) {
  const _initialValues = __isObject(initialValues) ? initialValues : {}
  const _values = __isObject(values) ? values : {}

  const keys = Array.from(new Set(Object.keys(_initialValues || {}).concat(Object.keys(_values || {}))))

  return keys.reduce((result, key) => {
    let value
    if (_values !== null) {
      value = _values[key]
    }
    let initValue
    if (_initialValues !== null) {
      initValue = _initialValues[key]
    }
    const initDefined = initValue != null
    const valueDefined = value != null && value !== ''
    if (initDefined && valueDefined) {
      // both defined, we may need to apply same logic on this property
      if (typeof value === 'object' && !(value instanceof Date)) {
        if (value instanceof Array) {
          // fieldArray values
          value = value.map((arrayValue, index) => {
            if (typeof arrayValue === 'object' && !(arrayValue instanceof Date) && !(arrayValue instanceof Array)) {
              return checkEmptyValues(arrayValue, initValue[index], true)
            }

            return arrayValue
          })
        } else {
          value = checkEmptyValues(value, initValue)
        }
      }
    } else if (initDefined) {
      // must be skip "id" in assignments because assignment remove or replace (with recommendation) always clear it and id:null is invalid for BE
      if (!(isAssignments && key === 'id')) {
        // property was erased
        value = null
      }
    }
    result[key] = value
    return result
  }, {})
}

export function getDefaultCurrencyId(state) {
  return getDefaultCurrency(state)?.id
}

export function getDefaultCurrency(state) {
  const currencyOptions = state.dashboard?.common.currencies ?? []

  let defaultCurrency
  if (state.auth.company.data) {
    defaultCurrency = __find(currencyOptions, { id: state.auth.company.data.default_currency })
  }
  return defaultCurrency || __find(currencyOptions, { name: 'HUF' })
}
