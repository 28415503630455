import styled from 'styled-components'

import { Typography } from '@components/ui'

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;

  input {
    flex: 1;
  }
`

export const Divider = styled.div`
  height: 1px;
  min-width: 20px;
  background-color: ${({ theme }) => theme.colors.gray[120]};
`

export const AdornmentTypography = styled(Typography)`
  font-style: italic;
`

export const ErrorTypography = styled(Typography)`
  margin-top: 4px;
`
