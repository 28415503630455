import React from 'react'
import PropTypes from 'prop-types'

import { Button, DialogContentText } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { authActions } from '@services'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@oldComponents/dialogs'

interface SessionExpiredDialogProps {
  logout: VoidFunction
  sessionExpired: boolean
}

function PureSessionExpiredDialog({ logout, sessionExpired }: SessionExpiredDialogProps) {
  function handleClose() {
    logout()
  }

  return (
    <Dialog open={sessionExpired} onClose={handleClose} aria-labelledby="session-expired-dialog">
      <DialogTitle id="session-expired-dialog">
        <FormattedMessage id="dialogs.sessionExpired.title" defaultMessage="Lejárt munkamenet" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="dialogs.sessionExpired.text"
            defaultMessage="A munkameneted lejárt. Kérünk jelentkezz be újra."
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          <FormattedMessage id="dialogs.sessionExpired.button" defaultMessage="Ok" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PureSessionExpiredDialog.propTypes = {
  logout: PropTypes.func.isRequired,
  sessionExpired: PropTypes.bool.isRequired,
}

export const SessionExpiredDialog = connect(
  (state: Store) => ({
    sessionExpired: state.auth.token_expired,
  }),
  {
    logout: authActions.logout.success, // skip logout api call but act as it happened
  }
)(PureSessionExpiredDialog)

SessionExpiredDialog.displayName = 'SessionExpiredDialog'
