import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { DateFilter, DatePeriodsMenu, DateTypeSelector } from '@components/filters'
import { DATE_WIDGET_VARIANTS, PAGE_FILTER_VARIANTS } from '@components/filters/constants'
import { Button, CustomDialogActions, CustomDialogHeader } from '@components/ui'

import {
  FILTER_DIALOG_FILTER_PROPS_PROP_TYPE,
  INITIAL_ORIGIN_FILTER,
  INITIAL_PAID_STATUS_FILTERS,
  INITIAL_PAID_THROUGH_FILTER,
  INITIAL_PAYMENT_METHOD_FILTER,
  INITIAL_VAT_AREA_FILTER,
} from '@constants'

import { FeaturePermissons, PlanPermission } from '@permissions'

import AmountFilters from './AmountFilters'
import CurrencySelectFilter from './CurrencySelectFilter'
import { FilterFieldLabel } from './FilterFieldLabel'
import IncludeExcludeFilters, { UpsellIncludeExcludeFilters } from './IncludeExcludeFilters'
import MultiCheckboxFilter from './MultiCheckboxFilter'
import MultiChoiceFilter from './MultiChoiceFilter'
import { PaidStatusFilter } from './PaidStatusFilter'
import { FormSearchFilter } from './SearchFilters'
import { StatusFilter } from './StatusFilter'

import {
  OriginLabelMessage,
  PaidStatusLabelMessage,
  PaidThroughLabelMessage,
  PaymentMethodLabelMessage,
  StatusLabelMessage,
  VatAreaLabelMessage,
} from '@messages'
import {
  ExcludeCategoryLabelMessage,
  ExcludeTagLabelMessage,
  IncludeCategoryLabelMessage,
  IncludeTagLabelMessage,
} from './messages'
import { DateFilterWrapperDiv, FilterDialogBody, FilterWrapper, StatusFilterWrapper, StyledDialog } from './styles'

/**
 * Helper function to determine the state of the status filters
 */
function getFilterSelectionState(values: Record<string, boolean | undefined>): FilterSelectionState {
  const definedValuesNum = Object.values(values).filter(value => value !== undefined).length
  if (definedValuesNum > 0) {
    if (definedValuesNum === Object.keys(values).length) {
      return 'all'
    }
    return 'some'
  }
  return 'none'
}

/**
 * Helper function to determine the state of the multi-choice filters
 */
function getMultiChoiceFilterSelectionState(
  values: unknown[] | undefined,
  config: { options: unknown[] } | undefined
): FilterSelectionState {
  if (values && config) {
    if (values.length > 0) {
      return 'some'
    }
    if (values.length === config.options.length) {
      return 'all'
    }
  }
  return 'none'
}

/**
 * Helper function to determine the state of the paidStatus filters
 */
function getPaidStatusSelectionState(values?: PaidStatusFilterValues): FilterSelectionState {
  if (values) {
    const { isExpired, isExpiring, isPaid } = values
    if (isExpired || isExpiring || isPaid) {
      if (isExpired && isExpiring && isPaid) {
        return 'all'
      }
      return 'some'
    }
  }
  return 'none'
}

/**
 * Helper function to map generic include and exclude keys to real filters key with given config
 * @param values - generic state values to hold MultiSelectFilter include and exclude values
 * @param {string} config.includeKey - mapper key for include values
 * @param {string} config.excludeKey - mapper key for exclude values
 * @returns mapped filter values
 */
function mapMultiSelectFilters({
  values,
  config,
}: {
  values?: FilterDialogMultiSelectValuesProps
  config?: { includeKey?: string; excludeKey?: string }
}) {
  const filters: Record<string, CommonIdAndNameType[]> = {}
  if (values && config) {
    if (config.includeKey) {
      filters[config.includeKey] = values.include
    }
    if (config.excludeKey) {
      filters[config.excludeKey] = values.exclude
    }
  }
  return filters
}

/**
 * Helper function to map generic values to real filters key with given config
 * @param values - generic state values to hold selectable values
 * @param {string} config.name - mapper key for generic values
 * @returns mapped filter values
 */
function mapMultiChoiceFilter({ values, config }: { values?: unknown[]; config?: { name: string } }) {
  if (values && config) {
    return { [config.name]: values }
  }
  return {}
}

interface PureFilterDialogProps<CustomStatusFilterConfig extends StatusFilterConfig> {
  filterProps: FilterDialogProps<CustomStatusFilterConfig>
  fromDate: Nullable<string>
  isDateFilterDisabled: boolean
  isDateFilterToggleDisabled: boolean
  onClose: VoidFunction
  open: boolean
  resetFilters: VoidFunction
  syncSearchInputValue?: (payload: string) => void
  toDate: Nullable<string>
  updateFilters: UpdateFiltersFunction
}

function PureFilterDialog<CustomStatusFilterConfig extends StatusFilterConfig>({
  filterProps: {
    dateFilterProps,
    page,
    searchFilterProps,
    statusFilterProps,
    categoryFilterProps,
    tagFilterProps,
    currencyFilterProps,
    amountFilterProps,
    originFilterProps,
    paymentMethodFilterProps,
    paidStatusFilterProps,
    paidThroughFilterProps,
    vatAreaFilterProps,
  },
  fromDate,
  isDateFilterDisabled: isStoreDateFilterDisabled,
  isDateFilterToggleDisabled,
  onClose,
  open,
  resetFilters,
  syncSearchInputValue,
  toDate,
  updateFilters,
}: PureFilterDialogProps<CustomStatusFilterConfig>) {
  const [dateFilters, setDateFilters] = React.useState(
    dateFilterProps ? { dateField: dateFilterProps.dateTypeValue, fromDate, toDate } : undefined
  )
  const [isDateFilterDisabled, setDateFilterDisabled] = React.useState(
    !isDateFilterToggleDisabled && isStoreDateFilterDisabled // ignore isDateFilterDisabled when isDateFilterToggleDisabled monthlyStatement V2 page
  )
  const [searchFilters, setSearchFilters] = React.useState<Omit<SearchFilterProps, 'options'> | undefined>(
    searchFilterProps
      ? { search: searchFilterProps.search, searchFields: searchFilterProps.searchFields } // do not pass options to update store
      : searchFilterProps
  )
  const [statusFilters, setStatusFilters] = React.useState<
    StatusFilterProps<CustomStatusFilterConfig>['values'] | undefined
  >(statusFilterProps?.values)
  const [statusFilterSelectionState, setStatusFilterSelectionState] = React.useState(
    getFilterSelectionState(statusFilterProps?.values ?? {})
  )
  const [categoryFilters, setCategoryFilters] = React.useState<IncludeExcludeFilterProps['values'] | undefined>(
    categoryFilterProps?.values
  )
  const [tagFilters, setTagFilters] = React.useState<IncludeExcludeFilterProps['values'] | undefined>(
    tagFilterProps?.values
  )
  const [currencyFilter, setCurrencyFilter] = React.useState<CurrencyFilterProps | undefined>(currencyFilterProps)
  const [amountFilters, setAmountFilters] = React.useState<AmountFilterProps | undefined>(amountFilterProps)
  const [originFilters, setOriginFilters] = React.useState<FilterDialogMultiChoiceFilterProps['values'] | undefined>(
    originFilterProps?.values
  )
  const [originFilterSelectionState, setOriginFilterSelectionState] = React.useState(
    getMultiChoiceFilterSelectionState(originFilters, originFilterProps?.config)
  )
  const [paymentMethodFilters, setPaymentMethodFilters] = React.useState<
    FilterDialogMultiChoiceFilterProps['values'] | undefined
  >(paymentMethodFilterProps?.values)
  const [paymentMethodFilterSelectionState, setPaymentMethodFilterSelectionState] = React.useState(
    getMultiChoiceFilterSelectionState(paymentMethodFilters, paymentMethodFilterProps?.config)
  )
  const [paidStatusFilters, setPaidStatusFilters] = React.useState<PaidStatusFilterValues | undefined>(
    paidStatusFilterProps?.values
  )
  const [paidStatusFilterSelectionState, setPaidStatusFilterSelectionState] = React.useState(
    getPaidStatusSelectionState(paidStatusFilterProps?.values)
  )
  const [paidThroughFilters, setPaidThroughFilters] = React.useState<
    FilterDialogPaidThroughFilterProps['values'] | undefined
  >(paidThroughFilterProps?.values)
  const [paidThroughFilterSelectionState, setPaidThroughFilterSelectionState] = React.useState(
    getMultiChoiceFilterSelectionState(paidThroughFilters, paidThroughFilterProps?.config)
  )
  const [vatAreaFilters, setVatAreaFilters] = React.useState<FilterDialogMultiChoiceFilterProps['values'] | undefined>(
    vatAreaFilterProps?.values
  )
  const [vatAreaFilterSelectionState, setVatAreaFilterSelectionState] = React.useState(
    getMultiChoiceFilterSelectionState(vatAreaFilters, paymentMethodFilterProps?.config)
  )

  function handleFilterChange<Values>(values: Partial<Values>, handler: React.Dispatch<React.SetStateAction<Values>>) {
    handler(state => ({ ...state, ...values }))
  }

  function handleDateFilterChange(values: DateFilterChangeValues) {
    setDateFilterDisabled(false)
    handleFilterChange(values, setDateFilters)
  }

  function handleSearchFilterChange(values: SearchFilterChangeValues) {
    handleFilterChange(values, setSearchFilters)
  }

  function handleStatusFilterChange(values: Record<CustomStatusFilterConfig['name'], boolean | undefined>) {
    handleFilterChange(values, setStatusFilters)
    setStatusFilterSelectionState(getFilterSelectionState({ ...statusFilters, ...values }))
  }

  function handleCategoryFilterChange(filterValuesKey: 'include' | 'exclude', values: CommonIdAndNameType[]) {
    handleFilterChange({ [filterValuesKey]: values }, setCategoryFilters)
  }

  function handleTagFilterChange(filterValuesKey: 'include' | 'exclude', values: CommonIdAndNameType[]) {
    handleFilterChange({ [filterValuesKey]: values }, setTagFilters)
  }

  function handleOriginFilterChange(values: FilterDialogMultiChoiceFilterProps['values']) {
    setOriginFilters(values)
    setOriginFilterSelectionState(getMultiChoiceFilterSelectionState(values, originFilterProps?.config))
  }

  function handlePaymentMethodFilterChange(values: FilterDialogMultiChoiceFilterProps['values']) {
    setPaymentMethodFilters(values)
    setPaymentMethodFilterSelectionState(getMultiChoiceFilterSelectionState(values, paymentMethodFilterProps?.config))
  }

  function handleVatAreaFilterChange(values: FilterDialogMultiChoiceFilterProps['values']) {
    setVatAreaFilters(values)
    setVatAreaFilterSelectionState(getMultiChoiceFilterSelectionState(values, vatAreaFilterProps?.config))
  }

  function handlePaidStatusFilterChange(values: PaidStatusFilterValues) {
    handleFilterChange(values, setPaidStatusFilters)
    setPaidStatusFilterSelectionState(getPaidStatusSelectionState(values))
  }

  function handlePaidThroughFilterChange(values: FilterDialogPaidThroughFilterProps['values']) {
    setPaidThroughFilters(values)
    setPaidThroughFilterSelectionState(getMultiChoiceFilterSelectionState(values, paidThroughFilterProps?.config))
  }

  function handleDateFilterToggle() {
    setDateFilterDisabled(state => !state)
  }

  function handleStatusDeselectAll() {
    const deselectAllValues = statusFilterProps?.config.reduce((values, currentObject) => {
      return {
        ...values,
        [currentObject.name]: undefined,
      }
    }, {} as NonNullable<typeof statusFilters>)
    setStatusFilters(deselectAllValues)
    setStatusFilterSelectionState('none')
  }

  function handleOriginDeselectAll() {
    setOriginFilters(INITIAL_ORIGIN_FILTER)
    setOriginFilterSelectionState('none')
  }

  function handlePaymentMethodDeselectAll() {
    setPaymentMethodFilters(INITIAL_PAYMENT_METHOD_FILTER)
    setPaymentMethodFilterSelectionState('none')
  }

  function handlePaidStatusDeselectAll() {
    setPaidStatusFilters(INITIAL_PAID_STATUS_FILTERS)
    setPaidStatusFilterSelectionState('none')
  }

  function handlePaidThroughDeselectAll() {
    setPaidThroughFilters(INITIAL_PAID_THROUGH_FILTER)
    setPaidThroughFilterSelectionState('none')
  }

  function handleVatAreaDeselectAll() {
    setVatAreaFilters(INITIAL_VAT_AREA_FILTER)
    setVatAreaFilterSelectionState('none')
  }

  function handleFiltersReset() {
    resetFilters()
    onClose()
  }

  function handleFiltersUpdate(dialogContent?: HTMLDivElement) {
    // dialogContent will always be given, but due to generic typing it could potentially be undefined
    // all errors inside the filter dialog must have a `data-error` attribute on them to catch them
    // if there is any error, get the first one and scroll to it without updating the store
    // otherwise just update the store and close the dialog
    const errors = dialogContent?.querySelectorAll('[data-error="true"]')
    if (errors?.length) {
      errors[0].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    } else {
      updateFilters({
        isDateFilterDisabled,
        ...dateFilters,
        ...searchFilters,
        ...statusFilters,
        ...mapMultiSelectFilters({ values: categoryFilters, config: categoryFilterProps?.config }),
        ...mapMultiSelectFilters({ values: tagFilters, config: tagFilterProps?.config }),
        ...currencyFilter,
        ...amountFilters,
        ...mapMultiChoiceFilter({ values: originFilters, config: originFilterProps?.config }),
        ...mapMultiChoiceFilter({ values: paymentMethodFilters, config: paymentMethodFilterProps?.config }),
        ...paidStatusFilters,
        ...mapMultiChoiceFilter({
          values: paidThroughFilters,
          config: paidThroughFilterProps?.config,
        }),
        ...mapMultiChoiceFilter({ values: vatAreaFilters, config: vatAreaFilterProps?.config }),
      })
      if (searchFilters) {
        syncSearchInputValue?.(searchFilters.search)
      }
      onClose()
    }
  }

  return (
    <StyledDialog open={open} onClose={onClose} stickyTopOffset={70} stickyBottomOffset={90}>
      {({ aria, contentRef }) => (
        <>
          <CustomDialogHeader
            aria={aria}
            align="left"
            title={
              <FormattedMessage
                id="filtersDialog.text.title"
                defaultMessage="Részletes szűrők ({page})"
                values={{
                  page,
                }}
              />
            }
          />
          <FilterDialogBody id={aria.describedby}>
            {dateFilterProps && dateFilters && (
              <DateFilterWrapperDiv>
                <DateFilter
                  dateTypeOptions={dateFilterProps.dateTypeOptions}
                  dateTypeValue={dateFilters.dateField}
                  disabled={isDateFilterDisabled}
                  isDateFilterToggleDisabled={isDateFilterToggleDisabled}
                  fromDate={dateFilters.fromDate}
                  toDate={dateFilters.toDate}
                  onChange={handleDateFilterChange}
                  onToggle={handleDateFilterToggle}
                  defaultView="month"
                  variant={PAGE_FILTER_VARIANTS.form}
                />
                <div>
                  <DateTypeSelector
                    onChange={handleDateFilterChange}
                    options={dateFilterProps.dateTypeOptions}
                    value={dateFilters.dateField}
                    variant={DATE_WIDGET_VARIANTS.list}
                  />
                </div>
                <div>
                  <DatePeriodsMenu
                    disabled={isDateFilterDisabled}
                    isDateFilterToggleDisabled={isDateFilterToggleDisabled}
                    onChange={handleDateFilterChange}
                    onToggle={handleDateFilterToggle}
                    variant={DATE_WIDGET_VARIANTS.list}
                  />
                </div>
              </DateFilterWrapperDiv>
            )}
            {searchFilterProps && searchFilters && (
              <FormSearchFilter
                onChange={handleSearchFilterChange}
                search={searchFilters.search}
                searchFields={searchFilters.searchFields}
                searchFieldOptions={searchFilterProps.options}
                searchFieldExtraOptions={searchFilterProps.extraOptions}
              />
            )}
            {statusFilterProps && statusFilters && (
              <StatusFilterWrapper>
                <FilterFieldLabel
                  label={StatusLabelMessage}
                  onToggleClick={handleStatusDeselectAll}
                  toggleSelectionState={statusFilterSelectionState}
                  toggleVariant="deselectOnly"
                  tooltipText={
                    <FormattedMessage
                      id="filterBar.statusFilter.tooltip.info"
                      defaultMessage="A Státusz szűrői között “ÉS” kapcsolat van. Az eredmények között csak az jelenik majd meg, amelyekre mindegyik feltétel igaz."
                    />
                  }
                />
                <StatusFilter
                  config={statusFilterProps.config}
                  value={statusFilters}
                  onChange={handleStatusFilterChange}
                />
              </StatusFilterWrapper>
            )}

            {categoryFilterProps && categoryFilters && (
              <PlanPermission
                perform={FeaturePermissons.CATEGORIZATION}
                yes={() => (
                  <IncludeExcludeFilters
                    excludeLabel={ExcludeCategoryLabelMessage}
                    includeLabel={IncludeCategoryLabelMessage}
                    onChange={handleCategoryFilterChange}
                    options={categoryFilterProps.config.options}
                    values={categoryFilters}
                  />
                )}
                no={() => (
                  <UpsellIncludeExcludeFilters
                    excludeLabel={ExcludeCategoryLabelMessage}
                    includeLabel={IncludeCategoryLabelMessage}
                  />
                )}
              />
            )}

            {tagFilterProps && tagFilters && (
              <PlanPermission
                perform={FeaturePermissons.CATEGORIZATION}
                yes={() => (
                  <IncludeExcludeFilters
                    excludeLabel={ExcludeTagLabelMessage}
                    includeLabel={IncludeTagLabelMessage}
                    onChange={handleTagFilterChange}
                    options={tagFilterProps.config.options}
                    values={tagFilters}
                  />
                )}
                no={() => (
                  <UpsellIncludeExcludeFilters
                    excludeLabel={ExcludeTagLabelMessage}
                    includeLabel={IncludeTagLabelMessage}
                  />
                )}
              />
            )}

            {paidStatusFilterProps && paidStatusFilters && (
              <FilterWrapper>
                <FilterFieldLabel
                  label={PaidStatusLabelMessage}
                  onToggleClick={handlePaidStatusDeselectAll}
                  toggleSelectionState={paidStatusFilterSelectionState}
                  toggleVariant="deselectOnly"
                  tooltipText={
                    <FormattedMessage
                      id="filterBar.paidStatusFilter.tooltip.info"
                      defaultMessage="A Fizetettség szűrői között “VAGY” kapcsolat van. Az eredmények között minden megjelenik, amire legalább egy feltétel igaz."
                    />
                  }
                />
                <PaidStatusFilter
                  value={paidStatusFilters}
                  onChange={handlePaidStatusFilterChange}
                  variant={PAGE_FILTER_VARIANTS.form}
                />
              </FilterWrapper>
            )}

            {originFilterProps && originFilters && (
              <FilterWrapper>
                <FilterFieldLabel
                  label={OriginLabelMessage}
                  onToggleClick={handleOriginDeselectAll}
                  toggleSelectionState={originFilterSelectionState}
                  toggleVariant="deselectOnly"
                  tooltipText={
                    <FormattedMessage
                      id="filterBar.originFilter.tooltip.info"
                      defaultMessage="A Forrás szűrői között “VAGY” kapcsolat van. Az eredmények között minden megjelenik, amire legalább egy feltétel igaz."
                    />
                  }
                />
                <MultiChoiceFilter
                  config={originFilterProps.config}
                  onChange={handleOriginFilterChange}
                  values={originFilters}
                />
              </FilterWrapper>
            )}

            {paidThroughFilterProps && paidThroughFilters && (
              <FilterWrapper>
                <FilterFieldLabel
                  label={PaidThroughLabelMessage}
                  onToggleClick={handlePaidThroughDeselectAll}
                  toggleSelectionState={paidThroughFilterSelectionState}
                  toggleVariant="deselectOnly"
                  tooltipText={
                    <FormattedMessage
                      id="filterBar.paidThroughFilter.tooltip.info"
                      defaultMessage="A Pénztárca szűrői között “VAGY” kapcsolat van. Az eredmények között minden megjelenik, amire legalább egy feltétel igaz."
                    />
                  }
                />
                <MultiCheckboxFilter
                  config={paidThroughFilterProps.config}
                  onChange={handlePaidThroughFilterChange}
                  values={paidThroughFilters}
                />
              </FilterWrapper>
            )}

            {amountFilterProps && amountFilters && <AmountFilters values={amountFilters} onChange={setAmountFilters} />}

            {currencyFilterProps && <CurrencySelectFilter value={currencyFilter} onChange={setCurrencyFilter} />}

            {vatAreaFilterProps && vatAreaFilters && (
              <FilterWrapper>
                <FilterFieldLabel
                  label={VatAreaLabelMessage}
                  onToggleClick={handleVatAreaDeselectAll}
                  toggleSelectionState={vatAreaFilterSelectionState}
                  toggleVariant="deselectOnly"
                  tooltipText={
                    <FormattedMessage
                      id="filterBar.vatAreaFilter.tooltip.info"
                      defaultMessage="A Területi hatály szűrői között “VAGY” kapcsolat van. Az eredmények között minden megjelenik, amire legalább egy feltétel igaz."
                    />
                  }
                />
                <MultiChoiceFilter
                  config={vatAreaFilterProps.config}
                  onChange={handleVatAreaFilterChange}
                  values={vatAreaFilters}
                />
              </FilterWrapper>
            )}

            {paymentMethodFilterProps && paymentMethodFilters && (
              <FilterWrapper>
                <FilterFieldLabel
                  label={PaymentMethodLabelMessage}
                  onToggleClick={handlePaymentMethodDeselectAll}
                  toggleSelectionState={paymentMethodFilterSelectionState}
                  toggleVariant="deselectOnly"
                  tooltipText={
                    <FormattedMessage
                      id="filterBar.paymentMethodFilter.tooltip.info"
                      defaultMessage="A Fizetés módja szűrői között “VAGY” kapcsolat van. Az eredmények között minden megjelenik, amire legalább egy feltétel igaz."
                    />
                  }
                />
                <MultiChoiceFilter
                  config={paymentMethodFilterProps.config}
                  onChange={handlePaymentMethodFilterChange}
                  values={paymentMethodFilters}
                />
              </FilterWrapper>
            )}
          </FilterDialogBody>

          <CustomDialogActions>
            <Button onClick={onClose} variant="secondaryContained" fullWidth>
              <FormattedMessage id="dialogs.filters.buttons.cancel" defaultMessage="Mégsem" />
            </Button>
            <Button onClick={handleFiltersReset} variant="primaryText" fullWidth>
              <FormattedMessage id="dialogs.filters.buttons.reset" defaultMessage="Szűrők törlése" />
            </Button>
            <Button onClick={() => handleFiltersUpdate(contentRef)} variant="primaryContained" fullWidth>
              <FormattedMessage id="dialogs.filters.buttons.filter" defaultMessage="Szűrés" />
            </Button>
          </CustomDialogActions>
        </>
      )}
    </StyledDialog>
  )
}

PureFilterDialog.propTypes = {
  filterProps: FILTER_DIALOG_FILTER_PROPS_PROP_TYPE.isRequired,
  fromDate: PropTypes.string,
  isDateFilterDisabled: PropTypes.bool.isRequired,
  isDateFilterToggleDisabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  syncSearchInputValue: PropTypes.func,
  toDate: PropTypes.string,
  updateFilters: PropTypes.func.isRequired,
}

export const FilterDialog = connect(({ filters: { fromDate, toDate } }: Store) => ({
  fromDate,
  toDate,
}))(PureFilterDialog)

FilterDialog.displayName = 'FilterDialog'
