import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { expenseActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useExpenseDetailsControlsDispatch, useExpenseDetailsControlsState } from '@contexts'

import { useCancellablePromiseRef } from '@hooks'

import {
  AsyncButtonWithIcon,
  Button,
  ButtonWithIcon,
  CustomDialog,
  CustomDialogActions,
  CustomDialogBody,
  CustomDialogHeader,
  LockIcon,
  Typography,
} from '@components/ui'

const UnlockButtonLabelMessage = <FormattedMessage id="buttons.label.unlockExportedStatus" defaultMessage="Feloldás" />

const StyledCustomDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
  min-width: 596px;

  ${Button} {
    min-width: 180px;
  }
`

const ButtonContainerDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  margin: -1rem;
`

interface ExpenseUnlockButtonProps {
  callUnlockAccounting: AsyncFunction<string>
  disabled?: boolean
  methodUrl: BackendCallableLink | undefined
}

function PureExpenseUnlockButton({ disabled, methodUrl, callUnlockAccounting }: ExpenseUnlockButtonProps) {
  const [open, setOpen] = React.useState(false)
  const { isAccountingInProgress } = useExpenseDetailsControlsState()
  const { setAccountingInProgress, setActionError } = useExpenseDetailsControlsDispatch()
  const cPromiseRef = useCancellablePromiseRef()

  function handleClick() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  async function handleUnlock() {
    if (methodUrl) {
      setAccountingInProgress(true)
      try {
        cPromiseRef.current = cancellablePromise(callUnlockAccounting(methodUrl))
        await cPromiseRef.current.promise
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          setActionError(errorMsg)
        }
      }
      setAccountingInProgress(false)
    } else {
      throw new Error('Unexpected error in call "unlock accounting" - Missing methodUrl')
    }
  }

  return (
    <ButtonContainerDiv>
      <ButtonWithIcon icon={<LockIcon />} variant="secondaryText" onClick={handleClick} disabled={disabled}>
        {UnlockButtonLabelMessage}
      </ButtonWithIcon>
      <CustomDialog open={open} onClose={handleClose}>
        <CustomDialogHeader
          title={
            <FormattedMessage
              id="confirmDialogs.unlockAccounting.title"
              defaultMessage="Exportált számla szerkesztés"
            />
          }
          borderless
        />
        <CustomDialogBody>
          <Typography align="center">
            <FormattedMessage
              id="confirmDialogs.unlockAccounting.description"
              defaultMessage="Ez a számla már exportálva lett könyvelőszoftver felé.{br}Biztosan feloldod ezt a számlát?"
              values={{ br: <br /> }}
            />
          </Typography>
        </CustomDialogBody>
        <StyledCustomDialogActions borderless>
          <Button variant="primaryContained" onClick={handleClose}>
            <FormattedMessage id="confirmDialogs.unlockAccounting.buttons.cancel" defaultMessage="Mellőzés" />
          </Button>
          <AsyncButtonWithIcon
            icon={<LockIcon />}
            variant="primaryText"
            onClick={handleUnlock}
            loading={isAccountingInProgress}
          >
            {UnlockButtonLabelMessage}
          </AsyncButtonWithIcon>
        </StyledCustomDialogActions>
      </CustomDialog>
    </ButtonContainerDiv>
  )
}

export const ExpenseUnlockButton = connect(
  (state: Store) => ({
    methodUrl: state.expense.details.data?.links?.accounting_unlock,
  }),
  dispatch => ({
    callUnlockAccounting: bindActionToPromise(dispatch, expenseActions.callUnlockAccounting.request),
  })
)(PureExpenseUnlockButton)

ExpenseUnlockButton.displayName = 'ExpenseUnlockButton'
