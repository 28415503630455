import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Checkbox, StyledCheckbox } from '@components/ui/Checkbox'
import { Typography } from '@components/ui/Typography'

const Label = styled.label<{ $checked: boolean }>`
  margin: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  gap: 6px;

  ${StyledCheckbox} {
    pointer-events: none;
    background-color: ${({ theme, $checked }) => ($checked ? 'var(--checkbox-color)' : theme.colors.gray[0])};
    border-color: var(--checkbox-color);
  }
`

interface ChartLegendProps<ColorMap extends Record<string, string>> {
  checked: boolean
  children: React.ReactChild
  colorMap: ColorMap
  dataKey: keyof ColorMap
  onToggle: VoidFunction
}

export default function ChartLegend<ColorMap extends Record<string, string>>({
  checked,
  children,
  colorMap,
  dataKey,
  onToggle,
}: ChartLegendProps<ColorMap>) {
  return (
    <Label
      onClick={onToggle}
      $checked={checked}
      style={{
        '--checkbox-color': colorMap[dataKey],
      }}
    >
      <Checkbox checked={checked} />
      <Typography color="gray-80" size="400-sm" tag="span">
        {children}
      </Typography>
    </Label>
  )
}

ChartLegend.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  colorMap: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}
