import React from 'react'
import PropTypes from 'prop-types'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import styled, { DefaultTheme } from 'styled-components'

import { QUICK_TYPOGRAPHY } from '@constants'

import { Typography, TYPOGRAPHY_COLOR, TypographyProps } from '../Typography'

const INFO_TEXT_COLOR = {
  SECONDARY_40: 'secondary-40',
  GRAY_40: 'gray-40',
} as const

type InfoTextColor = (typeof INFO_TEXT_COLOR)[keyof typeof INFO_TEXT_COLOR]

// Function to get icon color
function getIconColor(theme: DefaultTheme, color?: InfoTextColor) {
  switch (color) {
    case INFO_TEXT_COLOR.GRAY_40:
      return theme.colors.gray[40]
    case INFO_TEXT_COLOR.SECONDARY_40:
    default:
      return theme.colors.blue[40]
  }
}

const ICON_SIZE = '24px'

const WrapperParagraph = styled.p<{ $typographyLineHeight: string }>`
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  color: ${({ theme }) => theme.colors.secondary[5]};

  ${Typography} {
    margin-top: max(0px, calc((${ICON_SIZE} - ${({ $typographyLineHeight }) => $typographyLineHeight}) / 2));
  }
`

const StyledInfoIcon = styled(InfoOutlinedIcon)<{ $colorProp: InfoTextColor }>`
  color: ${({ theme, $colorProp }) => getIconColor(theme, $colorProp)};
  font-size: ${ICON_SIZE};
`

interface InfoTextProps {
  children: TypographyProps['children']
  className?: string
  color?: TypographyProps['color']
  iconColor?: InfoTextColor
  size?: TypographyProps['size']
}

export function InfoText({
  children,
  className,
  color,
  iconColor = INFO_TEXT_COLOR.SECONDARY_40,
  size = '400-xs',
}: InfoTextProps) {
  return (
    <WrapperParagraph className={className} $typographyLineHeight={QUICK_TYPOGRAPHY[size].lineHeight}>
      <StyledInfoIcon $colorProp={iconColor} />
      <Typography size={size} tag="span" color={color}>
        {children}
      </Typography>
    </WrapperParagraph>
  )
}

InfoText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(TYPOGRAPHY_COLOR)),
  iconColor: PropTypes.oneOf(Object.values(INFO_TEXT_COLOR)),
  size: PropTypes.oneOf(Object.keys(QUICK_TYPOGRAPHY)),
}

interface MultilineInfoTextProps {
  children: TypographyProps['children'][]
  className?: string
  color?: TypographyProps['color']
  iconColor?: InfoTextColor
  size?: TypographyProps['size']
}

export function MultilineInfoText({
  children,
  className,
  color,
  iconColor = INFO_TEXT_COLOR.SECONDARY_40,
  size = '400-xs',
}: MultilineInfoTextProps) {
  return (
    <WrapperParagraph as="div" className={className} $typographyLineHeight={QUICK_TYPOGRAPHY[size].lineHeight}>
      <StyledInfoIcon $colorProp={iconColor} />
      <div>
        {children.map((child, index) => (
          <Typography key={index} size={size} color={color}>
            {child}
          </Typography>
        ))}
      </div>
    </WrapperParagraph>
  )
}
