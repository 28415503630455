import React from 'react'

import styled from 'styled-components'

function BaseUserSolidIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(18 / 16) * size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 18"
    >
      <path
        fill="currentColor"
        d="M7.875 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm-1.607 1.688A6.267 6.267 0 0 0 0 16.956C0 17.532.468 18 1.044 18h13.662c.576 0 1.044-.468 1.044-1.044a6.267 6.267 0 0 0-6.268-6.268H6.268Z"
      />
    </svg>
  )
}

export const UserSolidIcon = styled(BaseUserSolidIcon)``
