import { DATA_TABS } from './tabs'

export const COMPANY_USER_PAGE_PERMISSION_MAPPER = {
  liquidity: 'deny_liquidity_permission',
  dokuments: 'deny_document_permission',
  partner: 'deny_partner_permission',
  monthlyStatement: 'deny_analytics_permission',
  cashflow: 'deny_cashflow_permission',
  pulse: 'deny_report_permission',
} as const

export const COMPANY_USER_PAGE_PERMISSIONS = Object.values(COMPANY_USER_PAGE_PERMISSION_MAPPER)

export const COMPANY_USER_DASHBOARD_PERMISSION_MAPPER = {
  [DATA_TABS.expense]: 'deny_expense_permission',
  [DATA_TABS.quarantine]: 'deny_nav_permission',
  [DATA_TABS.income]: 'deny_income_permission',
  [DATA_TABS.salary]: 'deny_salary_permission',
  [DATA_TABS.tax]: 'deny_tax_permission',
} as const

export const COMPANY_USER_FEATURE_PERMISSION_MAPPER = {
  approveExpense: 'deny_approve_expense_permission',
  accounting: 'deny_accounting_permission',
  tag: 'deny_tag_permission',
  delete: 'deny_delete_permission',
  bankTransactions: 'deny_bank_transactions_permission',
} as const

export const COMPANY_USER_PERMISSIONS = {
  ...COMPANY_USER_PAGE_PERMISSION_MAPPER,
  ...COMPANY_USER_DASHBOARD_PERMISSION_MAPPER,
  ...COMPANY_USER_FEATURE_PERMISSION_MAPPER,
} as const

export const COMPANY_USER_PERMISSIONS_LIST = Object.values(COMPANY_USER_PERMISSIONS)
