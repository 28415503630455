import React from 'react'
import PropTypes from 'prop-types'

import { closeSnackbar, MaterialDesignContent, SnackbarAction, SnackbarProvider, useSnackbar } from 'notistack'
import { defineMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { useAssertContext } from '@hooks/useAssertContext'

import { IconButton } from '@components/ui/Buttons'
import { CircleCheckRegularIcon, InfoIcon, WarningIcon, XmarkRegularIcon } from '@components/ui/svgIcons'

import { AlertDispatchContextProps, AlertProviderProps } from './types'

const HideMessage = defineMessage({
  id: 'buttons.hide',
  defaultMessage: 'Bezár',
})

const SNACK_ALERT_DEFAULT_TIMEOUT = 5000

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  flex-shrink: 0;
`

const StyledMaterialDesignContent = styled(MaterialDesignContent)`
  max-width: 400px;
  padding-left: 16px;
  flex-wrap: nowrap;

  &.notistack-MuiContent-success {
    background-color: ${({ theme }) => theme.colors.success[40]};
  }
  &.notistack-MuiContent-error {
    background-color: ${({ theme }) => theme.colors.error[40]};
  }
  &.notistack-MuiContent-info {
    background-color: ${({ theme }) => theme.colors.blue[40]};
  }
`

const ICON_VARIANTS = {
  info: (
    <IconWrapper>
      <InfoIcon size={16} />
    </IconWrapper>
  ),
  error: (
    <IconWrapper>
      <WarningIcon size={20} />
    </IconWrapper>
  ),
  success: (
    <IconWrapper>
      <CircleCheckRegularIcon size={20} />
    </IconWrapper>
  ),
}

const COMPONENTS = {
  info: StyledMaterialDesignContent,
  error: StyledMaterialDesignContent,
  success: StyledMaterialDesignContent,
}

export const AlertDispatchContext = React.createContext<AlertDispatchContextProps | undefined>(undefined)
AlertDispatchContext.displayName = 'AlertDispatchContext'

function CustomAlertProvider({ children }: AlertProviderProps) {
  const { enqueueSnackbar } = useSnackbar()

  const setErrorAlert = React.useCallback(
    (message, { autoHideDuration = SNACK_ALERT_DEFAULT_TIMEOUT, persist = false } = {}) => {
      enqueueSnackbar(message, { variant: 'error', autoHideDuration, persist })
    },
    [enqueueSnackbar]
  )

  const setInfoAlert = React.useCallback(
    (message, { autoHideDuration = SNACK_ALERT_DEFAULT_TIMEOUT, persist = false } = {}) => {
      enqueueSnackbar(message, { variant: 'info', autoHideDuration, persist })
    },
    [enqueueSnackbar]
  )

  const setSuccessAlert = React.useCallback(
    (message, { autoHideDuration = SNACK_ALERT_DEFAULT_TIMEOUT, persist = false } = {}) => {
      enqueueSnackbar(message, { variant: 'success', autoHideDuration, persist })
    },
    [enqueueSnackbar]
  )

  // due to dialog renders handled in the provider we need to memoize the provider values so it won't trigger
  // a rerender from all the consuming components
  const dispatchValue = React.useMemo(
    () => ({ setErrorAlert, setInfoAlert, setSuccessAlert }),
    [setErrorAlert, setInfoAlert, setSuccessAlert]
  )

  return <AlertDispatchContext.Provider value={dispatchValue}>{children}</AlertDispatchContext.Provider>
}

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const { formatMessage } = useIntl()

  const actionHandler: SnackbarAction = React.useCallback(
    snackbarId => (
      <IconButton
        onClick={() => closeSnackbar(snackbarId)}
        variant="primaryContainedTransparent"
        size="small"
        title={formatMessage(HideMessage)}
      >
        <XmarkRegularIcon size={12} />
      </IconButton>
    ),
    [formatMessage]
  )

  return (
    <SnackbarProvider maxSnack={3} action={actionHandler} iconVariant={ICON_VARIANTS} Components={COMPONENTS}>
      <CustomAlertProvider>{children}</CustomAlertProvider>
    </SnackbarProvider>
  )
}

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useAlertDispatch() {
  return useAssertContext(AlertDispatchContext)
}
