import React from 'react'

export default function CircleExclamationIcon({ className, size = 12 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
    >
      <path
        d="M6 12A6 6 0 1 0 6 0a6 6 0 1 0 0 12Zm0-9c.312 0 .563.25.563.563v2.624c0 .312-.251.563-.563.563a.561.561 0 0 1-.563-.563V3.563C5.438 3.252 5.689 3 6 3Zm.75 5.25a.75.75 0 1 1-1.498.002.75.75 0 0 1 1.498-.002Z"
        fill="currentColor"
      />
    </svg>
  )
}
