import React from 'react'

import { FormattedMessage } from 'react-intl'

interface SummaryValueMessageProps {
  info: React.ReactNode
}

export function PayablesSummaryValueTbjMessageComponent({ info }: SummaryValueMessageProps) {
  return (
    <FormattedMessage
      id="taxation.payables.summaryValue.tbj"
      defaultMessage="TB járulék összege {info}"
      values={{
        info,
      }}
    />
  )
}

export function PayablesSummaryValueSzochoMessageComponent({ info }: SummaryValueMessageProps) {
  return (
    <FormattedMessage
      id="taxation.payables.summaryValue.szocho"
      defaultMessage="SZOCHO adó összege {info}"
      values={{
        info,
      }}
    />
  )
}

export function PayablesSummaryValueSzjaMessageComponent({ info }: SummaryValueMessageProps) {
  return (
    <FormattedMessage
      id="taxation.payables.summaryValue.szja"
      defaultMessage="SZJA előleg összege {info}"
      values={{
        info,
      }}
    />
  )
}
