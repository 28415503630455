import React from 'react'

import styled from 'styled-components'

function BaseRocketIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M6.18441 7.93436C6.58753 8.13436 6.95628 8.39686 7.27816 8.72186C7.60003 9.04686 7.86566 9.41561 8.06566 9.81561C11.3719 8.87811 12.9625 7.33124 13.7375 5.87811C14.5188 4.41561 14.5907 2.82811 14.425 1.57499C13.1719 1.40936 11.5844 1.48124 10.1219 2.26249C8.66878 3.03749 7.12191 4.63124 6.18441 7.93436ZM12 9.75311V12.3219C12 13.1156 11.5813 13.8531 10.8969 14.2562L8.13128 15.8969C7.90003 16.0344 7.61253 16.0375 7.37816 15.9031C7.14378 15.7687 7.00003 15.5219 7.00003 15.25V11.6656C7.00003 10.9594 6.71878 10.2812 6.21878 9.78124C5.71878 9.28124 5.04066 8.99999 4.33441 8.99999H0.75003C0.48128 8.99999 0.23128 8.85624 0.0969053 8.62186C-0.0374697 8.38749 -0.0343447 8.09999 0.103155 7.86874L1.74378 5.10311C2.15003 4.41874 2.88441 3.99999 3.67816 3.99999H6.24691C8.80316 -0.118764 12.775 -0.265639 15.1219 0.165611C15.4844 0.231236 15.7688 0.515611 15.8344 0.878111C16.2657 3.22499 16.1188 7.19686 12 9.75311ZM0.884405 15.9969C0.384405 16.0094 -0.00934469 15.6156 0.00315531 15.1156C0.0312803 13.9375 0.240655 11.8656 1.31253 10.7969C2.38753 9.72186 4.12816 9.72186 5.20316 10.7969C6.27816 11.8719 6.27816 13.6125 5.20316 14.6875C4.13441 15.7594 2.06253 15.9687 0.884405 15.9969ZM2.45316 13.9812C2.85316 13.9594 3.42816 13.8656 3.74378 13.5531C4.10003 13.1969 4.10003 12.6156 3.74378 12.2594C3.38753 11.9031 2.80628 11.9031 2.45003 12.2594C2.13441 12.575 2.04378 13.15 2.02191 13.55C2.00628 13.8 2.20628 13.9969 2.45628 13.9844L2.45316 13.9812ZM10.25 4.49999C10.25 4.16847 10.3817 3.85052 10.6161 3.6161C10.8506 3.38168 11.1685 3.24999 11.5 3.24999C11.8316 3.24999 12.1495 3.38168 12.3839 3.6161C12.6183 3.85052 12.75 4.16847 12.75 4.49999C12.75 4.83151 12.6183 5.14945 12.3839 5.38387C12.1495 5.61829 11.8316 5.74999 11.5 5.74999C11.1685 5.74999 10.8506 5.61829 10.6161 5.38387C10.3817 5.14945 10.25 4.83151 10.25 4.49999Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const RocketIcon = styled(BaseRocketIcon)``
