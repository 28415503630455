import React from 'react'

/* eslint-disable max-len */
const CalendarIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.0375C0 3.21598 0.659791 2.55 1.47368 2.55H14.5263C15.3402 2.55 16 3.21598 16 4.0375V15.5125C16 16.334 15.3402 17 14.5263 17H1.47368C0.659791 17 0 16.334 0 15.5125V4.0375Z"
      fill="#2168D1"
    />
    <path
      d="M2.52632 1.4875C2.52632 0.665977 3.18611 0 4 0H4.84211C5.656 0 6.31579 0.665977 6.31579 1.4875V2.55H2.52632V1.4875Z"
      fill="#2168D1"
    />
    <path
      d="M9.68421 1.4875C9.68421 0.665977 10.344 0 11.1579 0H12C12.8139 0 13.4737 0.665977 13.4737 1.4875V2.55H9.68421V1.4875Z"
      fill="#2168D1"
    />
    <path d="M4.6316 9.35039H7.7895V12.5379H4.6316V9.35039Z" fill="white" />
    <path d="M1.26318 6.16289H4.21055V8.71289H1.26318V6.16289Z" fill="white" />
    <path d="M4.6316 6.16289H7.7895V8.71289H4.6316V6.16289Z" fill="white" />
    <path d="M8.21055 6.16289H11.3684V8.71289H8.21055V6.16289Z" fill="white" />
    <path d="M11.7895 6.16289H14.7369V8.71289H11.7895V6.16289Z" fill="white" />
    <path d="M1.26318 9.35039H4.21055V12.5379H1.26318V9.35039Z" fill="white" />
    <path d="M8.21055 9.35039H11.3684V12.5379H8.21055V9.35039Z" fill="white" />
    <path d="M11.7895 9.35039H14.7369V12.5379H11.7895V9.35039Z" fill="white" />
    <path d="M1.26318 13.1754H4.21055V15.7254H1.26318V13.1754Z" fill="white" />
    <path d="M4.6316 13.1754H7.7895V15.7254H4.6316V13.1754Z" fill="white" />
    <path d="M8.21055 13.1754H11.3684V15.7254H8.21055V13.1754Z" fill="white" />
    <path d="M11.7895 13.1754H14.7369V15.7254H11.7895V13.1754Z" fill="white" />
    <path
      d="M3.7895 1.70039C3.7895 1.46567 3.97801 1.27539 4.21055 1.27539H4.6316C4.86415 1.27539 5.05266 1.46567 5.05266 1.70039V4.25039C5.05266 4.48511 4.86415 4.67539 4.6316 4.67539H4.21055C3.97801 4.67539 3.7895 4.48511 3.7895 4.25039V1.70039Z"
      fill="white"
    />
    <path
      d="M10.9474 1.70039C10.9474 1.46567 11.1359 1.27539 11.3684 1.27539H11.7895C12.022 1.27539 12.2106 1.46567 12.2106 1.70039V4.25039C12.2106 4.48511 12.022 4.67539 11.7895 4.67539H11.3684C11.1359 4.67539 10.9474 4.48511 10.9474 4.25039V1.70039Z"
      fill="white"
    />
  </svg>
)

export default CalendarIcon
