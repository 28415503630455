import __camelCase from 'lodash/camelCase'
import __snakeCase from 'lodash/snakeCase'

import { CURRENCY_OPTIONS } from '@constants'

/**
 * helper function to create aria attributes for a dialog
 *
 * @param {string} prefix
 * @returns
 */
export function createAriaAttributes(prefix: string) {
  return {
    labelledby: `${prefix}-heading`,
    describedby: `${prefix}-description`,
  }
}

type DecimalHelperOptions = Partial<Record<'minimumFractionDigits' | 'maximumFractionDigits', number>>
/**
 * helper function to format numeric string or number value to numeric string with 2 decimal places (same as backend format)
 *
 * @param {Decimal | number} value input value to format into Decimal
 * @param {number} options.minimumFractionDigits minimum number of visible decimal places (Optional, Default: 0)
 * @param {number} options.maximumFractionDigits maximum number of visible decimal places (Optional, Default: 2)
 * @returns {Decimal}
 */
export function getDecimal(
  value: number | Decimal,
  { minimumFractionDigits = 2, maximumFractionDigits = 2 }: DecimalHelperOptions = {}
): Decimal {
  if (minimumFractionDigits < 0 || minimumFractionDigits > maximumFractionDigits) {
    throw Error('Invalid minimumFractionDigits option in getDecimal helper')
  }

  const stringValue = Number(value).toFixed(maximumFractionDigits)

  if (minimumFractionDigits === 0 && /^-?\d*(\.0+)?$/.test(stringValue)) {
    return stringValue.split('.')[0] // remove decimals because all is zero
  }
  const regex = new RegExp(`(\\.\\d*?[0-9]{${minimumFractionDigits}})0*$`)
  return stringValue.replace(regex, '$1') // remove trailing zeros after the decimal point
}

type RoundToDecimalOptionsAsDecimal = { minimumFractionDigits?: number; maximumFractionDigits?: number; numeric: false }
type RoundToDecimalOptionsAsNumber = { minimumFractionDigits?: number; maximumFractionDigits?: number; numeric: true }
type RoundToDecimalOptions = { minimumFractionDigits?: number; maximumFractionDigits?: number; numeric?: boolean }

//* CALCULATION helpers

export function roundToDecimal(amount: Decimal | number, options: RoundToDecimalOptionsAsDecimal): Decimal
export function roundToDecimal(amount: Decimal | number, options: RoundToDecimalOptionsAsNumber): number
export function roundToDecimal(amount: Decimal | number): Decimal
export function roundToDecimal(
  amount: Decimal | number,
  { minimumFractionDigits = 0, maximumFractionDigits = 2, numeric = false }: RoundToDecimalOptions = {}
) {
  const decimalString = getDecimal(String(amount), {
    minimumFractionDigits,
    maximumFractionDigits,
  })
  if (numeric) {
    return Number(decimalString)
  }
  return decimalString
}

/**
 * helper function to summarize number list
 *
 * @param {number[]} values - numbers list
 * @returns summarized value
 */
export function sum(values: number[]) {
  return values.reduce((acc, curr) => (acc += curr), 0)
}

/**
 * helper function to return maximum fraction digits number for currency
 * @param currency - currency ID or name (e.g. 'HUF', 'huf', 1) (optional)
 * @returns - maximum fraction digits number
 */
export function getMaximumFractionDigitsByCurrency(currency: Nullable<string | number | undefined>) {
  if (currency == null) {
    return CURRENCY_OPTIONS.maximumFractionDigits
  }

  // "1" is a valid ID for HUF currency
  return ['HUF', 'huf', 1].includes(currency)
    ? CURRENCY_OPTIONS.minimumFractionDigits
    : CURRENCY_OPTIONS.maximumFractionDigits
}

/**
 * Transform object with snake_case keys to object with camelCase keys (recursive)
 * @param {object} results - object with snake_case keys
 * @returns object with camelCase keys
 */
export function transformSnakeCaseObjectToCamelCase(results: any): any {
  if (Array.isArray(results)) {
    // If the array contains objects, transform each object. Otherwise, return the array as is.
    return results.map(item =>
      typeof item === 'object' && item !== null ? transformSnakeCaseObjectToCamelCase(item) : item
    )
  } else if (results !== null && typeof results === 'object') {
    return Object.fromEntries(
      Object.entries(results).map(([key, value]) => [
        __camelCase(key),
        Array.isArray(value)
          ? value.map(transformSnakeCaseObjectToCamelCase)
          : typeof value === 'object' && value !== null
          ? transformSnakeCaseObjectToCamelCase(value)
          : value,
      ])
    )
  }
  return results
}

/**
 * Helper function to transform object with camelCase keys to object with snake_case keys (recursive)
 * @param {object} results - object with camelCase keys
 * @returns object with snake_case keys (only 1 level deep)
 */
export function transformCamelCaseObjectToSnakeCase(results: any): any {
  if (Array.isArray(results)) {
    // If the array contains objects, transform each object. Otherwise, return the array as is.
    return results.map(item =>
      typeof item === 'object' && item !== null ? transformCamelCaseObjectToSnakeCase(item) : item
    )
  } else if (results !== null && typeof results === 'object') {
    return Object.fromEntries(
      Object.entries(results).map(([key, value]) => [
        __snakeCase(key),
        Array.isArray(value)
          ? transformCamelCaseObjectToSnakeCase(value)
          : typeof value === 'object' && value !== null
          ? transformCamelCaseObjectToSnakeCase(value)
          : value,
      ])
    )
  }
  return results
}
