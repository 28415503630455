import React from 'react'
import PropTypes from 'prop-types'

import { NumberFormatOptionsSignDisplay } from '@formatjs/ecma402-abstract'
import { FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'

import { accountNumberFormatter, getDefaultCurrencyName, getMaximumFractionDigitsByCurrency } from '@helpers'

import { CURRENCY_OPTIONS } from '@constants'

interface AccountNumberProps {
  value?: string
}

export function AccountNumber({ value }: AccountNumberProps) {
  if (!value) {
    return null
  }
  return <span>{accountNumberFormatter.format(value)}</span>
}

AccountNumber.propTypes = { value: PropTypes.string }

export interface CurrencyProps {
  companyCurrency: string
  currencies: Currency[]
  currency?: string
  currencyId?: number
  signDisplay?: NumberFormatOptionsSignDisplay
  value: number
}

function PureCurrency({
  companyCurrency,
  currencies,
  currency = companyCurrency,
  currencyId,
  signDisplay,
  value,
}: CurrencyProps) {
  const currencyToUse = currencyId ? currencies.find(({ id }) => id === currencyId)?.name ?? currency : currency
  return (
    <FormattedNumber
      {...CURRENCY_OPTIONS}
      currency={currencyToUse}
      minimumFractionDigits={getMaximumFractionDigitsByCurrency(currencyToUse)}
      signDisplay={signDisplay}
      value={value}
    />
  )
}

PureCurrency.propTypes = {
  companyCurrency: PropTypes.string.isRequired,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  currency: PropTypes.string,
  currencyId: PropTypes.number,
  signDisplay: PropTypes.oneOf([
    'auto',
    'always',
    'never',
    'exceptZero',
  ]) as React.Validator<NumberFormatOptionsSignDisplay>,
  value: PropTypes.number.isRequired,
}

/**
 * Component to display a value in a currency provided. If no currency is provided, it will use the default currency.
 *
 * Use with either a currency id or a currency name.
 */
export const Currency = connect((store: Store) => ({
  currencies: store.dashboard.common.currencies,
  companyCurrency: getDefaultCurrencyName(store),
}))(PureCurrency)

Currency.displayName = 'Currency'
