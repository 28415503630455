import React from 'react'

import { CircularProgress } from '@material-ui/core'
import { Document, pdfjs } from 'react-pdf'
import styled from 'styled-components'

import { setNumPages } from './actions'
import { PDFAllPages } from './PDFAllPages'
import { PDFControlsContext } from './PDFControlsProvider'
import { PDFSinglePage } from './PDFSinglePage'
import { initialPDFState } from './reducer'

const DocumentPDF = styled(Document)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
`

const REACT_PDF_DOCUMENT_OPTIONS = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
}

export function PDFPages() {
  const {
    state: { mainState, paginated },
    dispatch,
  } = React.useContext(PDFControlsContext)

  const currentDocument = mainState?.currentDocument || null

  const onLoadSuccessHandler = React.useCallback(({ numPages }) => dispatch(setNumPages(numPages)), [dispatch])

  const onLoadErrorHandler = React.useCallback(error => {
    console.error('PDF load error:', error)
  }, [])

  const onSourceErrorHandler = React.useCallback(error => {
    console.error('PDF source error:', error)
  }, [])

  React.useEffect(() => {
    dispatch(setNumPages(initialPDFState.numPages))
  }, [currentDocument])

  if (!currentDocument) return null

  return (
    <DocumentPDF
      file={currentDocument.fileData || currentDocument.uri} // can be a File or an URL
      loading={<CircularProgress size="20" color="inherit" />}
      onLoadError={onLoadErrorHandler}
      onLoadSuccess={onLoadSuccessHandler}
      onSourceError={onSourceErrorHandler}
      options={REACT_PDF_DOCUMENT_OPTIONS}
    >
      {paginated ? <PDFSinglePage /> : <PDFAllPages />}
    </DocumentPDF>
  )
}
