import { DocViewerConfig, DocViewerDocument, RendererRect } from './types'

export enum DocViewerActions {
  SET_ALL_DOCUMENTS = 'SET_ALL_DOCUMENTS',
  SET_DOCUMENT_LOADING = 'SET_DOCUMENT_LOADING',
  NEXT_DOCUMENT = 'NEXT_DOCUMENT',
  PREVIOUS_DOCUMENT = 'PREVIOUS_DOCUMENT',
  UPDATE_CURRENT_DOCUMENT = 'UPDATE_CURRENT_DOCUMENT',
  SET_RENDERER_RECT = 'SET_RENDERER_RECT',
  SET_MAIN_CONFIG = 'SET_MAIN_CONFIG',
}

interface SetAllDocuments {
  type: DocViewerActions.SET_ALL_DOCUMENTS
  documents: DocViewerDocument[]
  initialActiveDocument?: DocViewerDocument
}

interface SetDocumentLoading {
  type: DocViewerActions.SET_DOCUMENT_LOADING
  value: boolean
}

interface SetRendererRect {
  type: DocViewerActions.SET_RENDERER_RECT
  rect: RendererRect
}

interface SetMainConfig {
  type: DocViewerActions.SET_MAIN_CONFIG
  config: DocViewerConfig
}

interface NextDocument {
  type: DocViewerActions.NEXT_DOCUMENT
}

interface UpdateCurrentDocument {
  type: DocViewerActions.UPDATE_CURRENT_DOCUMENT
  document: DocViewerDocument
}

interface PreviousDocument {
  type: DocViewerActions.PREVIOUS_DOCUMENT
}

export type MainStateActions =
  | SetAllDocuments
  | SetDocumentLoading
  | NextDocument
  | PreviousDocument
  | UpdateCurrentDocument
  | SetRendererRect
  | SetMainConfig

export function setAllDocuments(
  documents: DocViewerDocument[],
  initialActiveDocument?: DocViewerDocument
): SetAllDocuments {
  return {
    type: DocViewerActions.SET_ALL_DOCUMENTS,
    documents,
    initialActiveDocument,
  }
}

export function setDocumentLoading(value: boolean): SetDocumentLoading {
  return {
    type: DocViewerActions.SET_DOCUMENT_LOADING,
    value,
  }
}

export function nextDocument(): NextDocument {
  return { type: DocViewerActions.NEXT_DOCUMENT }
}

export function previousDocument(): PreviousDocument {
  return {
    type: DocViewerActions.PREVIOUS_DOCUMENT,
  }
}

export function updateCurrentDocument(document: DocViewerDocument): UpdateCurrentDocument {
  return {
    type: DocViewerActions.UPDATE_CURRENT_DOCUMENT,
    document,
  }
}

export function setRendererRect(rect: RendererRect): SetRendererRect {
  return {
    type: DocViewerActions.SET_RENDERER_RECT,
    rect,
  }
}

export function setMainConfig(config: DocViewerConfig): SetMainConfig {
  return {
    type: DocViewerActions.SET_MAIN_CONFIG,
    config,
  }
}
