import React from 'react'

import styled from 'styled-components'

function BaseEditIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
    >
      <path
        d="M19.6242 2.99604C20.9515 1.66799 23.1055 1.66799 24.4329 2.99604L25.0045 3.56851C26.3318 4.8967 26.3318 7.05006 25.0045 8.37731L15.6893 17.6964C15.2784 18.1026 14.7729 18.4002 14.2155 18.5608L9.49181 19.9116C9.09502 20.025 8.66988 19.9116 8.37701 19.581C8.08886 19.3307 7.9755 18.9056 8.08886 18.5088L9.43985 13.7855C9.60045 13.2282 9.89805 12.7228 10.3043 12.3118L19.6242 2.99604ZM22.7891 4.59913C22.3875 4.15656 21.6695 4.15656 21.2255 4.59913L19.8509 5.97315L22.0285 8.15059L23.4031 6.7336C23.8472 6.33212 23.8472 5.61417 23.4031 5.1716L22.7891 4.59913ZM11.6222 14.409L10.8286 17.1721L13.592 16.3786C13.7809 16.3267 13.9463 16.2275 14.0833 16.0905L20.4272 9.7518L18.2496 7.57435L11.9103 13.9178C11.7734 14.0547 11.6742 14.2201 11.6222 14.409ZM11.4474 4.83955C12.0757 4.83955 12.5811 5.34731 12.5811 5.97315C12.5811 6.60135 12.0757 7.10674 11.4474 7.10674H6.15687C5.1134 7.10674 4.26738 7.95221 4.26738 8.99607V21.8435C4.26738 22.8873 5.1134 23.7328 6.15687 23.7328H19.0054C20.0493 23.7328 20.8948 22.8873 20.8948 21.8435V16.5534C20.8948 15.9252 21.4003 15.4198 22.0285 15.4198C22.6568 15.4198 23.1622 15.9252 23.1622 16.5534V21.8435C23.1622 24.139 21.3011 26 19.0054 26H6.15687C3.86114 26 2 24.139 2 21.8435V8.99607C2 6.70054 3.86114 4.83955 6.15687 4.83955H11.4474Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const EditIcon = styled(BaseEditIcon)``
