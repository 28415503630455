import __orderBy from 'lodash/orderBy'
import __partition from 'lodash/partition'

import { GroupedOptions, LedgerNumberSelectOptionValue, LedgerNumberSuggestion, SelectableOption } from './types'

function orderSelectOptions(options: LedgerNumberSelectOptionValue[]) {
  return __orderBy(options, item => item.data.code, 'asc')
}

function compareOption(inputValue: string, option: LedgerNumberSelectOptionValue) {
  const candidate = inputValue.toLowerCase()
  const optionValue = [option.data.code, option.data.name].join(' ').toLowerCase()
  return optionValue === candidate
}

function groupedCompareOptionGuard(option: LedgerNumberSelectOptionValue | GroupedOptions): option is GroupedOptions {
  return (option as GroupedOptions).options !== undefined
}

function groupedCompareOption(inputValue: string, option: LedgerNumberSelectOptionValue | GroupedOptions): boolean {
  // { label: String, options: Array}
  if (groupedCompareOptionGuard(option)) {
    return option.options.some(opt => compareOption(inputValue, opt))
  } else {
    return compareOption(inputValue, option)
  }
}

export function getValueComponents(inputValue: string): Omit<LedgerNumber, 'id'> {
  const [code, ...texts] = inputValue.split(' ')
  const name = texts.join(' ') || null
  return {
    code,
    name,
  }
}

function createOption(option: LedgerNumberSuggestion) {
  return {
    label: String(option.code),
    value: String(option.code),
    data: option,
  }
}

/**
 * Helper function to create select value and options from form value and options, recommendations and suggestion
 * @param {LedgerNumber[]} props.options - original options list from backend
 * @param {string} props.optionsLabel - label text for selectable options group
 * @param {number[]} props.recommendations - list of recommended ledger_number IDs
 * @param {string} props.recommendationsLabel - label text for recommended options group
 * @param {LedgerNumberSuggestion} props.suggestion - (optional) suggested ledger_number option
 * @param {Nullable<LedgerNumber>} props.value - form value of the select input
 * @returns selectValue: value(option) and selectOptions: options list for select
 */
export function generateSelectProps({
  options,
  optionsLabel,
  recommendations,
  recommendationsLabel,
  suggestion,
  value,
}: {
  options: LedgerNumber[]
  optionsLabel: string
  recommendations: number[]
  recommendationsLabel: string
  suggestion?: LedgerNumberSuggestion
  value: Nullable<LedgerNumber>
}) {
  // map backend data into select options
  const selectOptions: LedgerNumberSelectOptionValue[] = options.map(createOption)
  let filteredOptions = selectOptions
  let suggestionOption: LedgerNumberSelectOptionValue | undefined

  if (suggestion) {
    // find suggestion in options list

    suggestionOption = selectOptions.find(
      option => option.data.code === suggestion.code && option.data.name === suggestion.name
    )
    if (suggestionOption) {
      // remove duplication from pure options
      filteredOptions = selectOptions.filter(function (option) {
        return option.data.id !== suggestionOption?.data.id
      })
    }
  }

  // set select input value from form value
  let selectValue: Nullable<LedgerNumberSelectOptionValue> = null
  if (value) {
    selectValue = createOption(value)
  }

  // separate options and recommendations
  const [recommendedOptions, existingOptions] = __partition(filteredOptions, function (option) {
    return option.data.id && recommendations.includes(option.data.id)
  })

  // push suggestion to recommendations when needed
  if (suggestion) {
    if (suggestionOption) {
      recommendedOptions.push(suggestionOption)
    } else {
      recommendedOptions.push(createOption(suggestion))
    }
  }

  // create grouped options for react-select when recommendations list is not empty
  let selectableOptions: SelectableOption[] = []
  if (recommendedOptions.length > 0) {
    selectableOptions = [
      {
        label: recommendationsLabel,
        options: orderSelectOptions(recommendedOptions),
      },
      {
        label: optionsLabel,
        options: orderSelectOptions(existingOptions),
      },
    ]
  } else {
    selectableOptions = orderSelectOptions(existingOptions)
  }

  return { selectValue, selectOptions: selectableOptions }
}

/**
 * Helper function to control "create" state in react-select
 * @param {string} inputValue - raw input value
 * @param {LedgerNumberSelectOptionValue[]} selectValue - current value of the select (list of selected options)
 * @param {Readonly<SelectableOption[]>} selectOptions - selectable options list of the select
 * @returns - true when entered value is not exists in options, otherwise returns false
 */
export function isValidNewOption(
  inputValue: string,
  selectValue: LedgerNumberSelectOptionValue[],
  selectOptions: Readonly<SelectableOption[]>,
  isPaidThroughLedgerNumber = false
) {
  // returns true when
  // inputValue is not empty or
  // inputValue length is too short (minimum 2)
  // if paidThroughLedgerNumber is true, inputValue must start with '38'
  // selectValue does not match with entered inputValue or
  // select has no options what matches the inputValue
  return !(
    !inputValue ||
    inputValue.length < 2 ||
    (isPaidThroughLedgerNumber && !inputValue.startsWith('38')) ||
    selectValue.some(function (option) {
      return compareOption(inputValue, option)
    }) ||
    selectOptions.some(function (option) {
      return groupedCompareOption(inputValue, option)
    })
  )
}

// react-select type is wrong: optionLabel must be string
export function getNewOptionData(inputValue: string, optionLabel: React.ReactNode): LedgerNumberSelectOptionValue {
  const data = getValueComponents(inputValue)
  return {
    value: String(data.code),
    label: String(optionLabel), // cast it to string to match acceptable return type
    data: { ...data, isNew: true },
  }
}
