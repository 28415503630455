import { hasIntegration } from '@helpers'

import { PartnerData, PartnerDialogData } from './types'

interface InitialState {
  attachmentCount: number
  data: Nullable<PartnerData>
  error: Nullable<string>
  isEditDisabled: boolean
  loading: boolean
}

export const initialState: InitialState = {
  attachmentCount: 0,
  data: null,
  error: null,
  isEditDisabled: false,
  loading: true,
}

type Action =
  | { type: 'request' }
  | { type: 'success'; payload: PartnerDialogData }
  | { type: 'failure'; payload: string }
  | { type: 'attachment-increase' }
  | { type: 'attachment-decrease' }

export function reducer(state: InitialState, action: Action) {
  switch (action.type) {
    case 'request':
      return initialState
    case 'success': {
      const { attachment_count, ...data } = action.payload
      return {
        ...state,
        loading: false,
        isEditDisabled: hasIntegration(action.payload),
        data,
        attachmentCount: attachment_count,
      }
    }
    case 'failure':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case 'attachment-increase':
      return {
        ...state,
        attachmentCount: state.attachmentCount + 1,
      }
    case 'attachment-decrease':
      return {
        ...state,
        attachmentCount: state.attachmentCount - 1,
      }
    default:
      return state
  }
}
