import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  'LOAD_DATA',
  'SAVE_DATA',
  '!UPDATE_INVOICE_PLANNED_PAYMENT_DATE',
  '!UPDATE_INCOME_PLANNED_PAYMENT_DATE',
  '!LOAD_INVOICES',
  '!LOAD_PREDICTED_INVOICES',
  '!REMOVE_PREDICTED_INVOICE',
  '!LOAD_PREDICTED_INCOMES',
  '!START_LIQUIDITY_INCOMES_PROCESS',
  '!ABORT_LIQUIDITY_INCOMES_PROCESS',
  '!LOAD_PREDICTED_WAGES',
  '!REMOVE_PREDICTED_SALARY',
  '!CREATE_MONTHLY_SALARY',
  '!UPDATE_MONTHLY_SALARY',
  '!REMOVE_MONTHLY_SALARY',
  '!LOAD_TAXES',
  '!REMOVE_PREDICTED_TAX',
  '!CREATE_MONTHLY_TAX', // not used yet
  '!UPDATE_MONTHLY_TAX', // not used yet
  '!REMOVE_MONTHLY_TAX', // not used yet
  '!UPDATE_TAX',
] as const

export default serviceActionsGenerator<typeof actionTypes>('liquidity', actionTypes)
