import styled from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { OldIconButton } from '@components/ui/Buttons'
import { TableRow } from '@components/ui/TableElements'
import OverflowTextWithTooltip from '@oldComponents/OverflowTextWithTooltip'

import { COPY_AND_PASTE_RIGHT_PADDING } from './constants'

export const TableBodyRow = styled(TableRow)`
  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  }
`

interface ExpandIconButtonProps {
  $expanded: boolean
}

export const ExpandIconButton = styled(OldIconButton)<ExpandIconButtonProps>`
  pointer-events: all;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.gray[110]};

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${({ theme }) => applyOpacity(theme.colors.gray[110], 70)};
  }

  svg {
    transition: all 250ms ease;
    transform: rotate(${({ $expanded }) => ($expanded ? 180 : 0)}deg);
    width: 16px;
    height: 16px;
  }
`

export const NameOverflowTextWithTooltip = styled(OverflowTextWithTooltip)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-weight: 700;
`

export const RightAlignedCellHeaderWithCopy = styled.span`
  padding-right: ${COPY_AND_PASTE_RIGHT_PADDING}px;
`
