import React from 'react'

import { IntercomProvider } from 'react-use-intercom'

import { useAssertContext } from '@hooks'

function CustomIntercomProvider({
  children,
  onUnreadCountChange,
}: {
  children: React.ReactChild | React.ReactChild[]
  onUnreadCountChange: (count: number) => void
}) {
  if (!window.intercomAppId) {
    if (process.env.NODE_ENV !== 'test') {
      console.warn("Unable to render IntercomProvider because Intercom's appId is not provided")
    }
    return <>{children}</>
  }
  return (
    <IntercomProvider
      appId={window.intercomAppId}
      apiBase="https://api-iam.intercom.io"
      onUnreadCountChange={onUnreadCountChange}
    >
      {children}
    </IntercomProvider>
  )
}

const QuickIntercomContext = React.createContext({ unreadMessagesCount: 0 })

QuickIntercomContext.displayName = 'QuickIntercomContext'

export function QuickIntercomProvider({ children }: { children: React.ReactChild | React.ReactChild[] }) {
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0)

  const onUnreadCountChange = React.useCallback((amount: number) => {
    setUnreadMessagesCount(amount)
  }, [])

  return (
    <CustomIntercomProvider onUnreadCountChange={onUnreadCountChange}>
      <QuickIntercomContext.Provider value={{ unreadMessagesCount }}>{children}</QuickIntercomContext.Provider>
    </CustomIntercomProvider>
  )
}

export function useIntercomUnreadMessagesCount() {
  return useAssertContext(QuickIntercomContext).unreadMessagesCount
}
