import { defineMessages } from 'react-intl'

export const braintreeTokenizeErrors = defineMessages({
  fieldsEmpty: {
    id: 'Braintree.hostedFields.errors.fieldsEmpty',
    defaultMessage: 'All fields are empty! Please fill out the form.',
  },
  fieldsInvalid: {
    id: 'Braintree.hostedFields.errors.fieldsInvalid',
    defaultMessage: 'Some fields are invalid.',
  },
  tokenizationFailOnDuplicate: {
    id: 'Braintree.hostedFields.errors.tokenizationFailOnDuplicate',
    defaultMessage: 'This payment method already exists in your vault.',
  },
  cvvVerifaicationFailed: {
    id: 'Braintree.hostedFields.errors.cvvVerifaicationFailed',
    defaultMessage: 'CVV did not pass verification.',
  },
  tokenizationFailed: {
    id: 'Braintree.hostedFields.errors.tokenizationFailed',
    defaultMessage: 'Tokenization failed server side. Is the card valid?',
  },
  tokenizationNetworkError: {
    id: 'Braintree.hostedFields.errors.tokenizationNetworkError',
    defaultMessage: 'Network error occurred when tokenizing.',
  },
  createPaymentMethodError: {
    id: 'Braintree.hostedFields.errors.createPaymentMethodError',
    defaultMessage: 'Processing payment method failed on server side.',
  },
})
export const braintree3DSecureErrors = defineMessages({
  authenticateFailed: {
    id: 'Braintree.3DSecure.errors.authenticateFailed',
    defaultMessage: 'Failed No-Challenge Authentication - Cardholder enrolled, authentication unsuccessful.',
  },
  authenticateRejected: {
    id: 'Braintree.3DSecure.errors.authenticateRejected',
    defaultMessage: 'Rejected No-Challenge Authentication by the Issuer - Authentication unsuccessful.',
  },
  authenticateUnavailable: {
    id: 'Braintree.3DSecure.errors.authenticateUnavailable',
    defaultMessage: 'Authentication Not Available on Lookup - Authentication unavailable for this transaction.',
  },
  unableToAuthenticate: {
    id: 'Braintree.3DSecure.errors.unableToAuthenticate',
    defaultMessage: 'Unavailable No-Challenge Authentication from the Issuer - Authentication unavailable.',
  },
  lookupError: {
    id: 'Braintree.3DSecure.errors.lookupError',
    defaultMessage: 'Error on Lookup - An error occurred while attempting to lookup enrollment.',
  },
  lookupBypassed: {
    id: 'Braintree.3DSecure.errors.lookupBypassed',
    defaultMessage: 'Bypassed Authentication - An error occurred while attempting to lookup enrollment.',
  },
  challengeRequired: {
    id: 'Braintree.3DSecure.errors.challengeRequired',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Successful Challenge Authentication with user cancel / Failed Challenge Authentication / Challenge Authentication is Unavailable', // TODO: multiple state
  },
  SDKError: {
    id: 'Braintree.3DSecure.errors.SDKError',
    defaultMessage: 'A general error has occurred with Cardinal. See description for more information.',
  },
  verificationDefault: {
    id: 'Braintree.3DSecure.errors.verificationDefault',
    defaultMessage: 'Something bad happened during verification!',
  },
  threeDSLookupError: {
    id: 'Braintree.3DSecure.errors.threeDSLookupError',
    defaultMessage: 'Something went wrong during the 3D Secure lookup',
  },
  lookupValidationError: {
    id: 'Braintree.3DSecure.errors.lookupValidationError',
    defaultMessage: 'The data passed in `verifyCard` did not pass validation checks.',
  },
})

export const getBraintreeError = error => {
  console.group('getBraintreeError')
  console.log('code > : ', error.code)
  console.log(error)
  console.groupEnd()
  switch (error.code) {
    // built in error codes
    case 'HOSTED_FIELDS_FIELDS_EMPTY':
      return braintreeTokenizeErrors.fieldsEmpty
    case 'HOSTED_FIELDS_FIELDS_INVALID':
      return braintreeTokenizeErrors.fieldsInvalid
    case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
      return braintreeTokenizeErrors.tokenizationFailOnDuplicate
    case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
      return braintreeTokenizeErrors.cvvVerifaicationFailed
    case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
      return braintreeTokenizeErrors.tokenizationFailed
    case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
      return braintreeTokenizeErrors.tokenizationNetworkError

    // CREATE PAYMENT METHOD NONCE ON SERVER SIDE
    case 'CREATE_PAYMENT_METHOD_TOKEN_FAILURE':
      return braintreeTokenizeErrors.createPaymentMethodError
    // custom errors
    case '3DSecure':
      switch (error.status) {
        case 'authenticate_failed':
          return braintree3DSecureErrors.authenticateFailed
        case 'authenticate_unable_to_authenticate':
          return braintree3DSecureErrors.unableToAuthenticate
        case 'authenticate_rejected':
          return braintree3DSecureErrors.authenticateRejected
        case 'authentication_unavailable':
          return braintree3DSecureErrors.authenticateUnavailable
        case 'lookup_error':
          return braintree3DSecureErrors.lookupError
        case 'lookup_bypassed':
          return braintree3DSecureErrors.lookupBypassed
        case 'challenge_required':
          // TODO multiple start point
          // Successful Challenge Authentication - cancel popup
          // Failed Challenge Authentication
          // Challenge Authentication is Unavailable
          return braintree3DSecureErrors.challengeRequired
        default:
          return braintree3DSecureErrors.verificationDefault
      }
    case 'THREEDS_CARDINAL_SDK_ERROR':
      return braintree3DSecureErrors.SDKError
    case 'THREEDS_LOOKUP_ERROR':
      return braintree3DSecureErrors.threeDSLookupError
    case 'THREEDS_LOOKUP_VALIDATION_ERROR':
      return braintree3DSecureErrors.lookupValidationError

    // default error
    default:
      // NOTE BraintreeErrorMessage component will return error.message
      return null
  }
}

// cardholder name validator function
export const validateCardholder = value => {
  if (!value) {
    // required
    return false
  }
  const trimmed = String(value).trim()
  // minLength 4
  return trimmed.length >= 4
}
