import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { ContentWrapperDiv, styles } from './styles'

const useStyles = makeStyles(styles)

interface SettingsBlockProps {
  button?: React.ReactNode
  children: React.ReactNode
  className?: string
  heading: StringOrMessage
  id?: string
  withWrapper?: boolean
}

// TODO - reusable element for settings pages - later move to styled components and V2 design
export const SettingsBlock = React.forwardRef<HTMLDivElement, SettingsBlockProps>(
  ({ button, children, className, heading, id, withWrapper }, ref) => {
    const classes = useStyles()

    const content = withWrapper ? (
      <Grid item xs={12}>
        <ContentWrapperDiv>{children}</ContentWrapperDiv>
      </Grid>
    ) : (
      children
    )

    return (
      <Paper className={cx(classes.panelRoot, className)} id={id} ref={ref}>
        <Grid container className={classes.panelHeader} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" component="h3">
              {heading}
            </Typography>
          </Grid>
          {button && <Grid item>{button}</Grid>}
        </Grid>
        <Grid container className={classes.panelBody}>
          {content}
        </Grid>
      </Paper>
    )
  }
)

SettingsBlock.propTypes = {
  button: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired,
  id: PropTypes.string,
  withWrapper: PropTypes.bool,
}

SettingsBlock.displayName = 'SettingsBlock'
