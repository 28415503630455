import React from 'react'

import { useIntercom } from 'react-use-intercom'

import { CustomLink, IconWrapperSpan, LinkProps } from '@components/ui/Links/ExternalLink'
import { RightArrowLineIcon } from '@components/ui/svgIcons'
import { Typography } from '@components/ui/Typography'

import { useIntercomProps } from './useIntercomProps'

function IntercomLink(props: Omit<LinkProps, 'href' | 'rel' | 'target' | 'text'>) {
  const { show } = useIntercom()

  function handleClick() {
    show()
  }

  return <CustomLink {...props} onClick={handleClick} />
}

export function IntercomExternalLink({
  className,
  iconSize = 16,
  text,
  typographySize = 'inherit',
  variant = 'primary',
}: Omit<LinkProps, 'href' | 'rel' | 'target'>) {
  const Component = window.intercomAppId ? IntercomLink : CustomLink
  const props = useIntercomProps(className)
  return (
    <Component variant={variant} {...props}>
      <Typography tag="span" size={typographySize}>
        {text}
      </Typography>
      <IconWrapperSpan>
        <RightArrowLineIcon size={iconSize} />
      </IconWrapperSpan>
    </Component>
  )
}
