import React from 'react'

import styled from 'styled-components'

function BaseTagIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6819 11.3454c0-.8699.7043-1.5727 1.5727-1.5727.87 0 1.5728.7028 1.5728 1.5727 0 .87-.7028 1.5728-1.5728 1.5728-.8684 0-1.5727-.7028-1.5727-1.5728Zm5.7749-5.5046c.8355 0 1.6318.3313 2.2215.9215l8.6502 8.6477c1.2287 1.2287 1.2287 3.2242 0 4.4529l-6.5564 6.5564c-1.2288 1.2287-3.2242 1.2287-4.4529 0l-8.6477-8.6501a3.1362 3.1362 0 0 1-.9215-2.2216V8.2c0-1.303 1.0562-2.3592 2.3591-2.3592h7.3477ZM8.3396 16.103l8.6507 8.6502c.3047.3096.806.3096 1.1107 0l6.5613-6.5614c.3097-.3047.3097-.806 0-1.1107l-8.6501-8.6506a.7888.7888 0 0 0-.5554-.2306H8.1091v7.3477c0 .2065.083.408.2305.5554Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const TagIcon = styled(BaseTagIcon)``
