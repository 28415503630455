import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, PopperProps } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { IconButton } from '@components/ui'
import { DeleteIcon, LightTooltip as Tooltip, PaymentDownloadIcon } from '@oldComponents/ui'
import { DeleteFunctionVisibilityControl } from '@oldComponents/VisibilityControls'

import { EditButtonMessage } from '@messages'

const ActionIconButton = styled(IconButton)`
  background-color: inherit;
  color: ${({ theme }) => theme.colors.gray[100]};
  font-size: 20px;
  width: 20px;
  height: 20px;
  padding: 0;

  --css-hover-background-color: transparent;
  --css-active-background-color: transparent;
  --css-focus-border-color: transparent;

  &:active:not(:disabled),
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${({ theme }) => theme.colors.blue[40]};
  }
`

const WrapperDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 10px;
`

const POPPER_PROPS: Partial<PopperProps> = { disablePortal: true, placement: 'top' }

type DownloadHandler = (event: React.MouseEvent<HTMLButtonElement>) => void

export interface TableRowControlsProps {
  className?: string
  deleteDisabled?: boolean
  downloading?: boolean // allowing for external state, for cases when the download is interrupted by an external modal
  downloadButtonProps?: Record<string, unknown>
  loading?: boolean
  onDelete?: () => unknown
  onDeleteTooltipText?: React.ReactChild
  onDownload?: AsyncFunction<React.MouseEvent<HTMLButtonElement> | undefined, unknown> | DownloadHandler
  onDownloadTooltipText?: React.ReactChild
  onEdit?: () => unknown | void
  onEditTooltipText?: React.ReactChild
}

/**
 * Component to be used as actions container on any table's rows.
 *
 * Please check default tooltip texts before providing a new one!
 *
 * @param {TableRowControlsProps} {
 *   className,
 *   onDelete,
 *   onDeleteTooltipText,
 *   onEdit,
 *   onEditTooltipText,
 * }
 */
function PureTableRowControls({
  className,
  deleteDisabled = false,
  downloading = false,
  downloadButtonProps = {},
  loading = false,
  onDelete,
  onDeleteTooltipText,
  onDownload,
  onDownloadTooltipText,
  onEdit,
  onEditTooltipText,
}: TableRowControlsProps) {
  const [isDownloading, setDownloading] = React.useState(downloading)

  async function handleDownload(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    event.preventDefault()
    setDownloading(true)
    await onDownload?.(event)
    setDownloading(false)
  }

  function handleDelete(event: React.MouseEvent<HTMLButtonElement>) {
    // prevent event bubbling to avoid row click event
    event.stopPropagation()
    event.preventDefault()
    onDelete?.()
  }

  function handleEdit() {
    onEdit?.()
  }

  return (
    <WrapperDiv className={className}>
      {onDownload && (
        <Tooltip
          title={
            onDownloadTooltipText || (
              <FormattedMessage id="tableRowControls.defaults.download" defaultMessage="Letöltés" />
            )
          }
          PopperProps={POPPER_PROPS}
        >
          <div>
            <ActionIconButton
              onClick={handleDownload}
              disabled={isDownloading || downloading}
              data-testid="payment-export-button"
              {...downloadButtonProps}
            >
              {isDownloading || downloading ? (
                <CircularProgress color="inherit" size={14} />
              ) : (
                <PaymentDownloadIcon size={14} />
              )}
            </ActionIconButton>
          </div>
        </Tooltip>
      )}
      {onDelete && (
        <DeleteFunctionVisibilityControl>
          <Tooltip
            title={
              onDeleteTooltipText || <FormattedMessage id="tableRowControls.defaults.delete" defaultMessage="Törlés" />
            }
            PopperProps={POPPER_PROPS}
          >
            <div>
              <ActionIconButton onClick={handleDelete} disabled={deleteDisabled} data-testid="payment-delete-button">
                <DeleteIcon size={20} />
              </ActionIconButton>
            </div>
          </Tooltip>
        </DeleteFunctionVisibilityControl>
      )}
      {onEdit && (
        <Tooltip title={onEditTooltipText || EditButtonMessage} PopperProps={POPPER_PROPS}>
          <ActionIconButton onClick={handleEdit} data-testid="payment-edit-button" disabled={loading}>
            {loading ? <CircularProgress color="inherit" size={14} /> : <EditOutlined fontSize="inherit" />}
          </ActionIconButton>
        </Tooltip>
      )}
    </WrapperDiv>
  )
}

PureTableRowControls.propTypes = {
  className: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  downloading: PropTypes.bool,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleteTooltipText: PropTypes.node,
  onDownload: PropTypes.func,
  onDownloadTooltipText: PropTypes.node,
  onEdit: PropTypes.func,
  onEditTooltipText: PropTypes.node,
}

export const TableRowControls = styled(PureTableRowControls)`
  opacity: 0;
  transition: opacity 250ms ease;

  tr:hover &,
  tr:focus-within & {
    opacity: 1;
  }
`
