import React from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { getRouteUrl, parseIfExists } from '@helpers'

import { DetailsPageRouteKeys, RouteKeys } from '@constants'

interface DetailsPagingProps extends PagePaginationProps {
  company: number
  list: Array<{ id: ItemIdType }>
  paramId: string | undefined
  routeKey: DetailsPageRouteKeys
}

export function useDetailsPaging({
  company,
  fetchByPaging,
  list,
  nextPageUrl,
  paramId,
  prevPageUrl,
  routeKey,
}: DetailsPagingProps) {
  const navigate = useNavigate()
  const location = useLocation<PreviousPathHistoryState>()
  const pagingCount = list.length

  let displayPaging = false
  let itemIndex = -1

  const hasId = !!paramId
  const itemsNum = list.length
  const itemId = parseIfExists(paramId)

  if (hasId) {
    itemIndex = list.findIndex(x => x.id === itemId)
    displayPaging = pagingCount > 0 && itemIndex > -1
  }

  const disabledPrev = itemIndex === 0 && !prevPageUrl
  const disabledNext = itemIndex + 1 === itemsNum && !nextPageUrl

  const callNextOrPreviousDataFetch = React.useCallback(
    async (url: string, isNext: boolean) => {
      const newId = await fetchByPaging({ url, isNext })
      navigate(`${getRouteUrl(routeKey as unknown as RouteKeys, company)}/${newId}`, { state: location.state })
    },
    [company, fetchByPaging, location.state, navigate, routeKey]
  )

  const onPrev = React.useCallback(() => {
    if (itemIndex > 0) {
      navigate(`${getRouteUrl(routeKey as unknown as RouteKeys, company)}/${list[itemIndex - 1].id}`, {
        state: location.state,
      })
    } else if (prevPageUrl) {
      callNextOrPreviousDataFetch(prevPageUrl, false)
    }
  }, [callNextOrPreviousDataFetch, company, itemIndex, list, location.state, navigate, prevPageUrl, routeKey])

  const onNext = React.useCallback(() => {
    const itemsNum = list.length
    if (itemIndex < itemsNum - 1) {
      navigate(`${getRouteUrl(routeKey as unknown as RouteKeys, company)}/${list[itemIndex + 1].id}`, {
        state: location.state,
      })
    } else if (nextPageUrl) {
      callNextOrPreviousDataFetch(nextPageUrl, true)
    }
  }, [callNextOrPreviousDataFetch, company, itemIndex, list, location.state, navigate, nextPageUrl, routeKey])

  return {
    disabledNext,
    disabledPrev,
    displayPaging,
    onNext,
    onPrev,
  }
}
