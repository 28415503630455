import React from 'react'
import { bool } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

const WarningBadge = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    alignItems: 'center',
  },
  text: {
    display: 'inline-block',
    fontWeight: 700,
    // marginLeft: 3,
    marginRight: 2,
    fontSize: 14,
  },
}))(({ classes, isDuplicated, isNav }) => (
  <div className={cx('missing-warning', classes.root)}>
    <span className={classes.text}>
      {isDuplicated ? (
        isNav ? (
          <FormattedMessage id="badge.navDuplicated" defaultMessage="NAV duplikáció" />
        ) : (
          <FormattedMessage id="badge.duplicated" defaultMessage="Duplikáció" />
        )
      ) : (
        <FormattedMessage id="badge.deficient.data" defaultMessage="Adathiányos" />
      )}
    </span>
    {isDuplicated && <ErrorIcon style={{ fontSize: 14 }} />}
  </div>
))

WarningBadge.propTypes = { isDuplicated: bool, isNav: bool }
WarningBadge.defaultProps = { isDuplicated: false, isNav: false }

export const DeficientBadge = () => <WarningBadge />
export const DuplicatedBadge = ({ isNav }) => <WarningBadge isDuplicated isNav={isNav} />
DuplicatedBadge.defaultProps = { isNav: false }
DuplicatedBadge.propTypes = { isNav: bool }
