import React from 'react'
import PropTypes from 'prop-types'

export default function PaymentIcon({ color = 'currentColor', size = 28, className }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(size / 28) * 20}
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.667 0H3.33366C1.85366 0 0.680326 1.1125 0.680326 2.5L0.666992 17.5C0.666992 18.8875 1.85366 20 3.33366 20H24.667C26.147 20 27.3337 18.8875 27.3337 17.5V2.5C27.3337 1.1125 26.147 0 24.667 0ZM24.667 17.5H3.33366V10H24.667V17.5ZM24.667 5H3.33366V2.5H24.667V5Z" // eslint-disable-line max-len
        fill={color}
      />
    </svg>
  )
}

PaymentIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
}
