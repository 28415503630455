import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

function BaseDiamondRegularIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M7.809 2.392 2.392 7.809a.269.269 0 0 0-.08.191c0 .071.028.142.08.191l5.417 5.417c.052.052.12.08.191.08a.264.264 0 0 0 .191-.08l5.417-5.417a.269.269 0 0 0 .08-.191.264.264 0 0 0-.08-.191L8.191 2.392A.269.269 0 0 0 8 2.312a.264.264 0 0 0-.191.08ZM1.465 6.879l5.414-5.414a1.583 1.583 0 0 1 2.242 0l5.414 5.414a1.583 1.583 0 0 1 0 2.242l-5.414 5.414a1.583 1.583 0 0 1-2.242 0L1.465 9.121a1.583 1.583 0 0 1 0-2.242Z"
      />
    </svg>
  )
}

BaseDiamondRegularIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}

export const DiamondRegularIcon = styled(BaseDiamondRegularIcon)``
