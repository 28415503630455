import React from 'react'

import { FormattedMessage } from 'react-intl'

import { PureOriginIcon, Typography } from '@components/ui'

import { getProcessedSourceName } from './helper'

import { HeadingTypography, ReasonBlockWrapperDiv } from './styles'

interface ProcessedReasonBlockProps {
  origin: InvoiceOrigin | undefined
  readOnly: boolean
}

export function ProcessedReasonBlock({ origin, readOnly }: ProcessedReasonBlockProps) {
  const source = getProcessedSourceName(origin)

  return (
    <ReasonBlockWrapperDiv>
      <HeadingTypography>
        <PureOriginIcon origin={origin} />
        <FormattedMessage id="invoice.disabledEdit.heading" defaultMessage="Számla feldolgozva" />
      </HeadingTypography>
      {!readOnly && (
        <Typography align="center">
          {source ? (
            <FormattedMessage
              id="invoice.disabledEdit.integratedReasonText"
              defaultMessage="A könyvelőd már feldolgozta ezt a {source} forrásból beérkező számlát. A számla adatain már csak a kiemelt mezőket tudod szerkeszteni."
              values={{
                source,
              }}
            />
          ) : (
            <FormattedMessage
              id="invoice.disabledEdit.reasonText"
              defaultMessage="A könyvelőd már feldolgozta ezt a számlát. A számla adatain már csak a kiemelt mezőket tudod szerkeszteni."
            />
          )}
        </Typography>
      )}
    </ReasonBlockWrapperDiv>
  )
}
