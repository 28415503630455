import { defineMessages } from 'react-intl'

const messages = defineMessages({
  expanded: {
    id: 'buttons.collapseToggle.expanded',
    defaultMessage: 'Grafikonok elrejtése',
  },
  collapsed: {
    id: 'buttons.collapseToggle.collapsed',
    defaultMessage: 'Grafikonok megjelenítése',
  },
})

export default messages
