import { defineMessages } from 'react-intl'

export const globalMessages = defineMessages({
  editButtonTitle: {
    id: 'buttons.title.edit',
    defaultMessage: 'Szerkesztés',
  },
  deleteButtonTitle: {
    id: 'buttons.title.delete',
    defaultMessage: 'Törlés',
  },
  processMaxTriesError: {
    id: 'bacgroundJobProcess.errors.maxTries',
    defaultMessage: 'A folyamat megszakítva, mert elérte a maximális újrapróbálkozási korlátot',
  },
  //* dialog
  addButtonText: {
    id: 'dialogs.buttons.add',
    defaultMessage: 'Hozzáadás',
  },
  saveButtonText: {
    id: 'dialogs.buttons.save',
    defaultMessage: 'Mentés',
  },
  //* form
  dateFieldPlaceholder: {
    id: 'form.placeholder.dateField',
    defaultMessage: 'éééé-hh-nn',
  },
  optionYes: {
    id: 'form.options.yes',
    defaultMessage: 'Igen',
  },
  optionNo: {
    id: 'form.options.no',
    defaultMessage: 'Nem',
  },
  optionHU: {
    id: 'field.option.defaultLanguage.hu',
    defaultMessage: 'Magyar',
  },
  optionEN: {
    id: 'field.option.defaultLanguage.en',
    defaultMessage: 'Angol',
  },
  formLabelDefaultLanguage: {
    id: 'form.label.defaultLanguage',
    defaultMessage: 'Nyelv',
  },
  formLabelEmail: {
    id: 'form.label.email',
    defaultMessage: 'Email cím',
  },
  formLabelFirstName: {
    id: 'form.label.firstName',
    defaultMessage: 'Keresztnév',
  },
  formLabelLastName: {
    id: 'form.label.lastName',
    defaultMessage: 'Vezetéknév',
  },
  formLabelNewsletterText: {
    id: 'form.label.newsletterText',
    defaultMessage:
      'Elfogadom és hozzájárulok, hogy a program helyes működésével, frissítésekkel kapcsolatos tájékoztatókat kapjak. (Marketingcélú hírleveleket nem küldünk.)',
  },
  formLabelPassword: {
    id: 'form.label.password',
    defaultMessage: 'Jelszó',
  },
  formLabelRememberMe: {
    id: 'form.label.rememberMe',
    defaultMessage: 'Emlékezz rám egy hónapig',
  },
  formLabelTaxNumber: {
    id: 'form.label.taxNumber',
    defaultMessage: 'Adószám',
  },
  formLabelBusinessType: {
    id: 'form.label.businessType',
    defaultMessage: 'Vállalkozási forma',
  },
  formLabelIndustry: {
    id: 'form.label.industry',
    defaultMessage: 'Iparág',
  },
  formLabelDefaultCurrency: {
    id: 'form.label.defaultCurrency',
    defaultMessage: 'Pénznem',
  },
  formLabelCountry: {
    id: 'form.label.country',
    defaultMessage: 'Ország',
  },
  presentText: {
    id: 'generic.text.present',
    defaultMessage: 'jelen',
  },
  formLabelSzjaAmount: {
    id: 'form.label.szjaAmount',
    defaultMessage: 'SZJA összege',
  },
  formLabelSzochoAmount: {
    id: 'form.label.szochoAmount',
    defaultMessage: 'SZOCHO összege',
  },
  formLabelTbjAmount: {
    id: 'form.label.tbjAmount',
    defaultMessage: 'TBJ összege',
  },
  pettyCash: {
    id: 'forms.paidThrough.pettyCash',
    defaultMessage: 'Házipénztár',
  },
})
