import React from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import { defineMessages, useIntl } from 'react-intl'

import { inCaseSensitiveSearch } from '@helpers'

import { XmarkRegularIcon } from '@components/ui'

import { MenuItemWithNavigation } from './MenuItemWithNavigation'

import {
  ClearButton,
  NoResultTypography,
  ScrollableMenuWrapper,
  SearchGrid,
  SearchInput,
  SearchWrapper,
  StyledMenuList,
  StyledSearchIcon,
} from './styles'

const messages = defineMessages({
  noResults: {
    id: 'company.search.noResults',
    defaultMessage: 'Nincs találat',
  },
  placeholder: {
    id: 'company.search.placeholder',
    defaultMessage: 'Cégnév',
  },
})

const SEARCH_ITEM_LIMIT = 5

interface SearchableCompanyMenuProps {
  className?: string
  currentCompanyId: number | undefined
  data: CompaniesItem[]
  disabled: boolean
  onSelect: (company: CompaniesItem, isSettings?: boolean) => React.MouseEventHandler<HTMLAnchorElement>
}

export function SearchableCompanyMenu({
  className,
  currentCompanyId,
  data,
  disabled,
  onSelect,
}: SearchableCompanyMenuProps) {
  const { formatMessage } = useIntl()

  const [searchText, setSearchText] = React.useState('')

  function clearButtonHandler() {
    setSearchText('')
  }

  function searchInputOnChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }

  const showSearchInput = data.length > SEARCH_ITEM_LIMIT && !disabled
  const filteredData = data.filter(company => inCaseSensitiveSearch(company.name, searchText))

  return (
    <div className={className}>
      {showSearchInput && (
        <SearchWrapper>
          <SearchGrid>
            <StyledSearchIcon />
            <SearchInput
              disableUnderline
              endAdornment={
                searchText === '' ? null : (
                  <ClearButton onClick={clearButtonHandler}>
                    <XmarkRegularIcon />
                  </ClearButton>
                )
              }
              onChange={searchInputOnChangeHandler}
              placeholder={formatMessage(messages.placeholder)}
              value={searchText}
            />
          </SearchGrid>
        </SearchWrapper>
      )}
      <ScrollableMenuWrapper $shadowVisible={filteredData.length > SEARCH_ITEM_LIMIT}>
        <StyledMenuList>
          {filteredData.map(company => (
            <MenuItemWithNavigation
              company={company}
              disabled={disabled}
              isHighlighted={company.id === currentCompanyId}
              key={company.id}
              onSelect={onSelect}
            />
          ))}
        </StyledMenuList>

        {filteredData.length === 0 && searchText !== '' && (
          <Fade in>
            <NoResultTypography>{formatMessage(messages.noResults)}</NoResultTypography>
          </Fade>
        )}
      </ScrollableMenuWrapper>
    </div>
  )
}

SearchableCompanyMenu.propTypes = {
  className: PropTypes.string,
  currentCompanyId: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}
