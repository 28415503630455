import React from 'react'
import PropTypes from 'prop-types'

import {
  Cell,
  Column,
  Hooks,
  IdType,
  MetaBase,
  PluginHook,
  Row,
  SortingRule,
  TableCellProps,
  TableOptions,
  TableRowProps,
  useExpanded,
  useMountedLayoutEffect,
  useSortBy,
  useTable,
} from 'react-table'

import { NoDataCell, Table, TableBody, TableCell, TableRow, TableStyleVariant } from '@components/ui'

import TableHeadArea from '../TableHeadArea'
import { ExpandRowCell, SelectAllHeaderCell, SelectRowCell } from './elements'
import useControlledRowSelect from './useControlledRowSelect'

import { TableNoDataMessage } from '@messages'

const SELECTION_CELL_ID = 'selection'
const ROW_SELECT_PLUGINS = [
  useControlledRowSelect,
  <RowData extends object>(hooks: Hooks<RowData>) => {
    hooks.visibleColumns.push(columns => [
      {
        id: SELECTION_CELL_ID,
        Header: SelectAllHeaderCell,
        Cell: SelectRowCell,
        width: 40,
      },
      ...columns,
    ])
  },
]
const SORT_BY_PLUGINS = [useSortBy]
export const EXPANDER_CELL_ID = 'expander'
const EXPANDABLE_TABLE_PLUGINS = [
  useExpanded,
  <RowData extends object>(hooks: Hooks<RowData>) => {
    hooks.visibleColumns.push(columns => [
      ...columns,
      {
        id: EXPANDER_CELL_ID,
        Header: <>&nbsp;</>,
        Cell: ExpandRowCell,
        width: 50,
      },
    ])
  },
]

const DEFAULT_SORTING_STATE = {
  autoResetSortBy: false,
  disableMultiSort: true,
  disableSortRemove: true,
  manualSortBy: true,
}

const DEFAULT_SELECT_STATE = {
  autoResetSelectedRows: false,
}

export const DEFAULT_EXPAND_STATE = {
  autoResetExpanded: false, // keep expanded rows open
}

export type SimpleTableOnSelectChangeProp<RowData extends object> = (
  values: Partial<{ selectedRowIds: Record<IdType<RowData>, boolean>; isAllSelected: boolean }>
) => void

interface SimpleTableProps<RowData extends object> {
  columns: Column<RowData>[]
  customRowPropsGetter?: (row: RowData) => React.HTMLAttributes<HTMLTableRowElement>
  data: RowData[]
  isExpandable?: boolean
  layout?: React.CSSProperties['tableLayout']
  noDataMessage?: React.ReactNode
  onRowExpanded?: (payload: Row<RowData>['values']['rowData']) => void
  onSelectChange?: SimpleTableOnSelectChangeProp<RowData>
  onSortByChange?: (payload: SortingRule<RowData>[]) => void
  renderFooter?: (props: { colSpan: number }) => JSX.Element
  renderNotificationRow?: (props: { colSpan: number }) => JSX.Element
  useTablePlugins?: PluginHook<RowData>[]
  useTableProps?: Omit<TableOptions<RowData>, 'data' | 'columns'>
  variant?: TableStyleVariant
}

/**
 * A generic table.
 *
 * If you wish to enable backend sorting, just pass in a function as `onSortByChange` and the component will take care of the defaults.
 *
 * @template RowData - Type of the row data.
 * @param {SimpleTableProps<RowData>} {
 *   columns, - The columns of the table.
 *   customRowPropsGetter, - A function that returns custom row props. It gets the row data as an argument.
 *   data, - The data of the table.
 *   isExpandable, - Enable expandable plugin (optional)
 *   layout, - The layout style of the table.
 *   noDataMessage, - Optional content to display when data is empty.
 *   onRowExpanded, - Optional callback on row when isExpandable=True
 *   onSelectChange, - Optional callback to handle selection.
 *   onSortByChange, - Optional callback to handle sorting.
 *   renderFooter, - Optional render callback to render table-footer.
 *   renderNotificationRow, - Optional render callback for notification row.
 *   useTablePlugins, - Optional plugins to use for the table.
 *   useTableProps, - Optional props to use for the table.
 *   variant, - The variant style of the table.
 * }
 */
export default function SimpleTable<RowData extends object>({
  columns,
  customRowPropsGetter,
  data,
  isExpandable = false,
  layout,
  noDataMessage = TableNoDataMessage,
  onRowExpanded,
  onSelectChange,
  onSortByChange,
  renderFooter,
  renderNotificationRow,
  useTablePlugins = [],
  useTableProps,
  variant,
}: SimpleTableProps<RowData>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      ...(onSortByChange ? DEFAULT_SORTING_STATE : {}),
      ...(onSelectChange ? DEFAULT_SELECT_STATE : {}),
      ...(isExpandable ? DEFAULT_EXPAND_STATE : {}),
      onSelectChange,
      ...useTableProps,
    },
    ...(onSortByChange ? SORT_BY_PLUGINS : []),
    ...(isExpandable ? EXPANDABLE_TABLE_PLUGINS : []),
    ...(onSelectChange ? ROW_SELECT_PLUGINS : []),
    ...useTablePlugins
  )

  useMountedLayoutEffect(() => onSortByChange?.(sortBy), [sortBy])

  function enhancedRowGetter(props: Partial<TableRowProps>, meta: MetaBase<RowData> & { row: Row<RowData> }) {
    const customProps = customRowPropsGetter?.(meta.row.values as RowData)
    return { ...props, ...customProps }
  }

  function enhancedCellGetter(props: Partial<TableCellProps>, meta: MetaBase<RowData> & { cell: Cell<RowData> }) {
    const customProps = {
      align: meta.cell.column.align,
      className: meta.cell.column.className,
      style: { width: meta.cell.column.width },
    } as React.HTMLAttributes<HTMLTableCellElement>
    if (meta.cell.column.id === SELECTION_CELL_ID) {
      customProps.onClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
        event.stopPropagation()
      }
    }
    return { ...props, ...customProps }
  }

  return (
    <Table {...getTableProps()} layout={layout} variant={variant} selectable={Boolean(onSelectChange)}>
      <TableHeadArea headerGroups={headerGroups} />
      <TableBody {...getTableBodyProps()}>
        {renderNotificationRow?.({ colSpan: visibleColumns.length })}
        {rows.length ? (
          rows.map(row => {
            prepareRow(row)
            const { key: rowKey, ...rowProps } = row.getRowProps(enhancedRowGetter)
            return (
              <TableRow {...rowProps} key={rowKey} $isSubRow={row.depth > 0}>
                {row.cells.map(cell => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps(enhancedCellGetter)
                  return (
                    <TableCell
                      key={cellKey}
                      {...cellProps}
                      $hoverCursor={cell.column.id === SELECTION_CELL_ID ? 'default' : undefined}
                    >
                      {cell.render('Cell', cell.column.id === EXPANDER_CELL_ID ? { onRowExpanded } : undefined)}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })
        ) : (
          <TableRow>
            <NoDataCell colSpan={visibleColumns.length}>{noDataMessage}</NoDataCell>
          </TableRow>
        )}
      </TableBody>
      {renderFooter?.({ colSpan: visibleColumns.length })}
    </Table>
  )
}

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isExpandable: PropTypes.bool,
  layout: PropTypes.string,
  noDataMessage: PropTypes.node,
  onRowExpand: PropTypes.func,
  renderFooter: PropTypes.func,
  renderNotificationRow: PropTypes.func,
  useTablePlugins: PropTypes.array,
  useTableProps: PropTypes.object,
  variant: PropTypes.oneOf([
    'chartDetails',
    'default',
    'inner',
    'invoiceDetailsItems',
    'minimal',
    'minimalDetails',
    'paymentEditor',
  ]) as React.Validator<SimpleTableProps<object>['variant']>,
}
