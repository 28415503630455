import { isIntegrationInvoice, isNAVInvoice } from '@helpers'

type IncomeDetailsControlsProps<Data> = {
  isEditorUser: boolean
  isInvoiceProcessed?: (payload: Data) => boolean
}

type IncomeDetailsControls = {
  hasIntegration: boolean
  invoiceEditDisabled: boolean
  isEditDisabled: boolean
  isNavInvoice: boolean
  isNotEditable: boolean
  isProcessedInvoice: boolean
  readOnly: boolean
}

export function useIncomeDetailsControls<Data extends IncomeDetailsFrontendValues>(
  data: Data,
  { isEditorUser, isInvoiceProcessed }: IncomeDetailsControlsProps<Data>
): IncomeDetailsControls {
  const hasIntegration = isIntegrationInvoice(data)
  const invoiceEditDisabled = isInvoiceProcessed?.(data) ?? false
  const isEditDisabled = !isEditorUser || invoiceEditDisabled
  const isNavInvoice = isNAVInvoice(data)
  const readOnly = !isEditorUser
  return {
    hasIntegration,
    invoiceEditDisabled,
    isEditDisabled,
    isNavInvoice,
    isNotEditable: invoiceEditDisabled || hasIntegration,
    isProcessedInvoice: !readOnly && isEditDisabled,
    readOnly,
  }
}
