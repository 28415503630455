import React from 'react'

import Helmet from 'react-helmet'

import { APP_TITLE } from '@constants'

interface PageTitleProps {
  subtitle: string
}

export default function PageTitle({ subtitle }: PageTitleProps) {
  return <Helmet title={`${subtitle} | ${APP_TITLE}`} />
}
