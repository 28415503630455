import React from 'react'
import PropTypes from 'prop-types'

import { FormattedDate, FormattedMessage } from 'react-intl'
import { Column } from 'react-table'

import { useEditPayment } from '@contexts'

import { SimpleTable, TableRowControls } from '@components/tables'
import { AccountNumber, Currency } from '@components/ui'
import NotEditablePaymentTooltip from '@oldComponents/ui/NotEditablePaymentTooltip'

import { InvoicePaymentSource, PaymentSources } from './InvoicePaymentSource'

export type InvoicePayment = {
  account_number: string
  amount: Decimal
  created_by: PaymentSources
  currency_id: number
  date: string
  flow: PaymentFlowType
  has_restricted_transactions: boolean
  id: number
  payment_id: Nullable<number>
}

enum PaymentTableAccessors {
  ROW_DATA = 'rowData',
  SOURCE = 'created_by',
  DATE = 'date',
  ACCOUNT_NUMBER = 'account_number',
  AMOUNT = 'amount',
  ACTIONS = 'actions',
}

interface InvoicePaymentTableRow {
  [PaymentTableAccessors.ROW_DATA]: InvoicePayment
  [PaymentTableAccessors.SOURCE]: React.ReactChild
  [PaymentTableAccessors.DATE]: React.ReactChild
  [PaymentTableAccessors.ACCOUNT_NUMBER]: React.ReactChild
  [PaymentTableAccessors.AMOUNT]: React.ReactChild
  [PaymentTableAccessors.ACTIONS]: React.ReactNode
}

type InvoicePaymentTableColumn = Column<InvoicePaymentTableRow>

const TABLE_COLUMNS: InvoicePaymentTableColumn[] = [
  {
    accessor: PaymentTableAccessors.ROW_DATA,
  },
  {
    Header: <>&nbsp;</>,
    accessor: PaymentTableAccessors.SOURCE,
    width: '55px',
  },
  {
    Header: <FormattedMessage id="paymentHistory.table.th.date" defaultMessage="Fizetés időpontja" />,
    accessor: PaymentTableAccessors.DATE,
    width: 'auto',
  },
  {
    Header: <FormattedMessage id="paymentHistory.table.th.account_number" defaultMessage="Bankszámlaszám" />,
    accessor: PaymentTableAccessors.ACCOUNT_NUMBER,
    width: 'auto',
  },
  {
    Header: <FormattedMessage id="paymentHistory.table.th.amount" defaultMessage="Összeg" />,
    accessor: PaymentTableAccessors.AMOUNT,
    width: 'auto',
    align: 'right',
  },
  {
    Header: <>&nbsp;</>,
    accessor: PaymentTableAccessors.ACTIONS,
    width: '55px',
    align: 'right',
  },
]

interface InvoicePaymentDetailsTableProps {
  data: InvoicePayment[]
  isEditorUser: boolean
}

export function InvoicePaymentDetailsTable({ data, isEditorUser }: InvoicePaymentDetailsTableProps) {
  const {
    loadAndEditPayment,
    state: { loading },
  } = useEditPayment()

  const useTableProps = React.useMemo(() => {
    const hiddenColumns = [PaymentTableAccessors.ROW_DATA]

    if (!isEditorUser) {
      hiddenColumns.push(PaymentTableAccessors.ACTIONS)
    }

    return {
      initialState: {
        hiddenColumns,
      },
    }
  }, [isEditorUser])

  const tableData = React.useMemo(() => {
    return data.map(transaction => {
      const paymentId = transaction.payment_id
      return {
        [PaymentTableAccessors.ROW_DATA]: transaction,
        [PaymentTableAccessors.SOURCE]: <InvoicePaymentSource data={transaction.created_by} />,
        [PaymentTableAccessors.DATE]: <FormattedDate value={transaction.date} />,
        [PaymentTableAccessors.ACCOUNT_NUMBER]: <AccountNumber value={transaction.account_number} />,
        [PaymentTableAccessors.AMOUNT]: (
          <Currency value={Number(transaction.amount)} currencyId={transaction.currency_id} />
        ),
        [PaymentTableAccessors.ACTIONS]: isEditorUser && (
          <>
            {transaction.has_restricted_transactions && <NotEditablePaymentTooltip />}
            {paymentId && !transaction.has_restricted_transactions && transaction.flow !== 'express' && (
              <TableRowControls loading={loading} onEdit={() => loadAndEditPayment(paymentId)} />
            )}
          </>
        ),
      }
    })
  }, [data, isEditorUser, loadAndEditPayment, loading])

  return <SimpleTable columns={TABLE_COLUMNS} data={tableData} layout="fixed" useTableProps={useTableProps} />
}

InvoicePaymentDetailsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      account_number: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      created_by: PropTypes.oneOf(Object.values(PaymentSources)) as React.Validator<Nullable<PaymentSources>>,
      currency_id: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      flow: PropTypes.oneOf(['transfer', 'manual', 'express']).isRequired as React.Validator<PaymentFlowType>,
      has_restricted_transactions: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      payment_id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  isEditorUser: PropTypes.bool.isRequired,
}
