import React from 'react'

function AutoKasszaIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 149 149"
      width={size}
      height={size}
    >
      <path
        fill="#FF6630"
        d="M74.222 145.912a71.692 71.692 0 0 1-71.69-71.69 71.69 71.69 0 0 1 71.69-71.69 18.884 18.884 0 0 1 0 37.768 33.921 33.921 0 1 0 0 67.844 18.886 18.886 0 0 1 18.884 18.884 18.882 18.882 0 0 1-18.884 18.884Z"
      />
      <path
        fill="#3A4656"
        d="M74.222 145.912a18.881 18.881 0 0 1-18.884-18.884 18.885 18.885 0 0 1 18.884-18.884 33.917 33.917 0 0 0 31.34-20.94 33.936 33.936 0 0 0 0-25.963 33.933 33.933 0 0 0-18.359-18.359A33.921 33.921 0 0 0 74.222 40.3a18.884 18.884 0 1 1 0-37.768 71.689 71.689 0 0 1 50.692 122.383 71.69 71.69 0 0 1-50.692 20.997Z"
      />
      <path
        fill="#FF6630"
        d="M72.847 145.912c-.168 0-.336 0-.5-.007A71.766 71.766 0 0 1 2.558 72.36a18.884 18.884 0 1 1 37.754.99 33.923 33.923 0 0 0 33.021 34.8 18.884 18.884 0 0 1-.486 37.761v.001Z"
      />
      <path
        fill="#3A4656"
        d="M74.061 55.471c2.27-.022 4.533.231 6.741.753A15.12 15.12 0 0 1 86 58.517a10.355 10.355 0 0 1 3.32 3.9A12.496 12.496 0 0 1 90.485 68v15.33a4.25 4.25 0 0 1-.993 2.908 9.912 9.912 0 0 1-2.36 1.95c-2.967 1.78-7.142 2.67-12.525 2.669a28.612 28.612 0 0 1-6.535-.684 15.505 15.505 0 0 1-5-2.053 9.407 9.407 0 0 1-3.215-3.49 10.326 10.326 0 0 1-1.13-4.928c0-3.148.935-5.566 2.806-7.253 1.87-1.687 4.77-2.737 8.695-3.149l8.965-.958v-.479a2.937 2.937 0 0 0-1.745-2.84 11.757 11.757 0 0 0-5.03-.855c-1.703-.003-3.401.18-5.064.547a26.98 26.98 0 0 0-4.449 1.369A4.742 4.742 0 0 1 61.4 64.2a5.961 5.961 0 0 1-.616-2.635 4.387 4.387 0 0 1 .856-2.841 6.633 6.633 0 0 1 2.634-1.813 20.629 20.629 0 0 1 4.688-1.095 40.705 40.705 0 0 1 5.1-.345Zm.547 26.9c.86-.008 1.72-.088 2.567-.24a6.26 6.26 0 0 0 2.087-.65V76l-4.927.41a7.617 7.617 0 0 0-3.148.822 2.211 2.211 0 0 0-1.23 2.053 2.718 2.718 0 0 0 1.06 2.224 5.8 5.8 0 0 0 3.591.858v.004Z"
      />
    </svg>
  )
}

export default AutoKasszaIcon
