import { COMPANY_ROUTE } from '@constants'

// subscription
export const API_SUBSCRIPTION_PLAN_URL = `${COMPANY_ROUTE}plan/`
export const API_SUBSCRIPTION_URL = `${COMPANY_ROUTE}subscription/`
export const API_SUBSCRIPTION_DETAILS_URL = `${COMPANY_ROUTE}subscription/{subscription_id}/`
export const API_SUBSCRIPTION_HISTORY_URL = `${COMPANY_ROUTE}subscription/history/`

// braintree
export const API_CREATE_PAYMENT_METHOD_NONCE_URL = `${COMPANY_ROUTE}subscriptions/create-payment-method-nonce/`
