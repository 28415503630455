import styled from 'styled-components'

import { applyOpacity } from '@helpers'

import { Typography } from '@components/ui/Typography'
import { Paper } from '@components/ui/Wrappers'

import { PriceBox } from './PriceBox'

export const CardPaper = styled(Paper)`
  min-width: 340px;
  border-radius: 12px;
`

export const CardHeader = styled.header`
  height: 218px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: ${({ theme }) => theme.colors.blue[30]};
  position: relative;
  padding: 20px 20px 90px;
  color: ${({ theme }) => theme.colors.gray[0]};
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: ${({ theme }) => `linear-gradient(180deg, transparent, ${applyOpacity(theme.colors.gray[0], 30)})`};
    z-index: -1;
  }
  & > * + * {
    margin-top: 16px;
  }
`

export const CardBodySection = styled.section`
  padding: 60px 20px 20px;
  position: relative;
`

export const CardFooter = styled.footer`
  padding: 0 50px 20px;
`

export const StyledPriceBox = styled(PriceBox)`
  position: absolute;
  top: ${({ discountedPrice }) => (discountedPrice != null ? -40 : -32)}px;
`

export const TrialTypography = styled(Typography)`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
`
