import React from 'react'

import { FormattedMessage } from 'react-intl'

import { useConfetti } from '@hooks/useConfetti'

import {
  DialogOpenState,
  TaxDeclarationMultiClosureDialogState,
} from '@components/pages/PulsePage/TaxationTasks/constants'
import { ClosureDialogHeaderProps } from '@components/pages/PulsePage/TaxationTasks/elements'
import { CustomDialogHeader, CustomDialogHeaderProps } from '@components/ui'

import { DialogCompleteTitle } from '@messages'
import { SupportViewCommentLabelMessage } from '@components/pages/PulsePage/TaxationTasks/messages'

function getTitle({ dialogState, openState }: Pick<ClosureDialogHeaderProps, 'dialogState' | 'openState'>) {
  if (dialogState === TaxDeclarationMultiClosureDialogState.RESPONSE) {
    if (openState === DialogOpenState.SUPPORT) {
      return <FormattedMessage id="supportView.response.success" defaultMessage="Itt nincs több teendőd! 🎉" />
    }

    return DialogCompleteTitle
  }

  return SupportViewCommentLabelMessage
}

export function ClosureDialogHeader({ aria, className, dialogState, openState }: ClosureDialogHeaderProps) {
  const { triggerConfetti } = useConfetti({ spread: 90, particleCount: 100, origin: { y: 0.4 } })

  React.useEffect(() => {
    if (dialogState === TaxDeclarationMultiClosureDialogState.RESPONSE && openState) {
      triggerConfetti()
    }
  }, [triggerConfetti, dialogState, openState])

  if (!dialogState) {
    return null
  }

  const customProps: Exclude<CustomDialogHeaderProps, 'aria'> = {
    className,
    borderless: dialogState === TaxDeclarationMultiClosureDialogState.RESPONSE,
    title: getTitle({ openState, dialogState }),
  }

  return <CustomDialogHeader aria={aria} {...customProps} />
}
