import actions from './actions'

export const initialState: ApiKeysStore = {
  data: [],
  loading: false,
  error: null,
}

type ReducerAction =
  | { type: '@apiKeys/LOAD_API_KEYS_REQUEST'; payload: never }
  | { type: '@apiKeys/LOAD_API_KEYS_SUCCESS'; payload: ApiKeysData[] }
  | { type: '@apiKeys/LOAD_API_KEYS_FAILURE'; payload: string }
  | { type: '@apiKeys/REMOVE_API_KEY_SUCCESS'; payload: ApiKeysData['id'] }

function reducer(state: ApiKeysStore = initialState, action: ReducerAction) {
  switch (action.type) {
    case actions.loadApiKeys.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.loadApiKeys.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case actions.loadApiKeys.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case actions.removeApiKey.SUCCESS:
      return {
        ...state,
        data: state.data.filter(d => d.id !== action.payload),
      }

    default:
      return state
  }
}

export default reducer
