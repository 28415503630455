import React from 'react'

import type { Decorator } from 'final-form'
import { Form, FormProps } from 'react-final-form'

import { focusOnErrors } from '@helpers'

/**
 * Wrapper component over react-final-form to apply focusOnErrors decorator as a default decorator
 */
export default function ReactFinalForm<FormValues = Record<string, unknown>, InitialFormValues = Partial<FormValues>>({
  decorators,
  ...restProps
}: FormProps<FormValues, InitialFormValues>) {
  return (
    <Form
      // casting is required otherwise will error out
      decorators={[focusOnErrors as Decorator<FormValues, InitialFormValues>].concat(decorators || [])}
      {...restProps}
    />
  )
}
