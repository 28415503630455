import React from 'react'

import { useWatch } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { ReactHookFieldArray, ReactHookFormTextField } from '@components/ui/FormElements'
import { FlexRow } from '@components/ui/Wrappers'

const NotificationEmailsWrapper = styled(FlexRow)`
  gap: 0 20px;
  flex-wrap: wrap;
`

const StyledReactHookFormTextField = styled(ReactHookFormTextField)`
  max-width: 260px;
`

export function PaymentNotificationEmailsFieldArray({
  notificationEmails,
}: {
  notificationEmails: PaymentNotificationEmail[]
}) {
  const shouldRender = useWatch<{ showAlert: boolean }>({ name: 'showAlert' })
  if (!shouldRender) {
    return null
  }
  return (
    <NotificationEmailsWrapper>
      <ReactHookFieldArray
        name="notificationEmails"
        render={({ fields }) =>
          fields.map((field, index) => (
            <StyledReactHookFormTextField
              key={field.id}
              label={
                <FormattedMessage
                  id="form.payment.notificationEmails.label"
                  defaultMessage="{name} címzettek"
                  values={{ name: notificationEmails[index].partnerName }}
                />
              }
              name={`notificationEmails[${index}].emails`}
            />
          ))
        }
      />
    </NotificationEmailsWrapper>
  )
}
