import { createTheme } from '@material-ui/core/styles'

import { generateMaxHeightBreakPoint } from './theme'

// TODO: used at page wrapper, will be needed for styled-components
const CONTENT_WIDTH = 1420

//! old color object, do not use anymore
const QUICK_COLORS = {
  primary: {
    xDark: '#BE1B1B',
    dark: '#DC3416',
    mDark: '#E94022',
    main: '#EF572C',
    light: '#FF9944',
  },
  secondary: {
    xDark: '#325C68',
    dark: '#3A6B7A',
    mDark: '#427A8B',
    main: '#508EA1',
    light: '#41B2D4',
    xLight: '#76DDFB',
    xxLight: '#DBECF8',
    xxxLight: '#EBF5FF',
  },
  tertiary: {
    xDark: '#3A5915',
    dark: '#507A1D',
    mDark: '#639723',
    main: '#7EC02D',
    mLight: '#8ED932',
    darkBlue: '#0C1534',
    blue: '#5379F2',
    authBlue: '#4D87C6',
    lightPurple: '#EFE9F3',
    purple: '#A448B3',
    darkPurple: '#623EAE',
    navBadge: '#01988F',
    navGreen: '#4CA79E',
    paperStroke: '#D5E0EB',
    workoutPrimaryBlue: '#455BED',
    workoutSecondaryBlue: '#1161AF',
    workoutDefaultGray: '#DFDFDF',
    smokeBlue: '#DADEED',
    pageBackground: '#E3E6F0',
    oceanBlue: '#2168D1',
    outlineBlue: '#80BDFF',
    szamlazz: '#FF6630',
    stoicWhite: '#E6DAFF',
    gloomyPurple: '#8256DF',
  },
  neutral: {
    xDark: '#0F2F3B',
    dark: '#312F31',
    mDark: '#403E40',
    mLight: '#958F95',
    xxxLight: '#FFFFFF',
  },
  grayscale: {
    xxxDark: '#000000',
    xxDark: '#2C2C2C',
    xDark: '#4A4A4A',
    menu: '#4F4F4F',
    dark: '#656565',
    mDark: '#8C8C8C',
    main: '#9B9B9B',
    inputLabel: '#B1B1B1',
    mLight: '#B2B2B2',
    light: '#CBCBCB',
    xLight: '#E3E3E3',
    xxLight: '#F0F0F0',
    tableRowBg: '#F3F3F3',
    xxxLight: '#F7F7F7',
    inputBackground: '#FAFAFA',
    disabledInputBackground: '#F1F1F1', // disabled bootstrap input bgColor
    inactive: '#CDCDCD',
    //! new colors - use them with new design: 2022.03.12.
    black: '#000',
    blueDark: '#001826',
    grey70: '#656565',
    grey60: '#7B7B7B',
    grey50: '#8C8C8C',
    grey30: '#B1B1B1',
    grey20: '#CCCCCC',
    grey05: '#ECECEC',
    grey02: '#F6F6F6',
  },
  success: {
    dark: '#154E1E',
    main: '#16A82D',
  },
  error: {
    main: '#F44336',
    mLight: '#F5554A',
  },
}

const QUICK_SIZES = {
  xSmall: '0.25rem',
  small: '0.5rem',
  medium: '1rem',
  large: '1.5rem',
  xLarge: '2rem',
  xxLarge: '4rem',
  xxxLarge: '6rem',
}

const QUICK_FONT_SIZES = {
  xxSmall: '0.625rem',
  xSmall: '.75rem',
  small: '.875rem',
  medium: '1rem',
  large: '1.25rem',
  xLarge: '1.5rem',
  xxLarge: '1.75rem',
}

const THEME = createTheme({
  props: {
    MuiBadge: { max: 9999 },
    MuiButtonBase: { disableRipple: true },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: QUICK_COLORS.grayscale.xxxLight,
        color: QUICK_COLORS.neutral.dark,
      },
      colorSecondary: { backgroundColor: QUICK_COLORS.neutral.xxxLight },
    },
    MuiAlert: {
      root: {
        alignItems: 'center',
        padding: '6px 10px',
        minWidth: 150,
        maxWidth: 380,
        minHeight: 50,
      },
      icon: {
        fontSize: 18,
        padding: 0,
        marginRight: 10,
      },
      message: {
        fontSize: 14,
        lineHeight: 1.2,
        padding: 0,
      },
      outlinedError: {
        borderWidth: 1,
        backgroundColor: QUICK_COLORS.neutral.xxxLight,
        color: QUICK_COLORS.neutral.mDark,
        borderLeftWidth: 6,
      },
      filledInfo: {
        backgroundColor: QUICK_COLORS.tertiary.oceanBlue,
      },
    },
    MuiBadge: {
      colorSecondary: {
        backgroundColor: QUICK_COLORS.tertiary.oceanBlue,
      },
    },
    MuiButton: {
      root: {
        // color: '#FFF',
        textTransform: 'none',
      },
      textSecondary: { color: QUICK_COLORS.tertiary.oceanBlue },
      contained: {
        boxShadow: 'none',
        backgroundColor: QUICK_COLORS.grayscale.tableRowBg,
        color: QUICK_COLORS.grayscale.xxDark,
      },
    },
    MuiSvgIcon: {
      colorSecondary: { color: QUICK_COLORS.tertiary.oceanBlue },
    },
    MuiIconButton: { root: { padding: 0 } },
    MuiPaper: {
      root: {
        boxShadow: 'none',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: QUICK_COLORS.tertiary.paperStroke,
      },
      elevation1: { boxShadow: '0 0.3125rem 0.9375rem rgba(80,142,161,0.1)' },
      elevation4: { boxShadow: 'none' },
      elevation8: { boxShadow: '10px 10px 40px rgba(90,90,90,.8)' },
      elevation9: { boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)' },
    },
    MuiFormLabel: {
      root: {
        color: QUICK_COLORS.grayscale.inputLabel,
        '&$focused': { color: QUICK_COLORS.neutral.dark },
        '&$disabled': { color: QUICK_COLORS.grayscale.inputLabel },
      },
      asterisk: {
        color: QUICK_COLORS.primary.main,
      },
    },
    MuiTypography: {
      h5: {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: '33px',
        color: QUICK_COLORS.neutral.mDark,
      },
      h6: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '29px',
        color: QUICK_COLORS.neutral.mDark,
      },
      subtitle1: {
        fontSize: 14,
        lineHeight: '19px',
        color: '#333333',
        fontWeight: 400,
      },
      h3: {
        fontSize: 28,
        fontWeight: 700,
        color: QUICK_COLORS.neutral.mDark,
      },
      h2: {
        fontSize: 24,
        lineHeight: '39px',
        fontWeight: 700,
        color: QUICK_COLORS.neutral.mDark,
      },
      h1: {
        fontSize: 40,
        lineHeight: '65px',
        fontWeight: 700,
        color: QUICK_COLORS.neutral.mDark,
      },
      body1: {
        fontSize: 18,
        lineHeight: '29px',
        color: QUICK_COLORS.grayscale.xxDark,
      },
      body2: {
        fontSize: 16,
        lineHeight: '26px',
        textAlign: 'center',
        color: QUICK_COLORS.grayscale.xxDark,
      },
      button: {
        fontWeight: 600,
        fontSize: 12,
      },
    },
    MuiTableSortLabel: {
      root: {
        color: QUICK_COLORS.grayscale.xxxDark,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '16px',
        opacity: 0.54,
      },
      active: {
        color: QUICK_COLORS.grayscale.xxxDark,
        opacity: 1,
      },
    },
    MuiTableCell: {
      root: {
        color: QUICK_COLORS.grayscale.xxxDark,
        fontSize: 14,
        lineHeight: '19px',
        padding: '2px 5px',
        '&:first-child': { paddingLeft: 12 },
        '&:last-child': { paddingRight: 12 },
      },
      body: {
        color: QUICK_COLORS.grayscale.xxxDark,
        fontSize: 14,
      },
      paddingCheckbox: {
        padding: '0 !important',
        width: 40,
      },
    },
    MuiTableRow: {
      root: {
        height: 42,
        '&$hover:hover': { backgroundColor: QUICK_COLORS.grayscale.tableRowBg },
        '&$selected': { backgroundColor: '#EAEAEA' },
      },
      head: { height: 48 },
    },
    MuiTablePagination: { select: { paddingRight: 20 } },
    MuiCheckbox: {
      root: {
        color: '#CBCBCB',
        width: 48,
        height: 48,
        '&:focus': {},
      },
    },
    MuiTooltip: {
      popper: {
        opacity: 1,
        zIndex: 1299, // must be lower than dialog zIndex: 1300 (Safari issue with active CTA tooltip)
      },
    },
    MuiFormControlLabel: {
      root: { width: '100%' },
      label: {
        textAlign: 'left',
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.26px',
        color: QUICK_COLORS.neutral.mDark,
      },
    },
    MuiInput: { root: { color: QUICK_COLORS.neutral.mDark } },
    MuiDialog: { container: { width: '100%' } },
    // TODO: MuiPrivateTabIndicator is this valid? According to types it is not existing
    MuiPrivateTabIndicator: {
      root: { height: 4 },
      colorSecondary: { backgroundColor: QUICK_COLORS.tertiary.oceanBlue },
    },
    MuiTab: {
      textColorInherit: { opacity: 1 },
    },
    MuiMenuItem: {
      root: {
        boxSizing: 'content-box',
      },
    },
  },
  palette: QUICK_COLORS,
  sizes: QUICK_SIZES,
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    sizes: QUICK_FONT_SIZES,
    // useNextVariants: true, TODO: safely delete once it is here for a while and no changes detected
  },
  spacing: 4,
  borders: {
    main: `1px solid ${QUICK_COLORS.grayscale.xxLight}`,
    secondary: `.0625rem solid ${QUICK_COLORS.secondary.main}`,
  },
  links: {
    default: {
      textDecoration: 'none',
      color: QUICK_COLORS.neutral.dark,
    },
    primary: {
      textDecoration: 'none',
      color: QUICK_COLORS.primary.main,
    },
    secondary: {
      textDecoration: 'none',
      color: QUICK_COLORS.tertiary.oceanBlue,
    },
  },
  filterBarPaper: { padding: '4px 20px' },
  filterBarContainer: {
    // paddingTop: 23,
    display: 'flex',
    alignItems: 'flex-end',
  },
  paper: { padding: 0 },
  appBarHeight: 50,
  appBarHeightMedium: 40,
  statBarHeight: 82,
  statBarHeightMedium: 67,
  searchContainer: {
    display: 'flex',
    height: 44,
    alignItems: 'center',
  },
  searchInput: {
    fontSize: 14,
    color: QUICK_COLORS.grayscale.xxxDark,
    padding: 4,
    fontWeight: 700,
    height: 'auto',

    '&::placeholder': {
      color: QUICK_COLORS.grayscale.xDark,
      opacity: 1,
    },
    '&:hover::placeholder': { color: QUICK_COLORS.grayscale.xxxDark },
  },
  searchIcon: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
    verticalAlign: 'middle',
    color: '#757575',
  },
  tableHeading: { padding: '10px 20px' },
  circularProgress: { color: QUICK_COLORS.secondary.xLight },
  breakpoints: {
    quick: {
      height: { medium: 650 },
      width: {
        large: 1440,
        desktop: 1280,
        small: 1200,
        filterBarOpenButton: 1400,
        mobile: 600,
      },
    },
  },
  primaryLinkButton: {
    padding: 0,
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      color: QUICK_COLORS.primary.dark,
    },
  },
})
// extends theme
THEME.pageWrapper = {
  padding: '10px 15px',
  maxWidth: CONTENT_WIDTH,
  margin: '0 auto',
}
THEME.statBarRoot = {
  padding: '10px 20px 15px',
  minHeight: THEME.statBarHeight,
  alignItems: 'flex-end',
  flexWrap: 'nowrap',
  [generateMaxHeightBreakPoint('medium')]: {
    minHeight: THEME.statBarHeightMedium,
    padding: '10px 20px',
  },
}
THEME.stickyFilterBar = {
  height: 84,
  '& > *': {
    top: THEME.appBarHeight,
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 20,
    [generateMaxHeightBreakPoint('medium')]: { top: THEME.appBarHeightMedium },
  },
}
THEME.lightTooltip = {
  backgroundColor: THEME.palette.neutral.xxxLight,
  color: THEME.palette.grayscale.xxDark,
  boxShadow: THEME.shadows[1],
}

THEME.defaultSpacing = 12
THEME.pagingContainerZIndex = 1295

export default THEME
