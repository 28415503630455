import React from 'react'

import { trimLocalhostFromUrl } from '@helpers'

import { SUPPORTED_PLUGIN_RENDERERS } from '@components/ArtifactViewer'
import { DocViewer } from '@components/DocViewer'
import { GeneratedPreviewViewer } from '@components/GeneratedPreviewViewer'

import { DOC_PREVIEW_CONFIG } from '@constants'

import { PartnerPreviewData } from '../types'

interface PartnerLastInvoicePreviewProps {
  data: PartnerPreviewData
  height: number
  width: number
}

export function PartnerLastInvoicePreview({
  data: { artifact, previewUrl, variant },
  height,
  width,
}: PartnerLastInvoicePreviewProps) {
  const config = {
    ...DOC_PREVIEW_CONFIG,
    hideFileControls: true,
    pdfVerticalScrollByDefault: true,
    viewerSize: {
      maxHeight: height,
      maxWidth: width,
    },
  }

  if (artifact) {
    const documents = [
      {
        uri: trimLocalhostFromUrl(artifact.path),
        fileName: artifact.filename,
      },
    ]
    return <DocViewer documents={documents} pluginRenderers={SUPPORTED_PLUGIN_RENDERERS} config={config} />
  }

  if (previewUrl) {
    return <GeneratedPreviewViewer config={config} previewUrl={previewUrl} previewVariant={variant} />
  }

  return null
}
