import React from 'react'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { ApiErrorMessage } from '@messages'

export enum AsyncStatus {
  IDLE = 'idle',
  WIP = 'wip',
  DONE = 'done',
  ERROR = 'error',
}

interface UseAsyncStatusProps {
  clearTimeout?: number
  errorMessage?: StringOrMessage
  disableErrorAlert?: boolean
}

/**
 * Hook to use async status with auto clearing done and error states. Useful for async input fields as end adornment helper.
 * You can turn off snack alerting on error using `disableErrorAlert` parameter.
 *
 * @param {UseAsyncStatusProps} [{
 *   clearTimeout = 3000,
 *   errorMessage = ApiErrorMessage,
 *   disableErrorAlert = false,
 * }={}]
 * @returns {[AsyncStatus, React.Dispatch<React.SetStateAction<AsyncStatus>>]} [status, setStatus]
 */
export function useAsyncStatus({
  clearTimeout = 3000,
  errorMessage = ApiErrorMessage,
  disableErrorAlert = false,
}: UseAsyncStatusProps = {}): [AsyncStatus, React.Dispatch<React.SetStateAction<AsyncStatus>>] {
  const [status, setStatus] = React.useState<AsyncStatus>(AsyncStatus.IDLE)
  const { setErrorAlert } = useAlertDispatch()

  React.useEffect(() => {
    if (status === AsyncStatus.ERROR && !disableErrorAlert) {
      setErrorAlert(errorMessage)
    }
  }, [disableErrorAlert, errorMessage, setErrorAlert, status])

  // reset status after timeout
  React.useEffect(() => {
    let timeout: number
    if (status === AsyncStatus.DONE || status === AsyncStatus.ERROR) {
      timeout = window.setTimeout(() => setStatus(AsyncStatus.IDLE), clearTimeout)
    }
    return () => {
      if (timeout) window.clearTimeout(timeout)
    }
  }, [clearTimeout, status])

  return [status, setStatus]
}
