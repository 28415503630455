import React from 'react'
import PropTypes from 'prop-types'

import MultiSelectFilter from './MultiSelectFilter'

import { IncludeExcludeFilterWrapper } from '../styles'

interface IncludeExcludeFiltersProps {
  excludeLabel: StringOrMessage
  includeLabel: StringOrMessage
  onChange: (filter: 'exclude' | 'include', selectedValues: CommonIdAndNameType[]) => void
  options: CommonIdAndNameType[]
  values: { exclude: CommonIdAndNameType[]; include: CommonIdAndNameType[] }
}

export default function IncludeExcludeFilters({
  excludeLabel,
  includeLabel,
  onChange,
  options,
  values: { exclude, include },
}: IncludeExcludeFiltersProps) {
  function handleIncludeChange(selectedValueKeys: Array<CommonIdAndNameType['id']>) {
    onChange(
      'include',
      options.filter(({ id }) => selectedValueKeys.includes(id))
    )
  }

  function handleExcludeChange(selectedValueKeys: Array<CommonIdAndNameType['id']>) {
    onChange(
      'exclude',
      options.filter(({ id }) => selectedValueKeys.includes(id))
    )
  }

  return (
    <IncludeExcludeFilterWrapper>
      <MultiSelectFilter
        label={includeLabel}
        onChange={handleIncludeChange}
        options={options}
        value={include.map(({ id }) => id)}
      />
      <MultiSelectFilter
        label={excludeLabel}
        onChange={handleExcludeChange}
        options={options}
        value={exclude.map(({ id }) => id)}
      />
    </IncludeExcludeFilterWrapper>
  )
}

IncludeExcludeFilters.propTypes = {
  excludeLabel: PropTypes.node.isRequired,
  includeLabel: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  values: PropTypes.shape({
    include: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    exclude: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
}
