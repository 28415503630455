import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CircleExclamationIcon, Typography } from '@components/ui'

import { TaxStatus } from '../types'

import { IndicatorContainerDiv, OverrideInformationTypography } from './styles'

const TaxationPayablesWrapperDiv = styled.div``

interface TaxationPayablesProps {
  children: React.ReactChild | React.ReactChild[]
  className?: string
  fullWidth?: boolean
  override?: boolean
  taxStatus?: TaxStatus
}

export default function TaxationPayables({
  children,
  className,
  fullWidth,
  override = false,
  taxStatus,
}: TaxationPayablesProps) {
  return (
    <TaxationPayablesWrapperDiv className={className}>
      <Typography tag="h2" size="heading-6">
        <FormattedMessage id="taxation.payables.title" defaultMessage="Fizetendő adó" />
      </Typography>
      <IndicatorContainerDiv fullWidth={fullWidth}>
        {override && (
          <OverrideInformationTypography color="gray-50" size="700-xs">
            {taxStatus === TaxStatus.SUPPORT ? (
              <FormattedMessage
                id="taxation.task.payable.overrideInformationBySupport"
                defaultMessage="{icon} A fizetendő adód jelenleg egy ügyfélszolgálatunk által létrehozott érték, nem a QUiCK számolta ki neked."
                values={{
                  icon: <CircleExclamationIcon />,
                }}
              />
            ) : (
              <FormattedMessage
                id="taxation.task.payable.overrideInformationByUser"
                defaultMessage="{icon} A fizetendő adód jelenleg egy általad létrehozott érték, nem a QUiCK számolta ki neked."
                values={{
                  icon: <CircleExclamationIcon />,
                }}
              />
            )}
          </OverrideInformationTypography>
        )}
        {children}
      </IndicatorContainerDiv>
    </TaxationPayablesWrapperDiv>
  )
}
