import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { LightningBoltIcon } from '../svgIcons'

const WrapperSpan = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 7px;
  padding: 5px 10px 5px 6px;
  background-color: ${({ theme }) => theme.colors.common.stoicWhite};
  text-transform: uppercase;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  border-radius: 6px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.common.gloomyPurple};

  & svg {
    height: 14px;
    width: auto;
  }

  &.disabled {
    background-color: ${({ theme }) => theme.colors.common.inputLabel};
    color: ${({ theme }) => theme.colors.gray[160]};
  }
`

interface TextBadgeProps {
  className?: string
  tag?: 'span' | 'p'
  text: StringOrMessage
}

function TextBadge({ className, tag = 'span', text }: TextBadgeProps) {
  return (
    <WrapperSpan as={tag} className={className}>
      <LightningBoltIcon />
      {text}
    </WrapperSpan>
  )
}

TextBadge.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string,
  text: PropTypes.node.isRequired,
}

export function BetaBadge(props: Omit<TextBadgeProps, 'text'>) {
  return <TextBadge {...props} text={<FormattedMessage id="general.text.beta" defaultMessage="béta" />} />
}

export function NewTextBadge(props: Omit<TextBadgeProps, 'text'>) {
  return <TextBadge {...props} text={<FormattedMessage id="badges.new" defaultMessage="Új" />} />
}
