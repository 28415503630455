import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_CREATE_PAYMENT_METHOD_NONCE_URL,
  API_SUBSCRIPTION_DETAILS_URL,
  API_SUBSCRIPTION_HISTORY_URL,
  API_SUBSCRIPTION_PLAN_URL,
  API_SUBSCRIPTION_URL,
} from './urls'

export const fetchSubscriptionPlans = companyId => Api.get(injectCompanyToURL(API_SUBSCRIPTION_PLAN_URL, companyId))

export const applyPromocode = (companyId, data) =>
  Api.post(injectCompanyToURL(API_SUBSCRIPTION_PLAN_URL, companyId), data)

export const createSubscription = (companyId, data) =>
  Api.post(injectCompanyToURL(API_SUBSCRIPTION_URL, companyId), data)

export const updateSubscription = (companyId, { id, ...payload }) =>
  Api.patch(injectCompanyToURL(API_SUBSCRIPTION_DETAILS_URL, companyId).replace('{subscription_id}', id), payload)

export const fetchSubscriptionHistory = companyId =>
  Api.get(injectCompanyToURL(API_SUBSCRIPTION_HISTORY_URL, companyId))

// braintree
export const createPaymentMethodNonce = (companyId, data) =>
  Api.post(injectCompanyToURL(API_CREATE_PAYMENT_METHOD_NONCE_URL, companyId), data)
