import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!CHANGE_PASSWORD',
  '!CLEAR_TWO_FACTOR_AUTH',
  '!EXPIRED_TOKEN',
  '!FORCE_TO_LOGIN',
  '!GET_AUTH_DATA',
  '!GET_AUTH_STATUS',
  '!LOAD_INTEGRATION_PROVIDERS',
  '!REFRESH_COMPANY',
  '!RESTORE_PREFERENCES',
  '!TAXATION_SET_TAX_FORM',
  '!UPDATE_BACKUP_TOKENS',
  '!UPDATE_COMPANY_RFF', // used in Pulse/SetupTaskList to trigger updateCompany request
  '!VALIDATE_DEVICE',
  'ACCEPT_INVITATION',
  'CHANGE_PREFERENCES',
  'CREATE_COMPANY_MEMBER',
  'CREATE_COMPANY_VAT',
  'CREATE_COMPANY',
  'CREATE_INTEGRATION_PROVIDER', // számlázz.hu / billingo token
  'FETCH_COMPANY_MEMBERS',
  'FETCH_COMPANY_VATS',
  'FETCH_COMPANY',
  'FETCH_USER_COMPANIES',
  'FETCH_USER',
  'LOAD_INVITATION_DETAILS',
  'LOGIN',
  'LOGOUT',
  'ONBOARDING_AUTO_LOGIN',
  'ONBOARDING_MANUAL_LOGIN',
  'REMOVE_ACCOUNT',
  'REMOVE_COMPANY_MEMBER',
  'REMOVE_COMPANY_MEMBERSHIP',
  'REMOVE_COMPANY_VAT',
  'REMOVE_COMPANY',
  'RENEW_TOKEN',
  'RESET_PASSWORD_CONFIRM',
  'RESET_PASSWORD',
  'SELECT_COMPANY',
  'SET_ACTIVE_SUBSCRIPTION',
  'SIGNUP',
  'UPDATE_BADGE_DISPLAYED_AT',
  'UPDATE_COMPANY_FIELD',
  'UPDATE_COMPANY_MEMBER',
  'UPDATE_COMPANY_VAT',
  'UPDATE_COMPANY',
  'UPDATE_INTEGRATION_PROVIDER', // billingo keys update
  'UPDATE_NOTIFICATION_DISPLAYED_AT',
  'UPDATE_PERSONAL_INFO',
] as const

export default serviceActionsGenerator<typeof actionTypes>('auth', actionTypes)
