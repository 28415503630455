import { Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles'

export const datepickerStyles: Styles<Theme, Record<never, never>> = (theme: any) => ({
  datepickerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .SingleDatePickerInput_calendarIcon': {
      marginLeft: 0,
      padding: 0,
      display: 'flex',
    },
    '& .SingleDatePickerInput_calendarIcon_svg': { fill: theme.palette.secondary.xLight },
    '& .SingleDatePicker_picker': {
      borderRadius: 4,
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.4)',
      marginLeft: -10,
    },
    '& .DayPicker__withBorder': {
      borderRadius: 4,
      boxShadow: 'none',
    },
    '& .DateInput_input__small': {
      padding: 0,
      lineHeight: '29px',
      fontSize: 16,
      fontWeight: 700,
      fontFamily: 'Open Sans',
      color: theme.palette.grayscale.xxDark,
      letterSpacing: '-1.5px',
      borderBottom: 'none',
      cursor: 'pointer',
    },
    '& .DateInput__small': {
      width: '100%',
      maxWidth: 165,
    },
    '& .SingleDatePickerInput': {
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .DateInput_fang': { marginTop: 1 },
    '& .CalendarDay__selected, & .CalendarDay__selected:active, & .CalendarDay__selected:hover': {
      borderColor: '#41B2D4',
      backgroundColor: '#41B2D4',
    },
  },
  datepickerLabel: { fontSize: 18 },
  datepicker: {
    // overwrite react-widgets styles
    fontSize: 16,
    marginTop: '1.2rem',
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    color: 'inherit',
    '&.rw-state-focus': {
      '&>.rw-widget-container': {
        borderColor: '#80bdff',
        boxShadow: 'none',
        '&:hover': { boxShadow: 'none' },
      },
    },
    '&>.rw-widget-picker': {
      borderRadius: 4,
      backgroundColor: theme.palette.grayscale.inputBackground,
      border: `1px solid ${theme.palette.grayscale.light}`,
      display: 'inline-flex',
      height: 36,
      padding: '6px 10px',
      margin: 0,
      '& .rw-btn-select': { verticalAlign: 'top' },
    },
    '&>.rw-widget-picker>.rw-widget-input': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      padding: 0,
      color: theme.palette.neutral.mDark,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '26px',
      letterSpacing: '-1.2px', // more space for text
      order: 2,
      '&[readonly]': { cursor: 'pointer' },
    },
    '&>.rw-widget-picker>.rw-select': {
      border: 'none',
      order: 1,
      width: 25,
      '&:hover, &:active': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&>*': { width: 20 },
      '& .rw-i-calendar': { color: theme.palette.tertiary.oceanBlue },
    },
    // popup
    '&>.rw-popup-container': {
      '& .rw-calendar.rw-popup': {
        borderColor: theme.palette.grayscale.mLight,
        padding: 10,
        borderRadius: 4,
      },
      '& .rw-cell': {
        color: theme.palette.grayscale.xxDark,
        '&.rw-state-focus': { borderColor: theme.palette.tertiary.oceanBlue },
        '&.rw-state-selected': {
          backgroundColor: theme.palette.tertiary.oceanBlue,
          borderColor: theme.palette.tertiary.oceanBlue,
          color: theme.palette.neutral.xxxLight,
        },
      },
    },
  },
  datepickerError: {
    '&>.rw-widget-picker': { borderColor: `${theme.palette.error.main} !important` },
  },
})
