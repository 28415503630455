import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!ABORT_EXPORT_PARTNERS',
  '!CHANGE_ACTIVE_TAB',
  '!EXPORT_PARTNERS',
  '!FETCH_PARTNER_DETAILS',
  '!INIT_CUSTOMER_PARTNERS_PAGE_LOAD',
  '!INIT_PROVIDER_PARTNERS_PAGE_LOAD',
  '!SEARCH_PARTNERS',
  '!UPDATE_PARTNER_CALCULATION_BASE',
  'CREATE_PARTNER',
  'FETCH_PARTNERS',
  'MERGE_AND_REMOVE_PARTNER',
  'REMOVE_PARTNER',
  'UPDATE_ORDER',
  'UPDATE_PAGE',
  'UPDATE_PARTNER',
  'UPDATE_ROWS_PER_PAGE',
] as const

export default serviceActionsGenerator<typeof actionTypes>('partners', actionTypes)
