import { AxiosResponse } from 'axios'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { parseFiltersFromUrlWorkerRunner, salaryListWorkerRunner, SyncFiltersPayload } from '@webWorkers'
import { InitPageLoadSagaPayload } from '@services'
import { JOB_STATUS_FINISHED } from '@services/background/process'

import {
  generateBackgroundProcessActionPayload,
  getActiveCompanyId,
  getErrorMessage,
  getSalaryListFiltersFromStore,
  getUrlFilterOptionsFromStore,
} from '@helpers'

import { PageChangePayload } from '@hooks'

import { BackgroundProcessActions } from '@constants'

import dashboardActions from '../dashboard/actions'
import { bulkTagging as bulkTaggingApi, fetchTags as fetchTagsApi } from '../dashboard/api'
import filtersActions from '../filters/actions'
import actions from './actions'
import * as api from './api'
import { ExportBackgroundAction } from './backgroundActions'

type SalaryListResponse = AxiosResponse<BackendPaginatedListResponse<BackendSalaryListResult>>

function* initSalaryListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}: AsyncSagaAction<InitPageLoadSagaPayload>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const tagsResponse: AxiosResponse<Tag[]> = yield call(fetchTagsApi, companyId)
    yield put(dashboardActions.fetchTags.success(tagsResponse.data))

    const filterOptions: ReturnType<typeof getUrlFilterOptionsFromStore> = yield select(
      getUrlFilterOptionsFromStore,
      filtersStateKey
    )

    //* >> sync from url to store: call worker
    const { filters, params, validationLevel }: SyncFiltersPayload = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initSalaryListFiltersFromUrl.request({ filters })),
      put(filtersActions.initSalaryListParamsFromUrl.request(params)),
    ])

    yield call(resolve, validationLevel)
    yield put(actions.fetchSalaryListV2.request({ navigate }))
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
    yield put(actions.fetchSalaryListV2.failure(errorMsg))
  }
}

// worker sagas
function* fetchSalaryListV2Saga({ payload: { navigate } }: SagaAction<Pick<InitPageLoadSagaPayload, 'navigate'>>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: SalaryListStoreFilters = yield select(getSalaryListFiltersFromStore, { withCursor: true })
    // gather tags as we're going to need them for mapping purposes for the list
    const tags: Tag[] = yield select(
      ({
        dashboard: {
          tags: { data },
        },
      }: Store) => data
    )
    // start fetching expenses
    const listResponse: SalaryListResponse = yield call(api.fetchSalaryListV2, companyId, filters)
    // call worker
    const workerResults: SalaryListData[] | WorkerError = yield call(salaryListWorkerRunner, {
      salaries: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchSalaryListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
    //* << sync from store to url
    const storedFilters: SalaryListStoreFilters = yield select(getSalaryListFiltersFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield put(actions.fetchSalaryListV2.failure(errorMsg))
  }
}
//* When we do not need to fetch expense types and tags separately from the backend, only the list data (ie. when reordering)
function* fetchSalaryListV2SimpleSaga() {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: SalaryListStoreFilters = yield select(getSalaryListFiltersFromStore)
    const tags: Tag[] = yield select(
      ({
        dashboard: {
          tags: { data },
        },
      }: Store) => data
    )

    // start fetching expenses
    const listResponse: SalaryListResponse = yield call(api.fetchSalaryListV2, companyId, filters)
    // call worker
    const workerResults: SalaryListData[] | WorkerError = yield call(salaryListWorkerRunner, {
      salaries: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchSalaryListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield put(actions.fetchSalaryListV2.failure(errorMsg))
  }
}

function* fetchSalaryListByPagingV2Saga({ payload: { url } }: SagaAction<PageChangePayload>) {
  try {
    const tags: Tag[] = yield select(
      ({
        dashboard: {
          tags: { data },
        },
      }: Store) => data
    )

    const listResponse: SalaryListResponse = yield call(api.fetchSalaryListByPagingV2, url)
    // call worker
    const workerResults: SalaryListData[] | WorkerError = yield call(salaryListWorkerRunner, {
      salaries: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchSalaryListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield put(actions.fetchSalaryListV2.failure(errorMsg))
  }
}

//* v2 ACTIONS
function* bulkTaggingV2Saga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackendTaggingPayload>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    yield call(bulkTaggingApi, companyId, payload)
    yield put(actions.bulkTaggingV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* bulkDeleteV2Saga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: SalaryListStoreFilters = yield select(getSalaryListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.DELETE_SALARIES,
      payload,
      filters
    )
    const response: AxiosResponse<BackgroundActionResponse> = yield call(
      api.salaryBackgroundAction,
      companyId,
      apiPayload
    )
    // every selected items are removed clear selection
    if (response.data.status === JOB_STATUS_FINISHED) {
      // only trigger selection clear when no rejected items
      yield put(actions.bulkDeleteV2.success())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* startBulkExportV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: SalaryListStoreFilters = yield select(getSalaryListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.EXPORT_SALARIES,
      payload,
      filters
    )
    const response: AxiosResponse<BackgroundActionResponse> = yield call(
      ExportBackgroundAction.start,
      companyId,
      apiPayload
    )
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* stopBulkExportV2Saga() {
  yield call(ExportBackgroundAction.stop)
}

function* fetchLastSalarySaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackendSalaryListResult> = yield call(api.fetchLastSalary, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.initSalaryListPageLoad.REQUEST, initSalaryListPageLoadSaga)
  yield takeLatest(actions.fetchSalaryListV2.REQUEST, fetchSalaryListV2Saga)
  yield takeLatest(actions.fetchSalaryListByPagingV2.REQUEST, fetchSalaryListByPagingV2Saga)
  yield takeLatest(
    [
      actions.updateOrderV2.REQUEST,
      actions.updateRowsPerPageV2.REQUEST,
      filtersActions.toggleSalaryListDateFilter.REQUEST,
      filtersActions.updateSalaryListFilters.REQUEST,
      filtersActions.resetSalaryListFilters.REQUEST,
      actions.bulkTaggingV2.SUCCESS,
      actions.triggerSalaryListUpdate.REQUEST,
      actions.bulkDeleteV2.SUCCESS,
      dashboardActions.createMonthlySalaryV2.SUCCESS,
      dashboardActions.updateMonthlySalaryV2.SUCCESS,
      dashboardActions.removeMonthlySalaryV2.SUCCESS,
      actions.resetPagination.REQUEST,
    ],
    fetchSalaryListV2SimpleSaga
  )
  yield takeLatest(actions.bulkTaggingV2.REQUEST, bulkTaggingV2Saga)
  yield takeLatest(actions.bulkDeleteV2.REQUEST, bulkDeleteV2Saga)
  yield takeLatest(actions.startBulkExportV2.REQUEST, startBulkExportV2Saga)
  yield takeLatest(actions.stopBulkExportV2.REQUEST, stopBulkExportV2Saga)
  yield takeLatest(actions.fetchLastSalary.REQUEST, fetchLastSalarySaga)
}
