import React from 'react'

import { useFormContext } from 'react-hook-form'

import { useDokumentDetailsControlsDispatch } from '@contexts'

export function SyncFormWithDokumentDetailsControls() {
  const {
    formState: { isDirty },
  } = useFormContext()
  const { setFormDirty } = useDokumentDetailsControlsDispatch()

  React.useEffect(() => {
    setFormDirty(isDirty)
  }, [isDirty, setFormDirty])

  return null
}
