import React from 'react'

import { ErrorBoundary, wrapCreateBrowserRouter } from '@sentry/react'
import Loadable from 'react-loadable'
import { useSelector } from 'react-redux'
import { createBrowserRouter, Navigate, Outlet, ScrollRestoration, useLocation } from 'react-router-dom'

import { useGTMPageTracking } from '@hooks'

import { DashboardRoutes } from '@components/routes'
import { AsyncPageLoadingComponent } from '@oldComponents/ui'

import { ROUTES } from '@constants/routes'

import { RootErrorBoundary } from './RootErrorBoundary'

const AsyncLoginPage = Loadable({
  loader: () => import('@oldComponents/pages/LoginPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncSignupPage = Loadable({
  loader: () => import('@oldComponents/pages/SignupPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncResetPasswordPage = Loadable({
  loader: () => import('@oldComponents/pages/ResetPasswordPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncResetPasswordConfirmPage = Loadable({
  loader: () => import('@oldComponents/pages/ResetPasswordConfirmPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncNotFoundPublicPage = Loadable({
  loader: () => import('@components/pages/NotFoundPage/NotFoundPublicPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncInviteRegistrationPage = Loadable({
  loader: () => import('@oldComponents/pages/InviteRegistrationPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncOnboardingPage = Loadable({
  loader: () => import('@oldComponents/pages/OnboardingPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDownloadsPage = Loadable({
  loader: () => import('@oldComponents/pages/DownloadsPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncOnboardingInvitationPage = Loadable({
  loader: () => import('@oldComponents/pages/OnboardingInvitationPage'),
  loading: AsyncPageLoadingComponent,
})

function MainRouter() {
  useGTMPageTracking()

  return (
    <>
      <ScrollRestoration />
      <ErrorBoundary fallback={RootErrorBoundary}>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}

function PrivateRouteGuard() {
  const location = useLocation()
  const isLoggedIn = useSelector((state: Store) => state.auth.is_logged_in)

  if (isLoggedIn) {
    return <Outlet />
  } else {
    return <Navigate to={ROUTES.login} replace state={{ redirectToAfterLogin: location.pathname + location.search }} />
  }
}

function PublicRouteGuard() {
  const { state } = useLocation<{ redirectToAfterLogin?: string }>()

  const redirectUrl = state?.redirectToAfterLogin || ROUTES.root
  const isLoggedIn = useSelector((state: Store) => state.auth.is_logged_in)

  if (isLoggedIn) {
    return <Navigate to={redirectUrl} replace />
  } else {
    return <Outlet />
  }
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

export default sentryCreateBrowserRouter([
  {
    element: <MainRouter />,
    children: [
      {
        path: '/login',
        element: <PublicRouteGuard />,
        children: [
          {
            path: '/login',
            element: <AsyncLoginPage />,
          },
        ],
      },
      {
        path: '/signup',
        element: <PublicRouteGuard />,
        children: [
          {
            path: '/signup',
            element: <AsyncSignupPage />,
          },
        ],
      },
      {
        path: '/reset-password',
        element: <PublicRouteGuard />,
        children: [
          {
            path: '/reset-password',
            element: <AsyncResetPasswordPage />,
          },
        ],
      },
      {
        path: '/reset-password-confirm/:uid/:token',
        element: <PublicRouteGuard />,
        children: [
          {
            path: '/reset-password-confirm/:uid/:token',
            element: <AsyncResetPasswordConfirmPage />,
          },
        ],
      },
      {
        path: '/invite/:hash',
        element: <AsyncInviteRegistrationPage />,
      },
      {
        path: '/download/:hash',
        element: <AsyncDownloadsPage />,
      },
      {
        path: '/onboarding',
        element: <AsyncOnboardingPage />,
      },
      {
        path: '/onboarding/:company_id/invitation-status',
        element: <PrivateRouteGuard />,
        children: [
          {
            path: '/onboarding/:company_id/invitation-status',
            element: <AsyncOnboardingInvitationPage />,
          },
        ],
      },
      {
        path: '/onboarding/:company_id/check',
        element: <AsyncOnboardingPage />,
      },
      {
        path: '/onboarding/:company_id/kszi',
        element: <AsyncOnboardingPage />,
      },
      {
        path: '/onboarding/:company_id/subscription',
        element: <AsyncOnboardingPage />,
      },
      {
        path: '/dashboard/*',
        element: <PrivateRouteGuard />,
        children: [
          {
            path: '*',
            element: <DashboardRoutes />,
          },
        ],
      },
      {
        path: '/',
        element: <Navigate to={ROUTES.root} replace />,
      },
      {
        path: '*',
        element: <AsyncNotFoundPublicPage />,
      },
    ],
  },
])
