import React from 'react'
import PropTypes from 'prop-types'

import __debounce from 'lodash/debounce'
import { connect } from 'react-redux'

import { commonActions, CommonAxiosPayload } from '@services'

import { parseApiErrorMessage } from '@helpers/api'
import { bindActionToPromise, cancellablePromise } from '@helpers/index'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { PaidStatusHistory, PaidStatusIndicator, PaidStatusIndicatorProps } from './elements'

type Response = { history: BackendInvoicePaymentHistoryResults }
type PaidStatusState = {
  fetched: boolean
  loading: boolean
  results: BackendInvoicePaymentHistoryResults
}
export interface PureAsyncPaidStatusProps extends Pick<PaidStatusIndicatorProps, 'placedInModal' | 'value'> {
  callUrl: AsyncFunction<CommonAxiosPayload<void>, Response>
  historyUrl: string
  invoiceCurrencyId: number
}

export function PureAsyncPaidStatus({
  callUrl,
  historyUrl,
  invoiceCurrencyId,
  value,
  ...rest
}: PureAsyncPaidStatusProps) {
  const [{ fetched, loading, results }, setState] = React.useState<PaidStatusState>({
    fetched: false,
    loading: false,
    results: [],
  })
  const { setErrorAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef<Response>()

  const debouncedLoadHistoryRef = React.useRef(
    __debounce(async () => {
      setState(prevState => ({ ...prevState, loading: true }))
      try {
        cPromiseRef.current = cancellablePromise(callUrl({ url: historyUrl, method: 'get' }))
        const { history } = await cPromiseRef.current.promise

        setState({ fetched: true, loading: false, results: history })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
      }
    }, 400)
  )

  React.useEffect(
    () => () => {
      debouncedLoadHistoryRef.current.cancel()
    },
    []
  )

  function handleOpen() {
    debouncedLoadHistoryRef.current()
  }

  function handleClose() {
    debouncedLoadHistoryRef.current.cancel()
  }

  return (
    <PaidStatusIndicator
      {...rest}
      onClose={handleClose}
      onOpen={handleOpen}
      title={
        <PaidStatusHistory
          fetched={fetched}
          data={results}
          invoiceCurrencyId={invoiceCurrencyId}
          loading={loading}
          value={value}
        />
      }
      value={value}
    />
  )
}

PureAsyncPaidStatus.propTypes = {
  callUrl: PropTypes.func.isRequired,
  historyUrl: PropTypes.string.isRequired,
  invoiceCurrencyId: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired as React.Validator<InvoicePaidStatusValue>,
}

export const AsyncPaidStatus = connect(null, dispatch => ({
  callUrl: bindActionToPromise(dispatch, commonActions.callUrl.request),
}))(PureAsyncPaidStatus)

AsyncPaidStatus.displayName = 'AsyncPaidStatus'
