import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { CircleExclamationIcon, OverviewItemProps } from '@components/ui'
import { TaxationOverview } from '@components/ui/TaxationOverview'
import { Typography } from '@components/ui/Typography'

const LIMIT_STATES = {
  default: 'default',
  warning: 'warning',
  full: 'full',
  overLimit: 'over-limit',
} as const

const TaxationLimitCalulatorOverview = styled(TaxationOverview)`
  --overview-default-percent-color: ${({ theme }) => theme.colors.gray[0]};
  --overview-default-percent-border-color: ${({ theme }) => theme.colors.blue[40]};
  --overview-default-percent-background-color: ${({ theme }) => theme.colors.blue[40]};

  margin-top: 20px;

  &.${LIMIT_STATES.warning}, &.${LIMIT_STATES.full}, &.${LIMIT_STATES.overLimit} {
    --overview-border-color: var(--overview-error-color);
  }

  &.${LIMIT_STATES.overLimit} {
    --overview-primary-value-color: var(--overview-error-color);
    --overview-primary-percent-display: none;
    --overview-default-percent-border-color: var(--overview-error-color);
    --overview-default-percent-background-color: transparent;
    --overview-default-percent-color: var(--overview-error-color);
  }
`

// TODO TYPO color fix
const WarningTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.error[30]};
  margin-top: 6px;
  line-height: 16px;
  display: flex;
  gap: 6px;
  align-items: center;
`

/**
 * Helper function to figure out the state we're in for the calculation
 *
 * @param {Required<OverviewItemProps>} { percent, value }
 * @returns the state we're in in the calculation
 */
function getLimitState({ percent, value }: Required<OverviewItemProps>) {
  if (percent > 10) {
    return LIMIT_STATES.default
  } else if (0 < percent && percent <= 10) {
    return LIMIT_STATES.warning
  } else if (percent === 0 && value === 0) {
    return LIMIT_STATES.full
  } else {
    return LIMIT_STATES.overLimit
  }
}

interface TaxationLimitCalculatorProps {
  firstParam: Required<OverviewItemProps>
  limit: OverviewItemProps
  secondParam: Required<OverviewItemProps>
  warning: StringOrMessage
}

/**
 * Component to display taxation limit details
 *
 * @param {TaxationLimitCalculatorProps} {
 *   firstParam,
 *   limit,
 *   secondParam,
 *   warning,
 * }
 */
export function TaxationLimitCalculator({ firstParam, limit, secondParam, warning }: TaxationLimitCalculatorProps) {
  const limitState = getLimitState(firstParam)
  return (
    <>
      <TaxationLimitCalulatorOverview
        className={limitState}
        firstParam={firstParam}
        secondParam={secondParam}
        thirdParam={limit}
      />

      {limitState !== 'default' && warning && (
        <WarningTypography size="700-xs">
          <CircleExclamationIcon />
          <span>{warning}</span>
        </WarningTypography>
      )}
    </>
  )
}

TaxationLimitCalculator.propTypes = {
  firstParam: PropTypes.shape({
    label: PropTypes.node.isRequired,
    percent: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  limit: PropTypes.shape({
    label: PropTypes.node.isRequired,
    percent: PropTypes.number,
    value: PropTypes.number.isRequired,
  }),
  secondParam: PropTypes.shape({
    label: PropTypes.node.isRequired,
    percent: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  warning: PropTypes.node.isRequired,
}
