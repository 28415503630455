import React from 'react'
import PropTypes from 'prop-types'

import { Props as ModalProps } from 'react-modal'

import { useMeasure } from '@hooks'

import { Button, Typography } from '@components/ui'

import { CloseButtonMessage } from '@messages'
import { DialogResponseActions, DialogResponseWrapper } from './styles'

interface DialogResponseProps {
  aria: Required<ModalProps>['aria']
  children: JSX.Element
  error: string | null
  onClose: VoidFunction
  onErrorText: React.ReactChild
  onSubmitSuccessText: React.ReactChild
  success: boolean
}

/**
 * DialogResponse is a component that is used to display a response to a form submission or an error.
 *
 * @param {DialogResponseProps} {
 *   aria, - aria attributes for the dialog
 *   children, - the form
 *   error, - the error message
 *   onClose, - the function to call when the dialog is closed
 *   onErrorText, - the text to display when there is an error
 *   onSubmitSuccessText, - the text to display when the form is submitted successfully
 *   success, - whether the form was submitted successfully
 * }
 */
export default function DialogResponse({
  aria,
  children,
  error,
  onClose,
  onErrorText,
  onSubmitSuccessText,
  success,
}: DialogResponseProps) {
  // store and control width and height separately as height values can change more often than width
  const [savedFormWidth, setSavedFormWidth] = React.useState(0)
  const [savedFormHeight, setSavedHeight] = React.useState(0)

  const [formMeasureRef, { width: formWidth, height: formHeight }] = useMeasure()
  // update savedWidth when width is above 0
  React.useEffect(() => {
    if (formWidth > 0) {
      setSavedFormWidth(formWidth)
    }
  }, [formWidth])

  // update savedHeight when height is above 0
  React.useEffect(() => {
    if (formHeight > 0) {
      setSavedHeight(formHeight)
    }
  }, [formHeight])

  if (error || success) {
    return (
      <DialogResponseWrapper
        id={aria.describedby}
        // update width and height like this to avoid infinite amount of calculated classes
        style={{
          '--width-dialog-response': savedFormWidth > 0 ? `${savedFormWidth}px` : 'auto',
          '--min-height-dialog-response': `${savedFormHeight}px`,
        }}
      >
        {error && (
          <>
            <Typography size="400-sm" color="gray-50" align="center">
              {onErrorText}
            </Typography>
            <Typography size="400-sm" color="error" align="center">
              {error}
            </Typography>
          </>
        )}
        {success && (
          <Typography size="400-sm" color="gray-50" align="center">
            {onSubmitSuccessText}
          </Typography>
        )}
        <DialogResponseActions>
          <Button onClick={onClose} variant="primaryContained" type="button" data-testid="dialog-response-close-btn">
            {CloseButtonMessage}
          </Button>
        </DialogResponseActions>
      </DialogResponseWrapper>
    )
  }

  return <div ref={formMeasureRef}>{children}</div>
}

DialogResponse.propTypes = {
  aria: PropTypes.shape({
    labelledby: PropTypes.string.isRequired,
    describedby: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onErrorText: PropTypes.node.isRequired,
  onSubmitSuccessText: PropTypes.node.isRequired,
  success: PropTypes.bool.isRequired,
}
