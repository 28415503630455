import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { trimLocalhostFromUrl } from '@helpers'

import { PortalProvider, usePortal } from '@contexts'

import { useAssertContext } from '@hooks'

import {
  BMPRenderer,
  DocRenderer,
  DocViewer,
  JPGRenderer,
  NoRenderer,
  PDFRenderer,
  PNGRenderer,
  TXTRenderer,
} from '@components/DocViewer'
import { GeneratedPreviewViewer, InvoicePreviewVariant } from '@components/GeneratedPreviewViewer'
import {
  ButtonWithIcon,
  IconButton,
  JumpToInvoiceDetailsLinkButton,
  Typography,
  XmarkRegularIcon,
} from '@components/ui'
import { CustomDialog, CustomDialogActions, CustomDialogBody } from '@components/ui/Dialogs'
import { LeftArrowIcon } from '@components/ui/svgIcons'
import { FlexRow } from '@components/ui/Wrappers'
import { LightTooltip } from '@oldComponents/ui'

import { DIALOG_PADDING, DOC_PREVIEW_CONFIG, PreviewType } from '@constants'

import { PagePermissions, PlanPermission } from '@permissions'

import { CloseButtonMessage } from '@messages'
import { DialogHeader } from '@components/ui/Dialogs/styles'

export const SUPPORTED_PLUGIN_RENDERERS: DocRenderer[] = [
  BMPRenderer,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
  TXTRenderer,
]

export const DOC_VIEWER_CONFIG = {
  zoom: {
    zoomJump: 0.15,
  },
  pdfVerticalScrollByDefault: true,
  viewerSize: {
    maxWidth: DOC_PREVIEW_CONFIG.maxWidth,
    reducingWidth: DIALOG_PADDING,
    reducingHeight:
      2 * 52 + 3 * DIALOG_PADDING + DOC_PREVIEW_CONFIG.dialogHeaderMaxHeight + DOC_PREVIEW_CONFIG.controlsBarHeight + 2,
  },
  noRenderer: {
    overrideComponent: NoRenderer,
  },
}

const StyledCustomDialogBody = styled(CustomDialogBody)`
  padding: 0;
  padding-left: ${DIALOG_PADDING}px;
  min-height: 25vh;
  min-width: 300px;
  position: static;
`

const StyledDialogHeader = styled(DialogHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${DIALOG_PADDING}px;
`

const StyledCustomDialogActions = styled(CustomDialogActions)`
  padding-top: ${DIALOG_PADDING}px;
  justify-content: space-between;
`

const ControlsContainer = styled(FlexRow)`
  align-items: center;
  justify-content: flex-end;
  gap: ${DIALOG_PADDING / 2}px;
`

const CloseButton = styled(IconButton)`
  &,
  &:before {
    border-radius: 50%;
  }
`

export enum PreviewVariant {
  DOKUMENT = 'dokument',
  INVOICE = 'invoice',
}

type ArtifactPreviewData = {
  fileName: string
  path: string
  title?: Nullable<string>
}

type InvoicePreviewData = {
  invoiceId: ItemIdType
  previewUrl: string
  invoiceVariant: InvoicePreviewVariant
}

type ArtifactHandleOpenFunction = (data: ArtifactPreviewData, variant: PreviewVariant, detailsUrl?: string) => void

type InvoiceHandleOpenFunction = (data: InvoicePreviewData, variant: PreviewVariant, detailsUrl?: string) => void

type PreviewProviderContextValue = {
  handleOpenWithArtifact: ArtifactHandleOpenFunction
  handleOpenWithInvoice: InvoiceHandleOpenFunction
}

const PreviewProviderContext = React.createContext<Nullable<PreviewProviderContextValue>>(null)

PreviewProviderContext.displayName = 'PreviewProviderContext'

type PreviewProviderEmptyState = {
  artifactData: Nullable<ArtifactPreviewData>
  detailsUrl?: string
  invoiceData: Nullable<InvoicePreviewData>
  previewType: null
  variant: null
}

type PreviewProviderArtifactState = {
  artifactData: ArtifactPreviewData
  detailsUrl?: string
  invoiceData: null
  previewType: PreviewType.ARTIFACT
  variant: PreviewVariant
}

type PreviewProviderInvoiceState = {
  artifactData: null
  detailsUrl?: string
  invoiceData: InvoicePreviewData
  previewType: PreviewType.INVOICE
  variant: PreviewVariant
}

type PreviewProviderState = PreviewProviderEmptyState | PreviewProviderArtifactState | PreviewProviderInvoiceState

const initialState: PreviewProviderEmptyState = {
  artifactData: null,
  invoiceData: null,
  previewType: null,
  variant: null,
}

function PortalAnchorElement() {
  const { setPortalAnchorEl } = usePortal()
  return <ControlsContainer ref={setPortalAnchorEl} />
}

function getDialogTitle(
  previewType: Nullable<PreviewType>,
  artifactData: Nullable<ArtifactPreviewData>,
  invoiceData: Nullable<InvoicePreviewData>
) {
  if (previewType === PreviewType.ARTIFACT) {
    return artifactData?.title || artifactData?.fileName
  }
  if (previewType === PreviewType.INVOICE) {
    return (
      <FormattedMessage
        id="document.preview.invoice"
        defaultMessage="Számla ID: {value}"
        values={{ value: invoiceData?.invoiceId }}
      />
    )
  }
  return null
}

interface PreviewProviderProps {
  children: React.ReactNode
}

export function PreviewProvider({ children }: PreviewProviderProps) {
  const [{ artifactData, detailsUrl, invoiceData, previewType, variant }, setState] =
    React.useState<PreviewProviderState>(initialState)
  const location = useLocation()

  const memoizedDocuments = React.useMemo(() => {
    if (previewType === PreviewType.ARTIFACT && artifactData?.path) {
      return [
        {
          uri: trimLocalhostFromUrl(artifactData.path),
          fileName: artifactData.fileName,
        },
      ]
    }
    return []
  }, [artifactData, previewType])

  const handleOpenWithArtifact = React.useCallback<ArtifactHandleOpenFunction>((data, variant, detailsUrl) => {
    setState({ artifactData: data, invoiceData: null, previewType: PreviewType.ARTIFACT, detailsUrl, variant })
  }, [])

  const handleOpenWithInvoice = React.useCallback<InvoiceHandleOpenFunction>((data, variant, detailsUrl) => {
    setState({ artifactData: null, invoiceData: data, previewType: PreviewType.INVOICE, detailsUrl, variant })
  }, [])

  function handleClose() {
    setState(state => ({ ...state, previewType: null, variant: null }))
  }

  const contextValue = React.useMemo(
    () => ({
      handleOpenWithArtifact,
      handleOpenWithInvoice,
    }),
    [handleOpenWithArtifact, handleOpenWithInvoice]
  )

  const dialogTitle = getDialogTitle(previewType, artifactData, invoiceData)

  return (
    <>
      <PreviewProviderContext.Provider value={contextValue}>{children}</PreviewProviderContext.Provider>
      <CustomDialog open={Boolean(previewType)} onClose={handleClose} shouldReturnFocusAfterClose={false} fullWidth>
        <StyledDialogHeader>
          <div>
            {dialogTitle && (
              <Typography size="heading-6" tag="h1" color="gray-80">
                {dialogTitle}
              </Typography>
            )}
            {artifactData?.title && (
              <Typography size="400-sm">
                <FormattedMessage
                  id="document.preview.originalFileName"
                  defaultMessage="(Eredeti fájlnév: {fileName})"
                  values={{ fileName: artifactData?.fileName }}
                />
              </Typography>
            )}
          </div>
          <LightTooltip title={CloseButtonMessage} PopperProps={{ disablePortal: true }}>
            <CloseButton onClick={handleClose}>
              <XmarkRegularIcon size={18} />
            </CloseButton>
          </LightTooltip>
        </StyledDialogHeader>
        <PortalProvider>
          <StyledCustomDialogBody>
            {previewType === PreviewType.ARTIFACT ? (
              <>
                {artifactData?.path && (
                  <DocViewer
                    documents={memoizedDocuments}
                    pluginRenderers={SUPPORTED_PLUGIN_RENDERERS}
                    config={DOC_VIEWER_CONFIG}
                  />
                )}
              </>
            ) : (
              <>
                {invoiceData?.previewUrl && (
                  <GeneratedPreviewViewer
                    config={DOC_VIEWER_CONFIG}
                    previewUrl={invoiceData.previewUrl}
                    previewVariant={invoiceData.invoiceVariant}
                  />
                )}
              </>
            )}
          </StyledCustomDialogBody>
          <StyledCustomDialogActions>
            <div>
              {variant === PreviewVariant.INVOICE && detailsUrl && (
                <JumpToInvoiceDetailsLinkButton detailsUrl={detailsUrl} />
              )}
              {variant === PreviewVariant.DOKUMENT && detailsUrl && (
                <PlanPermission
                  perform={PagePermissions.DOKUMENTS}
                  yes={() => (
                    <ButtonWithIcon
                      variant="primaryText"
                      size="small"
                      icon={<LeftArrowIcon />}
                      forwardedAs={function CustomLink({ children, className }) {
                        return (
                          <Link className={className} to={detailsUrl} state={{ previousPath: location.pathname }}>
                            {children}
                          </Link>
                        )
                      }}
                    >
                      <FormattedMessage
                        id="document.preview.linkToDocumentDetails"
                        defaultMessage="Ugrás a dokumentumrészletekhez"
                      />
                    </ButtonWithIcon>
                  )}
                />
              )}
            </div>
            <PortalAnchorElement />
          </StyledCustomDialogActions>
        </PortalProvider>
      </CustomDialog>
    </>
  )
}

export function usePreviewOpenHandler() {
  return useAssertContext(PreviewProviderContext)
}
