import React from 'react'

import { Menu, MenuItem } from '@material-ui/core'
import Bowser from 'bowser'
import __uniqueId from 'lodash/uniqueId'
import printJS from 'print-js'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getPrintFileType } from '@components/DocViewer/helpers'

import { DocViewerMainState } from '@components/DocViewer/types'
import { ButtonWithIcon, IconButton } from '@components/ui'
import { DownloadFileIcon, PrintIcon, TrashRegularIcon, VerticalDotMenuIcon } from '@components/ui/svgIcons'

import { COMPANY_USER_PERMISSIONS } from '@constants/index'

import { permissionDeniedForUser } from '@permissions/index'

const StyledButtonWithIcon = styled(ButtonWithIcon)`
  justify-content: flex-start;
  gap: 8px;

  & > span {
    min-width: 20px;

    & > svg {
      margin: 0 auto;
    }
  }
`

const DefaultPrintLabelMessage = <FormattedMessage id="docViewer.fab.print" defaultMessage="Nyomtatás" />
const DefaultDownloadLabelMessage = <FormattedMessage id="docViewer.fab.downloadFile" defaultMessage="Letöltés" />

interface FileControlsProps {
  isDeleteAllowed: boolean
  mainState: DocViewerMainState
}

function PureFileControls({
  isDeleteAllowed,
  mainState: { currentDocument, config: { fileControls } = {} },
}: FileControlsProps) {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLButtonElement>>(null)
  const moreMenuId = __uniqueId('file-controls-menu')

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  // NOTE hide preview print function on Safari browser due to blank page print preview issue
  // https://github.com/crabbly/Print.js/issues/528 2021.04.12.
  const parser = Bowser.getParser(window.navigator.userAgent)
  const isSafari = parser.isBrowser('safari')
  const printableFileType = getPrintFileType(currentDocument?.uri)

  function handlePrint() {
    if (currentDocument && printableFileType) {
      printJS(decodeURIComponent(currentDocument.uri), printableFileType)
    }
  }

  if (!currentDocument) {
    return null
  }

  return (
    <>
      <IconButton size="small" onClick={handleClick} aria-label="more" aria-controls={moreMenuId} aria-haspopup="true">
        <VerticalDotMenuIcon size={22} />
      </IconButton>
      <Menu
        id={moreMenuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="menu"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {!fileControls?.hidePrint && printableFileType && (
          <MenuItem onClick={handleClose} dense disableGutters divider>
            {isSafari ? (
              <StyledButtonWithIcon
                icon={<PrintIcon size={19} />}
                size="small"
                fullWidth
                forwardedAs="a"
                href={currentDocument?.uri as string}
                rel="noopener noreferrer"
                target="_blank"
              >
                {fileControls?.printLabel ?? DefaultPrintLabelMessage}
              </StyledButtonWithIcon>
            ) : (
              <StyledButtonWithIcon icon={<PrintIcon size={19} />} size="small" fullWidth onClick={handlePrint}>
                {fileControls?.printLabel ?? DefaultPrintLabelMessage}
              </StyledButtonWithIcon>
            )}
          </MenuItem>
        )}
        {currentDocument?.fileData && (
          <MenuItem
            onClick={handleClose}
            dense
            disableGutters
            divider={Boolean(isDeleteAllowed && !fileControls?.hideDelete && fileControls?.deleteArtifact)}
          >
            {fileControls?.downloadArtifact ? (
              <StyledButtonWithIcon
                icon={<DownloadFileIcon size={19} />}
                size="small"
                fullWidth
                onClick={fileControls.downloadArtifact}
              >
                {fileControls?.downloadLabel ?? DefaultDownloadLabelMessage}
              </StyledButtonWithIcon>
            ) : (
              <StyledButtonWithIcon
                icon={<DownloadFileIcon size={19} />}
                size="small"
                fullWidth
                forwardedAs="a"
                download={currentDocument?.fileName || currentDocument?.uri}
                href={currentDocument?.fileData as string}
              >
                {fileControls?.downloadLabel ?? DefaultDownloadLabelMessage}
              </StyledButtonWithIcon>
            )}
          </MenuItem>
        )}
        {isDeleteAllowed && !fileControls?.hideDelete && fileControls?.deleteArtifact && (
          <MenuItem onClick={handleClose} dense disableGutters>
            <StyledButtonWithIcon
              icon={<TrashRegularIcon size={20} />}
              size="small"
              fullWidth
              variant="alertText"
              onClick={fileControls.deleteArtifact}
            >
              {fileControls.deleteLabel ?? <FormattedMessage id="docViewer.fab.deleteFile" defaultMessage="Törlés" />}
            </StyledButtonWithIcon>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export const FileControls = connect((state: Store) => ({
  isDeleteAllowed: !permissionDeniedForUser(state, COMPANY_USER_PERMISSIONS.delete),
}))(PureFileControls)

FileControls.displayName = 'FileControls'
