import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as yup from 'yup'

import { subscriptionActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { ReactHookForm, ReactHookFormError, ReactHookFormSubmitButton, ReactHookFormTextField } from '@components/ui'

import { formErrorMessages } from '@messages'

const StyledReactHookFormSubmitButton = styled(ReactHookFormSubmitButton)`
  width: 100%;
  margin-top: calc(16px + 1.2rem);

  & > button {
    height: 36px;
  }
`

const messages = defineMessages({
  promocodeLabel: {
    id: 'promocodeForm.promocodeLabel',
    defaultMessage: 'Promokód',
  },
})

const LabelWrapper = styled.div`
  font-size: 13.5px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.common.inputLabel};
  margin-top: 16px;
  transform: translateY(-1.5px);
`

const FakeInputWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`

const FakeInput = styled.div`
  width: 100%;
  height: 36px;
  padding: 6px 0;
  color: ${({ theme }) => theme.colors.gray[100]};
  font-weight: 700;
`

type PromoCodeFormValues = { promo_code: string }

const INITIAL_VALUES: PromoCodeFormValues = { promo_code: '' }

interface PromocodeFormProps {
  disabled?: boolean
  onSubmit: AsyncFunction<PromoCodeFormValues, unknown>
  promo: Nullable<{ code: string; discount: number }>
}

function PurePromocodeForm({ promo, onSubmit, disabled = false }: PromocodeFormProps) {
  const { formatMessage } = useIntl()

  const validationSchema: yup.SchemaOf<PromoCodeFormValues> = yup.object({
    promo_code: yup.string().required(formatMessage(formErrorMessages.required)),
  })

  if (promo) {
    const { code: enteredPromocode } = promo
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LabelWrapper>
            <FormattedMessage id="promocodeForm.enteredPromocode" defaultMessage="Érvényesített promokód" />
          </LabelWrapper>
          <FakeInputWrapper>
            <FakeInput>{enteredPromocode}</FakeInput>
          </FakeInputWrapper>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <ReactHookForm onSubmit={onSubmit} initialValues={INITIAL_VALUES} validationSchema={validationSchema}>
        <Grid container spacing={4}>
          <Grid item xs={7}>
            <ReactHookFormTextField
              label={formatMessage(messages.promocodeLabel)}
              name="promo_code"
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={5}>
            <StyledReactHookFormSubmitButton
              buttonText={<FormattedMessage id="promocodeForm.buttonText" defaultMessage="Érvényesít" />}
              disabled={disabled}
              fullWidth
              isCreateOnly
              variant="primaryText"
            />
          </Grid>
        </Grid>
        <Grid>
          <ReactHookFormError hideIcon />
        </Grid>
      </ReactHookForm>
    )
  }
}

PurePromocodeForm.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  promo: PropTypes.shape({
    code: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
  }),
}

export const PromocodeForm = connect(
  (state: Store) => ({
    promo: state.subscription.promo,
  }),
  dispatch => ({
    onSubmit: bindActionToPromise(dispatch, subscriptionActions.applyPromocode.request),
  })
)(PurePromocodeForm)
