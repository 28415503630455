import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

// circ.dep. issue: keep imports separated
import { AsyncButton, Button } from '@components/ui/Buttons'
import { CustomDialog, CustomDialogActions, CustomDialogBody, CustomDialogHeader } from '@components/ui/Dialogs'
import { Typography } from '@components/ui/Typography'

import { OptionNoMessage, OptionYesMessage } from '@messages'

const StyledDialogBody = styled(CustomDialogBody)`
  max-width: 596px;
  padding: 40px 20px;
`
const StyledDialogActions = styled(CustomDialogActions)`
  gap: 20px;
`

interface CompanyInactivePeriodDeleteConfirmDialogProps {
  loading: boolean
  onClose: VoidFunction
  onDelete: VoidFunction
  open: boolean
}

export function CompanyInactivePeriodDeleteConfirmDialog({
  loading,
  onClose,
  onDelete,
  open,
}: CompanyInactivePeriodDeleteConfirmDialogProps) {
  return (
    <CustomDialog open={open} onClose={onClose}>
      <CustomDialogHeader
        borderless
        title={
          <FormattedMessage
            id="dialogs.confirm.deleteCompanyInactivePeriod.title"
            defaultMessage="Biztosan törlöd ezt az időszakot?"
          />
        }
      />
      <StyledDialogBody>
        <Typography color="gray-80" align="center" size="400-sm">
          <FormattedMessage
            id="dialogs.confirm.deleteCompanyInactivePeriod.confirmText"
            defaultMessage="Szünetelt időszak törlése esetében módosulhatnak a KATA bevallásod adatai (például KATA keret, különadó)."
          />
        </Typography>
      </StyledDialogBody>
      <StyledDialogActions borderless>
        <AsyncButton variant="primaryText" onClick={onDelete} loading={loading}>
          {OptionYesMessage}
        </AsyncButton>
        <Button variant="primaryContained" onClick={onClose}>
          {OptionNoMessage}
        </Button>
      </StyledDialogActions>
    </CustomDialog>
  )
}

CompanyInactivePeriodDeleteConfirmDialog.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
