import React from 'react'

class AssertContextError extends Error {
  constructor(contextName: string) {
    super(`Can't call this hook in a component that is not under the ${contextName}Provider`)
  }
}

// Extracts a context value and ensures that it is defined.
export function useAssertContext<Context>(context: React.Context<Context>): NonNullable<Context> {
  const ctx = React.useContext(context)
  if (!ctx) {
    throw new AssertContextError(context.displayName ?? 'Unknown')
  }
  return ctx as NonNullable<Context>
}
