import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { OldIconButton } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { FILTER_DIALOG_FILTER_PROPS_PROP_TYPE } from '@constants/propTypes'

import { FilterDialog } from './FilterDialog'
import { ToggleIcon } from './ToggleIcon'

interface FiltersDialogButtonProps<CustomStatusFilterConfig extends StatusFilterConfig> {
  filterProps: FilterDialogProps<CustomStatusFilterConfig>
  isDateFilterDisabled: boolean
  isDateFilterToggleDisabled?: boolean
  resetFilters: VoidFunction
  syncSearchInputValue?: (payload: string) => void
  updateFilters: UpdateFiltersFunction
}

export function FilterDialogButton<CustomStatusFilterConfig extends StatusFilterConfig>({
  filterProps,
  isDateFilterDisabled,
  isDateFilterToggleDisabled = false,
  resetFilters,
  syncSearchInputValue,
  updateFilters,
}: FiltersDialogButtonProps<CustomStatusFilterConfig>) {
  // key is used to make sure the current filters and the filter dialog are in sync without any unnecessary effect requirement inside the dialog
  // note, if this eventually slows down the rendering of the dialog, it might be turned away from key
  const [{ key, isOpen }, setOpen] = React.useState({
    key: JSON.stringify(new Date().toISOString()),
    isOpen: false,
  })

  function toggleOpen() {
    setOpen(state => ({
      key: state.isOpen ? state.key : JSON.stringify(new Date().toISOString()),
      isOpen: !state.isOpen,
    }))
  }

  return (
    <>
      <LightTooltip
        title={<FormattedMessage id="pageFilterBar.buttons.tooltip.dialog" defaultMessage="Részletes szűrők" />}
      >
        <OldIconButton onClick={toggleOpen}>
          <ToggleIcon isChanged={filterProps.isChanged} />
        </OldIconButton>
      </LightTooltip>
      <FilterDialog
        isDateFilterDisabled={isDateFilterDisabled}
        isDateFilterToggleDisabled={isDateFilterToggleDisabled}
        key={key}
        filterProps={filterProps}
        onClose={toggleOpen}
        open={isOpen}
        resetFilters={resetFilters}
        syncSearchInputValue={syncSearchInputValue}
        updateFilters={updateFilters}
      />
    </>
  )
}

FilterDialogButton.propTypes = {
  filterProps: FILTER_DIALOG_FILTER_PROPS_PROP_TYPE.isRequired,
  isDateFilterDisabled: PropTypes.bool.isRequired,
  isDateFilterToggleDisabled: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
}
