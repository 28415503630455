import React from 'react'
import PropTypes from 'prop-types'

import __noop from 'lodash/noop'
import { useIntl } from 'react-intl'

import { Typography } from '@components/ui'
import Select from '@oldComponents/ui/form/select' // TODO move to components

import { formSelectMessages } from '@messages'

interface MultiSelectFilterProps {
  label: StringOrMessage
  onChange: (selectedValueKeys: Array<CommonIdAndNameType['id']>) => void
  options: CommonIdAndNameType[]
  value: Array<CommonIdAndNameType['id']>
}

export default function MultiSelectFilter({ label, onChange, options, value }: MultiSelectFilterProps) {
  const { formatMessage } = useIntl()

  return (
    <div>
      <Typography size="700-xs" color="gray-80">
        {label}
      </Typography>
      <Select
        input={{
          onBlur: __noop,
          onChange,
          value,
        }}
        labelKey="name"
        multi
        noResultsText={formatMessage(formSelectMessages.selectNoResultsText)}
        options={options}
        placeholder={formatMessage(formSelectMessages.creatableSelectPlaceholder)}
        valueKey="id"
        menuShouldBlockScroll
      />
    </div>
  )
}

MultiSelectFilter.propTypes = {
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}
