import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { CircleCheckSolidIcon, FlexRow, Typography } from '@components/ui'

const StyledCheckmarkIcon = styled(CircleCheckSolidIcon)`
  color: ${({ theme }) => theme.colors.success[40]};
  flex-shrink: 0;
`

const FormSummaryItemWrapperDiv = styled(FlexRow)`
  gap: 24px;
`

export function FormSummaryItem({ label, value }: { label: StringOrMessage; value: StringOrMessage | JSX.Element }) {
  return (
    <FormSummaryItemWrapperDiv>
      <StyledCheckmarkIcon />
      <div>
        <Typography color="gray-80" size="700-md" tag="h2">
          {label}
        </Typography>
        <Typography color="gray-80" size="400-sm">
          {value}
        </Typography>
      </div>
    </FormSummaryItemWrapperDiv>
  )
}

FormSummaryItem.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
}
