import { getCursorFromUrl, getDefaultPageSize, userSawMonthlySalaryOrTax } from '@helpers'

import { SALARY_V2_DEFAULT_SORTING } from '@constants'

import authActions from '../auth/actions'
import dashboardActions from '../dashboard/actions'
import filtersActions from '../filters/actions'
import actions from './actions'

const defaultPageSize = getDefaultPageSize()

export const initialState: SalaryStore = {
  list: {
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    pageSize: defaultPageSize,
    prevPageUrl: null,
    ...SALARY_V2_DEFAULT_SORTING,
  },
  kpiCharts: {
    data: undefined,
    error: null,
    fetched: false,
    loading: false,
  },
  editorNotification: {
    lastEditedItem: null,
    notifyUser: false,
  },
}

// TODO later type should depend on generated actions' types [xxx]_[REQUEST|SUCCESS|FAILURE]
type ReducerAction = { type: string; payload: any }

export default function reducer(state: SalaryStore = initialState, action: ReducerAction): SalaryStore {
  switch (action.type) {
    case authActions.selectCompany.REQUEST:
      return { ...initialState }

    case filtersActions.initSalaryListParamsFromUrl.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      }
    }

    case actions.fetchSalaryListV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }

    case actions.fetchSalaryListByPagingV2.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        list: {
          ...state.list,
          cursor,
          loading: true,
        },
      }
    }

    case actions.fetchSalaryListV2.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          prevPageUrl: action.payload.previous,
          nextPageUrl: action.payload.next,
          loading: false,
          error: null,
        },
        editorNotification: {
          ...state.editorNotification,
          notifyUser: true,
        },
      }

    case actions.fetchSalaryListV2.FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      }

    case actions.updateOrderV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          order: action.payload.order,
          orderBy: action.payload.orderBy,
        },
      }

    case actions.updateRowsPerPageV2.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
          pageSize: action.payload.pageSize,
        },
      }

    case filtersActions.toggleSalaryListDateFilter.REQUEST:
    case filtersActions.updateSalaryListFilters.REQUEST:
    case filtersActions.resetSalaryListFilters.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          error: null,
        },
        kpiCharts: {
          ...state.kpiCharts,
          error: null,
          loading: true,
        },
      }

    // user saw salary with "new" badge
    case dashboardActions.userViewSalaryV2.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: userSawMonthlySalaryOrTax(state.list.data, action.payload),
        },
      }

    // notification
    case dashboardActions.createMonthlySalaryV2.REQUEST:
      return {
        ...state,
        editorNotification: {
          ...state.editorNotification,
          notifyUser: false,
        },
      }

    case dashboardActions.createMonthlySalaryV2.SUCCESS: {
      const { id, salaries } = action.payload
      return {
        ...state,
        editorNotification: {
          lastEditedItem: {
            id,
            subRows: salaries,
          },
          notifyUser: false,
        },
      }
    }

    case dashboardActions.resetMonthlySalaryEditorNotification.REQUEST:
      return {
        ...state,
        editorNotification: {
          ...initialState.editorNotification,
        },
      }

    case actions.triggerSalaryListUpdate.REQUEST:
    case actions.resetPagination.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
        },
      }

    default:
      return state
  }
}
