import jsFileDownload from 'js-file-download'
import __get from 'lodash/get'
import moment from 'moment'

import { FILENAME_PREFIX_REGEX } from '@constants'

export const transformToDate = key => {
  // this fix the safari invalid date bug (yyyy MM is not supported format for Date)
  // key must be look like this "2018 8"
  const parts = key.split(' ')
  const date = new Date(parts[0], parseInt(parts[1], 10) - 1) // Date(year, month, day, ...)
  date.setDate(1)
  return date
}

export const getMonthFirstDay = date => new Date(moment(date).startOf('month').format('YYYY-MM-DD'))

export const generateBandDomains = (startDate, endDate) => {
  const domains = []
  let current = moment(startDate).startOf('month')
  while (current.isSameOrBefore(endDate, 'month')) {
    domains.push(new Date(current.format('YYYY-MM-DD')))
    current.add(1, 'month')
  }
  return domains
}

export const createFormattedDateString = (year, month, day) => moment(new Date(year, month, day)).format('YYYY-MM-DD')

export const transformObjectToArray = (obj, keyIsNumeric) =>
  Object.keys(obj).map(k => ({ value: keyIsNumeric ? Number(k) : k, label: obj[k] }))

// Download file via API response
export const getFileName = response => {
  const defaultFileName = 'unknown'
  const contentDisposition = response.headers['content-disposition']
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(FILENAME_PREFIX_REGEX)
    return __get(fileNameMatch, 1, defaultFileName)
  } else {
    return defaultFileName
  }
}
export const downloadFileFromAPIHeaders = response => {
  // const blob = new Blob([response.data], {type: response.data.type})
  // const url = window.URL.createObjectURL(blob)
  // const link = document.createElement('a')
  // link.href = url
  const fileName = getFileName(response)
  // link.setAttribute('download', fileName)
  // // document.body.appendChild(link);
  // link.click()
  // link.remove()
  // window.URL.revokeObjectURL(url)
  jsFileDownload(response.data, fileName)
}

export const downloadFileWithURL = response => {
  const url = response.data.path
  window.location.assign(url) // WARNING: Resource interpreted as Document but transferred with MIME type application/zip
}

export const sortByName = options => {
  return options.sort(function (a, b) {
    const textA = a.name.toLowerCase()
    const textB = b.name.toLowerCase()
    if (textA === textB) return 0
    if (textA < textB) return -1
    return 1
  })
}
