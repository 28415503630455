import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { SelectableFeature } from '@components/SelectableFeature'
import {
  Button,
  CustomDialog,
  CustomDialogActions,
  CustomDialogBody,
  CustomDialogHeader,
  Typography,
} from '@components/ui'

import { CloseButtonMessage, SaveButtonMessage } from '@messages'

const StyledCustomDialogBody = styled(CustomDialogBody)`
  min-width: 300px;
  max-width: 740px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
`

const StyledCustomDialogActions = styled(CustomDialogActions)`
  justify-content: space-between;
`

const StyledTypography = styled(Typography)`
  grid-column: span 2;
`

interface CalculationBaseControlDialogProps {
  calculationBase: CalculationBase
  onClose: VoidFunction
  open: boolean
  updatePartnerCalculationBase: AsyncFunction<CalculationBase, void>
}

export function CalculationBaseControlDialog({
  calculationBase,
  onClose,
  open,
  updatePartnerCalculationBase,
}: CalculationBaseControlDialogProps) {
  const [selected, setSelected] = React.useState<Nullable<CalculationBase>>(calculationBase)

  React.useEffect(() => {
    if (!open) {
      setSelected(calculationBase)
    }
  }, [calculationBase, open])

  function handleContinue() {
    if (selected) {
      updatePartnerCalculationBase(selected)
      onClose()
    }
  }

  return (
    <CustomDialog open={open} onClose={onClose}>
      <CustomDialogHeader
        borderless
        title={
          <FormattedMessage
            id="dialogs.calculationBaseControlDialog.title"
            defaultMessage="Számítások alapja beállítás"
          />
        }
      />
      <StyledCustomDialogBody>
        <StyledTypography size="400-md" color="gray-80" align="center">
          <FormattedMessage
            id="dialogs.calculationBaseControlDialog.description"
            defaultMessage="A kiválasztott összeg rögzítésre kerül, melyet a mezőben megjelenített kalkulátor ikon jelez. Alapértelmezetten a bruttó összegek alapján történik a kalkuláció. Ha a nettó összegek megadása egyszerűbb, itt átállíthatod és ez érvényes lesz a partner további számláira is."
          />
        </StyledTypography>
        <SelectableFeature
          description={
            <FormattedMessage
              id="dialogs.createSalaryOrTaxPathSelector.grossAmount.description"
              defaultMessage="A bruttó összeg beírása és az ÁFA-kulcs kiválasztása után a nettó összeg és az ÁFA érték automatikusan kiszámításra kerül."
            />
          }
          key="gross_amount"
          label={
            <FormattedMessage
              id="dialogs.createSalaryOrTaxPathSelector.grossAmount.label"
              defaultMessage="Bruttó összeg"
            />
          }
          onSelect={() => setSelected('gross_amount')}
          selected={'gross_amount' === selected}
        />
        <SelectableFeature
          description={
            <FormattedMessage
              id="dialogs.createSalaryOrTaxPathSelector.netAmount.description"
              defaultMessage="A nettó összeg beírása és az ÁFA-kulcs kiválasztása után a bruttó összeg és az ÁFA érték automatikusan kiszámításra kerül."
            />
          }
          key="net_amount"
          label={
            <FormattedMessage
              id="dialogs.createSalaryOrTaxPathSelector.netAmount.label"
              defaultMessage="Nettó összeg"
            />
          }
          onSelect={() => setSelected('net_amount')}
          selected={'net_amount' === selected}
        />
      </StyledCustomDialogBody>
      <StyledCustomDialogActions borderless>
        <Button variant="primaryText" onClick={onClose}>
          {CloseButtonMessage}
        </Button>
        <Button disabled={!selected} variant="primaryContained" onClick={handleContinue}>
          {SaveButtonMessage}
        </Button>
      </StyledCustomDialogActions>
    </CustomDialog>
  )
}
