import React from 'react'

import { useParams } from 'react-router'

/**
 * Custom hook to control ExchangeRateField visibility
 * @param {number} companyDefaultCurrencyId - current company default currency id
 * @param { number |undefined} currency - current selected currency id in form
 * @param {ItemIdType | undefined} incomeDetailsId - current income document id
 * @returns {boolean} flag for control visibility of exchange rate field
 */
export function useReactHookFormExchangeRateDisplay(
  companyDefaultCurrencyId: number,
  currency?: number,
  incomeDetailsId?: ItemIdType
): boolean {
  const { invoice_id } = useParams<{ invoice_id?: string }>()

  return React.useMemo(() => {
    const detailsMatchedWithParam = String(invoice_id) === String(incomeDetailsId)
    return Boolean(detailsMatchedWithParam && currency && currency !== companyDefaultCurrencyId)
  }, [companyDefaultCurrencyId, currency, incomeDetailsId, invoice_id])
}
