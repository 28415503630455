import { StatusFilters } from '@constants'

import {
  AccountingAdvancedExportedStatusMessage,
  AccountingExpressExportedStatusMessage,
  AccountingProcessedStatusMessage,
  AccountingTodoDoneStatusMessage,
  AccountingUnprocessedStatusMessage,
  AcountoReaydToSyncStatusMessage,
  ApprovedStatusMessage,
  DuplicatedStatusMessage,
  ExcludedFromAccountingStatusMessage,
  FiledStatusMessage,
  HasAccountingTodoStatusMessage,
  HasArtifactStatusMessage,
  HasAttachmentMessage,
  HasPostItMessage,
  HasUnfinishedAccountingTodoStatusMessage,
  NewStatusMessage,
  NoAccountingTodoStatusMessage,
  NoArtifactStatusMessage,
  NoAttachmentMessage,
  NoPostItMessage,
  NotApprovedStatusMessage,
  NotFiledStatusMessage,
  ReaydToSyncStatusMessage,
  SzamlazzReaydToSyncStatusMessage,
  UncategorizedStatusMessage,
  UpdatedStatusMessage,
} from '@messages'

export function getExpenseStatusFilterConfig({
  hasIntegrationAcounto,
  hasIntegrationSzamlazz,
  isAccountantUser,
  isAdvancedAccountingAvailableForUser,
}: Record<
  'hasIntegrationAcounto' | 'hasIntegrationSzamlazz' | 'isAccountantUser' | 'isAdvancedAccountingAvailableForUser',
  boolean
>): StatusFilterConfig[] {
  if (isAccountantUser && isAdvancedAccountingAvailableForUser) {
    return [
      {
        name: StatusFilters.IS_NEW,
        options: [{ value: true, label: NewStatusMessage }],
      },
      {
        name: StatusFilters.IS_FILED,
        options: [{ value: true, label: FiledStatusMessage }],
      },
      {
        name: StatusFilters.HAS_ARTIFACT,
        options: [{ value: false, label: NoArtifactStatusMessage }],
      },
      {
        name: StatusFilters.IS_PROCESSED,
        onChangeOverWriteRules: { [StatusFilters.IS_ACCOUNTING_EXPORTED]: undefined },
        options: [
          { value: false, label: AccountingUnprocessedStatusMessage },
          { value: true, label: AccountingProcessedStatusMessage },
        ],
      },
      {
        name: StatusFilters.IS_ACCOUNTING_EXPORTED,
        onChangeOverWriteRules: { [StatusFilters.IS_PROCESSED]: undefined },
        options: [{ value: true, label: AccountingAdvancedExportedStatusMessage }],
      },
      {
        name: StatusFilters.HAS_UNFINISHED_ACCOUNTING_TODO,
        options: [{ value: true, label: HasUnfinishedAccountingTodoStatusMessage }],
      },
    ]
  }

  const config: StatusFilterConfig[] = [
    {
      name: StatusFilters.IS_NEW,
      options: [{ value: true, label: NewStatusMessage }],
    },
    {
      name: StatusFilters.IS_UPDATED,
      options: [{ value: true, label: UpdatedStatusMessage }],
    },
    {
      name: StatusFilters.HAS_ARTIFACT,
      options: [{ value: false, label: NoArtifactStatusMessage }],
    },

    {
      name: StatusFilters.IS_APPROVED,
      options: [{ value: false, label: NotApprovedStatusMessage }],
    },
    {
      name: StatusFilters.IS_DUPLICATE,
      options: [{ value: true, label: DuplicatedStatusMessage }],
    },
    {
      name: StatusFilters.HAS_EXPENSE_TYPE,
      options: [{ value: false, label: UncategorizedStatusMessage }],
    },
  ]

  if (hasIntegrationSzamlazz) {
    config.push({
      name: StatusFilters.IS_READY_TO_SYNC_SZAMLAZZ,
      options: [
        { value: true, label: hasIntegrationAcounto ? SzamlazzReaydToSyncStatusMessage : ReaydToSyncStatusMessage },
      ],
    })
  }

  return config
}

export function getExpenseStatusFilterDialogConfig({
  hasIntegrationAcounto,
  hasIntegrationSzamlazz,
  isAdvancedAccountingAvailableForUser,
}: Record<
  'hasIntegrationAcounto' | 'hasIntegrationSzamlazz' | 'isAdvancedAccountingAvailableForUser',
  boolean
>): StatusFilterConfig[] {
  if (isAdvancedAccountingAvailableForUser) {
    const config: StatusFilterConfig[] = [
      {
        name: StatusFilters.IS_NEW,
        options: [{ value: true, label: NewStatusMessage }],
      },
      {
        name: StatusFilters.IS_UPDATED,
        options: [{ value: true, label: UpdatedStatusMessage }],
      },
      {
        name: StatusFilters.HAS_ARTIFACT,
        options: [
          { value: true, label: HasArtifactStatusMessage },
          { value: false, label: NoArtifactStatusMessage },
        ],
      },
      {
        name: StatusFilters.HAS_POST_IT,
        options: [
          { value: true, label: HasPostItMessage },
          { value: false, label: NoPostItMessage },
        ],
      },
      {
        name: StatusFilters.HAS_ATTACHMENT,
        options: [
          { value: true, label: HasAttachmentMessage },
          { value: false, label: NoAttachmentMessage },
        ],
      },
      {
        name: StatusFilters.IS_APPROVED,
        options: [
          { value: true, label: ApprovedStatusMessage },
          { value: false, label: NotApprovedStatusMessage },
        ],
      },
      {
        name: StatusFilters.IS_DUPLICATE,
        options: [{ value: true, label: DuplicatedStatusMessage }],
      },
      {
        name: StatusFilters.HAS_EXPENSE_TYPE,
        options: [{ value: false, label: UncategorizedStatusMessage }],
      },
      {
        name: StatusFilters.IS_FILED,
        options: [
          { value: true, label: FiledStatusMessage },
          { value: false, label: NotFiledStatusMessage },
        ],
      },
      {
        name: StatusFilters.IS_PROCESSED,
        onChangeOverWriteRules: { [StatusFilters.IS_ACCOUNTING_EXPORTED]: undefined },
        options: [
          { value: false, label: AccountingUnprocessedStatusMessage },
          { value: true, label: AccountingProcessedStatusMessage },
        ],
      },
      {
        name: StatusFilters.IS_ACCOUNTING_EXPORTED,
        onChangeOverWriteRules: { [StatusFilters.IS_PROCESSED]: undefined },
        options: [{ value: true, label: AccountingAdvancedExportedStatusMessage }],
      },
      {
        name: StatusFilters.HAS_ACCOUNTING_TODO,
        options: [
          { value: true, label: HasAccountingTodoStatusMessage },
          { value: false, label: NoAccountingTodoStatusMessage },
        ],
      },
      {
        name: StatusFilters.HAS_UNFINISHED_ACCOUNTING_TODO,
        options: [{ value: true, label: HasUnfinishedAccountingTodoStatusMessage }],
      },
      {
        name: StatusFilters.IS_ACCOUNTING_TODO_DONE,
        options: [{ value: true, label: AccountingTodoDoneStatusMessage }],
      },
      {
        name: StatusFilters.IS_EXCLUDED_FROM_ACCOUNTING,
        options: [{ value: true, label: ExcludedFromAccountingStatusMessage }],
      },
    ]

    // insert it after HAS_ARTIFACT
    if (hasIntegrationAcounto) {
      config.splice(3, 0, {
        name: StatusFilters.IS_READY_TO_SYNC_ACOUNTO,
        options: [
          { value: true, label: hasIntegrationSzamlazz ? AcountoReaydToSyncStatusMessage : ReaydToSyncStatusMessage },
        ],
      })
    }
    if (hasIntegrationSzamlazz) {
      config.splice(3, 0, {
        name: StatusFilters.IS_READY_TO_SYNC_SZAMLAZZ,
        options: [
          { value: true, label: hasIntegrationAcounto ? SzamlazzReaydToSyncStatusMessage : ReaydToSyncStatusMessage },
        ],
      })
    }

    return config
  }

  const config: StatusFilterConfig[] = [
    {
      name: StatusFilters.IS_NEW,
      options: [{ value: true, label: NewStatusMessage }],
    },
    {
      name: StatusFilters.IS_UPDATED,
      options: [{ value: true, label: UpdatedStatusMessage }],
    },
    {
      name: StatusFilters.HAS_ARTIFACT,
      options: [
        { value: true, label: HasArtifactStatusMessage },
        { value: false, label: NoArtifactStatusMessage },
      ],
    },
    {
      name: StatusFilters.IS_PROCESSED,
      onChangeOverWriteRules: { [StatusFilters.IS_ACCOUNTING_EXPORTED]: undefined },
      options: [
        { value: false, label: AccountingUnprocessedStatusMessage },
        { value: true, label: AccountingProcessedStatusMessage },
      ],
    },
    {
      name: StatusFilters.IS_ACCOUNTING_EXPORTED,
      onChangeOverWriteRules: { [StatusFilters.IS_PROCESSED]: undefined },
      options: [{ value: true, label: AccountingExpressExportedStatusMessage }],
    },
    {
      name: StatusFilters.HAS_POST_IT,
      options: [
        { value: true, label: HasPostItMessage },
        { value: false, label: NoPostItMessage },
      ],
    },
    {
      name: StatusFilters.HAS_ATTACHMENT,
      options: [
        { value: true, label: HasAttachmentMessage },
        { value: false, label: NoAttachmentMessage },
      ],
    },
    {
      name: StatusFilters.IS_APPROVED,
      options: [
        { value: true, label: ApprovedStatusMessage },
        { value: false, label: NotApprovedStatusMessage },
      ],
    },
    {
      name: StatusFilters.IS_DUPLICATE,
      options: [{ value: true, label: DuplicatedStatusMessage }],
    },
    {
      name: StatusFilters.HAS_EXPENSE_TYPE,
      options: [{ value: false, label: UncategorizedStatusMessage }],
    },
    {
      name: StatusFilters.IS_FILED,
      options: [
        { value: true, label: FiledStatusMessage },
        { value: false, label: NotFiledStatusMessage },
      ],
    },
  ]

  // insert it after IS_ACCOUNTING_EXPORTED
  if (hasIntegrationAcounto) {
    config.splice(5, 0, {
      name: StatusFilters.IS_READY_TO_SYNC_ACOUNTO,
      options: [
        { value: true, label: hasIntegrationSzamlazz ? AcountoReaydToSyncStatusMessage : ReaydToSyncStatusMessage },
      ],
    })
  }
  if (hasIntegrationSzamlazz) {
    config.splice(5, 0, {
      name: StatusFilters.IS_READY_TO_SYNC_SZAMLAZZ,
      options: [
        { value: true, label: hasIntegrationAcounto ? SzamlazzReaydToSyncStatusMessage : ReaydToSyncStatusMessage },
      ],
    })
  }

  return config
}
