import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { SelectableFeature } from '@components/SelectableFeature'
import { Button, CustomDialog, CustomDialogActions, CustomDialogBody } from '@components/ui'

import { InvoiceDownloadFlow } from '@constants'

import { CancelButtonMessage } from '@messages'
import { ListActionsDialogHeader } from '../styles'

const StyledDialogBody = styled(CustomDialogBody)`
  padding-left: 20px;
  padding-right: 20px;
  max-width: 480px;
  margin: 0 auto;
  gap: 20px;
  display: grid;
`

const StyledDialogActions = styled(CustomDialogActions)`
  justify-content: space-between;
`

export type InvoiceDownloadRequestParams = { download_flow: InvoiceDownloadFlow }

export type InvoiceDownloadHandlerFunction = AsyncFunction<InvoiceDownloadRequestParams>

const PLURAL_DESCRIPTIONS = {
  [InvoiceDownloadFlow.WITHOUT_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.description.withoutAttachments.plural"
      defaultMessage="Ha a kiválasztott számlákhoz tartozó számlaképeket szeretnéd letölteni, csatolmányok nélkül."
    />
  ),
  [InvoiceDownloadFlow.WITH_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.description.withAttachments.plural"
      defaultMessage="Ha minden számlaképet és ezek mellett a feltöltött csatolmányokat is szeretnéd letölteni."
    />
  ),
  [InvoiceDownloadFlow.MERGE_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.description.mergeAttachments.plural"
      defaultMessage="Ha a PDF számlaképekre és a hozzájuk tartozó PDF csatolmányra egyben, összefűzve van szükséged."
    />
  ),
}

const DESCRIPTIONS = {
  [InvoiceDownloadFlow.WITHOUT_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.description.withoutAttachments"
      defaultMessage="Ha a számlához tartozó számlaképet szeretnéd letölteni, csatolmányok nélkül."
    />
  ),
  [InvoiceDownloadFlow.WITH_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.description.withAttachments"
      defaultMessage="Ha a számlaképet és emellett a feltöltött csatolmányokat is szeretnéd letölteni."
    />
  ),
  [InvoiceDownloadFlow.MERGE_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.description.mergeAttachments"
      defaultMessage="Ha a PDF számlaképre és a hozzá tartozó PDF csatolmányra egyben, összefűzve van szükséged."
    />
  ),
}

const PLURAL_LABELS = {
  [InvoiceDownloadFlow.WITHOUT_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.label.withoutAttachments.plural"
      defaultMessage="Csak számlaképek"
    />
  ),
  [InvoiceDownloadFlow.WITH_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.label.withAttachments.plural"
      defaultMessage="Számlaképek és minden csatolmány"
    />
  ),
  [InvoiceDownloadFlow.MERGE_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.label.mergeAttachments.plural"
      defaultMessage="PDF számlaképek összefűzve"
    />
  ),
}

const LABELS = {
  [InvoiceDownloadFlow.WITHOUT_ATTACHMENTS]: (
    <FormattedMessage id="dialogs.invoiceDownload.flow.label.withoutAttachments" defaultMessage="Csak számlakép" />
  ),
  [InvoiceDownloadFlow.WITH_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.label.withAttachments"
      defaultMessage="Számlakép és minden csatolmány"
    />
  ),
  [InvoiceDownloadFlow.MERGE_ATTACHMENTS]: (
    <FormattedMessage
      id="dialogs.invoiceDownload.flow.label.mergeAttachments"
      defaultMessage="PDF számlakép összefűzve"
    />
  ),
}

interface DownloadConfirmDialogProps {
  downloadHandler: InvoiceDownloadHandlerFunction
  isPlural?: boolean
  onClose: VoidFunction
  open: boolean
  options: InvoiceDownloadFlow[]
}

export function InvoiceDownloadDialog({
  downloadHandler,
  isPlural = false,
  onClose,
  open,
  options,
}: DownloadConfirmDialogProps) {
  const [flow, setFlow] = React.useState<Nullable<InvoiceDownloadFlow>>(null)

  React.useEffect(() => {
    if (!open) {
      setFlow(null)
    }
  }, [open])

  function onDownloadHandler() {
    if (flow) {
      downloadHandler({ download_flow: flow })
    }
  }

  return (
    <CustomDialog open={open} onClose={onClose}>
      <ListActionsDialogHeader
        borderless
        title={
          isPlural ? (
            <FormattedMessage id="dialogs.invoiceDownload.title.plural" defaultMessage="Számlaképek letöltése" />
          ) : (
            <FormattedMessage id="dialogs.invoiceDownload.title" defaultMessage="Számlakép letöltése" />
          )
        }
      />
      <StyledDialogBody>
        {options.map(option => (
          <SelectableFeature
            description={isPlural ? PLURAL_DESCRIPTIONS[option] : DESCRIPTIONS[option]}
            key={option}
            label={isPlural ? PLURAL_LABELS[option] : LABELS[option]}
            onSelect={() => setFlow(option)}
            selected={option === flow}
          />
        ))}
      </StyledDialogBody>
      <StyledDialogActions borderless>
        <Button variant="secondaryText" onClick={onClose}>
          {CancelButtonMessage}
        </Button>
        <Button variant="primaryContained" onClick={onDownloadHandler} disabled={!flow}>
          <FormattedMessage id="dialogs.buttons.download" defaultMessage="Letöltés" />
        </Button>
      </StyledDialogActions>
    </CustomDialog>
  )
}

InvoiceDownloadDialog.propTypes = {
  downloadHandler: PropTypes.func.isRequired,
  isPlural: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}
