import React from 'react'

export function useCancellablePromiseRef<Results = any>() {
  const cPromiseRef = React.useRef<{ promise: Promise<Results>; cancel: VoidFunction }>()

  // cancel ongoing promise on unmount
  React.useEffect(() => {
    return () => {
      if (cPromiseRef.current) {
        // eslint warning can be ignored as this ref is only used for storing functions
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cPromiseRef.current.cancel()
      }
    }
  }, [])

  return cPromiseRef
}
