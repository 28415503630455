import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { useSubscription } from '@contexts'

import { Button, Typography } from '@components/ui'

import { SUBSCRIPTION_PERIODS, SUBSCRIPTION_PLANS, SUBSCRIPTION_PLANS_OBJ } from '@constants'

import {
  CardBodySection,
  CardFooter,
  CardHeader,
  CardPaper,
  FeatureList,
  StyledPriceBox,
  TrialTypography,
} from './elements'

function renderDescription(variant: string) {
  switch (variant) {
    case SUBSCRIPTION_PLANS_OBJ.okos:
      return (
        <FormattedMessage
          id="subscription-card.description.okos-box"
          defaultMessage="Az analitikusoknak, minden adatjunkie-nak, és az innovátoroknak. Azoknak, akik nagyot álmodnak és csapatban játszanak."
        />
      )
    case SUBSCRIPTION_PLANS_OBJ.multi:
      return (
        <FormattedMessage
          id="subscription-card.description.multi-box"
          defaultMessage="Nagyoknak, ahol a legfontosabb, hogy mindenki csak a lényegre koncentráljon és azt lássa, amivel feladata van. "
        />
      )
    case SUBSCRIPTION_PLANS_OBJ.szamla:
    default:
      return (
        <FormattedMessage
          id="subscription-card.description.szamla-box"
          defaultMessage="Kicsiknek, alkotóknak, környezettudatosaknak. Azoknak, akik egy helyen kezelnék számláikat. "
        />
      )
  }
}

interface SubscriptionCardProps {
  description?: StringOrMessage
  plan: SubscriptionPlan
}

export function SubscriptionCard({ description, plan }: SubscriptionCardProps) {
  const { isCurrentCard, isTrialAvailable, selectTier, buttonEnabled, isSubscriptionActive } = useSubscription()

  const { name, period, price, discounted_price: discountedPrice, tier } = plan
  const finalDescription = description ?? renderDescription(tier)
  const current = isCurrentCard(plan)

  function onClickHandler() {
    selectTier(tier)
  }

  return (
    <CardPaper as="article" elevation={3}>
      <CardHeader>
        <Typography align="center" size="heading-3" tag="h2">
          {name}
        </Typography>
        <Typography align="center" size="700-sm">
          {finalDescription}
        </Typography>
      </CardHeader>
      <CardBodySection>
        <StyledPriceBox
          price={price}
          discountedPrice={discountedPrice}
          subscriptionPeriod={period}
          isCurrent={current}
          isSubscriptionActive={isSubscriptionActive}
        />
        <FeatureList tier={tier} />
      </CardBodySection>
      <CardFooter>
        {((!current && isSubscriptionActive && buttonEnabled) || !isSubscriptionActive) && (
          <Button variant="primaryContained" onClick={onClickHandler} data-testid="confirm-btn" size="large" fullWidth>
            {isTrialAvailable ? (
              <TrialTypography tag="span">
                <FormattedMessage id="subscription-card.button.start-subscription" defaultMessage="Kezdhetjük!" />
                <Typography tag="span" size="400-xs">
                  <FormattedMessage
                    id="subscription-card.button.start-subscription-trial"
                    defaultMessage="Ingyenes 30 napos próbaidőszak"
                  />
                </Typography>
              </TrialTypography>
            ) : (
              <FormattedMessage id="subscription-card.button.subscription.select" defaultMessage="Ezt választom" />
            )}
          </Button>
        )}
        {current && isSubscriptionActive && buttonEnabled && (
          <Button variant="secondaryContained" onClick={onClickHandler} size="large" fullWidth>
            <FormattedMessage id="subscription-card.button.keepIt" defaultMessage="Megtartom" />
          </Button>
        )}
      </CardFooter>
    </CardPaper>
  )
}

SubscriptionCard.propTypes = {
  description: PropTypes.node,
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    period: PropTypes.oneOf(SUBSCRIPTION_PERIODS).isRequired,
    price: PropTypes.string.isRequired,
    discounted_price: PropTypes.number,
    tier: PropTypes.oneOf(SUBSCRIPTION_PLANS).isRequired,
  }).isRequired,
}
