// TODO: move to components

import React from 'react'
import { func, object, shape } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { defineMessages, injectIntl } from 'react-intl'

const messages = defineMessages({
  notBetaTesterWarning: {
    id: 'tester.warning',
    defaultMessage:
      'Jelenleg a QUiCK meghívásos béta tesztje zajlik. Ha szeretnél bekerülni a tesztelők közé, jelentkezz',
  },
  notBetaTesterLinkText: {
    id: 'tester.link',
    defaultMessage: 'itt',
  },
})

const styles = theme => ({
  root: {
    lineHeight: 1.1,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
})

const PureNotBetaTester = ({ classes, intl: { formatMessage } }) => (
  <span className={classes.root}>
    {formatMessage(messages.notBetaTesterWarning)}
    <a href="https://riport-quick.kickoffpages.com/" target="_blank" rel="noopener noreferrer" className={classes.link}>
      {' ' + formatMessage(messages.notBetaTesterLinkText)}
    </a>
  </span>
)

PureNotBetaTester.propTypes = {
  classes: object.isRequired,
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
}

const NotBetaTester = withStyles(styles)(injectIntl(PureNotBetaTester))

export default NotBetaTester
