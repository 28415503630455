import React from 'react'

import styled from 'styled-components'

function BaseXmarkRegularIcon({ className, size = 12 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
    >
      <path
        d="M11.705 10.468a.896.896 0 0 1 0 1.27.902.902 0 0 1-1.273 0L6.001 7.284l-4.463 4.45a.902.902 0 0 1-1.273 0 .896.896 0 0 1 0-1.269l4.464-4.45L.264 1.533a.896.896 0 0 1 0-1.27.902.902 0 0 1 1.272 0L6.001 4.75 10.464.298a.902.902 0 0 1 1.272 0 .896.896 0 0 1 0 1.27L7.272 6.016l4.433 4.45Z"
        fill="currentColor"
      />
    </svg>
  )
}

const XmarkRegularIcon = styled(BaseXmarkRegularIcon)``

export default XmarkRegularIcon
