import React from 'react'

import styled from 'styled-components'

function BaseBarchartRegularIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M12.4375 4.125C13.3692 4.125 14.125 4.88051 14.125 5.8125V18.1875C14.125 19.1191 13.3692 19.875 12.4375 19.875H11.3125C10.3809 19.875 9.62503 19.1191 9.62503 18.1875V5.8125C9.62503 4.88051 10.3809 4.125 11.3125 4.125H12.4375ZM12.4375 5.8125H11.3125V18.1875H12.4375V5.8125ZM6.81253 10.875C7.74417 10.875 8.50003 11.6309 8.50003 12.5625V18.1875C8.50003 19.1191 7.74417 19.875 6.81253 19.875H5.68753C4.75554 19.875 4.00003 19.1191 4.00003 18.1875V12.5625C4.00003 11.6309 4.75554 10.875 5.68753 10.875H6.81253ZM6.81253 12.5625H5.68753V18.1875H6.81253V12.5625ZM15.25 8.0625C15.25 7.13086 16.0059 6.375 16.9375 6.375H18.0625C18.9942 6.375 19.75 7.13086 19.75 8.0625V18.1875C19.75 19.1191 18.9942 19.875 18.0625 19.875H16.9375C16.0059 19.875 15.25 19.1191 15.25 18.1875V8.0625ZM16.9375 18.1875H18.0625V8.0625H16.9375V18.1875Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const BarchartRegularIcon = styled(BaseBarchartRegularIcon)``
