import { call, select, takeLatest } from 'redux-saga/effects'

import { getActiveCompanyId, getErrorMessage } from '@helpers'

import actions from './actions'
import * as api from './api'

function* fetchPostItSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    const response = yield call(api.fetchPostIt, company_id, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* createPostItSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    const response = yield call(api.createPostIt, company_id, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updatePostItSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    const response = yield call(api.updatePostIt, company_id, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* removePostItSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    yield call(api.removePostIt, company_id, payload)
    yield call(resolve, payload.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//! POST-IT on dashboard
function* fetchInvoicePostItsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchInvoicePostIts, companyId, payload)

    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchPostIt.REQUEST, fetchPostItSaga)
  yield takeLatest(actions.createPostIt.REQUEST, createPostItSaga)
  yield takeLatest(actions.updatePostIt.REQUEST, updatePostItSaga)
  yield takeLatest(actions.removePostIt.REQUEST, removePostItSaga)
  yield takeLatest(actions.fetchInvoicePostIts.REQUEST, fetchInvoicePostItsSaga)
}
