import React from 'react'

import styled from 'styled-components'

function BaseZoomOutIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(25 / 24) * size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
    >
      <path
        d="M9.75793 2.86609C11.7471 2.86609 13.6547 3.65627 15.0612 5.06279C16.4678 6.46931 17.2579 8.37697 17.2579 10.3661C17.2579 12.3552 16.4678 14.2629 15.0612 15.6694C13.6547 17.0759 11.7471 17.8661 9.75793 17.8661C7.76881 17.8661 5.86116 17.0759 4.45463 15.6694C3.04811 14.2629 2.25793 12.3552 2.25793 10.3661C2.25793 8.37697 3.04811 6.46931 4.45463 5.06279C5.86116 3.65627 7.76881 2.86609 9.75793 2.86609ZM9.75793 20.1161C12.0454 20.1161 14.1501 19.3286 15.8095 18.0114L22.0861 24.288C22.5267 24.7286 23.2392 24.7286 23.6751 24.288C24.1111 23.8473 24.1157 23.1348 23.6751 22.6989L17.4032 16.4177C18.7204 14.7583 19.5079 12.6536 19.5079 10.3661C19.5079 4.98015 15.1439 0.616089 9.75793 0.616089C4.372 0.616089 0.00793457 4.98015 0.00793457 10.3661C0.00793457 15.752 4.372 20.1161 9.75793 20.1161ZM6.38293 9.24109C5.7595 9.24109 5.25793 9.74265 5.25793 10.3661C5.25793 10.9895 5.7595 11.4911 6.38293 11.4911H13.1329C13.7564 11.4911 14.2579 10.9895 14.2579 10.3661C14.2579 9.74265 13.7564 9.24109 13.1329 9.24109H6.38293Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const ZoomOutIcon = styled(BaseZoomOutIcon)``
