import React from 'react'
import PropTypes from 'prop-types'

import { useIntercom } from 'react-use-intercom'

import { InheritLink, PrimaryInheritLink } from '@components/ui/Links'

import { useIntercomProps } from './useIntercomProps'

type LinkProps = Omit<SupportLinkProps, 'inherit'> & {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}
interface SupportLinkProps {
  children: React.ReactNode
  className?: string
  inherit?: boolean
}

interface IntercomLink extends Omit<SupportLinkProps, 'inherit'> {
  Component: React.ComponentType<LinkProps>
}

function IntercomLink({ children, className, Component }: IntercomLink) {
  const { show } = useIntercom()

  function handleClick() {
    show()
  }

  return (
    <Component className={className} onClick={handleClick}>
      {children}
    </Component>
  )
}

export function IntercomInheritLink({ children, className, inherit = false }: SupportLinkProps) {
  const LinkComponent = inherit ? InheritLink : PrimaryInheritLink
  const props = useIntercomProps(className)

  if (window.intercomAppId) {
    return (
      <IntercomLink Component={LinkComponent} className={className}>
        {children}
      </IntercomLink>
    )
  }

  return <LinkComponent {...props}>{children}</LinkComponent>
}

IntercomInheritLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inherit: PropTypes.bool,
}
