import React from 'react'

import { FormattedMessage } from 'react-intl'

export const DownloadFileMessage = <FormattedMessage id="docViewer.fab.downloadFile" defaultMessage="Letöltés" />

export const DownloadFileErrorMessage = (
  <FormattedMessage id="docViewer.fab.downloadFile.error" defaultMessage="Hiba történt a dokumentum letöltése közben" />
)

export const LandscapeViewMessage = (
  <FormattedMessage id="docViewer.fab.landscapeViewMessage" defaultMessage="Fekvő nézet" />
)

export const OrignalZoomMessage = (
  <FormattedMessage id="docViewer.fab.originalZoomMessage" defaultMessage="Eredeti méret" />
)

export const PortraitViewMessage = (
  <FormattedMessage id="docViewer.fab.portraitViewMessage" defaultMessage="Álló nézet" />
)

export const ResetZoomMessage = (
  <FormattedMessage id="docViewer.fab.zoomResetMessage" defaultMessage="Zoom visszaállítása" />
)
