import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { authActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { PublicPage } from '@oldComponents/pages'

import styles from './styles'

const useStyles = makeStyles(styles)

interface InitializeUserProps {
  fetchUser: AsyncFunction
}

function PureInitializeUser({ fetchUser }: InitializeUserProps) {
  const classes = useStyles()

  React.useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <PublicPage>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.root}>
          <div className={classes.loader}>
            <CircularProgress color="primary" size={50} />
          </div>
          <Typography variant="body1">
            <FormattedMessage id="pages.initialize.title" defaultMessage="Felhasználó azonosítása folyamatban..." />
          </Typography>
        </Grid>
      </Grid>
    </PublicPage>
  )
}

PureInitializeUser.propTypes = {
  fetchUser: PropTypes.func.isRequired,
}

export const InitializeUser = connect(null, dispatch => ({
  fetchUser: bindActionToPromise(dispatch, authActions.fetchUser.request),
}))(PureInitializeUser)

InitializeUser.displayName = 'InitializeUser'
