import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { FilterSwitch } from './FilterSwitch'

const WrapperDiv = styled.div`
  display: flex;
  padding: 16px 20px;
  row-gap: 6px;
  column-gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
`

interface StatusFilterComponentProps {
  className?: string
  config: readonly StatusFilterConfig[]
  onChange: (payload: Partial<Record<StatusFilterNames, boolean | undefined>>) => void
  value: Record<string, boolean | undefined>
}

/**
 * Component to display the status filter
 *
 * @param {StatusFilterComponentProps} { config, value, onChange }
 * @returns {React.ReactElement} - The status filter component
 */
export function StatusFilter({ config, value, onChange, className }: StatusFilterComponentProps) {
  function handleChange(filterConfig: StatusFilterConfig) {
    return function handler(name: string, value?: boolean) {
      if (filterConfig.onChangeOverWriteRules && value !== undefined) {
        onChange({ [name]: value, ...filterConfig.onChangeOverWriteRules })
      } else {
        onChange({ [name]: value })
      }
    }
  }

  return (
    <WrapperDiv className={className}>
      {config.map(filterConfig => (
        <FilterSwitch
          currentValue={value[filterConfig.name]}
          key={filterConfig.name}
          name={filterConfig.name}
          onChange={handleChange(filterConfig)}
          options={filterConfig.options}
        />
      ))}
    </WrapperDiv>
  )
}

StatusFilter.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChangeOverWriteRules: PropTypes.object,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.bool,
          label: PropTypes.node.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ),
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}
