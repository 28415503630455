import React from 'react'
import PropTypes from 'prop-types'

import { CopyAndPasteButton, TooLongText } from '@components/ui'
import { DuplicatedBadge } from '@oldComponents/ui'

interface InvoiceNumberProps extends Record<string, unknown> {
  badges: InvoiceBadgeValue[]
  invoiceNumber: Nullable<string>
  origin: InvoiceOrigin
}

export function InvoiceNumber({ invoiceNumber, badges, origin }: InvoiceNumberProps) {
  const isDuplicated = badges.includes('duplicate')
  const isNavDuplicated = origin === 'nav'

  if (isDuplicated) {
    return <DuplicatedBadge isNav={isNavDuplicated} />
  }

  if (invoiceNumber == null) {
    return <span>-</span>
  }

  return (
    <CopyAndPasteButton text={invoiceNumber} noWrap>
      <TooLongText value={invoiceNumber} />
    </CopyAndPasteButton>
  )
}

InvoiceNumber.propTypes = {
  invoiceNumber: PropTypes.string,
  badges: PropTypes.array.isRequired,
  origin: PropTypes.string.isRequired,
}
