import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { unreachable } from '@helpers'

import { Typography } from '@components/ui/Typography'

import horizontalBarChartPlaceholderSrc from '@assets/img/horizontal_bar_chart_placeholder.svg'
import verticalBarChartPlaceholderSrc from '@assets/img/vertical_bar_chart_placeholder.svg'

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
`

function getPlaceholderSrc(variant: ChartPlaceholderProps['variant']) {
  switch (variant) {
    case 'stackedBarChart':
      return verticalBarChartPlaceholderSrc
    case 'breakdownChart':
      return horizontalBarChartPlaceholderSrc
    default:
      return unreachable(variant)
  }
}

interface ChartPlaceholderProps {
  variant: 'stackedBarChart' | 'breakdownChart'
}

export default function ChartPlaceholder({ variant }: ChartPlaceholderProps) {
  return (
    <WrapperDiv>
      <img role="presentation" alt="" src={getPlaceholderSrc(variant)} />
      <Typography size="400-xs" color="gray-40" italic>
        <FormattedMessage id="chart.placeholder.text" defaultMessage="Nincs adat a grafikon megjelenítéséhez" />
      </Typography>
    </WrapperDiv>
  )
}

ChartPlaceholder.propTypes = {
  variant: PropTypes.oneOf(['stackedBarChart', 'breakdownChart']).isRequired,
}
