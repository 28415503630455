import React from 'react'

import { PDFControlsContext } from './PDFControlsProvider'
import { PDFSinglePage } from './PDFSinglePage'

export function PDFAllPages() {
  const {
    state: { numPages },
  } = React.useContext(PDFControlsContext)

  return (
    <>
      {Array.from(Array(numPages).keys()).map(index => (
        <PDFSinglePage key={index + 1} pageNum={index + 1} />
      ))}
    </>
  )
}
