import React from 'react'

import { FormattedMessage } from 'react-intl'

import { SUBSCRIPTION_PLANS_OBJ } from '@constants/subscriptions'

const BaseFeature1 = (
  <FormattedMessage
    id="subscription.plan.base.feature1"
    defaultMessage="Automatikus számlaszinkronizáció és azonosítás, villámgyors iktatás"
  />
)
const BaseFeature2 = (
  <FormattedMessage
    id="subscription.plan.base.feature2"
    defaultMessage="Költségek és bevételek címkézése, automatikus csoportosítása"
  />
)
const BaseFeature3 = (
  <FormattedMessage
    id="subscription.plan.base.feature3"
    defaultMessage="Utalási csomag készítése több, mint tíz népszerű bankszámlaszolgáltató felé"
  />
)
const BaseFeature4 = (
  <FormattedMessage
    id="subscription.plan.base.feature4"
    defaultMessage="Tartozások és bevételek monitorozása, élő áfa-pozíció becslés"
  />
)
const BaseFeature5 = (
  <FormattedMessage
    id="subscription.plan.base.feature5"
    defaultMessage="Adatexport PDF, Excel formátumban, akár API kapcsolaton keresztül is"
  />
)

const FinanceFeature1 = (
  <FormattedMessage
    id="subscription.plan.finance.feature1"
    defaultMessage="Közvetlen kapcsolódás a NAV Online Számla rendszerhez"
  />
)
const FinanceFeature2 = (
  <FormattedMessage
    id="subscription.plan.finance.feature2"
    defaultMessage="Összesített egyenlegek és tranzakciók megjelenítése, Autokassza kapcsolat"
  />
)
const FinanceFeature3 = (
  <FormattedMessage
    id="subscription.plan.finance.feature3"
    defaultMessage="Pénzmozgások követése az összes bankszámlád tranzakciói alapján"
  />
)
const FinanceFeature4 = (
  <FormattedMessage
    id="subscription.plan.finance.feature4"
    defaultMessage="Harminc napos likviditási modellezés, értéknapos lekérdezés"
  />
)
const FinanceFeature5 = (
  <FormattedMessage
    id="subscription.plan.finance.feature5"
    defaultMessage="Havi eredmény nézet teljesítési idő, számviteli időszak és cash flow alapján"
  />
)

const MultiFeature1 = (
  <FormattedMessage
    id="subscription.plan.multi.feature1"
    defaultMessage="Jogosultságok kezelése több felhasználó vagy pénzügyi csapatok esetén"
  />
)
const MultiFeature2 = (
  <FormattedMessage
    id="subscription.plan.multi.feature2"
    defaultMessage="Komplex számlaérkeztetési folyamatok és dokumentum kezelés"
  />
)
const MultiFeature3 = (
  <FormattedMessage
    id="subscription.plan.multi.feature3"
    defaultMessage="Bevételek címkézése és kategorizálás egyedi scriptek alapján"
  />
)
const MultiFeature4 = (
  <FormattedMessage
    id="subscription.plan.multi.feature4"
    defaultMessage="Elsőbbségi ügyfélszolgálat, egyedi kiszolgálás és fejlesztői támogatás"
  />
)
const MultiFeature5 = (
  <FormattedMessage
    id="subscription.plan.multi.feature5"
    defaultMessage="Dokumentumtár korlátlan tárhellyel, kategorizálási és AI funkciókkal"
  />
)

export const FEATURES = {
  [SUBSCRIPTION_PLANS_OBJ.szamla]: {
    title: <FormattedMessage id="subscription.plan.base.title" defaultMessage="Minden, ami a QUiCK legjava:" />,
    features: [BaseFeature1, BaseFeature2, BaseFeature3, BaseFeature4, BaseFeature5],
  },
  [SUBSCRIPTION_PLANS_OBJ.okos]: {
    title: <FormattedMessage id="subscription.plan.finance.title" defaultMessage="Minden SzámlaBox funkció, plusz:" />,
    features: [FinanceFeature2, FinanceFeature3, FinanceFeature4, FinanceFeature5, FinanceFeature1],
  },
  [SUBSCRIPTION_PLANS_OBJ.multi]: {
    title: <FormattedMessage id="subscription.plan.multi.title" defaultMessage="Minden OkosBox funkció, plusz:" />,
    features: [MultiFeature1, MultiFeature2, MultiFeature3, MultiFeature4, MultiFeature5],
  },
}
