import React from 'react'

import { ZoomOrientation, ZoomState } from '@components/DocViewer/constant'

import { ZoomControls } from '../ZoomControls'
import { changeZoomOrientation, setZoomLevel } from './actions'
import { ImageControlsContext } from './ImageControlsProvider'
import { initialImageState } from './reducer'
import { getDefaultZoomLevel, getZoomResetState } from './zoom'

import {
  LandscapeViewMessage,
  OrignalZoomMessage,
  PortraitViewMessage,
  ResetZoomMessage,
} from '@components/DocViewer/messages'

export const ZOOM_STATE_MESSAGES = {
  [ZoomState.RESET]: ResetZoomMessage,
  [ZoomState.CHANGE_TO_LANDSCAPE]: LandscapeViewMessage,
  [ZoomState.CHANGE_TO_PORTRAIT]: PortraitViewMessage,
  [ZoomState.ORIGINAL_SIZE]: OrignalZoomMessage,
}

export function ImageControls() {
  const {
    state: { zoomLevel, zoomJump, zoomOrientation, defaultZoomLevel, imageHeight, imageWidth, mainState },
    dispatch,
  } = React.useContext(ImageControlsContext)
  const imageOrientation =
    imageHeight && imageWidth && imageHeight > imageWidth ? ZoomOrientation.PORTRAIT : ZoomOrientation.LANDSCAPE

  function handleZoomIn() {
    dispatch(setZoomLevel(zoomLevel + zoomJump))
  }

  function handleZoomOut() {
    dispatch(setZoomLevel(zoomLevel - zoomJump))
  }

  function handleZoomReset() {
    if (imageHeight && imageWidth) {
      if (imageOrientation === ZoomOrientation.PORTRAIT) {
        if (zoomLevel !== defaultZoomLevel) {
          // when zoomLevel is modified in any orientation reset it
          dispatch(setZoomLevel(defaultZoomLevel))
        } else {
          const newZoomOrientation =
            zoomOrientation === ZoomOrientation.LANDSCAPE ? ZoomOrientation.PORTRAIT : ZoomOrientation.LANDSCAPE
          dispatch(
            changeZoomOrientation({
              defaultZoomLevel: getDefaultZoomLevel(newZoomOrientation, imageWidth, imageHeight, mainState),
              zoomOrientation: newZoomOrientation,
            })
          )
        }
      } else {
        if (zoomLevel !== defaultZoomLevel) {
          // when zoomLevel is modified in any orientation reset it
          dispatch(setZoomLevel(defaultZoomLevel))
        } else {
          dispatch(setZoomLevel(initialImageState.defaultZoomLevel))
        }
      }
    }
  }

  const zoomResetState = getZoomResetState({ zoomOrientation, zoomLevel, defaultZoomLevel, imageOrientation })

  return (
    <ZoomControls
      handleZoomIn={handleZoomIn}
      handleZoomOut={handleZoomOut}
      handleZoomReset={handleZoomReset}
      zoomResetTitle={ZOOM_STATE_MESSAGES[zoomResetState]}
    />
  )
}
