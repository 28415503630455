import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import __uniqueId from 'lodash/uniqueId'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ActionMeta, GroupBase, SelectComponentsConfig } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { dashboardActions } from '@services'

import { isFieldHighlighted } from '@helpers'
import { recommendationMessages } from '@helpers/recommendation'
import { bindActionToPromise } from '@helpers/service'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { usePromptTextCreator } from '@hooks'

import { Typography } from '@components/ui'
import { WindowedMenuList } from '@oldComponents/ui'

import { LEDGER_NUMBER_WITH_OPTIONAL_NAME_REGEX, OPTIONAL_DIGITS_REGEX } from '@constants'

import { CustomLedgerNumberOption } from './CustomLedgerNumberOption'
import { generateSelectProps, getNewOptionData, getValueComponents, isValidNewOption } from './helpers'
import { LedgerNumberSelectOptionValue, LedgerNumberSuggestion } from './types'

import { selectFormStyles as customReactSelectStyles } from '@styles/select'
import { formSelectMessages } from '@messages'

const componentOverridesProp: {
  components: SelectComponentsConfig<LedgerNumberSelectOptionValue, false, GroupBase<LedgerNumberSelectOptionValue>>
} = {
  components: {
    Option: CustomLedgerNumberOption,
  },
}

export interface LedgerNumberSelectOwnProps
  extends Pick<FieldRenderProps<Nullable<LedgerNumber>, HTMLSelectElement>, 'input'> {
  disabled?: boolean
  hasError: boolean
  highlighted?: boolean
  suggestion?: LedgerNumberSuggestion
  recommendations?: number[]
}

interface LedgerNumberSelectProps extends LedgerNumberSelectOwnProps {
  createNewOption: AsyncFunction<Omit<LedgerNumber, 'id'>, LedgerNumber>
  options: LedgerNumber[]
  isPaidThroughLedgerNumber?: boolean
}

export function PureLedgerNumberSelect({
  hasError,
  createNewOption,
  disabled,
  highlighted = false,
  input: { onBlur, onChange, value, ...inputProps },
  options,
  suggestion,
  recommendations = [],
  isPaidThroughLedgerNumber = false, // a flag to indicate if the ledger number is for PaidThrough
}: LedgerNumberSelectProps) {
  const [loading, setLoading] = React.useState(false)
  const { setErrorAlert } = useAlertDispatch()
  const timerRef = React.useRef<number | undefined>(undefined)
  const [inputValue, setInputValue] = React.useState('')
  const promptTextCreator = usePromptTextCreator()
  const { formatMessage } = useIntl()
  const { selectValue, selectOptions } = generateSelectProps({
    value,
    options,
    suggestion,
    recommendations,
    recommendationsLabel: formatMessage(recommendationMessages.recommendationsLabel),
    optionsLabel: formatMessage(recommendationMessages.optionsLabel),
  })

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  // use react-window when has more than 9 options
  if (options.length > 9) {
    componentOverridesProp.components = {
      ...componentOverridesProp.components,
      // @ts-expect-error windowend menu list cant handle props corretly with generic types
      MenuList: WindowedMenuList,
    }
  }

  function handleInputChange(rawValue: string) {
    const inputValue =
      (rawValue.length >= 2 && rawValue.match(LEDGER_NUMBER_WITH_OPTIONAL_NAME_REGEX)) || // already entered minimum 2 digits
      (rawValue.length < 2 && rawValue.match(OPTIONAL_DIGITS_REGEX)) // enter at least 2 digits first
        ? rawValue
        : rawValue.substring(0, rawValue.length - 1)
    setInputValue(inputValue)
  }

  function handleBlur() {
    timerRef.current = window.setTimeout(() => onBlur(), 100)
  }

  function handleChange(
    option: Nullable<LedgerNumberSelectOptionValue>,
    { action }: ActionMeta<LedgerNumberSelectOptionValue>
  ) {
    if (action === 'select-option' && option) {
      onChange(option.data) // set to form fields
    }
    if (action === 'clear') {
      onChange(null) // set to form fields
    }
  }

  async function handleCreateOption(inputValue: string) {
    setLoading(true)
    const data = getValueComponents(inputValue)
    try {
      const result = await createNewOption(data)
      onChange(result)
    } catch (error) {
      // display error in snackalert
      setErrorAlert(error)
    }
    setLoading(false)
  }

  function noOptionsMessage() {
    if (!selectOptions.length) {
      if (isPaidThroughLedgerNumber) {
        return formatMessage(formSelectMessages.paidThroughLedgerNumberEmptyCreateText)
      }
      return formatMessage(formSelectMessages.ledgerNumberEmptyCreateText)
    }
    return formatMessage(formSelectMessages.selectNoResultsText)
  }

  const ledgerNumberName = selectValue?.data.name

  return (
    <>
      <CreatableSelect
        classNamePrefix="react-select"
        instanceId={__uniqueId('rs')}
        inputId={inputProps.name}
        value={selectValue}
        className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
        isDisabled={disabled}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onBlur={handleBlur}
        options={selectOptions}
        placeholder={formatMessage(formSelectMessages.selectPlaceholder)}
        noOptionsMessage={noOptionsMessage}
        getNewOptionData={getNewOptionData}
        onCreateOption={handleCreateOption}
        formatCreateLabel={promptTextCreator}
        isValidNewOption={
          (inputValue, selectValue, selectOptions) =>
            isValidNewOption(inputValue, selectValue as any, selectOptions, isPaidThroughLedgerNumber) // need this cast because this callback "selectValue" type is wrong
        }
        styles={customReactSelectStyles}
        isClearable
        isLoading={loading}
        {...componentOverridesProp}
      />
      {ledgerNumberName && (
        <Typography size="400-xs" italic>
          {ledgerNumberName}
        </Typography>
      )}
    </>
  )
}

export const propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool.isRequired,
  highlighted: PropTypes.bool,
  input: PropTypes.object.isRequired as React.Validator<LedgerNumberSelectProps['input']>,
}

PureLedgerNumberSelect.propTypes = {
  ...propTypes,
  createNewOption: PropTypes.func.isRequired,
  isPaidThroughLedgerNumber: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }).isRequired as React.Validator<LedgerNumber>
  ).isRequired,
  recommendations: PropTypes.arrayOf(PropTypes.number.isRequired),
  suggestion: PropTypes.shape({
    code: PropTypes.string.isRequired,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
  }) as React.Validator<LedgerNumberSuggestion | undefined>,
}

export const LedgerNumberSelect = connect(
  (state: Store) => ({
    options: state.dashboard.ledgerNumbers,
  }),
  dispatch => ({
    createNewOption: bindActionToPromise(dispatch, dashboardActions.createLedgerNumber.request),
  })
)(PureLedgerNumberSelect)

LedgerNumberSelect.displayName = 'LedgerNumberSelect'
