//! This is a copy and modified version of react-table > useRowSelect plugin
import React from 'react'

import { ensurePluginOrder, makePropGetter, useGetLatest, useMountedLayoutEffect } from 'react-table'

const defaultGetToggleRowSelectedProps = (props, { instance, row }) => {
  const { manualRowSelectedKey = 'isSelected' } = instance
  let checked = false

  if (row.original && row.original[manualRowSelectedKey]) {
    checked = true
  } else {
    checked = row.isSelected
  }

  return [
    props,
    {
      onChange: e => {
        row.toggleRowSelected(e.target.checked)
      },
      style: {
        cursor: 'pointer',
      },
      checked,
      title: 'Toggle Row Selected',
      indeterminate: row.isSomeSelected,
    },
  ]
}

const defaultGetToggleAllRowsSelectedProps = (props, { instance }) => [
  props,
  {
    onChange: e => {
      instance.toggleAllRowsSelected(e.target.checked)
    },
    style: {
      cursor: 'pointer',
    },
    checked: instance.isAllRowsSelected,
    title: 'Toggle All Rows Selected',
    indeterminate: Boolean(!instance.isAllRowsSelected && Object.keys(instance.selectedRowIds).length),
  },
]

function useInstance(instance) {
  const {
    data,
    rows,
    getHooks,
    plugins,
    rowsById,
    nonGroupedRowsById = rowsById,
    autoResetSelectedRows = true,
    selectedRowIds,
    onSelectChange,
    selectSubRows = true,
    getSubRows,
  } = instance

  ensurePluginOrder(
    plugins,
    ['useFilters', 'useGroupBy', 'useSortBy', 'useExpanded', 'usePagination'],
    'useControlledRowSelect'
  )

  const getAutoResetSelectedRows = useGetLatest(autoResetSelectedRows)

  useMountedLayoutEffect(() => {
    if (getAutoResetSelectedRows()) {
      onSelectChange({ isAllSelected: false, selected: [] })
    }
  }, [data])

  let isAllRowsSelected = Boolean(Object.keys(nonGroupedRowsById).length && Object.keys(selectedRowIds).length)

  if (isAllRowsSelected) {
    //! filter out expandable rows from nonGroupedRowsById because those rows do not affect isAllSelected
    //! only works in QUiCK becasue expanded rows are not involved in calculations and action calls
    if (Object.keys(nonGroupedRowsById).some(id => !nonGroupedRowsById[id].canExpand && !selectedRowIds[id])) {
      isAllRowsSelected = false
    }
  }

  const selectedFlatRows = React.useMemo(() => {
    const selectedFlatRows = []

    rows.forEach(row => {
      const isSelected = selectSubRows ? getRowIsSelected(row, selectedRowIds, getSubRows) : !!selectedRowIds[row.id]
      row.isSelected = !!isSelected
      row.isSomeSelected = isSelected === null

      if (isSelected) {
        selectedFlatRows.push(row)
      }
    })

    return selectedFlatRows
  }, [rows, selectSubRows, selectedRowIds, getSubRows])

  const toggleAllRowsSelected = React.useCallback(
    value => {
      const selectAll = typeof value !== 'undefined' ? value : !isAllRowsSelected

      // Only remove/add the rows that are visible on the screen
      //  Leave all the other rows that are selected alone.
      const newSelectedRowIds = Object.assign({}, selectedRowIds)

      if (selectAll) {
        Object.keys(nonGroupedRowsById).forEach(rowId => {
          newSelectedRowIds[rowId] = true
        })
      } else {
        Object.keys(nonGroupedRowsById).forEach(rowId => {
          delete newSelectedRowIds[rowId]
        })
      }

      onSelectChange({ selectedRowIds: newSelectedRowIds })
    },
    [isAllRowsSelected, nonGroupedRowsById, onSelectChange, selectedRowIds]
  )

  const toggleRowSelected = React.useCallback(
    (id, value) => {
      const isSelected = selectedRowIds[id]
      const shouldExist = typeof value !== 'undefined' ? value : !isSelected

      if (isSelected === shouldExist) {
        return
      }

      const newSelectedRowIds = { ...selectedRowIds }

      const handleRowById = id => {
        const row = rowsById[id]

        if (!row.isGrouped) {
          if (shouldExist) {
            newSelectedRowIds[id] = true
          } else {
            delete newSelectedRowIds[id]
          }
        }

        if (selectSubRows && getSubRows(row)) {
          return getSubRows(row).forEach(row => handleRowById(row.id))
        }
      }

      handleRowById(id)
      onSelectChange({ selectedRowIds: newSelectedRowIds })
    },
    [getSubRows, onSelectChange, rowsById, selectSubRows, selectedRowIds]
  )

  const getInstance = useGetLatest(instance)

  const getToggleAllRowsSelectedProps = makePropGetter(getHooks().getToggleAllRowsSelectedProps, {
    instance: getInstance(),
  })

  Object.assign(instance, {
    selectedFlatRows,
    isAllRowsSelected,
    toggleRowSelected,
    toggleAllRowsSelected,
    getToggleAllRowsSelectedProps,
  })
}

function prepareRow(row, { instance }) {
  row.toggleRowSelected = set => instance.toggleRowSelected(row.id, set)

  row.getToggleRowSelectedProps = makePropGetter(instance.getHooks().getToggleRowSelectedProps, {
    instance: instance,
    row,
  })
}

function getRowIsSelected(row, selectedRowIds, getSubRows) {
  if (selectedRowIds[row.id]) {
    return true
  }

  const subRows = getSubRows(row)

  if (subRows && subRows.length) {
    let allChildrenSelected = true
    let someSelected = false

    subRows.forEach(subRow => {
      // Bail out early if we know both of these
      if (someSelected && !allChildrenSelected) {
        return
      }

      if (getRowIsSelected(subRow, selectedRowIds, getSubRows)) {
        someSelected = true
      } else {
        allChildrenSelected = false
      }
    })
    return allChildrenSelected ? true : someSelected ? null : false
  }

  return false
}

export default function useControlledRowSelect(hooks) {
  hooks.getToggleRowSelectedProps = [defaultGetToggleRowSelectedProps]
  hooks.getToggleAllRowsSelectedProps = [defaultGetToggleAllRowsSelectedProps]
  hooks.useInstance.push(useInstance)
  hooks.prepareRow.push(prepareRow)
}

useControlledRowSelect.pluginName = 'useControlledRowSelect'
