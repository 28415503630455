import React from 'react'

import styled from 'styled-components'

function BaseWalletPlusIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.8662 24.3908h10.7026c.4575.705 1.0499 1.3163 1.7362 1.8H6.9c-1.3256 0-2.4-1.0762-2.4-2.4v-8.4c0-1.3237 1.0744-2.4 2.4-2.4h14.4c.8925 0 1.6688.4838 2.0813 1.2075-.0938-.0037-.1875-.0075-.2813-.0075-.9825 0-1.9162.2138-2.7525.6H6.9c-.3315 0-.6.27-.6.6v8.4c0 .33.2685.6.6.6h-.0338ZM21 9.9908c.4988 0 .9.4031.9.9 0 .4988-.4012.9-.9.9H7.1662c-.4634 0-.9-.4012-.9-.9 0-.4969.4365-.9.9-.9H21Zm-1.8-3c.4987 0 .9.4031.9.9a.899.899 0 0 1-.9.9H8.9663c-.465 0-.9-.4031-.9-.9s.435-.9.9-.9H19.2Zm-1.5 13.8c0-2.9812 2.4188-5.4 5.4-5.4 2.9813 0 5.4 2.4188 5.4 5.4 0 2.9813-2.4187 5.4-5.4 5.4-2.9812 0-5.4-2.4187-5.4-5.4Zm6-2.4337c0-.2963-.27-.6-.6-.6-.33 0-.6.3037-.6.6v1.8h-1.8c-.33 0-.6.3037-.6.6 0 .3637.27.6.6.6h1.8v1.8c0 .3637.27.6.6.6.33 0 .6-.2363.6-.6v-1.8h1.8c.33 0 .6-.2363.6-.6 0-.2963-.27-.6-.6-.6h-1.8v-1.8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const WalletPlusIcon = styled(BaseWalletPlusIcon)``
