import { DATA_TABS, PULSE_REDIRECT_COMPANY_TYPES, RouteKeys, ROUTES } from '@constants'

import { getRouteUrl, replaceCompanyIdInPath } from './url'

export interface TransactionsSubRouteProps {
  isDokumentsVisibleForUser: boolean
  isExpenseVisibleForUser: boolean
  isIncomeVisibleForUser: boolean
  isQuarantineVisibleForUser: boolean
  isSalaryVisibleForUser: boolean
  isTaxVisibleForUser: boolean
  tabOrder: TransactionsDataTypeProps[]
}
interface RouterUserPermissions extends TransactionsSubRouteProps {
  isCashflowVisibleForUser: boolean
  isLiquidityVisibleForUser: boolean
  isMonthlyStatementVisibleForUser: boolean
  isPartnersVisibleForUser: boolean
  isPulseRedirectPreferred: boolean
  isPulseVisibleForUser: boolean
}

export function getPulseRedirectPreferredForCompanyType(companyType: Nullable<string> | undefined) {
  return companyType != null && (PULSE_REDIRECT_COMPANY_TYPES as string[]).includes(companyType)
}

export function getDefaultRedirectUrlWithCompany(
  companyId: number,
  {
    isCashflowVisibleForUser,
    isDokumentsVisibleForUser,
    isExpenseVisibleForUser,
    isIncomeVisibleForUser,
    isLiquidityVisibleForUser,
    isMonthlyStatementVisibleForUser,
    isPartnersVisibleForUser,
    isPulseRedirectPreferred,
    isPulseVisibleForUser,
    isQuarantineVisibleForUser,
    isSalaryVisibleForUser,
    isTaxVisibleForUser,
    tabOrder,
  }: RouterUserPermissions
) {
  if (isPulseVisibleForUser && isPulseRedirectPreferred) {
    return getRouteUrl(RouteKeys.PULSE, companyId)
  }

  const subRouteUrl = getTransactionsSubRoute({
    isDokumentsVisibleForUser,
    isExpenseVisibleForUser,
    isIncomeVisibleForUser,
    isQuarantineVisibleForUser,
    isSalaryVisibleForUser,
    isTaxVisibleForUser,
    tabOrder,
  })
  if (subRouteUrl) {
    return replaceCompanyIdInPath(subRouteUrl, companyId)
  }
  if (isLiquidityVisibleForUser) {
    return getRouteUrl(RouteKeys.LIQUIDITY, companyId)
  }
  if (isPartnersVisibleForUser) {
    return getRouteUrl(RouteKeys.PARTNERS, companyId).replace(':partner_type', 'providers')
  }
  if (isMonthlyStatementVisibleForUser) {
    return getRouteUrl(RouteKeys.MONTHLY_STATEMENT, companyId)
  }
  if (isCashflowVisibleForUser) {
    return getRouteUrl(RouteKeys.PAYMENT_ORDERS, companyId)
  }
  if (isPulseVisibleForUser) {
    return getRouteUrl(RouteKeys.PULSE, companyId)
  }
  return ROUTES.profile
}

export function getDefaultRedirectUrl(availableCompanies: Array<{ id: number }>) {
  // has company
  if (availableCompanies.length > 1) {
    return ROUTES.selectCompany
  }
  if (availableCompanies[0]) {
    // redirect to base company root route - that will set the final destination
    return replaceCompanyIdInPath(`${ROUTES.root}/:company_id/`, availableCompanies[0].id)
  }
  // do not have company

  return ROUTES.noCompany
}

const ROUTE_MAPPER = {
  [DATA_TABS.expense]: ROUTES[RouteKeys.EXPENSE_LIST],
  [DATA_TABS.quarantine]: ROUTES[RouteKeys.QUARANTINE_LIST],
  [DATA_TABS.income]: ROUTES[RouteKeys.INCOME_LIST],
  [DATA_TABS.salary]: ROUTES[RouteKeys.SALARY_LIST],
  [DATA_TABS.tax]: ROUTES[RouteKeys.TAX_LIST],
  [DATA_TABS.dokuments]: ROUTES[RouteKeys.DOKUMENT_LIST],
}

export function getTransactionsSubRoute({
  isDokumentsVisibleForUser,
  isExpenseVisibleForUser,
  isIncomeVisibleForUser,
  isQuarantineVisibleForUser,
  isSalaryVisibleForUser,
  isTaxVisibleForUser,
  tabOrder,
}: TransactionsSubRouteProps) {
  const permissions = {
    [DATA_TABS.expense]: isExpenseVisibleForUser,
    [DATA_TABS.quarantine]: isQuarantineVisibleForUser,
    [DATA_TABS.income]: isIncomeVisibleForUser,
    [DATA_TABS.salary]: isSalaryVisibleForUser,
    [DATA_TABS.tax]: isTaxVisibleForUser,
    [DATA_TABS.dokuments]: isDokumentsVisibleForUser,
  }
  const routeUrl = tabOrder.reduce((url, tab) => {
    if (!url && permissions[tab]) {
      return ROUTE_MAPPER[tab]
    }
    return url
  }, null as Nullable<string>)

  // manually check dokuments while is not in tabOrder
  if (!routeUrl && isDokumentsVisibleForUser) {
    return ROUTES[RouteKeys.DOKUMENT_LIST]
  }

  return routeUrl
}
