import '../../assets/css/style.css'

import React from 'react'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'
import Helmet from 'react-helmet'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import store from '@services/store'

import { AlertProvider, ConnectedIntlProvider, SuccessDialogProvider } from '@contexts'

import { InitializeApplication } from '@components/InitializeApplication'
import { QuickIntercomProvider } from '@components/Intercom'

import { APP_TITLE, STYLED_THEME, THEME as MUI_THEME } from '@constants'

import CheckingMobileBrowser from '../CheckingMobileBrowser'
import router from './router'

import { GlobalStyle } from '@styles/GlobalStyle'

function App() {
  return (
    <QuickIntercomProvider>
      <Provider store={store}>
        <StyledThemeProvider theme={STYLED_THEME}>
          <GlobalStyle />
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={MUI_THEME}>
              <CssBaseline />
              <ConnectedIntlProvider>
                <CheckingMobileBrowser>
                  <SuccessDialogProvider>
                    <AlertProvider>
                      <Helmet title={APP_TITLE} htmlAttributes={{ lang: store.getState().locale.language }} />
                      <InitializeApplication>
                        <RouterProvider router={router} />
                      </InitializeApplication>
                    </AlertProvider>
                  </SuccessDialogProvider>
                </CheckingMobileBrowser>
              </ConnectedIntlProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </StyledThemeProvider>
      </Provider>
    </QuickIntercomProvider>
  )
}

export default App
