// TODO: create separate component for these

import React from 'react'

import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const Dialog = withStyles(theme => ({
  dialog: {
    color: theme.palette.neutral.mDark,
  },
}))(({ classes, ...rest }) => <MuiDialog classes={{ root: classes.dialog }} {...rest} />)

export const DialogTitle = withStyles(() => ({
  dialogTitle: {
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 700,
    paddingTop: 50,
  },
}))(({ classes, ...rest }) => <MuiDialogTitle classes={{ root: classes.dialogTitle }} disableTypography {...rest} />)

export const DialogContent = withStyles(() => ({
  dialogContent: {
    textAlign: 'center',
    paddingLeft: 90,
    paddingRight: 90,
    lineHeight: 1.6,
    minWidth: 500,

    '&>form': {
      paddingBottom: 50,
    },
  },
}))(({ classes, ...rest }) => <MuiDialogContent classes={{ root: classes.dialogContent }} {...rest} />)

export const DialogActions = withStyles(() => ({
  dialogActions: {
    paddingBottom: 50,
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: 'center',

    '&>button': {
      fontWeight: '600 !important',
    },

    '&>* + *': {
      marginLeft: '1rem',
    },
  },
}))(({ classes, ...rest }) => <MuiDialogActions classes={{ root: classes.dialogActions }} {...rest} />)
