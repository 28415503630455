import styled from 'styled-components'

import { Typography } from '../Typography'

export const TooltipColumn = styled.div<{ align?: 'left' | 'center' | 'right' }>`
  text-align: ${({ align = 'left' }) => align};
`

export const TooltipTitle = styled(Typography).attrs({
  color: 'gray-80',
  size: '700-sm',
})`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.common.paperStroke}`};
  padding: 20px;
`
export const TooltipContainer = styled.div`
  padding: 20px;
`
export const TooltipContentContainer = styled(TooltipContainer)`
  min-width: 310px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 20px;
  row-gap: 5px;
`
