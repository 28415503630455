import React from 'react'

import { FormattedMessage } from 'react-intl'

export const SimpleNextMessage = <FormattedMessage id="general.text.next" defaultMessage="Tovább" />

export const NameMessage = <FormattedMessage id="general.text.name" defaultMessage="Név" />

export const CloseButtonMessage = <FormattedMessage id="general.buttons.close" defaultMessage="Bezár" />

export const CancelButtonMessage = <FormattedMessage id="general.buttons.cancel" defaultMessage="Mégse" />

export const ContinueButtonMessage = <FormattedMessage id="general.buttons.continue" defaultMessage="Tovább" />

export const SupportButtonMessage = <FormattedMessage id="general.buttons.support" defaultMessage="Segítséget kérek" />

export const SaveButtonMessage = <FormattedMessage id="general.buttons.save" defaultMessage="Mentés" />

export const ResetButtonMessage = <FormattedMessage id="general.buttons.reset" defaultMessage="Elvetés" />

export const ApiErrorMessage = (
  <FormattedMessage id="general.text.apiError" defaultMessage="Az adatok betöltése közben hiba történt" />
)

export const DataLoadingMessage = (
  <FormattedMessage id="general.text.dataLoading" defaultMessage="Adatok betöltése folyamatban..." />
)

export const TableNoDataMessage = (
  <FormattedMessage id="general.table.noData" defaultMessage="Nincs megjeleníthető adat" />
)

export const OptionYesMessage = <FormattedMessage id="general.options.yes" defaultMessage="Igen" />

export const OptionNoMessage = <FormattedMessage id="general.options.no" defaultMessage="Nem" />

export const OptionTrueMessage = <FormattedMessage id="general.options.true" defaultMessage="Igaz" />

export const OptionFalseMessage = <FormattedMessage id="general.options.false" defaultMessage="Hamis" />

export const DeleteButtonMessage = <FormattedMessage id="general.buttons.delete" defaultMessage="Törlés" />

export const UploadButtonMessage = <FormattedMessage id="general.buttons.upload" defaultMessage="Feltöltés" />

export const DeletePermanentlyButtonMessage = (
  <FormattedMessage id="general.buttons.deletePermanently" defaultMessage="Törlés véglegesen" />
)

export const DialogCompleteTitle = <FormattedMessage id="general.dialog.title.complete" defaultMessage="Kész! 🎉" />

export const OkButtonMessage = <FormattedMessage id="general.buttons.ok" defaultMessage="OK" />

export const EditButtonMessage = <FormattedMessage id="general.buttons.edit" defaultMessage="Szerkesztés" />

export const AcceptButtonMessage = <FormattedMessage id="general.buttons.accept" defaultMessage="Elfogadás" />

export const DoneButtonMessage = <FormattedMessage id="general.buttons.done" defaultMessage="Kész" />

export const CreateButtonMessage = <FormattedMessage id="dialogs.buttons.create" defaultMessage="Létrehozás" />

export const CopyFullAmountButtonMessage = (
  <FormattedMessage id="paymentEditor.buttons.copyTotalAmount" defaultMessage="Teljes összeg" />
)

export const AcceptNewsletterMessage = (
  <FormattedMessage
    id="form.label.newsletterText"
    defaultMessage="Elfogadom és hozzájárulok, hogy a program helyes működésével, frissítésekkel kapcsolatos tájékoztatókat kapjak. (Marketingcélú hírleveleket nem küldünk.)"
  />
)

export const AddNewButtonMessage = <FormattedMessage id="general.buttons.addNew" defaultMessage="Új hozzáadása" />

export const XmlUploadButtonMessage = (
  <FormattedMessage id="general.buttons.xmlUpload" defaultMessage="XML feltöltése" />
)

export const EndPeriodMessage = (
  <FormattedMessage id="general.periods.endOfYearlyPeriod" defaultMessage="év végén nyitott" />
)

export const GoToSzamlazzHuButtonMessage = (
  <FormattedMessage id="general.buttons.goToSzamlazzHu" defaultMessage="Tovább a Számlázz.hu-ra" />
)

export const NotAvailableValueMessage = <FormattedMessage id="placeholder.notAvailable" defaultMessage="N/A" />

export const MoreInformationMessage = (
  <FormattedMessage id="general.text.moreInformation" defaultMessage="További információ" />
)

export const ClearFiltersButtonMessage = (
  <FormattedMessage id="pageFilterBar.buttons.tooltip.clear" defaultMessage="Szűrők törlése" />
)

export const EmailLabelMessage = <FormattedMessage id="companyMembers.table.th.email" defaultMessage="E-mail cím" />

export const CloseAndNavigatteButtonMessage = (
  <FormattedMessage id="dialog.buttons.closeAndNavigate" defaultMessage="Megnézem" />
)

export const AttentionDialogCloseButtonMessage = (
  <FormattedMessage id="dialog.buttons.attentionClose" defaultMessage="Értem" />
)

export const SendButtonMessage = <FormattedMessage id="general.buttons.send" defaultMessage="Küldés" />

export const TaxDeleteConfirmTextMessage = (
  <FormattedMessage
    id="dialogs.confirm.deleteTax.confirmText"
    defaultMessage="Az adó a törlést követően többé nem lesz elérhető a rendszerben. A folyamat nem visszafordítható."
  />
)

export const CustomerServiceButtonOrLinkMessage = (
  <FormattedMessage id="buttons.customerService" defaultMessage="Írok az ügyfélszolgálatnak" />
)

export const BackToExpenseListButtonMessage = (
  <FormattedMessage id="buttons.backToList.expenses" defaultMessage="Vissza a számlákhoz" />
)

export const BackToIncomeListButtonMessage = (
  <FormattedMessage id="buttons.backToList.incomes" defaultMessage="Vissza a bevételekhez" />
)

export const BackToMonthlyStatementButtonMessage = (
  <FormattedMessage id="buttons.backTo.monthlyStatement" defaultMessage="Vissza a havi eredményhez" />
)

export const BackToPaymentOrdersButtonMessage = (
  <FormattedMessage id="buttons.backTo.paymentOrders" defaultMessage="Vissza az utalási csomagokhoz" />
)

export const PrintChartButtonMessage = (
  <FormattedMessage id="monthlyChart.print.title" defaultMessage="Grafikon letöltése" />
)

export const ReloadPageButtonMessage = <FormattedMessage id="buttons.pageReload" defaultMessage="Oldal újratöltése" />

export const FileUploadErrorMessage = (
  <FormattedMessage
    id="fileUpload.errors.invalidFiles"
    defaultMessage="Hiba történt egy vagy több fájl feltöltése során."
  />
)

export const BackToDocumentsButtonMessage = (
  <FormattedMessage id="buttons.backToList.documents" defaultMessage="Vissza a dokumentumokhoz" />
)

export const BackToExpenseButtonMessage = (
  <FormattedMessage id="buttons.backTo.expenseDetails" defaultMessage="Vissza a számlához" />
)

export const BackToLiquidityButtonMessage = (
  <FormattedMessage id="buttons.backTo.liquidity" defaultMessage="Vissza a likviditáshoz" />
)

export const BackToPartnersListButtonMessage = (
  <FormattedMessage id="buttons.backTo.partnersList" defaultMessage="Vissza a partnerekhez" />
)

export const GoToLinkMessage = <FormattedMessage id="general.buttons.goToLink" defaultMessage="Ugrás a hivatkozáshoz" />

export const FilingButtonMessage = <FormattedMessage id="general.buttons.filing" defaultMessage="Iktatás" />

export const FilingConfirmDialogTitleMessage = (
  <FormattedMessage id="dialogs.filing.confirmTitle" defaultMessage="Biztosan iktatod?" />
)

export const UnexpectedErrorMessage = (
  <FormattedMessage id="general.errors.unexpected" defaultMessage="Váratlan hiba történt" />
)

export const CalculatorTotalMessage = (
  <FormattedMessage id="payment.calculator.totalAmount" defaultMessage="Összesen:" />
)

export const ExportButtonMessage = <FormattedMessage id="buttons.export" defaultMessage="Adatok exportja" />

export const ConfirmDialogTitleMessage = (
  <FormattedMessage id="dialogs.confirm.title" defaultMessage="Biztos vagy benne?" />
)
