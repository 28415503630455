import React from 'react'

import { useWatch } from 'react-hook-form'

interface ReactHookFormConditionalFieldProps {
  children: React.ReactNode
  isVisible?: boolean
  name: string
  condition?: boolean | ((value: any) => boolean)
}

export function ReactHookFormConditionalField({
  children,
  condition,
  isVisible = false,
  name,
}: ReactHookFormConditionalFieldProps) {
  const value = useWatch({ name })

  let shouldRender = value
  if (condition != null) {
    shouldRender = typeof condition === 'function' ? condition(value) : condition
  }

  return <>{(shouldRender || isVisible) && children}</>
}
