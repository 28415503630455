import React from 'react'

import styled from 'styled-components'

function BaseFileExportIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 10.2272c0-2.0083 1.6281-3.6364 3.6364-3.6364h8.1818c2.0083 0 3.6363 1.628 3.6363 3.6364v12.7273c0 2.0083-1.628 3.6363-3.6363 3.6363h-8.1818c-1.4198 0-2.6495-.8136-3.2483-2h2.4548c.2398.1165.509.1818.7935.1818h3.182v-2.7274H15.5v-1.8181h.8184v-2.6363H15.5v-2h.8184v-2.6364h-5v-1.8182h5v-2.727h1.8181v2.727h4.9999v-.909c0-1.0042-.8141-1.8182-1.8182-1.8182h-8.1818c-1.0042 0-1.8182.814-1.8182 1.8182v3.3636H9.5v-3.3636Zm8.6365 14.5454v-2.7274h4.9999v.9093c0 1.0041-.8141 1.8181-1.8182 1.8181h-3.1817Zm0-4.5455h4.9999v-2.6363h-4.9999v2.6363Zm4.9999-4.6363v-2.6364h-4.9999v2.6364h4.9999Z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 14.5908c-.5523 0-1 .4477-1 1v7c0 .5523.4477 1 1 1h7c.5523 0 1-.4477 1-1v-7c0-.5523-.4477-1-1-1h-7Zm4.8984 7.5h1.0664l-1.914-2.9336 1.7773-2.7773h-1.0039l-1.3203 2.164-1.3281-2.164H7.6406l1.7696 2.75-1.8985 2.9609h1.004l1.4452-2.3398 1.4375 2.3398Z"
      />
    </svg>
  )
}

export const FileExportIcon = styled(BaseFileExportIcon)``
