import React from 'react'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomDialogBody, VisuallyHidden } from '@components/ui'

const TRANSITION_TIME = 300 //ms

const OverlayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  transition: all ${TRANSITION_TIME}ms ease-in-out;

  ::after {
    position: absolute;
    content: '';
    background-color: ${({ theme }) => theme.colors.gray[0]};
    transition: all ${TRANSITION_TIME}ms ease-in-out;
    z-index: -1;
    width: inherit;
    height: inherit;
  }

  ${CustomDialogBody}[aria-busy='true'] .MuiCircularProgress-root {
    opacity: 1;
  }
  ${CustomDialogBody}[aria-busy='false'] .MuiCircularProgress-root {
    opacity: 0;
  }

  ${CustomDialogBody}[aria-busy='true'] & {
    z-index: 1;

    ::after {
      opacity: 0.8;
    }
  }

  ${CustomDialogBody}[aria-busy='false'] &::after {
    opacity: 0;
  }
`

type LoadingDialogOverlayProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'aria-hidden'> & {
  loading: boolean
}

export function LoadingDialogOverlay({ loading, ...rest }: LoadingDialogOverlayProps) {
  const [isMounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    if (loading) {
      setMounted(true)
    }
  }, [loading])

  React.useEffect(() => {
    if (!loading && isMounted) {
      const timeout = window.setTimeout(() => setMounted(false), TRANSITION_TIME)
      return () => {
        window.clearTimeout(timeout)
      }
    }
  }, [isMounted, loading])

  return (
    <OverlayWrapper aria-hidden={!loading} {...rest}>
      <VisuallyHidden>
        <FormattedMessage id="assistive.text.loading" defaultMessage="Töltés folyamatban" />
      </VisuallyHidden>
      {isMounted && <CircularProgress size={20} color="primary" />}
    </OverlayWrapper>
  )
}
