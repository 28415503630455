import React from 'react'

import styled from 'styled-components'

import { Button, ButtonProps } from './Button'

export interface ButtonWithIconProps extends ButtonProps {
  icon?: React.ReactChild
}

const StyledButton = styled(Button)`
  gap: 4px;
`

const IconWrapperSpan = styled.span`
  display: inherit;
  align-items: inherit;
`

// TODO left-padding control by size
// TODO icon size control by size
function PureButtonWithIcon({ icon, children, ...rest }: ButtonWithIconProps) {
  return (
    <StyledButton {...rest}>
      <>
        {icon && <IconWrapperSpan>{icon}</IconWrapperSpan>}
        {children}
      </>
    </StyledButton>
  )
}

// need to handle forwardedAs
export const ButtonWithIcon = styled(PureButtonWithIcon)``
