import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { SimpleDialog } from '@components/ui/Dialogs'

import { CancelButtonMessage, ConfirmDialogTitleMessage, ResetButtonMessage } from '@messages'

export const DetailsResetMessage = (
  <FormattedMessage id="form.button.detailsReset" defaultMessage="Módosítások elvetése" />
)

const StyledSimpleDialog = styled(SimpleDialog)`
  --gap: 30px;
  --horizontal-padding: 80px;
  --vertical-padding: 40px;
  min-width: 360px;
`

interface FormResetConfirmDialogProps {
  descriptionMessage?: StringOrMessage
  open: boolean
  onClose: VoidFunction
  onConfirm: VoidFunction
}

export function FormResetConfirmDialog({ descriptionMessage, open, onClose, onConfirm }: FormResetConfirmDialogProps) {
  return (
    <StyledSimpleDialog
      description={
        descriptionMessage ?? (
          <FormattedMessage
            id="dialogs.confirm.formReset.description"
            defaultMessage="Biztosan elveted a módosításokat?"
          />
        )
      }
      onClose={onClose}
      onPrimaryAction={onClose}
      onSecondaryAction={onConfirm}
      open={open}
      primaryActionText={CancelButtonMessage}
      secondaryActionText={ResetButtonMessage}
      title={ConfirmDialogTitleMessage}
    />
  )
}
