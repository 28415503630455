import { Theme } from '@material-ui/core/styles'
import { StyleRulesCallback } from '@material-ui/styles'

import { generateMaxHeightBreakPoint } from '@constants'

const styles: StyleRulesCallback<Theme, Record<never, never>> = theme => ({
  root: {
    flexGrow: 1,
    flexWrap: 'nowrap',
    backgroundColor: '#F3F3F3',

    '&.blue-theme': {
      backgroundColor: theme.palette.tertiary.pageBackground,
    },
  },
  minWidth: {
    minWidth: 1200,
  },
  warningBarIsActive: {
    paddingTop: theme.appBarHeight,
    [generateMaxHeightBreakPoint('medium')]: {
      top: theme.appBarHeightMedium,
    },
  },
})

export default styles
