import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'

import styled, { DefaultTheme } from 'styled-components'

import { OutlineEffectStyles } from '@styles'

function CheckmarkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" fill="none">
      <g filter="url(#dropshadow)">
        <path
          fill="currentColor"
          d="M12.281 2.22a.751.751 0 0 1 0 1.061l-6 6a.751.751 0 0 1-1.061 0l-3-3A.751.751 0 0 1 3.28 5.22l2.47 2.468 5.471-5.468a.751.751 0 0 1 1.062 0h-.003Z"
        />
      </g>
      <defs>
        <filter
          id="dropshadow"
          width="14.501"
          height="11.501"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_144_7" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_144_7" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

function IndeterminateCheckmarkIcon() {
  return (
    <svg width="12" height="3" viewBox="0 0 12 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.486084" width="12" height="2" rx="1" fill="currentColor" />
    </svg>
  )
}

type StyleProps = { $checked?: boolean; $disabled?: boolean; $error?: boolean; theme: DefaultTheme }

function getConditionalStylesAttrs({ theme: { colors }, $error, $disabled, $checked }: StyleProps) {
  let backgroundColor: string = colors.gray[0]
  let color: string = colors.gray[0]
  let defaultBorderColor: string = colors.gray[40]
  let focusBorderColor: string = colors.primary[30]
  let hoverBorderColor: string = colors.gray[30]

  if ($error) {
    defaultBorderColor = focusBorderColor = hoverBorderColor = colors.error[50]
  } else if ($disabled) {
    color = defaultBorderColor = focusBorderColor = hoverBorderColor = colors.gray[40]
    backgroundColor = colors.gray[20]
  } else if ($checked) {
    backgroundColor = defaultBorderColor = focusBorderColor = colors.primary[50]
    hoverBorderColor = colors.primary[30]
  }

  return {
    $cssBackgroundColor: backgroundColor,
    $cssColor: color,
    $cssDefaultBorderColor: defaultBorderColor,
    $cssFocusBorderColor: focusBorderColor,
    $cssHoverBorderColor: hoverBorderColor,
  }
}

export const StyledCheckbox = styled.div.attrs(getConditionalStylesAttrs)<StyleProps>`
  align-items: center;
  background-color: ${({ $cssBackgroundColor }) => $cssBackgroundColor};
  border-color: ${({ $cssDefaultBorderColor }) => $cssDefaultBorderColor};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${({ $cssColor }) => $cssColor};
  display: flex;
  height: 20px;
  justify-content: center;
  transition: background-color 250ms ease, border-color 250ms ease;
  width: 20px;

  ${OutlineEffectStyles}

  &:hover {
    border-color: ${({ $cssHoverBorderColor }) => $cssHoverBorderColor};
  }
  &:focus-within {
    border-color: ${({ $cssFocusBorderColor }) => $cssFocusBorderColor};
  }
  &:focus-within {
    &::before {
      border-color: ${({ theme }) => theme.colors.primary[20]};
    }
  }

  input {
    cursor: inherit;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`

export interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  checked?: boolean
  className?: string
  disabled?: boolean
  error?: boolean
  indeterminate?: boolean
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  title?: string
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, indeterminate = false, disabled = false, checked, error = false, ...rest }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>()
    const resolvedRef = (ref as React.RefObject<HTMLInputElement>) ?? defaultRef

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate
      }
    }, [resolvedRef, indeterminate])

    const icon = indeterminate ? <IndeterminateCheckmarkIcon /> : checked ? <CheckmarkIcon /> : null

    return (
      <StyledCheckbox className={className} $checked={checked || indeterminate} $disabled={disabled} $error={error}>
        <input
          type="checkbox"
          ref={resolvedRef}
          onClick={e => e.stopPropagation()}
          defaultChecked={checked}
          disabled={disabled}
          {...rest}
        />
        {icon}
      </StyledCheckbox>
    )
  }
)

Checkbox.displayName = 'Checkbox'

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
  indeterminate: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
}
