import Qs from 'qs'

import { injectCompanyToURL } from '@helpers'
import { transformV2ParamsToV1 } from '@services/helpers'

import Api from '../api'
import {
  API_PARTNER_CALCULATION_BASE_URL,
  API_PARTNER_DETAILS_URL,
  API_PARTNER_INVOICE_COUNT_URL,
  API_PARTNER_LIST_EXPORT_URL,
  API_PARTNER_LIST_URL,
  API_PARTNER_MERGE_URL,
} from './urls'

export function fetchPartners(companyId, { page, search, ...params }) {
  return Api.get(injectCompanyToURL(API_PARTNER_LIST_URL, companyId), {
    params: {
      ...params,
      name: search, // migrate back to v1
      page: page + 1, // shift page because mui pagination start with 0 and BE pagination start with 1
    },
    paramsSerializer: {
      serialize(v2Params) {
        return Qs.stringify(transformV2ParamsToV1(v2Params), { indices: false })
      },
    },
  })
}

export function searchPartners(companyId, { page, ...params }) {
  const url = `${injectCompanyToURL(API_PARTNER_LIST_URL, companyId)}dropdown/`
  return Api.get(url, {
    params,
    paramsSerializer: {
      serialize(v2Params) {
        return Qs.stringify(transformV2ParamsToV1(v2Params), { indices: false })
      },
    },
  })
}

export function fetchPartnerDetails(companyId, partnerId) {
  return Api.get(injectCompanyToURL(API_PARTNER_DETAILS_URL, companyId).replace('{partner_id}', partnerId))
}

export function updatePartner({ company_id, id: partnerId, ...data }) {
  return Api.patch(injectCompanyToURL(API_PARTNER_DETAILS_URL, company_id).replace('{partner_id}', partnerId), data)
}

export function updatePartnerCalculationBase({ company, id, ...data }) {
  return Api.post(injectCompanyToURL(API_PARTNER_CALCULATION_BASE_URL, company).replace('{partner_id}', id), data)
}

export function createPartner({ company_id, ...data }) {
  return Api.post(injectCompanyToURL(API_PARTNER_LIST_URL, company_id), data)
}

export function removePartner({ companyId, partnerId }) {
  return Api.delete(injectCompanyToURL(API_PARTNER_DETAILS_URL, companyId).replace('{partner_id}', partnerId))
}

export function exportPartners(companyId, params) {
  return Api.post(injectCompanyToURL(API_PARTNER_LIST_EXPORT_URL, companyId), params)
}

export function getPartnerInvoiceCount({ companyId, partnerId }) {
  return Api.get(injectCompanyToURL(API_PARTNER_INVOICE_COUNT_URL, companyId).replace('{partner_id}', partnerId))
}

export function mergeAndRemovePartner({ companyId, partnerId, ...data }) {
  return Api.post(injectCompanyToURL(API_PARTNER_MERGE_URL, companyId).replace('{partner_id}', partnerId), data)
}
