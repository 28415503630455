import React from 'react'
import PropTypes from 'prop-types'

import { ListItemIcon, MenuItem, MenuList, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import cx from 'classnames'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { formatDate } from '@helpers'

import { DATE_WIDGET_VARIANTS as VARIANTS } from '../constants'

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 700,
    color: theme.palette.grayscale.black,
    fontSize: 12,
    lineHeight: 1.4,
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  list: {
    padding: '8px 0',
    listStyle: 'none',
    '&.list': {
      padding: 0,
      display: 'grid',
      gridTemplateRows: 'repeat(4, 1fr)',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridAutoFlow: 'column',
      gridRowGap: 10,
      gridColumnGap: 20,
    },
  },
  listItem: ({ variant }: Pick<DatePeriodsMenuProps, 'variant'>) =>
    variant === VARIANTS.list
      ? {
          padding: 0,
          fontSize: 12,
          lineHeight: 1.4,
          fontWeight: 700,
          minHeight: 0,
          color: theme.palette.tertiary.oceanBlue,

          '&:hover': {
            backgroundColor: 'initial',
            textDecoration: 'underline',
          },
          '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 10,
            fontSize: 14,
            color: 'inherit',
          },
        }
      : {
          padding: '5px 20px',
          height: 30,
          minHeight: 0,
          display: 'flex',
          alignItems: 'center',
          fontSize: 14,
          lineHeight: '20px',
          color: theme.palette.grayscale.black,
          boxSizing: 'border-box',

          '&:hover': {
            backgroundColor: `${theme.palette.tertiary.oceanBlue}10`,
          },
        },
}))

const PERIODS = {
  previousYear: 'previousYear',
  currentYear: 'currentYear',
  previousQuarter: 'previousQuarter',
  currentQuarter: 'currentQuarter',
  previousMonth: 'previousMonth',
  currentMonth: 'currentMonth',
  previousWeek: 'previousWeek',
  currentWeek: 'currentWeek',
} as const

interface DatePeriodsMenuProps {
  disabled?: boolean
  isDateFilterToggleDisabled?: boolean
  isToggleHidden?: boolean
  onChange: (values: { fromDate: string; toDate: string }) => void
  onToggle?: (payload: boolean) => void
  variant: ConstObjectValues<typeof VARIANTS>
}

export default function DatePeriodsMenu({
  disabled = false,
  isDateFilterToggleDisabled = false,
  isToggleHidden = false,
  onChange,
  onToggle,
  variant,
}: DatePeriodsMenuProps) {
  const classes = useStyles({ variant })

  const handleChange = (type: ConstObjectValues<typeof PERIODS>) => () => {
    let fromDate = formatDate(moment())
    let toDate = formatDate(moment())
    switch (type) {
      case PERIODS.previousYear: {
        fromDate = formatDate(moment().subtract(1, 'year').startOf('year'))
        toDate = formatDate(moment().subtract(1, 'year').endOf('year'))
        break
      }
      case PERIODS.currentYear: {
        fromDate = formatDate(moment().startOf('year'))
        toDate = formatDate(moment().endOf('year'))
        break
      }
      case PERIODS.previousQuarter: {
        const currentQuarterNumber = moment().quarter()
        if (currentQuarterNumber === 1) {
          fromDate = formatDate(moment().subtract(1, 'year').quarter(4).startOf('quarter'))
          toDate = formatDate(moment().subtract(1, 'year').endOf('year'))
        } else {
          fromDate = formatDate(
            moment()
              .quarter(currentQuarterNumber - 1)
              .startOf('quarter')
          )
          toDate = formatDate(
            moment()
              .quarter(currentQuarterNumber - 1)
              .endOf('quarter')
          )
        }
        break
      }
      case PERIODS.currentQuarter: {
        const currentQuarterNumber = moment().quarter()
        fromDate = formatDate(moment().quarter(currentQuarterNumber).startOf('quarter'))
        toDate = formatDate(
          currentQuarterNumber === 4 ? moment().endOf('year') : moment().quarter(currentQuarterNumber).endOf('quarter')
        )
        break
      }
      case PERIODS.previousMonth: {
        fromDate = formatDate(moment().subtract(1, 'month').startOf('month'))
        toDate = formatDate(moment().subtract(1, 'month').endOf('month'))
        break
      }
      case PERIODS.currentMonth: {
        fromDate = formatDate(moment().startOf('month'))
        toDate = formatDate(moment().endOf('month'))
        break
      }
      case PERIODS.previousWeek: {
        fromDate = formatDate(moment().subtract(1, 'week').startOf('week'))
        toDate = formatDate(moment().subtract(1, 'week').endOf('week'))
        break
      }
      case PERIODS.currentWeek: {
        fromDate = formatDate(moment().startOf('week'))
        toDate = formatDate(moment().endOf('week'))
        break
      }
      default:
        return
    }

    onChange({ fromDate, toDate })
  }

  function handleDateFilterToggle() {
    onToggle?.(!disabled)
  }

  return (
    <>
      {variant === VARIANTS.list && (
        <Typography variant="h4" className={classes.title}>
          <FormattedMessage id="datePeriodsMenu.title" defaultMessage="Időszakok" />
        </Typography>
      )}
      <MenuList className={cx(classes.list, { list: variant === VARIANTS.list })}>
        <MenuItem className={classes.listItem} onClick={handleChange(PERIODS.currentYear)}>
          {variant === VARIANTS.list && (
            <ListItemIcon>
              <ChevronRightIcon fontSize="inherit" color="inherit" />
            </ListItemIcon>
          )}
          <Typography variant="inherit">
            <FormattedMessage id="datePeriodsMenu.periods.currentYear" defaultMessage="Aktuális év" />
          </Typography>
        </MenuItem>

        <MenuItem className={classes.listItem} onClick={handleChange(PERIODS.currentQuarter)}>
          {variant === VARIANTS.list && (
            <ListItemIcon>
              <ChevronRightIcon fontSize="inherit" color="inherit" />
            </ListItemIcon>
          )}
          <Typography variant="inherit">
            <FormattedMessage id="datePeriodsMenu.periods.currentQuarter" defaultMessage="Aktuális negyedév" />
          </Typography>
        </MenuItem>

        <MenuItem className={classes.listItem} onClick={handleChange(PERIODS.currentMonth)}>
          {variant === VARIANTS.list && (
            <ListItemIcon>
              <ChevronRightIcon fontSize="inherit" color="inherit" />
            </ListItemIcon>
          )}
          <Typography variant="inherit">
            <FormattedMessage id="datePeriodsMenu.periods.currentMonth" defaultMessage="Aktuális hónap" />
          </Typography>
        </MenuItem>

        <MenuItem className={classes.listItem} onClick={handleChange(PERIODS.currentWeek)}>
          {variant === VARIANTS.list && (
            <ListItemIcon>
              <ChevronRightIcon fontSize="inherit" color="inherit" />
            </ListItemIcon>
          )}
          <Typography variant="inherit">
            <FormattedMessage id="datePeriodsMenu.periods.currentWeek" defaultMessage="Aktuális hét" />
          </Typography>
        </MenuItem>

        <MenuItem className={classes.listItem} onClick={handleChange(PERIODS.previousYear)}>
          {variant === VARIANTS.list && (
            <ListItemIcon>
              <ChevronRightIcon fontSize="inherit" color="inherit" />
            </ListItemIcon>
          )}
          <Typography variant="inherit">
            <FormattedMessage id="datePeriodsMenu.periods.previousYear" defaultMessage="Előző év" />
          </Typography>
        </MenuItem>

        <MenuItem className={classes.listItem} onClick={handleChange(PERIODS.previousQuarter)}>
          {variant === VARIANTS.list && (
            <ListItemIcon>
              <ChevronRightIcon fontSize="inherit" color="inherit" />
            </ListItemIcon>
          )}
          <Typography variant="inherit">
            <FormattedMessage id="datePeriodsMenu.periods.previousQuarter" defaultMessage="Előző negyedév" />
          </Typography>
        </MenuItem>

        <MenuItem className={classes.listItem} onClick={handleChange(PERIODS.previousMonth)}>
          {variant === VARIANTS.list && (
            <ListItemIcon>
              <ChevronRightIcon fontSize="inherit" color="inherit" />
            </ListItemIcon>
          )}
          <Typography variant="inherit">
            <FormattedMessage id="datePeriodsMenu.periods.previousMonth" defaultMessage="Előző hónap" />
          </Typography>
        </MenuItem>

        <MenuItem className={classes.listItem} onClick={handleChange(PERIODS.previousWeek)}>
          {variant === VARIANTS.list && (
            <ListItemIcon>
              <ChevronRightIcon fontSize="inherit" color="inherit" />
            </ListItemIcon>
          )}
          <Typography variant="inherit">
            <FormattedMessage id="datePeriodsMenu.periods.previousWeek" defaultMessage="Előző hét" />
          </Typography>
        </MenuItem>

        {!isToggleHidden && (
          <MenuItem className={classes.listItem} onClick={handleDateFilterToggle} disabled={isDateFilterToggleDisabled}>
            {variant === VARIANTS.list && (
              <ListItemIcon>
                <ChevronRightIcon fontSize="inherit" color="inherit" />
              </ListItemIcon>
            )}
            <Typography variant="inherit">
              {disabled ? (
                <FormattedMessage id="datePeriodsMenu.toggle.enable" defaultMessage="Dátumszűrés bekapcsolása" />
              ) : (
                <FormattedMessage id="datePeriodsMenu.toggle.disable" defaultMessage="Dátumszűrés kikapcsolása" />
              )}
            </Typography>
          </MenuItem>
        )}
      </MenuList>
    </>
  )
}

DatePeriodsMenu.propTypes = {
  disabled: PropTypes.bool,
  isDateFilterToggleDisabled: PropTypes.bool,
  isToggleHidden: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
}
