import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { FieldRenderProps } from 'react-final-form'

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginTop: 10,
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    color: theme.palette.grayscale.xxxDark,
    fontWeight: 700,
    lineHeight: 1.4,
  },
  value: {
    fontSize: 16,
    lineHeight: 1.4,
    color: theme.palette.neutral.mDark,
  },
  error: {
    fontSize: '0.8571428571428571rem',
    marginTop: 3,
    lineHeight: 1.66,
  },
}))

type ReadOnlyFieldProps = FieldRenderProps<string, HTMLElement> & {
  label: React.ReactNode
  format?: (value: string) => string
}

export default function ReadOnlyField({
  className,
  input: { value },
  label,
  meta: { touched, error, submitError },
  format,
}: ReadOnlyFieldProps) {
  const classes = useStyles()
  return (
    <div className={cx(classes.root, className)}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{(format?.(value) ?? value) || '-'}</Typography>
      {touched && (submitError || error) && (
        <Typography className={classes.error} color="error">
          {error || submitError}
        </Typography>
      )}
    </div>
  )
}

ReadOnlyField.propTypes = {
  className: PropTypes.string,
  format: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.node,
    submitError: PropTypes.node,
  }).isRequired,
}
