import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQuery } from '@material-ui/core'
import __noop from 'lodash/noop'
import { matchRoutes, useLocation } from 'react-router-dom'

import { ROUTES } from '@constants'

import { CompactNavbar } from './CompactNavbar'
import {
  DateFilterPageConfig,
  DateFilterWrapper,
  PageConfig,
  PageDateFilter,
  PageLink,
  PageMenu,
  PageNavbarContainer,
  SubPageConfig,
} from './elements'

const DEFAULT_DATE_FILTER_CONFIG: DateFilterPageConfig = {
  onChange: __noop,
  onToggle: __noop,
  disabled: false,
  dateTypeOptions: [],
  getDateTypeValue: () => '',
}

interface PageNavbarBaseProps {
  links: PageLink[]
  saveLastVisitedSubPage?: (to: string) => void
  subPageConfig?: SubPageConfig
}

export function PageNavbar({ links, subPageConfig, saveLastVisitedSubPage }: PageNavbarBaseProps) {
  const { pathname } = useLocation()
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const config: PageConfig | undefined = React.useMemo(() => {
    if (!subPageConfig) {
      return undefined
    }
    // find the route(s) that match for current pathname
    const routes = Object.keys(subPageConfig).map(routeKey => ({ path: ROUTES[routeKey as keyof typeof ROUTES] }))
    const matchedPages = matchRoutes(routes, pathname)
    // find the key of the 1st matched page or return undefined if no match
    if (!matchedPages) {
      return undefined
    } else {
      const matchedPage = matchedPages[0]
      const matchedKey = Object.keys(subPageConfig).find(routeKey => {
        return matchedPage.route.path === ROUTES[routeKey as keyof typeof ROUTES]
      }) as keyof typeof subPageConfig // we already have a match here so it can not be null

      return subPageConfig[matchedKey]
    }
  }, [pathname, subPageConfig])

  const dateFilterConfig = config?.dateFilter

  return (
    <>
      {isDesktop && <CompactNavbar dateFilterConfig={dateFilterConfig} links={links} />}
      <PageNavbarContainer>
        <PageMenu links={links} saveLastVisitedSubPage={saveLastVisitedSubPage} />
        <DateFilterWrapper $disabled={!dateFilterConfig}>
          <PageDateFilter
            dateTypeOptions={dateFilterConfig?.dateTypeOptions ?? DEFAULT_DATE_FILTER_CONFIG.dateTypeOptions}
            defaultView="year"
            disabled={dateFilterConfig?.disabled ?? DEFAULT_DATE_FILTER_CONFIG.disabled}
            getDateTypeValue={dateFilterConfig?.getDateTypeValue ?? DEFAULT_DATE_FILTER_CONFIG.getDateTypeValue}
            isDateFilterToggleDisabled={dateFilterConfig?.isDateFilterToggleDisabled}
            onChange={dateFilterConfig?.onChange ?? DEFAULT_DATE_FILTER_CONFIG.onChange}
            onToggle={dateFilterConfig?.onToggle ?? DEFAULT_DATE_FILTER_CONFIG.onToggle}
            variant="widget"
          />
        </DateFilterWrapper>
      </PageNavbarContainer>
    </>
  )
}

PageNavbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      companyPlanPermission: PropTypes.shape({
        perform: PropTypes.string.isRequired,
        upsellMessage: PropTypes.node,
      }),
      label: PropTypes.node.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  subPageConfig: PropTypes.shape({
    dateFilter: PropTypes.shape({
      dateTypeOptions: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          label: PropTypes.node.isRequired,
        }).isRequired
      ).isRequired,
      dateTypeValue: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }),
  }),
  saveLastVisitedSubPage: PropTypes.func,
}
