import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { ButtonWithIcon } from './Buttons'
import { LeftArrowIcon } from './svgIcons'

interface JumpToInvoiceDetailsLinkButtonProps {
  detailsUrl: string
}

export function JumpToInvoiceDetailsLinkButton({ detailsUrl }: JumpToInvoiceDetailsLinkButtonProps) {
  const location = useLocation()
  return (
    <ButtonWithIcon
      variant="primaryText"
      size="small"
      icon={<LeftArrowIcon />}
      forwardedAs={function CustomLink({ children, className }) {
        return (
          <Link className={className} to={detailsUrl} state={{ previousPath: location.pathname }}>
            {children}
          </Link>
        )
      }}
    >
      <FormattedMessage id="document.preview.linkToInvoiceDetails" defaultMessage="Ugrás a számlarészletekhez" />
    </ButtonWithIcon>
  )
}

JumpToInvoiceDetailsLinkButton.propTypes = {
  detailsUrl: PropTypes.string.isRequired,
}
