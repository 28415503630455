import React from 'react'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Page } from 'react-pdf'
import styled from 'styled-components'

import { usePostIt } from '@components/DocViewer/hooks'
import { Typography } from '@components/ui'

import { A4_PAPER_SIZE } from '@constants'

import { PDFControlsContext } from './PDFControlsProvider'

const PageWrapper = styled.div`
  & + & {
    margin-top: 20px;
  }
`

interface PDFSinglePageProps {
  pageNum?: number
}

export function PDFSinglePage({ pageNum }: PDFSinglePageProps) {
  const {
    state: { paginated, zoomLevel, numPages, currentPage },
  } = React.useContext(PDFControlsContext)
  const { renderPostItLayer } = usePostIt()

  const _pageNum = pageNum || currentPage

  return (
    <PageWrapper>
      {!paginated && numPages > 1 && (
        <Typography size="400-xs" color="gray-80">
          <FormattedMessage
            id="docViewer.pdfRenderer.page"
            defaultMessage="Oldal {currentPage}/{allPagesCount}"
            values={{
              currentPage: _pageNum,
              allPagesCount: numPages,
            }}
          />
        </Typography>
      )}
      <Page
        pageNumber={_pageNum || currentPage}
        scale={zoomLevel}
        height={A4_PAPER_SIZE.height}
        width={A4_PAPER_SIZE.width}
        loading={<CircularProgress size={20} color="inherit" />}
      />
      {renderPostItLayer?.(zoomLevel, _pageNum)}
    </PageWrapper>
  )
}
