import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'
import { components, OptionProps } from 'react-select'
import styled from 'styled-components'

import { CustomOption } from '@oldComponents/pages/CegjelzoPage/Fields'

import { PartnerOptionData } from './types'

const MessageOption = styled.div`
  padding: 8px 12px;
  line-height: 1.1;
  color: #b1b1b1;
  font-size: 80%;
`

function LoadingIndicator() {
  return <CircularProgress color="secondary" size={16} />
}

function PartnerCustomOption({ children, ...rest }: OptionProps<PartnerOptionData>) {
  const {
    data: { prefix, isMsg, ...optionData },
  } = rest

  if (isMsg) {
    return <MessageOption>{children}</MessageOption>
  }

  return (
    <components.Option {...rest}>
      <CustomOption data={optionData} prefix={prefix && <span className="badge">{prefix + ' '}</span>} />
    </components.Option>
  )
}

PartnerCustomOption.propTypes = {
  children: PropTypes.node.isRequired,
  texts: PropTypes.any,
  data: PropTypes.shape({
    prefix: PropTypes.node,
    isMsg: PropTypes.bool,
  }).isRequired as React.Validator<PartnerOptionData>,
}

export const COMPONENT_OVERRIDES = { Option: PartnerCustomOption, LoadingIndicator }
