import React from 'react'

import { useLocation } from 'react-router'

export function useScrollToHash(id) {
  const { hash } = useLocation()
  const ref = React.useRef(null)

  React.useEffect(() => {
    if (hash.substring(1, hash.length) === id) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }, [hash, id])

  return { ref, id }
}
