import React from 'react'
import PropTypes from 'prop-types'

import { isXmasPeriod } from '@helpers'

import { QUICK_COLORS } from '@constants'

interface QuickLogoProps {
  className?: string
  height?: number
  width?: number
  withDecoration?: boolean
}

export function QuickLogo({ className, withDecoration = false, width, height }: QuickLogoProps) {
  const isfestiveHatVisible = withDecoration && isXmasPeriod(6, 29)
  let svgWidth: number | 'auto' = 47
  let svgHeight: number | 'auto' = 24

  if (width != null) {
    svgWidth = width
    svgHeight = height != null ? height : 'auto'
  } else if (height != null) {
    svgHeight = height
    svgWidth = width != null ? width : 'auto'
  }

  return (
    <svg
      viewBox="0 0 1200 626"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={svgWidth}
      height={svgHeight}
      className={className}
    >
      <g>
        <path
          d="M873.194 237.635V200.659C873.194 136.977 838.143 100 778.349 100C716.494 100 683.504 136.977 683.504 200.659V408.139C683.504 471.821 718.556 508.798 778.349 508.798C840.205 508.798 873.194 471.821 873.194 408.139V371.162H811.339V412.247C811.339 441.007 798.968 451.278 778.349 451.278C757.731 451.278 745.36 441.007 745.36 412.247V196.55C745.36 167.791 757.731 157.519 778.349 157.519C798.968 157.519 811.339 167.791 811.339 196.55V237.635H873.194ZM873.194 237.635V200.659C873.194 136.977 838.143 100 778.349 100C716.494 100 683.504 136.977 683.504 200.659V408.139C683.504 471.821 718.556 508.798 778.349 508.798C840.205 508.798 873.194 471.821 873.194 408.139V371.162H811.339V412.247C811.339 441.007 798.968 451.278 778.349 451.278C757.731 451.278 745.36 441.007 745.36 412.247V196.55C745.36 167.791 757.731 157.519 778.349 157.519C798.968 157.519 811.339 167.791 811.339 196.55V237.635H873.194Z"
          fill="#4F4F4F"
        />
        <path
          d="M464.951 110.281V414.311C464.951 443.071 452.58 453.342 431.962 453.342C411.343 453.342 398.972 443.071 398.972 414.311V110.281H337.117V408.149C337.117 471.831 372.168 508.807 431.962 508.807C493.817 508.807 526.807 471.831 526.807 408.149V110.281H464.951ZM464.951 110.281V414.311C464.951 443.071 452.58 453.342 431.962 453.342C411.343 453.342 398.972 443.071 398.972 414.311V110.281H337.117V408.149C337.117 471.831 372.168 508.807 431.962 508.807C493.817 508.807 526.807 471.831 526.807 408.149V110.281H464.951Z"
          fill="#4F4F4F"
        />
        <path d="M636.083 202.711H572.166V500.579H636.083V202.711Z" fill="#4F4F4F" />
        <path d="M636.079 110.281H574.223V171.909H636.079V110.281Z" fill={QUICK_COLORS.blue[40]} />
        <path
          d="M1100 108.205V225.298C1100 266.383 1087.63 295.142 1058.76 307.468C1091.75 319.794 1100 350.607 1100 393.747V498.514H1036.08V391.693C1036.08 352.662 1023.71 338.282 994.845 338.282H972.165V498.514H908.248V110.259H972.165V284.871H996.907C1019.59 284.871 1034.02 274.6 1034.02 241.732V108.205H1100Z"
          fill="#4F4F4F"
        />
        <path
          d="M236.082 490.309C223.711 494.418 209.278 496.472 194.845 496.472C135.051 496.472 100 461.55 100 397.868V198.604C102.062 134.922 135.051 100 196.907 100C258.763 100 291.752 134.922 291.752 198.604V397.868C291.752 424.573 285.567 445.116 275.257 461.55C279.381 469.767 287.628 469.767 297.938 469.767H308.247V525.232H289.69C264.948 527.286 244.33 519.069 236.082 490.309ZM227.835 401.976V194.496C227.835 165.736 215.464 155.465 194.845 155.465C174.227 155.465 163.917 165.736 163.917 194.496V401.976C163.917 430.736 176.288 441.007 196.907 441.007C217.526 441.007 227.835 430.736 227.835 401.976Z"
          fill="#4F4F4F"
        />
      </g>
      {isfestiveHatVisible && (
        <g id="festive-hat-december">
          <path
            d="M107.898 264.11H301.258C315.798 264.11 327.624 251.696 327.624 237.156V219.578C327.624 205.039 315.798 193.211 301.258 193.211H107.898C103.04 193.211 99.1094 197.142 99.1094 202V255.32C99.1094 260.178 103.04 264.11 107.898 264.11Z"
            fill="#E6E6E6"
          />
          <path
            d="M66.9274 149.349L66.8588 149.266C71.8152 126.474 83.1044 104.929 99.9936 88.0404C123.237 64.7975 154.135 52 187 52C219.865 52 250.764 64.7975 274.006 88.0404C297.25 111.283 310.046 142.768 310.046 175.633V193.211H116.688V290.476H63.9532V175.633C63.9532 166.697 65.0066 157.935 66.9274 149.349Z"
            fill="#F44336"
          />
          <path
            d="M66.9268 149.332L66.8582 149.248C70.7804 131.207 78.823 114.41 90.3204 99.7734V290.46H63.9532V175.615C63.9526 166.679 65.006 157.917 66.9268 149.332Z"
            fill="black"
            fillOpacity="0.1"
          />
          <path
            d="M90.3204 264.11C114.578 264.11 134.266 283.796 134.266 308.054C134.266 332.312 114.578 352 90.3204 352C66.0624 352 46.375 332.312 46.375 308.054C46.375 283.796 66.0624 264.11 90.3204 264.11Z"
            fill="#E6E6E6"
          />
          <path
            d="M46.375 308.054C46.375 332.312 66.0624 352 90.3204 352V264.11C66.0624 264.11 46.375 283.796 46.375 308.054Z"
            fill="black"
            fillOpacity="0.1"
          />
        </g>
      )}
    </svg>
  )
}

QuickLogo.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  withDecoration: PropTypes.bool,
}
