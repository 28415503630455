import { DocViewerActions, MainStateActions } from './actions'
import { DocViewerMainState } from './types'

export const initialState: DocViewerMainState = {
  currentFileNo: 0,
  documents: [],
  documentLoading: true,
  currentDocument: undefined,
  rendererRect: undefined,
  config: {},
  pluginRenderers: [],
}

export type MainStateReducer = (state: DocViewerMainState, action: MainStateActions) => DocViewerMainState

export const mainStateReducer: MainStateReducer = (
  state = initialState,
  action: MainStateActions
): DocViewerMainState => {
  switch (action.type) {
    case DocViewerActions.SET_ALL_DOCUMENTS: {
      const { documents, initialActiveDocument } = action

      return {
        ...state,
        documents,
        currentDocument: initialActiveDocument ? initialActiveDocument : documents[0] || null,
        currentFileNo:
          initialActiveDocument && documents.includes(initialActiveDocument)
            ? documents.indexOf(initialActiveDocument)
            : initialState.currentFileNo,
      }
    }

    case DocViewerActions.SET_DOCUMENT_LOADING: {
      const { value } = action

      return { ...state, documentLoading: value }
    }

    case DocViewerActions.NEXT_DOCUMENT: {
      if (state.currentFileNo >= state.documents.length - 1) return state
      const nextDocumentNo = state.currentFileNo + 1

      if (state.onDocumentChange) {
        state.onDocumentChange(state.documents[nextDocumentNo])
      }

      return {
        ...state,
        currentFileNo: nextDocumentNo,
        currentDocument: state.documents[nextDocumentNo],
        documentLoading: true,
      }
    }

    case DocViewerActions.PREVIOUS_DOCUMENT: {
      if (state.currentFileNo <= 0) return state
      const prevDocumentNo = state.currentFileNo - 1

      if (state.onDocumentChange) {
        state.onDocumentChange(state.documents[prevDocumentNo])
      }

      return {
        ...state,
        currentFileNo: state.currentFileNo - 1,
        currentDocument: state.documents[prevDocumentNo],
        documentLoading: true,
      }
    }

    case DocViewerActions.UPDATE_CURRENT_DOCUMENT: {
      const { document } = action

      return {
        ...state,
        currentDocument: document,
        currentFileNo: state.documents.findIndex(doc => doc.uri === document.uri),
      }
    }

    case DocViewerActions.SET_RENDERER_RECT: {
      const { rect } = action

      return {
        ...state,
        rendererRect: rect,
      }
    }

    case DocViewerActions.SET_MAIN_CONFIG: {
      const { config } = action

      return {
        ...state,
        config,
      }
    }

    default:
      return state
  }
}
