import React from 'react'

import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { GroupBase, SelectInstance } from 'react-select'

import { dashboardActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { ReactHookFormSelectField } from '@components/ui'
import { ReactHookFormSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSelectField'

import { formSelectMessages } from '@messages'

type CreateTagHandler = (newOption: Tag, selectRef: SelectInstance<Tag, boolean, GroupBase<Tag>>) => void

interface ReactHookFormTagFieldProps extends ReactHookFormSelectFieldProps<Tag> {
  company: number
  createNewTag: CreateCommonIdAndNameTypeCallback
}

export function PureReactHookFormTagField({ company, createNewTag, ...rest }: ReactHookFormTagFieldProps) {
  const { formatMessage } = useIntl()
  const [isLoading, setIsLoading] = React.useState(false)
  const { setErrorAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef<CommonIdAndNameType>()

  const onCreateHandler = React.useCallback<CreateTagHandler>(
    async (newOption, selectRef) => {
      setIsLoading(true)
      try {
        cPromiseRef.current = cancellablePromise(createNewTag({ ...newOption, company }))
        const { id, name } = await cPromiseRef.current.promise
        // select it
        selectRef.selectOption({ id, name, company })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
      }
      setIsLoading(false)
    },
    [cPromiseRef, company, createNewTag, setErrorAlert]
  )

  return (
    <ReactHookFormSelectField
      {...rest}
      isClearable
      isLoading={isLoading}
      isMulti
      onCreate={onCreateHandler}
      onEmptyCreateText={formatMessage(formSelectMessages.tagsEmptyCreateText)}
    />
  )
}

export const ReactHookFormTagField = connect(
  (state: Store) => ({
    company: state.auth.company.data.id,
    options: state.dashboard.tags.data,
  }),
  dispatch => ({
    createNewTag: bindActionToPromise(dispatch, dashboardActions.createTag.request),
  })
)(PureReactHookFormTagField)

ReactHookFormTagField.displayName = 'ReactHookFormTagField'
