import React from 'react'
import PropTypes from 'prop-types'

import { LightTooltip } from '@oldComponents/ui'

interface TooLongTextProps {
  disablePortal?: boolean
  value: string
  maxLength?: number
}

// maxLength must be greater than 7
export default function TooLongText({ disablePortal = false, value, maxLength = 18 }: TooLongTextProps) {
  const valueLength = value.length

  if (valueLength > maxLength) {
    const shortenedValue = `${value.substring(0, maxLength - 7)}...${value.substring(valueLength - 4)}`
    return (
      <LightTooltip title={value} PopperProps={{ disablePortal }}>
        <span>{shortenedValue}</span>
      </LightTooltip>
    )
  }

  return <>{value}</>
}

TooLongText.propTypes = {
  disablePortal: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string.isRequired,
}
