import styled, { keyframes } from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { Button } from '@components/ui/Buttons'
import { PlugCircleBoltRegularIcon } from '@components/ui/svgIcons'
import { TableRow } from '@components/ui/TableElements'
import { FlexRow } from '@components/ui/Wrappers'

export const NotificationTableRow = styled(TableRow)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  height: 42px;

  &:hover {
    box-shadow: none;
  }

  ${FlexRow} {
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  ${Button} {
    --css-hover-background-color: ${({ theme }) => applyOpacity(theme.colors.gray[40], 15)};
  }
`

export const StyledFlexRow = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.blue[30]};
`

const scaleAnimation = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.2);
}
100% {
  transform: scale(1);
}
`

export const AnimatedPlugCircleBoltRegularIcon = styled(PlugCircleBoltRegularIcon)`
  animation: ${scaleAnimation} infinite 1.5s;
`
