import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Button, Typography } from '@components/ui'
import { DeleteFunctionVisibilityControl } from '@oldComponents/VisibilityControls'

import { QUARANTINE_STATUS } from '@constants'

import { getSourceName } from './helper'
import { NavHeader } from './NavHeader'

import styles from './NavInvoiceHandler/styles'

const useStyles = makeStyles(styles)

const StyledSection = styled.section`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
`

const StatusTypography = styled(Typography)`
  padding: 4px 12px;
  background: ${({ theme }) => theme.colors.error[40]};
  color: ${({ theme }) => theme.colors.gray[0]};
  border-radius: 4px;
  margin: 0 auto;
`

interface NavInvoiceHandlerProps {
  error: BackendError
  onAccept: AsyncFunction
  onDecline: VoidFunction
  origin: InvoiceOrigin
  quarantineStatus: Nullable<number>
}

export function NavInvoiceHandler({ error, onAccept, onDecline, origin, quarantineStatus }: NavInvoiceHandlerProps) {
  const classes = useStyles()

  function handleAccept() {
    onAccept()
  }

  return (
    <StyledSection>
      <NavHeader origin={origin} />
      {/* QUARANTINE PENDING STATE */}
      {quarantineStatus === QUARANTINE_STATUS.pending && (
        <>
          <Typography align="center">
            <FormattedMessage
              id="invoice.navQuarantine.headerText.pending"
              defaultMessage="A számla adatait a {source} importálta a NAV rendszerén keresztül. Szeretnéd, ha bekerülne a költségeid közé? Pontatlan adatok esetén, elfogadás után szerkesztheted a számlát." // eslint-disable-line max-len
              values={{ source: getSourceName(origin) }}
            />
          </Typography>
          <Grid container wrap="nowrap" justifyContent="center" spacing={4}>
            <Grid item>
              <DeleteFunctionVisibilityControl>
                <Button variant="secondaryContained" onClick={onDecline}>
                  <FormattedMessage id="nav.preview.handler.declineButtonLabel" defaultMessage="Számla elutasítása" />
                </Button>
              </DeleteFunctionVisibilityControl>
            </Grid>
            <Grid item>
              <Button variant="primaryContained" onClick={handleAccept}>
                <FormattedMessage
                  id="nav.preview.handler.acceptButtonLabel"
                  defaultMessage="Igen, elfogadom a számlát"
                />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {/* QUARANTINE REJECTED STATE */}
      {quarantineStatus === QUARANTINE_STATUS.rejected && (
        <>
          <StatusTypography className={cx(classes.status, 'error')}>
            <FormattedMessage id="nav.preview.handler.declinedStatus" defaultMessage="Elutasítva" />
          </StatusTypography>
          <Typography align="center">
            <FormattedMessage
              id="invoice.navQuarantine.headerText.rejected"
              defaultMessage="A számla adatait a {source} importálta a NAV rendszerén keresztül, Te pedig elutasítottad." // eslint-disable-line max-len
              values={{ source: getSourceName(origin) }}
            />
          </Typography>
        </>
      )}
      {error && (
        <div className={classes.error}>
          <Typography color="error">{error}</Typography>
        </div>
      )}
    </StyledSection>
  )
}

NavInvoiceHandler.propTypes = {
  error: PropTypes.string as React.Validator<BackendError>,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired as React.Validator<InvoiceOrigin>,
  quarantineStatus: PropTypes.number as React.Validator<Nullable<number>>,
}
