import React from 'react'

import styled from 'styled-components'

function BaseTrashRegularIcon({ className, size = 18 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={(21 / 24) * size}
      height={size}
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99219 2.41875L7.10156 3.75H13.8984L13.0078 2.41875C12.9375 2.31562 12.8203 2.25 12.6937 2.25H8.30156C8.175 2.25 8.05781 2.31094 7.9875 2.41875H7.99219ZM14.8828 1.17188L16.6031 3.75H17.25H19.5H19.875C20.4984 3.75 21 4.25156 21 4.875C21 5.49844 20.4984 6 19.875 6H19.5V20.25C19.5 22.3219 17.8219 24 15.75 24H5.25C3.17812 24 1.5 22.3219 1.5 20.25V6H1.125C0.501562 6 0 5.49844 0 4.875C0 4.25156 0.501562 3.75 1.125 3.75H1.5H3.75H4.39688L6.11719 1.16719C6.60469 0.440625 7.425 0 8.30156 0H12.6937C13.5703 0 14.3906 0.440625 14.8781 1.16719L14.8828 1.17188ZM3.75 6V20.25C3.75 21.0797 4.42031 21.75 5.25 21.75H15.75C16.5797 21.75 17.25 21.0797 17.25 20.25V6H3.75ZM7.5 9V18.75C7.5 19.1625 7.1625 19.5 6.75 19.5C6.3375 19.5 6 19.1625 6 18.75V9C6 8.5875 6.3375 8.25 6.75 8.25C7.1625 8.25 7.5 8.5875 7.5 9ZM11.25 9V18.75C11.25 19.1625 10.9125 19.5 10.5 19.5C10.0875 19.5 9.75 19.1625 9.75 18.75V9C9.75 8.5875 10.0875 8.25 10.5 8.25C10.9125 8.25 11.25 8.5875 11.25 9ZM15 9V18.75C15 19.1625 14.6625 19.5 14.25 19.5C13.8375 19.5 13.5 19.1625 13.5 18.75V9C13.5 8.5875 13.8375 8.25 14.25 8.25C14.6625 8.25 15 8.5875 15 9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const TrashRegularIcon = styled(BaseTrashRegularIcon)``
