// TODO: move to components

import React, { Component } from 'react'
import { bool, func, node, number, oneOfType, shape, string } from 'prop-types'

import cx from 'classnames'
import __get from 'lodash/get'
import __isObject from 'lodash/isObject'
import { components as reactSelectComponents } from 'react-select'
import AsyncSelect from 'react-select/async-creatable'

import { cancellablePromise, customFilterOption } from '@helpers'

import { selectFormStyles as customReactSelectStyles } from '@styles'

// need to customize the recommended option
const CustomSelectOption = reactSelectComponents.Option

function renderMessage(msg) {
  return function () {
    return msg
  }
}

class MySelect extends Component {
  state = {
    options: [],
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
    if (this.cPromise) this.cPromise.cancel()
  }

  loadOptions = (inputValue, callback) => {
    const { searchOptions, defaultOptions, input } = this.props

    // previously it was inputValue === undefined, but defualt by inputValue sems to be empty string
    const searchOnMount = defaultOptions && !inputValue

    const searchValue = searchOnMount ? input.value : inputValue

    if (String(searchValue).length >= 3) {
      this.cPromise = cancellablePromise(searchOptions(searchValue, callback))
      this.cPromise.promise
        .then(options => {
          callback(options)
          this.setState({ options })

          // trigger menuOpen with "openMenuOnFocus" prop
          this.select.focus()
        })
        .catch(err => {
          // silently handle promise cancelation
          if (!__isObject(err) || !Object.prototype.hasOwnProperty.call(err, 'isCanceled')) {
            // eslint-disable-next-line no-console
            console.error(err)
          }
        })
    } else {
      callback([])
    }
  }

  handleBlur = () => {
    const { onBlur, value } = this.props.input
    // directly can't call onBlur cause it will trigger a component update
    // what will break the react-select inputBlur event and won't clear
    // the focusedValue from its state
    this.timeout = setTimeout(onBlur, 100, value)
  }

  handleChange = (option, { action }) => {
    const {
      input: { onChange },
      valueKey,
      callback,
    } = this.props

    switch (action) {
      case 'select-option':
        onChange(__get(option, valueKey, ''))
        if (callback) {
          callback(option)
        }
        break
      case 'remove-value':
        onChange(__get(option, valueKey, ''))
        if (callback) {
          callback(option)
        }
        break
      case 'clear':
        onChange('')
        break
      default:
    }
  }

  handleCreateOption = newValue => {
    const { onCreate } = this.props

    const option = onCreate(newValue)
    // console.log('handleCreateOption', newValue)
    this.setState(state => ({ options: [...state.options, option] }))
    this.select.selectOption(option)
  }

  isValidNewOption = (valueKey, labelKey) => (inputValue, selectValue, selectOptions) => {
    const compareOption = (value, option) => {
      const candidate = String(value).toLowerCase()
      // const valueString = String(__get(option, valueKey, '')).toLowerCase()
      const labelString = String(__get(option, labelKey, '')).toLowerCase()
      // return valueString === candidate || labelString === candidate
      return labelString === candidate
    }
    const isGroupedOption = (inputValue, option) => {
      // { label: String, options: Array}
      if (
        __isObject(option) &&
        Object.prototype.hasOwnProperty.call(option, 'label') &&
        Object.prototype.hasOwnProperty.call(option, 'options') &&
        Array.isArray(option.options)
      ) {
        return option.options.some(function (opt) {
          return isGroupedOption(inputValue, opt)
        })
      } else {
        return compareOption(inputValue, option)
      }
    }

    return !(
      !inputValue ||
      selectValue.some(function (option) {
        return compareOption(inputValue, option)
      }) ||
      selectOptions.some(function (option) {
        return isGroupedOption(inputValue, option)
      })
    )
  }

  formatCreateLabel = val => {
    const { createLabelText } = this.props
    return `${createLabelText}: ${val}`
  }

  getValue = () => {
    const {
      input: { value },
      valueKey,
      labelKey,
    } = this.props
    if (value) {
      return {
        [valueKey]: value,
        [labelKey]: value,
      }
    } else {
      return null
    }
  }

  render() {
    const {
      labelKey,
      valueKey,
      hasError,
      input: { onBlur, onChange, value, ...inputProps },
      disabled,
      placeholder,
      noResultsText,
      isLoading,
      isClearable,
      loadingText,
      optionRenderer,
      defaultOptions,
    } = this.props

    const componentOverridesProp = {
      components: {
        // TODO: once refactored, this can be cleaned up
        // eslint-disable-next-line react/prop-types
        Option: ({ children, ...props }) => {
          return <CustomSelectOption {...props}>{optionRenderer(props.data)}</CustomSelectOption>
        },
      },
    }

    return (
      <AsyncSelect
        ref={ref => (this.select = ref)}
        classNamePrefix="react-select"
        {...inputProps}
        inputId="async-react-select-input"
        options={this.state.options}
        defaultOptions={defaultOptions}
        value={this.getValue()}
        isDisabled={disabled}
        placeholder={placeholder}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        className={cx({ error: hasError })}
        noOptionsMessage={renderMessage(noResultsText)}
        loadingMessage={renderMessage(loadingText)}
        loadOptions={this.loadOptions}
        // cacheOptions
        openMenuOnFocus
        // only for CreatableSelect
        getNewOptionData={(inputValue, optionLabel) => ({
          [valueKey]: inputValue,
          [labelKey]: optionLabel,
          // isCreatable: true,
        })}
        getOptionLabel={option => option[labelKey]}
        getOptionValue={option => option[valueKey]}
        onCreateOption={this.handleCreateOption}
        formatCreateLabel={this.formatCreateLabel}
        isValidNewOption={this.isValidNewOption(valueKey, labelKey)}
        filterOption={customFilterOption}
        styles={customReactSelectStyles}
        isClearable={isClearable}
        isLoading={isLoading}
        {...componentOverridesProp}
      />
    )
  }
}

MySelect.defaultProps = {
  hasError: false,
  isClearable: true,
  defaultOptions: false,
  isLoading: false,
  disabled: false,
}

MySelect.propTypes = {
  labelKey: string.isRequired,
  valueKey: string.isRequired,
  hasError: bool,
  noResultsText: oneOfType([string, node]),
  onCreate: func.isRequired,
  placeholder: oneOfType([string, node]),
  loadingText: oneOfType([string, node]),
  searchOptions: func.isRequired,
  optionRenderer: func.isRequired,
  createLabelText: string.isRequired,
  defaultOptions: bool,
  isLoading: bool,
  isClearable: bool,
  disabled: bool,
  input: shape({
    value: oneOfType([number, string]),
    onBlur: func.isRequired,
  }).isRequired,
  callback: func,
}

export default MySelect
