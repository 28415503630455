import React from 'react'

import { connect } from 'react-redux'

import { dashboardActions } from '@services/index'

import { bindActionToPromise } from '@helpers/service'

import { LedgerNumberSelectOwnProps, PureLedgerNumberSelect } from './LedgerNumberSelect'

interface PaidThroughLedgerNumberSelectProps extends LedgerNumberSelectOwnProps {
  createNewOption: AsyncFunction<Omit<LedgerNumber, 'id'>, LedgerNumber>
  options: LedgerNumber[]
}

function PurePaidThroughLedgerNumberSelect({ createNewOption, options, ...rest }: PaidThroughLedgerNumberSelectProps) {
  const filteredOptions = options.filter(option => option.code?.startsWith('38'))
  const filteredRecommendations = filteredOptions.map(option => option.id)

  return (
    <PureLedgerNumberSelect
      {...rest}
      options={filteredOptions}
      recommendations={filteredRecommendations}
      createNewOption={createNewOption}
      isPaidThroughLedgerNumber
    />
  )
}

export const PaidThroughLedgerNumberSelect = connect(
  (state: Store) => ({
    options: state.dashboard.ledgerNumbers,
  }),
  dispatch => ({
    createNewOption: bindActionToPromise(dispatch, dashboardActions.createLedgerNumber.request),
  })
)(PurePaidThroughLedgerNumberSelect)

PaidThroughLedgerNumberSelect.displayName = 'PaidThroughLedgerNumberSelect'
