import React from 'react'

import { useAssertContext } from '@hooks/useAssertContext'

import { EditorMode, PartnerEditorDialog, PartnerEditorState } from '@oldComponents/PartnerEditor/PartnerEditorDialog'

const PartnerEditorContext = React.createContext<
  | {
      openEditorForCreate: VoidFunction
      openEditorForEdite: (partnerId: number) => void
      variant: 'provider' | 'customer'
    }
  | undefined
>(undefined)

interface PartnerEditorProviderProps {
  children: React.ReactNode
  variant: 'provider' | 'customer'
}

export function PartnerEditorProvider({ children, variant }: PartnerEditorProviderProps) {
  const [state, setState] = React.useState<PartnerEditorState>({
    partnerId: null,
    open: false,
    method: EditorMode.CREATE,
  })

  function closeEditor() {
    setState(prevState => ({
      ...prevState,
      open: false,
    }))
  }

  const contextValue = React.useMemo(
    () => ({
      openEditorForCreate: () => {
        setState({
          open: true,
          partnerId: null,
          method: EditorMode.CREATE,
        })
      },
      openEditorForEdite: (partnerId: number) => {
        setState({
          open: true,
          partnerId,
          method: EditorMode.EDIT,
        })
      },
      variant,
    }),
    [variant]
  )

  return (
    <PartnerEditorContext.Provider value={contextValue}>
      {children}
      <PartnerEditorDialog editor={state} onClose={closeEditor} variant={variant} />
    </PartnerEditorContext.Provider>
  )
}

export function usePartnerEditor() {
  return useAssertContext(PartnerEditorContext)
}
