import React from 'react'

import { FormattedMessage, IntlShape } from 'react-intl'

import { DATE_FILTER_KEYS } from '@constants'

import { getCheckboxListSelectedOptions, getPaidStatusFieldConfig } from '../helpers'

import {
  AccountingPeriodOptionMessage,
  CreatedAtOptionMessage,
  DueAtOptionMessage,
  ExportedAtOptionMessage,
  FulfilledAtOptionMessage,
  IssuedAtOptionMessage,
  PaidStatusLabelMessage,
  PaidThroughLabelMessage,
  StatusLabelMessage,
  ValueDateOptionMessage,
} from '@messages'
import { CurrencyLabelMessage, MaxAmountLabelMessage, MinAmountLabelMessage } from '../messages'

interface ActiveFilter {
  label: React.ReactChild
  values: React.ReactChild[]
}

/**
 * Helper function to get the label and its text value for a filter
 *
 * @param {SelectableFilterProps} selectableFilterProps
 * @returns {ActiveFilter[]}
 */
export function getActiveSelectableFilters(selectableFilterProps?: SelectableFilterProps): ActiveFilter[] {
  if (!selectableFilterProps) return []

  // create an object with keyValue keys and labelText values to be used for the mapping below
  const selectableFilterLabels = selectableFilterProps.config.reduce((previous, { keyValue, labelText }) => {
    return {
      ...previous,
      [keyValue]: labelText,
    }
  }, {} as Record<keyof SelectableFilterValues, React.ReactChild>)

  return Object.entries(selectableFilterProps.values)
    .map(([key, value]: [string, SelectableFilterOption[]]) => {
      return {
        label: selectableFilterLabels[key as keyof SelectableFilterValues] || '',
        values: value.map(({ name }) => name),
      }
    })
    .filter(({ values }: ActiveFilter) => values.length > 0)
}

export function getActiveStatusFilters(statusFilterProps?: StatusFilterProps<StatusFilterConfig>): ActiveFilter[] {
  if (!statusFilterProps) return []

  const activeFilters: ActiveFilter[] = []

  // we only want to display those statuses that are actually tracked and sent to backend
  const activeExpenseStatusValues = Object.entries(statusFilterProps.values).reduce((values, [name, value]) => {
    const currentOption = statusFilterProps.config
      .find(({ name: statusName }) => statusName === name)
      ?.options.find(({ value: statusValue }) => statusValue === value)

    if (currentOption) {
      values.push(currentOption.label)
    }

    return values
  }, [] as React.ReactChild[])

  if (activeExpenseStatusValues.length) {
    activeFilters.push({
      label: StatusLabelMessage,
      values: activeExpenseStatusValues,
    })
  }

  return activeFilters
}

export function getActivePaidStatusFilters(
  formatMessage: IntlShape['formatMessage'],
  paidStatusFilterProps?: PaidStatusFilterProps
): ActiveFilter[] {
  if (!paidStatusFilterProps) return []

  const activeFilters: ActiveFilter[] = []

  const fields = getPaidStatusFieldConfig(formatMessage)
  const activeValues = getCheckboxListSelectedOptions(fields, paidStatusFilterProps.values)
  if (activeValues.length) {
    activeFilters.push({
      label: PaidStatusLabelMessage,
      values: activeValues,
    })
  }

  return activeFilters
}

export function getDateLabel(dateField?: string) {
  switch (dateField) {
    case DATE_FILTER_KEYS.ACCOUNTING_PERIOD:
      return AccountingPeriodOptionMessage
    case DATE_FILTER_KEYS.CREATED_AT:
      return CreatedAtOptionMessage
    case DATE_FILTER_KEYS.DUE_AT:
      return DueAtOptionMessage
    case DATE_FILTER_KEYS.FULFILLED_AT:
      return FulfilledAtOptionMessage
    case DATE_FILTER_KEYS.ISSUED_AT:
      return IssuedAtOptionMessage
    case DATE_FILTER_KEYS.FINANCIAL_FULFILLMENT:
    case DATE_FILTER_KEYS.VALUE_DATE:
      return ValueDateOptionMessage
    case DATE_FILTER_KEYS.EXPORTED_AT:
      return ExportedAtOptionMessage

    default:
      return <FormattedMessage id="pageFilterBar.activeFilters.date" defaultMessage="Dátum" />
  }
}

//! NEW
export function getActiveMultiChoiceFilters(
  label: StringOrMessage,
  multiChoiceFilterProps?: FilterDialogMultiChoiceFilterProps
) {
  if (!multiChoiceFilterProps) return []

  const activeFilters: ActiveFilter[] = []
  const activeValues = multiChoiceFilterProps.config.options.reduce((list, option) => {
    if (multiChoiceFilterProps.values.includes(option.value)) {
      list.push(option.label)
    }
    return list
  }, [] as StringOrMessage[])
  if (activeValues.length) {
    activeFilters.push({
      label,
      values: activeValues,
    })
  }

  return activeFilters
}

export function getActiveIncludeExcludeFilters(
  labels: { exclude: StringOrMessage; include: StringOrMessage },
  includeExcludeFilterProps?: IncludeExcludeFilterProps
) {
  if (!includeExcludeFilterProps) return []

  const activeFilters: ActiveFilter[] = []
  const activeIncludeValues = includeExcludeFilterProps.values.include.map(option => option.name)
  if (activeIncludeValues.length) {
    activeFilters.push({
      label: labels.include,
      values: activeIncludeValues,
    })
  }
  const activeExcludeValues = includeExcludeFilterProps.values.exclude.map(option => option.name)
  if (activeExcludeValues.length) {
    activeFilters.push({
      label: labels.exclude,
      values: activeExcludeValues,
    })
  }
  return activeFilters
}

export function getActiveAmountFilters(formatNumber: IntlShape['formatNumber'], amountFilterProps?: AmountFilterProps) {
  if (!amountFilterProps) return []

  const activeFilters: ActiveFilter[] = []
  if (amountFilterProps.grossAmountMin != null) {
    activeFilters.push({
      label: MinAmountLabelMessage,
      values: [formatNumber(Number(amountFilterProps.grossAmountMin))],
    })
  }
  if (amountFilterProps.grossAmountMax != null) {
    activeFilters.push({
      label: MaxAmountLabelMessage,
      values: [formatNumber(Number(amountFilterProps.grossAmountMax))],
    })
  }
  return activeFilters
}

export function getActiveCurrencyFilters(currencyFilterProps?: CurrencyFilterProps) {
  if (!currencyFilterProps) return []

  const activeFilters: ActiveFilter[] = []
  if (currencyFilterProps.currencyId != null) {
    activeFilters.push({
      label: CurrencyLabelMessage,
      values: [currencyFilterProps.currencyId.name],
    })
  }
  return activeFilters
}

export function getActivePaidThroughFilters(paidThroughFilterProps?: FilterDialogPaidThroughFilterProps) {
  if (!paidThroughFilterProps) return []

  const activeFilters: ActiveFilter[] = []
  const activeValues = paidThroughFilterProps.values.map(option => option.name)
  if (activeValues.length) {
    activeFilters.push({
      label: PaidThroughLabelMessage,
      values: activeValues,
    })
  }
  return activeFilters
}
