import { EV_COMPANY_TYPE, NON_EV_COMPANY_TYPE } from '@constants'

/**
 * Returns the normalized company type based on the provided value.
 *
 * @param {string} companyType - The company type from the company object.
 * @return {string} Either 'ev' or 'non-ev' depending on the provided value.
 */
export function getIntercomCompanyType(companyType: string) {
  return companyType === EV_COMPANY_TYPE ? EV_COMPANY_TYPE : NON_EV_COMPANY_TYPE
}

/**
 * Returns a formatted full name based on last name and first name of the user.
 *
 * @param {Nullable<string>} first_name - The first name of the user.
 * @param {Nullable<string>} last_name - The last name of the user.
 * @returns {string | undefined} - The formatted full name or undefined if either name is missing.
 */
export function getFullName(first_name: Nullable<string>, last_name: Nullable<string>): string | undefined {
  if (!first_name || !last_name) {
    return undefined
  }

  return `${last_name} ${first_name}`
}
