import React from 'react'

import { useForm } from 'react-final-form'

import { Button, ButtonProps } from '@components/ui'

import { DetailsResetMessage, FormResetConfirmDialog } from './elements'

interface RffFormResetButtonProps
  extends Omit<ButtonProps, 'type' | 'onClick' | 'children'>,
    Partial<Pick<ButtonProps, 'children'>> {
  confirmDialogMessage?: StringOrMessage
  needConfirmOnReset?: boolean
  onReset?: VoidFunction
}

export function RffFormResetButton({
  children = DetailsResetMessage,
  confirmDialogMessage,
  needConfirmOnReset = false,
  onReset,
  variant = 'primaryText',
  ...rest
}: RffFormResetButtonProps) {
  const [open, setOpen] = React.useState(false)
  const { reset, restart } = useForm()

  function handleResetRequest() {
    if (needConfirmOnReset) {
      setOpen(true)
    } else {
      handleReset()
    }
  }

  function handleClose() {
    setOpen(false)
  }

  function handleReset() {
    // RFF restart is buggy,  so need to use reset too in the next render cycle (thas why setTimeout is needed)
    restart()
    window.setTimeout(() => {
      reset()
    })

    onReset?.()
    if (open) {
      setOpen(false)
    }
  }

  return (
    <>
      <Button type="button" variant={variant} onClick={handleResetRequest} {...rest}>
        {children}
      </Button>
      <FormResetConfirmDialog
        descriptionMessage={confirmDialogMessage}
        onClose={handleClose}
        onConfirm={handleReset}
        open={open}
      />
    </>
  )
}
