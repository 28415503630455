import React from 'react'
import PropTypes from 'prop-types'

import { Button, DialogContentText, DialogProps, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@oldComponents/dialogs'
import { SubmitButton } from '@oldComponents/ui'

interface ConfirmDialogProps extends Omit<DialogProps, 'title' | 'classes'> {
  children?: React.ReactChild | React.ReactChild[]
  dialogActions?: React.ReactNode
  dialogContentClassName?: string
  disabled?: boolean
  disabledAll?: boolean
  error?: BackendError
  id: string
  loading: boolean
  onClose: VoidFunction
  onSubmit: VoidFunction
  open: boolean
  submitButtonLoadingText: StringOrMessage
  submitButtonText: StringOrMessage
  title: StringOrMessage
}

export default function ConfirmDialog({
  children,
  dialogActions,
  dialogContentClassName,
  disabled = false,
  disabledAll = false,
  error,
  id,
  loading,
  onClose,
  onSubmit,
  open,
  submitButtonLoadingText,
  submitButtonText,
  title,
  ...rest
}: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={id} {...rest}>
      <DialogTitle id={id}>{title}</DialogTitle>
      <DialogContent className={dialogContentClassName}>
        <DialogContentText component="div">{children}</DialogContentText>

        {error && (
          <Typography variant="caption" color="error" style={{ margin: '1rem 0' }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {dialogActions ?? (
          <>
            <SubmitButton
              buttonText={submitButtonText}
              loadingText={submitButtonLoadingText}
              loading={loading}
              disabled={disabledAll || disabled}
              onClick={onSubmit}
              type="button"
              color="default"
            />
            <Button onClick={onClose} color="primary" variant="contained" disabled={disabledAll}>
              <FormattedMessage id="dialogs.deleteConfirm.buttons.cancel" defaultMessage="Mégse" />
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired]),
  dialogActions: PropTypes.node,
  dialogContentClassName: PropTypes.string,
  disabled: PropTypes.bool,
  disabledAll: PropTypes.bool,
  error: PropTypes.node,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitButtonLoadingText: PropTypes.node.isRequired,
  submitButtonText: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
}
