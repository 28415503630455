import React from 'react'
import PropTypes from 'prop-types'

import { FormattedDate, FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Currency, Typography } from '@components/ui'

const List = styled.ul`
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[110]};
`

interface LastSavedBalancesListProps {
  data?: Nullable<PaidThroughDetailData>
}

export function LastSavedBalancesList({ data }: LastSavedBalancesListProps) {
  return (
    <List>
      <ListItem>
        <Typography size="400-sm" color="gray-80">
          <FormattedMessage
            id="paidThrough.editor.balance.lastSavedBalances"
            defaultMessage="Legutolsó mentett egyenlegek"
          />
        </Typography>
      </ListItem>
      {data && data.balances.length > 0 ? (
        data.balances.map(dataItem => (
          <ListItem key={dataItem.id}>
            <Typography size="400-sm">
              <Currency value={Number(dataItem.balance)} currency={data.currency?.name} />
            </Typography>
            <Typography size="400-sm">
              <FormattedDate value={dataItem.value_date} />
            </Typography>
          </ListItem>
        ))
      ) : (
        <Typography size="400-sm" color="gray-50" italic>
          <FormattedMessage
            id="paidThrough.editor.balance.noSavedBalances"
            defaultMessage="Nincs még mentett egyenleged."
          />
        </Typography>
      )}
    </List>
  )
}

LastSavedBalancesList.propTypes = {
  data: PropTypes.shape({
    balances: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.string.isRequired,
        value_date: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
}
