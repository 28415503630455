import React from 'react'
import PropTypes from 'prop-types'

import ReactDOM from 'react-dom'

interface PortalProps {
  anchorEl?: React.RefObject<HTMLElement>['current']
  children: JSX.Element
}

/**
 * Component to render its children into the referenced element
 * @param anchorEl (optional) reference of HTML element of destination
 * @param children Child element
 */
export function Portal({ anchorEl, children }: PortalProps) {
  if (!anchorEl) {
    return children
  }
  return ReactDOM.createPortal(children, anchorEl)
}

Portal.propTypes = {
  anchorEl: PropTypes.object,
  children: PropTypes.node.isRequired,
}
