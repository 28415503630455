import React from 'react'

import styled, { DefaultTheme } from 'styled-components'

import { RightArrowLineIcon } from '@components/ui/svgIcons'

import { Typography } from '../Typography'

export const CustomLink = styled.a<Pick<LinkProps, 'variant'>>`
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;

  color: ${({ variant, theme }) => (variant === 'primary' ? theme.colors.primary[50] : 'inherit')};

  &:hover {
    color: ${({ theme }) => theme.colors.primary[40]};
    cursor: pointer;
  }
`

export const IconWrapperSpan = styled.span`
  display: inherit;
  align-items: inherit;
`

export interface LinkProps {
  className?: string
  href?: string
  iconSize?: number
  rel?: string
  target?: string
  text: StringOrMessage
  typographySize?: keyof DefaultTheme['typography']
  variant?: 'primary' | 'secondary'
}

export type ExternalLinkProps = Omit<LinkProps, 'href'> & { href: string }

/**
 * Renders an 'a' tag with text and a right arrow icon.
 *
 * The 'target' and 'rel' props can be overwritten.
 */
export function ExternalLink({
  iconSize = 16,
  text,
  typographySize = 'inherit',
  variant = 'primary',
  ...rest
}: ExternalLinkProps) {
  return (
    <CustomLink target="_blank" rel="noopener noreferrer" {...rest} variant={variant}>
      <Typography tag="span" size={typographySize}>
        {text}
      </Typography>
      <IconWrapperSpan>
        <RightArrowLineIcon size={iconSize} />
      </IconWrapperSpan>
    </CustomLink>
  )
}
