import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { dashboardActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { XML_ACCEPTED_MIME_TYPES } from '@constants'

import xmlUploadSVGSrc from '@assets/img/xml_upload.svg'

import { FileUploadForm } from './FileUploadForm'
import { FileUploadFormProps } from './types'

interface XmlUploadFormProps
  extends Omit<
    FileUploadFormProps,
    | 'getFileUploadPayload'
    | 'acceptedFileMimeTypes'
    | 'fileUploadText'
    | 'title'
    | 'uploadSuggestionText'
    | 'uploadImgSrc'
    | 'showServerError'
    | 'withDialog'
  > {
  dataType: 'salary' | 'tax'
}

function PureXmlUploadForm({ dataType, ...rest }: XmlUploadFormProps) {
  return (
    <FileUploadForm
      {...rest}
      getFileUploadPayload={acceptedFiles => ({
        dataType,
        file: acceptedFiles[0],
      })}
      acceptedFileMimeTypes={XML_ACCEPTED_MIME_TYPES}
      fileUploadText={
        <FormattedMessage
          id="xmlFileUpload.texts.upload"
          defaultMessage="Húzd ide, vagy válaszd ki gépedről a '08-as jelű bevallást"
        />
      }
      uploadSuggestionText={
        <FormattedMessage
          id="xmlFileUpload.texts.suggestion"
          defaultMessage="Egy fájl feldolgozása 10-15 másodperc is lehet. Ha sok fájlod van, kényelmesebb, ha csatolmányként elküldöd őket e-mailben."
        />
      }
      uploadImgSrc={xmlUploadSVGSrc}
      showServerError
      withDialog
    />
  )
}

PureXmlUploadForm.propTypes = {
  uploading: PropTypes.bool.isRequired,
  uploadFile: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  uploadDescriptionText: PropTypes.node.isRequired as React.Validator<React.ReactChild>,
  dataType: PropTypes.oneOf(['salary', 'tax']).isRequired as React.Validator<XmlUploadFormProps['dataType']>,
}

export const XmlUploadForm = connect(
  (state: Store) => ({
    uploading: state.dashboard.xmlUploading,
  }),
  dispatch => ({
    uploadFile: bindActionToPromise(dispatch, dashboardActions.uploadSalaryAndTaxV2.request),
  })
)(PureXmlUploadForm)

XmlUploadForm.displayName = 'XmlUploadForm'
