import { BADGES_LIST } from './UserBadge'

export interface BadgeInfo {
  badge: Nullable<(typeof BADGES_LIST)[number]>
  badge_displayed_at: Nullable<string>
}

export function getCompanyUserBadge(state: Store): BadgeInfo {
  // badge: null - normal, 2 - assistant, 1 - master
  // badge_displayed_at: null - iso datetime string
  return {
    badge: state.auth.user.badge_type,
    badge_displayed_at: state.auth.user.badge_displayed_at,
  }
}

export function shouldShowBadgeView(state: Store) {
  const { badge, badge_displayed_at } = getCompanyUserBadge(state)
  return badge !== null && badge_displayed_at === null
}
