import React from 'react'
import PropTypes from 'prop-types'

import { Clear as ClearIcon, Done as DoneIcon } from '@material-ui/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { CopyIcon, LightTooltip } from '@oldComponents/ui'

const messages = defineMessages({
  copy: {
    id: 'copyPaste.alt',
    defaultMessage: 'Vágólapra másol',
  },
})

const WrapperDiv = styled.div<{ $noWrap: boolean }>`
  display: inline-flex;
  white-space: ${({ $noWrap }) => ($noWrap ? 'nowrap' : 'normal')};
  max-width: 100%;
`

const ButtonIconWrapper = styled.span`
  display: inherit;
  align-items: inherit;
  width: 16px;
  font-size: 16px;
  transition: opacity linear 0.1s;
  padding: 0;
`

const CopyButton = styled.button.attrs({
  type: 'button', //! NOTE - important when using it in form (in RFF first button submit the form)
})`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  min-height: 16px;
  max-width: inherit;
  outline: 0;
  padding: 0;
  font: inherit;

  &,
  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    border: unset;
    background-color: unset;
  }

  & ${ButtonIconWrapper} {
    opacity: 0;
  }

  &:disabled {
    pointer-events: none;
    color: unset;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    cursor: pointer;

    & ${ButtonIconWrapper} {
      opacity: 1;
    }
  }
`

function preventClick(event: React.MouseEvent<HTMLButtonElement>) {
  event.preventDefault()
  event.stopPropagation()
}

interface CopyAndPasteButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  disabled?: boolean
  text?: Nullable<string>
  noWrap?: boolean
}

export default function CopyAndPasteButton({
  children,
  text,
  className,
  onClick = preventClick,
  noWrap = false,
  ...rest
}: CopyAndPasteButtonProps) {
  const [isCopied, setCopied] = React.useState(false)
  const [isError, setError] = React.useState(false)
  const { formatMessage } = useIntl()

  React.useEffect(() => {
    let timeout: number
    if (isError) {
      timeout = window.setTimeout(() => {
        setError(false)
      }, 1500)
    } else if (isCopied) {
      timeout = window.setTimeout(() => {
        setCopied(false)
      }, 1500)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isError, isCopied])

  function onCopyHandler(text: string, result: boolean) {
    if (result) {
      setCopied(true)
    } else {
      setError(true)
    }
  }

  const isDefault = !isError && !isCopied

  if (!text) {
    return <>{children}</>
  }

  return (
    <WrapperDiv className={className} $noWrap={noWrap}>
      <CopyToClipboard text={text} onCopy={onCopyHandler}>
        <CopyButton onClick={onClick} {...rest}>
          {children}

          <LightTooltip title={formatMessage(messages.copy)}>
            <ButtonIconWrapper>
              {isDefault && <CopyIcon size={16} />}
              {isCopied && <DoneIcon fontSize="inherit" color="secondary" />}
              {isError && <ClearIcon fontSize="inherit" color="error" />}
            </ButtonIconWrapper>
          </LightTooltip>
        </CopyButton>
      </CopyToClipboard>
    </WrapperDiv>
  )
}

CopyAndPasteButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noWrap: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
}
