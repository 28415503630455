import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_AI_CHAT_DETAILS_URL,
  API_COMPANY_BANK_TRANSACTIONS_REPORT_URL,
  API_COMPANY_CREATE_PAYEE_WORKFLOW_URL,
  API_COMPANY_PARTNER_REPORT_DETAILS_URL,
  API_COMPANY_REPORT_URL,
  API_COMPANY_VAT_POSITION_REPORT_URL,
  API_COMPANY_WORKOUT_REPORT_DETAILS_URL,
  API_COMPANY_WORKOUT_REPORT_URL,
} from './urls'

export function fetchReports(companyId: number) {
  return Api.get(injectCompanyToURL(API_COMPANY_REPORT_URL, companyId))
}

export function fetchWorkoutReport(companyId: number, params: Record<string, string>) {
  return Api.get(injectCompanyToURL(API_COMPANY_WORKOUT_REPORT_URL, companyId), { params })
}

export function fetchWorkoutDetailsReport(companyId: number, partnerId: number, params: Record<string, string>) {
  return Api.get(
    injectCompanyToURL(API_COMPANY_WORKOUT_REPORT_DETAILS_URL, companyId).replace('{partner_id}', String(partnerId)),
    {
      params,
    }
  )
}

export function createPayeeWorkflow(companyId: number, partnerId: number, params: Record<string, string>) {
  return Api.post(
    injectCompanyToURL(API_COMPANY_CREATE_PAYEE_WORKFLOW_URL, companyId).replace('{partner_id}', String(partnerId)),
    params
  )
}

export function fetchPartnerDetails(companyId: number, partnerId: number, params: Record<string, string>) {
  return Api.get(
    injectCompanyToURL(API_COMPANY_PARTNER_REPORT_DETAILS_URL, companyId).replace('{partner_id}', String(partnerId)),
    {
      params,
    }
  )
}

export function fetchVatPositionReport(companyId: number, params: Record<string, string>) {
  return Api.get(injectCompanyToURL(API_COMPANY_VAT_POSITION_REPORT_URL, companyId), { params })
}

export function fetchBankTransactionsReport(companyId: number, params: Record<string, string>) {
  return Api.get<BackendBankTransactionsReport>(
    injectCompanyToURL(API_COMPANY_BANK_TRANSACTIONS_REPORT_URL, companyId),
    {
      params,
    }
  )
}

export function fetchAiChatDetails(companyId: number) {
  return Api.get(injectCompanyToURL(API_AI_CHAT_DETAILS_URL, companyId))
}
