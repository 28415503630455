import { Theme } from '@material-ui/core/styles'

export const listStyles = (theme: Theme) => ({
  list: {
    listStyle: 'none',
    maxHeight: 300,
    overflow: 'auto',
    padding: 0,
    margin: 0,
  },
  innerList: {
    padding: '0 0 0 30px',
  },
  listItem: {
    padding: '5px 0',
    display: 'block',
  },
  checkbox: {
    marginRight: 10,
  },
  controlLabel: {
    margin: 0,

    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.grayscale.black,
    },
  },
})

// TODO finish when FiltersDialog used
export const formStyles = (theme: Theme) => ({
  list: {
    listStyle: 'none',
    display: 'flex',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  innerList: {
    padding: '0 0 0 30px',
  },
  listItem: {
    padding: '5px 0',
    display: 'block',
  },
  checkbox: {
    width: '1em',
    height: '1em',
    fontSize: 20,
    marginRight: 10,

    '& .MuiSvgIcon-root': {
      fontSize: '1em',
    },
  },
  controlLabel: {
    margin: 0,

    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.grayscale.black,
    },
  },
})
