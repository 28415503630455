import styled from 'styled-components'

import { Typography } from './Typography'

export const WarningTypography = styled(Typography)`
  padding: 8px 20px;
  background-color: ${({ theme }) => theme.colors.common.warningMessageBackground};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`
