import styled, { css } from 'styled-components'

export const LoadingIndicatorDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

//* CSS Rules
export function setLoadingColorsStyles({ $loading }: { $loading: boolean }) {
  return (
    $loading &&
    css`
      &:disabled {
        --css-disabled-background-color: var(--css-background-color);
        --css-disabled-border-color: var(--css-border-color);
        --css-disabled-color: var(--css-color);
      }
    `
  )
}

export const AsyncButtonLoadingStyles = css<{ $loading: boolean }>`
  & > *:not(${LoadingIndicatorDiv}) {
    visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
  }
`
