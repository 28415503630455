import React from 'react'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { DateFilter, DateFilterProps } from '@components/filters'

import { DateFilterOwnProps, PageDateFilterProps } from './types'

function PurePageDateFilter({ onChange, onToggle, getDateTypeValue, ...rest }: PageDateFilterProps) {
  const navigate = useNavigate()

  const onToggleHandler: DateFilterProps['onToggle'] = React.useCallback(() => {
    onToggle({ navigate })
  }, [navigate, onToggle])

  const onChangeHandler: DateFilterProps['onChange'] = React.useCallback(
    filters => {
      onChange({ filters, navigate })
    },
    [navigate, onChange]
  )

  return <DateFilter {...rest} onChange={onChangeHandler} onToggle={onToggleHandler} />
}

export const PageDateFilter = connect((state: Store, { getDateTypeValue }: DateFilterOwnProps) => ({
  fromDate: state.filters.fromDate,
  toDate: state.filters.toDate,
  dateTypeValue: getDateTypeValue(state),
}))(PurePageDateFilter)

PageDateFilter.displayName = 'PageDateFilter'
