import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { Checkbox } from '@components/ui/Checkbox'

import { filterFormStyles } from '@styles/filters'
import { searchFilterStyles } from '@components/filters/PageFilterBars/styles'
import { ChoicesWrapper } from '../styles'

const CustomLabelSpan = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 20px;
`

const useStyles = makeStyles(searchFilterStyles)
const useFieldStyles = makeStyles(filterFormStyles)

interface MultiChoiceFilterProps {
  config: FilterDialogMultiChoiceFilterConfig
  onChange: (values: FilterDialogMultiChoiceFilterProps['values']) => void
  values: FilterDialogMultiChoiceFilterProps['values']
}

export default function MultiChoiceFilter({ config: { options }, onChange, values }: MultiChoiceFilterProps) {
  const classes = useStyles()
  const fieldClasses = useFieldStyles()

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValues = event.target.checked
      ? [...values, event.target.name]
      : values.filter(value => value !== event.target.name)
    onChange(newValues)
  }

  return (
    <ChoicesWrapper>
      {options.map(({ value, label, icon }) => (
        <FormControlLabel
          key={value}
          classes={{ label: fieldClasses.formControlLabel, root: classes.formControlRoot }}
          control={
            <Checkbox
              checked={values.includes(value)}
              className={classes.checkbox}
              name={value}
              onChange={handleChange}
            />
          }
          label={
            <CustomLabelSpan>
              {icon}
              {label}
            </CustomLabelSpan>
          }
        />
      ))}
    </ChoicesWrapper>
  )
}

MultiChoiceFilter.propTypes = {
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.node.isRequired,
        icon: PropTypes.node,
      }).isRequired
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}
