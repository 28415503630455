import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import cx from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

const StyledCheckbox = styled(Checkbox)`
  height: 34px;
  width: 34px;
  transform: translate(0, -4px);
  margin: -4px 8px 0 4px;

  &.MuiCheckbox-colorPrimary.Mui-disabled {
    opacity: 0.3;
  }
`

const StyledFormControlLabel = styled(FormControlLabel)<{ $isLabelHighlighted?: boolean }>`
  align-items: flex-start;
  padding-top: 6px;
  margin-bottom: 5px;
  width: max-content;
  max-width: 100%;

  ${({ $isLabelHighlighted }) =>
    $isLabelHighlighted &&
    css`
      .MuiFormControlLabel-label {
        font-weight: 700;
        color: ${({ theme }) => theme.colors.gray[100]};
      }
    `}
`

interface CheckboxProps {
  className?: string
  disabled?: boolean
  isLabelHighlighted?: boolean
  label: StringOrMessage
  name: string
}

export function ReactHookFormCheckboxField({
  className,
  disabled = false,
  isLabelHighlighted = false,
  label,
  name,
}: CheckboxProps) {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext()

  const hasError = !!errors[name]
  const isFieldDisabled = disabled || isSubmitting

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl
          className={cx(className, 'form-control', { 'form-control-error': hasError })}
          error={hasError}
          fullWidth
          margin="normal"
        >
          <StyledFormControlLabel
            $isLabelHighlighted={isLabelHighlighted}
            control={
              <StyledCheckbox color="primary" disabled={isFieldDisabled} id={name} {...field} checked={field.value} />
            }
            htmlFor={name}
            label={label}
          />
          {hasError && <FormHelperText>{errors[name]?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

ReactHookFormCheckboxField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}
