import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_2FACTOR_AUTH_URL,
  API_2FACTOR_BACKUP_TOKENS_URL,
  API_2FACTOR_DELETE_URL,
  API_2FACTOR_QR_CODE_URL,
  API_2FACTOR_VALIDATE_DEVICE_URL,
  API_CHANGE_PASSWORD_URL,
  API_COMPANY_DETAIL_URL,
  API_COMPANY_MEMBER_DETAILS_URL,
  API_COMPANY_MEMBERS_URL,
  API_COMPANY_URL,
  API_COMPANY_VAT_DETAILS_URL,
  API_COMPANY_VATS_URL,
  API_INTEGRATION_PROVIDER_URL,
  API_INVITATION_URL,
  API_LOGIN_URL,
  API_LOGOUT_URL,
  API_PREFERENCES_URL,
  API_RENEW_TOKEN_URL,
  API_RESET_PASSWORD_CONFIRM_URL,
  API_RESET_PASSWORD_URL,
  API_SIGNUP_URL,
  API_TAXATION_TAX_FORM_URL,
  API_USER_COMPANIES_URL,
  API_USER_NOTIFICATION_URL,
  API_USER_URL,
} from './urls'

const injectToUrl = (url, key, value) => url.replace(key, value)
const injectHashToUrl = (url, hash) => injectToUrl(url, '{hash}', hash)
const injectCompanyUserToUrl = (url, id) => injectToUrl(url, '{company_user_id}', id)
const injectCompanyVatToUrl = (url, id) => injectToUrl(url, '{company_vat_id}', id)

// AUTH API-s
export const renewToken = () => Api.post(API_RENEW_TOKEN_URL)
export const changePassword = data => Api.post(API_CHANGE_PASSWORD_URL, data)
export const login = data => Api.post(API_LOGIN_URL, data)
export const logout = () => Api.post(API_LOGOUT_URL)
export const resetPassword = data => Api.post(API_RESET_PASSWORD_URL, data)
export const resetPasswordConfirm = data => Api.post(API_RESET_PASSWORD_CONFIRM_URL, data)
export const signup = data => Api.post(API_SIGNUP_URL, data)
export const fetchUser = () => Api.get(API_USER_URL)
export const fetchUserCompanies = () => Api.get(API_USER_COMPANIES_URL)
// 2 FACTOR AUTH
export const getAuthStatus = () => Api.get(API_2FACTOR_AUTH_URL)
export const getAuthData = () => Api.post(API_2FACTOR_QR_CODE_URL)
export const validateDevice = data => Api.post(API_2FACTOR_VALIDATE_DEVICE_URL, data)
export const updateBackupTokens = () => Api.post(API_2FACTOR_BACKUP_TOKENS_URL)
export const clearTwoFactorAuth = () => Api.post(API_2FACTOR_DELETE_URL)

// INVITE
export const loadInvitationDetails = hash => Api.get(injectHashToUrl(API_INVITATION_URL, hash))

export const acceptInvitation = ({ hash, password, newsletter_status }) =>
  Api.post(injectHashToUrl(API_INVITATION_URL, hash), { password, newsletter_status })

// badge
export const updateBadgeDisplayedAt = badge_displayed_at => Api.patch(API_USER_URL, { badge_displayed_at })

// notifications
export const updateNotificationDisplayedAt = ({ id, displayed_at }) =>
  Api.patch(API_USER_NOTIFICATION_URL.replace('{notification_id}', id), { displayed_at })

// user personal info: { default_language, first_name, last_name }
export const updatePersonalInfo = payload => Api.patch(API_USER_URL, payload)

// user preferences
export const changeUserPreferences = partialPreferences => Api.patch(API_PREFERENCES_URL, partialPreferences)
export const restoreUserPreferences = () => Api.put(API_PREFERENCES_URL, { default: true })

// COMPANY
export const fetchCompanyDetails = companyId => {
  // TODO need refact after Yeri shutdown
  return Api.get(injectCompanyToURL(API_COMPANY_DETAIL_URL, companyId), { params: { fields: 'vat_categories' } })
}
export const createCompany = data => Api.post(API_COMPANY_URL, data)
export const updateCompany = ({ id, ...data }) => Api.patch(injectCompanyToURL(API_COMPANY_DETAIL_URL, id), data)

// COMPANY MEMBER
export const fetchCompanyMembers = companyId => Api.get(injectCompanyToURL(API_COMPANY_MEMBERS_URL, companyId))
export const fetchCompanyAdmins = companyId =>
  Api.get(injectCompanyToURL(API_COMPANY_DETAIL_URL, companyId), {
    params: { fields: 'admins' },
  })

export const createCompanyMember = (companyId, data) =>
  Api.post(injectCompanyToURL(API_COMPANY_MEMBERS_URL, companyId), data)

export const updateCompanyMember = (companyId, data) =>
  Api.patch(injectCompanyUserToUrl(injectCompanyToURL(API_COMPANY_MEMBER_DETAILS_URL, companyId), data.id), data)

export const removeCompanyMember = (companyId, companyUserId) =>
  Api.delete(injectCompanyUserToUrl(injectCompanyToURL(API_COMPANY_MEMBER_DETAILS_URL, companyId), companyUserId))

// COMPANY VAT
export const fetchCompanyVats = companyId => Api.get(injectCompanyToURL(API_COMPANY_VATS_URL, companyId))

export const createCompanyVat = (companyId, data) => Api.post(injectCompanyToURL(API_COMPANY_VATS_URL, companyId), data)

export const updateCompanyVat = (companyId, data) =>
  Api.patch(injectCompanyVatToUrl(injectCompanyToURL(API_COMPANY_VAT_DETAILS_URL, companyId), data.id), data)

export const removeCompanyVat = (companyId, companyVatId) =>
  Api.delete(injectCompanyVatToUrl(injectCompanyToURL(API_COMPANY_VAT_DETAILS_URL, companyId), companyVatId))

// COMPANY INTEGRATIONS
export const createIntegrationProvider = (companyId, data) =>
  Api.post(injectCompanyToURL(API_INTEGRATION_PROVIDER_URL, companyId), data)

export const updateIntegrationProvider = (companyId, { id, ...data }) =>
  Api.patch(injectCompanyToURL(`${API_INTEGRATION_PROVIDER_URL}${id}/`, companyId), data)

export const loadIntegrationProviders = companyId =>
  Api.get(injectCompanyToURL(API_INTEGRATION_PROVIDER_URL, companyId))

//* TAXATION
/**
 * Update tax form for the company
 *
 * @param {number} companyId - id of the company
 * @param {SetTaxFormPayload} data - post data
 */
export const taxationSetTaxForm = (companyId, data) =>
  Api.post(injectCompanyToURL(API_TAXATION_TAX_FORM_URL, companyId), data)
