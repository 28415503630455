import React from 'react'

import { FormattedMessage } from 'react-intl'

export const CreateNewCompanyMessage = (
  <FormattedMessage id="menu.buttons.createNewCompany" defaultMessage="Új cég létrehozása" />
)

export const MyCompaniesMessage = <FormattedMessage id="menu.profile.myCompanies" defaultMessage="Cégeim" />

export const ProfileSettingsMessage = <FormattedMessage id="menu.profile.settings" defaultMessage="Profilbeállítások" />
