import React from 'react'

import __isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'

import { filtersActions, SALARY_LIST_INITIAL_FILTERS } from '@services'

import { usePageConfig, usePortal } from '@contexts'

import { SALARY_LIST_DATE_FIELD_OPTIONS } from '@components/pages/constants'

import { FeaturePermissons, isPlanPermissionEnabled } from '@permissions'

import { FilterBar, FilterBarBaseProps } from './FilterBar'
import { getSelectableFilterProps, getStatusFilterDialogProps } from './helpers'

import { SalaryListPageMessage, TagFilterEmptyPlaceholderMessage, TagFilterLabelMessage } from '@messages'

interface PureSalaryListPageFilterBarProps extends FilterBarBaseProps {
  filters: SalaryListStoreFilters
  hasTagPermission: boolean
  tagOptions: Tag[]
}

function PureSalaryListPageFilterBar({
  filters,
  filters: {
    dateField,
    expiredDays,
    expiringDays,
    isExpired,
    isExpiring,
    isPaid,
    search,
    searchFields,
    withoutTagIds,
    withTagIds,
  },
  hasTagPermission,
  isDateFilterDisabled,
  resetFilters,
  tagOptions,
  updateFilters,
}: PureSalaryListPageFilterBarProps) {
  const { setPortalAnchorEl } = usePortal()
  const pageConfig = usePageConfig()

  const isDateFilterUsed = true
  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }

  const paidStatusFilterProps = {
    values: {
      expiredDays,
      expiringDays,
      isExpired,
      isExpiring,
      isPaid,
    },
  }

  const selectableFilterProps = getSelectableFilterProps({
    values: {
      withTagIds,
    },
    config: [
      {
        keyValue: 'withTagIds' as const,
        options: tagOptions,
        labelText: TagFilterLabelMessage,
        emptyPlaceholder: TagFilterEmptyPlaceholderMessage,
        hasPermission: hasTagPermission,
      },
    ],
  })

  const filterDialogProps: FilterDialogProps<StatusFilterConfig> = {
    dateFilterProps: {
      dateTypeOptions: SALARY_LIST_DATE_FIELD_OPTIONS,
      dateTypeValue: dateField,
    },
    isChanged: !__isEqual(filters, SALARY_LIST_INITIAL_FILTERS),
    page: SalaryListPageMessage,
    paidStatusFilterProps,
    searchFilterProps,
  }

  if (pageConfig.statusFilterProps) {
    filterDialogProps['statusFilterProps'] = getStatusFilterDialogProps({
      config: pageConfig.statusFilterProps.config,
      values: filters,
    })
  }
  if (pageConfig.tagFilterProps) {
    filterDialogProps['tagFilterProps'] = {
      config: {
        ...pageConfig.tagFilterProps.config,
        options: tagOptions,
      },
      values: {
        include: withTagIds,
        exclude: withoutTagIds,
      },
    }
  }
  if (pageConfig.amountFilterProps) {
    filterDialogProps['amountFilterProps'] = {
      // config: pageConfig.amountFilterProps.config,
      grossAmountMax: filters.grossAmountMax,
      grossAmountMin: filters.grossAmountMin,
    }
  }

  return (
    <FilterBar
      dateField={dateField}
      filterDialogProps={filterDialogProps}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      paidStatusFilterProps={paidStatusFilterProps}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      selectableFilterProps={selectableFilterProps}
      setPortalRef={setPortalAnchorEl}
      updateFilters={updateFilters}
    />
  )
}

export const SalaryListPageFilterBar = connect(
  (state: Store) => {
    const {
      dashboard: {
        tags: { data: tagOptions },
      },
      filters: { isGlobalDateFilterDisabled, salaryList },
    } = state
    return {
      filters: salaryList,
      hasTagPermission: isPlanPermissionEnabled(state, FeaturePermissons.TAG),
      isDateFilterDisabled: isGlobalDateFilterDisabled,
      tagOptions,
    }
  },
  {
    resetFilters: filtersActions.resetSalaryListFilters.request,
    updateFilters: filtersActions.updateSalaryListFilters.request,
  }
)(PureSalaryListPageFilterBar)

SalaryListPageFilterBar.displayName = 'SalaryListPageFilterBar'
