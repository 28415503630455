import React from 'react'

import styled from 'styled-components'

function BaseNoszIntegrationIcon({ className, size = 17 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40383 4.30613C7.40383 6.01945 6.15982 7.24783 4.62875 7.24783C3.11366 7.24783 1.85374 6.01945 1.85374 4.30613C1.85374 2.60887 3.11366 1.39667 4.62875 1.39667C6.15982 1.39667 7.40383 2.60887 7.40383 4.30613ZM8.98275 4.30613C8.98275 1.83314 7.03698 0.0388641 4.62875 0.0388641C2.22059 0.0388641 0.274841 1.83314 0.274841 4.30613C0.274841 6.79543 2.22059 8.60562 4.62875 8.60562C7.03698 8.60562 8.98275 6.79543 8.98275 4.30613ZM4.98141 5.27512C5.5007 5.07775 5.76371 4.49108 5.56896 3.96485C5.37422 3.43862 4.79536 3.17199 4.27613 3.36936C3.75692 3.56681 3.49383 4.1534 3.68858 4.6797C3.88333 5.20593 4.46218 5.47249 4.98141 5.27512Z"
        fill="#4EAD37"
      />
    </svg>
  )
}

export const NoszIntegrationIcon = styled(BaseNoszIntegrationIcon)``
