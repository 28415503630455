import React from 'react'
import PropTypes from 'prop-types'

import { Popover } from '@material-ui/core'
import styled, { css } from 'styled-components'

import { HTMLElementPropType } from '@helpers'

import { OldIconButton } from '@components/ui'

import FiltersIcon from './FiltersIcon'

function getVariantStyles(variant: FilterOptionsProps['variant']) {
  switch (variant) {
    case 'date':
      return css`
        width: 40px;
        height: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `
    case 'search':
      return css`
        width: 30px;
        height: 30px;
        margin-right: -5px;
      `
    default:
      throw new Error(`Invalid variant used: ${variant} for getVariantStyles`)
  }
}

const FilterIconButton = styled(OldIconButton)<{ $variant: FilterOptionsProps['variant'] }>`
  ${({ $variant }) => getVariantStyles($variant)}
`

interface FilterOptionsProps {
  anchorEl: HTMLDivElement | null
  children: React.ReactNode
  className?: string
  disabled?: boolean
  onClose: VoidFunction
  onClick: VoidFunction
  open: boolean
  variant: 'date' | 'search'
}

export default function FilterOptions({
  anchorEl,
  children,
  className,
  disabled = false,
  onClick,
  onClose,
  open,
  variant,
}: FilterOptionsProps) {
  const id = open ? 'filter-options-popover' : undefined

  return (
    <>
      <FilterIconButton
        $variant={variant}
        aria-describedby={id}
        className={className}
        disabled={disabled}
        onClick={onClick}
      >
        <FiltersIcon />
      </FilterIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Popover>
    </>
  )
}

FilterOptions.propTypes = {
  anchorEl: PropTypes.oneOfType([HTMLElementPropType, PropTypes.object]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
