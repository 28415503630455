import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { DocRenderer, DocViewerMainState } from '@components/DocViewer/types'

import { FileControls } from '../FileControls'
import { PreviewControls } from '../PreviewControls'
import { ImageControls } from './ImageControls'
import { ImageControlsProvider } from './ImageControlsProvider'
import { ImageDoc } from './ImageDoc'

const ImageRendererContainer = styled.div`
  display: flex;
  margin: auto;
`

export const ImageRenderer: DocRenderer = ({ hideFileControls, mainState }) => {
  return (
    <ImageControlsProvider mainState={mainState}>
      <ImageRendererContainer>
        <ImageDoc />
      </ImageRendererContainer>
      <PreviewControls>
        <ImageControls />
        {!hideFileControls && <FileControls mainState={mainState} />}
      </PreviewControls>
    </ImageControlsProvider>
  )
}

ImageRenderer.fileTypes = []

ImageRenderer.propTypes = {
  hideFileControls: PropTypes.bool,
  mainState: PropTypes.object.isRequired as React.Validator<DocViewerMainState>,
}
