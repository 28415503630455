import React from 'react'

import { useAssertContext } from '@hooks/useAssertContext'

import { CreatePaymentContextProps, EditPaymentContextProps } from './types'

export const CreatePaymentContext = React.createContext<Nullable<CreatePaymentContextProps>>(null)

CreatePaymentContext.displayName = 'CreatePaymentContext'

export function useCreatePayment() {
  return useAssertContext(CreatePaymentContext)
}

export const EditPaymentContext = React.createContext<Nullable<EditPaymentContextProps>>(null)

EditPaymentContext.displayName = 'EditPaymentContext'

export function useEditPayment() {
  return useAssertContext(EditPaymentContext)
}
