import { FormatNumberOptions, IntlShape } from 'react-intl'

import {
  FORMATTER_GIRO_BANK_ACCOUNT_NUMBER_REGEX,
  FORMATTER_HU_IBAN_BANK_ACCOUNT_NUMBER_REGEX,
  FORMATTER_HU_TAX_NUMBER_REGEX,
  FORMATTER_IBAN_BANK_ACCOUNT_NUMBER_REGEX,
  REQUIRED_DIGITS_REGEX,
  SPACE_FINDER_REGEX,
} from '@constants'

import Formatter from './inputFormatter'

export const taxNumberFormatter = Formatter({ re: FORMATTER_HU_TAX_NUMBER_REGEX, pattern: '12345678-1-12' })

// based on https://www.iban.com/structure
export const accountNumberFormatter = Formatter([
  { re: FORMATTER_GIRO_BANK_ACCOUNT_NUMBER_REGEX, pattern: '12345678-12345678-12345678' },
  { re: FORMATTER_HU_IBAN_BANK_ACCOUNT_NUMBER_REGEX, pattern: 'AB12 1234 5678 1234 5678 1234 5678' },
  { re: FORMATTER_IBAN_BANK_ACCOUNT_NUMBER_REGEX, pattern: 'AB12123456789012345678901234567890', length: 33 },
])

// TODO add validator
export const dateFormatter = Formatter(
  { re: REQUIRED_DIGITS_REGEX, pattern: '1111-22-33', length: 10 },
  { parseBeforeFormat: true }
)

//* for ReactHookFormTextField
export const taxNumberTransform: ReactHookFormTransform = {
  input: taxNumberFormatter.format,
  output: taxNumberFormatter.parse,
  positioner: taxNumberFormatter.positioner,
}

export const bankAccountNumberTransform: ReactHookFormTransform = {
  input: accountNumberFormatter.format,
  output: accountNumberFormatter.parse,
  positioner: accountNumberFormatter.positioner,
}

export function formatNumberToCopy(value: number, formatter: (value: number) => string) {
  return formatter(value).replace(SPACE_FINDER_REGEX, '')
}

/**
 * Helper function to replace multiple parts in text
 *
 * @param str - string to replace in
 * @param mapObject - key values object to replace with. We're looking for key, and replace it by value
 * @returns - replaced string
 */
export function replaceAll(str: string, mapObject: Record<string, string | number>) {
  const re = new RegExp(Object.keys(mapObject).join('|'), 'gi')

  return str.replace(re, function (matched) {
    return String(mapObject[matched.toLowerCase()])
  })
}

const ABBREVATIONS: Record<string, string[]> = {
  hu: ['', 'E', 'M', 'Mrd', 'B', 'Brd', 'T'],
  en: ['', 'k', 'm', 'bn', 'tn', 'P', 'E'],
}

type AbbrevOptions = { fractionDigits?: number; breakPoint?: number; locale?: string }

export function abbrevNumber(
  value: number,
  formatter: IntlShape['formatNumber'],
  formatterOptions: FormatNumberOptions,
  {
    fractionDigits = formatterOptions.maximumFractionDigits ?? 1,
    breakPoint = 10000000,
    locale = 'hu',
  } = {} as AbbrevOptions
) {
  if (Math.abs(value) >= breakPoint) {
    const roundDivider = Math.pow(10, fractionDigits)
    const roundedValue = Math.round(value * roundDivider) / roundDivider
    const valueLength = Math.floor((String(Math.floor(roundedValue)).length - (roundedValue < 0 ? 2 : 1)) / 3) * 3
    const abbreviation = ABBREVATIONS[locale][valueLength / 3]
    const currency = formatterOptions?.style === 'currency' ? formatter(1, formatterOptions).replace('1', '') : ''
    const abbrevValue = roundedValue / Math.pow(10, valueLength)
    const formatted = formatter(abbrevValue, formatterOptions)
    const pos = formatted.indexOf(currency)
    const formattedNumber = formatter(abbrevValue, {
      ...formatterOptions,
      style: 'decimal',
      maximumFractionDigits: fractionDigits,
    })

    if (abbreviation) {
      if (pos === 0) {
        return `${currency}${formattedNumber} ${abbreviation}`
      }
      return `${formattedNumber} ${abbreviation} ${currency}`
    }
  }
  return formatter(value, formatterOptions)
}
