import { COMPANY_ROUTE, V2_COMPANY_ROUTE } from '@constants'

export const API_QUARANTINE_DETAILS_URL = `${COMPANY_ROUTE}quarantine/{invoice_id}`
export const API_QUARANTINE_MERGE_INVOICES_URL = `${COMPANY_ROUTE}merge-invoices/merge/`
export const API_QUARANTINE_EXPENSE_PREVIEW_URL = `${COMPANY_ROUTE}merge-invoices/{invoice_id}/`

//* V2
export const V2_API_QUARANTINE_LIST_URL = `${V2_COMPANY_ROUTE}quarantine-expenses`

export const V2_API_QUARANTINE_CHARTS_URL = `${V2_COMPANY_ROUTE}quarantine-expenses/charts`

export const V2_API_QUARANTINE_ACTIONS_URL = `${V2_COMPANY_ROUTE}quarantine-expenses/actions`

export const V2_API_QUARANTINE_EXPENSE_EXISTS_URL = `${V2_COMPANY_ROUTE}quarantine-expenses/exists`
