import React from 'react'

import styled from 'styled-components'

function BaseTelescopeIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M22.2959 10.7752L19.6178 4.33456C19.5083 4.08065 19.2212 3.95893 18.9634 4.06434L15.2978 5.5834C15.0399 5.68887 14.9209 5.9784 15.0265 6.23621L15.1274 6.48003L4.65369 11.5533C4.39581 11.6784 4.26962 11.968 4.37119 12.2102L4.64472 12.8705L2.64206 13.7002C2.47212 13.7721 2.33337 13.9783 2.33337 14.1627C2.33337 14.2174 2.35095 14.3033 2.37147 14.3541L2.99834 15.8672C3.06866 16.0376 3.27572 16.1758 3.46022 16.1758C3.51491 16.1758 3.60075 16.1587 3.65153 16.1377L5.65212 15.3096L5.92516 15.9686C6.02678 16.2069 6.31641 16.3274 6.58984 16.2336L10.5586 14.8683C10.5649 14.8765 10.5686 14.8819 10.575 14.8901L9.12712 18.9721C8.98944 19.3627 9.1935 19.7914 9.58431 19.9292C9.66462 19.9877 9.749 20.0002 9.80525 20.0002C10.1139 20.0002 10.404 19.8078 10.5124 19.5002L11.8531 15.7002C11.9992 15.7306 12.15 15.7486 12.3056 15.7486C12.4609 15.7486 12.6125 15.7329 12.759 15.7029L14.0987 19.4998C14.2334 19.8064 14.524 20.0002 14.8053 20.0002C14.8883 20.0002 14.9723 19.9865 15.0553 19.9572C15.4459 19.8195 15.65 19.3906 15.5125 19L14.0618 14.8906C14.3662 14.5069 14.5553 14.0282 14.5553 13.5003C14.5553 13.4922 14.5528 13.4845 14.5527 13.4764L17.5755 12.4345L17.6768 12.677C17.7822 12.9348 18.0758 13.0539 18.3299 12.9486L21.9955 11.4295C22.2803 11.3252 22.399 11.0033 22.2959 10.7752ZM12.3053 14.2221C11.8918 14.2221 11.5553 13.8855 11.5553 13.4721C11.5553 13.0586 11.8918 12.7221 12.3053 12.7221C12.719 12.7221 13.0553 13.0586 13.0553 13.4721C13.0553 13.8855 12.7459 14.2221 12.3053 14.2221ZM14.0553 12.0689C13.6424 11.5724 13.0284 11.2502 12.3321 11.2502C11.1099 11.2502 10.1203 12.2252 10.0874 13.4392L6.94681 14.5236L6.11462 12.5127L15.699 7.84081L17.0274 11.0439L14.0553 12.0689ZM18.6803 11.1783L16.7656 6.55643L18.5865 5.80643L20.5009 10.4252L18.6803 11.1783Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const TelescopeIcon = styled(BaseTelescopeIcon)``
