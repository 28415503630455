import { serialize } from 'object-to-formdata'
import Qs from 'qs'

import { callUrl } from '@services/common/api'

import { injectCompanyToURL, replaceAll } from '@helpers'

import Api from '../api'
import {
  API_BULK_CATEGORIZATION_URL,
  API_INCOME_ARTIFACT_DELETE_URL,
  API_INCOME_ARTIFACT_DOWNLOAD_URL,
  API_INCOME_DETAILS_URL,
  API_INCOME_INVOICE_NUMBER_CHECK_URL,
  API_INCOME_LIST_URL,
  API_INCOME_REGISTER_DOWNLOAD_URL,
  API_INCOME_USER_VIEW_URL,
  API_INVOICE_RECOMMENDATION_URL,
  API_MISSING_INCOMES_URL,
  API_REVENUE_TYPE_URL,
  V2_API_INCOME_BACKGROUND_ACTION_URL,
  V2_API_INCOME_CHARTS_URL,
  V2_API_INCOME_LIST_URL,
  V2_API_INIT_INCOME_DOWNLOAD_URL,
} from './urls'

export function fetchIncomeDetails(companyId: number, payload: unknown) {
  return Api.get(injectCompanyToURL(API_INCOME_DETAILS_URL, companyId).replace('{income_id}', String(payload)))
}

//* INCOMES
export function userViewIncome(companyId: number, payload: unknown) {
  return Api.post(injectCompanyToURL(API_INCOME_USER_VIEW_URL, companyId).replace('{income_id}', String(payload)))
}

export function fetchRevenueTypes(companyId: number) {
  return Api.get(injectCompanyToURL(API_REVENUE_TYPE_URL, companyId))
}

export function createRevenueType(data: Record<string, unknown> & { company: number }) {
  return Api.post(injectCompanyToURL(API_REVENUE_TYPE_URL, data.company), data)
}

// recommendation
export function updateIncomeWithRecommendation({ company, ...payload }: Record<string, unknown> & { company: number }) {
  return Api.post(injectCompanyToURL(API_INVOICE_RECOMMENDATION_URL, company), payload)
}

//* BULK UPDATE
export function bulkIncomeUpdateCheck(
  companyId: number,
  { partner_id, payment_method, id: invoiceId }: { partner_id: number; payment_method: string; id: number }
) {
  return Api.get(
    replaceAll(injectCompanyToURL(API_MISSING_INCOMES_URL, companyId), {
      '{partner_id}': partner_id,
      '{payment_method}': payment_method,
      '{income_id}': invoiceId,
    })
  )
}

export function bulkIncomeUpdate({
  count,
  company_id,
  partner_id,
  payment_method,
  ...data
}: Record<string, unknown> & { id: number; company_id: number; partner_id: number; payment_method: string }) {
  return Api.patch(
    replaceAll(injectCompanyToURL(API_MISSING_INCOMES_URL, company_id), {
      '{partner_id}': partner_id,
      '{payment_method}': payment_method,
      '{income_id}': data.id,
    }),
    data
  )
}

//* ARTIFACTS
// artifact file upload to existing income
export function uploadIncomeArtifact(companyId: number, { invoice, ...data }: Record<string, unknown>) {
  return Api.patch(
    injectCompanyToURL(API_INCOME_DETAILS_URL, companyId).replace('{income_id}', String(invoice)),
    data,
    {
      timeout: 5 * 60000, // 5 * 60sec
      transformRequest: [
        function (data) {
          return serialize(data)
        },
      ],
    }
  )
}

export function removeIncomeArtifact(companyId: number, data: unknown) {
  return Api.post(injectCompanyToURL(API_INCOME_ARTIFACT_DELETE_URL, companyId), data)
}

//* CREATE INCOME
// by form post
export function createIncome(companyId: number, data: unknown) {
  return Api.post(injectCompanyToURL(API_INCOME_LIST_URL, companyId), data)
}

// update by form submit
export function updateIncome(companyId: number, { id, ...data }: Record<string, unknown>) {
  return Api.patch(injectCompanyToURL(API_INCOME_DETAILS_URL, companyId).replace('{income_id}', String(id)), data)
}

export function removeIncome(companyId: number, id: number) {
  return Api.delete(injectCompanyToURL(API_INCOME_DETAILS_URL, companyId).replace('{income_id}', String(id)))
}

// CATEGORIZATION
export function bulkCategorization(companyId: number, data: unknown) {
  return Api.post(injectCompanyToURL(API_BULK_CATEGORIZATION_URL, companyId), data)
}

// DOWNLOADS
export function downloadIncomeArtifact(companyId: number, { id, ...data }: Record<string, unknown>) {
  return Api.post(
    injectCompanyToURL(API_INCOME_ARTIFACT_DOWNLOAD_URL, companyId).replace('{income_id}', String(id)),
    data
  )
}

export function invoiceNumberCheck(companyId: number, data: unknown) {
  return Api.post(injectCompanyToURL(API_INCOME_INVOICE_NUMBER_CHECK_URL, companyId), data)
}

export function incomeRegisterDownload(companyId: number, data: unknown) {
  return Api.post(injectCompanyToURL(API_INCOME_REGISTER_DOWNLOAD_URL, companyId), data, { responseType: 'blob' })
}

//* V2 API
export function fetchIncomeListV2(companyId: number, params: Partial<IncomeListStoreFilters>) {
  return Api.get(injectCompanyToURL(V2_API_INCOME_LIST_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params)
      },
    },
  })
}

export function fetchIncomeCharts(companyId: number, params: Partial<IncomeListStoreFilters>) {
  return Api.get(injectCompanyToURL(V2_API_INCOME_CHARTS_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params)
      },
    },
  })
}

export function fetchIncomeListByPagingV2(url: string) {
  return callUrl({ method: 'get', url })
}

export function initDownloadV2(companyId: number, params: unknown) {
  return Api.get(injectCompanyToURL(V2_API_INIT_INCOME_DOWNLOAD_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params, { indices: false })
      },
    },
  })
}

//* SPECIAL BACKGROUND-ACTION API
// TODO payload type is ReturnType<typeof generateBackgroundProcessActionPayload> - service-v2 helper
export function incomeBackgroundAction(companyId: number, payload: unknown) {
  return Api.post<BackgroundActionResponse>(injectCompanyToURL(V2_API_INCOME_BACKGROUND_ACTION_URL, companyId), payload)
}
