import React from 'react'

import styled from 'styled-components'

import InfoIcon from './InfoIcon'

const StyledInfoIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.colors.gray[40]};
  cursor: pointer;
`

/**
 * ForwardRef component as this icon is mainly used with tooltips which require a ref
 */
const LabelInfoIcon = React.forwardRef<HTMLSpanElement, IconComponentProps>((props, ref) => {
  return (
    <span ref={ref}>
      <StyledInfoIcon size={12} {...props} />
    </span>
  )
})

LabelInfoIcon.displayName = 'LabelInfoIcon'

export default LabelInfoIcon
