import React from 'react'

export function useTimerRef() {
  const timerRef = React.useRef<number>()

  React.useEffect(() => {
    const timer = timerRef.current
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [])

  return timerRef
}
