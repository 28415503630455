import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem } from '@material-ui/core'

import { getRouteUrl, isAdmin, replaceCompanyIdInPath } from '@helpers'

import { SettingsIcon } from '@components/ui'
import { CompanyRole } from '@oldComponents/CompanyRole'
import OverflowTextWithTooltip from '@oldComponents/OverflowTextWithTooltip'

import { RouteKeys, ROUTES } from '@constants'

import { TertiaryInfoTypography } from '../styles'
import { CompanySelectLink, CompanySettingsLink } from './styles'

interface MenuItemWithNavigationProps {
  company: CompaniesItem
  disabled: boolean
  isHighlighted: boolean
  onSelect: (company: CompaniesItem, isSettings?: boolean) => React.MouseEventHandler<HTMLAnchorElement>
}

export function MenuItemWithNavigation({ company, disabled, onSelect, isHighlighted }: MenuItemWithNavigationProps) {
  return (
    <MenuItem selected={isHighlighted} disabled={disabled}>
      <CompanySelectLink
        onClick={onSelect(company)}
        to={replaceCompanyIdInPath(`${ROUTES.root}/:company_id/`, company.id)}
      >
        <OverflowTextWithTooltip disablePortal={false}>{company.name}</OverflowTextWithTooltip>
        <TertiaryInfoTypography>
          <CompanyRole data={company} />
        </TertiaryInfoTypography>
      </CompanySelectLink>
      {isAdmin(company.role) && (
        <CompanySettingsLink
          data-testid="company-settings-btn"
          onClick={onSelect(company, true)}
          to={getRouteUrl(RouteKeys.COMPANY, company.id)}
        >
          <SettingsIcon />
        </CompanySettingsLink>
      )}
    </MenuItem>
  )
}

MenuItemWithNavigation.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired, // TODO exact role options
    company_type: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}
