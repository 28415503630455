// actions
// helpers
import { getDefaultPageSize } from '@helpers'

import attachmentActions from '../attachment/actions'
import filtersActions from '../filters/actions'
import actions from './actions'

const defaultPageSize = getDefaultPageSize()

const returnFilteredPartnerData = (
  {
    account_number,
    address,
    auto_created_type,
    city,
    default_account_number,
    id,
    is_customer,
    is_kata_subject,
    is_provider,
    name,
    tax_number,
    zip_code,
  },
  isCreate = false
) => {
  const defaultPartnerData = {
    account_number,
    address,
    auto_created_type,
    city,
    default_account_number,
    id,
    is_customer,
    is_kata_subject,
    is_provider,
    name,
    tax_number,
    zip_code,
  }

  if (isCreate) {
    const extraProps = is_provider
      ? { sum_expense: 0, sum_outstanding: 0 }
      : {
          sum_customer_debt: 0,
          sum_income: 0,
        }

    return {
      ...defaultPartnerData,
      ...extraProps,
      has_attachment: false,
      has_alternate_name: false,
    }
  }
  return defaultPartnerData
}

const controlOrderByWithActiveTab = (state, activeTab) => {
  if (activeTab === 'customer') {
    if (state.orderBy === 'sum_outstanding') {
      return 'sum_customer_debt'
    }
    if (state.orderBy === 'sum_expense') {
      return 'sum_income'
    }
  }
  if (activeTab === 'provider') {
    if (state.orderBy === 'sum_customer_debt') {
      return 'sum_outstanding'
    }
    if (state.orderBy === 'sum_income') {
      return 'sum_expense'
    }
  }
  return state.orderBy
}

export const initialState = {
  data: [],
  order: 'asc',
  orderBy: 'name',
  count: 0,
  page: 0,
  pageSize: defaultPageSize,
  // nextPageUrl: null,
  // prevPageUrl: null,
  loading: false,
  error: null,
  fetched: false,
  activeTab: 'provider',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.fetchPartners.REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      }
    case actions.fetchPartners.SUCCESS:
      // partner page list w/ pagination
      if (
        Object.prototype.hasOwnProperty.call(action.payload, 'results') &&
        Object.prototype.hasOwnProperty.call(action.payload, 'count')
      ) {
        return {
          ...state,
          data: action.payload.results,
          count: action.payload.count,
          // nextPageUrl: action.payload.next,
          // prevPageUrl: action.payload.previous,
          loading: false,
          fetched: true,
          error: null,
        }
        // dropdown options - simple list
      }
      return {
        ...state,
        data: action.payload,
        loading: false,
        fetched: true,
        error: null,
      }

    case actions.fetchPartners.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case actions.updatePartner.SUCCESS:
      return {
        ...state,
        data: state.data.map(partner =>
          partner.id === action.payload.id ? { ...partner, ...returnFilteredPartnerData(action.payload) } : partner
        ),
      }

    case attachmentActions.updatePartnerAttachment.REQUEST:
      return {
        ...state,
        data: state.data.map(partner =>
          partner.id === action.payload.id ? { ...partner, ...action.payload } : partner
        ),
      }

    case filtersActions.updatePartnerListFilters.REQUEST:
      return {
        ...state,
        page: 0,
      }

    case actions.updateOrder.REQUEST:
      return {
        ...state,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
      }
    case actions.updatePage.REQUEST:
      return {
        ...state,
        page: action.payload,
      }
    case actions.updateRowsPerPage.REQUEST:
      return {
        ...state,
        pageSize: action.payload.pageSize,
      }

    case actions.createPartner.SUCCESS:
      return {
        ...state,
        data: [...state.data, returnFilteredPartnerData(action.payload, true)],
      }

    case actions.changeActiveTab.REQUEST:
      return {
        ...state,
        activeTab: action.payload,
        page: 0,
        orderBy: controlOrderByWithActiveTab(state, action.payload),
      }

    default:
      return state
  }
}
