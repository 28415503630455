import React from 'react'
import PropTypes from 'prop-types'

import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { getSzamlazzHuBackUrl } from '@helpers'

import { LinkButtonAnchorProps } from '@components/ui/Buttons/LinkButton'
import { SzamlazzHuIcon } from '@components/ui/svgIcons'

import { messages } from './messages'
import { StyledLinkButton } from './styles'

type BackToSzamlazzButtonProps = Omit<LinkButtonAnchorProps, 'children' | 'href'> & {
  backUrl?: string | null
  isCompact?: boolean
  isDesktop?: boolean
}

function PureBackToSzamlazzButton({
  backUrl,
  isCompact = false,
  isDesktop = false,
  ...rest
}: BackToSzamlazzButtonProps) {
  const { formatMessage } = useIntl()

  if (!backUrl) {
    return null
  }

  if (isDesktop) {
    return (
      <StyledLinkButton
        {...rest}
        href={backUrl}
        title={formatMessage(messages.backToSzamlazz)}
        $isCompact={isCompact}
        withoutIcon
      >
        <SzamlazzHuIcon />
      </StyledLinkButton>
    )
  }

  return (
    <StyledLinkButton {...rest} href={backUrl} $isCompact={isCompact} withoutIcon>
      {formatMessage(messages.backToSzamlazz)}
    </StyledLinkButton>
  )
}

PureBackToSzamlazzButton.propTypes = {
  backUrl: PropTypes.string,
  isCompact: PropTypes.bool,
  isDesktop: PropTypes.bool,
}

export const BackToSzamlazzButton = connect(
  (state: Store) => ({
    backUrl: getSzamlazzHuBackUrl(state),
  }),
  null
)(PureBackToSzamlazzButton)

BackToSzamlazzButton.displayName = 'BackToSzamlazzButton'
