import React from 'react'

import styled from 'styled-components'

import { ButtonBaseProps } from './ButtonBase'
import TextButton from './TextButton'

interface ButtonWithIconProps extends ButtonBaseProps {
  startIcon?: React.ReactChild
  endIcon?: React.ReactChild
}

const StyledTextButton = styled(TextButton)`
  display: flex;
  align-items: center;
  gap: 5px;
`

const IconWrapperSpan = styled.span`
  display: inherit;
  align-items: inherit;
`

export function TextButtonWithIcon({ startIcon, endIcon, children, ...props }: ButtonWithIconProps) {
  return (
    <StyledTextButton {...props}>
      <>
        {startIcon && <IconWrapperSpan>{startIcon}</IconWrapperSpan>}
        {children}
        {endIcon && <IconWrapperSpan>{endIcon}</IconWrapperSpan>}
      </>
    </StyledTextButton>
  )
}
