import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'
import { FlexColumn, Paper } from '@components/ui/Wrappers'

import { A4_PAPER_SIZE } from '@constants'

export const IconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${({ theme }) => theme.colors.gray[130]};
`

export const CardTitle = styled(Typography).attrs({
  tag: 'h3',
  size: 'heading-6',
  align: 'left',
})`
  margin-bottom: 10px;
  margin-right: 30px;
`

export const Label = styled(Typography).attrs({
  size: '400-xs',
  align: 'left',
})`
  color: ${({ theme }) => theme.colors.gray[130]};
`

export const Value = styled(Typography).attrs({
  size: '400-sm',
  align: 'left',
})``

export const BoldValue = styled(Typography).attrs({
  size: '700-sm',
  align: 'left',
})``

export const ContainerDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;

  & ${FlexColumn} {
    flex: 1;
  }

  & + & {
    margin-top: 8px;
  }
`

export const Card = styled(Paper).attrs({
  as: 'button',
})<{ $selected: boolean }>`
  position: relative;
  padding: 10px 20px;
  width: 100%;
  border-color: ${({ theme, $selected }) => ($selected ? theme.colors.primary[50] : theme.colors.common.paperStroke)};

  &:hover {
    cursor: pointer;
  }

  & + & {
    margin-top: 20px;
  }
`
export const ContentContainerDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  min-height: 300px;
`

export const PreviewContainer = styled(Paper)`
  width: ${A4_PAPER_SIZE.width}px;
  height: ${A4_PAPER_SIZE.height}px;
  overflow: auto;
  position: relative;
`

const PREVIEW_HEADER_CONTAINER_HEIGHT = 30

export const PreviewHeaderContainer = styled.div`
  height: ${PREVIEW_HEADER_CONTAINER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  padding: 5px 10px;
`

export const PreviewContainerArtifactWrapper = styled.div`
  height: calc(100% - ${PREVIEW_HEADER_CONTAINER_HEIGHT}px);
`

export const ColumnDiv = styled.div`
  padding: 5px 20px 15px;

  & > ${Typography} {
    margin-bottom: 5px;
  }

  & + & {
    border-left: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  }
`

export const ContentWrapperDiv = styled.div`
  min-width: 300px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export const PreviewHeaderContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  height: 24px;
  align-items: center;
`
