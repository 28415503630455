import React from 'react'

import { FormattedMessage } from 'react-intl'

export const TransactionsPageMessage = <FormattedMessage id="navLink.transactions" defaultMessage="Tranzakciók" />

export const BankTransactionsListPageMessage = (
  <FormattedMessage id="dashboard.dataTabs.bankTransactions" defaultMessage="Banki tranzakciók" />
)

export const ExpenseListPageMessage = <FormattedMessage id="dashboard.dataTabs.invoices" defaultMessage="Költségek" />

export const DokumentsPageMessage = <FormattedMessage id="navLink.documents" defaultMessage="Dokumentumok" />

export const QuarantineListPageMessage = (
  <FormattedMessage id="dashboard.dataTabs.quarantineInvoices" defaultMessage="Hiányos számlák" />
)

export const IncomeListPageMessage = <FormattedMessage id="dashboard.dataTabs.incomes" defaultMessage="Bevételek" />

export const TaxListPageMessage = <FormattedMessage id="dashboard.dataTabs.taxes" defaultMessage="Adók" />

export const SalaryListPageMessage = <FormattedMessage id="dashboard.dataTabs.salary" defaultMessage="Bérköltségek" />

export const MonthlyStatementPageMessage = (
  <FormattedMessage id="pages.title.monthlyStatement" defaultMessage="Havi eredmény" />
)

export const LiquidityPageMessage = <FormattedMessage id="navLink.liquidity" defaultMessage="Likviditás" />

export const ChatHeaderMessage = <FormattedMessage id="pulse.chat.card.header" defaultMessage="QUiCK Chat (béta)" />

export const DetailsTabGeneralInfoMessage = (
  <FormattedMessage id="detailsTabs.label.detailsForm" defaultMessage="Általános infó" />
)

export const DetailsTabItemsMessage = <FormattedMessage id="detailsTabs.label.items" defaultMessage="Tételek" />

export const DetailsTabEventsMessage = (
  <FormattedMessage id="detailsTabs.label.eventsLog" defaultMessage="Számlatörténet" />
)
