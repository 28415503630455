import React from 'react'

import { useFormState } from 'react-final-form'

import { AsyncButtonWithIcon, AsyncButtonWithIconProps } from './AsyncButtonWithIcon'

import { SaveButtonMessage } from '@messages'

type RffSubmitButtonProps = Omit<AsyncButtonWithIconProps, 'children' | 'loading' | 'type'> &
  Partial<Pick<AsyncButtonWithIconProps, 'children'>>

/**
 * Submit button component with dynamic disabled state based on form state
 */
export function RffSubmitButton({
  children = SaveButtonMessage,
  variant = 'primaryContained',
  ...rest
}: RffSubmitButtonProps) {
  const { submitting } = useFormState({
    subscription: {
      submitting: true,
    },
  })

  return (
    <AsyncButtonWithIcon {...rest} loading={submitting} type="submit" variant={variant}>
      {children}
    </AsyncButtonWithIcon>
  )
}
