import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Collapse } from '@components/ui/CollapseElements'
import { UnorderedList } from '@components/ui/Wrappers'

import triangleSVGSrc from '@assets/img/triangle_small.svg'

import { SimpleContentAccordionControl, SimpleContentAccordionControlBaseProps } from './elements'

const BulletedList = styled(UnorderedList)`
  padding-top: 12px;

  li {
    padding-left: 28px;
    position: relative;
    font-size: 12px;
    line-height: 16px;

    &:before {
      position: absolute;
      left: 0px;
      content: '';
      width: 16px;
      height: 16px;
      background-image: url(${triangleSVGSrc});
      background-position: top 4px left 4px;
      background-repeat: no-repeat;
    }
  }

  li + li {
    margin-top: 12px;
  }
`

interface InformationListAccordionProps extends SimpleContentAccordionControlBaseProps {
  children: React.ReactChild | Nullable<React.ReactChild>[]
  visibleOnRender?: boolean
  withoutList?: boolean
}

/**
 * Simple listing component with collapse functionality
 *
 * @param {InformationListAccordionProps} {
 *   children,
 *   className,
 *   title,
 *   titleTag = 'h2',
 *   visibleOnRender = true,
 *   withoutList = false,
 * }
 */
export default function InformationListAccordion({
  children,
  className,
  title,
  titleTag = 'h2',
  visibleOnRender = true,
  withoutList = false,
}: InformationListAccordionProps) {
  const [isDetailVisible, setDetailVisible] = React.useState(visibleOnRender)

  // wrap every children component into list elements to be DRY
  const controlledChildren = withoutList ? (
    children
  ) : (
    <BulletedList>
      {React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript error too.
        if (React.isValidElement(child)) {
          return <li>{React.cloneElement(child)}</li>
        }
        return child
      })}
    </BulletedList>
  )

  function toggleVisibility() {
    setDetailVisible(state => !state)
  }

  return (
    <>
      <SimpleContentAccordionControl
        className={className}
        title={title}
        titleTag={titleTag}
        isDetailVisible={isDetailVisible}
        toggleVisibility={toggleVisibility}
      />
      <Collapse isExpanded={isDetailVisible}>{controlledChildren}</Collapse>
    </>
  )
}

InformationListAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  titleTag: PropTypes.string,
  visibleOnRender: PropTypes.bool,
  withoutList: PropTypes.bool,
}
