import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { Button } from '@components/ui'

interface KnowledgeCenterButtonProps {
  href: string
  onClick: VoidFunction
}

export function KnowledgeCenterButton(props: KnowledgeCenterButtonProps) {
  return (
    <Button {...props} variant="primaryContained" as="a" rel="noopener noreferrer" target="_blank">
      <FormattedMessage id="general.buttons.knowledgeCenter" defaultMessage="Ugrás a Tudáscentrumba" />
    </Button>
  )
}

KnowledgeCenterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
