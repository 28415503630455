import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import { API_BACKGROUND_JOB_EMAIL_URL, API_BACKGROUND_JOB_URL } from './urls'

export const backgroundJob = ({ company_id, id }) =>
  Api.get(injectCompanyToURL(API_BACKGROUND_JOB_URL, company_id).replace('{job_id}', id))

export const backgroundJobEmail = ({ company_id, id }) =>
  Api.post(injectCompanyToURL(API_BACKGROUND_JOB_EMAIL_URL, company_id).replace('{job_id}', id))
