import React from 'react'
import PropTypes from 'prop-types'

import __difference from 'lodash/difference'
import __escapeRegExp from 'lodash/escapeRegExp'

import { CommonFilterSelectProps } from './types'
import { UpsellFilterSelect } from './UpsellFilterSelect'
import { WidgetFilterSelect } from './WidgetFilterSelect'

interface FilterSelectProps<Payload extends Record<string, SelectableFilterOption[]>>
  extends Omit<CommonFilterSelectProps<Payload>, 'selectableOptions' | 'selectedOptions'> {
  disabled?: boolean
  options: SelectableFilterOption[]
  value: SelectableFilterOption[]
}

export function FilterSelect<Payload extends Record<string, SelectableFilterOption[]>>({
  disabled,
  emptyPlaceholder,
  hasPermission,
  keyValue,
  labelText,
  onChange,
  options,
  selectedLabelText,
  upsellMessage,
  value,
}: FilterSelectProps<Payload>) {
  const [searchTerm, setSearchTerm] = React.useState('')

  const selectedOptions = React.useMemo(() => {
    const optionIds = options.map(({ id }) => id)
    return value.filter(({ id }) => optionIds.includes(id))
  }, [options, value])

  const selectableOptions = React.useMemo(() => {
    const diffOptions = __difference(options, selectedOptions)

    if (searchTerm) {
      const regex = new RegExp(__escapeRegExp(searchTerm), 'i')

      return diffOptions.filter(option => regex.test(option.name))
    }
    return diffOptions
  }, [options, searchTerm, selectedOptions])

  if (hasPermission === false) {
    return <UpsellFilterSelect emptyPlaceholder={emptyPlaceholder} labelText={labelText} message={upsellMessage} />
  }

  // PageFilterBar widget
  return (
    <WidgetFilterSelect
      disabled={disabled}
      emptyPlaceholder={emptyPlaceholder}
      keyValue={keyValue}
      labelText={labelText}
      onChange={onChange}
      searchTerm={searchTerm}
      selectableOptions={selectableOptions}
      selectedLabelText={selectedLabelText}
      selectedOptions={selectedOptions}
      setSearchTerm={setSearchTerm}
      value={value}
    />
  )
}

FilterSelect.propTypes = {
  disabled: PropTypes.bool,
  hasPermission: PropTypes.bool,
  keyValue: PropTypes.string.isRequired,
  labelText: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  upsellMessage: PropTypes.node,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    ]).isRequired
  ).isRequired,
}
