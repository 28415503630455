import styled, { css, DefaultTheme } from 'styled-components'

import { getFontStyles } from '@helpers/styled-helpers'

import { ButtonBase, ButtonBaseProps } from './ButtonBase'
import { BUTTON_SIZE, BUTTON_VARIANT } from './constant'

import { OutlineEffectStyles } from '@styles'

export type SizeProps = ConstObjectValues<typeof BUTTON_SIZE>
type VariantProps = ConstObjectValues<typeof BUTTON_VARIANT>

export enum StyleOnlyPropKeys {
  FULL_WIDTH = 'fullWidth',
  SIZE = 'size',
  VARIANT = 'variant',
}

export interface StyleOnlyProps {
  [StyleOnlyPropKeys.FULL_WIDTH]?: boolean
  [StyleOnlyPropKeys.SIZE]?: SizeProps
  [StyleOnlyPropKeys.VARIANT]?: VariantProps
}

export interface StyleProps extends StyleOnlyProps {
  theme: DefaultTheme
}

function getButtonSizeStyles({ theme, size }: StyleProps) {
  switch (size) {
    case BUTTON_SIZE.LARGE:
      return css`
        height: 52px;
        min-width: 100px;
        padding: 12px 20px;
        ${getFontStyles(theme, 'navigation-lg')}
      `

    case BUTTON_SIZE.SMALL:
      return css`
        height: 32px;
        min-width: 60px;
        padding: 4px 12px;
        ${getFontStyles(theme, 'navigation-md')}
      `

    case BUTTON_SIZE.NORMAL:
    default:
      return css`
        height: 40px;
        min-width: 80px;
        padding: 8px 16px;
        ${getFontStyles(theme, 'navigation-md')}
      `
  }
}

export function getButtonVariantStyles({ theme: { colors }, variant }: StyleProps) {
  let activeBackgroundColor = 'transparent'
  let backgroundColor = 'transparent'
  let borderColor = backgroundColor
  let disabledBackgroundColor = 'transparent'
  let disabledBorderColor = 'transparent'
  let focusBorderColor = 'inherit'
  let fontColor = 'inherit'
  let hoverBackgroundColor = 'transparent'
  let hoverBorderColor = hoverBackgroundColor

  switch (variant) {
    case BUTTON_VARIANT.ALERT:
      activeBackgroundColor = focusBorderColor = colors.gray[30]
      fontColor = borderColor = colors.error[50]
      hoverBackgroundColor = hoverBorderColor = colors.gray[20]
      break

    case BUTTON_VARIANT.ALERT_TEXT:
      activeBackgroundColor = focusBorderColor = colors.gray[30]
      fontColor = colors.error[50]
      hoverBackgroundColor = hoverBorderColor = colors.gray[20]
      break

    case BUTTON_VARIANT.SECONDARY_CONTAINED:
      activeBackgroundColor = borderColor = disabledBorderColor = focusBorderColor = colors.gray[30]
      backgroundColor = disabledBackgroundColor = colors.gray[0]
      fontColor = colors.gray[70]
      hoverBackgroundColor = hoverBorderColor = colors.gray[20]
      break

    case BUTTON_VARIANT.PRIMARY_TEXT:
      activeBackgroundColor = colors.mango[30]
      focusBorderColor = colors.primary[30]
      fontColor = colors.primary[50]
      hoverBackgroundColor = hoverBorderColor = colors.mango[20]
      break

    case BUTTON_VARIANT.PRIMARY_CONTAINED:
      activeBackgroundColor = colors.primary[60]
      backgroundColor = colors.primary[50]
      disabledBackgroundColor = disabledBorderColor = colors.gray[30]
      focusBorderColor = colors.primary[30]
      fontColor = colors.gray[0]
      hoverBackgroundColor = hoverBorderColor = colors.primary[40]
      break

    case BUTTON_VARIANT.PRIMARY_CONTAINED_TRANSPARENT:
      borderColor = colors.gray[40]
      disabledBackgroundColor = disabledBorderColor = colors.gray[30]
      focusBorderColor = 'transparent'
      hoverBorderColor = colors.primary[40]
      break

    case BUTTON_VARIANT.SECONDARY_TEXT:
    default:
      activeBackgroundColor = focusBorderColor = colors.gray[30]
      fontColor = colors.gray[70]
      hoverBackgroundColor = hoverBorderColor = colors.gray[20]
      break
  }

  return css`
    --css-background-color: ${backgroundColor};
    --css-border-color: ${borderColor};
    --css-color: ${fontColor};
    --css-disabled-background-color: ${disabledBackgroundColor};
    --css-disabled-border-color: ${disabledBorderColor};
    --css-disabled-color: ${colors.gray[40]};
    --css-hover-background-color: ${hoverBackgroundColor};
    --css-hover-border-color: ${hoverBorderColor};
    --css-active-background-color: ${activeBackgroundColor};
    --css-focus-border-color: ${focusBorderColor};

    background-color: var(--css-background-color);
    border-color: var(--css-border-color);
    color: var(--css-color);

    ${OutlineEffectStyles}

    &:not(:disabled) {
      &:hover,
      &:focus:not(:active) {
        background-color: var(--css-hover-background-color);
        border-color: var(--css-hover-border-color);
      }
      &:active,
      &:hover:active,
      &:focus:active {
        background-color: var(--css-active-background-color);
        border-color: var(--css-active-background-color);
      }
      &:focus:not(:active) {
        &::before {
          border-color: var(--css-focus-border-color);
        }
      }
    }
    &:disabled {
      background-color: var(--css-disabled-background-color);
      border-color: var(--css-disabled-border-color);
      color: var(--css-disabled-color);
    }
  `
}

export interface ButtonProps extends ButtonBaseProps, StyleOnlyProps {}

/**
 * Button component
 *
 * @param {boolean} fullWidth - make block element
 * @param {string} size - normal or small
 * @param {string} variant - primaryContained, primaryText, secondaryContained, secondaryText, alert
 */
export const Button = styled(ButtonBase).withConfig<ButtonProps>({
  shouldForwardProp: (prop, defaultValidator) => {
    return !(Object.values(StyleOnlyPropKeys) as unknown[]).includes(prop) && defaultValidator(prop)
  },
})`
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'unset')};

  ${getButtonSizeStyles}
  ${getButtonVariantStyles}
`
