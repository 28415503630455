import React from 'react'

import { FormHelperText } from '@material-ui/core'
import { useWatch } from 'react-hook-form'
import { connect } from 'react-redux'

import { PaymentDatePicker } from './PaymentDatePicker'

interface PlannedPaymentDatepickerProps {
  className?: string
  invoiceDetails: InvoiceDetailsFrontendValues
}

function PurePlannedPaymentDatepicker({
  className,
  invoiceDetails: { id, planned_payment_date },
}: PlannedPaymentDatepickerProps) {
  const formDueAt = useWatch<InvoiceDetailsFormInitialValues>({ name: 'due_at' })

  if (!id || !planned_payment_date || formDueAt === planned_payment_date) {
    return null
  }

  return (
    <FormHelperText component="div" className={className}>
      <PaymentDatePicker invoiceId={id} plannedPaymentDate={planned_payment_date} />
    </FormHelperText>
  )
}

export const ExpensePlannedPaymentDatepicker = connect((state: Store) => ({
  invoiceDetails: state.expense.details.data,
}))(PurePlannedPaymentDatepicker)

ExpensePlannedPaymentDatepicker.displayName = 'ExpensePlannedPaymentDatepicker'
