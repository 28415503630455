import React from 'react'

import { PopperProps } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { PreviewVariant, usePreviewOpenHandler } from '@contexts'

import { EyeIcon, IconButton, NoActionIcon } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { isSupportedFileForPreview } from '../helpers'

import { messages } from './messages'

export interface ArtifactPreviewOpenerProps {
  detailsUrl?: string
  disablePortal?: boolean
  fileName: string
  path: string
  placement?: PopperProps['placement']
  previewVariant: PreviewVariant
  supportedMessage?: string
  title?: Nullable<string>
  unsupportedMessage?: string
}

export function ArtifactPreviewOpener({
  detailsUrl,
  disablePortal = true,
  placement,
  previewVariant,
  supportedMessage,
  unsupportedMessage,
  ...rest
}: ArtifactPreviewOpenerProps) {
  const { formatMessage } = useIntl()
  const { handleOpenWithArtifact } = usePreviewOpenHandler()

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      handleOpenWithArtifact(rest, previewVariant, detailsUrl)
    },
    [detailsUrl, handleOpenWithArtifact, previewVariant, rest]
  )

  const isSupported = isSupportedFileForPreview(rest.fileName)

  if (isSupported) {
    return (
      <LightTooltip
        title={supportedMessage ?? formatMessage(messages.dokumentSupportedMessage)}
        PopperProps={{ disablePortal, placement }}
      >
        <IconButton onClick={handleOpen} size="small">
          <EyeIcon size={20} />
        </IconButton>
      </LightTooltip>
    )
  }

  return (
    <LightTooltip
      title={unsupportedMessage ?? formatMessage(messages.dokumentUnsupportedMessage)}
      PopperProps={{ disablePortal, placement }}
    >
      <div>
        <IconButton disabled size="small">
          <NoActionIcon size={16} />
        </IconButton>
      </div>
    </LightTooltip>
  )
}
