import __find from 'lodash/find'
import { IntlShape } from 'react-intl'

import { CURRENCY_OPTIONS } from '../constants'
import { getDecimal } from './common'

export function getCurrency(currencyId: number, currencies: Currency[]) {
  return __find(currencies, ['id', currencyId])?.['name'] ?? CURRENCY_OPTIONS.currency
}

export function formatCurrency(
  value: number | string,
  {
    formatter,
    currency,
    currencies,
  }: { formatter: IntlShape['formatNumber']; currency: number; currencies: Currency[] }
) {
  return formatter(Number(value), {
    ...CURRENCY_OPTIONS,
    currency: getCurrency(currency, currencies),
  })
}

export function getCurrencyOptions(currency: string) {
  return {
    ...CURRENCY_OPTIONS,
    currency,
  }
}

export function getFormattedCurrency(
  locale: Locale,
  options: typeof CURRENCY_OPTIONS,
  formatNumber: IntlShape['formatNumber']
) {
  const formatted = formatNumber(1, options)
  return locale === 'hu' ? formatted.substring(2) : formatted.substring(0, formatted.length - 2)
}

export function getDefaultCurrencyName(state: Store) {
  // for safety because company is not always provided
  if (!state.auth.company.data.default_currency) {
    return CURRENCY_OPTIONS.currency
  }
  return getCurrency(state.auth.company.data.default_currency, state.dashboard.common.currencies)
}

/**
 * Helper function to initialize exchange_rate field for details form initial values
 * @param param.exchangeRate - exchange rate value from backend form values
 * @param param.currency - currency id from backend form values
 * @param param.defaultCurrency - current company default currency id
 * @returns - exchange rate value for details form initial values
 */
export function getDefaultExchangeRateValueByCurrency({
  exchangeRate,
  currency,
  defaultCurrency,
}: {
  exchangeRate: Nullable<Decimal>
  currency: number
  defaultCurrency: number | undefined
}): Decimal {
  if (exchangeRate) {
    return exchangeRate
  }

  if (currency === defaultCurrency) {
    return getDecimal(1, { minimumFractionDigits: 0 })
  }

  return '' // keep empty when field is visible for user and not filled by backend
}
