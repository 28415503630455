import React from 'react'

import { Accept, DropzoneOptions, FileRejection } from 'react-dropzone'

export enum FileDropzoneVariants {
  DEFAULT = 'default',
  SMALL = 'small',
}

export enum FileDropzoneFontSizes {
  DEFAULT = 'default',
  BIG = 'big',
}

export type RejectedFileResponse = { filename: string; message: string }

export interface FileDropzoneStyleProps {
  variant: FileDropzoneVariants
  fontSize: FileDropzoneFontSizes
}
export interface FileDropzoneProps extends DropzoneOptions {
  // Note on accepted mime types: https://react-dropzone.js.org/#section-accepting-specific-file-types
  acceptedFileMimeTypes?: Accept
  className?: string
  customUploadingExtraContent?: React.ReactChild
  customUploadingText?: React.ReactChild
  error?: Nullable<React.ReactChild>
  fileUploadText?: React.ReactChild
  fontSize?: FileDropzoneFontSizes
  maxSize?: number
  multiple?: boolean
  onDrop: Required<DropzoneOptions>['onDrop']
  uploadImgSrc?: string
  uploading: boolean
  uploadSuggestionText?: React.ReactChild
  variant?: FileDropzoneVariants
}

/**
 * Note: use `type` instead of `interface` for `ExtendedState`
 * return an error as string or ReactChild if you rather have custom error handling
 */
export type UseFileDropzoneUploadHandler<ExtendedState extends Record<string, unknown> = Record<never, never>> = (
  state: UseFileDropzoneState & ExtendedState,
  setState: React.Dispatch<React.SetStateAction<UseFileDropzoneState & ExtendedState>>,
  argument?: Partial<UseFileDropzoneState & ExtendedState>
) => Promise<unknown | { error: React.ReactChild }>

/**
 * Note: use `type` instead of `interface` for `ExtendedState`
 */
export type UseFileDropzoneOnDropHandler<ExtendedState extends Record<string, unknown> = Record<never, never>> = (
  acceptedFiles: File[],
  rejectedFiles: FileRejection[],
  state: UseFileDropzoneState & ExtendedState,
  setState: React.Dispatch<React.SetStateAction<UseFileDropzoneState & ExtendedState>>
) => void

/**
 * Note: use `type` instead of `interface` for `ExtendedState`
 */
export interface UseFileDropzoneProps<ExtendedState extends Record<string, unknown> = Record<never, never>> {
  extendedState?: ExtendedState
  uploadHandler: UseFileDropzoneUploadHandler<ExtendedState>
  onDropHandler?: UseFileDropzoneOnDropHandler<ExtendedState>
}

export interface UseFileDropzoneState {
  error: Nullable<React.ReactChild>
  errorTimeout: number
  isSuccessDialogVisible: boolean
  isUploading: boolean
  storedAcceptedFiles: File[]
  storedRejectedFiles: FileRejection[]
  rejectedFileResponse: RejectedFileResponse[]
}

/**
 * Note: use `type` instead of `interface` for `ExtendedState`
 */
export type UseFileDropzoneReturnType<ExtendedState extends Record<string, unknown> = Record<never, never>> =
  UseFileDropzoneState & {
    handleClose: VoidFunction
    handleOnDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void
    handleUploadFiles: (
      event?: Event | Partial<UseFileDropzoneState & ExtendedState> | React.MouseEvent<HTMLButtonElement>
    ) => Promise<void>
    removeUploadedFile: (
      fileListName: 'storedAcceptedFiles' | 'storedRejectedFiles',
      index: number
    ) => (event: React.MouseEvent<HTMLButtonElement>) => void
    reset: VoidFunction
    setExtendedState: (extendedState: Partial<ExtendedState>) => void
  } & {
    [Key in keyof ExtendedState]: ExtendedState[Key]
  }

export function isStateObject<ExtendedState extends Record<string, unknown> = Record<never, never>>(
  argument: Event | React.MouseEvent<HTMLButtonElement> | Partial<UseFileDropzoneState & ExtendedState> | undefined
): argument is Partial<UseFileDropzoneState & ExtendedState> {
  if (!argument || typeof argument.preventDefault === 'function') {
    return false
  } else {
    return true
  }
}

export function hasError(result: unknown): result is { error: React.ReactChild } {
  if (!result || typeof result !== 'object') {
    return false
  } else {
    return Boolean((result as Record<string, unknown>).error)
  }
}

export function hasRejected(result: unknown): result is { rejected: RejectedFileResponse[] } {
  if (!result) {
    return false
  } else {
    return (result as { rejected: RejectedFileResponse[] }).rejected !== undefined
  }
}
