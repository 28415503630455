import React from 'react'

import { isInvoiceTransactionType } from './helpers'

export function useSecondaryIdVisible(transactionType: AllPaymentTransactionType, transactions: PaymentTransaction[]) {
  return React.useMemo(
    () => isInvoiceTransactionType(transactionType) && transactions.some(({ secondaryId }) => !!secondaryId),
    [transactionType, transactions]
  )
}
