import { defaultStyles } from '@visx/tooltip'

import { QUICK_COLORS } from './theme'

export const CHART_COLORS_MAP: Record<MonthlyChartKeys, string> = {
  net: QUICK_COLORS.blue[40],
  vat: QUICK_COLORS.blue[20],
  gross: QUICK_COLORS.blue[40],
}

export const MONTHLY_STATEMENT_CHART_COLORS_MAP: Record<MonthlyStatementMonthlyChartKeys, string> = {
  expense: QUICK_COLORS.blue[40],
  income: QUICK_COLORS.primary[50],
}

export const TAXATION_CHART_COLORS_MAP: Record<TaxationTaxChartKeys, string> = {
  kata: QUICK_COLORS.common.shipCove,
  szja: QUICK_COLORS.common.warningMessageBackground,
  szocho: QUICK_COLORS.common.oasisStream,
  tbj: QUICK_COLORS.common.stoicWhite,
  'kata-kulonado': QUICK_COLORS.primary[50],
}

export const CHART_TOOLTIP_STYLES = {
  ...defaultStyles,
  zIndex: 1310, // must be higher than modal's zIndex (1300) - expense transactions v2 fullscreen chart dialog
  padding: 0,
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)', // same shadow as LightTooltip has
}

export const BAR_STYLES = { transitionDelay: '150ms', transitionDuration: '300ms', transitionProperty: 'fill-opacity' }

export const LINE_STYLES = {
  transitionDelay: '150ms',
  transitionDuration: '300ms',
  transitionProperty: 'stroke-opacity',
}

export const AI_CHART_HEIGHT = 300 // in px

export enum PrintableChartVariant {
  EXPENSE = 'expense',
  INCOME = 'income',
  QUARANTINE = 'quarantine',
  MONTHLY_STATEMENT = 'monthly_statement',
  PULSE_MONTHLY = 'pulse_monthly',
  PULSE_TAX_CALCULATION = 'pulse_tax_calculation',
  PULSE_VAT_LIVE = 'pulse_vat_live',
  PULSE_CHAT = 'pulse_chat',
}
