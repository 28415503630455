import React from 'react'

import styled from 'styled-components'

function BaseCreditCardRegularIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M13.445 3.72a.39.39 0 0 1 .39.389v.778H2.167v-.778a.39.39 0 0 1 .389-.389h10.888Zm.39 3.5v4.668a.39.39 0 0 1-.39.389H2.557a.39.39 0 0 1-.39-.39V7.22h11.667ZM2.556 2.553C1.699 2.553 1 3.251 1 4.11v7.779c0 .858.698 1.555 1.556 1.555h10.888c.858 0 1.556-.697 1.556-1.555v-7.78c0-.857-.698-1.555-1.556-1.555H2.557Zm1.36 7.39a.582.582 0 0 0-.583.583c0 .324.26.584.584.584h1.166c.324 0 .584-.26.584-.584a.582.582 0 0 0-.584-.583H3.918Zm3.112 0a.582.582 0 0 0-.584.583c0 .324.26.584.584.584H9.75c.323 0 .583-.26.583-.584a.582.582 0 0 0-.583-.583H7.029Z"
      />
    </svg>
  )
}

export const CreditCardRegularIcon = styled(BaseCreditCardRegularIcon)``
