import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import BraintreeContext from './context'

//
// <HostedField
//   className={cx(classes.braintreeField, {
//     focus: focused['number'],
//     error: hasFieldError('number'),
//   })}
//   type="number"
//   onValidityChange={onValidityChange('number')}
//   onFocus={onFieldFocus('number')}
//   onBlur={onFieldBlur('number')}
// />

export default function HostedField(props) {
  const braintree = React.useContext(BraintreeContext)
  const [fieldId, setFieldId] = React.useState(null)
  const effectRef = React.useRef()
  const registeredRef = React.useRef(false)

  React.useEffect(() => {
    // on mount register field to provider
    if (!registeredRef.current) {
      const [_fieldId, onRenderComplete] = braintree.registerField(props)
      setFieldId(_fieldId)
      effectRef.current = onRenderComplete
      registeredRef.current = true
    }
  }, [braintree, props])

  // call effect after state update
  React.useEffect(() => {
    if (fieldId && effectRef.current) {
      effectRef.current()
    }
  }, [fieldId])

  React.useEffect(() => {
    if (registeredRef.current && braintree.hostedFields) {
      if (props.disabled) {
        braintree.hostedFields.setAttribute({
          field: props.name,
          attribute: 'disabled',
          value: true,
        })
      } else {
        braintree.hostedFields.removeAttribute({
          field: props.name,
          attribute: 'disabled',
        })
      }
    }
  }, [braintree.hostedFields, props.disabled, props.name])

  // focus() {
  //     this.context.braintreeApi.focusField(this.props.type);
  // }
  //
  // clear() {
  //     this.context.braintreeApi.clearField(this.props.type);
  // }
  //
  // setPlaceholder(text) {
  //     this.context.braintreeApi.setAttribute(this.props.type, 'placeholder', text);
  // }

  if (!fieldId) {
    return null
  }

  return <div id={fieldId} className={cx('braintree-hosted-field', props.className)} />
}

HostedField.propTypes = {
  name: PropTypes.oneOf([
    'cardholderName',
    'number',
    'expirationDate',
    'expirationMonth',
    'expirationYear',
    'cvv',
    'postalCode',
  ]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onCardTypeChange: PropTypes.func,
  onValidityChange: PropTypes.func,
  onNotEmpty: PropTypes.func,
  onFocus: PropTypes.func,
  onEmpty: PropTypes.func,
  onBlur: PropTypes.func,
  prefill: PropTypes.string,
  disabled: PropTypes.bool,
}
