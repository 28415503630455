import React from 'react'
import PropTypes from 'prop-types'

import { useController } from 'react-hook-form'
import { useIntl } from 'react-intl'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { connect } from 'react-redux'

import { getCurrencyOptions, getDecimal, getFormattedCurrency } from '@helpers'

import { useNumberFormatSeparators } from '@hooks'

import { AdornmentType, CustomFieldBehavior } from './CustomFieldBehavior'
import { ReactHookFormTextFieldProps } from './ReactHookFormTextField'

interface ReactHookFormTableAmountFieldProps extends ReactHookFormTextFieldProps {
  currency?: string | number
  currencyName?: string
  locale: Locale
}

function PureReactHookFormTableAmountField({
  className,
  currencyName,
  disabled,
  locale,
  name,
  placeholder,
  required,
}: ReactHookFormTableAmountFieldProps) {
  const { formatNumber } = useIntl()
  const {
    field: { onChange, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({ name })
  const { decimalSeparator, thousandSeparator } = useNumberFormatSeparators(locale)
  const showDecimals = currencyName?.toLowerCase() !== 'huf'
  const isFieldDisabled = disabled || isSubmitting
  const adornmentType = locale === 'hu' ? AdornmentType.end : AdornmentType.start
  const formattedCurrency = currencyName
    ? getFormattedCurrency(locale, getCurrencyOptions(currencyName), formatNumber)
    : undefined

  const onValueChange = React.useCallback(
    ({ value }: NumberFormatValues) => {
      onChange(value === '' ? value : getDecimal(value))
    },
    [onChange]
  )

  return (
    <NumberFormat
      adornmentType={adornmentType}
      autoComplete="off"
      className={className}
      customInput={CustomFieldBehavior}
      decimalScale={showDecimals ? 2 : 0}
      decimalSeparator={decimalSeparator}
      disabled={isFieldDisabled}
      error={!!error}
      fixedDecimalScale={showDecimals}
      formattedCurrency={formattedCurrency}
      helperText={error?.message ?? ''}
      isNumericString
      name={name}
      onValueChange={onValueChange}
      placeholder={placeholder}
      required={required}
      thousandSeparator={thousandSeparator}
      type="tel"
      value={value}
    />
  )
}

PureReactHookFormTableAmountField.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  currencyName: PropTypes.string,
  disabled: PropTypes.bool,
  locale: PropTypes.oneOf(['hu', 'en']).isRequired as React.Validator<Locale>,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}

export const ReactHookFormAmountTableField = connect(
  (state: Store, { currency }: Omit<ReactHookFormTableAmountFieldProps, 'locale' | 'currencyName'>) => {
    let currencyName
    if (currency) {
      currencyName =
        typeof currency === 'string'
          ? currency
          : state.dashboard.common.currencies.find(({ id }) => id === currency)?.name
    }
    return {
      currencyName,
      locale: state.locale.language,
    }
  }
)(PureReactHookFormTableAmountField)

ReactHookFormAmountTableField.displayName = 'ReactHookFormAmountTableField'
