import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'

import { authActions } from '@services/index'

import {
  getDefaultRedirectUrlWithCompany,
  getPulseRedirectPreferredForCompanyType,
  getRouteUrl,
  hasBlockedSubscription,
  isAdmin,
  userHasEditorPermission,
} from '@helpers'

import { CenteredPageError, CenteredPageLoader } from '@components/ui'
import { AsyncPageLoadingComponent } from '@oldComponents/ui'

import {
  COMPANY_USER_DASHBOARD_PERMISSION_MAPPER as TAB_PERMISSIONS,
  COMPANY_USER_PAGE_PERMISSION_MAPPER as PAGE_PERMISSIONS,
  EV_COMPANY_TYPE,
  FeatureFlags,
  RouteKeys,
  ROUTES,
} from '@constants'

import { featureFlagEnabledForUser, PagePermissions, permissionDeniedForUser, PlanPermission } from '@permissions'

import { AuthorizedContent } from '../AuthorizedContent'

// code splitting - async component import
const AsyncDashboardCostPage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardCostPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDashboardPartnersPage = Loadable({
  loader: () => import('@components/pages/DashboardPartnersPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncMonthlyStatementPage = Loadable({
  loader: () => import('@components/pages/MonthlyStatementPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncNotFoundPrivatePage = Loadable({
  loader: () => import('@components/pages/NotFoundPage/NotFoundPrivatePage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDashboardMultiUploadPage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardMultiUploadPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDashboardCompanyPage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardCompanyPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDashboardLiquidityPage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardLiquidityPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncNoTaxNumberPage = Loadable({
  loader: () => import('@oldComponents/pages/CegjelzoPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDashboardIncomePage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardIncomePage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDashboardQuarantineInvoicePage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardQuarantineInvoicePage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncSubscriptionLandingPage = Loadable({
  loader: () => import('@oldComponents/pages/SubscriptionLandingPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncSubscribePage = Loadable({
  loader: () => import('@oldComponents/pages/SubscribePage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncPulsePage = Loadable({
  loader: () => import('@components/pages/PulsePage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncTransactionsPage = Loadable({
  loader: () => import('@components/pages/TransactionsPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDocumentsMultiUploadPage = Loadable({
  loader: () => import('@oldComponents/pages/DashboardDocumentsMultiUploadPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncDokumentDetailsPage = Loadable({
  loader: () => import('@components/pages/DashboardDokumentDetailsPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncCashflowPage = Loadable({
  loader: () => import('@components/pages/CashflowPage'),
  loading: AsyncPageLoadingComponent,
})
const AsyncChatPage = Loadable({
  loader: () => import('@components/pages/ChatPage'),
  loading: AsyncPageLoadingComponent,
})

interface DashboardCompanyRoutesProps {
  availableCompanies: CompaniesItem[]
  callSelectCompany: (id: number) => void
  company: Nullable<Company>
  companyDetailsError: Nullable<string>
  hasSubscription: boolean
  hasTaxNumber: boolean
  isAdminUser: boolean
  isCashflowVisibleForUser: boolean
  isForceChatPageOnlyEnabled: boolean
  isCompanyFetched?: boolean
  isCompanyLoading: boolean
  isDokumentsVisibleForUser: boolean
  isEditorUser: boolean
  isExpenseVisibleForUser: boolean
  isIncomeVisibleForUser: boolean
  isLiquidityVisibleForUser: boolean
  isMonthlyStatementVisibleForUser: boolean
  isPartnersVisibleForUser: boolean
  isPulseVisibleForUser: boolean
  isQuarantineVisibleForUser: boolean
  isSalaryVisibleForUser: boolean
  isSubscriptionBlocked: boolean
  isTaxVisibleForUser: boolean
  tabOrder: TransactionsDataTypeProps[]
}

function PureDashboardCompanyRoutes({
  availableCompanies,
  callSelectCompany,
  company,
  companyDetailsError,
  hasSubscription,
  hasTaxNumber,
  isAdminUser,
  isCashflowVisibleForUser,
  isForceChatPageOnlyEnabled,
  isCompanyFetched,
  isCompanyLoading,
  isDokumentsVisibleForUser,
  isEditorUser,
  isExpenseVisibleForUser,
  isIncomeVisibleForUser,
  isLiquidityVisibleForUser,
  isMonthlyStatementVisibleForUser,
  isPartnersVisibleForUser,
  isPulseVisibleForUser,
  isQuarantineVisibleForUser,
  isSalaryVisibleForUser,
  isSubscriptionBlocked,
  isTaxVisibleForUser,
  tabOrder,
}: DashboardCompanyRoutesProps) {
  const location = useLocation()
  const { company_id: companyIdParameter } = useParams<{ company_id?: string }>()
  const companyIdFromUrl = Number(companyIdParameter) || null
  const companyFromUrl = availableCompanies.find(item => item.id === companyIdFromUrl)
  const isValidCompany = Boolean(companyFromUrl)
  const isCompanySynced = companyFromUrl?.id === company?.id
  const companyTypeToCheck =
    companyIdFromUrl && companyIdFromUrl === company?.id ? company.company_type : companyFromUrl?.company_type ?? null
  const isPulseRedirectPreferred = getPulseRedirectPreferredForCompanyType(companyTypeToCheck)
  const isEvCompany = companyTypeToCheck === EV_COMPANY_TYPE

  // Sync store with url and trigger company details fetch for company
  React.useEffect(() => {
    if (isValidCompany && companyIdFromUrl != null && !isCompanyLoading && !isCompanySynced && !companyDetailsError) {
      callSelectCompany(companyIdFromUrl)
    }
  }, [callSelectCompany, companyDetailsError, companyIdFromUrl, isCompanyLoading, isCompanySynced, isValidCompany])

  if (companyDetailsError) {
    return (
      <CenteredPageError
        title={
          <FormattedMessage
            id="screen.fetchCompanyDetails.failure.title"
            defaultMessage="Sajnáljuk! A cégadatok betöltése sikertelen."
          />
        }
        errorMessage={companyDetailsError}
      />
    )
  }

  if (isValidCompany) {
    if ((!isCompanyFetched || isCompanyLoading) && !isCompanySynced) {
      // syncing company in store with url - w/o this route will change to no-subscription
      // because "hasSubscription" comes from selected company
      return (
        <CenteredPageLoader>
          <FormattedMessage
            id="screen.fetchCompanyDetails.loading"
            defaultMessage="Cégadatok betöltése folyamatban..."
          />
        </CenteredPageLoader>
      )
    }
    if (!hasTaxNumber && isAdminUser) {
      return <AsyncNoTaxNumberPage />
    }

    // need to add store value as key to switch to update company specific info on url change
    // TODO find better solution: trigger async-fetch when params.companyId changes
    //! NOTE: companyStore is required so the network requests' order is important!

    // where to go?
    // TODO move it to context?
    let defaultRedirectUrl = getRouteUrl(RouteKeys.NO_SUBSCRIPTION, companyIdFromUrl)
    if (hasSubscription) {
      if (isForceChatPageOnlyEnabled) {
        // chat page
        defaultRedirectUrl = getRouteUrl(RouteKeys.CHAT, companyIdFromUrl)
      } else {
        defaultRedirectUrl = getDefaultRedirectUrlWithCompany(companyIdFromUrl as number, {
          isCashflowVisibleForUser,
          isDokumentsVisibleForUser,
          isExpenseVisibleForUser,
          isIncomeVisibleForUser,
          isLiquidityVisibleForUser,
          isMonthlyStatementVisibleForUser,
          isPartnersVisibleForUser,
          isPulseRedirectPreferred,
          isPulseVisibleForUser,
          isQuarantineVisibleForUser,
          isSalaryVisibleForUser,
          isTaxVisibleForUser,
          tabOrder,
        })
      }
    } else if (isForceChatPageOnlyEnabled) {
      // profile
      defaultRedirectUrl = getRouteUrl(RouteKeys.PROFILE)
    }

    return (
      <Routes key={company?.id}>
        <Route
          path="subscribe"
          element={
            !isEvCompany && !isSubscriptionBlocked && !isForceChatPageOnlyEnabled ? (
              <AuthorizedContent accessLevel="admin">
                <AsyncSubscribePage />
              </AuthorizedContent>
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          path="no-subscription"
          element={
            !hasSubscription && !isForceChatPageOnlyEnabled ? (
              <AsyncSubscriptionLandingPage />
            ) : (
              <Navigate to={ROUTES.root} replace />
            )
          }
        />
        {/* these routes only available with active subscription */}
        <Route
          path="chat"
          element={
            hasSubscription && isForceChatPageOnlyEnabled ? (
              <AsyncChatPage />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          path="income/:invoice_id?"
          element={
            hasSubscription && isIncomeVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <AsyncDashboardIncomePage />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          key={location.key} // need to reload details route after successful file upload
          path="expense/:invoice_id?"
          element={
            hasSubscription && isExpenseVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <AsyncDashboardCostPage />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          path="quarantine/:invoice_id?"
          element={
            hasSubscription && isQuarantineVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <AsyncDashboardQuarantineInvoicePage />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          path="expense-multi-upload"
          element={
            hasSubscription && isExpenseVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <AuthorizedContent accessLevel="editor">
                <AsyncDashboardMultiUploadPage />
              </AuthorizedContent>
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          path="liquidity"
          element={
            hasSubscription && isLiquidityVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <PlanPermission
                perform={PagePermissions.LIQUIDITY}
                yes={() => <AsyncDashboardLiquidityPage />}
                no={() => <Navigate to={ROUTES.root} replace />}
              />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          element={
            // TODO tabOrder does not include dokuments yet
            hasSubscription && !isForceChatPageOnlyEnabled ? (
              <AsyncTransactionsPage defaultRedirectUrl={defaultRedirectUrl} />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
          path="transactions/*"
        />
        <Route
          element={
            hasSubscription && isPartnersVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <AsyncDashboardPartnersPage />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
          path="partners/*"
        />
        <Route
          path="monthly-statement"
          element={
            hasSubscription && isMonthlyStatementVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <PlanPermission
                perform={PagePermissions.MONTHLY_STATEMENT}
                yes={() => <AsyncMonthlyStatementPage />}
                no={() => <Navigate to={ROUTES.root} replace />}
              />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          element={
            hasSubscription && isCashflowVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <PlanPermission
                perform={PagePermissions.PAYMENTS}
                yes={() => <AsyncCashflowPage defaultRedirectUrl={defaultRedirectUrl} />}
                no={() => <Navigate to={ROUTES.root} replace />}
              />
            ) : (
              <Navigate to={defaultRedirectUrl} />
            )
          }
          path="cash-flow/*"
        />
        <Route
          path="pulse"
          element={
            hasSubscription && isPulseVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <AsyncPulsePage />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          path="documents-multi-upload"
          element={
            hasSubscription && isEditorUser && isDokumentsVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <PlanPermission
                perform={PagePermissions.DOKUMENTS}
                yes={() => <AsyncDocumentsMultiUploadPage />}
                no={() => <Navigate to={ROUTES.root} replace />}
              />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          path="document/:document_id"
          element={
            hasSubscription && isDokumentsVisibleForUser && !isForceChatPageOnlyEnabled ? (
              <PlanPermission
                perform={PagePermissions.DOKUMENTS}
                yes={() => <AsyncDokumentDetailsPage />}
                no={() => <Navigate to={ROUTES.root} replace />}
              />
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        <Route
          path="company"
          element={
            hasSubscription && !isForceChatPageOnlyEnabled ? (
              <AuthorizedContent accessLevel="admin">
                <AsyncDashboardCompanyPage />
              </AuthorizedContent>
            ) : (
              <Navigate to={defaultRedirectUrl} replace />
            )
          }
        />
        {/* redirect to an existing route*/}
        <Route path="/" element={<Navigate to={defaultRedirectUrl} replace />} />
        <Route path="*" element={<AsyncNotFoundPrivatePage />} />
      </Routes>
    )
  } else {
    return <AsyncNotFoundPrivatePage />
  }
}

PureDashboardCompanyRoutes.propTypes = {
  availableCompanies: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired as React.Validator<
    DashboardCompanyRoutesProps['availableCompanies']
  >,
  callSelectCompany: PropTypes.func.isRequired,
  company: PropTypes.object as React.Validator<DashboardCompanyRoutesProps['company']>,
  companyDetailsError: PropTypes.string,
  hasSubscription: PropTypes.bool.isRequired,
  hasTaxNumber: PropTypes.bool.isRequired,
  isAdminUser: PropTypes.bool.isRequired,
  isCashflowVisibleForUser: PropTypes.bool.isRequired,
  isCompanyFetched: PropTypes.bool,
  isCompanyLoading: PropTypes.bool.isRequired,
  isDokumentsVisibleForUser: PropTypes.bool.isRequired,
  isEditorUser: PropTypes.bool.isRequired,
  isExpenseVisibleForUser: PropTypes.bool.isRequired,
  isForceChatPageOnlyEnabled: PropTypes.bool.isRequired,
  isIncomeVisibleForUser: PropTypes.bool.isRequired,
  isLiquidityVisibleForUser: PropTypes.bool.isRequired,
  isMonthlyStatementVisibleForUser: PropTypes.bool.isRequired,
  isPartnersVisibleForUser: PropTypes.bool.isRequired,
  isPulseVisibleForUser: PropTypes.bool.isRequired,
  isQuarantineVisibleForUser: PropTypes.bool.isRequired,
  isSalaryVisibleForUser: PropTypes.bool.isRequired,
  isSubscriptionBlocked: PropTypes.bool.isRequired,
  isTaxVisibleForUser: PropTypes.bool.isRequired,
  tabOrder: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired as React.Validator<
    DashboardCompanyRoutesProps['tabOrder']
  >,
}

const DashboardCompanyRoutes = connect(
  (state: Store) => ({
    availableCompanies: state.auth.companies,
    companyDetailsError: state.auth.company.error,
    hasTaxNumber: Boolean(state.auth.company.data?.tax_account_number),
    isAdminUser: isAdmin(state.auth.company.data?.role ?? null),
    isCashflowVisibleForUser: !permissionDeniedForUser(state, PAGE_PERMISSIONS.cashflow),
    isCompanyFetched: state.auth.company.fetched,
    isCompanyLoading: state.auth.company.loading,
    isDokumentsVisibleForUser: !permissionDeniedForUser(state, PAGE_PERMISSIONS.dokuments),
    isEditorUser: userHasEditorPermission(state.auth.company.data?.role), // company can be empty
    isExpenseVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.expense),
    isForceChatPageOnlyEnabled: featureFlagEnabledForUser(state, FeatureFlags.FORCE_CHAT_PAGE_ONLY),
    isIncomeVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.income),
    isLiquidityVisibleForUser: !permissionDeniedForUser(state, PAGE_PERMISSIONS.liquidity),
    isMonthlyStatementVisibleForUser: !permissionDeniedForUser(state, PAGE_PERMISSIONS.monthlyStatement),
    isPartnersVisibleForUser: !permissionDeniedForUser(state, PAGE_PERMISSIONS.partner),
    isPulseVisibleForUser: !permissionDeniedForUser(state, PAGE_PERMISSIONS.pulse),
    isQuarantineVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.quarantine),
    isSalaryVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.salary),
    isSubscriptionBlocked: hasBlockedSubscription(state),
    isTaxVisibleForUser: !permissionDeniedForUser(state, TAB_PERMISSIONS.tax),
    tabOrder: state.auth.user.preferences.tab_order,
  }),
  { callSelectCompany: authActions.selectCompany.request }
)(PureDashboardCompanyRoutes)

DashboardCompanyRoutes.displayName = 'DashboardCompanyRoutes'

export default DashboardCompanyRoutes
