import { ZoomOrientation } from '@components/DocViewer/constant'
import { DocViewerMainState } from '@components/DocViewer/types'

import { DocViewerImageActions, ImageStateActions } from './actions'

export type DocViewerImageState = {
  defaultZoomLevel: number
  zoomLevel: number
  zoomOrientation: ZoomOrientation
  zoomJump: number
  mainState?: DocViewerMainState
  imageWidth?: number
  imageHeight?: number
}

export const initialImageState: DocViewerImageState = {
  defaultZoomLevel: 1,
  zoomLevel: 1,
  zoomOrientation: ZoomOrientation.LANDSCAPE,
  zoomJump: 0.1,
}

export type ImageStateReducer = (state: DocViewerImageState, action: ImageStateActions) => DocViewerImageState

export const reducer: ImageStateReducer = (state = initialImageState, action) => {
  switch (action.type) {
    case DocViewerImageActions.SET_ZOOM_LEVEL: {
      const { value } = action

      return { ...state, zoomLevel: value }
    }

    case DocViewerImageActions.INIT: {
      const { zoomLevel, zoomOrientation, imageHeight, imageWidth } = action
      return { ...state, defaultZoomLevel: zoomLevel, zoomLevel, zoomOrientation, imageHeight, imageWidth }
    }

    case DocViewerImageActions.CHANGE_ZOOM_ORIENTATION: {
      const { defaultZoomLevel, zoomOrientation } = action
      return { ...state, defaultZoomLevel, zoomLevel: defaultZoomLevel, zoomOrientation }
    }

    default:
      return state
  }
}
