import React from 'react'

import TagManager from 'react-gtm-module'
import { useLocation } from 'react-router-dom'

export function useGTMPageTracking() {
  const location = useLocation()

  React.useEffect(() => {
    if (window.gtmId) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageview',
          page_location: location.pathname + location.search,
          page_title: document.title,
        },
      })
    }
  }, [location.pathname, location.search])
}
