import React from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import { CompanyNameWithBadge } from '@components/navigations'
import { Button } from '@components/ui/Buttons'

import { ROUTES } from '@constants'

import { NavLink } from '../NavLink'

import { ProfileSettingsMessage } from './messages'
import { StyledExpandMoreIcon } from './styles'

const StyledNavButton = styled(Button)`
  height: 36px;
  display: inline-flex;
  text-decoration: none;
  line-height: 20px;
  padding: 8px;
  color: ${({ theme }) => theme.colors.common.midnightEscape};
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }
`

const StyledNavLink = styled(NavLink)`
  height: 36px;
  display: inline-flex;
  text-decoration: none;
  line-height: 20px;
  padding: 8px;
  color: ${({ theme }) => theme.colors.common.midnightEscape};
  border-radius: 4px;
  font-weight: 600;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }
`

interface MobileProfileMenuToggleButtonProps {
  disabled: boolean
  noCompanies: boolean
  onClick: VoidFunction
  open: boolean
}

function PureMobileProfileMenuToggleButton({
  disabled,
  noCompanies,
  onClick,
  open,
}: MobileProfileMenuToggleButtonProps) {
  if (noCompanies) {
    return (
      <StyledNavLink linkWhenNoCompany to={ROUTES.profile}>
        {ProfileSettingsMessage}
      </StyledNavLink>
    )
  }
  return (
    <StyledNavButton data-testid="profile-menu-btn" disabled={disabled} onClick={onClick}>
      <CompanyNameWithBadge />
      <StyledExpandMoreIcon $isOpen={open} />
    </StyledNavButton>
  )
}

export const MobileProfileMenuToggleButton = connect((state: Store) => ({
  disabled: state.auth.company.loading,
  noCompanies: !state.auth.companies.length,
}))(PureMobileProfileMenuToggleButton)

MobileProfileMenuToggleButton.displayName = 'MobileProfileMenuToggleButton'
