import { generateMaxHeightBreakPoint, generateMaxWidthBreakPoint, generateMinWidthBreakPoint } from '@constants'

const selectorButtonWidth = 60

const styles = theme => ({
  root: {
    display: 'inline-block',
    [generateMaxWidthBreakPoint('small')]: {
      position: 'absolute',
      right: 15,
    },
  },
  desktopRoot: {
    [generateMaxWidthBreakPoint('small')]: {
      display: 'none',
    },
  },
  mobileRoot: {
    '& ul>li': {
      paddingLeft: 8,
      paddingRight: 8,
      [generateMaxHeightBreakPoint('medium')]: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    [generateMinWidthBreakPoint('small')]: {
      display: 'none',
    },
  },
  button: {
    height: theme.appBarHeight,
    minWidth: 0,
    width: selectorButtonWidth,
    borderRadius: 0,
    fontWeight: 700,
    padding: 8,
    '&>span': {
      margin: '0 auto',
    },
    '&>span>span.Select-arrow': {
      marginLeft: 3,
    },
    [generateMaxHeightBreakPoint('medium')]: {
      height: theme.appBarHeightMedium,
    },
  },
  dropdownContainer: {
    marginTop: -theme.appBarHeight,
    [generateMaxWidthBreakPoint('small')]: {
      border: 'none',
      boxShadow: 'none',
      marginLeft: 15,
    },
  },
  dropdownMenu: {
    padding: 0,
    width: selectorButtonWidth,
    color: theme.palette.neutral.xxxDark,
    '&>li': {
      justifyContent: 'center',
      '&>*': {
        fontSize: 'inherit !important',
      },
    },
    [generateMaxWidthBreakPoint('small')]: {
      width: 'auto',
      display: 'flex',
    },
  },
})

export default styles
