import React from 'react'
import PropTypes from 'prop-types'

import { ButtonProps, Typography, TypographyProps } from '@material-ui/core'
import Button from '@material-ui/core/Button' // NOTE - circular dependecy issue: without this invoice details page breaks during runtime

export interface SubmitButtonProps extends Pick<TypographyProps, 'className' | 'style'>, ButtonProps {
  ButtonComponent?: React.ComponentType
  buttonText: React.ReactNode
  loading: boolean
  loadingText: React.ReactNode
}

export const SubmitButton = React.forwardRef<HTMLDivElement, SubmitButtonProps>(
  (
    {
      color = 'primary',
      buttonText,
      className,
      disabled = false,
      loading,
      loadingText,
      size,
      onClick,
      fullWidth,
      style,
      variant = 'contained',
      ButtonComponent = Button,
      type = 'submit',
      ...rest
    },
    ref
  ) => (
    <div ref={ref}>
      {loading ? (
        <Typography
          className={className}
          style={{
            ...style,
            lineHeight: size === 'small' ? '34px' : `${(style && style.height) || 38}px`,
          }}
          variant="caption"
        >
          {loadingText}
        </Typography>
      ) : (
        <ButtonComponent
          className={className}
          color={color}
          disabled={disabled}
          fullWidth={fullWidth}
          onClick={onClick}
          size={size}
          type={type}
          variant={variant}
          {...rest}
        >
          {buttonText}
        </ButtonComponent>
      )}
    </div>
  )
)

SubmitButton.displayName = 'SubmitButton'

SubmitButton.propTypes = {
  ButtonComponent: PropTypes.elementType as React.Validator<React.ComponentType | undefined>,
  buttonText: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['inherit', 'default', 'primary', 'secondary']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  loadingText: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
}
