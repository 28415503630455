import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

function BasePlugCircleXmarkRegularIcon({ className, size = 16 }: IconComponentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={(15 / 16) * size}
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        fill="currentColor"
        d="M3.556.667A.665.665 0 0 0 2.889 0a.665.665 0 0 0-.667.667V3.11h1.334V.667Zm4.888 0A.665.665 0 0 0 7.778 0a.665.665 0 0 0-.667.667V3.11h1.333V.667ZM.667 4A.665.665 0 0 0 0 4.667c0 .37.297.666.667.666h.222v1.778a4.445 4.445 0 0 0 3.778 4.395v2.05c0 .369.297.666.666.666.37 0 .667-.297.667-.666v-2.05a4.43 4.43 0 0 0 1.197-.359 4.978 4.978 0 0 1-.053-1.506 3.11 3.11 0 0 1-4.922-2.53V5.333h6.222v1.534a4.909 4.909 0 0 1 1.334-1v-.534H10A.665.665 0 1 0 10 4H.667ZM12 14.222a4 4 0 1 0 0-7.999 4 4 0 0 0 0 8Zm1.647-5.02-1.02 1.02 1.02 1.02a.446.446 0 0 1 0 .627.446.446 0 0 1-.628 0L12 10.85l-1.02 1.02a.446.446 0 0 1-.627 0 .446.446 0 0 1 0-.628l1.02-1.02-1.02-1.02a.446.446 0 0 1 0-.627.446.446 0 0 1 .628 0L12 9.595l1.02-1.02a.446.446 0 0 1 .627 0 .446.446 0 0 1 0 .628Z"
      />
    </svg>
  )
}

BasePlugCircleXmarkRegularIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}

export const PlugCircleXmarkRegularIcon = styled(BasePlugCircleXmarkRegularIcon)``
