import React from 'react'
import PropTypes from 'prop-types'

import __find from 'lodash/find'
import { useWatch } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { Currency, Typography } from '@components/ui'

interface AssignmentsTotalGrossAmountProps {
  companyDefaultCurrency: number
  currencyOptions: CommonIdAndNameType[]
}

function PureAssignmentsTotalGrossAmount({
  companyDefaultCurrency,
  currencyOptions,
}: AssignmentsTotalGrossAmountProps) {
  const watchCurrency = useWatch({ name: 'currency' }) ?? companyDefaultCurrency
  const watchAssignments = (useWatch({ name: 'assignments' }) ?? []) as Array<{ gross_amount: Decimal }>

  const currentCurrencyName = __find(currencyOptions, ['id', watchCurrency])?.name

  const totalGrossAmount = watchAssignments.reduce((sum, { gross_amount }) => (sum += Number(gross_amount)), 0)

  return (
    <Typography align="right">
      <FormattedMessage
        id="income.manual.amountOfDailyTurnover"
        defaultMessage="Napi forgalom összesen: {total} (bruttó)"
        values={{
          total: (
            <strong>
              <Currency value={totalGrossAmount} currency={currentCurrencyName} />
            </strong>
          ),
        }}
      />
    </Typography>
  )
}

PureAssignmentsTotalGrossAmount.propTypes = {
  companyDefaultCurrency: PropTypes.number.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export const AssignmentsTotalGrossAmount = connect((state: Store) => ({
  companyDefaultCurrency: state.auth.company.data.default_currency,
}))(PureAssignmentsTotalGrossAmount)

AssignmentsTotalGrossAmount.displayName = 'AssignmentsTotalGrossAmount'
