import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getRouteUrl } from '@helpers/url'

import { CustomDialogActions, CustomDialogHeader } from '@components/ui'
import { Button, LinkButton } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'

import { RouteKeys } from '@constants'

import { AttentionDialogCloseButtonMessage, CloseAndNavigatteButtonMessage } from '@messages'
import { PaymentSummaryTitleMessage } from './messages'
import { PaymentResponseDialogBody, SameSizeButtonsContainer } from './styles'

const StyledCustomDialogActions = styled(CustomDialogActions)`
  justify-content: flex-end;
  padding-right: 40px;
  gap: 20px;
`

interface PaymentAttentionViewProps {
  companyId: Company['id']
  onClose: VoidFunction
}

function PurePaymentAttentionView({ companyId, onClose }: PaymentAttentionViewProps) {
  return (
    <>
      <CustomDialogHeader borderless title={PaymentSummaryTitleMessage} />
      <PaymentResponseDialogBody>
        <Typography align="center" color="gray-80" size="400-sm">
          <FormattedMessage
            id="payment.dialog.responseMessage.callOfAttention"
            defaultMessage="Utalási csomagod a Pénzmozgások menüpontban bármikor megtekintheted."
          />
        </Typography>
      </PaymentResponseDialogBody>
      <StyledCustomDialogActions borderless>
        <SameSizeButtonsContainer>
          <LinkButton to={getRouteUrl(RouteKeys.PAYMENT_ORDERS, companyId)} variant="secondaryContained" withoutIcon>
            {CloseAndNavigatteButtonMessage}
          </LinkButton>
          <Button onClick={onClose} variant="primaryContained">
            {AttentionDialogCloseButtonMessage}
          </Button>
        </SameSizeButtonsContainer>
      </StyledCustomDialogActions>
    </>
  )
}

PurePaymentAttentionView.propTypes = {
  companyId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

export const PaymentAttentionView = connect((state: Store) => ({
  companyId: state.auth.company.data.id,
}))(PurePaymentAttentionView)

PaymentAttentionView.displayName = 'PaymentAttentionView'
