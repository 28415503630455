import styled from 'styled-components'

import { ButtonBase } from './ButtonBase'

const TextButton = styled(ButtonBase)`
  color: inherit;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  font-weight: 600;
  background-color: unset;
  border: none;

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    text-decoration: underline;
    outline: 0;
  }
`

export default TextButton
