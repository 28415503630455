import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { expenseActions } from '@services'

import { bindActionToPromise, getRouteUrl } from '@helpers'

import { INVOICE_ACCEPTED_MIME_TYPES, RouteKeys } from '@constants'

import uploadSVGSrc from '@assets/img/scan_upload_folder.svg'

import { FileUploadForm } from './FileUploadForm'
import { InvoiceFileUploadFormProps } from './types'

import { FileUploadSuggestionMessage, FileUploadTextMessage } from './messages'

//! NOTE: file upload form on Expense details page - create new expense path
function PureExpenseFileUploadForm({ company, invoice, ...rest }: InvoiceFileUploadFormProps) {
  const navigate = useNavigate()

  return (
    <FileUploadForm
      {...rest}
      onUploadSuccess={response => {
        // TODO: fix types once service typed
        const url = (response as any).missing ? RouteKeys.QUARANTINE : RouteKeys.COST
        navigate(`${getRouteUrl(url, company)}/${(response as any).id}`, { replace: true })
      }}
      getFileUploadPayload={acceptedFiles => ({
        invoice,
        file: acceptedFiles[0],
      })}
      acceptedFileMimeTypes={INVOICE_ACCEPTED_MIME_TYPES}
      title="QUiCK autoscan"
      fileUploadText={FileUploadTextMessage}
      showServerError
      uploadDescriptionText={
        <FormattedMessage
          id="fileUpload.texts.description"
          defaultMessage="Itt feltöltheted a költségeidhez tartozó számláidat PDF, scan, vagy fotó formában. A felismert adatok alapján automatikusan frissítjük a jobb oldali mezőket."
        />
      }
      uploadSuggestionText={FileUploadSuggestionMessage}
      uploadImgSrc={uploadSVGSrc}
      withPaper
    />
  )
}

PureExpenseFileUploadForm.propTypes = {
  company: PropTypes.number.isRequired,
  invoice: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  maxSize: PropTypes.number.isRequired,
  uploadFile: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
}

// NOTE:this is only used for expense upload, important cause of "abortUploadExpenseProcess"
export const ExpenseFileUploadForm = connect(
  (state: Store) => ({
    company: state.auth.company.data.id,
    uploading: state.expense.details.uploading,
    invoice: state.expense.details.data?.id || null,
    maxSize: state.dashboard.common.artifact_max_size,
  }),
  dispatch => ({
    uploadFile: bindActionToPromise(dispatch, expenseActions.uploadExpense.request),
  })
)(PureExpenseFileUploadForm)

ExpenseFileUploadForm.displayName = 'ExpenseFileUploadForm'
