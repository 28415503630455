import { css, DefaultTheme } from 'styled-components'

import { HEX_COLOR_REGEX } from '@constants/regex'

/**
 *  Adds opacity HEX value to a HEX color
 *
 * @param {string} hexColor HEX color
 * @param {number} opacity opacity percentage value in 0-100 range
 * @returns a HEX color value with opacity
 */
export function applyOpacity(hexColor: string, opacity: number) {
  if (!hexColor.match(HEX_COLOR_REGEX)) {
    throw new Error(
      `Invalid color string provided: ${hexColor}. Requires 3 or 6 digits prefixed with # (ie #000 or #000000)`
    )
  }
  // make sure the value is positive
  let validOpacity = Math.abs(opacity)
  // in case wrong input always stay in range of 0-100
  validOpacity = validOpacity === 100 ? validOpacity : validOpacity % 100
  // in case we use 3 hex color value extend it to 6 - we have a hashtag at the first character
  const validHexColor = hexColor.length < 7 ? `${hexColor}${hexColor.substring(1)}` : hexColor
  // transform into hex
  const opacityHex = Math.round((validOpacity / 100) * 255)
    .toString(16)
    .toUpperCase()
  return `${validHexColor}${opacityHex.padStart(2, '0')}`
}

export function getFontStyles(theme: DefaultTheme, size: keyof DefaultTheme['typography']) {
  const { fontSize, lineHeight, fontWeight } = theme.typography[size]
  return css`
    font-size: ${fontSize};
    line-height: ${lineHeight};
    font-weight: ${fontWeight};
  `
}
