import React from 'react'

import styled from 'styled-components'

function BaseFileDownloadIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0625 11.0908H12.625c-.3798 0-.6875-.3094-.6875-.6875s.3077-.6875.6875-.6875h3.4375c.3781 0 .6875.3094.6875.6875s-.3094.6875-.6875.6875ZM16.0625 13.8408H12.625c-.3798 0-.6875-.3094-.6875-.6875s.3077-.6875.6875-.6875h3.4375c.3781 0 .6875.3094.6875.6875s-.3094.6875-.6875.6875Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M23.0265 20.6371c0 2.9363-2.3803 5.3166-5.3167 5.3166-2.9363 0-5.3166-2.3803-5.3166-5.3166s2.3803-5.3167 5.3166-5.3167c2.9364 0 5.3167 2.3804 5.3167 5.3167Zm-6.0039-2.3741a.6875.6875 0 1 1 1.375 0v2.8033l.5594-.5594a.6875.6875 0 0 1 .9722.9723l-1.7282 1.7282-.0048.0049a.6854.6854 0 0 1-.4841.2013.6846.6846 0 0 1-.4882-.2013l-.0049-.0049-1.7329-1.7329a.6875.6875 0 1 1 .9723-.9723l.5642.5642V18.263Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="m24.1965 9.6032-3.2068-3.2071c-.5186-.5156-1.219-.8053-1.9452-.8053H11.25c-1.519 0-2.75 1.231-2.75 2.75l.0003 16.5c0 1.5185 1.231 2.75 2.75 2.75H22.25c1.5125 0 2.75-1.2375 2.75-2.75V11.5463a2.752 2.752 0 0 0-.8035-1.943ZM11.7895 25.79h2.1079c1.0772.8176 2.4205 1.3028 3.8771 1.3028 3.545 0 6.4188-2.8737 6.4188-6.4187 0-1.4517-.4819-2.7908-1.2945-3.8661v-4.3421H19.5c-.7593 0-1.375-.6157-1.375-1.375V7.6589H11.25a.6875.6875 0 0 0-.6875.6875l.0003 16.4944c0 .3797.1715.9491 1.2267.9491Z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const FileDownloadIcon = styled(BaseFileDownloadIcon)``
