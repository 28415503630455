import { call, delay, takeLatest } from 'redux-saga/effects'

import { TYPING_INTERVAL } from '@constants'

import actions from './actions'
import * as api from './api'
import { serializer } from './helper'

export function* searchCompaniesSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const response = yield call(api.searchCompanies, payload)
    yield call(resolve, serializer(response.data))
  } catch (error) {
    yield call(reject, error.message)
  }
}

export default function* commonSaga() {
  yield takeLatest(actions.searchCompanies.REQUEST, searchCompaniesSaga)
}
