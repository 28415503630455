import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'

const WarningTypography = styled(Typography)`
  margin-top: 36px; // 'calc(1rem + 20px)',
  color: ${({ theme }) => theme.colors.gray[130]};
`

const IndicatorSpan = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary[50]};
  margin-right: 4px;
`

export function AvoidMissingNotification() {
  return (
    <WarningTypography size="400-xs">
      <IndicatorSpan>*</IndicatorSpan>
      <FormattedMessage
        id="expenseDetails.warning.avoidMissingState"
        defaultMessage="A csillaggal jelölt mezők kitöltése szükséges, hogy a számla ne legyen hiányos."
      />
    </WarningTypography>
  )
}
