import { AxiosResponse } from 'axios'

import { CommonAxiosPayload } from '@services/types'

import { trimLocalhostFromUrl } from '@helpers/url'

import Api from '../api'
import { API_CITIES_URL, API_EXCHANGE_RATE_URL } from './urls'

export function callUrl<Results = unknown, Data = never>({
  config,
  data,
  method,
  url,
}: CommonAxiosPayload<Data>): Promise<AxiosResponse<Results>> {
  return Api[method]<Results>(trimLocalhostFromUrl(url), method === 'get' ? config : data, config)
}

export function fetchCities(postalCode: string | number): Promise<AxiosResponse<BackendCitiesResponse>> {
  return Api.get(API_CITIES_URL, { params: { postalCode } })
}

export function fetchExchangeRate(data: FetchExchangeRatePayload) {
  return Api.post(API_EXCHANGE_RATE_URL, data)
}
