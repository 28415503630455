import { connect } from 'react-redux'

import { COMPANY_USER_PERMISSIONS_LIST, FeatureFlags, SUBSCRIPTION_PLANS } from '@constants'

// assets
import { companyPlanTiers, FeaturePermissons, PagePermissions } from './config'

type RuleSet = {
  static?: Array<PagePermissions | FeaturePermissons>
  dynamic?: Record<string, (payload: unknown) => boolean>
}

type Rules = Record<SubscriptionTier, RuleSet>
type StoreOrCompanyState = Store | Company

export function checkPermission(
  rules: Rules,
  level: Nullable<SubscriptionTier>,
  action: PagePermissions | FeaturePermissons,
  data?: unknown
): boolean {
  if (!level || !SUBSCRIPTION_PLANS.includes(level)) {
    return false
  }
  const permissions = rules[level]
  if (!permissions) {
    // level is not present in the rules
    return false
  }

  const staticPermissions = permissions.static

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule provided for action
    return true
  }

  const dynamicPermissions = permissions.dynamic

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action]
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false
    }

    return permissionCondition(data)
  }
  return false
}

function getCompanyPlan(state: StoreOrCompanyState, isCompanyState?: boolean): Nullable<SubscriptionTier> {
  // company has subscription object
  // subscription: {
  //   "is_trial_available": false,
  //   "blocked_reason": null,
  //   "id": 39,
  //   "trial": false,
  //   "cancelable": true,
  //   "plan": {
  //       "id": 9,
  //       "tier": "base",
  //       "period": "yearly",
  //       "is_grandfather": false
  //   },
  //   "is_active": true,
  //   "campaign_tier": "pro"
  // }
  const subscription = isCompanyState
    ? (state as Company)?.subscription
    : (state as Store).auth.company.data?.subscription

  return subscription?.campaign_tier ?? subscription?.plan?.tier ?? null
}

export function withCompanyPlan(Component: React.ComponentType<any>) {
  return connect((state: Store) => ({
    level: getCompanyPlan(state),
  }))(Component)
}

// need for user permissions in "permissionDeniedForUser" helper
export function isUserPermissionsEnabled(state: StoreOrCompanyState, isCompanyState = false) {
  return isPlanPermissionEnabled(state, FeaturePermissons.COMPANY_USER_PERMISSIONS, isCompanyState)
}

/**
 * Returns true if the permission is available in the current plan.
 *
 * @param {object} state State object
 * @param {string} permission Valid permission name from here: src/permissions/config.js
 * @param {boolean} isCompanyState
 * @returns {boolean} Use the subscription from the company or from the root of the state object.
 */
export function isPlanPermissionEnabled(
  state: StoreOrCompanyState,
  permission: PagePermissions | FeaturePermissons,
  isCompanyState = false
): boolean {
  const level = getCompanyPlan(state, isCompanyState)
  return checkPermission(companyPlanTiers, level, permission)
}

// User permission checks based on selected company
// need for active tab change in saga
export function permissionDeniedForUser(
  state: StoreOrCompanyState,
  permission: (typeof COMPANY_USER_PERMISSIONS_LIST)[number],
  isCompanyState = false
): boolean {
  const isEnabled = isUserPermissionsEnabled(state, isCompanyState)
  if (isEnabled) {
    if (isCompanyState) return (state as Company)?.[permission] ?? false
    return (state as Store).auth.company.data?.[permission] ?? false
  }
  // default value FALSE
  return false
}

// CompanyUserData
export function denyTransactionsPermission({
  deny_expense_permission = false,
  deny_nav_permission = false,
  deny_income_permission = false,
  deny_salary_permission = false,
  deny_tax_permission = false,
  deny_document_permission = false,
}: { [key: string]: unknown } & CompanyUserPermissions) {
  return (
    deny_expense_permission &&
    deny_nav_permission &&
    deny_income_permission &&
    deny_salary_permission &&
    deny_tax_permission &&
    deny_document_permission
  )
}

/**
 * Helper function to check if the user has the feature enabled.
 *
 * @param {Store} state - The redux store.
 * @param {(FeatureFlags | FeatureFlags[])} feature - The feature(s) to check.
 * @returns {boolean} - True if the user has the feature(s) enabled.
 */
export function featureFlagEnabledForUser(state: Store, feature: FeatureFlags | FeatureFlags[]) {
  const flags = state.auth.company.data?.flags || []
  if (Array.isArray(feature)) {
    return feature.every(flag => flags.includes(flag))
  } else {
    return flags.includes(feature)
  }
}
