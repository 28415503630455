import { CommonAxiosPayload } from '@services'

import { AccordionChildrenExtraProps } from '@components/ui'

import { TaskTypes } from './constants'

export enum TaskStatus {
  TODO = 'todo',
  YES = 'yes',
  NO = 'no',
  DISABLED = 'disabled',
}

export enum TaskQuestionName {
  kataAdoFizetes1 = 'kata-ado-fizetes-1',
  szjaElolegFiezetes1 = 'szja-eloleg-fizetes-1',
  tbSzochoBevallas1 = 'tb-szocho-bevallas-1',
  tbSzochoBevallas2 = 'tb-szocho-bevallas-2',
  tbSzochoBevallas3 = 'tb-szocho-bevallas-3',
  tbSzochoBevallas4 = 'tb-szocho-bevallas-4',
  tbSzochoBevallas5 = 'tb-szocho-bevallas-5',
  tbSzochoBevallas6 = 'tb-szocho-bevallas-6',
  tbSzochoBevallas7 = 'tb-szocho-bevallas-7',
  bevallas2358tbSzocho1 = 'bevallas-2358-tb-szocho-1',
  bevallas2358tbSzocho2 = 'bevallas-2358-tb-szocho-2',
  bevallas2358tbSzocho3 = 'bevallas-2358-tb-szocho-3',
  bevallas2358tbSzocho4 = 'bevallas-2358-tb-szocho-4',
  bevallas2358tbSzocho5 = 'bevallas-2358-tb-szocho-5',
  bevallas2358tbSzocho6 = 'bevallas-2358-tb-szocho-6',
  bevallas2358tbSzocho7 = 'bevallas-2358-tb-szocho-7',
}

export type TaskQuestion = {
  name: TaskQuestionName
  label: string
  status: TaskStatus
  links: { yes: BackendCallableLink; no: BackendCallableLink }
  tooltip?: { title?: string; description: string }
}

export enum TaxStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  SUPPORT = 'support',
}

// accordion header data
export type TaxationTaskData = {
  due_at: string
  headline?: Nullable<string>
  status: 'todo' | 'completed'
  task_type: TaskTypes
  year: number // The year of the `due_at` value
}

export interface TaxationTaskAccordionProps {
  refetchReports: VoidFunction
  report: {
    name: string
    data: TaxationTaskData
    links: { details: string }
  }
}

export type TaxationTaskContentProps<BackendResponse> = {
  callUrl: AsyncFunction<CommonAxiosPayload<unknown>, BackendResponse>
  detailsUrl: string
  isCompleted: boolean
  refetchReports: VoidFunction
} & AccordionChildrenExtraProps

//* Task details content (V2 api)
export type CommonTaxationTaskDetailsData<Overview, Indicators, Payables> = {
  dueAt: string
  questions: TaskQuestion[]
  taskType: TaskTypes // TODO remove from common and placed with proper subset into specific TaxationTask types
  taxStatus: TaxStatus
  indicators: Indicators
  isTaxOverwritten: boolean
  payable: Payables
  overview: Overview
  links: {
    bevallas?: BackendCallableLink
    changeTaxes?: BackendCallableLink
    complete: BackendCallableLink
    delete: BackendCallableLink
    support?: BackendCallableLink
  }
}

//* overview
type TaxationTaskOverviewKata = {
  haviBevetel: number
}

type TaxationTaskOverviewSzja = {
  negyedevesBevetel: number
  levonhatoKoltseghanyad: number
  adozasElottiJovedelem: number
  koltseghanyad: number
}

type TaxationTaskOverviewTbjSzocho2258 = {
  haviBevetel: number
  levonhatoKoltseghanyad: number
  adozasElottiJovedelem: number
  koltseghanyad: number
}

//* indicators
type TaxationTaskIndicatorsKata = null

type TaxationTaskIndicatorsSzja = {
  mentesitett: number
  quickSzja: Nullable<number>
  szjaAdoalap: number
  szjaAdokulcs: number
}

type TaxationTaskIndicatorsTbjSzocho2258 = {
  elozetesAdoalap: number
  mentesitett: number
  quickSzocho: Nullable<number>
  quickTbj: Nullable<number>
  szochoAdoalap: number
  szochoAdokulcs: number
  tbjAdokulcs: number
  tbjSzamitottAdoalap: number
}

//* payable
type TaxationTaskPayablesKata = {
  kata: number
}

type TaxationTaskPayablesSzja = {
  szja: number
}

export type TaxationTaskPayablesTbjSzocho = {
  tbj: number
  szocho: number
}

//* build specific details together
export type BackendTaxationTaskKataDetailsResponse = CommonTaxationTaskDetailsData<
  TaxationTaskOverviewKata,
  TaxationTaskIndicatorsKata,
  TaxationTaskPayablesKata
>

export type BackendTaxationTaskSzjaDetailsResponse = CommonTaxationTaskDetailsData<
  TaxationTaskOverviewSzja,
  TaxationTaskIndicatorsSzja,
  TaxationTaskPayablesSzja
>

export type BackendTaxationTaskTbjSzocho2258DetailsResponse = CommonTaxationTaskDetailsData<
  TaxationTaskOverviewTbjSzocho2258,
  TaxationTaskIndicatorsTbjSzocho2258,
  TaxationTaskPayablesTbjSzocho
>

// new 2358 TBJ & SZOCHO
type TaxationTaskOverviewTbjSzocho2358 = {
  bevetelQtrBevallas: number
  jovedelemQtrBevallas: number
  levonhatoKoltseghanyadQtrBevallas: number
  koltseghanyad: number
}

type TaxationTaskIndicatorsTbjSzocho2358 = {
  jovedelemAdoMentesQtrBevallas: number
  quickSzocho: Nullable<number>
  quickTbj: Nullable<number>
  szjaAlapPrevQrtYtdBevallas: number
  szjaAlapQrtYtdBevallas: number
  szjaAlapQtrBevallas: number
  szochoAdokulcs: number
  szochoAlapPrevQtrYtd: number
  szochoAlapQtrBevallas: number
  szochoElozetesAlapQtrBevallas: number
  tbjAdokulcs: number
  tbjAlapPrevQtrYtd: number
  tbjAlapQtrBevallas: number
  tbjElozetesAlapQtrBevallas: number
}

export type BackendTaxationTaskTbjSzocho2358DetailsResponse = CommonTaxationTaskDetailsData<
  TaxationTaskOverviewTbjSzocho2358,
  TaxationTaskIndicatorsTbjSzocho2358,
  TaxationTaskPayablesTbjSzocho
>

export type BevallasFormOption = { value: string | number; label: string }

export type BackendCommonBevallasResponse = {
  isSaved?: boolean
  links: {
    update: string
    bevallasXml?: string
    support?: string
  }
}

export interface MultiClosureViewProps {
  aria: { labelledby: string; describedby: string }
  onClose: VoidFunction
}

export interface MultiClosureAcceptResponseViewProps extends MultiClosureViewProps {
  loading: boolean
  onEnterFormState: AsyncFunction<any, unknown>
}
