import React from 'react'

import styled from 'styled-components'

function BaseNoActionIcon({ className, size = 21 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={(22 / 21) * size}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5325 5.39059C18.1324 6.99051 19.0312 9.16047 19.0312 11.4231C19.0312 13.0882 18.5444 14.7032 17.6496 16.0777C17.5156 16.2836 17.5359 16.557 17.7097 16.7308L18.4123 17.4335C18.6256 17.6467 18.9785 17.6245 19.1495 17.376C20.3463 15.637 21 13.5638 21 11.4231C21 8.63832 19.8938 5.96761 17.9246 3.99847C15.9555 2.02934 13.2848 0.923096 10.5 0.923096C8.35933 0.923096 6.28605 1.57679 4.54705 2.77363C4.29863 2.9446 4.2764 3.29752 4.48964 3.51075L5.1923 4.21341C5.36605 4.38716 5.63946 4.40751 5.84539 4.27345C7.21989 3.37865 8.83485 2.89185 10.5 2.89185C12.7626 2.89185 14.9326 3.79067 16.5325 5.39059Z"
        fill="currentColor"
      />
      <path
        d="M15.2323 18.9781C15.0689 18.8147 14.8155 18.786 14.6131 18.8974C13.3641 19.5846 11.9511 19.9543 10.5 19.9543C8.23737 19.9543 6.06742 19.0555 4.4675 17.4556C2.86758 15.8557 1.96875 13.6857 1.96875 11.4231C1.96875 9.97198 2.33845 8.55899 3.0257 7.31003C3.1371 7.10758 3.10835 6.85423 2.94496 6.69083L2.22792 5.9738C2.0057 5.75157 1.63534 5.78685 1.47473 6.05698C0.517534 7.66699 0 9.51837 0 11.4231C0 14.2079 1.10625 16.8786 3.07538 18.8477C5.04451 20.8168 7.71523 21.9231 10.5 21.9231C12.4047 21.9231 14.2561 21.4056 15.8661 20.4484C16.1362 20.2878 16.1715 19.9174 15.9493 19.6952L15.2323 18.9781Z"
        fill="currentColor"
      />
      <path
        d="M0.674966 1.97358C1.04774 1.6008 1.65212 1.6008 2.0249 1.97358L19.574 19.5227C19.9468 19.8955 19.9468 20.4998 19.574 20.8726C19.2012 21.2454 18.5968 21.2454 18.2241 20.8726L0.674965 3.32351C0.302192 2.95074 0.302192 2.34635 0.674966 1.97358Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const NoActionIcon = styled(BaseNoActionIcon)``
