import React from 'react'

import __cloneDeep from 'lodash/cloneDeep'
import { ArrayPath, FieldArray, FieldValues, Path, UseFieldArrayAppend, UseFormGetValues } from 'react-hook-form'

import { calculateNetAndVatAmount, getMaximumFractionDigitsByCurrency, roundToDecimal } from '@helpers'

import { EMPTY_EXPENSE_ASSIGNMENT, EMPTY_INCOME_ASSIGNMENT, InvoiceType } from '@constants'

function calculateGrossAmount(assignments: ExpenseDetailsAssignment[], grossAmount: number, currency: number) {
  const assignmentsGrossAmountTotal = assignments.reduce((acc, curr) => (acc += Number(curr.gross_amount) || 0), 0)
  const maximumFractionDigits = getMaximumFractionDigitsByCurrency(currency)
  const remainingGrossAmount = roundToDecimal(grossAmount - assignmentsGrossAmountTotal, {
    maximumFractionDigits,
    numeric: true,
  })

  return roundToDecimal(Math.max(remainingGrossAmount, 0), {
    maximumFractionDigits,
    numeric: false,
  }) // >= 0
}

type AddAssignmentHandler = VoidFunction
type GetCopyAssignmentHandler = (index: number) => VoidFunction

export function useAssignmentControls<FormValues extends FieldValues>(
  append: UseFieldArrayAppend<FormValues, ArrayPath<FormValues>>,
  companyVats: CompanyVatType[],
  getValues: UseFormGetValues<FormValues>,
  invoiceType: InvoiceType
): { addAssignmentHandler: AddAssignmentHandler; getCopyAssignmentHandler: GetCopyAssignmentHandler } {
  return React.useMemo(() => {
    function addAssignmentHandler() {
      const assignments = getValues('assignments' as Path<FormValues>) as ExpenseDetailsAssignment[]
      const currency = getValues('currency' as Path<FormValues>)
      const grossAmount = Number(getValues('gross_amount' as Path<FormValues>) || 0)
      const grossAmountValue = calculateGrossAmount(assignments, grossAmount, currency)

      // get last assignment's vat
      const lastAssignmentVat = assignments[assignments.length - 1]?.vat ?? null

      const assignmentValues = {
        ...(invoiceType === InvoiceType.EXPENSE ? EMPTY_EXPENSE_ASSIGNMENT : EMPTY_INCOME_ASSIGNMENT),
        gross_amount: grossAmountValue,
      }

      if (lastAssignmentVat) {
        const percent = companyVats.find(vat => vat.id === lastAssignmentVat)?.percent ?? null
        const amounts = calculateNetAndVatAmount(grossAmountValue, percent, currency)
        // when no vat is selected, return gross amount only because we can't calculate net and vat amounts
        assignmentValues.net_amount = amounts.net_amount
        assignmentValues.vat_amount = amounts.vat_amount
        assignmentValues.vat = lastAssignmentVat
      }

      append(assignmentValues as FieldArray<FormValues, ArrayPath<FormValues>>)
    }

    function getCopyAssignmentHandler(index: number) {
      return function handler() {
        const assignments = getValues('assignments' as Path<FormValues>) as ExpenseDetailsAssignment[]
        const assignment = assignments[index]

        if (assignment) {
          const currency = getValues('currency' as Path<FormValues>)
          const grossAmount = Number(getValues('gross_amount' as Path<FormValues>) || 0)
          const grossAmountValue = calculateGrossAmount(assignments, grossAmount, currency)

          const clonedAssignment = __cloneDeep(assignment)
          // Remove the id and note from the cloned field
          clonedAssignment.id = undefined
          clonedAssignment.note = null
          clonedAssignment.gross_amount = grossAmountValue

          const assignmentVat = assignments[index]?.vat ?? null

          if (assignmentVat) {
            const percent = companyVats.find(vat => vat.id === assignmentVat)?.percent ?? null
            const amounts = calculateNetAndVatAmount(grossAmountValue, percent, currency)
            // when no vat is selected, return gross amount only because we can't calculate net and vat amounts
            clonedAssignment.net_amount = amounts.net_amount
            clonedAssignment.vat_amount = amounts.vat_amount
            clonedAssignment.vat = assignmentVat
          }

          // Add the cloned field to the fields array
          append(clonedAssignment as FieldArray<FormValues, ArrayPath<FormValues>>)
        }
      }
    }

    return { addAssignmentHandler, getCopyAssignmentHandler }
  }, [append, companyVats, getValues, invoiceType])
}
