import React from 'react'
import PropTypes from 'prop-types'

import { useController, useFormContext } from 'react-hook-form'
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'
import { connect } from 'react-redux'

import { getDecimal } from '@helpers'

import { useNumberFormatSeparators } from '@hooks'

import { INVOICE_AMOUNT_INPUT_DECIMAL_PLACES } from '@constants'

import { TextFieldBehavior, TextFieldBehaviorProps } from './TextFieldBehavior'

interface ReactHookFormAmountFieldProps
  extends Omit<TextFieldBehaviorProps, 'error' | 'helperText' | 'onChange' | 'value'>,
    Omit<
      NumberFormatProps,
      | 'autoComplete'
      | 'decimalScale'
      | 'decimalSeparator'
      | 'fixedDecimalScale'
      | 'getInputRef'
      | 'isNumericString'
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'onFocus'
      | 'onValueChange'
      | 'thousandSeparator'
      | 'type'
    > {
  locale: Locale
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  onChange?: (name: string, value: string) => void
}

function PureReactHookFormAmountField({
  disabled,
  locale,
  maximumFractionDigits = INVOICE_AMOUNT_INPUT_DECIMAL_PLACES,
  minimumFractionDigits = 0,
  name,
  onChange: onChangeProp,
  ...rest
}: ReactHookFormAmountFieldProps) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control })
  const { decimalSeparator, thousandSeparator } = useNumberFormatSeparators(locale)

  const isFieldDisabled = disabled || isSubmitting

  const handleValueChange = React.useCallback(
    ({ value, floatValue }: NumberFormatValues) => {
      const inputValue =
        floatValue === undefined ? value : getDecimal(floatValue, { maximumFractionDigits, minimumFractionDigits })
      onChange?.(inputValue)
      onChangeProp?.(name, inputValue)
    },
    [maximumFractionDigits, minimumFractionDigits, name, onChange, onChangeProp]
  )

  return (
    <NumberFormat
      {...rest}
      autoComplete="off"
      customInput={TextFieldBehavior}
      decimalScale={maximumFractionDigits}
      decimalSeparator={decimalSeparator}
      disabled={isFieldDisabled}
      error={!!error}
      fixedDecimalScale={minimumFractionDigits === maximumFractionDigits && minimumFractionDigits !== 0}
      helperText={error?.message ?? ''}
      isNumericString
      name={name}
      onValueChange={handleValueChange} // we need the raw value instead of formatted (event.target.value), use decimal numeric string as BE provide this data to handle dirty fields properly
      thousandSeparator={thousandSeparator}
      type="tel"
      value={value}
    />
  )
}

PureReactHookFormAmountField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  label: PropTypes.node.isRequired,
  locale: PropTypes.oneOf(['hu', 'en']).isRequired as React.Validator<Locale>,
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showDecimals: PropTypes.bool,
}

export const ReactHookFormAmountField = connect(
  (state: Store) => ({
    locale: state.locale.language,
  }),
  {} // do not pass "dispatch" to props - caused issue in "rest"
)(PureReactHookFormAmountField)

ReactHookFormAmountField.displayName = 'ReactHookFormAmountField'
