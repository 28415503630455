import styled, { css } from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { ButtonProps, getButtonVariantStyles, StyleOnlyPropKeys, StyleProps } from './Button'
import { ButtonBase } from './ButtonBase'
import { BUTTON_SIZE } from './constant'

const buttonSizeMap = {
  [BUTTON_SIZE.SMALL]: { buttonSize: 28, buttonPadding: 4 },
  [BUTTON_SIZE.NORMAL]: { buttonSize: 36, buttonPadding: 8 },
  [BUTTON_SIZE.LARGE]: { buttonSize: 48, buttonPadding: 12 },
}

export function getIconButtonSizeStyles({ size = BUTTON_SIZE.NORMAL }: StyleProps) {
  const { buttonSize, buttonPadding } = buttonSizeMap[size]

  return css`
    height: ${buttonSize}px;
    width: ${buttonSize}px;
    padding: ${buttonPadding}px;
  `
}

const BaseIconButton = styled(ButtonBase)`
  border: 0;
  justify-content: center;
  background-color: transparent;
  transition: background-color 250ms ease;
  width: auto;
  height: auto;
  padding: 5px;

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${({ theme }) => applyOpacity(theme.colors.blue[40], 6)};
    box-shadow: unset;
  }
`

export const IconButton = styled(BaseIconButton).withConfig<ButtonProps>({
  shouldForwardProp: (prop, defaultValidator) =>
    !(Object.values(StyleOnlyPropKeys) as unknown[]).includes(prop) && defaultValidator(prop),
})`
  ${getIconButtonSizeStyles}
  ${getButtonVariantStyles}
`
