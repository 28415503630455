import React from 'react'

import { DocViewerConfig, RendererRect } from '../types'
import { useWindowSize } from './useWindowSize '

export function useRendererSizes(config?: DocViewerConfig): RendererRect {
  const size = useWindowSize()

  return React.useMemo(() => {
    const configMaxWidth = config?.viewerSize?.maxWidth ?? 0

    const windowWidth = size.width ?? 0
    const windowHeight = size.height ?? 0
    const configMaxHeight = config?.viewerSize?.maxHeight ?? windowHeight

    const maxWidth = Math.min(windowWidth, configMaxWidth) - (config?.viewerSize?.reducingWidth ?? 0)
    const maxHeight = Math.min(windowHeight, configMaxHeight) - (config?.viewerSize?.reducingHeight ?? 0)

    // default landscape view: biggest dialog on screen
    return { width: maxWidth, height: maxHeight }
  }, [size, config])
}
