import React from 'react'

import { CustomErrorBoundaryView } from '@components/ui'

interface RootErrorBoundaryProps {
  error: Error
}

export function RootErrorBoundary({ error }: RootErrorBoundaryProps) {
  console.error('RootErrorBoundary', error.message)

  return <CustomErrorBoundaryView />
}
