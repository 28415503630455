import React from 'react'
import { number, string } from 'prop-types'

export default function CategorizationIcon({ color, size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)" fill={color}>
        <path
          // eslint-disable-next-line max-len
          d="M28 13.373H14.693V0h1.109C22.594 0 28 5.433 28 12.259v1.114zm-11.09-2.229h8.872c-.554-4.597-4.158-8.358-8.871-8.915v8.915z"
        />

        <path
          // eslint-disable-next-line max-len
          d="M12.198 28C5.406 28 0 22.567 0 15.741 0 8.915 5.406 3.483 12.198 3.483h1.109v11.144h11.089v1.114C24.396 22.567 18.99 28 12.198 28zM11.089 5.85c-4.99.558-8.871 4.876-8.871 10.03 0 5.573 4.435 10.03 9.98 10.03 5.129 0 9.426-3.9 9.98-8.915H11.09V5.851z"
        />
        <path d="M13.018 15.162l-1.823 1.268 6.233 9.049 1.823-1.27-6.233-9.047z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

CategorizationIcon.defaultProps = {
  size: 24,
  color: 'currentColor',
}
CategorizationIcon.propTypes = {
  size: number,
  color: string,
}
