import React from 'react'

import styled from 'styled-components'

const InputWrapper = styled.div`
  font-size: ${({ theme }) => theme.typography['400-xs'].fontSize};
  line-height: ${({ theme }) => theme.typography['400-xs'].lineHeight};
  color: ${({ theme }) => theme.colors.gray[70]};
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  border-radius: 4px;
  padding: 4px 5px;
  height: 32px;
  display: grid;
  grid-template-columns: 28px 30px;
  align-items: center;
  cursor: default;

  &:focus-within,
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary[60]};
    color: ${({ theme }) => theme.colors.gray[80]};
  }
`

const StyledInput = styled.input`
  width: 100%;
  border: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  text-align: right;
  margin-right: 3px;
`

interface PaginationInputProps {
  defaultValue: number
  maxValue: number
  onChange: (value: number) => void
}

export function PaginationInput({ defaultValue, maxValue, onChange }: PaginationInputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [value, setValue] = React.useState(String(defaultValue))
  const numericValue = Number(value)
  const isValid = numericValue <= maxValue && numericValue > 0

  React.useEffect(() => {
    setValue(String(defaultValue))
  }, [defaultValue])

  function handleClick() {
    inputRef.current?.focus()
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value.replace(/\D/g, '')) // only allow digits
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Space') {
      if (isValid) {
        onChange(numericValue)
      } else {
        setValue(String(defaultValue))
      }
    }
  }

  function handleBlur() {
    if (isValid) {
      onChange(numericValue)
    } else {
      setValue(String(defaultValue))
    }
  }

  return (
    <InputWrapper onClick={handleClick}>
      <StyledInput
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
      />
      / {maxValue}
    </InputWrapper>
  )
}
