import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { dokumentsActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage, parseApiErrorWithConsent } from '@helpers'

import { useCompanyConsent, useSuccessDialog } from '@contexts'

import { useCancellablePromiseRef } from '@hooks'

import { AstromechSolidIcon, AsyncButtonWithIcon, FlexRow, InfoText, Typography } from '@components/ui'

import { DokumentAiRecognitionConfirmDialog, GrayCardPaper, GrayPaperBox } from './elements'

const CardPaper = styled(GrayCardPaper)`
  margin-bottom: 20px;
`

const StyledGrayPaperBox = styled(GrayPaperBox)`
  margin-bottom: 20px;
`

const CardHeader = styled(FlexRow)`
  padding: 16px 12px;
  background: linear-gradient(90deg, #2837c6, ${({ theme }) => theme.colors.common.badgePurple});
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

const CardBody = styled.div`
  padding: 20px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`

// synced from backend
// const DOCUMENT_AI_RECOGNITION_STATUS_CREATED = 0
// const DOCUMENT_AI_RECOGNITION_STATUS_STARTED = 10
// const DOCUMENT_AI_RECOGNITION_STATUS_SUCCESS = 20
const DOCUMENT_AI_RECOGNITION_STATUS_ERROR = 30

interface DokumentAiRecognitionProps {
  continueRecognition: AsyncFunction<string, BackgroundActionResponse>
  data: Nullable<DokumentAiRecognitionData>
  documentId: number
  fetchDetails: (id: number) => void
  needConfirm: boolean
  requestUrl: BackendCallableLink | undefined
  startRecognition: AsyncFunction<string, BackgroundActionResponse>
  statusUrl: BackendCallableLink | undefined
  stopRecognition: VoidFunction
}

function PureDokumentAiRecognition({
  continueRecognition,
  data,
  documentId,
  fetchDetails,
  needConfirm,
  requestUrl,
  startRecognition,
  statusUrl,
  stopRecognition,
}: DokumentAiRecognitionProps) {
  const [loading, setLoading] = React.useState(Boolean(statusUrl))
  const [open, setOpen] = React.useState(false)
  const cPromiseRef = useCancellablePromiseRef<BackgroundActionResponse>()
  const setSuccessMessage = useSuccessDialog()
  const stopRef = React.useRef(stopRecognition)
  const { requestConsent, requestWithConsentCheck } = useCompanyConsent()

  React.useEffect(() => {
    const callStopAction = stopRef.current
    return () => {
      if (cPromiseRef.current) {
        callStopAction()
      }
    }
  }, [cPromiseRef])

  const processWrapper = React.useCallback(
    async (actionCreator: AsyncFunction<string, BackgroundActionResponse>, url: string) => {
      try {
        cPromiseRef.current = cancellablePromise(actionCreator(url))
        await cPromiseRef.current.promise
        fetchDetails(documentId)
        setSuccessMessage({
          title: (
            <FormattedMessage id="dokumentAiRecognition.dialogs.success.title" defaultMessage="Sikeres feldolgozás" />
          ),
          message: (
            <FormattedMessage
              id="dokumentAiRecognition.dialogs.success.text"
              defaultMessage="A dokumentumod sikeresen feldolgoztuk."
            />
          ),
          withConfetti: true,
        })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          const isConsentAllowedOrNull = parseApiErrorWithConsent(error)

          // important to check against false, because null means that the consent is not needed
          if (isConsentAllowedOrNull === false) {
            setLoading(false)
            requestConsent()
          } else {
            console.error(`Document (ID: ${documentId}) AI Recognition failed: ${errorMessage}`)
            fetchDetails(documentId)
            setSuccessMessage({
              title: (
                <FormattedMessage
                  id="dokumentAiRecognition.dialogs.failure.title"
                  defaultMessage="Sikertelen feldolgozás"
                />
              ),
              message: (
                <FormattedMessage
                  id="dokumentAiRecognition.dialogs.failure.text"
                  defaultMessage="Ezt a dokumentumot nem tudjuk feldolgozni."
                />
              ),
            })
          }
        }
      }
    },
    [cPromiseRef, documentId, fetchDetails, requestConsent, setSuccessMessage]
  )

  // continue to ping status when recognition is started but not finished
  React.useEffect(() => {
    if (statusUrl) {
      processWrapper(continueRecognition, statusUrl)
    }
  }, [continueRecognition, processWrapper, statusUrl])

  function handleClose() {
    setOpen(false)
  }

  function handleConfirm() {
    if (requestUrl) {
      setOpen(false)
      setLoading(true)
      processWrapper(startRecognition, requestUrl)
    }
  }

  function handleClick() {
    if (needConfirm) {
      setOpen(true) // show confirm dialog
    } else {
      handleConfirm()
    }
  }

  // not supported or processed with error
  if (!requestUrl && !statusUrl && (!data || data.status === DOCUMENT_AI_RECOGNITION_STATUS_ERROR)) {
    return (
      <StyledGrayPaperBox elevation={0}>
        <InfoText iconColor="gray-40" size="400-sm" color="gray-80">
          <FormattedMessage
            id="dokumentAiRecognition.text.notSupported"
            defaultMessage="Ezt a dokumentumot nem tudjuk feldolgozni."
          />
        </InfoText>
      </StyledGrayPaperBox>
    )
  }

  // supported and processed
  if (!requestUrl && !statusUrl && data) {
    return (
      <StyledGrayPaperBox elevation={0}>
        <InfoText iconColor="gray-40" size="400-sm" color="gray-80">
          <FormattedMessage
            id="dokumentAiRecognition.text.processed"
            defaultMessage="Ezt a dokumentumot már feldolgoztuk."
          />
        </InfoText>
      </StyledGrayPaperBox>
    )
  }

  // supported and not processed yet
  return (
    <>
      <CardPaper borderRadius={6} elevation={0}>
        <CardHeader>
          <Typography color="white" size="700-sm">
            {loading ? (
              <FormattedMessage
                id="dokumentAiRecognition.text.status.loading"
                defaultMessage="A dokumentum feldolgozás alatt van"
              />
            ) : (
              <FormattedMessage
                id="dokumentAiRecognition.text.status.ready"
                defaultMessage="AI dokumentum feldolgozás"
              />
            )}
          </Typography>
          <AsyncButtonWithIcon
            icon={<AstromechSolidIcon />}
            loading={loading}
            onClick={requestWithConsentCheck(handleClick)}
            size="small"
            variant="primaryContained"
          >
            <FormattedMessage id="dokumentAiRecognition.buttons.process" defaultMessage="Feldolgozás" />
          </AsyncButtonWithIcon>
        </CardHeader>
        <CardBody>
          <InfoText iconColor="gray-40" size="400-sm" color="gray-80">
            <FormattedMessage
              id="dokumentAiRecognition.text.supported"
              defaultMessage="Jelenleg PDF dokumentumokhoz támogatjuk AI dokumentum feldolgozás funkciót, fotózott, szkennelt vagy egyéb fájlformátumokhoz nem. Minden dokumentum esetén automatikus feldolgozásra egyszer van lehetőséged. Ezzel a művelettel felülírhatod a korábban bevitt adataidat, a generálást követően minden mező szerkeszthető marad."
            />
          </InfoText>
        </CardBody>
      </CardPaper>
      <DokumentAiRecognitionConfirmDialog onClose={handleClose} onConfirm={handleConfirm} open={open} />
    </>
  )
}

export const DokumentAiRecognition = connect(null, dispatch => ({
  continueRecognition: bindActionToPromise(dispatch, dokumentsActions.continueAiRecognition.request),
  fetchDetails: bindActionCreators(dokumentsActions.fetchDokumentDetails.request, dispatch),
  startRecognition: bindActionToPromise(dispatch, dokumentsActions.startAiRecognition.request),
  stopRecognition: bindActionCreators(dokumentsActions.stopAiRecognition.request, dispatch),
}))(PureDokumentAiRecognition)

DokumentAiRecognition.displayName = 'DokumentAiRecognition'
