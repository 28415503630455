import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

export default function UpsellMessage({ type = 'upsell' }) {
  switch (type) {
    case 'upsell-multibox':
      return (
        <FormattedMessage
          id="page.noPermission.upsellMultibox.tooltip"
          defaultMessage="A <b>MultiBox</b> előfizetői csomagtól elérhető."
          values={{ b: chunks => <b>{chunks}</b> }} // eslint-disable-line react/display-name
        />
      )
    case 'upsell':
    default:
      return (
        <FormattedMessage
          id="page.noPermission.upsell.tooltip"
          defaultMessage="Az <b>OkosBox</b> előfizetői csomagtól elérhető."
          values={{ b: chunks => <b>{chunks}</b> }} // eslint-disable-line react/display-name
        />
      )
  }
}

UpsellMessage.propTypes = { type: PropTypes.oneOf(['upsell', 'upsell-multibox']) }
