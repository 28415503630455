import React from 'react'

import { FormattedNumber } from 'react-intl'

import { NotAvailableValueMessage } from '@messages'

interface FormattedNumberWhenAvailableProps {
  value: Nullable<number>
}

export function FormattedNumberWhenAvailable({ value }: FormattedNumberWhenAvailableProps) {
  if (value == null) {
    return NotAvailableValueMessage
  }

  return <FormattedNumber value={value} />
}
