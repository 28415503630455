import React from 'react'
import PropTypes from 'prop-types'

import { Fade, Typography, TypographyProps } from '@material-ui/core'

import { usePrevious } from '@hooks'

interface MessageTextProps extends Omit<TypographyProps, 'children'> {
  duration?: number
  value?: React.ReactNode
}

export default function MessageText({ className, duration = 4000, value, ...rest }: MessageTextProps) {
  const [showText, setShowText] = React.useState<React.ReactNode>(null)
  const timerRef = React.useRef<number>()
  const prevValue = usePrevious(value)

  React.useEffect(() => {
    if (value && value !== prevValue) {
      setShowText(value)
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [prevValue, value])

  return (
    <Fade
      in={Boolean(showText)}
      mountOnEnter
      unmountOnExit
      onEntered={() => {
        timerRef.current = window.setTimeout(() => setShowText(null), duration)
      }}
    >
      <div>
        <Typography {...rest}>{showText}</Typography>
      </div>
    </Fade>
  )
}

MessageText.propTypes = {
  duration: PropTypes.number,
  value: PropTypes.node,
}
