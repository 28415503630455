import { PrintTypes } from 'print-js'

export function getPrintFileType(fileUrl: string | undefined): PrintTypes | undefined {
  if (!fileUrl) {
    return
  }
  const results = fileUrl.match(/.+\.([^?]+)(\?|$)/)
  if (!results) {
    return
  }

  if (/pdf$/i.test(results[1])) {
    return 'pdf'
  }
  if (/(jpe?g|png|bmp)$/i.test(results[1])) {
    return 'image'
  }
  // unsupported fileType
  return
}
