import React from 'react'
import PropTypes from 'prop-types'

import { Button, Popover, Typography } from '@material-ui/core'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import cx from 'classnames'
import moment from 'moment'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Calendar from 'react-widgets/lib/Calendar'

import { formatDate } from '@helpers'

import { DATE_WIDGET_VARIANTS as MENU_VARIANTS, PAGE_FILTER_VARIANTS as VARIANTS } from '@components/filters/constants'
import { DatePeriodsMenu, DateTypeSelector, FilterOptions } from '@components/filters/elements'
import { DateTypeOption } from '@components/filters/types'

import { CALENDAR_LOCALE_FORMATS } from '@constants'

import { DisabledDateFilterValueMessage } from '@messages'

const DateInputButton = withStyles((theme: Theme) => ({
  root: {
    minWidth: 0,
    padding: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    justifyContent: 'flex-start',

    '&:hover': {
      backgroundColor: `${theme.palette.tertiary.oceanBlue}10`,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.grayscale.black,
    minWidth: 185, // prevent width change on disable
  },
  iconSizeMedium: {
    '&:first-child': {
      fontSize: 18,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 10,
    color: theme.palette.grayscale.grey50,
  },
}))(Button)

const useMinimalStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 0,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${theme.palette.tertiary.paperStroke}`,
    '&:not(.compact)': {
      borderRight: `1px solid ${theme.palette.tertiary.paperStroke}`,
    },

    '& > *': {
      height: '100%',
    },
    '&.compact': {
      height: theme.appBarHeightMedium,
    },
  },
  divider: {
    width: 1,
    backgroundColor: theme.palette.tertiary.paperStroke,
  },
  filtersIcon: {
    width: 38,
  },
  datepickerRoot: {
    width: 430,
    display: 'flex',
    padding: 10,
    gap: 10,
    fontSize: 12,

    '&>.rw-calendar': {
      width: 200,
      padding: 5,
    },
  },
  menuRoot: {
    minWidth: 244,
  },
}))

const useDatepickerStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: 10,
    fontSize: 16,
  },
  calendarContainer: {
    width: '50%',
    // fontSize: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,

    '& .rw-cell': {
      transition: 'all 150ms ease-in-out',
    },

    '& .rw-calendar': {
      padding: 5,
    },

    '& .rw-cell.rw-state-selected, & .rw-cell.rw-state-selected:hover': {
      backgroundColor: theme.palette.tertiary.oceanBlue,
      borderColor: theme.palette.tertiary.oceanBlue,
    },

    '&.disabled .rw-cell.rw-state-selected, &.disabled .rw-cell.rw-state-selected:hover': {
      backgroundColor: theme.palette.grayscale.grey20,
      borderColor: theme.palette.grayscale.grey20,
    },

    '& .rw-calendar-btn-view': {
      color: theme.palette.grayscale.grey60,
    },
  },
  label: {
    fontWeight: 700,
    color: theme.palette.grayscale.black,
    fontSize: 12,
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },
}))

interface MinimalDateFilterProps {
  children: React.ReactNode
  dateTypeOptions: DateTypeOption[]
  dateTypeValue: string
  disabled: boolean
  fromDate: Nullable<string>
  isCompact?: boolean
  isDateFilterToggleDisabled?: boolean
  onChange: (values: DateFilterChangeValues) => void
  onToggle: (payload: boolean) => void
  toDate: Nullable<string>
}

function MinimalDateFilter({
  children,
  dateTypeOptions,
  dateTypeValue,
  disabled,
  fromDate,
  isCompact = false,
  isDateFilterToggleDisabled = false,
  onChange,
  onToggle,
  toDate,
}: MinimalDateFilterProps) {
  const classes = useMinimalStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [optionsOpen, setOptionsOpen] = React.useState(false)
  const anchorElRef = React.useRef<HTMLDivElement>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <div className={cx(classes.container, { compact: isCompact })} ref={anchorElRef}>
        <DateInputButton
          aria-describedby={id}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
          startIcon={<CalendarTodayOutlinedIcon fontSize="inherit" />}
        >
          {disabled ? (
            DisabledDateFilterValueMessage
          ) : (
            <>
              <FormattedDate value={fromDate ?? undefined} /> - <FormattedDate value={toDate ?? undefined} />
            </>
          )}
        </DateInputButton>
        <div className={classes.divider} />
        <FilterOptions
          anchorEl={anchorElRef.current}
          onClick={() => setOptionsOpen(true)}
          onClose={() => setOptionsOpen(false)}
          open={optionsOpen}
          variant="date"
        >
          <div className={classes.menuRoot}>
            <DateTypeSelector
              onChange={onChange}
              options={dateTypeOptions}
              value={dateTypeValue}
              variant={MENU_VARIANTS.menu}
            />

            <DatePeriodsMenu
              disabled={disabled}
              isDateFilterToggleDisabled={isDateFilterToggleDisabled}
              onToggle={onToggle}
              onChange={onChange}
              variant={MENU_VARIANTS.menu}
            />
          </div>
        </FilterOptions>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.datepickerRoot}>{children}</div>
      </Popover>
    </>
  )
}

MinimalDateFilter.propTypes = {
  children: PropTypes.node.isRequired,
  dateTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
  dateTypeValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  fromDate: PropTypes.string.isRequired,
  isCompact: PropTypes.bool.isRequired,
  isDateFilterToggleDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  toDate: PropTypes.string.isRequired,
}

export interface PureDateFilterProps {
  dateTypeOptions: DateTypeOption[]
  dateTypeValue: string
  defaultView: 'year' | 'month' | 'decade'
  disabled: boolean
  fromDate: Nullable<string>
  locale: Locale
  isCompact?: boolean
  isDateFilterToggleDisabled?: boolean
  onChange: (values: DateFilterChangeValues) => void
  onToggle: (payload: boolean) => void
  toDate: Nullable<string>
  variant?: ConstObjectValues<typeof VARIANTS>
}

function PureDateFilter({
  dateTypeOptions,
  dateTypeValue,
  defaultView,
  disabled,
  fromDate,
  isDateFilterToggleDisabled = false,
  locale,
  onChange,
  onToggle,
  toDate,
  variant = VARIANTS.widget,
}: PureDateFilterProps) {
  const formats = CALENDAR_LOCALE_FORMATS // TODO move to props

  const datepickerClasses = useDatepickerStyles()

  const content = (
    <>
      <div className={cx(datepickerClasses.calendarContainer, { disabled })}>
        <Typography variant="h4" className={datepickerClasses.label}>
          <FormattedMessage id="datefilter.label.fromDate" defaultMessage="dátum (-tól)" />
        </Typography>
        <Calendar
          //   formats={formats.input[locale]}
          defaultView={defaultView}
          footer={false}
          headerFormat={formats.header[locale]}
          max={toDate ? new Date(toDate) : undefined}
          onChange={value => onChange({ fromDate: formatDate(moment(value)) })}
          value={fromDate ? new Date(fromDate) : undefined}
          views={['month', 'year', 'decade']}
        />
      </div>
      <div className={cx(datepickerClasses.calendarContainer, { disabled })}>
        <Typography variant="h4" className={datepickerClasses.label}>
          <FormattedMessage id="datefilter.label.toDate" defaultMessage="dátum (-ig)" />
        </Typography>
        <Calendar
          //   formats={formats.input[locale]}
          defaultView={defaultView}
          footer={false}
          headerFormat={formats.header[locale]}
          min={fromDate ? new Date(fromDate) : undefined}
          onChange={value => onChange({ toDate: formatDate(moment(value)) })}
          value={toDate ? new Date(toDate) : undefined}
          views={['month', 'year', 'decade']}
        />
      </div>
    </>
  )

  if (variant === VARIANTS.form) {
    return <div className={datepickerClasses.root}>{content}</div>
  }

  return (
    <MinimalDateFilter
      dateTypeOptions={dateTypeOptions}
      dateTypeValue={dateTypeValue}
      disabled={disabled}
      fromDate={fromDate}
      isCompact={variant === VARIANTS.compact}
      isDateFilterToggleDisabled={isDateFilterToggleDisabled}
      onChange={onChange}
      onToggle={onToggle}
      toDate={toDate}
    >
      {content}
    </MinimalDateFilter>
  )
}

PureDateFilter.propTypes = {
  dateTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired as React.Validator<DateTypeOption[]>,
  dateTypeValue: PropTypes.string.isRequired,
  defaultView: PropTypes.oneOf(['month', 'year', 'decade']).isRequired as React.Validator<'month' | 'year' | 'decade'>,
  disabled: PropTypes.bool.isRequired,
  fromDate: PropTypes.string,
  isDateFilterToggleDisabled: PropTypes.bool,
  locale: PropTypes.oneOf(['hu', 'en']).isRequired as React.Validator<Locale>,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  toDate: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
}

function mapStateToProps(state: Store) {
  return {
    locale: state.locale.language,
  }
}

const DateFilter = connect(mapStateToProps)(PureDateFilter)

DateFilter.displayName = 'DateFilter'

export default DateFilter
