import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, InputAdornment } from '@material-ui/core'
import __debounce from 'lodash/debounce'
import __noop from 'lodash/noop'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { commonActions } from '@services'

import {
  bindActionToPromise,
  cancellablePromise,
  formatDate,
  getCurrency,
  getDecimal,
  parseApiErrorMessage,
} from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef } from '@hooks'

import { IncomeCashRegisterFormValues } from '@components/forms/IncomeCashRegisterForm/types'
import { ReactHookFormDateField } from '@components/ui'
import { UpdateIcon } from '@oldComponents/ui'

import { exchangeRateDateMessage } from '@messages'
import messages from '@components/forms/messages'
import { RefreshIconButton } from './styles'

interface ReactHookFormExchangeDateProps {
  companyDefaultCurrency: number
  currencyOptions: CommonIdAndNameType[]
  disabled: boolean
  fetchExchangeRate: AsyncFunction<FetchExchangeRatePayload, FetchExchangeRateResponse>
  maximumFractionDigits?: number
  minimumFractionDigits?: number
}

const FIELD_NAME = 'exchange_date'

function PureReactHookFormExchangeDate({
  companyDefaultCurrency,
  currencyOptions,
  disabled,
  fetchExchangeRate,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
}: ReactHookFormExchangeDateProps) {
  const { formatMessage } = useIntl()
  const { setErrorAlert } = useAlertDispatch()

  const {
    setValue,
    formState: {
      isSubmitting,
      isValidating,
      defaultValues: {
        exchange_date: initialExchangeDate,
        currency: initialCurrency,
        exchange_rate: initialExchangeRate,
      } = {},
    },
    watch,
    getFieldState,
  } = useFormContext<IncomeCashRegisterFormValues>()
  const [exchangeDate, exchangeRate, fulfilledAt, currency] = watch([
    'exchange_date',
    'exchange_rate',
    'fulfilled_at',
    'currency',
  ])

  const [loading, setLoading] = React.useState(false)
  const [{ changed, valid, active }, setState] = React.useState({
    active: false,
    changed: false,
    valid: Boolean(exchangeRate) && !getFieldState(FIELD_NAME).invalid,
  })
  const isExchangeDateFieldChangedAndValidated = changed && valid
  const isFieldDisabled = disabled || isSubmitting
  const cPromiseRef = useCancellablePromiseRef<FetchExchangeRateResponse>()
  const isFormResetRef = React.useRef(true)
  const updateRef = React.useRef(__noop)
  const initExchangeDateRef = React.useRef(__noop)

  const debouncedFetch = React.useRef(
    __debounce((payload: FetchExchangeRatePayload) => {
      cPromiseRef.current = cancellablePromise(fetchExchangeRate(payload))
      // handle promise
      cPromiseRef.current.promise
        .then(response => {
          setLoading(false)
          setState(state => ({ ...state, changed: false })) // reset exchange_date state
          const decimalValue =
            response.exchange_rate.value === null
              ? ''
              : getDecimal(response.exchange_rate.value, {
                  maximumFractionDigits,
                  minimumFractionDigits,
                })
          setValue('exchange_rate', decimalValue)
        })
        .catch(error => {
          const errorMsg = parseApiErrorMessage(error)
          if (errorMsg) {
            setErrorAlert(errorMsg)
            setLoading(false)
          }
        })
    }, 1000)
  )

  const companyDefaultCurrencyName = React.useMemo(
    () => getCurrency(companyDefaultCurrency, currencyOptions),
    [companyDefaultCurrency, currencyOptions]
  )

  isFormResetRef.current =
    currency === initialCurrency && exchangeDate === initialExchangeDate && exchangeRate === initialExchangeRate

  updateRef.current = () => {
    setLoading(true)
    const currentCurrencyName = getCurrency(currency, currencyOptions)

    debouncedFetch.current({
      exchange_date: exchangeDate as string, // already validated, so it must be filled here
      from_currency: currentCurrencyName,
      to_currency: companyDefaultCurrencyName,
    })
  }

  initExchangeDateRef.current = () => {
    let date = exchangeDate
    if (!date) {
      if (fulfilledAt && !getFieldState('fulfilled_at').invalid) {
        date = fulfilledAt
      }
      if (!date) {
        // current date
        date = formatDate()
      }
      setValue('exchange_date', date, { shouldValidate: true })
    }
    setState(state => ({ ...state, changed: true }))
  }

  //* INIT exchange_date
  React.useEffect(() => {
    if (!disabled && !exchangeDate && active) {
      initExchangeDateRef.current()
    }
  }, [active, currency, disabled, exchangeDate])

  // track exchange_date field change and validation
  React.useEffect(() => {
    setState(state => ({ ...state, changed: true, valid: false }))
  }, [exchangeDate])

  React.useEffect(() => {
    if (changed && !valid && !isValidating) {
      setState(state => ({ ...state, valid: Boolean(exchangeDate) && !getFieldState(FIELD_NAME).invalid }))
    }
  }, [changed, exchangeDate, getFieldState, isValidating, valid])

  //* ON UPDATE: curreny or exchangeDate
  React.useEffect(() => {
    if (!disabled && !isFormResetRef.current && currency && isExchangeDateFieldChangedAndValidated) {
      // user change
      updateRef.current()
    }
  }, [currency, disabled, isExchangeDateFieldChangedAndValidated, exchangeDate])

  function handleRefreshClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    updateRef.current()
  }

  return (
    <ReactHookFormDateField
      name="exchange_date"
      label={formatMessage(messages.exchangeDateLabel)}
      placeholder={formatMessage(messages.dateFieldPlaceholder)}
      endAdornment={
        isFieldDisabled ? null : (
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress color="secondary" size={16} />
            ) : (
              <RefreshIconButton onClick={handleRefreshClick} title={formatMessage(exchangeRateDateMessage)}>
                <UpdateIcon size={16} color="currentColor" />
              </RefreshIconButton>
            )}
          </InputAdornment>
        )
      }
      disabled={isFieldDisabled}
      required
      onFocus={() => setState(state => ({ ...state, active: true }))}
      onBlur={() => setState(state => ({ ...state, active: false }))}
    />
  )
}

PureReactHookFormExchangeDate.propTypes = {
  companyDefaultCurrency: PropTypes.number.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  fetchExchangeRate: PropTypes.func.isRequired,
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
}

export const ReactHookFormExchangeDate = connect(
  (state: Store) => ({
    companyDefaultCurrency: state.auth.company.data.default_currency,
    currencyOptions: state.dashboard.common.currencies,
  }),
  dispatch => ({
    fetchExchangeRate: bindActionToPromise(dispatch, commonActions.fetchExchangeRate.request),
  })
)(PureReactHookFormExchangeDate)

ReactHookFormExchangeDate.displayName = 'ReactHookFormExchangeDate'
