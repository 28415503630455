import React from 'react'

interface SyncInvoiceByUrlProps {
  clearDetails: VoidFunction
  detailsId: ItemIdType | undefined
  fetchDetails: (id: ItemIdType) => void
  paramId: string | undefined
}

export function useSyncDetailsByUrl({ clearDetails, detailsId, fetchDetails, paramId }: SyncInvoiceByUrlProps) {
  const hasId = Boolean(paramId)
  const urlAndDetailsIdMatched = String(detailsId) === String(paramId)

  React.useEffect(() => {
    return () => {
      clearDetails()
    }
  }, [clearDetails])

  React.useEffect(() => {
    if (paramId) {
      fetchDetails(Number(paramId))
    }
  }, [fetchDetails, paramId])

  return [hasId, urlAndDetailsIdMatched]
}

// only for dokument details page (there is no create view - so clearDetails is not needed)
export function useSyncDokumentDetailsByUrl({
  detailsId,
  fetchDetails,
  paramId,
}: Omit<SyncInvoiceByUrlProps, 'clearDetails'>) {
  const hasId = Boolean(paramId)
  const urlAndDetailsIdMatched = String(detailsId) === String(paramId)

  React.useEffect(() => {
    if (paramId) {
      fetchDetails(Number(paramId))
    }
  }, [fetchDetails, paramId])

  return [hasId, urlAndDetailsIdMatched]
}
