// copy from BE
// TODO move to constants
export const INCOME_TYPE_INVOICE = 1
export const INCOME_TYPE_AID = 2
export const INCOME_TYPE_COMPENSATION = 3
export const INCOME_TYPE_SEVERANCE_PAY = 4
export const INCOME_TYPE_DISCOUNT = 5
export const INCOME_TYPE_CASH_REGISTER_VOUCHER = 6

// TODO move to common helpers
export function readFile(fileReference: Blob): Promise<{ url: string; file: Blob }> {
  return new Promise((resolve, reject) => {
    if (fileReference) {
      const reader = new FileReader()

      reader.onload = event => {
        resolve({ url: event.target?.result as string, file: fileReference })
      }
      reader.onerror = () => {
        reject('FileReader error')
      }

      reader.readAsDataURL(fileReference)
    } else {
      reject('Missing file reference')
    }
  })
}
