import __orderBy from 'lodash/orderBy'
import { GroupBase } from 'react-select'

export function isGroupedOptionGuard<Option>(option: Option | GroupBase<Option>): option is GroupBase<Option> {
  return typeof (option as GroupBase<Option>).options !== 'undefined'
}

export function orderSelectOptions<Option>(options: Option[], labelKey: keyof Option, skipSorting = false) {
  if (skipSorting) {
    return options
  }

  return __orderBy(
    options,
    item => {
      const label = isGroupedOptionGuard(item) ? item.label : item?.[labelKey] ?? ''
      if (typeof label === 'string') {
        return label.toLowerCase()
      } else {
        return label
      }
    },
    'asc'
  )
}

function isGroupedOption<Option>(option: Option) {
  // { label: String, options: Array}
  if (isGroupedOptionGuard(option)) {
    //! too generic - react-select options has no more than 1 level recursion
    // return option.options.map(function (innerOption) {
    //   return isGroupedOption(innerOption)
    // })
    return option.options
  } else {
    return option
  }
}

// utility helper
function flatten(arr: any[]): any[] {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
  }, [])
}

export function getSelectableOptions<Option>(options: Option[]) {
  const optionsArrays = options.map(option => isGroupedOption(option))
  return flatten(optionsArrays)
}
