import React from 'react'
import { node } from 'prop-types'

import { useAssertContext } from '@hooks/useAssertContext'

interface RowExpanderContextValues {
  expandedRows: Record<string, boolean>
  toggleRow: (id: string) => void
}

const TableRowExpanderContext = React.createContext<undefined | RowExpanderContextValues>(undefined)
TableRowExpanderContext.displayName = 'TableRowExpanderContext'

interface ExpanderProviderProps {
  children: React.ReactChild
}

/**
 * Provider to use with tables using row expansions.
 *
 * Since `react-table`'s own `useRowState` rerenders the whole table on every state change we are intentionally using an own context to store these values.
 * State and dispatch is not separated as only the expander is using the function which also uses the state which is going to be unique on each state change anyway.
 * By utilizing `<TableRowExpansionController />` the passed `children` is responsible for memoization if required.
 */
export const TableRowExpanderProvider = ({ children }: ExpanderProviderProps) => {
  const [expandedRows, setExpandedRows] = React.useState<Record<string, boolean>>({})

  const contextValue = React.useMemo(
    () => ({
      expandedRows,
      toggleRow: function (id: string) {
        setExpandedRows(state => ({ ...state, [id]: !state[id] }))
      },
    }),
    [expandedRows]
  )

  return <TableRowExpanderContext.Provider value={contextValue}>{children}</TableRowExpanderContext.Provider>
}

TableRowExpanderProvider.propTypes = {
  children: node.isRequired,
}

export function useTableRowExpander() {
  return useAssertContext(TableRowExpanderContext)
}
