import React from 'react'

import { connect } from 'react-redux'

import { filtersActions, QUARANTINE_LIST_INITIAL_FILTERS } from '@services'

import { usePageConfig, usePortal } from '@contexts'

import { QUARANTINE_LIST_DATE_FIELD_OPTIONS } from '@components/pages/constants'

import { FilterBar, FilterBarBaseProps } from './FilterBar'
import { checkFiltersChanged, getStatusFilterDialogProps, getStatusFilterProps } from './helpers'

import { QuarantineListPageMessage } from '@messages'

interface PureQuarantineListPageFilterBarProps extends FilterBarBaseProps {
  expenseTypeOptions: CommonIdAndNameType[]
  filters: Omit<QuarantineListStoreFilters, 'isDateFilterDisabled'>
  syncSearchInputValue: (value: string) => void
  tagOptions: Tag[]
}

function PureQuarantineListPageFilterBar({
  filters,
  filters: {
    dateField,
    origin,
    search,
    searchFields,
    withExpenseTypeIds,
    withoutExpenseTypeIds,
    withoutTagIds,
    withTagIds,
  },
  expenseTypeOptions,
  isDateFilterDisabled,
  resetFilters,
  syncSearchInputValue,
  tagOptions,
  updateFilters,
}: PureQuarantineListPageFilterBarProps) {
  const { setPortalAnchorEl } = usePortal()
  const pageConfig = usePageConfig()

  const isDateFilterUsed = true

  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }

  const statusFilterProps = getStatusFilterProps({
    config: pageConfig.statusFilterProps?.config ?? [],
    values: filters,
    width: 442,
  })

  const filterDialogProps: FilterDialogProps<StatusFilterConfig> = {
    dateFilterProps: {
      dateTypeOptions: QUARANTINE_LIST_DATE_FIELD_OPTIONS,
      dateTypeValue: dateField,
    },
    isChanged: checkFiltersChanged({ ...filters, isDateFilterDisabled }, QUARANTINE_LIST_INITIAL_FILTERS),
    page: QuarantineListPageMessage,
    searchFilterProps,
  }

  if (pageConfig.statusFilterProps) {
    filterDialogProps['statusFilterProps'] = getStatusFilterDialogProps({
      config: pageConfig.statusFilterProps.config,
      values: filters,
    })
  }

  if (pageConfig.categoryFilterProps) {
    filterDialogProps['categoryFilterProps'] = {
      config: {
        ...pageConfig.categoryFilterProps.config,
        options: expenseTypeOptions,
      },
      values: {
        include: withExpenseTypeIds,
        exclude: withoutExpenseTypeIds,
      },
    }
  }
  if (pageConfig.tagFilterProps) {
    filterDialogProps['tagFilterProps'] = {
      config: {
        ...pageConfig.tagFilterProps.config,
        options: tagOptions,
      },
      values: {
        include: withTagIds,
        exclude: withoutTagIds,
      },
    }
  }
  if (pageConfig.currencyFilterProps) {
    filterDialogProps['currencyFilterProps'] = {
      // config: pageConfig.currencyFilterProps.config,
      currencyId: filters.currencyId, // TODO need to refact: { config: { name: 'currency', options: currencyOptions }, values: filters.currency }
    }
  }
  if (pageConfig.amountFilterProps) {
    filterDialogProps['amountFilterProps'] = {
      // config: pageConfig.amountFilterProps.config,
      grossAmountMax: filters.grossAmountMax,
      grossAmountMin: filters.grossAmountMin,
    }
  }
  if (pageConfig.originFilterProps) {
    filterDialogProps['originFilterProps'] = {
      config: pageConfig.originFilterProps.config,
      values: origin,
    }
  }

  return (
    <FilterBar
      dateField={filters.dateField}
      filterDialogProps={filterDialogProps}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      setPortalRef={setPortalAnchorEl}
      statusFilterProps={statusFilterProps}
      syncSearchInputValue={syncSearchInputValue}
      updateFilters={updateFilters}
    />
  )
}

export const QuarantineListPageFilterBar = connect(
  ({
    dashboard: {
      tags: { data: tagOptions },
    },
    expense: {
      expenseTypes: { data: expenseTypeOptions },
    },
    filters: {
      quarantineList: { isDateFilterDisabled, ...quarantineListFilters },
    },
  }: Store) => {
    return {
      expenseTypeOptions,
      filters: quarantineListFilters,
      isDateFilterDisabled,
      tagOptions,
    }
  },
  {
    resetFilters: filtersActions.resetQuarantineListFilters.request,
    syncSearchInputValue: filtersActions.syncExpenseSearchFilterInput.request,
    updateFilters: filtersActions.updateQuarantineListFilters.request,
  }
)(PureQuarantineListPageFilterBar)

QuarantineListPageFilterBar.displayName = 'QuarantineListPageFilterBar'
