import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'

import { CommonAxiosPayload } from '@services'

import { cancellablePromise, getFontStyles, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef } from '@hooks'

import {
  AsyncButton,
  CircleCheckSolidIcon,
  INFORMATIONAL_MESSAGE_VARIANTS,
  InformationalMessage,
  InformationalMessageVariant,
  Typography,
  XmarkRegularIcon,
} from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { TaskQuestion, TaskQuestionName, TaskStatus } from '../types'

import { OptionFalseMessage, OptionTrueMessage } from '@messages'
import { StyledLabelInfoIcon } from './styles'

const CheckListWrapperDiv = styled.div`
  width: 650px;

  & > ${Typography} {
    margin-bottom: 12px;
  }
`

const CheckListInformationalMessage = styled(InformationalMessage).attrs({ icon: 'error' })`
  margin-top: 8px;
  margin-bottom: 16px;
`

const StepIndicator = styled.span`
  display: flex;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  ${({ theme }) => getFontStyles(theme, '700-sm')}

  background-color: var(--step-indicator-background-color, transparent);
  color: var(--step-indicator-text-color, inherit);

  ${CircleCheckSolidIcon} {
    color: ${({ theme }) => theme.colors.success[40]};
  }
`

const QuestionWrapperDiv = styled.div`
  width: 350px;
  display: flex;
  gap: 12px;
  position: relative;
  min-height: 58px;
  padding-top: 4px;
  flex-grow: 1;

  ${StepIndicator} {
    margin-top: 4px;
  }
`

const ButtonsContainerDiv = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
`

const CHECKLIST_CLASSES = {
  disabled: 'disabled',
  yes: 'passed',
  no: 'rejected',
  active: 'active',
}

const CheckListItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  --step-indicator-line-color: ${({ theme }) => theme.colors.primary[50]};
  --step-indicator-text-color: ${({ theme }) => theme.colors.gray[0]};
  --step-indicator-background-color: ${({ theme }) => theme.colors.primary[50]};
  --step-text-color: ${({ theme }) => theme.colors.gray[130]};

  &.${CHECKLIST_CLASSES.disabled} {
    --step-indicator-text-color: ${({ theme }) => theme.colors.gray[130]};
    --step-indicator-background-color: ${({ theme }) => theme.colors.gray[30]};
  }

  &.${CHECKLIST_CLASSES.yes} {
    --step-indicator-text-color: ${({ theme }) => theme.colors.gray[0]};
    --step-indicator-background-color: ${({ theme }) => theme.colors.gray[0]};
  }

  &.${CHECKLIST_CLASSES.no} {
    --step-indicator-text-color: ${({ theme }) => theme.colors.gray[0]};
    --step-indicator-background-color: ${({ theme }) => theme.colors.error[30]};
  }

  /* Note: Template literal for the last selector required to silence a ts-styles-plugin (9999) error
    as it does not work well with interpolation */
  &.${CHECKLIST_CLASSES.disabled}, &.${CHECKLIST_CLASSES.yes}, &.${CHECKLIST_CLASSES.no} {
    --step-indicator-line-color: ${({ theme }) => theme.colors.gray[130]};
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;

    ${QuestionWrapperDiv}:before {
      display: block;
      content: '';
      position: absolute;
      width: 1px;
      left: 10px;
      top: 34px;
      bottom: 0;
      background-color: var(--step-indicator-line-color, inherit);
    }
  }

  ${QuestionWrapperDiv} ${Typography} {
    margin-top: 4px;
    transition: all 200ms ease-out;
  }

  &.${CHECKLIST_CLASSES.active} ${QuestionWrapperDiv} ${Typography} {
    color: ${({ theme }) => theme.colors.gray[100]};
    margin-top: 2px;
    ${({ theme }) => getFontStyles(theme, '400-md')};
  }
`

const ChecklistAsyncButton = styled(AsyncButton)<{ $answered: boolean }>`
  ${({ $answered, theme }) =>
    $answered &&
    css`
      &:disabled {
        color: ${theme.colors.primary[50]};
      }
    `}
`

enum AnswerChoices {
  YES = 'yes',
  NO = 'no',
}

type ChoiceIdentifier = `${TaskQuestionName}-${AnswerChoices}`

interface TaxationCheckListProps extends React.HTMLAttributes<HTMLDivElement> {
  callUpdate: (payload: Record<string, unknown>) => void
  callUrl: AsyncFunction<CommonAxiosPayload, unknown>
  errorMessage: StringOrMessage
  informationalMessageVariant?: InformationalMessageVariant
  isCompleted: boolean
  questions: TaskQuestion[]
}

export function TaxationCheckList({
  callUpdate,
  callUrl,
  className,
  errorMessage,
  informationalMessageVariant,
  isCompleted,
  questions,
}: TaxationCheckListProps) {
  const [loading, setLoading] = React.useState<null | ChoiceIdentifier>(null)

  const { setErrorAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef()

  function handleClick({ choice, url }: { choice: ChoiceIdentifier; url: BackendCallableLink }) {
    return async function handler() {
      if (url) {
        setLoading(choice)
        try {
          cPromiseRef.current = cancellablePromise(callUrl({ url, method: 'post' }))
          const payload = await cPromiseRef.current.promise
          callUpdate(payload)
        } catch (error) {
          const errorMsg = parseApiErrorMessage(error)
          if (errorMsg) {
            setErrorAlert(errorMsg)
          }
        }

        setLoading(null)
      }
    }
  }

  // check when need to display warning
  let hasNoAnswer = false
  let hasTodoQuestion = false
  questions.forEach(({ status }) => {
    if (!hasTodoQuestion && status === TaskStatus.TODO) {
      hasTodoQuestion = true
    }
    if (!hasNoAnswer && status === TaskStatus.NO) {
      hasNoAnswer = true
    }
  })

  return (
    <CheckListWrapperDiv className={className}>
      <Typography color="gray-80" size="700-md" tag="h2">
        <FormattedMessage id="taxation.checkList.title" defaultMessage="Helyesek az adatok?" />
      </Typography>

      {questions.map(({ name, status, links, label, tooltip }, index) => (
        <CheckListItem
          key={`step-${index}`}
          className={cx({
            [CHECKLIST_CLASSES.disabled]: status === TaskStatus.DISABLED,
            [CHECKLIST_CLASSES.yes]: status === TaskStatus.YES,
            [CHECKLIST_CLASSES.no]: status === TaskStatus.NO,
            [CHECKLIST_CLASSES.active]: status === TaskStatus.TODO && Boolean(links.yes || links.no),
          })}
        >
          <QuestionWrapperDiv>
            <StepIndicator>
              {status === TaskStatus.YES ? (
                <CircleCheckSolidIcon size={20} />
              ) : status === TaskStatus.DISABLED || status === TaskStatus.NO ? (
                <XmarkRegularIcon size={10} />
              ) : (
                index + 1
              )}
            </StepIndicator>
            <Typography size="400-sm">
              {tooltip ? (
                <>
                  {label}&nbsp;
                  <LightTooltip
                    title={
                      <>
                        {tooltip.title && <b>{tooltip.title} </b>}
                        {tooltip.description}
                      </>
                    }
                    placement="top"
                  >
                    <StyledLabelInfoIcon />
                  </LightTooltip>
                </>
              ) : (
                label
              )}
            </Typography>
          </QuestionWrapperDiv>

          {(links.yes || links.no) && (
            <ButtonsContainerDiv>
              <ChecklistAsyncButton
                disabled={!links.yes || !!loading}
                loading={loading === `${name}-${AnswerChoices.YES}`}
                onClick={handleClick({ choice: `${name}-${AnswerChoices.YES}`, url: links.yes })}
                variant={
                  status === TaskStatus.TODO
                    ? 'primaryContained'
                    : status === TaskStatus.YES
                    ? 'primaryText'
                    : 'secondaryText'
                }
                $answered={status === TaskStatus.YES}
              >
                {OptionTrueMessage}
              </ChecklistAsyncButton>
              <ChecklistAsyncButton
                disabled={!links.no || !!loading}
                loading={loading === `${name}-${AnswerChoices.NO}`}
                onClick={handleClick({ choice: `${name}-${AnswerChoices.NO}`, url: links.no })}
                variant={
                  status === TaskStatus.TODO
                    ? 'secondaryContained'
                    : status === TaskStatus.YES
                    ? 'secondaryText'
                    : 'primaryText'
                }
                $answered={status === TaskStatus.NO}
              >
                {OptionFalseMessage}
              </ChecklistAsyncButton>
            </ButtonsContainerDiv>
          )}
        </CheckListItem>
      ))}
      {!isCompleted && hasNoAnswer && !hasTodoQuestion && (
        <CheckListInformationalMessage variant={informationalMessageVariant}>
          {errorMessage}
        </CheckListInformationalMessage>
      )}
    </CheckListWrapperDiv>
  )
}

TaxationCheckList.propTypes = {
  callUrl: PropTypes.func.isRequired,
  callUpdate: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.node.isRequired,
  informationalMessageVariant: PropTypes.oneOf(Object.values(INFORMATIONAL_MESSAGE_VARIANTS)),
  isCompleted: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      links: PropTypes.shape({
        yes: PropTypes.string,
        no: PropTypes.string,
      }).isRequired,
      tooltip: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string.isRequired,
      }),
    }).isRequired
  ).isRequired,
}
