import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Button } from '@components/ui/Buttons'
import { CustomDialogActions, CustomDialogBody, CustomDialogHeader } from '@components/ui/Dialogs'
import { Typography } from '@components/ui/Typography'

import { ALL_PAYMENT_TRANSACTION_TYPES } from '@constants/payments'

import { OkButtonMessage } from '@messages'
import { PaymentDialogTitleMessage } from './messages'

const StyledCustomDialogBody = styled(CustomDialogBody)`
  max-width: 600px;
`

interface NoTransactionsMessageProps {
  isEVCompany: boolean
  transactionType: AllPaymentTransactionType
}

function PureNoTransactionsMessage({ isEVCompany, transactionType }: NoTransactionsMessageProps) {
  if (transactionType === ALL_PAYMENT_TRANSACTION_TYPES.income) {
    if (isEVCompany) {
      return (
        <FormattedMessage
          id="paymentEditor.emptyDialog.income.evCompany.text"
          defaultMessage="A kiválasztott bevételek közül egyik sem rendezetlen pénzügyileg, vagy integrációból származik, ezért nem rögzíthető rá kifizetés."
        />
      )
    }
    return (
      <FormattedMessage
        id="paymentEditor.emptyDialog.income.text"
        defaultMessage="A kiválasztott bevételek közül egyik sem rendezetlen pénzügyileg, ezért nem rögzíthető rá kifizetés."
      />
    )
  }

  return (
    <FormattedMessage
      id="paymentEditor.emptyDialog.text"
      defaultMessage="A kiválasztott költségek közül egyik sem tartalmaz fizetendő hátralékot, hiányosan vannak kitöltve, NAV-os összerendelésre várnak, vagy duplikációk."
    />
  )
}

const NoTransactionsMessage = connect((state: Store) => ({
  isEVCompany: state.auth.company.data.company_type === 'ev',
}))(PureNoTransactionsMessage)

NoTransactionsMessage.displayName = 'NoTransactionsMessage'

interface PaymentNoTransactionsViewProps extends Pick<NoTransactionsMessageProps, 'transactionType'> {
  onClose: VoidFunction
}

export function PaymentNoTransactionsView({ onClose, transactionType }: PaymentNoTransactionsViewProps) {
  return (
    <>
      <CustomDialogHeader title={PaymentDialogTitleMessage} borderless />
      <StyledCustomDialogBody>
        <Typography align="center">
          <NoTransactionsMessage transactionType={transactionType} />
        </Typography>
      </StyledCustomDialogBody>
      <CustomDialogActions borderless>
        <Button onClick={onClose} variant="primaryContained">
          {OkButtonMessage}
        </Button>
      </CustomDialogActions>
    </>
  )
}
