import React from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { AutoKasszaIcon } from '@components/ui/svgIcons'

enum ClassKeys {
  FADED = 'faded',
}

const WrapperSpan = styled.span`
  position: relative;
  height: 20px;
  display: block;

  &.${ClassKeys.FADED} {
    opacity: 0.5;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${({ theme }) => theme.colors.gray[0]};
      mix-blend-mode: color;
    }
  }
`

interface PaidThroughSyncStatusProps {
  canBeEnabled: boolean
  className?: string
  syncEnabled: boolean
}

export function PaidThroughSyncStatus({ syncEnabled, canBeEnabled, className }: PaidThroughSyncStatusProps) {
  if (!canBeEnabled) {
    return null
  }

  return (
    <WrapperSpan className={cx({ [ClassKeys.FADED]: !syncEnabled }, className)}>
      <AutoKasszaIcon size={20} />
    </WrapperSpan>
  )
}
