import React from 'react'

import { connect } from 'react-redux'

import { EditPaymentFormData, EditPaymentPayload } from '@contexts/PaymentProvider'

import {
  Button,
  ReactHookForm,
  ReactHookFormDatePickerField,
  ReactHookFormError,
  ReactHookFormSubmitButton,
} from '@components/ui'

import { EXPRESS_PAID_THROUGH_NAME } from '@constants'

import { EditPaymentFormProps } from '../types'
import { BalanceAllTransactionsButton } from './BalanceAllTransactionsButton'
import { BinxInfoMessage } from './BinxInfoMessage'
import { PaidThroughField } from './fields'
import { PaidThroughBalance } from './PaidThroughBalance'
import { PaymentTransactionsFieldArrayTable } from './PaymentTransactionsFieldArrayTable'
import { TransferPaymentHeader } from './TransferPaymentHeader'
import { useTransformPaymentFormValidationSchema } from './validators'

import { CancelButtonMessage } from '@messages'
import { FormDialogBody } from '@components/FormDialog/styles'
import { DatePickerLabelMessage } from './messages'
import {
  PaymentDialogActions,
  PaymentDialogBodyWrapper,
  SameSizeButtonsContainer,
  StyledTransactionsTotalAmount,
} from './styles'

function useInitialValues(data: PaymentDetailsResponseData) {
  return React.useMemo<EditPaymentFormData>(() => {
    const { date, id, paidThroughId, transactions } = data
    return {
      date,
      id,
      paidThroughId,
      transactions: transactions.map(({ amount, beneficiaryAccountNumber, id }) => ({
        amount,
        accountNumber: beneficiaryAccountNumber,
        transactionId: id,
      })),
    }
  }, [data])
}

function PureEditTransferPaymentForm({
  data,
  data: { transactions, flow, transactionType },
  onClose,
  paidThroughOptions,
  onSubmit,
  onSubmitSuccess,
  skipStoreUpdate,
}: EditPaymentFormProps) {
  const validationSchema = useTransformPaymentFormValidationSchema()
  const initialValues = useInitialValues(data)

  function onSubmitHandler(values: EditPaymentFormData) {
    const payload: EditPaymentPayload = {
      ...values,
      paidThroughType: 2, // selectable paidThroughOptions are already filtered by this type - only bank type allowed here,
      skipStoreUpdate,
    }
    return onSubmit(payload)
  }

  return (
    <ReactHookForm
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      onSubmitSuccess={onSubmitSuccess}
      validationSchema={validationSchema}
      data-testid="edit-transfer-payment-form"
    >
      <FormDialogBody>
        <PaymentDialogBodyWrapper>
          <TransferPaymentHeader
            button={<BalanceAllTransactionsButton transactions={transactions} />}
            datepicker={<ReactHookFormDatePickerField name="date" label={DatePickerLabelMessage} required />}
            paidThroughSelect={<PaidThroughField options={paidThroughOptions} />}
            paidThroughBalance={<PaidThroughBalance />}
          />
          <BinxInfoMessage />
          <PaymentTransactionsFieldArrayTable
            flow={flow}
            transactions={transactions}
            transactionType={transactionType}
          />
          <StyledTransactionsTotalAmount transactions={transactions} />
        </PaymentDialogBodyWrapper>
        <PaymentDialogActions borderless>
          <ReactHookFormError />
          <SameSizeButtonsContainer>
            <Button onClick={onClose} variant="secondaryContained" type="button">
              {CancelButtonMessage}
            </Button>
            <ReactHookFormSubmitButton isCreateOnly />
          </SameSizeButtonsContainer>
        </PaymentDialogActions>
      </FormDialogBody>
    </ReactHookForm>
  )
}

export const EditTransferPaymentForm = connect((state: Store) => ({
  paidThroughOptions: state.payment.paidThroughOptions.filter(
    ({ name, paidthrough_type }) => paidthrough_type === 2 && name !== EXPRESS_PAID_THROUGH_NAME
  ), // only bank type allowed here,
}))(PureEditTransferPaymentForm)

EditTransferPaymentForm.displayName = 'EditTransferPaymentForm'
