import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import __find from 'lodash/find'
import { Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import { dashboardActions, incomeActions } from '@services'

import {
  bindActionToPromise,
  cancellablePromise,
  getDecimal,
  getMaximumFractionDigitsByCurrency,
  roundToDecimal,
} from '@helpers'
import { INCOME_TYPE_CASH_REGISTER_VOUCHER } from '@oldComponents/pages/DashboardIncomePage/helpers'

import { useCancellablePromiseRef } from '@hooks'

import {
  ButtonsContainerDiv,
  ReactHookFormExchangeRateFields,
  ReactHookFormTagField,
  TagIconContainerDiv,
} from '@components/forms/elements'
import {
  Button,
  FormBlock,
  FormBlockTitle,
  InfoIcon,
  ReactHookFieldArray,
  ReactHookForm,
  ReactHookFormAmountField,
  ReactHookFormDateField,
  ReactHookFormError,
  ReactHookFormSelectField,
  ReactHookFormSubmitButton,
  ReactHookFormTextField,
} from '@components/ui'
import { FakeInput, LightTooltip } from '@oldComponents/ui'
import { DeleteFunctionVisibilityControl } from '@oldComponents/VisibilityControls'

import { COMPANY_USER_FEATURE_PERMISSION_MAPPER as FEATURE_PERMISSIONS } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { validationEngine, ValidationErrors } from '../validationEngine'
import { AssignmentsTotalGrossAmount } from './AssignmentsTotalGrossAmount'
import { IncomeCashRegisterFormProps, IncomeCashRegisterFormValues } from './types'
import { useFormInitialValues } from './useFormInitialValues'
import useValidationSchema from './useValidationSchema'

import { formErrorMessages, InvoiceDateBlockTitleMessage } from '@messages'
import messages from '@components/forms/messages'
import { SPACING } from '@oldComponents/forms/DetailsForm/styles'

function calculateAmountsFromGrossAmount(
  { gross_amount, vat }: { vat: number; gross_amount: Decimal },
  companyVatOptions: CompanyVatType[],
  maximumFractionDigits: number
) {
  const percent = companyVatOptions.find(option => option.id === vat)?.percent || 0
  const grossAmount = Number(gross_amount) || 0
  const netAmount = grossAmount / (1 + percent / 100)

  return {
    net_amount: roundToDecimal(netAmount, { maximumFractionDigits, numeric: false }),
    vat_amount: roundToDecimal(grossAmount - netAmount, { maximumFractionDigits, numeric: false }),
  }
}

function calculateAmountsFromAssignments(assignments: IncomeDetailsAssignment[]) {
  const { grossAmount, netAmount, vatAmount } = assignments.reduce(
    (acc, curr) => {
      acc.grossAmount += Number(curr.gross_amount) || 0
      acc.netAmount += Number(curr.net_amount) || 0
      acc.vatAmount += Number(curr.vat_amount) || 0

      return acc
    },
    { grossAmount: 0, netAmount: 0, vatAmount: 0 }
  )
  return {
    gross_amount: getDecimal(grossAmount),
    net_amount: getDecimal(netAmount),
    vat_amount: getDecimal(vatAmount),
  }
}

function PureIncomeCashRegisterForm({
  callInvoiceNumberCheck,
  companyVatOptions,
  currencyOptions,
  defaultCurrencyId,
  deleteIncome,
  invoiceDetails,
  isEditDisabled,
  isTagsDisabledForUser,
  onSubmit,
  onSubmitSuccess,
  preventSubmitSuccess,
  readOnly,
  skipUnsavedChanges,
  uploading,
}: IncomeCashRegisterFormProps) {
  const { formatMessage } = useIntl()
  const validationSchema = useValidationSchema(defaultCurrencyId)
  const initialValues = useFormInitialValues(invoiceDetails, defaultCurrencyId, companyVatOptions)
  const cPromiseRef = useCancellablePromiseRef()

  //* Validator:invoiceNumber
  const invoiceNumberValidator = React.useCallback(
    async ({ invoice_number }: IncomeDetailsFormInitialValues) => {
      const errors = {} as ValidationErrors<IncomeDetailsFormInitialValues>
      if (invoice_number) {
        try {
          cPromiseRef.current = cancellablePromise(
            callInvoiceNumberCheck({
              id: invoiceDetails.id,
              invoice_number,
              income_type: INCOME_TYPE_CASH_REGISTER_VOUCHER,
            })
          )
          const { result } = await cPromiseRef.current.promise
          if (!result) {
            errors['invoice_number'] = formatMessage(formErrorMessages.isUniqueInvoiceNumber)
          }
        } catch (err) {
          // do nothing
        }
      }
      return errors
    },
    [cPromiseRef, callInvoiceNumberCheck, formatMessage, invoiceDetails.id]
  )

  // before submit we have to calculate amounts
  const handleSubmit = React.useCallback(
    (values: IncomeCashRegisterFormValues) => {
      const fractionDigits = getMaximumFractionDigitsByCurrency(values.currency)

      const extendedAssignments = values.assignments.map(assignment => ({
        ...assignment,
        ...calculateAmountsFromGrossAmount(assignment, companyVatOptions, fractionDigits),
      })) as IncomeDetailsAssignment[]

      const calculatedAmounts = calculateAmountsFromAssignments(extendedAssignments)
      // checkEmptyValues helper usage is not needed here, because there aren't any clearable fields in the form
      const extendedValues = {
        ...values,
        ...calculatedAmounts,
        assignments: extendedAssignments,
      } as IncomeDetailsFormInitialValues

      return validationEngine([invoiceNumberValidator], onSubmit, extendedValues)
    },
    [companyVatOptions, invoiceNumberValidator, onSubmit]
  )

  function renderVat(value: number) {
    const vatOption = __find(companyVatOptions, ['id', value])
    if (!vatOption) {
      throw Error('Invalid vat ID')
    }
    return <div>{vatOption.name || `${vatOption.percent}%`}</div>
  }

  const isAllFieldDisabled = readOnly || isEditDisabled || uploading

  return (
    <ReactHookForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSubmitSuccess={onSubmitSuccess}
      preventSubmitSuccess={preventSubmitSuccess}
      skipUnsavedChanges={skipUnsavedChanges}
      validationSchema={validationSchema}
    >
      <FormBlock>
        <Grid container spacing={SPACING}>
          <Grid item xs={4}>
            <ReactHookFormTextField
              disabled={isAllFieldDisabled}
              label={formatMessage(messages.voucherNumberLabel)}
              name="invoice_number"
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <ReactHookFormTagField
              disabled={isTagsDisabledForUser || isAllFieldDisabled}
              label={formatMessage(messages.itemSimpleTagsLabel)}
              name="simple_tags"
            />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormTagField
              disabled={isTagsDisabledForUser || isAllFieldDisabled}
              name="tags"
              label={
                <LightTooltip title={formatMessage(messages.smartTagTooltip)} placement="top">
                  <TagIconContainerDiv>
                    {formatMessage(messages.itemTagsLabel)}
                    <InfoIcon size={16} />
                  </TagIconContainerDiv>
                </LightTooltip>
              }
              selectVariant="purple"
            />
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <FormBlockTitle>{InvoiceDateBlockTitleMessage}</FormBlockTitle>
        <Grid container spacing={SPACING}>
          <Grid item xs={4}>
            <ReactHookFormDateField
              disabled={isAllFieldDisabled}
              label={formatMessage(messages.fulfilledDateLabel)}
              name="fulfilled_at"
              placeholder={formatMessage(messages.dateFieldPlaceholder)}
              required
            />
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <FormBlockTitle>
          <FormattedMessage id="incomeManual.heading.grossAmount" defaultMessage="Bruttó összeg" />
        </FormBlockTitle>
        <Grid container spacing={SPACING}>
          <Grid item xs={4}>
            <ReactHookFormSelectField
              disabled={isAllFieldDisabled}
              label={formatMessage(messages.currencyLabel)}
              labelKey="name"
              name="currency"
              options={currencyOptions}
              valueKey="id"
            />
          </Grid>
          <ReactHookFormExchangeRateFields
            defaultCurrencyId={defaultCurrencyId}
            disabled={isAllFieldDisabled}
            invoiceDetailsId={invoiceDetails?.id}
          />
        </Grid>
      </FormBlock>

      <ReactHookFieldArray
        name="assignments"
        render={({ fields }) => (
          <>
            {fields.map((field, index) => (
              <Grid container spacing={SPACING} key={`assignment-${field.id}`}>
                <Grid item xs={6}>
                  <Controller
                    name={`assignments.${index}.vat`}
                    render={({ field: { value } }) => (
                      <FakeInput
                        placeholder={renderVat(value)}
                        noUpsellMessage
                        label={
                          <LightTooltip
                            title={
                              <FormattedMessage
                                id="incomeManual.tooltip.vat"
                                defaultMessage="Az itt megjelenő ÁFA kulcsokat a cég beállításaiban tudod szerkeszteni"
                              />
                            }
                            placement="top"
                          >
                            <TagIconContainerDiv>
                              {formatMessage(messages.vatRateLabel)}
                              <InfoIcon size={16} />
                            </TagIconContainerDiv>
                          </LightTooltip>
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ReactHookFormAmountField
                    disabled={isAllFieldDisabled}
                    name={`assignments.${index}.gross_amount`}
                    label={formatMessage(messages.grossAmountLabel)}
                    required
                  />
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={SPACING}>
              <Grid item xs={12}>
                <AssignmentsTotalGrossAmount currencyOptions={currencyOptions} />
              </Grid>
            </Grid>
          </>
        )}
      />
      <ReactHookFormError hideIcon />
      <ButtonsContainerDiv>
        <ReactHookFormSubmitButton
          hasDisabledState
          initialValues={initialValues}
          isCreateOnly={!invoiceDetails.id}
          lastUpdated={invoiceDetails.user_updated}
          needConfirmOnReset
        />

        {!isEditDisabled && Boolean(invoiceDetails.id) && (
          <DeleteFunctionVisibilityControl>
            <Button variant="secondaryContained" onClick={deleteIncome}>
              <FormattedMessage id="buttons.incomeDetails.removeIncome" defaultMessage="Bevétel törlése" />
            </Button>
          </DeleteFunctionVisibilityControl>
        )}
      </ButtonsContainerDiv>
    </ReactHookForm>
  )
}

PureIncomeCashRegisterForm.propTypes = {
  callInvoiceNumberCheck: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
  companyVatOptions: PropTypes.arrayOf(
    PropTypes.shape({
      cash_register_voucher_visibility: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      percent: PropTypes.number as React.Validator<Nullable<number>>,
    }).isRequired
  ).isRequired,
  createNewTag: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  deleteIncome: PropTypes.func.isRequired,
  invoiceDetails: PropTypes.object.isRequired as React.Validator<IncomeDetailsFrontendValues>,
  isEditDisabled: PropTypes.bool.isRequired,
  isTagsDisabledForUser: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  preventSubmitSuccess: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  skipUnsavedChanges: PropTypes.bool.isRequired,
  tagOptions: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  uploading: PropTypes.bool.isRequired,
}

function mapStateToProps(state: Store) {
  return {
    company: state.auth.company.data.id,
    companyVatOptions: state.auth.companyVats.data,
    currencyOptions: state.dashboard.common.currencies,
    defaultCurrencyId: state.auth.company.data.default_currency,
    invoiceDetails: state.income.details.data,
    isTagsDisabledForUser: permissionDeniedForUser(state, FEATURE_PERMISSIONS.tag),
    tagOptions: state.dashboard.tags.data,
    uploading: state.income.details.uploading,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return {
    callInvoiceNumberCheck: bindActionToPromise(dispatch, incomeActions.invoiceNumberCheck.request),
    createNewTag: bindActionToPromise(dispatch, dashboardActions.createTag.request),
  }
}

export const IncomeCashRegisterForm = connect(mapStateToProps, mapDispatchToProps)(PureIncomeCashRegisterForm)

IncomeCashRegisterForm.displayName = 'IncomeCashRegisterForm'
