import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Typography } from '@components/ui'

import { INVOICE_ORIGINS } from '@constants/sources'

import { getSourceName } from './helper'
import { NavHeader } from './NavHeader'

import { ReasonBlockWrapperDiv } from './styles'

function getContent({ origin, hasArtifact }: { origin: InvoiceOrigin; hasArtifact: boolean }) {
  if (origin === INVOICE_ORIGINS.NOSZ) {
    if (hasArtifact) {
      return (
        <FormattedMessage
          id="invoice.nav.nosz.heading.withArtifact"
          defaultMessage="A számla adatait a NAV Online Számla rendszeréből importáltuk."
        />
      )
    }
    return (
      <FormattedMessage
        id="invoice.nav.nosz.heading.noArtifact"
        defaultMessage="A számla adatait a NAV Online Számla rendszeréből importáltuk. Hogy kényelmesebb legyen az adatok olvasása, számlaképet generáltunk hozzá. Ha szeretnéd a tényleges számlát csatolni helyette, a bal oldalon megteheted."
      />
    )
  }

  if (hasArtifact) {
    return (
      <FormattedMessage
        id="invoice.nav.heading.withArtifact"
        defaultMessage="A számla adatait a {source} importálta a NAV rendszerén keresztül."
        values={{ source: getSourceName(origin) }}
      />
    )
  }
  return (
    <FormattedMessage
      id="invoice.nav.heading.noArtifact"
      defaultMessage="A számla adatait a {source} importálta a NAV rendszerén keresztül. Hogy kényelmesebb legyen az adatok olvasása, számlaképet generáltunk hozzá. Ha szeretnéd a tényleges számlát csatolni helyette, a bal oldalon megteheted."
      values={{ source: getSourceName(origin) }}
    />
  )
}

interface NavPreviewBlockProps {
  origin: InvoiceOrigin
  hasArtifact: boolean
}

export function NavPreviewBlock({ origin, hasArtifact }: NavPreviewBlockProps) {
  return (
    <ReasonBlockWrapperDiv>
      <NavHeader origin={origin} />
      <Typography align="center">{getContent({ origin, hasArtifact })}</Typography>
    </ReasonBlockWrapperDiv>
  )
}
