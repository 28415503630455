import React from 'react'

import { useIntl } from 'react-intl'

import { formSelectMessages } from '@messages'

/**
 * Hook for create a custom promptTextCreator function for creatable select
 * @returns promptTextCreator function
 */
export function usePromptTextCreator() {
  const { formatMessage } = useIntl()
  return React.useCallback(
    (value: string) => `${formatMessage(formSelectMessages.selectOnCreate)}: "${value}"`,
    [formatMessage]
  )
}
