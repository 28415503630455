import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Typography } from '@components/ui'

import { FeatureListItem } from './FeatureListItem'
import { FEATURES } from './features'

const StyledList = styled.ul`
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
`

const FeatureListWrapper = styled.div`
  display: inline-block;
  margin-bottom: 32px;
`

interface FeatureListProps {
  className?: string
  isExcluded?: boolean
  tier: string
  withoutTitle?: boolean
}

export function FeatureList({ className, isExcluded, tier, withoutTitle = false }: FeatureListProps) {
  const { features, title } = FEATURES[tier]
  return (
    <FeatureListWrapper className={className}>
      {!withoutTitle && (
        <Typography size="700-sm" color="gray-80">
          {isExcluded ? (
            <FormattedMessage
              id="subscription.featureList.excludedTitle"
              defaultMessage="Új csomagod nem tartalmazza ezeket a funkciókat:"
            />
          ) : (
            title
          )}
        </Typography>
      )}
      <StyledList>
        {features.map((feature, index) => (
          <FeatureListItem key={`${tier}-feature-${index}`} isExcluded={isExcluded}>
            {feature}
          </FeatureListItem>
        ))}
      </StyledList>
    </FeatureListWrapper>
  )
}
