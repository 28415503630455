import React from 'react'

import { ZoomOrientation, ZoomState } from '@components/DocViewer/constant'

import { ZoomControls } from '../ZoomControls'
import { changeZoomOrientation, setZoomLevel } from './actions'
import { PaginationControls } from './PaginationControls'
import { PDFControlsContext } from './PDFControlsProvider'
import { getDefaultZoomLevel, getZoomResetState } from './zoom'

import { LandscapeViewMessage, PortraitViewMessage, ResetZoomMessage } from '@components/DocViewer/messages'

const ZOOM_STATE_MESSAGES = {
  [ZoomState.RESET]: ResetZoomMessage,
  [ZoomState.CHANGE_TO_LANDSCAPE]: LandscapeViewMessage,
  [ZoomState.CHANGE_TO_PORTRAIT]: PortraitViewMessage,
}

export function PDFControls() {
  const {
    state: { zoomLevel, zoomJump, defaultZoomLevel, zoomOrientation, mainState, paginated },
    dispatch,
  } = React.useContext(PDFControlsContext)

  function handleZoomIn() {
    dispatch(setZoomLevel(zoomLevel + zoomJump))
  }

  function handleZoomOut() {
    dispatch(setZoomLevel(zoomLevel - zoomJump))
  }

  function handleZoomReset() {
    // when zoomLevel is modified in any orientation reset it
    if (zoomLevel !== defaultZoomLevel) {
      dispatch(setZoomLevel(defaultZoomLevel))
    } else {
      // change orientation
      const newZoomOrientation =
        zoomOrientation === ZoomOrientation.LANDSCAPE ? ZoomOrientation.PORTRAIT : ZoomOrientation.LANDSCAPE
      dispatch(
        changeZoomOrientation({
          defaultZoomLevel: getDefaultZoomLevel(newZoomOrientation, mainState),
          zoomOrientation: newZoomOrientation,
        })
      )
    }
  }

  const zoomResetState = getZoomResetState({ zoomOrientation, zoomLevel, defaultZoomLevel })

  return (
    <>
      <ZoomControls
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleZoomReset={handleZoomReset}
        zoomResetTitle={ZOOM_STATE_MESSAGES[zoomResetState]}
      />
      {paginated && <PaginationControls />}
    </>
  )
}
