import React from 'react'

import cx from 'classnames'
import ReactModal, { Props as ModalProps } from 'react-modal'
import styled from 'styled-components'

import { applyOpacity } from '@helpers'

import { DIALOG_CLASS_NAMES, DIALOG_CLOSING_TIMEOUT } from '@constants'

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#portal')
}

const BaseDialogOverlay = styled.div`
  &.${DIALOG_CLASS_NAMES.overlay.base} {
    align-items: center;
    background-color: ${({ theme }) => applyOpacity(theme.colors.gray[100], 50)};
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity ${DIALOG_CLOSING_TIMEOUT}ms ease-in-out;
    z-index: ${({ theme }) => theme.dialogZIndex};
  }

  &.${DIALOG_CLASS_NAMES.overlay.afterOpen} {
    opacity: 1;
  }

  &.${DIALOG_CLASS_NAMES.overlay.beforeClose} {
    opacity: 0;
  }
`

const BaseDialogContent = styled.div`
  &.${DIALOG_CLASS_NAMES.content.base} {
    scroll-behavior: smooth;
    outline: none;
    background-color: ${({ theme }) => theme.colors.gray[0]};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
    box-shadow: ${({ theme }) => theme.shadows[50]};
    overflow: auto;
    max-width: min(calc(100vw - 20px * 2), 1600px);
    max-height: calc(100vh - 52px * 2);
  }

  &.${DIALOG_CLASS_NAMES.content.afterOpen} {
    /* future place for any transition effect */
  }

  &.${DIALOG_CLASS_NAMES.content.beforeClose} {
    /* future place for any transition effect */
  }

  &.${DIALOG_CLASS_NAMES.prompt} {
    min-height: 200px;
    justify-content: space-between;
  }
`

interface BaseDialogProps extends Omit<ModalProps, 'className'> {
  className?: string
  stickyTopOffset?: number
  stickyBottomOffset?: number
}

/**
 * The project defined base Dialog component to use with predefined base stylings
 * Usage documentation: https://reactcommunity.org/react-modal/
 */
export function BaseDialog({ className, stickyBottomOffset, stickyTopOffset, ...restProps }: BaseDialogProps) {
  return (
    <ReactModal
      {...restProps}
      portalClassName={DIALOG_CLASS_NAMES.portal}
      overlayClassName={DIALOG_CLASS_NAMES.overlay}
      className={{
        ...DIALOG_CLASS_NAMES.content,
        base: cx(DIALOG_CLASS_NAMES.content.base, className),
      }}
      bodyOpenClassName={DIALOG_CLASS_NAMES.body}
      htmlOpenClassName={DIALOG_CLASS_NAMES.html}
      contentElement={(props, children) => (
        <BaseDialogContent
          {...props}
          style={{
            ...props.style,
            '--scroll-padding-top': stickyTopOffset,
            '--scroll-padding-bottom': stickyBottomOffset,
          }}
        >
          {children}
        </BaseDialogContent>
      )}
      overlayElement={(props, children) => <BaseDialogOverlay {...props}>{children}</BaseDialogOverlay>}
      closeTimeoutMS={DIALOG_CLOSING_TIMEOUT}
    />
  )
}
