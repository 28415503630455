import styled from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { ExpandIconButton, TableBodyRow } from '../../styles'

// a bit of a fake animation still applied like this
// TODO: finalize animation
export const ExpandedTableRow = styled.tr`
  & > td {
    max-height: 0;
    overflow: hidden;
    transition: all 250ms ease;
    padding: 0;
  }

  &[aria-hidden='false'] > td {
    max-height: 99999px;
  }
`

export const ExpandableTableBodyRow = styled(TableBodyRow)`
  &:hover ${ExpandIconButton}:not(:disabled) {
    background-color: ${({ theme }) => applyOpacity(theme.colors.gray[110], 70)};
  }
`
