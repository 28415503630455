import React from 'react'

import styled from 'styled-components'

function BaseLockIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M11.75 3.875A3.124 3.124 0 0 1 14.875 7v2.5h-6.25V7a3.124 3.124 0 0 1 3.125-3.125ZM6.75 7v2.5H5.5A2.502 2.502 0 0 0 3 12v7.5C3 20.879 4.121 22 5.5 22H18c1.379 0 2.5-1.121 2.5-2.5V12c0-1.379-1.121-2.5-2.5-2.5h-1.25V7a5 5 0 1 0-10 0ZM5.5 11.375H18c.344 0 .625.281.625.625v7.5a.627.627 0 0 1-.625.625H5.5a.627.627 0 0 1-.625-.625V12c0-.344.281-.625.625-.625Zm7.188 3.125a.935.935 0 0 0-.938-.938.935.935 0 0 0-.938.938V17c0 .52.418.938.938.938s.938-.418.938-.938v-2.5Z"
      />
    </svg>
  )
}

export const LockIcon = styled(BaseLockIcon)``
