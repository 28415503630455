import styled, { css } from 'styled-components'

import { ButtonBase } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'
import { FlexRow } from '@components/ui/Wrappers'

export const FormBlock = styled.div`
  margin: 0 -1rem;
  padding: 1rem;

  & .form-control {
    margin-top: 6px;
    margin-bottom: 0;
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.gray[110]};
  }
`

export const FormBlockTitle = styled(Typography).attrs({
  color: 'gray-80',
  size: 'heading-6',
})`
  margin-top: 0;
  margin-bottom: -10px;
`

export const ButtonsContainerDiv = styled(FlexRow)`
  gap: 20px;
`

export const SelectableCardButton = styled(ButtonBase)<{ $isSelected: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.gray[40]};
  border-radius: 8px;
  padding: 12px 12px 12px 40px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex-wrap: wrap;
  white-space: normal;
  background-color: transparent;
  position: relative;

  &:hover,
  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.colors.primary[30]};
  }

  &:before {
    border-radius: 9px;
  }

  &:hover:after {
    border-color: ${({ theme }) => theme.colors.gray[30]};
  }

  &:focus {
    &:before {
      border-color: ${({ theme }) => theme.colors.primary[20]};
    }
    &:after {
      border-color: ${({ theme }) => theme.colors.primary[30]};
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 16px;
    left: 12px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.gray[40]};
    transition: border-width linear 200ms;
  }

  ${({ theme, $isSelected }) =>
    $isSelected &&
    css`
      border-color: ${theme.colors.primary[50]};

      &:focus:hover {
        border-color: ${theme.colors.primary[40]};
      }
      &:after {
        border-width: 5px;
        border-color: ${theme.colors.primary[50]};
      }
      &:focus:after,
      &:hover:after {
        border-color: ${theme.colors.primary[50]};
      }
    `}
`
