// TODO should transform into enum
export const DATA_TABS = {
  dokuments: 'dokuments',
  expense: 'expense',
  income: 'income',
  quarantine: 'quarantine',
  salary: 'salary',
  tax: 'tax',
} as const

export const TABS_CLASS_NAMES = {
  disabledTabClassName: 'disabled',
  selectedTabClassName: 'selected',
  selectedTabPanelClassName: 'selected',
}
