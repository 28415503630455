import { AxiosError } from 'axios'
import __isArray from 'lodash/isArray'
import __isObject from 'lodash/isObject'

/**
 * Helper function to parse error messages. It returns `null` if error is a cancelled promise
 *
 * @param {unknown} error - the error in catch
 * @returns {(string | null)} returns string if it is a valid error message, otherwise `null`
 */
export function parseApiErrorMessage(error: unknown): string | null {
  if (typeof error === 'string') {
    return error
  } else if (__isArray(error)) {
    return error[0]
  } else if (__isObject(error) && !Object.prototype.hasOwnProperty.call(error, 'isCanceled')) {
    return (error as Error).message
  } else {
    return null
  }
}

export function parseApiErrorWithConsent(error: unknown): ErrorWithConsent['isAllowedOrNull'] {
  if (__isObject(error) && Object.prototype.hasOwnProperty.call(error, 'isAllowedOrNull')) {
    return (error as ErrorWithConsent).isAllowedOrNull
  }
  return null
}

function isFileUploadError(error: unknown): error is FileUploadError {
  return (error as FileUploadError).files !== undefined
}

export function parseFileUploadErrors(error: unknown) {
  if (__isObject(error) && !Object.prototype.hasOwnProperty.call(error, 'isCanceled')) {
    // AxiosError extends Error
    if (error instanceof AxiosError) {
      const errorResponseData = error.response?.data
      if (errorResponseData && isFileUploadError(errorResponseData)) {
        if (__isArray(errorResponseData.files)) {
          return errorResponseData.files[0]
        }
        return errorResponseData.files
      }
    }
    if (error instanceof Error) {
      return error.message
    }
  }
  return null
}
