import moment from 'moment'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

import { IncomeDetailsFormValues } from './types'

import { formErrorMessages } from '@messages'

export default function useValidationSchema(defaultCurrencyId: number): yup.SchemaOf<IncomeDetailsFormValues> {
  const { formatMessage } = useIntl()

  const exchangeRateSchema = yup
    .string()
    .test(
      'valid_exchange_rate',
      formatMessage(formErrorMessages.greaterThan, {
        number: 0,
      }),
      value => !value || Number(value) > 0
    )
    .when(['currency'], {
      is: (currency: number | string) => currency != defaultCurrencyId,
      then: yup
        .string()
        .test(
          'valid_exchange_rate_non_one',
          formatMessage(formErrorMessages.invalidExchangeRateError),
          value => !value || Number(value) !== 1
        )
        .required(formatMessage(formErrorMessages.required)),
    })
    .required(formatMessage(formErrorMessages.required))

  const exchangeDateSchema = yup
    .string()
    .nullable()
    .test(
      'valid_date',
      formatMessage(formErrorMessages.invalidDate),
      value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
    )
    .when(['currency'], {
      is: (currency: number | string) => currency != defaultCurrencyId,
      then: yup.string().required(formatMessage(formErrorMessages.required)),
    })

  const incomeDetailsAssignmentSchema: yup.SchemaOf<IncomeDetailsAssignment> = yup.object({
    vat: yup.number().required(formatMessage(formErrorMessages.required)),
    gross_amount: yup.string().required(formatMessage(formErrorMessages.required)),
    net_amount: yup.string().required(formatMessage(formErrorMessages.required)),
    vat_amount: yup.string().required(formatMessage(formErrorMessages.required)),
    id: yup.number(),
    revenue_type: yup.number().nullable(),
    tags: yup.array().of(yup.number().required()).required(),
  })

  return yup.object({
    accounting_period_start: yup
      .string()
      .nullable()
      .test(
        'valid_date',
        formatMessage(formErrorMessages.invalidDate),
        value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
      )
      .when(['need_accounting_period'], {
        is: (value: boolean) => value,
        then: yup.string().nullable().required(formatMessage(formErrorMessages.required)),
      }),
    accounting_period_end: yup
      .string()
      .nullable()
      .test(
        'valid_date',
        formatMessage(formErrorMessages.invalidDate),
        value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
      )
      .when(['need_accounting_period'], {
        is: (value: boolean) => value,
        then: yup.string().nullable().required(formatMessage(formErrorMessages.required)),
      }),
    fulfilled_at: yup
      .string()
      .required(formatMessage(formErrorMessages.required))
      .test(
        'valid_date',
        formatMessage(formErrorMessages.invalidDate),
        value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
      ),
    issued_at: yup
      .string()
      .required(formatMessage(formErrorMessages.required))
      .test(
        'valid_date',
        formatMessage(formErrorMessages.invalidDate),
        value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
      ),
    due_at: yup
      .string()
      .required(formatMessage(formErrorMessages.required))
      .test(
        'valid_date',
        formatMessage(formErrorMessages.invalidDate),
        value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
      ),
    planned_payment_date: yup
      .string()
      .nullable()
      .test(
        'valid_date',
        formatMessage(formErrorMessages.invalidDate),
        value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
      )
      .when(['reschedule_payment'], {
        is: (value: boolean) => value,
        then: yup.string().nullable().required(formatMessage(formErrorMessages.required)),
      }),
    currency: yup.number().required(formatMessage(formErrorMessages.required)),
    exchange_rate: exchangeRateSchema,
    exchange_date: exchangeDateSchema,
    simple_tags: yup.array().required(),
    tags: yup.array().required(),
    partner_id: yup.mixed<ItemIdType>().required(formatMessage(formErrorMessages.required)), // TODO check if it is required and why mixed
    partner_name: yup.string().required(formatMessage(formErrorMessages.required)),
    partner_account_number: yup.string().nullable(),
    partner_address: yup.string().nullable(),
    partner_city: yup.string().nullable(),
    partner_country: yup.string().nullable(),
    partner_tax_number: yup.string().nullable(),
    partner_zip_code: yup.string().nullable(),
    payment_method: yup.string().nullable(),
    paid_through: yup
      .number()
      .nullable()
      .when(['payment_method'], {
        is: (payment_method: string) => payment_method === 'card',
        then: yup.number().nullable().required(formatMessage(formErrorMessages.required)),
      }),
    gross_amount: yup.string().required(formatMessage(formErrorMessages.required)),
    invoice_number: yup.string().nullable(),
    vat_area: yup.string().nullable(),
    assignments: yup
      .array()
      .of(incomeDetailsAssignmentSchema)
      .min(1, formatMessage(formErrorMessages.noAssignmentsError)),
    secondary_id: yup.string().nullable(),
    //* non visible fields
    id: yup.mixed<ItemIdType>(),
    income_type: yup.number().required(),
    need_accounting_period: yup.boolean(),
    reschedule_payment: yup.boolean(),
  })
}
