import { FormRenderProps } from 'react-final-form'
import { Props as ModalProps } from 'react-modal'

import { EditorStateProps } from '@hooks'

import { PaidThroughType } from '@constants'

export enum PaidThroughDialogVariant {
  LIST_ITEM = 'list_item',
  SELECT_OPTION = 'select_option',
}

export type PaidThroughFormValues = {
  account_number?: string
  add_new_balance: boolean
  balance?: Nullable<string>
  currency?: Nullable<number>
  id?: number
  is_autokassza: boolean
  ledger_number: Nullable<LedgerNumber>
  name: string
  provider: Nullable<number>
  paidthrough_type: Nullable<PaidThroughType>
  value_date?: Nullable<string>
}

export interface PurePaidThroughEditorFormProps
  extends FormRenderProps<PaidThroughFormValues, Partial<PaidThroughFormValues> | null> {
  aria: Required<ModalProps>['aria']
  currencies: Currency[]
  bankProviders: BankProvider[]
  isEdit: boolean
  onClose: (event?: React.MouseEvent<HTMLElement>) => void
  detailsData?: PaidThroughDetailData | null
  isAdvancedAccountingAvailableForUser: boolean
  isBankTransactionsAllowed: boolean
}

export interface PurePaidThroughEditorDialogProps {
  callCreateListItem: AsyncFunction<PaidThroughFormValues, PaidThroughDetailData>
  callCreateSelectOption: AsyncFunction<PaidThroughFormValues, PaidThroughDetailData>
  callUpdate: AsyncFunction<PaidThroughFormValues, PaidThroughDetailData>
  editor: EditorStateProps<number, { name: string }>
  loadDetails: AsyncFunction<number, PaidThroughDetailData>
  onClose: VoidFunction
  onSubmitSuccess?: (results: PaidThroughDetailData) => void
  variant?: PaidThroughDialogVariant
}
