import React from 'react'

import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'
import { FlexColumn } from '@components/ui/Wrappers'

import { GeneratedPreviewViewerContext } from '../GeneratedPreviewViewerProvider'
import { ScalableSvgPreview } from './ScalableSvgPreview'

const InvoicePreviewLoaderWrapper = styled(FlexColumn)`
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
`

export function InvoicePreviewRenderer() {
  const { data, error, loading, previewVariant, rendererRect, renderPostItLayer } =
    React.useContext(GeneratedPreviewViewerContext)

  if (!loading && error) {
    return (
      <InvoicePreviewLoaderWrapper>
        <Typography color="error">{error}</Typography>
      </InvoicePreviewLoaderWrapper>
    )
  }

  return (
    <>
      {loading && (
        <InvoicePreviewLoaderWrapper>
          <CircularProgress size={30} />
        </InvoicePreviewLoaderWrapper>
      )}
      {data && (
        <ScalableSvgPreview
          data={data}
          rendererRect={rendererRect}
          renderPostItLayer={renderPostItLayer}
          variant={previewVariant}
        />
      )}
    </>
  )
}
