import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

interface CustomerInfoProps {
  className: string
  partnerName: string
  partnerCity: string
  partnerStreet: string
  partnerTaxNo: string
}

export function CustomerInfo({ className, partnerName, partnerCity, partnerStreet, partnerTaxNo }: CustomerInfoProps) {
  return (
    <>
      <Typography variant="body1" className={className}>
        <strong>{partnerName}</strong>
      </Typography>
      <Typography variant="body1" className={className}>
        {partnerCity}
      </Typography>
      <Typography variant="body1" className={className}>
        {partnerStreet}
      </Typography>
      <Typography variant="body1" className={className}>
        {partnerTaxNo}
      </Typography>
    </>
  )
}

CustomerInfo.propTypes = {
  className: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  partnerCity: PropTypes.string.isRequired,
  partnerStreet: PropTypes.string.isRequired,
  partnerTaxNo: PropTypes.string.isRequired,
}
