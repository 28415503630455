import { InvoiceType } from '@constants'

// Expense kontir - invoice_class === 0
const EXPENSE_LEDGER_NUMBER_SUGGESTIONS = {
  HU: {
    code: '4541',
    name: 'Szállító belföld végösszeg',
  },
  EU: {
    code: '4542',
    name: 'Szállító eu végösszeg',
  },
  '3RD': {
    code: '4543',
    name: 'Szállító 3.o. végösszeg',
  },
}

const INCOME_LEDGER_NUMBER_SUGGESTIONS = {
  HU: {
    code: '311',
    name: 'Vevő belföld végösszeg',
  },
  EU: {
    code: '317',
    name: 'Vevő EU végösszeg',
  },
  '3RD': {
    code: '318',
    name: 'Vevő 3.o. végösszeg',
  },
}

const EXPENSE_VAT_LEDGER_NUMBER = {
  code: '466',
  name: 'Előzetesen felszámított ÁFA',
}

const INCOME_VAT_LEDGER_NUMBER = {
  code: '467',
  name: 'Fizetendő ÁFA',
}

export const MAIN_LEDGER_NUMBER_SUGGESTIONS: Record<InvoiceType, Record<string, LedgerNumberFormValue>> = {
  [InvoiceType.EXPENSE]: EXPENSE_LEDGER_NUMBER_SUGGESTIONS,
  [InvoiceType.INCOME]: INCOME_LEDGER_NUMBER_SUGGESTIONS,
}

export const VAT_LEDGER_NUMBER_SUGGESTION: Record<InvoiceType, LedgerNumberFormValue> = {
  [InvoiceType.EXPENSE]: EXPENSE_VAT_LEDGER_NUMBER,
  [InvoiceType.INCOME]: INCOME_VAT_LEDGER_NUMBER,
}
