import React from 'react'

import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { partnersActions } from '@services/index'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers/index'

import { useAlertDispatch } from '@contexts/index'

import { useCancellablePromiseRef } from '@hooks/index'

import { AsyncButton } from '@components/ui'
import { PartnerData } from '@oldComponents/PartnerEditor/types'

interface CopyPartnerDetailsButtonProps {
  fetchPartnerDetails: AsyncFunction<number, PartnerData>
}

function PureCopyPartnerDetailsButton({ fetchPartnerDetails }: CopyPartnerDetailsButtonProps) {
  const cPromiseRef = useCancellablePromiseRef()
  const { setErrorAlert } = useAlertDispatch()
  const [isCopyDone, setCopyDone] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)

  const { getValues, setValue } = useForm<InvoiceDetailsFormInitialValues>()
  const partnerId = getValues('partner_id')

  async function copyPartnerDetails() {
    if (partnerId) {
      setLoading(true)
      try {
        cPromiseRef.current = cancellablePromise(fetchPartnerDetails(partnerId))
        const results = await cPromiseRef.current.promise
        setCopyDone(true)
        setValue('partner_account_number', results.account_number ?? '')
        setValue('partner_address', results.address ?? '')
        setValue('partner_city', results.city ?? '')
        setValue('partner_country', results.country ?? '')
        setValue('partner_name', results.name ?? '')
        setValue('partner_tax_number', results.tax_number ?? '') // TODO formatted value, should we keep or parse it?
        setValue('partner_zip_code', results.zip_code ?? '')
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          console.error(`Failed to fetch partner details for ID: ${partnerId}`)
          setErrorAlert(errorMessage)
        }
      }
      setLoading(false)
    }
  }

  return (
    <AsyncButton
      loading={isLoading}
      variant="primaryText"
      size="small"
      onClick={copyPartnerDetails}
      disabled={isCopyDone || !partnerId}
    >
      <FormattedMessage id="form.partnerBlock.copyDetails" defaultMessage="Partnertörzs másolása" />
    </AsyncButton>
  )
}

export const CopyPartnerDetailsButton = connect(null, dispatch => ({
  fetchPartnerDetails: bindActionToPromise(dispatch, partnersActions.fetchPartnerDetails.request),
}))(PureCopyPartnerDetailsButton)

CopyPartnerDetailsButton.displayName = 'CopyPartnerDetailsButton'
