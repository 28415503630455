import styled from 'styled-components'

import { getFontStyles } from '@helpers/styled-helpers'

import { Button } from './Button'

// for NotificationRow and SelectAllPopover
const SecondaryLinkButton = styled(Button).attrs({
  variant: 'secondaryText',
})`
  padding: 0;
  ${({ theme }) => getFontStyles(theme, '400-sm')}
  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    text-decoration: underline;
  }
`

export default SecondaryLinkButton
