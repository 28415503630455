import { NavigateFunction } from 'react-router'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getBankTransactionParamsFromStore,
  getDokumentsParamsFromStore,
  getExpenseListFiltersFromStore,
  getIncomeListFiltersFromStore,
  getMonthlyStatementFiltersFromStore,
  getPaidThroughParamsFromStore,
  getPartnerParamsFromStore,
  getPaymentParamsFromStore,
  getPaymentTransactionParamsFromStore,
  getQuarantineListFiltersFromStore,
  getSalaryListFiltersFromStore,
  getTaxListFiltersFromStore,
  syncFiltersWithUrl,
} from '@helpers'

import dokumentsActions from '../dokuments/actions'
import expenseActions from '../expense/actions'
import incomeActions from '../income/actions'
import partnerActions from '../partners/actions'
import paymentActions from '../payment/actions'
import quarantineActions from '../quarantine/actions'
import salaryActions from '../salary/actions'
import taxActions from '../tax/actions'
import actions from './actions'

// TODO handle error
function* syncFiltersToUrlSaga({
  payload: { navigate, filters, skipDateFilters },
}: SagaAction<{ navigate: NavigateFunction; filters: ApiPageParams; skipDateFilters?: boolean }>) {
  try {
    yield call(syncFiltersWithUrl, { navigate, filters, skipDateFilters })
  } catch (error) {
    console.error('SNYC URL Error: syncFiltersToUrlSaga', error)
  }
}

function* syncExpenseFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore, { withCursor: true })
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncQuarantineFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getQuarantineListFiltersFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncIncomeFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getIncomeListFiltersFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncSalaryFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getSalaryListFiltersFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncTaxFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getTaxListFiltersFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncPaidThroughListFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getPaidThroughParamsFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters, skipDateFilters: true }))
}

function* syncPaymentOrderFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getPaymentParamsFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncPaymentTransactionFiltersToUrlSaga({
  payload: { navigate },
}: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getPaymentTransactionParamsFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncBankTransactionFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getBankTransactionParamsFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncMonthlyStatementFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getMonthlyStatementFiltersFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncDokumentFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getDokumentsParamsFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

function* syncPartnerListFiltersToUrlSaga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  const filters: ApiPageParams = yield select(getPartnerParamsFromStore)
  yield put(actions.syncFiltersToUrl.request({ navigate, filters }))
}

export default function* commonSaga() {
  yield takeLatest(actions.syncFiltersToUrl.REQUEST, syncFiltersToUrlSaga)
  yield takeLatest(
    [
      actions.resetExpenseListFilters.REQUEST,
      actions.toggleExpenseListDateFilter.REQUEST,
      actions.updateExpenseListFilters.REQUEST,
      expenseActions.updateOrderV2.REQUEST,
      expenseActions.updateRowsPerPageV2.REQUEST,
    ],
    syncExpenseFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.toggleQuarantineListDateFilter.REQUEST,
      actions.updateQuarantineListFilters.REQUEST,
      actions.resetQuarantineListFilters.REQUEST,
      quarantineActions.updateOrderV2.REQUEST,
      quarantineActions.updateRowsPerPageV2.REQUEST,
    ],
    syncQuarantineFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.toggleIncomeListDateFilter.REQUEST,
      actions.updateIncomeListFilters.REQUEST,
      actions.resetIncomeListFilters.REQUEST,
      incomeActions.updateOrderV2.REQUEST,
      incomeActions.updateRowsPerPageV2.REQUEST,
    ],
    syncIncomeFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.toggleSalaryListDateFilter.REQUEST,
      actions.updateSalaryListFilters.REQUEST,
      actions.resetSalaryListFilters.REQUEST,
      salaryActions.updateOrderV2.REQUEST,
      salaryActions.updateRowsPerPageV2.REQUEST,
    ],
    syncSalaryFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.toggleTaxListDateFilter.REQUEST,
      actions.updateTaxListFilters.REQUEST,
      actions.resetTaxListFilters.REQUEST,
      taxActions.updateOrderV2.REQUEST,
      taxActions.updateRowsPerPageV2.REQUEST,
    ],
    syncTaxFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.updatePaidThroughFilters.REQUEST,
      actions.resetPaidThroughFilters.REQUEST,
      paymentActions.updatePaidThroughsRowsPerPage.REQUEST,
    ],
    syncPaidThroughListFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.updatePaymentOrderFilters.REQUEST,
      actions.resetPaymentOrderFilters.REQUEST,
      actions.togglePaymentOrderDateFilter.REQUEST,
      paymentActions.updatePaymentsRowsPerPage.REQUEST,
    ],
    syncPaymentOrderFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.updatePaymentTransactionsFilters.REQUEST,
      actions.resetPaymentTransactionsFilters.REQUEST,
      actions.togglePaymentTransactionsDateFilter.REQUEST,
      paymentActions.updatePaymentTransactionsRowsPerPage.REQUEST,
    ],
    syncPaymentTransactionFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.updateBankTransactionsFilters.REQUEST,
      actions.resetBankTransactionsFilters.REQUEST,
      actions.toggleBankTransactionsDateFilter.REQUEST,
      paymentActions.updateBankTransactionsRowsPerPage.REQUEST,
    ],
    syncBankTransactionFiltersToUrlSaga
  )
  yield takeLatest(
    [actions.resetMonthlyStatementFilters.REQUEST, actions.updateMonthlyStatementFilters.REQUEST],
    syncMonthlyStatementFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.updateDokumentFilters.REQUEST,
      actions.resetDokumentFilters.REQUEST,
      actions.toggleDokumentDateFilter.REQUEST,
      dokumentsActions.updateOrder.REQUEST,
      dokumentsActions.updateRowsPerPage.REQUEST,
    ],
    syncDokumentFiltersToUrlSaga
  )
  yield takeLatest(
    [
      actions.resetPartnerListFilters.REQUEST,
      actions.updatePartnerListFilters.REQUEST,
      partnerActions.updateRowsPerPage.REQUEST,
    ],
    syncPartnerListFiltersToUrlSaga
  )
}
