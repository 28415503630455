import { Grid } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import styled from 'styled-components'

export const ContentWrapperDiv = styled.div`
  max-width: 530px;
`

// TODO - this is a duplication of oldComponents/settingsBlock/styles - keep this file
export const styles = (theme: Theme) => ({
  panelRoot: {
    marginTop: 15,
    overflow: 'hidden',
  },
  panelHeader: {
    backgroundColor: theme.palette.grayscale.xxxLight,
    padding: '15px 15px 15px 30px',
    borderBottom: `1px solid ${theme.palette.grayscale.xLight}`,
  },
  panelBody: {
    padding: '35px 30px 45px',
    '& h4': {
      marginBottom: 20,
    },
  },
  subscriptionHistoryPanelBody: {
    padding: '20px 15px 30px 30px',
    minHeight: 150,
  },
  contentContainer: {
    paddingLeft: 15,
    fontSize: 14,
  },
  primaryLinkButton: theme.primaryLinkButton,
  buttonsContainer: {
    '&> * + *': {
      marginLeft: 10,
    },
  },
})

export const GridWithDivider = styled(Grid)`
  position: relative;
  padding-top: 20px;

  * + & {
    margin-top: 20px;
  }

  &::before {
    content: '';
    width: calc(100% + 60px);
    position: absolute;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray[105]};
    left: -30px;
    top: 0;
  }
`
