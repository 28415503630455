import React from 'react'
import { bool, node, oneOf, shape, string } from 'prop-types'

import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'

import { PublicNavbar } from '@components/PublicNavbar'

import { APP_TITLE } from '@constants'

import styles from './styles'

export function PublicPage({ children, classes, subtitle, lang, withExtraDesign, theme }) {
  return (
    <Grid
      container
      alignItems="center"
      className={cx(
        classes.staticPage,
        {
          [classes.designPage]: withExtraDesign,
        },
        `${theme}Theme`
      )}
    >
      {subtitle && <Helmet title={`${subtitle} | ${APP_TITLE}`} />}
      <Helmet htmlAttributes={{ lang }} />
      <PublicNavbar withExtraDesign={withExtraDesign} />
      {children}
    </Grid>
  )
}

PublicPage.propTypes = {
  children: node.isRequired,
  classes: shape({
    staticPage: string.isRequired,
  }).isRequired,
  subtitle: string,
  lang: oneOf(['hu', 'en']).isRequired,
  withExtraDesign: bool,
  theme: oneOf(['default', 'blue', 'purple']),
}

PublicPage.defaultProps = {
  withExtraDesign: false,
  theme: 'default',
}

export default connect(state => ({
  lang: state.locale.language,
}))(withStyles(styles)(PublicPage))
