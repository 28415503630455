import React from 'react'

import { Container } from '@components/DocViewer/elements/ProxyRenderer/ProxyRenderer'
import { useRendererSizes } from '@components/DocViewer/hooks'
import { DocViewerConfig } from '@components/DocViewer/types'

import { InvoicePreviewRenderer } from './elements'
import { GeneratedPreviewViewerProvider } from './GeneratedPreviewViewerProvider'
import { InvoicePreviewVariant } from './types'

interface GeneratedPreviewViewerProps {
  config: DocViewerConfig
  previewUrl: string
  previewVariant: InvoicePreviewVariant
  renderPostItLayer?: (scale: number, page?: number) => Nullable<JSX.Element>
}

export function GeneratedPreviewViewer({ config, ...rest }: GeneratedPreviewViewerProps) {
  const rendererRect = useRendererSizes(config)
  return (
    <Container $config={rendererRect}>
      <GeneratedPreviewViewerProvider {...rest} rendererRect={rendererRect}>
        <InvoicePreviewRenderer />
      </GeneratedPreviewViewerProvider>
    </Container>
  )
}
