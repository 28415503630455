import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, Grid, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import __get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { authActions, subscriptionActions } from '@services'

import { bindActionToPromise, calculatePlanPrices, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useSubscription } from '@contexts'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { AsyncButton, ExternalLink, Typography } from '@components/ui'

import { LINKS } from '@constants'

import acceptedCreditCardsSrc from '@assets/img/accepted_credit_cards.svg'
import braintreeLogoSrc from '@assets/img/braintree_logo.svg'

import BraintreeErrorMessage from './BraintreeErrorMessage'
import BraintreeForm from './BraintreeForm'
import BraintreeProvider from './BraintreeProvider'
import HostedField from './HostedField'
import { LoadingOverlay } from './LoadingOverlay'
import { PromocodeForm } from './PromocodeForm'

import { formStyles } from '@styles'
import { MoreInformationMessage } from '@messages'
import styles from './styles'

const StyledExternalLink = styled(ExternalLink)`
  width: max-content;
  margin: 0 auto 10px;
`

const useStyles = makeStyles(theme => ({
  ...formStyles(theme),
  ...styles(theme),
}))

export function BraintreeContainer({
  braintreeToken,
  createPaymentMethodNonce,
  callCreateSubscription,
  children,
  setActiveSubscription,
  promoCode,
}) {
  const { selectedPlan, setSuccessView, submitting, setSubmitting } = useSubscription()
  const classes = useStyles()

  const [error, setError] = React.useState(null)
  const [ready, setReady] = React.useState(false)
  const [subscriptionError, setSubscriptionError] = React.useState(null)

  const timerRef = React.useRef(null)
  const cPromiseRef = useCancellablePromiseRef()

  const selectedPlanAmount = React.useMemo(() => {
    if (!selectedPlan) {
      return null
    }
    return calculatePlanPrices(selectedPlan)
  }, [selectedPlan])

  const onError = React.useCallback(err => setError(err), [setError])
  const onReady = React.useCallback(() => setReady(true), [setReady])

  const onSubmitSuccess = React.useCallback(
    (payload, stopSubmit) => {
      if (!selectedPlan) {
        throw Error('Missing selected plan in braintree container')
      }
      cPromiseRef.current = cancellablePromise(
        callCreateSubscription({
          plan_id: selectedPlan.id,
          promo_code: promoCode,
          nonce: payload.nonce,
        })
      )

      cPromiseRef.current.promise
        .then(newSubscription => {
          try {
            setSuccessView('payment')
            setActiveSubscription(newSubscription)
          } catch (err) {
            console.log("ERROR: setActiveSubscription", err.message); // eslint-disable-line
          }
        })
        .catch(error => {
          const errorMsg = parseApiErrorMessage(error)
          if (errorMsg) {
            setSubscriptionError(errorMsg)
            stopSubmit()
            timerRef.current = setTimeout(setSubscriptionError, 15000, null)
          }
        })
    },
    [cPromiseRef, callCreateSubscription, promoCode, selectedPlan, setActiveSubscription, setSuccessView]
  )

  if (!braintreeToken) {
    // TODO handle it
    return <div>Error, missing braintreeToken</div>
  }

  // selectedPlanAmount need for Braintree 3DSecure verifyCard method
  return (
    <>
      <LoadingOverlay loading={submitting} />
      <Grid container spacing={4} className={cx('fields', { submitting })} data-testid="braintree-container">
        <div></div>
        <Grid item xs={12}>
          <PromocodeForm disabled={submitting} />
        </Grid>

        <Grid item xs={12}>
          <BraintreeProvider
            className={cx({ disabled: !ready })}
            createPaymentMethodNonce={createPaymentMethodNonce}
            onError={onError}
            onReady={onReady}
            styles={{
              input: {
                fontSize: 16,
                padding: '6px 10px',
                fontFamily: ['Roboto', 'sans-serif'].join(','),
              },
            }}
            token={braintreeToken}
          >
            <BraintreeForm
              onSubmitSuccess={onSubmitSuccess}
              amount={selectedPlanAmount}
              setSubmitting={setSubmitting}
              render={({ hasFieldError, hasFieldFocus, onFieldBlur, onFieldFocus, onValidityChange, handleSubmit }) => {
                return (
                  <Grid container spacing={4} className="fields">
                    <Grid item xs={12}>
                      <FormControl fullWidth error={hasFieldError('cardholderName')}>
                        <InputLabel
                          shrink
                          className={classes.bootstrapFormLabel}
                          focused={hasFieldFocus('cardholderName')}
                        >
                          <FormattedMessage
                            id="subscriptionForm.labels.cardholderName"
                            defaultMessage="Kártyabirtokos neve"
                          />
                        </InputLabel>
                        <HostedField
                          className={cx(classes.braintreeField, {
                            focus: hasFieldFocus('cardholderName'),
                            error: hasFieldError('cardholderName'),
                          })}
                          name="cardholderName"
                          onValidityChange={onValidityChange('cardholderName')}
                          onFocus={onFieldFocus('cardholderName')}
                          onBlur={onFieldBlur('cardholderName')}
                          disabled={submitting}
                        />
                        {hasFieldError('cardholderName') && (
                          <FormHelperText>
                            <FormattedMessage
                              id="subscriptionForm.errors.cardholderName"
                              defaultMessage="A mező kitöltése kötelező (legalább 4 karaktert kell tartalmaznia)"
                            />
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth error={hasFieldError('number')}>
                        <InputLabel shrink className={classes.bootstrapFormLabel} focused={hasFieldFocus('number')}>
                          <FormattedMessage id="subscriptionForm.labels.number" defaultMessage="Kártyaszám" />
                        </InputLabel>
                        <HostedField
                          className={cx(classes.braintreeField, {
                            focus: hasFieldFocus('number'),
                            error: hasFieldError('number'),
                          })}
                          name="number"
                          onValidityChange={onValidityChange('number')}
                          onFocus={onFieldFocus('number')}
                          onBlur={onFieldBlur('number')}
                          disabled={submitting}
                        />
                        {hasFieldError('number') && (
                          <FormHelperText>
                            <FormattedMessage id="subscriptionForm.errors.number" defaultMessage="Hibás kártyaszám" />
                          </FormHelperText>
                        )}
                        <div
                          style={{
                            marginTop: 10,
                            fontSize: 12,
                            textAlign: 'left',
                            lineHeight: '1.2em',
                          }}
                        >
                          <img src={acceptedCreditCardsSrc} alt="Visa, Mastercard, American Express, JCB, Discover" />
                        </div>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth error={hasFieldError('expirationDate')}>
                        <InputLabel
                          shrink
                          className={classes.bootstrapFormLabel}
                          focused={hasFieldFocus('expirationDate')}
                        >
                          <FormattedMessage
                            id="subscriptionForm.labels.expirationDate"
                            defaultMessage="Lejárati dátum"
                          />
                        </InputLabel>
                        <HostedField
                          className={cx(classes.braintreeField, {
                            focus: hasFieldFocus('expirationDate'),
                            error: hasFieldError('expirationDate'),
                          })}
                          name="expirationDate"
                          onValidityChange={onValidityChange('expirationDate')}
                          onFocus={onFieldFocus('expirationDate')}
                          onBlur={onFieldBlur('expirationDate')}
                          placeholder="hh / éééé"
                          disabled={submitting}
                        />
                        {hasFieldError('expirationDate') && (
                          <FormHelperText>
                            <FormattedMessage
                              id="subscriptionForm.errors.expirationDate"
                              defaultMessage="Hibás lejárati dátum"
                            />
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth error={hasFieldError('cvv')}>
                        <InputLabel shrink className={classes.bootstrapFormLabel} focused={hasFieldFocus('cvv')}>
                          <FormattedMessage id="subscriptionForm.labels.cvv" defaultMessage="CVV" />
                        </InputLabel>
                        <HostedField
                          className={cx(classes.braintreeField, {
                            focus: hasFieldFocus('cvv'),
                            error: hasFieldError('cvv'),
                          })}
                          name="cvv"
                          onValidityChange={onValidityChange('cvv')}
                          onFocus={onFieldFocus('cvv')}
                          onBlur={onFieldBlur('cvv')}
                          disabled={submitting}
                        />
                        {hasFieldError('cvv') && (
                          <FormHelperText>
                            <FormattedMessage id="subscriptionForm.errors.cvv" defaultMessage="Hibás biztonsági kód" />
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography size="400-xs" align="center" color="gray-80">
                        <FormattedMessage
                          id="subscriptionForm.disclaimerText"
                          defaultMessage="A Fizetés gomb megnyomásával elfogadod általános szerződési feltételeinket. Kártyaadatokat nem tárolunk."
                        />
                      </Typography>

                      <StyledExternalLink href={LINKS.terms} text={MoreInformationMessage} typographySize="400-xs" />
                    </Grid>

                    {(error || subscriptionError) && (
                      <Grid item xs={12}>
                        {error && <BraintreeErrorMessage className={classes.braintreeErrorMessage} error={error} />}
                        {subscriptionError && <div className={classes.braintreeErrorMessage}>{subscriptionError}</div>}
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <div className={classes.braintreeButtonsContainer}>
                        <AsyncButton
                          variant="primaryContained"
                          onClick={handleSubmit}
                          loading={submitting}
                          fullWidth
                          disabled={!ready}
                        >
                          <FormattedMessage id="subscription.form.submitButton.text" defaultMessage="Fizetés" />
                        </AsyncButton>
                        {children}
                      </div>
                    </Grid>

                    <Grid container alignItems="center" className={classes.paymentProviderContainer} direction="column">
                      <FormattedMessage id="subscription.paymentProvider" defaultMessage="A fizetési szolgáltató:" />
                      <img src={braintreeLogoSrc} alt="Braintree" />
                    </Grid>
                  </Grid>
                )
              }}
            />
          </BraintreeProvider>
        </Grid>
      </Grid>
    </>
  )
}

BraintreeContainer.propTypes = {
  braintreeToken: PropTypes.string,
  callCreateSubscription: PropTypes.func.isRequired,
  children: PropTypes.node,
  promoCode: PropTypes.string,
  setActiveSubscription: PropTypes.func.isRequired,
  createPaymentMethodNonce: PropTypes.func.isRequired,
}

export default connect(
  state => ({
    braintreeToken: state.subscription.braintree_token,
    promoCode: __get(state, ['subscription', 'promo', 'code']),
  }),
  dispatch => ({
    callCreateSubscription: bindActionToPromise(dispatch, subscriptionActions.createSubscription.request),
    setActiveSubscription: payload => dispatch(authActions.setActiveSubscription.request(payload)),
    createPaymentMethodNonce: bindActionToPromise(dispatch, subscriptionActions.createPaymentMethodNonce.request),
  })
)(BraintreeContainer)
