// polyfill promise in older browsers and https://caniuse.com/mdn-javascript_builtins_promise_withresolvers
import 'core-js/features/promise'
import './assets/css/react-select.css'

import React from 'react'

import * as Sentry from '@sentry/react'
import moment from 'moment'
import huLocalization from 'moment/locale/hu'
import { render } from 'react-dom'
import TagManager from 'react-gtm-module'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import momentLocalizer from 'react-widgets-moment'

import App from '@oldComponents/App'

// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker'

moment.updateLocale('hu', huLocalization)
momentLocalizer()

if (window.sentryDsn) {
  Sentry.init({
    dsn: window.sentryDsn,
    release: window.appVersion,
    environment: window.appEnv,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // https://github.com/getsentry/sentry/issues/9331 - avast extension
      `Cannot read property '_avast_submit' of undefined`,
      "Cannot read properties of undefined (reading '_avast_submit')",
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // Last pass extension
      // eslint-disable-next-line max-len
      `TypeError: undefined is not an object (evaluating 'bg.get("LPContentScriptFeatures").icon_expanded_looks_like_username')`,
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

if (window.gtmId) {
  TagManager.initialize({ gtmId: window.gtmId })
}

render(<App />, document.getElementById('root'))

// registerServiceWorker()
unregister()
