import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import LabelInfoIcon from '@components/ui/svgIcons/LabelInfoIcon'

import LightTooltip from './LightTooltip'

const StyledLabelInfoIcon = styled(LabelInfoIcon)`
  color: inherit;
`

interface FieldLabelWithInfoTooltipProps {
  label: StringOrMessage
  tooltipText: StringOrMessage
}

/**
 * Temporary info labeled field label for MUI fields to be passed as label
 *
 * @param {FieldLabelWithInfoTooltipProps} { label, tooltipText }
 */
export default function FieldLabelWithInfoTooltip({ label, tooltipText }: FieldLabelWithInfoTooltipProps) {
  return (
    <span>
      {label}
      <LightTooltip
        PopperProps={{ style: { zIndex: 1400 } }} // dialog has z-index of 1300
        title={tooltipText}
        placement="top"
      >
        <StyledLabelInfoIcon />
      </LightTooltip>
    </span>
  )
}

FieldLabelWithInfoTooltip.propTypes = {
  label: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
}
