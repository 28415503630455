import React from 'react'

import { connect } from 'react-redux'

import { filtersActions } from '@services'

import { usePageConfig } from '@contexts'

import { FilterBar, FilterBarBaseProps } from './FilterBar'

interface PurePaidThroughsPageFilterBarProps extends FilterBarBaseProps {
  filters: PaidThroughsStoreFilters
}

function PurePaidThroughsPageFilterBar({
  filters: { search, searchFields },
  isDateFilterDisabled,
  resetFilters,
  updateFilters,
}: PurePaidThroughsPageFilterBarProps) {
  const pageConfig = usePageConfig()

  const isDateFilterUsed = false
  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
  }

  return (
    <FilterBar
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      updateFilters={updateFilters}
    />
  )
}

export const PaidThroughsPageFilterBar = connect(
  ({ filters: { isGlobalDateFilterDisabled, paidThroughs } }: Store) => {
    return {
      filters: paidThroughs,
      isDateFilterDisabled: isGlobalDateFilterDisabled,
    }
  },
  {
    resetFilters: filtersActions.resetPaidThroughFilters.request,
    updateFilters: filtersActions.updatePaidThroughFilters.request,
  }
)(PurePaidThroughsPageFilterBar)
PaidThroughsPageFilterBar.displayName = 'PaidThroughsPageFilterBar'
