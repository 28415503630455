import React from 'react'

import { FileRejection } from 'react-dropzone'

import { parseFileUploadErrors } from '@helpers'

import {
  hasError,
  hasRejected,
  isStateObject,
  RejectedFileResponse,
  UseFileDropzoneProps,
  UseFileDropzoneReturnType,
  UseFileDropzoneState,
} from './types'

import { FileUploadErrorMessage } from '@messages'

const DEFAULT_ERROR_TIMEOUT = 6000

export function useFileDropzone<ExtendedState extends Record<string, unknown> = Record<never, never>>({
  extendedState,
  uploadHandler,
  onDropHandler,
}: UseFileDropzoneProps<ExtendedState>): UseFileDropzoneReturnType<ExtendedState> {
  const [state, setState] = React.useState({
    error: null,
    errorTimeout: DEFAULT_ERROR_TIMEOUT,
    isSuccessDialogVisible: false,
    isUploading: false,
    storedAcceptedFiles: [] as File[],
    storedRejectedFiles: [] as FileRejection[],
    rejectedFileResponse: [] as RejectedFileResponse[],
    ...extendedState,
  } as UseFileDropzoneState & ExtendedState)
  const { error, errorTimeout, storedAcceptedFiles, storedRejectedFiles } = state

  // clear errors after 3s
  React.useEffect(() => {
    let timeout: number
    if (error) {
      timeout = window.setTimeout(
        () => setState(state => ({ ...state, error: null, errorTimeout: DEFAULT_ERROR_TIMEOUT })),
        errorTimeout
      )
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [error, errorTimeout])

  const handleOnDrop = React.useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (typeof onDropHandler === 'function') {
        onDropHandler(acceptedFiles, rejectedFiles, state, setState)
      } else {
        setState(state => ({
          ...state,
          error: null,
          storedAcceptedFiles: [...state.storedAcceptedFiles, ...acceptedFiles],
          storedRejectedFiles: [...state.storedRejectedFiles, ...rejectedFiles],
        }))
      }
    },
    [onDropHandler, state]
  )

  const removeUploadedFile = React.useCallback(
    (fileListName: 'storedAcceptedFiles' | 'storedRejectedFiles', index: number) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        const fileList = fileListName === 'storedAcceptedFiles' ? [...storedAcceptedFiles] : [...storedRejectedFiles]
        fileList.splice(index, 1)
        setState(state => ({
          ...state,
          [fileListName]: fileList,
        }))
      },
    [storedAcceptedFiles, storedRejectedFiles]
  )

  const reset = React.useCallback(() => {
    setState(state => ({
      ...state,
      error: null,
      storedAcceptedFiles: [],
      storedRejectedFiles: [],
      rejectedFileResponse: [],
    }))
  }, [])

  const handleUploadFiles = React.useCallback(
    async (argument?: Event | Partial<UseFileDropzoneState & ExtendedState> | React.MouseEvent<HTMLButtonElement>) => {
      if (!isStateObject(argument) && typeof argument?.preventDefault === 'function') {
        argument.preventDefault()
      }
      setState(state => ({
        ...state,
        isUploading: true,
        error: null,
        rejectedFiles: [],
      }))
      try {
        const result = await uploadHandler(state, setState, isStateObject(argument) ? argument : undefined)

        let error: React.ReactNode | undefined
        let rejectedFileResponse: RejectedFileResponse[] = []
        if (hasError(result)) {
          error = result.error
        }
        if (hasRejected(result)) {
          rejectedFileResponse = result.rejected
        }
        setState(state => ({
          ...state,
          isUploading: false,
          isSuccessDialogVisible: !error,
          error: error || null,
          rejectedFileResponse,
        }))
      } catch (error) {
        const submissionErrors = parseFileUploadErrors(error)
        if (submissionErrors) {
          if (typeof submissionErrors === 'string') {
            setState(state => ({
              ...state,
              isUploading: false,
              error: submissionErrors,
            }))
          } else {
            setState(state => ({
              ...state,
              storedAcceptedFiles: state.storedAcceptedFiles.filter((file, index) => !submissionErrors[index]),
              rejectedFileResponse: Object.entries(submissionErrors).map(([index, errors]) => ({
                filename: state.storedAcceptedFiles[Number(index)]?.name,
                message: errors[0],
              })),
              isUploading: false,
              error: FileUploadErrorMessage,
            }))
          }
        }
      }
    },
    [state, uploadHandler]
  )

  const setExtendedState = React.useCallback((extendedStateObject: Partial<ExtendedState>) => {
    setState(state => ({
      ...state,
      ...extendedStateObject,
    }))
  }, [])

  const handleClose = React.useCallback(() => {
    setState(state => ({
      ...state,
      error: null,
      isSuccessDialogVisible: false,
      storedAcceptedFiles: [],
      storedRejectedFiles: [],
    }))
  }, [])

  return {
    ...state,
    handleClose,
    handleOnDrop,
    handleUploadFiles,
    removeUploadedFile,
    reset,
    setExtendedState,
  }
}
