import React from 'react'
import PropTypes from 'prop-types'

import { useField, useForm, useFormState } from 'react-final-form'
import { useIntl } from 'react-intl'

import { DEFAULT_CHANGE_FIELD_LISTENER_SUBSCRIPTION } from '@constants'

import { FieldListenerProps } from './types'

//* COMPONENT TO USE FOR CHANGE EVENTS
export function ChangeFieldListener<Props extends Record<string, any>>({
  handler,
  name,
  subscription = DEFAULT_CHANGE_FIELD_LISTENER_SUBSCRIPTION,
  ...rest
}: FieldListenerProps<Props>) {
  const form = useForm()
  const {
    meta: { active = true, initial },
    input: { value },
    input,
    meta,
  } = useField(name, { subscription })
  const { values } = useFormState({ subscription: { values: true } })
  const intl = useIntl()
  const [prevValue, setPrevValue] = React.useState(value)

  // update value if reinitialized
  React.useEffect(() => {
    if (initial !== undefined) {
      setPrevValue(value)
    }
  }, [initial, value])

  // kick off handler if value changes on active field with "change" type
  React.useEffect(() => {
    if (active && value !== prevValue) {
      setPrevValue(value)
      // call handler
      handler({ ...rest, form, intl, values, input, meta, prevValue })
    }
  }, [value, active, form, intl, values, prevValue, handler, input, meta, rest])

  return null
}

ChangeFieldListener.propTypes = {
  handler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  subscription: PropTypes.object,
}
