import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  titleLabel: {
    id: 'dokumentDetails.form.label.title',
    defaultMessage: 'Belső dokumentum név',
  },
  titleTooltip: {
    id: 'dokumentDetails.form.tooltip.title',
    defaultMessage:
      'A dokumentumod QUiCK-ben vezetett elnevezése. Ha kitöltöd ezt a mezőt, ez a név jelenik meg a listában és keresni is tudsz rá, de a dokumentum eredeti elnevezését nem írja felül.',
  },
  summaryLabel: {
    id: 'dokumentDetails.form.label.summary',
    defaultMessage: 'Összefoglaló',
  },
  summaryTooltip: {
    id: 'dokumentDetails.form.tooltip.summary',
    defaultMessage:
      'Rövid összefoglaló, amely gyors tartalmi áttekintést ad. A listaoldalon az összefoglaló tartalma szerint is tudsz keresni.',
  },
  keywordsLabel: {
    id: 'dokumentDetails.form.label.keywords',
    defaultMessage: 'Kulcsszavak',
  },
  keywordsTooltip: {
    id: 'dokumentDetails.form.tooltip.keywords',
    defaultMessage:
      'Vesszővel elválasztva adj hozzá új kulcsszavakat! Ezekkel egyedi kereshetőséget tehetsz lehetővé a típus és címkék megadása mellett.',
  },
  simpleTagsLabel: {
    id: 'dokumentDetails.form.label.simpleTags',
    defaultMessage: 'Címke',
  },
  documentTypeLabel: {
    id: 'dokumentDetails.form.label.documentType',
    defaultMessage: 'Típus',
  },
  senderLabel: {
    id: 'dokumentDetails.form.label.sender',
    defaultMessage: 'Feladó',
  },
  recipientLabel: {
    id: 'dokumentDetails.form.label.recipient',
    defaultMessage: 'Címzett',
  },
  containsPayableLabel: {
    id: 'dokumentDetails.form.label.containsPayable',
    defaultMessage: 'Fizetendőt tartalmaz',
  },
})
