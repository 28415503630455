import { COMPANY_ROUTE } from '@constants'

export const API_LIQUIDITY_URL = `${COMPANY_ROUTE}liquidity/`
export const API_LIQUIDITY_INVOICES_URL = `${COMPANY_ROUTE}liquidity/{year}/{week}/invoices/`
export const API_LIQUIDITY_PREDICTED_INVOICES_URL = `${COMPANY_ROUTE}liquidity/{year}/{week}/predicted-invoices/`
export const API_LIQUIDITY_PREDICTED_INCOMES_URL = `${COMPANY_ROUTE}liquidity/{year}/{week}/incomes/`
export const API_LIQUIDITY_PREDICTED_SALARIES_URL = `${COMPANY_ROUTE}liquidity/{year}/{week}/salaries/`
export const API_LIQUIDITY_TAXES_URL = `${COMPANY_ROUTE}liquidity/{year}/{week}/taxes/`
// update
export const API_LIQUIDITY_INVOICE_DETAIL_URL = `${COMPANY_ROUTE}liquidity/invoices/{id}/`
export const API_LIQUIDITY_PREDICTED_INCOME_DETAIL_URL = `${COMPANY_ROUTE}liquidity/incomes/{id}/`
// remove
export const API_LIQUIDITY_PREDICTED_INVOICE_DETAIL_URL = `${COMPANY_ROUTE}liquidity/{year}/{week}/predicted-invoices/{id}/` // eslint-disable-line max-len
// check: is szamlazzHU connected?
export const API_LIQUIDITY_INCOMES_URL = `${COMPANY_ROUTE}liquidity/incomes/`

// tax
export const API_TAX_DETAILS_URL = `${COMPANY_ROUTE}tax/{tax_id}`
