import React from 'react'

import styled from 'styled-components'

function BaseRightArrowIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 11.2L9.80005 5.59995C9.40005 5.19995 8.80005 5.19995 8.40005 5.59995C8.00005 5.99995 8.00005 6.59995 8.40005 6.99995L13.3 12L8.40005 17C8.00005 17.4 8.00005 18 8.40005 18.4C8.60005 18.6 8.80005 18.7 9.10005 18.7C9.40005 18.7 9.60005 18.6 9.80005 18.4L15.5 12.8C15.9 12.3 15.9 11.7 15.5 11.2C15.5 11.3 15.5 11.3 15.5 11.2Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const RightArrowIcon = styled(BaseRightArrowIcon)``
