import { call, takeLatest } from 'redux-saga/effects'

import { setDefaultPageSize } from '@helpers'

import expenseActions from '../expense/actions'
import incomeActions from '../income/actions'
import partnerActions from '../partners/actions'
import quarantineActions from '../quarantine/actions'
import salaryActions from '../salary/actions'
import taxActions from '../tax/actions'

// save rowsPerPage option to cookie
export function* saveDefaultPageSizeSaga({ payload }) {
  try {
    yield call(setDefaultPageSize, payload.pageSize)
  } catch (error) {
    // ignore errors
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(
    [
      expenseActions.updateRowsPerPageV2.REQUEST,
      incomeActions.updateRowsPerPageV2.REQUEST,
      partnerActions.updateRowsPerPage.REQUEST,
      quarantineActions.updateRowsPerPageV2.REQUEST,
      salaryActions.updateRowsPerPageV2.REQUEST,
      taxActions.updateRowsPerPageV2.REQUEST,
    ],
    saveDefaultPageSizeSaga
  )
}
