import { generateMaxHeightBreakPoint, generateMaxWidthBreakPoint } from '@constants'

import blue_theme_auth_bg_mobile from '@assets/img/blue_theme_auth_bg_mobile.svg'
import purple_theme_auth_bg_mobile from '@assets/img/purple_theme_auth_bg_mobile.svg'

const styles = theme => ({
  staticPage: {
    minHeight: '100vh',
    backgroundColor: theme.palette.grayscale.xxxLight,
    color: theme.palette.neutral.dark,
    paddingTop: theme.appBarHeight + 20,
    paddingBottom: 20,

    '& > header': {
      border: 'none !important',
    },

    [generateMaxHeightBreakPoint('medium')]: {
      paddingTop: theme.appBarHeightMedium + 20,
    },
  },
  designPage: {
    minWidth: 340,
    backgroundColor: theme.palette.tertiary.darkBlue,
    position: 'relative',

    [generateMaxWidthBreakPoint('mobile')]: {
      backgroundPosition: `center ${theme.appBarHeight}px`,
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',

      '&.blueTheme': {
        backgroundImage: `url(${blue_theme_auth_bg_mobile})`,
      },
      '&.purpleTheme': {
        backgroundImage: `url(${purple_theme_auth_bg_mobile})`,
      },
    },
  },
})

export default styles
