import { call, delay, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { getActiveCompanyId, getErrorMessage, transformWorkoutFiltersToParams } from '@helpers'

import { TYPING_INTERVAL } from '@constants'

import actions from './actions'
import * as api from './api'

export function* fetchReportsSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchReports, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* fetchWorkoutReportSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId = yield select(getActiveCompanyId)
    const params = yield call(transformWorkoutFiltersToParams, payload)
    const response = yield call(api.fetchWorkoutReport, companyId, params)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* fetchWorkoutDetailsReportSaga({ payload: { partnerId, cluster }, meta: { resolve, reject } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId = yield select(getActiveCompanyId)
    const params = yield call(transformWorkoutFiltersToParams, { cluster })
    const response = yield call(api.fetchWorkoutDetailsReport, companyId, partnerId, params)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* createPayeeWorkflowSaga({ payload: { partnerId, params }, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createPayeeWorkflow, companyId, partnerId, params)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* fetchPartnerDetailsSaga({ payload: { partnerId, params }, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchPartnerDetails, companyId, partnerId, params)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* fetchVatPositionReportSaga({ meta: { resolve, reject } }) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchVatPositionReport, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* fetchBankTransactionsReportSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchBankTransactionsReport, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* fetchAiChatDetailsSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchAiChatDetails, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchReports.REQUEST, fetchReportsSaga)
  yield takeLatest(actions.fetchWorkoutReport.REQUEST, fetchWorkoutReportSaga)
  yield takeEvery(actions.fetchWorkoutDetailsReport.REQUEST, fetchWorkoutDetailsReportSaga)
  yield takeLatest(actions.createPayeeWorkflow.REQUEST, createPayeeWorkflowSaga)
  yield takeLatest(actions.fetchPartnerDetails.REQUEST, fetchPartnerDetailsSaga)
  yield takeLatest(actions.fetchVatPositionReport.REQUEST, fetchVatPositionReportSaga)
  yield takeLatest(actions.fetchBankTransactionsReport.REQUEST, fetchBankTransactionsReportSaga)
  yield takeLatest(actions.fetchAiChatDetails.REQUEST, fetchAiChatDetailsSaga)
}
