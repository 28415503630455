import React from 'react'
import PropTypes from 'prop-types'

import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import __uniqueId from 'lodash/uniqueId'

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: 2,
  },
  links: {
    fontSize: 14,
    lineHeight: 1,
    padding: '5px 10px',
    height: 21,
    color: theme.palette.grayscale.xxxDark,
  },
}))

export interface SpecialDownloadRenderProps {
  'aria-controls': string
  'aria-expanded': boolean
  'aria-haspopup': boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface SpecialDownloadProps {
  children: (renderProps: SpecialDownloadRenderProps) => React.ReactNode
  onDownload: (type?: string) => void
  options: Array<{ name: string; transferType: string }>
}

/**
 * Special component when downloading requires extra user action.
 *
 * @param {SpecialDownloadProps} { onDownload, children }
 */
export function SpecialDownload({ onDownload, children, options }: SpecialDownloadProps) {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLButtonElement>>(null)
  const classes = useStyles()
  const menuId = __uniqueId('transfer-type-selection-menu')

  function onClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleClick(type: string) {
    return function handler() {
      onDownload(type)
      setAnchorEl(null)
    }
  }

  function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      event.preventDefault()
      anchorEl?.focus()
      setAnchorEl(null)
    }
  }

  return (
    <>
      {children({ onClick, 'aria-controls': menuId, 'aria-haspopup': true, 'aria-expanded': Boolean(anchorEl) })}
      <Popper
        anchorEl={anchorEl}
        className={classes.popper}
        disablePortal
        open={Boolean(anchorEl)}
        placement="bottom"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper data-testid="transfer-type-selection-menu">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={Boolean(anchorEl)}
                  dense
                  id={menuId}
                  onKeyDown={handleListKeyDown}
                  variant="menu"
                >
                  {options.map(option => (
                    <MenuItem
                      className={classes.links}
                      key={option.transferType}
                      onClick={handleClick(option.transferType)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

SpecialDownload.propTypes = {
  children: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
}
