import React from 'react'
import { bool, func } from 'prop-types'

import { Switch as MuiSwitch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'

const Switch = withStyles(theme => ({
  root: {
    padding: '3px 5px',
    width: 42,
    height: 20,
    marginRight: 10,
    overflow: 'visible',
  },
  switchBase: {
    width: 20,
    height: 20,
    fontSize: 12,

    '&:hover': {
      transform: 'scale(1.1)',
    },
    '&$checked': {
      transform: 'translateX(20px)',
    },
    '&$checked:hover': {
      transform: 'translateX(20px) scale(1.1)',
    },
  },
  track: {
    backgroundColor: theme.palette.primary.main,
  },
  colorPrimary: {
    '&, &$checked, &:hover, &$checked:hover': {
      color: theme.palette.neutral.xxxLight,
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
}))(MuiSwitch)

export default function LockToggleButton({ checked, onChange }) {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      icon={<LockIcon fontSize="inherit" />}
      checkedIcon={<LockOpenIcon fontSize="inherit" />}
      color="primary"
    />
  )
}

LockToggleButton.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
}
