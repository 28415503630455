import React from 'react'

import { FormattedMessage } from 'react-intl'

export const DatePickerLabelMessage = (
  <FormattedMessage id="forms.payment.label.dueAt" defaultMessage="Fizetés időpontja" />
)

export const PaymentSummaryTitleMessage = (
  <FormattedMessage id="payment.dialog.summary.title" defaultMessage="Utalási csomagod elkészült" />
)

export const PaymentDialogTitleMessage = (
  <FormattedMessage id="payment.dialog.title" defaultMessage="Kifizetések kezelése" />
)
