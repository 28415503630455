import React from 'react'

import { useIntercom } from 'react-use-intercom'

import { ROUTES } from '@constants/routes'

function IntercomLoader() {
  const { boot, shutdown } = useIntercom()

  React.useLayoutEffect(() => {
    const page = window.location.pathname
    if (page === ROUTES.login || page === ROUTES.signup) {
      // clear the previous user data from non expired intercom session
      boot() // need to boot to able to clear the session
      shutdown() // clear session cookies
      // start new cleared session
      boot()
    }

    return () => {
      shutdown()
    }
  }, [boot, shutdown])

  return null
}

export function PublicIntercomInitializer() {
  if (window.intercomAppId) {
    // use a fake react component because custom hook can't be used with conditional render
    return <IntercomLoader />
  }

  return null
}
