import React from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'
import { FlexRow } from '@components/ui/Wrappers'

interface TableSelectionControlsProps {
  isAllSelected: boolean
  selectedNum: number
}

const WrapperDiv = styled(FlexRow)`
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
`

export default function TableSelectionControls({ isAllSelected, selectedNum }: TableSelectionControlsProps) {
  const hasSelection = isAllSelected || selectedNum > 0

  const count = isAllSelected ? (
    <FormattedMessage id="tables.selection.values.all" defaultMessage="Minden" />
  ) : (
    selectedNum
  )

  return (
    <WrapperDiv>
      <Fade in={hasSelection} timeout={{ exit: 0 }}>
        <Typography size="400-md">
          <FormattedMessage
            id="tables.selection.controls.selected"
            defaultMessage="{count} elem kijelölve"
            values={{
              count: <strong>{count}</strong>,
            }}
          />
        </Typography>
      </Fade>
    </WrapperDiv>
  )
}

TableSelectionControls.propTypes = {
  isAllSelected: PropTypes.bool.isRequired,
  selectedNum: PropTypes.number.isRequired,
}
