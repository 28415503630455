import React from 'react'

const AnnouncementIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="37.5" fill="white" stroke="#EF572C" strokeWidth="5" />
    <path
      d="M43.9375 48.0312H36.4375L35.375 16.5H45L43.9375 48.0312ZM40.1875 52.9688C41.7083 52.9688 42.9271
        53.4167 43.8438 54.3125C44.7812 55.2083 45.25 56.3542 45.25 57.75C45.25 59.125 44.7812 60.2604
        43.8438 61.1562C42.9271 62.0521 41.7083 62.5 40.1875 62.5C38.6875 62.5 37.4688 62.0521 36.5312
        61.1562C35.6146 60.2604 35.1562 59.125 35.1562 57.75C35.1562 56.375 35.6146 55.2396 36.5312
        54.3438C37.4688 53.4271 38.6875 52.9688 40.1875 52.9688Z"
      fill="#EF572C"
    />
  </svg>
)

export default AnnouncementIcon
