import React from 'react'

import styled from 'styled-components'

function BaseHamburgerMenuIcon({ className, size = 24 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="2" x="2" y="5" fill="currentColor" rx="1" />
      <rect width="20" height="2" x="2" y="11" fill="currentColor" rx="1" />
      <rect width="20" height="2" x="2" y="17" fill="currentColor" rx="1" />
    </svg>
  )
}

export const HamburgerMenuIcon = styled(BaseHamburgerMenuIcon)``
